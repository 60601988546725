import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { FeedbackModalEmptyStateContainer } from "./styled-feedback-modal-body";

type EmptyFeedbackModalProps = {
  subText: string;
  customFeedback?: boolean;
};

export const EmptyFeedbackModal = (props: EmptyFeedbackModalProps) => {
  const { subText, customFeedback } = props;

  return (
    <FeedbackModalEmptyStateContainer
      customFeedback={customFeedback !== undefined ? true : false}
    >
      <EmptyState
        text="Select date to show graphs"
        subText={subText}
        hasHeader={false}
        hasSearch={false}
        title=""
        button={<></>}
      />
    </FeedbackModalEmptyStateContainer>
  );
};
