import {
  messageHasReactions,
  useChatContext,
  useMessageContext,
} from "stream-chat-react";
import { DefaultStreamChatMessage } from "./default-stream-chat-message";
import { SenderCustomMessage } from "./sender-custom-message";
import { ReceiverCustomMessage } from "./receiver-custom-message";

export function CustomMessage() {
  const { message, reactionSelectorRef, showDetailedReactions } =
    useMessageContext();

  const { client } = useChatContext();

  const hasAttachments = message.attachments && message.attachments.length > 0;

  const voiceAttachments = [];

  if (hasAttachments) {
    voiceAttachments.push(
      message.attachments?.find((msg) => msg.type === "audio")
    );
  }

  const hasReactions = messageHasReactions(message);

  const messageActions =
    hasReactions && message.own_reactions?.length! > 0
      ? ["edit", "delete", "flag", "mute", "pin", "quote", "reply"]
      : ["edit", "delete", "flag", "mute", "pin", "quote", "reply", "react"];

  if (
    message.type !== "deleted" &&
    message.attachments?.some((attachment) => attachment.type === "audio")
  ) {
    if (message.user?.id === client.user?.id) {
      return (
        <SenderCustomMessage
          isPinnedMessage={false}
          message={message}
          hasReactions={hasReactions}
          reactionSelectorRef={reactionSelectorRef}
          showDetailedReactions={showDetailedReactions}
        />
      );
    } else {
      return (
        <ReceiverCustomMessage
          isPinnedMessage={false}
          message={message}
          hasReactions={hasReactions}
          reactionSelectorRef={reactionSelectorRef}
          showDetailedReactions={showDetailedReactions}
        />
      );
    }
  } else {
    return (
      <DefaultStreamChatMessage
        message={message}
        messageActions={messageActions}
        hasReactions={hasReactions}
        whitePinnedIcon={message.user?.id === client.user?.id}
      />
    );
  }
}
