import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  flexCenterSpaceBetween,
  mediumRegularFont,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const DietitianReportTabHeaderContainer = styled.div`
  padding: 0px 24px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenterSpaceBetween}
`;

export const DietitianReportTabHeaderTitleContainer = styled.div`
  ${flexCenter}
  gap: 0px 12px;
`;

export const DietitianReportTabHeaderTitleIcon = styled.div`
  ${flexCenterCenter}
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};

  img {
    width: 50%;
    height: 50%;
  }
`;

export const DietitianReportTabHeaderTitleText = styled.div`
  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
  }

  span {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
