import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  mediumFont,
  mediumRegularFont,
  regularMediumFont,
} from "../../../styles/classes/gloabl-classes";
import { transition } from "../../../styles/global-style";

export const FoodLoggingSelectedPatientMealContainer = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-bottom: 90px;
`;

export const FoodLoggingSelectedPatientMealHeader = styled.div`
  background: ${({ theme }) => theme.newColors.containerColorAlt};
  padding: 16px 24px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenterSpaceBetween}
`;

export const FoodLoggingSelectedPatientMealHeaderText = styled.div`
  p {
    ${flexCenter}
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px 0px 8px;

    span {
      color: ${({ theme }) => theme.colors.success};
      ${regularMediumFont}
      margin-left: 8px;
    }
  }

  span {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.titleColor};
    ${flexCenter}
    gap: 0px 8px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  strong {
    color: ${({ theme }) => theme.colors.titleColor};
    font-size: 18px;
    ${flexCenter}
    gap: 0px 8px;

    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const FoodLoggingSelectedPatientMealContainerTitle = styled.p`
  margin: 0px 0px 16px;
  color: ${({ theme }) => theme.colors.titleColor};
  ${mediumFont}
`;

export const FoodLoggingSelectedPatientMealBody = styled.div`
  padding: 8px 24px;
  display: grid;
`;

// Carb & Protein Sources styles
export const FoodLoggingSelectedPatientMealSourcesContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  gap: 0px 16px;
`;

export const FoodLoggingSelectedPatientMealSourcesInputContainer = styled.div`
  width: 450px;
  ${flexCenter}
  gap: 0px 8px;

  button {
    padding: 8px 16px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.primaryColor};
    border: 0px;
    outline: 0px;
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.containerColor};
    transition: ${transition};
  }
`;

export const FoodLoggingSelectedPatientMealSourcesList = styled.ul`
  padding: 0;
  display: flex;
  align-items: flex-end;
  margin: auto 0px 0px;
  gap: 0.5rem 0rem;
  flex-wrap: wrap;
`;

export const FoodLoggingSelectedPatientMealSourcesListItem = styled.li`
  ${mediumFont}
  color: ${({ theme }) => theme.colors.buttonColorAlt};
  background: ${({ theme }) => theme.newColors.gray6};
  border-radius: 94px;
  display: flex;
  align-items: center;
  padding: 6px 16px;
  gap: 0px 8px;
  width: max-content;
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.newColors.newBorderColor};
  margin-right: 0.4rem;
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.colors.textColor};
    font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
    cursor: pointer;
  }
`;

export const FoodLoggingSelectedPatientMealNotesOrVoiceNotesContainerContainer = styled.div`
  background: ${({ theme }) => theme.colors.bodyColor};
  border-radius: 16px;
  padding: 16px;
  margin: 0 auto;
  width: 100%;
`;
