import {
  EducationListItemLeftContainer,
  EducationListItemTextContainer,
} from "../../../plan-steps/education/styled-education";
import { VideoModal } from "../../../plan-steps/education/video-modal/video-modal";
import { SinglePlanDropdown } from "./components/single-plan-dropdown";
import { SinglePlanDropdownListItem } from "./components/styled-single-plan-dropdown";
import { getEducationContent } from "../../../context/goals-context";
import { PatientDetailedPlanDTO } from "../../../../../../models/patient-plan-dtos/patient-detailed-plan-dto";

type SinglePlanEducationProps = {
  patientDetailedPlanDTO: PatientDetailedPlanDTO;
};

export const SinglePlanEducation = (props: SinglePlanEducationProps) => {
  const { patientDetailedPlanDTO } = props;

  const assignedEducationGoalsList = patientDetailedPlanDTO.patientPlanGoals.filter(
    (item) => item.goalType === "EducationContent"
  );

  const education = assignedEducationGoalsList.map((goal, index) => {
    const goalObject = getEducationContent(goal.goal).lesson;

    return (
      <SinglePlanDropdownListItem key={index}>
        <EducationListItemLeftContainer>
          <EducationListItemTextContainer arabicFlex>
            <p>{goalObject.title}</p>
            {goalObject.type === "Video" && (
              <VideoModal viewPlansModal={true} videoURL={goalObject.link} />
            )}
            {(goalObject.type === "PDF" || goalObject.type === "Image") && (
              <a href={goalObject.link} target="_blank" rel="noreferrer">
                <span className="material-symbols-outlined">description</span>
                {goalObject.type === "Image" && "Article"}
                {goalObject.type === "PDF" && "PDF"}
              </a>
            )}
          </EducationListItemTextContainer>
        </EducationListItemLeftContainer>
      </SinglePlanDropdownListItem>
    );
  });

  return (
    <div>
      <SinglePlanDropdown
        icon="/img/plans/nav/education.svg"
        img="/img/view-plans/education.svg"
        items={education}
        subtitle="تابع بعض الفيديوهات و المقالات"
        title="المحتوي التعليمي"
      />
    </div>
  );
};
