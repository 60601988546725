import { TH } from "../../../../components/table/styled-table";

export const ContentCoursesTableHeadings = () => {
  const tableHeadings = ["Thumbnail", "Name", "Status", "Actions"].map(
    (heading, index) => (
      <TH key={index} actions={heading === "Actions" && true}>
        <div>{heading}</div>
      </TH>
    )
  );

  return <>{tableHeadings}</>;
};
