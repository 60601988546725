import { useDateRange } from "../../../../../../components/date-range/context/date-range-context";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { useNutritionAnalysisData } from "../../context/nutrition-analysis-data-context";
import { NutritionAnalysisResponseHeader } from "../nutrition-analysis-response-header/nutrition-analysis-response-header";
import { NutritionAnalysisMacrosChart } from "./nutrition-analysis-macros-chart/nutrition-analysis-macros-chart";
import { NutritionAnalysisMealsChart } from "./nutrition-analysis-meals-chart/nutrition-analysis-meals-chart";
import {
  NutritionAnalysisResponseChartsContainer,
  NutritionAnalysisResponseContainer,
} from "./styled-nutrition-analysis-response";

export const NutritionAnalysisResponse = () => {
  const { groupedPatientMealsByDate } = useNutritionAnalysisData();

  const { selectedDates } = useDateRange();

  if (selectedDates.count > 31) {
    return (
      <NutritionAnalysisResponseContainer>
        <EmptyState
          button={<></>}
          hasHeader={false}
          hasSearch={false}
          text="Please Choose a date range less than 31 days"
          title=""
          img="Exclamation"
        />
      </NutritionAnalysisResponseContainer>
    );
  }

  if (groupedPatientMealsByDate.length === 0) {
    return (
      <NutritionAnalysisResponseContainer>
        <EmptyState
          button={<></>}
          hasHeader={false}
          hasSearch={false}
          text="No Meals within the selected date range"
          title=""
        />
      </NutritionAnalysisResponseContainer>
    );
  }

  return (
    <NutritionAnalysisResponseContainer>
      <NutritionAnalysisResponseHeader />
      <NutritionAnalysisResponseChartsContainer>
        <NutritionAnalysisMealsChart />
        <NutritionAnalysisMacrosChart />
      </NutritionAnalysisResponseChartsContainer>
    </NutritionAnalysisResponseContainer>
  );
};
