import { Attachment } from "stream-chat";
import { Modal } from "../../../../../../../../../components/modal/modal";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import {
  MessagingMediaSidebarCustomMessageImagesModalContainer,
  MessagingMediaSidebarCustomMessageImagesModalSlide,
  MessagingMediaSidebarCustomMessageImagesModalSlideNumber,
  MessagingMediaSidebarCustomMessageImagesModalSlider,
  MessagingMediaSidebarCustomMessageImagesModalSliderButtons,
} from "./styled-messaging-media-sidebar-custom-message-images-modal";
import { useState } from "react";

type MessagingMediaSidebarCustomMessageImagesModalProps = {
  closeModal: () => void;
  isModalOpen: boolean;
  modalBodyKey: number;
  images?: Attachment<DefaultStreamChatGenerics>[];
};

export const MessagingMediaSidebarCustomMessageImagesModal = (
  props: MessagingMediaSidebarCustomMessageImagesModalProps
) => {
  const { closeModal, isModalOpen, modalBodyKey, images } = props;

  const [currentImage, setCurrentImage] = useState(0);

  const leftButton = (
    <span
      onClick={() => {
        if (images) {
          if (currentImage <= 0) {
            setCurrentImage(images.length - 1);
          } else {
            setCurrentImage(currentImage - 1);
          }
        }
      }}
    >
      &#8249;
    </span>
  );

  const rightButton = (
    <span
      onClick={() => {
        if (images) {
          if (currentImage + 1 >= images?.length) {
            setCurrentImage(0);
          } else {
            setCurrentImage(currentImage + 1);
          }
        }
      }}
    >
      &#8250;
    </span>
  );

  const buttons = (
    <MessagingMediaSidebarCustomMessageImagesModalSliderButtons>
      {leftButton}
      {rightButton}
    </MessagingMediaSidebarCustomMessageImagesModalSliderButtons>
  );

  return (
    <Modal
      handleHideModal={() => {
        closeModal();
        setCurrentImage(0);
      }}
      showModal={isModalOpen}
    >
      <MessagingMediaSidebarCustomMessageImagesModalContainer
        key={modalBodyKey}
      >
        <MessagingMediaSidebarCustomMessageImagesModalSlider>
          {images &&
            images.map((image, index) => (
              <MessagingMediaSidebarCustomMessageImagesModalSlide
                key={image.image_url}
                showImage={currentImage === index}
              >
                <MessagingMediaSidebarCustomMessageImagesModalSlideNumber>
                  <p>{`${currentImage + 1} / ${images.length}`}</p>
                </MessagingMediaSidebarCustomMessageImagesModalSlideNumber>
                <img src={image.image_url} alt={`from ${image.author_name}`} />
              </MessagingMediaSidebarCustomMessageImagesModalSlide>
            ))}
          {buttons}
        </MessagingMediaSidebarCustomMessageImagesModalSlider>
      </MessagingMediaSidebarCustomMessageImagesModalContainer>
    </Modal>
  );
};
