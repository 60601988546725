import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";

type NutritionReportVegetablesTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportVegetablesTableCell = (
  props: NutritionReportVegetablesTableCellProps
) => {
  const { kind } = props;

  const { vegetablesRow, vegetablesMeal } = useMemo(() => {
    const vegetablesRow =
      kind.kind === "Row"
        ? kind.data.foodGroups.find((item) => item.foodGroup.id === 4)
        : undefined;

    const vegetablesMeal =
      kind.kind === "Meal"
        ? kind.data.foodGroups.find((item) => item.foodGroup.id === 4)
        : undefined;

    return { vegetablesRow, vegetablesMeal };
  }, [kind.data, kind.kind]);

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      value={
        kind.kind === "Row"
          ? vegetablesRow?.totalServingsCount
          : vegetablesMeal?.servingsCount
      }
    />
  );
};
