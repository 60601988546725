import { useCallback } from "react";
import { PatientLabTestResultDTO } from "../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";

export function useGetPatientLabTestResults(): (
  patientId: number,
  startDate?: string,
  endDate?: string,
  labTestTypeId?: number
) => Promise<PatientLabTestResultDTO[]> {
  const client = useClient();

  return useCallback(
    async (
      patientId,
      startDate = undefined,
      endDate = undefined,
      labTestTypeId = undefined
    ) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientLabTestResultDTO[]>(
        `${PATIENTS_PATH}/${patientId}/lab-test-results`,
        {
          params: {
            startDate,
            endDate,
            labTestTypeId,
          },
        }
      );

      return response.data;
    },
    [client]
  );
}
