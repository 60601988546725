import { Controller, useFormContext } from "react-hook-form";
import {
  UXInputContainer,
  UXInputLabel,
  UXInputTextarea,
  UXInputWrapper,
} from "../../../../../../../../styles/classes/reusable-classes";
import { ContentModuleUpdateModeInputs } from "../../../../../../context/content-module-mode-context";

type LessonUpdateModeCardTextPartProps = {
  cardIndex: number;
  cardPartIndex: number;
};

export const LessonUpdateModeCardTextPart = (
  props: LessonUpdateModeCardTextPartProps
) => {
  const { cardIndex, cardPartIndex } = props;

  const { control } = useFormContext<ContentModuleUpdateModeInputs>();

  return (
    <Controller
      control={control}
      name={`contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}.text`}
      render={({ field: { value, onChange } }) => (
        <UXInputContainer $textareaDimentions>
          <UXInputLabel $width="50px">Text</UXInputLabel>
          <UXInputWrapper $textareaDimentions $width="100%">
            <UXInputTextarea
              value={value === undefined ? "" : value}
              onChange={(e) =>
                onChange(e.target.value === undefined ? "" : e.target.value)
              }
              placeholder="Enter Text"
            />
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
