import styled from "styled-components/macro";
import {
  flexCenter,
  flexColCenter,
  smallMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const ViewPlansMedicationsList = styled.ul`
  padding: 1rem;
  display: grid;
  gap: 1rem;
`;

export const ViewPlansMedicationsListItem = styled.li`
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 8px 16px;
  ${flexCenter}
  gap: 0rem 1rem;
`;

export const ViewPlansMedicationsListItemMedicationIndicator = styled.div<{
  isStopped: boolean;
  isNew: boolean;
}>`
  display: ${({ isStopped, isNew }) => (isNew || isStopped ? "block" : "none")};
  ${flexColCenter}
  margin-left: auto;

  div {
    background: ${({ isStopped, isNew, theme }) =>
      isStopped
        ? theme.newColors.dangerAlt
        : isNew
        ? theme.newColors.successAlt
        : "transparent"};
    border-radius: 16px;
    color: ${({ isStopped, isNew, theme }) =>
      isStopped
        ? theme.colors.danger
        : isNew
        ? theme.colors.success
        : "transparent"};
    text-align: center;
    padding: 2px 8px;
    min-width: 70px;
  }

  span {
    margin-top: 4px;
    ${smallMediumFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
