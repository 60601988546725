import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { ContentModuleDTO } from "../../models/content-module-dtos/content-module-dto";
import { ContentModuleType } from "../../models/content-module-dtos/content-module-type";

export function useGetContentModules(): (
  contentModuleType?: ContentModuleType,
  parentContentModuleId?: number
) => Promise<ContentModuleDTO[]> {
  const client = useClient();

  return useCallback(
    async (
      contentModuleType = undefined,
      parentContentModuleId = undefined
    ) => {
      const response = await client.get<ContentModuleDTO[]>(
        `${BASE_PATH}/content-modules`,
        {
          params: {
            contentModuleType,
            parentContentModuleId,
          },
        }
      );

      return response.data;
    },
    [client]
  );
}
