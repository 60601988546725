import { FormProvider, useForm } from "react-hook-form";
import { ManualReadingsModal } from "./manual-readings-modal";
import { PatientPlanFeedbackTone } from "../../../../../../../models/patient-plan-dtos/patient-plan-feedback-dto";

type ManualReadingsModalContainerProps = {
  resetCurrentFeedbackModal: () => void;
};

export type ManualReadingsFormInputs = {
  feedbackTone?: PatientPlanFeedbackTone;
  notes?: string;
  bloodGlucoseManualStartDate?: string;
  bloodGlucoseManualEndDate?: string;
  bloodGlucoseManualTag?: string;
};

export const ManualReadingsModalContainer = (
  props: ManualReadingsModalContainerProps
) => {
  const { resetCurrentFeedbackModal } = props;

  const methods = useForm<ManualReadingsFormInputs>({
    defaultValues: {
      feedbackTone: undefined,
      notes: undefined,
      bloodGlucoseManualStartDate: undefined,
      bloodGlucoseManualEndDate: undefined,
      bloodGlucoseManualTag: undefined,
    },
  });

  return (
    <FormProvider {...methods}>
      <ManualReadingsModal
        resetCurrentFeedbackModal={resetCurrentFeedbackModal}
      />
    </FormProvider>
  );
};
