import {
  ImmediateAttentionPatientsFiltersSidebarFilterTitle,
  ImmediateAttentionPatientsFiltersSidebarFilterCheckboxList,
} from "../styled-immediate-attention-patients-filters-sidebar";

const chronicDiseases = [
  "Pre-diabetes",
  "Diabetes Mellitus Type 2",
  "Diabetes Mellitus Type 1",
  "Gestational Diabetes",
  "Hypertension",
  "Chronic Heart Disease",
  "Ischemic Heart Disease",
  "Polycystic ovaries",
  "Dyslipidemia",
  "Chronic thyroid disorder",
  "GIT disorders",
  "Kidney diseases",
  "Liver diseases",
  "Oncology",
  "Kidney stones",
  "Cholecystitis / stones",
  "Respiratory disorders",
];

export const ImmediateAttentionPatientsFiltersSidebarChronicDiseases = () => {
  return (
    <>
      <ImmediateAttentionPatientsFiltersSidebarFilterTitle>
        Chronic Diseases
      </ImmediateAttentionPatientsFiltersSidebarFilterTitle>
      <ImmediateAttentionPatientsFiltersSidebarFilterCheckboxList $height="190px">
        {chronicDiseases.map((chronicDisease) => (
          <div key={chronicDisease}>
            <input type="checkbox" />
            <label>{chronicDisease}</label>
          </div>
        ))}
      </ImmediateAttentionPatientsFiltersSidebarFilterCheckboxList>
    </>
  );
};
