import { useCallback } from "react";
import { LabTestTypeDTO } from "../../../../../../models/lab-test-types/lab-test-type-dto";
import { EditLabResult } from "../../../edit-lab-result/edit-lab-result";
import { displayRef } from "../../../utils/lab-test-results-utils";
import { GroupedLabTestResultsByType } from "../../lab-result-test";
import {
  LabResultTestRefsContainer,
  LabResultTestRefsHeader,
  LabResultTestRefsSubContainer,
  LabResultTestRefsTagContainer,
} from "./styled-new-lab-result-test-refs";
import { useLabResults } from "../../../context/loadable-lab-results-context";

type LabResultTestRefsProps = {
  groupedLabTestResultsByType: GroupedLabTestResultsByType;
};

export const LabResultTestRefs = (props: LabResultTestRefsProps) => {
  const { groupedLabTestResultsByType } = props;

  const { labTestTypeRefDTOs } = useLabResults();

  const refsDisplays = useCallback(
    (labTestTypeDTO: LabTestTypeDTO) =>
      labTestTypeRefDTOs
        .filter((ref) => ref.labTestTypeId === labTestTypeDTO.id)
        .map((ref) => displayRef(ref)),
    [labTestTypeRefDTOs]
  );

  return (
    <LabResultTestRefsContainer>
      <LabResultTestRefsHeader>
        <p>Ref. Range</p>
      </LabResultTestRefsHeader>
      {groupedLabTestResultsByType.map((labTest) => (
        <LabResultTestRefsSubContainer key={labTest.labTestTypeDTO.name}>
          <div>
            {refsDisplays(labTest.labTestTypeDTO).map((refDisplay, index) => {
              if (refDisplay.parts.includes(":")) {
                const indexOfColon = refDisplay.parts.indexOf(":");
                const tag = refDisplay.parts.slice(0, indexOfColon);
                const refDefinition = refDisplay.parts.slice(
                  indexOfColon + 1,
                  refDisplay.parts.length
                );

                return (
                  <LabResultTestRefsTagContainer key={index}>
                    <span>{tag}</span> <p>{refDefinition}</p>
                  </LabResultTestRefsTagContainer>
                );
              } else {
                return <p key={index}>{refDisplay.parts}</p>;
              }
            })}
          </div>
          <EditLabResult labTest={labTest.sortedLabTestResultsByDate} />
        </LabResultTestRefsSubContainer>
      ))}
    </LabResultTestRefsContainer>
  );
};
