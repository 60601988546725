import styled from "styled-components/macro";
import {
  flexCenter,
  regularSemiBoldFont,
} from "../../../../../../../../../styles/classes/gloabl-classes";

export const CarbCounterMealAddFoodModalBodyFoodResponseQuantityContainer = styled.div`
  div {
    ${flexCenter}
    gap: 0px 16px;
    margin-top: 4px;

    button,
    input {
      border-radius: 2px;
      background: ${({ theme }) => theme.colors.borderColor};
    }

    input {
      padding: 10px;
      width: 60px;
      height: 40px;
      outline: 0px;
      border: 0px;
      text-align: center;
    }

    button {
      ${regularSemiBoldFont}
    }
  }
`;
