import { Temporal } from "temporal-polyfill";
import { GoogleChartOptions } from "react-google-charts";
import { Source } from "../../../models/patient-cgm-entry-dtos/patient-cgm-entry-source";

export function buildOptions(
  date: Temporal.PlainDate,
  max: number,
  min: number,
  vAxisTicks: number[],
  feedbacksHeight: number | undefined,
  chartArea: {
    left: number;
    top: number;
    bottom: number;
    right: number;
  },
  source?: Source
): GoogleChartOptions {
  let lineWidth!: number;
  let hAxisFontSize!: number;
  let vAxisFontSize!: number;

  const firstTick = date.toPlainDateTime("00:00:00");
  const hAxisTicks: Date[] = [];

  for (let hour = 0; hour <= 24; hour += 3) {
    const tick = firstTick.add({ hours: hour });
    const jsDate = new Date(tick.toLocaleString());
    hAxisTicks.push(jsDate);
  }

  // Most options are copy-pasted from old-report. Not entirely sure if they all make sense, honestly.
  return {
    tooltip: {
      isHtml: true,
      trigger: feedbacksHeight === undefined ? "both" : "none",
    },
    // The casting is just to bypass the type-check, not an actual conversion.
    // React-Google-Charts' width seems to accept only `number`, while old-report sometimes sent 'auto'
    height: feedbacksHeight !== undefined ? feedbacksHeight : 180,
    chartArea: {
      height: feedbacksHeight !== undefined ? feedbacksHeight : 180,
      left: chartArea.left,
      top: chartArea.top,
      bottom: chartArea.bottom,
      right: chartArea.right,
    },
    title: "",
    lineWidth: source === "Manual" ? 5 : lineWidth,
    colors: source === "Manual" ? ["#00086b"] : ["#adb2b1"],
    titleTextStyle: {
      color: "#686868",
      fontName: "Verdana",
      fontSize: 20,
      bold: false,
      italic: false,
    },
    curveType: "function",
    backgroundColor: "none",
    legend: "none",
    hAxis: {
      textStyle: {
        fontName: "Verdana",
        color: "#666666",
        fontSize: hAxisFontSize,
      },
      title: "",
      titleTextStyle: {
        color: "#666666",
        fontName: "Verdana",
        fontSize: hAxisFontSize,
        bold: true,
        italic: false,
      },
      format: "h a",
      ticks: hAxisTicks,
      gridlines: {
        color: "transparent",
        count: 0,
      },
    },
    vAxis: {
      textStyle: {
        fontName: "Verdana",
        color: "#666666",
        fontSize: vAxisFontSize,
      },
      title: "",
      titleTextStyle: {
        color: "#666666",
        fontName: "Verdana",
        fontSize: vAxisFontSize,
        bold: true,
        italic: false,
      },
      viewWindowMode: "explicit",
      viewWindow: {
        max,
        min,
      },
      ticks: vAxisTicks,
      gridlines: {
        color: "transparent",
        count: 0,
      },
      minorGridlines: { color: "transparent", count: 0 },
    },
    series: {
      3: {
        type: "line",
      },
      4: {
        type: "line",
      },
      0: {
        areaOpacity: 1,
        lineWidth: 0,
        type: "area",
        color: "#fdf5f3",
        visibleInLegend: false,
        enableInteractivity: false,
      },
      1: {
        areaOpacity: 1,
        lineWidth: 0,
        type: "area",
        color: "#f2fcf9",
        visibleInLegend: false,
        enableInteractivity: false,
      },
      2: {
        areaOpacity: 1,
        lineWidth: 0,
        type: "area",
        color: "#fdf5f3",
        visibleInLegend: false,
        enableInteractivity: false,
      },
    },
  };
}
