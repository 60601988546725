import axios, { Axios } from "axios";
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "../../constants/config";
import { BASE_PATH } from "../../constants/paths";
import { UserDTO } from "../../models/user-dto";

export async function verifyOtp(
  phoneNumber: string,
  otp: string
): Promise<string> {
  const client = axios.create();
  const response = await client.post<{ id_token: string }>(
    `https://${AUTH0_DOMAIN}/oauth/token`,
    {
      grant_type: "http://auth0.com/oauth/grant-type/passwordless/otp",
      client_id: AUTH0_CLIENT_ID,
      otp: otp,
      realm: "sms",
      username: `+2${phoneNumber}`,
    }
  );

  return response.data.id_token;
}

export async function getLoggedInUser(
  client: Axios,
  token: string
): Promise<UserDTO> {
  const response = await client.get<UserDTO>(`${BASE_PATH}/user`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

export async function sendOtp(phoneNumber: string) {
  const client = axios.create();
  await client.post(`https://${AUTH0_DOMAIN}/passwordless/start`, {
    client_id: AUTH0_CLIENT_ID,
    connection: "sms",
    phone_number: `+2${phoneNumber}`,
    send: "code",
  });
}
