import { PatientCGMEntryDTO } from "../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientInsulinLogDTO } from "../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";
import {
  getAfterMealTag,
  getTag,
} from "../../../screens/single-patient/bgl-analysis/upload-options/manual-readings/manual-readings-modal/manual-readings-table/tag-dropdown/tag-dropdown-utils";
import {
  ReadingTooltipBody,
  ReadingTooltipInOrOutOfRangeTag,
  ReadingTooltipReadingText,
  ReadingTooltipTagText,
  ToolTipBody,
  TooltipContainer,
  TooltipHeader,
} from "../../../styles/classes/tooltips";

export type BgmChartTooltipProps = {
  x: string;
  y: number;
  kind:
    | {
        kind: "Reading";
        data: PatientCGMEntryDTO & { isInRange: boolean };
      }
    | {
        kind: "Insulin";
        data: PatientInsulinLogDTO;
      };
};

export const BgmChartTooltip = (props: BgmChartTooltipProps) => {
  const { x, y, kind } = props;

  const tooltipBody =
    kind.kind === "Reading" ? (
      <ReadingTooltipBody>
        <div>
          <ReadingTooltipReadingText>
            {kind.data.glucoseMGPerDL} <span>mg/dL</span>
          </ReadingTooltipReadingText>
          <ReadingTooltipTagText>{`${getTag(kind.data.tag!)}${
            kind.data.afterMealTag !== undefined
              ? `- ${getAfterMealTag(kind.data.afterMealTag)}`
              : ""
          }`}</ReadingTooltipTagText>
        </div>
        <ReadingTooltipInOrOutOfRangeTag isInRange={kind.data.isInRange}>
          {kind.data.isInRange ? "In Range" : "Out of Range"}
        </ReadingTooltipInOrOutOfRangeTag>
      </ReadingTooltipBody>
    ) : (
      <ToolTipBody>
        <p>
          <span>{kind.data.insulinMedication.name}</span>
        </p>
        <p>
          {y} <span>Units</span>
        </p>
      </ToolTipBody>
    );

  return (
    <TooltipContainer>
      <TooltipHeader>
        <p>{kind.kind}</p>
        <strong>{x}</strong>
      </TooltipHeader>
      {tooltipBody}
    </TooltipContainer>
  );
};
