import styled from "styled-components/macro";
import {
  mediumFont,
  regularMediumFont,
} from "../../../../../../../styles/classes/gloabl-classes";
import {
  SidebarWidth,
  SinglePatientSidebarWidth,
} from "../../../../../../../styles/global-style";
import { NewPrescriotionSidebarWidth } from "../../../constants/prescription-constants";

export const NewPrescriptionFormFooterContainer = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.containerColor};
  height: 96px;
  margin-top: auto;
  position: fixed;
  bottom: 0;
  left: calc(7px + (${NewPrescriotionSidebarWidth} + ${SidebarWidth}));
  right: ${SinglePatientSidebarWidth};

  @media screen and (max-width: 1024px) {
    right: 0;
  }
`;

export const NewPrescriptionFormFooterSig = styled.div`
  margin: 1.5rem auto 1.5rem 0rem;

  span {
    color: ${({ theme }) => theme.colors.screenTitleColor};
    ${mediumFont}
  }

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.screenTitleColor};
    margin: 0;
    margin-top: 4px;
  }
`;
