import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Temporal } from "temporal-polyfill";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { useUpdatePatientPlan } from "../../../../../../../hooks/patient-plan-hooks/use-update-patient-plan";
import { UpdatePatientPlanDTO } from "../../../../../../../models/patient-plan-dtos/update-patient-plan-dto";
import {
  Button,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import { PlansForm } from "../../../../components/plans-form/plans-form";
import {
  useDetailedPlan,
  useReloadDetailedPlan,
} from "../../../../context/loadable-detailed-plan-context";
import { FeedbackModalModalContainer } from "../feedback-modal-body/styled-feedback-modal-body";
import { CustomFeedbackFormInputs } from "./custom-feedback-container";
import { CustomFeedbackLabCards } from "./custom-feedback-lab-cards";

type CustomFeedbackProps = {
  resetCurrentFeedbackModal: () => void;
};

export const CustomFeedback = (props: CustomFeedbackProps) => {
  const { resetCurrentFeedbackModal } = props;

  const { handleSubmit, watch } = useFormContext<CustomFeedbackFormInputs>();

  const updatePatientPlan = useUpdatePatientPlan();
  const reloadPatientDetailedPlan = useReloadDetailedPlan();

  const labTestTypeId = watch("labTestTypeId");
  const feedbackTone = watch("feedbackTone");
  const notes = watch("notes");

  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const temporalStartDate = Temporal.PlainDate.from(
    startDate.toISOString().split("T")[0]
  )
    .add({ days: 1 })
    .toString();
  const temporalEndDate = Temporal.PlainDate.from(
    endDate.toISOString().split("T")[0]
  )
    .add({ days: 1 })
    .toString();

  const { patientDetailedPlanDTO } = useDetailedPlan();
  const { showToast } = useToastService();

  const onSubmit = handleSubmit(async () => {
    const updateDTO: UpdatePatientPlanDTO = {
      ...patientDetailedPlanDTO,
      patientPlanFeedbacks: [
        ...patientDetailedPlanDTO.patientPlanFeedbacks,
        {
          feedbackType: "Custom",
          feedbackTone: feedbackTone!,
          notes,
          labTestTypeId: labTestTypeId!,
          labTestResultsStartDate: temporalStartDate,
          labTestResultsEndDate: temporalEndDate,
        },
      ],
    };

    updatePatientPlan(patientDetailedPlanDTO.patientPlan, updateDTO)
      .then(() => {
        showToast("Success", "Feedback added successfully");
        reloadPatientDetailedPlan();
      })
      .catch(() => showToast("Error", "Failed to add feedback :("));
  });

  const notesCondition = notes === "" || notes === undefined;
  const modalDisabledCondition = feedbackTone === undefined || notesCondition;

  return (
    <ModalForm width={900} height={750} onSubmit={onSubmit}>
      <ModalHeader
        onModalClose={resetCurrentFeedbackModal}
        title="Other Feedback - Custom Feedback"
        subTitle="Create a Custom Feedback for the patient"
      />
      <FeedbackModalModalContainer>
        <PlansForm
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          customFeedbackForm={true}
        />
        <CustomFeedbackLabCards
          temporalEndDate={temporalEndDate}
          temporalStartDate={temporalStartDate}
        />
      </FeedbackModalModalContainer>
      <ModalFooter>
        <Button type="button" outlined onClick={resetCurrentFeedbackModal}>
          Cancel
        </Button>
        <Button type="submit" disabled={modalDisabledCondition}>
          Add
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
