import { CreatePlansFooter } from "../../create-plans/create-plans-footer/create-plans-footer";
import { PlanStepsHeader } from "../../components/plan-steps-header/plan-steps-header";
import { PlansSubScreenContainer } from "../styles";
import { EmptyFeedback } from "./empty-feedback";
import { FeedbackModal } from "./feedback-modal/feedback-modal";
import { useDetailedPlan } from "../../context/loadable-detailed-plan-context";
import { ListFeedback } from "./list-feedback/list-feedback";

export const Feedback = () => {
  const { patientDetailedPlanDTO } = useDetailedPlan();
  const listFeedbacksData = patientDetailedPlanDTO.patientPlanFeedbacks;

  return (
    <PlansSubScreenContainer>
      <PlanStepsHeader
        title="Feedback about last week"
        subTitle="Share with your patients some feedback"
        icon="/img/plans/nav/feedback.svg"
        button={
          listFeedbacksData.length === 0 ? (
            <></>
          ) : (
            <FeedbackModal variant="outline" />
          )
        }
      />
      {listFeedbacksData.length === 0 ? (
        <EmptyFeedback />
      ) : (
        <>
          {listFeedbacksData.map((item, index) => (
            <ListFeedback key={index} item={item} />
          ))}
        </>
      )}
      <CreatePlansFooter nextStep="Medical" prevStep="Goals" />
    </PlansSubScreenContainer>
  );
};
