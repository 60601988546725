import { FormProvider, useForm } from "react-hook-form";
import { PatientPlanFeedbackTone } from "../../../../../../../models/patient-plan-dtos/patient-plan-feedback-dto";
import { BloodGlucoseModalBody } from "./blood-glucose-modal-body";
import { UpdatePatientPlanPatientPlanFeedbackAttachmentDTO } from "../../../../../../../models/patient-plan-dtos/update-patient-plan-patient-plan-feedback-attachment-dto";

type BloodGlucoseContainerProps = {
  resetCurrentFeedbackModal: () => void;
};

export type BloodGlucoseFormInputs = {
  feedbackTone?: PatientPlanFeedbackTone;
  notes?: string;
  attachments: UpdatePatientPlanPatientPlanFeedbackAttachmentDTO[];
};

export const BloodGlucoseContainer = (props: BloodGlucoseContainerProps) => {
  const { resetCurrentFeedbackModal } = props;

  const methods = useForm<BloodGlucoseFormInputs>({
    defaultValues: {
      feedbackTone: undefined,
      notes: undefined,
      attachments: [],
    },
  });

  return (
    <FormProvider {...methods}>
      <BloodGlucoseModalBody
        resetCurrentFeedbackModal={resetCurrentFeedbackModal}
      />
    </FormProvider>
  );
};
