import styled from "styled-components/macro";
import {
  flexCenterCenter,
  regularRegularFont,
  smallRegularFont,
} from "../../../../../../../../styles/classes/gloabl-classes";

export const MessagingMediaSidebarCustomMessageImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 150px);
  gap: 2px;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto;
  width: 302px;
  position: relative;
`;

export const MessagingMediaSidebarCustomMessageImageContainer = styled.div`
  width: 150px;
  height: 150px;
  ${flexCenterCenter}
  cursor: zoom-in;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MessagingMediaSidebarCustomMessageImagesTime = styled.time`
  color: ${({ theme }) => theme.colors.buttonColor};
  position: absolute;
  bottom: 5px;
  right: 10px;
  ${smallRegularFont}
`;

export const MessagingMediaSidebarCustomMessageImagesMoreContainer = styled.div`
  background: #00000099;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0px 0px 8px 0px;
  width: 150px;
  height: 150px;
  ${flexCenterCenter}
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.buttonColor};
    ${regularRegularFont}
  }
`;
