import { usePrescriptions } from "../../context/prescriptions-context";
import { calculateHeightAndMarginTop } from "../../utils/prescription-utils";
import { AddedPrescriptionMedicinesCard } from "./added-prescription-medicines-card/added-prescription-medicines-card";
import {
  AddedPrescriptionMedicinesCards,
  AddedPrescriptionMedicinesContainer,
  AddedPrescriptionMedicinesTitle,
} from "./styled-added-prescription-medicines";

export const AddedPrescriptionMedicines = () => {
  const { prescriptionMedicines } = usePrescriptions();

  const displayAddedMedicines = prescriptionMedicines.map((card, index) => (
    <AddedPrescriptionMedicinesCard key={index} card={card} />
  ));

  return (
    <>
      {prescriptionMedicines.length > 0 ? (
        <AddedPrescriptionMedicinesContainer
          calculateHeight={calculateHeightAndMarginTop(
            prescriptionMedicines.length > 0
          )}
        >
          <AddedPrescriptionMedicinesTitle>
            Added medicines
          </AddedPrescriptionMedicinesTitle>
          <AddedPrescriptionMedicinesCards>
            {displayAddedMedicines}
          </AddedPrescriptionMedicinesCards>
        </AddedPrescriptionMedicinesContainer>
      ) : (
        <></>
      )}
    </>
  );
};
