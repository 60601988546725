import { Modal } from "../../../../../components/modal/modal";
import { ImportLibreviewNewReadingsModal } from "./import-libreview-new-readings-modal/import-libreview-new-readings-modal";
import { DropdownListItem } from "../../../../../components/dropdown/styled-dropdown";
import { useModalHelpers } from "../../../../../components/modal/use-modal-helpers";

export function ImportLibreviewNewReadings() {
  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <>
      <DropdownListItem onClick={openModal}>
        Import Libreview (new readings)
      </DropdownListItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <ImportLibreviewNewReadingsModal
          key={modalBodyKey}
          onModalClose={closeModal}
        />
      </Modal>
    </>
  );
}
