import { Link, useParams } from "react-router-dom";
import {
  TD,
  TDContainer,
  TDLink,
} from "../../../../../components/table/styled-table";
import { GenericActionsList } from "../../../../../components/generic-actions-list/generic-actions-list";
import { GenericActionsListItemItem } from "../../../../../components/generic-actions-list/styled-generic-actions-list";
import { useMemo } from "react";
import { ContentModuleDTO } from "../../../../../models/content-module-dtos/content-module-dto";
import {
  ContentScreenTableRowsStatusContainer,
  ContentScreenTableRowsThumbnailContainer,
} from "../../../styles/content-reusable-classes";
import { DeleteConentCourseModuleLesson } from "./delete-conent-course-module-lesson";

type ContentCourseModuleLessonsTableRowsProps = {
  searchTerm: string;
  contentModuleDTO: ContentModuleDTO;
};

export const ContentCourseModuleLessonsTableRows = (
  props: ContentCourseModuleLessonsTableRowsProps
) => {
  const { searchTerm, contentModuleDTO } = props;

  const childContentModules = contentModuleDTO.childContentModules;

  const { courseID, moduleID } = useParams();
  const parentContentCourseId = parseInt(courseID!);
  const parentContentCourseModuleId = parseInt(moduleID!);

  const sorted = useMemo(
    () =>
      childContentModules !== undefined
        ? childContentModules.sort((a, b) => {
            if (a.uiOrder !== undefined && b.uiOrder !== undefined) {
              return a.uiOrder - b.uiOrder;
            } else if (a.uiOrder !== undefined) {
              return -1;
            } else if (b.uiOrder !== undefined) {
              return 1;
            } else {
              return a.name.localeCompare(b.name);
            }
          })
        : [],
    [childContentModules]
  );

  const filtered = useMemo(
    () =>
      sorted.filter((item) => {
        if (searchTerm === "") {
          return item;
        } else if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        } else {
          return 0;
        }
      }),
    [sorted, searchTerm]
  );

  const tableRows = filtered.map((lessonDTO) => (
    <tr key={lessonDTO.id}>
      <TD width={100}>
        <TDContainer>
          <span>{lessonDTO.uiOrder}</span>
        </TDContainer>
      </TD>
      <TD width={100}>
        <ContentScreenTableRowsThumbnailContainer>
          {lessonDTO.thumbnailUploadedFile?.presignedUrl && (
            <img
              src={lessonDTO.thumbnailUploadedFile?.presignedUrl}
              alt={`${lessonDTO.name}'s Course Thumbnail`}
            />
          )}
        </ContentScreenTableRowsThumbnailContainer>
      </TD>
      <TD>
        <TDContainer>
          <TDLink>
            <Link
              to={`../courses/course/${parentContentCourseId}/module/${parentContentCourseModuleId}/lesson/${lessonDTO.id}`}
            >
              {lessonDTO.name}
            </Link>
          </TDLink>
        </TDContainer>
      </TD>
      <TD>
        <ContentScreenTableRowsStatusContainer
          $live={lessonDTO.isDraft ? false : true}
        >
          <span>{lessonDTO.isDraft ? "Darft" : "Live"}</span>
        </ContentScreenTableRowsStatusContainer>
      </TD>
      <TD width={100}>
        <TDContainer>
          <GenericActionsList>
            <Link
              to={`../courses/course/${parentContentCourseId}/module/${parentContentCourseModuleId}/lesson/${lessonDTO.id}`}
            >
              <GenericActionsListItemItem>View</GenericActionsListItemItem>
            </Link>
            <Link
              to={`../courses/course/${parentContentCourseId}/module/${parentContentCourseModuleId}/lesson/${lessonDTO.id}/update`}
            >
              <GenericActionsListItemItem>Update</GenericActionsListItemItem>
            </Link>
            <DeleteConentCourseModuleLesson conentModuleLesson={lessonDTO} />
          </GenericActionsList>
        </TDContainer>
      </TD>
    </tr>
  ));

  return <>{tableRows}</>;
};
