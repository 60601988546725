import { PatientDTO } from "../../../models/patient-dtos/patient-dto";
import { useForm, Controller } from "react-hook-form";
import { useAddPatient } from "../../../hooks/patient-hooks/use-add-patient";
import { FormSectionTitle } from "./styled-patient-modal";
import { PatientTypeSelect } from "./patient-type-select";
import {
  Button,
  ErrorText,
  Input,
  InputContainer,
  InputLabel,
  InputsGroup,
  ModalFooter,
} from "../../../styles/classes/reusable-classes";
import { ModalHeader } from "../../../components/modal/modal-header/modal-header";
import { ModalForm } from "../../../components/modal/modal-form/modal-form";
import { useToastService } from "../../../context/toast-service-context";
import { useReloadPatients } from "../context/loadable-patients-context";
import { useUpdatePatient } from "../../../hooks/patient-hooks/use-update-patient";
import { PatientType } from "../../../models/patient-dtos/patient-type";

type Inputs = {
  name: string;
  mobile?: string;
  whatsAppId: number | undefined;
  type: PatientType;
  libreviewPatientId: string | undefined;
  cronometerPatientId: string | undefined;
  activationDate?: string;
};

export type PatientModalProps = {
  onModalClose: () => void;
  mode: { kind: "add" } | { kind: "edit"; patientDTO: PatientDTO };
};

export function PatientModal(props: PatientModalProps) {
  const { onModalClose, mode } = props;
  const addPatient = useAddPatient();
  const updatePatient = useUpdatePatient();
  const reloadPatients = useReloadPatients();
  const { showToast } = useToastService();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues:
      mode.kind === "edit"
        ? {
            name: mode.patientDTO.name,
            whatsAppId: mode.patientDTO.whatsAppId,
            type: mode.patientDTO.type,
            libreviewPatientId: mode.patientDTO.libreviewPatientId,
            cronometerPatientId: mode.patientDTO.cronometerPatientId,
            mobile: mode.patientDTO.mobile,
            activationDate: mode.patientDTO.activationDate,
          }
        : {
            name: "",
            whatsAppId: undefined,
            type: "Type1",
            libreviewPatientId: undefined,
            cronometerPatientId: undefined,
            mobile: undefined,
            activationDate: undefined,
          },
  });

  const onSubmit = handleSubmit(async (inputs) => {
    if (mode.kind === "add") {
      try {
        await addPatient(inputs);
        showToast("Success", "Patient added successfully!");
        reloadPatients();
        onModalClose();
      } catch (e) {
        showToast("Error", "Failed to add patient :(");
      }
    } else {
      const updatedPatientDTO = {
        ...mode.patientDTO,
        name: inputs.name,
        whatsAppId: inputs.whatsAppId,
        type: inputs.type,
        libreviewPatientId: inputs.libreviewPatientId,
        cronometerPatientId: inputs.cronometerPatientId,
        mobile: inputs.mobile,
        activationDate:
          inputs.activationDate !== "" ? inputs.activationDate : undefined,
      };

      try {
        await updatePatient(mode.patientDTO, updatedPatientDTO);
        showToast("Success", "Patient updated successfully!");
        reloadPatients();
        onModalClose();
      } catch (e) {
        showToast("Error", "Failed to update patient :(");
      }
    }
  });

  return (
    <ModalForm onSubmit={onSubmit} height={600} dataTestId="patient-form">
      <ModalHeader
        title={`${mode.kind === "add" ? "Add New " : "Edit "} Patient`}
        onModalClose={onModalClose}
      />
      <FormSectionTitle>Personal Information</FormSectionTitle>

      <InputContainer>
        <InputLabel>Name</InputLabel>
        <Input
          data-testid="name-field"
          {...register("name", { required: true })}
          error={errors.name !== undefined}
          type="text"
        />
        {errors.name?.type === "required" && (
          <ErrorText>Enter patient's name</ErrorText>
        )}
      </InputContainer>
      <InputsGroup>
        <InputContainer>
          <InputLabel>Type</InputLabel>
          <Controller
            name="type"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <PatientTypeSelect value={value} onChange={onChange} />
            )}
          />
          {errors.type?.type === "required" && (
            <ErrorText>Select patient's type</ErrorText>
          )}
        </InputContainer>
        <InputContainer style={{ width: "199px" }}>
          <InputLabel>
            Activation Date
            <InputLabel small>(optional)</InputLabel>
          </InputLabel>
          <Controller
            name="activationDate"
            control={control}
            rules={{ required: false }}
            render={({ field: { value, onChange } }) => (
              <Input
                className="small-input"
                type="date"
                placeholder=""
                value={value !== undefined ? value : ""}
                onChange={(e) =>
                  onChange(e.target.value !== undefined ? e.target.value : "")
                }
              />
            )}
          />
        </InputContainer>
      </InputsGroup>
      <InputsGroup>
        <InputContainer>
          <InputLabel>
            Phone Number
            <InputLabel small>(optional)</InputLabel>
          </InputLabel>
          <Input
            className="small-input"
            type="text"
            placeholder="+201XXXXXXXXX"
            {...register("mobile", {
              required: false,
              setValueAs: (value) => (value === "" ? undefined : value),
            })}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>
            Whats App ID <InputLabel small>(optional)</InputLabel>
          </InputLabel>
          <Input
            data-testid="whatsApp-field"
            className="small-input"
            type="number"
            {...register("whatsAppId", {
              required: false,
              setValueAs: (value) => (value === "" ? undefined : value),
            })}
          />
        </InputContainer>
      </InputsGroup>

      <InputsGroup>
        <InputContainer>
          <InputLabel>
            Patient Libreview ID <InputLabel small>(optional)</InputLabel>
          </InputLabel>
          <Input
            className="small-input"
            {...register("libreviewPatientId", {
              required: false,
              setValueAs: (value) => (value === "" ? undefined : value),
            })}
            type="text"
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>
            Patient Cronometer ID <InputLabel small>(optional)</InputLabel>
          </InputLabel>
          <Input
            className="small-input"
            {...register("cronometerPatientId", {
              required: false,
              setValueAs: (value) => (value === "" ? undefined : value),
            })}
            type="text"
          />
        </InputContainer>
      </InputsGroup>

      <ModalFooter>
        <Button outlined onClick={onModalClose} type="button">
          Cancel
        </Button>
        <Button type="submit">Submit</Button>
      </ModalFooter>
    </ModalForm>
  );
}
