import { Modal } from "../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { NutritionReportTableCellRowOrMealKind } from "../../types/nutrition-report-table-types";
import { NutritionReportFeedbackTableCellModalBody } from "./nutrition-report-feedback-table-cell-modal-body/nutrition-report-feedback-table-cell-modal-body";
import {
  NutritionReportFeedbackTableCellContainer,
  NutritionReportFeedbackTableCellIconContainer,
  NutritionReportFeedbackTableCellTD,
} from "./styled-nutrition-report-feedback-table-cell";
import { NutritionReportMealDateTableCellNote } from "../nutrition-report-meal-date-table-cell/nutrition-report-meal-date-table-cell-note";

type NutritionReportFeedbackTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportFeedbackTableCell = (
  props: NutritionReportFeedbackTableCellProps
) => {
  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();
  const { kind } = props;

  if (kind.kind === "Row") {
    return <td></td>;
  }

  return (
    <NutritionReportFeedbackTableCellTD>
      <NutritionReportFeedbackTableCellContainer>
        <NutritionReportMealDateTableCellNote mealNote={kind.data.feedback} />
        <NutritionReportFeedbackTableCellIconContainer onClick={openModal}>
          <img src="/img/edit.svg" alt="edit" />
        </NutritionReportFeedbackTableCellIconContainer>
        <Modal handleHideModal={closeModal} showModal={isModalOpen}>
          <NutritionReportFeedbackTableCellModalBody
            key={modalBodyKey}
            onClose={closeModal}
            meal={kind.data}
          />
        </Modal>
      </NutritionReportFeedbackTableCellContainer>
    </NutritionReportFeedbackTableCellTD>
  );
};
