import { FormProvider, useForm } from "react-hook-form";
import { Modal } from "../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { PatientProfileEditTimelineModalBody } from "./patient-profile-edit-timeline-modal-body/patient-profile-edit-timeline-modal-body";
import { PatientType } from "../../../../../../models/patient-dtos/patient-type";
import { PutPatientTimelineAnginaPectorisEmergencyDTO } from "../../../../../../models/patient-timeline-dtos/put-patient-timeline-angina-pectoris-emergency-dto";
import { PutPatientTimelineBariatricSurgeryDTO } from "../../../../../../models/patient-timeline-dtos/put-patient-timeline-bariatric-surgery-dto";
import {
  BARIATRIC_SURGERIES,
  BariatricSurgery,
} from "../../../../../../models/surgery";
import { useMemo } from "react";
import { PutPatientTimelineStrokeEmergencyDTO } from "../../../../../../models/patient-timeline-dtos/put-patient-timeline-stroke-emergency-dto";
import { usePatientProfileTimeline } from "../../context/loadable-patient-profile-timeline";
import { usePatientProfile } from "../../../context/loadable-patient-profile-context";

export type PatientProfileEditTimelineModalInputs = {
  type: PatientType;
  diagnosedSince?: string;
  anginaPectorisEmergency?: PutPatientTimelineAnginaPectorisEmergencyDTO;
  bariatricSurgery?: PutPatientTimelineBariatricSurgeryDTO;
  a1cAtDiabetesDiagnosis?: string;
  strokeEmergency?: PutPatientTimelineStrokeEmergencyDTO;
};

export const PatientProfileEditTimelineModal = () => {
  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  const { patientDTO } = usePatientProfile();

  const { patientTimelineDTO } = usePatientProfileTimeline();

  const isAnginaPectorisDefined = useMemo(
    () =>
      patientTimelineDTO.events.find(
        (item) => item.emergency === "AnginaPectoris"
      ),
    [patientTimelineDTO.events]
  );

  const isBariatricSurgeryDefined = useMemo(
    () =>
      patientTimelineDTO.events.find((patientTimeline) => {
        let bariatricSurgeryItem = undefined;

        for (const bariatricSurgery of BARIATRIC_SURGERIES) {
          if (patientTimeline.surgery === bariatricSurgery) {
            bariatricSurgeryItem = patientTimeline.surgery;
          }
        }

        return bariatricSurgeryItem;
      }),
    [patientTimelineDTO.events]
  );

  const isA1cAtDiabetesDiagnosisDefined = useMemo(
    () =>
      patientTimelineDTO.events.find(
        (item) => item.a1cAtDiabetesDiagnosis !== undefined
      ),
    [patientTimelineDTO]
  );

  const isStrokeDefined = useMemo(
    () => patientTimelineDTO.events.find((item) => item.emergency === "Stroke"),
    [patientTimelineDTO]
  );

  const methods = useForm<PatientProfileEditTimelineModalInputs>({
    defaultValues: {
      type: patientDTO.type,
      diagnosedSince:
        patientDTO.diagnosedSince !== undefined
          ? patientDTO.diagnosedSince
          : undefined,
      anginaPectorisEmergency:
        isAnginaPectorisDefined !== undefined
          ? {
              emergency: "AnginaPectoris",
              diagnosedSince:
                isAnginaPectorisDefined.emergency === "AnginaPectoris" &&
                isAnginaPectorisDefined !== undefined
                  ? isAnginaPectorisDefined.diagnosedSince
                  : undefined,
            }
          : undefined,
      bariatricSurgery:
        isBariatricSurgeryDefined !== undefined
          ? {
              surgery:
                isBariatricSurgeryDefined.surgery !== undefined
                  ? (isBariatricSurgeryDefined.surgery as BariatricSurgery)
                  : undefined,
              diagnosedSince:
                isBariatricSurgeryDefined.diagnosedSince !== undefined
                  ? isBariatricSurgeryDefined.diagnosedSince
                  : undefined,
            }
          : undefined,
      a1cAtDiabetesDiagnosis:
        isA1cAtDiabetesDiagnosisDefined !== undefined
          ? isA1cAtDiabetesDiagnosisDefined.a1cAtDiabetesDiagnosis?.toString()
          : undefined,
      strokeEmergency:
        isStrokeDefined !== undefined
          ? {
              emergency: "Stroke",
              diagnosedSince: isStrokeDefined.diagnosedSince,
            }
          : undefined,
    },
  });

  return (
    <>
      <Button outlined flex onClick={openModal}>
        <img src="/img/edit.svg" alt="Edit" /> Edit
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FormProvider {...methods}>
          <PatientProfileEditTimelineModalBody
            key={modalBodyKey}
            onClose={closeModal}
            isAnginaPectorisDefined={isAnginaPectorisDefined}
            isBariatricSurgeryDefined={isBariatricSurgeryDefined}
            isStrokeDefined={isStrokeDefined}
          />
        </FormProvider>
      </Modal>
    </>
  );
};
