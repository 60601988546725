import { PatientProfileInformationHeaderCenterBottom } from "./patient-profile-information-header-center-bottom/patient-profile-information-header-center-bottom";
import { PatientProfileInformationHeaderCenterTop } from "./patient-profile-information-header-center-top/patient-profile-information-header-center-top";
import { PatientProfileInformationHeaderCenterContainer } from "./styled-patient-profile-information-header-center";

export const PatientProfileInformationHeaderCenter = () => {
  return (
    <PatientProfileInformationHeaderCenterContainer>
      <PatientProfileInformationHeaderCenterTop />
      <PatientProfileInformationHeaderCenterBottom />
    </PatientProfileInformationHeaderCenterContainer>
  );
};
