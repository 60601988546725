import { FormEvent, useState } from "react";
import { ManualReadingsTable } from "./manual-readings-table/manual-readings-table";
import { ManualReadingsFormHeader } from "./styled-manual-readings-modal";
import { Temporal } from "temporal-polyfill";
import {
  Button,
  ModalFooter,
} from "../../../../../../styles/classes/reusable-classes";
import { useRowsActions } from "./context/rows-actions-context";
import { DatePicker } from "../../../../../../components/date-picker/date-picker";
import { ModalForm } from "../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../components/modal/modal-header/modal-header";

type ManualReadingsModalProps = {
  handleHideModal: () => void;
};

export const ManualReadingsModal = (props: ManualReadingsModalProps) => {
  const { handleHideModal } = props;

  const [date, setDate] = useState(Temporal.Now.plainDateISO());
  const { startAdding } = useRowsActions();

  const handleIsAdding = () => {
    startAdding();
  };

  return (
    <ModalForm
      height={700}
      width={900}
      onSubmit={(event: FormEvent<HTMLFormElement>) => event.preventDefault()}
    >
      <ModalHeader title="Manual Readings" onModalClose={handleHideModal} />
      <ManualReadingsFormHeader>
        <DatePicker date={date} onChange={setDate} />
        <Button flex onClick={handleIsAdding}>
          <span className="material-symbols-outlined">add</span>
          <p>New Reading</p>
        </Button>
      </ManualReadingsFormHeader>
      <ManualReadingsTable date={date.toString()} />
      <ModalFooter>
        <Button outlined onClick={handleHideModal} type="button">
          Close
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
