import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { CreatePatientDTO } from "../../models/patient-dtos/create-patient-dto";

export function useAddPatient(): (
  createPatientDTO: CreatePatientDTO
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (createPatientDTO) => {
      await client.post<void>(PATIENTS_PATH, createPatientDTO);
    },
    [client]
  );
}
