import { useCallback } from "react";
import { PutPatientMealFeedbackDTO } from "../../models/patient-meal-dtos/put-patient-meal-feedback-dto";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { PatientDTO } from "../../models/patient-dtos/patient-dto";

export function usePutPatientMealFeedback(): (
  patientDTO: PatientDTO,
  patientMealId: number,
  putPatientMealFeedbackDTO: PutPatientMealFeedbackDTO
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientDTO, patientMealId, putPatientMealFeedbackDTO) => {
      await client.put<void>(
        `${PATIENTS_PATH}/${patientDTO.id}/meals/${patientMealId}/feedback`,
        putPatientMealFeedbackDTO
      );
    },
    [client]
  );
}
