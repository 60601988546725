import { LabTestTypeDTO } from "../../../../models/lab-test-types/lab-test-type-dto";
import { LabTestCategoryDTO } from "../../../../models/lab-test-categories/lab-test-category-dto";
import { LabTestTypeRefDTO } from "../../../../models/lab-test-type-refs/lab-test-type-ref-dto";

export function getLabTestCategoryFromLocation(
  locationPath: string,
  labTestCategoryDTOs: LabTestCategoryDTO[]
): LabTestCategoryDTO {
  return labTestCategoryDTOs.find((labTestCategory) =>
    locationPath.endsWith(labTestCategory.urlSegment)
  )!;
}

export function getCategoryLabTestTypeDTOsFromLocation(
  locationPath: string,
  labTestCategoryDTOs: LabTestCategoryDTO[],
  labTestTypeDTOs: LabTestTypeDTO[]
): LabTestTypeDTO[] {
  const labTestCategoryDTO = getLabTestCategoryFromLocation(
    locationPath,
    labTestCategoryDTOs
  );
  return labTestTypeDTOs
    .filter(
      (labTestType) => labTestType.labTestCategoryId === labTestCategoryDTO.id
    )
    .sort((a, b) => {
      const aCategory = getCategoryFromLabTestType(a, labTestCategoryDTOs);
      const bCategory = getCategoryFromLabTestType(b, labTestCategoryDTOs);

      if (aCategory.uiOrder < bCategory.uiOrder) {
        return -1;
      } else if (aCategory.uiOrder > bCategory.uiOrder) {
        return 1;
      } else {
        if (a.uiOrder < b.uiOrder) {
          return -1;
        } else if (a.uiOrder > b.uiOrder) {
          return 1;
        } else {
          return 0;
        }
      }
    });
}

export function getCategoryFromLabTestType(
  labTestTypeDTO: LabTestTypeDTO,
  labTestCategoryDTOs: LabTestCategoryDTO[]
): LabTestCategoryDTO {
  return labTestCategoryDTOs.find(
    (labTestCategory) => labTestCategory.id === labTestTypeDTO.labTestCategoryId
  )!;
}

type DisplayRefType = {
  parts: string;
  max: number;
  min: number;
  tag: string;
};

export function displayRef(ref: LabTestTypeRefDTO): DisplayRefType {
  const parts: string[] = [];

  if (ref.tag !== undefined) {
    parts.push(`${ref.tag}:`);
  }

  if (
    ref.min !== undefined &&
    !ref.isMinExclusive &&
    ref.max !== undefined &&
    !ref.isMaxExclusive
  ) {
    parts.push(`${ref.min} - ${ref.max}`);
  } else if (
    ref.min !== undefined &&
    ref.isMinExclusive &&
    ref.max === undefined
  ) {
    parts.push(`> ${ref.min}`);
  } else if (
    ref.max !== undefined &&
    ref.isMaxExclusive &&
    ref.min === undefined
  ) {
    parts.push(`< ${ref.max}`);
  } else if (
    ref.min !== undefined &&
    !ref.isMinExclusive &&
    ref.max === undefined
  ) {
    parts.push(`>= ${ref.min}`);
  } else if (
    ref.max !== undefined &&
    !ref.isMaxExclusive &&
    ref.min === undefined
  ) {
    parts.push(`<= ${ref.max}`);
  } else {
    if (ref.min !== undefined) {
      parts.push(ref.min.toString());
      if (ref.isMinExclusive) {
        parts.push("<");
      } else {
        parts.push("<=");
      }
    }

    parts.push("result");

    if (ref.max !== undefined) {
      if (ref.isMaxExclusive) {
        parts.push("<");
      } else {
        parts.push("<=");
      }
      parts.push(ref.max.toString());
    }
  }

  const min = ref.min!;
  const max = ref.max!;

  return {
    parts: parts.join(" "),
    min,
    max,
    tag: ref.tag!,
  };
}
