import styled from "styled-components/macro";
import { flexCenter } from "../../../../../../../../styles/classes/gloabl-classes";

export const ImmediateAttentionPatientsFiltersSidebarPriorityContainer = styled.div``;

export const ImmediateAttentionPatientsFiltersSidebarPriorityList = styled.ul`
  ${flexCenter}
  margin: 0px;
`;

export const ImmediateAttentionPatientsFiltersSidebarPriorityListItem = styled.li<{
  $color: string;
}>`
  ${flexCenter}
  gap: 0px 8px;

  p {
    font-size: 13px;
    font-weight: 400;
    margin: 0px 4px 0px 0px;
  }

  div {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: ${({ $color, theme }) =>
      $color === "High"
        ? theme.colors.danger
        : $color === "Medium"
        ? theme.colors.warning
        : theme.newColors.warningAlt};
  }
`;
