import { Modal } from "../../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../../components/modal/use-modal-helpers";
import { WeightProgressModalBody } from "./weight-progress-modal-body/weight-progress-modal-body";

type WeightProgressModalProps = {
  value: string | number;
  unit: string;
};

export const WeightProgressModal = (props: WeightProgressModalProps) => {
  const { unit, value } = props;
  const { closeModal, isModalOpen, openModal, modalBodyKey } =
    useModalHelpers();

  return (
    <>
      <span
        className="cursor-pointer"
        style={{ textDecoration: "underline" }}
        onClick={openModal}
      >
        <strong>{value}</strong>
        {unit}
      </span>
      <Modal showModal={isModalOpen} handleHideModal={closeModal}>
        <WeightProgressModalBody key={modalBodyKey} onClose={closeModal} />
      </Modal>
    </>
  );
};
