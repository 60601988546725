import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";
import { roundTo1DecimalPlace } from "../../../../../utils/math-utils";

type NutritionReportKTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportKTableCell = (
  props: NutritionReportKTableCellProps
) => {
  const { kind } = props;

  const { k } = useMemo(() => {
    const k =
      kind.kind === "Row"
        ? kind.data.totalKPotassiumGm
        : kind.data.patientMealMacrosSummary.kPotassiumGm;

    return { k };
  }, [kind.data, kind.kind]);

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      value={roundTo1DecimalPlace(k)}
    />
  );
};
