import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { PATIENTS_PATH } from "../../constants/paths";
import { PatientPrescriptionDTO } from "../../models/patient-prescriptions/patient-prescription-dto";
import { CreatePatientPrescriptionDTO } from "../../models/patient-prescriptions/create-patient-prescription-dto";

export function useAddPatientPrescription(): (
  patientId: number,
  createPatientPrescriptionDTO: CreatePatientPrescriptionDTO
) => Promise<PatientPrescriptionDTO> {
  const client = useClient();

  return useCallback(
    async (patientId, createPatientPrescriptionDTO) => {
      const response = await client.post<PatientPrescriptionDTO>(
        `${PATIENTS_PATH}/${patientId}/prescriptions`,
        createPatientPrescriptionDTO
      );

      return response.data;
    },
    [client]
  );
}
