import { Button } from "../../../../../styles/classes/reusable-classes";
import { usePlansGoals } from "../../context/goals-context";
import { PlanStepInfo } from "../../components/plan-step-info/plan-step-info";
import { GoalsListItem } from "../styles";
import { AssignedGoalsContainer, ContainerTitle } from "./styled-goals";

type AssignedGoalsListProps = {
  searchTerm: string;
};

export const AssignedGoalsList = (props: AssignedGoalsListProps) => {
  const { searchTerm } = props;

  const { assignedGoals, handleDeleteGoal } = usePlansGoals();

  return (
    <AssignedGoalsContainer>
      <ContainerTitle>Assigned Goals</ContainerTitle>
      {assignedGoals
        .filter((item) => {
          if (searchTerm === "") {
            return item;
          } else if (
            item.goal.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return item;
          } else {
            return 0;
          }
        })
        .map((goal, index) => (
          <GoalsListItem key={index}>
            <PlanStepInfo
              arabicFlex
              icon="/img/plans/nav/goals.svg"
              title={goal.goal}
              subTitle=""
            />
            <Button
              success
              flex
              type="button"
              onClick={() =>
                handleDeleteGoal({ goal: goal.goal, goalType: "Goal" })
              }
            >
              <span className="material-symbols-outlined">done</span>
              Added
            </Button>
          </GoalsListItem>
        ))}
    </AssignedGoalsContainer>
  );
};
