import { PatientMealMacrosSummaryDTO } from "../../../../../models/patient-meal-dtos/patient-meal-macros-summary-dto";
import {
  FoodLoggingSelectedPatientMealMacrosBar,
  FoodLoggingSelectedPatientMealMacrosCarbs,
  FoodLoggingSelectedPatientMealMacrosContainer,
  FoodLoggingSelectedPatientMealMacrosFats,
  FoodLoggingSelectedPatientMealMacrosLegend,
  FoodLoggingSelectedPatientMealMacrosLegendPoint,
  FoodLoggingSelectedPatientMealMacrosProtiens,
  FoodLoggingSelectedPatientMealMacrosTitleContainer,
} from "./styled-food-logging-selected-patient-meal-macros";

type FoodLoggingSelectedPatientMealMacrosProps = {
  macros: PatientMealMacrosSummaryDTO;
};

export const FoodLoggingSelectedPatientMealMacros = (
  props: FoodLoggingSelectedPatientMealMacrosProps
) => {
  const { macros } = props;

  return (
    <FoodLoggingSelectedPatientMealMacrosContainer>
      <FoodLoggingSelectedPatientMealMacrosTitleContainer>
        <p>Macronutrients</p>
        <span>{macros.kCals} Kcal</span>
      </FoodLoggingSelectedPatientMealMacrosTitleContainer>
      <FoodLoggingSelectedPatientMealMacrosBar>
        <FoodLoggingSelectedPatientMealMacrosCarbs
          width={`${macros.carbsPercentage}%`}
        />
        <FoodLoggingSelectedPatientMealMacrosFats
          width={`${macros.carbsPercentage + macros.fatPercentage}%`}
        />
        <FoodLoggingSelectedPatientMealMacrosProtiens
          width={`${
            macros.carbsPercentage +
            macros.fatPercentage +
            macros.proteinPercentage
          }%`}
        />
      </FoodLoggingSelectedPatientMealMacrosBar>
      <FoodLoggingSelectedPatientMealMacrosLegend>
        <FoodLoggingSelectedPatientMealMacrosLegendPoint>
          <div />
          <span>Carbs</span>
        </FoodLoggingSelectedPatientMealMacrosLegendPoint>
        <FoodLoggingSelectedPatientMealMacrosLegendPoint>
          <div />
          <span>Fat</span>
        </FoodLoggingSelectedPatientMealMacrosLegendPoint>
        <FoodLoggingSelectedPatientMealMacrosLegendPoint>
          <div />
          <span>Protien</span>
        </FoodLoggingSelectedPatientMealMacrosLegendPoint>
      </FoodLoggingSelectedPatientMealMacrosLegend>
    </FoodLoggingSelectedPatientMealMacrosContainer>
  );
};
