import { useLocation } from "react-router-dom";
import { getLabTestCategoryFromLocation } from "../../../utils/lab-test-results-utils";
import {
  LabResultTestHeaderContainer,
  LabResultTestHeaderLegend,
  LabResultTestHeaderLegendPoint,
  LabResultTestHeaderTitle,
} from "./styled-new-lab-result-test-header";
import { useLabResults } from "../../../context/loadable-lab-results-context";

export const LabResultTestHeader = () => {
  const location = useLocation();
  const { labTestCategoryDTOs } = useLabResults();

  return (
    <LabResultTestHeaderContainer>
      <LabResultTestHeaderTitle>
        {
          getLabTestCategoryFromLocation(location.pathname, labTestCategoryDTOs)
            .name
        }
      </LabResultTestHeaderTitle>
      <LabResultTestHeaderLegend>
        <LabResultTestHeaderLegendPoint>
          <div />
          <p>Normal</p>
        </LabResultTestHeaderLegendPoint>
        <LabResultTestHeaderLegendPoint>
          <div />
          <p>Above the Ref.</p>
        </LabResultTestHeaderLegendPoint>
        <LabResultTestHeaderLegendPoint>
          <div />
          <p>Below the Ref.</p>
        </LabResultTestHeaderLegendPoint>
      </LabResultTestHeaderLegend>
    </LabResultTestHeaderContainer>
  );
};
