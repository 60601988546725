import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";
import { PatientMealDTO } from "../../../models/patient-meal-dtos/patient-meal-dto";

export type PatientTodoMealsCountsUpdates = {
  todoMealsCountsUpdatesByPatientId: Map<number, number>;
  lastUpdatedPatientMealByPatientMealId: Map<number, PatientMealDTO>;
  setTodoMealsCountsUpdatesByPatientId: (
    value: React.SetStateAction<Map<number, number>>
  ) => void;
  setLastUpdatedPatientMealByPatientMealId: (
    value: React.SetStateAction<Map<number, PatientMealDTO>>
  ) => void;
};

const PatientTodoMealsCountsUpdatesContext = createContext<
  PatientTodoMealsCountsUpdates | undefined
>(undefined);

export function PatientTodoMealsCountsUpdatesProvider(
  props: PropsWithChildren<{}>
) {
  const { children } = props;

  const [
    todoMealsCountsUpdatesByPatientId,
    setTodoMealsCountsUpdatesByPatientId,
  ] = useState<Map<number, number>>(new Map());

  const [
    lastUpdatedPatientMealByPatientMealId,
    setLastUpdatedPatientMealByPatientMealId,
  ] = useState<Map<number, PatientMealDTO>>(new Map());

  const value = useMemo(
    () => ({
      todoMealsCountsUpdatesByPatientId,
      lastUpdatedPatientMealByPatientMealId:
        lastUpdatedPatientMealByPatientMealId,
      setTodoMealsCountsUpdatesByPatientId,
      setLastUpdatedPatientMealByPatientMealId,
    }),
    [
      todoMealsCountsUpdatesByPatientId,
      lastUpdatedPatientMealByPatientMealId,
      setTodoMealsCountsUpdatesByPatientId,
      setLastUpdatedPatientMealByPatientMealId,
    ]
  );

  return (
    <PatientTodoMealsCountsUpdatesContext.Provider value={value}>
      {children}
    </PatientTodoMealsCountsUpdatesContext.Provider>
  );
}

export function usePatientTodoMealsCountsUpdates() {
  const context = useContext(PatientTodoMealsCountsUpdatesContext);

  if (context === undefined) {
    throw new Error(
      "usePatientTodoMealsCountsUpdates must be used within a PatientTodoMealsCountsUpdatesProvider"
    );
  }

  return context;
}
