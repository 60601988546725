import styled from "styled-components/macro";
import {
  flexCenterCenter,
  flexCenterSpaceBetween,
} from "../../../../../../styles/classes/gloabl-classes";
import {
  NavbarHeight,
  SidebarWidth,
  SinglePatientSidebarWidth,
} from "../../../../../../styles/global-style";
import { NewPrescriptionHeaderHeight } from "../../constants/prescription-constants";

export const NewPrescriptionHeaderContainer = styled.div`
  ${flexCenterSpaceBetween}
  padding: ${({ theme }) => theme.padding.pad1All};
  padding-right: 40px;
  background: ${({ theme }) => theme.colors.containerColor};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  height: ${NewPrescriptionHeaderHeight};
  position: fixed;
  top: ${NavbarHeight};
  left: ${SidebarWidth};
  right: ${SinglePatientSidebarWidth};
  z-index: ${({ theme }) => theme.zIndex.z400};

  @media screen and (max-width: 1024px) {
    right: 0;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
    gap: 1rem 0rem;
    height: auto;
  }

  @media screen and (max-width: 500px) {
    left: 0;
  }
`;

export const NewPrescriptionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  span {
    color: ${({ theme }) => theme.colors.textColor};
  }

  p {
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    color: ${({ theme }) => theme.colors.titleColor};
    display: grid;
    margin: 0;

    span {
      color: ${({ theme }) => theme.colors.textColor};
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media screen and (max-width: 550px) {
    margin-right: auto;
  }
`;

export const NewPrescriptionHeaderButtonsContainer = styled.div`
  ${flexCenterCenter}
  gap: 0rem 1rem;

  @media screen and (max-width: 550px) {
    width: 100%;
    gap: 0rem 2rem;
  }
`;
