import { AddNewChannelModal } from "./add-new-channel-modal/add-new-channel-modal";
import { useModalHelpers } from "../../../../../../../../components/modal/use-modal-helpers";
import { CreateMessagingChannelHeaderCreateNewGroupButton } from "../styled-create-messaging-channel";
import { Modal } from "../../../../../../../../components/modal/modal";

type AddNewChannelProps = {
  closeSidebar: () => void;
};

export const AddNewChannel = (props: AddNewChannelProps) => {
  const { closeSidebar } = props;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <>
      <CreateMessagingChannelHeaderCreateNewGroupButton onClick={openModal}>
        <img src="/img/chat/group.svg" alt="create new group" />
        <span>New Group</span>
      </CreateMessagingChannelHeaderCreateNewGroupButton>
      <Modal showModal={isModalOpen} handleHideModal={closeModal}>
        <AddNewChannelModal
          key={modalBodyKey}
          onClose={closeModal}
          closeSidebar={closeSidebar}
        />
      </Modal>
    </>
  );
};
