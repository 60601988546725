import { PatientServingDTO } from "../../../models/patient-serving-dto";
import { Temporal } from "temporal-polyfill";
import {
  MealTooltipBody,
  MealTooltipBodyList,
  MealTooltipBodyListItem,
  MealTooltipBodyTitle,
  MealTooltipContainer,
  MealtootipHeader,
  MealtootipHeaderBottom,
  MealtootipHeaderTop,
} from "../../../styles/classes/tooltips";

export type MealTooltipProps = {
  // Array with at least one value. See: https://stackoverflow.com/a/67026991/6690391
  patientServingDTOs: PatientServingDTO[];
};

export function MealTooltip(props: MealTooltipProps) {
  const { patientServingDTOs } = props;

  // Converting to Temporal.PlainDateTime to take advantange of its string formatting options
  const plainDateTime = Temporal.PlainDateTime.from(
    `${patientServingDTOs[0].date}T${patientServingDTOs[0].time}`
  );

  const displayDateTime = plainDateTime.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const totalFats = patientServingDTOs.reduce(
    (totalFats: number, item: PatientServingDTO) => {
      if (item.nutrition.fatG === null || item.nutrition.fatG === undefined) {
        return totalFats;
      } else {
        totalFats += item.nutrition.fatG;
      }

      return Math.abs(totalFats);
    },
    0
  );

  const totalCarbs = patientServingDTOs.reduce(
    (totalCarbs: number, item: PatientServingDTO) => {
      if (
        item.nutrition.carbsG === null ||
        item.nutrition.carbsG === undefined
      ) {
        return totalCarbs;
      } else {
        totalCarbs += item.nutrition.carbsG;
      }

      return Math.abs(totalCarbs);
    },
    0
  );

  return (
    <MealTooltipContainer>
      <MealtootipHeader>
        <MealtootipHeaderTop>
          <p>Food Log</p>
          <strong>{displayDateTime}</strong>
        </MealtootipHeaderTop>
        <MealtootipHeaderBottom>
          <span>Carbs: {totalCarbs.toFixed(0)} g</span>
          <span>Fats: {totalFats.toFixed(0)} g</span>
        </MealtootipHeaderBottom>
      </MealtootipHeader>
      <MealTooltipBody>
        <MealTooltipBodyTitle>Meal ingredients</MealTooltipBodyTitle>
        <MealTooltipBodyList>
          {patientServingDTOs.map((meal) => (
            <MealTooltipBodyListItem key={meal.id}>
              <span>{meal.foodName}</span>
              <strong>{meal.amount}</strong>
            </MealTooltipBodyListItem>
          ))}
        </MealTooltipBodyList>
      </MealTooltipBody>
    </MealTooltipContainer>
  );
}
