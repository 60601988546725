import { useFieldArray, useForm } from "react-hook-form";
import { Temporal } from "temporal-polyfill";
import { useBulkUpdatePatientWeights } from "../../../../../../../../hooks/patient-weights-hooks/use-bulk-update-patient-weights";
import { ModalForm } from "../../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../../components/modal/modal-header/modal-header";
import {
  TD,
  TDContainer,
  TDInput,
  TH,
} from "../../../../../../../../components/table/styled-table";
import { Table } from "../../../../../../../../components/table/table";
import {
  Button,
  ModalFooter,
} from "../../../../../../../../styles/classes/reusable-classes";
import { useParams } from "react-router-dom";
import { useToastService } from "../../../../../../../../context/toast-service-context";
import {
  WeightModalBodyButtonContainer,
  WeightModalBodyFormContainer,
} from "./styled-weight-modal-body";
import {
  useReloadSinglePatientSidebar,
  useSinglePatientSidebar,
} from "../../../../../context/loadable-single-patient-sidebar-context";

type WeightModalBodyProps = {
  onClose: () => void;
};

type Inputs = {
  weights: {
    date: string;
    weightKg: number;
  }[];
};

export const WeightModalBody = (props: WeightModalBodyProps) => {
  const { onClose } = props;
  const { id } = useParams();
  const { showToast } = useToastService();
  const reloadSinglePatientSidebar = useReloadSinglePatientSidebar();
  const today = Temporal.Now.plainDateISO();
  const { patientWeightDTOs } = useSinglePatientSidebar();
  const bulkUpdatePatientWeights = useBulkUpdatePatientWeights();

  const { control, handleSubmit, register } = useForm<Inputs>({
    defaultValues: {
      weights: patientWeightDTOs,
    },
  });

  const { append, fields, remove } = useFieldArray({
    name: "weights",
    control,
  } as never);

  const onSubmit = handleSubmit(async (inputs) => {
    try {
      await bulkUpdatePatientWeights(parseInt(id!), inputs);
      showToast("Success", "Weight Readings updated successfully");
      onClose();
      reloadSinglePatientSidebar();
    } catch (error) {
      showToast("Error", "Failed to update Weight readings :(");
    }
  });

  const headings = ["Date", "Body Weight", "Remove"].map((heading) => (
    <TH key={heading} modalTableHead actions={heading === "Remove"}>
      <div>{heading}</div>
    </TH>
  ));

  const rowsData = fields.map((field, index) => (
    <tr key={field.id}>
      <TD>
        <TDContainer>
          <TDInput
            type="date"
            defaultValue={today.toString()}
            {...register(`weights.${index}.date`)}
          />
        </TDContainer>
      </TD>
      <TD>
        <TDContainer>
          <TDInput
            type="number"
            step={0.01}
            {...register(`weights.${index}.weightKg`)}
          />
        </TDContainer>
      </TD>
      <TD>
        <TDContainer className="cursor-pointer">
          <span
            title="Delete"
            className="material-symbols-outlined"
            onClick={() => remove(index)}
          >
            delete
          </span>
        </TDContainer>
      </TD>
    </tr>
  ));

  return (
    <ModalForm width={700} height={600} onSubmit={onSubmit}>
      <ModalHeader title="Body Weight" onModalClose={onClose} />
      <WeightModalBodyFormContainer>
        <WeightModalBodyButtonContainer>
          <Button
            type="button"
            flex
            onClick={() =>
              append({
                date: today.toString(),
                weight: 0,
              })
            }
          >
            <span className="material-symbols-outlined">
              add_circle_outline
            </span>
            Add a reading
          </Button>
        </WeightModalBodyButtonContainer>
        <Table tableHeadings={headings} tableRows={rowsData} modalTable />
      </WeightModalBodyFormContainer>
      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Close
        </Button>
        <Button type="submit">Confirm</Button>
      </ModalFooter>
    </ModalForm>
  );
};
