import { useMemo } from "react";
import { PateintProfileColumnHeader } from "../../components/pateint-profile-column-header/pateint-profile-column-header";
import {
  PaitnetProfileChartContainer,
  PatientProfileContainer,
  PatientProfileContainerBody,
} from "../../styles/patient-profile-styles/patient-profile-classes";
import { calculateBMI } from "../../../single-patient-sidebar/patient-sidebar-tabs/personal-info-tab/body-measurments/body-measurments-utils";
import {
  AxisModel,
  Category,
  ChartComponent,
  DataLabel,
  Inject,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { displayBMI_Indicators } from "../../utils/patient-profile-utils";
import { PatientProfileBMIIndicator } from "./styled-patient-profile-bmi";
import { Temporal } from "temporal-polyfill";
import { usePatientProfile } from "../../context/loadable-patient-profile-context";

export const PatientProfileBmi = () => {
  const { patientWeightDTOs, patientDTO } = usePatientProfile();

  const height = patientDTO.heightCm !== undefined ? patientDTO.heightCm : 0;

  const dataSource = useMemo(
    () =>
      patientWeightDTOs
        .sort((a, b) => a.date.localeCompare(b.date))
        .map((item) => ({
          date: Temporal.PlainDate.from(item.date).toLocaleString("en-UK", {
            day: "2-digit",
            month: "2-digit",
          }),
          bmi: height !== 0 ? Number(calculateBMI(height, item.weightKg)) : 0,
        })),
    [patientWeightDTOs, height]
  );

  const latestBMIReading =
    dataSource !== undefined && dataSource.length > 0
      ? dataSource[dataSource.length - 1].bmi
      : 0;

  const primaryxAxis: AxisModel = {
    valueType: "Category",
    majorGridLines: { width: 0 },
    edgeLabelPlacement: "Shift",
    title: "Date",
  };

  const primaryyAxis: AxisModel = {
    minimum: 15,
    title: "BMI",
  };

  const marker = {
    visible: true,
    width: 10,
    height: 10,
    isFilled: true,
    fill: "#1097cf",
    border: { width: 0, color: "transparent" },
    dataLabel: { visible: true },
  };

  return (
    <PatientProfileContainer>
      <PateintProfileColumnHeader
        title="BMI"
        img="/img/patient-profile/bmi.svg"
        button={
          <PatientProfileBMIIndicator>
            {height === 0
              ? "Height is Missing"
              : patientWeightDTOs.length === 0
              ? ""
              : displayBMI_Indicators(latestBMIReading)}
          </PatientProfileBMIIndicator>
        }
      />
      {dataSource.length > 0 && height !== 0 ? (
        <PatientProfileContainerBody>
          <PaitnetProfileChartContainer>
            <ChartComponent
              primaryXAxis={primaryxAxis}
              primaryYAxis={primaryyAxis}
              title="BMI Progress"
              height="100%"
            >
              <Inject services={[Category, LineSeries, StripLine, DataLabel]} />
              <SeriesCollectionDirective>
                <SeriesDirective
                  dataSource={dataSource}
                  xName="date"
                  yName="bmi"
                  opacity={1}
                  zOrder={110}
                  type="Line"
                  width={3}
                  fill="#00086b"
                  marker={marker}
                ></SeriesDirective>
              </SeriesCollectionDirective>
            </ChartComponent>
          </PaitnetProfileChartContainer>
        </PatientProfileContainerBody>
      ) : (
        <EmptyState
          button={<></>}
          hasHeader={false}
          hasSearch={false}
          title=""
          text="There are no data in BMI"
        />
      )}
    </PatientProfileContainer>
  );
};
