import {
  FoodLoggingSelectedPatientMealMacrosBar,
  FoodLoggingSelectedPatientMealMacrosCarbs,
  FoodLoggingSelectedPatientMealMacrosContainer,
  FoodLoggingSelectedPatientMealMacrosFats,
  FoodLoggingSelectedPatientMealMacrosLegend,
  FoodLoggingSelectedPatientMealMacrosLegendPoint,
  FoodLoggingSelectedPatientMealMacrosProtiens,
  FoodLoggingSelectedPatientMealMacrosTitleContainer,
} from "../../../../food-logging/food-logging-selected-patient-meal/legacy-food-logging-selected-patient-meal/food-logging-selected-patient-meal-macros/styled-food-logging-selected-patient-meal-macros";
import { useNutritionGolasLastFoodGroupSelected } from "../../nutrition-goals/context/nutrition-goals-last-food-group-selected-context";
import { calculateNutritionGoalsMacros } from "../../nutrition-goals/utils/nutrition-goals-utils";
import { DietitianReportMacrosBarContainer } from "./styled-dietitian-report-macros-bar";

export const DietitianReportMacrosBar = () => {
  const { lastFoodGroupSelected } = useNutritionGolasLastFoodGroupSelected();

  const {
    carbsKCalsGrams,
    carbsPercentage,
    fatKCalsGrams,
    fatPercentage,
    kCals,
    proteinKCalsGrams,
    proteinPercentage,
  } = calculateNutritionGoalsMacros(lastFoodGroupSelected);

  return (
    <DietitianReportMacrosBarContainer>
      <FoodLoggingSelectedPatientMealMacrosContainer>
        <FoodLoggingSelectedPatientMealMacrosTitleContainer>
          <p>Macronutrients</p>
          <span>{kCals} Kcal</span>
        </FoodLoggingSelectedPatientMealMacrosTitleContainer>
        <FoodLoggingSelectedPatientMealMacrosBar>
          <FoodLoggingSelectedPatientMealMacrosCarbs
            width={`${carbsPercentage}%`}
          />
          <FoodLoggingSelectedPatientMealMacrosFats
            width={`${carbsPercentage + fatPercentage}%`}
          />
          <FoodLoggingSelectedPatientMealMacrosProtiens
            width={`${carbsPercentage + fatPercentage + proteinPercentage}%`}
          />
        </FoodLoggingSelectedPatientMealMacrosBar>
        <FoodLoggingSelectedPatientMealMacrosLegend>
          <FoodLoggingSelectedPatientMealMacrosLegendPoint>
            <div />
            <span>
              Carbs: {`${carbsKCalsGrams}gm`} ({carbsPercentage}%)
            </span>
          </FoodLoggingSelectedPatientMealMacrosLegendPoint>
          <FoodLoggingSelectedPatientMealMacrosLegendPoint>
            <div />
            <span>
              Fat: {`${fatKCalsGrams}gm`} ({fatPercentage}%)
            </span>
          </FoodLoggingSelectedPatientMealMacrosLegendPoint>
          <FoodLoggingSelectedPatientMealMacrosLegendPoint>
            <div />
            <span>
              Protien: {`${proteinKCalsGrams}gm`} ({proteinPercentage}%)
            </span>
          </FoodLoggingSelectedPatientMealMacrosLegendPoint>
        </FoodLoggingSelectedPatientMealMacrosLegend>
      </FoodLoggingSelectedPatientMealMacrosContainer>
    </DietitianReportMacrosBarContainer>
  );
};
