import {
  ChangeEvent,
  DragEvent,
  MutableRefObject,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

export type VideoUploadState =
  | { state: "Idle" }
  | { state: "Upload"; previewUrl?: string }
  | { state: "Drag"; dragActive: boolean }
  | { state: "Uploaded"; previewUrl: string };

export type VideoUploadHelpers = {
  handleRemoveVideo: () => void;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDrag: (event: DragEvent<HTMLFormElement | HTMLDivElement>) => void;
  handleDrop: (event: DragEvent<HTMLDivElement>) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  videoFile: Blob | null;
  setVideoFile: (VideoFile: Blob | null) => void;
  videoUploadState: VideoUploadState;
  setVideoUploadState: (VideoUploadState: VideoUploadState) => void;
  handleUploadedVideoState: (VideoFile: Blob) => void;
  videoFileInputRef: MutableRefObject<HTMLInputElement | null>;
};

const VideoUploadHelpersContext = createContext<VideoUploadHelpers | undefined>(
  undefined
);

type VideoUploadHelpersProviderProps = PropsWithChildren<{}>;

export function VideoUploadHelpersProvider(
  props: VideoUploadHelpersProviderProps
) {
  const { children } = props;

  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState<Blob | null>(null);
  const [videoUploadState, setVideoUploadState] = useState<VideoUploadState>({
    state: "Idle",
  });

  const videoFileInputRef = useRef<HTMLInputElement | null>(null);

  const handleRemoveVideo = useCallback(() => {
    if (
      videoFile !== null &&
      (videoUploadState.state === "Upload" ||
        videoUploadState.state === "Uploaded")
    ) {
      setVideoFile(null);

      setVideoUploadState({
        state: "Upload",
        previewUrl: undefined,
      });
    }
  }, [videoFile, videoUploadState.state, setVideoFile, setVideoUploadState]);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (files && files.length > 0) {
        const selectedFiles = Array.from(files);
        const file = selectedFiles[0];

        if (!file.type.startsWith("video/") || file.type === "video/gif") {
          alert("Please upload a valid Video file (no GIFs allowed).");
          return;
        }

        setVideoFile(file);

        const readerPromises = selectedFiles.map((file) => {
          return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result as string);
            };
            reader.readAsDataURL(file);
          });
        });

        Promise.all(readerPromises).then((previewUrls) => {
          setVideoUploadState({
            state: "Upload",
            previewUrl: previewUrls[0],
          });
        });
      }
    },
    []
  );

  const handleDrag = useCallback(
    (event: DragEvent<HTMLFormElement | HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (event.type === "dragenter" || event.type === "dragover") {
        setVideoUploadState({
          state: "Drag",
          dragActive: true,
        });
      } else if (event.type === "dragleave") {
        setVideoUploadState({
          state: "Drag",
          dragActive: false,
        });
      }
    },
    []
  );

  const handleDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;

    if (files && files.length > 0) {
      const selectedFiles = Array.from(files);
      const file = selectedFiles[0];

      if (!file.type.startsWith("video/") || file.type === "video/gif") {
        alert("Please upload a valid Video file (no GIFs allowed).");
        setVideoUploadState({
          state: "Drag",
          dragActive: false,
        });
        return;
      }

      setVideoFile(selectedFiles[0]);

      const readerPromises = selectedFiles.map((file) => {
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(readerPromises).then((previewUrls) => {
        setVideoUploadState({
          state: "Upload",
          previewUrl: previewUrls[0],
        });
      });
    }
  }, []);

  const handleUploadedVideoState = useCallback((VideoFile: Blob) => {
    const readerPromise = new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(VideoFile);
    });

    Promise.all([readerPromise]).then((previewUrls) => {
      setVideoUploadState({
        state: "Uploaded",
        previewUrl: previewUrls[0],
      });
    });
  }, []);

  const value = useMemo(
    () => ({
      handleRemoveVideo,
      handleFileChange,
      handleDrag,
      handleDrop,
      loading,
      setLoading,
      videoFile,
      setVideoFile,
      videoUploadState,
      setVideoUploadState,
      handleUploadedVideoState,
      videoFileInputRef,
    }),
    [
      handleRemoveVideo,
      handleFileChange,
      handleDrag,
      handleDrop,
      loading,
      setLoading,
      videoFile,
      setVideoFile,
      videoUploadState,
      setVideoUploadState,
      handleUploadedVideoState,
      videoFileInputRef,
    ]
  );

  return (
    <VideoUploadHelpersContext.Provider value={value}>
      {children}
    </VideoUploadHelpersContext.Provider>
  );
}

export function useVideoUploadHelpers(): VideoUploadHelpers {
  const context = useContext(VideoUploadHelpersContext);

  if (context === undefined) {
    throw new Error(
      "useVideoUploadHelpers must be used within a VideoUploadHelpersProvider"
    );
  }

  return context;
}
