import { PatientMealDTO } from "../../../../models/patient-meal-dtos/patient-meal-dto";
import { CarbCounterFoodLoggingSelectedPatientMealDetails } from "./carb-counter-food-logging-selected-patient-meal-header/carb-counter-food-logging-selected-patient-meal-details/carb-counter-food-logging-selected-patient-meal-details";
import { CarbCounterFoodLoggingSelectedPatientMealHeader } from "./carb-counter-food-logging-selected-patient-meal-header/carb-counter-food-logging-selected-patient-meal-header";
import { CarbCounterFoodLoggingSelectedPatientMealAnalysisContainer } from "./carb-counter-food-logging-selected-patient-meal-response/styled-carb-counter-food-logging-selected-patient-meal-response";

type AnalyzingCarbCounterMealProps = {
  patientMealDTO: PatientMealDTO;
};

export const AnalyzingCarbCounterMeal = (
  props: AnalyzingCarbCounterMealProps
) => {
  const { patientMealDTO } = props;

  return (
    <>
      <CarbCounterFoodLoggingSelectedPatientMealHeader
        patientMealDTO={patientMealDTO}
        carbCounterFoodLoggingSelectedPatientMealDetails={
          <CarbCounterFoodLoggingSelectedPatientMealDetails
            carbCounterMealDetailsState={{ kind: "Loading" }}
          />
        }
      />
      <CarbCounterFoodLoggingSelectedPatientMealAnalysisContainer>
        <img src="/img/analyzing-meal.gif" alt="Analyzing Meal.gif" />
      </CarbCounterFoodLoggingSelectedPatientMealAnalysisContainer>
    </>
  );
};
