import styled from "styled-components/macro";
import {
  flexCenter,
  mediumFont,
  regularMediumFont,
} from "../../styles/classes/gloabl-classes";
import { lightShadowSimple, SidebarWidth } from "../../styles/global-style";

export const TablePaginationContainerHeight = `70px`;

export const TableContainer = styled.div<{ modalTable?: boolean }>`
  padding: ${({ theme, modalTable }) =>
    modalTable ? "0rem" : theme.padding.pad05All};
  background: ${({ theme }) => theme.colors.containerColor};
  height: ${({ modalTable }) => (modalTable ? "310px" : `unset`)};
  overflow: ${({ modalTable }) => (modalTable ? "auto" : "unset")};

  ::-webkit-scrollbar {
    height: 7px;
  }

  @media screen and (max-width: 1540px) {
    width: 100%;
    overflow-x: auto;
  }
`;

export const TableTable = styled.table`
  width: 100%;
  margin-top: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-collapse: collapse;
  border-spacing: 0;
`;

export const TBody = styled.tbody`
  tr {
    :nth-child(odd) {
      background-color: #f9fbfc;
    }
  }
`;

export const TablePaginationContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 0px 16px;
  position: fixed;
  bottom: 0px;
  left: ${SidebarWidth};
  right: 0px;
  ${lightShadowSimple}
  height: ${TablePaginationContainerHeight};
`;

export const TDContainer = styled.div<{ canContainError?: boolean }>`
  ${flexCenter}
  margin: 0.3rem 0;
  padding: ${({ theme }) => theme.padding.px_05};
  position: ${({ canContainError }) =>
    canContainError ? "relative" : "static"};

  span {
    bottom: -20px;
    left: 15px;
  }
`;

export const TDInput = styled.input`
  ${regularMediumFont}
  padding: 0.3rem 0.5rem;
  outline: 0;
  border: 2px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 0.5rem;
`;

export const TDText = styled.p`
  ${mediumFont}
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0;
`;

export const TDLink = styled.h4`
  ${mediumFont}
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  a {
    color: ${({ theme }) => theme.colors.titleColor};
    text-decoration: none;
  }
`;

export const TH = styled.th<{
  modalTableHead?: boolean;
  actions?: boolean;
  width?: number;
}>`
  div {
    width: ${({ actions, width }) =>
      actions ? "100px" : width ? `${width}px` : "unset"};
    ${flexCenter}
    font-size: ${({ theme }) => theme.typography.fontSize.mdFontSize};
    margin-bottom: 1rem;
    padding: ${({ modalTableHead, theme }) =>
      modalTableHead ? theme.padding.pad05_1 : "0rem"};
    border-top: ${({ theme, modalTableHead }) =>
      modalTableHead ? `1px solid ${theme.colors.borderColor}` : "0px"};
    border-bottom: ${({ theme, modalTableHead }) =>
      modalTableHead ? `1px solid ${theme.colors.borderColor}` : "0px"};

    span {
      font-size: ${({ theme }) => theme.typography.fontSize.mdFontSize};
    }

    @media screen and (max-width: 1540px) {
      width: ${({ actions, width }) =>
        actions ? "70px" : width ? `${width}px` : "150px"};
    }
  }
`;

export const TD = styled.td<{ width?: number }>`
  width: ${({ width }) => `${width}px`};
`;
