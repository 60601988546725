import { FoodGroupDTO } from "../../../../../models/food-groups/food-group-dto";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FoodLoggingSelectedPatientMealInputs } from "../../food-logging-selected-patient-meal";
import { useMemo } from "react";
import {
  FoodLoggingSelectedPatientMealFoodGroupsContainer,
  FoodLoggingSelectedPatientMealFoodGroupsInputContainer,
  FoodLoggingSelectedPatientMealFoodGroupsInputLabel,
  FoodLoggingSelectedPatientMealFoodGroupsInputsContainer,
} from "./styled-food-logging-selected-patient-meal-food-groups";
import { FoodLoggingSelectedPatientMealContainerTitle } from "../../styled-food-logging-selected-patient-meal";
import { useFoodLoggingData } from "../../../context/food-logging-data-context";

export function FoodLoggingSelectedPatientMealFoodGroups() {
  const { foodGroupDTOs } = useFoodLoggingData();

  const { watch, control } =
    useFormContext<FoodLoggingSelectedPatientMealInputs>();

  const groups = watch("foodGroups");

  const oilsAndFatsLegacyDTO = groups.find((item) => item.foodGroupId === 12);

  const showOilsAndFatsLegacyDTO =
    oilsAndFatsLegacyDTO !== undefined &&
    oilsAndFatsLegacyDTO.servingsCount > 0;

  const {
    fields: updatePatientMealFoodGroupDTOs,
    append: appendFoodGroup,
    update: updateFoodGroup,
  } = useFieldArray({
    name: "foodGroups",
    control,
  });

  const updatePatientMealFoodGroupIndexById: Map<number, number> =
    useMemo(() => {
      return new Map(
        updatePatientMealFoodGroupDTOs.map(
          (updatePatientMealFoodGroupDTO, index) => [
            updatePatientMealFoodGroupDTO.foodGroupId,
            index,
          ]
        )
      );
    }, [updatePatientMealFoodGroupDTOs]);

  function handleFoodGroupInputChange(
    foodGroupDTO: FoodGroupDTO,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const updatePatientMealFoodGroupIndex =
      updatePatientMealFoodGroupIndexById.get(foodGroupDTO.id);

    if (updatePatientMealFoodGroupIndex === undefined) {
      appendFoodGroup({
        foodGroupId: foodGroupDTO.id,
        servingsCount: Number(event.target.value),
      });
    } else {
      updateFoodGroup(updatePatientMealFoodGroupIndex, {
        foodGroupId: foodGroupDTO.id,
        servingsCount: Number(event.target.value),
      });
    }
  }

  const foodGroupsArray = showOilsAndFatsLegacyDTO
    ? foodGroupDTOs
    : foodGroupDTOs.filter(
        (foodGroupDTO) => foodGroupDTO.name !== "Oils and Fats (Legacy)"
      );

  return (
    <FoodLoggingSelectedPatientMealFoodGroupsContainer>
      <FoodLoggingSelectedPatientMealContainerTitle>
        Food groups
      </FoodLoggingSelectedPatientMealContainerTitle>
      <FoodLoggingSelectedPatientMealFoodGroupsInputsContainer>
        {foodGroupsArray.map((foodGroupDTO) => {
          const updatePatientMealFoodGroupIndex =
            updatePatientMealFoodGroupIndexById.get(foodGroupDTO.id);

          const updatePatientMealFoodGroupDTO =
            updatePatientMealFoodGroupIndex === undefined
              ? undefined
              : updatePatientMealFoodGroupDTOs[updatePatientMealFoodGroupIndex];

          return (
            <FoodLoggingSelectedPatientMealFoodGroupsInputContainer
              key={foodGroupDTO.id}
            >
              <FoodLoggingSelectedPatientMealFoodGroupsInputLabel>
                {foodGroupDTO.name}
              </FoodLoggingSelectedPatientMealFoodGroupsInputLabel>
              <input
                type="number"
                min={0}
                placeholder="0"
                value={updatePatientMealFoodGroupDTO?.servingsCount ?? ""}
                onChange={(event) =>
                  handleFoodGroupInputChange(foodGroupDTO, event)
                }
              />
            </FoodLoggingSelectedPatientMealFoodGroupsInputContainer>
          );
        })}
      </FoodLoggingSelectedPatientMealFoodGroupsInputsContainer>
    </FoodLoggingSelectedPatientMealFoodGroupsContainer>
  );
}
