import { Fragment } from "react";
import { NutritionReportTableRow } from "../nutrition-report-table-row/nutrition-report-table-row";
import { NutritionReportTotalOrAverageTableRow } from "../nutrition-report-total-or-average-table-row/nutrition-report-total-or-average-table-row";
import { createMealsWithFrequencies } from "../utils/create-meals-with-frequencies";
import { createPatientNutritionReportDailySummaryDayDTOWithIds } from "../utils/create-patient-nutrition-report-daily-summary-day-dto-with-ids";
import { usePatientNutritionReportDailySummary } from "../../../context/nutrition-report-context/patient-nutrition-report-daily-summary-context";

export const NutritionReportTableRows = () => {
  const { patientNutritionReportDailySummary } =
    usePatientNutritionReportDailySummary();

  return (
    <tbody>
      {createPatientNutritionReportDailySummaryDayDTOWithIds(
        patientNutritionReportDailySummary
      ).map((row, index) => (
        <Fragment key={index}>
          <NutritionReportTableRow
            kind={{
              kind: "Row",
              data: row,
            }}
            index={index}
          />
          {createMealsWithFrequencies(row).map((mealRow, mealIndex) => (
            <NutritionReportTableRow
              key={mealIndex}
              index={index}
              kind={{
                kind: "Meal",
                data: mealRow,
              }}
            />
          ))}
        </Fragment>
      ))}
      <NutritionReportTotalOrAverageTableRow type="Total" />
      <NutritionReportTotalOrAverageTableRow type="Average" />
    </tbody>
  );
};
