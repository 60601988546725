import { PatientMealDTO } from "../../../../../../../../models/patient-meal-dtos/patient-meal-dto";

export const staticFoodGroups = [
  {
    id: 1,
    title: "Starch",
    value: 0,
  },
  {
    id: 2,
    title: "Legumes",
    value: 0,
  },
  {
    id: 3,
    title: "Fruits",
    value: 0,
  },
  {
    id: 4,
    title: "Vegetables",
    value: 0,
  },
  {
    id: 5,
    title: "Caffeinated Beverages",
    value: 0,
  },
  {
    id: 6,
    title: "Meats",
    value: 0,
  },
  {
    id: 7,
    title: "Dairy",
    value: 0,
  },
  {
    id: 8,
    title: "Oils & Fats",
    value: 0,
  },
];

type NutritionSummaryServing = {
  id: number;
  title: string;
  value: number;
  tooltip?: {
    tooltipTitle: string;
    items: {
      id: number;
      title: string;
      value: number;
    }[];
  };
};

export function foodGroups(patientMealDTOs: PatientMealDTO[]) {
  const nutritionSummaryServings: NutritionSummaryServing[] = [];

  for (const patientMealDTO of patientMealDTOs) {
    const carbCounterV1MealSummary = patientMealDTO.carbCounterV1MealSummary;

    if (carbCounterV1MealSummary) {
      const foodGroups = carbCounterV1MealSummary.foodGroups;

      const meatsFoodGroups = foodGroups.filter((item) =>
        item.foodGroup.name.includes("Meats")
      );

      const dairyFoodGroups = foodGroups.filter((item) =>
        item.foodGroup.name.includes("Dairy")
      );

      const oilsAndFatsFoodGroups = foodGroups.filter((item) =>
        item.foodGroup.name.includes("Oils and Fats")
      );

      for (const foodGroup of foodGroups) {
        const foodGroupTitle = foodGroup.foodGroup.name;
        const foodGroupValue = foodGroup.servingsCount;

        if (foodGroupTitle === "Caffeinated Beverages") {
          const existingItem = nutritionSummaryServings.find(
            (item) => item.title === "Caffeinated Beverages"
          );

          if (existingItem) {
            existingItem.value += foodGroupValue;
          } else {
            nutritionSummaryServings.push({
              id: foodGroup.foodGroup.id,
              title: foodGroupTitle,
              value: foodGroupValue,
            });
          }
        }

        if (foodGroupTitle === "Fruits") {
          const existingItem = nutritionSummaryServings.find(
            (item) => item.title === "Fruits"
          );

          if (existingItem) {
            existingItem.value += foodGroupValue;
          } else {
            nutritionSummaryServings.push({
              id: foodGroup.foodGroup.id,
              title: foodGroupTitle,
              value: foodGroupValue,
            });
          }
        }

        if (foodGroupTitle === "Legumes") {
          const existingItem = nutritionSummaryServings.find(
            (item) => item.title === "Legumes"
          );

          if (existingItem) {
            existingItem.value += foodGroupValue;
          } else {
            nutritionSummaryServings.push({
              id: foodGroup.foodGroup.id,
              title: foodGroupTitle,
              value: foodGroupValue,
            });
          }
        }

        if (foodGroupTitle === "Starch") {
          const existingItem = nutritionSummaryServings.find(
            (item) => item.title === "Starch"
          );

          if (existingItem) {
            existingItem.value += foodGroupValue;
          } else {
            nutritionSummaryServings.push({
              id: foodGroup.foodGroup.id,
              title: foodGroupTitle,
              value: foodGroupValue,
            });
          }
        }

        if (foodGroupTitle === "Vegetables") {
          const existingItem = nutritionSummaryServings.find(
            (item) => item.title === "Vegetables"
          );

          if (existingItem) {
            existingItem.value += foodGroupValue;
          } else {
            nutritionSummaryServings.push({
              id: foodGroup.foodGroup.id,
              title: foodGroupTitle,
              value: foodGroupValue,
            });
          }
        }
      }

      for (const meatFoodGroup of meatsFoodGroups) {
        const existingItem = nutritionSummaryServings.find(
          (item) => item.title === "Meats"
        );
        const foodGroupTitle = meatFoodGroup.foodGroup.name;
        const foodGroupValue = meatFoodGroup.servingsCount;

        if (existingItem) {
          existingItem.value += foodGroupValue;
          existingItem.tooltip!.tooltipTitle! = "Meats";

          if (
            existingItem.tooltip?.items.some(
              (item) =>
                item.title === foodGroupTitle.slice(8, foodGroupTitle.length)
            )
          ) {
            const existingTooltipItem = existingItem.tooltip?.items.find(
              (item) =>
                item.title === foodGroupTitle.slice(8, foodGroupTitle.length)
            );
            if (existingTooltipItem) {
              existingTooltipItem.value += foodGroupValue;
            }
          } else {
            existingItem.tooltip?.items.push({
              id: meatFoodGroup.foodGroup.id,
              title: foodGroupTitle.slice(8, foodGroupTitle.length),
              value: foodGroupValue,
            });
          }
        } else {
          nutritionSummaryServings.push({
            id: meatFoodGroup.foodGroup.id,
            title: "Meats",
            value: foodGroupValue,
            tooltip: {
              tooltipTitle: "Protein Sources Frequency",
              items: [
                {
                  id: meatFoodGroup.foodGroup.id,
                  title: foodGroupTitle.slice(8, foodGroupTitle.length),
                  value: foodGroupValue,
                },
              ],
            },
          });
        }
      }

      for (const dairyFoodGroup of dairyFoodGroups) {
        const existingItem = nutritionSummaryServings.find(
          (item) => item.title === "Dairy"
        );
        const foodGroupTitle = dairyFoodGroup.foodGroup.name;
        const foodGroupValue = dairyFoodGroup.servingsCount;

        if (existingItem) {
          existingItem.value += foodGroupValue;
          existingItem.tooltip!.tooltipTitle! = "Dairy";

          if (
            existingItem.tooltip?.items.some(
              (item) =>
                item.title === foodGroupTitle.slice(7, foodGroupTitle.length)
            )
          ) {
            const existingTooltipItem = existingItem.tooltip?.items.find(
              (item) =>
                item.title === foodGroupTitle.slice(7, foodGroupTitle.length)
            );
            if (existingTooltipItem) {
              existingTooltipItem.value += foodGroupValue;
            }
          } else {
            existingItem.tooltip?.items.push({
              id: dairyFoodGroup.foodGroup.id,
              title: foodGroupTitle.slice(7, foodGroupTitle.length),
              value: foodGroupValue,
            });
          }
        } else {
          nutritionSummaryServings.push({
            id: dairyFoodGroup.foodGroup.id,
            title: "Dairy",
            value: foodGroupValue,
            tooltip: {
              tooltipTitle: "Dairy",
              items: [
                {
                  id: dairyFoodGroup.foodGroup.id,
                  title: foodGroupTitle.slice(7, foodGroupTitle.length),
                  value: foodGroupValue,
                },
              ],
            },
          });
        }
      }

      for (const oilsAndFatsFoodGroup of oilsAndFatsFoodGroups) {
        const existingItem = nutritionSummaryServings.find(
          (item) => item.title === "Oils & Fats"
        );
        const foodGroupTitle = oilsAndFatsFoodGroup.foodGroup.name;
        const foodGroupValue = oilsAndFatsFoodGroup.servingsCount;

        if (existingItem) {
          existingItem.value += foodGroupValue;
          existingItem.tooltip!.tooltipTitle! = "Oils & Fats";

          if (
            existingItem.tooltip?.items.some(
              (item) => item.title === foodGroupTitle
            )
          ) {
            const existingTooltipItem = existingItem.tooltip?.items.find(
              (item) => item.title === foodGroupTitle
            );
            if (existingTooltipItem) {
              existingTooltipItem.value += foodGroupValue;
            }
          } else {
            existingItem.tooltip?.items.push({
              id: oilsAndFatsFoodGroup.foodGroup.id,
              title: foodGroupTitle,
              value: foodGroupValue,
            });
          }
        } else {
          nutritionSummaryServings.push({
            id: oilsAndFatsFoodGroup.foodGroup.id,
            title: "Oils & Fats",
            value: foodGroupValue,
            tooltip: {
              tooltipTitle: "Oils & Fats",
              items: [
                {
                  id: oilsAndFatsFoodGroup.foodGroup.id,
                  title: foodGroupTitle,
                  value: foodGroupValue,
                },
              ],
            },
          });
        }
      }
    } else {
      for (const staticItem of staticFoodGroups) {
        nutritionSummaryServings.push({
          id: staticItem.id,
          title: staticItem.title,
          value: 0,
        });
      }
    }
  }

  return nutritionSummaryServings;
}
