import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FullPageOtidaLoader } from "../components/loaders/full-page-otida-loader";

export type FullPageLoaderService = {
  startLoading: () => number;
  stopLoading: (id: number) => void;
};

const FullPageLoaderServiceContext = createContext<
  FullPageLoaderService | undefined
>(undefined);

export function FullPageLoaderServiceProvider({
  children,
}: PropsWithChildren<{}>) {
  const nextId = useRef(0);
  const [loadingIds, setLoadingIds] = useState<number[]>([]);

  const startLoading = useCallback(() => {
    const currentId = nextId.current;

    nextId.current = currentId + 1 === Number.MAX_VALUE ? 0 : currentId + 1;

    setLoadingIds((prevLoadingIds) => [...prevLoadingIds, currentId]);

    return currentId;
  }, [setLoadingIds]);

  const stopLoading = useCallback(
    (id: number) =>
      setLoadingIds((prevLoadingIds) =>
        prevLoadingIds.filter((loadingId) => loadingId !== id)
      ),
    [setLoadingIds]
  );

  const value = useMemo(() => ({ startLoading, stopLoading }), [
    startLoading,
    stopLoading,
  ]);

  return (
    <FullPageLoaderServiceContext.Provider value={value}>
      <>
        {children}
        {loadingIds.length > 0 && <FullPageOtidaLoader />}
      </>
    </FullPageLoaderServiceContext.Provider>
  );
}

/**
 * Not exposing this since you'd always want to clean up on component's unmount.
 */
function useFullPageLoaderService(): FullPageLoaderService {
  const fullPageLoaderService = useContext(FullPageLoaderServiceContext);
  if (fullPageLoaderService === undefined) {
    throw Error(
      "useFullPageLoaderService must be used within an FullPageLoaderServiceProvider"
    );
  }

  return fullPageLoaderService;
}

/**
 * If a component unmounts, it will clean up the loading state automatically.
 *
 * Always use this one. That's why we're not exporting `useFullPageLoaderService`.
 */
export function useFullPageLoaderServiceWithCleanup(): FullPageLoaderService {
  const fullPageLoaderService = useFullPageLoaderService();

  const loadingIds = useRef<number[]>([]);

  useEffect(() => {
    return () => {
      loadingIds.current.forEach((id) => fullPageLoaderService.stopLoading(id));
    };
  }, [fullPageLoaderService]);

  const startLoading = useCallback(() => {
    const id = fullPageLoaderService.startLoading();

    loadingIds.current = [...loadingIds.current, id];

    return id;
  }, [fullPageLoaderService]);

  const stopLoading = useCallback(
    (id: number) => {
      fullPageLoaderService.stopLoading(id);

      loadingIds.current = loadingIds.current.filter(
        (loadingId) => loadingId !== id
      );
    },
    [fullPageLoaderService]
  );

  const fullPageLoaderServiceWithCleanup = useMemo(
    () => ({ startLoading, stopLoading }),
    [startLoading, stopLoading]
  );

  return fullPageLoaderServiceWithCleanup;
}
