import styled from "styled-components/macro";
import { lightShadowAlt } from "../../../../../../styles/global-style";
import { mediumSemiBoldFont } from "../../../../../../styles/classes/gloabl-classes";

export const CGMReportResponseChartsContainer = styled.div`
  padding: 0px 24px;
`;

export const CGMReportResponseChartsCard = styled.div`
  ${lightShadowAlt}
  border-radius: 8px;
  margin-top: 32px;
`;

export const CGMReportResponseChartsHeader = styled.div`
  background: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;

  p {
    ${mediumSemiBoldFont}
    margin: 0px;
  }
`;

export const CGMReportResponseChartsBody = styled.div<{ $isEmpty: boolean }>`
  padding: 8px 16px;
  height: calc(100% - 37px);
  display: ${({ $isEmpty }) => ($isEmpty ? "block" : "grid")};
  grid-template-columns: repeat(7, 120px);
  overflow-x: auto;

  @media screen and (min-width: 1500px) {
    grid-template-columns: repeat(7, 180px);
  }

  @media screen and (max-width: 1158px) {
    grid-template-columns: repeat(7, 90px);
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(7, 85px);
  }
`;
