import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { DietDTO } from "../../models/diet-dtos/diet-dto";

export function useGetDiets(): () => Promise<DietDTO[]> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<DietDTO[]>(`${BASE_PATH}/diets`);

    return response.data;
  }, [client]);
}
