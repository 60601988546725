import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { PatientNutritionReportDailySummaryDTO } from "../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-daily-summary-dto";

type PatientNutritionReportDailySummary = {
  patientNutritionReportDailySummary: PatientNutritionReportDailySummaryDTO;
};

const PatientNutritionReportDailySummaryContext = createContext<
  PatientNutritionReportDailySummary | undefined
>(undefined);

type PatientNutritionReportDailySummaryProps = PropsWithChildren<{
  patientNutritionReportDailySummary: PatientNutritionReportDailySummaryDTO;
}>;

export const PatientNutritionReportDailySummaryProvider = (
  props: PatientNutritionReportDailySummaryProps
) => {
  const { children, patientNutritionReportDailySummary } = props;

  const value = useMemo(
    () => ({
      patientNutritionReportDailySummary,
    }),
    [patientNutritionReportDailySummary]
  );

  return (
    <PatientNutritionReportDailySummaryContext.Provider value={value}>
      {children}
    </PatientNutritionReportDailySummaryContext.Provider>
  );
};

export const usePatientNutritionReportDailySummary =
  (): PatientNutritionReportDailySummary => {
    const patientNutritionReportDailySummary = useContext(
      PatientNutritionReportDailySummaryContext
    );

    if (patientNutritionReportDailySummary === undefined) {
      throw new Error(
        `usePatientNutritionReportDailySummary must be within PatientNutritionReportDailySummaryProvider`
      );
    }

    return patientNutritionReportDailySummary;
  };
