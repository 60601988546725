import { useLocation } from "react-router-dom";
import { getLabTestCategoryFromLocation } from "../../utils/lab-test-results-utils";
import { NewBulkLabResultsModalHeader } from "./styled-new-lab-results-modal-header";
import { useLabResults } from "../../context/loadable-lab-results-context";

type NewLabResultsModalHeaderProps = {
  onClose: () => void;
};

export const NewLabResultsModalHeader = (
  props: NewLabResultsModalHeaderProps
) => {
  const { onClose } = props;

  const location = useLocation();
  const { labTestCategoryDTOs } = useLabResults();

  return (
    <NewBulkLabResultsModalHeader>
      <p>
        {
          getLabTestCategoryFromLocation(location.pathname, labTestCategoryDTOs)
            .name
        }
      </p>
      <span className="material-symbols-outlined" onClick={onClose}>
        close
      </span>
    </NewBulkLabResultsModalHeader>
  );
};
