import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useChatContext } from "stream-chat-react";
import { Event as StreamChatEvent } from "stream-chat";
import { useLocation } from "react-router-dom";

type ChatNotifications = {
  requestingChatNotificationsPermission: boolean;
  enableNotification: () => void;
};

const ChatNotificationsContext = createContext<ChatNotifications | undefined>(
  undefined
);

export const ChatNotificationsProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  const [
    requestingChatNotificationsPermission,
    setRequestingChatNotificationsPermission,
  ] = useState(false);

  const { client } = useChatContext();

  const location = useLocation();
  const chatLocation = location.pathname.endsWith("/chat/home");

  useEffect(() => {
    const showNotification = (event: StreamChatEvent) => {
      const message = event.message;

      if (
        !("Notification" in window) ||
        Notification.permission !== "granted" ||
        message?.user?.id === client?.user?.id
      ) {
        return;
      }

      if (chatLocation && !document.hidden) {
        return;
      }

      const options: NotificationOptions = {
        body:
          message?.text !== undefined ? message.text : "You have new message",
        icon: "/img/otida.png",
        dir: "auto",
      };

      new Notification("Otida Chat", options);
    };

    const onMessageNewReturn = client?.on("message.new", showNotification);

    return () => {
      onMessageNewReturn?.unsubscribe();
    };
  }, [client, chatLocation]);

  function enableNotification() {
    if (!("Notification" in window)) {
      alert("Browser does not support desktop notification");
    } else {
      setRequestingChatNotificationsPermission(true);
      Notification.requestPermission().finally(() =>
        setRequestingChatNotificationsPermission(false)
      );
    }
  }

  const value = useMemo(
    () => ({ requestingChatNotificationsPermission, enableNotification }),
    [requestingChatNotificationsPermission]
  );

  return (
    <ChatNotificationsContext.Provider value={value}>
      {children}
    </ChatNotificationsContext.Provider>
  );
};

export function useChatNotifications(): ChatNotifications {
  const chatNotifications = useContext(ChatNotificationsContext);

  if (chatNotifications === undefined) {
    throw new Error(
      `useChatNotifications must be within ChatNotificationsProvider`
    );
  }

  return chatNotifications;
}
