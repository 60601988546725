import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import { useFoodLoggingDatePicker } from "../food-logging-date-picker-context";
import { useGetPatientNutritionReportDailySummary } from "../../../../hooks/patient-nutrition-reports-hooks/use-get-patient-nutrition-report-daily-summary";
import { LoadableDataLoading } from "../../../../components/loadable-data-loading";
import {
  UseLoadableDataReturn,
  useLoadableData,
} from "../../../../hooks/use-loadable-data";
import { PatientNutritionReportDailySummaryDTO } from "../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-daily-summary-dto";
import { PatientNutritionReportDailySummaryProvider } from "./patient-nutrition-report-daily-summary-context";

export type LoadablePatientNutritionReportDailySummary =
  UseLoadableDataReturn<PatientNutritionReportDailySummaryDTO>;

const LoadablePatientNutritionReportDailySummaryContext = createContext<
  LoadablePatientNutritionReportDailySummary | undefined
>(undefined);

export const LoadablePatientNutritionReportDailySummaryProvider = (
  props: PropsWithChildren<{}>
) => {
  const { children } = props;

  const { patientID } = useParams();

  const { startDateString, endDateString } = useFoodLoggingDatePicker();

  const getPatientNutritionReportDailySummary =
    useGetPatientNutritionReportDailySummary();

  const load = useCallback(
    () =>
      getPatientNutritionReportDailySummary(
        parseInt(patientID!),
        startDateString,
        endDateString
      ),
    [
      getPatientNutritionReportDailySummary,
      patientID,
      startDateString,
      endDateString,
    ]
  );

  const loadablePatientNutritionReportDailySummary = useLoadableData(load);

  const value: LoadablePatientNutritionReportDailySummary = useMemo(
    () => ({
      state: loadablePatientNutritionReportDailySummary.state,
      reload: loadablePatientNutritionReportDailySummary.reload,
    }),
    [loadablePatientNutritionReportDailySummary]
  );

  return (
    <LoadablePatientNutritionReportDailySummaryContext.Provider value={value}>
      <LoadableDataLoading
        state={loadablePatientNutritionReportDailySummary.state}
        successComponent={(patientNutritionReportDailySummary) => (
          <PatientNutritionReportDailySummaryProvider
            patientNutritionReportDailySummary={
              patientNutritionReportDailySummary
            }
          >
            {children}
          </PatientNutritionReportDailySummaryProvider>
        )}
      />
    </LoadablePatientNutritionReportDailySummaryContext.Provider>
  );
};

export function useLoadablePatientNutritionReportDailySummary(): LoadablePatientNutritionReportDailySummary {
  const loadablePatientNutritionReportDailySummary = useContext(
    LoadablePatientNutritionReportDailySummaryContext
  );

  if (loadablePatientNutritionReportDailySummary === undefined) {
    throw Error(
      "useLoadablePatientNutritionReportDailySummary must be used within an LoadablePatientNutritionReportDailySummaryProvider"
    );
  }

  return loadablePatientNutritionReportDailySummary;
}

export function useReloadPatientNutritionReportDailySummary(): () => void {
  const loadablePatientNutritionReportDailySummary =
    useLoadablePatientNutritionReportDailySummary();
  return loadablePatientNutritionReportDailySummary.reload;
}
