import styled from "styled-components/macro";

export const NutritionReportTableTableContainer = styled.div`
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 7px;
  }
`;

export const NutritionReportTableTable = styled.table`
  width: 1420px;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 24px;
`;
