import styled from "styled-components/macro";
import {
  flexCenterCenter,
  mediumFont,
  regularRegularFont,
} from "../../../styles/classes/gloabl-classes";
import { NavbarHeight } from "../../../styles/global-style";
import { FoodLoggingHeaderHeight, FoodLoggingSidebarWidth } from "../constants";

export const FoodLoggingPatientsContainer = styled.aside`
  background: ${({ theme }) => theme.colors.containerColor};
  width: ${FoodLoggingSidebarWidth};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-top: 0px;
  border-left: 0px;
`;

export const FoodLoggingPatientsBody = styled.div`
  overflow-y: auto;
  height: calc(100vh - (${NavbarHeight} + ${FoodLoggingHeaderHeight} + 124px));
`;

export const FoodLoggingPatientCardContainer = styled.div<{
  activeState?: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => theme.padding.pad05_1};
  gap: 1rem;
  border: 1px solid
    ${({ theme, activeState }) =>
      activeState ? "transparent" : theme.colors.borderColor};
  border-left: 0px;
  border-right: 0px;
  width: calc(100% - 7px);
  cursor: pointer;
  min-height: 65px;
  height: fit-content;
  background: ${({ activeState, theme }) =>
    activeState ? theme.newColors.otidaO2 : theme.colors.containerColor};
`;

export const FoodLoggingPatientCardIconContainer = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenterCenter}
`;

export const FoodLoggingPatientCardTitle = styled.p`
  ${regularRegularFont}
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0;
  word-break: break-all;

  span {
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const FoodLoggingPatientCardText = styled.span`
  margin-top: 4px;
  ${mediumFont}
  color: ${({ theme }) => theme.colors.textColor};
`;
