import styled from "styled-components/macro";

export const BulkImportModalForm = styled.form`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const BulkImportCenter = styled.div`
  padding-top: 1rem;
`;

export const BulkImportData = styled.div`
  overflow: auto;
  height: 250px;
  padding: 0.5rem 0rem;
`;

export const BulkImportDataItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 1rem;
  margin: 1rem 0rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
`;

export const PatientName = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const PatientStatus = styled.div`
  color: ${({ theme }) => theme.colors.textColor};
  font-size: ${({ theme }) => theme.typography.fontSize.mdFontSize};

  span {
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};

    &.success {
      color: ${({ theme }) => theme.colors.success};
    }

    &.failure {
      color: ${({ theme }) => theme.colors.danger};
    }
  }
`;

export const BulkImportModalContent = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  border-radius: 0.75rem;
  width: 500px;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.z600};
  height: 500px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    position: fixed;
    border-radius: 2.625rem 2.625rem 0rem 0rem;
    bottom: 0;
  }
`;
