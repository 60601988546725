import styled from "styled-components/macro";
import {
  flexCenter,
  regularSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";
import { ErrorText } from "../../../../../styles/classes/reusable-classes";
import { LabResultsInputCSS } from "../styles";

export const NewLabResultsListScrollContainer = styled.div`
  @media screen and (max-width: 850px) {
    overflow-x: scroll;
    overflow-y: hidden;

    ::-webkit-scrollbar {
      height: 6px;
    }
  }
`;

export const NewLabResultsListContainer = styled.div<{
  isListItemsEmpty: boolean;
}>`
  border-radius: 14px;
  max-height: 460px;
  min-height: ${({ isListItemsEmpty }) => (isListItemsEmpty ? "0px" : "200px")};
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 0px 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  margin-top: 24px;

  @media screen and (max-width: 850px) {
    width: 900px;
  }
`;

export const NewLabResultsListHeaders = styled.div<{ smallInputs?: boolean }>`
  background: ${({ theme }) => theme.newColors.containerColorAlt};
  ${flexCenter}
  padding: 8px 24px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

  p {
    margin: 0;
    ${regularSemiBoldFont}
    color: ${({ theme }) => theme.colors.titleColor};
    width: ${({ smallInputs }) => (smallInputs ? "264px" : "164px")};
  }

  p:nth-child(2) {
    width: ${({ smallInputs }) => (smallInputs ? "264px" : "324px")};
  }
`;

export const NewLabResultsListUL = styled.ul`
  margin: 0px;
`;

export const NewLabResultsListListItem = styled.li`
  border: 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 12px 24px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 0px 24px;
`;

export const NewLabResultsListTypeContainer = styled.div`
  p {
    ${regularSemiBoldFont}
    color: ${({ theme }) => theme.colors.textColor};
    margin: 0;
    min-width: 140px;
  }
`;

export const NewLabResultsListInputContainer = styled.div<{
  smallInputs?: boolean;
}>`
  position: relative;

  ${ErrorText} {
    top: 30px;
    left: 8px;
    color: ${({ theme }) => theme.newColors.veryDanger};
  }

  input {
    ${LabResultsInputCSS}
    ${regularSemiBoldFont}
    width: ${({ smallInputs }) => smallInputs && "250px"};
  }
`;

export const NewLabResultsListIconContainer = styled.div`
  width: 50px;

  img {
    cursor: pointer;
  }
`;
