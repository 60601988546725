import { Link } from "react-router-dom";
import { GenericActionsList } from "../../../components/generic-actions-list/generic-actions-list";
import {
  TD,
  TDContainer,
  TDLink,
  TDText,
} from "../../../components/table/styled-table";
import { useToastService } from "../../../context/toast-service-context";
import { useDeletePatient } from "../../../hooks/patient-hooks/use-delete-patient";
import { PatientDTO } from "../../../models/patient-dtos/patient-dto";
import { useReloadPatients } from "../context/loadable-patients-context";
import { DeletePatient } from "../delete-patient/delete-patient";
import { EditPatient } from "../edit-patient/edit-patient";
import { Temporal } from "temporal-polyfill";
import { SyncPatient } from "../sync-patient/sync-patient";
import { FEATURE_FLAG_CHAT } from "../../../constants/config";

export type PatientsTableRowsProps = {
  currentPagePatientDTOs: PatientDTO[];
};

export function PatientsTableRows(props: PatientsTableRowsProps) {
  const { currentPagePatientDTOs } = props;

  const deletePatient = useDeletePatient();
  const { showToast } = useToastService();
  const reloadPatients = useReloadPatients();

  async function handleConfirmDelete(patientDTO: PatientDTO) {
    try {
      await deletePatient(patientDTO);
      showToast("Success", "Patient deleted successfully!");
      reloadPatients();
    } catch (error) {
      showToast("Error", "Failed to delete patient :(");
    }
  }

  return (
    <>
      {currentPagePatientDTOs.map((patientDTO) => {
        const displayDate =
          patientDTO.createdAt !== undefined
            ? new Date(patientDTO.createdAt).toLocaleString("en-EG", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
            : "";

        return (
          <tr key={patientDTO.id}>
            <TD>
              <TDContainer>
                <TDLink>
                  <Link to={`/patients/${patientDTO.id}`}>
                    {patientDTO.name}
                  </Link>
                </TDLink>
              </TDContainer>
            </TD>
            <TD width={200}>
              <TDContainer>{patientDTO.type}</TDContainer>
            </TD>
            <TD width={200}>
              <TDContainer>
                <TDText>{patientDTO.mobile}</TDText>
              </TDContainer>
            </TD>
            <TD width={200}>
              <TDContainer>
                <TDText>
                  {patientDTO.activationDate !== undefined
                    ? Temporal.PlainDate.from(
                        patientDTO.activationDate
                      ).toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    : ""}
                </TDText>
              </TDContainer>
            </TD>
            <TD width={200}>
              <TDContainer>
                <TDText>{displayDate}</TDText>
              </TDContainer>
            </TD>
            <TD width={100}>
              <GenericActionsList>
                <EditPatient patientDTO={patientDTO} />
                <DeletePatient
                  onDelete={() => handleConfirmDelete(patientDTO)}
                  patientName={patientDTO.name}
                />
                {FEATURE_FLAG_CHAT && <SyncPatient patientDTO={patientDTO} />}
              </GenericActionsList>
            </TD>
          </tr>
        );
      })}
    </>
  );
}
