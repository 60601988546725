import {
  NoLogoLoaderLoader,
  NoLogoLoaderLoadingContainer,
} from "./styled-no-logo-loader";

type NoLogoLoaderProps = {};

export const NoLogoLoader = (props: NoLogoLoaderProps) => {
  return (
    <NoLogoLoaderLoadingContainer>
      <NoLogoLoaderLoader />
    </NoLogoLoaderLoadingContainer>
  );
};
