import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { PatientProfileDietModalInputs } from "../../patient-profile-edit-diet-modal";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileTagsLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import {
  PateintProfileFamilyMemberDiseasesFormControlBody,
  PateintProfileFamilyMemberDiseasesFormControlDropdownsContainer,
} from "../../../../../patient-profile-medical-history-section/patient-profile-medical-history/patient-profile-medical-history-edit-modal/patient-profile-medical-history-edit-modal-body/pateint-profile-family-member-diseases-form-control/styled-pateint-profile-family-member-diseases-form-control";
import {
  Button,
  ErrorText,
} from "../../../../../../../../styles/classes/reusable-classes";
import { Dropdown } from "../../../../../../../../components/dropdown/dropdown";
import { DropdownListItem } from "../../../../../../../../components/dropdown/styled-dropdown";
import { useCallback } from "react";
import { usePatientProfileDiet } from "../../../../context/loadable-patient-profile-diet-context";

export const PatientProfileEditDietLifeActivitiesFormControl = () => {
  const { activities } = usePatientProfileDiet();

  const findActivityById = useCallback(
    (id: number) => activities.find((item) => item.id === id),
    [activities]
  );

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<PatientProfileDietModalInputs>();

  const { append, fields, remove } = useFieldArray({
    name: "patientLifeActivity",
    control,
  } as never);

  const patientLifeActivity = watch("patientLifeActivity");

  return (
    <PatientProfileModalFormControl>
      <PatientProfileTagsLabelContainer>
        <label>Activities / Exercise</label>
        <span>(Baseline / Pre-Otida)</span>
      </PatientProfileTagsLabelContainer>
      <PateintProfileFamilyMemberDiseasesFormControlBody
        isNotEmptyFamilyMemberDiseases={
          patientLifeActivity !== undefined && patientLifeActivity.length > 0
        }
        style={{ gap: "24px" }}
      >
        {fields.map((field, index) => (
          <PatientProfileModalInputContainer key={field.id}>
            <PateintProfileFamilyMemberDiseasesFormControlDropdownsContainer>
              <Button outlineNoBorder type="button">
                <img
                  src="/img/trash.svg"
                  alt="Trash Icon"
                  onClick={() => remove(index)}
                />
              </Button>
              <Controller
                name={`patientLifeActivity.${index}.activityId`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Dropdown
                      text={
                        value !== undefined &&
                        findActivityById(value)?.name !== undefined
                          ? // the next line should never be undefined because of the previous condition
                            findActivityById(value)?.name!
                          : "Select Activity"
                      }
                      variant="designed-ux"
                      fullWidth
                    >
                      {activities
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((activity) => (
                          <DropdownListItem
                            variant="designed-ux"
                            key={activity.id}
                            onClick={() => onChange(activity.id)}
                          >
                            {activity.name}
                          </DropdownListItem>
                        ))}
                    </Dropdown>
                  );
                }}
              />

              <PatientProfileModalInputContainer>
                <Controller
                  name={`patientLifeActivity.${index}.minsPerWeek`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <input
                        type="number"
                        placeholder="Mins/Week"
                        value={value !== undefined ? value : ""}
                        onChange={(e) =>
                          onChange(
                            e.target.value !== undefined ? e.target.value : ""
                          )
                        }
                      />
                    );
                  }}
                />
                {errors.patientLifeActivity?.[index]?.minsPerWeek?.type ===
                  "required" && (
                  <ErrorText left={10} top={35}>
                    Required
                  </ErrorText>
                )}
              </PatientProfileModalInputContainer>
            </PateintProfileFamilyMemberDiseasesFormControlDropdownsContainer>
          </PatientProfileModalInputContainer>
        ))}
        <Button
          type="button"
          width={100}
          height={40}
          flex
          outlineNoBorder
          onClick={() => append({})}
        >
          <span className="material-symbols-outlined">add</span> Add
        </Button>
      </PateintProfileFamilyMemberDiseasesFormControlBody>
    </PatientProfileModalFormControl>
  );
};
