export const BARIATRIC_SURGERIES = [
  "GastricBypass",
  "GastricBalloon",
  "GastricRing",
  "SleeveGastrectomy",
  "TummyTuck",
] as const;

export type BariatricSurgery = typeof BARIATRIC_SURGERIES[number];

export const NON_BARIATRIC_SURGERIES = [
  "Cholecystectomy",
  "CsSection",
  "Liposuction",
  "OpenHeart",
  "IhdStents",
  "Other",
] as const;

export type NonBariatricSurgery = typeof NON_BARIATRIC_SURGERIES[number];

export const SURGERIES = [
  ...BARIATRIC_SURGERIES,
  ...NON_BARIATRIC_SURGERIES,
] as const;

export type Surgery = typeof SURGERIES[number];
