import { PatientNutritionReportDailySummaryDayPatientMealDTO } from "../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-daily-summary-day-patient-meal-dto";
import {
  NutritionReportTableRowTDVoiceNote,
  NutritionReportTableRowTDVoiceNotes,
} from "../components/styled-nutrition-report-table-cell";

type NutritionReportMealDateTableCellVoiceNotesProps = {
  meal: PatientNutritionReportDailySummaryDayPatientMealDTO;
};

export const NutritionReportMealDateTableCellVoiceNotes = (
  props: NutritionReportMealDateTableCellVoiceNotesProps
) => {
  const { meal } = props;

  return (
    <NutritionReportTableRowTDVoiceNotes>
      {meal.patientMealVoiceNotes.map((patientMealVoiceNote) => (
        <NutritionReportTableRowTDVoiceNote
          key={patientMealVoiceNote.publicUrl}
        >
          <audio controls src={patientMealVoiceNote.publicUrl} />
        </NutritionReportTableRowTDVoiceNote>
      ))}
    </NutritionReportTableRowTDVoiceNotes>
  );
};
