import { Modal } from "../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import {
  NutritionReportTableRowTD,
  NutritionReportTableRowTDContainer,
  NutritionReportTableRowTDText,
} from "../components/styled-nutrition-report-table-cell";
import { Temporal } from "temporal-polyfill";
import { NutritionReportMealDateTableCellImages } from "./nutrition-report-meal-date-table-cell-images";
import { PatientNutritionReportDailySummaryDayPatientMealDTO } from "../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-daily-summary-day-patient-meal-dto";
import { NutritionReportMealDateTableCellVoiceNotes } from "./nutrition-report-meal-date-table-cell-voice-notes";
import { NutritionReportMealDateTableCellNote } from "./nutrition-report-meal-date-table-cell-note";
import { FoodLoggingSelectedPatientMealImagesModal } from "../../../../food-logging-selected-patient-meal/components/food-logging-selected-patient-meal-images/food-logging-selected-patient-meal-images-modal/food-logging-selected-patient-meal-images-modal";

type NutritionReportMealDateTableCellProps = {
  meal: PatientNutritionReportDailySummaryDayPatientMealDTO;
};

export const NutritionReportMealDateTableCell = (
  props: NutritionReportMealDateTableCellProps
) => {
  const { meal } = props;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <NutritionReportTableRowTD type="Normal-bodyColor" sticky>
      <NutritionReportTableRowTDContainer>
        <NutritionReportTableRowTDText cellKind="Meal">
          {Temporal.PlainDate.from(meal.date).toLocaleString("en-UK", {
            day: "numeric",
            month: "short",
          })}
          ,{" "}
          {Temporal.PlainTime.from(meal.time)
            .toLocaleString("en-UK", {
              hour12: true,
              hour: "numeric",
              minute: "numeric",
              second: undefined,
            })
            .toLocaleUpperCase()}
        </NutritionReportTableRowTDText>
      </NutritionReportTableRowTDContainer>
      {meal.patientMealImages.length === 0 &&
        meal.patientMealVoiceNotes.length === 0 && (
          <NutritionReportMealDateTableCellNote mealNote={meal.note} />
        )}
      {meal.patientMealImages.length > 0 && (
        <NutritionReportMealDateTableCellImages
          meal={meal}
          openModal={openModal}
        />
      )}
      {meal.patientMealVoiceNotes.length > 0 && (
        <NutritionReportMealDateTableCellVoiceNotes meal={meal} />
      )}
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FoodLoggingSelectedPatientMealImagesModal
          key={modalBodyKey}
          patientMealImageDTOs={meal.patientMealImages}
        />
      </Modal>
    </NutritionReportTableRowTD>
  );
};
