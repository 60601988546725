import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { PatientProfileWeightEditModal } from "./patient-profile-weight-edit-modal/patient-profile-weight-edit-modal";
import {
  PatientProfileTableAndChartContainerHeaderContainer,
  PatientProfileTableAndChartContainerHeaderDateContainer,
} from "../../styles/patient-profile-weight-section-classes";

type PatientProfileWeightHeaderProps = {
  showPicker: boolean;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date) => void;
};

export const PatientProfileWeightHeader = (
  props: PatientProfileWeightHeaderProps
) => {
  const { showPicker, setStartDate, setEndDate } = props;

  return (
    <PatientProfileTableAndChartContainerHeaderContainer>
      {showPicker ? (
        <PatientProfileTableAndChartContainerHeaderDateContainer>
          <DateRangePickerComponent
            placeholder="Select date range"
            format="dd/ M /yyyy"
            separator="-"
            startDate={undefined}
            endDate={undefined}
            onChange={(e: any) => {
              if (e.target.value !== null) {
                setStartDate(e.target.value[0]);
                setEndDate(e.target.value[1]);
              }
            }}
          />
        </PatientProfileTableAndChartContainerHeaderDateContainer>
      ) : (
        <></>
      )}
      <PatientProfileWeightEditModal showPicker={showPicker} />
    </PatientProfileTableAndChartContainerHeaderContainer>
  );
};
