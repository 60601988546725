import { Attachment, MessageResponse } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

type AttachmentWithDate = Attachment<DefaultStreamChatGenerics> & {
  date?: string;
  monthName?: string;
};

export type TransformMessagesAndAttachments = {
  created_at?: string;
  attachments?: AttachmentWithDate[];
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function transfromMessages(
  messages: MessageResponse<DefaultStreamChatGenerics>[]
): TransformMessagesAndAttachments[] {
  return messages.map((message) => {
    const attachmentsWithDate:
      | AttachmentWithDate[]
      | undefined = message.attachments?.map((attachment) => ({
      ...attachment,
      date: message.created_at,
    }));

    return {
      created_at: message.created_at?.split("T")[0],
      attachments: attachmentsWithDate,
    };
  });
}

function transformMessagesDates(
  messages: MessageResponse<DefaultStreamChatGenerics>[]
): TransformMessagesAndAttachments[] {
  return transfromMessages(messages).map((item) => {
    const createdDate = new Date(item.created_at!);
    const monthName = `${
      months[createdDate.getMonth()]
    }-${createdDate.getFullYear()}`;

    const attachments = item.attachments?.map((attachment) => ({
      ...attachment,
      monthName,
      date: attachment.date,
    }));

    return {
      created_at: monthName,
      attachments,
    };
  });
}

export function groupAttachmentsByMonth(
  messages: MessageResponse<DefaultStreamChatGenerics>[]
): TransformMessagesAndAttachments[] {
  const groupedAttachments: TransformMessagesAndAttachments[] = [];

  // Create a map to store attachments by month
  const attachmentsByMonth: Map<string, AttachmentWithDate[]> = new Map();

  // Iterate through the data
  transformMessagesDates(messages).forEach((item) => {
    const monthName = item.created_at;

    if (monthName && item.attachments) {
      // Check if the month already exists in the map

      if (attachmentsByMonth.has(monthName)) {
        // Append the attachments to the existing month

        attachmentsByMonth.get(monthName)?.push(...item.attachments);
      } else {
        // Create a new entry for the month and add attachments

        attachmentsByMonth.set(monthName, [...item.attachments]);
      }
    }
  });

  // Convert the map to an array of objects
  attachmentsByMonth.forEach((attachments, monthName) => {
    groupedAttachments.push({
      created_at: monthName,
      attachments: attachments.map((attachment) => ({
        ...attachment,
        date: attachment.date,
        monthName,
      })),
    });
  });

  return groupedAttachments;
}
