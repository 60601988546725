import { VideoUploadHelpersProvider } from "../../../../../../../components/video-upload/context/video-upload-helpers-context";
import { CreateLessonCardPartsVideoUpload } from "./create-lesson-card-part-video-upload";

type CardVideoPartProps = {
  cardIndex: number;
  cardPartIndex: number;
};

export const CardVideoPart = (props: CardVideoPartProps) => {
  const { cardIndex, cardPartIndex } = props;

  return (
    <VideoUploadHelpersProvider>
      <CreateLessonCardPartsVideoUpload
        cardIndex={cardIndex}
        cardPartIndex={cardPartIndex}
      />
    </VideoUploadHelpersProvider>
  );
};
