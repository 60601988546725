import { RangesCounts } from "../../../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import {
  ChartNumber,
  ChartText,
  CircleChart,
  CircleChartContainer,
} from "./styled-daily-chart-ranges";

export type DailyChartRangesProps = {
  rangesCounts: RangesCounts;
  smallCircles: boolean;
  viewPlanChart?: boolean;
};

export function DailyChartRanges(props: DailyChartRangesProps) {
  const { rangesCounts, smallCircles, viewPlanChart } = props;

  return (
    <>
      <Range
        label="Below"
        percentage={rangesCounts.below}
        isBad={rangesCounts.below > 4}
        smallCircles={smallCircles}
        viewPlanChart={viewPlanChart}
      />
      <Range
        label="In Range"
        percentage={rangesCounts.inRange}
        isBad={rangesCounts.inRange < 70}
        smallCircles={smallCircles}
        viewPlanChart={viewPlanChart}
      />
      <Range
        label="Above"
        percentage={rangesCounts.above}
        isBad={rangesCounts.above > 25}
        smallCircles={smallCircles}
        viewPlanChart={viewPlanChart}
      />
    </>
  );
}

function Range(props: {
  label: string;
  percentage: number;
  isBad: boolean;
  smallCircles: boolean;
  viewPlanChart?: boolean;
}) {
  const { label, percentage, isBad, smallCircles, viewPlanChart } = props;

  const percentageCeiled = Math.ceil(percentage);

  return (
    <CircleChartContainer
      smallCircles={smallCircles}
      viewPlanChart={viewPlanChart}
    >
      <CircleChart
        smallCircles={smallCircles}
        chartProgress={percentageCeiled}
        isBad={isBad}
        viewPlanChart={viewPlanChart}
      >
        <ChartNumber
          chartProgress={percentageCeiled}
          smallCircles={smallCircles}
          viewPlanChart={viewPlanChart}
        >
          {Math.trunc(percentage)}%
        </ChartNumber>
      </CircleChart>
      <ChartText smallCircles={smallCircles} viewPlanChart={viewPlanChart}>
        {label}
      </ChartText>
    </CircleChartContainer>
  );
}
