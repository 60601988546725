import styled from "styled-components/macro";
import {
  regularMediumFont,
  regularSemiBoldFont,
} from "../../../../styles/classes/gloabl-classes";

export const PatientSidebarInfoContainer = styled.div`
  text-align: center;
  width: 100%;

  p {
    margin: 0;
  }

  img {
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    border-radius: 50%;
    padding: 8px 8px 0px;
    width: 100px;
  }
`;

export const PatientName = styled.p`
  ${regularSemiBoldFont}
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const PatientType = styled.span`
  color: ${({ theme }) => theme.colors.success};
`;

export const PatientAge = styled.p`
  ${regularMediumFont}
  color: ${({ theme }) => theme.colors.textColor};
`;

export const PatientGender = styled.span<{ gender: string }>`
  ${regularMediumFont}
  color: ${({ theme, gender }) =>
    gender === "Male"
      ? theme.newColors.byOtida
      : gender === "Female"
      ? theme.colors.dangerText
      : theme.colors.textColor};
`;
