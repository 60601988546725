import { FormEventHandler, PropsWithChildren } from "react";
import { ModalFormForm } from "./styled-modal-form";
import { HasDataTestId } from "../../../utils/has-data-test-id";

type ModalFormProps = PropsWithChildren<{
  onSubmit?: FormEventHandler<HTMLFormElement>;
  height?: number;
  width?: number;
}> &
  HasDataTestId;

export const ModalForm = (props: ModalFormProps) => {
  const { onSubmit, children, height, width, dataTestId } = props;

  return (
    <ModalFormForm
      data-testid={dataTestId}
      height={height}
      width={width}
      onSubmit={onSubmit}
    >
      {children}
    </ModalFormForm>
  );
};
