import {
  Avatar,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";
import {
  MessagingMediaSidebarCloseIcon,
  MessagingMediaSidebarContainer,
  MessagingMediaSidebarList,
  MessagingMediaSidebarListItem,
  MessagingMediaSidebarParticipantsContainer,
  MessagingMediaSidebarParticipantsList,
  MessagingMediaSidebarParticipantsListItem,
  MessagingMediaSidebarTop,
} from "./styled-messaging-media-sidebar";
import { StreamChatGenerics } from "../../../types/stream-chat-types";
import { useEffect, useState } from "react";
import { MessagingMediaSidebarActiveList } from "./messaging-media-sidebar-active-list/messaging-media-sidebar-active-list";

type MessagingMediaSidebarProps = {
  showMediaSidebar: boolean;
  handleHideMediaSidebar: () => void;
};

export type ChatMessagingMediaSidebarActiveList =
  | ""
  | "Media"
  | "Pinned Messages";

export const MessagingMediaSidebar = (props: MessagingMediaSidebarProps) => {
  const { showMediaSidebar, handleHideMediaSidebar } = props;

  const { client } = useChatContext<StreamChatGenerics>();
  const { channel } = useChannelStateContext<StreamChatGenerics>();

  const [title, setTitle] = useState("");
  const [activeList, setActiveList] =
    useState<ChatMessagingMediaSidebarActiveList>("");
  const handleResetActiveList = () => setActiveList("");

  const members = Object.values(channel.state.members || {}).filter(
    (member) => member.user?.id
  );

  useEffect(() => {
    if (!channel.data?.name) {
      setTitle(
        members
          .map(
            (member) => member.user?.name || member.user?.id || "Unnamed User"
          )
          .join(", ")
      );
    }
  }, [channel.data?.name, members]);

  const avatar = () => {
    const groupChannelSrc = "/img/chat/group.svg";
    const userAvatar = "/img/chat/user.svg";

    const isSingleUserChannel = members.length === 1;
    const singleUserChannelSrc =
      isSingleUserChannel && client.user?.image !== undefined
        ? client.user.image
        : userAvatar;

    const isNotGroupChannel = members.length <= 2;
    const channelAvatar = members.find(
      (member) => member.user?.id !== client.user?.id
    )?.user?.image;

    const twoUsersChannelSrc = isNotGroupChannel
      ? channelAvatar !== undefined
        ? channelAvatar
        : userAvatar
      : groupChannelSrc;

    return (
      <Avatar
        image={
          isSingleUserChannel
            ? singleUserChannelSrc
            : isNotGroupChannel
            ? twoUsersChannelSrc
            : groupChannelSrc
        }
        size={40}
        shape="circle"
      />
    );
  };

  const channelName =
    members.length <= 2
      ? members.find((member) => member.user?.id !== client.user?.id)?.user
          ?.name
      : channel.data?.name !== undefined
      ? channel.data.name
      : title;

  return (
    <MessagingMediaSidebarContainer showMediaSidebar={showMediaSidebar}>
      {activeList === "" ? (
        <>
          <MessagingMediaSidebarTop>
            {avatar()}
            <p>{channelName}</p>
            <MessagingMediaSidebarCloseIcon onClick={handleHideMediaSidebar}>
              <img src="/img/chat/close.svg" alt="close" />
            </MessagingMediaSidebarCloseIcon>
          </MessagingMediaSidebarTop>
          <MessagingMediaSidebarList>
            <MessagingMediaSidebarListItem
              onClick={() => setActiveList("Media")}
            >
              <p>Media, links and Docs</p>
              <span>
                <img src="/img/chat/chev.svg" alt="Chevron right" />
              </span>
            </MessagingMediaSidebarListItem>
            <MessagingMediaSidebarListItem
              onClick={() => setActiveList("Pinned Messages")}
            >
              <p>Pinned Messages</p>
              <span>
                <img src="/img/chat/chev.svg" alt="Chevron right" />
              </span>
            </MessagingMediaSidebarListItem>
          </MessagingMediaSidebarList>
          {members.length > 2 && (
            <MessagingMediaSidebarParticipantsContainer>
              <p>{members.length} Participants</p>
              <MessagingMediaSidebarParticipantsList>
                {members.map((member) =>
                  member.user?.id === client.userID ? (
                    <MessagingMediaSidebarParticipantsListItem
                      key={client.userID}
                    >
                      <Avatar
                        image={
                          member.user?.image !== undefined
                            ? member.user.image
                            : "/img/chat/user.svg"
                        }
                        size={25}
                      />
                      <span>You</span>
                    </MessagingMediaSidebarParticipantsListItem>
                  ) : (
                    <MessagingMediaSidebarParticipantsListItem
                      key={member.user_id}
                    >
                      <Avatar
                        image={
                          member.user?.image !== undefined
                            ? member.user.image
                            : "/img/chat/user.svg"
                        }
                        size={25}
                      />
                      <span>
                        {member.user?.name !== undefined
                          ? `${member.user?.name}`
                          : `${member.user?.id}`}
                      </span>
                    </MessagingMediaSidebarParticipantsListItem>
                  )
                )}
              </MessagingMediaSidebarParticipantsList>
            </MessagingMediaSidebarParticipantsContainer>
          )}
        </>
      ) : (
        <MessagingMediaSidebarActiveList
          activeList={activeList}
          handleResetActiveList={handleResetActiveList}
        />
      )}
    </MessagingMediaSidebarContainer>
  );
};
