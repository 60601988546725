import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";

export function useConvertWebmToM4a(): (webmFile: Blob) => Promise<Blob> {
  const client = useClient();

  return useCallback(
    async (webmFile) => {
      const formData = new FormData();
      formData.append("webmFile", webmFile);

      const response = await client.post<Blob>(
        `${BASE_PATH}/media-convert/webm-to-m4a`,
        formData,
        { responseType: "blob" }
      );

      return response.data;
    },
    [client]
  );
}
