import styled from "styled-components/macro";
import { regularMediumFont } from "../../../../../styles/classes/gloabl-classes";

export const ImmediateAttentionPatientsSidebarPatientDiagnosisContainer = styled.div`
  margin-bottom: 16px;

  p {
    margin: 0px;
    ${regularMediumFont}
  }

  ul {
    margin: 8px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    li {
      font-size: 13px;
      font-weight: 500;
      color: #14212e;
      background: ${({ theme }) => theme.newColors.gray6};
      border-radius: 94px;
      padding: 6px 16px;
      gap: 8px;
      width: max-content;
      height: fit-content;
    }
  }
`;
