import { Nutrition } from "./nutrition";

export type PatientServingDTO = {
  id?: number;
  date: string;
  time: string;
  foodName: string;
  amount: string;
  category: string;
  nutrition: Nutrition;
  patientId: number;
};

export const SERVING_MEDICINE_CATEGORY = "Supplements";

export function isMedicine(patientServingDTO: PatientServingDTO): boolean {
  return patientServingDTO.category === SERVING_MEDICINE_CATEGORY;
}
