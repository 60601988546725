import { useEffect, useState } from "react";
import { useGetPatientActivityLogStats } from "../../../hooks/patient-activity-logs-hooks/use-get-patient-activity-log-stats";
import { PatientActivityLogStatsDTO } from "../../../models/patient-activity-log-dtos/patient-activity-log-stats-dto";
import { useParams } from "react-router-dom";

type UseGetPatientActivityLogStatsDTO = {
  patientActivityLogStatsDTO: PatientActivityLogStatsDTO;
  isLoaded: boolean;
};

export function useGetPatientActivityLogStatsDTO(
  startDate?: string,
  endDate?: string
): UseGetPatientActivityLogStatsDTO {
  const { id } = useParams();

  const [patientActivityLogStatsDTO, setPatientActivityLogStatsDTO] = useState<
    PatientActivityLogStatsDTO
  >({} as PatientActivityLogStatsDTO);
  const [isLoaded, setIsLoaded] = useState(false);

  const getPatientActivityLogStats = useGetPatientActivityLogStats();

  useEffect(() => {
    const fetch = async () => {
      getPatientActivityLogStats(Number(id!), startDate, endDate).then(
        (response) => {
          setPatientActivityLogStatsDTO(response);
          setIsLoaded(true);
        }
      );
    };

    fetch();
  }, [id, getPatientActivityLogStats, setIsLoaded, startDate, endDate]);

  return { patientActivityLogStatsDTO, isLoaded };
}
