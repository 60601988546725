import styled from "styled-components/macro";
import { transition } from "../../../../../../styles/global-style";
import {
  flexCenter,
  flexCenterCenter,
  flexCol,
  mediumRegularFont,
  regularMediumFont,
  regularSemiBoldFont,
  smallMediumFont,
} from "../../../../../../styles/classes/gloabl-classes";
import {
  NutritionReportCellKind,
  NutritionReportThContainerVariants,
} from "../../types/nutrition-report-table-types";

export const NutritionReportTableRowTDContainer = styled.div`
  ${flexCenter}
  gap: 0px 16px;
  padding: 8px 16px;
`;

export type NutritionReportThContainerType = {
  type: NutritionReportThContainerVariants;
  sticky?: boolean;
};

export const NutritionReportTableRowTD = styled.td<
  NutritionReportThContainerType & {
    cellKind?: NutritionReportCellKind;
  }
>`
  position: ${({ sticky }) => (sticky ? "sticky" : "relative")};
  left: ${({ sticky }) => (sticky ? "0px" : "unset")};
  z-index: ${({ sticky }) => (sticky ? "100" : "0")};
  background: ${({ theme, type }) =>
    type === "Meat"
      ? theme.newColors.otidaTableCells
      : type === "Dairy"
      ? theme.newColors.redTableCells
      : type === "Fat"
      ? theme.newColors.greenTableCells
      : type === "Normal"
      ? theme.colors.containerColor
      : type === "Normal-bodyColor"
      ? theme.colors.bodyColor
      : type.includes("Total")
      ? theme.colors.borderColor
      : theme.colors.scrollbarThumb};

  ${NutritionReportTableRowTDContainer} {
    width: ${({ type, cellKind }) =>
      type.includes("Meat")
        ? "348px"
        : type.includes("Dairy")
        ? "262px"
        : type.includes("Fat")
        ? "378px"
        : cellKind === "Sources"
        ? "150px"
        : "109px"};
    justify-content: ${({ type }) =>
      type.includes("Meat") || type.includes("Dairy") || type.includes("Fat")
        ? "space-between"
        : "center"};
  }

  :first-child {
    ${NutritionReportTableRowTDContainer} {
      width: 185px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0px 4px;
    }
  }
`;

export const NutritionReportTableRowTDText = styled.span<{
  cellKind?: NutritionReportCellKind;
}>`
  ${({ cellKind }) =>
    cellKind !== undefined && cellKind === "Row"
      ? regularSemiBoldFont
      : cellKind === "Meal"
      ? regularMediumFont
      : cellKind === undefined
      ? regularSemiBoldFont
      : cellKind === "Sources"
      ? smallMediumFont
      : ``};
  cursor: pointer;
  margin-right: 6px;
  ${flexCenter}
  min-width: 25px;
`;

export const NutritionReportTableRowTDCheckboxContainer = styled.span`
  input {
    cursor: pointer;
    width: 12px;
    margin: 0;
  }
`;

export const NutritionReportTableRowTDChevIcon = styled.span<{
  rotate: "true" | "false";
}>`
  font-size: 16px;
  cursor: pointer;
  position: relative;
  z-index: 10000;
  transform: ${({ rotate }) =>
    rotate === "true" ? "rotate(90deg)" : "rotate(0deg)"};
  transition: ${transition};
`;

export const NutritionReportTableRowTDImages = styled.div<{ show: boolean }>`
  width: 130px;
  height: 52px;
  height: ${({ show }) => (show ? "52px" : "0px")};
  display: ${({ show }) => (show ? "block" : "none")};
  position: relative;
  ${flexCenter}
`;

export const NutritionReportTableRowTDImage = styled.div<{ faded?: boolean }>`
  position: absolute;
  z-index: 3;
  inset: 0;
  background: ${({ faded, theme }) =>
    faded ? theme.newColors.transparentImageCountContainer : "transparent"};
  cursor: pointer;
  border-radius: 10px;
  left: 60px;
  ${flexCenterCenter}

  :nth-child(1) {
    z-index: 1;
    left: 16px;
  }

  :nth-child(2) {
    z-index: 2;
    left: 36px;
  }

  img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  p {
    font-size: 20px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    color: ${({ theme }) => theme.colors.titleColor};
    text-align: center;
  }
`;

export const NutritionReportTableRowTDVoiceNotes = styled.div`
  ${flexCol}
  gap: 8px 0px;
`;

export const NutritionReportTableRowTDVoiceNote = styled.div`
  audio {
    width: 250px;
  }

  audio::-webkit-media-controls-panel {
    background: ${({ theme }) => theme.colors.containerColor};
  }
`;

export const NutritionReportTableRowTDNoteContainer = styled.div`
  padding: 0px 16px;
`;

export const NutritionReportTableRowTDNote = styled.p`
  margin: 0px;
  ${mediumRegularFont}

  span {
    margin-left: 4px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primaryColorAlt};
  }
`;
