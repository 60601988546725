import { Modal } from "../../../../../../components/modal/modal";
import { ModalForm } from "../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../components/modal/modal-header/modal-header";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { PatientNutritionReportDTO } from "../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-dto";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { ViewNutritionReportSummary } from "./view-nutrition-report-summary/view-nutrition-report-summary";
import { PatientDTO } from "../../../../../../models/patient-dtos/patient-dto";

type AnalyticsAndReportsBodyModalProps = {
  patientNutritionReport: PatientNutritionReportDTO;
  patientDTO: PatientDTO;
};

export const AnalyticsAndReportsBodyModal = (
  props: AnalyticsAndReportsBodyModalProps
) => {
  const { patientNutritionReport, patientDTO } = props;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <>
      <Button outlined type="button" onClick={openModal}>
        View Report
      </Button>
      <Modal showModal={isModalOpen} handleHideModal={closeModal}>
        <ModalForm key={modalBodyKey} width={1000} height={800}>
          <ModalHeader
            onModalClose={closeModal}
            title={`Report ${patientNutritionReport.id} - Patient: ${patientDTO.name}`}
            subTitle={`${new Date(
              patientNutritionReport.createdAt
            ).toLocaleString("en-EG", {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              timeZoneName: "short",
              hour12: true,
            })}`}
          />
          <ViewNutritionReportSummary
            patientNutritionReport={patientNutritionReport}
          />
        </ModalForm>
      </Modal>
    </>
  );
};
