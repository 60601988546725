import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  DateRangeContainer,
  DateRangeInputContainer,
  DateRangeLabel,
  DateRangeSubContainer,
} from "./styled-date-range";
import { useDateRange } from "./context/date-range-context";

type DateRangeProps = {
  label?: string;
};

export const DateRange = (props: DateRangeProps) => {
  const { label } = props;

  const {
    handleDecrementDates,
    handleIncrementDates,
    onDatesChange,
    temporalEndDate,
    temporalStartDate,
    dateRangePickerRef,
    onDateRangePickerClick,
  } = useDateRange();

  return (
    <DateRangeContainer>
      <DateRangeLabel>{label ? label : "Pick a Date"}</DateRangeLabel>
      <DateRangeSubContainer>
        <button onClick={handleDecrementDates}>
          <span className="material-symbols-outlined">arrow_back_ios</span>
        </button>
        <DateRangeInputContainer>
          <DateRangePickerComponent
            startDate={new Date(temporalStartDate)}
            endDate={new Date(temporalEndDate)}
            placeholder="Select date range"
            format="dd/ M /yyyy"
            separator="-"
            onChange={onDatesChange}
            ref={dateRangePickerRef}
            onClick={onDateRangePickerClick}
          />
        </DateRangeInputContainer>
        <button onClick={handleIncrementDates}>
          <span className="material-symbols-outlined">arrow_forward_ios</span>
        </button>
      </DateRangeSubContainer>
    </DateRangeContainer>
  );
};
