import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  mediumRegularFont,
} from "../../../../../../../../styles/classes/gloabl-classes";

export const ViewNutritionReportSummaryHeaderContainer = styled.div`
  ${flexCenterSpaceBetween}
  padding: 0px 16px;
  margin: 16px 16px 0px;
`;

export const ViewNutritionReportSummaryHeaderTitle = styled.p`
  margin: 0px;
  ${mediumRegularFont}
  color: ${({ theme }) => theme.colors.textColor};
`;
