import styled from "styled-components/macro";
import {
  flexCenterCenter,
  smallSemiBoldFont,
} from "../../../../styles/classes/gloabl-classes";
import { EmptyStateBody } from "../../../../components/empty-state/styled-empty-state";
import { transition } from "../../../../styles/global-style";

export const ContentModuleLessonMobilePreviewCardsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px 0px;
  height: 350px;
  margin-top: 0px;
  margin-bottom: 90px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  ${EmptyStateBody} {
    height: 20vh;

    img {
      height: 140px;
    }

    p {
      font-size: 16px;
    }
  }
`;

export const ContentModuleLessonMobilePreviewCardListItem = styled.li<{
  $activeCard: boolean;
}>`
  opacity: ${({ $activeCard }) => ($activeCard ? "1" : "0")};
  height: ${({ $activeCard }) => ($activeCard ? "auto" : "0px")};
  overflow: ${({ $activeCard }) => ($activeCard ? "visible" : "hidden")};
  transition: ${transition};
`;

export const ContentModuleLessonMobilePreviewCardHeader = styled.div`
  h6 {
    text-align: center;
    ${smallSemiBoldFont}
    width: 100%;
    margin: 8px 0px;
  }
`;

export const ContentModuleLessonMobilePreviewCardPartsListItem = styled.li`
  ${flexCenterCenter}

  p {
    ${smallSemiBoldFont}
  }

  img,
  video {
    border-radius: 8px;
    width: fit-content;
    max-width: 160px;
  }
`;
