import styled, { DefaultTheme, StyledComponent } from "styled-components/macro";
import {
  FEATURE_FLAG_NUTRITION_SUMMARY_FOR_DEMO,
  FEATURE_FLAG_NUTRITION_SUMMARY_PIE_CHART,
} from "../../../../../../../constants/config";
import {
  lightShadow,
  transition,
} from "../../../../../../../styles/global-style";

export const NutritionSummaryCard = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  position: relative;
  border-radius: 0.5rem;
  padding: 0.8rem 0rem;
  width: 290px;
  border: 1px solid ${({ theme }) => theme.colors.borderColorAlt};
  border-radius: 12px;
  font-size: 12px;

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const NutritionSummaryCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.8rem 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  height: 77px;

  h3 {
    font-size: 1rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.screenTitleColor};
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.newColors.modalContainerAlt};
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
`;

export const NutritionSummaryCardCenter = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
`;

export const NutritionSummaryCardCenterList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin: 0;

  div {
    width: 50%;
  }

  div ~ div {
    border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
  }
`;

export const NutritionSummaryCardCenterListItem = styled.li`
  background: ${({ theme }) => theme.colors.containerColor};
  display: flex;
  align-items: center;
  gap: 0 0rem;
  padding: 0rem 0.2rem 0rem 0.5rem;
  text-align: left;
  height: 40px;

  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.textColor};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    line-height: 1.4;
    flex: 1;
    text-transform: capitalize;
  }

  span {
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    ${!FEATURE_FLAG_NUTRITION_SUMMARY_FOR_DEMO ? `flex: 0.3;` : `width: 29px;`}
  }

  :nth-child(odd) {
    background: ${({ theme }) => theme.newColors.oddBackground};
  }
`;

export const NutritionSummaryCardFooter = styled.div`
  padding: 0.5rem 0.8rem 0rem;
`;

export const NutritionSummaryCardFooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.screenTitleColor};
    margin: 0;
  }

  span {
    color: ${({ theme }) => theme.newColors.modalContainerAlt};
    font-weight: 600;
  }
`;

export const NutritionSummaryCardFooterCenter = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 6px;
  background: ${({ theme }) => theme.newColors.otidaO2};
  margin: 0.5rem 0rem;
  display: flex;
  align-items: center;
  position: relative;
`;

export const Carbs = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  left: 0;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.newColors.warningAlt};
  position: absolute;
  z-index: 3;
`;

export const Fat = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  left: 0;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.primaryColor};
  position: absolute;
  z-index: 2;
`;

export const Protien = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  left: 0;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.primaryColorAlt};
  position: absolute;
  z-index: 1;
`;

export const NutritionSummaryCardFooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0rem 1rem;
  margin-top: 1rem;
  padding: 0rem 1rem;
`;

export const NutritionSummaryCardLegendPoint = styled.div`
  display: flex;
  align-items: center;

  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  :first-child > div {
    background: ${({ theme }) => theme.newColors.warningAlt};
  }

  :nth-child(2) > div {
    background: ${({ theme }) => theme.colors.primaryColor};
  }

  :nth-child(3) > div {
    background: ${({ theme }) => theme.colors.primaryColorAlt};
  }

  span {
    color: ${({ theme }) => theme.colors.titleColor};
    margin-left: 0.5rem;
  }
`;

const getFatSummaryStyles = (): StyledComponent<
  "div",
  DefaultTheme,
  {},
  never
> => {
  if (FEATURE_FLAG_NUTRITION_SUMMARY_PIE_CHART) {
    return styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.5rem 0rem;
      width: 61%;
      padding: 0rem 1rem;
    `;
  } else {
    return styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      gap: 0rem 1rem;
    `;
  }
};

export const FatSummaryContainer = getFatSummaryStyles();

export const FatSummaryText = styled.p<{ isGood: boolean }>`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  margin: 0;
  color: ${({ theme }) => theme.colors.textColor};

  span {
    color: ${({ theme, isGood }) =>
      isGood ? theme.colors.success : theme.colors.danger};
  }
`;

export const FatSummaryTextTwo = styled.p<{ isGood: boolean }>`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  margin: 0;
  color: ${({ theme }) => theme.colors.textColor};
  display: flex;
  width: 100%;

  strong {
    color: ${({ theme, isGood }) =>
      isGood ? theme.colors.success : theme.colors.danger};
    flex: 0.4;
  }

  span {
    text-align: left;
    flex: 0.6;
  }
`;

type PieChartType = {
  carbs: number;
  fat: number;
  protien: number;
  kcal: string;
};

export const NutritionSummaryCardFooterTwo = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 0.5rem 1rem 0rem;
  position: relative;
`;

export const ToolTip = styled.div`
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  transition: ${transition};
  width: 277px;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.z300};
  background: ${({ theme }) => theme.colors.containerColor};
  font-size: ${({ theme }) => theme.typography.fontSize.mdFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  left: -75px;
  top: -230px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  ${lightShadow}

  p {
    margin: 0;
    padding-bottom: 0.4rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.titleColor};
  }

  ::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid ${({ theme }) => theme.colors.containerColor};
    position: absolute;
    bottom: -20px;
    left: 40%;
    transition: ${transition};
  }
`;

export const TootlTipText = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
  color: ${({ theme }) => theme.colors.titleColor};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};

  span {
    padding: 0.1rem 0.5rem;
    width: 30%;
    text-align: right;
  }
`;

export const PieChart = styled.div<PieChartType>`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${({ carbs, fat, protien, theme }) =>
    carbs === 0 && fat === 0 && protien === 0
      ? theme.newColors.otidaO2
      : `repeating-conic-gradient(
    from 0deg,
    ${theme.newColors.warningAlt} 0deg calc(3.6deg * ${carbs}),
    ${theme.colors.primaryColor} calc(3.6deg * ${carbs}) calc(3.6deg * ${fat}),
    ${theme.colors.primaryColorAlt} calc(3.6deg * ${fat}) calc(3.6deg * ${protien})
  )`};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  ::before {
    content: "";
    position: absolute;
    width: 70%;
    height: 70%;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.containerColor};
  }

  ::after {
    content: "${({ kcal }) => kcal} kcal";
    text-align: center;
    position: absolute;
    font-size: 0.8rem;
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
    color: ${({ theme }) => theme.colors.titleColor};
    width: 50%;
    height: 45%;
  }

  :hover + ${ToolTip} {
    visibility: visible;
    overflow: visible;
    opacity: 1;
  }
`;
