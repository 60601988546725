import {
  ImmediateAttentionPatientsFiltersSidebarFilterTitle,
  ImmediateAttentionPatientsFiltersSidebarFilterCheckboxList,
} from "../styled-immediate-attention-patients-filters-sidebar";

const riskFactors = [
  "Overweight",
  "Obesity",
  "Smoking",
  "Alcohol",
  "Sedentary lifestyle",
  "Family history of diabetes mellitus",
  "Unhealthy diet",
  "Gestational diabetes",
];

export const ImmediateAttentionPatientsFiltersSidebarRiskFactors = () => {
  return (
    <>
      <ImmediateAttentionPatientsFiltersSidebarFilterTitle>
        Risk Factors
      </ImmediateAttentionPatientsFiltersSidebarFilterTitle>
      <ImmediateAttentionPatientsFiltersSidebarFilterCheckboxList $height="100px">
        {riskFactors.map((riskFactor) => (
          <div key={riskFactor}>
            <input type="checkbox" />
            <label>{riskFactor}</label>
          </div>
        ))}
      </ImmediateAttentionPatientsFiltersSidebarFilterCheckboxList>
    </>
  );
};
