import { Temporal } from "temporal-polyfill";
import { PatientMealDTO } from "../../../../models/patient-meal-dtos/patient-meal-dto";

export function getMealDisplayDateAndTime(patientMealDTO: PatientMealDTO) {
  const displayMealDate =
    patientMealDTO.date !== undefined
      ? Temporal.PlainDate.from(patientMealDTO.date).toLocaleString("en-UK", {
          day: "numeric",
          month: "short",
        })
      : "";

  const displayMealTime =
    patientMealDTO.date !== undefined
      ? Temporal.PlainTime.from(patientMealDTO.time).toLocaleString("en-UK", {
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
          second: undefined,
        })
      : "";

  return { displayMealDate, displayMealTime };
}

export function isPatientMealDTOLegacy(
  patientMealDTO: PatientMealDTO
): boolean {
  if (
    patientMealDTO.patientMealCarbSources.length > 0 ||
    patientMealDTO.patientMealProteinSources.length > 0 ||
    patientMealDTO.patientMealFoodGroups.some(
      (foodGroud) => foodGroud.servingsCount > 0
    )
  ) {
    return true;
  } else {
    return false;
  }
}
