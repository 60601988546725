import { PatientCGMEntryDTO } from "../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { useRowsActions } from "../../context/rows-actions-context";
import { useState } from "react";
import { TagDropdown } from "../tag-dropdown/tag-dropdown";
import { useUpdatePatientCGMEntry } from "../../../../../../../../hooks/use-update-patient-cgm-entry";
import { CrudListContainer } from "./styled-table-rows";
import { ActionIcons } from "../../../../../../../../components/action-icons/action-icons";
import {
  TD,
  TDContainer,
  TDInput,
} from "../../../../../../../../components/table/styled-table";
import { getAfterMealTag, getTag } from "../tag-dropdown/tag-dropdown-utils";

type Props = {
  row: PatientCGMEntryDTO;
  onReadingsChanged: () => void;
};

export const UpdateTableRow = (props: Props) => {
  const { row, onReadingsChanged } = props;

  const { cancelOrConfirm } = useRowsActions();
  const updatePatientCGMEntry = useUpdatePatientCGMEntry();

  const [patientCGMEntryDTO, setPatientCGMEntryDTO] =
    useState<PatientCGMEntryDTO>(row);

  async function handleConfirmEditing() {
    try {
      await updatePatientCGMEntry(patientCGMEntryDTO);
      onReadingsChanged();
    } catch (error) {
      console.error(error);
    }
    cancelOrConfirm();
  }

  // The goal of `.substring` below is to fix this bug:
  // https://app.asana.com/0/1204250435622327/1204423142310952
  // https://stackoverflow.com/questions/34548724/how-to-correctly-use-time-input-element

  return (
    <tr>
      <TD>
        <TDContainer>
          <TDInput
            type="time"
            value={patientCGMEntryDTO.time.substring(0, 5)}
            onChange={(e) =>
              setPatientCGMEntryDTO({
                ...patientCGMEntryDTO,
                time: e.target.value + ":00",
              })
            }
          />
        </TDContainer>
      </TD>
      <TD>
        <TDContainer>
          <TDInput
            // reading
            type="text"
            value={patientCGMEntryDTO.glucoseMGPerDL}
            onChange={(e) =>
              setPatientCGMEntryDTO({
                ...patientCGMEntryDTO,
                glucoseMGPerDL: Number(e.target.value),
              })
            }
          />
        </TDContainer>
      </TD>
      <TD>
        <TDContainer>
          <TagDropdown
            value={`${
              patientCGMEntryDTO.tag !== undefined
                ? getTag(patientCGMEntryDTO.tag)
                : "Select Tag"
            }${
              patientCGMEntryDTO.afterMealTag !== undefined
                ? ` - ${getAfterMealTag(patientCGMEntryDTO.afterMealTag)}`
                : ""
            }`}
            onChange={(value, afterMealValue) =>
              setPatientCGMEntryDTO({
                ...patientCGMEntryDTO,
                tag: getTag(value!),
                afterMealTag: getAfterMealTag(afterMealValue!),
              })
            }
          />
        </TDContainer>
      </TD>
      <TD>
        <CrudListContainer>
          <ActionIcons
            onConfirm={handleConfirmEditing}
            onCancel={cancelOrConfirm}
          />
        </CrudListContainer>
      </TD>
    </tr>
  );
};
