import { useParams } from "react-router-dom";
import { useGetPatientNutritionReportSummary } from "../../../../hooks/patient-nutrition-reports-hooks/use-get-patient-nutrition-report-summary";
import { useCallback, useMemo } from "react";
import { NutritionReportSummaryHeader } from "./nutrition-report-summary-header/nutrition-report-summary-header";
import { Loading } from "../../../../components/loading";
import { NutritionReportSummaryResponse } from "./nutrition-report-summary-screen/nutrition-report-summary-response";
import { useNutritionReportSearchParams } from "../../context/nutrition-report-context/nutrition-report-search-params-context";
import { FormProvider, useForm } from "react-hook-form";
import { CreatePatientNutritionReportHealthyHabitDTO } from "../../../../models/patient-nutrition-report-dtos/create-patient-nutrition-report-healthy-habit-dto";
import { CreatePatientNutritionReportUnhealthyHabitDTO } from "../../../../models/patient-nutrition-report-dtos/create-patient-nutrition-report-unhealthy-habit-dto";
import { useNutritionReportSelectedTableRows } from "../../context/nutrition-report-context/nutrition-report-selected-table-rows-context";

export type NutrtionReportSummaryNotesInputs = {
  patientNutritionReportHealthyHabits?: CreatePatientNutritionReportHealthyHabitDTO[];
  patientNutritionReportUnhealthyHabits?: CreatePatientNutritionReportUnhealthyHabitDTO[];
};

export const NutritionReportSummary = () => {
  const getPatientNutritionReportSummary =
    useGetPatientNutritionReportSummary();

  const { patientID } = useParams();
  const { searchParams } = useNutritionReportSearchParams();

  const { selectedDays } = useNutritionReportSelectedTableRows();
  const selectedDates = useMemo(
    () => selectedDays.map((day) => day.date),
    [selectedDays]
  );

  const getPatientNutritionReportSummaryDates =
    searchParams.getAll("selectedDays").length === 0 ||
    searchParams.getAll("selectedDays") === undefined
      ? selectedDates
      : searchParams.getAll("selectedDays");

  const nutritionReportSummaryHeaderDates =
    searchParams.getAll("selectedDays") !== undefined &&
    searchParams.getAll("selectedDays").length > 0
      ? {
          startDate: searchParams.getAll("selectedDays")[0],
          endDate:
            searchParams.getAll("selectedDays")[
              searchParams.getAll("selectedDays").length - 1
            ],
        }
      : {
          startDate: selectedDates[0],
          endDate: selectedDates[selectedDays.length - 1],
        };

  const load = useCallback(
    () =>
      getPatientNutritionReportSummary(
        parseInt(patientID!),
        getPatientNutritionReportSummaryDates
      ),
    [
      getPatientNutritionReportSummary,
      patientID,
      getPatientNutritionReportSummaryDates,
    ]
  );

  const nutrtionReportSummaryNotesFormMethods =
    useForm<NutrtionReportSummaryNotesInputs>();

  return (
    <div>
      <NutritionReportSummaryHeader dates={nutritionReportSummaryHeaderDates} />
      <Loading
        load={load}
        successComponent={(patientNutritionReportSummary) => (
          <FormProvider {...nutrtionReportSummaryNotesFormMethods}>
            <NutritionReportSummaryResponse
              patientNutritionReportSummary={patientNutritionReportSummary}
            />
          </FormProvider>
        )}
      />
    </div>
  );
};
