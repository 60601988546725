import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { NutritionRegimenDTO } from "../../../../../models/nutrition-regimen-dtos/nutrition-regimen-dto";
import { useNutritionGoals } from "./loadable-nutrition-goals-context";

type NutritionGolasLastFoodGroupSelected = {
  lastFoodGroupSelected?: NutritionRegimenDTO;
  setLastFoodGroupSelected: (
    lastFoodGroupSelected: NutritionRegimenDTO
  ) => void;
};

const NutritionGolasLastFoodGroupSelectedContext = createContext<
  NutritionGolasLastFoodGroupSelected | undefined
>(undefined);

export const NutritionGolasLastFoodGroupSelectedProvider = (
  props: PropsWithChildren<{}>
) => {
  const { children } = props;

  const { activeNutritionRegimen } = useNutritionGoals();

  const [lastFoodGroupSelected, setLastFoodGroupSelected] = useState<
    NutritionRegimenDTO | undefined
  >(
    activeNutritionRegimen !== undefined &&
      activeNutritionRegimen.nutritionRegimen !== undefined
      ? activeNutritionRegimen.nutritionRegimen
      : undefined
  );

  const value = useMemo(
    () => ({
      lastFoodGroupSelected,
      setLastFoodGroupSelected,
    }),
    [lastFoodGroupSelected]
  );

  return (
    <NutritionGolasLastFoodGroupSelectedContext.Provider value={value}>
      {children}
    </NutritionGolasLastFoodGroupSelectedContext.Provider>
  );
};

export const useNutritionGolasLastFoodGroupSelected =
  (): NutritionGolasLastFoodGroupSelected => {
    const nutritionGolasLastFoodGroupSelected = useContext(
      NutritionGolasLastFoodGroupSelectedContext
    );

    if (nutritionGolasLastFoodGroupSelected === undefined) {
      throw new Error(
        `useNutritionGolasLastFoodGroupSelected must be within NutritionGolasLastFoodGroupSelectedProvider`
      );
    }

    return nutritionGolasLastFoodGroupSelected;
  };
