import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  mediumFont,
} from "../../../../../../../styles/classes/gloabl-classes";

export const VeiwPlansManualReadingsChartLegendContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  ${flexCenterSpaceBetween}
`;

export const VeiwPlansManualReadingsChartLegendItem = styled.div`
  ${flexCenter}
  gap: 10px;
`;

export const VeiwPlansManualReadingsChartLegendText = styled.p`
  ${mediumFont}
  color: ${({ theme }) => theme.colors.textColor};
  margin: 0px;
`;

export const VeiwPlansManualReadingsChartLegendPoint = styled.div<{
  inRange: boolean;
}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ inRange, theme }) =>
    inRange ? theme.newColors.bgmChartGreen : theme.newColors.bgmChartRed};
`;
