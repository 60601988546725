import { ReactNode } from "react";
import { useDropdown } from "../../../../../../../components/dropdown/hooks/use-dropdown";
import {
  HomeFilterDropdownButton,
  HomeFilterDropdownContainer,
  HomeFilterDropdownList,
} from "./styled-home-filter-dropdown";

type HomeFilterDropdownProps = {
  buttonText: string;
  dropdownOptions: ReactNode;
  width: string;
  height: string;
};

export const HomeFilterDropdown = (props: HomeFilterDropdownProps) => {
  const { buttonText, dropdownOptions, width, height } = props;

  const { showDropdown, toggleDropdown, dropdownContainerRef } = useDropdown();

  return (
    <HomeFilterDropdownContainer ref={dropdownContainerRef}>
      <HomeFilterDropdownButton
        $showDropdown={showDropdown}
        onClick={toggleDropdown}
      >
        {buttonText}
        <span className="material-symbols-outlined">expand_more</span>
      </HomeFilterDropdownButton>
      <HomeFilterDropdownList
        $showDropdown={showDropdown}
        $width={width}
        $height={height}
      >
        {dropdownOptions}
      </HomeFilterDropdownList>
    </HomeFilterDropdownContainer>
  );
};
