import {
  DietitianReportTabHeaderContainer,
  DietitianReportTabHeaderTitle,
} from "../../components/reusable-styles";

export const AnalyticsAndReportsHeader = () => {
  return (
    <DietitianReportTabHeaderContainer>
      <DietitianReportTabHeaderTitle>
        Previous Reports and analysis
      </DietitianReportTabHeaderTitle>
    </DietitianReportTabHeaderContainer>
  );
};
