import { useMemo } from "react";
import {
  sortedPatientMedicationDTOs,
  sortMedicationsByName,
} from "../../../medications/utils/medications-utils";
import { CreatePlansFooter } from "../../create-plans/create-plans-footer/create-plans-footer";
import { PlanStepsHeader } from "../../components/plan-steps-header/plan-steps-header";
import { PlansSubScreenContainer } from "../styles";
import { CurrentMedications } from "./current-medications";
import { EmptyMedical } from "./empty-medical";
import { useGetPatientMedicationDTOs } from "../../hooks/use-get-patient-medication-dtos";
import { useDetailedPlan } from "../../context/loadable-detailed-plan-context";

export const Medical = () => {
  const { patientDTO } = useDetailedPlan();

  const { patientMedicationDTOs } = useGetPatientMedicationDTOs(patientDTO);

  const srotedPatientMedicationDTOs = useMemo(
    () =>
      sortedPatientMedicationDTOs(sortMedicationsByName(patientMedicationDTOs)),
    [patientMedicationDTOs]
  );

  return (
    <PlansSubScreenContainer>
      <PlanStepsHeader
        title="Medical"
        subTitle="List of current medications and / or required investigations"
        icon="/img/plans/nav/medical.svg"
        button={<></>}
      />
      {srotedPatientMedicationDTOs.length === 0 ? (
        <EmptyMedical />
      ) : (
        <CurrentMedications
          srotedPatientMedicationDTOs={srotedPatientMedicationDTOs}
        />
      )}
      <CreatePlansFooter nextStep="Nutrition" prevStep="Feedback" />
    </PlansSubScreenContainer>
  );
};
