import styled from "styled-components/macro";
import { flexCenter } from "../../../../../../../styles/classes/gloabl-classes";
import {
  lightShadowSecondary,
  transition,
} from "../../../../../../../styles/global-style";

export const MessagingChannelListHeaderSearchResultsContainer = styled.div<{
  showList: boolean;
}>`
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  background: ${({ theme }) => theme.colors.containerColor};
  z-index: 2;
  max-height: ${({ showList }) => (showList ? "200px" : "0px")};
  height: fit-content;
  opacity: ${({ showList }) => (showList ? "1" : "0")};
  overflow-y: ${({ showList }) => (showList ? "auto" : "hidden")};
  overflow-x: hidden;
  ${lightShadowSecondary}
  border-radius: 0px 0px 8px 8px;
  transition: ${({ showList }) => (showList ? transition : "")};
`;

export const MessagingChannelListHeaderSearchResultsItem = styled.div`
  cursor: pointer;
  padding: 8px;
  ${flexCenter}
  gap: 0px 8px;
  transition: ${transition};

  .str-chat__avatar {
    margin-right: 0px;
  }

  p {
    margin: 0px;
    color: ${({ theme }) => theme.colors.textColor};
  }

  :hover {
    background: ${({ theme }) => theme.colors.inputColor};
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;
