import {
  AllPatientsAnalyticsCardItem,
  AllPatientsAnalyticsCardItemImageContainer,
  AllPatientsAnalyticsCardItemTextContainer,
  AllPatientsAnalyticsCardsList,
  AllPatientsAnalyticsContainer,
} from "./styled-all-patients-analytics";

function formatNumberWithCommas(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const cards = [
  {
    id: 1,
    title: "Total",
    value: 15,
    icon: "card-11",
    iconExtention: "svg",
  },
  {
    id: 2,
    title: "High priority",
    value: 4,
    icon: "card-2",
    iconExtention: "png",
  },
  {
    id: 3,
    title: "Medium priority",
    value: 3,
    icon: "card-3",
    iconExtention: "png",
  },
  {
    id: 4,
    title: "Low priority",
    value: 8,
    icon: "card-4",
    iconExtention: "png",
  },
];

export const ImmediateAttentionPatientsCards = () => {
  return (
    <AllPatientsAnalyticsContainer>
      <AllPatientsAnalyticsCardsList>
        {cards.map((card) => (
          <AllPatientsAnalyticsCardItem key={card.id}>
            <AllPatientsAnalyticsCardItemImageContainer>
              <img
                src={`/img/all-patients/${card.icon}.${card.iconExtention}`}
                alt={card.title}
              />
            </AllPatientsAnalyticsCardItemImageContainer>
            <AllPatientsAnalyticsCardItemTextContainer>
              <p>{card.title}</p>
              <span>{formatNumberWithCommas(card.value)}</span>
            </AllPatientsAnalyticsCardItemTextContainer>
          </AllPatientsAnalyticsCardItem>
        ))}
      </AllPatientsAnalyticsCardsList>
    </AllPatientsAnalyticsContainer>
  );
};
