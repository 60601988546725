import { PlansBtn } from "./plans-btn";
import { EmptyState } from "../../../components/empty-state/empty-state";

export const EmptyPlans = () => {
  return (
    <EmptyState
      hasSearch={false}
      title="Weekly plan (0)"
      text="There are no plans yet"
      subText="Start creating the first plan now"
      hasHeader={true}
      button={<PlansBtn outlined={false} />}
    />
  );
};
