import { AgpPercentile } from "../../../../utils/agp-calculate-percentile";

type AgpSeries50th = {
  x: Date;
  point50th: number;
};

export function buildAgpSeries50th(
  percentile50: AgpPercentile[]
): AgpSeries50th[] {
  const agpSeries50th: AgpSeries50th[] = [];

  for (const p50 of percentile50) {
    const x = new Date(`2024-01-01T${p50.hour}:00:00`);

    agpSeries50th.push({
      x,
      point50th: p50.cgmEntry.glucoseMGPerDL,
    });
  }

  if (agpSeries50th.length !== 0) {
    agpSeries50th.push({
      x: new Date(`2024-01-02T00:00:00`),
      point50th: agpSeries50th[0].point50th,
    });
  }

  return agpSeries50th;
}
