import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";

type NutritionReportCarbsgmTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportCarbsgmTableCell = (
  props: NutritionReportCarbsgmTableCellProps
) => {
  const { kind } = props;

  const { carbsGm } = useMemo(() => {
    const carbsGm =
      kind.kind === "Row"
        ? kind.data.totalCarbsGm
        : kind.data.patientMealMacrosSummary.carbsGm;

    return { carbsGm };
  }, [kind.kind, kind.data]);

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      value={carbsGm}
    />
  );
};
