import styled from "styled-components/macro";
import { OtidaSpinner } from "../../../components/loaders/animations/otida-loader-animation";

export const PatientLoginLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem 0rem;
`;

export const PatientLoginLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ::before {
    content: "";
    width: 35px;
    height: 35px;
    border: 3px solid ${({ theme }) => theme.colors.barBackground};
    border-top-color: ${({ theme }) => theme.colors.containerColor};
    border-radius: 50%;
    animation: ${OtidaSpinner} 0.8s linear infinite;
  }
`;
