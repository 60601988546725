import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useContentModuleMode } from "../../../context/content-module-mode-context";
import { ReadContentModule } from "../../../components/read-content-module/read-content-module";
import { ContentModuleLessonMobilePreview } from "../../../components/content-module-lesson-mobile-preview/content-module-lesson-mobile-preview";
import { LessonReadModeCards } from "./lesson-read-mode-cards/lesson-read-mode-cards";
import { UpdateContentModule } from "../../../components/update-content-module/update-content-module";
import { LessonUpdateModeCards } from "./lesson-update-mode-cards/lesson-update-mode-cards";
import { useCardPartsLoading } from "../../context/card-parts-loading-context";

export const ContentCourseModuleLessonModes = () => {
  const {
    contentModuleMode,
    onContentModuleModeChangeToUpdate,
  } = useContentModuleMode();

  const location = useLocation();

  const { courseID, moduleID } = useParams();
  const parentCourseID = parseInt(courseID!);
  const parentModuleID = parseInt(moduleID!);

  const { cardPartsLoading } = useCardPartsLoading();

  useEffect(() => {
    if (location.pathname.includes("update")) {
      onContentModuleModeChangeToUpdate(contentModuleMode.mode.contentModule);
    }
  }, [
    location,
    onContentModuleModeChangeToUpdate,
    contentModuleMode.mode.contentModule,
  ]);

  if (contentModuleMode.mode.kind === "Read") {
    return (
      <ReadContentModule
        contentModuleDTOType="Lesson"
        contentModuleLessonReadModeComponents={{
          lessonReadModeCards: (
            <LessonReadModeCards
              contentModuleCards={
                contentModuleMode.mode.contentModule.contentModuleCards
              }
            />
          ),
          lessonReadModeMobilePreview: (
            <ContentModuleLessonMobilePreview
              kind={{
                mode: "Read",
                contentModuleDTO: contentModuleMode.mode.contentModule,
              }}
            />
          ),
        }}
      />
    );
  }

  return (
    <UpdateContentModule
      contentModuleDTO={contentModuleMode.mode.contentModule}
      routeAfterUpdate={`/content/courses/course/${parentCourseID}/module/${parentModuleID}/lesson/${contentModuleMode.mode.contentModule.id}`}
      contentModuleLessonUpdateModeComponents={{
        lessonUpdateModeCards: <LessonUpdateModeCards />,
        lessonUpdateModeMobilePreview: (
          <ContentModuleLessonMobilePreview
            kind={{
              mode: "Update",
              lessonTitle: contentModuleMode.mode.contentModule.name,
            }}
          />
        ),
        lessonCardPartsHeaderLoading: cardPartsLoading,
      }}
    />
  );
};
