import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";

type NutritionReportAllProteinSourcesTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportAllProteinSourcesTableCell = (
  props: NutritionReportAllProteinSourcesTableCellProps
) => {
  const { kind } = props;

  const mealProteinSources =
    kind.kind === "Meal"
      ? kind.data.patientMealProteinSources.map(
          (patientMealProteinSource) =>
            patientMealProteinSource.proteinSource.name
        )
      : "";

  return (
    <NutritionReportTableCell
      cellKind="Sources"
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      stringValue={
        mealProteinSources !== "" ? mealProteinSources.join(",") : ""
      }
    />
  );
};
