import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PatientMealDTO } from "../../models/patient-meal-dtos/patient-meal-dto";
import { UpdatePatientMealDTO } from "../../models/patient-meal-dtos/update-patient-meal-dto";

export function useUpdatePatientMeal(): (
  patientMealDTO: PatientMealDTO,
  updatePatientMealDTO: UpdatePatientMealDTO
) => Promise<PatientMealDTO> {
  const client = useClient();

  return useCallback(
    async (patientMealDTO, updatePatientMealDTO) => {
      const response = await client.put<PatientMealDTO>(
        `${PATIENTS_PATH}/${patientMealDTO.patientId}/meals/${patientMealDTO.id}`,
        updatePatientMealDTO
      );

      return response.data;
    },
    [client]
  );
}
