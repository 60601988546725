import { Fragment, useMemo } from "react";
import {
  FilteredPatientDataByDate,
  useFilterPatientCGMEntries,
} from "../../../../../hooks/use-filter-patient-cgm-entries/use-filter-patient-cgm-entries";
import { Temporal } from "temporal-polyfill";
import { ManualReadingsTableContainer } from "./styled-manual-readings-table";
import { PatientInsulinLogDTO } from "../../../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";
import { PatientCGMEntryDTO } from "../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { useGetPatientInsulinLogDTOs } from "../../../../../components/cgm-google-chart/hooks/use-get-patient-insulin-log-dtos";
import useOnReadingsChanged from "../../upload-options/manual-readings/manual-readings-modal/hook/useOnReadingsChanged";
import {
  getAfterMealTag,
  getTag,
} from "../../upload-options/manual-readings/manual-readings-modal/manual-readings-table/tag-dropdown/tag-dropdown-utils";

type ManualReadingsTableProps = {
  startDate: string;
  endDate: string;
  tagValue: string;
};

export type ManualReadingsTableData = {
  date: string;
  time: string;
  cell:
    | { kind: "Reading"; data: FilteredPatientDataByDate | PatientCGMEntryDTO }
    | { kind: "Insulin"; data: PatientInsulinLogDTO };
};

export const ManualReadingsTable = (props: ManualReadingsTableProps) => {
  const { startDate, endDate, tagValue } = props;

  const { patientInsulinLogDTOs } = useGetPatientInsulinLogDTOs();

  const { patientData } = useOnReadingsChanged();

  const {
    filteredPatientDataByDate,
    filteredByTags,
  } = useFilterPatientCGMEntries(startDate, endDate, tagValue, patientData);

  const insulinResponseTableData = useMemo(() => {
    const result: ManualReadingsTableData[] = [];

    for (const patientInsulinLogDTO of patientInsulinLogDTOs) {
      const date = patientInsulinLogDTO.date;
      const time = patientInsulinLogDTO.time;

      result.push({
        date,
        time,
        cell: {
          kind: "Insulin",
          data: patientInsulinLogDTO,
        },
      });
    }

    if (filteredByTags.length === 0) {
      for (const filteredPatientDataByDateDTO of filteredPatientDataByDate) {
        const date = filteredPatientDataByDateDTO.date;
        const time = filteredPatientDataByDateDTO.time;

        result.push({
          date,
          time,
          cell: {
            kind: "Reading",
            data: filteredPatientDataByDateDTO,
          },
        });
      }
    } else {
      for (const filteredByTag of filteredByTags) {
        const date = filteredByTag.date;
        const time = filteredByTag.time;

        result.push({
          date,
          time,
          cell: {
            kind: "Reading",
            data: filteredByTag,
          },
        });
      }
    }

    return result
      .sort((a, b) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;

        return a.time.localeCompare(b.time);
      })
      .filter((item) => item.date >= startDate && item.date <= endDate);
  }, [
    startDate,
    endDate,
    filteredPatientDataByDate,
    patientInsulinLogDTOs,
    filteredByTags,
  ]);

  const tableHeadings = (
    <thead>
      <tr>
        <th>Date</th>
        <th>Time</th>
        <th>Insulin Name / BG Tag</th>
        <th>Dose / BG Reading</th>
      </tr>
    </thead>
  );

  return (
    <ManualReadingsTableContainer>
      <table>
        {tableHeadings}
        {insulinResponseTableData.map((item, index) => (
          <Fragment key={`${index}-${item.date}-${item.time}`}>
            {item.cell.kind === "Reading" ? (
              <tr>
                <td>
                  {Temporal.PlainDate.from(item.date).toLocaleString("en-UK", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  })}
                </td>
                <td>
                  {Temporal.PlainTime.from(item.time).toLocaleString("en-UK", {
                    hour12: true,
                    hour: "2-digit",
                    minute: "2-digit",
                    second: undefined,
                  })}
                </td>
                <td>
                  {getTag(item.cell.data.tag!)}
                  {item.cell.data.afterMealTag !== undefined
                    ? ` - ${getAfterMealTag(item.cell.data.afterMealTag)}`
                    : ``}
                </td>
                <td>{item.cell.data.glucoseMGPerDL} mg/dl</td>
              </tr>
            ) : (
              <tr>
                <td>
                  {Temporal.PlainDate.from(item.date).toLocaleString("en-UK", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  })}
                </td>
                <td>
                  {Temporal.PlainTime.from(item.time).toLocaleString("en-UK", {
                    hour12: true,
                    hour: "2-digit",
                    minute: "2-digit",
                    second: undefined,
                  })}
                </td>
                <td>{item.cell.data.insulinMedication.name}</td>
                <td>{item.cell.data.dose} units</td>
              </tr>
            )}
          </Fragment>
        ))}
      </table>
    </ManualReadingsTableContainer>
  );
};
