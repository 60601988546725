import { Link } from "react-router-dom";
import { EmptyState } from "../../../components/empty-state/empty-state";
import { ContentModuleDTO } from "../../../models/content-module-dtos/content-module-dto";
import { ContentScreenTableContainer } from "../styles/content-reusable-classes";
import { Button } from "../../../styles/classes/reusable-classes";
import { ContentCourseModuleLessonsTable } from "./content-course-module-lessons-table/content-course-module-lessons-table";

type ContentCourseModuleLessonsProps = {
  contentModuleDTO: ContentModuleDTO;
};

export const ContentCourseModuleLessons = (
  props: ContentCourseModuleLessonsProps
) => {
  const { contentModuleDTO } = props;

  if (
    contentModuleDTO.childContentModules === undefined ||
    contentModuleDTO.childContentModules.length === 0
  ) {
    return (
      <ContentScreenTableContainer $childTable>
        <EmptyState
          button={
            <Link
              to={`../courses/course/${contentModuleDTO.parentContentModuleId}/module/${contentModuleDTO.id}/create-new-lesson`}
            >
              <Button flex type="button">
                <span className="material-symbols-outlined">add</span>
                Add Lessons
              </Button>
            </Link>
          }
          hasHeader={false}
          hasSearch={false}
          text="No Lessons for this module"
          title=""
        />
      </ContentScreenTableContainer>
    );
  }

  return (
    <ContentScreenTableContainer $childTable>
      <ContentCourseModuleLessonsTable contentModuleDTO={contentModuleDTO} />
    </ContentScreenTableContainer>
  );
};
