import styled from "styled-components/macro";
import {
  mediumRegularFont,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const ManualReadingsTableContainer = styled.div`
  max-width: 900px;
  width: 100%;
  height: 320px;
  margin: 24px auto 0px;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      position: sticky;
      top: 0px;

      th {
        padding: 8px 24px;
        text-align: left;
        ${regularMediumFont}
        color: ${({ theme }) => theme.colors.titleColor};
      }
    }

    tr {
      background: ${({ theme }) => theme.colors.containerColor};

      :nth-child(odd) {
        background: ${({ theme }) => theme.colors.bodyColor};
      }

      td {
        padding: 8px 24px;
        ${mediumRegularFont}
        color: ${({ theme }) => theme.colors.titleColor};
      }
    }
  }
`;
