import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  flexCenterSpaceBetween,
  mediumFont,
  regularRegularFont,
} from "../../../styles/classes/gloabl-classes";
import { FoodLoggingHeaderHeight } from "../constants";
import { NavbarHeight } from "../../../styles/global-style";

export const FoodLoggingPatientMealsUL = styled.ul`
  margin: 0px;
  width: 100%;
  height: calc(
    100vh - (${NavbarHeight} + ${FoodLoggingHeaderHeight} + 124px + 28px)
  );
  overflow-y: auto;
  padding-bottom: 60px;
`;

export const FoodLoggingPatientMealsLI = styled.li<{ activeState: boolean }>`
  ${flexCenterSpaceBetween}
  height: 74px;
  border-bottom: 1px solid
    ${({ theme, activeState }) =>
      activeState ? "transparent" : theme.colors.borderColor};
  background: ${({ activeState, theme }) =>
    activeState ? theme.newColors.otidaO2 : theme.colors.containerColor};
  padding: 12px 16px;
  width: 100%;
  cursor: pointer;
`;

export const FoodLoggingPatientMealsLiImageOrVoiceNoteContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  ${flexCenterCenter}
  background: ${({ theme }) => `${theme.colors.primaryColor}11`};

  img {
    width: 50%;
    height: 50%;
  }

  span {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 10px solid ${({ theme }) => theme.colors.titleColor};
  }
`;

export const FoodLoggingPatientMealsLiText = styled.div`
  display: grid;

  p {
    ${regularRegularFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px 0px 4px;
  }

  span {
    ${mediumFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const FoodLoggingPatientMealsLiIndicatorsContainer = styled.div`
  ${flexCenter}
  justify-content: flex-end;
  gap: 8px;
  min-width: 60px;
`;

export const DraftMealIndicator = styled.div<{ show: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ theme, show }) =>
    show ? theme.colors.warning : "transparent"};
`;

export const UserAddedMealIdicator = styled.span`
  color: ${({ theme }) => theme.colors.primaryColorAlt};
  font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
`;
