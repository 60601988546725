import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PatientDTO } from "../../models/patient-dtos/patient-dto";
import { PutPatientDTO } from "../../models/patient-dtos/put-patient-dto";

export function useUpdatePatient(): (
  patientDTO: PatientDTO,
  putPatientDTO: PutPatientDTO
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientDTO, putPatientDTO) => {
      await client.put<void>(
        `${PATIENTS_PATH}/${patientDTO.id}`,
        putPatientDTO
      );
    },
    [client]
  );
}
