import styled from "styled-components/macro";
import {
  flexCenterSpaceAround,
  regularMediumFont,
} from "../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../styles/global-style";

export const FoodLoggingTabsContainer = styled.div`
  padding: 1rem 0.5rem 0rem;
  display: grid;
  justify-content: center;
  margin-bottom: 8px;
  gap: 24px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

  span {
    color: ${({ theme }) => theme.colors.titleColor};
    margin-top: 1px;
  }
`;

export const FoodLoggingTabsNav = styled.nav`
  ${flexCenterSpaceAround}
`;

export const FoodLoggingTabsNavLink = styled.button<{ active: boolean }>`
  margin: 0px;
  padding-bottom: 8px;
  ${regularMediumFont}
  color: ${({ theme, active }) =>
    active ? theme.colors.titleColor : theme.colors.textColor};
  border-bottom: 4px solid
    ${({ theme, active }) =>
      active ? theme.colors.primaryColorAlt : "transparent"};
  cursor: pointer;
  transition: ${transition};
  width: 50%;
  text-align: center;
  background: transparent;
`;
