import styled, { css, keyframes } from "styled-components/macro";
import { flexCenterSpaceBetween } from "../../../../../styles/classes/gloabl-classes";
import { DropdownButton } from "../../../../../components/dropdown/styled-dropdown";

const Scale = keyframes`
  0% {
    transform: scale(1);
    font-size: 14px;
  }

  100% {
    transform: scale(1.2);
    font-size: 24px;
  }
`;

const AnimationCSS = css`
  animation: ${Scale} 0.5s ease;
`;

export const NutritionGoalsHeaderSubContainer = styled.div<{
  dropdownText: string;
}>`
  ${flexCenterSpaceBetween}

  ${DropdownButton} {
    ${({ dropdownText }) => dropdownText === "Custom" && AnimationCSS};
    color: ${({ dropdownText, theme }) =>
      dropdownText === "Custom" && theme.colors.primaryColorAlt};
    font-weight: ${({ dropdownText, theme }) =>
      dropdownText === "Custom" && theme.typography.fontWeight.bold};
  }
`;
