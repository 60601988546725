import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

type SelectedFilter = {
  filterName: string;
  filterValue: string;
};

type SelectedGender = "All" | "Male" | "Female";

type ImmediateAttentionPatientsSelectedFilters = {
  selectedFilters: SelectedFilter[];
  setSelectedFilters: (selectedFilters: SelectedFilter[]) => void;
  selectedGender: SelectedGender;
  setSelectedGender: (selectedGender: SelectedGender) => void;
  maxAgeValue: number;
  setMaxAgeValue: (maxValue: number) => void;
  minAgeValue: number;
  setMinAgeValue: (minAgeValue: number) => void;
  priorityOptions: string[];
  setPriorityOptions: (priorityOptions: string[]) => void;
};

const ImmediateAttentionPatientsSelectedFiltersContext = createContext<
  ImmediateAttentionPatientsSelectedFilters | undefined
>(undefined);

type ImmediateAttentionPatientsSelectedFiltersProviderProps = PropsWithChildren<{}>;

export const ImmediateAttentionPatientsSelectedFiltersProvider = (
  props: ImmediateAttentionPatientsSelectedFiltersProviderProps
) => {
  const { children } = props;

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);

  const [selectedGender, setSelectedGender] = useState<SelectedGender>("All");

  const [minAgeValue, setMinAgeValue] = useState<number>(0);
  const [maxAgeValue, setMaxAgeValue] = useState<number>(100);

  const [priorityOptions, setPriorityOptions] = useState<string[]>([]);

  const value = useMemo(
    () => ({
      selectedFilters,
      setSelectedFilters,
      selectedGender,
      setSelectedGender,
      maxAgeValue,
      setMaxAgeValue,
      minAgeValue,
      setMinAgeValue,
      priorityOptions,
      setPriorityOptions,
    }),
    [
      selectedFilters,
      setSelectedFilters,
      selectedGender,
      setSelectedGender,
      maxAgeValue,
      setMaxAgeValue,
      minAgeValue,
      setMinAgeValue,
      priorityOptions,
      setPriorityOptions,
    ]
  );

  return (
    <ImmediateAttentionPatientsSelectedFiltersContext.Provider value={value}>
      {children}
    </ImmediateAttentionPatientsSelectedFiltersContext.Provider>
  );
};

export const useImmediateAttentionPatientsSelectedFilters = () => {
  const context = useContext(ImmediateAttentionPatientsSelectedFiltersContext);

  if (context === undefined) {
    throw new Error(
      "useImmediateAttentionPatientsSelectedFilters must be used within a ImmediateAttentionPatientsSelectedFiltersProvider"
    );
  }

  return context;
};
