import { useCallback, useState } from "react";
import {
  MealsAnalysisResponseHeaderCarbsourcesFilterClearIcon,
  MealsAnalysisResponseHeaderCarbsourcesFilterContainer,
} from "./styled-meals-analysis-response-header-carbsources-filter";
import { useCarbsourcesFilter } from "../../context/meals-analysis-response-carbsources-filter-context";
import { AutoSelect } from "../../../../../../../components/auto-select/auto-select";

export const MealsAnalysisResponseHeaderCarbsourcesFilter = () => {
  const {
    onCarbSourceChange,
    carbSourceDTO,
    mealsCarbsourceDTOs,
  } = useCarbsourcesFilter();

  const [searchTerm, setSearchTerm] = useState("");

  const findCarbsourceDTOByName = useCallback(
    (value: string) =>
      mealsCarbsourceDTOs.find((item) => item.carbSource.name === value),
    [mealsCarbsourceDTOs]
  );

  return (
    <MealsAnalysisResponseHeaderCarbsourcesFilterContainer>
      <AutoSelect
        itemsArray={mealsCarbsourceDTOs.map(
          (carbSourceDTO) => carbSourceDTO.carbSource.name
        )}
        onChange={(value: string) => {
          // This value should never be changed with undefined !
          onCarbSourceChange(findCarbsourceDTOByName(value)?.carbSource);
          setSearchTerm(value);
        }}
        value={searchTerm}
        fullWidth
        placeHolder="Choose a Carbsource"
      />
      <MealsAnalysisResponseHeaderCarbsourcesFilterClearIcon
        $disabled={carbSourceDTO === undefined}
        className="material-symbols-outlined"
        onClick={() => {
          onCarbSourceChange(undefined);
          setSearchTerm("");
        }}
      >
        close
      </MealsAnalysisResponseHeaderCarbsourcesFilterClearIcon>
    </MealsAnalysisResponseHeaderCarbsourcesFilterContainer>
  );
};
