import { useCallback, useMemo } from "react";
import { TargetAudienceDTO } from "../../../../../models/target-audience-dtos/target-audience-dto";
import { SearchForTargetAudienceListItem } from "./search-for-target-audience-list-item/search-for-target-audience-list-item";
import {
  TargetAudienceAutoSelectListItem,
  TargetAudienceAutoSelectListList,
} from "./styled-target-audience-autoselect-list";
import { CreateNewTargetAudienceListItem } from "./create-new-target-audience-list-item";
import {
  useContentModulesTargetAudience,
  useReloadContentModulesTargetAudience,
} from "../../../context/loadable-content-modules-target-audience-context";
import { useTargetAudienceSearchTerm } from "../context/target-audience-search-term-context";

type TargetAudienceAutoselectListProps = {
  showDropdown: boolean;
  targetAudienceWatcher: TargetAudienceDTO[];
  handleAppend: (targetAudienceDTO: TargetAudienceDTO) => void;
};

export const TargetAudienceAutoselectList = (
  props: TargetAudienceAutoselectListProps
) => {
  const { showDropdown, targetAudienceWatcher, handleAppend } = props;

  const { resetSearchTerm, searchTerm } = useTargetAudienceSearchTerm();

  const reloadContentModulesTargetAudience = useReloadContentModulesTargetAudience();

  const onAppendHandler = useCallback(
    (targetAudienceDTO: TargetAudienceDTO) => {
      handleAppend(targetAudienceDTO);
      resetSearchTerm();
      reloadContentModulesTargetAudience();
    },
    [resetSearchTerm, handleAppend, reloadContentModulesTargetAudience]
  );

  const { targetAudienceDTOs } = useContentModulesTargetAudience();

  const filterAppendedTargetAudience = useMemo(() => {
    const appendedTargetAudience: TargetAudienceDTO[] = [];

    for (const targetAudienceItem of targetAudienceDTOs) {
      if (
        !targetAudienceWatcher.some((item) => item.id === targetAudienceItem.id)
      ) {
        appendedTargetAudience.push(targetAudienceItem);
      }
    }

    return appendedTargetAudience;
  }, [targetAudienceDTOs, targetAudienceWatcher]);

  const searchedTargetAudience = useMemo(
    () =>
      filterAppendedTargetAudience.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [filterAppendedTargetAudience, searchTerm]
  );

  const searchForTargetAudienceListItem = <SearchForTargetAudienceListItem />;

  const mappedTargetAudience = useMemo(
    () =>
      searchedTargetAudience.map((item) => (
        <TargetAudienceAutoSelectListItem
          key={item.id}
          onClick={() => onAppendHandler(item)}
        >
          {item.name}
        </TargetAudienceAutoSelectListItem>
      )),
    [searchedTargetAudience, onAppendHandler]
  );

  const createNewTargetAudienceListItem = (
    <CreateNewTargetAudienceListItem handleAppend={onAppendHandler} />
  );

  if (searchedTargetAudience.length === 0 && searchTerm !== "") {
    return (
      <TargetAudienceAutoSelectListList $showDropdown={showDropdown}>
        {searchForTargetAudienceListItem}
        {createNewTargetAudienceListItem}
      </TargetAudienceAutoSelectListList>
    );
  }

  return (
    <TargetAudienceAutoSelectListList $showDropdown={showDropdown}>
      {searchForTargetAudienceListItem}
      {mappedTargetAudience}
      {createNewTargetAudienceListItem}
    </TargetAudienceAutoSelectListList>
  );
};
