import { ImmediateAttentionPatientsCards } from "./immediate-attention-patients-cards/immediate-attention-patients-cards";
import { ImmediateAttentionPatientsFiltersSidebarProvider } from "./context/immediate-attention-patients-filters-sidebar-context";
import { ImmediateAttentionPatientsSelectedRowsRowsProvider } from "./context/immediate-attention-patients-selected-rows-context";
import { ImmediateAttentionPatientsSidebarProvider } from "./context/immediate-attention-patients-sidebar-context";
import { ImmediateAttentionPatientsSidebar } from "./immediate-attention-patients-sidebar/immediate-attention-patients-sidebar";
import { ImmediateAttentionPatientsTable } from "./immediate-attention-patients-table/immediate-attention-patients-table";
import { ImmediateAttentionPatientsFiltersSidebar } from "./immediate-attention-patients-table/immediate-attention-patients-table-header/immediate-attention-patients-table-filters/immediate-attention-patients-filters-sidebar/immediate-attention-patients-filters-sidebar";
import { ImmediateAttentionPatientsSelectedFiltersProvider } from "./context/immediate-attention-patients-selected-filters-context";

export const ImmediateAttentionPatients = (props: {
  allPatinets?: boolean;
}) => {
  const { allPatinets } = props;

  return (
    <ImmediateAttentionPatientsFiltersSidebarProvider>
      <ImmediateAttentionPatientsSidebarProvider>
        <ImmediateAttentionPatientsSelectedRowsRowsProvider>
          <ImmediateAttentionPatientsSelectedFiltersProvider>
            <div style={{ overflow: "hidden" }}>
              {!allPatinets && <ImmediateAttentionPatientsCards />}
              <ImmediateAttentionPatientsTable allPatinets={allPatinets} />
              <ImmediateAttentionPatientsSidebar />
              <ImmediateAttentionPatientsFiltersSidebar />
            </div>
          </ImmediateAttentionPatientsSelectedFiltersProvider>
        </ImmediateAttentionPatientsSelectedRowsRowsProvider>
      </ImmediateAttentionPatientsSidebarProvider>
    </ImmediateAttentionPatientsFiltersSidebarProvider>
  );
};
