import { DeleteModal } from "../../../../../../../../components/delete-modal/delete-modal";
import { GenericActionsListItem } from "../../../../../../../../components/generic-actions-list/generic-actions-list-item";
import { Modal } from "../../../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../../../components/modal/use-modal-helpers";

type DeleteMedicineProps = {
  currentMedicationName: string;
  handleDeleteMedicine: () => void;
};

export const DeleteMedicine = (props: DeleteMedicineProps) => {
  const { currentMedicationName, handleDeleteMedicine } = props;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  function onSubmit() {
    handleDeleteMedicine();
  }

  return (
    <>
      <GenericActionsListItem
        icon={<img src="/img/prescription/delete.svg" alt="Delete Icon" />}
        text="Delete"
        onClick={openModal}
      />
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <DeleteModal
          key={modalBodyKey}
          onModalClose={closeModal}
          title={currentMedicationName}
          onDelete={onSubmit}
        />
      </Modal>
    </>
  );
};
