import { useCallback } from "react";
import { useModalHelpers } from "../../../../../components/modal/use-modal-helpers";
import { useToastService } from "../../../../../context/toast-service-context";
import { useDeleteContentModule } from "../../../../../hooks/content-module-hooks/use-delete-content-module";
import { ContentModuleDTO } from "../../../../../models/content-module-dtos/content-module-dto";
import { useReloadContent } from "../../../context/loadable-content-context";
import { GenericActionsListItemItem } from "../../../../../components/generic-actions-list/styled-generic-actions-list";
import { DeleteModal } from "../../../../../components/delete-modal/delete-modal";
import { Modal } from "../../../../../components/modal/modal";

type DeleteConentCourseModuleLessonProps = {
  conentModuleLesson: ContentModuleDTO;
};

export const DeleteConentCourseModuleLesson = (
  props: DeleteConentCourseModuleLessonProps
) => {
  const { conentModuleLesson } = props;

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  const deleteContentModule = useDeleteContentModule();
  const { showToast } = useToastService();
  const reloadCourses = useReloadContent();

  const doesLessonHasChildren =
    conentModuleLesson.contentModuleCards.length > 0;

  const onDelete = useCallback(async () => {
    try {
      if (!doesLessonHasChildren) {
        await deleteContentModule(conentModuleLesson.id);

        showToast("Success", "Lesson Deleted Successfully!");
        closeModal();
        reloadCourses();
      }
    } catch (error) {
      if (
        error instanceof Error &&
        error.message === "Request failed with status code 409"
      ) {
        showToast("Error", "Failed to Delete Lesson, Delete It's Cards First");
      } else {
        showToast("Error", "Failed to Delete Lesson :(");
      }
    }
  }, [
    conentModuleLesson.id,
    closeModal,
    deleteContentModule,
    reloadCourses,
    showToast,
    doesLessonHasChildren,
  ]);

  return (
    <>
      <GenericActionsListItemItem isDanger onClick={openModal}>
        Delete
      </GenericActionsListItemItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <DeleteModal
          key={modalBodyKey}
          title={`Module ${conentModuleLesson.name}`}
          onDelete={onDelete}
          onModalClose={closeModal}
          disableDelete={doesLessonHasChildren}
          subMessage={
            doesLessonHasChildren
              ? `Unable to delete Lesson, delete it's cards first`
              : ``
          }
        />
      </Modal>
    </>
  );
};
