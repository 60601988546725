import { Outlet } from "react-router-dom";
import { ViewPlansScreen } from "./styled-view-plans";

export const ViewPlans = () => {
  return (
    <ViewPlansScreen>
      <Outlet />
    </ViewPlansScreen>
  );
};
