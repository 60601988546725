import { useMemo } from "react";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { useCGMReportLoadedResponse } from "../../context/cgm-report-loaded-response-context";
import {
  CGMReportResponseChartsBody,
  CGMReportResponseChartsCard,
  CGMReportResponseChartsContainer,
  CGMReportResponseChartsHeader,
} from "./styled-cgm-report-response-charts";
import { CGMReportResponseChart } from "./cgm-report-response-chart/cgm-report-response-chart";

export const CGMReportResponceCharts = () => {
  const { groupCgmEntriesByDate } = useCGMReportLoadedResponse();

  const maxYAxisValue = useMemo(() => {
    return Math.max(
      ...groupCgmEntriesByDate
        .map((entry) => entry.readings)
        .flat()
        .map((glucoseValue) => glucoseValue.glucoseMGPerDL)
    );
  }, [groupCgmEntriesByDate]);

  if (groupCgmEntriesByDate.length === 0) {
    return (
      <CGMReportResponseChartsContainer>
        <CGMReportResponseChartsCard>
          <CGMReportResponseChartsHeader>
            <p>Daily Glucose Profiles</p>
          </CGMReportResponseChartsHeader>
          <CGMReportResponseChartsBody $isEmpty>
            <EmptyState
              button={<></>}
              hasHeader={false}
              hasSearch={false}
              text="No Data Within the selected dates"
              title=""
            />
          </CGMReportResponseChartsBody>
        </CGMReportResponseChartsCard>
      </CGMReportResponseChartsContainer>
    );
  }

  return (
    <CGMReportResponseChartsContainer>
      <CGMReportResponseChartsCard>
        <CGMReportResponseChartsHeader>
          <p>Daily Glucose Profiles</p>
        </CGMReportResponseChartsHeader>
        <CGMReportResponseChartsBody $isEmpty={false}>
          {groupCgmEntriesByDate.map((entry, index) => (
            <CGMReportResponseChart
              key={index}
              date={entry.date}
              patientCGMEntryDTOs={entry.readings}
              showWeekDays={index <= 6}
              showYAxisValues={index % 7 === 0}
              maxYAxisValue={maxYAxisValue > 200 ? maxYAxisValue + 50 : 250}
            />
          ))}
        </CGMReportResponseChartsBody>
      </CGMReportResponseChartsCard>
    </CGMReportResponseChartsContainer>
  );
};
