import { NutritionGoalsBody } from "./nutrition-goals-body/nutrition-goals-body";
import { NutritionGoalsFooter } from "./nutrition-goals-footer/nutrition-goals-footer";
import { DietitianReportTabHeader } from "../components/dietitian-report-header/dietitian-report-header";
import { NutritionGoalsHeader } from "./nutrition-goals-header/nutrition-goals-header";
import { DietitianReportTabContainer } from "../components/reusable-styles";
import { FormProvider, useForm } from "react-hook-form";
import { CreateNutritionRegimenNutritionRegimenFoodGroupDTO } from "../../../../models/patient-nutrition-regimen-dtos/create-nutrition-regimen-nutrition-regimen-food-group-dto";
import { PutNutritionRegimenNutritionRegimenFoodGroupDTO } from "../../../../models/patient-nutrition-regimen-dtos/put-nutrition-regimen-nutrition-regimen-food-group-dto";
import { useNutritionGolasLastFoodGroupSelected } from "./context/nutrition-goals-last-food-group-selected-context";
import { useNutritionGoals } from "./context/loadable-nutrition-goals-context";

export type NutritionGoalsInput = {
  nutritionRegimenId?: number;
  nutritionRegimenFoodGroups: CreateNutritionRegimenNutritionRegimenFoodGroupDTO[];
  updateNutritionRegimenFoodGroups: PutNutritionRegimenNutritionRegimenFoodGroupDTO[];
};

export type NutritionGoalsFormState =
  | "Disabled"
  | "Update"
  | "Create Custom"
  | "Update Custom";

export const NutritionGoalsLoading = () => {
  const { activeNutritionRegimen } = useNutritionGoals();

  const { lastFoodGroupSelected } = useNutritionGolasLastFoodGroupSelected();

  const methods = useForm<NutritionGoalsInput>({
    defaultValues: {
      nutritionRegimenId: undefined,
      nutritionRegimenFoodGroups:
        lastFoodGroupSelected?.nutritionRegimenFoodGroups.map((item) => ({
          dailyServingsCount: item.dailyServingsCount,
          foodGroupId: item.foodGroupId,
        })),
      updateNutritionRegimenFoodGroups:
        activeNutritionRegimen !== undefined &&
        activeNutritionRegimen.nutritionRegimen !== undefined &&
        activeNutritionRegimen.nutritionRegimen.customForPatientId !== undefined
          ? activeNutritionRegimen.nutritionRegimen.nutritionRegimenFoodGroups.map(
              (item) => ({
                dailyServingsCount: item.dailyServingsCount,
                foodGroupId: item.foodGroupId,
              })
            )
          : undefined,
    },
  });

  return (
    <FormProvider {...methods}>
      <DietitianReportTabContainer>
        <DietitianReportTabHeader
          icon="/img/plans/nav/goals.svg"
          title="Nutrition Goals"
          subTitle="Add nutrition item for you patient"
        />
        <NutritionGoalsHeader />
        <NutritionGoalsBody />
        <NutritionGoalsFooter />
      </DietitianReportTabContainer>
    </FormProvider>
  );
};
