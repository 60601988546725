import { useMemo } from "react";
import { PatientCGMEntryDTO } from "../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import {
  getAfterMealTag,
  getTag,
} from "../../screens/single-patient/bgl-analysis/upload-options/manual-readings/manual-readings-modal/manual-readings-table/tag-dropdown/tag-dropdown-utils";

export type FilteredPatientDataByDate = {
  dateTime: Date;
  id?: number | undefined;
  date: string;
  time: string;
  source: "Libreview" | "Manual";
  recordType?: "HistoricGlucose" | "ScanGlucose" | undefined;
  tag?: string | undefined;
  afterMealTag?: string | undefined;
  glucoseMGPerDL: number;
  patientId: number;
};

type FilterPatientCGMEntries = {
  filteredPatientDataByDate: FilteredPatientDataByDate[];
  filteredByTags: PatientCGMEntryDTO[];
  afterMealTags: FilteredPatientDataByDate[];
};

export const useFilterPatientCGMEntries = (
  startDate: string,
  endDate: string,
  tagValue: string,
  patientCGMEntriens: PatientCGMEntryDTO[]
): FilterPatientCGMEntries => {
  const filteredPatientDataByDate: FilteredPatientDataByDate[] = useMemo(
    () =>
      patientCGMEntriens
        .filter(
          (filteredItem) =>
            filteredItem.date.toString() >= startDate &&
            filteredItem.date.toString() <= endDate
        )
        .map((item) => {
          const jsDate = new Date(item.date);

          const year = jsDate.getFullYear();
          const month = jsDate.getMonth() + 1;

          let realMonth = ``;
          if (month >= 0 && month <= 9) {
            realMonth = `0${month.toString()}`;
          } else {
            realMonth = month.toString();
          }

          const day = jsDate.getDate();
          let realDay = ``;
          if (day >= 0 && day <= 9) {
            realDay = `0${day.toString()}`;
          } else {
            realDay = day.toString();
          }

          const realDate = `${year}-${realMonth}-${realDay}`;

          return {
            ...item,
            dateTime: new Date(`${realDate}T${item.time}`),
          };
        }),
    [startDate, endDate, patientCGMEntriens]
  );

  const filteredByTags = useMemo(
    () =>
      filteredPatientDataByDate.filter((item) => {
        if (getTag(item.tag!) === "After Meal") {
          return (
            tagValue.includes(getTag(item.tag!)!) &&
            tagValue.includes(getAfterMealTag(item.afterMealTag!)!)
          );
        } else {
          return tagValue.includes(getTag(item.tag!)!);
        }
      }),
    [filteredPatientDataByDate, tagValue]
  );

  const afterMealTags =
    tagValue === "After Meal"
      ? filteredPatientDataByDate.filter((item) => item.tag === "afterMeal")
      : [];

  return {
    filteredPatientDataByDate,
    filteredByTags,
    afterMealTags,
  };
};
