import { useCallback, useState } from "react";
import { useChatContext } from "stream-chat-react";
import { AddNewChannelModalContainer } from "./styled-add-new-channel-modal";
import { ChannelData, UserResponse } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useToastService } from "../../../../../../../../../context/toast-service-context";
import { randomUUID } from "../../../../../../../../../utils/random-uuid";
import { useEnterKeypress } from "../../../../../../../../../hooks/use-enter-keypress/use-enter-keypress";
import { ModalForm } from "../../../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  Input,
  InputContainer,
  InputLabel,
  ModalFooter,
} from "../../../../../../../../../styles/classes/reusable-classes";
import { AutoSelect } from "../../../../../../../../../components/auto-select/auto-select";
import {
  ChannelModalAutoSelectContainer,
  ChannelModalMembersContainer,
  ChannelModalTagList,
  ChannelModalTagListItem,
} from "../../../../../../styled-chat-home";
import { useChatQueryUsers } from "../../../../../../../chat-providers/chat-query-users-context";

type AddNewChannelModalProps = {
  onClose: () => void;
  closeSidebar: () => void;
};

type AddNewChannelInputs = {
  channelName?: string;
  channelUsers?: UserResponse<DefaultStreamChatGenerics>[];
};

export const AddNewChannelModal = (props: AddNewChannelModalProps) => {
  const { onClose, closeSidebar } = props;

  const { control, watch, handleSubmit } = useForm<AddNewChannelInputs>({
    defaultValues: {
      channelName: undefined,
      channelUsers: undefined,
    },
  });

  const { client, setActiveChannel } = useChatContext();

  const { users } = useChatQueryUsers();

  const findUserById = useCallback(
    (userId: string) => users.find((item) => item.id === userId),
    [users]
  );

  const findUserByValue = useCallback(
    (value: string) =>
      users.find((item) =>
        value === item.name ? item.name === value : item.id === value
      ),
    [users]
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [autoSelectTagValue, setAutoSelectTagValue] = useState<
    UserResponse<DefaultStreamChatGenerics> | undefined
  >();

  const { append, fields, remove } = useFieldArray({
    name: "channelUsers",
    control,
  } as never);

  const channelUsersWatcher = watch("channelUsers");

  const handleAppend = () => {
    if (
      channelUsersWatcher !== undefined &&
      autoSelectTagValue !== undefined &&
      !channelUsersWatcher.some((obj) => obj.id === autoSelectTagValue.id) &&
      users.some((obj) => obj.id === autoSelectTagValue?.id)
    ) {
      append(autoSelectTagValue);
      setAutoSelectTagValue(undefined);
      setSearchTerm("");
    }
  };

  const { showToast } = useToastService();

  const onSubmit = handleSubmit(async (inputs) => {
    const selectedUsersIds = inputs.channelUsers?.map((u) => u.id)!;

    const channelData: ChannelData<DefaultStreamChatGenerics> = {
      name: inputs.channelName!,
      members: [...selectedUsersIds, client.userID!],
    };

    try {
      if (
        client &&
        client.user !== undefined &&
        channelUsersWatcher !== undefined
      ) {
        const conversation = client.channel(
          "messaging",
          randomUUID(),
          channelData
        );

        await conversation.watch();

        setActiveChannel?.(conversation);
        showToast("Success", "Chat Created successfully!");
        onClose();
        closeSidebar();
      }
    } catch (error) {
      showToast("Error", "Failed to Create Chat");
    }
  });

  const { onEnterKeyPress } = useEnterKeypress(handleAppend);

  return (
    <ModalForm onSubmit={onSubmit} height={700} width={520}>
      <ModalHeader title="Create new group" onModalClose={onClose} />
      <AddNewChannelModalContainer>
        <InputContainer>
          <InputLabel>Channel Name</InputLabel>
          <Controller
            control={control}
            name="channelName"
            render={({ field: { value, onChange } }) => (
              <Input
                type="text"
                placeholder="Enter Channel Name"
                value={value !== undefined ? value : ""}
                onChange={(e) =>
                  onChange(e.target.value !== undefined ? e.target.value : "")
                }
              />
            )}
          />
        </InputContainer>
        <ChannelModalMembersContainer>
          <InputLabel>Choose Members</InputLabel>
          <Controller
            control={control}
            name="channelUsers"
            render={() => (
              <ChannelModalAutoSelectContainer>
                <AutoSelect
                  placeHolder="Add Users"
                  itemsArray={users
                    .filter((user) => user.id !== client.userID)
                    .filter((item) =>
                      item.name !== undefined
                        ? item.name
                            ?.toLocaleLowerCase()
                            .includes(searchTerm.toLocaleLowerCase())
                        : item.id
                            .toLocaleLowerCase()
                            .includes(searchTerm.toLocaleLowerCase())
                    )
                    .map((user) => {
                      const userWhatsAppId =
                        user.whatsAppId !== undefined
                          ? ` (${user.whatsAppId})`
                          : "";

                      return user.name !== undefined
                        ? `${user.name}${userWhatsAppId}`
                        : `${user.id}${userWhatsAppId}`;
                    })}
                  onChange={(value: string) => {
                    // This value should never be changed with undefined !
                    const indexOfBracket = value.indexOf("(");
                    const valueWithoutWhatsappId = value.includes("(")
                      ? value.slice(0, indexOfBracket - 1)
                      : value;
                    setAutoSelectTagValue(
                      findUserByValue(valueWithoutWhatsappId)
                    );
                    setSearchTerm(value);
                  }}
                  value={searchTerm}
                  fullWidth
                  disabled={users.length === 0}
                />
                <Button
                  type="button"
                  onClick={handleAppend}
                  onKeyDown={(e) => onEnterKeyPress(e)}
                  width={100}
                  height={40}
                  flex
                  outlineNoBorder
                >
                  <span className="material-symbols-outlined">add</span> Add
                </Button>
              </ChannelModalAutoSelectContainer>
            )}
          />
          <ChannelModalTagList>
            {fields.map((field, index) => (
              <ChannelModalTagListItem key={field.id}>
                {channelUsersWatcher !== undefined
                  ? findUserById(channelUsersWatcher[index].id)?.name !==
                    undefined
                    ? findUserById(channelUsersWatcher[index].id)?.name
                    : findUserById(channelUsersWatcher[index].id)?.id!
                  : ""}
                <span
                  className="material-symbols-outlined"
                  onClick={() => remove(index)}
                >
                  close
                </span>
              </ChannelModalTagListItem>
            ))}
          </ChannelModalTagList>
        </ChannelModalMembersContainer>
      </AddNewChannelModalContainer>
      <ModalFooter>
        <Button type="button" outlined onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit">Create</Button>
      </ModalFooter>
    </ModalForm>
  );
};
