export const EMERGENCIES = [
  "AnginaPectoris",
  "Stroke",
  "DiabeticKetoacidosis",
  "Hypoglycemia",
] as const;

export type Emergency = typeof EMERGENCIES[number];

export const DIABETES_EMERGENCIES = [
  "DiabeticKetoacidosis",
  "Hypoglycemia",
] as const;

export type DiabetesEmergency = typeof DIABETES_EMERGENCIES[number];
