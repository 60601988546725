import { useState } from "react";
import { useImmediateAttentionPatientsSelectedRowsRows } from "../../context/immediate-attention-patients-selected-rows-context";
import {
  ImmediateAttentionPatientsSelectedActionsContainer,
  ImmediateAttentionPatientsSelectedIconsContainer,
  ImmediateAttentionPatientsSelectedRadioButtonContainer,
  ImmediateAttentionPatientsSelectedTextContainer,
} from "./styled-immediate-attention-patients-selected-actions";
import { RadioButton } from "../../../../../styles/classes/reusable-classes";

export const ImmediateAttentionPatientsSelectedActions = () => {
  const { selectedRows } = useImmediateAttentionPatientsSelectedRowsRows();

  const [showOnlySelected, setShowOnlySelected] = useState(false);

  return (
    <ImmediateAttentionPatientsSelectedActionsContainer
      $showContainer={selectedRows.length > 0}
    >
      <ImmediateAttentionPatientsSelectedTextContainer>
        <p>{selectedRows.length} selected</p>
      </ImmediateAttentionPatientsSelectedTextContainer>
      <ImmediateAttentionPatientsSelectedIconsContainer>
        <img src="/img/all-patients/actions-icons/edit.png" alt="Note" />
        <img src="/img/all-patients/actions-icons/care-giver.png" alt="" />
        <img src="/img/all-patients/actions-icons/calendar.png" alt="" />
      </ImmediateAttentionPatientsSelectedIconsContainer>
      <ImmediateAttentionPatientsSelectedRadioButtonContainer
        $checked={showOnlySelected}
      >
        <RadioButton
          checked={showOnlySelected}
          onClick={() => setShowOnlySelected(!showOnlySelected)}
        >
          <span></span>
        </RadioButton>
        <p>Show only selected</p>
      </ImmediateAttentionPatientsSelectedRadioButtonContainer>
    </ImmediateAttentionPatientsSelectedActionsContainer>
  );
};
