import styled from "styled-components/macro";

export const LabResultIndiactor = styled.div<{
  state?: "Normal" | "Above" | "Bellow";
}>`
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
  background: ${({ state, theme }) =>
    state === "Normal"
      ? theme.colors.success
      : state === "Above"
      ? theme.colors.danger
      : state === "Bellow"
      ? theme.newColors.warningAlt
      : "transparent"};
`;
