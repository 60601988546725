import { PropsWithChildren, createContext, useContext } from "react";
import { UseLoadableDataReturn } from "../../../../hooks/use-loadable-data";
import { PatientDTO } from "../../../../models/patient-dtos/patient-dto";
import { PatientWeightDTO } from "../../../../models/patient-weight-dtos/patient-weight-dto";
import { PatientBloodPressureDTO } from "../../../../models/patient-blood-pressure-dtos/patient-blood-pressure-dto";

export type PatientProfile = {
  patientDTO: PatientDTO;
  patientWeightDTOs: PatientWeightDTO[];
  patientBloodPressureDTOs: PatientBloodPressureDTO[];
};

export type LoadablePatientProfile = UseLoadableDataReturn<PatientProfile>;

const LoadablePatientProfileContext = createContext<
  LoadablePatientProfile | undefined
>(undefined);

export type LoadablePatientProfileProviderProps = PropsWithChildren<{
  loadablePatientProfile: LoadablePatientProfile;
}>;

export function LoadablePatientProfileProvider(
  props: LoadablePatientProfileProviderProps
) {
  const { loadablePatientProfile, children } = props;

  return (
    <LoadablePatientProfileContext.Provider value={loadablePatientProfile}>
      {children}
    </LoadablePatientProfileContext.Provider>
  );
}

export function useLoadablePatientProfile(): LoadablePatientProfile {
  const loadablePatientProfile = useContext(LoadablePatientProfileContext);

  if (loadablePatientProfile === undefined) {
    throw Error(
      "useLoadablePatientProfile must be used within an LoadablePatientProfileProvider"
    );
  }

  return loadablePatientProfile;
}

export function usePatientProfile(): PatientProfile {
  const loadablePatientProfile = useLoadablePatientProfile();

  if (loadablePatientProfile.state.data === undefined) {
    throw Error("Patient Profile have never been loaded successfully yet.");
  }

  return loadablePatientProfile.state.data;
}

export function useReloadPatientProfile(): () => void {
  const loadablePatientProfile = useLoadablePatientProfile();

  return loadablePatientProfile.reload;
}
