import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { NutritionRegimenDTO } from "../../models/nutrition-regimen-dtos/nutrition-regimen-dto";
import { PutNutritionRegimenDTO } from "../../models/patient-nutrition-regimen-dtos/put-nutrition-regimen-dto";

export function usePutNutritionRegimen(): (
  nutritionRegimenDTO: NutritionRegimenDTO,
  putNutritionRegimenDTO: PutNutritionRegimenDTO
) => Promise<NutritionRegimenDTO> {
  const client = useClient();

  return useCallback(
    async (nutritionRegimenDTO, putNutritionRegimenDTO) => {
      const response = await client.put<NutritionRegimenDTO>(
        `${PATIENTS_PATH}/${nutritionRegimenDTO.customForPatientId!}/nutrition-regimens/${
          nutritionRegimenDTO.id
        }`,
        putNutritionRegimenDTO
      );

      return response.data;
    },
    [client]
  );
}
