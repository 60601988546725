import {
  MedicationCardImageContainer,
  MedicationCardText,
  MedicationCardTitle,
} from "../../../../../single-patient/medications/components/medication-card/styled-medication-card";
import {
  MedicationContainer,
  MedicationTD,
  StatusContainer,
} from "../../../../../single-patient/medications/medications-table/styled-medications-table";
import { ImmediateAttentionPatientsSidebarMedicationsTabContainer } from "./styled-immediate-attention-patients-sidebar-medications-tab";

export const ImmediateAttentionPatientsSidebarMedicationsTab = () => {
  return (
    <ImmediateAttentionPatientsSidebarMedicationsTabContainer>
      <table>
        <caption>Last 14 days</caption>
        <thead>
          <tr>
            <th>
              <div>Name</div>
            </th>
            <th>
              <div>Adherent Level</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <MedicationTD>
              <MedicationContainer>
                <MedicationCardImageContainer>
                  <img
                    src="/img/all-patients/medications-tab/med-1.png"
                    alt="Lantus"
                  />
                </MedicationCardImageContainer>
                <div>
                  <MedicationCardTitle>Lantus</MedicationCardTitle>
                  <MedicationCardText>
                    100 1.U./ML, S.C,FLEXPEN
                  </MedicationCardText>
                </div>
              </MedicationContainer>
            </MedicationTD>
            <MedicationTD>
              <StatusContainer stopped={false} notStartedYet={false}>
                <p>Adherent</p>
              </StatusContainer>
            </MedicationTD>
          </tr>
          <tr>
            <MedicationTD>
              <MedicationContainer>
                <MedicationCardImageContainer>
                  <img
                    src="/img/all-patients/medications-tab/med-2.png"
                    alt="Glucophage"
                  />
                </MedicationCardImageContainer>
                <div>
                  <MedicationCardTitle>Glucophage</MedicationCardTitle>
                  <MedicationCardText>500 MG, P.O,Tablet</MedicationCardText>
                </div>
              </MedicationContainer>
            </MedicationTD>
            <MedicationTD>
              <StatusContainer
                stopped={false}
                notStartedYet={false}
                style={{ width: 140 }}
              >
                <p style={{ color: "#4c559a", background: "#eaf7ff" }}>
                  Somehow adherent
                </p>
              </StatusContainer>
            </MedicationTD>
          </tr>
          <tr>
            <MedicationTD>
              <MedicationContainer>
                <MedicationCardImageContainer>
                  <img
                    src="/img/all-patients/medications-tab/med-3.png"
                    alt="Orlistat"
                  />
                </MedicationCardImageContainer>
                <div>
                  <MedicationCardTitle>Orlistat</MedicationCardTitle>
                  <MedicationCardText>120 MG, P.O,CAPS</MedicationCardText>
                </div>
              </MedicationContainer>
            </MedicationTD>
            <MedicationTD>
              <StatusContainer
                stopped={false}
                notStartedYet={false}
                style={{ width: 140 }}
              >
                <p style={{ color: "#4c559a", background: "#eaf7ff" }}>
                  Somehow adherent
                </p>
              </StatusContainer>
            </MedicationTD>
          </tr>
          <tr>
            <MedicationTD>
              <MedicationContainer>
                <MedicationCardImageContainer>
                  <img
                    src="/img/all-patients/medications-tab/med-4.png"
                    alt="Vitacid 1000m"
                  />
                </MedicationCardImageContainer>
                <div>
                  <MedicationCardTitle>Osteocare</MedicationCardTitle>
                  <MedicationCardText>P.O,Tablet</MedicationCardText>
                </div>
              </MedicationContainer>
            </MedicationTD>
            <MedicationTD>
              <StatusContainer
                stopped={true}
                notStartedYet={false}
                style={{ width: 140 }}
              >
                <p>Not adherent</p>
              </StatusContainer>
            </MedicationTD>
          </tr>
        </tbody>
      </table>
    </ImmediateAttentionPatientsSidebarMedicationsTabContainer>
  );
};
