import { useNutritionReportSelectedTableRows } from "../../../context/nutrition-report-context/nutrition-report-selected-table-rows-context";
import { useNutritionReportMealTableRow } from "../../../context/nutrition-report-context/nutrition-report-meal-table-row-context";
import {
  NutritionReportTableRowTD,
  NutritionReportTableRowTDCheckboxContainer,
  NutritionReportTableRowTDChevIcon,
  NutritionReportTableRowTDContainer,
} from "./components/styled-nutrition-report-table-cell";
import { useMemo } from "react";

type NutritionReportDateTableCellProps = {
  date: string;
  index: number;
};

export const NutritionReportDateTableCell = (
  props: NutritionReportDateTableCellProps
) => {
  const { date, index } = props;

  const {
    toggleMealTableRow,
    showMealTableRow,
  } = useNutritionReportMealTableRow();

  const {
    handleRowSelect,
    selectedDays,
  } = useNutritionReportSelectedTableRows();

  const selectedDaysIds = useMemo(() => selectedDays.map((item) => item.id), [
    selectedDays,
  ]);

  return (
    <NutritionReportTableRowTD type="Normal" sticky={true}>
      <NutritionReportTableRowTDContainer>
        <NutritionReportTableRowTDCheckboxContainer>
          <input
            type="checkbox"
            checked={selectedDaysIds.includes(index)}
            onChange={() => handleRowSelect(index)}
          />
        </NutritionReportTableRowTDCheckboxContainer>
        <NutritionReportTableRowTDChevIcon
          className="material-symbols-outlined"
          rotate={showMealTableRow.includes(index) ? "true" : "false"}
          onClick={() => toggleMealTableRow(index)}
        >
          chevron_right
        </NutritionReportTableRowTDChevIcon>
        {date}
      </NutritionReportTableRowTDContainer>
    </NutritionReportTableRowTD>
  );
};
