import { ReactElement } from "react";
import { assertNever } from "../utils/assert-never";
import { OtidaLoader } from "./loaders/otida-loader/otida-loader";
import { LoadableDataState } from "../hooks/use-loadable-data";

export type LoadableDataLoadingProps<T> = {
  state: LoadableDataState<T>;
  /**
   * Do NOT call `setState` in this function!
   *
   * This function is called in render of `LoadableDataLoading` component. React warns against calling `setState`
   * of another component in render. I'm not sure why, honestly.
   */
  successComponent: (data: T) => ReactElement;
};

export function LoadableDataLoading<T>(props: LoadableDataLoadingProps<T>) {
  const { state, successComponent } = props;

  if (state.kind === "loading") {
    return <OtidaLoader />;
  } else if (state.kind === "loaded") {
    return successComponent(state.data);
  } else if (state.kind === "errored") {
    return <>Errored. Error: {state.error}.</>;
  } else {
    return assertNever(state);
  }
}
