import { useCallback, useMemo, useState } from "react";
import { PatientActivityLogDTO } from "../../../models/patient-activity-log-dtos/patient-activity-log-dto";
import { buildDaysAggregates } from "../utils/build-days-aggregates";
import { PatientDTO } from "../../../models/patient-dtos/patient-dto";
import { useGetPatientCGMEntryDTOs } from "./use-get-patient-cgm-entry-dtos";
import { useGetPatientServingDTOs } from "./use-get-patient-serving-dtos";
import { useGetPatientMealDTOs } from "./use-get-patient-meal-dtos";
import { useGetPatientActivityLogStatsDTO } from "./use-get-patient-activity-log-stats";
import { useGetPatientInsulinLogDTOs } from "./use-get-patient-insulin-log-dtos";

export const useBuildDayAggregates = (
  patientDTO: PatientDTO,
  feedback?: boolean,
  startDate?: string,
  endDate?: string
) => {
  const {
    patientCGMEntryDTOs,
    isLoaded: patientCGMEntryDTOsIsLoaded,
  } = useGetPatientCGMEntryDTOs(startDate, endDate);
  const {
    patientServingDTOs,
    isLoaded: patientServingDTOsIsLoaded,
  } = useGetPatientServingDTOs();
  const {
    patientMealDTOs,
    isLoaded: patientMealDTOsIsLoaded,
  } = useGetPatientMealDTOs(startDate, endDate);
  const {
    patientActivityLogStatsDTO,
    isLoaded: patientActivityLogStatsDTOIsLoaded,
  } = useGetPatientActivityLogStatsDTO(startDate, endDate);
  const {
    patientInsulinLogDTOs,
    isLoaded: patientInsulinLogDTOsIsLoaded,
  } = useGetPatientInsulinLogDTOs(startDate, endDate);

  const [isLoading, setIsLoading] = useState(false);

  const patientActivityLogs = useMemo((): PatientActivityLogDTO[] => {
    const patientActivityLogDTOs: PatientActivityLogDTO[] = [];

    if (
      patientActivityLogStatsDTO.activityModes !== undefined &&
      patientActivityLogStatsDTO.activityModes.length > 0
    ) {
      for (const PatientActivityLogStatsActivityModeDTO of patientActivityLogStatsDTO.activityModes) {
        for (const patientActivityLogStatsActivityModeDayDTO of PatientActivityLogStatsActivityModeDTO.days) {
          for (const PatientActivityLogDTO of patientActivityLogStatsActivityModeDayDTO.patientActivityLogs) {
            patientActivityLogDTOs.push(PatientActivityLogDTO);
          }
        }
      }
    }

    return patientActivityLogDTOs;
  }, [patientActivityLogStatsDTO]);

  const daysAggregates = useMemo(() => {
    if (
      !patientCGMEntryDTOsIsLoaded ||
      !patientServingDTOsIsLoaded ||
      !patientMealDTOsIsLoaded ||
      !patientActivityLogStatsDTOIsLoaded ||
      !patientInsulinLogDTOsIsLoaded
    ) {
      setIsLoading(true);
      return [];
    }

    setIsLoading(false);

    return buildDaysAggregates(
      patientDTO,
      patientCGMEntryDTOs,
      patientServingDTOs,
      patientMealDTOs,
      patientInsulinLogDTOs,
      feedback === undefined || feedback === false
        ? patientActivityLogs
        : undefined
    );
  }, [
    patientDTO,
    patientCGMEntryDTOs,
    patientServingDTOs,
    patientMealDTOs,
    patientActivityLogs,
    feedback,
    patientInsulinLogDTOs,
    patientCGMEntryDTOsIsLoaded,
    patientServingDTOsIsLoaded,
    patientMealDTOsIsLoaded,
    patientActivityLogStatsDTOIsLoaded,
    patientInsulinLogDTOsIsLoaded,
  ]);

  const filteredDaysAggregatesByDate = useCallback(
    (date: string) =>
      daysAggregates.filter((item) => item.date.toString() === date),
    [daysAggregates]
  );

  const filterDaysAggregatesBetweenStartDateAndEndDate = useCallback(
    (startDate: string, endDate: string) =>
      daysAggregates.filter(
        (item) =>
          item.date.toString() >= startDate && item.date.toString() <= endDate
      ),
    [daysAggregates]
  );

  return {
    daysAggregates,
    filteredDaysAggregatesByDate,
    filterDaysAggregatesBetweenStartDateAndEndDate,
    isLoading,
  };
};
