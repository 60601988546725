import { Controller, useFormContext } from "react-hook-form";
import { PatientProfileEditTimelineModalInputs } from "../../patient-profile-edit-timeline-modal";
import { useRef } from "react";
import {
  PatientProfileModalFormControl,
  PatientProfileModalFormControlFlexNoStyles,
  PatientProfileModalInputContainer,
  PatientProfileModalLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { RadioButton } from "../../../../../../../../styles/classes/reusable-classes";
import { AnginaDateInputContainer } from "../patient-profile-angina-pectoris-form-control/styled-patient-profile-angina-pectoris-form-control";

type PatientProfileStrokeFormControlProps = {
  isStroke: boolean;
  toggleIsStroke: () => void;
};

export const PatientProfileStrokeFormControl = (
  props: PatientProfileStrokeFormControlProps
) => {
  const { isStroke, toggleIsStroke } = props;
  const { control } = useFormContext<PatientProfileEditTimelineModalInputs>();

  const strokeDiagnosedSinceRef = useRef<HTMLInputElement | null>(null);

  return (
    <PatientProfileModalFormControl gridLayout>
      <PatientProfileModalFormControlFlexNoStyles>
        <PatientProfileModalLabelContainer>
          <label>Stroke</label>
        </PatientProfileModalLabelContainer>

        <PatientProfileModalInputContainer>
          <RadioButton
            type="button"
            checked={isStroke}
            onClick={toggleIsStroke}
          >
            <span></span>
          </RadioButton>

          {isStroke ? (
            <AnginaDateInputContainer>
              <PatientProfileModalInputContainer
                hasOneInput
                onClick={() => {
                  strokeDiagnosedSinceRef.current?.showPicker();
                }}
              >
                <span className="material-symbols-outlined">
                  calendar_today
                </span>
                <Controller
                  name="strokeEmergency.diagnosedSince"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { value, onChange } }) => (
                    <input
                      ref={strokeDiagnosedSinceRef}
                      type="month"
                      placeholder=""
                      value={value !== undefined ? value : ""}
                      onChange={(e) =>
                        onChange(
                          e.target.value !== undefined ? e.target.value : ""
                        )
                      }
                    />
                  )}
                />
              </PatientProfileModalInputContainer>
            </AnginaDateInputContainer>
          ) : (
            <></>
          )}
        </PatientProfileModalInputContainer>
      </PatientProfileModalFormControlFlexNoStyles>
    </PatientProfileModalFormControl>
  );
};
