type TimeInterval = { from: string; to: string };

export function timeIntervals(): TimeInterval[] {
  const timeIntervals: TimeInterval[] = [{ from: "23:00:00", to: "01:30:00" }];

  for (let i = 0; i <= 21; i++) {
    const startTime = i >= 0 && i <= 9 ? `0${i}:30:00` : `${i}:30:00`;
    const endTime = i >= 0 && i <= 7 ? `0${i + 2}:30:00` : `${i + 2}:30:00`;
    timeIntervals.push({ from: startTime, to: endTime });
  }

  timeIntervals.push({ from: "22:30:00", to: "01:00:00" });

  return timeIntervals;
}
