import { useState } from "react";
import { useClient } from "../../context/client-context";
import { useAuth } from "../../context/auth-context/auth-context";
import {
  FormHeader,
  LoginContainer,
  LoginFormContainer,
  LoginFormLogoContainer,
} from "../../screens/login/styled-login";
import {
  Button,
  ErrorText,
  Input,
  InputContainer,
  InputLabel,
} from "../../styles/classes/reusable-classes";
import { OtpInputs } from "./otp-inputs/otp-inputs";
import { PatientLoginLoading } from "./patient-login-loading/patient-login-loading";
import {
  InputHardCodedPlusTwo,
  PatientLoginBackIcon,
  PatientLoginButtonContainer,
  PatientLoginContainer,
  PatientLoginInputContainerForHardCoddedPlusTwo,
  PatientLoginSendAgainContainer,
  PatientLoginSubContainer,
  PatientOtpInputsContainerForErrorText,
} from "./styled-patient-login";
import { getLoggedInUser, sendOtp, verifyOtp } from "./pateint-login-utils";

export function PatientLogin() {
  const { setAndPersistUser } = useAuth();

  // This client won't add Authorization header since `user` is undefined when it's used.
  // `getLoggedInUser` will add the Authorization header.
  const client = useClient();

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const onOtpChange = (value: string) => setOtp(value);
  const [showOtp, setShowOtp] = useState(false);

  const [loadingState, setLoadingState] = useState<
    "idle" | "loading" | "error"
  >("idle");

  function handleSendOtp() {
    setLoadingState("loading");
    sendOtp(phoneNumber)
      .then(() => {
        setLoadingState("idle");
      })
      .catch(() => {
        setLoadingState("error");
      });
    setShowOtp(true);
  }

  function handleVerifyOtp() {
    setLoadingState("loading");
    verifyOtp(phoneNumber, otp)
      .then((id_token) => {
        getLoggedInUser(client, id_token)
          .then((userDTO) => {
            setAndPersistUser({
              patientPhoneNumber: phoneNumber,
              patientToken: id_token,
              patientId: userDTO.patientId,
              patientName: userDTO.name,
              patientType: userDTO.type,
            });
            setLoadingState("idle");
          })
          .catch(() => {});
      })
      .catch(() => {
        setLoadingState("error");
      });
  }

  return (
    <LoginContainer>
      <LoginFormContainer>
        <LoginFormLogoContainer>
          <img src="/img/logo.svg" alt="" />
        </LoginFormLogoContainer>
        <PatientLoginContainer>
          <FormHeader>
            <h1>
              {showOtp ? (
                <>
                  <PatientLoginBackIcon
                    className="material-symbols-outlined"
                    onClick={() => setShowOtp(false)}
                  >
                    chevron_left
                  </PatientLoginBackIcon>
                  Verification
                </>
              ) : (
                `Patient login`
              )}
            </h1>
            <p>
              {showOtp
                ? `Enter Verification code sent to your phone number.`
                : `Signin to view your progress`}
            </p>
          </FormHeader>
          {!showOtp && (
            <PatientLoginSubContainer>
              <InputContainer>
                <InputLabel>
                  Enter your phone number{" "}
                  <InputLabel small>(01XXXXXXXXX)</InputLabel>
                </InputLabel>
                <PatientLoginInputContainerForHardCoddedPlusTwo>
                  <InputHardCodedPlusTwo>+2</InputHardCodedPlusTwo>
                  <Input
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </PatientLoginInputContainerForHardCoddedPlusTwo>
                {loadingState === "error" && (
                  <ErrorText>Something went wrong</ErrorText>
                )}
              </InputContainer>
              <PatientLoginButtonContainer>
                <Button
                  height={64}
                  width={220}
                  disabled={loadingState === "loading"}
                  onClick={handleSendOtp}
                  type="button"
                >
                  {loadingState === "loading" ? (
                    <PatientLoginLoading />
                  ) : (
                    "Send OTP"
                  )}
                </Button>
              </PatientLoginButtonContainer>
            </PatientLoginSubContainer>
          )}
          {showOtp && (
            <PatientLoginSubContainer>
              <PatientOtpInputsContainerForErrorText>
                <OtpInputs value={otp} onChange={onOtpChange} valueLength={6} />
                {loadingState === "error" && (
                  <ErrorText>Something went wrong</ErrorText>
                )}
              </PatientOtpInputsContainerForErrorText>
              <PatientLoginSendAgainContainer>
                <p>Didn’t reveive a code?</p>
                <button onClick={handleSendOtp}>Send it again</button>
              </PatientLoginSendAgainContainer>
              <PatientLoginButtonContainer>
                <Button
                  height={64}
                  width={220}
                  disabled={loadingState === "loading"}
                  onClick={handleVerifyOtp}
                  type="button"
                >
                  {loadingState === "loading" ? (
                    <PatientLoginLoading />
                  ) : (
                    "Verify OTP"
                  )}
                </Button>
              </PatientLoginButtonContainer>
            </PatientLoginSubContainer>
          )}
        </PatientLoginContainer>
      </LoginFormContainer>
    </LoginContainer>
  );
}
