import { PatientCGMEntryDTO } from "../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientServingDTO } from "../../models/patient-serving-dto";
import {
  Category,
  ChartComponent,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  StripLine,
  Tooltip,
  TooltipSettingsModel,
} from "@syncfusion/ej2-react-charts";
import { DataLabel, SplineSeries } from "@syncfusion/ej2-react-charts/src";
import { useCallback, useMemo } from "react";
import { getInRangeNumbers } from "../../utils/patient-type-utils";
import { PatientDTO } from "../../models/patient-dtos/patient-dto";
import { Temporal } from "temporal-polyfill";
import { buildPrimaryYAxis } from "./utils/build-primary-y-axis";
import { buildPrimaryXAxis } from "./utils/build-primary-x-axis";
import { buildAxisLabelRender } from "./utils/build-axis-label-render";
import { buildTooltip } from "./utils/build-tooltip";
import { buildReadingsSeries } from "./series/build-readings-series";
import { buildMealsSeries } from "./series/build-meals-series";
import { buildMedicinesSeries } from "./series/build-medicines-series";
import { randomUUID } from "../../utils/random-uuid";

export type GlucoseChartProps = {
  startPlainDateTime: Temporal.PlainDateTime;
  patientDTO: PatientDTO;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
  patientServingDTOs: PatientServingDTO[];
  height?: string;
  hasToolTip: boolean;
  smallModalGraphs: boolean;
};

export function GlucoseChart(props: GlucoseChartProps) {
  const {
    startPlainDateTime,
    patientDTO,
    patientCGMEntryDTOs,
    patientServingDTOs,
    height,
    hasToolTip,
    smallModalGraphs,
  } = props;

  const id = useMemo(() => `glucose-chart-${randomUUID()}`, []);

  const minY = useMemo(
    () =>
      Math.min(
        ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
      ),
    [patientCGMEntryDTOs]
  );

  const maxY = useMemo(() => {
    let maxGlucose = Math.max(
      ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
    );
    return maxGlucose > 250 ? maxGlucose + 25 : 250;
  }, [patientCGMEntryDTOs]);

  const inRangeNumbers = useMemo(
    () => getInRangeNumbers(patientDTO.type),
    [patientDTO]
  );

  const vAxisTicks = useMemo(
    () => [...inRangeNumbers, maxY],
    [inRangeNumbers, maxY]
  );

  const primaryXAxis = useMemo(() => buildPrimaryXAxis(), []);

  const primaryYAxis = useMemo(() => {
    const title = smallModalGraphs ? "" : "Glucose";

    return buildPrimaryYAxis(minY, maxY, inRangeNumbers, title);
  }, [minY, maxY, inRangeNumbers, smallModalGraphs]);

  const axisLabelRender = useMemo(
    () => buildAxisLabelRender(vAxisTicks),
    [vAxisTicks]
  );

  const tooltip: TooltipSettingsModel = useMemo(
    () => buildTooltip(hasToolTip),
    [hasToolTip]
  );

  const readingsSeries = useMemo(
    () => buildReadingsSeries(patientCGMEntryDTOs, startPlainDateTime),
    [patientCGMEntryDTOs, startPlainDateTime]
  );

  const mealsSeries = useMemo(
    () => buildMealsSeries(patientServingDTOs, startPlainDateTime),
    [patientServingDTOs, startPlainDateTime]
  );

  const medicinesSeries = useMemo(
    () => buildMedicinesSeries(patientServingDTOs, startPlainDateTime),
    [patientServingDTOs, startPlainDateTime]
  );

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);
    // Should always enter this condition, but let's be safe
    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  return (
    <ChartComponent
      id={id}
      primaryXAxis={primaryXAxis}
      primaryYAxis={primaryYAxis}
      axisLabelRender={axisLabelRender}
      tooltip={tooltip}
      border={{
        width: 0,
        color: "transparent",
      }}
      height={height}
      loaded={handleChartLoaded}
    >
      <Inject
        services={[SplineSeries, DataLabel, Category, StripLine, Tooltip]}
      />
      <SeriesCollectionDirective>
        <SeriesDirective
          type="Spline"
          dataSource={readingsSeries}
          xName="x"
          yName="y"
          fill="#686868"
          marker={{
            dataLabel: { visible: false, name: "gloc" },
            visible: true,
          }}
          width={3}
          enableTooltip
          tooltipMappingName="tooltip"
        />
        <SeriesDirective
          type="Spline"
          dataSource={mealsSeries}
          xName="x"
          yName="y"
          marker={{
            dataLabel: { visible: false, name: "meal" },
            visible: true,
            width: 10,
            height: 10,
            fill: "#1097cf",
          }}
          fill="#1097CF"
          bullFillColor="#1097CF"
          width={100}
          enableTooltip
          tooltipMappingName="tooltip"
        />
        <SeriesDirective
          type="Spline"
          dataSource={medicinesSeries}
          xName="x"
          yName="y"
          marker={{
            dataLabel: { visible: false, name: "medicines" },
            visible: true,
            width: 10,
            height: 10,
            fill: "#219653",
          }}
          fill="#219653"
          bullFillColor="#219653"
          width={100}
          enableTooltip
          tooltipMappingName="tooltip"
        />
      </SeriesCollectionDirective>
    </ChartComponent>
  );
}
