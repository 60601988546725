import { useFormContext } from "react-hook-form";
import { ModalForm } from "../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../styles/classes/reusable-classes";
import { PatientProfileModalBodyContainer } from "../../../styles/patient-profile-styles/patient-profile-classes";
import { useToastService } from "../../../../../../context/toast-service-context";
import { usePutPatientProfile } from "../../../../../../hooks/patient-profile-hooks/use-put-patient-profile";
import { PutPatientProfileDTO } from "../../../../../../models/patient-profile-dtos/put-patient-profile-dto";
import { PatientProfileEditProfileInputs } from "../patient-profile-edit-profile-modal";
import { PatientProfileEditProfileHealthBarriersFormControl } from "./patient-profile-edit-profile-health-barriers-form-control";
import { PatientProfileEditProfilePatientNameFormControl } from "./patient-profile-edit-profile-patient-name-form-control";
import { PatientProfileEditProfilePatientEmailAndMobileFormControl } from "./patient-profile-edit-profile-patient-email-and-mobile-form-control";
import { PatientProfileEditProfilePatientJobFormControl } from "./patient-profile-edit-profile-patient-job-form-control";
import { PatientProfileEditProfileLocationFormControl } from "./patient-profile-edit-profile-location-form-control";
import { PatientProfileEditProfileBirthdateFormControl } from "./patient-profile-edit-profile-birthdate-form-control";
import { PatientProfileEditProfileDiagnosis } from "./patient-profile-edit-profile-diagnosis";
import { PateintProfileEditProfileMaritalStatusFormControl } from "./pateint-profile-edit-profile-marital-status-form-control";
import {
  usePatientProfileInformationHeader,
  useReloadPatientProfileInformationHeader,
} from "../../context/loadable-patient-profile-information-header-context";

type PatientProfileEditProfileModalBodyProps = {
  onClose: () => void;
};

export const PatientProfileEditProfileModalBody = (
  props: PatientProfileEditProfileModalBodyProps
) => {
  const { onClose } = props;

  const { handleSubmit } = useFormContext<PatientProfileEditProfileInputs>();

  const { patientDTO } = usePatientProfileInformationHeader();
  const putPatientProfile = usePutPatientProfile();
  const { showToast } = useToastService();
  const reoloadPatientProfileInformationHeader =
    useReloadPatientProfileInformationHeader();

  const onSubmit = handleSubmit(async (inputs) => {
    const putPatientProfileObject: PutPatientProfileDTO = {
      name: inputs.name!,
      email: inputs.email !== "" ? inputs.email : undefined,
      mobile: inputs.mobile !== "" ? inputs.mobile : undefined,
      job: inputs.job !== "" ? inputs.job : undefined,
      dateOfBirth: inputs.dateOfBirth !== "" ? inputs.dateOfBirth : undefined,
      address: inputs.address !== "" ? inputs.address : undefined,
      patientHealthBarriers: inputs.patientHealthBarriers!,
      patientProfileDiagnoses: inputs.patientProfileDiagnoses!,
      maritalStatusId: inputs.maritalStatusId!,
      /*TODO@Omar: Replace with real list*/
      patientFundusExaminations: [],
    };

    try {
      await putPatientProfile(patientDTO, putPatientProfileObject);
      showToast("Success", "Patient profile updated successfully!");
      reoloadPatientProfileInformationHeader();
      onClose();
    } catch (error) {
      showToast("Error", "Failed to update patient profile");
    }
  });

  return (
    <ModalForm width={880} height={800} onSubmit={onSubmit}>
      <ModalHeader title="Edit Profile" onModalClose={onClose} />
      <PatientProfileModalBodyContainer canScrollY>
        <PatientProfileEditProfileDiagnosis />
        <PatientProfileEditProfileHealthBarriersFormControl />
        <PatientProfileEditProfilePatientNameFormControl />
        <PatientProfileEditProfilePatientEmailAndMobileFormControl />
        <PatientProfileEditProfilePatientJobFormControl />
        <PatientProfileEditProfileLocationFormControl />
        <PatientProfileEditProfileBirthdateFormControl />
        <PateintProfileEditProfileMaritalStatusFormControl />
      </PatientProfileModalBodyContainer>

      <ModalFooter>
        <Button type="button" onClick={onClose} outlined>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </ModalFooter>
    </ModalForm>
  );
};
