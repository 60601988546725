import { Medicine } from "../../../cgm-google-chart/utils/build-days-aggregates";
import {
  MealTooltipBody,
  MealTooltipBodyList,
  MealTooltipBodyListItem,
  MealTooltipContainer,
  MealtootipHeader,
  MealtootipHeaderTop,
} from "../../../../styles/classes/tooltips";

type MedicationTooltipProps = {
  x: Date;
  y: number;
  data: Medicine;
};

export const MedicationTooltip = (props: MedicationTooltipProps) => {
  const { x, data } = props;

  return (
    <MealTooltipContainer>
      <MealtootipHeader>
        <MealtootipHeaderTop>
          <p>Medications</p>
          <strong>
            {x.toLocaleString("en-UK", {
              year: undefined,
              month: undefined,
              day: undefined,
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
              second: undefined,
            })}
          </strong>
          <span className="material-symbols-outlined">close</span>
        </MealtootipHeaderTop>
      </MealtootipHeader>
      <MealTooltipBody>
        <MealTooltipBodyList>
          {data.items.map((medication, index) => (
            <MealTooltipBodyListItem key={index}>
              <span>{medication.name}</span>
              <strong>{medication.amount}</strong>
            </MealTooltipBodyListItem>
          ))}
        </MealTooltipBodyList>
      </MealTooltipBody>
    </MealTooltipContainer>
  );
};
