import { useCallback } from "react";
import { DeleteModal } from "../../../../../../components/delete-modal/delete-modal";
import { Modal } from "../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { useToastService } from "../../../../../../context/toast-service-context";
import { useUpdatePatientPlan } from "../../../../../../hooks/patient-plan-hooks/use-update-patient-plan";
import { PatientDetailedPlanPatientPlanFeedbackDTO } from "../../../../../../models/patient-plan-dtos/patient-detailed-plan-patient-plan-feedback-dto";
import { UpdatePatientPlanDTO } from "../../../../../../models/patient-plan-dtos/update-patient-plan-dto";
import {
  useDetailedPlan,
  useReloadDetailedPlan,
} from "../../../context/loadable-detailed-plan-context";

type DeleteFeedbackProps = {
  item: PatientDetailedPlanPatientPlanFeedbackDTO;
};

export const DeleteFeedback = (props: DeleteFeedbackProps) => {
  const { item } = props;
  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  const reloadPatientDetailedPlan = useReloadDetailedPlan();
  const updatePatientPlan = useUpdatePatientPlan();
  const { showToast } = useToastService();
  const { patientDetailedPlanDTO } = useDetailedPlan();

  const handleDeleteFeedback = useCallback(
    (item: PatientDetailedPlanPatientPlanFeedbackDTO) => {
      const updateDTO: UpdatePatientPlanDTO = {
        ...patientDetailedPlanDTO,
        patientPlanFeedbacks:
          patientDetailedPlanDTO.patientPlanFeedbacks.filter(
            (feedback) => feedback.id !== item.id
          ),
      };

      updatePatientPlan(patientDetailedPlanDTO.patientPlan, updateDTO)
        .then(() => {
          showToast("Success", `Feedback removed successfully`);
          reloadPatientDetailedPlan();
        })
        .catch(() => showToast("Error", `Failed to remove feedback :(`));
    },
    [
      reloadPatientDetailedPlan,
      patientDetailedPlanDTO,
      updatePatientPlan,
      showToast,
    ]
  );

  return (
    <>
      <button onClick={openModal}>
        <img src="/img/prescription/delete.svg" alt="Delete Icon" />
        Delete
      </button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <DeleteModal
          key={modalBodyKey}
          title="this feedback"
          onModalClose={closeModal}
          onDelete={() => handleDeleteFeedback(item)}
        />
      </Modal>
    </>
  );
};
