import {
  Category,
  ChartComponent,
  DataLabel,
  Inject,
  Legend,
  SeriesCollectionDirective,
  SeriesDirective,
  StackingColumnSeries,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { useCallback, useMemo, useRef } from "react";
import { randomUUID } from "../../../../../../../utils/random-uuid";
import { buildAxisLabelRender } from "./utils/build-axis-label-render";
import { buildPrimaryXAxis } from "./utils/build-primary-x-axis";
import { buildPrimaryYAxis } from "./utils/build-primary-y-axis";
import { useNutritionAnalysisData } from "../../../context/nutrition-analysis-data-context";
import { buildMealsTagsSeries } from "./utils/series/build-meals-tags-series";
import {
  NutritionAnalysisMealsChartContainer,
  NutritionAnalysisMealsChartWrapper,
} from "./styled-nutrition-analysis-meals-chart";

export const NutritionAnalysisMealsChart = () => {
  const {
    groupedPatientMealsByDate,
    patientMeals,
    groupedPatientMealsByDateTotalKcals,
  } = useNutritionAnalysisData();

  const id = useMemo(
    () => `nutrition-analysis-syncfusion-chart-${randomUUID()}`,
    []
  );

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);

    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const chartRef = useRef<ChartComponent | null>(null);

  const columnWidth = 0.3;

  const primaryxAxis = useMemo(() => buildPrimaryXAxis(), []);

  const minY = useMemo(
    () =>
      Math.min(
        ...[
          0,
          ...patientMeals.map((entry) => entry.patientMealMacrosSummary.kCals),
        ]
      ),
    [patientMeals]
  );

  const maxY = useMemo(
    () => Math.max(...[0, ...groupedPatientMealsByDateTotalKcals]) + 200,
    [groupedPatientMealsByDateTotalKcals]
  );

  const primaryyAxis = useMemo(
    () => buildPrimaryYAxis(maxY, minY),
    [maxY, minY]
  );

  const { vAxisTicks } = useMemo(() => {
    const sortedIntegerKcals = groupedPatientMealsByDateTotalKcals
      .sort((a, b) => a - b)
      .map((kcal) => Math.floor(kcal));

    const vAxisTicks: number[] = [];
    const maxKcal = Math.max(...sortedIntegerKcals);
    const step = maxKcal > 1000 ? 500 : 50;

    for (let i = 0; i <= maxKcal; i += step) {
      vAxisTicks.push(i);
    }

    return { vAxisTicks };
  }, [groupedPatientMealsByDateTotalKcals]);

  const axisLabelRender = useMemo(
    () => buildAxisLabelRender(vAxisTicks),
    [vAxisTicks]
  );

  const mealsTagsSereis = useMemo(
    () => buildMealsTagsSeries(groupedPatientMealsByDate),
    [groupedPatientMealsByDate]
  );

  return (
    <NutritionAnalysisMealsChartContainer>
      <NutritionAnalysisMealsChartWrapper>
        <ChartComponent
          id={id}
          primaryXAxis={primaryxAxis}
          primaryYAxis={primaryyAxis}
          loaded={handleChartLoaded}
          axisLabelRender={axisLabelRender}
          border={{ width: 0 }}
          ref={chartRef}
          height="100%"
          width="100%"
        >
          <Inject
            services={[
              StackingColumnSeries,
              Legend,
              DataLabel,
              StripLine,
              Category,
            ]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={mealsTagsSereis}
              xName="x"
              yName="y"
              name="Breakfast"
              type="StackingColumn"
              fill="#f5c753"
              columnWidth={columnWidth}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={mealsTagsSereis}
              xName="x"
              yName="y1"
              name="Lunch"
              type="StackingColumn"
              fill="#5eb3c6"
              columnWidth={columnWidth}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={mealsTagsSereis}
              xName="x"
              yName="y3"
              name="Dinner"
              type="StackingColumn"
              fill="#f29d43"
              columnWidth={columnWidth}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={mealsTagsSereis}
              xName="x"
              yName="y2"
              name="SnackOrOther"
              type="StackingColumn"
              fill="#bbc047"
              columnWidth={columnWidth}
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </NutritionAnalysisMealsChartWrapper>
    </NutritionAnalysisMealsChartContainer>
  );
};
