import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { PATIENTS_PATH } from "../../constants/paths";
import { BulkCreatePatientLabTestResultsDTO } from "../../models/patient-lab-test-result-dtos/bulk-create-patient-lab-test-results-dto";
import { BulkCreatePatientLabTestResultsResponseDTO } from "../../models/patient-lab-test-result-dtos/bulk-create-patient-lab-test-results-response-dto";

export function useBulkAddPatientLabTestResults(): (
  patientId: number,
  bulkCreatePatientLabTestResultsDTO: BulkCreatePatientLabTestResultsDTO
) => Promise<BulkCreatePatientLabTestResultsResponseDTO> {
  const client = useClient();

  return useCallback(
    async (patientId, bulkCreatePatientLabTestResultsDTO) => {
      const response =
        await client.post<BulkCreatePatientLabTestResultsResponseDTO>(
          `${PATIENTS_PATH}/${patientId}/lab-test-results/bulk-add`,
          bulkCreatePatientLabTestResultsDTO
        );

      return response.data;
    },
    [client]
  );
}
