import { PatientActivityLogDTO } from "../../../../models/patient-activity-log-dtos/patient-activity-log-dto";
import {
  ActivitiesTooltipBody,
  ActivitiesTooltipBodyItem,
  TooltipContainer,
  TooltipHeader,
} from "../../../../styles/classes/tooltips";
type ActivitiesTooltipProps = {
  x: Date;
  y: number;
  data: PatientActivityLogDTO;
};

export const ActivitiesTooltip = (props: ActivitiesTooltipProps) => {
  const { x, data } = props;

  return (
    <TooltipContainer>
      <TooltipHeader>
        <p>
          {data.activity?.activityMode.name === undefined
            ? "Other Activity"
            : data.activity?.activityMode.name}
        </p>
        <strong>
          {x.toLocaleString("en-UK", {
            year: undefined,
            month: undefined,
            day: undefined,
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
            second: undefined,
          })}
        </strong>
        <span className="material-symbols-outlined">close</span>
      </TooltipHeader>
      <ActivitiesTooltipBody>
        <ActivitiesTooltipBodyItem>
          <p>
            {data.activity?.name === undefined
              ? data.description
              : data.activity?.name}
          </p>
          <span>{data.durationMins} mins</span>
        </ActivitiesTooltipBodyItem>
      </ActivitiesTooltipBody>
    </TooltipContainer>
  );
};
