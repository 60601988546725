import styled from "styled-components/macro";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";
import { PatientProfileModalInputContainer } from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { DropdownContainer } from "../../../../../../../../components/dropdown/styled-dropdown";

export const PateintProfileDietsAddedSugarsFormControlDropdownsContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  position: relative;
  width: 100%;
  gap: 16px 24px;

  ${Button} {
    position: absolute;
    left: -60px;
    top: 0px;
  }

  ${PatientProfileModalInputContainer} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    :nth-child(1) {
      ${DropdownContainer} {
        grid-column: span 2;
      }
    }
  }
`;

export const PateintProfileDietsRelativeInputContainer = styled.div`
  position: relative;
`;
