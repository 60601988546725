import styled, { css } from "styled-components/macro";
import {
  flexCenterSpaceAround,
  mediumFont,
  regularMediumFont,
} from "../../../../styles/classes/gloabl-classes";

export const SmsOtpsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
`;

export const SmsOtpsList = styled.ul`
  margin: 24px 0px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 16px;
  padding: 16px 0px 0px;
  grid-column: 2 / 4;

  @media screen and (max-width: 1240px) {
    grid-column: 1 / 5;
  }
`;

type SmsOtpsListItemType = {
  header: boolean;
};

const SmsOtpsHeaderListItemCSS = css`
  ${mediumFont}
  color: ${({ theme }) => theme.newColors.textColorAlt};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 0px 12px 12px;
  text-align: left;
`;

const SmsOtpsBodyListItemCSS = css`
  color: ${({ theme }) => theme.colors.titleColor};
  ${regularMediumFont}
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 16px 12px;

  :nth-child(2) {
    border: 0px;
  }
`;

export const SmsOtpsListItem = styled.li<SmsOtpsListItemType>`
  ${({ header }) =>
    header ? SmsOtpsHeaderListItemCSS : SmsOtpsBodyListItemCSS};
  ${flexCenterSpaceAround}

  p {
    width: 100%;
    margin: 0px;
  }
`;
