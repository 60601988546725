import { FormProvider, useForm } from "react-hook-form";
import { PatientPlanFeedbackTone } from "../../../../../../../models/patient-plan-dtos/patient-plan-feedback-dto";
import { CustomFeedback } from "./custom-feedback";

type CustomFeedbackProps = {
  resetCurrentFeedbackModal: () => void;
};

export type CustomFeedbackFormInputs = {
  feedbackTone?: PatientPlanFeedbackTone;
  notes?: string;
  labTestTypeId?: number;
};

export const CustomFeedbackContainer = (props: CustomFeedbackProps) => {
  const { resetCurrentFeedbackModal } = props;

  const methods = useForm<CustomFeedbackFormInputs>({
    defaultValues: {
      feedbackTone: undefined,
      notes: undefined,
      labTestTypeId: undefined,
    },
  });

  return (
    <FormProvider {...methods}>
      <CustomFeedback resetCurrentFeedbackModal={resetCurrentFeedbackModal} />
    </FormProvider>
  );
};
