import { useCallback, useEffect, useState } from "react";
import { ScreenSubNavbar } from "../../../components/screen-sub-navbar/screen-sub-navbar";
import { buildLabResultsNavbarLinks } from "./lab-results";
import { useGetLabTestCategories } from "../../../hooks/lab-test-categories/use-get-lab-test-categories";
import { useParams } from "react-router-dom";

type LabResutsSubNavbarLink = {
  name: string;
  path: string;
};

export const LabResultsHeader = () => {
  const { id } = useParams();
  const getLabTestCategories = useGetLabTestCategories();

  const [links, setLinks] = useState<LabResutsSubNavbarLink[]>([]);

  const getLabResultsSubNavbarLinks = useCallback(
    async () =>
      await getLabTestCategories().then((response) =>
        setLinks(buildLabResultsNavbarLinks(id!, response))
      ),
    [id, getLabTestCategories]
  );

  useEffect(() => {
    getLabResultsSubNavbarLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ScreenSubNavbar links={links} />;
};
