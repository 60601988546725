import {
  ChangeEvent,
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type TargetAudienceSearchTerm = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  resetSearchTerm: () => void;
  handleOnSearchInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const TargetAudienceSearchTermContext = createContext<
  TargetAudienceSearchTerm | undefined
>(undefined);

type TargetAudienceSearchTermProviderProps = PropsWithChildren<{}>;

export const TargetAudienceSearchTermProvider = (
  props: TargetAudienceSearchTermProviderProps
) => {
  const { children } = props;

  const [searchTerm, setSearchTerm] = useState("");

  const handleOnSearchInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value),
    [setSearchTerm]
  );

  const resetSearchTerm = useCallback(() => setSearchTerm(""), []);

  const value = useMemo(
    () => ({
      searchTerm,
      setSearchTerm,
      resetSearchTerm,
      handleOnSearchInputChange,
    }),
    [searchTerm, setSearchTerm, resetSearchTerm, handleOnSearchInputChange]
  );

  return (
    <TargetAudienceSearchTermContext.Provider value={value}>
      {children}
    </TargetAudienceSearchTermContext.Provider>
  );
};

export const useTargetAudienceSearchTerm = () => {
  const context = useContext(TargetAudienceSearchTermContext);

  if (context === undefined) {
    throw new Error(
      "useTargetAudienceSearchTerm must be used within a TargetAudienceSearchTermProvider"
    );
  }

  return context;
};
