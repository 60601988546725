import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  PatientNutritionReportDailySummaryDayDTOWithId,
  createPatientNutritionReportDailySummaryDayDTOWithIds,
} from "../../nutrition-report/nutrition-report-table/utils/create-patient-nutrition-report-daily-summary-day-dto-with-ids";
import { usePatientNutritionReportDailySummary } from "./patient-nutrition-report-daily-summary-context";
import { useNutritionReportSearchParams } from "./nutrition-report-search-params-context";

type NutritionReportSelectedTableRows = {
  handleRowSelect: (id: number) => void;
  allRowsSelectedFlag: boolean;
  toggleAllRows: () => void;
  selectedDays: PatientNutritionReportDailySummaryDayDTOWithId[];
};

const NutritionReportSelectedTableRowsContext = createContext<
  NutritionReportSelectedTableRows | undefined
>(undefined);

type NutritionReportSelectedTableRowsProviderProps = PropsWithChildren<{}>;

export const NutritionReportSelectedTableRowsProvider = (
  props: NutritionReportSelectedTableRowsProviderProps
) => {
  const { children } = props;

  const { patientNutritionReportDailySummary } =
    usePatientNutritionReportDailySummary();

  const { searchParams } = useNutritionReportSearchParams();

  const patientNutritionReportDailySummaryDayDTOWithIds =
    createPatientNutritionReportDailySummaryDayDTOWithIds(
      patientNutritionReportDailySummary
    );

  const getSelectedDaysInitialValue = useMemo(() => {
    const sortedSelectedDaysFromParams = searchParams
      .getAll("selectedDays")
      .sort();
    const selectedDaysFromSearchParams: PatientNutritionReportDailySummaryDayDTOWithId[] =
      [];

    if (searchParams.getAll("selectedDays").length > 0) {
      for (const day of sortedSelectedDaysFromParams) {
        for (const patientNutritionReportDailySummaryDayDTOWithId of patientNutritionReportDailySummaryDayDTOWithIds) {
          if (patientNutritionReportDailySummaryDayDTOWithId.date === day) {
            selectedDaysFromSearchParams.push(
              patientNutritionReportDailySummaryDayDTOWithId
            );
          }
        }
      }
    }

    return selectedDaysFromSearchParams.length > 0
      ? selectedDaysFromSearchParams
      : patientNutritionReportDailySummaryDayDTOWithIds;
  }, [patientNutritionReportDailySummaryDayDTOWithIds, searchParams]);

  const idsForAllRows = useMemo(
    () => patientNutritionReportDailySummaryDayDTOWithIds.map((day) => day.id),
    [patientNutritionReportDailySummaryDayDTOWithIds]
  );

  const [selectedDays, setSelectedDays] = useState<
    PatientNutritionReportDailySummaryDayDTOWithId[]
  >(getSelectedDaysInitialValue);

  const isAllRowsSelected = selectedDays.length === idsForAllRows.length;

  const [allRowsSelectedFlag, setAllRowsSelectedFlag] =
    useState(isAllRowsSelected);

  const toggleAllRows = useCallback(() => {
    if (allRowsSelectedFlag && isAllRowsSelected) {
      setAllRowsSelectedFlag(false);
      setSelectedDays([]);
    } else {
      setAllRowsSelectedFlag(true);
      setSelectedDays(patientNutritionReportDailySummaryDayDTOWithIds);
    }
  }, [
    allRowsSelectedFlag,
    isAllRowsSelected,
    patientNutritionReportDailySummaryDayDTOWithIds,
  ]);

  const handleRowSelect = useCallback(
    (id: number) => {
      if (selectedDays.length === 0) {
        setSelectedDays(
          patientNutritionReportDailySummaryDayDTOWithIds.filter(
            (day) => day.id === id
          )
        );
      } else if (!selectedDays.find((item) => item.id === id)) {
        const newItem = patientNutritionReportDailySummaryDayDTOWithIds.filter(
          (day) => day.id === id
        )[0];

        setSelectedDays([...selectedDays, newItem]);
      } else {
        setAllRowsSelectedFlag(false);
        setSelectedDays(selectedDays.filter((day) => day.id !== id));
      }
    },
    [selectedDays, patientNutritionReportDailySummaryDayDTOWithIds]
  );

  useEffect(() => {
    if (isAllRowsSelected) {
      setAllRowsSelectedFlag(true);
    }
  }, [isAllRowsSelected]);

  const value = useMemo(
    () => ({
      handleRowSelect,
      allRowsSelectedFlag,
      toggleAllRows,
      selectedDays,
    }),
    [handleRowSelect, allRowsSelectedFlag, toggleAllRows, selectedDays]
  );

  return (
    <NutritionReportSelectedTableRowsContext.Provider value={value}>
      {children}
    </NutritionReportSelectedTableRowsContext.Provider>
  );
};

export const useNutritionReportSelectedTableRows =
  (): NutritionReportSelectedTableRows => {
    const nutritionReportSelectedTableRows = useContext(
      NutritionReportSelectedTableRowsContext
    );

    if (nutritionReportSelectedTableRows === undefined) {
      throw new Error(
        `useNutritionReportSelectedTableRows must be wrapped with in NutritionReportSelectedTableRowsProvider`
      );
    }

    return nutritionReportSelectedTableRows;
  };
