import { useMemo } from "react";
import {
  PersonalInfoGroup,
  PersonalInfoGroupBottom,
  PersonalInfoGroupTitle,
  PersonalInfoGroupTop,
  PersonalInfoItem,
} from "../../personal-info-tab/styled-personal-info-tab";
import { TransformedFamilyMember } from "../../../../patient-profile/patient-profile-medical-history-section/patient-profile-medical-history/patient-profile-medical-history-read/patient-profile-medical-history-read-family-disease";
import { FamilyMember } from "../../../../../../models/family-member";
import { FamilyMemberDisease } from "../../../../../../models/family-member-disease";
import { viewFamilyMemberDisease } from "../../../../patient-profile/utils/patient-profile-utils";
import { useSinglePatientSidebar } from "../../../context/loadable-single-patient-sidebar-context";

export const FamilyHasDiseases = () => {
  const { patientMedicalHistoryDTO } = useSinglePatientSidebar();

  const familyMemberDiseases = patientMedicalHistoryDTO.familyMemberDiseases;

  const transformedFamilyMembers = useMemo((): TransformedFamilyMember[] => {
    const transformedFamilyMembers: TransformedFamilyMember[] = [];

    const familyMembersMap: Map<FamilyMember, FamilyMemberDisease[]> =
      new Map();

    familyMemberDiseases.forEach((item) => {
      item.familyMembers.forEach((familyMember) => {
        if (!familyMembersMap.has(familyMember)) {
          familyMembersMap.set(familyMember, []);
        }

        const diseases = familyMembersMap.get(familyMember);
        if (diseases) {
          diseases.push(item.familyMemberDisease);
        }
      });
    });

    familyMembersMap.forEach((diseases, familyMember) => {
      transformedFamilyMembers.push({
        familyMember,
        familyMemberDiseases: diseases,
        other: familyMemberDiseases.find((item) => item.other !== undefined)
          ?.other,
      });
    });

    return transformedFamilyMembers;
  }, [familyMemberDiseases]);

  const hasFamilyMembersDiseases =
    transformedFamilyMembers !== undefined &&
    transformedFamilyMembers.length > 0
      ? "Yes"
      : "No";

  return (
    <PersonalInfoGroup>
      <PersonalInfoGroupTop>
        <PersonalInfoGroupTitle>Family History Diseases</PersonalInfoGroupTitle>
        <PersonalInfoGroupTitle>
          {hasFamilyMembersDiseases}
        </PersonalInfoGroupTitle>
      </PersonalInfoGroupTop>
      {hasFamilyMembersDiseases === "Yes" && (
        <PersonalInfoGroupBottom>
          {transformedFamilyMembers.map((familyDisease, index) => (
            <PersonalInfoItem key={index}>
              {`${
                familyDisease.familyMember !== "Other"
                  ? `${familyDisease.familyMember}:`
                  : ""
              } `}
              {familyDisease.familyMember !== "Other" ? (
                `(
        ${familyDisease.familyMemberDiseases
          .map(
            (disease, i) =>
              `${viewFamilyMemberDisease(disease)}${
                familyDisease.familyMemberDiseases.length > 1 &&
                familyDisease.familyMemberDiseases[i + 1]
                  ? ", "
                  : ""
              }`
          )
          .join(" ")}
        )`
              ) : (
                <></>
              )}
            </PersonalInfoItem>
          ))}
          {familyMemberDiseases !== undefined &&
          familyMemberDiseases.some((item) => item.other !== undefined) ? (
            <PersonalInfoItem>
              Other: {familyMemberDiseases.find((item) => item.other)?.other}
            </PersonalInfoItem>
          ) : (
            ""
          )}
        </PersonalInfoGroupBottom>
      )}
    </PersonalInfoGroup>
  );
};
