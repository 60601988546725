import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  mediumFont,
  regularMediumFont,
} from "../../../../../../../../styles/classes/gloabl-classes";

export const BloodPressureModalBodyContainer = styled.div`
  margin: 24px 0px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 16px 0px;
  border-radius: 1rem;
`;

export const BloodPressureModalBodyHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 0px 16px 16px;
  ${flexCenterSpaceBetween}

  p {
    margin: 0px;
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;

export const BloodPressureModalBodyTableContainer = styled.div`
  padding: 0px 16px;

  th {
    div {
      border: 0px;
      color: ${({ theme }) => theme.colors.textColor};
      ${mediumFont}
      margin: 0px;
    }
  }

  tr {
    background: ${({ theme }) => theme.colors.containerColor};

    :nth-child(odd) {
      background-color: ${({ theme }) => theme.colors.containerColor};
    }

    td {
      padding: 12px 0px;

      input {
        background: ${({ theme }) => `${theme.colors.inputColor}80`};
        border: 0px;
        padding: 12px 12px 8px;
      }
    }
  }
`;
