import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useCallback } from "react";
import { FoodLoggingHeaderDatePickerContainer } from "./styled-food-logging-header-date-picker";
import { useFoodLoggingDatePicker } from "../../context/food-logging-date-picker-context";

export const FoodLoggingHeaderDatePicker = () => {
  const { startDate, endDate, setStartDate, setEndDate } =
    useFoodLoggingDatePicker();

  const handleDateRangeChange = useCallback(
    (e: any) => {
      if (e.target.value !== null) {
        setStartDate(e.target.value[0]);
        setEndDate(e.target.value[1]);
      }
    },
    [setStartDate, setEndDate]
  );

  return (
    <FoodLoggingHeaderDatePickerContainer>
      <DateRangePickerComponent
        startDate={startDate}
        endDate={endDate}
        placeholder="Select date range"
        format="dd/ M /yyyy"
        separator="$"
        onChange={handleDateRangeChange}
      />
    </FoodLoggingHeaderDatePickerContainer>
  );
};
