import { PropsWithChildren, useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";

export function ReactPortal(props: PropsWithChildren<{}>) {
  const { children } = props;

  // Would've preferred to use `undefined` for consistency with the rest of the code. But can't until React 18
  // See: https://stackoverflow.com/a/52223388/6690391
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    const portalElement = document.createElement("div");
    document.body.appendChild(portalElement);
    setPortalElement(portalElement);
    return () => {
      document.body.removeChild(portalElement);
    };
  }, [setPortalElement]);

  return portalElement && createPortal(children, portalElement);
}
