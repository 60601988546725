import {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";

export type RowsActions = {
  state: RowsActionsState;
  startAdding: () => void;
  startEditing: (id: number) => void;
  startDeleting: (id: number) => void;
  cancelOrConfirm: () => void;
};

export type RowsActionsState =
  | { kind: "idle" }
  | { kind: "isEditing"; id: number }
  | { kind: "isDeleting"; id: number }
  | { kind: "isAdding" };

const RowsActionsContext = createContext<RowsActions | undefined>(undefined);

export function RowsActionsProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const [state, setState] = useState<RowsActionsState>({ kind: "idle" });

  const startAdding = useCallback(
    () => setState({ kind: "isAdding" }),
    [setState]
  );

  const startEditing = useCallback(
    (id: number) => setState({ kind: "isEditing", id }),
    [setState]
  );

  const startDeleting = useCallback(
    (id: number) => setState({ kind: "isDeleting", id }),
    [setState]
  );

  const cancelOrConfirm = useCallback(
    () => setState({ kind: "idle" }),
    [setState]
  );

  const value = useMemo(
    () => ({
      state,
      startAdding,
      startEditing,
      startDeleting,
      cancelOrConfirm,
    }),
    [state, startAdding, startEditing, startDeleting, cancelOrConfirm]
  );

  return (
    <RowsActionsContext.Provider value={value}>
      {children}
    </RowsActionsContext.Provider>
  );
}

export function useRowsActions(): RowsActions {
  const rowsActions = useContext(RowsActionsContext);
  if (rowsActions === undefined) {
    throw new Error("useRowsActions must be used within RowsActionsProvider");
  }

  return rowsActions;
}
