import { Temporal } from "temporal-polyfill";
import { PatientDetailedPrescriptionDTO } from "../../../../../models/patient-prescriptions/patient-detailed-prescription-dto";
import { SinglePrescriptionMedicationCard } from "./single-prescription-medication-card/single-prescription-medication-card";
import {
  SinglePrescriptionContainer,
  SinglePrescriptionHeader,
  SinglePrescriptionHeaderTitleContainer,
  SinglePrescriptionMedications,
} from "./styled-single-prescription";

type SinglePrescriptionProps = {
  prescription: PatientDetailedPrescriptionDTO;
  searchTerm: string;
};

export const SinglePrescription = (props: SinglePrescriptionProps) => {
  const { prescription, searchTerm } = props;

  return (
    <SinglePrescriptionContainer>
      <SinglePrescriptionHeader>
        <SinglePrescriptionHeaderTitleContainer>
          <span className="material-symbols-outlined">vaccines</span>
          <p>
            {Temporal.PlainDate.from(prescription.date).toLocaleString(
              "en-US",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )}
            <span>{prescription.patientMedications.length} Medicines</span>
          </p>
        </SinglePrescriptionHeaderTitleContainer>
      </SinglePrescriptionHeader>
      <SinglePrescriptionMedications>
        {prescription.patientMedications
          .filter((item) => {
            if (
              item.medication.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              item.startDate?.toString().includes(searchTerm)
            ) {
              return item;
            } else if (searchTerm === "") {
              return item;
            } else {
              return "No Results";
            }
          })
          .map((card, index) => (
            <SinglePrescriptionMedicationCard key={index} card={card} />
          ))}
      </SinglePrescriptionMedications>
    </SinglePrescriptionContainer>
  );
};
