import { useCallback } from "react";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { PatientNutritionReportSummaryDTO } from "../../models/patient-nutrition-report-dtos/patient-nutrition-report-summary-dto";

export function useGetPatientNutritionReportSummary(): (
  patientId: number,
  dates: string[]
) => Promise<PatientNutritionReportSummaryDTO> {
  const client = useClient();

  return useCallback(
    async (patientId, dates) => {
      const params = new URLSearchParams();
      dates.forEach((date) => {
        params.append("dates", date);
      });

      const response = await client.get<PatientNutritionReportSummaryDTO>(
        `${PATIENTS_PATH}/${patientId}/nutrition-reports/summary`,
        { params }
      );

      return response.data;
    },
    [client]
  );
}
