import { useMemo } from "react";
import { useFoodLoggingDatePicker } from "../../../../context/food-logging-date-picker-context";
import { useNutritionReportSelectedTableRows } from "../../../../context/nutrition-report-context/nutrition-report-selected-table-rows-context";
import {
  NutritionReportSummaryHeaderDatesRangeContainer,
  NutritionReportSummaryHeaderDatesRangeText,
} from "./styled-nutrition-report-summary-header-dates-range";
import { Temporal } from "temporal-polyfill";
import { getDatesInRange } from "../../util/getDatesInRange";

export const NutritionReportSummaryHeaderDatesRange = () => {
  const { selectedDays } = useNutritionReportSelectedTableRows();
  const { startDateString, endDateString } = useFoodLoggingDatePicker();

  const selectedDates = useMemo(
    () => selectedDays.map((day) => day.date),
    [selectedDays]
  );

  const datesInRange = getDatesInRange(startDateString, endDateString);
  const inRangeDates =
    datesInRange.length <= 30 ? datesInRange : datesInRange.slice(0, 30);

  return (
    <NutritionReportSummaryHeaderDatesRangeContainer>
      {inRangeDates.map((dateInRange, index) => (
        <NutritionReportSummaryHeaderDatesRangeText
          key={index}
          selected={selectedDates.includes(
            dateInRange.toISOString().split("T")[0].toString()
          )}
        >
          {Temporal.PlainDate.from(
            dateInRange.toISOString().split("T")[0]
          ).toLocaleString("en-UK", {
            month: "short",
            day: "numeric",
          })}
        </NutritionReportSummaryHeaderDatesRangeText>
      ))}
    </NutritionReportSummaryHeaderDatesRangeContainer>
  );
};
