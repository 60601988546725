import { useFieldArray, useForm } from "react-hook-form";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import {
  TDInput,
  TH,
} from "../../../../../../../components/table/styled-table";
import { Table } from "../../../../../../../components/table/table";
import {
  Button,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import { useBulkUpdatePatientBloodPressures } from "../../../../../../../hooks/patient-blood-pressures-hooks/use-bulk-update-patient-blood-pressures";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { Temporal } from "temporal-polyfill";
import { useParams } from "react-router-dom";
import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { PatientProfileBloodPressureChart } from "../../patient-profile-blood-pressure-chart/patient-profile-blood-pressure-chart";
import {
  PatientProfileModalTableAndChartTableContainer,
  PatientProfileModalTableAndChartTableHeaderContainer,
  PatientProfileModalTableAndChartWrapper,
} from "../../../styles/patient-profile-weight-section-classes";
import {
  usePatientProfile,
  useReloadPatientProfile,
} from "../../../../context/loadable-patient-profile-context";

type PatientProfileBloodPressureContainerModalBodyProps = {
  onClose: () => void;
};

type BloodPressuresInputs = {
  bloodPressures: {
    date: string;
    systolic: number;
    diastolic: number;
  }[];
};

export const PatientProfileBloodPressureContainerModalBody = (
  props: PatientProfileBloodPressureContainerModalBodyProps
) => {
  const { onClose } = props;

  const { id } = useParams();
  const today = Temporal.Now.plainDateISO();
  const { showToast } = useToastService();
  const reloadPatientProfile = useReloadPatientProfile();
  const { patientBloodPressureDTOs } = usePatientProfile();
  const bulkUpdatePatientBloodPressures = useBulkUpdatePatientBloodPressures();

  const { control, handleSubmit, register } = useForm<BloodPressuresInputs>({
    defaultValues: {
      bloodPressures: patientBloodPressureDTOs,
    },
  });

  const { append, fields, remove } = useFieldArray({
    name: "bloodPressures",
    control,
  } as never);

  const onSubmit = handleSubmit(async (inputs) => {
    try {
      await bulkUpdatePatientBloodPressures(parseInt(id!), inputs);
      showToast("Success", "Blood pressure readings updated successfully!");
      onClose();
      reloadPatientProfile();
    } catch (error) {
      showToast("Error", "Failed to update Blood pressure readings :(");
    }
  });

  const tableHeadings = ["Date", "Systolic", "Diastolic"].map((heading) => (
    <TH key={heading} modalTableHead>
      <div>{heading}</div>
    </TH>
  ));

  const tableRows = fields.map((field, index) => (
    <tr key={field.id}>
      <td>
        <TDInput
          type="date"
          defaultValue={today.toString()}
          {...register(`bloodPressures.${index}.date`)}
        />
      </td>
      <td>
        <TDInput
          type="number"
          {...register(`bloodPressures.${index}.systolic`)}
        />
      </td>
      <td>
        <TDInput
          type="number"
          {...register(`bloodPressures.${index}.diastolic`)}
        />
      </td>
      <td>
        <span
          title="Delete"
          className="material-symbols-outlined cursor-pointer"
          onClick={() => remove(index)}
        >
          delete
        </span>
      </td>
    </tr>
  ));

  return (
    <ModalForm
      width={900}
      height={patientBloodPressureDTOs.length > 0 ? 740 : 520}
      onSubmit={onSubmit}
    >
      <ModalHeader
        title="Blood pressure"
        subTitle="Blood pressure graph with all readings"
        onModalClose={onClose}
      />
      <PatientProfileModalTableAndChartWrapper>
        {patientBloodPressureDTOs.length > 0 ? (
          <div>
            <PatientProfileBloodPressureChart />
          </div>
        ) : (
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="There are no data in Blood pressure"
            title=""
            subText=""
          />
        )}
        <PatientProfileModalTableAndChartTableContainer>
          <PatientProfileModalTableAndChartTableHeaderContainer>
            <p>Readings</p>
            <Button
              type="button"
              flex
              onClick={() =>
                append({
                  date: today.toString(),
                  systolic: 0,
                  diastolic: 0,
                })
              }
            >
              <span className="material-symbols-outlined">add</span>
              New reading
            </Button>
          </PatientProfileModalTableAndChartTableHeaderContainer>
          <Table tableHeadings={tableHeadings} tableRows={tableRows} />
        </PatientProfileModalTableAndChartTableContainer>
      </PatientProfileModalTableAndChartWrapper>

      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </ModalFooter>
    </ModalForm>
  );
};
