import { Button } from "../../../../../../styles/classes/reusable-classes";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CreateLessonInputs } from "../../create-lesson";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { CreateLessonCard } from "./create-lesson-card/create-lesson-card";
import { useCallback } from "react";
import {
  LessonCardsContainer,
  LessonCardsFooter,
} from "../../../../styles/content-module-lesson-classes";

export const CreateLessonCards = () => {
  const { control, watch } = useFormContext<CreateLessonInputs>();
  const contentModuleCardsWatcher = watch("contentModuleCards");

  const { append, fields, remove } = useFieldArray<CreateLessonInputs>({
    name: "contentModuleCards",
    control,
  } as never);

  const addInitialCard = (
    <Button
      outlinedPrimary
      flex
      type="button"
      onClick={() =>
        append({
          uiOrder: fields.length + 1,
          title: "",
          contentModuleCardParts: [],
        })
      }
    >
      <span className="material-symbols-outlined">add</span>
      Add Card
    </Button>
  );

  const handleRemove = useCallback((index: number) => remove(index), [remove]);

  if (contentModuleCardsWatcher.length === 0) {
    return (
      <LessonCardsContainer>
        <EmptyState
          button={addInitialCard}
          hasHeader={false}
          hasSearch={false}
          text="No Cards Added Yet"
          title=""
        />
      </LessonCardsContainer>
    );
  }

  return (
    <LessonCardsContainer>
      {fields.map((field, index) => (
        <CreateLessonCard
          key={field.id}
          cardIndex={index}
          remove={() => handleRemove(index)}
        />
      ))}
      <LessonCardsFooter>{addInitialCard}</LessonCardsFooter>
    </LessonCardsContainer>
  );
};
