import { PatientDetailedPlanDTO } from "../../../../../../models/patient-plan-dtos/patient-detailed-plan-dto";
import { SinglePlanDropdown } from "./components/single-plan-dropdown";
import {
  SinglePlanDropdownButtonIcon,
  SinglePlanDropdownButtonText,
  SinglePlanDropdownListItem,
} from "./components/styled-single-plan-dropdown";

type SinglePlanGoalsProps = {
  patientDetailedPlanDTO: PatientDetailedPlanDTO;
};

export const SinglePlanGoals = (props: SinglePlanGoalsProps) => {
  const { patientDetailedPlanDTO } = props;

  const assignedGoals = patientDetailedPlanDTO.patientPlanGoals.filter(
    (item) => item.goalType === "Goal"
  );

  const goals = assignedGoals.map((goal, index) => (
    <SinglePlanDropdownListItem key={index}>
      <SinglePlanDropdownButtonIcon>
        <img src="/img/plans/nav/goals.svg" alt="Goals Icon" />
      </SinglePlanDropdownButtonIcon>
      <SinglePlanDropdownButtonText>
        <p>{goal.goal}</p>
      </SinglePlanDropdownButtonText>
    </SinglePlanDropdownListItem>
  ));

  return (
    <div>
      <SinglePlanDropdown
        icon="/img/plans/nav/goals.svg"
        img="/img/view-plans/goals.svg"
        items={goals}
        subtitle="الاهداف  العامة  والاسبوعية"
        title={`Goals  :  الاهداف`}
      />
    </div>
  );
};
