import { useGetPatients } from "../../hooks/patient-hooks/use-get-patients";
import { useCallback } from "react";
import { Loading } from "../../components/loading";
import { useGetCarbSources } from "../../hooks/carb-sources/use-get-carb-sources";
import { useGetFoodGroups } from "../../hooks/food-groups/use-get-food-groups";
import { FoodLoggingHeader } from "./food-logging-header/food-logging-header";
import { DirtyMealProvider } from "./context/dirty-meal-context";
import { PatientTodoMealsCountsUpdatesProvider } from "./context/patient-todo-meals-counts-updates-context";
import { useGetProteinSources } from "../../hooks/protein-sources/use-get-protein-sources";
import { Outlet } from "react-router-dom";
import { FoodLoggingDataProvider } from "./context/food-logging-data-context";
import { FoodLoggingDatePickerProvider } from "./context/food-logging-date-picker-context";
import { NutritionReportSearchParamsProvider } from "./context/nutrition-report-context/nutrition-report-search-params-context";
import { NutritionReportProviders } from "./context/nutrition-report-context/nutrition-report-providers";

export function FoodLogging() {
  // Loading APIs
  const getPatients = useGetPatients();
  const getCarbSources = useGetCarbSources();
  const getFoodGroups = useGetFoodGroups();
  const getProteinSources = useGetProteinSources();

  // load callbacks for `Loading` components
  const load = useCallback(async () => {
    const [patientDTOs, carbSourceDTOs, foodGroupDTOs, proteinSources] =
      await Promise.all([
        getPatients(true),
        getCarbSources(),
        getFoodGroups(),
        getProteinSources(),
      ]);
    return { patientDTOs, carbSourceDTOs, foodGroupDTOs, proteinSources };
  }, [getPatients, getCarbSources, getFoodGroups, getProteinSources]);

  return (
    <DirtyMealProvider>
      <PatientTodoMealsCountsUpdatesProvider>
        <Loading
          load={load}
          successComponent={({
            patientDTOs,
            carbSourceDTOs,
            foodGroupDTOs,
            proteinSources,
          }) => (
            <FoodLoggingDataProvider
              carbSourceDTOs={carbSourceDTOs}
              foodGroupDTOs={foodGroupDTOs}
              patientDTOs={patientDTOs}
              proteinSources={proteinSources}
            >
              <NutritionReportSearchParamsProvider>
                <FoodLoggingDatePickerProvider>
                  <FoodLoggingHeader patientDTOs={patientDTOs} />
                  <NutritionReportProviders>
                    <Outlet />
                  </NutritionReportProviders>
                </FoodLoggingDatePickerProvider>
              </NutritionReportSearchParamsProvider>
            </FoodLoggingDataProvider>
          )}
        />
      </PatientTodoMealsCountsUpdatesProvider>
    </DirtyMealProvider>
  );
}
