import { useSinglePatientSidebar } from "../../../context/loadable-single-patient-sidebar-context";
import {
  PersonalInfoGroup,
  PersonalInfoGroupBottom,
  PersonalInfoGroupTitle,
  PersonalInfoGroupTop,
  PersonalInfoItem,
} from "../styled-personal-info-tab";

export const Behavioral = () => {
  const { patientDTO, patientDietProfileDTO } = useSinglePatientSidebar();

  const patientSmokes =
    patientDietProfileDTO.patientSmokes.length > 0 &&
    patientDietProfileDTO.patientSmokes !== undefined
      ? patientDietProfileDTO.patientSmokes.map(
          (item, index) => `
        ${item.smoke === "Other" ? item.other : item.smoke} ${
            item.quantity !== 0 && item.quantity !== undefined
              ? `(${item.quantity}${
                  item.frequency !== undefined
                    ? ` ${
                        item.smoke === "Cigarettes" || item.smoke === "Iqos"
                          ? `cigarettes`
                          : item.smoke === "Vape"
                          ? `ml`
                          : `times`
                      }/${item.frequency})`
                    : ""
                }`
              : ""
          }${
            patientDietProfileDTO.patientSmokes.length > 1 &&
            patientDietProfileDTO.patientSmokes[index + 1]
              ? ", "
              : ""
          }
      `
        )
      : "";

  const patientAlcohols =
    patientDietProfileDTO.patientAlcohols.length > 0 &&
    patientDietProfileDTO.patientAlcohols !== undefined
      ? patientDietProfileDTO.patientAlcohols.map(
          (item, index) => `
          ${item.alcohol === "Other" ? item.other : item.alcohol} ${
            item.quantity !== 0 && item.quantity !== undefined
              ? `(${item.quantity}${
                  item.frequency !== undefined
                    ? ` ${
                        item.alcohol === "Beer"
                          ? `bottels`
                          : item.alcohol === "Spirits" ||
                            item.alcohol === "Wine"
                          ? `glasses`
                          : ``
                      } /${item.frequency})`
                    : ""
                }`
              : ""
          }${
            patientDietProfileDTO.patientAlcohols.length > 1 &&
            patientDietProfileDTO.patientAlcohols[index + 1]
              ? ", "
              : ""
          }
        `
        )
      : "";

  const sleepHours =
    patientDTO.sleepHoursPerDay !== undefined &&
    patientDTO.sleepHoursPerDay !== 0
      ? patientDTO.sleepHoursPerDay
      : "";

  return (
    <PersonalInfoGroup>
      <PersonalInfoGroupTop>
        <PersonalInfoGroupTitle>Behavioral</PersonalInfoGroupTitle>
      </PersonalInfoGroupTop>
      <PersonalInfoGroupBottom>
        {patientSmokes !== "" && (
          <PersonalInfoItem>{patientSmokes}</PersonalInfoItem>
        )}
        {patientAlcohols !== "" && (
          <PersonalInfoItem>{patientAlcohols}</PersonalInfoItem>
        )}
        {sleepHours !== "" && (
          <PersonalInfoItem>
            {sleepHours} H ({patientDTO.sleepPattern})
          </PersonalInfoItem>
        )}
      </PersonalInfoGroupBottom>
    </PersonalInfoGroup>
  );
};
