import styled from "styled-components/macro";
import { lightShadow } from "../../../../styles/global-style";
import { flexCenterCenter } from "../../../../styles/classes/gloabl-classes";
import { Button } from "../../../../styles/classes/reusable-classes";

export const AddNewMealContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  background: ${({ theme }) => theme.colors.containerColor};
  ${lightShadow}
  ${flexCenterCenter}
  padding: 8px;
  isolation: isolate;
  height: 65px;

  ${Button} {
    padding: 0.5rem 0.8rem;
  }
`;
