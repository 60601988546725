import styled from "styled-components/macro";
import { flexCenter } from "../../../../../styles/classes/gloabl-classes";
import { MedicationsInputCSS } from "../../components/css";

export const StopMedicineModalContainer = styled.div`
  display: grid;
  place-items: center;
`;

export const StopMedicineInputContainer = styled.div`
  margin: 32px 16px;
  ${flexCenter}
  background: ${({ theme }) => theme.colors.inputColor};
  border-radius: 5px;
  padding: ${({ theme }) => theme.padding.pad05All};
  width: 50%;
  height: 40px;
  position: relative;
`;

export const StopMedicineInput = styled.input`
  ${MedicationsInputCSS}
`;
