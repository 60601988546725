import { useImmediateAttentionPatientsFiltersSidebar } from "../../../../../context/immediate-attention-patients-filters-sidebar-context";
import {
  ImmediateAttentionPatientsFiltersSidebarHeaderContainer,
  ImmediateAttentionPatientsFiltersSidebarHeaderHeaderIcons,
  ImmediateAttentionPatientsFiltersSidebarHeaderHeaderText,
} from "./styled-immediate-attention-patients-filters-sidebar-header";

export const ImmediateAttentionPatientsFiltersSidebarHeader = () => {
  const { closeSidebar } = useImmediateAttentionPatientsFiltersSidebar();

  return (
    <ImmediateAttentionPatientsFiltersSidebarHeaderContainer>
      <ImmediateAttentionPatientsFiltersSidebarHeaderHeaderText>
        <p>All Filters</p>
      </ImmediateAttentionPatientsFiltersSidebarHeaderHeaderText>
      <ImmediateAttentionPatientsFiltersSidebarHeaderHeaderIcons>
        <span className="material-symbols-outlined" onClick={closeSidebar}>
          close
        </span>
      </ImmediateAttentionPatientsFiltersSidebarHeaderHeaderIcons>
    </ImmediateAttentionPatientsFiltersSidebarHeaderContainer>
  );
};
