import { Temporal } from "temporal-polyfill";
import { Reading } from "../../build-days-aggregates";
import { GoogleDataTableRow } from "react-google-charts";
import { readingToHtmlTooltip } from "./reading-to-html-tooltip";
import { buildRow } from "../build-row";
import { PatientDTO } from "../../../../../models/patient-dtos/patient-dto";

export function buildReadingRow(
  datetime: Temporal.PlainDateTime,
  reverseVAxisTicks: number[],
  reading: Reading,
  patientDTO: PatientDTO
): GoogleDataTableRow {
  const tooltip = readingToHtmlTooltip(reading, patientDTO);

  return buildRow({
    datetime,
    reverseVAxisTicks,
    glucose: {
      value: reading.glucose,
      tooltip,
    },
  });
}
