import { Avatar, useChatContext } from "stream-chat-react";
import {
  CreateMessagingChannelContainer,
  CreateMessagingChannelHeader,
  CreateMessagingChannelHeaderInputContainer,
  CreateMessagingChannelHeaderTitle,
  CreateMessagingChannelUser,
  CreateMessagingChannelUsers,
} from "./styled-create-messaging-channel";
import { ChannelData } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { memo, useState } from "react";
import { AddNewChannel } from "./add-new-channel/add-new-channel";
import { useChatQueryUsers } from "../../../../../chat-providers/chat-query-users-context";

type CreateMessagingChannelProps = {
  show: boolean;
  onClose: () => void;
};

export const CreateMessagingChannel = memo(
  (props: CreateMessagingChannelProps) => {
    const { show, onClose } = props;

    const { client, setActiveChannel } = useChatContext();
    const { users } = useChatQueryUsers();
    const { showToast } = useToastService();
    const [searchTerm, setSearchTerm] = useState("");

    const onUserSelect = async (userId: string) => {
      const channelData: ChannelData<DefaultStreamChatGenerics> = {
        members: [userId, client.user?.id!],
      };

      try {
        if (client && client.user !== undefined) {
          const conversation = client.channel("messaging", channelData);

          await conversation.watch();

          setActiveChannel?.(conversation);
          onClose();
        }
      } catch (error) {
        showToast("Error", "Failed to Create Chat");
      }
    };

    return (
      <CreateMessagingChannelContainer show={show}>
        <CreateMessagingChannelHeader>
          <CreateMessagingChannelHeaderTitle>
            <p>New Chat</p>
            <div onClick={onClose}>
              <img src="/img/chat/close.svg" alt="close create new chat" />
            </div>
          </CreateMessagingChannelHeaderTitle>
          <CreateMessagingChannelHeaderInputContainer>
            <input
              type="text"
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </CreateMessagingChannelHeaderInputContainer>
          <AddNewChannel closeSidebar={onClose} />
        </CreateMessagingChannelHeader>
        <CreateMessagingChannelUsers>
          {users
            .filter((user) => user.id !== client.userID)
            .filter((item) =>
              item.name !== undefined
                ? item.name
                    ?.toLocaleLowerCase()
                    .includes(searchTerm.toLocaleLowerCase())
                : item.id
                    .toLocaleLowerCase()
                    .includes(searchTerm.toLocaleLowerCase())
            )
            .map((user) => (
              <CreateMessagingChannelUser
                key={user.id}
                onClick={() => onUserSelect(user.id)}
              >
                <Avatar
                  image={
                    user.image !== undefined ? user.image : "/img/chat/user.svg"
                  }
                  name={user.name}
                  size={30}
                />
                <span>{user.name !== undefined ? user.name : user.id}</span>
              </CreateMessagingChannelUser>
            ))}
        </CreateMessagingChannelUsers>
      </CreateMessagingChannelContainer>
    );
  }
);
