import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { MaritalStatusDTO } from "../../models/marital-status-dtos/marital-status-dto";

export function useGetMaritalStatuses(): () => Promise<MaritalStatusDTO[]> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<MaritalStatusDTO[]>(
      `${BASE_PATH}/marital-statuses`
    );

    return response.data;
  }, [client]);
}
