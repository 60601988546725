import { MealsAnalysisResponseTableRow } from "./styled-meals-analysis-response-table-rows";
import { InsulinDataLoaded } from "../../types/insulin-data-loaded";
import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { MealsAnalysisResponseDateTableCell } from "./meals-analysis-response-table-cells/meals-analysis-response-date-table-cell";
import { MealsAnalysisResponseTypeTableCell } from "./meals-analysis-response-table-cells/meals-analysis-response-type-table-cell";
import { MealsAnalysisResponseMediaTableCell } from "./meals-analysis-response-table-cells/meals-analysis-response-media-table-cell/meals-analysis-response-media-table-cell";
import { MealsAnalysisResponseMacrosTableCell } from "./meals-analysis-response-table-cells/meals-analysis-response-macros-table-cell/meals-analysis-response-macros-table-cell";
import { MealsAnalysisResponseGraphTableCell } from "./meals-analysis-response-table-cells/meals-analysis-response-graph-table-cell/meals-analysis-response-graph-table-cell-graph";
import { MealsAnalysisResponseInsulinTableCell } from "./meals-analysis-response-table-cells/meals-analysis-response-insulin-table-cell";
import { MealsAnalysisResponseCarbServingsTableCell } from "./meals-analysis-response-table-cells/meals-analysis-response-carb-servings-table-cell/meals-analysis-response-carb-servings-table-cell";
import { useEffect, useMemo } from "react";
import { useCarbsourcesFilter } from "../../context/meals-analysis-response-carbsources-filter-context";
import { PatientMealCarbSourceDTO } from "../../../../../../../models/patient-meal-dtos/patient-meal-carb-source-dto";

type MealsAnalysisResponseTableRowsProps = {
  insulinDataLoaded: InsulinDataLoaded;
};

export const MealsAnalysisResponseTableRows = (
  props: MealsAnalysisResponseTableRowsProps
) => {
  const { insulinDataLoaded } = props;

  const { carbSourceDTO, setMealsCarbsourceDTOs } = useCarbsourcesFilter();

  const filteredByCarbSources = useMemo(() => {
    if (carbSourceDTO !== undefined) {
      return insulinDataLoaded.patientMealResponseDTO.filter(
        (patientMealResponseDTO) =>
          patientMealResponseDTO.patientMeal.patientMealCarbSources
            .map((item) => item.carbSource.id)
            .includes(carbSourceDTO.id)
      );
    } else {
      return insulinDataLoaded.patientMealResponseDTO;
    }
  }, [carbSourceDTO, insulinDataLoaded.patientMealResponseDTO]);

  const carbSourcesInMeals = useMemo(() => {
    const patientMealResponseDTOs = insulinDataLoaded.patientMealResponseDTO;
    const mealsCarbSources: PatientMealCarbSourceDTO[] = [];

    for (const patientMealResponseDTO of patientMealResponseDTOs) {
      const mealResponseDTOCarbSources =
        patientMealResponseDTO.patientMeal.patientMealCarbSources;

      for (const mealResponseDTOCarbSource of mealResponseDTOCarbSources) {
        mealsCarbSources.push(mealResponseDTOCarbSource);
      }
    }

    return mealsCarbSources;
  }, [insulinDataLoaded.patientMealResponseDTO]);

  useEffect(() => {
    setMealsCarbsourceDTOs(carbSourcesInMeals);
  }, [setMealsCarbsourceDTOs, carbSourcesInMeals]);

  const tableRows = filteredByCarbSources.map((tableRow, index) => (
    <MealsAnalysisResponseTableRow key={index}>
      <MealsAnalysisResponseDateTableCell
        date={tableRow.patientMeal.date}
        time={tableRow.patientMeal.time}
      />
      <MealsAnalysisResponseTypeTableCell
        patientMealTag={tableRow.patientMeal.tag}
      />
      <MealsAnalysisResponseMediaTableCell patientMeal={tableRow.patientMeal} />
      <MealsAnalysisResponseCarbServingsTableCell
        patientMeal={tableRow.patientMeal}
      />
      <MealsAnalysisResponseMacrosTableCell
        patientMeal={tableRow.patientMeal}
      />
      <MealsAnalysisResponseInsulinTableCell
        patientInsulinLog={tableRow.patientInsulinLog}
      />
      <MealsAnalysisResponseGraphTableCell
        tableRow={tableRow}
        patientDTO={insulinDataLoaded.patientDTO}
      />
    </MealsAnalysisResponseTableRow>
  ));

  if (
    insulinDataLoaded.patientMealResponseDTO.length === 0 ||
    filteredByCarbSources.length === 0
  ) {
    return (
      <EmptyState
        button={<></>}
        hasHeader={false}
        hasSearch={false}
        text={`No Data within the selected ${
          insulinDataLoaded.patientMealResponseDTO.length === 0
            ? "Dates"
            : "Carbsource"
        }`}
        title=""
      />
    );
  }

  return <tbody>{tableRows}</tbody>;
};
