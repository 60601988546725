import { PatientMealImageDTO } from "../../../../../../../models/patient-meal-dtos/patient-meal-image-dto";
import { FoodLoggingSelectedPatientMealImagesModal } from "../../../../../../food-logging/food-logging-selected-patient-meal/components/food-logging-selected-patient-meal-images/food-logging-selected-patient-meal-images-modal/food-logging-selected-patient-meal-images-modal";

type PatientMealImagesModalBodyProps = {
  patientMealImageDTOs: PatientMealImageDTO[];
};

export const PatientMealImagesModalBody = (
  props: PatientMealImagesModalBodyProps
) => {
  const { patientMealImageDTOs } = props;

  return (
    <FoodLoggingSelectedPatientMealImagesModal
      patientMealImageDTOs={patientMealImageDTOs}
    />
  );
};
