import { PatientMealCarbSourceDTO } from "../../../../../../../../../../models/patient-meal-dtos/patient-meal-carb-source-dto";
import { roundTo1DecimalPlace } from "../../../../../../../../../../utils/math-utils";
import {
  MealsAnalysisResponseCarbServingsTableCellCarbSourcesContainer,
  MealsAnalysisResponseCarbServingsTableCellCarbSourcesList,
  MealsAnalysisResponseCarbServingsTableCellCarbSourcesListItem,
} from "./styled-meals-analysis-response-carb-servings-table-cell-carb-sources";

type MealsAnalysisResponseCarbServingsTableCellCarbSourcesProps = {
  carbServings: number;
  carbSources: PatientMealCarbSourceDTO[];
};

export const MealsAnalysisResponseCarbServingsTableCellCarbSources = (
  props: MealsAnalysisResponseCarbServingsTableCellCarbSourcesProps
) => {
  const { carbServings, carbSources } = props;

  return (
    <MealsAnalysisResponseCarbServingsTableCellCarbSourcesContainer>
      <span>Carb Servings: {roundTo1DecimalPlace(carbServings)}</span>
      {carbSources.length > 0 && <span>Carb Sources:</span>}
      <MealsAnalysisResponseCarbServingsTableCellCarbSourcesList>
        {carbSources.map((carbSource) => {
          return (
            <MealsAnalysisResponseCarbServingsTableCellCarbSourcesListItem
              key={carbSource.id}
            >
              {carbSource.carbSource.name}
            </MealsAnalysisResponseCarbServingsTableCellCarbSourcesListItem>
          );
        })}
      </MealsAnalysisResponseCarbServingsTableCellCarbSourcesList>
    </MealsAnalysisResponseCarbServingsTableCellCarbSourcesContainer>
  );
};
