import { PatientNutritionReportDailySummaryDayDTOWithId } from "../create-patient-nutrition-report-daily-summary-day-dto-with-ids";
import { getSelectedDaysIds } from "./get-selected-days-ids";

export function getSelectedDaysCount(
  selectedDays: PatientNutritionReportDailySummaryDayDTOWithId[]
) {
  const selectedDaysIds = getSelectedDaysIds(selectedDays);

  return selectedDaysIds.length;
}
