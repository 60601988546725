import { useCallback } from "react";
import { PatientMedicationDTO } from "../../models/patient-medication-dto";
import { DiscontinuePatientMedicationDTO } from "../../models/patient-medication-dtos/discontinue-patient-medication-dto";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";

export function useDiscontinuePatientMedication(): (
  patientMedicationDTO: PatientMedicationDTO,
  discontinuePatientMedicationDTO: DiscontinuePatientMedicationDTO
) => Promise<PatientMedicationDTO> {
  const client = useClient();

  return useCallback(
    async (patientMedicationDTO, discontinuePatientMedicationDTO) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.post<PatientMedicationDTO>(
        `${PATIENTS_PATH}/${patientMedicationDTO.patientId}/medications/${patientMedicationDTO.id}/discontinue`,
        discontinuePatientMedicationDTO
      );

      return response.data;
    },
    [client]
  );
}
