import { useState } from "react";
import {
  ImmediateAttentionPatientsSidebarTabsButton,
  ImmediateAttentionPatientsSidebarTabsContainer,
  ImmediateAttentionPatientsSidebarTabsNavbar,
} from "./styled-immediate-attention-patients-sidebar-tabs";
import { ImmediateAttentionPatientsSidebarMedicationsTab } from "./immediate-attention-patients-sidebar-medications-tab/immediate-attention-patients-sidebar-medications-tab";
import { ImmediateAttentionPatientsSidebarGlucoseTab } from "./immediate-attention-patients-sidebar-glucose-tab/immediate-attention-patients-sidebar-glucose-tab";
import { ImmediateAttentionPatientsSidebarHistoryTab } from "./immediate-attention-patients-sidebar-history-tab/immediate-attention-patients-sidebar-history-tab";

type ImmediateAttentionPatientsSidebarTabsState =
  | "Medications"
  | "Glucose"
  | "History";

export const ImmediateAttentionPatientsSidebarTabs = () => {
  const [sidebarTab, setSidebarTab] = useState<
    ImmediateAttentionPatientsSidebarTabsState
  >("Medications");

  const tabsBody = () => {
    if (sidebarTab === "Medications")
      return <ImmediateAttentionPatientsSidebarMedicationsTab />;

    if (sidebarTab === "Glucose")
      return <ImmediateAttentionPatientsSidebarGlucoseTab />;

    if (sidebarTab === "History")
      return <ImmediateAttentionPatientsSidebarHistoryTab />;
  };

  return (
    <ImmediateAttentionPatientsSidebarTabsContainer>
      <div className="line" />
      <ImmediateAttentionPatientsSidebarTabsNavbar>
        <ImmediateAttentionPatientsSidebarTabsButton
          $active={sidebarTab === "Medications"}
          onClick={() => setSidebarTab("Medications")}
        >
          Medications
        </ImmediateAttentionPatientsSidebarTabsButton>
        <ImmediateAttentionPatientsSidebarTabsButton
          onClick={() => setSidebarTab("Glucose")}
          $active={sidebarTab === "Glucose"}
        >
          Glucose
        </ImmediateAttentionPatientsSidebarTabsButton>
        <ImmediateAttentionPatientsSidebarTabsButton
          onClick={() => setSidebarTab("History")}
          $active={sidebarTab === "History"}
        >
          History
        </ImmediateAttentionPatientsSidebarTabsButton>
      </ImmediateAttentionPatientsSidebarTabsNavbar>
      {tabsBody()}
    </ImmediateAttentionPatientsSidebarTabsContainer>
  );
};
