import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { NutritionRegimenDTO } from "../../models/nutrition-regimen-dtos/nutrition-regimen-dto";

export function useGetNutritionRegimens(): () => Promise<
  NutritionRegimenDTO[]
> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<NutritionRegimenDTO[]>(
      `${BASE_PATH}/nutrition-regimens`
    );

    return response.data;
  }, [client]);
}
