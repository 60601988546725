import styled from "styled-components/macro";
import {
  flexCenterSpaceAround,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../styles/global-style";

export const ImmediateAttentionPatientsSidebarTabsContainer = styled.div`
  .line {
    height: 1px;
    width: 90%;
    background-color: ${({ theme }) => theme.colors.borderColor};
    margin: 0px auto 16px;
  }
`;

export const ImmediateAttentionPatientsSidebarTabsNavbar = styled.nav`
  ${flexCenterSpaceAround}
  margin-bottom: 8px;
`;

export const ImmediateAttentionPatientsSidebarTabsButton = styled.button<{
  $active: boolean;
}>`
    background: transparent;
    ${regularMediumFont}
    position: relative;
    color: ${({ theme }) => theme.colors.titleColor};
    /* border-bottom: 3px solid ${({ $active, theme }) =>
      $active ? theme.colors.primaryColorAlt : "transparent"}; */
    transition: ${transition};
    
    ::before {
        content: '';
        position: absolute;
        width: ${({ $active }) => ($active ? "100%" : "0px")};
        height: 4px;
        background: ${({ theme }) => theme.colors.primaryColorAlt};
        inset: 0px;
        top: unset;
        bottom: -4px;
        transition: ${transition};
    }
`;
