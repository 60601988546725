import { RadioButton } from "../../../../../../styles/classes/reusable-classes";
import {
  MedicationsInputContainer,
  MedicationsInputLabel,
} from "../styled-medications-inputs";

type AssignedByRadioButtonProps = {
  isAssignedByOtida: boolean;
  toggleIsAssignedByOtida: () => void;
};

export const AssignedByRadioButton = (props: AssignedByRadioButtonProps) => {
  const { isAssignedByOtida, toggleIsAssignedByOtida } = props;

  return (
    <MedicationsInputContainer>
      <MedicationsInputLabel width={150}>
        Assigned by Otida
      </MedicationsInputLabel>
      <RadioButton
        type="button"
        checked={isAssignedByOtida}
        onClick={toggleIsAssignedByOtida}
      >
        <span></span>
      </RadioButton>
    </MedicationsInputContainer>
  );
};
