import { DaysAggregatesReadingsWithTimeDifferenceValue } from "../build-readings-with-time-differance-value";
import { isGlucosePointLow } from "./is-glucose-point-low";
import { removeTimeDifferanceValueFromReading } from "./remove-time-differance-value-from-reading";

export function calculateLowReadingsTimeDiffrenceTotal(
  readingsWithTimeDifferenceValue: DaysAggregatesReadingsWithTimeDifferenceValue[],
  minRange: number
) {
  let lowReadingsTimeDiffrenceTotal: number = 0;

  for (const readingWithTimeDifferenceValue of readingsWithTimeDifferenceValue) {
    if (
      isGlucosePointLow(
        removeTimeDifferanceValueFromReading(readingWithTimeDifferenceValue),
        minRange
      )
    ) {
      if (readingWithTimeDifferenceValue.timeDifference !== undefined) {
        if (readingWithTimeDifferenceValue.timeDifference > 15) {
          lowReadingsTimeDiffrenceTotal += 15;
        } else {
          lowReadingsTimeDiffrenceTotal +=
            readingWithTimeDifferenceValue.timeDifference;
        }
      }
    }
  }

  return lowReadingsTimeDiffrenceTotal;
}
