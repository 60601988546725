import { Temporal } from "temporal-polyfill";
import { Source } from "../../../models/patient-cgm-entry-dtos/patient-cgm-entry-source";
import { PatientMealDTO } from "../../../models/patient-meal-dtos/patient-meal-dto";
import { PatientActivityLogDTO } from "../../../models/patient-activity-log-dtos/patient-activity-log-dto";
import { PatientDTO } from "../../../models/patient-dtos/patient-dto";
import { PatientCGMEntryDTO } from "../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import {
  PatientServingDTO,
  isMedicine,
} from "../../../models/patient-serving-dto";
import { getInRangeNumbers } from "../../../utils/patient-type-utils";
import { PatientInsulinLogDTO } from "../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";

export type DayAggregate = {
  date: Temporal.PlainDate;
  readings: Reading[];
  meals: Meal[];
  medicines: Medicine[];
  maxGlucose: number;
  minGlucose: number;
  inRangeNumbers: [number, number];
  rangesCounts: RangesCounts;
  source?: Source;
  patientMealDTO: PatientMealDTO[];
  patientActivityLogs: PatientActivityLogDTO[];
  patientInsulinLogDTOs: PatientInsulinLogDTO[];
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
};

export type Reading = {
  time: Temporal.PlainTime;
  glucose: number;
  tag?: string;
  source: Source;
};

export type Meal = {
  time: Temporal.PlainTime;
  items: MealItem[];
  carbs: number;
  fats: number;
};

export type Medicine = {
  time: Temporal.PlainTime;
  items: MedicineItem[];
};

export type RangesCounts = {
  below: number;
  inRange: number;
  above: number;
};

export type MealItem = {
  name: string;
  amount: string;
};

export type MedicineItem = {
  name: string;
  amount: string;
};

/**
 * For internal use.
 */
type InternalAggregate = Omit<
  DayAggregate,
  "date" | "meals" | "medicines" | "PatientMealDTO" | "patientActivityLog"
> & {
  timeToMeal: TimeToMeal;
  timeToMedicine: TimeToMeal;
};

/**
 * For internal use.
 */
type DayToAggregate = Map<string, InternalAggregate>;

/**
 * For internal use.
 */
type InternalMeal = Omit<Meal, "time">;

/**
 * For internal use.
 */
type TimeToMeal = Map<string, InternalMeal>;

/**
 * For internal use.
 */
type InternalMedicine = Omit<Medicine, "time">;

/**
 * For internal use.
 */
type TimeToMedicine = Map<string, InternalMedicine>;

export function buildDaysAggregates(
  patientDTO: PatientDTO,
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientServingDTOs: PatientServingDTO[],
  patientMealDTO: PatientMealDTO[],
  patientInsulinLogDTOs?: PatientInsulinLogDTO[],
  patientActivityLogs?: PatientActivityLogDTO[]
): DayAggregate[] {
  const inRangeNumbers = getInRangeNumbers(patientDTO.type);

  const dayToAggregate: DayToAggregate = new Map();

  for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
    const date = patientCGMEntryDTO.date;
    const source = patientCGMEntryDTO.source;

    const aggregate = getOrCreateAndSetAggregate(
      dayToAggregate,
      date,
      patientDTO,
      source
    );

    addReading(aggregate, patientCGMEntryDTO);
    updateMaxGlucose(aggregate, patientCGMEntryDTO);
    updateMinGlucose(aggregate, patientCGMEntryDTO);
    updateRangesCounts(aggregate, patientCGMEntryDTO, inRangeNumbers);
  }

  for (const patientMeal of patientMealDTO) {
    const date = patientMeal.date;

    const source = undefined;
    const aggregate = getOrCreateAndSetAggregate(
      dayToAggregate,
      date,
      patientDTO,
      source
    );

    addPatientMeal(aggregate, patientMeal);
  }

  for (const patientServingDTO of patientServingDTOs) {
    const date = patientServingDTO.date;
    const aggregate = getOrCreateAndSetAggregate(
      dayToAggregate,
      date,
      patientDTO
    );

    if (isMedicine(patientServingDTO)) {
      const timeToMedicine = aggregate.timeToMedicine;
      const time = patientServingDTO.time;
      const medicine = getOrCreateAndSetMedicine(timeToMedicine, time);

      addMedicineItem(medicine, patientServingDTO);
    } else {
      const timeToMeal = aggregate.timeToMeal;
      const time = patientServingDTO.time;
      const meal = getOrCreateAndSetMeal(timeToMeal, time);

      addMealItem(meal, patientServingDTO);
      updateCarbs(meal, patientServingDTO);
      updateFats(meal, patientServingDTO);
    }
  }

  if (patientInsulinLogDTOs !== undefined) {
    for (const patientInsulinLogDTO of patientInsulinLogDTOs) {
      const date = patientInsulinLogDTO.date;

      const source = undefined;
      const aggregate = getOrCreateAndSetAggregate(
        dayToAggregate,
        date,
        patientDTO,
        source
      );

      addPatientInsulinLog(aggregate, patientInsulinLogDTO);
    }
  }

  if (patientActivityLogs !== undefined) {
    for (const patientActivityLog of patientActivityLogs) {
      const date = patientActivityLog.date;

      const source = undefined;
      const aggregate = getOrCreateAndSetAggregate(
        dayToAggregate,
        date,
        patientDTO,
        source
      );

      addPatientActivity(aggregate, patientActivityLog);
    }
  }
  const daysAggregates = convertDayToAggregateToDaysAggregates(dayToAggregate);

  // Sort descendingly by date
  return daysAggregates.sort((a, b) =>
    Temporal.PlainDate.compare(b.date, a.date)
  );
}

function getOrCreateAndSetAggregate(
  dayToAggregate: DayToAggregate,
  date: string,
  patientDTO: PatientDTO,
  source?: Source
): InternalAggregate {
  return getOrCreateAndSet(dayToAggregate, date, () =>
    createInitialAggregate(patientDTO, source!)
  );
}

function getOrCreateAndSetMeal(
  timeToMeal: TimeToMeal,
  time: string
): InternalMeal {
  return getOrCreateAndSet(timeToMeal, time, createInitialMeal);
}

function getOrCreateAndSetMedicine(
  timeToMedicine: TimeToMedicine,
  time: string
): InternalMedicine {
  return getOrCreateAndSet(timeToMedicine, time, createInitialMedicine);
}

function getOrCreateAndSet<MapValueType>(
  map: Map<string, MapValueType>,
  key: string,
  create: () => MapValueType
): MapValueType {
  let value = map.get(key);

  if (value === undefined) {
    value = create();
    map.set(key, value);
  }

  return value;
}

function createInitialAggregate(
  patientDTO: PatientDTO,
  source: Source
): InternalAggregate {
  const inRangeNumbers = getInRangeNumbers(patientDTO.type);

  return {
    readings: [],
    timeToMeal: new Map(),
    timeToMedicine: new Map(),
    maxGlucose: 0,
    minGlucose: Number.MAX_VALUE,
    inRangeNumbers,
    rangesCounts: {
      below: 0,
      inRange: 0,
      above: 0,
    },
    source,
    patientMealDTO: [],
    patientActivityLogs: [],
    patientInsulinLogDTOs: [],
    patientCGMEntryDTOs: [],
  };
}

function createInitialMeal(): InternalMeal {
  return {
    items: [],
    carbs: 0,
    fats: 0,
  };
}

function createInitialMedicine(): InternalMedicine {
  return {
    items: [],
  };
}

function addReading(
  aggregate: InternalAggregate,
  patientCGMEntryDTO: PatientCGMEntryDTO
): void {
  const plainTime = Temporal.PlainTime.from(patientCGMEntryDTO.time);
  const glucose = patientCGMEntryDTO.glucoseMGPerDL;
  const tag = patientCGMEntryDTO.tag;
  const source = patientCGMEntryDTO.source;

  aggregate.readings.push({ time: plainTime, glucose, tag, source });
  aggregate.patientCGMEntryDTOs.push(patientCGMEntryDTO);
}

function addPatientMeal(
  aggregate: InternalAggregate,
  patientMeal: PatientMealDTO
): void {
  aggregate.patientMealDTO.push(patientMeal);
}

function addPatientActivity(
  aggregate: InternalAggregate,
  patientActivityLog: PatientActivityLogDTO
): void {
  aggregate.patientActivityLogs.push(patientActivityLog);
}

function addPatientInsulinLog(
  aggregate: InternalAggregate,
  patientInsulinLogDTO: PatientInsulinLogDTO
): void {
  aggregate.patientInsulinLogDTOs.push(patientInsulinLogDTO);
}

function updateMaxGlucose(
  aggregate: InternalAggregate,
  patientCGMEntryDTO: PatientCGMEntryDTO
): void {
  aggregate.maxGlucose = Math.max(
    aggregate.maxGlucose,
    patientCGMEntryDTO.glucoseMGPerDL
  );
}

function updateMinGlucose(
  aggregate: InternalAggregate,
  patientCGMEntryDTO: PatientCGMEntryDTO
): void {
  aggregate.minGlucose = Math.min(
    aggregate.minGlucose,
    patientCGMEntryDTO.glucoseMGPerDL
  );
}

function updateRangesCounts(
  aggregate: InternalAggregate,
  patientCGMEntryDTO: PatientCGMEntryDTO,
  inRangeNumbers: [number, number]
): void {
  if (patientCGMEntryDTO.glucoseMGPerDL < inRangeNumbers[0]) {
    aggregate.rangesCounts.below++;
  } else if (patientCGMEntryDTO.glucoseMGPerDL <= inRangeNumbers[1]) {
    aggregate.rangesCounts.inRange++;
  } else {
    aggregate.rangesCounts.above++;
  }
}

function addMealItem(
  meal: InternalMeal,
  patientServingDTO: PatientServingDTO
): void {
  const name = patientServingDTO.foodName;
  const amount = patientServingDTO.amount;

  meal.items.push({ name, amount });
}

function updateCarbs(
  meal: InternalMeal,
  patientServingDTO: PatientServingDTO
): void {
  meal.carbs += patientServingDTO.nutrition.carbsG || 0;
}

function updateFats(
  meal: InternalMeal,
  patientServingDTO: PatientServingDTO
): void {
  meal.fats += patientServingDTO.nutrition.fatG || 0;
}

function addMedicineItem(
  medicine: InternalMedicine,
  patientServingDTO: PatientServingDTO
): void {
  const name = patientServingDTO.foodName;
  const amount = patientServingDTO.amount;

  medicine.items.push({ name, amount });
}

function convertDayToAggregateToDaysAggregates(
  dayToAggregate: DayToAggregate
): DayAggregate[] {
  return Array.from(dayToAggregate, ([date, aggregate]) => ({
    ...aggregate,
    date: Temporal.PlainDate.from(date),
    meals: convertTimeToMealToMeals(aggregate.timeToMeal),
    medicines: convertTimeToMedicineToMedicines(aggregate.timeToMedicine),
  })).sort((a, b) => Temporal.PlainDate.compare(a.date, b.date));
}

function convertTimeToMealToMeals(timeToMeal: TimeToMeal): Meal[] {
  return Array.from(timeToMeal, ([time, meal]) => ({
    ...meal,
    time: Temporal.PlainTime.from(time),
  })).sort((a, b) => Temporal.PlainTime.compare(a.time, b.time));
}

function convertTimeToMedicineToMedicines(
  timeToMedicine: TimeToMedicine
): Medicine[] {
  return Array.from(timeToMedicine, ([time, medicine]) => ({
    ...medicine,
    time: Temporal.PlainTime.from(time),
  })).sort((a, b) => Temporal.PlainTime.compare(a.time, b.time));
}
