import { FormProvider, useForm } from "react-hook-form";
import { PatientPlanFeedbackTone } from "../../../../../../../models/patient-plan-dtos/patient-plan-feedback-dto";
import { WeightLossModal } from "./weight-loss-modal";

type WeightLossModalContainerProps = {
  resetCurrentFeedbackModal: () => void;
};

export type WeightLossModalFormInputs = {
  feedbackTone?: PatientPlanFeedbackTone;
  notes?: string;
};

export const WeightLossModalContainer = (
  props: WeightLossModalContainerProps
) => {
  const { resetCurrentFeedbackModal } = props;

  const methods = useForm<WeightLossModalFormInputs>({
    defaultValues: {
      feedbackTone: undefined,
      notes: undefined,
    },
  });

  return (
    <FormProvider {...methods}>
      <WeightLossModal resetCurrentFeedbackModal={resetCurrentFeedbackModal} />
    </FormProvider>
  );
};
