import { Meal } from "../../../cgm-google-chart/utils/build-days-aggregates";
import {
  MealTooltipBody,
  MealTooltipBodyList,
  MealTooltipBodyListItem,
  MealTooltipBodyTitle,
  MealTooltipContainer,
  MealtootipHeader,
  MealtootipHeaderBottom,
  MealtootipHeaderTop,
} from "../../../../styles/classes/tooltips";

type MealsTooltipProps = {
  x: Date;
  y: number;
  data: Meal;
};

export const MealsTooltip = (props: MealsTooltipProps) => {
  const { x, data } = props;

  return (
    <MealTooltipContainer>
      <MealtootipHeader>
        <MealtootipHeaderTop>
          <p>Food Log</p>
          <strong>
            {x.toLocaleString("en-UK", {
              year: undefined,
              month: undefined,
              day: undefined,
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
              second: undefined,
            })}
          </strong>
          <span className="material-symbols-outlined">close</span>
        </MealtootipHeaderTop>
        <MealtootipHeaderBottom>
          <span>Carbs: {data.carbs.toFixed(0)} g</span>
          <span>Fats: {data.fats.toFixed(0)} g</span>
        </MealtootipHeaderBottom>
      </MealtootipHeader>
      <MealTooltipBody>
        <MealTooltipBodyTitle>Meal ingredients</MealTooltipBodyTitle>
        <MealTooltipBodyList>
          {data.items.map((meal, index) => (
            <MealTooltipBodyListItem key={index}>
              <span>{meal.name}</span>
              <strong>{meal.amount}</strong>
            </MealTooltipBodyListItem>
          ))}
        </MealTooltipBodyList>
      </MealTooltipBody>
    </MealTooltipContainer>
  );
};
