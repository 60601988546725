import styled from "styled-components/macro";
import {
  flexCenter,
  regularSemiBoldFont,
} from "../../../../../../../styles/classes/gloabl-classes";

export const MessagingMediaSidebarActiveListHeaderContainer = styled.div`
  ${flexCenter}
  padding: 16px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  position: sticky;
  top: 0px;
  z-index: 3;
  background: ${({ theme }) => theme.colors.containerColor};

  div {
    transform: rotate(180deg);
    ${flexCenter}
    cursor: pointer;

    img {
      width: 25px;
      height: 25px;
    }
  }

  p {
    ${regularSemiBoldFont}
    margin: 0 auto;
  }
`;
