import { useCallback } from "react";
import { PatientNoteDTO } from "../../models/patient-note-dtos/patient-note-dto";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";

export function useGetPatientNotes(): (
  patientId: number
) => Promise<PatientNoteDTO[]> {
  const client = useClient();

  return useCallback(
    async (patientId) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientNoteDTO[]>(
        `${PATIENTS_PATH}/${patientId}/notes`
      );

      return response.data;
    },
    [client]
  );
}
