import styled from "styled-components";
import { mediumSemiBoldFont } from "../../../../../../../styles/classes/gloabl-classes";

export const MealsAnalysisResponseTableRow = styled.tr`
  max-height: 150px;

  td {
    max-width: 300px;
    text-align: center;
    padding: 0px 8px;
    ${mediumSemiBoldFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }

  :nth-child(even) {
    background-color: ${({ theme }) => theme.colors.bodyColor};
  }
`;
