import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { PlansInputLabel } from "../../plan-steps/styles";
import {
  DateInputContainer,
  DateInputWrapper,
} from "./styled-plans-date-input";
import { useCallback } from "react";

type PlansDateInputProps = {
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date) => void;
  label: string;
};

export const PlansDateInput = (props: PlansDateInputProps) => {
  const { setStartDate, setEndDate, label } = props;

  const handleDateRangeChange = useCallback(
    (e: any) => {
      if (e.target.value !== null) {
        setStartDate(e.target.value[0]);
        setEndDate(e.target.value[1]);
      }
    },
    [setStartDate, setEndDate]
  );

  return (
    <DateInputWrapper>
      <PlansInputLabel>{label}</PlansInputLabel>
      <DateInputContainer>
        <DateRangePickerComponent
          placeholder="Select date range"
          format="dd/ M /yyyy"
          separator="$"
          onChange={handleDateRangeChange}
        />
      </DateInputContainer>
    </DateInputWrapper>
  );
};
