import { Temporal } from "temporal-polyfill";
import { PatientMealResponseDTO } from "../../../../../../../../../models/patient-meal-response-dtos/patient-meal-response-dto";
import { PatientDTO } from "../../../../../../../../../models/patient-dtos/patient-dto";
import { MealsAnalysisResponseTableCell } from "../styled-meals-analysis-response-table-cells";
import { MealsAnalysisResponseGraphTableCellChartContainer } from "./styled-meals-analysis-response-graph-table-cell-graph";
import { MealsAnalysisResponseGraphTableCellGraph } from "./meals-analysis-response-graph-table-cell-graph/meals-analysis-response-table-rows-graph";

type MealsAnalysisResponseGraphTableCellProps = {
  tableRow: PatientMealResponseDTO;
  patientDTO: PatientDTO;
};

export const MealsAnalysisResponseGraphTableCell = (
  props: MealsAnalysisResponseGraphTableCellProps
) => {
  const { tableRow, patientDTO } = props;

  const date =
    tableRow.patientInsulinLog !== undefined
      ? Temporal.PlainDate.from(tableRow.patientInsulinLog.date)
      : Temporal.PlainDate.from(tableRow.patientMeal.date);

  const startPlainDateTime =
    tableRow.patientInsulinLog !== undefined
      ? date.toPlainDateTime(
          Temporal.PlainTime.from(tableRow.patientInsulinLog.time)
        )
      : date.toPlainDateTime(
          Temporal.PlainTime.from(tableRow.patientMeal.time)
        );

  return (
    <MealsAnalysisResponseTableCell $graphCell>
      <MealsAnalysisResponseGraphTableCellChartContainer>
        <MealsAnalysisResponseGraphTableCellGraph
          startPlainDateTime={startPlainDateTime}
          patientCGMEntryDTOs={tableRow.patientCGMEntries}
          patientInsulinLogDTOs={tableRow.patientInsulinLogs}
          patientMealDTOs={tableRow.patientMeals}
          patientDTO={patientDTO}
        />
      </MealsAnalysisResponseGraphTableCellChartContainer>
    </MealsAnalysisResponseTableCell>
  );
};
