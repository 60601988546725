import { GenericActionsListItem } from "../../../../../../../../components/generic-actions-list/generic-actions-list-item";
import { Modal } from "../../../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../../../components/modal/use-modal-helpers";
import { FrequencyDTO } from "../../../../../../../../models/frequencies/frequency-dto";
import { CreatePatientPrescriptionMedicationDTO } from "../../../../../../../../models/patient-prescriptions/create-patient-prescription-medication-dto";
import { EditForm } from "./edit-form/edit-form";

type EditMedicineProps = {
  card: CreatePatientPrescriptionMedicationDTO;
  currentFrequency?: FrequencyDTO;
  formHeaderData: {
    name: string | undefined;
    strength: string;
    routeOfAdministration: string | undefined;
    doseUnit: string | undefined;
  };
};

export const EditMedicine = (props: EditMedicineProps) => {
  const { card, currentFrequency, formHeaderData } = props;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <>
      <GenericActionsListItem
        icon={<img src="/img/prescription/edit.svg" alt="Edit Icon" />}
        text="Edit"
        onClick={openModal}
      />
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <EditForm
          key={modalBodyKey}
          onClose={closeModal}
          card={card}
          currentFrequency={currentFrequency}
          formHeaderData={formHeaderData}
        />
      </Modal>
    </>
  );
};
