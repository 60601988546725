import styled from "styled-components/macro";
import { transition } from "../../styles/global-style";

export const ModalContainer = styled.div<{
  showModal: boolean;
}>`
  position: fixed;
  inset: 0;
  z-index: ${({ theme }) => theme.zIndex.z600};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ showModal }) => (showModal ? "100vh" : "0vh")};
  opacity: ${({ showModal }) => (showModal ? "1" : "0")};
  overflow: ${({ showModal }) => (showModal ? "visible" : "hidden")};
  transition: ${transition};

  @media screen and (max-width: 768px) {
    z-index: ${({ theme }) => theme.zIndex.z1000};
  }

  @media screen and (max-width: 500px) {
    height: 100vh;
    transform: ${({ showModal }) =>
      showModal ? "translateY(0%)" : "translateY(200%)"};
    z-index: ${({ theme }) => theme.zIndex.z1000};
  }
`;

export const ModalBackdrop = styled.div<{
  showModal: boolean;
}>`
  background: ${({ theme }) => theme.newColors.backdrop};
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.z500};
  inset: 0;
  height: ${({ showModal }) => (showModal ? "100vh" : "0vh")};
  opacity: ${({ showModal }) => (showModal ? "visible" : "hidden")};
`;
