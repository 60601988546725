import { PatientCGMEntryDTO } from "../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import {
  getAfterMealTag,
  getTag,
} from "../../../../screens/single-patient/bgl-analysis/upload-options/manual-readings/manual-readings-modal/manual-readings-table/tag-dropdown/tag-dropdown-utils";
import {
  ReadingTooltipBody,
  ReadingTooltipInOrOutOfRangeTag,
  ReadingTooltipReadingText,
  ReadingTooltipTagText,
  TooltipContainer,
  TooltipHeader,
} from "../../../../styles/classes/tooltips";

type CgmOrBgmReadingsTooltipProps = {
  x: Date;
  y: number;
  data: PatientCGMEntryDTO & { isInRange: boolean };
};

export const CgmOrBgmReadingsTooltip = (
  props: CgmOrBgmReadingsTooltipProps
) => {
  const { x, y, data } = props;

  return (
    <TooltipContainer>
      <TooltipHeader>
        <p>Glucose</p>
        <strong>
          {x.toLocaleString("en-UK", {
            year: undefined,
            month: undefined,
            day: undefined,
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
            second: undefined,
          })}
        </strong>
        <span className="material-symbols-outlined">close</span>
      </TooltipHeader>
      <ReadingTooltipBody>
        <div>
          <ReadingTooltipReadingText>
            {y} <span>mg/dL</span>
          </ReadingTooltipReadingText>
          <ReadingTooltipTagText>{`${
            data.tag !== undefined ? getTag(data.tag) : ""
          }${
            data.afterMealTag !== undefined
              ? `- ${getAfterMealTag(data.afterMealTag)}`
              : ""
          }`}</ReadingTooltipTagText>
        </div>
        <ReadingTooltipInOrOutOfRangeTag isInRange={data.isInRange}>
          {data.isInRange ? "In Range" : "Out of Range"}
        </ReadingTooltipInOrOutOfRangeTag>
      </ReadingTooltipBody>
    </TooltipContainer>
  );
};
