import { PatientCGMEntryDTO } from "../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientMealDTO } from "../../../../models/patient-meal-dtos/patient-meal-dto";
import { CgmTooltipProps } from "../cgm-tooltip/cgm-tooltip";
import { calculateYaxisValue } from "../cgm-tooltip/utils/calculateYaxisValue";

export type PatientMealsSeries = {
  x: Date;
  y: number;
  tooltip: CgmTooltipProps;
};

export function buildPatientMealsSeries(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientMeals: PatientMealDTO[]
): PatientMealsSeries[] {
  const patientMealsSeries: PatientMealsSeries[] = [];

  for (const patientMeal of patientMeals) {
    const date = patientMeal.date;
    const time = patientMeal.time;

    const x = new Date(`${date}T${time}`);
    const y = calculateYaxisValue(patientCGMEntryDTOs, date, x);

    const tooltip: CgmTooltipProps = {
      x,
      y,
      kind: {
        kind: "PatientMeal",
        data: patientMeal,
      },
    };

    patientMealsSeries.push({ x, y, tooltip });
  }

  return patientMealsSeries;
}
