import {
  ChangeEvent,
  DragEvent,
  MutableRefObject,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

export type ImageUploadState =
  | { state: "Idle" }
  | { state: "Upload"; previewUrl?: string }
  | { state: "Drag"; dragActive: boolean }
  | { state: "Uploaded"; previewUrl: string };

export type ImageUploadHelpers = {
  handleRemoveImage: () => void;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDrag: (event: DragEvent<HTMLFormElement | HTMLDivElement>) => void;
  handleDrop: (event: DragEvent<HTMLDivElement>) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  imageFile: Blob | null;
  setImageFile: (imageFile: Blob | null) => void;
  imageUploadState: ImageUploadState;
  setImageUploadState: (imageUploadState: ImageUploadState) => void;
  handleUploadedImageState: (imageFile: Blob) => void;
  imageFileInputRef: MutableRefObject<HTMLInputElement | null>;
};

const ImageUploadHelpersContext = createContext<ImageUploadHelpers | undefined>(
  undefined
);

type ImageUploadHelpersProviderProps = PropsWithChildren<{}>;

export function ImageUploadHelpersProvider(
  props: ImageUploadHelpersProviderProps
) {
  const { children } = props;

  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState<Blob | null>(null);
  const [imageUploadState, setImageUploadState] = useState<ImageUploadState>({
    state: "Idle",
  });

  const imageFileInputRef = useRef<HTMLInputElement | null>(null);

  const handleRemoveImage = useCallback(() => {
    if (
      imageFile !== null &&
      (imageUploadState.state === "Upload" ||
        imageUploadState.state === "Uploaded")
    ) {
      setImageFile(null);

      setImageUploadState({
        state: "Upload",
        previewUrl: undefined,
      });
    }
  }, [imageFile, imageUploadState.state, setImageFile, setImageUploadState]);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (files && files.length > 0) {
        const selectedFiles = Array.from(files);
        const file = selectedFiles[0];

        if (!file.type.startsWith("image/") || file.type === "image/gif") {
          alert("Please upload a valid image file (no GIFs allowed).");
          return;
        }

        setImageFile(file);

        const readerPromises = selectedFiles.map((file) => {
          return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result as string);
            };
            reader.readAsDataURL(file);
          });
        });

        Promise.all(readerPromises).then((previewUrls) => {
          setImageUploadState({
            state: "Upload",
            previewUrl: previewUrls[0],
          });
        });
      }
    },
    []
  );

  const handleDrag = useCallback(
    (event: DragEvent<HTMLFormElement | HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (event.type === "dragenter" || event.type === "dragover") {
        setImageUploadState({
          state: "Drag",
          dragActive: true,
        });
      } else if (event.type === "dragleave") {
        setImageUploadState({
          state: "Drag",
          dragActive: false,
        });
      }
    },
    []
  );

  const handleDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;

    if (files && files.length > 0) {
      const selectedFiles = Array.from(files);
      const file = selectedFiles[0];

      if (!file.type.startsWith("image/") || file.type === "image/gif") {
        alert("Please upload a valid image file (no GIFs allowed).");
        setImageUploadState({
          state: "Drag",
          dragActive: false,
        });
        return;
      }

      setImageFile(selectedFiles[0]);

      const readerPromises = selectedFiles.map((file) => {
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(readerPromises).then((previewUrls) => {
        setImageUploadState({
          state: "Upload",
          previewUrl: previewUrls[0],
        });
      });
    }
  }, []);

  const handleUploadedImageState = useCallback((imageFile: Blob) => {
    const readerPromise = new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(imageFile);
    });

    Promise.all([readerPromise]).then((previewUrls) => {
      setImageUploadState({
        state: "Uploaded",
        previewUrl: previewUrls[0],
      });
    });
  }, []);

  const value = useMemo(
    () => ({
      handleRemoveImage,
      handleFileChange,
      handleDrag,
      handleDrop,
      loading,
      setLoading,
      imageFile,
      setImageFile,
      imageUploadState,
      setImageUploadState,
      handleUploadedImageState,
      imageFileInputRef,
    }),
    [
      handleRemoveImage,
      handleFileChange,
      handleDrag,
      handleDrop,
      loading,
      setLoading,
      imageFile,
      setImageFile,
      imageUploadState,
      setImageUploadState,
      handleUploadedImageState,
      imageFileInputRef,
    ]
  );

  return (
    <ImageUploadHelpersContext.Provider value={value}>
      {children}
    </ImageUploadHelpersContext.Provider>
  );
}

export function useImageUploadHelpers(): ImageUploadHelpers {
  const context = useContext(ImageUploadHelpersContext);

  if (context === undefined) {
    throw new Error(
      "useImageUploadHelpers must be used within a ImageUploadHelpersProvider"
    );
  }

  return context;
}
