import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";

type NutritionReportProteinSourcesFrequencyTableCellsProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportProteinSourcesFrequencyTableCells = (
  props: NutritionReportProteinSourcesFrequencyTableCellsProps
) => {
  const { kind } = props;

  const proteinsourcesFrequencyTableCells =
    kind.kind === "Row"
      ? kind.data.proteinSources.map((cell) => (
          <NutritionReportTableCell
            cellKind="Row"
            key={cell.proteinSource.id}
            type="Normal"
            value={cell.totalCount}
          />
        ))
      : kind.data.proteinSources.map((cell, index) => (
          <NutritionReportTableCell
            key={index}
            cellKind="Meal"
            type="Normal-bodyColor"
            stringValue={cell}
          />
        ));

  return <>{proteinsourcesFrequencyTableCells}</>;
};
