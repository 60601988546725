import { MessageResponse } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import {
  MediaSidebarMediaListContainer,
  MediaSidebarMediaListDateListItem,
  MediaSidebarMediaListList,
  MediaSidebarMediaListListItem,
} from "./styled-media-sidebar-media-list";
import {
  groupAttachmentsByMonth,
  months,
} from "../../utils/group-attachments-by-month";
import { MediaSidebarMediaListModal } from "./media-sidebar-media-list-modal";
import { useModalHelpers } from "../../../../../../../../../components/modal/use-modal-helpers";
import { useState } from "react";
import { EmptyState } from "../../../../../../../../../components/empty-state/empty-state";

type MediaSidebarMediaListProps = {
  messages: MessageResponse<DefaultStreamChatGenerics>[];
};

export const MediaSidebarMediaList = (props: MediaSidebarMediaListProps) => {
  const { messages } = props;

  const groupedMessages = groupAttachmentsByMonth(messages);

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  const [currentSlide, setCurrentSlide] = useState(0);

  if (groupedMessages.length === 0) {
    return (
      <EmptyState
        hasSearch={false}
        text="No Media Yet"
        title=""
        hasHeader={false}
        button={<></>}
      />
    );
  }

  return (
    <MediaSidebarMediaListContainer>
      {groupedMessages.map((groupedMessage, index) => {
        const messageMonth =
          groupedMessage.created_at !== undefined &&
          months[new Date(groupedMessage.created_at).getMonth()];

        const firstMonth =
          groupedMessages[0].created_at !== undefined &&
          months[new Date(groupedMessages[0].created_at).getMonth()];

        return (
          <MediaSidebarMediaListList key={index}>
            <MediaSidebarMediaListDateListItem>
              <p>
                {messageMonth === firstMonth
                  ? "This Month"
                  : groupedMessage.created_at}
              </p>
            </MediaSidebarMediaListDateListItem>
            {groupedMessage.attachments &&
              groupedMessage.attachments
                .sort((a, b) => {
                  if (a.date && b.date) {
                    return b.date.localeCompare(a.date);
                  } else {
                    return -1;
                  }
                })
                .filter(
                  (attachment) =>
                    attachment.type !== "file" &&
                    attachment.type !== "audio" &&
                    attachment.title_link === undefined
                )
                .map((attachment, groupedMessageIndex) => (
                  <MediaSidebarMediaListListItem
                    key={groupedMessageIndex}
                    onClick={() => {
                      openModal();
                      setCurrentSlide(groupedMessageIndex);
                    }}
                  >
                    {attachment?.image_url ? (
                      <img
                        src={attachment?.image_url}
                        alt={`From ${attachment.author_name}`}
                      />
                    ) : (
                      <video src={attachment?.asset_url} controls></video>
                    )}
                  </MediaSidebarMediaListListItem>
                ))}
          </MediaSidebarMediaListList>
        );
      })}
      <MediaSidebarMediaListModal
        groupedMessages={groupedMessages}
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        modalBodyKey={modalBodyKey}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
      />
    </MediaSidebarMediaListContainer>
  );
};
