import { useImmediateAttentionPatientsSidebar } from "../../context/immediate-attention-patients-sidebar-context";
import { ImmediateAttentionPatientsSidebarPatientKeyIndicatorsContainer } from "./styled-immediate-attention-patients-sidebar-patient-key-indicators";

export const ImmediateAttentionPatientsSidebarPatientKeyIndicators = () => {
  const {
    immediateAttentionPatientsSidebarPatientData,
  } = useImmediateAttentionPatientsSidebar();

  const patientKeyIndicators =
    immediateAttentionPatientsSidebarPatientData.keyIndicators;

  return (
    <ImmediateAttentionPatientsSidebarPatientKeyIndicatorsContainer>
      <p>Key Indicators</p>
      <ul>
        {patientKeyIndicators.map((indicator, index) => (
          <li key={index}>
            <strong>{indicator.title}</strong>
            <span>{indicator.value}</span>
          </li>
        ))}
      </ul>
    </ImmediateAttentionPatientsSidebarPatientKeyIndicatorsContainer>
  );
};
