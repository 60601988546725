import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type ConentModuleMobilePreviewCarousel = {
  currentCard: number;
  goToNextCard: (index: number) => void;
  goToPreviousCard: (index: number) => void;
};

const ConentModuleMobilePreviewCarouselContext = createContext<
  ConentModuleMobilePreviewCarousel | undefined
>(undefined);

type ConentModuleMobilePreviewCarouselProviderProps = PropsWithChildren<{}>;

export const ConentModuleMobilePreviewCarouselProvider = (
  props: ConentModuleMobilePreviewCarouselProviderProps
) => {
  const { children } = props;

  const [currentCard, setCurrentCard] = useState<number>(0);

  const goToNextCard = useCallback((index: number) => setCurrentCard(index), [
    setCurrentCard,
  ]);

  const goToPreviousCard = useCallback(
    (index: number) => setCurrentCard(index),
    [setCurrentCard]
  );

  const value = useMemo(
    () => ({ currentCard, goToNextCard, goToPreviousCard }),
    [currentCard, goToNextCard, goToPreviousCard]
  );

  return (
    <ConentModuleMobilePreviewCarouselContext.Provider value={value}>
      {children}
    </ConentModuleMobilePreviewCarouselContext.Provider>
  );
};

export const useConentModuleMobilePreviewCarousel = () => {
  const context = useContext(ConentModuleMobilePreviewCarouselContext);

  if (context === undefined) {
    throw new Error(
      "useConentModuleMobilePreviewCarousel must be used within a ConentModuleMobilePreviewCarouselProvider"
    );
  }

  return context;
};
