import styled from "styled-components/macro";

export const ActionIconsContainer = styled.div`
  span {
    font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};

    :nth-child(1) {
      color: ${({ theme }) => theme.colors.success};
      margin-right: 0.5rem;
    }

    :nth-child(2) {
      color: ${({ theme }) => theme.colors.danger};
    }
  }
`;
