import { PatientNutritionReportDTO } from "../../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-dto";
import { NutritionReportSummaryScreenContainer } from "../../../../../../food-logging/nutrition-report/nutrition-report-summary/nutrition-report-summary-screen/styled-nutrition-report-summary-response";
import { ViewNutritionReportSummaryHeader } from "./view-nutrition-report-summary-header/view-nutrition-report-summary-header";
import { ViewNutritionReportSummaryList } from "./view-nutrition-report-summary-list/view-nutrition-report-summary-list";
import { ViewNutritionReportSummaryNotes } from "./view-nutrition-report-summary-notes/view-nutrition-report-summary-notes";

type ViewNutritionReportSummaryProps = {
  patientNutritionReport: PatientNutritionReportDTO;
};

export const ViewNutritionReportSummary = (
  props: ViewNutritionReportSummaryProps
) => {
  const { patientNutritionReport } = props;

  return (
    <div>
      <ViewNutritionReportSummaryHeader
        patientNutritionReport={patientNutritionReport}
      />
      <NutritionReportSummaryScreenContainer viewReportUI={true}>
        <ViewNutritionReportSummaryList
          patientNutritionReport={patientNutritionReport}
        />
        <ViewNutritionReportSummaryNotes
          patientNutritionReport={patientNutritionReport}
        />
      </NutritionReportSummaryScreenContainer>
    </div>
  );
};
