import { useEffect } from "react";
import { Button } from "../../../../../styles/classes/reusable-classes";
import { usePlansSteps } from "../../context/plans-steps-context";
import { CreatePlansFooterContainer } from "./styled-create-plans-footer";

type NextOrPrevStep =
  | "Goals"
  | "Feedback"
  | "Medical"
  | "Nutrition"
  | "Lifestyle"
  | "Education"
  | "Review"
  | "";

type CreatePlansFooterProps = {
  nextStep: NextOrPrevStep;
  prevStep: NextOrPrevStep;
};

export const CreatePlansFooter = (props: CreatePlansFooterProps) => {
  const { nextStep, prevStep } = props;
  const { handleSetCurrentStep, handleSetCompletedSteps } = usePlansSteps();

  useEffect(() => {
    handleSetCompletedSteps();
  }, [handleSetCompletedSteps]);

  const handleNextClick = () => {
    if (nextStep !== "") {
      handleSetCurrentStep(nextStep);
    }
  };

  const handleBackClick = () => {
    if (prevStep !== "") {
      handleSetCurrentStep(prevStep);
    }
  };

  return (
    <CreatePlansFooterContainer>
      <Button flex outlinedPrimary type="button" onClick={handleBackClick}>
        <span className="material-symbols-outlined">navigate_before</span> Back
      </Button>
      <Button flex type="button" onClick={handleNextClick}>
        Next <span className="material-symbols-outlined">navigate_next</span>
      </Button>
    </CreatePlansFooterContainer>
  );
};
