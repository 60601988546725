import { useState } from "react";
import { useParams } from "react-router-dom";
import { ActionIcons } from "../../../../../../../../components/action-icons/action-icons";
import {
  TD,
  TDContainer,
  TDInput,
} from "../../../../../../../../components/table/styled-table";
import { useAddPatientCGMEntry } from "../../../../../../../../hooks/use-add-patient-cgm-entry";
import { useRowsActions } from "../../context/rows-actions-context";
import { TagDropdown } from "../tag-dropdown/tag-dropdown";
import { CrudListContainer } from "./styled-table-rows";
import { CreatePatientCGMEntryDTO } from "../../../../../../../../models/patient-cgm-entry-dtos/create-patient-cgm-entry-dto";
import { getAfterMealTag, getTag } from "../tag-dropdown/tag-dropdown-utils";

type Props = {
  cancelAdding: () => void;
  date: string;
  onReadingsChanged: () => void;
};

export const AddNewTableRow = (props: Props) => {
  const { cancelAdding, date, onReadingsChanged } = props;

  const { id } = useParams<{ id: string }>();

  const addPatientCGMEntry = useAddPatientCGMEntry();
  const { cancelOrConfirm } = useRowsActions();

  const [createPatientCGMEntryDTO, setCreatePatientCGMEntryDTO] =
    useState<CreatePatientCGMEntryDTO>(() => ({
      date: date,
      time: "00:00:00",
      glucoseMGPerDL: 0,
    }));

  async function handleConfirmAdding() {
    try {
      await addPatientCGMEntry(parseInt(id!), createPatientCGMEntryDTO);
      onReadingsChanged();
    } catch (e) {
      console.error(e);
    }

    cancelOrConfirm();
  }

  // The goal of `.substring` below is to fix this bug:
  // https://app.asana.com/0/1204250435622327/1204423142310952
  // https://stackoverflow.com/questions/34548724/how-to-correctly-use-time-input-element

  return (
    <tr>
      <TD>
        <TDContainer>
          <TDInput
            type="time"
            value={createPatientCGMEntryDTO.time.substring(0, 5)}
            onChange={(e) =>
              setCreatePatientCGMEntryDTO({
                ...createPatientCGMEntryDTO,
                time: e.target.value + ":00",
              })
            }
          />
        </TDContainer>
      </TD>
      <TD>
        <TDContainer>
          <TDInput
            type="text"
            value={createPatientCGMEntryDTO.glucoseMGPerDL}
            onChange={(e) =>
              setCreatePatientCGMEntryDTO({
                ...createPatientCGMEntryDTO,
                glucoseMGPerDL: Number(e.target.value),
              })
            }
          />
        </TDContainer>
      </TD>
      <TD>
        <TDContainer>
          <TagDropdown
            value={
              createPatientCGMEntryDTO.afterMealTag !== undefined
                ? `${getTag(createPatientCGMEntryDTO.tag!)} - ${getAfterMealTag(
                    createPatientCGMEntryDTO.afterMealTag!
                  )}`
                : getTag(createPatientCGMEntryDTO.tag!)
            }
            onChange={(value, afterMealValue) =>
              setCreatePatientCGMEntryDTO({
                ...createPatientCGMEntryDTO,
                tag: getTag(value!),
                afterMealTag: getAfterMealTag(afterMealValue!),
              })
            }
          />
        </TDContainer>
      </TD>
      <TD>
        <CrudListContainer>
          <ActionIcons
            onConfirm={handleConfirmAdding}
            onCancel={cancelAdding}
          />
        </CrudListContainer>
      </TD>
    </tr>
  );
};
