export function timeStringToMinutes(timeString: string) {
  const [hourStr, minuteStr] = timeString.split(":");
  let hour = parseInt(hourStr);
  const minute = parseInt(minuteStr.substring(0, 2));
  const period = timeString.substring(timeString.length - 2);

  if (period === "PM" && hour !== 12) {
    hour += 12;
  } else if (period === "AM" && hour === 12) {
    hour = 0;
  }

  return hour * 60 + minute;
}
