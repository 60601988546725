import { useCallback } from "react";
import { AutoSelectForMedications } from "../../../../../components/auto-select-for-medications/auto-select-for-medications";
import { MedicationDTO } from "../../../../../models/medications/medication-dto";
import { useGetMedicationDTOs } from "../../hooks/use-get-medication-dtos";

export type MedicationAutoSelectProps = {
  onChange: (value: MedicationDTO) => void;
  errorState?: boolean;
  editValue: MedicationDTO | "";
};

export function MedicationAutoSelect(props: MedicationAutoSelectProps) {
  const { onChange, errorState, editValue } = props;

  const { medicationDTOs } = useGetMedicationDTOs();

  const display = useCallback((medicationDTO: MedicationDTO) => {
    let result = medicationDTO.name;

    if (medicationDTO.strength !== undefined) {
      result += ` - ${medicationDTO.strength}`;
    }

    if (medicationDTO.doseUnit !== undefined) {
      result += ` - ${medicationDTO.doseUnit}`;
    }

    return result;
  }, []);

  const editDefaultValue =
    editValue !== ""
      ? `${editValue.name}${
          editValue.strength !== undefined ? ` - ${editValue.strength}` : ``
        }${editValue.doseUnit !== undefined ? ` - ${editValue.doseUnit}` : ``}`
      : undefined;

  return (
    <AutoSelectForMedications<MedicationDTO>
      errorState={errorState}
      options={medicationDTOs}
      display={display}
      onChange={onChange}
      label="Medication"
      placeHolder="Select medication name"
      defaultValue={editDefaultValue}
    />
  );
}
