import {
  AxisModel,
  Category,
  ChartComponent,
  DataLabel,
  Inject,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { PatientWeightDTO } from "../../../../../../models/patient-weight-dtos/patient-weight-dto";
import { useCallback, useMemo } from "react";
import { PaitnetProfileChartContainer } from "../../../styles/patient-profile-styles/patient-profile-classes";
import { randomUUID } from "../../../../../../utils/random-uuid";

type PatientProfileWeightChartProps = {
  filteredPatientWeightDTOsByDateRange?: PatientWeightDTO[];
  formatedDatesPatientWeightDTOs: PatientWeightDTO[];
  startDate?: Date;
  endDate?: Date;
};

export const PatientProfileWeightChart = (
  props: PatientProfileWeightChartProps
) => {
  const {
    filteredPatientWeightDTOsByDateRange,
    formatedDatesPatientWeightDTOs,
    startDate,
    endDate,
  } = props;

  const primaryxAxis: AxisModel = {
    valueType: "Category",
    majorGridLines: { width: 0 },
    edgeLabelPlacement: "Shift",
    title: "Date",
  };

  const primaryyAxis: AxisModel = {
    minimum: 50,
    title: "Weight (Kg)",
  };

  const marker = {
    visible: true,
    width: 10,
    height: 10,
    isFilled: true,
    fill: "#1097cf",
    border: { width: 0, color: "transparent" },
    dataLabel: { visible: true },
  };

  const id = useMemo(() => `patient-profile-weight-chart-${randomUUID()}`, []);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);
    // Should always enter this condition, but let's be safe
    if (chart) {
      chart.style.overflow = "visible";
      chart.style.display = "block";
    }
  }, [id]);

  return (
    <PaitnetProfileChartContainer>
      <ChartComponent
        id={id}
        primaryXAxis={primaryxAxis}
        primaryYAxis={primaryyAxis}
        title="Weight Progress"
        height="100%"
        loaded={handleChartLoaded}
      >
        <Inject services={[Category, LineSeries, StripLine, DataLabel]} />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={
              filteredPatientWeightDTOsByDateRange !== undefined &&
              startDate !== undefined &&
              endDate !== undefined
                ? filteredPatientWeightDTOsByDateRange
                : formatedDatesPatientWeightDTOs
            }
            xName="date"
            yName="weightKg"
            opacity={1}
            zOrder={110}
            type="Line"
            width={3}
            fill="#00086b"
            marker={marker}
          ></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    </PaitnetProfileChartContainer>
  );
};
