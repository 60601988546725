import styled from "styled-components/macro";
import {
  NavbarHeight,
  SinglePatientSidebarWidth,
  transition,
} from "../../../styles/global-style";

export const SinglePatientContainer = styled.div`
  display: flex;
  align-items: flex-start;
  overflow-x: hidden;

  @media screen and (max-width: 1024px) {
    width: 100%;
    flex: 1;
    position: relative;
  }
`;

export const SinglePatientMain = styled.main<{
  $showSinglePatientSidebarUI: boolean;
  plansLayout: boolean;
  isPatientProfileRoute: boolean;
}>`
  width: ${({
    plansLayout,
    $showSinglePatientSidebarUI,
    isPatientProfileRoute,
  }) =>
    isPatientProfileRoute
      ? "100%"
      : plansLayout && !$showSinglePatientSidebarUI
      ? "100%"
      : `calc(100% - (${SinglePatientSidebarWidth}))`};
  transition: ${transition};
  height: calc(100vh - ${NavbarHeight});
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    flex: 1;
  }
`;
