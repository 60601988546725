import { Temporal } from "temporal-polyfill";
import { Medicine } from "../../build-days-aggregates";
import { GoogleDataTableRow } from "react-google-charts";
import { isMobileOrTablet } from "../../../../../utils/device-utils";
import { medicineToHtmlTooltip } from "./medicine-to-html-tooltip";
import { buildRow } from "../build-row";
import { MEDICINE_GLUCOSE_POINT } from "../../../../../constants/config";

export function buildMedicineRow(
  datetime: Temporal.PlainDateTime,
  reverseVAxisTicks: number[],
  medicine: Medicine
): GoogleDataTableRow {
  let bigPointSize!: number;
  if (isMobileOrTablet()) {
    bigPointSize = 4;
  } else {
    bigPointSize = 4;
  }

  const tooltip = medicineToHtmlTooltip(medicine);

  const style = `
  point {
    size: ${bigPointSize};
    shape-type: circle;
    fill-color: #219653;
    visible :true
  }`;

  return buildRow({
    datetime,
    reverseVAxisTicks,
    isNotGlucose: {
      value: MEDICINE_GLUCOSE_POINT,
      tooltip,
    },
    isNotGlucoseStyle: style,
  });
}
