import styled from "styled-components/macro";
import { flexCenter } from "../../../../../../styles/classes/gloabl-classes";
import { lightShadowSecondary } from "../../../../../../styles/global-style";

export const LabResultTestButtonsContainer = styled.div`
  ${flexCenter}
  gap: 0px 16px;
  position: fixed;
  padding: 20px 24px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 525px;
  ${lightShadowSecondary}
  border-radius: 14px;
  background: ${({ theme }) => theme.colors.containerColor};

  @media (min-width: 1024px) and (max-width: 1330px) {
    left: 40%;
  }
`;
