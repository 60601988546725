import { useCallback, useMemo } from "react";
import { BgmChart } from "../../../../../components/bgm-chart/bgm-chart";
import { ManualReadingsChartContainer } from "./styled-manual-readings-chart";
import { useGetPatientInsulinLogDTOs } from "../../../../../components/cgm-google-chart/hooks/use-get-patient-insulin-log-dtos";
import { useParams } from "react-router-dom";
import { useGetPatient } from "../../../../../hooks/patient-hooks/use-get-patient";
import { Loading } from "../../../../../components/loading";
import { buildInsulinDosesSeries } from "./utils/build-insulin-doses-series";

type ManualReadingsChartProps = {
  startDate: string;
  endDate: string;
  tagValue: string;
};

export const ManualReadingsChart = (props: ManualReadingsChartProps) => {
  const { startDate, endDate, tagValue } = props;

  const { patientInsulinLogDTOs } = useGetPatientInsulinLogDTOs();

  const insulinSeriesDataSource = useMemo(
    () => buildInsulinDosesSeries(patientInsulinLogDTOs),
    [patientInsulinLogDTOs]
  );

  const { id } = useParams();
  const patientID = parseInt(id!);

  const getPatient = useGetPatient();

  const load = useCallback(async () => await getPatient(patientID), [
    patientID,
    getPatient,
  ]);

  return (
    <ManualReadingsChartContainer>
      <Loading
        load={load}
        successComponent={(patientDTO) => (
          <BgmChart
            startDate={startDate}
            endDate={endDate}
            tagValue={tagValue}
            containerHeight="auto"
            patientType={patientDTO.type}
            hasTooltip
            insulinSeriesDataSource={insulinSeriesDataSource}
          />
        )}
      />
    </ManualReadingsChartContainer>
  );
};
