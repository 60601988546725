import { Temporal } from "temporal-polyfill";
import { GoogleDataTableRow } from "react-google-charts";
import { isMobileOrTablet } from "../../../../../utils/device-utils";
import { buildRow } from "../build-row";
import { PatientInsulinLogDTO } from "../../../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";
import { insulinToHtmlTooltip } from "./insulin-to-html-tooltip";

export function buildInsulinRow(
  datetime: Temporal.PlainDateTime,
  reverseVAxisTicks: number[],
  patientInsulinLogDTO: PatientInsulinLogDTO
): GoogleDataTableRow {
  let bigPointSize!: number;
  if (isMobileOrTablet()) {
    bigPointSize = 4;
  } else {
    bigPointSize = 4;
  }

  const tooltip = insulinToHtmlTooltip(patientInsulinLogDTO);

  const style = `
  point {
    size: ${bigPointSize};
    shape-type: circle;
    fill-color: #df0fdf;
    visible :true
  }`;

  return buildRow({
    datetime,
    reverseVAxisTicks,
    isNotGlucose: {
      value: 150,
      tooltip,
    },
    isNotGlucoseStyle: style,
  });
}
