import { useFieldArray, useFormContext } from "react-hook-form";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../styles/classes/reusable-classes";
import { TargetAudienceAutoselect } from "../../../../components/target-audience-autoselect/target-audience-autoselect";
import { useCallback } from "react";
import { TargetAudienceDTO } from "../../../../../../models/target-audience-dtos/target-audience-dto";
import { LoadableDataLoading } from "../../../../../../components/loadable-data-loading";
import { LoadableContentModuleTargetAudienceProvider } from "../../../../context/loadable-content-modules-target-audience-context";
import { useGetTargetAudiences } from "../../../../../../hooks/target-audience-hooks/use-get-target-audiences";
import { useLoadableData } from "../../../../../../hooks/use-loadable-data";
import { CreateLessonInputs } from "../../create-lesson";

export const CreateLessonTargetAudienceController = () => {
  const { control, watch } = useFormContext<CreateLessonInputs>();
  const targetAudienceWatcher = watch("targetAudience");

  const { append, fields, remove } = useFieldArray<CreateLessonInputs>({
    name: "targetAudience",
    control,
  } as never);

  const handleAppend = useCallback(
    (targetAudienceDTO: TargetAudienceDTO) => append(targetAudienceDTO),
    [append]
  );

  const handleRemove = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const getTargetAudiences = useGetTargetAudiences();

  const loadTargetAudience = useCallback(async () => {
    const [targetAudienceDTOs] = await Promise.all([getTargetAudiences()]);

    return { targetAudienceDTOs };
  }, [getTargetAudiences]);

  const loadableTargetAudience = useLoadableData(loadTargetAudience);

  return (
    <div>
      <UXInputContainer>
        <UXInputLabel $width="150px" $isOptional>
          TargetAudience
          <span>(Optional)</span>
        </UXInputLabel>
        <LoadableDataLoading
          state={loadableTargetAudience.state}
          successComponent={() => (
            <LoadableContentModuleTargetAudienceProvider
              loadableContentModuleTargetAudience={loadableTargetAudience}
            >
              <TargetAudienceAutoselect
                handleAppend={handleAppend}
                targetAudienceWatcher={targetAudienceWatcher}
                handleRemove={handleRemove}
                fields={fields as TargetAudienceDTO[]}
              />
            </LoadableContentModuleTargetAudienceProvider>
          )}
        />
      </UXInputContainer>
    </div>
  );
};
