import { ReactNode } from "react";
import { CarbCounterFoodLoggingSelectedPatientMealMedia } from "./carb-counter-food-logging-selected-patient-meal-media/carb-counter-food-logging-selected-patient-meal-media";
import { CarbCounterFoodLoggingSelectedPatientMealHeaderContainer } from "./styled-carb-counter-food-logging-selected-patient-meal-header";
import { PatientMealDTO } from "../../../../../models/patient-meal-dtos/patient-meal-dto";

type CarbCounterFoodLoggingSelectedPatientMealHeaderProps = {
  patientMealDTO: PatientMealDTO;
  carbCounterFoodLoggingSelectedPatientMealDetails: ReactNode;
};

export const CarbCounterFoodLoggingSelectedPatientMealHeader = (
  props: CarbCounterFoodLoggingSelectedPatientMealHeaderProps
) => {
  const { patientMealDTO, carbCounterFoodLoggingSelectedPatientMealDetails } =
    props;

  return (
    <CarbCounterFoodLoggingSelectedPatientMealHeaderContainer>
      <CarbCounterFoodLoggingSelectedPatientMealMedia
        patientMealDTO={patientMealDTO}
      />
      {carbCounterFoodLoggingSelectedPatientMealDetails}
    </CarbCounterFoodLoggingSelectedPatientMealHeaderContainer>
  );
};
