import { PropsWithChildren } from "react";
import { DateRangeProvider } from "../../../../../components/date-range/context/date-range-context";

type CGMReportProvidersProps = PropsWithChildren<{}>;

export const CGMReportProviders = (props: CGMReportProvidersProps) => {
  const { children } = props;

  return <DateRangeProvider>{children}</DateRangeProvider>;
};
