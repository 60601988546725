import { Button } from "../../../../../styles/classes/reusable-classes";
import { PlanStepInfo } from "../../components/plan-step-info/plan-step-info";
import { PlanStepsHeader } from "../../components/plan-steps-header/plan-steps-header";
import { usePlansGoals } from "../../context/goals-context";
import { GoalsScreenContainer } from "../goals/styled-goals";
import { GoalsListItem } from "../styles";
import { ReviewCard } from "./styled-review";

export const ReviewNutrition = () => {
  const { assignedNutritionGoalsList, handleDeleteGoal } = usePlansGoals();

  const nutrition = assignedNutritionGoalsList.length > 0 && (
    <ReviewCard>
      <PlanStepsHeader
        title="Nutrition goals"
        subTitle="Add nutrition item for you patient"
        icon="/img/plans/nav/nutrition.svg"
        button={<></>}
      />
      <GoalsScreenContainer>
        <ul>
          {assignedNutritionGoalsList.map((goal, index) => (
            <GoalsListItem key={index}>
              <PlanStepInfo
                arabicFlex
                icon="/img/plans/nav/nutrition.svg"
                title={goal.goal}
                subTitle=""
              />
              <Button
                flex
                type="button"
                outlinedPrimary
                onClick={() =>
                  handleDeleteGoal({
                    goal: goal.goal,
                    goalType: "NutritionInstruction",
                  })
                }
              >
                <img src="/img/prescription/delete.svg" alt="Delete Icon" />
                Delete
              </Button>
            </GoalsListItem>
          ))}
        </ul>
      </GoalsScreenContainer>
    </ReviewCard>
  );
  return <>{nutrition}</>;
};
