import { PATIENTS_PATH } from "../constants/paths";
import { useClient } from "../context/client-context";
import { useCallback } from "react";
import { PatientDTO } from "../models/patient-dtos/patient-dto";
import { ImportNewPatientCGMEntriesFromLibreviewDTO } from "../models/import-new-patient-cgm-entries-from-libreview-dto";

export function useImportNewCGMEntriesFromLibreview(): (
  patientDTO: PatientDTO,
  importNewPatientCGMEntriesFromLibreviewDTO: ImportNewPatientCGMEntriesFromLibreviewDTO
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientDTO, importNewPatientCGMEntriesFromLibreviewDTO) => {
      await client.post<void>(
        `${PATIENTS_PATH}/${patientDTO.id!}/cgm-entries/import-new-from-libreview`,
        importNewPatientCGMEntriesFromLibreviewDTO
      );
    },
    [client]
  );
}
