import { useCallback } from "react";
import { FrequencyDTO } from "../../../../../models/frequencies/frequency-dto";
import { AutoSelectForMedications } from "../../../../../components/auto-select-for-medications/auto-select-for-medications";
import { useGetFrequencyDTOs } from "../../hooks/use-get-frequency-dtos";

type FrequencyAutoSelectProps = {
  onChange: (value: FrequencyDTO) => void;
  errorState?: boolean;
  defaultValue?: string;
};

export const FrequencyAutoSelect = (props: FrequencyAutoSelectProps) => {
  const { onChange, errorState, defaultValue } = props;

  const { frequencyDTOs } = useGetFrequencyDTOs();

  const display = useCallback((frequencyDTO: FrequencyDTO) => {
    let result = ``;

    if (frequencyDTO.abbreviation !== undefined) {
      result += `${frequencyDTO.explanation} (${frequencyDTO.abbreviation})`;
    }

    return result;
  }, []);

  return (
    <AutoSelectForMedications<FrequencyDTO>
      defaultValue={defaultValue}
      options={frequencyDTOs}
      display={display}
      onChange={onChange}
      label="Frequency"
      placeHolder="select frequency"
      errorState={errorState}
    />
  );
};
