import styled from "styled-components/macro";
import { transition } from "../../../../../styles/global-style";
import {
  flexCenter,
  flexCenterSpaceBetween,
  flexCol,
  flexColCenterCenter,
  regularMediumFont,
  regularRegularFont,
  regularSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";

export const MessagingMediaSidebarContainer = styled.aside<{
  showMediaSidebar: boolean;
}>`
  border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCol}
  opacity: ${({ showMediaSidebar }) => (showMediaSidebar ? "1" : "0")};
  width: ${({ showMediaSidebar }) => (showMediaSidebar ? "440px" : "0px")};
  overflow-x: hidden;
  overflow-y: ${({ showMediaSidebar }) =>
    showMediaSidebar ? "auto" : "hidden"};
  height: 100%;
  background: ${({ theme }) => theme.colors.containerColor};
  transform: ${({ showMediaSidebar }) =>
    showMediaSidebar ? "translateX(0px)" : "translateX(100%)"};
  transition: ${transition};
`;

export const MessagingMediaSidebarTop = styled.div`
  ${flexColCenterCenter}
  padding: 8px 16px;
  position: relative;

  p {
    ${regularSemiBoldFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }

  .str-chat__avatar {
    margin: 0px auto;
  }
`;

export const MessagingMediaSidebarCloseIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 20px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    opacity: 0.6;
  }
`;

export const MessagingMediaSidebarList = styled.ul`
  padding: 8px 0px;
`;

export const MessagingMediaSidebarListItem = styled.li`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenterSpaceBetween}
  cursor: pointer;
  background: ${({ theme }) => theme.colors.containerColor};
  transition: ${transition};

  p {
    margin: 0px;
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }

  span {
    width: 20px;
    height: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  :hover {
    background: ${({ theme }) => theme.colors.bodyColor};
  }
`;

export const MessagingMediaSidebarParticipantsContainer = styled.div`
  padding: 16px 24px;

  p {
    ${regularSemiBoldFont}
    margin: 0px;
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;

export const MessagingMediaSidebarParticipantsList = styled.ul`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 8px 16px;
  border-radius: 16px;
  max-height: 200px;
  overflow-y: auto;
`;

export const MessagingMediaSidebarParticipantsListItem = styled.li`
  ${flexCenter}
  padding: 8px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

  :last-child {
    border: 0px;
  }

  span {
    ${regularRegularFont}
  }
`;
