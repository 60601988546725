import { MealsAnalysisResponseProviders } from "./context/meals-analysis-response-providers";
import { MealsAnalysisResponseHeader } from "./meals-analysis-response-header/meals-analysis-response-header";
import { MealsAnalysisResponseTable } from "./meals-analysis-response-table/meals-analysis-response-table";

export const MealsAnalysisResponse = () => {
  return (
    <MealsAnalysisResponseProviders>
      <MealsAnalysisResponseHeader />
      <MealsAnalysisResponseTable />
    </MealsAnalysisResponseProviders>
  );
};
