import styled from "styled-components/macro";

export const PatientProfileInformationHeaderCenterContainer = styled.div`
  padding: 0px 24px;
  flex: 1;

  @media screen and (max-width: 1024px) {
    padding: 0px 8px;
  }

  @media screen and (max-width: 1199px) {
    width: 100%;
    margin-top: 16px;
  }
`;
