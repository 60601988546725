import { FormEvent, useCallback, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ModalForm } from "../../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../../components/modal/modal-header/modal-header";
import { useToastService } from "../../../../../../../../context/toast-service-context";
import {
  Button,
  InputLabel,
  ModalFooter,
} from "../../../../../../../../styles/classes/reusable-classes";
import { DiagnosisAutoSelect } from "./diagnosis-auto-select/diagnosis-auto-select";
import {
  DiagnosisAddedTagsContainer,
  DiagnosisIsEmpyText,
  DiagnosisItem,
  DiagnosisList,
  DiagnosisModalBodyContainer,
} from "./styled-diagnosis-modal-body";
import { SidebarDiagnosisModalInputs } from "../diagnosis-modal";
import { PutPatientProfileDiagnosesDTO } from "../../../../../../../../models/patient-profile-diagnosis-dtos/put-patient-profile-diagnoses-dto";
import { usePutPatientProfileDiagnoses } from "../../../../../../../../hooks/patient-profile-diagnoses-hooks/use-put-patient-profile-diagnoses";
import { PutPatientProfileDiagnosesProfileDiagnosisDTO } from "../../../../../../../../models/patient-profile-diagnosis-dtos/put-patient-profile-diagnoses-profile-diagnosis-dto";
import {
  useReloadSinglePatientSidebar,
  useSinglePatientSidebar,
} from "../../../../../context/loadable-single-patient-sidebar-context";

type DiagnosisModalBodyProps = {
  onModalClose: () => void;
};

export const DiagnosisModalBody = (props: DiagnosisModalBodyProps) => {
  const { onModalClose } = props;

  const { patientDTO, profileDiagnoses } = useSinglePatientSidebar();

  const findDiagnosisById = useCallback(
    (profileDiagnosesId: number) =>
      profileDiagnoses.find((item) => item.id === profileDiagnosesId),
    [profileDiagnoses]
  );

  const putPatientProfileDiagnoses = usePutPatientProfileDiagnoses();
  const reloadSinglePatientSidebar = useReloadSinglePatientSidebar();
  const { showToast } = useToastService();

  const { control, watch, handleSubmit } = useFormContext<
    SidebarDiagnosisModalInputs
  >();

  const { fields, remove, append } = useFieldArray({
    name: "profileDiagnoses",
    control,
  } as never);

  const profileDiagnosesWatcher = watch("profileDiagnoses");

  const [autoSelectTagValue, setAutoSelectTagValue] = useState<
    PutPatientProfileDiagnosesProfileDiagnosisDTO | undefined
  >();

  const onSubmit = handleSubmit(async (inputs) => {
    const putPatientProfileDiagnosesDTO: PutPatientProfileDiagnosesDTO = {
      profileDiagnoses: inputs.profileDiagnoses!,
    };

    try {
      await putPatientProfileDiagnoses(
        patientDTO,
        putPatientProfileDiagnosesDTO
      );
      onModalClose();
      showToast("Success", "Diagnosis added successfully!");
      reloadSinglePatientSidebar();
    } catch (e) {
      showToast("Error", "Failed to add Diagnosis :(");
    }
  });

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
  };

  const emptyDiagnosis = (
    <DiagnosisIsEmpyText>No Added Tags</DiagnosisIsEmpyText>
  );

  const currentDiagnosis = fields.map((field, index) => (
    <DiagnosisItem key={field.id}>
      {profileDiagnosesWatcher !== undefined
        ? findDiagnosisById(profileDiagnosesWatcher[index].profileDiagnosisId)
            ?.name
        : ""}
      <span className="material-symbols-outlined" onClick={() => remove(index)}>
        close
      </span>
    </DiagnosisItem>
  ));

  const handleAppend = () => {
    if (
      profileDiagnosesWatcher !== undefined &&
      autoSelectTagValue !== undefined &&
      !profileDiagnosesWatcher.some(
        (obj) =>
          obj.profileDiagnosisId === autoSelectTagValue.profileDiagnosisId
      ) &&
      profileDiagnoses.some(
        (obj) => obj.id === autoSelectTagValue?.profileDiagnosisId
      )
    ) {
      append(autoSelectTagValue);
    }
  };

  return (
    <ModalForm onSubmit={submitHandler} width={608} height={700}>
      <ModalHeader title="Diagnosis" onModalClose={onModalClose} />
      <DiagnosisModalBodyContainer>
        <DiagnosisAutoSelect
          handleAppend={handleAppend}
          autoSelectTagValue={autoSelectTagValue}
          setAutoSelectTagValue={setAutoSelectTagValue}
        />
        <DiagnosisAddedTagsContainer>
          <InputLabel>Added tags</InputLabel>
          <DiagnosisList>
            {profileDiagnosesWatcher.length === 0
              ? emptyDiagnosis
              : currentDiagnosis}
          </DiagnosisList>
        </DiagnosisAddedTagsContainer>
      </DiagnosisModalBodyContainer>
      <ModalFooter>
        <Button type="button" outlined onClick={onModalClose}>
          Cancel
        </Button>
        <Button type="button" onClick={onSubmit}>
          Confirm
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
