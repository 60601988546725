import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Temporal } from "temporal-polyfill";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import { UpdatePatientPlanDTO } from "../../../../../../../models/patient-plan-dtos/update-patient-plan-dto";
import {
  Button,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import { WeightProgressChart } from "../../../../../single-patient-sidebar/patient-sidebar-tabs/personal-info-tab/body-measurments/weight-progress-modal/weight-progress-modal-body/weight-progress-chart";
import { PlansForm } from "../../../../components/plans-form/plans-form";
import { EmptyFeedbackModal } from "../feedback-modal-body/empty-feedback-modal";
import { FeedbackModalModalContainer } from "../feedback-modal-body/styled-feedback-modal-body";
import { WeightLossModalChartContainer } from "./styled-weight-loss-modal";
import { useUpdatePatientPlan } from "../../../../../../../hooks/patient-plan-hooks/use-update-patient-plan";
import { WeightLossModalFormInputs } from "./weight-loss-modal-container";
import { useToastService } from "../../../../../../../context/toast-service-context";
import {
  useDetailedPlan,
  useReloadDetailedPlan,
} from "../../../../context/loadable-detailed-plan-context";

type WeightLossModalProps = {
  resetCurrentFeedbackModal: () => void;
};

export const WeightLossModal = (props: WeightLossModalProps) => {
  const { resetCurrentFeedbackModal } = props;

  const { patientWeightDTOs } = useDetailedPlan();
  const { handleSubmit, watch } = useFormContext<WeightLossModalFormInputs>();
  const updatePatientPlan = useUpdatePatientPlan();
  const reloadPatientDetailedPlan = useReloadDetailedPlan();

  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const temporalStartDate = Temporal.PlainDate.from(
    startDate.toISOString().split("T")[0]
  )
    .add({ days: 1 })
    .toString();
  const temporalEndDate = Temporal.PlainDate.from(
    endDate.toISOString().split("T")[0]
  )
    .add({ days: 1 })
    .toString();

  const weightDTOs = useMemo(
    () =>
      patientWeightDTOs.filter(
        (item) => item.date >= temporalStartDate && item.date <= temporalEndDate
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [temporalStartDate, temporalEndDate]
  );

  const feedbackTone = watch("feedbackTone");
  const notes = watch("notes");

  const { patientDetailedPlanDTO } = useDetailedPlan();
  const { showToast } = useToastService();

  const onSubmit = handleSubmit(async () => {
    const updateDTO: UpdatePatientPlanDTO = {
      ...patientDetailedPlanDTO,
      patientPlanFeedbacks: [
        ...patientDetailedPlanDTO.patientPlanFeedbacks,
        {
          feedbackType: "Weight",
          feedbackTone: feedbackTone!,
          notes,
          weightStartDate: temporalStartDate,
          weightEndDate: temporalEndDate,
        },
      ],
    };

    updatePatientPlan(patientDetailedPlanDTO.patientPlan, updateDTO)
      .then(() => {
        showToast("Success", "Feedback added successfully");
        reloadPatientDetailedPlan();
      })
      .catch(() => showToast("Error", "Failed to add feedback :("));
  });

  const notesCondition = notes === "" || notes === undefined;
  const modalDisabledCondition =
    weightDTOs.length === 0 || feedbackTone === undefined || notesCondition;

  return (
    <ModalForm width={900} height={750} onSubmit={onSubmit}>
      <ModalHeader
        onModalClose={resetCurrentFeedbackModal}
        title="Add Feedback - Weight loss"
        subTitle="Complete the info related to patient's Weight loss"
      />
      <FeedbackModalModalContainer>
        <PlansForm setStartDate={setStartDate} setEndDate={setEndDate} />
        {weightDTOs.length === 0 ? (
          <EmptyFeedbackModal subText="Select start and end date from Graph date to view weight loss graph" />
        ) : (
          <WeightLossModalChartContainer>
            <WeightProgressChart dataSource={weightDTOs} />
          </WeightLossModalChartContainer>
        )}
      </FeedbackModalModalContainer>
      <ModalFooter>
        <Button type="button" outlined onClick={resetCurrentFeedbackModal}>
          Cancel
        </Button>
        <Button type="submit" disabled={modalDisabledCondition}>
          Add
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
