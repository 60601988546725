import { useMemo } from "react";
import { RE_DIGIT } from "./otp-inputs-reg";
import {
  onOtpInputChange,
  onOtpInputFocus,
  onOtpInputKeydown,
} from "./otp-inputs-utils";
import { OtpInput, OtpInputsContainer } from "./styled-otp-inputs";

type OtpInputsProps = {
  value: string;
  valueLength: number;
  onChange: (value: string) => void;
};

export const OtpInputs = (props: OtpInputsProps) => {
  const { onChange, value, valueLength } = props;

  const valueItems = useMemo(() => {
    const valueArray = value.split("");
    const items: string[] = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, valueLength]);

  return (
    <OtpInputsContainer>
      {valueItems.map((htmlInput, index) => (
        <OtpInput
          key={index}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          value={htmlInput}
          onChange={(event) =>
            onOtpInputChange(event, index, value, valueLength, onChange)
          }
          onKeyDown={onOtpInputKeydown}
          onFocus={onOtpInputFocus}
        />
      ))}
    </OtpInputsContainer>
  );
};
