import { ModalForm } from "../../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../../../styles/classes/reusable-classes";
import { useSinglePatientSidebar } from "../../../../../context/loadable-single-patient-sidebar-context";
import { WeightProgressChart } from "./weight-progress-chart";

type WeightProgressModalBodyProps = {
  onClose: () => void;
};

export const WeightProgressModalBody = (
  props: WeightProgressModalBodyProps
) => {
  const { onClose } = props;

  const { patientWeightDTOs } = useSinglePatientSidebar();

  return (
    <ModalForm width={900} height={650} onSubmit={(e) => e.preventDefault()}>
      <ModalHeader title="Weight Progress" onModalClose={onClose} />
      <div style={{ height: "450px" }}>
        <WeightProgressChart dataSource={patientWeightDTOs} />
      </div>
      <ModalFooter>
        <Button type="button" outlined onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
