import { Controller, useFormContext } from "react-hook-form";
import { Dropdown } from "../../../../../../../../components/dropdown/dropdown";
import { DropdownListItem } from "../../../../../../../../components/dropdown/styled-dropdown";
import { InputLabel } from "../../../../../../../../styles/classes/reusable-classes";
import { CarbCounterMealAddFoodModalInputs } from "../../carb-counter-meal-add-food-modal";
import { CarbCounterMealAddFoodModalBodyFoodResponseQuantity } from "./carb-counter-meal-add-food-modal-body-food-response-quantity/carb-counter-meal-add-food-modal-body-food-response-quantity";
import {
  CarbCounterMealAddFoodModalBodyFoodResponseDropdownContainer,
  CarbCounterMealAddFoodModalBodyFoodResponseFoodMeassures,
  CarbCounterMealAddFoodModalBodyFoodResponseFoodName,
} from "./styled-carb-counter-meal-add-food-modal-body-food-response";

export const CarbCounterMealAddFoodModalBodyFoodResponse = () => {
  const { control, watch } =
    useFormContext<CarbCounterMealAddFoodModalInputs>();

  const addFoodResponseWatcher = watch("addFoodResponse");

  return (
    <div>
      {addFoodResponseWatcher !== undefined && (
        <>
          <CarbCounterMealAddFoodModalBodyFoodResponseFoodName>
            {addFoodResponseWatcher.food.name}
          </CarbCounterMealAddFoodModalBodyFoodResponseFoodName>
          <CarbCounterMealAddFoodModalBodyFoodResponseFoodMeassures>
            <Controller
              control={control}
              name="foodMessure"
              render={({ field: { value, onChange } }) => (
                <CarbCounterMealAddFoodModalBodyFoodResponseDropdownContainer>
                  <InputLabel>Food Meassure</InputLabel>
                  <Dropdown
                    text={
                      value === undefined
                        ? "Choose A Food Meassure"
                        : `${value.name} (${value.gramsPerOneUnitOfMeasure} g)`
                    }
                    variant="designed-ux"
                    fullWidth
                  >
                    <DropdownListItem
                      variant="designed-ux"
                      onClick={() => {
                        onChange(addFoodResponseWatcher.suggestedFoodMeasure);
                      }}
                    >
                      {addFoodResponseWatcher.suggestedFoodMeasure.name} (
                      {
                        addFoodResponseWatcher.suggestedFoodMeasure
                          .gramsPerOneUnitOfMeasure
                      }{" "}
                      g) <span>[Suggested]</span>
                    </DropdownListItem>
                    {addFoodResponseWatcher.food.foodMeasures.map(
                      (foodMeassure, index) => (
                        <DropdownListItem
                          key={index}
                          variant="designed-ux"
                          onClick={() => onChange(foodMeassure)}
                        >
                          {foodMeassure.name} (
                          {foodMeassure.gramsPerOneUnitOfMeasure} g)
                        </DropdownListItem>
                      )
                    )}
                  </Dropdown>
                </CarbCounterMealAddFoodModalBodyFoodResponseDropdownContainer>
              )}
            />
            <CarbCounterMealAddFoodModalBodyFoodResponseQuantity />
          </CarbCounterMealAddFoodModalBodyFoodResponseFoodMeassures>
        </>
      )}
    </div>
  );
};
