import { PropsWithChildren, createContext, useContext } from "react";
import { UseLoadableDataReturn } from "../../../../../hooks/use-loadable-data";
import { CarbCounterV1MealSummaryDTO } from "../../../../../models/carb-counter-dtos/carb-counter-v1-meal-summary-dto";

type CarbCounterMealMacros = {
  carbCounterV1MealSummaryDTO: CarbCounterV1MealSummaryDTO;
};

export type LoadableCarbCounterMealMacros =
  UseLoadableDataReturn<CarbCounterMealMacros>;

const LoadableCarbCounterMealContext = createContext<
  LoadableCarbCounterMealMacros | undefined
>(undefined);

type LoadableCarbCounterMealProviderProps = PropsWithChildren<{
  loadableCarbCounterMealMacros: LoadableCarbCounterMealMacros;
}>;

export function LoadableCarbCounterMealProvider(
  props: LoadableCarbCounterMealProviderProps
) {
  const { children, loadableCarbCounterMealMacros } = props;

  return (
    <LoadableCarbCounterMealContext.Provider
      value={loadableCarbCounterMealMacros}
    >
      {children}
    </LoadableCarbCounterMealContext.Provider>
  );
}

export function useLoadableCarbCounterMealMacros(): LoadableCarbCounterMealMacros {
  const loadableCarbCounterMealMacros = useContext(
    LoadableCarbCounterMealContext
  );

  if (loadableCarbCounterMealMacros === undefined) {
    throw Error(
      "useLoadableCarbCounterMealMacros must be used within an LoadableCarbCounterMealProvider"
    );
  }

  return loadableCarbCounterMealMacros;
}

export function useCarbCounterMealMacros(): CarbCounterMealMacros {
  const loadableCarbCounterMealMacros = useLoadableCarbCounterMealMacros();

  if (loadableCarbCounterMealMacros.state.data === undefined) {
    throw Error(
      "CarbCounterMealMacros have never been loaded successfully yet."
    );
  }

  return loadableCarbCounterMealMacros.state.data;
}

export function useReloadCarbCounterMealMacros(): () => void {
  const loadableCarbCounterMealMacros = useLoadableCarbCounterMealMacros();

  return loadableCarbCounterMealMacros.reload;
}
