import { useCallback, useEffect, useMemo } from "react";
import { ImmediateAttentionPatientsTablePriorityFilterContainer } from "./styled-immediate-attention-patients-table-priority-filter";
import { HomeFilterDropdown } from "../components/home-filter-dropdown";
import { useHomeStaticData } from "../../../../../context/home-static-data-context";
import { useImmediateAttentionPatientsSelectedFilters } from "../../../../context/immediate-attention-patients-selected-filters-context";

export const ImmediateAttentionPatientsTablePriorityFilter = () => {
  const {
    setSelectedFilters,
    selectedFilters,
    setPriorityOptions,
    priorityOptions,
  } = useImmediateAttentionPatientsSelectedFilters();

  const { homeStaticData, setFilteredHomeStaticData } = useHomeStaticData();

  const handleAddFilter = useCallback(
    (newPriorityOptions: string[]) => {
      const priority = newPriorityOptions.join(", ");
      const existingFilter = selectedFilters.find(
        (filter) => filter.filterName === "priority"
      );

      if (existingFilter) {
        // Update the existing filter
        setSelectedFilters(
          selectedFilters.map((filter) =>
            filter.filterName === "priority"
              ? { ...filter, filterValue: priority }
              : filter
          )
        );
      } else {
        // Add a new filter
        setSelectedFilters([
          ...selectedFilters,
          { filterName: "priority", filterValue: priority },
        ]);
      }
    },
    [selectedFilters, setSelectedFilters]
  );

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const newPriorityOptions = checked
      ? [...priorityOptions, value]
      : priorityOptions.filter((priority) => priority !== value);

    setPriorityOptions(newPriorityOptions);
    handleAddFilter(newPriorityOptions); // Update filters based on the new state
  };

  const filteredPatients = useMemo(() => {
    if (priorityOptions.length === 0) {
      return homeStaticData;
    }

    return homeStaticData.filter((patient) =>
      priorityOptions.includes(patient.priority)
    );
  }, [priorityOptions, homeStaticData]);

  useEffect(() => {
    setFilteredHomeStaticData(filteredPatients);
  }, [filteredPatients, setFilteredHomeStaticData]);

  return (
    <HomeFilterDropdown
      width="calc(100% + 20px)"
      height="120px"
      buttonText="Priority"
      dropdownOptions={
        <>
          {["High", "Medium", "Low"].map((p) => (
            <ImmediateAttentionPatientsTablePriorityFilterContainer
              key={p}
              $color={p}
            >
              <label className="container">
                <input
                  type="checkbox"
                  value={p}
                  checked={
                    priorityOptions.includes(p) && priorityOptions.length > 0
                      ? true
                      : false
                  }
                  onChange={handleCheckboxChange}
                />
                <span className="checkmark"></span>
              </label>
              <div />
              <p>{p}</p>
            </ImmediateAttentionPatientsTablePriorityFilterContainer>
          ))}
        </>
      }
    />
  );
};
