import { Temporal } from "temporal-polyfill";
import { GoogleDataTableRow } from "react-google-charts";
import { buildRow } from "./build-row";

export function buildGapRow(
  datetime: Temporal.PlainDateTime,
  reverseVAxisTicks: number[]
): GoogleDataTableRow {
  return buildRow({ datetime, reverseVAxisTicks });
}
