import { ReactNode } from "react";
import {
  PateintProfileColumnHeaderContainer,
  PateintProfileColumnHeaderEditButtonContainer,
  PateintProfileColumnHeaderTitle,
  PateintProfileColumnHeaderTitleContainer,
  PateintProfileColumnHeaderTitleIconContainer,
} from "./styled-pateint-profile-column-header";

type PateintProfileColumnHeaderProps = {
  title: string;
  button: ReactNode;
  img?: string;
  icon?: string;
};

export const PateintProfileColumnHeader = (
  props: PateintProfileColumnHeaderProps
) => {
  const { title, button, img, icon } = props;

  return (
    <PateintProfileColumnHeaderContainer>
      <div>
        <PateintProfileColumnHeaderTitleContainer>
          <PateintProfileColumnHeaderTitleIconContainer>
            {img !== undefined && <img src={`${img}`} alt={img} />}
            {icon !== undefined && (
              <span className="material-symbols-outlined">{icon}</span>
            )}
          </PateintProfileColumnHeaderTitleIconContainer>
          <PateintProfileColumnHeaderTitle>
            {title}
          </PateintProfileColumnHeaderTitle>
        </PateintProfileColumnHeaderTitleContainer>
      </div>
      <PateintProfileColumnHeaderEditButtonContainer>
        {button}
      </PateintProfileColumnHeaderEditButtonContainer>
    </PateintProfileColumnHeaderContainer>
  );
};
