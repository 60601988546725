import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { CreateContentModuleCardDTO } from "../../../../../../models/content-module-dtos/create-content-module-card-dto";
import { useConentModuleMobilePreviewCarousel } from "../../context/conent-module-mobile-preview-carousel-context";
import { ContentModuleLessonMobilePreviewCardsList } from "../../mobile-preview-lesson-cards-styles";
import { CreateContentModuleLessonMobilePreviewCard } from "./create-content-module-lesson-mobile-preview-card";

type CreateContentModuleLessonMobilePreviewCardsProps = {
  contentModuleCards: CreateContentModuleCardDTO[];
};

export const CreateContentModuleLessonMobilePreviewCards = (
  props: CreateContentModuleLessonMobilePreviewCardsProps
) => {
  const { contentModuleCards } = props;

  const { currentCard } = useConentModuleMobilePreviewCarousel();

  if (contentModuleCards.length === 0) {
    return (
      <ContentModuleLessonMobilePreviewCardsList>
        <EmptyState
          button={<></>}
          hasHeader={false}
          hasSearch={false}
          text="No Cards Added Yet"
          title=""
        />
      </ContentModuleLessonMobilePreviewCardsList>
    );
  }

  return (
    <ContentModuleLessonMobilePreviewCardsList>
      {contentModuleCards.map((card, index) => (
        <CreateContentModuleLessonMobilePreviewCard
          key={index}
          card={card}
          activeCard={index === currentCard}
        />
      ))}
    </ContentModuleLessonMobilePreviewCardsList>
  );
};
