import styled from "styled-components/macro";
import { flexCenterSpaceBetween } from "../../../../../styles/classes/gloabl-classes";

export const NewBulkLabResultsModalHeader = styled.div`
  ${flexCenterSpaceBetween}

  p {
    font-size: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.titleColor};
  }

  span {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
