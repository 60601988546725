import { Temporal } from "temporal-polyfill";
import { PatientCGMEntryDTO } from "../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientType } from "../../../../models/patient-dtos/patient-type";
import { Reading } from "../../../cgm-google-chart/utils/build-days-aggregates";
import { CgmTooltipProps } from "../cgm-tooltip/cgm-tooltip";
import { isGlucosePointInRange } from "../../../../utils/glucose-point-utils/glucose-point-range-utils/is-glucose-point-in-range";

export type BGMReadingDataSource = {
  x: Date;
  y: number;
  tooltip: CgmTooltipProps;
};

export function buildBGMReadingsSereis(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientType: PatientType
): BGMReadingDataSource[] {
  const readingsSeries: BGMReadingDataSource[] = [];

  for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
    if (patientCGMEntryDTO.source === "Manual") {
      const reading: Reading = {
        glucose: patientCGMEntryDTO.glucoseMGPerDL,
        source: patientCGMEntryDTO.source,
        time: Temporal.PlainTime.from(patientCGMEntryDTO.time),
        tag: patientCGMEntryDTO.tag,
      };

      const tooltip: CgmTooltipProps = {
        x: new Date(`${patientCGMEntryDTO.date}T${patientCGMEntryDTO.time}`),
        y: patientCGMEntryDTO.glucoseMGPerDL,
        kind: {
          kind: "Reading",
          data: {
            ...patientCGMEntryDTO,
            isInRange: isGlucosePointInRange(patientType, reading)!,
          },
        },
      };

      readingsSeries.push({
        x: new Date(`${patientCGMEntryDTO.date}T${patientCGMEntryDTO.time}`),
        y: patientCGMEntryDTO.glucoseMGPerDL,
        tooltip,
      });
    }
  }

  return readingsSeries;
}
