import { MessageResponse } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import {
  MessagingMediaSidebarCustomMessageImageContainer,
  MessagingMediaSidebarCustomMessageImagesContainer,
  MessagingMediaSidebarCustomMessageImagesMoreContainer,
  MessagingMediaSidebarCustomMessageImagesTime,
} from "./styled-messaging-media-sidebar-custom-message-images";
import { useModalHelpers } from "../../../../../../../../components/modal/use-modal-helpers";
import { MessagingMediaSidebarCustomMessageImagesModal } from "./messaging-media-sidebar-custom-message-images-modal/messaging-media-sidebar-custom-message-images-modal";

type MessagingMediaSidebarCustomMessageImagesProps = {
  message: MessageResponse<DefaultStreamChatGenerics>;
};

export const MessagingMediaSidebarCustomMessageImages = (
  props: MessagingMediaSidebarCustomMessageImagesProps
) => {
  const { message } = props;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  const images =
    message &&
    message.attachments &&
    message.attachments.filter((attachment) => attachment.type === "image");

  return (
    <div>
      {message && message.attachments && (
        <MessagingMediaSidebarCustomMessageImagesContainer>
          {message.attachments.slice(0, 4).map((image) => (
            <MessagingMediaSidebarCustomMessageImageContainer
              key={image.image_url}
              onClick={openModal}
            >
              <img src={image.image_url} alt={`from ${image.author_name}`} />
            </MessagingMediaSidebarCustomMessageImageContainer>
          ))}
          {message.attachments.length > 4 && (
            <MessagingMediaSidebarCustomMessageImagesMoreContainer
              onClick={openModal}
            >
              <p>
                {
                  message.attachments.slice(4, message.attachments.length!)
                    .length
                }{" "}
                More
              </p>
            </MessagingMediaSidebarCustomMessageImagesMoreContainer>
          )}
          <MessagingMediaSidebarCustomMessageImagesTime>
            {message.created_at !== undefined
              ? new Date(message.created_at).toLocaleString("en-AU", {
                  month: undefined,
                  year: undefined,
                  day: undefined,
                  hour: "2-digit",
                  minute: "2-digit",
                  weekday: undefined,
                })
              : ""}
          </MessagingMediaSidebarCustomMessageImagesTime>
        </MessagingMediaSidebarCustomMessageImagesContainer>
      )}
      <MessagingMediaSidebarCustomMessageImagesModal
        modalBodyKey={modalBodyKey}
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        images={images}
      />
    </div>
  );
};
