import { FormProvider, useForm } from "react-hook-form";
import { Modal } from "../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { CarbCounterMealAddFoodModalBody } from "./carb-counter-meal-add-food-modal-body/carb-counter-meal-add-food-modal-body";
import { CarbCounterV1FoodMeasureDTO } from "../../../../../../models/carb-counter-dtos/carb-counter-v1-food-measure-dto";
import { CarbCounterV1AddFoodResponseDTO } from "../../../../../../models/carb-counter-dtos/carb-counter-v1-add-food-response-dto";

export type CarbCounterMealAddFoodModalInputs = {
  foodDescription?: string;
  foodMessure?: CarbCounterV1FoodMeasureDTO;
  foodQuantity?: string;
  addFoodResponse?: CarbCounterV1AddFoodResponseDTO;
};

export const CarbCounterMealAddFoodModal = () => {
  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  const formMethods = useForm<CarbCounterMealAddFoodModalInputs>();

  return (
    <>
      <Button flex type="button" outlineNoBorder onClick={openModal}>
        <span className="material-symbols-outlined">add</span>
        Add More
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FormProvider {...formMethods}>
          <CarbCounterMealAddFoodModalBody
            key={modalBodyKey}
            onClose={closeModal}
          />
        </FormProvider>
      </Modal>
    </>
  );
};
