import {
  CGMReportResponseHeaderContainer,
  CGMReportResponseHeaderSelectedDatesContainer,
} from "./styled-cgm-report-response-header";
import { DateRange } from "../../../../../../components/date-range/date-range";
import { useDateRange } from "../../../../../../components/date-range/context/date-range-context";
import { Temporal } from "temporal-polyfill";
import { useCGMReportLoadedResponse } from "../../context/cgm-report-loaded-response-context";
import { convertCGMEntriesToReadings } from "../../../../../../utils/glucose-point-utils/convert-cgm-entries-to-readings";
import { buildDaysAggregatesReadingsWithTimeDifference } from "../../../../../../utils/glucose-point-utils/build-readings-with-time-differance-value";
import { calculateReadingsTimeDiffrenceTotal } from "../../../../../../utils/glucose-point-utils/calculate-readings-time-differance-total";
import { roundTo1DecimalPlace } from "../../../../../../utils/math-utils";
import { useMemo } from "react";

export const CGMReportResponseHeader = () => {
  const { selectedDates } = useDateRange();
  const { groupCgmEntriesByDate } = useCGMReportLoadedResponse();

  const { cgmActivePercentage } = useMemo(() => {
    const cgmReadingsInSelectedDateRange = groupCgmEntriesByDate
      .map((item) => item.readings)
      .flat();

    const convertCgmReadingsInSelectedDateRangeToReadings = convertCGMEntriesToReadings(
      cgmReadingsInSelectedDateRange
    );

    const readingsWithTimeDifferenceValue = buildDaysAggregatesReadingsWithTimeDifference(
      convertCgmReadingsInSelectedDateRangeToReadings
    );

    const readingsTimeDifferanceTotal = calculateReadingsTimeDiffrenceTotal(
      readingsWithTimeDifferenceValue
    );

    const totalHoursInSelectedDates = selectedDates.count * 24;
    const totalMinutesInSelectedDates = totalHoursInSelectedDates * 60;

    const cgmActivePercentage = roundTo1DecimalPlace(
      (readingsTimeDifferanceTotal / totalMinutesInSelectedDates) * 100
    );

    return { cgmActivePercentage };
  }, [groupCgmEntriesByDate, selectedDates.count]);

  const selectedStartDate = Temporal.PlainDate.from(
    selectedDates.temporalStartDate
  ).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: undefined,
  });

  const selectedEndDate = Temporal.PlainDate.from(
    selectedDates.temporalEndDate
  ).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <CGMReportResponseHeaderContainer>
      <DateRange />
      <CGMReportResponseHeaderSelectedDatesContainer>
        <p>
          Selected Dates:{" "}
          {`${selectedStartDate} - ${selectedEndDate} (${selectedDates.count} Days)`}
        </p>
        {groupCgmEntriesByDate.length > 0 && (
          <span>% Time CGM is Active: {cgmActivePercentage}%</span>
        )}
      </CGMReportResponseHeaderSelectedDatesContainer>
    </CGMReportResponseHeaderContainer>
  );
};
