import { PatientCGMEntryDTO } from "../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { Temporal } from "temporal-polyfill";
import { SeriesDataSource } from "./series";
import { GAP_THRESHOLD_MINUTES } from "../../../constants/config";
import { ReadingTooltipProps } from "../tooltips/reading-tooltip";
import { CommonTooltipProps } from "../tooltips/tooltip";

export function buildReadingsSeries(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  startPlainDateTime: Temporal.PlainDateTime
): SeriesDataSource {
  const series: SeriesDataSource = [];
  let lastPlainDateTime: Temporal.PlainDateTime | undefined = undefined;
  for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
    const currentPlainDateTime = Temporal.PlainDateTime.from(
      `${patientCGMEntryDTO.date}T${patientCGMEntryDTO.time}`
    );

    const x = currentPlainDateTime
      .since(startPlainDateTime)
      .total({ unit: "minutes" });
    const y = patientCGMEntryDTO.glucoseMGPerDL;
    const tooltip: CommonTooltipProps & {
      kind: "reading";
      readingTooltipProps: ReadingTooltipProps;
    } = {
      x,
      y,
      kind: "reading",
      readingTooltipProps: { patientCGMEntryDTO },
    };

    if (
      lastPlainDateTime !== undefined &&
      Temporal.PlainDateTime.compare(
        lastPlainDateTime.add({ minutes: GAP_THRESHOLD_MINUTES }),
        currentPlainDateTime
      ) === -1
    ) {
      series.push({ x });
    }

    series.push({
      x: x,
      y: y,
      tooltip: tooltip,
    });

    lastPlainDateTime = currentPlainDateTime;
  }

  return series;
}
