import { LoadingContainer } from "./styled-loading";

export const Loading = () => {
  return (
    <LoadingContainer>
      <div />
      <div />
      <div />
    </LoadingContainer>
  );
};
