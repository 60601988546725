import styled from "styled-components/macro";
import { flexCol } from "../../../../../styles/classes/gloabl-classes";
import { LabResultsLabel } from "../styles";

export const LabResultsDateInputContainer = styled.div`
  ${flexCol}
  gap: 8px 0px;
`;

export const LabResultsDateInputLabel = styled.label`
  ${LabResultsLabel}
`;

export const LabResultsDateInputInput = styled.input`
  width: 250px;
  height: 40px;
  border-radius: 5px;
  outline: 0;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.inputColor};
  padding: 8px 16px;
`;
