import { useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { MessageResponse } from "stream-chat";
import { DefaultStreamChatMessage } from "../../../custom-message/default-stream-chat-message";
import { ReceiverCustomMessage } from "../../../custom-message/receiver-custom-message";
import { SenderCustomMessage } from "../../../custom-message/sender-custom-message";
import { MessagingMediaSidebarCustomMessageContainer } from "./styled-messaging-media-sidebar-custom-message";
import { MessagingMediaSidebarCustomMessageImages } from "./messaging-media-sidebar-custom-message-images/messaging-media-sidebar-custom-message-images";

type MessagingMediaSidebarCustomMessageProps = {
  message: MessageResponse<DefaultStreamChatGenerics>;
};

export const MessagingMediaSidebarCustomMessage = (
  props: MessagingMediaSidebarCustomMessageProps
) => {
  const { message } = props;

  const { client } = useChatContext();

  const hasAttachments = message.attachments && message.attachments.length > 0;

  const voiceAttachments = [];

  if (hasAttachments) {
    voiceAttachments.push(
      message.attachments?.find((msg) => msg.type === "audio")
    );
  }

  if (message.attachments?.some((attachment) => attachment.type === "audio")) {
    if (message.user?.id === client.user?.id) {
      return (
        <MessagingMediaSidebarCustomMessageContainer>
          <SenderCustomMessage isPinnedMessage={true} message={message} />
        </MessagingMediaSidebarCustomMessageContainer>
      );
    } else {
      return (
        <MessagingMediaSidebarCustomMessageContainer>
          <ReceiverCustomMessage isPinnedMessage={true} message={message} />
        </MessagingMediaSidebarCustomMessageContainer>
      );
    }
  } else {
    if (
      message.attachments?.some((attachment) => attachment.type === "image")
    ) {
      return <MessagingMediaSidebarCustomMessageImages message={message} />;
    }
    return (
      <MessagingMediaSidebarCustomMessageContainer>
        <DefaultStreamChatMessage
          message={message}
          messageActions={[]}
          hidePinnedIcon
        />
      </MessagingMediaSidebarCustomMessageContainer>
    );
  }
};
