import { PatientNutritionReportDailySummaryDayDTO } from "../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-daily-summary-day-dto";
import { PatientNutritionReportDailySummaryDayPatientMealDTO } from "../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-daily-summary-day-patient-meal-dto";

export type MealWithFrequencies = PatientNutritionReportDailySummaryDayPatientMealDTO & {
  carbSources: ""[];
  proteinSources: ""[];
  frequencyFoodGroups: ""[];
};

export const createMealsWithFrequencies = (
  patientNutritionReportDailySummaryDayDTO: PatientNutritionReportDailySummaryDayDTO
): MealWithFrequencies[] => {
  const result: MealWithFrequencies[] = [];
  const carbSources = patientNutritionReportDailySummaryDayDTO.carbSources;
  const proteinSources =
    patientNutritionReportDailySummaryDayDTO.proteinSources;
  const frequencyFoodGroups =
    patientNutritionReportDailySummaryDayDTO.frequencyFoodGroups;

  for (const meal of patientNutritionReportDailySummaryDayDTO.patientMeals) {
    result.push({
      ...meal,
      carbSources: carbSources.map((_) => ""),
      proteinSources: proteinSources.map((_) => ""),
      frequencyFoodGroups: frequencyFoodGroups.map((_) => ""),
    });
  }

  return result;
};
