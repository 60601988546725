import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  lgSemiBoldFont,
  smallRegularFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const LabResultTestHeaderContainer = styled.div`
  padding: 24px;
  ${flexCenterSpaceBetween}
`;

export const LabResultTestHeaderTitle = styled.p`
  margin: 0px;
  ${lgSemiBoldFont}
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const LabResultTestHeaderLegend = styled.div`
  ${flexCenter}
  gap: 0px 24px;
`;

export const LabResultTestHeaderLegendPoint = styled.div`
  ${flexCenter}
  gap: 0px 6px;

  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  p {
    color: ${({ theme }) => theme.colors.textColor};
    ${smallRegularFont}
    margin: 0px;
  }

  :nth-child(1) {
    div {
      background-color: ${({ theme }) => theme.colors.success};
    }
  }

  :nth-child(2) {
    div {
      background-color: ${({ theme }) => theme.colors.danger};
    }
  }

  :nth-child(3) {
    div {
      background-color: ${({ theme }) => theme.newColors.warningAlt};
    }
  }
`;
