import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { PatientActivityLogStatsDTO } from "../../../../../../models/patient-activity-log-dtos/patient-activity-log-stats-dto";
import { Temporal } from "temporal-polyfill";
import { useGetPatientActivityLogStats } from "../../../../../../hooks/patient-activity-logs-hooks/use-get-patient-activity-log-stats";
import { useParams } from "react-router-dom";

type PatientProfileLifeActivities = {
  load: () => Promise<{
    patientActivityLogStatsDTO: PatientActivityLogStatsDTO;
  }>;
  startDate: Date;
  setStartDate: (startDate: Date) => void;
  endDate: Date;
  setEndDate: (endDate: Date) => void;
};

type PatientProfileLifeActivitiesProviderProps = PropsWithChildren<{}>;

const PatientProfileLifeActivitiesContext = createContext<
  undefined | PatientProfileLifeActivities
>(undefined);

export const PatientProfileLifeActivitiesProvider = (
  props: PatientProfileLifeActivitiesProviderProps
) => {
  const { children } = props;

  const { id } = useParams();

  const patientID = parseInt(id!);

  const today = new Date();
  const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
  const [startDate, setStartDate] = useState(lastWeek);
  const [endDate, setEndDate] = useState(today);

  const temporalStartDate = Temporal.PlainDate.from(
    startDate.toISOString().split("T")[0]
  )
    .add({ days: 1 })
    .toString();

  const temporalEndDate = Temporal.PlainDate.from(
    endDate.toISOString().split("T")[0]
  )
    .add({ days: 1 })
    .toString();

  const getPatientActivityLogStats = useGetPatientActivityLogStats();

  const load = useCallback(async () => {
    const [patientActivityLogStatsDTO] = await Promise.all([
      getPatientActivityLogStats(patientID, temporalStartDate, temporalEndDate),
    ]);

    return { patientActivityLogStatsDTO };
  }, [
    getPatientActivityLogStats,
    patientID,
    temporalStartDate,
    temporalEndDate,
  ]);

  const value = useMemo(
    () => ({
      load,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
    }),
    [load, startDate, setStartDate, endDate, setEndDate]
  );

  return (
    <PatientProfileLifeActivitiesContext.Provider value={value}>
      {children}
    </PatientProfileLifeActivitiesContext.Provider>
  );
};

export const usePatientProfileLifeActivities = () => {
  const patientProfileLifeActivities = useContext(
    PatientProfileLifeActivitiesContext
  );

  if (patientProfileLifeActivities === undefined) {
    throw new Error(
      "usePatientProfileLifeActivities must be used within PatientProfileLifeActivitiesProvider"
    );
  }

  return patientProfileLifeActivities;
};
