import {
  CGMReportResponseAGPBody,
  CGMReportResponseAGPCard,
  CGMReportResponseAGPHeader,
  CGMReportResponseAGPContainer,
} from "./styled-cgm-report-response-agp";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { useCGMReportLoadedResponse } from "../../context/cgm-report-loaded-response-context";
import { CGMReportResponseAGPGraph } from "./cgm-report-response-agp-graph/cgm-report-response-agp-graph";

export const CGMReportResponseAGP = () => {
  const { groupCgmEntriesByDate } = useCGMReportLoadedResponse();

  if (groupCgmEntriesByDate.length === 0) {
    return (
      <CGMReportResponseAGPContainer>
        <CGMReportResponseAGPCard>
          <CGMReportResponseAGPHeader>
            <p>AGP Graph</p>
          </CGMReportResponseAGPHeader>
          <CGMReportResponseAGPBody $isEmpty>
            <EmptyState
              button={<></>}
              hasHeader={false}
              hasSearch={false}
              text="No Data Within the selected dates"
              title=""
            />
          </CGMReportResponseAGPBody>
        </CGMReportResponseAGPCard>
      </CGMReportResponseAGPContainer>
    );
  }

  return (
    <CGMReportResponseAGPContainer>
      <CGMReportResponseAGPCard>
        <CGMReportResponseAGPHeader>
          <p>AGP Graph</p>
        </CGMReportResponseAGPHeader>
        <CGMReportResponseAGPBody $isEmpty={false}>
          <CGMReportResponseAGPGraph />
        </CGMReportResponseAGPBody>
      </CGMReportResponseAGPCard>
    </CGMReportResponseAGPContainer>
  );
};
