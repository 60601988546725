import { AgpPercentile } from "../../../../utils/agp-calculate-percentile";
import { groupPercentiles } from "./group-percentiles";

type AgpSeries75th25th = {
  x: Date;
  high75th: number;
  low25th: number;
};

export function buildAgpSeries75th25th(
  percentile75: AgpPercentile[],
  percentile25: AgpPercentile[]
): AgpSeries75th25th[] {
  const agpSeries75th25th: AgpSeries75th25th[] = [];

  const percentiles75thAnd25th = groupPercentiles([percentile75, percentile25]);

  for (const percentile75thAnd25th of percentiles75thAnd25th) {
    const x = new Date(`2024-01-01T${percentile75thAnd25th.hour}:00:00`);

    const high75th = percentile75thAnd25th.cgmEntries.find(
      (i) => i.keyPercentile === "75%"
    );
    const low25th = percentile75thAnd25th.cgmEntries.find(
      (i) => i.keyPercentile === "25%"
    );

    if (high75th && low25th) {
      agpSeries75th25th.push({
        x,
        high75th: high75th.glucoseMGPerDL,
        low25th: low25th.glucoseMGPerDL,
      });
    }
  }

  if (agpSeries75th25th.length !== 0) {
    agpSeries75th25th.push({
      x: new Date(`2024-01-02T00:00:00`),
      high75th: agpSeries75th25th[0].high75th,
      low25th: agpSeries75th25th[0].low25th,
    });
  }

  return agpSeries75th25th;
}
