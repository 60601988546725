import styled from "styled-components/macro";
import { mediumRegularFont } from "../../../../../styles/classes/gloabl-classes";

export const PatientProfileBMIIndicator = styled.p`
  ${mediumRegularFont}
  background: ${({ theme }) => theme.newColors.otidaO2};
  color: ${({ theme }) => theme.newColors.byOtida};
  margin: 0px;
  border-radius: 16px;
  text-align: center;
  padding: 8px 16px;
`;
