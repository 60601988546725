import {
  MedicationsInputContainer,
  MedicationsInputLabel,
  MedicationsInputWrapper,
} from "../styled-medications-inputs";
import { MedicationsTextArea } from "./styled-medications-notes-input";

type MedicationsNotesInputProps = {
  value: string | undefined;
  onChange: (value: string) => void;
};

export const MedicationsNotesInput = (props: MedicationsNotesInputProps) => {
  const { value, onChange } = props;

  return (
    <MedicationsInputContainer notesDimentions>
      <MedicationsInputLabel>Notes</MedicationsInputLabel>
      <MedicationsInputWrapper notesDimentions>
        <MedicationsTextArea
          value={value === undefined ? "" : value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Write notes..."
        />
      </MedicationsInputWrapper>
    </MedicationsInputContainer>
  );
};
