import styled from "styled-components/macro";
import { NavbarHeight, transition } from "../../styles/global-style";
import { FoodLoggingHeaderHeight, MealsListWidth } from "./constants";

export const FoodLoggingContainer = styled.div`
  display: flex;
  height: calc(100vh - (${FoodLoggingHeaderHeight} + ${NavbarHeight}));
  background-color: ${({ theme }) => theme.colors.containerColor};
`;

export const FoodLoggingPatientsAndMealsContainer = styled.div`
  position: relative;
  display: flex;
`;

export const FoodLoggingPatientMealsContainer = styled.div<{
  showMealsMenu: boolean;
}>`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  height: 98%;
  transform: ${({ showMealsMenu }) =>
    showMealsMenu ? "translateX(0px)" : "translateX(-100%)"};
  overflow-y: auto;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: ${MealsListWidth};
  background: ${({ theme }) => theme.colors.containerColor};
  position: absolute;
  bottom: 0px;
  transition: ${transition};

  ::-webkit-scrollbar {
    width: 0px;
  }
`;
