import { Dropdown } from "../../../../../components/dropdown/dropdown";
import { DropdownListItem } from "../../../../../components/dropdown/styled-dropdown";
import { DietitianReportMacrosBar } from "../../components/dietitian-report-macros-bar/dietitian-report-macros-bar";
import {
  DietitianReportTabHeaderContainer,
  DietitianReportTabHeaderTitle,
} from "../../components/reusable-styles";
import { NutritionGoalsHeaderSubContainer } from "./styled-nutrition-goals-header";
import { Controller, useFormContext } from "react-hook-form";
import { useNutritionGolasLastFoodGroupSelected } from "../context/nutrition-goals-last-food-group-selected-context";
import { useNutritionGoalsDropdownText } from "../context/nutrition-goals-dropdown-text-context";
import { NutritionGoalsInput } from "../nutrition-loading";
import { useNutritionGoals } from "../context/loadable-nutrition-goals-context";

export const NutritionGoalsHeader = () => {
  const { dropdownText, setDropdownText } = useNutritionGoalsDropdownText();

  const { setLastFoodGroupSelected } = useNutritionGolasLastFoodGroupSelected();

  const { nutritionRegimenDTOs } = useNutritionGoals();

  const { control, setValue } = useFormContext<NutritionGoalsInput>();

  return (
    <DietitianReportTabHeaderContainer>
      <DietitianReportTabHeaderTitle>
        Set Daily nutrition goals
      </DietitianReportTabHeaderTitle>
      <NutritionGoalsHeaderSubContainer dropdownText={dropdownText}>
        <Controller
          control={control}
          name="nutritionRegimenId"
          render={({ field: { onChange } }) => (
            <Dropdown text={dropdownText} variant="designed-ux" width={270}>
              {nutritionRegimenDTOs.map((item) => (
                <DropdownListItem
                  variant="designed-ux"
                  key={item.id}
                  onClick={() => {
                    setDropdownText(item.name);
                    onChange(item.id);
                    setLastFoodGroupSelected(item);
                    setValue(
                      "nutritionRegimenFoodGroups",
                      item.nutritionRegimenFoodGroups.map((i) => ({
                        dailyServingsCount: i.dailyServingsCount,
                        foodGroupId: i.foodGroupId,
                      }))
                    );
                  }}
                >
                  {item.name}
                </DropdownListItem>
              ))}
            </Dropdown>
          )}
        />
        <DietitianReportMacrosBar />
      </NutritionGoalsHeaderSubContainer>
    </DietitianReportTabHeaderContainer>
  );
};
