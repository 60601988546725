import { useMemo, useState } from "react";
import { useFoodLoggingData } from "./context/food-logging-data-context";
import { FoodLoggingPatients } from "./food-logging-patients/food-logging-patients";
import {
  FoodLoggingContainer,
  FoodLoggingPatientMealsContainer,
  FoodLoggingPatientsAndMealsContainer,
} from "./styled-food-logging";
import { PatientDTO } from "../../models/patient-dtos/patient-dto";
import { PatientMealDTO } from "../../models/patient-meal-dtos/patient-meal-dto";
import { FoodLoggingPatientsTab } from "./food-logging-patients/food-logging-patients-tabs/food-logging-patients-tabs";
import { FoodLoggingSelectedPatient } from "./food-logging-selected-patient/food-logging-selected-patient";
import { getPatientMealAfterUpdates } from "./util/get-patient-meal-after-updates";
import { FoodLoggingEmptyState } from "./food-logging-empty-state/food-logging-empty-state";
import { FoodLoggingSelectedPatientMeal } from "./food-logging-selected-patient-meal/food-logging-selected-patient-meal";
import { useDirtyMeal } from "./context/dirty-meal-context";
import { usePatientTodoMealsCountsUpdates } from "./context/patient-todo-meals-counts-updates-context";
import { LoadablePatientMeals } from "./food-logging-patient-meals/loadable-patient-meals";

export const FoodLoggingHome = () => {
  const {
    selectedPatientDTO,
    setSelectedPatientDTO,
    selectedPatientMealDTO,
    setSelectedPatientMealDTO,
  } = useFoodLoggingData();

  const { lastUpdatedPatientMealByPatientMealId } =
    usePatientTodoMealsCountsUpdates();

  const { dirty } = useDirtyMeal();

  // selected tab state
  const [selectedTab, setSelectedTab] =
    useState<FoodLoggingPatientsTab>("todo");

  const updatedSelectedPatientMealDTO = useMemo(() => {
    if (selectedPatientMealDTO === undefined) {
      return undefined;
    }

    return getPatientMealAfterUpdates(
      selectedPatientMealDTO,
      lastUpdatedPatientMealByPatientMealId
    );
  }, [selectedPatientMealDTO, lastUpdatedPatientMealByPatientMealId]);

  // event handlers
  function confirmedIfDirty() {
    if (dirty) {
      return window.confirm(
        "You may have unsaved meal changes that will be discard. Are you sure you want to continue?"
      );
    }

    return true;
  }

  function handleTabChange(tab: FoodLoggingPatientsTab) {
    if (tab === selectedTab) {
      return;
    }

    if (!confirmedIfDirty()) {
      return;
    }

    setSelectedPatientMealDTO(undefined);
    setSelectedPatientDTO(undefined);
    setSelectedTab(tab);
  }

  function handleHideMealsMenu() {
    setSelectedPatientDTO(undefined);
  }

  function handlePatientChange(patientDTO: PatientDTO) {
    if (patientDTO.id === selectedPatientDTO?.id) {
      return;
    }

    if (!confirmedIfDirty()) {
      return;
    }

    setSelectedPatientMealDTO(undefined);
    setSelectedPatientDTO(patientDTO);
  }

  function handlePatientMealChange(patientMealDTO: PatientMealDTO) {
    if (patientMealDTO.id === selectedPatientMealDTO?.id) {
      return;
    }

    if (!confirmedIfDirty()) {
      return;
    }

    setSelectedPatientMealDTO(patientMealDTO);
  }

  return (
    <FoodLoggingContainer>
      <FoodLoggingPatientsAndMealsContainer>
        <FoodLoggingPatients
          onPatientChange={handlePatientChange}
          selectedTab={selectedTab}
          onTabChange={handleTabChange}
        />
        <FoodLoggingPatientMealsContainer
          showMealsMenu={selectedPatientDTO !== undefined}
        >
          {selectedPatientDTO && (
            <>
              <FoodLoggingSelectedPatient
                handleHideMealsMenu={handleHideMealsMenu}
              />
              <LoadablePatientMeals
                onPatientMealChange={handlePatientMealChange}
                selectedPatientMealDTO={updatedSelectedPatientMealDTO}
                selectedTab={selectedTab}
              />
            </>
          )}
        </FoodLoggingPatientMealsContainer>
      </FoodLoggingPatientsAndMealsContainer>
      {updatedSelectedPatientMealDTO && selectedPatientDTO && (
        <FoodLoggingSelectedPatientMeal
          // key is for resetting meal component when changing meals
          key={updatedSelectedPatientMealDTO.id}
          patientMealDTO={updatedSelectedPatientMealDTO}
          selectedTab={selectedTab}
        />
      )}
      {!selectedPatientDTO || !updatedSelectedPatientMealDTO ? (
        <FoodLoggingEmptyState />
      ) : (
        <></>
      )}
    </FoodLoggingContainer>
  );
};
