import { Temporal } from "temporal-polyfill";
import { PatientCGMEntryDTO } from "../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import {
  ToolTipBody,
  TooltipContainer,
  TooltipHeader,
} from "../../../styles/classes/tooltips";

export type ReadingTooltipProps = {
  patientCGMEntryDTO: PatientCGMEntryDTO;
};

export function ReadingTooltip(props: ReadingTooltipProps) {
  const { patientCGMEntryDTO } = props;

  // Converting to Temporal.PlainDateTime to take advantange of its string formatting options
  // Syncfusion has styled components support as well as classnames
  // https://ej2.syncfusion.com/react/documentation/appearance/styled-component/

  const plainDateTime = Temporal.PlainDateTime.from(
    `${patientCGMEntryDTO.date}T${patientCGMEntryDTO.time}`
  );

  const glucose = patientCGMEntryDTO.glucoseMGPerDL;

  const displayDateTime = plainDateTime.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <TooltipContainer>
      <TooltipHeader>
        <p>Glucose</p>
        <strong>{displayDateTime}</strong>
      </TooltipHeader>
      <ToolTipBody>
        <p>
          {glucose} <span>mg/dL</span>
        </p>
      </ToolTipBody>
    </TooltipContainer>
  );
}
