import { PatientMealVoiceNoteDTO } from "../../../../../models/patient-meal-dtos/patient-meal-voice-note-dto";
import { FoodLoggingSelectedPatientMealNotesOrVoiceNotesContainerContainer } from "../../styled-food-logging-selected-patient-meal";
import {
  FoodLoggingSelectedPatientMealVoiceNotesContainer,
  FoodLoggingSelectedPatientMealVoiceNotesSubContainer,
} from "./styled-food-logging-selected-patient-meal-voice-notes";

type FoodLoggingSelectedPatientMealVoiceNotesProps = {
  patientMealVoiceNotes: PatientMealVoiceNoteDTO[];
};

export const FoodLoggingSelectedPatientMealVoiceNotes = (
  props: FoodLoggingSelectedPatientMealVoiceNotesProps
) => {
  const { patientMealVoiceNotes } = props;

  return (
    <FoodLoggingSelectedPatientMealNotesOrVoiceNotesContainerContainer>
      <FoodLoggingSelectedPatientMealVoiceNotesContainer>
        {patientMealVoiceNotes.map((item) => (
          <FoodLoggingSelectedPatientMealVoiceNotesSubContainer
            key={item.publicUrl}
          >
            <audio controls src={item.publicUrl} />
          </FoodLoggingSelectedPatientMealVoiceNotesSubContainer>
        ))}
      </FoodLoggingSelectedPatientMealVoiceNotesContainer>
    </FoodLoggingSelectedPatientMealNotesOrVoiceNotesContainerContainer>
  );
};
