import {
  PersonalInfoGroup,
  PersonalInfoGroupTitle,
  PersonalInfoGroupTop,
} from "../styled-personal-info-tab";
import {
  PersonalInfoKeyIndicator,
  PersonalInfoKeyIndicatorsBottom,
} from "./styled-key-indicators";
import { useMemo } from "react";
import { PatientLabTestResultDTO } from "../../../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import { DEFAULT_KEY_INDICATORS_LAB_TESTS_NAMES } from "../../../../../../data/sidebar-data/default-key-indicators-data";
import { Temporal } from "temporal-polyfill";
import { useSinglePatientSidebar } from "../../../context/loadable-single-patient-sidebar-context";

export const KeyIndicators = () => {
  const { patientLabTestResultsDTOs, labTestTypeDTOs } =
    useSinglePatientSidebar();

  const keyIndicatorsLatestResult = useMemo(() => {
    const keyIndicatorsLatestResult: Map<string, PatientLabTestResultDTO> =
      new Map();
    for (const patientLabTestResultsDTO of patientLabTestResultsDTOs) {
      const labTestType = labTestTypeDTOs.find(
        (labTestTypeDTO) =>
          labTestTypeDTO.id === patientLabTestResultsDTO.labTestTypeId
      )!.name;
      if (!DEFAULT_KEY_INDICATORS_LAB_TESTS_NAMES.includes(labTestType)) {
        continue;
      }

      const currentLatestResult = keyIndicatorsLatestResult.get(labTestType);

      if (
        currentLatestResult === undefined ||
        Temporal.PlainDate.compare(
          Temporal.PlainDate.from(patientLabTestResultsDTO.date),
          Temporal.PlainDate.from(currentLatestResult.date)
        ) > 0
      ) {
        keyIndicatorsLatestResult.set(labTestType, patientLabTestResultsDTO);
      }
    }

    return DEFAULT_KEY_INDICATORS_LAB_TESTS_NAMES.map((keyIndicator) => ({
      keyIndicator,
      patientLabTestResultDTO: keyIndicatorsLatestResult.get(keyIndicator),
      unit: labTestTypeDTOs.find(
        (labTestTypeDTO) => labTestTypeDTO.name === keyIndicator
      )!.unit,
    }));
  }, [labTestTypeDTOs, patientLabTestResultsDTOs]);

  return (
    <PersonalInfoGroup>
      <PersonalInfoGroupTop>
        <PersonalInfoGroupTitle>Key indicators</PersonalInfoGroupTitle>
      </PersonalInfoGroupTop>
      <PersonalInfoKeyIndicatorsBottom>
        {/*There's another task for adding blood glucose to "Others" lab tests first*/}
        {/*<PersonalInfoKeyIndicator>*/}
        {/*  <span>Blood Glucose</span>*/}
        {/*  <span>*/}
        {/*    <strong>100</strong>*/}
        {/*    mg/dL*/}
        {/*  </span>*/}
        {/*</PersonalInfoKeyIndicator>*/}
        {keyIndicatorsLatestResult.map(
          ({ keyIndicator, patientLabTestResultDTO, unit }) => (
            <PersonalInfoKeyIndicator key={keyIndicator}>
              <span>{keyIndicator}</span>
              {patientLabTestResultDTO === undefined && (
                <span>
                  <strong>N/A</strong>
                </span>
              )}
              {patientLabTestResultDTO !== undefined && (
                <span>
                  <strong>{patientLabTestResultDTO.result}</strong>
                  {unit === "-" ? "" : unit}
                </span>
              )}
            </PersonalInfoKeyIndicator>
          )
        )}
      </PersonalInfoKeyIndicatorsBottom>
    </PersonalInfoGroup>
  );
};
