import styled from "styled-components/macro";
import {
  flexCenter,
  mediumFont,
  mediumRegularFont,
  regularRegularFont,
} from "../../styles/classes/gloabl-classes";
import { lightShadowAlt, transition } from "../../styles/global-style";

export const AutoSelectForMedicationsDropdownWrapper = styled.div<{
  showDropdown?: boolean;
  errorState?: boolean;
}>`
  ${flexCenter}
  background: ${({ theme }) => theme.colors.inputColor};
  border-radius: 5px;
  padding: ${({ theme }) => theme.padding.pad05All};
  width: 100%;
  height: 40px;
  position: relative;
  border: 1px solid
    ${({ errorState, theme }) =>
      errorState ? theme.colors.danger : "transparent"};

  p {
    margin: 0px 0px 0px 8px;
    ${mediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    cursor: pointer;
    transform: ${({ showDropdown }) =>
      showDropdown ? "rotate(-180deg)" : "rotate(0deg)"};
    transition: ${transition};
  }
`;

export const AutoSelectForMedicationsInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const AutoSelectForMedicationsInputLabel = styled.label`
  ${regularRegularFont}
  margin: 0px 32px 0px 16px;
  width: 75px;
`;

export const AutoSelectForMedicationsInput = styled.input`
  background: transparent;
  border: 0;
  outline: 0;
  width: 100%;
  height: 100%;
  ${mediumFont}
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const AutoSelectForMedicationsDropdownList = styled.ul<{
  showDropdown: boolean;
}>`
  max-height: ${({ showDropdown }) => (showDropdown ? "206px" : "0px")};
  height: fit-content;
  overflow-y: ${({ showDropdown }) => (showDropdown ? "auto" : "hidden")};
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  inset: 0;
  top: 24px;
  z-index: ${({ theme }) => theme.zIndex.z300};
  background: ${({ theme }) => theme.colors.containerColor};
  ${lightShadowAlt}
  border-radius: 1rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  transition: ${({ showDropdown }) => (showDropdown ? transition : "")};
`;

export const AutoSelectForMedicationsDropdownListItem = styled.li`
  ${mediumRegularFont}
  cursor: pointer;
  width: 100%;
  padding: 10px 16px;
  transition: ${transition};

  :hover {
    background: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.buttonColor};
  }
`;
