import { User } from "./auth-context";
import jwtDecode from "jwt-decode";

type GoogleJwtToken = {
  name: string;
  picture?: string;
  iss: string;
};

export function credentialToUser(credential: string): User {
  const { name, picture, iss } = jwtDecode<GoogleJwtToken>(credential);

  return { credential, name, imageUrl: picture, iss };
}
