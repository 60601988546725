import { Temporal } from "temporal-polyfill";
import { PatientCGMEntryDTO } from "../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { IsInRange } from "../bgm-chart";
import { BgmChartTooltipProps } from "../bgm-chart-tooltip/bgm-chart-tooltip";

type OutOfRangeTagsSeriesDataSource = {
  x: string;
  y: number;
  dataSource: PatientCGMEntryDTO[];
  tooltip: BgmChartTooltipProps;
};

export function buildOutOfRangeTagsSeries(
  isInRange: IsInRange[],
  filteredByTags: PatientCGMEntryDTO[]
) {
  const outOfRangeTagsSeries: OutOfRangeTagsSeriesDataSource[] = [];
  const dataSource: PatientCGMEntryDTO[] = [];

  for (const filteredByTag of filteredByTags) {
    const isInRangeIndex = isInRange.findIndex(
      (element) => element.item === filteredByTag
    );

    const isInRangeItemExist = isInRange[isInRangeIndex].isInrange === false;

    if (isInRangeItemExist) {
      const tooltip: BgmChartTooltipProps = {
        x: `${filteredByTag.date} ${Temporal.PlainTime.from(
          filteredByTag.time
        ).toLocaleString("en-UK", {
          hour: "2-digit",
          minute: "2-digit",
          second: undefined,
          hour12: true,
        })}`,
        y: filteredByTag.glucoseMGPerDL,
        kind: {
          kind: "Reading",
          data: {
            ...filteredByTag,
            isInRange:
              isInRange[isInRangeIndex].isInrange !== undefined
                ? isInRange[isInRangeIndex].isInrange!
                : false,
          },
        },
      };

      dataSource.push(filteredByTag);

      outOfRangeTagsSeries.push({
        x: filteredByTag.date,
        y: filteredByTag.glucoseMGPerDL,
        dataSource,
        tooltip,
      });
    }
  }

  return outOfRangeTagsSeries;
}
