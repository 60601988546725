import { PropsWithChildren, createContext, useContext } from "react";
import { ContentModuleDTO } from "../../../models/content-module-dtos/content-module-dto";
import { UseLoadableDataReturn } from "../../../hooks/use-loadable-data";

export type TContent = {
  contentModuleDTOs: ContentModuleDTO[];
  contentModuleLessonsDTOs: ContentModuleDTO[];
};

export type LoadableContent = UseLoadableDataReturn<TContent>;

const LoadableContentContext = createContext<LoadableContent | undefined>(
  undefined
);

export type LoadableContentProviderProps = PropsWithChildren<{
  loadableContent: LoadableContent;
}>;

export const LoadableContentProvider = (
  props: LoadableContentProviderProps
) => {
  const { loadableContent, children } = props;

  return (
    <LoadableContentContext.Provider value={loadableContent}>
      {children}
    </LoadableContentContext.Provider>
  );
};

export const useLoadableContent = (): LoadableContent => {
  const loadableContent = useContext(LoadableContentContext);

  if (loadableContent === undefined) {
    throw Error(
      "useLoadableContent must be used within an LoadableContentProvider"
    );
  }

  return loadableContent;
};

export const useContent = (): TContent => {
  const loadableContent = useLoadableContent();

  if (loadableContent.state.data === undefined) {
    throw Error("Content have never been loaded successfully yet.");
  }

  return loadableContent.state.data;
};

type UseReloadContent = () => void;

export const useReloadContent = (): UseReloadContent => {
  const loadableContent = useLoadableContent();

  return loadableContent.reload;
};
