import styled from "styled-components/macro";

export const ImmediateAttentionPatientsTablePriorityFilterContainer = styled.div<{
  $color: string;
}>`
  display: flex;
  align-items: center;
  margin: 8px 0px;
  gap: 0px 10px;

  p {
    margin: 0px;
  }

  div {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: ${({ $color, theme }) =>
      $color === "High"
        ? theme.colors.danger
        : $color === "Medium"
        ? theme.colors.warning
        : theme.newColors.warningAlt};
  }
`;
