import {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { MedicationDTO } from "../../../../../models/medications/medication-dto";

export type ActivePrescriptionItemType = {
  id?: number;
  medicine?: MedicationDTO;
};

export type ActivePrescriptionItem = {
  activePrescriptionItem: ActivePrescriptionItemType;
  handleSetActivePrescriptionItem: (item: ActivePrescriptionItemType) => void;
  handleResetActivePrescriptionItem: () => void;
};

const ActivePrescriptionItemContext = createContext<
  ActivePrescriptionItem | undefined
>(undefined);

export function ActivePrescriptionItemProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const [activePrescriptionItem, setActivePrescriptionItem] =
    useState<ActivePrescriptionItemType>({
      id: undefined,
      medicine: undefined,
    });

  const handleSetActivePrescriptionItem = useCallback(
    (item: ActivePrescriptionItemType) => {
      setActivePrescriptionItem(item);
    },
    []
  );

  const handleResetActivePrescriptionItem = useCallback(
    () => setActivePrescriptionItem({ id: undefined, medicine: undefined }),
    []
  );

  const value = useMemo(
    () => ({
      activePrescriptionItem,
      handleSetActivePrescriptionItem,
      handleResetActivePrescriptionItem,
    }),
    [
      activePrescriptionItem,
      handleSetActivePrescriptionItem,
      handleResetActivePrescriptionItem,
    ]
  );

  return (
    <ActivePrescriptionItemContext.Provider value={value}>
      {children}
    </ActivePrescriptionItemContext.Provider>
  );
}

export function useActivePrescriptionItem(): ActivePrescriptionItem {
  const activeItem = useContext(ActivePrescriptionItemContext);

  if (activeItem === undefined) {
    throw new Error(
      "useActivePrescriptionItem must be used within ActivePrescriptionItemProvider"
    );
  }

  return activeItem;
}
