import { useCallback, useState } from "react";
import { DoubleRangeInput } from "../../components/double-range-input/double-range-input";
import { ImmediateAttentionPatientsTableAgeFilterContainer } from "../../immediate-attention-patients-table-age-filter/styled-immediate-attention-patients-table-age-filter";
import { ImmediateAttentionPatientsFiltersSidebarFilterTitle } from "../styled-immediate-attention-patients-filters-sidebar";
import { ImmediateAttentionPatientsFiltersSidebarNumberOfComplicationsContainer } from "./styled-immediate-attention-patients-filters-sidebar-number-of-complications";

export const ImmediateAttentionPatientsFiltersSidebarNumberOfComplications = () => {
  const [, setRangeValues] = useState<[number, number]>([0, 10]);

  const handleRangeChange = useCallback(
    (minValue: number, maxValue: number) => {
      setRangeValues([minValue, maxValue]);
    },
    []
  );

  return (
    <ImmediateAttentionPatientsFiltersSidebarNumberOfComplicationsContainer>
      <ImmediateAttentionPatientsFiltersSidebarFilterTitle
        style={{
          marginBottom: 16,
        }}
      >
        No. of complications{" "}
      </ImmediateAttentionPatientsFiltersSidebarFilterTitle>
      <ImmediateAttentionPatientsTableAgeFilterContainer>
        <DoubleRangeInput
          min={0}
          max={10}
          step={1}
          onChange={handleRangeChange}
        />
      </ImmediateAttentionPatientsTableAgeFilterContainer>
    </ImmediateAttentionPatientsFiltersSidebarNumberOfComplicationsContainer>
  );
};
