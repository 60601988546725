import { SuccessOrError, Toast } from "../components/toast/toast";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { ToastContainer } from "../components/toast/styled-toast";

export type ToastService = {
  showToast: (successOrError: SuccessOrError, message: string) => void;
};

const ToastServiceContext = createContext<ToastService | undefined>(undefined);

export type ToastData = {
  id: number;
  successOrError: SuccessOrError;
  message: string;
};

export function ToastServiceProvider({ children }: PropsWithChildren<{}>) {
  const nextId = useRef(0);
  const [toastList, setToastList] = useState<ToastData[]>([]);

  const removeToast = useCallback(
    (id: number) => {
      setToastList((prevToastList) =>
        prevToastList.filter((toast) => toast.id !== id)
      );
    },
    [setToastList]
  );

  const showToast = useCallback(
    (successOrError: SuccessOrError, message: string) => {
      const currentId = nextId.current;

      nextId.current = currentId + 1 === Number.MAX_VALUE ? 0 : currentId + 1;

      setToastList((prevToastList) => [
        ...prevToastList,
        { id: currentId, successOrError, message },
      ]);

      window.setTimeout(() => removeToast(currentId), 5000);
    },
    [removeToast, setToastList]
  );

  const toastService = useMemo(() => ({ showToast }), [showToast]);

  return (
    <ToastServiceContext.Provider value={toastService}>
      {children}

      <ToastContainer>
        {toastList.map((toast) => (
          <Toast
            key={toast.id}
            successOrError={toast.successOrError}
            message={toast.message}
            onCloseClick={() => removeToast(toast.id)}
            toastList={toastList}
          />
        ))}
      </ToastContainer>
    </ToastServiceContext.Provider>
  );
}

export function useToastService(): ToastService {
  const toastService = useContext(ToastServiceContext);
  if (toastService === undefined) {
    throw Error("useToastService must be used within an ToastServiceProvider");
  }

  return toastService;
}
