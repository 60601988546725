import { Button } from "../../../../../styles/classes/reusable-classes";
import {
  areEducationContentsEqual,
  getEducationContent,
  usePlansGoals,
} from "../../context/goals-context";
import { ContainerTitle } from "../goals/styled-goals";
import {
  EducationListItem,
  EducationListItemLeftContainer,
  EducationListItemTextContainer,
} from "./styled-education";
import { VideoModal } from "./video-modal/video-modal";

export const SelectEducationList = (props: { searchTerm: string }) => {
  const { searchTerm } = props;

  const { selectEducationGoalsList, handleAddGoal } = usePlansGoals();

  const uniqueArray = selectEducationGoalsList.filter(
    (item, index) =>
      index ===
      selectEducationGoalsList.findIndex((obj) => {
        const objEducationContent = getEducationContent(obj.goal);
        const itemEducationContent = getEducationContent(item.goal);

        return areEducationContentsEqual(
          objEducationContent,
          itemEducationContent
        );
      })
  );

  return (
    <>
      <ul>
        <ContainerTitle>Select new Education goals</ContainerTitle>
        {uniqueArray
          .sort((a, b) => {
            if (a.goal > b.goal) {
              return 1;
            } else {
              return -1;
            }
          })
          .filter((goal) => {
            const goalObject = getEducationContent(goal.goal).lesson;

            if (searchTerm === "") {
              return goal;
            } else if (
              goalObject.title.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return goalObject;
            } else {
              return 0;
            }
          })
          .map((goal, index) => {
            const goalObject = getEducationContent(goal.goal).lesson;

            return (
              <EducationListItem key={index}>
                <EducationListItemLeftContainer>
                  <EducationListItemTextContainer>
                    <p>{goalObject.title}</p>
                    {goalObject.type === "Video" && (
                      <VideoModal videoURL={goalObject.link} />
                    )}
                    {(goalObject.type === "PDF" ||
                      goalObject.type === "Image") && (
                      <a
                        href={goalObject.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="material-symbols-outlined">
                          description
                        </span>
                        {goalObject.type === "Image" && "Article"}
                        {goalObject.type === "PDF" && "PDF"}
                      </a>
                    )}
                  </EducationListItemTextContainer>
                </EducationListItemLeftContainer>
                <Button
                  flex
                  type="button"
                  outlinedPrimary
                  onClick={() =>
                    handleAddGoal({
                      goal: goal.goal,
                      goalType: "EducationContent",
                    })
                  }
                >
                  <span className="material-symbols-outlined">add</span>
                  Add
                </Button>
              </EducationListItem>
            );
          })}
      </ul>
    </>
  );
};
