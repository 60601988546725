import { useCallback } from "react";
import { LoadablePrescriptionProvider } from "./context/lodable-prescription-context";
import { useGetPatientDetailedPrescriptions } from "../../../../hooks/patient-prescriptions-hooks/use-get-patient-detailed-prescriptions";
import { useParams } from "react-router-dom";
import { useLoadableData } from "../../../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../../../components/loadable-data-loading";
import { PrescriptionsLoading } from "./prescriptions-loading";

export const Prescriptions = () => {
  const { id } = useParams();

  const getPatientDetailedPrescriptions = useGetPatientDetailedPrescriptions();

  const loadablePrescriptionsCallback = useCallback(async () => {
    const patientID = Number(id!);

    const [patientDetailedPrescriptions] = await Promise.all([
      getPatientDetailedPrescriptions(patientID),
    ]);

    return { patientDetailedPrescriptions };
  }, [id, getPatientDetailedPrescriptions]);

  const loadablePrescription = useLoadableData(loadablePrescriptionsCallback);

  return (
    <LoadablePrescriptionProvider loadablePrescription={loadablePrescription}>
      <LoadableDataLoading
        state={loadablePrescription.state}
        successComponent={() => <PrescriptionsLoading />}
      />
    </LoadablePrescriptionProvider>
  );
};
