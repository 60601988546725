import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type ImmediateAttentionPatientsSidebarPatientDataBodyMeasurment = {
  title: string;
  value: string;
  unit: string;
  img: string;
};

export type ImmediateAttentionPatientsSidebarPatientData = {
  patientInfo: {
    img: string;
    name: string;
    age: string;
    gender: string;
  };
  diagnosis: string[];
  keyIndicators: {
    title: string;
    value: string;
  }[];
  bodyMeasurments: ImmediateAttentionPatientsSidebarPatientDataBodyMeasurment[];
};

type ImmediateAttentionPatientsSidebar = {
  showSidebar: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
  toggleSidebar: () => void;
  immediateAttentionPatientsSidebarPatientData: ImmediateAttentionPatientsSidebarPatientData;
};

const ImmediateAttentionPatientsSidebarContext = createContext<
  ImmediateAttentionPatientsSidebar | undefined
>(undefined);

type ImmediateAttentionPatientsSidebarProviderProps = PropsWithChildren<{}>;

export const ImmediateAttentionPatientsSidebarProvider = (
  props: ImmediateAttentionPatientsSidebarProviderProps
) => {
  const { children } = props;

  const immediateAttentionPatientsSidebarPatientData: ImmediateAttentionPatientsSidebarPatientData = useMemo(
    () => ({
      patientInfo: {
        img: "/img/all-patients/patients/patient-83.png",
        name: "Ahmed Hamed",
        age: "44 Y",
        gender: "Male",
      },
      diagnosis: [
        "Type 2",
        "Controlled Diabetes Mellitus Type 2",
        "Dyslipidemia",
        "Vitamin D Deficiency",
        "Anemia",
      ],
      keyIndicators: [
        { title: "HbA1c", value: "6.8%" },
        { title: "LDL", value: "96mg/dL" },
        { title: "Serum Triglycerides", value: "121mg/dL" },
        { title: "eGFR", value: "139mL/min/1.73m2" },
      ],
      bodyMeasurments: [
        {
          title: "BMI",
          value: "31.71",
          unit: "",
          img: "/img/single-patient-sidebar/4.png",
        },
        {
          title: "Height",
          value: "168",
          unit: "cm",
          img: "/img/single-patient-sidebar/5.png",
        },
        {
          title: "Weight",
          value: "89.5",
          unit: "kg",
          img: "/img/single-patient-sidebar/6.png",
        },
      ],
    }),
    []
  );

  const [showSidebar, setShowSidebar] = useState(false);

  const openSidebar = useCallback(() => setShowSidebar(true), []);

  const closeSidebar = useCallback(() => setShowSidebar(false), []);

  const toggleSidebar = useCallback(() => setShowSidebar(!showSidebar), [
    showSidebar,
  ]);

  const value = useMemo(
    () => ({
      showSidebar,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      immediateAttentionPatientsSidebarPatientData,
    }),
    [
      showSidebar,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      immediateAttentionPatientsSidebarPatientData,
    ]
  );

  return (
    <ImmediateAttentionPatientsSidebarContext.Provider value={value}>
      {children}
    </ImmediateAttentionPatientsSidebarContext.Provider>
  );
};

export const useImmediateAttentionPatientsSidebar = () => {
  const context = useContext(ImmediateAttentionPatientsSidebarContext);

  if (context === undefined) {
    throw new Error(
      "useImmediateAttentionPatientsSidebar must be used within a ImmediateAttentionPatientsSidebarProvider"
    );
  }

  return context;
};
