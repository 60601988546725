import styled from "styled-components/macro";
import {
  flexCenter,
  regularMediumFont,
} from "../../../../styles/classes/gloabl-classes";
import {
  SidebarWidth,
  SinglePatientSidebarWidth,
} from "../../../../styles/global-style";

export const DietitianReportTabContainer = styled.div`
  margin: 24px 24px 0px;
  border-radius: 24px;
  padding: 16px 0px;
  background: ${({ theme }) => theme.colors.containerColor};
  min-height: 640px;
`;

export const DietitianReportTabHeaderContainer = styled.div`
  margin-top: 24px;
  padding: 0px 24px;
`;

export const DietitianReportTabHeaderTitle = styled.p`
  ${regularMediumFont}
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0px 0px 8px;
`;

export const DietitianReportTabFooterContainer = styled.footer`
  ${flexCenter}
  padding: 16px;
  position: fixed;
  left: calc(${SidebarWidth} + 24px);
  right: calc(${SinglePatientSidebarWidth} + 24px);
  bottom: 0px;
  margin-top: auto;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.containerColor};

  @media screen and (max-width: 1024px) {
    right: 24px;
  }

  @media screen and (max-width: 500px) {
    left: 24px;
  }
`;

export const DietitianReportTabFooterButtons = styled.div`
  ${flexCenter}
  gap: 0px 16px;
  margin-left: auto;
`;
