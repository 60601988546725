import { Temporal } from "temporal-polyfill";
import { PatientMedicationDTO } from "../../../../models/patient-medication-dto";

function compareMedicationNames(aName: string, bName: string) {
  return aName.localeCompare(bName);
}

export function sortMedicationsByName(
  patientMedicationData: PatientMedicationDTO[]
): PatientMedicationDTO[] {
  return patientMedicationData.sort((a, b) =>
    compareMedicationNames(a.medication.name, b.medication.name)
  );
}

export function generateSig(patientMedicationDTO: PatientMedicationDTO) {
  const sig = {
    quantity: patientMedicationDTO
      ? `${
          patientMedicationDTO.quantity !== undefined
            ? patientMedicationDTO.quantity
            : ""
        } ${
          patientMedicationDTO.medication.doseUnit !== undefined
            ? patientMedicationDTO.medication.doseUnit
            : ""
        },`
      : ``,
    frequency:
      patientMedicationDTO && patientMedicationDTO.frequency !== undefined
        ? `${patientMedicationDTO.frequency.explanation} (${patientMedicationDTO.frequency.abbreviation})`
        : ``,
    duration:
      patientMedicationDTO && patientMedicationDTO.durationDays !== undefined
        ? `for ${patientMedicationDTO.durationDays} days`
        : ``,
    notes:
      patientMedicationDTO && patientMedicationDTO.notes !== undefined
        ? `${patientMedicationDTO.notes}`
        : ``,
  };

  return sig;
}

export const sortedPatientMedicationDTOs = (
  patientMedicationDTOs: PatientMedicationDTO[]
) =>
  patientMedicationDTOs.sort((a, b) => {
    const today = Temporal.Now.plainDateISO();

    const aStartDatePlusDuration =
      a.startDate !== undefined && a.durationDays !== undefined
        ? Temporal.PlainDate.from(a.startDate).add({
            days: a.durationDays,
          })
        : undefined;
    const aIsFinished =
      aStartDatePlusDuration !== undefined
        ? Temporal.PlainDate.compare(today, aStartDatePlusDuration)
        : undefined;

    const bStartDatePlusDuration =
      b.startDate !== undefined && b.durationDays !== undefined
        ? Temporal.PlainDate.from(b.startDate).add({
            days: b.durationDays,
          })
        : undefined;
    const bIsFinished =
      bStartDatePlusDuration !== undefined
        ? Temporal.PlainDate.compare(today, bStartDatePlusDuration)
        : undefined;

    if (a.discontinueDate === undefined && b.discontinueDate !== undefined) {
      return -1;
    }
    if (a.discontinueDate !== undefined && b.discontinueDate === undefined) {
      return 1;
    }

    const aIsAssignedByOtida =
      a.patientPrescriptionId !== undefined || a.isAssignedByOtida === true;
    const bIsAssignedByOtida =
      b.patientPrescriptionId !== undefined || b.isAssignedByOtida === true;

    if (aIsAssignedByOtida && !bIsAssignedByOtida) {
      return -1;
    } else if (!aIsAssignedByOtida && bIsAssignedByOtida) {
      return 1;
    }

    if (
      aIsFinished !== undefined &&
      aIsFinished !== 1 &&
      bIsFinished !== undefined &&
      bIsFinished === 1
    ) {
      return -1;
    }

    if (
      aIsFinished !== undefined &&
      aIsFinished === 1 &&
      bIsFinished !== undefined &&
      bIsFinished === 1
    ) {
      return -1;
    }

    if (
      aIsFinished !== undefined &&
      aIsFinished === 1 &&
      bIsFinished !== undefined &&
      bIsFinished !== 1
    ) {
      return 1;
    }

    if (a.startDate !== undefined && b.startDate !== undefined) {
      return Temporal.PlainDate.compare(b.startDate, a.startDate);
    }

    if (
      (a.startDate !== undefined && b.startDate === undefined) ||
      (a.startDate === undefined && b.startDate !== undefined)
    ) {
      return -1;
    }

    return -1;
  });
