import styled from "styled-components/macro";
import { transition } from "../../styles/global-style";
import { regularMediumFont } from "../../styles/classes/gloabl-classes";

export const NavContainer = styled.div`
  ::-webkit-scrollbar {
    height: 5px;
  }

  @media screen and (max-width: 900px) {
    overflow-x: auto;
  }
`;

export const NavList = styled.ul`
  display: flex;
  gap: 0px 16px;

  a {
    color: inherit;
    text-decoration: none;
  }

  @media screen and (max-width: 685px) {
    width: 100%;
    height: 50px;
    align-items: center;
  }
`;

export const NavListItem = styled.li<{ activeLink: boolean }>`
  position: relative;
  padding-bottom: 8px;
  cursor: pointer;
  transition: ${transition};
  width: max-content;
  ${regularMediumFont}
  color: ${({ theme, activeLink }) =>
    activeLink ? theme.colors.buttonColorAlt : theme.colors.textColor};

  ::after {
    content: "";
    position: absolute;
    width: ${({ activeLink }) => (activeLink ? "100%" : "0%")};
    height: 4px;
    background: ${({ theme }) => theme.colors.primaryColorAlt};
    bottom: 0;
    left: 0;
    transition: ${transition};
  }

  @media screen and (max-width: 580px) {
    font-size: ${({ theme }) => theme.typography.fontSize.regularSmFont};
    white-space: nowrap;
  }
`;
