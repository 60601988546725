import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { BASE_PATH } from "../../constants/paths";
import { CreateTargetAudienceDTO } from "../../models/target-audience-dtos/create-target-audience-dto";
import { TargetAudienceDTO } from "../../models/target-audience-dtos/target-audience-dto";

export function useAddTargetAudience(): (
  createTargetAudienceDTO: CreateTargetAudienceDTO
) => Promise<TargetAudienceDTO> {
  const client = useClient();

  return useCallback(
    async (createTargetAudienceDTO) => {
      const response = await client.post<TargetAudienceDTO>(
        `${BASE_PATH}/target-audiences`,
        createTargetAudienceDTO
      );

      return response.data;
    },
    [client]
  );
}
