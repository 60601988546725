import { Modal } from "../../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../../components/modal/use-modal-helpers";
import { Button } from "../../../../../../../styles/classes/reusable-classes";
import { PatientProfileWeightEditModalBody } from "./patient-profile-weight-edit-modal-body";

type PatientProfileWeightEditModalProps = {
  showPicker: boolean;
};

export const PatientProfileWeightEditModal = (
  props: PatientProfileWeightEditModalProps
) => {
  const { showPicker } = props;
  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  return (
    <>
      <Button outlined flex onClick={openModal}>
        {showPicker ? (
          <>
            <img src="/img/edit.svg" alt="Edit" /> Edit
          </>
        ) : (
          <>
            <span className="material-symbols-outlined">
              add_circle_outline
            </span>{" "}
            Add New
          </>
        )}
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <PatientProfileWeightEditModalBody
          key={modalBodyKey}
          onClose={closeModal}
        />
      </Modal>
    </>
  );
};
