import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type SinglePatientSidebarUIContextType = {
  showSinglePatientSidebarUI: boolean;
  onSidebarShow: () => void;
  onSidebarHide: () => void;
  onSidebarShowOrHide: () => void;
};

const SinglePatientSidebarUIContext = createContext<
  SinglePatientSidebarUIContextType | undefined
>(undefined);

export function SinglePatientSidebarUIProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const [showSinglePatientSidebarUI, setShowSinglePatientSidebarUI] =
    useState(true);

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    if (windowWidth > 1024) {
      setShowSinglePatientSidebarUI(true);
    } else {
      setShowSinglePatientSidebarUI(false);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  const onSidebarShow = useCallback(
    () => setShowSinglePatientSidebarUI(true),
    [setShowSinglePatientSidebarUI]
  );

  const onSidebarHide = useCallback(
    () => setShowSinglePatientSidebarUI(false),
    [setShowSinglePatientSidebarUI]
  );

  const onSidebarShowOrHide = useCallback(
    () => setShowSinglePatientSidebarUI(!showSinglePatientSidebarUI),
    [showSinglePatientSidebarUI, setShowSinglePatientSidebarUI]
  );

  const value = useMemo(
    () => ({
      showSinglePatientSidebarUI,
      onSidebarShow,
      onSidebarHide,
      onSidebarShowOrHide,
    }),
    [
      showSinglePatientSidebarUI,
      onSidebarShow,
      onSidebarHide,
      onSidebarShowOrHide,
    ]
  );

  return (
    <SinglePatientSidebarUIContext.Provider value={value}>
      {children}
    </SinglePatientSidebarUIContext.Provider>
  );
}

export function useSinglePatientSidebarUI(): SinglePatientSidebarUIContextType {
  const SinglePatientSidebarUI = useContext(SinglePatientSidebarUIContext);

  if (SinglePatientSidebarUI === undefined) {
    throw Error(
      "useSinglePatientSidebarUI must be used within an SinglePatientSidebarUIProvider"
    );
  }

  return SinglePatientSidebarUI;
}
