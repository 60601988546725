import { useCallback } from "react";
import { PatientDiagnosisDTO } from "../../models/patient-diagnosis-dtos/patient-diagnosis-dto";
import { useClient } from "../../context/client-context";
import { PATIENTS_PATH } from "../../constants/paths";

/**
 * If patient has no diagnosis, this will throw 404.
 */
export function useGetPatientDiagnosis(): (
  patientId: number
) => Promise<PatientDiagnosisDTO> {
  const client = useClient();

  return useCallback(
    async (patientId) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientDiagnosisDTO>(
        `${PATIENTS_PATH}/${patientId}/diagnosis`
      );

      return response.data;
    },
    [client]
  );
}
