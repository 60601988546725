import styled from "styled-components/macro";
import { lightShadowAlt, transition } from "../../../../../styles/global-style";
import { RadioButton } from "../../../../../styles/classes/reusable-classes";

export const ImmediateAttentionPatientsSelectedActionsContainer = styled.div<{
  $showContainer: boolean;
}>`
  background: ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.buttonColor};
  ${lightShadowAlt}
  padding: 16px;
  border-radius: 8px;

  display: flex;
  align-items: center;
  gap: 0px 8px;

  transform: ${({ $showContainer }) =>
    $showContainer ? "translateY(0px)" : "translateY(100vh)"};
  transition: ${transition};

  width: fit-content;
  position: fixed;
  inset: 0px;
  bottom: 40px;
  top: unset;
  margin: 0 auto;
`;

export const ImmediateAttentionPatientsSelectedTextContainer = styled.div`
  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    padding-right: 8px;

    ::after {
      content: "";
      position: absolute;
      height: 20px;
      width: 2px;
      top: 0px;
      right: 0px;
      background-color: ${({ theme }) => theme.colors.borderColor};
      opacity: 0.3;
    }
  }
`;

export const ImmediateAttentionPatientsSelectedIconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0px 8px;
  position: relative;
  padding-right: 8px;
  height: 21px;

  img {
    filter: invert(1);
  }

  ::after {
    content: "";
    position: absolute;
    height: 20px;
    width: 2px;
    top: 0px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.borderColor};
    opacity: 0.3;
  }
`;

export const ImmediateAttentionPatientsSelectedRadioButtonContainer = styled.div<{
  $checked: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 0px 8px;

  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
  }

  ${RadioButton} {
    width: 50px;
    height: 20px;
    background: ${({ theme, $checked }) =>
      $checked ? theme.colors.success : `${theme.colors.primaryColorAlt}80`};
    margin-right: 8px;

    span {
      width: 14px;
      height: 14px;
      top: 3px;
      left: ${({ $checked }) => ($checked ? "30px" : "5px")};
    }
  }
`;
