import { roundTo1DecimalPlace } from "../../../../../../utils/math-utils";
import { PatientNutritionReportDailySummaryDayDTOWithId } from "../create-patient-nutrition-report-daily-summary-day-dto-with-ids";
import { getSelectedDaysCount } from "./get-selected-days-count";

export function calcualteProteinSourcesTotalsAndAverages(
  selectedDays: PatientNutritionReportDailySummaryDayDTOWithId[]
) {
  const daysProteinSources = selectedDays.map((item) => item.proteinSources);

  let redMeatSum = 0;
  let redMeatAvg = 0;
  let whiteMeatSum = 0;
  let whiteMeatAvg = 0;
  let seaFoodSum = 0;
  let seaFoodAvg = 0;

  for (const daysProteinSource of daysProteinSources) {
    for (const dayProteinSource of daysProteinSource) {
      if (dayProteinSource.proteinSource.id === 3) {
        // Seafood Freq.
        seaFoodSum += dayProteinSource.totalCount;
        seaFoodAvg = seaFoodSum / getSelectedDaysCount(selectedDays);
      }

      if (dayProteinSource.proteinSource.id === 1) {
        // Red Meat Freq.
        redMeatSum += dayProteinSource.totalCount;
        redMeatAvg = redMeatSum / getSelectedDaysCount(selectedDays);
      }

      if (dayProteinSource.proteinSource.id === 2) {
        // White Meat Freq.
        whiteMeatSum += dayProteinSource.totalCount;
        whiteMeatAvg = whiteMeatSum / getSelectedDaysCount(selectedDays);
      }
    }
  }

  return {
    redMeatSum,
    redMeatAvg: roundTo1DecimalPlace(redMeatAvg),
    whiteMeatSum,
    whiteMeatAvg: roundTo1DecimalPlace(whiteMeatAvg),
    seaFoodSum,
    seaFoodAvg: roundTo1DecimalPlace(seaFoodAvg),
  };
}
