import { useCallback, useEffect, useMemo, useState } from "react";

export type UseModalHelpersReturn = {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  modalBodyKey: number;
};

export function useModalHelpers(): UseModalHelpersReturn {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalBodyKey, setModalBodyKey] = useState(0);

  // To reset modal state when closed & re-opened
  useEffect(
    () =>
      setModalBodyKey((modalKey) =>
        modalKey === Number.MAX_VALUE ? 0 : modalKey + 1
      ),
    [isModalOpen]
  );

  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);

  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  return useMemo(
    () => ({
      isModalOpen,
      openModal,
      closeModal,
      modalBodyKey,
    }),
    [isModalOpen, openModal, closeModal, modalBodyKey]
  );
}
