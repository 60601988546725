import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PatientDTO } from "../../models/patient-dtos/patient-dto";
import { PutPatientProfileDiagnosesDTO } from "../../models/patient-profile-diagnosis-dtos/put-patient-profile-diagnoses-dto";

export function usePutPatientProfileDiagnoses(): (
  patientDTO: PatientDTO,
  putPatientProfileDiagnosesDTO: PutPatientProfileDiagnosesDTO
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientDTO, putPatientProfileDiagnosesDTO) => {
      await client.put<void>(
        `${PATIENTS_PATH}/${patientDTO.id}/profile-diagnoses`,
        putPatientProfileDiagnosesDTO
      );
    },
    [client]
  );
}
