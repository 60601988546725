import { useEffect } from "react";
import { useImageUploadHelpers } from "../../image-upload/context/image-upload-helpers-context";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../styles/classes/reusable-classes";
import { ImageUpload } from "../../image-upload/image-upload";
import { ContentModuleType } from "../../../../../models/content-module-dtos/content-module-type";
import { UploadedFileWithPresignedUrlDTO } from "../../../../../models/uploaded-file-dtos/uploaded-file-with-presigned-url-dto";

type ReadContentModuleThumbnailControllerProps = {
  contnetModuleType: ContentModuleType;
  thumbnailUploadedFile?: UploadedFileWithPresignedUrlDTO;
};

export const ReadContentModuleThumbnailController = (
  props: ReadContentModuleThumbnailControllerProps
) => {
  const { contnetModuleType, thumbnailUploadedFile } = props;

  const { setImageFile, setImageUploadState } = useImageUploadHelpers();

  useEffect(() => {
    if (thumbnailUploadedFile) {
      setImageUploadState({
        state: "Uploaded",
        previewUrl: thumbnailUploadedFile.presignedUrl,
      });

      const jsonThumbnailUploadedFile = JSON.stringify(thumbnailUploadedFile);
      const blob = new Blob([jsonThumbnailUploadedFile], {
        type: "application/json",
      });
      setImageFile(blob);
    }
  }, [setImageUploadState, thumbnailUploadedFile, setImageFile]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px" $isOptional>
        {contnetModuleType} Thumbnail
        <span>(Optional)</span>
      </UXInputLabel>
      <ImageUpload readOnly />
    </UXInputContainer>
  );
};
