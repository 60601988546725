import { Link } from "react-router-dom";
import { TableHeader } from "../../../../components/table/table-header/table-header";
import { ContentCourseModulesTableContainer } from "../../content-course-modules/content-course-modules-table/styled-content-course-modules-table";
import { Button } from "../../../../styles/classes/reusable-classes";
import { Table } from "../../../../components/table/table";
import { ContentCourseModuleLessonsTableHeadings } from "./content-course-module-lessons-table-headings";
import { useState } from "react";
import { ContentCourseModuleLessonsTableRows } from "./content-course-module-lessons-table-rows/content-course-module-lessons-table-rows";
import { ContentModuleDTO } from "../../../../models/content-module-dtos/content-module-dto";

type ContentCourseModuleLessonsTableProps = {
  contentModuleDTO: ContentModuleDTO;
};

export const ContentCourseModuleLessonsTable = (
  props: ContentCourseModuleLessonsTableProps
) => {
  const { contentModuleDTO } = props;

  const [searchTerm, setSearchTerm] = useState("");

  const onSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  return (
    <ContentCourseModulesTableContainer>
      <TableHeader
        title="Lessons"
        searchTerm={searchTerm}
        setSearchTerm={onSearchTermChange}
        boldTitle
        button={
          <Link
            to={`../courses/course/${contentModuleDTO.parentContentModuleId}/module/${contentModuleDTO.id}/create-new-lesson`}
          >
            <Button flex type="button">
              <span className="material-symbols-outlined">add</span>
              Add Lessons
            </Button>
          </Link>
        }
      />
      <Table
        tableHeadings={<ContentCourseModuleLessonsTableHeadings />}
        tableRows={
          <ContentCourseModuleLessonsTableRows
            contentModuleDTO={contentModuleDTO}
            searchTerm={searchTerm}
          />
        }
      />
    </ContentCourseModulesTableContainer>
  );
};
