import { SearchInput } from "../../../../styles/atoms/search-input";
import {
  FoodLoggingTabsContainer,
  FoodLoggingTabsNav,
  FoodLoggingTabsNavLink,
} from "./styled-food-logging-patients-tabs";

export type FoodLoggingPatientsTab = "todo" | "all";

export type FoodLoggingPatientsTabsProps = {
  todoPatientsCount: number;
  selectedTab: FoodLoggingPatientsTab;
  onTabChange: (tab: FoodLoggingPatientsTab) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
};

export function FoodLoggingPatientsTabs(props: FoodLoggingPatientsTabsProps) {
  const {
    todoPatientsCount,
    selectedTab,
    onTabChange,
    searchTerm,
    setSearchTerm,
  } = props;

  return (
    <FoodLoggingTabsContainer>
      <SearchInput
        width="261px"
        valueProp={searchTerm}
        onChangeHandler={(e) => setSearchTerm(e.target.value)}
        placeHolder="Search by name or whatsapp ID"
      />
      <FoodLoggingTabsNav>
        <FoodLoggingTabsNavLink
          active={selectedTab === "todo"}
          onClick={() => onTabChange("todo")}
          disabled={selectedTab === "todo"}
        >
          Todo ({todoPatientsCount})
        </FoodLoggingTabsNavLink>
        <FoodLoggingTabsNavLink
          active={selectedTab === "all"}
          onClick={() => onTabChange("all")}
          disabled={selectedTab === "all"}
        >
          All
        </FoodLoggingTabsNavLink>
      </FoodLoggingTabsNav>
    </FoodLoggingTabsContainer>
  );
}

// <div>
// <div>
//   <button
//     onClick={() => onTabChange("todo")}
//     disabled={selectedTab === "todo"}
//   >
//     To do ({todoPatientsCount})
//   </button>
//   <button
//     onClick={() => onTabChange("finished")}
//     disabled={selectedTab === "finished"}
//   >
//     Finished
//   </button>
// </div>
// </div>
