import { useEffect } from "react";
import { UploadedFileWithPresignedUrlDTO } from "../../../../../../../../models/uploaded-file-dtos/uploaded-file-with-presigned-url-dto";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../styles/classes/reusable-classes";
import { VideoUpload } from "../../../../../../components/video-upload/video-upload";
import { useVideoUploadHelpers } from "../../../../../../components/video-upload/context/video-upload-helpers-context";

type LessonReadModeCardPartVideoProps = {
  thumbnailUploadedFile?: UploadedFileWithPresignedUrlDTO;
};

export const LessonReadModeCardPartVideo = (
  props: LessonReadModeCardPartVideoProps
) => {
  const { thumbnailUploadedFile } = props;

  const { setVideoFile, setVideoUploadState } = useVideoUploadHelpers();

  useEffect(() => {
    if (thumbnailUploadedFile) {
      setVideoUploadState({
        state: "Uploaded",
        previewUrl: thumbnailUploadedFile.presignedUrl,
      });

      const jsonThumbnailUploadedFile = JSON.stringify(thumbnailUploadedFile);
      const blob = new Blob([jsonThumbnailUploadedFile], {
        type: "application/json",
      });
      setVideoFile(blob);
    }
  }, [setVideoUploadState, thumbnailUploadedFile, setVideoFile]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="50px" $isOptional>
        Video
      </UXInputLabel>
      <VideoUpload readOnly />
    </UXInputContainer>
  );
};
