import styled from "styled-components/macro";
import { TimeLineDateInputContainerCSS } from "../styled-patient-profile-edit-timeline-modal-body";
import { RadioButton } from "../../../../../../../../styles/classes/reusable-classes";
import { PatientProfileModalInputInputContainerCSS } from "../../../../../styles/patient-profile-styles/patient-profile-css";

export const BariatricSurgeryInputContainer = styled.div`
  ${PatientProfileModalInputInputContainerCSS}

  ${RadioButton} {
    margin: 5px auto 13px 0px;
    width: 65px;
  }
`;

export const BariatricSurgeryDateInputContainer = styled.div`
  ${TimeLineDateInputContainerCSS}
  margin-left: 8px;
`;
