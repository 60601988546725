import { useFormContext } from "react-hook-form";
import { Button } from "../../../../../styles/classes/reusable-classes";
import { NutritionReportSummaryFooterContainer } from "./styled-nutrition-report-summary-footer";
import { NutrtionReportSummaryNotesInputs } from "../nutrition-report-summary";
import { useToastService } from "../../../../../context/toast-service-context";
import { useAddPatientNutritionReport } from "../../../../../hooks/patient-nutrition-reports-hooks/use-add-patient-nutrition-report";
import { useNavigate, useParams } from "react-router-dom";
import { CreatePatientNutritionReportDTO } from "../../../../../models/patient-nutrition-report-dtos/create-patient-nutrition-report-dto";
import { useNutritionReportSearchParams } from "../../../context/nutrition-report-context/nutrition-report-search-params-context";
import { CreatePatientNutritionReportDateDTO } from "../../../../../models/patient-nutrition-report-dtos/create-patient-nutrition-report-date-dto";
import { useState } from "react";
import { Loader } from "../../../../../components/loaders/otida-loader/styled-otida-loader";

export const NutritionReportSummaryFooter = () => {
  const { patientID } = useParams();
  const { searchParams } = useNutritionReportSearchParams();
  const [loading, setLoading] = useState(false);

  const { handleSubmit } = useFormContext<NutrtionReportSummaryNotesInputs>();

  const addPatientNutritionReport = useAddPatientNutritionReport();
  const { showToast } = useToastService();
  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (inputs) => {
    const createPatientNutritionReportDateDTOs: CreatePatientNutritionReportDateDTO[] =
      searchParams.getAll("selectedDays").map((date) => ({
        date,
      }));

    const createPatientNutritionReportDTO: CreatePatientNutritionReportDTO = {
      startDate: searchParams.get("startDate")!,
      endDate: searchParams.get("endDate")!,
      patientNutritionReportDates: createPatientNutritionReportDateDTOs,
      patientNutritionReportHealthyHabits:
        inputs.patientNutritionReportHealthyHabits!,
      patientNutritionReportUnhealthyHabits:
        inputs.patientNutritionReportUnhealthyHabits!,
    };

    try {
      setLoading(true);
      await addPatientNutritionReport(
        parseInt(patientID!),
        createPatientNutritionReportDTO
      ).then(() => {
        navigate(
          `/patients/${patientID}/dietitian-report/analysis-and-reports`
        );
      });
      setLoading(false);
      showToast("Success", "Report submitted successfully");
    } catch (error) {
      showToast("Error", "Failed to submit report :(");
    }
  });

  return (
    <NutritionReportSummaryFooterContainer>
      <Button type="button" onClick={onSubmit} disabled={loading}>
        {loading ? <Loader radius={20} /> : "Submit report"}
      </Button>
    </NutritionReportSummaryFooterContainer>
  );
};
