import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { FoodGroupDTO } from "../../models/food-groups/food-group-dto";

export function useGetFoodGroups(): (
  leafOnly?: boolean
) => Promise<FoodGroupDTO[]> {
  const client = useClient();

  return useCallback(
    async (leafOnly = undefined) => {
      const response = await client.get<FoodGroupDTO[]>(
        `${BASE_PATH}/food-groups`,
        {
          params: {
            leafOnly,
          },
        }
      );

      return response.data;
    },
    [client]
  );
}
