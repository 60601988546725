import { MessageInput } from "stream-chat-react";
import { MessagingInputContainer } from "./styled-messaging-input";
import { useState } from "react";
import { AudioRecorder } from "./audio-recorder/audio-recorder";

export const MessagingInput = () => {
  const [isRecording, setIsRecording] = useState(false);

  return (
    <MessagingInputContainer isRecording={isRecording}>
      <span className="plus">
        <img src="/img/chat/close-blue.svg" alt="Add an attachment" />
      </span>
      <MessageInput
        hideSendButton={false}
        additionalTextareaProps={{
          dir: "auto",
        }}
      />
      <AudioRecorder
        onStartRecording={() => setIsRecording(true)}
        onStopRecording={() => setIsRecording(false)}
      />
    </MessagingInputContainer>
  );
};
