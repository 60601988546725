import { CarbCounterV1MealSummaryDTO } from "../../../../../../../models/carb-counter-dtos/carb-counter-v1-meal-summary-dto";
import { roundTo1DecimalPlace } from "../../../../../../../utils/math-utils";
import {
  FoodLoggingSelectedPatientMealMacrosLegend,
  FoodLoggingSelectedPatientMealMacrosLegendPoint,
} from "../../../../legacy-food-logging-selected-patient-meal/food-logging-selected-patient-meal-macros/styled-food-logging-selected-patient-meal-macros";

type CarbCounterFoodLoggingSelectedPatientMealMacrosLegendProps = {
  carbCounterV1MealSummaryDTO?: CarbCounterV1MealSummaryDTO;
};

export const CarbCounterFoodLoggingSelectedPatientMealMacrosLegend = (
  props: CarbCounterFoodLoggingSelectedPatientMealMacrosLegendProps
) => {
  const { carbCounterV1MealSummaryDTO } = props;

  const definedCarbCounterV1MealSummaryDTO =
    carbCounterV1MealSummaryDTO !== undefined;

  const carbsGM = definedCarbCounterV1MealSummaryDTO
    ? `: ${roundTo1DecimalPlace(carbCounterV1MealSummaryDTO.totalCarbsGm)} gm`
    : ``;

  const fatsGM = definedCarbCounterV1MealSummaryDTO
    ? `: ${roundTo1DecimalPlace(carbCounterV1MealSummaryDTO.totalFatGm)} gm`
    : ``;

  const protienGM = definedCarbCounterV1MealSummaryDTO
    ? `: ${roundTo1DecimalPlace(carbCounterV1MealSummaryDTO.totalProteinGm)} gm`
    : ``;

  return (
    <FoodLoggingSelectedPatientMealMacrosLegend>
      <FoodLoggingSelectedPatientMealMacrosLegendPoint>
        <div />
        <span>Carbs{carbsGM}</span>
      </FoodLoggingSelectedPatientMealMacrosLegendPoint>
      <FoodLoggingSelectedPatientMealMacrosLegendPoint>
        <div />
        <span>Fat{fatsGM}</span>
      </FoodLoggingSelectedPatientMealMacrosLegendPoint>
      <FoodLoggingSelectedPatientMealMacrosLegendPoint>
        <div />
        <span>Protien{protienGM}</span>
      </FoodLoggingSelectedPatientMealMacrosLegendPoint>
    </FoodLoggingSelectedPatientMealMacrosLegend>
  );
};
