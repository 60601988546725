import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  mediumFont,
  regularSemiBoldFont,
} from "../../../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../../../styles/global-style";

export const CreateMessagingChannelContainer = styled.div<{ show: boolean }>`
  width: 300px;
  position: absolute;
  inset: 0;
  top: 2%;
  border-radius: 8px 8px 0px 0px;
  z-index: 5;
  padding: 16px 8px;
  transition: ${({ show }) => (show ? transition : "")};
  transform: ${({ show }) => (show ? "translateY(0%)" : "translateY(100%)")};
  background: ${({ theme }) => theme.colors.containerColor};
  overflow: ${({ show }) => (show ? "visible" : "hidden")};
  padding: ${({ show }) => (show ? "16px 8px" : "0px")};
  height: ${({ show }) => (show ? "auto" : "0px")};
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22);
`;

export const CreateMessagingChannelHeader = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

export const CreateMessagingChannelHeaderTitle = styled.div`
  ${flexCenter}

  p {
    margin: 0px 0px 0px auto;
    color: ${({ theme }) => theme.colors.titleColor};
    ${regularSemiBoldFont}
  }

  div {
    margin-left: auto;
    ${flexCenterCenter}
    cursor: pointer;

    img {
      opacity: 0.6;
      width: 20px;
      height: 20px;
    }
  }
`;

export const CreateMessagingChannelHeaderInputContainer = styled.div`
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.inputColor};
  margin-top: 16px;

  input {
    outline: 0px;
    border: 0px;
    background: transparent;
  }
`;

export const CreateMessagingChannelHeaderCreateNewGroupButton = styled.button`
  ${flexCenterCenter}
  gap: 16px;
  margin-top: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.buttonColor};
  padding: 8px 16px;
  opacity: 0.9;
  ${mediumFont}
  transition: ${transition};

  img {
    filter: invert(1);
  }

  :hover {
    opacity: 1;
  }
`;

export const CreateMessagingChannelUsers = styled.div`
  overflow-y: auto;
  height: calc(100% - (16px + 155px));

  ::after {
    content: "";
    display: block;
    height: 250px;
  }
`;

export const CreateMessagingChannelUser = styled.button`
  ${flexCenter}
  width: 100%;
  padding: 16px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: transparent;
  transition: ${transition};

  :last-child {
    border: 0px;
  }

  :hover {
    background: ${({ theme }) => theme.newColors.otidaO2};
  }

  span {
    text-align: left;
  }
`;
