import styled from "styled-components/macro";
import {
  CGMReportResponseGlucoseStatisticsBody,
  CGMReportResponseGlucoseStatisticsBodyItem,
} from "../../../../../single-patient/bgl-analysis/cgm-report/cgm-report-response/cgm-report-response-cards/cgm-report-response-glucose-statistics/styled-cgm-report-response-glucose-statistics";
import { flexCenter } from "../../../../../../styles/classes/gloabl-classes";
import { lightShadowAlt } from "../../../../../../styles/global-style";

export const ImmediateAttentionPatientsSidebarGlucoseTabContainer = styled.div`
  .caption {
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textColor};
  }

  ${CGMReportResponseGlucoseStatisticsBody} {
    ${flexCenter}

    p {
      font-size: 14px;
      font-weight: 600;
      margin-top: 0px;
    }

    .shrink-cgm-report-title {
      ${flexCenter}
      position: relative;

      .material-symbols-outlined {
        font-size: 16px;
        cursor: pointer;
        margin-left: 12px;

        :hover .shrinked-cgm-report-span {
          display: block;
        }
      }

      .shrinked-cgm-report-span {
        display: none;
        position: absolute;
        left: 0;
        top: 100%; /* Position it below the title */
        background-color: ${({ theme }) => theme.colors.containerColor};
        ${lightShadowAlt}
        z-index: 10;
      }
    }

    .material-symbols-outlined:hover + .shrinked-cgm-report-span {
      display: block;
    }
  }

  ${CGMReportResponseGlucoseStatisticsBodyItem} {
    width: 50%;
  }
`;
