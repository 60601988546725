import { PatientProfileSection } from "../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileBloodPressure } from "./patient-profile-blood-pressure/patient-profile-blood-pressure";
import { PatientProfileBmi } from "./patient-profile-bmi/patient-profile-bmi";
import { PatientProfileWeight } from "./patient-profile-weight/patient-profile-weight";

export const PatientProfileWeightSection = () => {
  return (
    <PatientProfileSection
      reverse={false}
      threeContainers
      style={{ zIndex: "395", position: "relative" }}
    >
      <PatientProfileBmi />
      <PatientProfileWeight />
      <PatientProfileBloodPressure />
    </PatientProfileSection>
  );
};
