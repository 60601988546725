import { useState } from "react";
import { TableHeader } from "../../../../components/table/table-header/table-header";
import { Link } from "react-router-dom";
import { Button } from "../../../../styles/classes/reusable-classes";
import { Table } from "../../../../components/table/table";
import { ContentCourseModulesTableHeadings } from "./content-course-modules-table-headings";
import { ContentCourseModulesTableRows } from "./content-course-modules-table-rows/content-course-modules-table-rows";
import { ContentModuleDTO } from "../../../../models/content-module-dtos/content-module-dto";
import { ContentCourseModulesTableContainer } from "./styled-content-course-modules-table";

type ContentCourseModulesTableProps = {
  contentModuleDTO: ContentModuleDTO;
};

export const ContentCourseModulesTable = (
  props: ContentCourseModulesTableProps
) => {
  const { contentModuleDTO } = props;

  const [searchTerm, setSearchTerm] = useState("");

  function onSearchTermChange(searchTerm: string) {
    setSearchTerm(searchTerm);
  }

  return (
    <ContentCourseModulesTableContainer>
      <TableHeader
        title="Modules"
        searchTerm={searchTerm}
        setSearchTerm={onSearchTermChange}
        boldTitle
        button={
          <Link
            to={`../courses/course/${contentModuleDTO.id}/module/create-new-module`}
          >
            <Button flex type="button">
              <span className="material-symbols-outlined">add</span>
              Add Module
            </Button>
          </Link>
        }
      />
      <Table
        tableHeadings={<ContentCourseModulesTableHeadings />}
        tableRows={
          <ContentCourseModulesTableRows
            contentModuleDTO={contentModuleDTO}
            searchTerm={searchTerm}
          />
        }
      />
    </ContentCourseModulesTableContainer>
  );
};
