import {
  Category,
  ChartComponent,
  DataLabel,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  StackingColumnSeries,
  Legend,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { useCallback, useMemo, useRef } from "react";
import { randomUUID } from "../../../../../../../utils/random-uuid";
import { buildPrimaryXAxis } from "./utils/build-primary-x-axis";
import { useNutritionAnalysisData } from "../../../context/nutrition-analysis-data-context";
import { buildPrimaryYAxis } from "./utils/build-primary-y-axis";
import { buildAxisLabelRender } from "./utils/build-axis-label-render";
import { buildMacrosSeries } from "./utils/series/build-macros-series";
import {
  NutritionAnalysisMacrosChartContainer,
  NutritionAnalysisMacrosChartWrapper,
} from "./styled-nutrition-analysis-macros-chart";

export const NutritionAnalysisMacrosChart = () => {
  const {
    groupedPatientMealsByDate,
    patientMeals,
    groupedPatientMealsByDateTotalKcals,
  } = useNutritionAnalysisData();

  const id = useMemo(
    () => `nutrition-analysis-macros-syncfusion-chart-${randomUUID()}`,
    []
  );

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);

    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const chartRef = useRef<ChartComponent | null>(null);

  const columnWidth = 0.3;

  const primaryxAxis = useMemo(() => buildPrimaryXAxis(), []);

  const minY = useMemo(
    () =>
      Math.min(
        ...[
          0,
          ...patientMeals.map((entry) => entry.patientMealMacrosSummary.kCals),
        ]
      ),
    [patientMeals]
  );

  const maxY = useMemo(
    () => Math.max(...[0, ...groupedPatientMealsByDateTotalKcals]) + 200,
    [groupedPatientMealsByDateTotalKcals]
  );

  const primaryyAxis = useMemo(
    () => buildPrimaryYAxis(maxY, minY),
    [maxY, minY]
  );

  const { vAxisTicks } = useMemo(() => {
    const sortedIntegerKcals = groupedPatientMealsByDateTotalKcals
      .sort((a, b) => a - b)
      .map((kcal) => Math.floor(kcal));

    const vAxisTicks: number[] = [];
    const maxKcal = Math.max(...sortedIntegerKcals);
    const step = maxKcal > 1000 ? 500 : 50;

    for (let i = 0; i <= maxKcal; i += step) {
      vAxisTicks.push(i);
    }

    return { vAxisTicks };
  }, [groupedPatientMealsByDateTotalKcals]);

  const axisLabelRender = useMemo(
    () => buildAxisLabelRender(vAxisTicks),
    [vAxisTicks]
  );

  const macrosSeries = useMemo(
    () => buildMacrosSeries(groupedPatientMealsByDate),
    [groupedPatientMealsByDate]
  );

  return (
    <NutritionAnalysisMacrosChartContainer>
      <NutritionAnalysisMacrosChartWrapper>
        <ChartComponent
          id={id}
          primaryXAxis={primaryxAxis}
          primaryYAxis={primaryyAxis}
          loaded={handleChartLoaded}
          axisLabelRender={axisLabelRender}
          border={{ width: 0 }}
          ref={chartRef}
          height="400px"
          width="100%"
        >
          <Inject
            services={[
              StackingColumnSeries,
              DataLabel,
              Category,
              Legend,
              StripLine,
            ]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={macrosSeries}
              xName="x"
              yName="y"
              name="Carbs"
              type="StackingColumn"
              fill="#71b3ae"
              columnWidth={columnWidth}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={macrosSeries}
              xName="x"
              yName="y1"
              name="Fat"
              type="StackingColumn"
              fill="#5b2184"
              columnWidth={columnWidth}
            ></SeriesDirective>
            <SeriesDirective
              dataSource={macrosSeries}
              xName="x"
              yName="y2"
              name="Protein"
              type="StackingColumn"
              fill="#e9b466"
              columnWidth={columnWidth}
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </NutritionAnalysisMacrosChartWrapper>
    </NutritionAnalysisMacrosChartContainer>
  );
};
