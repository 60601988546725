import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  PatientProfileAutoSelectContainer,
  PatientProfileAutoSelectFormControlContainer,
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileTagList,
  PatientProfileTagListItem,
  PatientProfileTagsLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileMedicalHistoryEditModalInputs } from "../../profile-medical-history-edit-modal";
import {
  DIABETES_EMERGENCIES,
  DiabetesEmergency,
} from "../../../../../../../../models/emergency";
import { AutoSelect } from "../../../../../../../../components/auto-select/auto-select";
import { useState } from "react";
import { PutPatientMedicalHistoryDiabetesEmergencyDTO } from "../../../../../../../../models/patient-medical-history-dtos/put-patient-medical-history-diabetes-emergency-dto";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";
import { useEnterKeypress } from "../../../../../../../../hooks/use-enter-keypress/use-enter-keypress";
import {
  DisplayEmergencies,
  revertViewEmergencies,
  viewEmergencies,
} from "../../../../../utils/patient-profile-utils";

export const PatientProfileDiabetesEmergenciesFormControl = () => {
  const { control, watch } = useFormContext<
    PatientProfileMedicalHistoryEditModalInputs
  >();

  const { append, fields, remove } = useFieldArray({
    name: "diabetesEmergencies",
    control,
  } as never);

  const diabetesEmergencies = watch("diabetesEmergencies");

  const [autoSelectTagValue, setAutoSelectTagValue] = useState<
    PutPatientMedicalHistoryDiabetesEmergencyDTO | undefined
  >();

  const handleAppend = () => {
    if (
      diabetesEmergencies !== undefined &&
      autoSelectTagValue !== undefined &&
      !diabetesEmergencies.some(
        (obj) => obj.emergency === autoSelectTagValue.emergency
      ) &&
      DIABETES_EMERGENCIES.some(
        (obj) =>
          obj ===
          revertViewEmergencies(
            autoSelectTagValue?.emergency as DisplayEmergencies
          )
      )
    ) {
      append(autoSelectTagValue);
      setAutoSelectTagValue(undefined);
    }
  };

  const { onEnterKeyPress } = useEnterKeypress(handleAppend);

  return (
    <PatientProfileModalFormControl>
      <PatientProfileTagsLabelContainer>
        <label>Diabetes Emergencies</label>
      </PatientProfileTagsLabelContainer>
      <PatientProfileModalInputContainer>
        <Controller
          control={control}
          name="diabetesEmergencies"
          render={({ field: { value, onChange } }) => {
            return (
              <PatientProfileAutoSelectFormControlContainer>
                <PatientProfileAutoSelectContainer>
                  <AutoSelect
                    itemsArray={DIABETES_EMERGENCIES.map((item) =>
                      viewEmergencies(item)
                    )}
                    onChange={(value: string) =>
                      setAutoSelectTagValue({
                        emergency: revertViewEmergencies(
                          value as DisplayEmergencies
                        ) as DiabetesEmergency,
                      })
                    }
                    value={viewEmergencies(
                      autoSelectTagValue?.emergency as DiabetesEmergency
                    )}
                    fullWidth
                    placeHolder="Select Diabetes Emergency"
                  />
                  <Button
                    type="button"
                    onClick={handleAppend}
                    onKeyDown={(e) => onEnterKeyPress(e)}
                    width={100}
                    height={40}
                    flex
                    outlineNoBorder
                  >
                    <span className="material-symbols-outlined">add</span> Add
                  </Button>
                </PatientProfileAutoSelectContainer>
                <PatientProfileTagList>
                  {fields.map((field, index) => (
                    <PatientProfileTagListItem key={field.id} variant="Normal">
                      {diabetesEmergencies !== undefined
                        ? viewEmergencies(diabetesEmergencies[index].emergency)
                        : ""}
                      <span
                        className="material-symbols-outlined"
                        onClick={() => remove(index)}
                      >
                        close
                      </span>
                    </PatientProfileTagListItem>
                  ))}
                </PatientProfileTagList>
              </PatientProfileAutoSelectFormControlContainer>
            );
          }}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
