import { ReactNode } from "react";
import { SearchInput } from "../../styles/atoms/search-input";
import {
  EmptyStateBody,
  EmptyStateBodySubText,
  EmptyStateHeader,
} from "./styled-empty-state";

type EmptyStateProps = {
  title: string;
  text: string;
  subText?: string;
  button: ReactNode;
  hasSearch: boolean;
  hasHeader: boolean;
  dataTestId?: string;
  img?: "Exclamation";
};

export const EmptyState = (props: EmptyStateProps) => {
  const {
    button,
    text,
    subText,
    title,
    hasSearch,
    hasHeader,
    dataTestId,
    img,
  } = props;

  return (
    <>
      {hasHeader ? (
        <EmptyStateHeader>
          <p data-testid={dataTestId}>{title}</p>
          {hasSearch ? <SearchInput /> : <></>}
        </EmptyStateHeader>
      ) : (
        <></>
      )}
      <EmptyStateBody>
        <img
          src={
            img !== undefined
              ? "/img/exclamation-mark.jpg"
              : "/img/empty-state.svg"
          }
          alt="Empty Screen"
        />
        <p>{text}</p>
        <EmptyStateBodySubText>{subText}</EmptyStateBodySubText>
        {button}
      </EmptyStateBody>
    </>
  );
};
