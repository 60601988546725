import { Reading } from "../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { roundTo1DecimalPlace } from "../../../math-utils";
import { buildDaysAggregatesReadingsWithTimeDifference } from "../../build-readings-with-time-differance-value";
import { calculateReadingsTimeDiffrenceTotal } from "../../calculate-readings-time-differance-total";
import { calculateLowReadingsTimeDiffrenceTotal } from "../calculate-low-readings-time-differance-total";

export function calculateLowPercentage(readings: Reading[], minRange: number) {
  const readingsWithTimeDifferenceValue = buildDaysAggregatesReadingsWithTimeDifference(
    readings
  );

  const lowReadingsTimeDiffrenceTotal = calculateLowReadingsTimeDiffrenceTotal(
    readingsWithTimeDifferenceValue,
    minRange
  );
  const readingsTimeDifferanceTotal = calculateReadingsTimeDiffrenceTotal(
    readingsWithTimeDifferenceValue
  );

  const lowPercentage = roundTo1DecimalPlace(
    (lowReadingsTimeDiffrenceTotal / readingsTimeDifferanceTotal) * 100
  );

  return lowPercentage;
}
