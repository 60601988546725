import { useMemo } from "react";
import { GenericActionsList } from "../../../../../../../components/generic-actions-list/generic-actions-list";
import { CreatePatientPrescriptionMedicationDTO } from "../../../../../../../models/patient-prescriptions/create-patient-prescription-medication-dto";
import {
  MedicationCardIconContainer,
  MedicationCardText,
  MedicationCardTitle,
} from "../../../../components/medication-card/styled-medication-card";
import { usePrescriptions } from "../../../context/prescriptions-context";
import { DeleteMedicine } from "./delete-medicine/delete-medicine";
import { EditMedicine } from "./edit-medicine/edit-medicine";
import {
  AddedPrescriptionMedicinesCardContainer,
  AddedPrescriptionMedicinesCardDetails,
  AddedPrescriptionMedicinesCardIcon,
} from "./styled-added-prescription-medicines-card";
import { useGetMedicationDTOs } from "../../../../hooks/use-get-medication-dtos";
import { useGetFrequencyDTOs } from "../../../../hooks/use-get-frequency-dtos";

type AddedPrescriptionMedicinesCardProps = {
  card: CreatePatientPrescriptionMedicationDTO;
};

export const AddedPrescriptionMedicinesCard = (
  props: AddedPrescriptionMedicinesCardProps
) => {
  const { card } = props;

  const { medicationDTOs } = useGetMedicationDTOs();
  const { frequencyDTOs } = useGetFrequencyDTOs();

  const { startDeleting } = usePrescriptions();

  const currentMedication = useMemo(
    () => medicationDTOs.find((item) => item.id === card.medicationId),
    [card.medicationId, medicationDTOs]
  );

  const currentFrequency = useMemo(
    () => frequencyDTOs.find((item) => item.id === card.frequencyId),
    [card.frequencyId, frequencyDTOs]
  );

  const editFormHeaderData = {
    name: currentMedication?.name,
    strength:
      currentMedication?.strength !== undefined
        ? ` - ${currentMedication?.strength}`
        : ``,
    routeOfAdministration: currentMedication?.routeOfAdministration,
    doseUnit: currentMedication?.doseUnit,
  };

  return (
    <AddedPrescriptionMedicinesCardContainer>
      <AddedPrescriptionMedicinesCardDetails>
        <MedicationCardIconContainer>
          <span className="material-symbols-outlined">medication</span>
        </MedicationCardIconContainer>
        <div>
          <MedicationCardTitle>
            {currentMedication?.name}
            <span>
              {currentMedication?.strength !== undefined
                ? ` - ${currentMedication?.strength}`
                : ``}
            </span>
          </MedicationCardTitle>
          <MedicationCardText>
            {`${card.quantity} ${
              currentMedication?.doseUnit !== undefined
                ? currentMedication.doseUnit
                : ""
            }`}
            {`, ${currentFrequency?.explanation} for ${card.durationDays} days`}
          </MedicationCardText>
        </div>
      </AddedPrescriptionMedicinesCardDetails>
      <AddedPrescriptionMedicinesCardIcon>
        <GenericActionsList customZIndex="300">
          <EditMedicine
            card={card}
            currentFrequency={currentFrequency}
            formHeaderData={editFormHeaderData}
          />
          <DeleteMedicine
            currentMedicationName={
              currentMedication?.name !== undefined
                ? currentMedication?.name
                : ""
            }
            handleDeleteMedicine={() => startDeleting(card)}
          />
        </GenericActionsList>
      </AddedPrescriptionMedicinesCardIcon>
    </AddedPrescriptionMedicinesCardContainer>
  );
};
