import { Temporal } from "temporal-polyfill";
import { Meal, Medicine, Reading } from "../build-days-aggregates";
import { GoogleDataTableRow } from "react-google-charts";
import { buildGapRow } from "./build-gap-row";
import { buildReadingsRows } from "./build-readings-rows/build-readings-rows";
import { buildMealsRows } from "./build-meals-rows/build-meals-rows";
import { buildMedicinesRows } from "./build-medicines-rows/build-medicines-rows";
import { PatientMealDTO } from "../../../../models/patient-meal-dtos/patient-meal-dto";
import { buildPatientMealsRows } from "./build-patient-meals-rows/build-patient-meals-rows";
import { PatientActivityLogDTO } from "../../../../models/patient-activity-log-dtos/patient-activity-log-dto";
import { buildPatientActivitiesRows } from "./build-activities-rows/build-activities-rows";
import { PatientDTO } from "../../../../models/patient-dtos/patient-dto";
import { buildPatientInsulinLogRows } from "./build-insulin-rows/build-insulin-rows";
import { PatientInsulinLogDTO } from "../../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";

export function buildRows(
  date: Temporal.PlainDate,
  readings: Reading[],
  meals: Meal[],
  medicines: Medicine[],
  reverseVAxisTicks: number[],
  patientMealsDTO: PatientMealDTO[],
  patientActivityLogs: PatientActivityLogDTO[],
  patientInsulinLogDTOs: PatientInsulinLogDTO[],
  patientDTO: PatientDTO
): GoogleDataTableRow[] {
  const rows: GoogleDataTableRow[] = [];

  const plainDate = Temporal.PlainDate.from(date);

  const plainDateAt0000 = plainDate.toPlainDateTime({
    hour: 0,
    minute: 0,
    second: 0,
  });
  rows.push(buildGapRow(plainDateAt0000, reverseVAxisTicks));

  const readingsRows = buildReadingsRows(
    date,
    readings,
    reverseVAxisTicks,
    patientDTO
  );
  rows.push(...readingsRows);

  const mealsRows = buildMealsRows(date, meals, reverseVAxisTicks);
  rows.push(...mealsRows);

  const medicinesRows = buildMedicinesRows(date, medicines, reverseVAxisTicks);
  rows.push(...medicinesRows);

  const patientMealsRows = buildPatientMealsRows(
    date,
    patientMealsDTO,
    reverseVAxisTicks
  );
  rows.push(...patientMealsRows);

  const patientActivityLogsRows = buildPatientActivitiesRows(
    date,
    patientActivityLogs,
    reverseVAxisTicks
  );
  rows.push(...patientActivityLogsRows);

  const patientInsulinLogsRows = buildPatientInsulinLogRows(
    date,
    patientInsulinLogDTOs,
    reverseVAxisTicks
  );
  rows.push(...patientInsulinLogsRows);

  const nextPlainDateTimeAt0000 = plainDate.add({ days: 1 }).toPlainDateTime({
    hour: 0,
    minute: 0,
    second: 0,
  });
  rows.push(buildGapRow(nextPlainDateTimeAt0000, reverseVAxisTicks));

  return rows;
}
