import { Outlet } from "react-router-dom";
import { SinglePatientLayout } from "./single-patient-layout/single-patient-layout";
import { SinglePatientSidebarUIProvider } from "./context/single-patient-sidebar-ui-context";

export const SinglePatient = () => {
  return (
    <SinglePatientSidebarUIProvider>
      <SinglePatientLayout>
        <Outlet />
      </SinglePatientLayout>
    </SinglePatientSidebarUIProvider>
  );
};
