import { Controller, useFormContext } from "react-hook-form";
import {
  PatientProfileModalFormControl,
  PatientProfileModalFormControlFlexNoStyles,
  PatientProfileModalInputContainer,
  PatientProfileModalLabelContainer,
} from "../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileEditProfileInputs } from "../patient-profile-edit-profile-modal";

export const PatientProfileEditProfilePatientJobFormControl = () => {
  const { control } = useFormContext<PatientProfileEditProfileInputs>();

  return (
    <PatientProfileModalFormControl gridLayout>
      <PatientProfileModalFormControlFlexNoStyles>
        <PatientProfileModalLabelContainer>
          <label>Job</label>
        </PatientProfileModalLabelContainer>
        <PatientProfileModalInputContainer hasOneInput>
          <span className="material-symbols-outlined">business_center</span>
          <Controller
            name="job"
            control={control}
            rules={{ required: false }}
            render={({ field: { value, onChange } }) => (
              <input
                type="text"
                placeholder="Job"
                value={value !== undefined ? value : undefined}
                onChange={(e) =>
                  onChange(
                    e.target.value !== undefined ? e.target.value : undefined
                  )
                }
              />
            )}
          />
        </PatientProfileModalInputContainer>
      </PatientProfileModalFormControlFlexNoStyles>
    </PatientProfileModalFormControl>
  );
};
