import styled, { css } from "styled-components/macro";
import {
  flexCenterCenter,
  mediumFont,
  regularRegularFont,
} from "../../../../../styles/classes/gloabl-classes";

export const MedicationCardImageContainerCSS = css`
  ${flexCenterCenter}
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin-right: 1rem;
  background: ${({ theme }) => theme.colors.borderColor};

  img {
    width: 70%;
    height: 70%;
  }
`;

export const MedicationCardImageContainer = styled.div`
  ${MedicationCardImageContainerCSS}
`;

export const MedicationCardIconContainerCSS = css`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenterCenter}
`;

export const MedicationCardIconContainer = styled.div`
  ${MedicationCardIconContainerCSS}
`;

export const MedicationCardTitleCSS = css`
  ${regularRegularFont}
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0;
  word-break: break-all;
`;

export const MedicationCardTitle = styled.p`
  ${MedicationCardTitleCSS}

  span {
    margin-top: 4px;
    ${mediumFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const MedicationCardTextCSS = css`
  margin-top: 4px;
  ${mediumFont}
  color: ${({ theme }) => theme.colors.textColor};
`;

export const MedicationCardText = styled.span`
  ${MedicationCardTextCSS}
`;
