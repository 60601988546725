import { useMemo } from "react";
import { DayAggregate } from "../../../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import {
  DailyFixedSummaryChartCardChildContainer,
  DailyFixedSummaryChartCardContainer,
} from "./styled-daily-fixed-summary-chart-card";
import { roundTo1DecimalPlace } from "../../../../../../../utils/math-utils";

type DailyFixedSummaryChartCardProps = {
  dayAggregate: DayAggregate;
};

export const DailyFixedSummaryChartCard = (
  props: DailyFixedSummaryChartCardProps
) => {
  const { dayAggregate } = props;

  const patientCGMEntryDTOs = dayAggregate.patientCGMEntryDTOs;

  const { average } = useMemo(() => {
    let total = 0;

    if (patientCGMEntryDTOs.length === 0) return { average: 0 };

    for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
      const glucoseReading = patientCGMEntryDTO.glucoseMGPerDL;

      total += glucoseReading;
    }

    const average = roundTo1DecimalPlace(total / patientCGMEntryDTOs.length);

    return { average };
  }, [patientCGMEntryDTOs]);

  const patientMealDTOs = dayAggregate.patientMealDTO;

  const { totalCarbs } = useMemo(() => {
    let totalCarbCounter = 0;
    let totalMacrosSummary = 0;

    let totalCarbs = 0;

    for (const patientMealDTO of patientMealDTOs) {
      if (patientMealDTO.carbCounterV1MealSummary) {
        const carbCounterMacrosTotalCarbs =
          patientMealDTO.carbCounterV1MealSummary.totalCarbsGm;

        totalCarbCounter += carbCounterMacrosTotalCarbs;
        totalCarbs = totalCarbCounter;
      } else {
        const patientMealMacrosCarbsGM =
          patientMealDTO.patientMealMacrosSummary.carbsGm;

        totalMacrosSummary += patientMealMacrosCarbsGM;
        totalCarbs = totalMacrosSummary;
      }
    }

    return { totalCarbs: roundTo1DecimalPlace(totalCarbs) };
  }, [patientMealDTOs]);

  const patientInsulinLogDTOs = dayAggregate.patientInsulinLogDTOs;

  const { totalInsulin } = useMemo(() => {
    let totalInsulin = 0;

    for (const patientInsulinLogDTO of patientInsulinLogDTOs) {
      const insulinReading = patientInsulinLogDTO.dose;

      totalInsulin += insulinReading;
    }

    return { totalInsulin: roundTo1DecimalPlace(totalInsulin) };
  }, [patientInsulinLogDTOs]);

  const { numberOfHypos } = useMemo(() => {
    let numberOfHypos: number = 0;

    let previousGlucose: number | null = null;

    for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
      if (
        previousGlucose !== null &&
        patientCGMEntryDTO.glucoseMGPerDL !== null
      ) {
        if (previousGlucose >= 70 && patientCGMEntryDTO.glucoseMGPerDL < 70) {
          numberOfHypos++;
        }
      }

      previousGlucose = patientCGMEntryDTO.glucoseMGPerDL;
    }

    return { numberOfHypos: roundTo1DecimalPlace(numberOfHypos) };
  }, [patientCGMEntryDTOs]);

  return (
    <DailyFixedSummaryChartCardContainer>
      <DailyFixedSummaryChartCardChildContainer>
        <img src="/img/readings.svg" alt="glucose reader" />
        <p>
          {average} <span>mg/dl</span>
        </p>
      </DailyFixedSummaryChartCardChildContainer>
      <DailyFixedSummaryChartCardChildContainer>
        <img src="/img/meals.svg" alt="apple fruit" />
        <p>
          {totalCarbs} <span>grams</span>
        </p>
      </DailyFixedSummaryChartCardChildContainer>
      <DailyFixedSummaryChartCardChildContainer>
        <img src="/img/insulin.svg" alt="insulin pen" />
        <p>
          {totalInsulin} <span>unit{totalInsulin > 1 ? "s" : ""}</span>
        </p>
      </DailyFixedSummaryChartCardChildContainer>
      <DailyFixedSummaryChartCardChildContainer>
        <img src="/img/hypos.png" alt="red arrow pointing down" />
        <p>
          {numberOfHypos} <span>hypo{numberOfHypos > 1 ? "s" : ""}</span>
        </p>
      </DailyFixedSummaryChartCardChildContainer>
    </DailyFixedSummaryChartCardContainer>
  );
};
