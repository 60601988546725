import { PatientMealDTO } from "../../../../models/patient-meal-dtos/patient-meal-dto";
import { Temporal } from "temporal-polyfill";
import { Button } from "../../../../styles/classes/reusable-classes";
import { useModalHelpers } from "../../../modal/use-modal-helpers";
import { Modal } from "../../../modal/modal";
import { roundTo1DecimalPlace } from "../../../../utils/math-utils";
import {
  PatientMealBodyFoodGroupContainer,
  PatientMealBodyFoodGroupsContainer,
  PatientMealBodySourceContainer,
  PatientMealBodySourcesContainer,
  PatientMealSorurceTitle,
  PatientMealTooltipBody,
  PatientMealTooltipBodyImageContainer,
  PatientMealTooltipBodyNoteContainer,
  PatientMealTooltipBodyVoiceNoteContainer,
  PatientMealTooltipBodyVoiceNotesContainer,
  PatientMealTooltipContainer,
  PatientMealTooltipHeader,
  PatientMealTooltipHeaderBottom,
  PatientMealTooltipHeaderTop,
} from "../../../../styles/classes/tooltips";
import { FoodLoggingSelectedPatientMealImagesModal } from "../../../../screens/food-logging/food-logging-selected-patient-meal/components/food-logging-selected-patient-meal-images/food-logging-selected-patient-meal-images-modal/food-logging-selected-patient-meal-images-modal";

type PatientMealsTooltipProps = {
  x: Date;
  y: number;
  data: PatientMealDTO;
};

export const PatientMealsTooltip = (props: PatientMealsTooltipProps) => {
  const { data } = props;

  const mealTag = data.tag;

  const carbCounterV1MealSummary =
    data.carbCounterV1MealSummary !== undefined ? true : false;

  const foodGroups =
    data.carbCounterV1MealSummary !== undefined
      ? data.carbCounterV1MealSummary.foodGroups.filter(
          (item) => item.servingsCount > 0
        )
      : data.patientMealFoodGroups;

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  return (
    <PatientMealTooltipContainer>
      <PatientMealTooltipHeader>
        <PatientMealTooltipHeaderTop>
          <p>
            {roundTo1DecimalPlace(
              carbCounterV1MealSummary
                ? data.carbCounterV1MealSummary?.totalKcal!
                : data.patientMealMacrosSummary.kCals
            )}{" "}
            Kcal
            <span>
              {mealTag !== undefined && mealTag !== null ? mealTag : ""}
            </span>
          </p>
          <div>
            <strong>
              {Temporal.PlainTime.from(data.time).toLocaleString("en-UK", {
                year: undefined,
                month: undefined,
                day: undefined,
                hour12: true,
                hour: "2-digit",
                minute: "2-digit",
                second: undefined,
              })}
            </strong>
            {!data.finishedFoodLogging && <strong>(Draft)</strong>}
          </div>
          <span className="material-symbols-outlined">close</span>
        </PatientMealTooltipHeaderTop>
        <PatientMealTooltipHeaderBottom>
          <p>
            Carbs:{" "}
            {roundTo1DecimalPlace(
              carbCounterV1MealSummary
                ? data.carbCounterV1MealSummary?.carbsKcalPercentage!
                : data.patientMealMacrosSummary.carbsPercentage
            )}
            %
          </p>
          <p>
            Fats:{" "}
            {roundTo1DecimalPlace(
              carbCounterV1MealSummary
                ? data.carbCounterV1MealSummary?.fatKcalPercentage!
                : data.patientMealMacrosSummary.fatPercentage
            )}
            %
          </p>
          <p>
            Proteins:{" "}
            {roundTo1DecimalPlace(
              carbCounterV1MealSummary
                ? data.carbCounterV1MealSummary?.proteinKcalPercentage!
                : data.patientMealMacrosSummary.proteinPercentage
            )}
            %
          </p>
        </PatientMealTooltipHeaderBottom>
      </PatientMealTooltipHeader>
      <PatientMealTooltipBody>
        {data.patientMealImages.length !== 0 && (
          <PatientMealTooltipBodyImageContainer>
            <img
              src={data.patientMealImages[0].publicUrl}
              alt="Meal"
              crossOrigin="anonymous"
              onClick={openModal}
            />
            <Button outlineNoBorder type="button" onClick={openModal}>
              {data.patientMealImages.length}{" "}
              {`Image${data.patientMealImages.length > 1 ? `s` : ``}`}
            </Button>
            <Modal handleHideModal={closeModal} showModal={isModalOpen}>
              <FoodLoggingSelectedPatientMealImagesModal
                key={modalBodyKey}
                patientMealImageDTOs={data.patientMealImages}
              />
            </Modal>
          </PatientMealTooltipBodyImageContainer>
        )}
        {data.patientMealVoiceNotes.length !== 0 && (
          <PatientMealTooltipBodyVoiceNotesContainer>
            {data.patientMealVoiceNotes.map((voiceNote) => (
              <PatientMealTooltipBodyVoiceNoteContainer
                key={voiceNote.publicUrl}
              >
                <audio controls src={voiceNote.publicUrl}></audio>
              </PatientMealTooltipBodyVoiceNoteContainer>
            ))}
          </PatientMealTooltipBodyVoiceNotesContainer>
        )}
        <PatientMealTooltipBodyNoteContainer>
          <p>{data.note}</p>
        </PatientMealTooltipBodyNoteContainer>
        <PatientMealSorurceTitle>Food Groups</PatientMealSorurceTitle>
        <PatientMealBodyFoodGroupsContainer>
          {foodGroups.map((foodGroup, index) => (
            <PatientMealBodyFoodGroupContainer key={index}>
              <p>{foodGroup.foodGroup.name}</p>
              <span>{roundTo1DecimalPlace(foodGroup.servingsCount)}</span>
            </PatientMealBodyFoodGroupContainer>
          ))}
        </PatientMealBodyFoodGroupsContainer>
        {!carbCounterV1MealSummary && (
          <>
            <PatientMealSorurceTitle>Carbs</PatientMealSorurceTitle>
            <PatientMealBodySourcesContainer>
              {data.patientMealCarbSources.map((carbSource, index) => (
                <PatientMealBodySourceContainer key={carbSource.id}>
                  {carbSource.carbSource.name}
                  {index === data.patientMealCarbSources.length - 1
                    ? ""
                    : ","}{" "}
                </PatientMealBodySourceContainer>
              ))}
            </PatientMealBodySourcesContainer>

            <PatientMealSorurceTitle>Proteins</PatientMealSorurceTitle>
            <PatientMealBodySourcesContainer>
              {data.patientMealProteinSources.map((proteinSource, index) => (
                <PatientMealBodySourceContainer key={proteinSource.id}>
                  {proteinSource.proteinSource.name}
                  {index === data.patientMealProteinSources.length - 1
                    ? ""
                    : ","}{" "}
                </PatientMealBodySourceContainer>
              ))}
            </PatientMealBodySourcesContainer>
          </>
        )}
      </PatientMealTooltipBody>
    </PatientMealTooltipContainer>
  );
};
