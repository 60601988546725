import { FormProvider, useForm } from "react-hook-form";
import {
  ContentModuleReadModeInputs,
  useContentModuleMode,
} from "../../context/content-module-mode-context";
import { ReadContentModuleDescriptionController } from "./read-content-module-left-controllers/read-content-module-description-controller";
import { ReadContentModuleNameController } from "./read-content-module-left-controllers/read-content-module-name-controller";
import { ReadContentModuleObjectiveController } from "./read-content-module-left-controllers/read-content-module-objective-controller";
import { ReadContentModuleThumbnailController } from "./read-content-module-right-controllers/read-content-module-thumbnail-controller";
import { ReadContentModuleTargetAudienceController } from "./read-content-module-right-controllers/read-content-module-target-audience-controller";
import { ContentModuleType } from "../../../../models/content-module-dtos/content-module-type";
import { ReactNode } from "react";
import { ReadContentModuleHeader } from "./read-content-module-header";
import {
  ContentModuleFormControls,
  ContentModuleLeftFormControls,
  ContentModuleRightFormControls,
} from "../../styles/content-modules-form-controllers/content-modules-form-controllers-css";

type ReadContentModuleProps = {
  contentModuleDTOType: ContentModuleType;
  childTable?: ReactNode;
  contentModuleLessonReadModeComponents?: {
    lessonReadModeCards: ReactNode;
    lessonReadModeMobilePreview: ReactNode;
  };
};

export const ReadContentModule = (props: ReadContentModuleProps) => {
  const {
    contentModuleDTOType,
    childTable,
    contentModuleLessonReadModeComponents,
  } = props;

  const { contentModuleMode } = useContentModuleMode();

  const contentModuleDTO = contentModuleMode.mode.contentModule;

  const readModeCourseFormMethods = useForm<ContentModuleReadModeInputs>({
    defaultValues: {
      status: contentModuleDTO.isDraft ? false : true,
      targetAudience: contentModuleDTO.contentModuleTargetAudiences.map(
        (cmta) => ({
          id: cmta.targetAudienceId,
          name: cmta.targetAudience.name,
        })
      ),
    },
  });

  return (
    <FormProvider {...readModeCourseFormMethods}>
      <form>
        <ReadContentModuleHeader />
        <ContentModuleFormControls
          $lessonsUI={contentModuleDTOType === "Lesson" ? true : false}
        >
          <ContentModuleLeftFormControls
            $lessonsUI={contentModuleDTOType === "Lesson" ? true : false}
          >
            <ReadContentModuleNameController
              contentModuleDTO={contentModuleDTO}
            />
            {contentModuleDTOType !== "Lesson" && (
              <ReadContentModuleDescriptionController
                contnetModuleType={contentModuleDTOType}
                conentModuleDescription={contentModuleDTO.description}
              />
            )}
            {contentModuleDTOType !== "Lesson" && (
              <ReadContentModuleObjectiveController
                contnetModuleType={contentModuleDTOType}
                contentModuleObjective={contentModuleDTO.objective}
              />
            )}
            {contentModuleDTOType === "Lesson" &&
              contentModuleLessonReadModeComponents &&
              contentModuleLessonReadModeComponents.lessonReadModeCards}
          </ContentModuleLeftFormControls>
          <ContentModuleRightFormControls
            $lessonsUI={contentModuleDTOType === "Lesson" ? true : false}
          >
            <ReadContentModuleThumbnailController
              contnetModuleType={contentModuleDTOType}
              thumbnailUploadedFile={contentModuleDTO.thumbnailUploadedFile}
            />
            <ReadContentModuleTargetAudienceController />
            {contentModuleDTOType === "Lesson" &&
              contentModuleLessonReadModeComponents &&
              contentModuleLessonReadModeComponents.lessonReadModeMobilePreview}
          </ContentModuleRightFormControls>
        </ContentModuleFormControls>
        {childTable && childTable}
      </form>
    </FormProvider>
  );
};
