import { Controller, useFormContext } from "react-hook-form";
import { CreateLessonInputs } from "../../../../create-lesson";
import {
  UXInputContainer,
  UXInputLabel,
  UXInputTextarea,
  UXInputWrapper,
} from "../../../../../../../../styles/classes/reusable-classes";

type CardTextPartProps = {
  cardIndex: number;
  cardPartIndex: number;
};

export const CardTextPart = (props: CardTextPartProps) => {
  const { cardIndex, cardPartIndex } = props;

  const { control } = useFormContext<CreateLessonInputs>();

  return (
    <Controller
      control={control}
      name={`contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}.text`}
      render={({ field: { value, onChange } }) => (
        <UXInputContainer $textareaDimentions>
          <UXInputLabel $width="50px">Text</UXInputLabel>
          <UXInputWrapper $textareaDimentions $width="100%">
            <UXInputTextarea
              dir="auto"
              value={value === undefined ? "" : value}
              onChange={(e) =>
                onChange(e.target.value === undefined ? "" : e.target.value)
              }
              placeholder="Enter Text"
            />
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
