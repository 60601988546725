import styled from "styled-components/macro";
import {
  TableContainer,
  TablePaginationContainerHeight,
} from "../../../components/table/styled-table";
import { NavbarHeight } from "../../../styles/global-style";

const patientsHeaderHeight = `147px`;
const patientsHeaderMargin = `16px`;

export const PatientsTableContainer = styled.div`
  ${TableContainer} {
    background: ${({ theme }) => theme.colors.containerColor};
    min-height: calc(
      100vh -
        (
          ${NavbarHeight} + ${TablePaginationContainerHeight} +
            ${patientsHeaderHeight} + ${patientsHeaderMargin} + 10px
        )
    );

    padding-bottom: 100px;

    tr {
      height: 50px;
    }
  }
`;
