import styled from "styled-components/macro";
import { regularRegularFont } from "../../styles/classes/gloabl-classes";

export const EmptyStateHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

  p {
    font-size: 21px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
    margin-bottom: 16px;

    @media screen and (max-width: 650px) {
      margin: 0 auto 16px;
    }
  }

  @media screen and (max-width: 650px) {
    margin-bottom: 16px;
  }
`;

export const EmptyStateBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;

  p {
    font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
    font-size: 20px;
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0;

    @media screen and (max-width: 400px) {
      text-align: center;
    }
  }

  button {
    margin-bottom: auto;
    margin-top: 20px;
  }
`;

export const EmptyStateBodySubText = styled.span`
  ${regularRegularFont}
  color: ${({ theme }) => theme.colors.textColor};
  margin-top: 4px;
`;
