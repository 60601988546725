import { FormProvider, useForm } from "react-hook-form";
import { Modal } from "../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { PutPatientDietProfileAddedSugarDTO } from "../../../../../../models/patient-diet-profile-dtos/put-patient-diet-profile-added-sugar-dto";
import { PutPatientDietProfileDietDTO } from "../../../../../../models/patient-diet-profile-dtos/put-patient-diet-profile-diet-dto";
import { PutPatientDietProfileFoodAllergyDTO } from "../../../../../../models/patient-diet-profile-dtos/put-patient-diet-profile-food-allergy-dto";
import { SleepPattern } from "../../../../../../models/patient-dtos/sleep-pattern";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { PatientProfileEditDietModalBody } from "./patient-profile-edit-diet-modal-body/patient-profile-edit-diet-modal-body";
import { PutPatientDietProfileSmokeDTO } from "../../../../../../models/patient-diet-profile-dtos/put-patient-diet-profile-smoke-dto";
import { PutPatientDietProfileAlcoholDTO } from "../../../../../../models/patient-diet-profile-dtos/put-patient-diet-profile-alcohol-dto";
import { PutPatientDietProfileLifeActivityDTO } from "../../../../../../models/patient-diet-profile-dtos/put-patient-diet-profile-life-activity-dto";
import { usePatientProfileDiet } from "../../context/loadable-patient-profile-diet-context";

export type PatientProfileDietModalInputs = {
  waterIntakeCupsPerDay?: string;
  caffeineIntakeCupsPerDay?: string;
  sleepHoursPerDay?: number;
  sleepPattern?: SleepPattern;
  patientDiets: PutPatientDietProfileDietDTO[];
  patientFoodAllergies: PutPatientDietProfileFoodAllergyDTO[];
  patientAddedSugars: PutPatientDietProfileAddedSugarDTO[];
  patientAddedSugarsOther?: PutPatientDietProfileAddedSugarDTO;
  patientSmokes?: PutPatientDietProfileSmokeDTO[];
  patientSmokesOther?: PutPatientDietProfileSmokeDTO;
  patientAlcohols?: PutPatientDietProfileAlcoholDTO[];
  patientAlcoholsOther?: PutPatientDietProfileAlcoholDTO;
  patientLifeActivity?: PutPatientDietProfileLifeActivityDTO[];
  patientLifeActivityOther?: PutPatientDietProfileLifeActivityDTO;
};

export const PatientProfileEditDietModal = () => {
  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  const { patientDietProfileDTO } = usePatientProfileDiet();

  const methods = useForm<PatientProfileDietModalInputs>({
    defaultValues: {
      waterIntakeCupsPerDay:
        patientDietProfileDTO.waterIntakeCupsPerDay !== undefined
          ? patientDietProfileDTO.waterIntakeCupsPerDay.toString()
          : undefined,
      caffeineIntakeCupsPerDay:
        patientDietProfileDTO.caffeineIntakeCupsPerDay !== undefined
          ? patientDietProfileDTO.caffeineIntakeCupsPerDay.toString()
          : undefined,
      sleepHoursPerDay:
        patientDietProfileDTO.sleepHoursPerDay !== undefined
          ? patientDietProfileDTO.sleepHoursPerDay
          : undefined,
      sleepPattern:
        patientDietProfileDTO.sleepPattern !== undefined
          ? (patientDietProfileDTO.sleepPattern as SleepPattern)
          : undefined,
      patientDiets:
        patientDietProfileDTO.patientDiets !== undefined
          ? patientDietProfileDTO.patientDiets
          : undefined,
      patientFoodAllergies:
        patientDietProfileDTO.patientFoodAllergies !== undefined
          ? patientDietProfileDTO.patientFoodAllergies
          : undefined,
      patientAddedSugars:
        patientDietProfileDTO.patientAddedSugars !== undefined
          ? patientDietProfileDTO.patientAddedSugars.filter(
              (item) => item.addedSugar.name !== "Other"
            )
          : undefined,
      patientAddedSugarsOther:
        patientDietProfileDTO.patientAddedSugars !== undefined
          ? patientDietProfileDTO.patientAddedSugars.filter(
              (item) => item.addedSugar.name === "Other"
            )[0]
          : undefined,
      patientSmokes:
        patientDietProfileDTO.patientSmokes !== undefined
          ? patientDietProfileDTO.patientSmokes.filter(
              (item) => item.smoke !== "Other"
            )
          : undefined,
      patientSmokesOther:
        patientDietProfileDTO.patientSmokes !== undefined
          ? patientDietProfileDTO.patientSmokes.filter(
              (item) => item.smoke === "Other"
            )[0]
          : undefined,
      patientAlcohols:
        patientDietProfileDTO.patientAlcohols !== undefined
          ? patientDietProfileDTO.patientAlcohols.filter(
              (item) => item.alcohol !== "Other"
            )
          : undefined,
      patientAlcoholsOther:
        patientDietProfileDTO.patientAlcohols !== undefined
          ? patientDietProfileDTO.patientAlcohols.filter(
              (item) => item.alcohol === "Other"
            )[0]
          : undefined,
      patientLifeActivity:
        patientDietProfileDTO.patientLifeActivities !== undefined
          ? patientDietProfileDTO.patientLifeActivities.filter(
              (item) => item.notes === undefined
            )
          : undefined,
      patientLifeActivityOther:
        patientDietProfileDTO.patientLifeActivities !== undefined
          ? patientDietProfileDTO.patientLifeActivities.filter(
              (item) => item.notes !== undefined
            )[0]
          : undefined,
    },
  });

  return (
    <>
      <Button outlined flex onClick={openModal}>
        <img src="/img/edit.svg" alt="Edit" /> Edit
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FormProvider {...methods}>
          <PatientProfileEditDietModalBody
            key={modalBodyKey}
            onClose={closeModal}
          />
        </FormProvider>
      </Modal>
    </>
  );
};
