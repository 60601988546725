import { ReactNode } from "react";
import { PlanStepInfo } from "../plan-step-info/plan-step-info";
import { StepsHeaderContainer } from "./styled-plan-steps-header";

type PlanStepsHeaderProps = {
  title: string;
  subTitle: string;
  icon: string;
  button: ReactNode;
};

export const PlanStepsHeader = (props: PlanStepsHeaderProps) => {
  const { title, subTitle, button, icon } = props;

  return (
    <StepsHeaderContainer>
      <PlanStepInfo title={title} subTitle={subTitle} icon={icon} />
      <div>{button}</div>
    </StepsHeaderContainer>
  );
};
