import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  regularMediumFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const NutritionReportSummaryListLI = styled.li`
  ${flexCenterSpaceBetween}
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

  :last-child {
    border: 0px;
  }
`;

export const NutritionReportSummaryListTitleContainer = styled.div`
  ${flexCenter}
  width: 201px;

  img {
    margin-right: 24px;
    width: 24px;
    height: 24px;
  }

  p {
    ${regularMediumFont}
    margin: 0px;
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;

export const NutritionReportSummaryListBarContainer = styled.div`
  ${flexCenterSpaceBetween}
  flex: 1;
`;

export const NutritionReportSummaryListBar = styled.div<{ progress: number }>`
  width: 84px;
  height: 3px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.borderColor};
  margin: 0px 20px 0px auto;
  position: relative;

  div {
    height: 100%;
    background: ${({ theme }) => theme.colors.primaryColor};
    width: ${({ progress }) => `${progress < 100 ? progress : "100"}%`};
  }

  ::after {
    content: "";
    position: absolute;
    top: -100%;
    border-radius: 8px;
    height: 10px;
    width: 3px;
    background: ${({ theme }) => theme.colors.primaryColor};
    left: 50%;
  }
`;

export const NutritionReportSummaryListGoalContainer = styled.div`
  width: 60px;

  p {
    ${regularMediumFont}
    margin: 0px;
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;
