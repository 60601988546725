import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

export type CardPartsLoadingState = {
  cardIndex?: number;
  loading: boolean;
};

type CardPartsLoading = {
  cardPartsLoading: CardPartsLoadingState;
  setCardPartsLoading: (cardPartsLoading: CardPartsLoadingState) => void;
};

const CardPartsLoadingContext = createContext<CardPartsLoading | undefined>(
  undefined
);

type CardPartsLoadingContextProviderProps = PropsWithChildren<{}>;

export const CardPartsLoadingContextProvider = ({
  children,
}: CardPartsLoadingContextProviderProps) => {
  const [cardPartsLoading, setCardPartsLoading] = useState<
    CardPartsLoadingState
  >({
    cardIndex: undefined,
    loading: false,
  });

  const value = useMemo(() => ({ cardPartsLoading, setCardPartsLoading }), [
    cardPartsLoading,
    setCardPartsLoading,
  ]);

  return (
    <CardPartsLoadingContext.Provider value={value}>
      {children}
    </CardPartsLoadingContext.Provider>
  );
};

export const useCardPartsLoading = () => {
  const context = useContext(CardPartsLoadingContext);

  if (context === undefined) {
    throw new Error(
      "useCardPartsLoading must be used within a CardPartsLoadingContextProvider"
    );
  }

  return context;
};
