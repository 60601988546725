export const getDateFormat = () => {
  const today = new Date();

  const todayFormatted = today.toLocaleDateString("en-AU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const yesterdayFormatted = yesterday.toLocaleDateString("en-AU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const twoDaysAgo = new Date(today);
  twoDaysAgo.setDate(today.getDate() - 2);

  const twoDaysAgoFormatted = twoDaysAgo.toLocaleDateString("en-AU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const threeDaysAgo = new Date(today);
  threeDaysAgo.setDate(today.getDate() - 3);

  const threeDaysAgoFormatted = threeDaysAgo.toLocaleDateString("en-AU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const fourDaysAgo = new Date(today);
  fourDaysAgo.setDate(today.getDate() - 4);

  const fourDaysAgoFormatted = fourDaysAgo.toLocaleDateString("en-AU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const fiveDaysAgo = new Date(today);
  fiveDaysAgo.setDate(today.getDate() - 5);

  const fiveDaysAgoFormatted = fiveDaysAgo.toLocaleDateString("en-AU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const sixDaysAgo = new Date(today);
  sixDaysAgo.setDate(today.getDate() - 6);

  const sixDaysAgoFormatted = sixDaysAgo.toLocaleDateString("en-AU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);

  const sevenDaysAgoFormatted = sevenDaysAgo.toLocaleDateString("en-AU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return {
    todayFormatted,
    yesterdayFormatted,
    twoDaysAgoFormatted,
    threeDaysAgoFormatted,
    fourDaysAgoFormatted,
    fiveDaysAgoFormatted,
    sixDaysAgoFormatted,
    sevenDaysAgoFormatted,
  };
};

export const formatDate = (date: Date) => {
  const dateFormated = date.toLocaleDateString("en-AU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  if (dateFormated === getDateFormat().todayFormatted) {
    return "Today";
  } else if (dateFormated === getDateFormat().yesterdayFormatted) {
    return "Yesterday";
  } else if (
    dateFormated === getDateFormat().twoDaysAgoFormatted ||
    dateFormated === getDateFormat().threeDaysAgoFormatted ||
    dateFormated === getDateFormat().fourDaysAgoFormatted ||
    dateFormated === getDateFormat().fiveDaysAgoFormatted ||
    dateFormated === getDateFormat().sixDaysAgoFormatted ||
    dateFormated === getDateFormat().sevenDaysAgoFormatted
  ) {
    return date.toLocaleDateString("en-AU", { weekday: "long" });
  } else {
    return date.toLocaleDateString("en-AU", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }
};
