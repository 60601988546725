import styled, { css } from "styled-components/macro";
import {
  FoodLoggingSelectedPatientMealImagesContainer,
  FoodLoggingSelectedPatientMealImagesImageContainer,
} from "../../../../../../../food-logging/food-logging-selected-patient-meal/components/food-logging-selected-patient-meal-images/styled-food-logging-selected-patient-meal-images";

type StyledMealsAnalysisResponseTableCellProps = {
  $dateCell?: boolean;
  $typeCell?: boolean;
  $mediaCell?: boolean;
  $macrosCell?: boolean;
  $graphCell?: boolean;
};

const MealsAnalysisResponseDateTableCellCSS = css`
  width: 150px;

  span {
    display: block;
  }
`;

const MealsAnalysisResponseTypeTableCellCSS = css`
  width: 80px;
`;

const MealsAnalysisResponseMediaTableCellCSS = css`
  width: 250px;

  ${FoodLoggingSelectedPatientMealImagesContainer} {
    height: 100px;
  }

  ${FoodLoggingSelectedPatientMealImagesImageContainer} {
    height: 80px;
  }
`;

const MealsAnalysisResponseMacrosTableCellCSS = css`
  width: 200px;
`;

const MealsAnalysisResponseGraphTableCellCSS = css`
  width: 250px;
`;

export const MealsAnalysisResponseTableCell = styled.td<
  StyledMealsAnalysisResponseTableCellProps
>`
  ${({ $dateCell }) => $dateCell && MealsAnalysisResponseDateTableCellCSS};
  ${({ $typeCell }) => $typeCell && MealsAnalysisResponseTypeTableCellCSS};
  ${({ $mediaCell }) => $mediaCell && MealsAnalysisResponseMediaTableCellCSS};
  ${({ $macrosCell }) =>
    $macrosCell && MealsAnalysisResponseMacrosTableCellCSS};
  ${({ $graphCell }) => $graphCell && MealsAnalysisResponseGraphTableCellCSS};
`;
