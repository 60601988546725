import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { AutoSelect } from "../../../../../../components/auto-select/auto-select";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import {
  PatientProfileAutoSelectContainer,
  PatientProfileAutoSelectFormControlContainer,
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileTagList,
  PatientProfileTagListItem,
  PatientProfileTagsLabelContainer,
} from "../../../styles/patient-profile-styles/patient-profile-classes";
import { useEnterKeypress } from "../../../../../../hooks/use-enter-keypress/use-enter-keypress";
import { useCallback, useState } from "react";
import { PatientProfileEditProfileInputs } from "../patient-profile-edit-profile-modal";
import { PutPatientProfileProfileDiagnosisDTO } from "../../../../../../models/patient-profile-dtos/put-patient-profile-profile-diagnosis-dto";
import { usePatientProfileInformationHeader } from "../../context/loadable-patient-profile-information-header-context";

export const PatientProfileEditProfileDiagnosis = () => {
  const { profileDiagnosis } = usePatientProfileInformationHeader();

  const { control, watch } = useFormContext<PatientProfileEditProfileInputs>();

  const findDiagnosisByName = useCallback(
    (profileDiagnosesName: string) =>
      profileDiagnosis.find((item) => item.name === profileDiagnosesName),
    [profileDiagnosis]
  );

  const findDiagnosisById = useCallback(
    (profileDiagnosesId: number) =>
      profileDiagnosis.find((item) => item.id === profileDiagnosesId),
    [profileDiagnosis]
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [autoSelectTagValue, setAutoSelectTagValue] = useState<
    PutPatientProfileProfileDiagnosisDTO | undefined
  >();

  const { append, fields, remove } = useFieldArray({
    name: "patientProfileDiagnoses",
    control,
  } as never);

  const profileDiagnosesWatcher = watch("patientProfileDiagnoses");

  const handleAppend = () => {
    if (
      profileDiagnosesWatcher !== undefined &&
      autoSelectTagValue !== undefined &&
      !profileDiagnosesWatcher.some(
        (obj) =>
          obj.profileDiagnosisId === autoSelectTagValue.profileDiagnosisId
      ) &&
      profileDiagnosis.some(
        (obj) => obj.id === autoSelectTagValue?.profileDiagnosisId
      )
    ) {
      append(autoSelectTagValue);
      setAutoSelectTagValue(undefined);
      setSearchTerm("");
    }
  };

  const { onEnterKeyPress } = useEnterKeypress(handleAppend);

  return (
    <PatientProfileModalFormControl>
      <PatientProfileTagsLabelContainer>
        <label>Diagnosis</label>
        <span>Select or add own diagonsis</span>
      </PatientProfileTagsLabelContainer>
      <PatientProfileModalInputContainer>
        <Controller
          name="patientProfileDiagnoses"
          control={control}
          render={() => {
            return (
              <PatientProfileAutoSelectFormControlContainer>
                <PatientProfileAutoSelectContainer>
                  <AutoSelect
                    itemsArray={profileDiagnosis
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .filter((item) =>
                        item.name.toLocaleLowerCase().startsWith(searchTerm)
                      )
                      .map((item) => item.name)}
                    onChange={(value: string) => {
                      // This value should never be changed with undefined !
                      setAutoSelectTagValue({
                        profileDiagnosisId: findDiagnosisByName(value)?.id!,
                      });
                      setSearchTerm(value);
                    }}
                    value={searchTerm}
                    fullWidth
                    placeHolder="Select Diagnose"
                  />
                  <Button
                    type="button"
                    onClick={handleAppend}
                    onKeyDown={(e) => onEnterKeyPress(e)}
                    width={100}
                    height={40}
                    flex
                    outlineNoBorder
                  >
                    <span className="material-symbols-outlined">add</span> Add
                  </Button>
                </PatientProfileAutoSelectContainer>
                <PatientProfileTagList>
                  {fields.map((field, index) => (
                    <PatientProfileTagListItem
                      key={field.id}
                      variant="Diagnosis"
                    >
                      {profileDiagnosesWatcher !== undefined
                        ? findDiagnosisById(
                            profileDiagnosesWatcher[index].profileDiagnosisId
                          )?.name
                        : ""}
                      <span
                        className="material-symbols-outlined"
                        onClick={() => remove(index)}
                      >
                        close
                      </span>
                    </PatientProfileTagListItem>
                  ))}
                </PatientProfileTagList>
              </PatientProfileAutoSelectFormControlContainer>
            );
          }}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
