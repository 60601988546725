import { useNavigate } from "react-router-dom";
import {
  ViewPlansIconContainer,
  ViewPlansTextContainer,
} from "../styled-view-plans";
import { ViewPlansHeader } from "./view-plans-header/view-plans-header";
import {
  ViewPlansHomeCardsList,
  ViewPlansHomeCardsListItem,
} from "./styled-view-plans-home";

export const ViewPlansHome = () => {
  const navigate = useNavigate();

  const cards = buildPlansListItemsData().map((card) => (
    <ViewPlansHomeCardsListItem
      key={card.id}
      onClick={() => {
        if (card.id === 0) {
          navigate("../patient-plans/plans-list");
        }

        if (card.id === 1) {
          navigate("../patient-plans/medications");
        }
      }}
    >
      <ViewPlansTextContainer>
        <p>{card.title}</p>
        <span>{card.subTitle}</span>
        <button>
          <span className="material-symbols-outlined">keyboard_arrow_left</span>
          مشاهدة المزيد
        </button>
      </ViewPlansTextContainer>
      <ViewPlansIconContainer>
        <img src={card.imgURL} alt="Plans icon" />
      </ViewPlansIconContainer>
    </ViewPlansHomeCardsListItem>
  ));

  return (
    <>
      <ViewPlansHeader />
      <ViewPlansHomeCardsList>{cards}</ViewPlansHomeCardsList>
    </>
  );
};

const buildPlansListItemsData = () => {
  const viewPlansListItems = [
    {
      id: 0,
      title: "الخطط الإسبوعية",
      subTitle:
        "تعليمات ونتائج للمتابعة بشكل دوري لمساعدتك في تحقيق أهدافك الصحية",
      imgURL: "/img/view-plans/plans.svg",
    },
    {
      id: 1,
      title: "الأدوية",
      subTitle:
        "أدوية مختارة لك من الطبيب المعالج لمساعدتك في الخطة العلاجيةوالوصول للأهداف المتفق عليها",
      imgURL: "/img/view-plans/medications.svg",
    },
  ];

  return viewPlansListItems;
};
