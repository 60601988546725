import { SinglePlanDropdown } from "./components/single-plan-dropdown";
import {
  SinglePlanDropdownButtonIcon,
  SinglePlanDropdownButtonText,
  SinglePlanDropdownListItem,
} from "./components/styled-single-plan-dropdown";
import { PatientDetailedPlanDTO } from "../../../../../../models/patient-plan-dtos/patient-detailed-plan-dto";

type SinglePlanLifestyleProps = {
  patientDetailedPlanDTO: PatientDetailedPlanDTO;
};

export const SinglePlanLifestyle = (props: SinglePlanLifestyleProps) => {
  const { patientDetailedPlanDTO } = props;
  const assignedLifestyleGoalsList =
    patientDetailedPlanDTO.patientPlanGoals.filter(
      (item) => item.goalType === "LifestyleInstruction"
    );

  const nutrition = assignedLifestyleGoalsList.map((goal, index) => (
    <SinglePlanDropdownListItem key={index}>
      <SinglePlanDropdownButtonIcon>
        <img src="/img/plans/nav/life-style.svg" alt="Goals Icon" />
      </SinglePlanDropdownButtonIcon>
      <SinglePlanDropdownButtonText>
        <p>{goal.goal}</p>
      </SinglePlanDropdownButtonText>
    </SinglePlanDropdownListItem>
  ));

  return (
    <div>
      <SinglePlanDropdown
        icon="/img/plans/nav/life-style.svg"
        img="/img/view-plans/life-style.svg"
        items={nutrition}
        subtitle="أهم التعليمات التي نحتاجها لتحسين الحياة"
        title="تحسين نمط الحياة"
      />
    </div>
  );
};
