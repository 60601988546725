import { Outlet, useParams } from "react-router-dom";
import { ScreenSubNavbar } from "../../../components/screen-sub-navbar/screen-sub-navbar";
import { SubScreenContainer } from "../../../styles/classes/reusable-classes";
import { useCallback } from "react";
import { useGetPatientSmsOtps } from "../../../hooks/patient-sms-otp-hooks/use-get-patient-sms-otps";
import { Loading } from "../../../components/loading";
import { AdministrationProvider } from "./context/administration-context";

export const Administration = () => {
  const { id } = useParams();

  const getPatientSmsOtps = useGetPatientSmsOtps();

  const loadPatientSmsOtpsCallback = useCallback(async () => {
    const patientID = Number(id!);

    const [smsOtps] = await Promise.all([getPatientSmsOtps(patientID)]);

    return { smsOtps };
  }, [id, getPatientSmsOtps]);

  return (
    <div>
      <ScreenSubNavbar links={buildAdministrationNavLinks(id!)} />
      <SubScreenContainer>
        <Loading
          load={loadPatientSmsOtpsCallback}
          successComponent={(smsOtpsResponse) => (
            <AdministrationProvider
              smsOtpsResponse={{ smsOtps: smsOtpsResponse.smsOtps }}
            >
              <Outlet />
            </AdministrationProvider>
          )}
        />
      </SubScreenContainer>
    </div>
  );
};

function buildAdministrationNavLinks(id: string) {
  const administrationNavLinks = [
    {
      name: "SMS OTPs",
      path: `/patients/${id}/administration/sms-otps`,
    },
  ];

  return administrationNavLinks;
}
