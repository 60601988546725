import styled from "styled-components/macro";
import {
  cardDateFont,
  cardTitleFont,
  flexCenter,
} from "../../../../../../styles/classes/gloabl-classes";

export const ChartCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 0.5rem 1rem;

  @media screen and (max-width: 1440px) {
    padding: 0.5rem calc(1.7rem - 3px);
  }

  @media screen and (max-width: 940px) {
    width: 100%;
    overflow: auto;
  }

  @media screen and (max-width: 375px) {
    padding: 0.5rem 0rem;
  }
`;

export const DailyChartsDate = styled.div`
  ${flexCenter}
  gap: 0 1rem;

  p {
    ${cardDateFont};
  }
`;

export const DailyChartsContainer = styled.div`
  display: flex;
  gap: 0rem calc(2rem - 13px);
  max-width: 1350px;
  width: 100%;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    gap: 1.5rem 0rem;
    text-align: center;
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.borderColorAlt};
  width: calc(100% - 290px);
  min-width: 510px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    min-width: unset;
  }
`;

export const ChartCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  margin-bottom: auto;
  height: 90px;

  @media screen and (max-width: 590px) {
    flex-direction: column;
    height: auto;
  }
`;

export const ChartCardTitle = styled.div`
  p {
    ${cardTitleFont}
    margin: 0rem 0rem 0.5rem;

    @media screen and (max-width: 420px) {
      font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
    }
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};

    @media screen and (max-width: 420px) {
      font-size: ${({ theme }) => theme.typography.fontSize.regularSmFont};
    }
  }
`;

export const CircleCharts = styled.div<{ viewPlanChart?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ viewPlanChart }) =>
    viewPlanChart ? "space-between" : ""};
  width: ${({ viewPlanChart }) => (viewPlanChart ? "100%" : "")};
  gap: ${({ viewPlanChart }) => (viewPlanChart ? "0rem" : "0rem 1rem")};

  @media screen and (max-width: 590px) {
    margin-top: 1rem;
  }
`;

export const LineChartContainer = styled.div`
  @media screen and (max-width: 940px) {
    overflow-x: scroll;
    overflow-y: hidden;

    ::-webkit-scrollbar {
      height: 5px;
    }
  }
`;

export const ChartContainerLegend = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 0.625rem 1.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0rem 1.5rem;
  margin-top: auto;
`;

export const ChartLegendPoint = styled.div<{
  medic?: boolean;
  manual?: boolean;
  activity?: boolean;
  insulin?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 0rem 0.5rem;
  padding: 0.5rem 0rem;

  div {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: ${({ theme, medic, manual, activity, insulin }) =>
      medic
        ? theme.colors.success
        : manual
        ? theme.colors.primaryColor
        : activity
        ? theme.newColors.gold
        : insulin
        ? theme.newColors.insulinSeries
        : theme.colors.primaryColorAlt};
  }

  img {
    width: 15px;
    height: 15px;
    border-radius: 100%;
  }

  span {
    font-size: ${({ theme }) => theme.typography.fontSize.mdFontSize};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    color: ${({ theme }) => theme.colors.lightTextColor};
  }
`;
