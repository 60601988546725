import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";
import { roundTo1DecimalPlace } from "../../../../../utils/math-utils";

type NutritionReportCarbsPercentageTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportCarbsPercentageTableCell = (
  props: NutritionReportCarbsPercentageTableCellProps
) => {
  const { kind } = props;

  const { carbsPercentage } = useMemo(() => {
    const carbsPercentage =
      kind.kind === "Row"
        ? kind.data.carbsPercentage
        : kind.data.patientMealMacrosSummary.carbsPercentage;

    return { carbsPercentage };
  }, [kind.kind, kind.data]);

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      stringValue={
        carbsPercentage !== undefined
          ? `${roundTo1DecimalPlace(carbsPercentage)}%`
          : ``
      }
    />
  );
};
