import styled from "styled-components/macro";

export const PrescriptionFormHeaderContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 16px;
  gap: 16px;
  width: 100%;
`;
