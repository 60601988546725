import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { BASE_PATH } from "../../constants/paths";
import { CreateContentModuleDTO } from "../../models/content-module-dtos/create-content-module-dto";
import { ContentModuleDTO } from "../../models/content-module-dtos/content-module-dto";

export function useAddContentModule(): (
  createContentModuleDTO: CreateContentModuleDTO
) => Promise<ContentModuleDTO> {
  const client = useClient();

  return useCallback(
    async (createContentModuleDTO) => {
      const response = await client.post<ContentModuleDTO>(
        `${BASE_PATH}/content-modules`,
        createContentModuleDTO
      );

      return response.data;
    },
    [client]
  );
}
