import { Outlet } from "react-router-dom";
import { useChatTokenOrUndefined } from "./chat-providers/chat-token-context";
import { OtidaLoader } from "../../components/loaders/otida-loader/otida-loader";

export const Chat = () => {
  const chatTokenDTO = useChatTokenOrUndefined();

  if (chatTokenDTO === undefined) {
    return <OtidaLoader />;
  } else {
    return <Outlet />;
  }
};
