import { Temporal } from "temporal-polyfill";
import { PatientCGMEntryDTO } from "../../../../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";

type BGMReadingDataSource = {
  x: number;
  y: number;
};

export function buildBGMReadingsSereis(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  startPlainDateTime: Temporal.PlainDateTime
): BGMReadingDataSource[] {
  const readingsSeries: BGMReadingDataSource[] = [];

  for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
    if (patientCGMEntryDTO.source === "Manual") {
      const currentPlainDateTime = Temporal.PlainDateTime.from(
        `${patientCGMEntryDTO.date}T${patientCGMEntryDTO.time}`
      );

      const x = currentPlainDateTime
        .since(startPlainDateTime)
        .total({ unit: "minutes" });
      const y = patientCGMEntryDTO.glucoseMGPerDL;

      readingsSeries.push({
        x: x,
        y: y,
      });
    }
  }

  return readingsSeries;
}
