import { memo } from "react";
import { ChannelList, useChatContext } from "stream-chat-react";
import { MessagingChannelListHeader } from "./messaging-channel-list-header/messaging-channel-list-header";
import {
  MessagingSidebarBackdrop,
  MessagingSidebarContiner,
} from "./styled-messaging-sidebar";
import { MessagingChannelPreview } from "./messaging-channel-preview/messaging-channel-preview";
import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { useSetChannelOptions } from "../../../hooks/use-set-channel-options";

type MessagingSidebarProps = {
  showSidebar: boolean;
  handleHideSidebar: () => void;
  handleHideMediaSidebar: () => void;
};

export const MessagingSidebar = memo((props: MessagingSidebarProps) => {
  const { showSidebar, handleHideSidebar, handleHideMediaSidebar } = props;

  const { channel: acitveChannel } = useChatContext();
  const { channelFilters, channelOptions, channelSort } =
    useSetChannelOptions();

  return (
    <MessagingSidebarContiner showSidebar={showSidebar}>
      {acitveChannel !== undefined && (
        <MessagingSidebarBackdrop
          showSidebar={showSidebar}
          onClick={handleHideSidebar}
        />
      )}
      <MessagingChannelListHeader handleHideSidebar={handleHideSidebar} />
      <ChannelList
        filters={channelFilters}
        options={channelOptions}
        sort={channelSort}
        EmptyStateIndicator={() => (
          <EmptyState
            hasSearch={false}
            text="No Links Yet"
            title=""
            hasHeader={false}
            button={<></>}
          />
        )}
        Preview={(props) => (
          <MessagingChannelPreview
            {...props}
            handleHideMediaSidebar={handleHideMediaSidebar}
          />
        )}
      />
    </MessagingSidebarContiner>
  );
});
