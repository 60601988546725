import styled from "styled-components/macro";
import { flexCenter } from "../../../../../styles/classes/gloabl-classes";
import { lightShadowAlt } from "../../../../../styles/global-style";

export const ImmediateAttentionPatientsTableNameCell = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 48px;
    border-radius: 100%;
    margin-right: 18px;
    cursor: pointer;
  }

  div {
    display: flex;
    flex-direction: column;

    p {
      margin: 0px;
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;

      :hover {
        text-decoration: underline;
      }
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.textColor};
    }
  }

  .mrn-tag {
    font-size: 11px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.textColor};
    background: ${({ theme }) => theme.newColors.gray6};
    background: #eaeaef;
    border-radius: 4px;
    padding: 4px 6px;
    gap: 8px;
    width: max-content;
    height: fit-content;
    cursor: pointer;
    position: relative;

    strong {
      position: absolute;
      top: -35px;
      left: 0px;
      right: 0px;
      background: ${({ theme }) => theme.colors.containerColor};
      padding: 6px;
      text-align: center;
      ${lightShadowAlt}
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.colors.borderColor};

      ::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid ${({ theme }) => theme.colors.containerColor};
        position: absolute;
        bottom: -10px;
        left: 25px;
      }
    }
  }
`;

export const ImmediateAttentionPatientsTableDiagnosisCell = styled.div`
  p {
    margin: 0px;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const ImmediateAttentionPatientsTableAlertsCell = styled.div`
  ${flexCenter}
  gap: 0px 16px;

  img {
    width: 20px;
    height: 20px;
  }

  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
  }

  .symptoms-icon {
    position: relative;
    transform: scaleX(-1);

    ::after {
      content: "";
      position: absolute;
      left: 9px;
      top: 7px;
      width: 7px;
      height: 9px;
      background: white;
      border-radius: 100%;
    }
  }
`;

export const ImmediateAttentionPatientsTablePriorityCell = styled.div<{
  $level: "High" | "Medium" | "Low";
}>`
  ${flexCenter}
  gap: 0px 4px;

  span {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 4px;
    background: ${({ $level, theme }) =>
      $level === "High"
        ? theme.colors.danger
        : $level === "Medium"
        ? theme.colors.warning
        : theme.newColors.warningAlt};
  }

  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const ImmediateAttentionPatientsTableVisitsCell = styled.div`
  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;

    :nth-child(2) {
      margin-top: 4px;
      font-size: 14px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;
