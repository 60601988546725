import styled from "styled-components/macro";
import { LoginFormContainerCSS } from "../../screens/login/styled-login";
import {
  flexCenter,
  flexCenterCenter,
  flexCol,
  lgSemiBoldFont,
  mediumFont,
  mediumRegularFont,
  regularMediumFont,
  smallSemiBoldFont,
} from "../../styles/classes/gloabl-classes";

export const PatientLoginContainer = styled.div`
  ${LoginFormContainerCSS}
  gap: 1rem 0rem;

  h1 {
    ${lgSemiBoldFont}
  }

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const PatientLoginSubContainer = styled.div`
  ${flexCol}
  height: 55%;
  margin: 32px 4px 0px;
`;

export const PatientLoginBackIcon = styled.span`
  margin-right: 16px;
  color: ${({ theme }) => theme.colors.titleColor};
  ${lgSemiBoldFont}
  cursor: pointer;
`;

export const PatientOtpInputsContainerForErrorText = styled.div`
  position: relative;
  margin: 0 auto;

  span {
    bottom: -30px;
    left: 0px;
  }
`;

export const PatientLoginSendAgainContainer = styled.div`
  ${flexCenter}
  margin: 36px 0px 0px;
  align-self: center;

  p {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }

  button {
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.primaryColor};
    ${mediumFont}
    font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
    background: transparent;
    padding: 0px;
  }
`;

export const PatientLoginButtonContainer = styled.div`
  margin: 64px auto 0px;
  margin-top: auto;

  button {
    border-radius: 16px;

    :disabled {
      opacity: 1;
    }
  }
`;

export const PatientLoginInputContainerForHardCoddedPlusTwo = styled.div`
  ${flexCenterCenter}
  gap: 0rem 1rem;
`;

export const InputHardCodedPlusTwo = styled.span`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.inputColor};
  padding: 0.375rem 1rem;
  ${smallSemiBoldFont}
`;
