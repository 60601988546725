import { css } from "styled-components/macro";
import { PatientProfileModalInputContainer } from "../../../../styles/patient-profile-styles/patient-profile-classes";

export const TimeLineDateInputContainerCSS = css`
  width: 100%;

  ${PatientProfileModalInputContainer} {
    padding: 8px 16px;
    height: 40px;
    margin: 0px;
    margin-bottom: 8px;

    input {
      padding: 0px;
    }

    span {
      font-size: 16px;
    }
  }
`;
