import {
  ChartComponent,
  DataLabel,
  DateTime,
  Inject,
  MultiColoredLineSeries,
  SegmentDirective,
  SegmentsDirective,
  SeriesCollectionDirective,
  SeriesDirective,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { PatientCGMEntryDTO } from "../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { randomUUID } from "../../../../../../../utils/random-uuid";
import { getInRangeNumbers } from "../../../../../../../utils/patient-type-utils";
import { useCGMReportLoadedResponse } from "../../../context/cgm-report-loaded-response-context";
import { buildReadingsSereis } from "./utils/build-readings-series";
import { buildAxisLabelRender } from "./utils/build-axis-label-render";
import { buildPrimaryYAxis } from "./utils/build-primary-y-axis";
import { buildPrimaryXAxis } from "./utils/build-primary-x-axis";
import {
  CGMReportResponseChartContainer,
  CGMReportResponseChartDayDate,
  CGMReportResponseChartDayXAxisLabelsContainer,
  CGMReportResponseChartWeekDay,
  CGMReportResponseChartWrapper,
} from "./styled-cgm-report-response-chart";
import { Temporal } from "temporal-polyfill";

type CGMReportResponseChartProps = {
  date: string;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
  showWeekDays: boolean;
  showYAxisValues: boolean;
  maxYAxisValue: number;
};

export const CGMReportResponseChart = (props: CGMReportResponseChartProps) => {
  const {
    date,
    patientCGMEntryDTOs,
    showWeekDays,
    showYAxisValues,
    maxYAxisValue,
  } = props;

  const weekDay = Temporal.PlainDate.from(date).toLocaleString("en-US", {
    weekday: "long",
  });

  const dayDate = Temporal.PlainDate.from(date).toLocaleString("en-US", {
    day: "numeric",
  });

  const { patientDTO } = useCGMReportLoadedResponse();

  const chartRef = useRef<ChartComponent | null>(null);

  const id = useMemo(() => `cgm-syncfusion-chart-${randomUUID()}`, []);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);

    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const minY = useMemo(
    () =>
      Math.min(
        ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
      ),
    [patientCGMEntryDTOs]
  );

  const inRangeNumbers = useMemo(() => getInRangeNumbers(patientDTO.type), [
    patientDTO,
  ]);

  const vAxisTicks = useMemo(() => [...inRangeNumbers, maxYAxisValue], [
    inRangeNumbers,
    maxYAxisValue,
  ]);

  const primaryxAxis = useMemo(
    () =>
      buildPrimaryXAxis(
        new Date(`${date}T00:00:00`),
        new Date(`${date}T23:59:59`)
      ),
    [date]
  );

  const primaryyAxis = useMemo(
    () => buildPrimaryYAxis(minY, maxYAxisValue, inRangeNumbers),
    [minY, maxYAxisValue, inRangeNumbers]
  );

  const axisLabelRender = useMemo(
    () => buildAxisLabelRender(showYAxisValues ? vAxisTicks : []),
    [vAxisTicks, showYAxisValues]
  );

  const readingsSeries = useMemo(
    () => buildReadingsSereis(patientCGMEntryDTOs, patientDTO.type),
    [patientCGMEntryDTOs, patientDTO.type]
  );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const chartWidth = windowWidth > 1441 ? "228px" : "168px";
  const chartWidth =
    windowWidth >= 768 && windowWidth <= 1024
      ? "133px"
      : windowWidth <= 1158 && windowWidth >= 1024
      ? "140px"
      : windowWidth >= 1500
      ? "240px"
      : "170px";

  return (
    <CGMReportResponseChartContainer $showYAxisValues={showYAxisValues}>
      <CGMReportResponseChartWeekDay>
        {showWeekDays ? weekDay : ""}
      </CGMReportResponseChartWeekDay>
      <CGMReportResponseChartWrapper>
        <CGMReportResponseChartDayDate>{dayDate}</CGMReportResponseChartDayDate>
        <ChartComponent
          id={id}
          primaryXAxis={primaryxAxis}
          primaryYAxis={primaryyAxis}
          height="120px"
          width={chartWidth}
          loaded={handleChartLoaded}
          axisLabelRender={axisLabelRender}
          border={{ width: 0 }}
          ref={chartRef}
        >
          <Inject
            services={[MultiColoredLineSeries, DateTime, DataLabel, StripLine]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={readingsSeries}
              xName="x"
              width={2}
              yName="y"
              type="MultiColoredLine"
              segmentAxis="Y"
              opacity={0.5}
            >
              <SegmentsDirective>
                <SegmentDirective
                  value={maxYAxisValue}
                  color="red"
                ></SegmentDirective>
                <SegmentDirective
                  value={inRangeNumbers[1]}
                  color="#686868"
                ></SegmentDirective>
                <SegmentDirective
                  value={inRangeNumbers[0]}
                  color="red"
                ></SegmentDirective>
              </SegmentsDirective>
            </SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
        <CGMReportResponseChartDayXAxisLabelsContainer>
          <span>12 am</span>
          <span>12 pm</span>
        </CGMReportResponseChartDayXAxisLabelsContainer>
      </CGMReportResponseChartWrapper>
    </CGMReportResponseChartContainer>
  );
};
