import { Controller, useFormContext } from "react-hook-form";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileModalLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileDietModalInputs } from "../../patient-profile-edit-diet-modal";

export const PatientProfileEditDietOtherLifeActivitiesFormControl = () => {
  const { control } = useFormContext<PatientProfileDietModalInputs>();

  return (
    <PatientProfileModalFormControl>
      <PatientProfileModalLabelContainer>
        <label>Activities / Exercise (Others)</label>
        <span>(Baseline / Pre-Otida)</span>
      </PatientProfileModalLabelContainer>
      <PatientProfileModalInputContainer>
        <Controller
          name={`patientLifeActivityOther.notes`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <input
              type="text"
              placeholder="Notes (Other Only)"
              value={value !== undefined ? value : ""}
              onChange={(e) => {
                onChange(e.target.value !== undefined ? e.target.value : "");
              }}
            />
          )}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
