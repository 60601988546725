import { createContext, PropsWithChildren, useContext } from "react";
import { NutritionRegimenDTO } from "../../../../../models/nutrition-regimen-dtos/nutrition-regimen-dto";
import { GetActiveNutritionRegimenResponseDTO } from "../../../../../models/patient-nutrition-regimen-dtos/get-active-nutrition-regimen-response-dto";
import { UseLoadableDataReturn } from "../../../../../hooks/use-loadable-data";
import { PatientDTO } from "../../../../../models/patient-dtos/patient-dto";

type NutritionGoals = {
  patientDTO: PatientDTO;
  nutritionRegimenDTOs: NutritionRegimenDTO[];
  activeNutritionRegimen: GetActiveNutritionRegimenResponseDTO;
};

export type LoadableNutritionGoals = UseLoadableDataReturn<NutritionGoals>;

const LoadableNutritionGoalsContext = createContext<
  LoadableNutritionGoals | undefined
>(undefined);

export type LoadableNutritionGoalsProviderProps = PropsWithChildren<{
  loadableNutritionGoals: LoadableNutritionGoals;
}>;

export function LoadableNutritionGoalsProvider(
  props: LoadableNutritionGoalsProviderProps
) {
  const { loadableNutritionGoals, children } = props;

  return (
    <LoadableNutritionGoalsContext.Provider value={loadableNutritionGoals}>
      {children}
    </LoadableNutritionGoalsContext.Provider>
  );
}

export function useLoadableNutritionGoals(): LoadableNutritionGoals {
  const loadableNutritionGoals = useContext(LoadableNutritionGoalsContext);
  if (loadableNutritionGoals === undefined) {
    throw Error(
      "useLoadableNutritionGoals must be used within an LoadableNutritionGoalssProvider"
    );
  }

  return loadableNutritionGoals;
}

export function useNutritionGoals(): NutritionGoals {
  const loadableNutritionGoals = useLoadableNutritionGoals();
  if (loadableNutritionGoals.state.data === undefined) {
    throw Error("NutritionGoals have never been loaded successfully yet.");
  }

  return loadableNutritionGoals.state.data;
}

export function useReloadNutritionGoals(): () => void {
  const loadableNutritionGoals = useLoadableNutritionGoals();
  return loadableNutritionGoals.reload;
}
