import { AxisModel } from "@syncfusion/ej2-react-charts";

export function buildPrimaryYAxis(minY: number, maxY: number): AxisModel {
  return {
    edgeLabelPlacement: "Shift",
    majorGridLines: {
      color: "transparent",
      width: 0,
    },
    majorTickLines: {
      width: 0,
    },
    minimum: minY,
    maximum: maxY,
    title: "KCal",
    interval: 1,
    stripLines: [
      {
        start: 1999,
        end: 2000,
        color: "#040415",
        opacity: 0.2,
        dashArray: "10,5",
        size: 2,
        sizeType: "Pixel",
      },
    ],
  };
}
