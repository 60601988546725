import styled from "styled-components/macro";
import {
  flexCenter,
  mediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const TargetAudienceTagsList = styled.ul`
  ${flexCenter}
  flex-wrap: wrap;
  gap: 8px;
  border-radius: 8px;
  padding: 0px;
  margin: 0px;
`;

export const TargetAudienceTagsListItem = styled.li`
  ${mediumFont}
  color: #14212e;
  background: ${({ theme }) => theme.newColors.otidaO2};
  border-radius: 94px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
  width: max-content;
  height: fit-content;
  user-select: none;
  cursor: pointer;

  span {
    font-size: 19px;
  }
`;

export const TargetAudienceTagsEmptyStateText = styled.p`
  margin: 0px;
  ${mediumFont}
  color: ${({ theme }) => theme.colors.textColor};
`;
