import { PropsWithChildren, useEffect } from "react";
import { ModalContainer, ModalBackdrop } from "./styled-modal";
import { ReactPortal } from "../react-portal";

type Props = PropsWithChildren<{
  handleHideModal: () => void;
  showModal: boolean;
}>;

export const Modal = (props: Props) => {
  const { handleHideModal, children, showModal } = props;

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  return (
    <ReactPortal>
      <ModalContainer showModal={showModal}>
        <ModalBackdrop showModal={showModal} onClick={handleHideModal} />
        {children}
      </ModalContainer>
    </ReactPortal>
  );
};
