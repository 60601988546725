import { PATIENTS_PATH } from "../constants/paths";
import { useClient } from "../context/client-context";
import { useCallback } from "react";
import { PatientCGMEntryDTO } from "../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";

export function useUpdatePatientCGMEntry(): (
  patientCGMEntry: PatientCGMEntryDTO
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientCGMEntryDTO) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      await client.put<void>(
        `${PATIENTS_PATH}/${patientCGMEntryDTO.patientId}/cgm-entries/${patientCGMEntryDTO.id}`,
        patientCGMEntryDTO
      );
    },
    [client]
  );
}
