import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Button } from "../../../../../../../styles/classes/reusable-classes";
import {
  NutrtionReportSummaryNotesList,
  NutrtionReportSummaryNotesListContainer,
  NutrtionReportSummaryNotesListHeader,
  NutrtionReportSummaryNotesListItem,
  NutrtionReportSummaryNotesListItemDeleteIcon,
  NutrtionReportSummaryNotesListItemInputForm,
} from "../styled-nutrtion-report-summary-notes";
import { NutrtionReportSummaryNotesInputs } from "../../../nutrition-report-summary";

export const NutrtionReportSummaryUnhealthyNotes = () => {
  const { control } = useFormContext<NutrtionReportSummaryNotesInputs>();

  const { append, fields, remove } = useFieldArray({
    name: "patientNutritionReportUnhealthyHabits",
    control,
  } as never);

  const isFieldsEmpty = fields.length === 0;

  return (
    <NutrtionReportSummaryNotesListContainer>
      <NutrtionReportSummaryNotesListHeader habbits="Unhealthy">
        <img src="/img/plans/Warning.svg" alt="Warning" />
        <p>Unhealthy habits to avoid</p>
      </NutrtionReportSummaryNotesListHeader>
      {isFieldsEmpty && (
        <Button outlineNoBorder flex type="button" onClick={() => append({})}>
          Add Note<span className="material-symbols-outlined">add</span>
        </Button>
      )}
      <NutrtionReportSummaryNotesList isFieldsEmpty={isFieldsEmpty}>
        {fields.map((field, index) => (
          <NutrtionReportSummaryNotesListItem key={field.id}>
            <NutrtionReportSummaryNotesListItemDeleteIcon
              onClick={() => remove(index)}
            >
              <img src="/img/trash.svg" alt="trash" />
            </NutrtionReportSummaryNotesListItemDeleteIcon>
            <Controller
              name={`patientNutritionReportUnhealthyHabits.${index}.note`}
              control={control}
              render={({ field: { value, onChange } }) => (
                <NutrtionReportSummaryNotesListItemInputForm
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    autoFocus
                    type="text"
                    placeholder="Add Notes"
                    value={value !== undefined ? value : ""}
                    onChange={(e) =>
                      onChange(
                        e.target.value !== undefined ? e.target.value : ""
                      )
                    }
                  />
                  {index === fields.length - 1 && (
                    <Button
                      outlineNoBorder
                      flex
                      type="submit"
                      onClick={() => append({ value })}
                    >
                      <span className="material-symbols-outlined">add</span>
                    </Button>
                  )}
                </NutrtionReportSummaryNotesListItemInputForm>
              )}
            />
          </NutrtionReportSummaryNotesListItem>
        ))}
      </NutrtionReportSummaryNotesList>
    </NutrtionReportSummaryNotesListContainer>
  );
};
