import { PatientDTO } from "../../../models/patient-dtos/patient-dto";
import { useMemo, useState } from "react";
import {
  FoodLoggingPatientsTab,
  FoodLoggingPatientsTabs,
} from "./food-logging-patients-tabs/food-logging-patients-tabs";
import { usePatientTodoMealsCountsUpdates } from "../context/patient-todo-meals-counts-updates-context";
import { getPatientTodoMealsCount } from "../util/get-patient-todo-meals-count";
import {
  FoodLoggingPatientCardContainer,
  FoodLoggingPatientCardIconContainer,
  FoodLoggingPatientCardText,
  FoodLoggingPatientCardTitle,
  FoodLoggingPatientsBody,
  FoodLoggingPatientsContainer,
} from "./styled-food-logging-patients";
import { assertNever } from "../../../utils/assert-never";
import { useFoodLoggingData } from "../context/food-logging-data-context";

export type FoodLoggingPatientsProps = {
  onPatientChange: (patientDTO: PatientDTO) => void;
  selectedTab: FoodLoggingPatientsTab;
  onTabChange: (tab: FoodLoggingPatientsTab) => void;
};

export function FoodLoggingPatients(props: FoodLoggingPatientsProps) {
  const { onPatientChange, selectedTab, onTabChange } = props;

  const {
    todoMealsCountsUpdatesByPatientId,
  } = usePatientTodoMealsCountsUpdates();

  const { selectedPatientDTO, patientDTOs } = useFoodLoggingData();

  const [searchTerm, setSearchTerm] = useState("");

  const sortedPatientDTOs = useMemo(
    () =>
      patientDTOs.sort((a) => {
        if (a.name.includes("Test") || a.name.includes("test")) {
          return 1;
        } else {
          return -1;
        }
      }),
    [patientDTOs]
  );

  const todoPatientDTOs = useMemo(
    () =>
      sortedPatientDTOs.filter((patientDTO) => {
        return (
          getPatientTodoMealsCount(
            patientDTO,
            todoMealsCountsUpdatesByPatientId
          ) > 0
        );
      }),
    [sortedPatientDTOs, todoMealsCountsUpdatesByPatientId]
  );

  const selectedPatientDTOs = useMemo(() => {
    if (selectedTab === "todo") {
      return todoPatientDTOs;
    } else if (selectedTab === "all") {
      return sortedPatientDTOs;
    } else {
      return assertNever(selectedTab);
    }
  }, [sortedPatientDTOs, selectedTab, todoPatientDTOs]);

  const selectedPatientDTOsFilteredBySearch = useMemo(
    () =>
      selectedPatientDTOs.filter((patientDTO) => {
        if (
          patientDTO.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          patientDTO.whatsAppId
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
        ) {
          return patientDTO;
        } else if (searchTerm === "") {
          return patientDTO;
        } else {
          return 0;
        }
      }),
    [selectedPatientDTOs, searchTerm]
  );

  const todoPatientsCount = todoPatientDTOs.length;

  return (
    <FoodLoggingPatientsContainer>
      <FoodLoggingPatientsTabs
        todoPatientsCount={todoPatientsCount}
        selectedTab={selectedTab}
        onTabChange={onTabChange}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <FoodLoggingPatientsBody>
        {selectedPatientDTOsFilteredBySearch.map((patientDTO) => (
          <FoodLoggingPatientCardContainer
            key={patientDTO.id}
            activeState={patientDTO.id === selectedPatientDTO?.id}
            onClick={() => onPatientChange(patientDTO)}
          >
            <FoodLoggingPatientCardIconContainer className="material-symbols-outlined">
              person
            </FoodLoggingPatientCardIconContainer>
            <div>
              <FoodLoggingPatientCardTitle>
                {patientDTO.name}
                <span>
                  {patientDTO.whatsAppId !== undefined
                    ? ` - ${patientDTO.whatsAppId}`
                    : ""}
                </span>
              </FoodLoggingPatientCardTitle>
              <FoodLoggingPatientCardText>
                {getPatientTodoMealsCount(
                  patientDTO,
                  todoMealsCountsUpdatesByPatientId
                )}{" "}
                todo meals
              </FoodLoggingPatientCardText>
            </div>
          </FoodLoggingPatientCardContainer>
        ))}
      </FoodLoggingPatientsBody>
    </FoodLoggingPatientsContainer>
  );
}
