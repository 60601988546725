import { Controller, useFormContext } from "react-hook-form";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileModalLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileDietModalInputs } from "../../patient-profile-edit-diet-modal";

export const PatientProfileEditDietWaterIntakeFormControl = () => {
  const { control } = useFormContext<PatientProfileDietModalInputs>();

  return (
    <PatientProfileModalFormControl>
      <PatientProfileModalLabelContainer>
        <label>Water Intake</label>
      </PatientProfileModalLabelContainer>
      <PatientProfileModalInputContainer hasTwoInputsFlex inputWithSublabel>
        <div>
          <Controller
            control={control}
            name="waterIntakeCupsPerDay"
            render={({ field: { value, onChange } }) => (
              <input
                type="text"
                placeholder="Quantity"
                value={value !== undefined ? value : undefined}
                onChange={(e) =>
                  onChange(
                    e.target.value !== undefined ? e.target.value : undefined
                  )
                }
              />
            )}
          />
          <strong>Cup</strong>
        </div>
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
