import { Outlet, useParams } from "react-router-dom";
import {
  ScreenSubNavbar,
  SubNavLinksType,
} from "../../../components/screen-sub-navbar/screen-sub-navbar";
import { SubScreenContainer } from "../../../styles/classes/reusable-classes";
import { useCallback } from "react";
import { LoadableMedicationsProvider } from "./context/loadable-medications-context";
import { useLoadableData } from "../../../hooks/use-loadable-data";
import { useGetPatientMedications } from "../../../hooks/use-get-patient-medications";
import { LoadableDataLoading } from "../../../components/loadable-data-loading";
import { useGetPatient } from "../../../hooks/patient-hooks/use-get-patient";

export const Medications = () => {
  const { id, page } = useParams();

  const getPatient = useGetPatient();
  const getMedciations = useGetPatientMedications();

  const loadableMedicationsCallback = useCallback(async () => {
    const patientID = Number(id!);

    const [patientDTO, patientMedicationDTOs] = await Promise.all([
      getPatient(patientID),
      getMedciations(patientID),
    ]);

    return { patientDTO, patientMedicationDTOs };
  }, [id, getPatient, getMedciations]);

  const loadableMedications = useLoadableData(loadableMedicationsCallback);

  return (
    <div>
      <ScreenSubNavbar links={buildMedicationsNavbarLinks(id, page)} />
      <SubScreenContainer>
        <LoadableMedicationsProvider loadableMedications={loadableMedications}>
          <LoadableDataLoading
            state={loadableMedications.state}
            successComponent={() => <Outlet />}
          />
        </LoadableMedicationsProvider>
      </SubScreenContainer>
    </div>
  );
};

function buildMedicationsNavbarLinks(
  id: string | undefined,
  page: string | undefined
): SubNavLinksType {
  const medicationLinks: SubNavLinksType = [
    {
      name: "All Medications",
      path: `/patients/${id}/medications/table/${
        page !== undefined ? page : 1
      }`,
    },
    {
      name: "Otida Prescriptions",
      path: `/patients/${id}/medications/prescriptions`,
    },
  ];

  return medicationLinks;
}
