import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  mediumFont,
} from "../../../../../../../styles/classes/gloabl-classes";

export const MessagingMediaSidebarPinnedMessagesListList = styled.ul``;

export const MessagingMediaSidebarPinnedMessagesListListItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 8px;

  :first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  }
`;

export const MessagingMediaSidebarPinnedMessagesListListItemTopContainer = styled.div`
  ${flexCenterSpaceBetween}
`;

export const MessagingMediaSidebarPinnedMessagesUserInfo = styled.div`
  ${flexCenter}

  .str-chat__avatar {
    margin-right: 8px;
  }

  p {
    margin: 0px;
    color: ${({ theme }) => theme.colors.titleColor};
    ${mediumFont}
  }
`;

export const MessagingMediaSidebarPinnedMessagesDate = styled.span`
  ${mediumFont}
  color: ${({ theme }) => theme.colors.textColor};
`;
