import { PatientMealDTO } from "../../../../../../../../../models/patient-meal-dtos/patient-meal-dto";
import { MealsAnalysisResponseTableCell } from "../styled-meals-analysis-response-table-cells";
import { MealsAnalysisResponseCarbServingsTableCellCarbSources } from "./meals-analysis-response-carb-servings-table-cell-carb-sources/meals-analysis-response-carb-servings-table-cell-carb-sources";

type MealsAnalysisResponseCarbServingsTableCellProps = {
  patientMeal: PatientMealDTO;
};

export const MealsAnalysisResponseCarbServingsTableCell = (
  props: MealsAnalysisResponseCarbServingsTableCellProps
) => {
  const { patientMeal } = props;

  const macrosCarbCounter = patientMeal.carbCounterV1MealSummary;
  const macrosMealSummary = patientMeal.patientMealMacrosSummary;

  const carbSources = patientMeal.patientMealCarbSources;

  return (
    <MealsAnalysisResponseTableCell $macrosCell>
      <MealsAnalysisResponseCarbServingsTableCellCarbSources
        carbServings={
          macrosCarbCounter !== undefined
            ? macrosCarbCounter.totalCarbsGm / 15
            : macrosMealSummary.carbsGm / 15
        }
        carbSources={carbSources}
      />
    </MealsAnalysisResponseTableCell>
  );
};
