import {
  PatientLoginLoader,
  PatientLoginLoadingContainer,
} from "./styled-patient-login-loading";

export const PatientLoginLoading = () => {
  return (
    <PatientLoginLoadingContainer>
      <PatientLoginLoader />
    </PatientLoginLoadingContainer>
  );
};
