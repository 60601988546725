import { ReactNode } from "react";
import { PatientMealDTO } from "../../../../../models/patient-meal-dtos/patient-meal-dto";
import { FoodLoggingPatientsTab } from "../../../food-logging-patients/food-logging-patients-tabs/food-logging-patients-tabs";
import { FoodLoggingSelectedPatientMealFooter } from "../../components/food-logging-selected-patient-meal-footer/food-logging-selected-patient-meal-footer";
import {
  CarbCounterFoodLoggingSelectedPatientMealLayoutContainer,
  CarbCounterFoodLoggingSelectedPatientMealLayoutFeedbackContainer,
} from "./styled-carb-counter-selected-patient-meal-layout";
import { FoodLoggingSelectedPatientMealFeedback } from "../../components/food-logging-selected-patient-meal-feedback/food-logging-selected-patient-meal-feedback";
import { useCarbCounterMealState } from "../context/carb-counter-meal-state-context";

type CarbCounterFoodLoggingSelectedPatientMealLayoutProps = {
  patientMealDTO: PatientMealDTO;
  selectedTab: FoodLoggingPatientsTab;
  mealBody: ReactNode;
};

export const CarbCounterFoodLoggingSelectedPatientMealLayout = (
  props: CarbCounterFoodLoggingSelectedPatientMealLayoutProps
) => {
  const { patientMealDTO, selectedTab, mealBody } = props;

  const { carbCounterState } = useCarbCounterMealState();

  return (
    <CarbCounterFoodLoggingSelectedPatientMealLayoutContainer>
      {mealBody}
      {(carbCounterState.state === "Analyzed" ||
        carbCounterState.state === "Persisted") && (
        <CarbCounterFoodLoggingSelectedPatientMealLayoutFeedbackContainer>
          <FoodLoggingSelectedPatientMealFeedback />
        </CarbCounterFoodLoggingSelectedPatientMealLayoutFeedbackContainer>
      )}
      <FoodLoggingSelectedPatientMealFooter
        patientMealDTO={patientMealDTO}
        selectedTab={selectedTab}
      />
    </CarbCounterFoodLoggingSelectedPatientMealLayoutContainer>
  );
};
