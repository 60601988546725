import { useSinglePatientSidebar } from "../../../context/loadable-single-patient-sidebar-context";
import {
  PersonalInfoGroup,
  PersonalInfoGroupTitle,
  PersonalInfoGroupTop,
} from "../styled-personal-info-tab";
import { BloodPressureModal } from "./blood-pressure-modal/blood-pressure-modal";
import {
  BloodPressureContainer,
  BloodPressureList,
  BloodPressureListItem,
} from "./styled-blood-pressure";

export const BloodPressure = () => {
  const { patientBloodPressureDTOs } = useSinglePatientSidebar();

  return (
    <PersonalInfoGroup>
      <PersonalInfoGroupTop>
        <PersonalInfoGroupTitle>Blood Pressure</PersonalInfoGroupTitle>
        <BloodPressureModal />
      </PersonalInfoGroupTop>
      <BloodPressureContainer>
        <BloodPressureList>
          {patientBloodPressureDTOs
            .sort((a, b) =>
              a.date.localeCompare(b.date, "en", {
                sensitivity: "base",
              })
            )
            .map((item, index) => (
              <BloodPressureListItem key={index}>
                <p>
                  {item.systolic}/{item.diastolic}
                </p>
                <span>{item.date}</span>
              </BloodPressureListItem>
            ))}
        </BloodPressureList>
      </BloodPressureContainer>
    </PersonalInfoGroup>
  );
};
