import { useEffect } from "react";
import { useImmediateAttentionPatientsSidebar } from "../context/immediate-attention-patients-sidebar-context";
import {
  ImmediateAttentionPatientsSidebarBackdrop,
  ImmediateAttentionPatientsSidebarBodyContainer,
  ImmediateAttentionPatientsSidebarContainer,
} from "./styled-immediate-attention-patients-sidebar";
import { ImmediateAttentionPatientsSidebarHeader } from "./immediate-attention-patients-sidebar-header/immediate-attention-patients-sidebar-header";
import { ImmediateAttentionPatientsSidebarPatientInfo } from "./immediate-attention-patients-sidebar-patient-info/immediate-attention-patients-sidebar-patient-info";
import { ImmediateAttentionPatientsSidebarPatientDiagnosis } from "./immediate-attention-patients-sidebar-patient-diagnosis/immediate-attention-patients-sidebar-patient-diagnosis";
import { ImmediateAttentionPatientsSidebarPatientKeyIndicators } from "./immediate-attention-patients-sidebar-patient-key-indicators/immediate-attention-patients-sidebar-patient-key-indicators";
import { ImmediateAttentionPatientsSidebarPatientBodyMeasurments } from "./immediate-attention-patients-sidebar-patient-body-measurments/immediate-attention-patients-sidebar-patient-body-measurments";
import { ImmediateAttentionPatientsSidebarTabs } from "./immediate-attention-patients-sidebar-tabs/immediate-attention-patients-sidebar-tabs";

export const ImmediateAttentionPatientsSidebar = () => {
  const { showSidebar, closeSidebar } = useImmediateAttentionPatientsSidebar();

  useEffect(() => {
    if (showSidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showSidebar]);

  return (
    <ImmediateAttentionPatientsSidebarContainer $showSidebar={showSidebar}>
      <ImmediateAttentionPatientsSidebarBackdrop onClick={closeSidebar} />
      <ImmediateAttentionPatientsSidebarBodyContainer
        $showSidebar={showSidebar}
      >
        <ImmediateAttentionPatientsSidebarHeader />
        <ImmediateAttentionPatientsSidebarPatientInfo />
        <ImmediateAttentionPatientsSidebarPatientDiagnosis />
        <ImmediateAttentionPatientsSidebarPatientKeyIndicators />
        <ImmediateAttentionPatientsSidebarPatientBodyMeasurments />
        <ImmediateAttentionPatientsSidebarTabs />
      </ImmediateAttentionPatientsSidebarBodyContainer>
    </ImmediateAttentionPatientsSidebarContainer>
  );
};
