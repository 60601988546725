import { useParams } from "react-router-dom";
import { useGetContentModules } from "../../../../hooks/content-module-hooks/use-get-content-modules";
import { useCallback } from "react";
import { ContentModuleType } from "../../../../models/content-module-dtos/content-module-type";
import { ContentCourseModuleBody } from "./content-course-module-body/content-course-module-body";
import { Loading } from "../../../../components/loading";

export const ContentCourseModule = () => {
  const { moduleID, courseID } = useParams();
  const parentContentModuleId = parseInt(courseID!);
  const currentModuleID = parseInt(moduleID!);

  const getContentModules = useGetContentModules();

  const loadContentCourseModuleCallback = useCallback(async () => {
    const contentModuleTypeModule: ContentModuleType = "Module";

    const contentModuleDTOs = await getContentModules(contentModuleTypeModule);

    const contentCourseModule =
      parentContentModuleId !== undefined
        ? contentModuleDTOs.find(
            (moduleDTO) =>
              moduleDTO.id === currentModuleID &&
              moduleDTO.parentContentModuleId === parentContentModuleId
          )
        : undefined;

    return { contentCourseModule };
  }, [getContentModules, currentModuleID, parentContentModuleId]);

  return (
    <Loading
      load={loadContentCourseModuleCallback}
      successComponent={(contentCourseModule) => (
        <ContentCourseModuleBody
          contentCourseModule={contentCourseModule.contentCourseModule}
        />
      )}
    />
  );
};
