import { DropdownListItem } from "../../../../../components/dropdown/styled-dropdown";
import { Modal } from "../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../components/modal/use-modal-helpers";
import { ImportCronometerNewReadingsModal } from "./import-cronometer-new-readings-modal/import-cronometer-new-readings-modal";

export function ImportCronometerNewReadings() {
  const { isModalOpen, modalBodyKey, closeModal, openModal } =
    useModalHelpers();

  return (
    <>
      <DropdownListItem onClick={openModal}>
        Import Cronometer (new readings)
      </DropdownListItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <ImportCronometerNewReadingsModal
          key={modalBodyKey}
          onModalClose={closeModal}
        />
      </Modal>
    </>
  );
}
