import { EmptyState } from "../../../../../components/empty-state/empty-state";

export const EmptyEducation = () => {
  return (
    <EmptyState
      hasHeader={false}
      hasSearch={false}
      text="No Educatioin Content Available"
      subText=""
      button={<></>}
      title=""
    />
  );
};
