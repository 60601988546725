import {
  // isMedicine,
  PatientServingDTO,
} from "../../../models/patient-serving-dto";
import { Temporal } from "temporal-polyfill";
import { SeriesDataSource } from "./series";
import { MealTooltipProps } from "../tooltips/meal-tooltip";
import { CommonTooltipProps } from "../tooltips/tooltip";

export function buildMealsSeries(
  patientServingDTOs: PatientServingDTO[],
  startPlainDateTime: Temporal.PlainDateTime
): SeriesDataSource {
  const meals = Object.values(groupServingsIntoMeals2(patientServingDTOs));

  const series: SeriesDataSource = [];
  for (const mealPatientServingDTOs of meals) {
    const mealPatientServingDTOsArray = Object.values(
      mealPatientServingDTOs
    ).map((item) => {
      return item;
    });

    const firstPatientServingDTO = mealPatientServingDTOsArray[0];

    const dateTime = Temporal.PlainDateTime.from(
      `${firstPatientServingDTO.date}T${firstPatientServingDTO.time}`
    );

    const x = dateTime.since(startPlainDateTime).total({ unit: "minutes" });
    const y = 100;
    const tooltip: CommonTooltipProps & {
      kind: "meal";
      mealTooltipProps: MealTooltipProps;
    } = {
      x,
      y,
      kind: "meal",
      mealTooltipProps: { patientServingDTOs: mealPatientServingDTOs },
    };

    // Meal
    series.push({ x, y, tooltip: tooltip });

    // Gap
    series.push({ x });
  }

  return series;
}

// Hossam's Code
// function groupServingsIntoMeals(patientServingDTOs: PatientServingDTO[]) {
//   const meals: Array<[PatientServingDTO, ...PatientServingDTO[]]> = [];
//   let lastPlainDateTime: Temporal.PlainDateTime | undefined = undefined;
//   for (const patientServingDTO of patientServingDTOs) {
//     if (isMedicine(patientServingDTO)) {
//       continue;
//     }

//     const currentDateTime = Temporal.PlainDateTime.from(
//       `${patientServingDTO.date}T${patientServingDTO.time}`
//     );

//     if (currentDateTime !== lastPlainDateTime) {
//       meals.push([patientServingDTO]);
//     } else {
//       meals[meals.length - 1].push(patientServingDTO);
//     }

//     lastPlainDateTime = currentDateTime;
//   }
//   return meals;
// }

type Meals = Record<string, PatientServingDTO[]>;

const groupServingsIntoMeals2 = (patientServingDTOs: PatientServingDTO[]) => {
  const result = patientServingDTOs.reduce(
    (groupedMeals: Meals, item: PatientServingDTO) => {
      if (!groupedMeals[item.time]) groupedMeals[item.time] = [];

      groupedMeals[item.time].push(item);

      return groupedMeals;
    },
    {} as Meals
  );

  return result;
};
