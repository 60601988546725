import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";

type NutritionReportAllCarbSourcesTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportAllCarbSourcesTableCell = (
  props: NutritionReportAllCarbSourcesTableCellProps
) => {
  const { kind } = props;

  const mealCarbSources =
    kind.kind === "Meal"
      ? kind.data.patientMealCarbSources.map(
          (patientMealCarbSource) => patientMealCarbSource.carbSource.name
        )
      : "";

  return (
    <NutritionReportTableCell
      cellKind="Sources"
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      stringValue={mealCarbSources !== "" ? mealCarbSources.join(",") : ""}
    />
  );
};
