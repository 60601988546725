import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PutPatientDietProfileDTO } from "../../models/patient-diet-profile-dtos/put-patient-diet-profile-dto";
import { PatientDTO } from "../../models/patient-dtos/patient-dto";

export function usePutPatientDietProfile(): (
  patientDTO: PatientDTO,
  putPatientDietProfileDTO: PutPatientDietProfileDTO
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientDTO, putPatientDietProfileDTO) => {
      await client.put<void>(
        `${PATIENTS_PATH}/${patientDTO.id}/diet-profile`,
        putPatientDietProfileDTO
      );
    },
    [client]
  );
}
