import styled from "styled-components/macro";
import {
  ToolTip,
  TootlTipText,
} from "../../nutrition-summary/styled-nutrition-summary";
import { flexCenterCenter } from "../../../../../../../../styles/classes/gloabl-classes";

export const NutritionSummaryForDemoTooltipContainer = styled.span`
  width: 4px;
  ${flexCenterCenter}

  ${ToolTip} {
    width: 92%;
    top: 0px;
    left: 0px;
    right: 0px;

    div {
      border: 0px;
    }

    ::after {
      display: none;
    }
  }

  ${TootlTipText} {
    width: 90%;
    margin: 0 auto;

    p {
      text-align: left;
      max-width: 60%;
    }
  }

  :hover ${ToolTip} {
    visibility: visible;
    overflow: visible;
    opacity: 1;
  }
`;

export const NutritionSummaryForDemoTooltipIcon = styled.i`
  font-size: 18px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textColor};
`;
