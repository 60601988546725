import { useConentModuleMobilePreviewCarousel } from "../context/conent-module-mobile-preview-carousel-context";
import { ContentModuleLessonMobilePreviewHeaderContainer } from "./styled-content-module-lesson-mobile-preview-header";

type ContentModuleLessonMobilePreviewHeaderProps = {
  lessonTitle: string;
  lessonCardsLength: number;
};

export const ContentModuleLessonMobilePreviewHeader = (
  props: ContentModuleLessonMobilePreviewHeaderProps
) => {
  const { lessonTitle, lessonCardsLength } = props;

  const {
    currentCard,
    goToPreviousCard,
  } = useConentModuleMobilePreviewCarousel();

  const currentIndex =
    (currentCard - 1 + lessonCardsLength) % lessonCardsLength;

  return (
    <ContentModuleLessonMobilePreviewHeaderContainer>
      <span
        className="material-symbols-outlined"
        onClick={() => goToPreviousCard(currentIndex)}
      >
        chevron_left
      </span>
      <p dir="auto">{lessonTitle}</p>
    </ContentModuleLessonMobilePreviewHeaderContainer>
  );
};
