import { useEffect, useState } from "react";
import { useGetPatientCGMEntries } from "../../../hooks/use-get-patient-cgm-entries";
import { PatientCGMEntryDTO } from "../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { useParams } from "react-router-dom";
import { Source } from "../../../models/patient-cgm-entry-dtos/patient-cgm-entry-source";

type UseGetPatientCGMEntryDTOs = {
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
  isLoaded: boolean;
};

export function useGetPatientCGMEntryDTOs(
  startDate?: string,
  endDate?: string,
  source?: Source
): UseGetPatientCGMEntryDTOs {
  const { id } = useParams();

  const [patientCGMEntryDTOs, setPatientCGMEntryDTOs] = useState<
    PatientCGMEntryDTO[]
  >([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const getPatientCGMEntries = useGetPatientCGMEntries();

  useEffect(() => {
    const fetch = async () => {
      getPatientCGMEntries(Number(id!), source, startDate, endDate).then(
        (response) => {
          setPatientCGMEntryDTOs(response);
          setIsLoaded(true);
        }
      );
    };

    fetch();
  }, [id, getPatientCGMEntries, setIsLoaded, source, startDate, endDate]);

  return { patientCGMEntryDTOs, isLoaded };
}
