import { PropsWithChildren } from "react";
import {
  GenericActionsListContainer,
  GenericActionsListList,
} from "./styled-generic-actions-list";
import { useDropdown } from "../dropdown/hooks/use-dropdown";

type GenericActionsListProps = PropsWithChildren<{
  disabled?: boolean;
  customZIndex?: string;
  showMoreIconImg?: boolean;
}>;

export function GenericActionsList(props: GenericActionsListProps) {
  const { disabled, customZIndex, showMoreIconImg } = props;
  const { dropdownContainerRef, toggleDropdown, showDropdown } = useDropdown();

  return (
    <GenericActionsListContainer disabled={disabled}>
      {showMoreIconImg ? (
        <div
          className="cursor-pointer"
          ref={dropdownContainerRef}
          onClick={toggleDropdown}
        >
          <img src="/img/chat/show-more.svg" alt="" />
        </div>
      ) : (
        <span
          data-testid="generic-actions-list-ellipsis"
          className="material-icons-outlined"
          ref={dropdownContainerRef}
          onClick={toggleDropdown}
        >
          more_horiz
        </span>
      )}
      <GenericActionsListList
        show={showDropdown}
        customZIndex={customZIndex !== undefined ? customZIndex : ""}
      >
        {props.children}
      </GenericActionsListList>
    </GenericActionsListContainer>
  );
}
