import styled from "styled-components/macro";
import {
  flexCenter,
  regularMediumFont,
  regularRegularFont,
  regularSemiBoldFont,
  smallRegularFont,
  smallSemiBoldFont,
} from "../../../../styles/classes/gloabl-classes";

export const ViewPlansScreen = styled.div`
  position: fixed;
  inset: 0;
  z-index: 550;
  background: ${({ theme }) => theme.colors.containerColor};
  overflow-y: auto;
  overflow-x: hidden;
`;

// Shared Classes
export const ViewPlansIconContainer = styled.div`
  width: 45%;
  height: 120px;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 1024px) {
    left: 100px;
  }
`;

export const ViewPlansTextContainer = styled.div<{ usedAsHeader?: boolean }>`
  width: 55%;
  text-align: right;

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px 0px 4px;

    @media screen and (min-width: 768px) {
      font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
      font-size: 17px;
    }
  }

  span {
    ${smallRegularFont}
    color: ${({ theme }) => theme.colors.textColor};

    @media screen and (min-width: 768px) {
      ${regularRegularFont}
    }
  }

  button {
    ${flexCenter}
    gap: 0px 4px;
    margin-left: auto;
    margin-top: 8px;
    background: transparent;
    ${smallSemiBoldFont}
    color: ${({ theme }) => theme.newColors.byOtida};
    padding: 0px;

    @media screen and (min-width: 768px) {
      ${regularSemiBoldFont}
    }
  }

  @media screen and (min-width: 1024px) {
    padding-right: ${({ usedAsHeader }) => usedAsHeader && "20%"};
  }
`;

export const SinglePlanDropdownsContainer = styled.div`
  display: grid;
  gap: 0.5rem 0rem;
  background: ${({ theme }) => theme.colors.bodyColor};
  min-height: 100vh;
  align-content: flex-start;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: 1024px) {
    width: 50%;
    margin: 0 auto;
    background: ${({ theme }) => theme.colors.containerColor};
  }
`;
