import styled from "styled-components/macro";
import { lightShadowAlt } from "../../../../styles/global-style";
import {
  flexCenter,
  flexCenterCenter,
} from "../../../../styles/classes/gloabl-classes";

export const AllPatientsAnalyticsContainer = styled.div`
  padding: 16px 24px;
  background: ${({ theme }) => theme.colors.bodyColor};
`;

export const AllPatientsAnalyticsCardItem = styled.li`
  padding: 12px;
  background: ${({ theme }) => theme.colors.containerColor};
  border-radius: 8px;
  min-width: 200px;
  ${flexCenter}
  gap: 0px 8px;
`;

export const AllPatientsAnalyticsCardItemImageContainer = styled.div`
  width: 50px;
  height: 50px;
  padding: 8px;
  border-radius: 8px;
  ${flexCenterCenter}

  img {
    width: 100%;
    height: 100%;
  }
`;

export const AllPatientsAnalyticsCardItemTextContainer = styled.div`
  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.titleColor};
  }
  span {
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;

export const AllPatientsAnalyticsCardsList = styled.ul`
  margin: 0px auto;
  padding: 0px;
  ${flexCenterCenter}
  gap: 0px 24px;
  background: ${({ theme }) => theme.colors.containerColor};
  ${lightShadowAlt}
  width: 50%;
  border-radius: 8px;

  li:nth-child(1) {
    ${AllPatientsAnalyticsCardItemImageContainer} {
      background: ${({ theme }) => theme.colors.primaryColorAlt};

      img {
      }
    }
  }

  li:nth-child(2) {
    ${AllPatientsAnalyticsCardItemImageContainer} {
      background: ${({ theme }) => theme.colors.danger};
    }
  }

  li:nth-child(3) {
    ${AllPatientsAnalyticsCardItemImageContainer} {
      background: ${({ theme }) => theme.colors.warning};
    }
  }

  li:nth-child(4) {
    ${AllPatientsAnalyticsCardItemImageContainer} {
      background: ${({ theme }) => theme.newColors.warningAlt};
    }
  }
`;
