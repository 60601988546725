import { useCallback, useEffect, useState } from "react";

/**
 * In loading & errored case, `data` is the last data successfully retrieved.
 */
export type LoadableDataState<T> =
  | { kind: "loading"; data?: T }
  | { kind: "loaded"; data: T }
  | { kind: "errored"; error: any; data?: T };

export type UseLoadableDataReturn<T> = {
  state: LoadableDataState<T>;
  reload: () => void;
};

export function useLoadableData<T>(load: () => Promise<T>) {
  const [state, setState] = useState<LoadableDataState<T>>({
    kind: "loading",
  });

  const reload = useCallback(() => {
    setState((state) => ({ kind: "loading", data: state.data }));
    load()
      .then((data) => setState({ kind: "loaded", data }))
      .catch((error) =>
        setState((state) => ({ kind: "errored", error, data: state.data }))
      );
  }, [load]);

  useEffect(() => {
    reload();
  }, [reload]);

  return { state, reload };
}
