import { Link, useParams } from "react-router-dom";
import { Button } from "../../../../../styles/classes/reusable-classes";
import {
  NutritionReportTableFooterButtonContainer,
  NutritionReportTableFooterContainer,
  NutritionReportTableFooterErrorMessage,
} from "./styled-nutrition-report-table-footer";
import { GetActiveNutritionRegimenResponseDTO } from "../../../../../models/patient-nutrition-regimen-dtos/get-active-nutrition-regimen-response-dto";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetActiveNutritionRegimen } from "../../../../../hooks/patient-nutrition-regimen-hooks/use-get-active-nutrition-regimen";
import { useNutritionReportSelectedTableRows } from "../../../context/nutrition-report-context/nutrition-report-selected-table-rows-context";

export const NutritionReportTableFooter = () => {
  const { patientID } = useParams();
  const { selectedDays } = useNutritionReportSelectedTableRows();

  const [activeNutritionRegimen, setActiveNutritionRegimen] =
    useState<GetActiveNutritionRegimenResponseDTO>({});

  const getActiveNutritionRegimen = useGetActiveNutritionRegimen();

  const loadActiveNutritionRegimen = useCallback(
    () =>
      getActiveNutritionRegimen(parseInt(patientID!)).then((res) =>
        setActiveNutritionRegimen(res)
      ),
    [patientID, getActiveNutritionRegimen]
  );

  useEffect(() => {
    loadActiveNutritionRegimen();
  }, [loadActiveNutritionRegimen]);

  const isNutritionRegimen = useMemo(
    () => activeNutritionRegimen.nutritionRegimen !== undefined,
    [activeNutritionRegimen.nutritionRegimen]
  );
  const isSelectedDays = selectedDays.length > 0;

  return (
    <NutritionReportTableFooterContainer>
      <NutritionReportTableFooterErrorMessage>
        <span>
          {!isSelectedDays && "To generate report please select a date range."}
        </span>
        {!isNutritionRegimen &&
          "There are no nutrition goals set for this patient. In order to generate a report please set goals for this patient."}
        {!isNutritionRegimen && (
          <Link to={`/patients/${patientID}/dietitian-report/nutrition-goals`}>
            Set Goals
          </Link>
        )}
      </NutritionReportTableFooterErrorMessage>
      <NutritionReportTableFooterButtonContainer
        disabled={!isNutritionRegimen || !isSelectedDays}
      >
        <Link
          to={`/food-logging/food-logging-nutrition-report/${patientID}/report-summary`}
        >
          <Button disabled={!isNutritionRegimen || !isSelectedDays}>
            Generate Report
          </Button>
        </Link>
      </NutritionReportTableFooterButtonContainer>
    </NutritionReportTableFooterContainer>
  );
};
