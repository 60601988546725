import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useVideoUploadHelpers } from "../../../../../../../components/video-upload/context/video-upload-helpers-context";
import { useAddUploadedFile } from "../../../../../../../../../hooks/uploaded-file-hooks/use-add-uploaded-file";
import { useToastService } from "../../../../../../../../../context/toast-service-context";
import { ContentModuleUpdateModeInputs } from "../../../../../../../context/content-module-mode-context";
import { ContentModuleCardPartType } from "../../../../../../../../../models/content-module-dtos/content-module-card-part-type";
import { CreateUploadedFileDTO } from "../../../../../../../../../models/uploaded-file-dtos/create-uploaded-file-dto";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../../styles/classes/reusable-classes";
import { VideoUpload } from "../../../../../../../components/video-upload/video-upload";
import { UploadedFileWithPresignedUrlDTO } from "../../../../../../../../../models/uploaded-file-dtos/uploaded-file-with-presigned-url-dto";
import { useCardPartsLoading } from "../../../../../../context/card-parts-loading-context";

type LessonUpdateModeCardVideoPartControllerProps = {
  cardIndex: number;
  cardPartIndex: number;
};

export const LessonUpdateModeCardVideoPartController = (
  props: LessonUpdateModeCardVideoPartControllerProps
) => {
  const { cardIndex, cardPartIndex } = props;

  const {
    videoFile,
    videoUploadState,
    setVideoFile,
    setVideoUploadState,
    handleUploadedVideoState,
    setLoading,
    videoFileInputRef,
  } = useVideoUploadHelpers();

  const { setCardPartsLoading } = useCardPartsLoading();

  const addUploadedFile = useAddUploadedFile();
  const { showToast } = useToastService();
  const { setValue, watch } = useFormContext<ContentModuleUpdateModeInputs>();

  type ContentModuleCardPartWatcher = {
    contentModuleCardPartType: ContentModuleCardPartType;
    uiOrder: number;
    videoUploadedFileId?: number;
    videoUploadedFile?: UploadedFileWithPresignedUrlDTO;
  };

  const contentModuleCardPartWatcher: ContentModuleCardPartWatcher = watch(
    `contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}`
  );

  useEffect(() => {
    if (contentModuleCardPartWatcher.videoUploadedFile) {
      setVideoUploadState({
        state: "Uploaded",
        previewUrl: contentModuleCardPartWatcher.videoUploadedFile.presignedUrl,
      });

      const jsonThumbnailUploadedFile = JSON.stringify(
        contentModuleCardPartWatcher.videoUploadedFile
      );
      const blob = new Blob([jsonThumbnailUploadedFile], {
        type: "application/json",
      });
      setVideoFile(blob);
    }
  }, [
    setVideoUploadState,
    contentModuleCardPartWatcher.videoUploadedFile,
    setVideoFile,
  ]);

  const onUpload = useCallback(async () => {
    try {
      if (videoFile) {
        const createUploadedFileDTO: CreateUploadedFileDTO = {
          feature: "ContentModuleCardPartVideo",
        };

        setLoading(true);
        setCardPartsLoading({
          cardIndex,
          loading: true,
        });
        await addUploadedFile(createUploadedFileDTO, videoFile).then(
          (response) => {
            setValue(
              `contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}.videoUploadedFile`,
              {
                id: response.id,
                presignedUrl: response.presignedUrl,
              }
            );
            setValue(
              `contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}.videoUploadedFileId`,
              response.id
            );
          }
        );
        handleUploadedVideoState(videoFile);
        showToast("Success", "Card Video Uploaded Successfully");
        setLoading(false);
        setCardPartsLoading({
          cardIndex: undefined,
          loading: false,
        });
      }
    } catch (error) {
      showToast("Error", "Failed to Upload Card Video :(");
    }
  }, [
    videoFile,
    setLoading,
    showToast,
    addUploadedFile,
    handleUploadedVideoState,
    setValue,
    cardIndex,
    cardPartIndex,
    setCardPartsLoading,
  ]);

  useEffect(() => {
    if (videoFile && videoUploadState.state === "Upload") {
      onUpload();
    } else if (
      videoUploadState.state === "Upload" &&
      videoUploadState.previewUrl === undefined
    ) {
      setValue(
        `contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}.videoUploadedFileId`,
        undefined
      );
    }
  }, [
    videoFile,
    onUpload,
    videoUploadState,
    setValue,
    cardIndex,
    cardPartIndex,
    videoUploadState.state,
  ]);

  const openUploaderOnMount = useCallback(() => {
    videoFileInputRef.current?.click();
  }, [videoFileInputRef]);

  useEffect(() => {
    if (videoUploadState.state === "Idle") {
      setTimeout(() => {
        openUploaderOnMount();
      }, 300);
    }
  }, [openUploaderOnMount, videoUploadState.state]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="50px" $isOptional>
        Video
      </UXInputLabel>
      <VideoUpload uploadText="Video" />
    </UXInputContainer>
  );
};
