import { Temporal } from "temporal-polyfill";
import { Meal } from "../../build-days-aggregates";
import { GoogleDataTableRow } from "react-google-charts";
import { buildGapRow } from "../build-gap-row";
import { buildMealRow } from "./build-meal-row";

export function buildMealsRows(
  date: Temporal.PlainDate,
  meals: Meal[],
  reverseVAxisTicks: number[]
): GoogleDataTableRow[] {
  const rows: GoogleDataTableRow[] = [];

  for (const meal of meals) {
    const time = meal.time;
    const plainDateTime = date.toPlainDateTime(time);

    rows.push(buildGapRow(plainDateTime, reverseVAxisTicks));
    rows.push(buildMealRow(plainDateTime, reverseVAxisTicks, meal));
  }

  return rows;
}
