import styled from "styled-components/macro";
import {
  mediumFont,
  smallRegularFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const MeasurmentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 115px);
`;

export const Measurment = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0rem 0.5rem;
  margin: 0.5rem 0rem 0rem;

  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }

  span {
    ${smallRegularFont}
    display: flex;
    color: ${({ theme }) => theme.colors.textColor};

    strong {
      ${mediumFont}
      color: ${({ theme }) => theme.colors.titleColor};
      margin-right: 0.3rem;
    }
  }
`;
