import { PatientMealDTO } from "../../../../../../../models/patient-meal-dtos/patient-meal-dto";
import {
  NutritionSummaryCardCenter,
  NutritionSummaryCardCenterList,
  NutritionSummaryCardCenterListItem,
  ToolTip,
  TootlTipText,
} from "../nutrition-summary/styled-nutrition-summary";
import { foodGroups, staticFoodGroups } from "./utils/food-groups-utils";
import {
  NutritionSummaryForDemoTooltipContainer,
  NutritionSummaryForDemoTooltipIcon,
} from "../nutrition-summary-for-demo/nutrition-summary-for-demo-tooltip/styled-nutrition-summary-for-demo-tooltip";
import { roundTo1DecimalPlace } from "../../../../../../../utils/math-utils";

type NutritionSummaryFromMealsCenterProps = {
  patientMealDTOs: PatientMealDTO[];
};

export const NutritionSummaryFromMealsCenter = (
  props: NutritionSummaryFromMealsCenterProps
) => {
  const { patientMealDTOs } = props;

  const nutritionSummaryServings = foodGroups(patientMealDTOs);

  const categoriesWithServings = nutritionSummaryServings.map(
    (nutritionSummaryServing) => (
      <NutritionSummaryCardCenterListItem key={nutritionSummaryServing.id}>
        <span>{roundTo1DecimalPlace(nutritionSummaryServing.value)}</span>
        <p>
          {nutritionSummaryServing.title === "Caffeinated Beverages"
            ? "Caffeinated Bev."
            : nutritionSummaryServing.title}
        </p>
        {(nutritionSummaryServing.title === "Meats" ||
          nutritionSummaryServing.title === "Dairy" ||
          nutritionSummaryServing.title === "Oils & Fats") && (
          <NutritionSummaryForDemoTooltipContainer>
            <NutritionSummaryForDemoTooltipIcon className="material-symbols-outlined">
              info
            </NutritionSummaryForDemoTooltipIcon>
            <ToolTip>
              <p>{nutritionSummaryServing.tooltip?.tooltipTitle}</p>
              {nutritionSummaryServing.tooltip?.items.map((item, index) => (
                <TootlTipText key={index}>
                  <p>
                    {item.title.includes("Oils")
                      ? item.title.slice(15, item.title.length).replace(")", "")
                      : item.title}
                  </p>
                  <span>{roundTo1DecimalPlace(item.value)}</span>
                </TootlTipText>
              ))}
            </ToolTip>
          </NutritionSummaryForDemoTooltipContainer>
        )}
      </NutritionSummaryCardCenterListItem>
    )
  );

  const left =
    categoriesWithServings.length > 0
      ? categoriesWithServings.slice(0, 4)
      : staticFoodGroups.slice(0, 4).map((item) => (
          <NutritionSummaryCardCenterListItem key={item.id}>
            <span>{item.value}</span>
            <p>{item.title}</p>
          </NutritionSummaryCardCenterListItem>
        ));
  const right =
    categoriesWithServings.length > 0
      ? categoriesWithServings.slice(4, 8)
      : staticFoodGroups.slice(4, 8).map((item) => (
          <NutritionSummaryCardCenterListItem key={item.id}>
            <span>{item.value}</span>
            <p>{item.title}</p>
          </NutritionSummaryCardCenterListItem>
        ));

  return (
    <NutritionSummaryCardCenter>
      <NutritionSummaryCardCenterList>
        <div>{left}</div>
        <div>{right}</div>
      </NutritionSummaryCardCenterList>
    </NutritionSummaryCardCenter>
  );
};
