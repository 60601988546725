import { useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContent } from "../../context/loadable-content-context";
import { ContentCoursesTableHeadings } from "./content-courses-table-headings";
import { ContentCoursesTableRows } from "./content-courses-table-rows/content-courses-table-rows";
import { TableHeader } from "../../../../components/table/table-header/table-header";
import { Table } from "../../../../components/table/table";
import { TablePaginationContainer } from "../../../../components/table/styled-table";
import { Pagination } from "../../../../components/pagination/pagination";
import { Button } from "../../../../styles/classes/reusable-classes";
import { ContentScreenTableContainer } from "../../styles/content-reusable-classes";

export const ContentCoursesTable = () => {
  const { contentModuleDTOs } = useContent();

  const [searchTerm, setSearchTerm] = useState("");
  const [tableRowsPerPage, setTableRowsPerPage] = useState(8);

  const { coursesPage } = useParams();
  const navigate = useNavigate();

  const sorted = useMemo(
    () => contentModuleDTOs.sort((a, b) => a.name.localeCompare(b.name)),
    [contentModuleDTOs]
  );

  const filtered = useMemo(
    () =>
      sorted.filter((item) => {
        if (searchTerm === "") {
          return item;
        } else if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        } else {
          return 0;
        }
      }),
    [sorted, searchTerm]
  );

  const maxPage = Math.ceil(filtered.length / tableRowsPerPage);

  const currentOrMaxPage = Math.min(Number(coursesPage), maxPage);

  const currentPageCourses = useMemo(() => {
    const indexOfLastEntry = currentOrMaxPage * tableRowsPerPage;
    const indexOfFirstEntry = indexOfLastEntry - tableRowsPerPage;

    return filtered.slice(indexOfFirstEntry, indexOfLastEntry);
  }, [filtered, tableRowsPerPage, currentOrMaxPage]);

  function onSearchTermChange(searchTerm: string) {
    navigate(`../courses/1`);
    setSearchTerm(searchTerm);
  }

  return (
    <ContentScreenTableContainer $childTable={false}>
      <TableHeader
        title="All Courses"
        searchTerm={searchTerm}
        setSearchTerm={onSearchTermChange}
        boldTitle
        button={
          <Link to={`../courses/create-new-course`}>
            <Button flex type="button">
              <span className="material-symbols-outlined">add</span>
              Add Course
            </Button>
          </Link>
        }
      />
      <Table
        tableHeadings={<ContentCoursesTableHeadings />}
        tableRows={
          <ContentCoursesTableRows currentPageCourses={currentPageCourses} />
        }
      />
      <TablePaginationContainer>
        <Pagination
          totalRowsCount={filtered.length}
          tableRowsPerPage={tableRowsPerPage}
          setRowsPerPage={setTableRowsPerPage}
          navigateBasePath={"/courses"}
          pageVariable={coursesPage}
        />
      </TablePaginationContainer>
    </ContentScreenTableContainer>
  );
};
