import { useFoodLoggingData } from "../context/food-logging-data-context";
import { usePatientTodoMealsCountsUpdates } from "../context/patient-todo-meals-counts-updates-context";
import { getPatientTodoMealsCount } from "../util/get-patient-todo-meals-count";
import {
  FoodLoggingSelectedPatientBackIcon,
  FoodLoggingSelectedPatientContainer,
  FoodLoggingSelectedPatientIcon,
  FoodLoggingSelectedPatientText,
} from "./styled-food-logging-selected-patient";

export type FoodLoggingSelectedPatientProps = {
  handleHideMealsMenu: () => void;
};

export function FoodLoggingSelectedPatient(
  props: FoodLoggingSelectedPatientProps
) {
  const { handleHideMealsMenu } = props;

  const { selectedPatientDTO } = useFoodLoggingData();

  const {
    todoMealsCountsUpdatesByPatientId,
  } = usePatientTodoMealsCountsUpdates();

  return (
    <FoodLoggingSelectedPatientContainer>
      <FoodLoggingSelectedPatientBackIcon
        className="material-symbols-outlined"
        onClick={handleHideMealsMenu}
      >
        west
      </FoodLoggingSelectedPatientBackIcon>
      <FoodLoggingSelectedPatientIcon className="material-symbols-outlined">
        person
      </FoodLoggingSelectedPatientIcon>
      <FoodLoggingSelectedPatientText>
        <p>{selectedPatientDTO?.name}</p>
        <span>
          {getPatientTodoMealsCount(
            selectedPatientDTO!,
            todoMealsCountsUpdatesByPatientId
          )}{" "}
          todo meals
        </span>
      </FoodLoggingSelectedPatientText>
    </FoodLoggingSelectedPatientContainer>
  );
}
