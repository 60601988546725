import { PATIENTS_PATH } from "../constants/paths";
import { useClient } from "../context/client-context";
import { useCallback } from "react";

export function useImportCronometerServings(): (
  patientId: number,
  cronometerServingsCSV: string
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientId, cronometerServingsCSV) => {
      await client.post<void>(
        `${PATIENTS_PATH}/${patientId}/import-cronometer-servings`,
        cronometerServingsCSV,
        { headers: { "Content-Type": "text/plain" } }
      );
    },
    [client]
  );
}
