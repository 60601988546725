import { BGLAnalysisHeaderContainer } from "../../styled-bgl-analysis";
import { ManualReadingsLegend } from "../manual-readings-filters/manual-readings-legend/manual-readings-graph-legend";
import { AddManualReadings } from "./add-manual-readings";

type ManualReadingsHeaderProps = { isEmpty: boolean };

export const ManualReadingsHeader = (props: ManualReadingsHeaderProps) => {
  const { isEmpty } = props;

  return (
    <BGLAnalysisHeaderContainer>
      <p>Manual Readings</p>
      <ManualReadingsLegend />
      {!isEmpty && <AddManualReadings />}
    </BGLAnalysisHeaderContainer>
  );
};
