import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { PateintProfileColumnHeader } from "../../components/pateint-profile-column-header/pateint-profile-column-header";
import { usePatientProfile } from "../../context/loadable-patient-profile-context";
import {
  PatientProfileContainer,
  PatientProfileContainerBody,
} from "../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileBloodPressureChart } from "./patient-profile-blood-pressure-chart/patient-profile-blood-pressure-chart";
import { PatientProfileBloodPressureHeader } from "./patient-profile-blood-pressure-header/patient-profile-blood-pressure-header";

export const PatientProfileBloodPressure = () => {
  const { patientBloodPressureDTOs } = usePatientProfile();

  return (
    <PatientProfileContainer>
      <PateintProfileColumnHeader
        img="/img/plans/nav/life-style.svg"
        title="Blood Pressure"
        button={
          patientBloodPressureDTOs.length > 0 ? (
            <PatientProfileBloodPressureHeader
              showPicker={patientBloodPressureDTOs.length > 0}
            />
          ) : (
            <></>
          )
        }
      />
      <PatientProfileContainerBody>
        {patientBloodPressureDTOs.length > 0 ? (
          <PatientProfileBloodPressureChart />
        ) : (
          <EmptyState
            button={
              <PatientProfileBloodPressureHeader
                showPicker={patientBloodPressureDTOs.length > 0}
              />
            }
            hasHeader={false}
            hasSearch={false}
            text="There are no data in Blood pressure"
            title=""
            subText=""
          />
        )}
      </PatientProfileContainerBody>
    </PatientProfileContainer>
  );
};
