import { PatientType } from "../../models/patient-dtos/patient-type";

const USER_LOCALSTORAGE_KEY = "user";

/**
 * { credential: string } => Google sign-in for admins/medical team
 * { token: string; patientId: number; name: string } => Patient sign-in
 */
export type LocalStorageUser =
  | { credential: string }
  | {
      patientPhoneNumber: string;
      patientToken: string;
      patientId: number;
      patientName: string;
      patientType: PatientType;
    };

export function getUserFromLocalStorage(): LocalStorageUser | undefined {
  const user = localStorage.getItem("user");
  return user !== null ? JSON.parse(user) : undefined;
}

export function setUserToLocalStorage(user: LocalStorageUser): void {
  localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(user));
}

export function removeUserFromLocalStorage(): void {
  localStorage.removeItem(USER_LOCALSTORAGE_KEY);
}
