import { Reading } from "../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { PatientType } from "../../../../models/patient-dtos/patient-type";
import { roundTo1DecimalPlace } from "../../../math-utils";
import { buildDaysAggregatesReadingsWithTimeDifference } from "../../build-readings-with-time-differance-value";
import { calculateReadingsTimeDiffrenceTotal } from "../../calculate-readings-time-differance-total";
import { calculateAboveRangeReadingsTimeDiffrenceTotal } from "../calculate-above-range-readings-time-differance-total";

export function calculateAboveRangePercentage(
  patientType: PatientType,
  readings: Reading[]
) {
  const readingsWithTimeDifferenceValue = buildDaysAggregatesReadingsWithTimeDifference(
    readings
  );

  const aboveRangeReadingsTimeDiffrenceTotal = calculateAboveRangeReadingsTimeDiffrenceTotal(
    patientType,
    readingsWithTimeDifferenceValue
  );
  const readingsTimeDifferanceTotal = calculateReadingsTimeDiffrenceTotal(
    readingsWithTimeDifferenceValue
  );

  const aboveRangePercentage = roundTo1DecimalPlace(
    (aboveRangeReadingsTimeDiffrenceTotal / readingsTimeDifferanceTotal) * 100
  );

  return aboveRangePercentage;
}
