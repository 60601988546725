import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PutActiveNutritionRegimenDTO } from "../../models/patient-nutrition-regimen-dtos/put-active-nutrition-regimen-dto";
import { PatientDTO } from "../../models/patient-dtos/patient-dto";

export function usePutActiveNutritionRegimen(): (
  patientDTO: PatientDTO,
  PutActiveNutritionRegimenDTO: PutActiveNutritionRegimenDTO
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientDTO, PutActiveNutritionRegimenDTO) => {
      await client.put<void>(
        `${PATIENTS_PATH}/${patientDTO.id}/active-nutrition-regimen`,
        PutActiveNutritionRegimenDTO
      );
    },
    [client]
  );
}
