import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Temporal } from "temporal-polyfill";
import { useToastService } from "../../../../../context/toast-service-context";
import { useAddPatientPrescription } from "../../../../../hooks/patient-prescriptions-hooks/use-add-patient-prescription";
import { CreatePatientPrescriptionDTO } from "../../../../../models/patient-prescriptions/create-patient-prescription-dto";
import { ActivePrescriptionItemProvider } from "../context/active-prescription-context";
import { usePrescriptions } from "../context/prescriptions-context";
import { AddedPrescriptionMedicines } from "./added-prescription-medicines/added-prescription-medicines";
import { PrescriptionFormInputs } from "./new-prescription";
import { NewPrescriptionBody } from "./new-prescription-body/new-prescription-body";
import { NewPrescriptionHeader } from "./new-prescription-header/new-prescription-header";

export const NewPrescriptionForm = () => {
  const addPatientPrescription = useAddPatientPrescription();
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToastService();

  const { prescriptionMedicines } = usePrescriptions();

  const { handleSubmit } = useFormContext<PrescriptionFormInputs>();

  const onSubmit = handleSubmit(async () => {
    const createPrescriptionDTO: CreatePatientPrescriptionDTO = {
      date: Temporal.Now.plainDateISO().toString(),
      medications: prescriptionMedicines,
    };

    try {
      await addPatientPrescription(parseInt(id!), createPrescriptionDTO);

      showToast("Success", "Prescription added successfully");
      navigate(`../medications/prescriptions`);
    } catch (error) {
      showToast("Error", "Failed to add Prescription :(");
    }
  });

  return (
    <ActivePrescriptionItemProvider>
      <form onSubmit={onSubmit}>
        <NewPrescriptionHeader />
        <AddedPrescriptionMedicines />
        <NewPrescriptionBody />
      </form>
    </ActivePrescriptionItemProvider>
  );
};
