import { useImmediateAttentionPatientsSidebar } from "../../context/immediate-attention-patients-sidebar-context";
import {
  ImmediateAttentionPatientsSidebarPatientBodyMeasurmentsContainer,
  ImmediateAttentionPatientsSidebarPatientBodyMeasurmentsImageContainer,
  ImmediateAttentionPatientsSidebarPatientBodyMeasurmentsTextContainer,
} from "./styled-immediate-attention-patients-sidebar-patient-body-measurments";

export const ImmediateAttentionPatientsSidebarPatientBodyMeasurments = () => {
  const {
    immediateAttentionPatientsSidebarPatientData,
  } = useImmediateAttentionPatientsSidebar();

  const patientBodyMeasurments =
    immediateAttentionPatientsSidebarPatientData.bodyMeasurments;

  return (
    <ImmediateAttentionPatientsSidebarPatientBodyMeasurmentsContainer>
      <p>Body Measurments</p>
      <ul>
        {patientBodyMeasurments.map((measurement, index) => (
          <li key={index}>
            <ImmediateAttentionPatientsSidebarPatientBodyMeasurmentsImageContainer>
              <img src={measurement.img} alt={measurement.title} />
            </ImmediateAttentionPatientsSidebarPatientBodyMeasurmentsImageContainer>
            <ImmediateAttentionPatientsSidebarPatientBodyMeasurmentsTextContainer>
              <strong>{measurement.title}</strong>
              <span>
                {measurement.value} <span>{measurement.unit}</span>
              </span>
            </ImmediateAttentionPatientsSidebarPatientBodyMeasurmentsTextContainer>
          </li>
        ))}
      </ul>
    </ImmediateAttentionPatientsSidebarPatientBodyMeasurmentsContainer>
  );
};
