import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  flexCenterSpaceBetween,
  mediumRegularFont,
  regularMediumFont,
} from "../../../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../../../styles/global-style";

export const SinglePlanDropdownContainer = styled.div<{
  showDropdown: boolean;
}>`
  width: 100%;
  background: ${({ theme }) => theme.colors.containerColor};
  ${flexCenterSpaceBetween}
  flex-direction: row-reverse;
  padding: 12px 16px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${regularMediumFont}

  div:first-child {
    ${flexCenter}
    flex-direction: row-reverse;
    gap: 0rem 1rem;
  }

  .material-symbols-outlined {
    transition: ${transition};
    transform: ${({ showDropdown }) =>
      showDropdown ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

export const SinglePlanDropdownButtonIcon = styled.div`
  background: ${({ theme }) => theme.newColors.containerColorAlt};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  width: 40px;
  height: 40px;
  border-radius: 8px;
  ${flexCenterCenter}

  img {
    width: 50%;
    height: 50%;
  }
`;

export const SinglePlanDropdownButtonText = styled.div`
  text-align: right;

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px 0px 4px;
  }

  span {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const SinglePlanDropdownList = styled.ul<{
  showDropdown: boolean;
}>`
  margin: 0;
  background: ${({ theme }) => theme.colors.containerColor};
  padding: ${({ showDropdown }) => (showDropdown ? "12px 16px 36px" : "0px")};
  height: ${({ showDropdown }) => (showDropdown ? "auto" : "0px")};
  overflow-y: ${({ showDropdown }) => (showDropdown ? "auto" : "hidden")};
  overflow-x: hidden;
  transition: ${({ showDropdown }) => (showDropdown ? transition : "")};
`;

export const SinglePlanDropdownListItem = styled.li<{
  viewPlanGraphs?: boolean;
}>`
  ${({ viewPlanGraphs, theme }) =>
    viewPlanGraphs
      ? ``
      : `
      :first-child {
        border-radius: 16px;
        background: ${theme.newColors.imageBackdrop};
        ${flexCenterCenter}
        height: 150px;

        img {
          width: 181px;
          height: 140px;
        }
      }`};

  ${flexCenter}
  flex-direction: row-reverse;
  gap: 0rem 1rem;
  margin: 16px 0px;
  border: ${({ viewPlanGraphs, theme }) =>
    viewPlanGraphs ? "0px" : `1px solid ${theme.colors.borderColor}`};
  border-bottom: ${({ theme, viewPlanGraphs }) =>
    viewPlanGraphs ? `1px solid ${theme.colors.borderColor}` : ``};
  border-radius: 8px;
  padding: ${({ viewPlanGraphs }) => (viewPlanGraphs ? "0px" : "12px 16px")};
`;
