import { Controller, useForm } from "react-hook-form";
import { ModalForm } from "../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../styles/classes/reusable-classes";
import { usePlansGoals } from "../../../context/goals-context";
import { AddGoalModalTextAreaContainer } from "../../styles";

type AddLifestyleModalBodyProps = {
  onClose: () => void;
};

type LifestyleFormInput = {
  lifestyleGoal?: string;
};

export const AddLifestyleModalBody = (props: AddLifestyleModalBodyProps) => {
  const { onClose } = props;

  const { handleAddGoal } = usePlansGoals();
  const { handleSubmit, control, watch } = useForm<LifestyleFormInput>({
    defaultValues: {
      lifestyleGoal: undefined,
    },
  });

  const lifestyleGoal = watch("lifestyleGoal");

  const onSubmit = handleSubmit(async () => {
    handleAddGoal({
      goal: lifestyleGoal !== undefined ? lifestyleGoal : "",
      goalType: "LifestyleInstruction",
    });

    onClose();
  });

  return (
    <ModalForm width={670} height={340} onSubmit={onSubmit}>
      <ModalHeader
        title="Add a life style item"
        onModalClose={onClose}
        subTitle="Add Lifestyle item for your patient"
      />

      <Controller
        name="lifestyleGoal"
        control={control}
        render={({ field: { value, onChange } }) => (
          <AddGoalModalTextAreaContainer>
            <textarea
              placeholder="Write here..."
              value={value === undefined ? "" : value}
              onChange={(e) =>
                onChange(e.target.value === undefined ? "" : e.target.value)
              }
            ></textarea>
          </AddGoalModalTextAreaContainer>
        )}
      />

      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={lifestyleGoal === "" || lifestyleGoal === undefined}
        >
          Yes, Add
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
