import { CreateContentModuleCardPartDTO } from "../../../../../../models/content-module-dtos/create-content-module-card-part-dto";
import { ContentModuleLessonMobilePreviewCardPartsListItem } from "../../mobile-preview-lesson-cards-styles";

type CreateContentModuleLessonMobilePreviewCardPartsProps = {
  cardParts: CreateContentModuleCardPartDTO[];
};

export const CreateContentModuleLessonMobilePreviewCardParts = (
  props: CreateContentModuleLessonMobilePreviewCardPartsProps
) => {
  const { cardParts } = props;

  return (
    <ul>
      {cardParts.map((cardPart, index) => (
        <ContentModuleLessonMobilePreviewCardPartsListItem key={index}>
          {cardPart.contentModuleCardPartType === "Text" && (
            <p dir="auto">{cardPart.text}</p>
          )}
          {cardPart.contentModuleCardPartType === "Image" && (
            <img src={cardPart.imageUploadedFileURL} alt="" />
          )}
          {cardPart.contentModuleCardPartType === "Video" && (
            <video src={cardPart.videoUploadedFileURL} controls></video>
          )}
        </ContentModuleLessonMobilePreviewCardPartsListItem>
      ))}
    </ul>
  );
};
