import { MessageResponse } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { useEffect, useMemo, useState } from "react";
import { useChatContext } from "stream-chat-react";
import { DefaultStreamChatMessage } from "../../../../custom-message/default-stream-chat-message";
import { MediaSidebarMediaListContainer } from "./media-sidebar-media-list/styled-media-sidebar-media-list";
import { EmptyState } from "../../../../../../../../components/empty-state/empty-state";

export const MediaSidebarDocsList = () => {
  const { client, channel } = useChatContext();

  const [messages, setMessages] = useState<
    MessageResponse<DefaultStreamChatGenerics>[]
  >([]);

  useEffect(() => {
    const getMessages = async () => {
      const channelState = await client
        .channel("messaging", channel?.id)
        .query({});

      setMessages(channelState.messages);
    };

    getMessages();
  }, [channel?.id, client]);

  const { messagesWithDocsAttachments } = useMemo(() => {
    const messagesWithDocsAttachments: MessageResponse<DefaultStreamChatGenerics>[] =
      [];

    for (const message of messages) {
      if (message.attachments) {
        const attachments = message.attachments.flat();

        for (const attachment of attachments) {
          if (attachment.type === "file") {
            messagesWithDocsAttachments.push(message);
          }
        }
      }
    }

    return { messagesWithDocsAttachments };
  }, [messages]);

  if (messagesWithDocsAttachments.length === 0) {
    return (
      <EmptyState
        hasSearch={false}
        text="No Docs Yet"
        title=""
        hasHeader={false}
        button={<></>}
      />
    );
  }

  return (
    <MediaSidebarMediaListContainer>
      {messagesWithDocsAttachments.map((message) => (
        <DefaultStreamChatMessage
          key={message.id}
          message={message}
          messageActions={[]}
          hidePinnedIcon
        />
      ))}
    </MediaSidebarMediaListContainer>
  );
};
