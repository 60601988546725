import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Temporal } from "temporal-polyfill";
import { ModalForm } from "../../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../../components/modal/modal-header/modal-header";
import {
  TDInput,
  TH,
} from "../../../../../../../../components/table/styled-table";
import { Table } from "../../../../../../../../components/table/table";
import { useToastService } from "../../../../../../../../context/toast-service-context";
import { useBulkUpdatePatientBloodPressures } from "../../../../../../../../hooks/patient-blood-pressures-hooks/use-bulk-update-patient-blood-pressures";
import {
  Button,
  ModalFooter,
} from "../../../../../../../../styles/classes/reusable-classes";
import {
  BloodPressureModalBodyContainer,
  BloodPressureModalBodyHeader,
  BloodPressureModalBodyTableContainer,
} from "./styled-blood-pressure-modal-body";
import {
  useReloadSinglePatientSidebar,
  useSinglePatientSidebar,
} from "../../../../../context/loadable-single-patient-sidebar-context";

type BloodPressuresInputs = {
  bloodPressures: {
    date: string;
    systolic: number;
    diastolic: number;
  }[];
};

type BloodPressureModalBodyProps = {
  onClose: () => void;
};

export const BloodPressureModalBody = (props: BloodPressureModalBodyProps) => {
  const { onClose } = props;

  const { id } = useParams();
  const today = Temporal.Now.plainDateISO();
  const { showToast } = useToastService();
  const reloadSinglePatientSidebar = useReloadSinglePatientSidebar();
  const { patientBloodPressureDTOs } = useSinglePatientSidebar();
  const bulkUpdatePatientBloodPressures = useBulkUpdatePatientBloodPressures();

  const { control, handleSubmit, register } = useForm<BloodPressuresInputs>({
    defaultValues: {
      bloodPressures: patientBloodPressureDTOs,
    },
  });

  const { append, fields, remove } = useFieldArray({
    name: "bloodPressures",
    control,
  } as never);

  const onSubmit = handleSubmit(async (inputs) => {
    try {
      await bulkUpdatePatientBloodPressures(parseInt(id!), inputs);
      showToast("Success", "Blood pressure readings updated successfully!");
      onClose();
      reloadSinglePatientSidebar();
    } catch (error) {
      showToast("Error", "Failed to update Blood pressure readings :(");
    }
  });

  const headings = ["Date", "Systolic", "Diastolic", "Remove"].map(
    (heading) => (
      <TH key={heading} modalTableHead actions={heading === "Remove"}>
        <div>{heading}</div>
      </TH>
    )
  );

  const bloodPressuresData = fields.map((field, index) => (
    <tr key={field.id}>
      <td>
        <TDInput
          type="date"
          defaultValue={today.toString()}
          {...register(`bloodPressures.${index}.date`)}
        />
      </td>
      <td>
        <TDInput
          type="number"
          {...register(`bloodPressures.${index}.systolic`)}
        />
      </td>
      <td>
        <TDInput
          type="number"
          {...register(`bloodPressures.${index}.diastolic`)}
        />
      </td>
      <td>
        <span
          title="Delete"
          className="material-symbols-outlined cursor-pointer"
          onClick={() => remove(index)}
        >
          delete
        </span>
      </td>
    </tr>
  ));

  return (
    <ModalForm width={900} height={650} onSubmit={onSubmit}>
      <ModalHeader
        title="Blood Pressure"
        subTitle="Blood Pressure with all readings"
        onModalClose={onClose}
      />
      <BloodPressureModalBodyContainer>
        <BloodPressureModalBodyHeader>
          <p>Readings</p>
          <Button
            type="button"
            flex
            onClick={() =>
              append({
                date: today.toString(),
                systolic: 0,
                diastolic: 0,
              })
            }
          >
            <span className="material-symbols-outlined">
              add_circle_outline
            </span>
            Add a reading
          </Button>
        </BloodPressureModalBodyHeader>
        <BloodPressureModalBodyTableContainer>
          <Table
            tableHeadings={headings}
            tableRows={bloodPressuresData}
            modalTable
          />
        </BloodPressureModalBodyTableContainer>
      </BloodPressureModalBodyContainer>
      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Close
        </Button>
        <Button type="submit">Confirm</Button>
      </ModalFooter>
    </ModalForm>
  );
};
