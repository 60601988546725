import { useMemo } from "react";
import { MedicationCard } from "../../../medications/components/medication-card/medication-card";
import { sortMedicationsByName } from "../../../medications/utils/medications-utils";
import { PlanStepsHeader } from "../../components/plan-steps-header/plan-steps-header";
import {
  MedicalListContainer,
  MedicalListLI,
  MedicalListUL,
} from "../medical/styles";
import { ReviewCard } from "./styled-review";
import { useGetPatientMedicationDTOs } from "../../hooks/use-get-patient-medication-dtos";
import { useDetailedPlan } from "../../context/loadable-detailed-plan-context";

export const ReviewMedical = () => {
  const { patientDTO } = useDetailedPlan();

  const { patientMedicationDTOs } = useGetPatientMedicationDTOs(patientDTO);

  const filteredPatientMedicationDTOs = useMemo(
    () =>
      sortMedicationsByName(
        patientMedicationDTOs.filter(
          (item) => item.discontinuedAt === undefined
        )
      ),
    [patientMedicationDTOs]
  );

  const medical = filteredPatientMedicationDTOs.length > 0 && (
    <ReviewCard>
      <PlanStepsHeader
        title="Medical"
        subTitle="Medial items that doctor write for the patient"
        icon="/img/plans/nav/medical.svg"
        button={<></>}
      />
      <MedicalListContainer>
        <MedicalListUL currentMedicationsList>
          {filteredPatientMedicationDTOs.map((medication) => (
            <MedicalListLI key={medication.id}>
              <MedicationCard patientMedicationDTO={medication} img={true} />
            </MedicalListLI>
          ))}
        </MedicalListUL>
      </MedicalListContainer>
    </ReviewCard>
  );

  return <>{medical}</>;
};
