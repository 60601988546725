type GetTagBackEndForm =
  | "fasting"
  | "beforeMeal"
  | "afterMeal"
  | "bedtime"
  | "beforeActivity"
  | "afterActivity"
  | undefined;

type GetTagFrontEndForm =
  | "Fasting"
  | "Before Meal"
  | "After Meal"
  | "Bedtime"
  | "Before Activity"
  | "After Activity";

export function getTag(tag: string): GetTagBackEndForm | GetTagFrontEndForm {
  if (tag === "fasting") {
    return "Fasting";
  } else if (tag === "beforeMeal") {
    return "Before Meal";
  } else if (tag === "afterMeal") {
    return "After Meal";
  } else if (tag === "bedtime") {
    return "Bedtime";
  } else if (tag === "beforeActivity") {
    return "Before Activity";
  } else if (tag === "afterActivity") {
    return "After Activity";
  }

  if (tag === "Fasting") {
    return "fasting";
  } else if (tag === "Before Meal") {
    return "beforeMeal";
  } else if (tag === "After Meal") {
    return "afterMeal";
  } else if (tag === "Bedtime") {
    return "bedtime";
  } else if (tag === "Before Activity") {
    return "beforeActivity";
  } else if (tag === "After Activity") {
    return "afterActivity";
  } else {
    return undefined;
  }
}

type GetAfterMealTagBackEndForm =
  | "immediately"
  | "withinHour"
  | "hours2"
  | "hours3"
  | "hours6"
  | undefined;

type GetAfterMealTagFrontEndForm =
  | "Immediately"
  | "Within an Hour"
  | "2 Hours"
  | "3 Hours"
  | "6 Hours";

export function getAfterMealTag(
  tag: string
): GetAfterMealTagBackEndForm | GetAfterMealTagFrontEndForm {
  if (tag === "immediately") {
    return "Immediately";
  } else if (tag === "withinHour") {
    return "Within an Hour";
  } else if (tag === "hours2") {
    return "2 Hours";
  } else if (tag === "hours3") {
    return "3 Hours";
  } else if (tag === "hours6") {
    return "6 Hours";
  }

  if (tag === "Immediately") {
    return "immediately";
  } else if (tag === "Within an Hour") {
    return "withinHour";
  } else if (tag === "2 Hours") {
    return "hours2";
  } else if (tag === "3 Hours") {
    return "hours3";
  } else if (tag === "6 Hours") {
    return "hours6";
  } else {
    return undefined;
  }
}

export type DropdownTag = {
  id: number;
  tagName: string;
  nested: boolean;
  nestedTags?:
    | {
        id: number;
        tagName: string;
      }[]
    | undefined;
};

export const buildNestedDropdownTags = (): DropdownTag[] => {
  const nestedDropdownTags: DropdownTag[] = [
    {
      id: 0,
      tagName: "Fasting",
      nested: false,
    },
    {
      id: 1,
      tagName: "Before Meal",
      nested: false,
    },
    {
      id: 2,
      tagName: "Bedtime",
      nested: false,
    },
    {
      id: 3,
      tagName: "Before Activity",
      nested: false,
    },
    {
      id: 4,
      tagName: "After Activity",
      nested: false,
    },
    {
      id: 5,
      tagName: "After Meal",
      nested: true,
      nestedTags: [
        { id: 2.1, tagName: "Immediately" },
        { id: 2.2, tagName: "Within an Hour" },
        { id: 2.3, tagName: "2 Hours" },
        { id: 2.4, tagName: "3 Hours" },
        { id: 2.5, tagName: "6 Hours" },
      ],
    },
  ];

  return nestedDropdownTags;
};
