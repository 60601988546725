import { useCallback, useMemo, useRef } from "react";
import { randomUUID } from "../../../../../../../utils/random-uuid";
import {
  ChartComponent,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineRangeAreaSeries,
  SplineSeries,
  StripLine,
  DateTime,
} from "@syncfusion/ej2-react-charts";
import { buildAxisLabelRender } from "./utils/build-axis-label-render";
import { getInRangeNumbers } from "../../../../../../../utils/patient-type-utils";
import { useCGMReportLoadedResponse } from "../../../context/cgm-report-loaded-response-context";
import { useAGPLoadedResponse } from "../../../context/agp-loaded-response-context";
import { buildPrimaryXAxis } from "./utils/build-primary-x-axis";
import { buildPrimaryYAxis } from "./utils/build-primary-y-axis";
import { buildAgpSeries95th5th } from "./utils/build-agp-series-95th-5th";
import { buildAgpSeries50th } from "./utils/build-agp-series-50th";
import { buildAgpSeries75th25th } from "./utils/build-agp-series-75th-25th";
import { CGMReportResponseAGPGraphContainer } from "./styled-cgm-report-response-agp-graph";

export const CGMReportResponseAGPGraph = () => {
  const { patientDTO } = useCGMReportLoadedResponse();
  const {
    percentile5,
    percentile25,
    percentile50,
    percentile75,
    percentile95,
  } = useAGPLoadedResponse();

  const chartRef = useRef<ChartComponent | null>(null);

  const id = useMemo(() => `agp-syncfusion-chart-${randomUUID()}`, []);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);

    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const maxY = 350;

  const inRangeNumbers = useMemo(() => getInRangeNumbers(patientDTO.type), [
    patientDTO,
  ]);

  const vAxisTicks = useMemo(() => {
    const ticks = [0, 54, inRangeNumbers[0], inRangeNumbers[1], 250, maxY];

    return ticks;
  }, [inRangeNumbers, maxY]);

  const primaryxAxis = useMemo(
    () =>
      buildPrimaryXAxis(
        new Date(`2024-01-01T00:00:00`),
        new Date(`2024-01-02T00:00:00`)
      ),
    []
  );

  const primaryyAxis = useMemo(
    () => buildPrimaryYAxis(0, maxY, inRangeNumbers),
    [maxY, inRangeNumbers]
  );

  const axisLabelRender = useMemo(() => buildAxisLabelRender(vAxisTicks), [
    vAxisTicks,
  ]);

  const agpSeries95th5th = useMemo(
    () => buildAgpSeries95th5th(percentile5, percentile95),
    [percentile5, percentile95]
  );

  const agpSeries75th25th = useMemo(
    () => buildAgpSeries75th25th(percentile25, percentile75),
    [percentile25, percentile75]
  );

  const agpSeries50th = useMemo(() => buildAgpSeries50th(percentile50), [
    percentile50,
  ]);

  return (
    <CGMReportResponseAGPGraphContainer>
      <ChartComponent
        id={id}
        primaryXAxis={primaryxAxis}
        primaryYAxis={primaryyAxis}
        title="Ambulatory glucose profile (AGP)"
        axisLabelRender={axisLabelRender}
        ref={chartRef}
        loaded={handleChartLoaded}
        height="515px"
        width="100%"
      >
        <Inject
          services={[SplineRangeAreaSeries, StripLine, SplineSeries, DateTime]}
        />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={agpSeries95th5th}
            xName="x"
            high="high95th"
            low="low5th"
            opacity={0.4}
            type="SplineRangeArea"
            fill="#418AC0"
            dashArray="5,5"
            border={{
              color: "#ACB1C0",
              width: 4,
            }}
          ></SeriesDirective>
          <SeriesDirective
            dataSource={agpSeries75th25th}
            xName="x"
            high="high75th"
            low="low25th"
            opacity={1}
            fill="#85A6D0"
            type="SplineRangeArea"
            border={{
              color: "transparent",
              width: 0,
            }}
          ></SeriesDirective>
          <SeriesDirective
            dataSource={agpSeries50th}
            xName="x"
            yName="point50th"
            opacity={1}
            type="Spline"
            width={3}
            fill="#000"
          ></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    </CGMReportResponseAGPGraphContainer>
  );
};
