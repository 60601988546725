import { Link, useParams } from "react-router-dom";
import { Temporal } from "temporal-polyfill";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { SidebarIcon } from "../../../../single-patient-header/sidebar-icon/sidebar-icon";
import { useActivePrescriptionItem } from "../../context/active-prescription-context";
import { usePrescriptions } from "../../context/prescriptions-context";
import {
  NewPrescriptionHeaderButtonsContainer,
  NewPrescriptionHeaderContainer,
  NewPrescriptionTitleContainer,
} from "./styled-new-prescription-header";

export const NewPrescriptionHeader = () => {
  const { id } = useParams();
  const { prescriptionMedicines } = usePrescriptions();
  const { handleResetActivePrescriptionItem } = useActivePrescriptionItem();

  return (
    <NewPrescriptionHeaderContainer>
      <NewPrescriptionTitleContainer>
        <Link to={`/patients/${id}/medications/prescriptions`}>
          <span className="material-symbols-outlined">west</span>
        </Link>
        <p>
          Create new prescriptions
          <span>
            {Temporal.Now.plainDateISO().toLocaleString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </span>
        </p>
      </NewPrescriptionTitleContainer>
      <NewPrescriptionHeaderButtonsContainer>
        <Button
          flex
          type="submit"
          disabled={prescriptionMedicines.length === 0}
          onClick={handleResetActivePrescriptionItem}
        >
          <span
            data-testid="dropdown-icon"
            className="material-symbols-outlined"
          >
            check_circle
          </span>
          Finish
        </Button>
        <SidebarIcon />
      </NewPrescriptionHeaderButtonsContainer>
    </NewPrescriptionHeaderContainer>
  );
};
