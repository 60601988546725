import { ChangeEvent, ReactNode } from "react";
import { SearchInput } from "../../../styles/atoms/search-input";
import { TableHeaderContainer, TableTitle } from "./styled-table-header";
import { HasDataTestId } from "../../../utils/has-data-test-id";

type TableHeaderProps = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  title: string;
  removeSearch?: boolean;
  button?: ReactNode;
  boldTitle?: boolean;
} & HasDataTestId;

export const TableHeader = (props: TableHeaderProps) => {
  const {
    searchTerm,
    setSearchTerm,
    title,
    removeSearch,
    dataTestId,
    button,
    boldTitle,
  } = props;

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(event.target.value);

  return (
    <TableHeaderContainer data-testid={dataTestId}>
      <TableTitle $boldTitle={boldTitle}>{title}</TableTitle>
      {!removeSearch && (
        <SearchInput
          tableSearch
          onChangeHandler={(event: ChangeEvent<HTMLInputElement>) =>
            handleSearchInputChange(event)
          }
          valueProp={searchTerm}
        />
      )}
      {button && button}
    </TableHeaderContainer>
  );
};
