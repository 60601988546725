import { useState } from "react";
import {
  UXInputContainer,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../styles/classes/reusable-classes";
import { ContentModuleCardDTO } from "../../../../../../../models/content-module-dtos/content-module-card-dto";
import { LessonReadModeCardParts } from "./lesson-read-mode-card-parts/lesson-read-mode-card-parts";
import {
  LessonCardContainer,
  LessonCardHeader,
} from "../../../../../styles/content-module-lesson-classes";

type LessonReadModeCardProps = {
  card: ContentModuleCardDTO;
};

export const LessonReadModeCard = (props: LessonReadModeCardProps) => {
  const { card } = props;

  const [showDropdown, setShowDropdown] = useState(true);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <LessonCardContainer $showParts={showDropdown}>
      <LessonCardHeader>
        <UXInputContainer>
          <UXInputLabel $width="100px">Card Title</UXInputLabel>
          <UXInputWrapper
            $width="100%"
            className="content-module-name-input-wrapper"
            $readOnly
          >
            <h6 dir="auto">{card.title}</h6>
          </UXInputWrapper>
        </UXInputContainer>
        <span
          className="material-symbols-outlined show-parts-arrow"
          onClick={toggleDropdown}
        >
          expand_more
        </span>
      </LessonCardHeader>
      <LessonReadModeCardParts cardParts={card.contentModuleCardParts} />
    </LessonCardContainer>
  );
};
