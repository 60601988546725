import { PatientProfileInformationHeaderCenter } from "./patient-profile-information-header-center/patient-profile-information-header-center";
import { PatientProfileInformationHeaderImage } from "./patient-profile-information-header-image/patient-profile-information-header-image";
import { PatientProfileInformationHeaderDiagnosis } from "./patient-profile-information-header-diagnosis/patient-profile-information-header-diagnosis";
import { PatientProfileInformationHeaderContainer } from "./styled-patient-profile-information-header";
import { LoadablePatientProfileInformationHeaderProvider } from "./context/loadable-patient-profile-information-header-context";
import { LoadableDataLoading } from "../../../../components/loadable-data-loading";
import { useLoadableData } from "../../../../hooks/use-loadable-data";
import { useCallback } from "react";
import { useGetHealthBarriers } from "../../../../hooks/health-barrier-hooks/use-get-health-barriers";
import { useGetPatientLabTestResults } from "../../../../hooks/patient-lab-test-result-hooks/use-get-patient-lab-test-results";
import { useGetLabTestTypes } from "../../../../hooks/lab-test-types/use-get-lab-test-types";
import { useGetPatientHealthBarriers } from "../../../../hooks/patient-health-barriers-hooks/use-get-patient-health-barriers";
import { useGetProfileDiagnoses } from "../../../../hooks/profile-diagnosis-hooks/use-get-profile-diagnoses";
import { useGetPatientProfileDiagnoses } from "../../../../hooks/patient-profile-diagnoses-hooks/use-get-patient-profile-diagnoses";
import { useGetPatientDiagnosis } from "../../../../hooks/patient-diagnosis-hooks/use-get-patient-diagnosis";
import { useGetMaritalStatuses } from "../../../../hooks/marital-status-hooks/use-get-marital-statuses";
import { useGetPatientWeights } from "../../../../hooks/patient-weights-hooks/use-get-patient-weights";
import { useGetPatientBloodPressures } from "../../../../hooks/patient-blood-pressures-hooks/use-get-patient-blood-pressures";
import { useGetPatient } from "../../../../hooks/patient-hooks/use-get-patient";
import { useParams } from "react-router-dom";

export const PatientProfileInformationHeader = () => {
  const { id } = useParams();

  const getPatient = useGetPatient();
  const getPatientBloodPressures = useGetPatientBloodPressures();
  const getPatientWeights = useGetPatientWeights();
  const getMaritalStatus = useGetMaritalStatuses();
  const getPatientDiagnosis = useGetPatientDiagnosis();
  const getPatientProfileDiagnoses = useGetPatientProfileDiagnoses();
  const getProfileDiagnoses = useGetProfileDiagnoses();
  const getPatientHealthBarriers = useGetPatientHealthBarriers();
  const getLabTestTypes = useGetLabTestTypes();
  const getPatientLabTestResults = useGetPatientLabTestResults();
  const getHealthBarriers = useGetHealthBarriers();

  const patientProfileInformationHeaderCallback = useCallback(async () => {
    const patientId = parseInt(id!);

    const [
      patientDTO,
      patientBloodPressureDTOs,
      patientWeightDTOs,
      MaritalStatusDTOs,
      patientDiagnosisDTO,
      patientProfileDiagnosis,
      profileDiagnosis,
      patientHealthBarriers,
      labTestTypeDTOs,
      patientLabTestResultsDTOs,
      healthBarriers,
    ] = await Promise.all([
      getPatient(patientId),
      getPatientBloodPressures(patientId),
      getPatientWeights(patientId),
      getMaritalStatus(),
      getPatientDiagnosis(patientId).catch(() => undefined),
      getPatientProfileDiagnoses(patientId),
      getProfileDiagnoses(),
      getPatientHealthBarriers(patientId),
      getLabTestTypes(),
      getPatientLabTestResults(patientId),
      getHealthBarriers(),
    ]);

    return {
      patientDTO,
      patientBloodPressureDTOs,
      patientWeightDTOs,
      MaritalStatusDTOs,
      patientDiagnosisDTO,
      patientProfileDiagnosis,
      profileDiagnosis,
      patientHealthBarriers,
      labTestTypeDTOs,
      patientLabTestResultsDTOs,
      healthBarriers,
    };
  }, [
    id,
    getPatient,
    getPatientBloodPressures,
    getPatientWeights,
    getMaritalStatus,
    getPatientDiagnosis,
    getPatientProfileDiagnoses,
    getProfileDiagnoses,
    getPatientHealthBarriers,
    getLabTestTypes,
    getPatientLabTestResults,
    getHealthBarriers,
  ]);

  const loadablePatientProfileInformationHeader = useLoadableData(
    patientProfileInformationHeaderCallback
  );

  return (
    <PatientProfileInformationHeaderContainer>
      <LoadablePatientProfileInformationHeaderProvider
        loadablePatientProfileInformationHeader={
          loadablePatientProfileInformationHeader
        }
      >
        <LoadableDataLoading
          state={loadablePatientProfileInformationHeader.state}
          successComponent={() => (
            <>
              <PatientProfileInformationHeaderImage />
              <PatientProfileInformationHeaderCenter />
              <PatientProfileInformationHeaderDiagnosis />
            </>
          )}
        />
      </LoadablePatientProfileInformationHeaderProvider>
    </PatientProfileInformationHeaderContainer>
  );
};
