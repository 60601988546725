import { ChangeEvent } from "react";
import { PlansInputLabel } from "../../plan-steps/styles";
import {
  PlansNotesInputContainer,
  PlansNotesTextAreaContainer,
} from "./styled-plans-notes-input";

type PlansNotesInputProps = {
  value?: string;
  onChange: (value?: string) => void;
};

export const PlansNotesInput = (props: PlansNotesInputProps) => {
  const { onChange, value } = props;

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value !== undefined ? e.target.value : "");
  };

  return (
    <PlansNotesInputContainer>
      <PlansInputLabel>Notes</PlansInputLabel>
      <PlansNotesTextAreaContainer>
        <textarea
          placeholder="Write notes..."
          value={value === undefined ? "" : value}
          onChange={handleTextAreaChange}
        ></textarea>
      </PlansNotesTextAreaContainer>
    </PlansNotesInputContainer>
  );
};
