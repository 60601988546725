import { useCarbCounterMealState } from "../context/carb-counter-meal-state-context";
import { roundTo3DecimalPlaces } from "../../../../../utils/math-utils";
import {
  CarbCounterFoodLoggingSelectedPatientMealAnalysisContainer,
  CarbCounterFoodLoggingSelectedPatientMealQuantityContainer,
  CarbCounterFoodLoggingSelectedPatientMealResponseAutoSelectContainer,
  CarbCounterFoodLoggingSelectedPatientMealResponseFoodItemsDeleteIcon,
  CarbCounterFoodLoggingSelectedPatientMealResponseAddMoreFoodsContainer,
  CarbCounterFoodLoggingSelectedPatientMealResponseMealFoodNameContainer,
  CarbCounterFoodLoggingSelectedPatientMealResponseTableContainer,
  CarbCounterFoodLoggingSelectedPatientMealResponseTitleContainer,
  CarbCounterFoodLoggingSelectedPatientMealResponseDropdownContainer,
  CarbCounterFoodLoggingSelectedPatientMealResponseMealFoodNameInfoIcon,
  CarbCounterFoodLoggingSelectedPatientMealResponseMealFoodNameInfoContainer,
} from "./styled-carb-counter-food-logging-selected-patient-meal-response";
import { useCarbCounterMeal } from "../context/carb-counter-meal-dto-context";
import {
  getFoodMeasureDisplayName,
  isFoodMeasureSelected,
} from "../utils/carb-counter-meal-utils";
import { CarbCounterMealAddFoodModal } from "./carb-counter-meal-add-food-modal/carb-counter-meal-add-food-modal";
import { AutoSelectForMedications } from "../../../../../components/auto-select-for-medications/auto-select-for-medications";
import { useFoodLoggingData } from "../../../context/food-logging-data-context";
import { FoodGroupDTO } from "../../../../../models/food-groups/food-group-dto";
import { useCallback, useState } from "react";
import { Dropdown } from "../../../../../components/dropdown/dropdown";
import { DropdownListItem } from "../../../../../components/dropdown/styled-dropdown";

type CarbCounterFoodLoggingSelectedPatientMealResponseProps = {
  readOnly: boolean;
};

export const CarbCounterFoodLoggingSelectedPatientMealResponse = (
  props: CarbCounterFoodLoggingSelectedPatientMealResponseProps
) => {
  const { readOnly } = props;

  const {
    meal,
    decrementMealQuantity,
    incrementMealQuantity,
    removeMealFood,
    updateMealFoodQuantityFoodMeasure,
    handleInputChange,
    updateFoodGroup,
  } = useCarbCounterMeal();

  const {
    showFoodFullName,
    handleToggleShowFoodFullName,
  } = useDisplayFoodFullName();

  const { handleAnalizeMeal } = useCarbCounterMealState();

  const { foodGroupDTOs } = useFoodLoggingData();

  const displayFoodGroup = useCallback(
    (foodGroupDTO: FoodGroupDTO) => foodGroupDTO.name,
    []
  );

  const findFoodGroupByID = useCallback(
    (foodGroupID: number) =>
      foodGroupDTOs.find((foodGroupDTO) => foodGroupDTO.id === foodGroupID),
    [foodGroupDTOs]
  );

  return (
    <CarbCounterFoodLoggingSelectedPatientMealAnalysisContainer>
      <CarbCounterFoodLoggingSelectedPatientMealResponseTitleContainer>
        <p>Confirm food items & quantity:</p>
        <span className="material-symbols-outlined" onClick={handleAnalizeMeal}>
          refresh
        </span>
      </CarbCounterFoodLoggingSelectedPatientMealResponseTitleContainer>
      <CarbCounterFoodLoggingSelectedPatientMealResponseTableContainer>
        <table>
          <tbody>
            {meal.mealFoods.map((food, index) => (
              <tr key={index}>
                <td>
                  <CarbCounterFoodLoggingSelectedPatientMealResponseMealFoodNameContainer>
                    {!readOnly && (
                      <CarbCounterFoodLoggingSelectedPatientMealResponseFoodItemsDeleteIcon
                        onClick={() => removeMealFood(index)}
                      >
                        <img src="/img/trash.svg" alt="" />
                      </CarbCounterFoodLoggingSelectedPatientMealResponseFoodItemsDeleteIcon>
                    )}
                    <div>
                      <p>
                        {food.food.displayName !== undefined
                          ? food.food.displayName
                          : food.food.name}
                      </p>
                      {food.food.name === showFoodFullName.foodFullName &&
                        index === showFoodFullName.id && (
                          <CarbCounterFoodLoggingSelectedPatientMealResponseMealFoodNameInfoContainer>
                            <p>{food.food.name}</p>
                          </CarbCounterFoodLoggingSelectedPatientMealResponseMealFoodNameInfoContainer>
                        )}
                    </div>
                    {food.food.displayName !== undefined && (
                      <CarbCounterFoodLoggingSelectedPatientMealResponseMealFoodNameInfoIcon
                        onClick={() =>
                          handleToggleShowFoodFullName(index, food.food.name)
                        }
                      >
                        <span className="material-symbols-outlined">info</span>
                      </CarbCounterFoodLoggingSelectedPatientMealResponseMealFoodNameInfoIcon>
                    )}
                  </CarbCounterFoodLoggingSelectedPatientMealResponseMealFoodNameContainer>
                </td>
                <td>
                  <CarbCounterFoodLoggingSelectedPatientMealQuantityContainer>
                    {!readOnly && (
                      <button
                        type="button"
                        onClick={() => decrementMealQuantity(food, index)}
                      >
                        -
                      </button>
                    )}
                    <input
                      type="number"
                      value={roundTo3DecimalPlaces(food.quantity)}
                      onChange={(event) => handleInputChange(index, event)}
                      readOnly={readOnly}
                    />
                    {!readOnly && (
                      <button
                        type="button"
                        onClick={() => incrementMealQuantity(food, index)}
                      >
                        +
                      </button>
                    )}
                  </CarbCounterFoodLoggingSelectedPatientMealQuantityContainer>
                </td>
                <td>
                  <CarbCounterFoodLoggingSelectedPatientMealResponseDropdownContainer>
                    <Dropdown
                      text={getFoodMeasureDisplayName(
                        food.quantityFoodMeasure,
                        food
                      )}
                      variant="designed-ux"
                      fullWidth
                      disabled={readOnly}
                    >
                      {food.food.foodMeasures.map(
                        (foodMeasure, foodMeassureIndex) => (
                          <DropdownListItem
                            key={foodMeassureIndex}
                            variant="designed-ux"
                            active={isFoodMeasureSelected(foodMeasure, food)}
                            onClick={() =>
                              updateMealFoodQuantityFoodMeasure(
                                index,
                                foodMeassureIndex
                              )
                            }
                          >
                            {getFoodMeasureDisplayName(foodMeasure, food)}
                          </DropdownListItem>
                        )
                      )}
                    </Dropdown>
                  </CarbCounterFoodLoggingSelectedPatientMealResponseDropdownContainer>
                </td>
                <td>
                  <CarbCounterFoodLoggingSelectedPatientMealResponseAutoSelectContainer>
                    <AutoSelectForMedications<FoodGroupDTO>
                      display={displayFoodGroup}
                      label=""
                      onChange={(value) => updateFoodGroup(index, value.id)}
                      options={foodGroupDTOs}
                      placeHolder={
                        food.food.foodGroupId !== undefined
                          ? findFoodGroupByID(food.food.foodGroupId)?.name!
                          : `Choose A Food Group (Optional)`
                      }
                      errorState={false}
                    />
                  </CarbCounterFoodLoggingSelectedPatientMealResponseAutoSelectContainer>
                </td>
              </tr>
            ))}
            {!readOnly && (
              <tr>
                <td>
                  <CarbCounterFoodLoggingSelectedPatientMealResponseAddMoreFoodsContainer>
                    <CarbCounterMealAddFoodModal />
                  </CarbCounterFoodLoggingSelectedPatientMealResponseAddMoreFoodsContainer>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CarbCounterFoodLoggingSelectedPatientMealResponseTableContainer>
    </CarbCounterFoodLoggingSelectedPatientMealAnalysisContainer>
  );
};

function useDisplayFoodFullName() {
  type ShowFoodFullName = {
    id?: number;
    foodFullName?: string;
  };

  const [showFoodFullName, setShowFoodFullName] = useState<ShowFoodFullName>({
    id: undefined,
    foodFullName: undefined,
  });

  const resetFoodFullNameState = useCallback(() => {
    setShowFoodFullName({
      id: undefined,
      foodFullName: undefined,
    });
  }, []);

  const handleToggleShowFoodFullName = useCallback(
    (id: number, foodFullName: string) => {
      if (id === showFoodFullName.id) {
        resetFoodFullNameState();
        return;
      } else {
        setShowFoodFullName({
          id: id,
          foodFullName: foodFullName,
        });
      }
    },
    [resetFoodFullNameState, showFoodFullName.id]
  );

  return { handleToggleShowFoodFullName, showFoodFullName };
}
