import { Modal } from "../../../components/modal/modal";
import { BulkImportModalContent } from "./styled-bulk-import";
import { BulkImportModal } from "./bulk-import-libreview-modal";
import { useModalHelpers } from "../../../components/modal/use-modal-helpers";
import { DropdownListItem } from "../../../components/dropdown/styled-dropdown";
import { useLoadablePatients } from "../context/loadable-patients-context";

export function BulkImport() {
  const { isModalOpen, openModal, closeModal, modalBodyKey } =
    useModalHelpers();

  const loadablePatients = useLoadablePatients();

  const arePatientsLoaded = loadablePatients.state.data !== undefined;

  return (
    <>
      <DropdownListItem
        onClick={openModal}
        className={`${arePatientsLoaded ? "" : "disabled"}`}
      >
        CGM data
      </DropdownListItem>
      {arePatientsLoaded && (
        <Modal handleHideModal={closeModal} showModal={isModalOpen}>
          <BulkImportModalContent key={modalBodyKey}>
            <BulkImportModal onModalClose={closeModal} />
          </BulkImportModalContent>
        </Modal>
      )}
    </>
  );
}
