import {
  Message,
  MessageActionsArray,
  SimpleReactionsList,
  StreamMessage,
  useChatContext,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { CSSProperties } from "styled-components";
import { PinnedMessageIcon } from "../../styled-chat-home";

type DefaultStreamChatMessageProps = {
  message: StreamMessage<DefaultStreamChatGenerics>;
  messageActions?: MessageActionsArray;
  hasReactions?: boolean;
  whitePinnedIcon?: boolean;
  hidePinnedIcon?: boolean;
};

export const DefaultStreamChatMessage = (
  props: DefaultStreamChatMessageProps
) => {
  const {
    message,
    messageActions,
    hasReactions,
    whitePinnedIcon,
    hidePinnedIcon,
  } = props;

  const MessageContainerStyle: CSSProperties = {
    position: "relative",
  };

  const { client } = useChatContext();

  return (
    <div style={MessageContainerStyle}>
      <Message
        closeReactionSelectorOnClick={true}
        message={message}
        renderText={(text) =>
          text?.includes("http") ? (
            <a
              href={text}
              target="_blank"
              rel="noreferrer"
              style={{ color: "inherit" }}
            >
              {text}
            </a>
          ) : (
            <p dir="auto">{text}</p>
          )
        }
        formatDate={(date) =>
          date.toLocaleTimeString("en-AU", {
            hour12: true,
            second: undefined,
            hour: "2-digit",
            minute: "2-digit",
          })
        }
        messageActions={messageActions}
      />
      {message.pinned &&
        !hidePinnedIcon &&
        message.pinned_by?.id === client.userID && (
          <PinnedMessageIcon whiteIcon={whitePinnedIcon}>
            <img src="/img/chat/star.svg" alt="pinned message" />
          </PinnedMessageIcon>
        )}
      {hasReactions && <SimpleReactionsList />}
    </div>
  );
};
