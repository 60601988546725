import { ChatMessagingMediaSidebarActiveList } from "../messaging-media-sidebar";
import { MessagingMediaSidebarActiveListHeader } from "./messaging-media-sidebar-active-list-header/messaging-media-sidebar-active-list-header";
import { MessagingMediaSidebarMediaList } from "./messaging-media-sidebar-media-list/messaging-media-sidebar-media-list";
import { MessagingMediaSidebarPinnedMessagesList } from "./messaging-media-sidebar-pinned-messages-list/messaging-media-sidebar-pinned-messages-list";

type MessagingMediaSidebarActiveListProps = {
  activeList: ChatMessagingMediaSidebarActiveList;
  handleResetActiveList: () => void;
};

export const MessagingMediaSidebarActiveList = (
  props: MessagingMediaSidebarActiveListProps
) => {
  const { activeList, handleResetActiveList } = props;

  return (
    <div>
      <MessagingMediaSidebarActiveListHeader
        title={activeList}
        handleResetActiveList={handleResetActiveList}
      />
      {activeList === "Media" && <MessagingMediaSidebarMediaList />}
      {activeList === "Pinned Messages" && (
        <MessagingMediaSidebarPinnedMessagesList />
      )}
    </div>
  );
};
