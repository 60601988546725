import { ContentModuleCardPartType } from "../../../models/content-module-dtos/content-module-card-part-type";
import { Button } from "../../../styles/classes/reusable-classes";

type CardPartsActionButtonProps = {
  handleClick: () => void;
  contentModuleCardPartType: ContentModuleCardPartType;
};

export const CardPartsActionButton = (props: CardPartsActionButtonProps) => {
  const { handleClick, contentModuleCardPartType } = props;

  return (
    <Button outlineNoBorder flex type="button" onClick={handleClick}>
      <span className="material-symbols-outlined">add</span>{" "}
      {contentModuleCardPartType}
    </Button>
  );
};
