import styled from "styled-components/macro";
import { smallRegularFont } from "../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../styles/global-style";

export const LabResultsCardCharts = styled.div`
  display: flex;
  width: 50%;
  overflow-x: auto;
  height: 100%;
  gap: 0rem 1.2rem;
  align-items: center;
  justify-content: flex-start;

  ::-webkit-scrollbar {
    height: 7px;
  }

  @media screen and (max-width: 425px) {
    width: 70%;
    justify-content: center;
  }
`;

export const LabResultsCardChartContainer = styled.div`
  ${smallRegularFont}
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.4rem 0rem;
`;

export const LabResultsCardChart = styled.div<{
  height: number;
  current?: boolean;
  result: number;
  date: string;
}>`
  width: 22px;
  background: ${({ theme, current }) =>
    current
      ? theme.colors.primaryColorAlt
      : theme.newColors.labResultsOldUIChartColor};
  height: ${({ height }) => `${height}%`};
  border-radius: 0.25rem;
  transition: ${transition};
  margin-bottom: 0.2rem;
  position: relative;

  ::before {
    content: "${({ result }) => result}";
    position: absolute;
    top: -20px;
  }

  ::after {
    content: "${({ date }) => date}";
    position: absolute;
    bottom: -18px;
    left: 0;
    width: 44px;
  }
`;
