import { Controller, useForm } from "react-hook-form";
import { Temporal } from "temporal-polyfill";
import { ModalForm } from "../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../components/modal/modal-header/modal-header";
import { useToastService } from "../../../../../context/toast-service-context";
import { useDiscontinuePatientMedication } from "../../../../../hooks/patient-medication-hooks/use-discontinue-patient-medication";
import { PatientMedicationDTO } from "../../../../../models/patient-medication-dto";
import {
  Button,
  ModalFooter,
} from "../../../../../styles/classes/reusable-classes";
import {
  StopMedicineInput,
  StopMedicineInputContainer,
  StopMedicineModalContainer,
} from "./styled-stop-medicine-modal-body";
import { useReloadMedications } from "../../context/loadable-medications-context";

type StopMedicineModalBodyProps = {
  onClose: () => void;
  row: PatientMedicationDTO;
};

type Input = {
  discontinueDate: Temporal.PlainDate;
};

export const StopMedicineModalBody = (props: StopMedicineModalBodyProps) => {
  const { onClose, row } = props;

  const discontinuePatientMedication = useDiscontinuePatientMedication();
  const { showToast } = useToastService();

  const reloadMedications = useReloadMedications();

  const { control, handleSubmit } = useForm<Input>({
    defaultValues: { discontinueDate: Temporal.Now.plainDateISO() },
  });

  const onSubmit = handleSubmit(async (input) => {
    const discontinueDate = {
      discontinueDate: input.discontinueDate.toString(),
    };

    try {
      await discontinuePatientMedication(row, discontinueDate);
      showToast("Success", "Medicine stopped successfully!");
      reloadMedications();
    } catch (error) {
      showToast("Error", "Failed to add Medicine :(");
    }
  });

  return (
    <ModalForm onSubmit={onSubmit}>
      <ModalHeader title="Stop medicine" onModalClose={onClose} />
      <StopMedicineModalContainer>
        <StopMedicineInputContainer>
          <Controller
            name="discontinueDate"
            control={control}
            rules={{ required: false }}
            render={({ field: { value, onChange } }) => (
              <StopMedicineInput
                type="date"
                value={value === undefined ? "" : value.toString()}
                onChange={(e) =>
                  onChange(e.target.value === "" ? undefined : e.target.value)
                }
              />
            )}
          />
        </StopMedicineInputContainer>
      </StopMedicineModalContainer>
      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit">Confirm</Button>
      </ModalFooter>
    </ModalForm>
  );
};
