import {
  MedicationCardIconContainer,
  MedicationCardText,
  MedicationCardTitle,
} from "../../../components/medication-card/styled-medication-card";
import { PrescriptionFormHeaderContainer } from "./styled-prescription-form-header";

type PrescriptionsFormHeaderProps = {
  formHeaderData: {
    name: string | undefined;
    strength: string | undefined;
    routeOfAdministration: string | undefined;
    doseUnit: string | undefined;
  };
};

export const PrescriptionFormHeader = (props: PrescriptionsFormHeaderProps) => {
  const { formHeaderData } = props;

  return (
    <PrescriptionFormHeaderContainer>
      <MedicationCardIconContainer>
        <span className="material-symbols-outlined">medication</span>
      </MedicationCardIconContainer>
      <div>
        <MedicationCardTitle>{formHeaderData.name}</MedicationCardTitle>
        <MedicationCardText>
          Details:{" "}
          {`${
            formHeaderData.strength === undefined ||
            formHeaderData.strength === ""
              ? ""
              : `${formHeaderData.strength}, `
          }`}
          {`${
            formHeaderData.routeOfAdministration === undefined
              ? ""
              : ` ${formHeaderData.routeOfAdministration},`
          }`}
          {formHeaderData.doseUnit !== undefined ? formHeaderData.doseUnit : ""}
        </MedicationCardText>
      </div>
    </PrescriptionFormHeaderContainer>
  );
};
