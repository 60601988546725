import { Link } from "react-router-dom";
import { FoodLoggingSelectedPatientMealFooterContainer } from "./styled-food-logging-selected-patient-meal-footer";
import { useFormContext } from "react-hook-form";
import { PatientMealDTO } from "../../../../../models/patient-meal-dtos/patient-meal-dto";
import { FoodLoggingPatientsTab } from "../../../food-logging-patients/food-logging-patients-tabs/food-logging-patients-tabs";
import { FoodLoggingSelectedPatientMealInputs } from "../../food-logging-selected-patient-meal";
import { useFoodLoggingData } from "../../../context/food-logging-data-context";
import { usePatientTodoMealsCountsUpdates } from "../../../context/patient-todo-meals-counts-updates-context";
import { useToastService } from "../../../../../context/toast-service-context";
import { useApiWithFullPageLoader } from "../../../../../hooks/use-api-with-full-page-loader";
import { useUpdatePatientMeal } from "../../../../../hooks/patient-meal-hooks/use-update-patient-meal";
import { isPatientMealDTOLegacy } from "../../utils/food-logging-selected-patient-meal-utils";
import { UpdatePatientMealDTO } from "../../../../../models/patient-meal-dtos/update-patient-meal-dto";
import { getPatientTodoMealsCount } from "../../../util/get-patient-todo-meals-count";
import { FEATURE_FLAG_NUTRITION_REPORT } from "../../../../../constants/config";
import { Button } from "../../../../../styles/classes/reusable-classes";

type FoodLoggingSelectedPatientMealFooterProps = {
  patientMealDTO: PatientMealDTO;
  selectedTab: FoodLoggingPatientsTab;
};

export const FoodLoggingSelectedPatientMealFooter = (
  props: FoodLoggingSelectedPatientMealFooterProps
) => {
  const { patientMealDTO, selectedTab } = props;

  const { handleSubmit } =
    useFormContext<FoodLoggingSelectedPatientMealInputs>();

  const {
    selectedPatientDTO,
    setSelectedPatientDTO,
    selectedPatientMealDTO,
    setSelectedPatientMealDTO,
  } = useFoodLoggingData();

  const {
    lastUpdatedPatientMealByPatientMealId,
    todoMealsCountsUpdatesByPatientId,
    setTodoMealsCountsUpdatesByPatientId,
    setLastUpdatedPatientMealByPatientMealId,
  } = usePatientTodoMealsCountsUpdates();

  const { showToast } = useToastService();
  const updatePatientMeal = useApiWithFullPageLoader(useUpdatePatientMeal());

  async function handleSaveAsDraft(
    inputs: FoodLoggingSelectedPatientMealInputs
  ) {
    await handleUpdate({
      ...inputs,
      finishedFoodLogging: false,
      patientMealFoods: isPatientMealDTOLegacy(selectedPatientMealDTO!)
        ? undefined
        : inputs.patientMealFoods!,
    });
  }

  async function handleFinish(inputs: FoodLoggingSelectedPatientMealInputs) {
    await handleUpdate({
      ...inputs,
      finishedFoodLogging: true,
      patientMealFoods: isPatientMealDTOLegacy(selectedPatientMealDTO!)
        ? undefined
        : inputs.patientMealFoods!,
    });
  }

  async function handleUpdate(updatePatientMealDTO: UpdatePatientMealDTO) {
    try {
      const updatedPatientMealDTO = await updatePatientMeal(
        patientMealDTO,
        updatePatientMealDTO
      );
      showToast("Success", "Meal updated successfully!");

      handleMealUpdatedSuccessfully(updatedPatientMealDTO);
    } catch (error) {
      showToast("Error", "Failed to update meal :(");
    }
  }

  function handleMealUpdatedSuccessfully(
    updatedPatientMealDTO: PatientMealDTO
  ) {
    updateTodoMealsCountsUpdatesByPatientId(updatedPatientMealDTO);
    updateLastUpdatedPatientMealByPatientMealId(updatedPatientMealDTO);
    updateSelectedPatientMeal(updatedPatientMealDTO);
  }

  function updateTodoMealsCountsUpdatesByPatientId(
    updatedPatientMealDTO: PatientMealDTO
  ) {
    const patientMealDTO = selectedPatientMealDTO;

    if (patientMealDTO === undefined) {
      return;
    }

    const lastUpdatedPatientMealDTO = lastUpdatedPatientMealByPatientMealId.get(
      patientMealDTO.id
    );

    const lastFinishedLogging =
      lastUpdatedPatientMealDTO?.finishedFoodLogging ??
      patientMealDTO.finishedFoodLogging;

    if (lastFinishedLogging === updatedPatientMealDTO.finishedFoodLogging) {
      return;
    }

    setTodoMealsCountsUpdatesByPatientId((prev) => {
      const next = new Map(prev);
      const todoMealsCountUpdate = next.get(patientMealDTO.patientId) ?? 0;
      const nextTodoMealsCountUpdate =
        todoMealsCountUpdate +
        (updatedPatientMealDTO.finishedFoodLogging ? -1 : 1);
      next.set(patientMealDTO.patientId, nextTodoMealsCountUpdate);
      return next;
    });
  }

  function updateLastUpdatedPatientMealByPatientMealId(
    updatedPatientMealDTO: PatientMealDTO
  ) {
    setLastUpdatedPatientMealByPatientMealId((prev) => {
      const next = new Map(prev);
      next.set(updatedPatientMealDTO.id, updatedPatientMealDTO);
      return next;
    });
  }

  function updateSelectedPatientMeal(updatedPatientMealDTO: PatientMealDTO) {
    if (selectedTab === "todo" && updatedPatientMealDTO.finishedFoodLogging) {
      setSelectedPatientMealDTO(undefined);

      if (
        getPatientTodoMealsCount(
          selectedPatientDTO!,
          todoMealsCountsUpdatesByPatientId
        ) === 1
      ) {
        setSelectedPatientDTO(undefined);
      }
    }
  }

  return (
    <FoodLoggingSelectedPatientMealFooterContainer>
      {FEATURE_FLAG_NUTRITION_REPORT && (
        <Link
          to={`/food-logging/food-logging-nutrition-report/${selectedPatientDTO?.id!}`}
        >
          <Button outlined type="button">
            Generate Report
          </Button>
        </Link>
      )}
      <Button outlined type="button" onClick={handleSubmit(handleSaveAsDraft)}>
        Save as draft
      </Button>
      <Button type="submit" onClick={handleSubmit(handleFinish)}>
        Finish
      </Button>
    </FoodLoggingSelectedPatientMealFooterContainer>
  );
};
