import { useCallback, useState } from "react";
import { useSinglePatientSidebarUI } from "../context/single-patient-sidebar-ui-context";
import { PatientSidebarTabs } from "./patient-sidebar-tabs/patient-sidebar-tabs";
import {
  CloseIcon,
  SidebarSubContainer,
  SidebarSubContainerUserInfo,
  SidebarSubContainerUserInfoButtons,
  SidebarSubContainerUserInfoButton,
  SinglePatientSidebarBackdrop,
  SinglePatientSidebarLoadableContainer,
} from "./styled-single-patient-sidebar";
import { PatientSidebarInfo } from "./patient-sidebar-info/patient-sidebar-info";
import { useLocation, useParams } from "react-router-dom";
import { LoadableSinglePatientSidebarProvider } from "./context/loadable-single-patient-sidebar-context";
import { useLoadableData } from "../../../hooks/use-loadable-data";
import { useGetPatient } from "../../../hooks/patient-hooks/use-get-patient";
import { useGetPatientMedicalHistory } from "../../../hooks/patient-medical-history-hooks/use-get-patient-medical-history";
import { useGetPatientBloodPressures } from "../../../hooks/patient-blood-pressures-hooks/use-get-patient-blood-pressures";
import { useGetPatientWeights } from "../../../hooks/patient-weights-hooks/use-get-patient-weights";
import { useGetPatientTimeline } from "../../../hooks/patient-timeline-hooks/use-get-patient-timeline";
import { useGetMaritalStatuses } from "../../../hooks/marital-status-hooks/use-get-marital-statuses";
import { useGetPatientDietProfile } from "../../../hooks/patient-diet-profile-hooks/use-get-patient-diet-profile";
import { useGetPatientDiagnosis } from "../../../hooks/patient-diagnosis-hooks/use-get-patient-diagnosis";
import { useGetPatientProfileDiagnoses } from "../../../hooks/patient-profile-diagnoses-hooks/use-get-patient-profile-diagnoses";
import { LoadableDataLoading } from "../../../components/loadable-data-loading";
import { useGetProfileDiagnoses } from "../../../hooks/profile-diagnosis-hooks/use-get-profile-diagnoses";
import { useGetPatientLabTestResults } from "../../../hooks/patient-lab-test-result-hooks/use-get-patient-lab-test-results";
import { useGetLabTestTypes } from "../../../hooks/lab-test-types/use-get-lab-test-types";

export const SinglePatientSidebar = () => {
  const [activeBtn, setActiveBtn] = useState(1);
  const location = useLocation();
  const { planID } = useParams();

  const {
    showSinglePatientSidebarUI,
    onSidebarHide,
  } = useSinglePatientSidebarUI();

  const { id } = useParams();

  const getPatient = useGetPatient();
  const getPatientMedicalHistory = useGetPatientMedicalHistory();
  const getPatientBloodPressures = useGetPatientBloodPressures();
  const getPatientWeights = useGetPatientWeights();
  const getPatientTimeline = useGetPatientTimeline();
  const getMaritalStatus = useGetMaritalStatuses();
  const getPatientDietProfile = useGetPatientDietProfile();
  const getPatientDiagnosis = useGetPatientDiagnosis();
  const getPatientProfileDiagnoses = useGetPatientProfileDiagnoses();
  const getProfileDiagnoses = useGetProfileDiagnoses();
  const getLabTestsResults = useGetPatientLabTestResults();
  const getLabTestTypes = useGetLabTestTypes();

  const patientSidebarCallback = useCallback(async () => {
    const patientId = parseInt(id!);

    const [
      patientDTO,
      patientMedicalHistoryDTO,
      patientBloodPressureDTOs,
      patientWeightDTOs,
      patientTimelineDTO,
      MaritalStatusDTOs,
      patientDietProfileDTO,
      patientDiagnosisDTO,
      patientProfileDiagnoses,
      profileDiagnoses,
      patientLabTestResultsDTOs,
      labTestTypeDTOs,
    ] = await Promise.all([
      getPatient(patientId),
      getPatientMedicalHistory(patientId),
      getPatientBloodPressures(patientId),
      getPatientWeights(patientId),
      getPatientTimeline(patientId),
      getMaritalStatus(),
      getPatientDietProfile(patientId),
      getPatientDiagnosis(patientId).catch(() => undefined),
      getPatientProfileDiagnoses(patientId),
      getProfileDiagnoses(),
      getLabTestsResults(patientId),
      getLabTestTypes(),
    ]);

    return {
      patientDTO,
      patientMedicalHistoryDTO,
      patientBloodPressureDTOs,
      patientWeightDTOs,
      patientTimelineDTO,
      MaritalStatusDTOs,
      patientDietProfileDTO,
      getPatientDietProfile,
      patientDiagnosisDTO,
      patientProfileDiagnoses,
      profileDiagnoses,
      patientLabTestResultsDTOs,
      labTestTypeDTOs,
    };
  }, [
    id,
    getPatient,
    getPatientMedicalHistory,
    getPatientBloodPressures,
    getPatientWeights,
    getPatientTimeline,
    getMaritalStatus,
    getPatientDietProfile,
    getPatientDiagnosis,
    getPatientProfileDiagnoses,
    getProfileDiagnoses,
    getLabTestsResults,
    getLabTestTypes,
  ]);

  const loadableSinglePatientSidebar = useLoadableData(patientSidebarCallback);

  return (
    <>
      <SinglePatientSidebarBackdrop
        onClick={onSidebarHide}
        $showSinglePatientSidebarUI={showSinglePatientSidebarUI}
      />
      <SinglePatientSidebarLoadableContainer
        $showSinglePatientSidebarUI={showSinglePatientSidebarUI}
        plansLayout={location.pathname.includes(`plans/${planID}`)}
      >
        <LoadableSinglePatientSidebarProvider
          loadableSinglePatientSidebar={loadableSinglePatientSidebar}
        >
          <LoadableDataLoading
            state={loadableSinglePatientSidebar.state}
            successComponent={() => (
              <aside>
                <CloseIcon>
                  <span
                    className="material-symbols-outlined"
                    onClick={onSidebarHide}
                  >
                    close
                  </span>
                </CloseIcon>
                <SidebarSubContainer>
                  <SidebarSubContainerUserInfo>
                    <PatientSidebarInfo />
                    <SidebarSubContainerUserInfoButtons>
                      <SidebarSubContainerUserInfoButton
                        activeBtn={activeBtn === 1}
                        onClick={() => setActiveBtn(1)}
                      >
                        Personal info.
                      </SidebarSubContainerUserInfoButton>
                      <SidebarSubContainerUserInfoButton
                        onClick={() => setActiveBtn(2)}
                        activeBtn={activeBtn === 2}
                      >
                        Medical history
                      </SidebarSubContainerUserInfoButton>
                    </SidebarSubContainerUserInfoButtons>
                  </SidebarSubContainerUserInfo>
                  <PatientSidebarTabs activeBtn={activeBtn} />
                </SidebarSubContainer>
              </aside>
            )}
          />
        </LoadableSinglePatientSidebarProvider>
      </SinglePatientSidebarLoadableContainer>
    </>
  );
};
