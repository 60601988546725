import { createContext, PropsWithChildren, useContext } from "react";
import { PatientDTO } from "../../../../models/patient-dtos/patient-dto";
import { PatientLabTestResultDTO } from "../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import { LabTestCategoryDTO } from "../../../../models/lab-test-categories/lab-test-category-dto";
import { LabTestTypeDTO } from "../../../../models/lab-test-types/lab-test-type-dto";
import { LabTestTypeRefDTO } from "../../../../models/lab-test-type-refs/lab-test-type-ref-dto";
import { UseLoadableDataReturn } from "../../../../hooks/use-loadable-data";

export type LabResults = {
  patientDTO: PatientDTO;
  patientLabTestResultsDTOs: PatientLabTestResultDTO[];
  labTestCategoryDTOs: LabTestCategoryDTO[];
  labTestTypeDTOs: LabTestTypeDTO[];
  labTestTypeRefDTOs: LabTestTypeRefDTO[];
};

export type LoadableLabResults = UseLoadableDataReturn<LabResults>;

const LoadableLabResultsContext = createContext<LoadableLabResults | undefined>(
  undefined
);

export type LoadableLabResultsProviderProps = PropsWithChildren<{
  loadableLabResults: LoadableLabResults;
}>;

export function LoadableLabResultsProvider(
  props: LoadableLabResultsProviderProps
) {
  const { loadableLabResults, children } = props;

  return (
    <LoadableLabResultsContext.Provider value={loadableLabResults}>
      {children}
    </LoadableLabResultsContext.Provider>
  );
}

export function useLoadableLabResults(): LoadableLabResults {
  const loadableLabResults = useContext(LoadableLabResultsContext);
  if (loadableLabResults === undefined) {
    throw Error(
      "useLoadableLabResults must be used within an LoadableLabResultssProvider"
    );
  }

  return loadableLabResults;
}

export function useLabResults(): LabResults {
  const loadableLabResults = useLoadableLabResults();
  if (loadableLabResults.state.data === undefined) {
    throw Error("LabResults have never been loaded successfully yet.");
  }

  return loadableLabResults.state.data;
}

export function useReloadLabResults(): () => void {
  const loadableLabResults = useLoadableLabResults();
  return loadableLabResults.reload;
}
