import styled from "styled-components/macro";
import { SidebarWidth } from "../../../../../styles/global-style";
import {
  flexCenter,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const NutritionReportTableFooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: ${SidebarWidth};
  background: ${({ theme }) => theme.colors.containerColor};
  ${flexCenter}
  padding: 1rem;
  right: 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
`;

export const NutritionReportTableFooterErrorMessage = styled.p`
  ${regularMediumFont}
  margin: 0px 0px 0px auto;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primaryColorAlt};
    margin-left: 8px;
  }

  span {
    margin: 0px 32px;
  }
`;

export const NutritionReportTableFooterButtonContainer = styled.div<{
  disabled: boolean;
}>`
  margin-left: auto;

  a {
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
    color: inherit;
    text-decoration: none;
  }
`;
