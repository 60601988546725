import styled from "styled-components/macro";

export const SinglePrescriptionMedicationCardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => theme.padding.pad05_1};
  gap: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 12px;
  width: 100%;
  height: 100%;
`;
