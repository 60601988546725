import { createContext, PropsWithChildren, useContext } from "react";
import { PatientMedicationDTO } from "../../../../models/patient-medication-dto";
import { UseLoadableDataReturn } from "../../../../hooks/use-loadable-data";
import { PatientDTO } from "../../../../models/patient-dtos/patient-dto";

export type Medications = {
  patientDTO: PatientDTO;
  patientMedicationDTOs: PatientMedicationDTO[];
};

export type LoadableMedications = UseLoadableDataReturn<Medications>;

const LoadableMedicationsContext = createContext<
  LoadableMedications | undefined
>(undefined);

export type LoadableMedicationsProviderProps = PropsWithChildren<{
  loadableMedications: LoadableMedications;
}>;

export function LoadableMedicationsProvider(
  props: LoadableMedicationsProviderProps
) {
  const { loadableMedications, children } = props;

  return (
    <LoadableMedicationsContext.Provider value={loadableMedications}>
      {children}
    </LoadableMedicationsContext.Provider>
  );
}

export function useLoadableMedications(): LoadableMedications {
  const loadableMedications = useContext(LoadableMedicationsContext);
  if (loadableMedications === undefined) {
    throw Error(
      "useLoadableMedications must be used within an LoadableMedicationssProvider"
    );
  }

  return loadableMedications;
}

export function useMedications(): Medications {
  const loadableMedications = useLoadableMedications();

  if (loadableMedications.state.data === undefined) {
    throw Error("Medications have never been loaded successfully yet.");
  }

  return loadableMedications.state.data;
}

export function useReloadMedications(): () => void {
  const loadableMedications = useLoadableMedications();

  return loadableMedications.reload;
}
