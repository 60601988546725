import styled, { css } from "styled-components/macro";
import {
  flexCenterCenter,
  flexColCenterCenter,
  mediumRegularFont,
  regularRegularFont,
} from "../../../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../../../styles/global-style";

export const FeedbackModalBodyContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  place-items: center;
  gap: 1.5rem;
  margin: 2rem 1.5rem;

  @media screen and (max-width: 420px) {
    grid-template-columns: repeat(1, 1fr);
    overflow-y: scroll;
  }
`;

export const FeedbackModalBodyCard = styled.div`
  border-radius: 0.5rem;
  width: 159px;
  height: 159px;
  ${flexColCenterCenter}
  gap: 1rem 0rem;
  background: ${({ theme }) => theme.colors.bodyColor};
  cursor: pointer;
  transition: ${transition};

  :hover {
    transform: scale(1.1);
  }

  img {
    width: 40px;
    height: 40px;
  }

  p {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.screenTitleColor};
    margin: 0;
  }
`;

export const FeedbackModalModalContainer = styled.div`
  padding: 24px 8px 0px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const FeedbackModalEmptyStateContainer = styled.div<{
  customFeedback?: boolean;
}>`
  width: 500px;
  text-align: center;
  height: ${({ customFeedback }) => (customFeedback ? "250px" : "300px")};
  margin: 0 auto;
  overflow: hidden;

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 1rem;
  }

  img {
    margin: 0;
  }
`;

export const ChartButton = styled.button<{
  added: boolean;
  disabled: boolean;
}>`
  ${flexCenterCenter}
  gap: 0rem 0.5rem;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: transparent;
  outline: 0;
  border: 1px solid
    ${({ theme, added }) =>
      added ? theme.colors.success : theme.colors.primaryColor};
  color: ${({ theme, added }) =>
    added ? theme.colors.success : theme.colors.primaryColor};
  padding: 0.7rem;
  transition: ${transition};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? "0.7" : "1")};

  span {
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  }

  :hover {
    background: ${({ theme, added }) =>
      added ? "transparent" : theme.colors.primaryColor};
    border: 1px solid "transparent";
    color: ${({ theme, added }) =>
      added ? theme.colors.success : theme.colors.buttonColor};
  }
`;

export const ChartsContainerTitle = styled.p`
  ${regularRegularFont}
  color: ${({ theme }) => theme.colors.titleColor};

  span {
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

// Charts Shared CSS Classes

export const ChartsContainerCSS = css`
  margin-top: 36px;
`;
