import { roundTo1DecimalPlace } from "../../../../../../utils/math-utils";
import { PatientNutritionReportDailySummaryDayDTOWithId } from "../create-patient-nutrition-report-daily-summary-day-dto-with-ids";
import { getSelectedDaysCount } from "./get-selected-days-count";

export function calculateNumberOfMealsTotalsAndAverages(
  selectedDays: PatientNutritionReportDailySummaryDayDTOWithId[]
) {
  const daysNumberOfMeals = selectedDays.map((item) => item.totalNumberOfMeals);

  let numberOfMealsSum = 0;
  let numberOfMealsAvg = 0;

  for (const dayNumberOfMeals of daysNumberOfMeals) {
    numberOfMealsSum += dayNumberOfMeals;
    numberOfMealsAvg = numberOfMealsSum / getSelectedDaysCount(selectedDays);
  }

  return {
    numberOfMealsSum,
    numberOfMealsAvg: roundTo1DecimalPlace(numberOfMealsAvg),
  };
}
