import styled from "styled-components/macro";
import {
  flexCenter,
  mediumSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";

export const ContentModuleLessonMobilePreviewHeaderContainer = styled.div`
  ${flexCenter}
  padding: 8px;
  margin-bottom: auto;
  height: 30px;

  span {
    color: ${({ theme }) => theme.colors.textColor};
    cursor: pointer;
  }

  p {
    text-align: center;
    width: 100%;
    margin: 0px;
    margin-right: 24px;
    ${mediumSemiBoldFont}
  }
`;
