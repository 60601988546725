import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  PateintProfileColumnHeaderContainer,
  PateintProfileColumnHeaderTitle,
  PateintProfileColumnHeaderTitleContainer,
  PateintProfileColumnHeaderTitleIconContainer,
} from "../../../components/pateint-profile-column-header/styled-pateint-profile-column-header";
import {
  PatientProfileLifeActivitiesHeaderDateRange,
  PatientProfileLifeActivitiesHeaderSummaryBottom,
  PatientProfileLifeActivitiesHeaderSummaryBottomDot,
  PatientProfileLifeActivitiesHeaderSummaryBottomText,
  PatientProfileLifeActivitiesHeaderSummaryCardsContainer,
  PatientProfileLifeActivitiesHeaderSummaryContainer,
  PatientProfileLifeActivitiesHeaderSummaryTitle,
} from "./styled-patient-profile-life-activities-header";
import { useMemo } from "react";
import { PatientActivityLogStatsDTO } from "../../../../../../models/patient-activity-log-dtos/patient-activity-log-stats-dto";
import { usePatientProfileLifeActivities } from "../context/patient-profile-life-activities-context";

type PatientProfileLifeActivitiesHeaderProps = {
  patientActivityLogStatsDTO: PatientActivityLogStatsDTO;
};

export const PatientProfileLifeActivitiesHeader = (
  props: PatientProfileLifeActivitiesHeaderProps
) => {
  const { patientActivityLogStatsDTO } = props;

  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = usePatientProfileLifeActivities();

  const aerobicActivityTotalMins = useMemo(
    () =>
      patientActivityLogStatsDTO.activityModes.find(
        (activity) => activity.activityMode?.name === "Aerobic Activity"
      )?.totalMins,
    [patientActivityLogStatsDTO]
  );

  const anaerobicActivityTotalMins = useMemo(
    () =>
      patientActivityLogStatsDTO.activityModes.find(
        (activity) => activity.activityMode?.name === "Anaerobic Activity"
      )?.totalMins,
    [patientActivityLogStatsDTO]
  );

  const mixedActivityTotalMins = useMemo(
    () =>
      patientActivityLogStatsDTO.activityModes.find(
        (activity) => activity.activityMode?.name === "Mixed Activity"
      )?.totalMins,
    [patientActivityLogStatsDTO]
  );

  const otherActivityTotalMins = useMemo(
    () =>
      patientActivityLogStatsDTO.activityModes.find(
        (activity) => activity.activityMode?.name === undefined
      )?.totalMins,
    [patientActivityLogStatsDTO]
  );

  const cards = buildPatientProfileLifeActivitiesHeaderSummaryData(
    aerobicActivityTotalMins,
    anaerobicActivityTotalMins,
    mixedActivityTotalMins,
    otherActivityTotalMins
  ).map((item) => (
    <PatientProfileLifeActivitiesHeaderSummaryContainer key={item.id}>
      <PatientProfileLifeActivitiesHeaderSummaryTitle>
        {item.mins} <span>mins</span>
      </PatientProfileLifeActivitiesHeaderSummaryTitle>
      <PatientProfileLifeActivitiesHeaderSummaryBottom>
        <PatientProfileLifeActivitiesHeaderSummaryBottomDot
          color={item.dotColor}
        />
        <PatientProfileLifeActivitiesHeaderSummaryBottomText
          positiveFeedback={item.positiveFeedback}
        >
          <h4>{item.type}</h4>

          {/* <div>
            <span className="material-symbols-outlined">arrow_drop_down</span>
            <p>{item.feedback}</p>
          </div> */}
        </PatientProfileLifeActivitiesHeaderSummaryBottomText>
      </PatientProfileLifeActivitiesHeaderSummaryBottom>
    </PatientProfileLifeActivitiesHeaderSummaryContainer>
  ));

  return (
    <PateintProfileColumnHeaderContainer lifeActivities>
      <PateintProfileColumnHeaderTitleContainer>
        <PateintProfileColumnHeaderTitleIconContainer>
          <img src={`/img/plans/nav/life-style.svg`} alt="lifestyle" />
        </PateintProfileColumnHeaderTitleIconContainer>
        <PateintProfileColumnHeaderTitle>
          Activities Log
        </PateintProfileColumnHeaderTitle>
      </PateintProfileColumnHeaderTitleContainer>
      <PatientProfileLifeActivitiesHeaderSummaryCardsContainer>
        {cards}
      </PatientProfileLifeActivitiesHeaderSummaryCardsContainer>
      <PatientProfileLifeActivitiesHeaderDateRange>
        <DateRangePickerComponent
          placeholder="Select date range"
          format="dd/ M /yyyy"
          separator="-"
          startDate={startDate}
          endDate={endDate}
          onChange={(e: any) => {
            if (e.target.value !== null) {
              setStartDate(e.target.value[0]);
              setEndDate(e.target.value[1]);
            }
          }}
        />
      </PatientProfileLifeActivitiesHeaderDateRange>
    </PateintProfileColumnHeaderContainer>
  );
};

function buildPatientProfileLifeActivitiesHeaderSummaryData(
  aerobicActivityTotalMins?: number,
  anaerobicActivityTotalMins?: number,
  mixedActivityTotalMins?: number,
  otherActivityTotalMins?: number
) {
  const patientProfileLifeActivitiesHeaderSummary = [
    {
      id: 0,
      mins:
        aerobicActivityTotalMins !== undefined ? aerobicActivityTotalMins : 0,
      type: "Aerobic",
      positiveFeedback: true,
      feedback: 5,
      dotColor: "#1097CF",
    },
    {
      id: 1,
      mins:
        anaerobicActivityTotalMins !== undefined
          ? anaerobicActivityTotalMins
          : 0,
      type: "Anaerobic",
      positiveFeedback: false,
      feedback: 6,
      dotColor: "#3E66FB",
    },
    {
      id: 2,
      mins: mixedActivityTotalMins !== undefined ? mixedActivityTotalMins : 0,
      type: "Mix",
      positiveFeedback: true,
      feedback: 7,
      dotColor: "#00086B",
    },
    {
      id: 3,
      mins: otherActivityTotalMins !== undefined ? otherActivityTotalMins : 0,
      type: "Other",
      positiveFeedback: true,
      feedback: 4,
      dotColor: "#4682B4",
    },
  ];

  return patientProfileLifeActivitiesHeaderSummary;
}
