import { PropsWithChildren, createContext, useContext } from "react";
import { UseLoadableDataReturn } from "../../../hooks/use-loadable-data";
import { TargetAudienceDTO } from "../../../models/target-audience-dtos/target-audience-dto";

export type TContentModuleTargetAudience = {
  targetAudienceDTOs: TargetAudienceDTO[];
};

export type LoadableContentModuleTargetAudience =
  UseLoadableDataReturn<TContentModuleTargetAudience>;

const LoadableContentModuleTargetAudienceContext = createContext<
  LoadableContentModuleTargetAudience | undefined
>(undefined);

export type LoadableContentModuleTargetAudienceProviderProps =
  PropsWithChildren<{
    loadableContentModuleTargetAudience: LoadableContentModuleTargetAudience;
  }>;

export const LoadableContentModuleTargetAudienceProvider = (
  props: LoadableContentModuleTargetAudienceProviderProps
) => {
  const { loadableContentModuleTargetAudience, children } = props;

  return (
    <LoadableContentModuleTargetAudienceContext.Provider
      value={loadableContentModuleTargetAudience}
    >
      {children}
    </LoadableContentModuleTargetAudienceContext.Provider>
  );
};

export const useLoadableContentModuleTargetAudience =
  (): LoadableContentModuleTargetAudience => {
    const LoadableContentModuleTargetAudience = useContext(
      LoadableContentModuleTargetAudienceContext
    );

    if (LoadableContentModuleTargetAudience === undefined) {
      throw Error(
        "useLoadableContentModuleTargetAudience must be used within an LoadableContentModuleTargetAudienceProvider"
      );
    }

    return LoadableContentModuleTargetAudience;
  };

export const useContentModulesTargetAudience =
  (): TContentModuleTargetAudience => {
    const LoadableContentModuleTargetAudience =
      useLoadableContentModuleTargetAudience();

    if (LoadableContentModuleTargetAudience.state.data === undefined) {
      throw Error(
        "Content Module target audience have never been loaded successfully yet."
      );
    }

    return LoadableContentModuleTargetAudience.state.data;
  };

type UseReloadContentModulesTargetAudience = () => void;

export const useReloadContentModulesTargetAudience =
  (): UseReloadContentModulesTargetAudience => {
    const LoadableContentModuleTargetAudience =
      useLoadableContentModuleTargetAudience();

    return LoadableContentModuleTargetAudience.reload;
  };
