import { CreateContentModuleCardDTO } from "../../../../../../models/content-module-dtos/create-content-module-card-dto";
import {
  ContentModuleLessonMobilePreviewCardHeader,
  ContentModuleLessonMobilePreviewCardListItem,
} from "../../mobile-preview-lesson-cards-styles";
import { CreateContentModuleLessonMobilePreviewCardParts } from "./create-content-module-lesson-mobile-preview-card-parts";

type CreateContentModuleLessonMobilePreviewCardProps = {
  card: CreateContentModuleCardDTO;
  activeCard: boolean;
};

export const CreateContentModuleLessonMobilePreviewCard = (
  props: CreateContentModuleLessonMobilePreviewCardProps
) => {
  const { card, activeCard } = props;

  return (
    <ContentModuleLessonMobilePreviewCardListItem $activeCard={activeCard}>
      <ContentModuleLessonMobilePreviewCardHeader>
        <h6 dir="auto">{card.title}</h6>
      </ContentModuleLessonMobilePreviewCardHeader>
      <CreateContentModuleLessonMobilePreviewCardParts
        cardParts={card.contentModuleCardParts}
      />
    </ContentModuleLessonMobilePreviewCardListItem>
  );
};
