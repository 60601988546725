import { css } from "styled-components";
import {
  flexCenter,
  flexCenterSpaceBetween,
  mediumFont,
  mediumRegularFont,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";
import {
  TDContainer,
  TableContainer,
} from "../../../../../components/table/styled-table";
import { PaitnetProfileChartContainer } from "../../styles/patient-profile-styles/patient-profile-classes";
import { EmptyStateBody } from "../../../../../components/empty-state/styled-empty-state";
import { Button } from "../../../../../styles/classes/reusable-classes";

// Modals Contains Table & Chart
export const PatientProfileModalTableAndChartWrapperCSS = css`
  overflow-y: scroll;

  th {
    div {
      color: ${({ theme }) => theme.colors.textColor};
      border: 0px solid transparent;
      margin: 0px;
    }
  }

  tr {
    input {
      background: ${({ theme }) => `${theme.newColors.inputColorAlt}80`};
      border: 0;
      padding: 12px 12px 8px;
      margin-top: 16px;
      ${mediumRegularFont}
    }
  }

  ${TDContainer} {
    position: relative;
    margin: 0px;

    strong {
      position: absolute;
      left: 150px;
      top: 50%;
      ${mediumFont}
      color: ${({ theme }) => theme.colors.titleColor};
    }
  }

  tr:nth-child(odd) {
    background: ${({ theme }) => theme.colors.containerColor};
  }

  table {
    border: 0px;
  }

  ${TableContainer} {
    padding: 0px;
  }

  ${PaitnetProfileChartContainer} {
    height: 200px;
    margin-top: 0px;
  }

  ${EmptyStateBody} {
    height: 50%;

    img {
      height: 100px;
    }

    p {
      ${regularMediumFont}
    }
  }
`;

export const PatientProfileModalTableAndChartTableContainerCSS = css`
  margin: 24px 24px 24px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 16px;
  overflow-y: scroll;
  padding-bottom: 16px;
`;

export const PatientProfileModalTableAndChartTableHeaderContainerCSS = css`
  ${flexCenterSpaceBetween}
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 16px 24px;

  ${Button} {
    padding: 8px 16px;
    gap: 0px 8px;
    font-weight: 500;
  }

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
  }
`;

export const PatientProfileTableAndChartContainerHeaderContainerCSS = css`
  ${flexCenter}
  gap: 0px 8px;
`;

export const PatientProfileTableAndChartContainerHeaderDateContainerCSS = css`
  background: ${({ theme }) => theme.colors.inputColor};
  width: 200px;
  height: 40px;
  border-radius: 8px;
  padding: 8px 16px;
  margin-left: 49px;
  position: relative;

  input {
    border: 0;
    outline: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }
`;
