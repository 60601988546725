import { PateintProfileColumnHeader } from "../../../../../single-patient/patient-profile/components/pateint-profile-column-header/pateint-profile-column-header";
import {
  PatientProfileMedicalHistoryItemContainer,
  PatientProfileMedicalHistoryItemTextContainer,
  PatientProfileMedicalHistoryItemTitleContainer,
} from "../../../../../single-patient/patient-profile/patient-profile-medical-history-section/patient-profile-medical-history/styled-profile-medical-history";
import {
  PatientProfileTimeLineDateContainer,
  PatientProfileTimeLineList,
  PatientProfileTimeLineListItem,
  PatientProfileTimeLineProgressBarContainer,
  PatientProfileTimeLineProgressBarEllipse,
  PatientProfileTimeLineProgressBarLine,
  PatientProfileTimeLineTextContainer,
} from "../../../../../single-patient/patient-profile/patient-profile-medical-history-section/patient-profile-timeline/styled-patient-profile-timeline";
import { PatientProfileContainerBody } from "../../../../../single-patient/patient-profile/styles/patient-profile-styles/patient-profile-classes";
import {
  ImmediateAttentionPatientsSidebarHistoryPatientProfileItemsContainer,
  ImmediateAttentionPatientsSidebarHistoryTabContainer,
} from "./styled-immediate-attention-patients-sidebar-history-tab";

export const ImmediateAttentionPatientsSidebarHistoryTab = () => {
  return (
    <ImmediateAttentionPatientsSidebarHistoryTabContainer>
      <PateintProfileColumnHeader
        icon="calendar_month"
        title="Timeline"
        button={<></>}
      />
      <PatientProfileContainerBody>
        <PatientProfileTimeLineList>
          <PatientProfileTimeLineListItem>
            <PatientProfileTimeLineDateContainer>
              <p>Jan</p>
              <span>2022</span>
            </PatientProfileTimeLineDateContainer>
            <PatientProfileTimeLineProgressBarContainer>
              <PatientProfileTimeLineProgressBarEllipse />
              <PatientProfileTimeLineProgressBarLine />
            </PatientProfileTimeLineProgressBarContainer>
            <PatientProfileTimeLineTextContainer>
              <p>Gastric Bypass</p>
            </PatientProfileTimeLineTextContainer>
          </PatientProfileTimeLineListItem>
          <PatientProfileTimeLineListItem>
            <PatientProfileTimeLineDateContainer>
              <p>May</p>
              <span>2017</span>
            </PatientProfileTimeLineDateContainer>
            <PatientProfileTimeLineProgressBarContainer>
              <PatientProfileTimeLineProgressBarEllipse />
              <PatientProfileTimeLineProgressBarLine />
            </PatientProfileTimeLineProgressBarContainer>
            <PatientProfileTimeLineTextContainer>
              <p>Stroke</p>
            </PatientProfileTimeLineTextContainer>
          </PatientProfileTimeLineListItem>
          <PatientProfileTimeLineListItem>
            <PatientProfileTimeLineDateContainer>
              <p>Mar</p>
              <span>2015</span>
            </PatientProfileTimeLineDateContainer>
            <PatientProfileTimeLineProgressBarContainer>
              <PatientProfileTimeLineProgressBarEllipse />
              <PatientProfileTimeLineProgressBarLine />
            </PatientProfileTimeLineProgressBarContainer>
            <PatientProfileTimeLineTextContainer>
              <p>Angina Pectoris</p>
            </PatientProfileTimeLineTextContainer>
          </PatientProfileTimeLineListItem>
          <PatientProfileTimeLineListItem>
            <PatientProfileTimeLineDateContainer>
              <p>Feb</p>
              <span>2013</span>
            </PatientProfileTimeLineDateContainer>
            <PatientProfileTimeLineProgressBarContainer>
              <PatientProfileTimeLineProgressBarEllipse />
              <PatientProfileTimeLineProgressBarLine />
            </PatientProfileTimeLineProgressBarContainer>
            <PatientProfileTimeLineTextContainer>
              <p>Type2</p>
              <span>A1c: 11.5</span>
            </PatientProfileTimeLineTextContainer>
          </PatientProfileTimeLineListItem>
        </PatientProfileTimeLineList>
        <ImmediateAttentionPatientsSidebarHistoryPatientProfileItemsContainer>
          <PatientProfileMedicalHistoryItemContainer>
            <PatientProfileMedicalHistoryItemTitleContainer>
              <img
                src="/img/patient-profile/medical-history/diabetes.svg"
                alt=""
              />
              <p>Diabetes Emergencies</p>
            </PatientProfileMedicalHistoryItemTitleContainer>
            <PatientProfileMedicalHistoryItemTextContainer>
              <p>Diabetic Ketoacidosis</p>
            </PatientProfileMedicalHistoryItemTextContainer>
          </PatientProfileMedicalHistoryItemContainer>
          <PatientProfileMedicalHistoryItemContainer>
            <PatientProfileMedicalHistoryItemTitleContainer>
              <img
                src="/img/patient-profile/medical-history/sugar.svg"
                alt=""
              />
              <p>Surgery</p>
            </PatientProfileMedicalHistoryItemTitleContainer>
            <PatientProfileMedicalHistoryItemTextContainer>
              <p>Liposuction</p>
            </PatientProfileMedicalHistoryItemTextContainer>
          </PatientProfileMedicalHistoryItemContainer>
          <PatientProfileMedicalHistoryItemContainer>
            <PatientProfileMedicalHistoryItemTitleContainer>
              <img
                src="/img/patient-profile/medical-history/family.svg"
                alt=""
              />
              <p>Family disease</p>
            </PatientProfileMedicalHistoryItemTitleContainer>
            <PatientProfileMedicalHistoryItemTextContainer>
              <div>
                <p>
                  Father: ( Diabetes Type 2 ) Mother: ( Obesity, Hypertension )
                </p>
              </div>
            </PatientProfileMedicalHistoryItemTextContainer>
          </PatientProfileMedicalHistoryItemContainer>
        </ImmediateAttentionPatientsSidebarHistoryPatientProfileItemsContainer>
      </PatientProfileContainerBody>
    </ImmediateAttentionPatientsSidebarHistoryTabContainer>
  );
};
