import { Temporal } from "temporal-polyfill";
import { DiagnosisModal } from "./diagnosis-modal/diagnosis-modal";
import {
  PersonalInfoGroup,
  PersonalInfoGroupTop,
  PersonalInfoGroupTitle,
  PersonalInfoGroupBottom,
  PersonalInfoItem,
  DiagnosedDate,
} from "../styled-personal-info-tab";
import { useSinglePatientSidebar } from "../../../context/loadable-single-patient-sidebar-context";

export const Diagnosis = () => {
  const { patientProfileDiagnoses, patientDTO } = useSinglePatientSidebar();

  const isDefinedPatientProfileDiagnoses =
    patientProfileDiagnoses !== undefined;

  const diagnoses = isDefinedPatientProfileDiagnoses ? (
    patientProfileDiagnoses.map((diagnose, index) => (
      <PersonalInfoItem key={index}>
        {diagnose.profileDiagnosis.name}
      </PersonalInfoItem>
    ))
  ) : (
    <></>
  );

  const diagnosedSince =
    patientDTO.diagnosedSince !== undefined
      ? Temporal.PlainDate.from(
          `${patientDTO.diagnosedSince}-01`
        ).toLocaleString("en-UK", {
          day: undefined,
          month: "short",
          year: "numeric",
        })
      : "";

  return (
    <PersonalInfoGroup>
      <PersonalInfoGroupTop>
        <PersonalInfoGroupTitle>Diagnosis</PersonalInfoGroupTitle>
        <DiagnosisModal />
      </PersonalInfoGroupTop>
      <PersonalInfoGroupBottom>
        <PersonalInfoItem>{patientDTO.type}</PersonalInfoItem>
        {diagnoses}
      </PersonalInfoGroupBottom>
      <DiagnosedDate>Diagnosed Date: {diagnosedSince}</DiagnosedDate>
    </PersonalInfoGroup>
  );
};
