import styled from "styled-components/macro";
import {
  flexCenterSpaceAround,
  flexCenterSpaceBetween,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const DailyMealGraphsHeaderContainer = styled.div`
  ${flexCenterSpaceBetween}
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: ${({ theme }) => theme.padding.pad05_1};
  background: ${({ theme }) => theme.colors.containerColor};

  @media screen and (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DailyMealGraphsHeaderTitle = styled.p`
  ${regularMediumFont}
  color: ${({ theme }) => theme.colors.buttonColorAlt};
`;

export const DailyMealGraphsHeaderRightContainer = styled.div`
  ${flexCenterSpaceAround}
  gap: 0rem 1.5rem;

  @media screen and (max-width: 550px) {
    align-self: center;
  }
`;
