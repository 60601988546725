import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  mediumRegularFont,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../styles/global-style";

export const EducationListItemLeftContainer = styled.div`
  ${flexCenter}
  gap: 0px 16px;
`;

export const EducationListItem = styled.li<{ arabicFlex?: boolean }>`
  ${flexCenterSpaceBetween}
  flex-direction: ${({ arabicFlex }) => (arabicFlex ? "row-reverse" : "row")};
  padding: 12px 16px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  margin: 16px 0px;
  border-radius: 8px;

  ${EducationListItemLeftContainer} {
    flex-direction: ${({ arabicFlex }) => (arabicFlex ? "row-reverse" : "row")};
  }
`;

export const EducationListItemImageContainer = styled.div<{
  width?: number;
  height?: number;
}>`
  width: ${({ width }) => (width ? `${width}px` : "80px")};
  width: ${({ height }) => (height ? `${height}px` : "60px")};
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const EducationListItemTextContainer = styled.div<{
  arabicFlex?: boolean;
}>`
  ${({ arabicFlex }) =>
    arabicFlex &&
    `  display: flex;
  flex-direction: column;
  align-items: flex-end;`};

  p {
    margin: 8px 0px 4px;
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }

  a {
    ${flexCenter}
    gap: 0px 8px;
    color: ${({ theme }) => theme.colors.textColor};
    ${mediumRegularFont}
    margin-top: 8px;
    text-decoration: none;

    span {
    }
  }

  button {
    ${flexCenter}
    gap: 0px 8px;
    color: ${({ theme }) => theme.colors.textColor};
    ${mediumRegularFont}
    border: 0;
    outline: 0;
    background: transparent;
    padding: 0;

    span {
    }
  }
`;

export const EducationButtonsContainer = styled.div`
  ${flexCenter}
  gap: 0px 8px;

  button {
    ${flexCenter}
    ${mediumRegularFont}
    background: transparent;
    padding: ${({ theme }) => theme.padding.pad05All};
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    outline: 0;
    border-radius: 0.5rem;
    transition: ${transition};

    img {
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
      transition: ${transition};
    }

    :hover {
      color: ${({ theme }) => theme.colors.primaryColor};
      border: 1px solid ${({ theme }) => theme.colors.primaryColor};
    }
  }
`;
