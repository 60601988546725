import styled from "styled-components/macro";
import { mediumFont } from "../../../../../../../../../../styles/classes/gloabl-classes";

export const MealsAnalysisResponseCarbServingsTableCellCarbSourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const MealsAnalysisResponseCarbServingsTableCellCarbSourcesList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
  max-height: 110px;
  margin: 4px 0px 0px;
`;

export const MealsAnalysisResponseCarbServingsTableCellCarbSourcesListItem = styled.li`
  ${mediumFont}
  background: ${({ theme }) => theme.newColors.newBorderColorAlt};
  color: ${({ theme }) => theme.colors.titleColor};
  border: 1px solid ${({ theme }) => theme.newColors.newBorderColor};
  border-radius: 94px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  width: max-content;
  height: 25px;
`;
