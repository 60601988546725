import { PropsWithChildren } from "react";
import { useDropdown } from "./hooks/use-dropdown";
import {
  DropdownButtonIcon,
  DropdownButton,
  DropdownContainer,
  DropdownList,
} from "./styled-dropdown";

export type VariantType =
  | "outline"
  | "fill"
  | "outline-dark"
  | "pagination"
  | "designed-ux";

type DropdownProps = PropsWithChildren<{
  text: string;
  variant?: VariantType;
  fullWidth?: boolean;
  height?: number;
  width?: number;
  disabled?: boolean;
  modalDropdown?: boolean;
}>;

export const Dropdown = (props: DropdownProps) => {
  const {
    children,
    text,
    variant,
    fullWidth,
    modalDropdown,
    width,
    disabled,
  } = props;

  const {
    dropdownContainerRef,
    showDropdown,
    hideDropdown,
    toggleDropdown,
  } = useDropdown();

  const currentVariant = variant || "outline";

  if (currentVariant === "designed-ux") {
    return (
      <DropdownContainer
        ref={dropdownContainerRef}
        width={width}
        fullWidth={fullWidth}
      >
        <DropdownButton
          showDropdown={showDropdown}
          onClick={toggleDropdown}
          type="button"
          titleColor={!text.startsWith("Select") && !text.startsWith("Choose")}
          variant="designed-ux"
          disabled={disabled}
        >
          {text}
          {!disabled && (
            <span className="material-symbols-outlined">expand_more</span>
          )}
        </DropdownButton>
        <DropdownList
          variant={currentVariant}
          onClick={hideDropdown}
          showDropdown={showDropdown}
        >
          {children}
        </DropdownList>
      </DropdownContainer>
    );
  }

  return (
    <DropdownContainer
      width={width}
      fullWidth={fullWidth}
      ref={dropdownContainerRef}
      modalDropdown={modalDropdown}
      variant={variant}
      data-testid="dropdown"
    >
      <DropdownButton
        type="button"
        showDropdown={showDropdown}
        onClick={toggleDropdown}
        variant={currentVariant}
        disabled={disabled}
        data-testid="dropdown-button"
        titleColor={!text.startsWith("Select") && !text.startsWith("Choose")}
      >
        {currentVariant === "fill" && (
          <span
            data-testid="dropdown-icon"
            className="material-symbols-outlined add-icon"
          >
            add_circle_outline
          </span>
        )}
        <p>{text}</p>
        <DropdownButtonIcon
          variant={currentVariant}
          showDropdown={showDropdown}
          disabled={disabled}
        >
          <span
            data-testid="dropdown-icon"
            className="material-symbols-outlined"
          >
            expand_more
          </span>
        </DropdownButtonIcon>
      </DropdownButton>
      <DropdownList
        variant={currentVariant}
        showDropdown={showDropdown}
        onClick={hideDropdown}
      >
        {children}
      </DropdownList>
    </DropdownContainer>
  );
};
