import { Controller, useFormContext } from "react-hook-form";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileModalLabelContainer,
} from "../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileEditProfileInputs } from "../patient-profile-edit-profile-modal";

export const PatientProfileEditProfilePatientNameFormControl = () => {
  const { control } = useFormContext<PatientProfileEditProfileInputs>();

  return (
    <PatientProfileModalFormControl>
      <PatientProfileModalLabelContainer>
        <label>Patient name</label>
      </PatientProfileModalLabelContainer>
      <PatientProfileModalInputContainer hasTwoInputsFlex>
        <Controller
          name="name"
          control={control}
          rules={{ required: false }}
          render={({ field: { value, onChange } }) => (
            <input
              type="text"
              placeholder="Patient Name"
              value={value !== undefined ? value : ""}
              onChange={(e) =>
                onChange(e.target.value !== undefined ? e.target.value : "")
              }
            />
          )}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
