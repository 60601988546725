import { DaysAggregatesReadingsWithTimeDifferenceValue } from "./build-readings-with-time-differance-value";

export function calculateReadingsTimeDiffrenceTotal(
  readingsWithTimeDifferenceValue: DaysAggregatesReadingsWithTimeDifferenceValue[]
) {
  let readingsTimeDiffrenceTotal: number = 0;

  for (const readingWithTimeDifferenceValue of readingsWithTimeDifferenceValue) {
    if (readingWithTimeDifferenceValue.timeDifference !== undefined) {
      if (readingWithTimeDifferenceValue.timeDifference > 15) {
        readingsTimeDiffrenceTotal += 15;
      } else {
        readingsTimeDiffrenceTotal +=
          readingWithTimeDifferenceValue.timeDifference;
      }
    }
  }

  return readingsTimeDiffrenceTotal;
}
