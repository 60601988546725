import { useState } from "react";
import {
  DoubleRangeInputContainer,
  DoubleRangeInputRange,
  DoubleRangeInputSlider,
  DoubleRangeInputTrack,
  LeftInputRangeContainer,
  RightInputRangeContainer,
} from "./styled-double-range-input";
import { useImmediateAttentionPatientsSelectedFilters } from "../../../../../context/immediate-attention-patients-selected-filters-context";

type DoubleRangeInputProps = {
  min: number;
  max: number;
  step: number;
  onChange: (minValue: number, maxValue: number) => void;
  ageFilter?: boolean;
};

export const DoubleRangeInput = (props: DoubleRangeInputProps) => {
  const { min, max, step, onChange, ageFilter } = props;

  const {
    maxAgeValue,
    minAgeValue,
    setMaxAgeValue,
    setMinAgeValue,
  } = useImmediateAttentionPatientsSelectedFilters();

  const [minValue, setMinValue] = useState<number>(min);
  const [maxValue, setMaxValue] = useState<number>(max);

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (ageFilter) {
      const value = Math.min(Number(e.target.value), maxAgeValue - step);
      setMinAgeValue(value);
      onChange(value, maxAgeValue);
    } else {
      const value = Math.min(Number(e.target.value), maxValue - step);
      setMinValue(value);
      onChange(value, maxValue);
    }
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (ageFilter) {
      const value = Math.max(Number(e.target.value), minAgeValue + step);
      setMaxAgeValue(value);
      onChange(minAgeValue, value);
    } else {
      const value = Math.max(Number(e.target.value), minValue + step);
      setMaxValue(value);
      onChange(minValue, value);
    }
  };

  const ageLeft = `${((minAgeValue - min) / (max - min)) * 100}%`;
  const anyLeft = `${((minValue - min) / (max - min)) * 100}%`;

  const ageRight = `${((max - maxAgeValue) / (max - min)) * 100}%`;
  const anyRight = `${((max - maxValue) / (max - min)) * 100}%`;

  return (
    <DoubleRangeInputContainer>
      <DoubleRangeInputSlider>
        <DoubleRangeInputTrack />
        <DoubleRangeInputRange
          $left={ageFilter ? ageLeft : anyLeft}
          $right={ageFilter ? ageRight : anyRight}
        />
        <LeftInputRangeContainer
          $left={ageFilter ? ageLeft : anyLeft}
          $content={ageFilter ? minAgeValue : minValue}
        >
          <input
            type="range"
            min={min}
            max={max}
            step={step}
            value={ageFilter ? minAgeValue : minValue}
            onChange={handleMinChange}
            className="thumb thumb-left"
            style={
              ageFilter
                ? { zIndex: minAgeValue > max - 100 ? "5" : "3" }
                : { zIndex: minValue > max - 100 ? "5" : "3" }
            }
          />
        </LeftInputRangeContainer>
        <RightInputRangeContainer
          $right={ageFilter ? ageRight : anyRight}
          $content={ageFilter ? maxAgeValue : maxValue}
        >
          <input
            type="range"
            min={min}
            max={max}
            step={step}
            value={ageFilter ? maxAgeValue : maxValue}
            onChange={handleMaxChange}
            className="thumb thumb-right"
          />
        </RightInputRangeContainer>
      </DoubleRangeInputSlider>
    </DoubleRangeInputContainer>
  );
};
