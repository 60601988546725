import { Loader, LoaderContainer } from "./styled-otida-loader";

export type OtidaLoaderProps = {
  fullScreen?: boolean;
};

export const OtidaLoader = (props: OtidaLoaderProps) => {
  const { fullScreen } = props;

  return (
    <LoaderContainer fullScreen={fullScreen}>
      <Loader />
      <img src="/img/logo-blue.png" alt="Otida" />
    </LoaderContainer>
  );
};
