import styled from "styled-components/macro";
import { NavbarHeight } from "../../../../styles/global-style";

export const ContentModuleLessonMobilePreviewWidth = `245px`;

export const ContentModuleLessonMobilePreviewContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  padding: 0px 12px 12px;
  position: relative;
  width: ${ContentModuleLessonMobilePreviewWidth};
  height: 531px;
  margin: 0px auto 0px;
  position: relative;
  display: grid;
  background: ${({ theme }) => theme.colors.containerColor};

  @media screen and (max-width: 1024px) {
    position: fixed;
    top: calc(${NavbarHeight} + 100px);
    right: 18px;
  }
`;
