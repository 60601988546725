import {
  FoodLoggingSelectedPatientMealMacrosBar,
  FoodLoggingSelectedPatientMealMacrosCarbs,
  FoodLoggingSelectedPatientMealMacrosContainer,
  FoodLoggingSelectedPatientMealMacrosFats,
  FoodLoggingSelectedPatientMealMacrosProtiens,
  FoodLoggingSelectedPatientMealMacrosTitleContainer,
} from "../../../../legacy-food-logging-selected-patient-meal/food-logging-selected-patient-meal-macros/styled-food-logging-selected-patient-meal-macros";
import { CarbCounterFoodLoggingSelectedPatientMealMacrosLegend } from "./carb-counter-food-logging-selected-patient-meal-macros-legend";

export const EmptyCarbCounterFoodLoggingSelectedPatientMealMacros = () => {
  return (
    <FoodLoggingSelectedPatientMealMacrosContainer>
      <FoodLoggingSelectedPatientMealMacrosTitleContainer>
        <p>Macronutrients</p>
        <span>0 Kcal</span>
      </FoodLoggingSelectedPatientMealMacrosTitleContainer>
      <FoodLoggingSelectedPatientMealMacrosBar>
        <FoodLoggingSelectedPatientMealMacrosCarbs width="0%" />
        <FoodLoggingSelectedPatientMealMacrosFats width="0%" />
        <FoodLoggingSelectedPatientMealMacrosProtiens width="0%" />
      </FoodLoggingSelectedPatientMealMacrosBar>
      <CarbCounterFoodLoggingSelectedPatientMealMacrosLegend />
    </FoodLoggingSelectedPatientMealMacrosContainer>
  );
};
