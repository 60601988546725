import { useEffect, useState } from "react";
import {
  Avatar,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";
import { StreamChatGenerics } from "../../../types/stream-chat-types";
import {
  MessagingChannelHeaderContainer,
  MessagingChannelHeaderImageContainer,
  MessagingChannelHeaderMenuIconContainer,
  MessagingChannelHeaderRight,
  MessagingChannelHeaderTextContainer,
} from "./styled-messaging-channel-header";
import { EditChannelModal } from "./edit-channel-modal/edit-channel-modal";
import { GenericActionsList } from "../../../../../components/generic-actions-list/generic-actions-list";
import { GenericActionsListItemItem } from "../../../../../components/generic-actions-list/styled-generic-actions-list";

type MessagingChannelHeaderProps = {
  handleShowSidebar: () => void;
  handleToggleMediaSidebar: () => void;
};

export const MessagingChannelHeader = (props: MessagingChannelHeaderProps) => {
  const { handleShowSidebar, handleToggleMediaSidebar } = props;

  const { client, setActiveChannel } = useChatContext<StreamChatGenerics>();
  const { channel } = useChannelStateContext<StreamChatGenerics>();
  const [title, setTitle] = useState("");

  const members = Object.values(channel.state.members || {}).filter(
    (member) => member.user?.id
  );

  useEffect(() => {
    if (!channel.data?.name) {
      setTitle(
        members
          .map(
            (member) => member.user?.name || member.user?.id || "Unnamed User"
          )
          .join(", ")
      );
    }
  }, [channel.data?.name, members]);

  const avatar = () => {
    const groupChannelSrc = "/img/chat/group.svg";
    const userAvatar = "/img/chat/user.svg";

    const isSingleUserChannel = members.length === 1;
    const singleUserChannelSrc =
      isSingleUserChannel && client.user?.image !== undefined
        ? client.user.image
        : userAvatar;

    const isNotGroupChannel = members.length <= 2;
    const channelAvatar = members.find(
      (member) => member.user?.id !== client.user?.id
    )?.user?.image;

    const twoUsersChannelSrc = isNotGroupChannel
      ? channelAvatar !== undefined
        ? channelAvatar
        : userAvatar
      : groupChannelSrc;

    return (
      <Avatar
        image={
          isSingleUserChannel
            ? singleUserChannelSrc
            : isNotGroupChannel
            ? twoUsersChannelSrc
            : groupChannelSrc
        }
        size={40}
        shape="circle"
      />
    );
  };

  const channelName =
    members.length <= 2
      ? members.find((member) => member.user?.id !== client.user?.id)?.user
          ?.name
      : channel.data?.name !== undefined
      ? channel.data.name
      : title;

  return (
    <MessagingChannelHeaderContainer>
      <MessagingChannelHeaderMenuIconContainer onClick={handleShowSidebar}>
        <img src="/img/chat/menu.svg" alt="menu" />
      </MessagingChannelHeaderMenuIconContainer>
      <MessagingChannelHeaderImageContainer>
        {avatar()}
      </MessagingChannelHeaderImageContainer>
      <MessagingChannelHeaderTextContainer onClick={handleToggleMediaSidebar}>
        <p>{channelName}</p>
        <span>
          {members
            .filter((member) => member.user?.id !== client.userID)
            .map((member) =>
              member.user?.name !== undefined
                ? `${member.user?.name}`
                : `${member.user?.id}`
            )
            .join(", ")}
          {members.some((member) => member.user?.id === client.userID)
            ? ", You"
            : ""}
        </span>
      </MessagingChannelHeaderTextContainer>
      <MessagingChannelHeaderRight>
        <GenericActionsList showMoreIconImg>
          {members.length > 2 && <EditChannelModal />}
          <GenericActionsListItemItem
            onClick={() => {
              setActiveChannel(undefined);
              handleShowSidebar();
            }}
          >
            Close Chat
          </GenericActionsListItemItem>
        </GenericActionsList>
      </MessagingChannelHeaderRight>
    </MessagingChannelHeaderContainer>
  );
};
