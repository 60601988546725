import { Modal } from "../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../components/modal/use-modal-helpers";
import { Button } from "../../../../../styles/classes/reusable-classes";
import { RowsActionsProvider } from "../../upload-options/manual-readings/manual-readings-modal/context/rows-actions-context";
import { ManualReadingsModal } from "../../upload-options/manual-readings/manual-readings-modal/manual-readings-modal";

export const AddManualReadings = () => {
  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  return (
    <div>
      <Button flex type="button" onClick={openModal}>
        <span className="material-symbols-outlined">add</span>
        Add Manual Readings
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <RowsActionsProvider>
          <ManualReadingsModal
            key={modalBodyKey}
            handleHideModal={closeModal}
          />
        </RowsActionsProvider>
      </Modal>
    </div>
  );
};
