import styled from "styled-components/macro";
import {
  flexCenter,
  smallMediumFont,
} from "../../../../../../../../../../styles/classes/gloabl-classes";
import { MealsAnalysisResponseTableCellMacrosInfoContainer } from "./meals-analysis-response-table-cell-macros-info/styled-meals-analysis-response-table-cell-macros-info";

export const MealsAnalysisResponseTableRowsMacrosContainer = styled.div`
  display: flex;
  gap: 0px 16px;
`;

export const MealsAnalysisResponseTableRowsMacrosBar = styled.div`
  height: 100px;
  width: 10px;
  border-radius: 6px;
  background: ${({ theme }) => theme.newColors.otidaO2};
  position: relative;

  :hover {
    ${MealsAnalysisResponseTableCellMacrosInfoContainer} {
      opacity: 1;
      height: 100%;
      overflow: visible;
    }
  }
`;

export const MealsAnalysisResponseTableRowsMacrosCarbs = styled.div<{
  $height: string;
}>`
  height: ${({ $height }) => $height};
  top: 0px;
  width: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.newColors.warningAlt};
  position: absolute;
  z-index: 100;
`;

export const MealsAnalysisResponseTableRowsMacrosFats = styled.div<{
  $height: string;
}>`
  height: ${({ $height }) => $height};
  top: 0px;
  width: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.primaryColor};
  position: absolute;
  z-index: 50;
`;

export const MealsAnalysisResponseTableRowsMacrosProtiens = styled.div<{
  $height: string;
}>`
  height: ${({ $height }) => $height};
  max-height: 100%;
  top: 0px;
  width: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.primaryColorAlt};
  position: absolute;
  z-index: 10;
`;

export const MealsAnalysisResponseTableRowsMacrosLegend = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0px 8px;
  margin-top: 8px;

  strong {
    color: ${({ theme }) => theme.colors.textColor};
    font-weight: 600;
    text-align: left;
  }
`;

export const MealsAnalysisResponseTableRowsMacrosLegendPoint = styled.div`
  ${flexCenter}

  :first-child > p {
    ::before {
      background: ${({ theme }) => theme.newColors.warningAlt};
    }
  }

  :nth-child(2) > p {
    ::before {
      background: ${({ theme }) => theme.colors.primaryColor};
    }
  }

  :nth-child(3) > p {
    ::before {
      background: ${({ theme }) => theme.colors.primaryColorAlt};
    }
  }

  p {
    position: relative;
    margin: 0px;
    color: ${({ theme }) => theme.colors.titleColor};
    margin-left: 0.5rem;
    ${smallMediumFont}

    ::before {
      content: "";
      position: absolute;
      left: -15px;
      top: 10%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
`;
