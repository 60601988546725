import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";

type NutritionReportFruitsTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportFruitsTableCell = (
  props: NutritionReportFruitsTableCellProps
) => {
  const { kind } = props;

  const { fruitsRow, fruitsMeal } = useMemo(() => {
    const fruitsRow =
      kind.kind === "Row"
        ? kind.data.foodGroups.find((item) => item.foodGroup.id === 3)
        : undefined;

    const fruitsMeal =
      kind.kind === "Meal"
        ? kind.data.foodGroups.find((item) => item.foodGroup.id === 3)
        : undefined;

    return { fruitsRow, fruitsMeal };
  }, [kind.data, kind.kind]);

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      value={
        kind.kind === "Row"
          ? fruitsRow?.totalServingsCount
          : fruitsMeal?.servingsCount
      }
    />
  );
};
