import { Controller, useFormContext } from "react-hook-form";
import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../styles/classes/reusable-classes";
import { CreateLessonCardParts } from "./create-lesson-card-parts/create-lesson-card-parts";
import { CreateLessonInputs } from "../../../create-lesson";

import { useState } from "react";
import {
  LessonCardContainer,
  LessonCardHeader,
} from "../../../../../styles/content-module-lesson-classes";
import { useCardPartsLoading } from "../../../../context/card-parts-loading-context";

type CreateLessonCardProps = {
  cardIndex: number;
  remove: () => void;
};

export const CreateLessonCard = (props: CreateLessonCardProps) => {
  const { cardIndex, remove } = props;

  const { cardPartsLoading } = useCardPartsLoading();

  const [showDropdown, setShowDropdown] = useState(true);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const { control } = useFormContext<CreateLessonInputs>();

  return (
    <LessonCardContainer $showParts={showDropdown}>
      <LessonCardHeader>
        <Controller
          control={control}
          name={`contentModuleCards.${cardIndex}.title`}
          render={({ field: { value, onChange } }) => (
            <UXInputContainer>
              <UXInputLabel $width="100px">Card Title</UXInputLabel>
              <UXInputWrapper
                $width="100%"
                className="content-module-name-input-wrapper"
              >
                <UXInputInput
                  dir="auto"
                  type="text"
                  placeholder="Enter Card Title"
                  value={value === undefined ? "" : value}
                  onChange={(e) =>
                    onChange(e.target.value === undefined ? "" : e.target.value)
                  }
                />
              </UXInputWrapper>
            </UXInputContainer>
          )}
        />
        {!cardPartsLoading.loading && (
          <span onClick={remove}>
            <img src="/img/trash.svg" alt="Trash Icon" />
          </span>
        )}
        <span
          className="material-symbols-outlined show-parts-arrow"
          onClick={toggleDropdown}
        >
          expand_more
        </span>
      </LessonCardHeader>
      <CreateLessonCardParts cardIndex={cardIndex} />
    </LessonCardContainer>
  );
};
