import { Outlet, useParams } from "react-router-dom";
import {
  ScreenSubNavbar,
  SubNavLinksType,
} from "../../../components/screen-sub-navbar/screen-sub-navbar";
import { NutritionGolasLastFoodGroupSelectedProvider } from "./nutrition-goals/context/nutrition-goals-last-food-group-selected-context";
import { NutritionGoalsDropdownTextProvider } from "./nutrition-goals/context/nutrition-goals-dropdown-text-context";
import { LoadableNutritionGoalsProvider } from "./nutrition-goals/context/loadable-nutrition-goals-context";
import { useGetNutritionRegimens } from "../../../hooks/nutrition-regimen-hooks/use-get-nutrition-regimens";
import { useGetActiveNutritionRegimen } from "../../../hooks/patient-nutrition-regimen-hooks/use-get-active-nutrition-regimen";
import { useCallback } from "react";
import { useLoadableData } from "../../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../../components/loadable-data-loading";
import { useGetPatient } from "../../../hooks/patient-hooks/use-get-patient";
import { SubScreenContainer } from "../../../styles/classes/reusable-classes";

export const DietitianReport = () => {
  const { id } = useParams();

  const getPatient = useGetPatient();
  const getNutritionRegimens = useGetNutritionRegimens();
  const getActiveNutritionRegimen = useGetActiveNutritionRegimen();

  const loadableNutritionGoalsCallback = useCallback(async () => {
    const patientId = parseInt(id!);

    const [patientDTO, nutritionRegimenDTOs, activeNutritionRegimen] =
      await Promise.all([
        getPatient(patientId),
        getNutritionRegimens(),
        getActiveNutritionRegimen(patientId),
      ]);

    return {
      patientDTO,
      nutritionRegimenDTOs,
      activeNutritionRegimen,
    };
  }, [id, getPatient, getNutritionRegimens, getActiveNutritionRegimen]);

  const loadableNutritionGoals = useLoadableData(
    loadableNutritionGoalsCallback
  );

  return (
    <>
      <ScreenSubNavbar links={buildBglNavLinks(id!)} />
      <SubScreenContainer>
        <LoadableNutritionGoalsProvider
          loadableNutritionGoals={loadableNutritionGoals}
        >
          <LoadableDataLoading
            state={loadableNutritionGoals.state}
            successComponent={() => (
              <NutritionGolasLastFoodGroupSelectedProvider>
                <NutritionGoalsDropdownTextProvider>
                  <Outlet />
                </NutritionGoalsDropdownTextProvider>
              </NutritionGolasLastFoodGroupSelectedProvider>
            )}
          />
        </LoadableNutritionGoalsProvider>
      </SubScreenContainer>
    </>
  );
};

function buildBglNavLinks(id: string): SubNavLinksType {
  const navLinks: SubNavLinksType = [
    {
      name: "Nutrition Goals",
      path: `/patients/${id}/dietitian-report/nutrition-goals`,
    },
    {
      name: "Nutrition Reports",
      path: `/patients/${id}/dietitian-report/nutrition-reports`,
    },
    {
      name: "Nutrition Analysis",
      path: `/patients/${id}/dietitian-report/nutrition-analysis`,
    },
  ];

  return navLinks;
}
