import { useFieldArray, useFormContext } from "react-hook-form";
import { CardPartsActionButton } from "../../../../../../components/card-parts-actions-button";
import {
  LessonCardPartsActions,
  LessonCardPartsList,
  LessonCardPartsListItem,
} from "../../../../../../styles/content-module-lesson-classes";
import { ContentModuleUpdateModeInputs } from "../../../../../../context/content-module-mode-context";
import { useCallback, useRef } from "react";
import { LessonUpdateModeCardTextPart } from "./lesson-update-mode-card-text-part";
import { LessonUpdateModeCardImagePart } from "./lesson-update-mode-card-image-part/lesson-update-mode-card-image-part";
import { PutContentModuleCardPartDTO } from "../../../../../../../../models/content-module-dtos/put-content-module-card-part-dto";
import { LessonUpdateModeCardVideoPart } from "./lesson-update-mode-card-video-part/lesson-update-mode-card-video-part";
import { useCardPartsLoading } from "../../../../../context/card-parts-loading-context";

type LessonUpdateModeCardPartsProps = {
  cardIndex: number;
};

export const LessonUpdateModeCardParts = (
  props: LessonUpdateModeCardPartsProps
) => {
  const { cardIndex } = props;

  const { cardPartsLoading } = useCardPartsLoading();

  const { control } = useFormContext<ContentModuleUpdateModeInputs>();

  const { append, fields, remove, move } = useFieldArray<
    ContentModuleUpdateModeInputs
  >({
    name: `contentModuleCards.${cardIndex}.contentModuleCardParts`,
    control,
  } as never);

  type ContentModuleCardPartsFields = PutContentModuleCardPartDTO & {
    id: string;
  };

  const contentModuleCardPartsFields = fields as ContentModuleCardPartsFields[];

  const handleRemove = useCallback(
    (partIndex: number) => {
      remove(partIndex);
    },
    [remove]
  );

  const dragItem = useRef<number>(0);
  const draggedOverItem = useRef<number>(0);

  const handleSort = useCallback(() => {
    move(dragItem.current, draggedOverItem.current);
  }, [move]);

  return (
    <div className="card-parts-list">
      <LessonCardPartsList>
        {contentModuleCardPartsFields.map((field, cardPartIndex) => (
          <LessonCardPartsListItem
            key={field.id}
            draggable={cardPartsLoading.cardIndex !== cardIndex}
            onDragStart={() => (dragItem.current = cardPartIndex)}
            onDragEnter={() => (draggedOverItem.current = cardPartIndex)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
          >
            {cardPartsLoading.cardIndex !== cardIndex && (
              <span className="material-symbols-outlined grab-lesson-card-part-icon">
                menu
              </span>
            )}
            {field.contentModuleCardPartType === "Text" && (
              <LessonUpdateModeCardTextPart
                cardIndex={cardIndex}
                cardPartIndex={cardPartIndex}
              />
            )}
            {field.contentModuleCardPartType === "Image" && (
              <LessonUpdateModeCardImagePart
                cardIndex={cardIndex}
                cardPartIndex={cardPartIndex}
              />
            )}
            {field.contentModuleCardPartType === "Video" && (
              <LessonUpdateModeCardVideoPart
                cardIndex={cardIndex}
                cardPartIndex={cardPartIndex}
              />
            )}
            {cardPartsLoading.cardIndex !== cardIndex && (
              <span
                className="remove-lesson-card-part-icon"
                onClick={() => handleRemove(cardPartIndex)}
              >
                <img src="/img/trash.svg" alt="Trash Icon" />
              </span>
            )}
          </LessonCardPartsListItem>
        ))}
      </LessonCardPartsList>
      <LessonCardPartsActions>
        <CardPartsActionButton
          handleClick={() =>
            append({
              contentModuleCardPartType: "Text",
              uiOrder: fields.length + 1,
              text: "",
            })
          }
          contentModuleCardPartType="Text"
        />
        <CardPartsActionButton
          handleClick={() =>
            append({
              contentModuleCardPartType: "Image",
              uiOrder: fields.length + 1,
            })
          }
          contentModuleCardPartType="Image"
        />
        <CardPartsActionButton
          handleClick={() =>
            append({
              contentModuleCardPartType: "Video",
              uiOrder: fields.length + 1,
            })
          }
          contentModuleCardPartType="Video"
        />
      </LessonCardPartsActions>
    </div>
  );
};
