import { useState } from "react";
import { PatientMealImageDTO } from "../../../../../../models/patient-meal-dtos/patient-meal-image-dto";
import {
  FoodLoggingSelectedPatientMealImagesModalContainer,
  FoodLoggingSelectedPatientMealImagesModalIndicatorsContainer,
  FoodLoggingSelectedPatientMealImagesModalIndicatorsImageContainer,
  FoodLoggingSelectedPatientMealImagesModalSlide,
  FoodLoggingSelectedPatientMealImagesModalSlideArrow,
  FoodLoggingSelectedPatientMealImagesModalSlideImage,
} from "./styled-food-logging-selected-patient-meal-images-modal";

type FoodLoggingSelectedPatientMealImagesModalProps = {
  patientMealImageDTOs: PatientMealImageDTO[];
};

export const FoodLoggingSelectedPatientMealImagesModal = (
  props: FoodLoggingSelectedPatientMealImagesModalProps
) => {
  const { patientMealImageDTOs } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <FoodLoggingSelectedPatientMealImagesModalContainer>
      <FoodLoggingSelectedPatientMealImagesModalSlideArrow
        className="material-symbols-outlined"
        onClick={() =>
          setActiveIndex(
            activeIndex < 1 ? patientMealImageDTOs.length - 1 : activeIndex - 1
          )
        }
      >
        arrow_back_ios
      </FoodLoggingSelectedPatientMealImagesModalSlideArrow>

      {patientMealImageDTOs.map((img, index) => (
        <FoodLoggingSelectedPatientMealImagesModalSlide
          key={index}
          active={index === activeIndex}
        >
          <FoodLoggingSelectedPatientMealImagesModalSlideImage
            src={img.publicUrl}
            alt={`Meal ${index}`}
          />
        </FoodLoggingSelectedPatientMealImagesModalSlide>
      ))}
      <FoodLoggingSelectedPatientMealImagesModalSlideArrow
        className="material-symbols-outlined"
        onClick={() =>
          setActiveIndex(
            activeIndex === patientMealImageDTOs.length - 1
              ? 0
              : activeIndex + 1
          )
        }
      >
        arrow_forward_ios
      </FoodLoggingSelectedPatientMealImagesModalSlideArrow>
      <FoodLoggingSelectedPatientMealImagesModalIndicatorsContainer>
        {patientMealImageDTOs.map((indicatorImage, index) => (
          <FoodLoggingSelectedPatientMealImagesModalIndicatorsImageContainer
            key={index}
            active={index === activeIndex}
            onClick={() => setActiveIndex(index)}
          >
            <img src={indicatorImage.publicUrl} alt={`Meal ${index}`} />
          </FoodLoggingSelectedPatientMealImagesModalIndicatorsImageContainer>
        ))}
      </FoodLoggingSelectedPatientMealImagesModalIndicatorsContainer>
    </FoodLoggingSelectedPatientMealImagesModalContainer>
  );
};
