import { AxisModel } from "@syncfusion/ej2-react-charts";

export function buildPrimaryXAxis(): AxisModel {
  return {
    valueType: "Category",
    edgeLabelPlacement: "Shift",
    labelFormat: "{value}",
    majorGridLines: {
      color: "transparent",
      width: 0,
    },
  };
}
