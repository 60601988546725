import styled from "styled-components/macro";
import { flexCol } from "../../../../../../styles/classes/gloabl-classes";

export const PersonalInfoKeyIndicator = styled.div`
  ${flexCol}

  span {
    font-size: ${({ theme }) => theme.typography.fontSize.smFontSize};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
    color: ${({ theme }) => theme.colors.textColor};

    strong {
      font-size: ${({ theme }) => theme.typography.fontSize.mdFontSize};
      color: ${({ theme }) => theme.colors.titleColor};
      font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
      margin-right: 0.3rem;
    }
  }
`;

export const PersonalInfoKeyIndicatorsBottom = styled.div`
  margin: 1rem 0rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem 2rem;
`;
