import styled from "styled-components/macro";

export const LabResultTestListsContainer = styled.div`
  padding-bottom: 7rem;
  position: relative;
`;

export const LabResultTestListsSubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 24px 0px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 14px;
  position: relative;
`;
