import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { PATIENTS_PATH } from "../../constants/paths";
import { PatientNoteDTO } from "../../models/patient-note-dtos/patient-note-dto";
import { CreatePatientNoteDTO } from "../../models/patient-note-dtos/create-patient-note-dto";

export function useAddPatientNote(): (
  patientId: number,
  createPatientNoteDTO: CreatePatientNoteDTO
) => Promise<PatientNoteDTO> {
  const client = useClient();

  return useCallback(
    async (patientId, createPatientNoteDTO) => {
      const response = await client.post<PatientNoteDTO>(
        `${PATIENTS_PATH}/${patientId}/notes`,
        createPatientNoteDTO
      );

      return response.data;
    },
    [client]
  );
}
