import { useState } from "react";
import { Modal } from "../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { BloodGlucoseContainer } from "./blood-glucose-modal-body/blood-glucose-container";
import { CustomFeedbackContainer } from "./custom-feedback/custom-feedback-container";
import { FeedbackModalBody } from "./feedback-modal-body/feedback-modal-body";
import { MealGraphsContainer } from "./meal-graphs-modal/meal-graphs-container";
import { WeightLossModalContainer } from "./weight-loss-modal/weight-loss-modal-container";
import { ManualReadingsModalContainer } from "./manual-readings-modal/manual-readings-modal-container";

export type CurrentFeedbackModal =
  | "Blood glucose"
  | "Meal graphs"
  | "Weight loss"
  | "Custom Feedback"
  | "Manual Readings"
  | "";

type VariantType = "outline" | undefined;
type FeedbackModalProps = {
  variant?: VariantType;
};

export const FeedbackModal = (props: FeedbackModalProps) => {
  const { variant } = props;

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  const [currentFeedbackModal, setCurrentFeedbackModal] = useState<
    CurrentFeedbackModal
  >("");

  const handleCloseModal = () => {
    closeModal();
    setCurrentFeedbackModal("");
  };

  return (
    <>
      <Button
        outlinedPrimary={variant !== undefined ? true : false}
        flex
        type="button"
        onClick={openModal}
      >
        <span className="material-symbols-outlined">add</span>
        Add feedback
      </Button>
      <Modal handleHideModal={handleCloseModal} showModal={isModalOpen}>
        {currentFeedbackModal === "" && (
          <FeedbackModalBody
            key={modalBodyKey}
            onClose={handleCloseModal}
            setCurrentFeedbackModal={setCurrentFeedbackModal}
          />
        )}
        {currentFeedbackModal === "Blood glucose" && (
          <BloodGlucoseContainer
            resetCurrentFeedbackModal={() => setCurrentFeedbackModal("")}
          />
        )}
        {currentFeedbackModal === "Meal graphs" && (
          <MealGraphsContainer
            resetCurrentFeedbackModal={() => setCurrentFeedbackModal("")}
          />
        )}
        {currentFeedbackModal === "Weight loss" && (
          <WeightLossModalContainer
            resetCurrentFeedbackModal={() => setCurrentFeedbackModal("")}
          />
        )}
        {currentFeedbackModal === "Custom Feedback" && (
          <CustomFeedbackContainer
            resetCurrentFeedbackModal={() => setCurrentFeedbackModal("")}
          />
        )}
        {currentFeedbackModal === "Manual Readings" && (
          <ManualReadingsModalContainer
            resetCurrentFeedbackModal={() => setCurrentFeedbackModal("")}
          />
        )}
      </Modal>
    </>
  );
};
