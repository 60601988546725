import { MedicationTH } from "./styled-medications-table";

export const MedicationsTableHeadings = () => {
  const headings = [
    "Name",
    "Ind.",
    "Status",
    "Sig.",
    "Start date",
    "Assign by",
    "Note",
    "Actions",
  ];

  return (
    <>
      {headings.map((heading, index) => (
        <MedicationTH
          key={index}
          minWidth={
            index === 1 || index === 2
              ? 40
              : index === 0
              ? 200
              : index === 3 || index === 6
              ? 140
              : index === 5
              ? 100
              : index === 4
              ? 100
              : 30
          }
        >
          <div>
            {heading}
            {/* <span className="material-symbols-outlined">arrow_drop_down</span> */}
          </div>
        </MedicationTH>
      ))}
    </>
  );
};
