import { ViewPatientType } from "../../../components/view-patient-type";
import { Dropdown, VariantType } from "../../../components/dropdown/dropdown";
import { DropdownListItem } from "../../../components/dropdown/styled-dropdown";
import {
  PATIENT_TYPES,
  PatientType,
} from "../../../models/patient-dtos/patient-type";

export type PatientTypeSelectProps = {
  value: PatientType | undefined;
  onChange: (value: PatientType) => void;
  variant?: VariantType;
};

export function PatientTypeSelect(props: PatientTypeSelectProps) {
  const { value, onChange, variant } = props;

  function handlePatientTypeOnClick(value: PatientType) {
    onChange(value);
  }

  return (
    <Dropdown
      text={value === undefined ? "Select Type" : value}
      variant={variant !== undefined ? variant : "outline-dark"}
      modalDropdown
    >
      {PATIENT_TYPES.map((patientType) => (
        <DropdownListItem
          key={patientType}
          onClick={() => handlePatientTypeOnClick(patientType)}
          variant={variant !== undefined ? variant : undefined}
        >
          <ViewPatientType type={patientType} />
        </DropdownListItem>
      ))}
    </Dropdown>
  );
}
