import styled from "styled-components/macro";
import {
  flexCenter,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const ImmediateAttentionPatientsSidebarPatientBodyMeasurmentsContainer = styled.div`
  margin-bottom: 24px;
  padding-left: 8px;

  p {
    margin: 0px;
    ${regularMediumFont}
  }

  ul {
    margin: 8px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0px 8px;

    li {
      ${flexCenter}
    }
  }
`;

export const ImmediateAttentionPatientsSidebarPatientBodyMeasurmentsImageContainer = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ImmediateAttentionPatientsSidebarPatientBodyMeasurmentsTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.textColor};
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.titleColor};

    span {
      font-size: 12px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;
