import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  flexCenterSpaceBetween,
  mediumFont,
  smallMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const PatientProfileInformationHeaderDiagnosisContainer = styled.div`
  max-width: 440px;

  button {
    margin-left: auto;
  }

  @media screen and (max-width: 1199px) {
    max-width: unset;
    width: 100%;
    ${flexCenterSpaceBetween}
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 544px) {
    flex-direction: column;
    margin-top: 16px;
  }
`;

export const PatientProfileInformationHeaderOwnDiagnosis = styled.div`
  margin-top: 21px;
  text-align: right;

  p {
    margin: 0px;
    ${mediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }

  ul {
    ${flexCenter}
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;

    li {
      ${smallMediumFont}
      padding: 4px 8px;
      border-radius: 94px;
      ${flexCenterCenter}
      width: max-content;
      margin: 0px;
    }

    @media screen and (max-width: 722px) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (max-width: 544px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 1199px) {
    text-align: left;
  }

  @media screen and (max-width: 544px) {
    width: 100%;
  }
`;
