import { useCallback } from "react";
import { useCalculateMealSummary } from "../../../../hooks/carb-counter-v1-meals-hooks/use-calculate-meal-summary";
import { CarbCounterFoodLoggingSelectedPatientMealHeader } from "./carb-counter-food-logging-selected-patient-meal-header/carb-counter-food-logging-selected-patient-meal-header";
import { LoadableCarbCounterMealProvider } from "./context/loadable-carb-counter-meal-macros-context";
import { useLoadableData } from "../../../../hooks/use-loadable-data";
import { useCarbCounterMeal } from "./context/carb-counter-meal-dto-context";
import { PatientMealDTO } from "../../../../models/patient-meal-dtos/patient-meal-dto";
import { CarbCounterFoodLoggingSelectedPatientMealDetails } from "./carb-counter-food-logging-selected-patient-meal-header/carb-counter-food-logging-selected-patient-meal-details/carb-counter-food-logging-selected-patient-meal-details";
import { CarbCounterFoodLoggingSelectedPatientMealResponse } from "./carb-counter-food-logging-selected-patient-meal-response/carb-counter-food-logging-selected-patient-meal-response";

type PatientCarbCounterMealProps = {
  patientMealDTO: PatientMealDTO;
};

export const PatientCarbCounterMeal = (props: PatientCarbCounterMealProps) => {
  const { patientMealDTO } = props;

  const { meal } = useCarbCounterMeal();

  const calculateMealSummary = useCalculateMealSummary();

  const load = useCallback(async () => {
    const [carbCounterV1MealSummaryDTO] = await Promise.all([
      calculateMealSummary(meal),
    ]);

    return { carbCounterV1MealSummaryDTO };
  }, [calculateMealSummary, meal]);

  const loadableCarbCounterMealMacros = useLoadableData(load);

  return (
    <LoadableCarbCounterMealProvider
      loadableCarbCounterMealMacros={loadableCarbCounterMealMacros}
    >
      <CarbCounterFoodLoggingSelectedPatientMealHeader
        patientMealDTO={patientMealDTO}
        carbCounterFoodLoggingSelectedPatientMealDetails={
          <CarbCounterFoodLoggingSelectedPatientMealDetails
            carbCounterMealDetailsState={{
              kind: "Loaded",
              loadableCarbCounterMealMacrosState:
                loadableCarbCounterMealMacros.state,
            }}
          />
        }
      />
      <CarbCounterFoodLoggingSelectedPatientMealResponse readOnly={true} />
    </LoadableCarbCounterMealProvider>
  );
};
