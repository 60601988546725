import { FormProvider, useForm } from "react-hook-form";
import { Modal } from "../../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../../components/modal/use-modal-helpers";
import { PersonalInfoGroupText } from "../../styled-personal-info-tab";
import { DiagnosisModalBody } from "./diagnosis-modal-body/diagnosis-modal-body";
import { PutPatientProfileDiagnosesProfileDiagnosisDTO } from "../../../../../../../models/patient-profile-diagnosis-dtos/put-patient-profile-diagnoses-profile-diagnosis-dto";
import { useSinglePatientSidebar } from "../../../../context/loadable-single-patient-sidebar-context";

export type SidebarDiagnosisModalInputs = {
  profileDiagnoses: PutPatientProfileDiagnosesProfileDiagnosisDTO[];
};

export const DiagnosisModal = () => {
  const { patientProfileDiagnoses } = useSinglePatientSidebar();

  const { closeModal, isModalOpen, openModal, modalBodyKey } =
    useModalHelpers();

  const methods = useForm<SidebarDiagnosisModalInputs>({
    defaultValues: {
      profileDiagnoses:
        patientProfileDiagnoses !== undefined
          ? patientProfileDiagnoses
          : undefined,
    },
  });

  return (
    <>
      <PersonalInfoGroupText onClick={openModal}>Edit</PersonalInfoGroupText>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FormProvider {...methods}>
          <DiagnosisModalBody key={modalBodyKey} onModalClose={closeModal} />
        </FormProvider>
      </Modal>
    </>
  );
};
