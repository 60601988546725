import { useCallback } from "react";
import { useModalHelpers } from "../../../../../components/modal/use-modal-helpers";
import { useToastService } from "../../../../../context/toast-service-context";
import { useDeleteContentModule } from "../../../../../hooks/content-module-hooks/use-delete-content-module";
import { ContentModuleDTO } from "../../../../../models/content-module-dtos/content-module-dto";
import { useReloadContent } from "../../../context/loadable-content-context";
import { GenericActionsListItemItem } from "../../../../../components/generic-actions-list/styled-generic-actions-list";
import { DeleteModal } from "../../../../../components/delete-modal/delete-modal";
import { Modal } from "../../../../../components/modal/modal";

type DeleteConentCourseModuleProps = {
  moduleDTO: ContentModuleDTO;
};

export const DeleteConentCourseModule = (
  props: DeleteConentCourseModuleProps
) => {
  const { moduleDTO } = props;

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  const deleteContentModule = useDeleteContentModule();
  const { showToast } = useToastService();
  const reloadCourses = useReloadContent();

  const doesModuleHasChildLessons = moduleDTO.childContentModules?.length! > 0;

  const onDelete = useCallback(async () => {
    try {
      if (!doesModuleHasChildLessons) {
        await deleteContentModule(moduleDTO.id);

        showToast("Success", "Module Deleted Successfully!");
        closeModal();
        reloadCourses();
      }
    } catch (error) {
      if (
        error instanceof Error &&
        error.message === "Request failed with status code 409"
      ) {
        showToast(
          "Error",
          "Failed to Delete Module, Delete It's Lessons First"
        );
      } else {
        showToast("Error", "Failed to Delete Module :(");
      }
    }
  }, [
    moduleDTO.id,
    closeModal,
    deleteContentModule,
    reloadCourses,
    showToast,
    doesModuleHasChildLessons,
  ]);

  return (
    <>
      <GenericActionsListItemItem isDanger onClick={openModal}>
        Delete
      </GenericActionsListItemItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <DeleteModal
          key={modalBodyKey}
          title={`Module ${moduleDTO.name}`}
          onDelete={onDelete}
          onModalClose={closeModal}
          disableDelete={doesModuleHasChildLessons}
          subMessage={
            doesModuleHasChildLessons
              ? `Unable to delete Module, delete it's lessons first`
              : ``
          }
        />
      </Modal>
    </>
  );
};
