import { Modal } from "../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { PatientProfileNotesModalBody } from "../patient-profile-notes-modal-body/patient-profile-notes-modal-body";

export const PatientProfileAddNotesModal = () => {
  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  return (
    <>
      <Button outlined flex onClick={openModal}>
        <span className="material-symbols-outlined">add_circle_outline</span>
        Add Note
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <PatientProfileNotesModalBody
          key={modalBodyKey}
          onClose={closeModal}
          state={{ kind: "Add" }}
        />
      </Modal>
    </>
  );
};
