import { roundTo1DecimalPlace } from "../../../../../../utils/math-utils";
import { PatientNutritionReportDailySummaryDayDTOWithId } from "../create-patient-nutrition-report-daily-summary-day-dto-with-ids";
import { getSelectedDaysCount } from "./get-selected-days-count";

export function calculateFoodGroupsTotalsAndAverages(
  selectedDays: PatientNutritionReportDailySummaryDayDTOWithId[]
) {
  const daysFrequencyFoodGroups = selectedDays.map(
    (item) => item.frequencyFoodGroups
  );

  let nutsFreqSum = 0;
  let nutsFreqAvg = 0;

  for (const daysFrequencyFoodGroup of daysFrequencyFoodGroups) {
    for (const dayFrequencyFoodGroup of daysFrequencyFoodGroup) {
      if (dayFrequencyFoodGroup.foodGroup.id === 19) {
        nutsFreqSum += dayFrequencyFoodGroup.totalCount;
        nutsFreqAvg = nutsFreqSum / getSelectedDaysCount(selectedDays);
      }
    }
  }

  return {
    nutsFreqSum,
    nutsFreqAvg: roundTo1DecimalPlace(nutsFreqAvg),
  };
}
