import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileTagsLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileDietModalInputs } from "../../patient-profile-edit-diet-modal";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";
import {
  PateintProfileFamilyMemberDiseasesFormControlBody,
  PateintProfileFamilyMemberDiseasesFormControlDropdownsContainer,
} from "../../../../../patient-profile-medical-history-section/patient-profile-medical-history/patient-profile-medical-history-edit-modal/patient-profile-medical-history-edit-modal-body/pateint-profile-family-member-diseases-form-control/styled-pateint-profile-family-member-diseases-form-control";
import { Dropdown } from "../../../../../../../../components/dropdown/dropdown";
import { SMOKES, Smoke } from "../../../../../../../../models/smoke";
import { DropdownListItem } from "../../../../../../../../components/dropdown/styled-dropdown";

export const PatientProfileEditDietSmokesFormControl = () => {
  type SmokesFrequency = "Daily" | "Occasionally" | undefined;
  const smokesFrequecy: SmokesFrequency[] = ["Daily", "Occasionally"];

  const { control, watch } = useFormContext<PatientProfileDietModalInputs>();

  const { append, fields, remove } = useFieldArray({
    name: "patientSmokes",
    control,
  } as never);

  const patientSmokes = watch("patientSmokes");

  return (
    <PatientProfileModalFormControl>
      <PatientProfileTagsLabelContainer>
        <label>Smokes</label>
      </PatientProfileTagsLabelContainer>
      <PateintProfileFamilyMemberDiseasesFormControlBody
        isNotEmptyFamilyMemberDiseases={
          patientSmokes !== undefined && patientSmokes.length > 0
        }
        style={{ gap: "24px" }}
      >
        {fields.map((field, index) => (
          <PatientProfileModalInputContainer key={field.id}>
            <PateintProfileFamilyMemberDiseasesFormControlDropdownsContainer>
              <Button outlineNoBorder type="button">
                <img
                  src="/img/trash.svg"
                  alt="Trash Icon"
                  onClick={() => remove(index)}
                />
              </Button>
              <Controller
                name={`patientSmokes.${index}.smoke`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Dropdown
                      text={
                        value !== undefined && value !== ("" as Smoke)
                          ? value
                          : "Select Smoke"
                      }
                      variant="designed-ux"
                      fullWidth
                    >
                      {SMOKES.slice(0, SMOKES.indexOf("Other")).map((smoke) => (
                        <DropdownListItem
                          variant="designed-ux"
                          key={smoke}
                          onClick={() => onChange(smoke)}
                        >
                          {smoke}
                        </DropdownListItem>
                      ))}
                    </Dropdown>
                  );
                }}
              />
              <Controller
                name={`patientSmokes.${index}.quantity`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <input
                      type="number"
                      placeholder="Smoke Quantity"
                      value={value !== undefined ? value : ""}
                      onChange={(e) =>
                        onChange(
                          e.target.value !== undefined ? e.target.value : ""
                        )
                      }
                    />
                  );
                }}
              />
              <Controller
                name={`patientSmokes.${index}.frequency`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Dropdown
                      text={
                        value !== undefined && value !== ("" as SmokesFrequency)
                          ? value
                          : "Select Frequency"
                      }
                      variant="designed-ux"
                      fullWidth
                    >
                      {smokesFrequecy.map((frequecy) => (
                        <DropdownListItem
                          variant="designed-ux"
                          key={frequecy}
                          onClick={() => onChange(frequecy)}
                        >
                          {frequecy}
                        </DropdownListItem>
                      ))}
                    </Dropdown>
                  );
                }}
              />
            </PateintProfileFamilyMemberDiseasesFormControlDropdownsContainer>
          </PatientProfileModalInputContainer>
        ))}
        <Button
          type="button"
          width={100}
          height={40}
          flex
          outlineNoBorder
          onClick={() => {
            append({});
          }}
        >
          <span className="material-symbols-outlined">add</span> Add
        </Button>
      </PateintProfileFamilyMemberDiseasesFormControlBody>
    </PatientProfileModalFormControl>
  );
};
