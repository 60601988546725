import styled from "styled-components/macro";
import {
  MedicationCardText,
  MedicationCardTitle,
} from "../../../../../single-patient/medications/components/medication-card/styled-medication-card";

export const ImmediateAttentionPatientsSidebarMedicationsTabContainer = styled.div`
  margin-top: 20px;
  width: 100%;

  caption {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: left;
    color: ${({ theme }) => theme.colors.textColor};
  }

  th {
    div {
      text-align: left;
      font-size: 14px;
      font-weight: 500;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  ${MedicationCardTitle} {
    font-size: 14px;
  }

  ${MedicationCardText} {
    font-size: 12px;
  }
`;
