import styled from "styled-components/macro";
import { NavbarHeight, SidebarWidth } from "../../styles/global-style";
import {
  flexCenterSpaceBetween,
  regularSemiBoldFont,
} from "../../styles/classes/gloabl-classes";

export const FourZeroFourNotFoundContainer = styled.div`
  width: calc(100vw - ${SidebarWidth});
  height: calc(100vh - (${NavbarHeight} + 16px));
  ${flexCenterSpaceBetween}

  h1 {
    display: grid;
    font-size: 128px;
    color: ${({ theme }) => theme.colors.textColor};
    text-align: center;
    margin: 0px;
    margin-bottom: auto;
    margin-left: 64px;

    span {
      ${regularSemiBoldFont}
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
