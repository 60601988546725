import { Modal } from "../../../../components/modal/modal";
import { useModalHelpers } from "../../../../components/modal/use-modal-helpers";
import { BulkLabResultsModalBody } from "./bulk-lab-results-modal-body/bulk-lab-results-modal-body";
import { Button } from "../../../../styles/classes/reusable-classes";

export const BulkLabResultsModal = () => {
  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  return (
    <>
      <Button width={230} height={44} flex type="button" onClick={openModal}>
        <span className="material-symbols-outlined">add</span>
        Add bulk lab results
      </Button>
      <Modal showModal={isModalOpen} handleHideModal={closeModal}>
        <BulkLabResultsModalBody key={modalBodyKey} onClose={closeModal} />
      </Modal>
    </>
  );
};
