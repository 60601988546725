import { useState } from "react";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import { PatientProfileModalBodyContainer } from "../../../../styles/patient-profile-styles/patient-profile-classes";
import { useFormContext } from "react-hook-form";
import { usePutPatientTimeline } from "../../../../../../../hooks/patient-timeline-hooks/use-put-patient-timeline";
import { PutPatientTimelineDTO } from "../../../../../../../models/patient-timeline-dtos/put-patient-timeline-dto";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { PatientProfileEditTimelineModalInputs } from "../patient-profile-edit-timeline-modal";
import { PatientTimelineEventDTO } from "../../../../../../../models/patient-timeline-dtos/patient-timeline-event-dto";
import { PatientProfileDiabetesTypeFormControl } from "./patient-profile-diabetes-type-form-control/patient-profile-diabetes-type-form-control";
import { PatientProfileAnginaPectorisFormControl } from "./patient-profile-angina-pectoris-form-control/patient-profile-angina-pectoris-form-control";
import { PatientProfileBariatricSurgeryFormControl } from "./patient-profile-bariatric-surgery-form-control/patient-profile-bariatric-surgery-form-control";
import { PatientProfileA1cAtDiabetesDiagnosisFormControl } from "./patient-profile-a1c-at-diabetes-diagnosis-form-control/patient-profile-a1c-at-diabetes-diagnosis-form-control";
import { PatientProfileStrokeFormControl } from "./patient-profile-stroke-form-control/patient-profile-stroke-form-control";
import {
  usePatientProfile,
  useReloadPatientProfile,
} from "../../../../context/loadable-patient-profile-context";

type PatientProfileEditTimelineModalBodyProps = {
  onClose: () => void;
  isAnginaPectorisDefined: PatientTimelineEventDTO | undefined;
  isBariatricSurgeryDefined: PatientTimelineEventDTO | undefined;
  isStrokeDefined?: PatientTimelineEventDTO;
};

export const PatientProfileEditTimelineModalBody = (
  props: PatientProfileEditTimelineModalBodyProps
) => {
  const {
    onClose,
    isAnginaPectorisDefined,
    isBariatricSurgeryDefined,
    isStrokeDefined,
  } = props;
  const { patientDTO } = usePatientProfile();

  const putPatientTimeline = usePutPatientTimeline();
  const { showToast } = useToastService();
  const reloadPatientProfile = useReloadPatientProfile();

  const { handleSubmit } =
    useFormContext<PatientProfileEditTimelineModalInputs>();

  const [isAnginaPectoris, setIsAnginaPectoris] = useState(
    isAnginaPectorisDefined !== undefined ? true : false
  );
  const toggleIsAnginaPectoris = () => setIsAnginaPectoris(!isAnginaPectoris);

  const [isBariatricSurgery, setIsBariatricSurgery] = useState(
    isBariatricSurgeryDefined !== undefined ? true : false
  );
  const toggleIsBariatricSurgery = () =>
    setIsBariatricSurgery(!isBariatricSurgery);

  const [isStroke, setIsStroke] = useState(
    isStrokeDefined !== undefined ? true : false
  );
  const toggleIsStroke = () => setIsStroke(!isStroke);

  const onSubmit = handleSubmit(async (inputs) => {
    const putPatientTimelineDTO: PutPatientTimelineDTO = {
      type: inputs.type,
      diagnosedSince:
        inputs.diagnosedSince === undefined || inputs.diagnosedSince === ""
          ? undefined
          : inputs.diagnosedSince,
      anginaPectorisEmergency: isAnginaPectoris
        ? {
            emergency: "AnginaPectoris",
            diagnosedSince:
              inputs.anginaPectorisEmergency?.diagnosedSince === undefined ||
              inputs.anginaPectorisEmergency?.diagnosedSince === ""
                ? undefined
                : inputs.anginaPectorisEmergency?.diagnosedSince,
          }
        : undefined,
      bariatricSurgery:
        isBariatricSurgery && inputs.bariatricSurgery !== undefined
          ? {
              surgery: inputs.bariatricSurgery.surgery,
              diagnosedSince:
                inputs.bariatricSurgery.diagnosedSince === undefined ||
                inputs.bariatricSurgery.diagnosedSince === ""
                  ? undefined
                  : inputs.bariatricSurgery.diagnosedSince,
            }
          : undefined,
      a1cAtDiabetesDiagnosis:
        inputs.a1cAtDiabetesDiagnosis !== undefined &&
        inputs.a1cAtDiabetesDiagnosis !== ""
          ? Number(inputs.a1cAtDiabetesDiagnosis)
          : undefined,
      strokeEmergency: isStroke
        ? {
            emergency: "Stroke",
            diagnosedSince:
              inputs.strokeEmergency?.diagnosedSince !== undefined &&
              inputs.strokeEmergency?.diagnosedSince !== ""
                ? inputs.strokeEmergency?.diagnosedSince
                : undefined,
          }
        : undefined,
    };

    try {
      await putPatientTimeline(patientDTO, putPatientTimelineDTO);
      showToast("Success", "Patient timeline updated successfully!");
      reloadPatientProfile();
      onClose();
    } catch (error) {
      showToast("Error", "Failed to update patient timeline");
    }
  });

  return (
    <ModalForm width={880} height={580} onSubmit={onSubmit}>
      <ModalHeader title="Edit timeline" onModalClose={onClose} />
      <PatientProfileModalBodyContainer canScrollY>
        <PatientProfileDiabetesTypeFormControl />
        <PatientProfileA1cAtDiabetesDiagnosisFormControl />
        <PatientProfileAnginaPectorisFormControl
          isAnginaPectoris={isAnginaPectoris}
          toggleIsAnginaPectoris={toggleIsAnginaPectoris}
        />
        <PatientProfileStrokeFormControl
          isStroke={isStroke}
          toggleIsStroke={toggleIsStroke}
        />
        <PatientProfileBariatricSurgeryFormControl
          isBariatricSurgery={isBariatricSurgery}
          toggleIsBariatricSurgery={toggleIsBariatricSurgery}
        />
      </PatientProfileModalBodyContainer>
      <ModalFooter>
        <Button type="button" onClick={onClose} outlined>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </ModalFooter>
    </ModalForm>
  );
};
