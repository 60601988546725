import {
  PatientProfileContainer,
  PatientProfileSection,
} from "../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileLifeActivities } from "./patient-profile-life-activities/patient-profile-life-activities";
import { PatientProfileNotes } from "./patient-profile-notes/patient-profile-notes";
import { Loading } from "../../../../components/loading";
import { usePatientProfileLifeActivities } from "./patient-profile-life-activities/context/patient-profile-life-activities-context";
import { LoadablePatientProfileNotesProvider } from "./patient-profile-notes/context/loadable-patient-profile-notes";
import { useCallback } from "react";
import { useGetPatientNotes } from "../../../../hooks/patient-notes-hooks/use-get-patient-notes";
import { useParams } from "react-router-dom";
import { useLoadableData } from "../../../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../../../components/loadable-data-loading";
import { useGetPatient } from "../../../../hooks/patient-hooks/use-get-patient";

export const PatientProfileActivitiesSection = () => {
  const { load } = usePatientProfileLifeActivities();

  const { id } = useParams();
  const patientID = parseInt(id!);

  const getPatient = useGetPatient();
  const getPatientNotes = useGetPatientNotes();

  const loadPatientProfileNotes = useCallback(async () => {
    const patientDTO = await getPatient(patientID);
    const patientNotes = await getPatientNotes(patientID);

    return { patientDTO, patientNotes };
  }, [patientID, getPatient, getPatientNotes]);

  const loadablePatientProfileNotes = useLoadableData(loadPatientProfileNotes);

  return (
    <PatientProfileSection reverse={false} halfContainers>
      <LoadablePatientProfileNotesProvider
        loadablePatientProfileNotes={loadablePatientProfileNotes}
      >
        <PatientProfileContainer>
          <LoadableDataLoading
            state={loadablePatientProfileNotes.state}
            successComponent={() => <PatientProfileNotes />}
          />
        </PatientProfileContainer>
      </LoadablePatientProfileNotesProvider>
      <PatientProfileContainer lifeActivitiesContainer>
        <Loading
          load={load}
          successComponent={(patientActivityLogStatsDTO) => (
            <PatientProfileLifeActivities
              patientActivityLogStatsDTO={
                patientActivityLogStatsDTO.patientActivityLogStatsDTO
              }
              chartID="patient-profile"
            />
          )}
        />
      </PatientProfileContainer>
    </PatientProfileSection>
  );
};
