import { useParams } from "react-router-dom";
import { useGetContentModules } from "../../../../hooks/content-module-hooks/use-get-content-modules";
import { useCallback } from "react";
import { ContentModuleType } from "../../../../models/content-module-dtos/content-module-type";
import { ContentCourseModuleLessonBody } from "./content-course-module-lesson-body/content-course-module-lesson-body";
import { Loading } from "../../../../components/loading";

export const ContentCourseModuleLesson = () => {
  const { moduleID, lessonID } = useParams();
  const parentContentModuleId = parseInt(moduleID!);
  const currentLessonID = parseInt(lessonID!);

  const getContentModules = useGetContentModules();

  const loadContentCourseModuleLessonCallback = useCallback(async () => {
    const contentModuleTypeModule: ContentModuleType = "Lesson";

    const contentModuleDTOs = await getContentModules(contentModuleTypeModule);

    const contentCourseModuleLesson =
      parentContentModuleId !== undefined && currentLessonID !== undefined
        ? contentModuleDTOs.find(
            (lessonDTO) =>
              lessonDTO.id === currentLessonID &&
              lessonDTO.parentContentModuleId === parentContentModuleId
          )
        : undefined;

    return { contentCourseModuleLesson };
  }, [getContentModules, parentContentModuleId, currentLessonID]);

  return (
    <Loading
      load={loadContentCourseModuleLessonCallback}
      successComponent={(contentCourseModuleLesson) => (
        <ContentCourseModuleLessonBody
          contentCourseModuleLesson={
            contentCourseModuleLesson.contentCourseModuleLesson
          }
        />
      )}
    />
  );
};
