import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  mediumFont,
  regularSemiBoldFont,
} from "../../../../../../styles/classes/gloabl-classes";
import {
  lightShadowSecondary,
  transition,
} from "../../../../../../styles/global-style";

export const PatientProfileInformationHeaderCenterTopNameContainer = styled.div`
  ${flexCenter}
  gap: 0px 12px;
`;

export const PatientProfileInformationHeaderCenterTopName = styled.p`
  margin: 0px;
  font-size: 20px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const CopiedText = styled.span`
  ${flexCenterCenter}
  ${lightShadowSecondary}
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.primaryColor};
  position: absolute;
  ${mediumFont}
  color: ${({ theme }) => theme.colors.buttonColor};
  width: 70px;
  height: 30px;
  top: -50px;
  left: -20px;

  ::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${({ theme }) => theme.colors.primaryColor};
    position: absolute;
    bottom: -10px;
    left: 25px;
  }
`;

export const PatientProfileInformationHeaderCenterTopMobileOrMobile = styled.p`
  width: 0px;
  overflow: hidden;
  opacity: 0;
  transition: ${transition};
  margin: 0px;
  ${regularSemiBoldFont}
  color: ${({ theme }) => theme.colors.textColor};
`;

export const PatientProfileInformationHeaderCenterTopNameIcons = styled.div`
  ${flexCenter}
  gap: 0px 12px;

  div {
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.inputColor};
    ${flexCenterCenter}
    padding: 8px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: ${transition};
    position: relative;

    :hover {
      border-radius: 10px;
      width: auto;

      ${PatientProfileInformationHeaderCenterTopMobileOrMobile} {
        width: fit-content;
        overflow: visible;
        opacity: 1;
        margin-left: 16px;
      }
    }
  }
`;

export const PatientProfileInformationHeaderCenterTopInformation = styled.div`
  ${flexCenter}
  flex-wrap: wrap;
  gap: 8px 16px;
  margin-top: 8px;
`;

export const PatientProfileInformationHeaderCenterTopInformationItem = styled.div`
  ${flexCenter}
  gap: 0px 6px;

  p {
    margin: 0px;
    ${mediumFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
