import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { ChatTokenDTO } from "../../../models/chat-dtos/chat-token-dto";
import { useGenerateToken } from "../../../hooks/chat-hooks/use-generate-token";
import { useOptionalUser } from "../../../context/auth-context/auth-context";

type ChatToken = {
  chatTokenDTO: {
    chatTokenDTO: ChatTokenDTO;
  };
};

const ChatTokenContext = createContext<ChatToken | undefined>(undefined);

type ChatTokenProviderProps = PropsWithChildren<{}>;

export const ChatTokenProvider = (props: ChatTokenProviderProps) => {
  const { children } = props;

  const [chatTokenDTO, setChatTokenDTO] = useState<ChatTokenDTO | undefined>();

  const generateToken = useGenerateToken();
  const user = useOptionalUser();

  useEffect(() => {
    const fetch = async () => {
      if (user) {
        await generateToken().then((res) => setChatTokenDTO(res));
      }
    };

    fetch();
  }, [generateToken, user]);

  if (chatTokenDTO) {
    const value = { chatTokenDTO: { chatTokenDTO } };

    return (
      <ChatTokenContext.Provider value={value}>
        {children}
      </ChatTokenContext.Provider>
    );
  } else {
    return <>{children}</>;
  }
};

export const useChatTokenContext = (): ChatToken => {
  const chatToken = useContext(ChatTokenContext);

  if (chatToken === undefined) {
    throw new Error(`useChatTokenContext must be within ChatTokenProvider`);
  }

  return chatToken;
};

export const useChatTokenOrUndefined = (): ChatToken | undefined => {
  const chatToken = useContext(ChatTokenContext);

  return chatToken;
};
