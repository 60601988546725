import { useState } from "react";
import { LabResultsDropdown } from "../lab-results-dropdown/lab-results-dropdown";
import { LabResultsDropdownListItem } from "../lab-results-dropdown/styled-lab-results-dropdown";

type IonizedCalciumDropdownProps = {
  units: string[];
  value?: string;
  onChange: (value: string) => void;
};

export const IonizedCalciumDropdown = (props: IonizedCalciumDropdownProps) => {
  const { units, value, onChange } = props;

  const [displayText, setDisplayText] = useState(
    value === undefined ? "Select lab result unit" : value
  );

  function handleLabTestResultUnitOnClick(value: string) {
    onChange(value);
  }

  return (
    <LabResultsDropdown
      displayText={displayText}
      label=""
      items={units.map((unit, index) => (
        <LabResultsDropdownListItem
          key={index}
          onClick={() => {
            handleLabTestResultUnitOnClick(unit);
            setDisplayText(unit);
          }}
        >
          {unit}
        </LabResultsDropdownListItem>
      ))}
    />
  );
};
