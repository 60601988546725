import styled from "styled-components/macro";

export const OtherDataHeader = styled.div`
  margin-bottom: 0.5rem;
`;

export const OtherDataBody = styled.div`
  width: 90%;
  margin: 0 auto;
`;
