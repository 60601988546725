import {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useToastService } from "../../../../../context/toast-service-context";
import { CreatePatientPrescriptionMedicationDTO } from "../../../../../models/patient-prescriptions/create-patient-prescription-medication-dto";
import { objectAlreadyInculded } from "../utils/prescription-utils";

export type PrescriptionMedicines = {
  prescriptionMedicines: CreatePatientPrescriptionMedicationDTO[];
  startAdding: (medicine: CreatePatientPrescriptionMedicationDTO) => void;
  startDeleting: (medicine: CreatePatientPrescriptionMedicationDTO) => void;
  startEditing: (
    id: number,
    medicine: CreatePatientPrescriptionMedicationDTO
  ) => void;
};

const PrescriptionsContext = createContext<PrescriptionMedicines | undefined>(
  undefined
);

export function PrescriptionsProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const [prescriptionMedicines, setPrescriptionMedicines] = useState<
    CreatePatientPrescriptionMedicationDTO[]
  >([]);
  const { showToast } = useToastService();

  const startAdding = useCallback(
    (medicine: CreatePatientPrescriptionMedicationDTO) => {
      if (objectAlreadyInculded(medicine, prescriptionMedicines)) {
        showToast("Error", "Already added this medicine");
      } else {
        setPrescriptionMedicines([...prescriptionMedicines, medicine]);
      }
    },
    [prescriptionMedicines, showToast]
  );

  const startEditing = useCallback(
    (id: number, medicine: CreatePatientPrescriptionMedicationDTO) => {
      const newPrescriptionMedicines = prescriptionMedicines.map((item) => {
        if (item.medicationId === id) {
          return medicine;
        }
        return item;
      });

      setPrescriptionMedicines(newPrescriptionMedicines);
    },
    [prescriptionMedicines]
  );

  const startDeleting = useCallback(
    (medicine: CreatePatientPrescriptionMedicationDTO) => {
      const copyAddedMedicines = [...prescriptionMedicines];

      if (objectAlreadyInculded(medicine, copyAddedMedicines)) {
        copyAddedMedicines.splice(copyAddedMedicines.indexOf(medicine), 1);
        setPrescriptionMedicines(copyAddedMedicines);
      }
    },
    [prescriptionMedicines]
  );

  const value = useMemo(
    () => ({
      prescriptionMedicines,
      startAdding,
      startDeleting,
      startEditing,
    }),
    [prescriptionMedicines, startAdding, startDeleting, startEditing]
  );

  return (
    <PrescriptionsContext.Provider value={value}>
      {children}
    </PrescriptionsContext.Provider>
  );
}

export function usePrescriptions(): PrescriptionMedicines {
  const prescriptions = useContext(PrescriptionsContext);

  if (prescriptions === undefined) {
    throw new Error(
      "usePrescriptions must be used within PrescriptionsProvider"
    );
  }

  return prescriptions;
}
