import { PropsWithChildren, createContext, useContext } from "react";
import { PatientNoteDTO } from "../../../../../../models/patient-note-dtos/patient-note-dto";
import { UseLoadableDataReturn } from "../../../../../../hooks/use-loadable-data";
import { PatientDTO } from "../../../../../../models/patient-dtos/patient-dto";

export type PatientProfileNotes = {
  patientDTO: PatientDTO;
  patientNotes: PatientNoteDTO[];
};

export type LoadablePatientProfileNotes =
  UseLoadableDataReturn<PatientProfileNotes>;

const LoadablePatientProfileNotesContext = createContext<
  LoadablePatientProfileNotes | undefined
>(undefined);

export type LoadablePatientProfileNotesProviderProps = PropsWithChildren<{
  loadablePatientProfileNotes: LoadablePatientProfileNotes;
}>;

export function LoadablePatientProfileNotesProvider(
  props: LoadablePatientProfileNotesProviderProps
) {
  const { loadablePatientProfileNotes, children } = props;

  return (
    <LoadablePatientProfileNotesContext.Provider
      value={loadablePatientProfileNotes}
    >
      {children}
    </LoadablePatientProfileNotesContext.Provider>
  );
}

export function useLoadablePatientProfileNotes(): LoadablePatientProfileNotes {
  const loadablePatientProfileNotes = useContext(
    LoadablePatientProfileNotesContext
  );

  if (loadablePatientProfileNotes === undefined) {
    throw Error(
      "useLoadablePatientProfileNotes must be used within an LoadablePatientProfileNotesProvider"
    );
  }

  return loadablePatientProfileNotes;
}

export function usePatientProfileNotes(): PatientProfileNotes {
  const loadablePatientProfileNotes = useLoadablePatientProfileNotes();

  if (loadablePatientProfileNotes.state.data === undefined) {
    throw Error("Patient Profile have never been loaded successfully yet.");
  }

  return loadablePatientProfileNotes.state.data;
}

export function useReloadPatientProfileNotes(): () => void {
  const loadablePatientProfileNotes = useLoadablePatientProfileNotes();

  return loadablePatientProfileNotes.reload;
}
