import { PatientNutritionReportDTO } from "../../../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-dto";
import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";
import {
  NutritionReportSummaryListBar,
  NutritionReportSummaryListBarContainer,
  NutritionReportSummaryListGoalContainer,
  NutritionReportSummaryListLI,
  NutritionReportSummaryListTitleContainer,
} from "../../../../../../../food-logging/nutrition-report/nutrition-report-summary/nutrition-report-summary-screen/nutrition-report-summary-list/styled-nutrition-report-summary-list";
import { ViewNutritionReportSummaryListFoodgroups } from "./view-nutrition-report-summary-list-foodgroups";

type ViewNutritionReportSummaryListProps = {
  patientNutritionReport: PatientNutritionReportDTO;
};

export const ViewNutritionReportSummaryList = (
  props: ViewNutritionReportSummaryListProps
) => {
  const { patientNutritionReport } = props;

  return (
    <ul>
      <ViewNutritionReportSummaryListFoodgroups
        patientNutritionReport={patientNutritionReport}
      />
      <NutritionReportSummaryListLI>
        <NutritionReportSummaryListTitleContainer>
          <img
            src={`/img/nutrition-goals/carbs.svg`}
            alt={`carbs percentage`}
          />
          <p>Carbs %</p>
        </NutritionReportSummaryListTitleContainer>
        <NutritionReportSummaryListBarContainer>
          <NutritionReportSummaryListBar
            progress={
              ((patientNutritionReport.actualCarbsKCalsPercentage! /
                patientNutritionReport.goalCarbsKCalsPercentage!) *
                100) /
              2
            }
          >
            <div />
          </NutritionReportSummaryListBar>
          <NutritionReportSummaryListGoalContainer>
            <p>
              {patientNutritionReport.actualCarbsKCalsPercentage !== undefined
                ? roundTo1DecimalPlace(
                    patientNutritionReport.actualCarbsKCalsPercentage
                  )
                : 0}
              /
              {patientNutritionReport.goalCarbsKCalsPercentage !== undefined
                ? roundTo1DecimalPlace(
                    patientNutritionReport.goalCarbsKCalsPercentage
                  )
                : 0}
              %
            </p>
          </NutritionReportSummaryListGoalContainer>
        </NutritionReportSummaryListBarContainer>
      </NutritionReportSummaryListLI>
    </ul>
  );
};
