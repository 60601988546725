import styled from "styled-components/macro";

type OtidaLogoProps = {
  navLogo?: boolean | undefined;
};

export const OtidaLogo = (props: OtidaLogoProps) => {
  const { navLogo } = props;

  return (
    <OtidaLogoContainer navLogo={navLogo !== undefined && navLogo}>
      <OtidaLogoLogo>
        <img src="/img/logo-2.svg" alt="Otida Logo" />
        <p>Otida</p>
      </OtidaLogoLogo>
    </OtidaLogoContainer>
  );
};

const OtidaLogoContainer = styled.div<{ navLogo: boolean }>`
  display: none;

  @media screen and (max-width: 500px) {
    display: block;
  }
`;

const OtidaLogoLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 0rem 0.5rem;

  img {
    width: 30px;
  }

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.typography.fontSize.regularSmFont};
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  }
`;
