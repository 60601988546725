import styled from "styled-components/macro";
import { lightShadowAlt, transition } from "../../styles/global-style";

export const GenericActionsListContainer = styled.div<{ disabled?: boolean }>`
  position: relative;

  span,
  img {
    color: ${({ theme }) => theme.colors.textColor};
    cursor: pointer;
    pointer-events: ${({ disabled }) => (disabled ? "none" : "unset")};
  }
`;

export const GenericActionsListList = styled.ul<{
  show: boolean;
  customZIndex?: string;
}>`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 0.7rem;
  border-radius: 0.5rem;
  width: max-content;
  ${lightShadowAlt}
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  z-index: ${({ theme, customZIndex }) =>
    customZIndex === "" ? theme.zIndex.z700 : customZIndex};
  top: 0;
  left: -70px;

  @media screen and (max-width: 500px) {
    left: -80px;
  }
`;

type GenericActionsListItemItemType = {
  isDanger?: boolean;
  disabled?: boolean;
  hasIcon?: boolean;
  isPrimary?: boolean;
};

export const GenericActionsListItemItem = styled.li<GenericActionsListItemItemType>`
  padding: 0.5rem 0rem;
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  transition: ${transition};
  color: ${({ isDanger, isPrimary, theme }) =>
    isDanger
      ? theme.colors.dangerText
      : isPrimary
      ? theme.colors.primaryColor
      : theme.colors.titleColor};
  display: ${({ disabled, hasIcon }) =>
    disabled ? "none" : hasIcon ? "flex" : "block"};
  align-items: center;

  :hover {
    transform: scale(1.1);
  }

  img {
    width: 19px;
    margin-right: 11px;
  }
`;
