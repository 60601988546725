import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Temporal } from "temporal-polyfill";
import { Loading } from "../../../../../../../components/loading";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import { DAILY_MEALS_GRAPHS_RESPONE_SPAN_IN_MINUTES } from "../../../../../../../constants/daily-meals-graphs-constants";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { useUpdatePatientPlan } from "../../../../../../../hooks/patient-plan-hooks/use-update-patient-plan";
import { useGetPatientServingsMealResponses } from "../../../../../../../hooks/use-get-patient-servings-meal-responses";
import { UpdatePatientPlanDTO } from "../../../../../../../models/patient-plan-dtos/update-patient-plan-dto";
import {
  Button,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import { DailyMealGraphsResponse } from "../../../../../bgl-analysis/daily-meal-graphs/daily-meal-graphs-cards/daily-meal-graphs-response";
import { PlansForm } from "../../../../components/plans-form/plans-form";
import {
  useDetailedPlan,
  useReloadDetailedPlan,
} from "../../../../context/loadable-detailed-plan-context";
import { EmptyFeedbackModal } from "../feedback-modal-body/empty-feedback-modal";
import {
  ChartButton,
  ChartsContainerTitle,
  FeedbackModalModalContainer,
} from "../feedback-modal-body/styled-feedback-modal-body";
import { MealGraphsModalInputs } from "./meal-graphs-container";

type MealGraphsModalProps = {
  resetCurrentFeedbackModal: () => void;
};

export const MealGraphsModal = (props: MealGraphsModalProps) => {
  const { resetCurrentFeedbackModal } = props;

  const { handleSubmit, watch, setValue } = useFormContext<
    MealGraphsModalInputs
  >();
  const updatePatientPlan = useUpdatePatientPlan();
  const getPatientMealResponses = useGetPatientServingsMealResponses();
  const { patientDTO } = useDetailedPlan();
  const reloadPatientDetailedPlan = useReloadDetailedPlan();

  const patientId = patientDTO.id!;

  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const temporalStartDate = Temporal.PlainDate.from(
    startDate.toISOString().split("T")[0]
  ).add({ days: 1 });
  const temporalEndDate = Temporal.PlainDate.from(
    endDate.toISOString().split("T")[0]
  ).add({ days: 1 });

  const notes = watch("notes");
  const feedbackTone = watch("feedbackTone");
  const mealGraphsAttachments = watch("attachments");

  const { patientDetailedPlanDTO } = useDetailedPlan();
  const { showToast } = useToastService();

  const onSubmit = handleSubmit(async () => {
    const updateDTO: UpdatePatientPlanDTO = {
      ...patientDetailedPlanDTO,
      patientPlanFeedbacks: [
        ...patientDetailedPlanDTO.patientPlanFeedbacks,
        {
          feedbackType: "MealGraph",
          feedbackTone: feedbackTone!,
          notes,
          attachments: mealGraphsAttachments,
        },
      ],
    };

    updatePatientPlan(patientDetailedPlanDTO.patientPlan, updateDTO)
      .then(() => {
        showToast("Success", "Feedback added successfully");
        reloadPatientDetailedPlan();
      })
      .catch(() => showToast("Error", "Failed to add feedback :("));
  });

  const temporalStartDateStr = temporalStartDate.toString();
  const temporalEndDateStr = temporalEndDate.toString();

  const load = useCallback(
    () =>
      getPatientMealResponses(
        patientId,
        temporalStartDateStr,
        temporalEndDateStr,
        DAILY_MEALS_GRAPHS_RESPONE_SPAN_IN_MINUTES
      ).then((patientMealResponses) => {
        setValue("attachments", []);
        return patientMealResponses.filter(
          (item) => item.readings.length !== 0
        );
      }),
    [
      getPatientMealResponses,
      patientId,
      temporalStartDateStr,
      temporalEndDateStr,
      setValue,
    ]
  );

  const findMealAttachment = useCallback(
    (date: string, time: string) => {
      return mealGraphsAttachments.indexOf(
        mealGraphsAttachments.find(
          (item) =>
            item.mealGraphChartDate === date && item.mealGraphChartTime === time
        )!
      );
    },
    [mealGraphsAttachments]
  );

  const handleToggleChart = useCallback(
    (date: string, time: string) => {
      if (findMealAttachment(date, time) > -1) {
        setValue(
          "attachments",
          mealGraphsAttachments.filter(
            (item) =>
              !(
                item.attachmentType === "MealGraphChart" &&
                item.mealGraphChartDate === date &&
                item.mealGraphChartTime === time
              )
          )
        );
      } else {
        setValue("attachments", [
          ...mealGraphsAttachments,
          {
            attachmentType: "MealGraphChart",
            mealGraphChartDate: date,
            mealGraphChartTime: time,
          },
        ]);
      }
    },
    [setValue, findMealAttachment, mealGraphsAttachments]
  );

  const chartButton = useCallback(
    (date: string, time: string) => (
      <ChartButton
        disabled={
          mealGraphsAttachments.length >= 3 &&
          findMealAttachment(date, time) === -1
        }
        added={findMealAttachment(date, time) > -1}
        type="button"
        onClick={() => handleToggleChart(date, time)}
      >
        {findMealAttachment(date, time) > -1 ? (
          <span className="material-symbols-outlined">done</span>
        ) : (
          <span className="material-symbols-outlined">add</span>
        )}
      </ChartButton>
    ),
    [findMealAttachment, handleToggleChart, mealGraphsAttachments.length]
  );

  const notesCondition = notes === "" || notes === undefined;
  const modalDisabledCondition =
    mealGraphsAttachments.length === 0 ||
    feedbackTone === undefined ||
    notesCondition;

  return (
    <ModalForm width={900} height={750} onSubmit={onSubmit}>
      <ModalHeader
        onModalClose={resetCurrentFeedbackModal}
        title="Add Feedback - Meal graphs"
        subTitle="Complete the info related to meal graphs for the patient"
      />
      <FeedbackModalModalContainer>
        <PlansForm setEndDate={setEndDate} setStartDate={setStartDate} />
        <Loading
          load={load}
          successComponent={(patientMealResponses) => {
            if (patientMealResponses.length === 0) {
              return (
                <EmptyFeedbackModal subText="Select start and end date from Graph date to show meal graphs" />
              );
            } else {
              return (
                <>
                  <ChartsContainerTitle>
                    Select attached daily graph to send <span>(maximum 3)</span>
                  </ChartsContainerTitle>
                  <DailyMealGraphsResponse
                    patientMealResponse={{
                      patientDTO: patientDTO,
                      patientMealResponses: patientMealResponses,
                    }}
                    hasToolTip={false}
                    smallModalGraphs={true}
                    button={chartButton}
                  />
                </>
              );
            }
          }}
        />
      </FeedbackModalModalContainer>
      <ModalFooter>
        <Button type="button" outlined onClick={resetCurrentFeedbackModal}>
          Cancel
        </Button>
        <Button type="submit" disabled={modalDisabledCondition}>
          Add
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
