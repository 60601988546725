import { PropsWithChildren, createContext, useContext } from "react";
import { UseLoadableDataReturn } from "../../../../../hooks/use-loadable-data";
import { PatientMedicalHistoryDTO } from "../../../../../models/patient-medical-history-dtos/patient-medical-history-dto";
import { DiabetesComplicationDTO } from "../../../../../models/diabetes-complication-dtos/diabetes-complication-dto";
import { PatientDTO } from "../../../../../models/patient-dtos/patient-dto";

export type PatientProfileMedicalHistory = {
  patientDTO: PatientDTO;
  patientMedicalHistoryDTO: PatientMedicalHistoryDTO;
  diabetesComplications: DiabetesComplicationDTO[];
};

export type LoadablePatientProfileMedicalHistory =
  UseLoadableDataReturn<PatientProfileMedicalHistory>;

const LoadablePatientProfileMedicalHistoryContext = createContext<
  LoadablePatientProfileMedicalHistory | undefined
>(undefined);

export type LoadablePatientProfileMedicalHistoryProviderProps =
  PropsWithChildren<{
    loadablePatientProfileMedicalHistory: LoadablePatientProfileMedicalHistory;
  }>;

export function LoadablePatientProfileMedicalHistoryProvider(
  props: LoadablePatientProfileMedicalHistoryProviderProps
) {
  const { loadablePatientProfileMedicalHistory, children } = props;

  return (
    <LoadablePatientProfileMedicalHistoryContext.Provider
      value={loadablePatientProfileMedicalHistory}
    >
      {children}
    </LoadablePatientProfileMedicalHistoryContext.Provider>
  );
}

export function useLoadablePatientProfileMedicalHistory(): LoadablePatientProfileMedicalHistory {
  const loadablePatientProfileMedicalHistory = useContext(
    LoadablePatientProfileMedicalHistoryContext
  );

  if (loadablePatientProfileMedicalHistory === undefined) {
    throw Error(
      "useLoadablePatientProfileMedicalHistory must be used within an LoadablePatientProfileMedicalHistoryProvider"
    );
  }

  return loadablePatientProfileMedicalHistory;
}

export function usePatientProfileMedicalHistory(): PatientProfileMedicalHistory {
  const loadablePatientProfileMedicalHistory =
    useLoadablePatientProfileMedicalHistory();

  if (loadablePatientProfileMedicalHistory.state.data === undefined) {
    throw Error("Patient Profile have never been loaded successfully yet.");
  }

  return loadablePatientProfileMedicalHistory.state.data;
}

export function useReloadPatientProfileMedicalHistory(): () => void {
  const loadablePatientProfileMedicalHistory =
    useLoadablePatientProfileMedicalHistory();

  return loadablePatientProfileMedicalHistory.reload;
}
