import { PatientServingDTO } from "../../../models/patient-serving-dto";
import { Temporal } from "temporal-polyfill";
import {
  MealTooltipBody,
  MealTooltipBodyList,
  MealTooltipBodyListItem,
  MealTooltipContainer,
  MealtootipHeader,
  MealtootipHeaderTop,
} from "../../../styles/classes/tooltips";

export type MedicinesTooltipProps = {
  // Array with at least one value. See: https://stackoverflow.com/a/67026991/6690391
  patientServingDTOs: [PatientServingDTO, ...PatientServingDTO[]];
};

export function MedicinesTooltip(props: MedicinesTooltipProps) {
  const { patientServingDTOs } = props;

  // Converting to Temporal.PlainDateTime to take advantange of its string formatting options
  const plainDateTime = Temporal.PlainDateTime.from(
    `${patientServingDTOs[0].date}T${patientServingDTOs[0].time}`
  );

  const displayDateTime = plainDateTime.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <MealTooltipContainer>
      <MealtootipHeader>
        <MealtootipHeaderTop>
          <p>Medicines</p>
          <strong>{displayDateTime}</strong>
        </MealtootipHeaderTop>
      </MealtootipHeader>
      <MealTooltipBody>
        <MealTooltipBodyList>
          {patientServingDTOs.map((medicine) => (
            <MealTooltipBodyListItem key={medicine.id}>
              <span>{medicine.foodName}</span>
              <strong>{medicine.amount}</strong>
            </MealTooltipBodyListItem>
          ))}
        </MealTooltipBodyList>
      </MealTooltipBody>
    </MealTooltipContainer>
  );
}
