import { DeleteModal } from "../../../components/delete-modal/delete-modal";
import { GenericActionsListItem } from "../../../components/generic-actions-list/generic-actions-list-item";
import { Modal } from "../../../components/modal/modal";
import { useModalHelpers } from "../../../components/modal/use-modal-helpers";

type DeletePatientProps = {
  patientName: string;
  onDelete: () => void;
};

export const DeletePatient = (props: DeletePatientProps) => {
  const { patientName, onDelete } = props;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <>
      <GenericActionsListItem
        text="Delete"
        onClick={openModal}
        variant="danger"
      />
      <Modal showModal={isModalOpen} handleHideModal={closeModal}>
        <DeleteModal
          key={modalBodyKey}
          onModalClose={closeModal}
          onDelete={onDelete}
          title={patientName}
        />
      </Modal>
    </>
  );
};
