import styled from "styled-components/macro";
import {
  flexCenter,
  mediumFont,
  regularRegularFont,
  smallRegularFont,
} from "../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../styles/global-style";
import { MedicationsInputCSS } from "../css";

export const MedicationsInputContainer = styled.div<{
  notesDimentions?: boolean;
}>`
  display: flex;
  align-items: ${({ notesDimentions }) =>
    notesDimentions ? "flex-start" : "center"};
  width: 100%;
  position: relative;
`;

export const MedicationsInputLabel = styled.label<{
  width?: number;
  isOptional?: true;
}>`
  display: ${({ isOptional }) => (isOptional ? "block" : "flex")};
  position: relative;
  ${regularRegularFont}
  margin: 0px 32px 0px 16px;
  width: ${({ width }) => (width !== undefined ? `${width}px` : "75px")};

  span {
    position: absolute;
    bottom: -15px;
    left: 0;
    ${smallRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const MedicationsInputWrapper = styled.div<{
  notesDimentions?: boolean;
  errorState?: boolean;
}>`
  ${flexCenter}
  background: ${({ theme }) => theme.colors.inputColor};
  border-radius: 5px;
  padding: ${({ theme }) => theme.padding.pad05All};
  width: 80%;
  height: ${({ notesDimentions }) => (notesDimentions ? "102px" : "40px")};
  position: relative;
  border: 1px solid
    ${({ theme, errorState }) =>
      errorState ? theme.colors.danger : "transparent"};

  p {
    margin: 0px 0px 0px 8px;
    ${mediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    width: 100px;
    text-align: right;
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    cursor: pointer;
    transition: ${transition};
  }
`;

export const MedicationsInputInput = styled.input`
  ${MedicationsInputCSS}
`;
