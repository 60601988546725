import { useEffect } from "react";

type EnterKeyPress = {
  onEnterKeyPress: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
};

export const useEnterKeypress = (handleAppend: () => void): EnterKeyPress => {
  useEffect(() => {
    const keyPress = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleAppend();
      }
    };

    document.addEventListener("keypress", keyPress);

    return () => document.removeEventListener("keypress", keyPress);
  }, [handleAppend]);

  const onEnterKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === "Enter") {
      handleAppend();
    } else {
      return;
    }
  };

  return { onEnterKeyPress };
};
