import styled from "styled-components/macro";
import {
  lightShadowSimple,
  transition,
} from "../../../../../../../../../../../styles/global-style";
import { flexCenterSpaceBetween } from "../../../../../../../../../../../styles/classes/gloabl-classes";

export const MealsAnalysisResponseTableCellMacrosInfoContainer = styled.div`
  display: grid;
  position: absolute;
  left: 20px;
  z-index: 101;
  background: ${({ theme }) => theme.colors.containerColor};
  border-radius: 8px;
  padding: 12px;
  width: 180px;
  opacity: 0;
  overflow: hidden;
  height: 0px;
  ${lightShadowSimple}
  transition: ${transition};
`;

export const MealsAnalysisResponseTableCellMacrosInfoDetails = styled.div`
  ${flexCenterSpaceBetween}
  gap: 0px 16px;

  :first-child > p {
    ::before {
      background: ${({ theme }) => theme.newColors.warningAlt};
    }
  }

  :nth-child(2) > p {
    ::before {
      background: ${({ theme }) => theme.colors.primaryColor};
    }
  }

  :nth-child(3) > p {
    ::before {
      background: ${({ theme }) => theme.colors.primaryColorAlt};
    }
  }

  p {
    position: relative;
    margin: 0px 0px 0px 8px;

    ::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 100%;
      position: absolute;
      top: 25%;
      left: -18px;
    }
  }
`;
