import { useCallback } from "react";
import { useHomeStaticData } from "../../../../../../context/home-static-data-context";
import { DoubleRangeInput } from "../../components/double-range-input/double-range-input";
import { ImmediateAttentionPatientsFiltersSidebarAgeContainer } from "./styled-immediate-attention-patients-filters-sidebar-age";
import { ImmediateAttentionPatientsTableAgeFilterContainer } from "../../immediate-attention-patients-table-age-filter/styled-immediate-attention-patients-table-age-filter";
import { ImmediateAttentionPatientsFiltersSidebarFilterTitle } from "../styled-immediate-attention-patients-filters-sidebar";
import { useImmediateAttentionPatientsSelectedFilters } from "../../../../../context/immediate-attention-patients-selected-filters-context";

export const ImmediateAttentionPatientsFiltersSidebarAge = () => {
  const {
    setSelectedFilters,
    selectedFilters,
  } = useImmediateAttentionPatientsSelectedFilters();

  const { homeStaticData, setFilteredHomeStaticData } = useHomeStaticData();

  const handleAddFilter = useCallback(
    (ageRange: string) => {
      const existingFilter = selectedFilters.find(
        (filter) => filter.filterName === "Age"
      );

      if (existingFilter) {
        // Update the existing filter
        setSelectedFilters(
          selectedFilters.map((filter) =>
            filter.filterName === "Age"
              ? { ...filter, filterValue: ageRange }
              : filter
          )
        );
      } else {
        // Add a new filter
        setSelectedFilters([
          ...selectedFilters,
          { filterName: "Age", filterValue: ageRange },
        ]);
      }
    },
    [selectedFilters, setSelectedFilters]
  );

  const handleRangeChange = useCallback(
    (minValue: number, maxValue: number) => {
      setFilteredHomeStaticData(
        homeStaticData.filter(
          (item) => item.patient.age >= minValue && item.patient.age <= maxValue
        )
      );
      handleAddFilter(`From: ${minValue} To: ${maxValue}`);
    },
    [homeStaticData, setFilteredHomeStaticData, handleAddFilter]
  );

  return (
    <ImmediateAttentionPatientsFiltersSidebarAgeContainer>
      <ImmediateAttentionPatientsFiltersSidebarFilterTitle
        style={{
          marginBottom: 16,
        }}
      >
        Age
      </ImmediateAttentionPatientsFiltersSidebarFilterTitle>
      <ImmediateAttentionPatientsTableAgeFilterContainer>
        <DoubleRangeInput
          min={0}
          max={100}
          step={1}
          onChange={handleRangeChange}
          ageFilter
        />
      </ImmediateAttentionPatientsTableAgeFilterContainer>
    </ImmediateAttentionPatientsFiltersSidebarAgeContainer>
  );
};
