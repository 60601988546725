import { useCallback, useState } from "react";
import { DoubleRangeInput } from "../../components/double-range-input/double-range-input";
import { ImmediateAttentionPatientsTableAgeFilterContainer } from "../../immediate-attention-patients-table-age-filter/styled-immediate-attention-patients-table-age-filter";
import { ImmediateAttentionPatientsFiltersSidebarHba1cContainer } from "./styled-immediate-attention-patients-filters-sidebar-hba1c";
import { ImmediateAttentionPatientsFiltersSidebarFilterTitle } from "../styled-immediate-attention-patients-filters-sidebar";

export const ImmediateAttentionPatientsFiltersSidebarHba1c = () => {
  const [, setRangeValues] = useState<[number, number]>([4, 15]);

  const handleRangeChange = useCallback(
    (minValue: number, maxValue: number) => {
      setRangeValues([minValue, maxValue]);
    },
    []
  );

  return (
    <ImmediateAttentionPatientsFiltersSidebarHba1cContainer>
      <ImmediateAttentionPatientsFiltersSidebarFilterTitle
        style={{
          marginBottom: 16,
        }}
      >
        HbA1c
      </ImmediateAttentionPatientsFiltersSidebarFilterTitle>
      <ImmediateAttentionPatientsTableAgeFilterContainer>
        <DoubleRangeInput
          min={4}
          max={15}
          step={0.1}
          onChange={handleRangeChange}
        />
      </ImmediateAttentionPatientsTableAgeFilterContainer>
    </ImmediateAttentionPatientsFiltersSidebarHba1cContainer>
  );
};
