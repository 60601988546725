import { Controller, useFormContext } from "react-hook-form";
import { CreateCourseInputs } from "../../create-course";
import {
  RadioButton,
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../styles/classes/reusable-classes";

export const CreateCourseStatusController = () => {
  const { control } = useFormContext<CreateCourseInputs>();

  return (
    <Controller
      control={control}
      name="status"
      render={({ field: { value, onChange } }) => (
        <UXInputContainer $width="240px">
          <UXInputLabel $width="110px">Course Status</UXInputLabel>
          <RadioButton
            type="button"
            checked={value}
            onClick={() => onChange(!value)}
          >
            <span />
          </RadioButton>
        </UXInputContainer>
      )}
    />
  );
};
