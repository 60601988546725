import styled from "styled-components/macro";

export const EditPrescriptionModalBodyInputsContainer = styled.div`
  display: grid;
  gap: 1.5rem 0rem;
  margin-top: 26px;

  label {
    margin-left: 2.5px;
  }
`;
