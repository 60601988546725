import { useFormContext } from "react-hook-form";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { ContentModuleLessonMobilePreviewCardsList } from "../../mobile-preview-lesson-cards-styles";
import { UpdateContentModuleLessonMobilePreviewCard } from "./update-content-module-lesson-mobile-preview-card";
import { ContentModuleUpdateModeInputs } from "../../../../context/content-module-mode-context";
import { useConentModuleMobilePreviewCarousel } from "../../context/conent-module-mobile-preview-carousel-context";

type UpdateContentModuleLessonMobilePreviewCardsProps = {};

export const UpdateContentModuleLessonMobilePreviewCards = (
  props: UpdateContentModuleLessonMobilePreviewCardsProps
) => {
  const { watch } = useFormContext<ContentModuleUpdateModeInputs>();
  const contentModuleCards = watch("contentModuleCards");

  const { currentCard } = useConentModuleMobilePreviewCarousel();

  if (contentModuleCards.length === 0) {
    return (
      <ContentModuleLessonMobilePreviewCardsList>
        <EmptyState
          button={<></>}
          hasHeader={false}
          hasSearch={false}
          text="No Cards Added Yet"
          title=""
        />
      </ContentModuleLessonMobilePreviewCardsList>
    );
  }

  return (
    <ContentModuleLessonMobilePreviewCardsList>
      {contentModuleCards.map((card, index) => (
        <UpdateContentModuleLessonMobilePreviewCard
          key={index}
          card={card}
          activeCard={index === currentCard}
        />
      ))}
    </ContentModuleLessonMobilePreviewCardsList>
  );
};
