import { Channel, MessageList, Thread, Window } from "stream-chat-react";
import { useState } from "react";
import { MessagingChannelHeader } from "./messaging-channel-header/messaging-channel-header";
import { MessagingSidebar } from "./messaging-sidebar/messaging-sidebar";
import { ChatHomeBodyContainer } from "../styled-chat-home";
import { CustomMessage } from "./custom-message/custom-message";
import { MessagingInput } from "./messaging-input/messaging-input";
import { formatDate } from "../../utils/date-format-utils";
import { MessagingMediaSidebar } from "./messaging-media-sidebar/messaging-media-sidebar";

export const ChatHomeBody = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const handleShowSidebar = () => setShowSidebar(true);
  const handleHideSidebar = () => setShowSidebar(false);

  const [showMediaSidebar, setShowMediaSidebar] = useState(false);
  const handleToggleMediaSidebar = () => setShowMediaSidebar(!showMediaSidebar);
  const handleHideMediaSidebar = () => setShowMediaSidebar(false);

  return (
    <ChatHomeBodyContainer>
      <MessagingSidebar
        showSidebar={showSidebar}
        handleHideSidebar={handleHideSidebar}
        handleHideMediaSidebar={handleHideMediaSidebar}
      />
      <Channel>
        <Window>
          <MessagingChannelHeader
            handleShowSidebar={handleShowSidebar}
            handleToggleMediaSidebar={handleToggleMediaSidebar}
          />
          <MessageList
            Message={CustomMessage}
            formatDate={(date) => formatDate(date)}
            closeReactionSelectorOnClick={true}
          />
          <MessagingInput />
        </Window>
        <Thread />
        <MessagingMediaSidebar
          showMediaSidebar={showMediaSidebar}
          handleHideMediaSidebar={handleHideMediaSidebar}
        />
      </Channel>
    </ChatHomeBodyContainer>
  );
};
