import {
  AxisModel,
  ChartComponent,
  ColumnSeries,
  DataLabel,
  DateTime,
  Inject,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  TooltipSettingsModel,
  Tooltip,
  AxesDirective,
  AxisDirective,
  RowsDirective,
  RowDirective,
  MarkerSettingsModel,
} from "@syncfusion/ej2-react-charts";
import { BgmChartContainer } from "./styled-bgm-chart";
import {
  FilteredPatientDataByDate,
  useFilterPatientCGMEntries,
} from "../../hooks/use-filter-patient-cgm-entries/use-filter-patient-cgm-entries";
import { useCallback, useEffect, useMemo } from "react";
import { Temporal } from "temporal-polyfill";
import { buildBgmChartTooltip } from "./bgm-chart-tooltip/build-bgm-chart-tooltip";
import { buildInRangeSeries } from "./series/build-in-range-series";
import { buildOutOfRangeSeries } from "./series/build-out-of-range-series";
import { buildInRangeTagsSeries } from "./series/build-in-range-tags-series";
import { buildOutOfRangeTagsSeries } from "./series/build-out-of-range-tags-series";
import { PatientType } from "../../models/patient-dtos/patient-type";
import { randomUUID } from "../../utils/random-uuid";
import useOnReadingsChanged from "../../screens/single-patient/bgl-analysis/upload-options/manual-readings/manual-readings-modal/hook/useOnReadingsChanged";
import { isGlucosePointInRange } from "../../utils/glucose-point-utils/glucose-point-range-utils/is-glucose-point-in-range";
import { InsulinSeries } from "../../screens/single-patient/bgl-analysis/manual-readings-graph/manual-readings-chart/utils/build-insulin-doses-series";

type BgmChartProps = {
  startDate: string;
  endDate: string;
  tagValue: string;
  patientType: PatientType;
  hasTooltip: boolean;
  containerHeight?: string;
  fullWidth?: boolean;
  patientPlansPatientId?: number;
  insulinSeriesDataSource?: InsulinSeries[];
};

export type IsInRange = {
  isInrange: boolean | undefined;
  item: FilteredPatientDataByDate;
};

export const BgmChart = (props: BgmChartProps) => {
  const {
    startDate,
    endDate,
    tagValue,
    containerHeight,
    patientType,
    hasTooltip,
    fullWidth,
    patientPlansPatientId,
    insulinSeriesDataSource,
  } = props;

  const { onReadingsChanged, patientData } = useOnReadingsChanged(
    patientPlansPatientId
  );

  const {
    filteredPatientDataByDate,
    filteredByTags,
  } = useFilterPatientCGMEntries(startDate, endDate, tagValue, patientData);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onReadingsChanged(), []);

  const id = useMemo(() => `manual-readings-chart-${randomUUID()}`, []);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);
    // Should always enter this condition, but let's be safe
    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const tooltip: TooltipSettingsModel = useMemo(
    () => buildBgmChartTooltip(hasTooltip),
    [hasTooltip]
  );

  const primaryxAxis: AxisModel = {
    valueType: "DateTime",
    labelFormat: "dd-MM <br> hh:mm",
    majorGridLines: { width: 0 },
    edgeLabelPlacement: "Shift",
  };

  const primaryyAxis: AxisModel = {
    minimum: 40,
  };

  const markerRed = {
    visible: true,
    width: 10,
    height: 10,
    isFilled: true,
    fill: "#fff",
    border: { width: 2, color: "#ee3c3e" },
    dataLabel: { visible: true },
  };

  const markerGreen = {
    visible: true,
    width: 10,
    height: 10,
    isFilled: true,
    fill: "#fff",
    border: { width: 2, color: "#63c38f" },
    dataLabel: { visible: true },
  };

  const markerInsulin: MarkerSettingsModel = {
    visible: true,
    width: 25,
    height: 25,
    shape: "Image",
    imageUrl: "/img/insulin.svg",
  };

  const screenWidthLessThan1300px = window.innerWidth >= 1300 ? true : false;

  const isInRange: IsInRange[] = filteredPatientDataByDate.map((item) => ({
    isInrange: isGlucosePointInRange(patientType, {
      glucose: item.glucoseMGPerDL,
      source: "Manual",
      time: Temporal.PlainTime.from(item.time),
      tag: item.tag,
    }),
    item,
  }));

  const inRangeSeriesDataSource = useMemo(() => buildInRangeSeries(isInRange), [
    isInRange,
  ]);

  const inRangeTagsSeriesDataSource = useMemo(
    () => buildInRangeTagsSeries(isInRange, filteredByTags),
    [isInRange, filteredByTags]
  );

  const outOfRangeSeriesDataSource = useMemo(
    () => buildOutOfRangeSeries(isInRange),
    [isInRange]
  );

  const outOfRangeTagsSeriesDataSource = useMemo(
    () => buildOutOfRangeTagsSeries(isInRange, filteredByTags),
    [isInRange, filteredByTags]
  );

  return (
    <BgmChartContainer
      containerHeight={containerHeight !== undefined ? containerHeight : "auto"}
    >
      <ChartComponent
        id={id}
        primaryXAxis={primaryxAxis}
        primaryYAxis={primaryyAxis}
        title="Manual Readings"
        height="100%"
        width={fullWidth ? "100%" : screenWidthLessThan1300px ? "800px" : "90%"}
        tooltip={tooltip}
        loaded={handleChartLoaded}
      >
        <Inject
          services={[DataLabel, ColumnSeries, DateTime, Tooltip, LineSeries]}
        />
        {insulinSeriesDataSource !== undefined && (
          <AxesDirective>
            <AxisDirective
              rowIndex={1}
              name="yAxis1"
              opposedPosition={true}
              title="Insulin"
              labelFormat="{value} units"
              minimum={0}
              interval={3}
            ></AxisDirective>
          </AxesDirective>
        )}
        {insulinSeriesDataSource !== undefined && (
          <RowsDirective>
            <RowDirective height="50%"></RowDirective>
          </RowsDirective>
        )}
        {filteredPatientDataByDate.length >= 10 ? (
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={
                filteredByTags.length !== 0
                  ? outOfRangeTagsSeriesDataSource
                  : outOfRangeSeriesDataSource
              }
              xName="x"
              yName="y"
              type="Line"
              fill={"transparent"}
              width={4}
              marker={markerRed}
              tooltipMappingName="tooltip"
            ></SeriesDirective>
            <SeriesDirective
              dataSource={
                filteredByTags.length !== 0
                  ? inRangeTagsSeriesDataSource
                  : inRangeSeriesDataSource
              }
              xName="x"
              yName="y"
              type="Line"
              fill="transparent"
              width={4}
              marker={markerGreen}
              tooltipMappingName="tooltip"
            ></SeriesDirective>
            {insulinSeriesDataSource !== undefined && (
              <SeriesDirective
                dataSource={insulinSeriesDataSource.filter(
                  (item) =>
                    item.x.toISOString().split("T")[0].toString() >=
                      startDate &&
                    item.x.toISOString().split("T")[0].toString() <= endDate
                )}
                xName="x"
                yName="y"
                type="Line"
                marker={markerInsulin}
                yAxisName="yAxis1"
                fill="transparent"
                tooltipMappingName="tooltip"
              ></SeriesDirective>
            )}
          </SeriesCollectionDirective>
        ) : (
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={
                filteredByTags.length !== 0
                  ? inRangeTagsSeriesDataSource
                  : inRangeSeriesDataSource
              }
              xName="x"
              yName="y"
              type="Column"
              fill="#63c38f"
              width={4}
              marker={markerGreen}
              tooltipMappingName="tooltip"
            ></SeriesDirective>
            <SeriesDirective
              dataSource={
                filteredByTags.length !== 0
                  ? outOfRangeTagsSeriesDataSource
                  : outOfRangeSeriesDataSource
              }
              xName="x"
              yName="y"
              type="Column"
              fill="#ee3c3e"
              width={4}
              marker={markerRed}
              tooltipMappingName="tooltip"
            ></SeriesDirective>
            {insulinSeriesDataSource !== undefined && (
              <SeriesDirective
                dataSource={insulinSeriesDataSource.filter(
                  (item) =>
                    item.x.toISOString().split("T")[0].toString() >=
                      startDate &&
                    item.x.toISOString().split("T")[0].toString() <= endDate
                )}
                xName="x"
                yName="y"
                type="Line"
                marker={markerInsulin}
                yAxisName="yAxis1"
                fill="transparent"
                tooltipMappingName="tooltip"
              ></SeriesDirective>
            )}
          </SeriesCollectionDirective>
        )}
      </ChartComponent>
    </BgmChartContainer>
  );
};
