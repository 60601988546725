import { useParams } from "react-router-dom";
import { LoadableDataLoading } from "../../../../components/loadable-data-loading";
import {
  PatientProfileContainer,
  PatientProfileSection,
} from "../styles/patient-profile-styles/patient-profile-classes";
import { LoadablePatientProfileDietProvider } from "./context/loadable-patient-profile-diet-context";
import { PatientProfileDiet } from "./patient-profile-diet/patient-profile-diet";
import { PatientProfileMedications } from "./patient-profile-medications/patient-profile-medications";
import { useCallback } from "react";
import { useLoadableData } from "../../../../hooks/use-loadable-data";
import { useGetPatientDietProfile } from "../../../../hooks/patient-diet-profile-hooks/use-get-patient-diet-profile";
import { useGetAddedSugars } from "../../../../hooks/added-sugar-hooks/use-get-added-sugars";
import { useGetActivities } from "../../../../hooks/activity-hooks/use-get-activities";
import { useGetDiets } from "../../../../hooks/diet-hooks/use-get-diets";
import { useGetFoodAllergies } from "../../../../hooks/food-allergy-hooks/use-get-food-allergies";
import { useGetPatient } from "../../../../hooks/patient-hooks/use-get-patient";

export const PatientProfileMedicationsSection = () => {
  const { id } = useParams();

  const getPatient = useGetPatient();
  const getPatientDietProfile = useGetPatientDietProfile();
  const getAddedSugars = useGetAddedSugars();
  const getActivities = useGetActivities();
  const getDiets = useGetDiets();
  const getFoodAllergies = useGetFoodAllergies();

  const patientProfileDietCallback = useCallback(async () => {
    const patientId = parseInt(id!);

    const [
      patientDTO,
      patientDietProfileDTO,
      addedSugars,
      activities,
      diets,
      foodAllergies,
    ] = await Promise.all([
      getPatient(patientId),
      getPatientDietProfile(patientId),
      getAddedSugars(),
      getActivities(),
      getDiets(),
      getFoodAllergies(),
    ]);

    return {
      patientDTO,
      patientDietProfileDTO,
      addedSugars,
      activities,
      diets,
      foodAllergies,
    };
  }, [
    id,
    getPatient,
    getPatientDietProfile,
    getAddedSugars,
    getActivities,
    getDiets,
    getFoodAllergies,
  ]);

  const loadablePatientProfileDiet = useLoadableData(
    patientProfileDietCallback
  );

  return (
    <PatientProfileSection reverse={false} height={520}>
      <PatientProfileMedications />
      <LoadablePatientProfileDietProvider
        loadablePatientProfileDiet={loadablePatientProfileDiet}
      >
        <PatientProfileContainer>
          <LoadableDataLoading
            state={loadablePatientProfileDiet.state}
            successComponent={() => <PatientProfileDiet />}
          />
        </PatientProfileContainer>
      </LoadablePatientProfileDietProvider>
    </PatientProfileSection>
  );
};
