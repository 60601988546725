import { PropsWithChildren, createContext, useContext } from "react";
import { UseLoadableDataReturn } from "../../../../../hooks/use-loadable-data";
import { PatientTimelineDTO } from "../../../../../models/patient-timeline-dtos/patient-timeline-dto";

export type PatientProfileTimeline = {
  patientTimelineDTO: PatientTimelineDTO;
};

export type LoadablePatientProfileTimeline =
  UseLoadableDataReturn<PatientProfileTimeline>;

const LoadablePatientProfileTimelineContext = createContext<
  LoadablePatientProfileTimeline | undefined
>(undefined);

export type LoadablePatientProfileTimelineProviderProps = PropsWithChildren<{
  loadablePatientProfileTimeline: LoadablePatientProfileTimeline;
}>;

export function LoadablePatientProfileTimelineProvider(
  props: LoadablePatientProfileTimelineProviderProps
) {
  const { loadablePatientProfileTimeline, children } = props;

  return (
    <LoadablePatientProfileTimelineContext.Provider
      value={loadablePatientProfileTimeline}
    >
      {children}
    </LoadablePatientProfileTimelineContext.Provider>
  );
}

export function useLoadablePatientProfileTimeline(): LoadablePatientProfileTimeline {
  const loadablePatientProfileTimeline = useContext(
    LoadablePatientProfileTimelineContext
  );

  if (loadablePatientProfileTimeline === undefined) {
    throw Error(
      "useLoadablePatientProfileTimeline must be used within an LoadablePatientProfileTimelineProvider"
    );
  }

  return loadablePatientProfileTimeline;
}

export function usePatientProfileTimeline(): PatientProfileTimeline {
  const loadablePatientProfileTimeline = useLoadablePatientProfileTimeline();

  if (loadablePatientProfileTimeline.state.data === undefined) {
    throw Error("Patient Profile have never been loaded successfully yet.");
  }

  return loadablePatientProfileTimeline.state.data;
}

export function useReloadPatientProfileTimeline(): () => void {
  const loadablePatientProfileTimeline = useLoadablePatientProfileTimeline();

  return loadablePatientProfileTimeline.reload;
}
