import React, { useRef } from "react";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileModalLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { Controller, useFormContext } from "react-hook-form";
import { PatientTypeSelect } from "../../../../../../../patients/patient-modal/patient-type-select";
import { ErrorText } from "../../../../../../../../styles/classes/reusable-classes";
import { PatientProfileEditTimelineModalInputs } from "../../patient-profile-edit-timeline-modal";
import { DiabetesDateInputContainer } from "./styled-patient-profile-diabetes-type-form-control";

export const PatientProfileDiabetesTypeFormControl = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext<PatientProfileEditTimelineModalInputs>();

  const typeDiagnosedSinceRef = useRef<HTMLInputElement | null>(null);

  return (
    <PatientProfileModalFormControl>
      <PatientProfileModalLabelContainer>
        <label>Diabetes type</label>
      </PatientProfileModalLabelContainer>

      <PatientProfileModalInputContainer hasTwoInputsFlex>
        <Controller
          name="type"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <PatientTypeSelect
              value={value}
              onChange={onChange}
              variant="designed-ux"
            />
          )}
        />
        {errors.type?.type === "required" && (
          <ErrorText>Select patient's type</ErrorText>
        )}
        <DiabetesDateInputContainer>
          <PatientProfileModalInputContainer
            hasOneInput
            onClick={() => {
              typeDiagnosedSinceRef.current?.showPicker();
            }}
          >
            <span className="material-symbols-outlined">calendar_today</span>
            <Controller
              name="diagnosedSince"
              control={control}
              rules={{ required: false }}
              render={({ field: { value, onChange } }) => (
                <input
                  ref={typeDiagnosedSinceRef}
                  type="month"
                  value={value === undefined ? undefined : value}
                  onChange={(e) =>
                    onChange(e.target.value !== undefined ? e.target.value : "")
                  }
                />
              )}
            />
          </PatientProfileModalInputContainer>
        </DiabetesDateInputContainer>
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
