import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  mediumFont,
  regularSemiBoldFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const PatientProfileLifeActivitiesTooltipBodyList = styled.ul`
  display: grid;
  gap: 8px;
  padding: 0.5rem;
`;

export const PatientProfileLifeActivitiesTooltipBodyListItem = styled.li`
  ${flexCenterSpaceBetween}

  strong {
    margin: 0px;
    ${regularSemiBoldFont}
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.titleColor};
  }

  span {
    ${mediumFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
