import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  xSmallSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";

export const ContentModuleLessonMobilePreviewStatusBarContainer = styled.div`
  ${flexCenterSpaceBetween}
  padding: 8px 0px;
  height: 30px;
  background: ${({ theme }) => theme.colors.containerColor};

  div {
    ${flexCenter}
    gap: 0px 4px;
    width: 50px;
  }

  .create-lesson-mobile-preview-dynamic-island {
    width: 45px;
    height: 10px;
    background-color: #000;
    border-radius: 94px;
    margin: 0 auto;
  }

  .create-lesson-mobile-preview-time {
    ${xSmallSemiBoldFont}
    margin: 0px;
    width: 50px;
  }

  .create-lesson-mobile-preview-battery {
    transform: rotate(90deg);
  }

  span {
    font-size: 11px;
  }
`;
