import { Dropdown } from "../../../../components/dropdown/dropdown";
import { SidebarIcon } from "../../single-patient-header/sidebar-icon/sidebar-icon";
import { ImportCronometer } from "./import-cronometer";
import { ImportCronometerNewReadings } from "./import-cronometer-new-readings/import-cronometer-new-readings";
import { ImportLibreview } from "./import-libreview";
import { ImportLibreviewNewReadings } from "./import-libreview-new-readings/import-libreview-new-readings";
import { ManualReadings } from "./manual-readings/manual-readings";

export const BglAnalysisUploadOptions = () => {
  return (
    <>
      <Dropdown text="Upload">
        <ImportLibreview />
        <ImportLibreviewNewReadings />
        <ImportCronometer />
        <ImportCronometerNewReadings />
        <ManualReadings />
      </Dropdown>
      <SidebarIcon />
    </>
  );
};
