import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  ViewPlansIconContainer,
  ViewPlansTextContainer,
} from "../styled-view-plans";
import {
  ViewWeeklyPlansHeaderBackIcon,
  ViewWeeklyPlansHeaderContianer,
  ViewWeeklyPlansTitleCard,
} from "./styled-view-weekly-plans";

export const ViewWeeklyPlans = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const header = (
    <ViewWeeklyPlansHeaderContianer>
      <ViewWeeklyPlansHeaderBackIcon
        className="material-symbols-outlined"
        onClick={() => navigate("../")}
      >
        west
      </ViewWeeklyPlansHeaderBackIcon>
      {location.pathname.includes("medications") && (
        <ViewWeeklyPlansTitleCard>
          <ViewPlansTextContainer usedAsHeader={true}>
            <p>الأدوية</p>
            <span>
              أدوية مختارة لك من الطبيب المعالج لمساعدتك في الخطة العلاجية
              والوصول للأهداف المتفق عليها
            </span>
          </ViewPlansTextContainer>
          <ViewPlansIconContainer>
            <img src="/img/view-plans/medications.svg" alt="Medications icon" />
          </ViewPlansIconContainer>
        </ViewWeeklyPlansTitleCard>
      )}
      {location.pathname.includes("plans-list") && (
        <ViewWeeklyPlansTitleCard>
          <ViewPlansTextContainer usedAsHeader={true}>
            <p>خطة الأسبوع</p>
            <span>
              تعليمات ونتائج للمتابعة بشكل دوري لمساعدتك في تحقيق أهدافك الصحية
            </span>
          </ViewPlansTextContainer>
          <ViewPlansIconContainer>
            <img src="/img/view-plans/plans.svg" alt="Plans icon" />
          </ViewPlansIconContainer>
        </ViewWeeklyPlansTitleCard>
      )}
    </ViewWeeklyPlansHeaderContianer>
  );

  return (
    <div>
      {header}
      <Outlet />
    </div>
  );
};
