import { NutrtionReportSummaryNotesContainer } from "./styled-nutrtion-report-summary-notes";
import { NutrtionReportSummaryHealtyNotes } from "./nutrtion-report-summary-healthy-notes/nutrtion-report-summary-healty-notes";
import { NutrtionReportSummaryUnhealthyNotes } from "./nutrtion-report-summary-unhealthy-notes/nutrtion-report-summary-unhealthy-notes";

export const NutrtionReportSummaryNotes = () => {
  return (
    <NutrtionReportSummaryNotesContainer>
      <NutrtionReportSummaryHealtyNotes />
      <NutrtionReportSummaryUnhealthyNotes />
    </NutrtionReportSummaryNotesContainer>
  );
};
