import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { BASE_PATH } from "../../constants/paths";

export function useSyncPatient(): (patientId: Number) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientId) => {
      await client.post<void>(`${BASE_PATH}/chat/patients/${patientId}/sync`);
    },
    [client]
  );
}
