import { Controller, useFormContext } from "react-hook-form";
import {
  PatientProfileModalFormControl,
  PatientProfileModalFormControlFlexNoStyles,
  PatientProfileModalInputContainer,
  PatientProfileModalLabelContainer,
} from "../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileEditProfileInputs } from "../patient-profile-edit-profile-modal";
import { useRef } from "react";

export const PatientProfileEditProfileBirthdateFormControl = () => {
  const { control } = useFormContext<PatientProfileEditProfileInputs>();

  const dateInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <PatientProfileModalFormControl gridLayout>
      <PatientProfileModalFormControlFlexNoStyles>
        <PatientProfileModalLabelContainer>
          <label>Birthday date</label>
        </PatientProfileModalLabelContainer>
        <PatientProfileModalInputContainer
          hasOneInput
          onClick={() => {
            dateInputRef.current?.showPicker();
          }}
        >
          <span className="material-symbols-outlined">calendar_today</span>
          <Controller
            name="dateOfBirth"
            control={control}
            rules={{ required: false }}
            render={({ field: { value, onChange } }) => (
              <input
                ref={dateInputRef}
                type="date"
                placeholder="Birthday date"
                value={value !== undefined ? value : undefined}
                onChange={(e) =>
                  onChange(
                    e.target.value !== undefined ? e.target.value : undefined
                  )
                }
              />
            )}
          />
        </PatientProfileModalInputContainer>
      </PatientProfileModalFormControlFlexNoStyles>
    </PatientProfileModalFormControl>
  );
};
