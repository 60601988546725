import {
  getAfterMealTag,
  getTag,
} from "../../../../../bgl-analysis/upload-options/manual-readings/manual-readings-modal/manual-readings-table/tag-dropdown/tag-dropdown-utils";

export function translateTag(tag: string) {
  if (tag === "All") {
    return "الكل";
  }
  if (tag === getTag("fasting")) {
    return "صائم";
  }
  if (tag === getTag("beforeMeal")) {
    return "قبل الوجبة";
  }
  if (tag === getTag("afterMeal")) {
    return "بعد الوجبة";
  }
  if (tag === getTag("bedtime")) {
    return "وقت النوم";
  }
  if (tag === getTag("beforeActivity")) {
    return "قبل ممارسة نشاط بدني";
  }
  if (tag === getTag("afterActivity")) {
    return "بعد ممارسة نشاط بدني";
  }
  if (tag === getTag(getAfterMealTag("readingTime")!)) {
    return "وقت القراءة";
  }
  if (tag.includes(getAfterMealTag("immediately")!)) {
    return "في الحال بعد الوجبة";
  }
  if (tag.includes(getAfterMealTag("withinHour")!)) {
    return "بعد الوجبة خلال 1 ساعة";
  }
  if (tag.includes(getAfterMealTag("hours2")!)) {
    return "ساعتين بعد الوجبة";
  }
  if (tag.includes(getAfterMealTag("hours3")!)) {
    return "بعد الوجبة 3-5 ساعات";
  }
  if (tag.includes(getAfterMealTag("hours6")!)) {
    return "بعد الوجبة 6-8 ساعات";
  }
}
