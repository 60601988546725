import { useMemo } from "react";
import { LabTestTypeDTO } from "../../../../../models/lab-test-types/lab-test-type-dto";
import { IonizedCalciumDropdown } from "../ionized-calcium-dropdown/ionized-calcium-dropdown";
import { LabResultsModalBodyUnitInputContainer } from "../styles";

type NewLabResultsUnitsProps = {
  labTestTypeDTO: LabTestTypeDTO;
  value: string | undefined;
  onChange: (value: string) => void;
};

export const NewLabResultsUnits = (props: NewLabResultsUnitsProps) => {
  const { labTestTypeDTO, value, onChange } = props;

  const units = useMemo(
    () =>
      labTestTypeDTO.name === "Ionized Calcium"
        ? [labTestTypeDTO.unit, "mmol/L"]
        : [labTestTypeDTO.unit],
    [labTestTypeDTO.name, labTestTypeDTO.unit]
  );

  if (labTestTypeDTO.name === "Ionized Calcium") {
    return (
      <IonizedCalciumDropdown value={value} onChange={onChange} units={units} />
    );
  } else {
    return (
      <LabResultsModalBodyUnitInputContainer>
        {units.map((unit, index) => (
          <input key={index} type="text" readOnly defaultValue={unit} />
        ))}
      </LabResultsModalBodyUnitInputContainer>
    );
  }
};
