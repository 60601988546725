import { MutableRefObject } from "react";
import {
  Avatar,
  MessageOptions,
  MessageRepliesCountButton,
  MessageStatus,
  ReactionSelector,
  SimpleReactionsList,
  StreamMessage,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { CSSProperties } from "styled-components";

type SenderCustomMessageProps = {
  isPinnedMessage: boolean;
  message: StreamMessage<DefaultStreamChatGenerics>;
  reactionSelectorRef?: MutableRefObject<HTMLDivElement | null>;
  showDetailedReactions?: boolean;
  hasReactions?: boolean;
};

export const SenderCustomMessage = (props: SenderCustomMessageProps) => {
  const {
    isPinnedMessage,
    message,
    showDetailedReactions,
    reactionSelectorRef,
    hasReactions,
  } = props;

  const hasAttachments = message.attachments && message.attachments.length > 0;

  const voiceAttachments = [];

  if (hasAttachments) {
    voiceAttachments.push(
      message.attachments?.find((msg) => msg.type === "audio")
    );
  }

  const containerStyle: CSSProperties = {
    justifyContent: "flex-end",
    alignItems: "center",
  };

  if (isPinnedMessage) {
    return (
      <div className="str-chat__message str-chat__message-simple str-chat__message--regular str-chat__message--received str-chat__message--other str-chat__message--has-text">
        <div className="str-chat__message-inner audio-sent-by-me">
          <audio
            src={`${voiceAttachments[0]?.asset_url}`}
            controls
            controlsList="nodownload noplaybackrate"
          ></audio>
          <div className="str-chat__message-data str-chat__message-simple-data">
            <time className="str-chat__message-simple-timestamp custom-message-time">
              {message.created_at !== undefined
                ? new Date(message.created_at).toLocaleString("en-AU", {
                    month: undefined,
                    year: undefined,
                    day: undefined,
                    hour: "2-digit",
                    minute: "2-digit",
                    weekday: undefined,
                  })
                : ""}
            </time>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={containerStyle}
        className="str-chat__message str-chat__message-simple str-chat__message--regular str-chat__message--received str-chat__message--other str-chat__message--has-text"
      >
        <div className="str-chat__message-inner audio-sent-by-me">
          {showDetailedReactions && (
            <ReactionSelector ref={reactionSelectorRef} />
          )}
          <audio
            src={`${voiceAttachments[0]?.asset_url}`}
            controls
            controlsList="nodownload noplaybackrate"
          ></audio>
          <div className="str-chat__message-data str-chat__message-simple-data">
            <time className="str-chat__message-simple-timestamp custom-message-time">
              {message.created_at?.toLocaleString("en-AU", {
                month: undefined,
                year: undefined,
                day: undefined,
                hour: "2-digit",
                minute: "2-digit",
                weekday: undefined,
              })}
            </time>
            <MessageStatus
              Avatar={() => <Avatar image={message.user?.image} />}
            />
          </div>
          {hasReactions && (
            <span className={`${hasReactions ? "hide-reactions" : ""}`}>
              <SimpleReactionsList />
            </span>
          )}
          <MessageRepliesCountButton reply_count={message.reply_count} />
          <MessageOptions />
        </div>
        <Avatar image={message.user?.image} />
      </div>
    );
  }
};
