import { useMemo } from "react";
import { FamilyMember } from "../../../../../../models/family-member";
import { FamilyMemberDisease } from "../../../../../../models/family-member-disease";
import {
  PatientProfileMedicalHistoryItemContainer,
  PatientProfileMedicalHistoryItemTextContainer,
  PatientProfileMedicalHistoryItemTitleContainer,
} from "../styled-profile-medical-history";
import { viewFamilyMemberDisease } from "../../../utils/patient-profile-utils";
import { usePatientProfileMedicalHistory } from "../../context/loadable-patient-profile-medical-history";

export type TransformedFamilyMember = {
  familyMember: FamilyMember;
  familyMemberDiseases: FamilyMemberDisease[];
  other?: string;
};

export const PatientProfileMedicalHistoryReadFamilyDisease = () => {
  const { patientMedicalHistoryDTO } = usePatientProfileMedicalHistory();

  const familyMemberDiseases = patientMedicalHistoryDTO.familyMemberDiseases;

  const transformedFamilyMembers = useMemo((): TransformedFamilyMember[] => {
    const transformedFamilyMembers: TransformedFamilyMember[] = [];

    const familyMembersMap: Map<FamilyMember, FamilyMemberDisease[]> =
      new Map();

    familyMemberDiseases.forEach((item) => {
      item.familyMembers.forEach((familyMember) => {
        if (!familyMembersMap.has(familyMember)) {
          familyMembersMap.set(familyMember, []);
        }

        const diseases = familyMembersMap.get(familyMember);
        if (diseases) {
          diseases.push(item.familyMemberDisease);
        }
      });
    });

    familyMembersMap.forEach((diseases, familyMember) => {
      transformedFamilyMembers.push({
        familyMember,
        familyMemberDiseases: diseases,
        other: familyMemberDiseases.find((item) => item.other !== undefined)
          ?.other,
      });
    });

    return transformedFamilyMembers;
  }, [familyMemberDiseases]);

  return (
    <PatientProfileMedicalHistoryItemContainer>
      <PatientProfileMedicalHistoryItemTitleContainer>
        <img src="/img/patient-profile/medical-history/family.svg" alt="" />
        <p>Family disease</p>
      </PatientProfileMedicalHistoryItemTitleContainer>
      <PatientProfileMedicalHistoryItemTextContainer>
        <div>
          {transformedFamilyMembers !== undefined &&
          transformedFamilyMembers.length > 0
            ? transformedFamilyMembers.map((item, index) => (
                <p key={index}>
                  {`${
                    item.familyMember !== "Other" ? `${item.familyMember}:` : ""
                  } `}
                  {item.familyMember !== "Other" ? (
                    `(
                  ${item.familyMemberDiseases
                    .map(
                      (disease, i) =>
                        `${viewFamilyMemberDisease(disease)}${
                          item.familyMemberDiseases.length > 1 &&
                          item.familyMemberDiseases[i + 1]
                            ? ", "
                            : ""
                        }`
                    )
                    .join(" ")}
                  )`
                  ) : (
                    <></>
                  )}
                </p>
              ))
            : "--"}
        </div>
        <div>
          <p>
            {familyMemberDiseases !== undefined &&
            familyMemberDiseases.some((item) => item.other !== undefined)
              ? `Other: ${
                  familyMemberDiseases.find((item) => item.other)?.other
                }`
              : ""}
          </p>
        </div>
      </PatientProfileMedicalHistoryItemTextContainer>
    </PatientProfileMedicalHistoryItemContainer>
  );
};
