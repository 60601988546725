import { Modal } from "../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { AddLifestyleModalBody } from "./add-lifestyle-modal-body";

export const AddLifestyleModal = () => {
  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  return (
    <>
      <Button flex outlinedPrimary onClick={openModal}>
        <span className="material-symbols-outlined">add_circle_outline</span>
        Add goal
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <AddLifestyleModalBody key={modalBodyKey} onClose={closeModal} />
      </Modal>
    </>
  );
};
