import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  mediumRegularFont,
  regularRegularFont,
} from "../../../../styles/classes/gloabl-classes";
import { NavbarHeight } from "../../../../styles/global-style";

export const CreatePlansHeaderHeight = `190px`;

export const PlansSubScreenContainer = styled.div<{ reviewLayout?: boolean }>`
  background: ${({ theme, reviewLayout }) =>
    reviewLayout ? "transparent" : theme.colors.containerColor};
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  margin: ${({ reviewLayout }) =>
    reviewLayout
      ? "0px 24px"
      : `calc(${CreatePlansHeaderHeight} + 24px) 24px 0px 24px`};
  padding-bottom: 5rem;
  min-height: calc(
    100vh - (${CreatePlansHeaderHeight} + 24px + ${NavbarHeight})
  );

  @media screen and (max-width: 620px) {
    width: 100%;
    border-radius: 0px;
    margin: calc(${CreatePlansHeaderHeight} + 24px) 0px 0px;
  }
`;

export const PlansInputLabel = styled.label`
  ${regularRegularFont}
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const GoalsListItem = styled.li`
  ${flexCenterSpaceBetween}flex-direction: row-reverse;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 12px;
  margin: 12px 0px;
`;

export const AddGoalModalTextAreaContainer = styled.div`
  margin: 24px;
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.inputColor};
  padding: 1rem;

  textarea {
    background: transparent;
    border: 0;
    outline: 0;
    font-family: "IBM Plex Sans Arabic", sans-serif;
    resize: none;
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.titleColor};
    width: 100%;
    height: 100%;

    ::placeholder {
      color: ${({ theme }) => theme.colors.titleColor};
    }
  }
`;
