import { useHomeStaticData } from "../../../../../context/home-static-data-context";
import { DoubleRangeInput } from "../components/double-range-input/double-range-input";
import { HomeFilterDropdown } from "../components/home-filter-dropdown";
import { useCallback } from "react";
import { ImmediateAttentionPatientsTableAgeFilterContainer } from "./styled-immediate-attention-patients-table-age-filter";
import { useImmediateAttentionPatientsSelectedFilters } from "../../../../context/immediate-attention-patients-selected-filters-context";

export const ImmediateAttentionPatientsTableAgeFilter = () => {
  const {
    setSelectedFilters,
    selectedFilters,
  } = useImmediateAttentionPatientsSelectedFilters();

  const { homeStaticData, setFilteredHomeStaticData } = useHomeStaticData();

  const handleAddFilter = useCallback(
    (ageRange: string) => {
      const existingFilter = selectedFilters.find(
        (filter) => filter.filterName === "Age"
      );

      if (existingFilter) {
        // Update the existing filter
        setSelectedFilters(
          selectedFilters.map((filter) =>
            filter.filterName === "Age"
              ? { ...filter, filterValue: ageRange }
              : filter
          )
        );
      } else {
        // Add a new filter
        setSelectedFilters([
          ...selectedFilters,
          { filterName: "Age", filterValue: ageRange },
        ]);
      }
    },
    [selectedFilters, setSelectedFilters]
  );

  const handleRangeChange = useCallback(
    (minValue: number, maxValue: number) => {
      setFilteredHomeStaticData(
        homeStaticData.filter(
          (item) => item.patient.age >= minValue && item.patient.age <= maxValue
        )
      );
      handleAddFilter(`From: ${minValue} To: ${maxValue}`);
    },
    [homeStaticData, setFilteredHomeStaticData, handleAddFilter]
  );

  return (
    <HomeFilterDropdown
      width="200px"
      height="fit-content"
      buttonText="Age"
      dropdownOptions={
        <ImmediateAttentionPatientsTableAgeFilterContainer
          style={{ padding: "24px 0px 0px" }}
        >
          <DoubleRangeInput
            min={0}
            max={100}
            step={1}
            onChange={handleRangeChange}
            ageFilter
          />
        </ImmediateAttentionPatientsTableAgeFilterContainer>
      }
    />
  );
};
