import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getCategoryLabTestTypeDTOsFromLocation } from "../../utils/lab-test-results-utils";
import { LabResultsDropdown } from "../lab-results-dropdown/lab-results-dropdown";
import { LabResultsDropdownListItem } from "../lab-results-dropdown/styled-lab-results-dropdown";
import { useLabResults } from "../../context/loadable-lab-results-context";

type NewLabResultsTypeDropdownProps = {
  value: number | undefined;
  onChange: (value: number) => void;
  disabledValue: boolean;
};

export const NewLabResultsTypeDropdown = (
  props: NewLabResultsTypeDropdownProps
) => {
  const { value, onChange, disabledValue } = props;

  const { labTestCategoryDTOs, labTestTypeDTOs } = useLabResults();

  const location = useLocation();

  const filteredLabTestTypeDTOs = useMemo(
    () =>
      getCategoryLabTestTypeDTOsFromLocation(
        location.pathname,
        labTestCategoryDTOs,
        labTestTypeDTOs
      ),
    [location.pathname, labTestTypeDTOs, labTestCategoryDTOs]
  );

  const displayValue = useMemo(
    () =>
      labTestTypeDTOs.find((labTestTypeDTO) => labTestTypeDTO.id === value)
        ?.name,
    [value, labTestTypeDTOs]
  );

  function handleLabTestResultTypeOnClick(value: number) {
    onChange(value);
  }

  const listItems = filteredLabTestTypeDTOs.map((test, index) => {
    return (
      <LabResultsDropdownListItem
        key={index}
        onClick={() => handleLabTestResultTypeOnClick(test.id)}
      >
        {test.name}
      </LabResultsDropdownListItem>
    );
  });

  return (
    <LabResultsDropdown
      displayText={
        displayValue === undefined ? "Select lab result type" : displayValue
      }
      label="Type"
      items={listItems}
      disabledValue={disabledValue}
    />
  );
};
