import { AnginaOrStroke } from "./angina-or-stroke/angina-or-stroke";
import { ChronicDiseases } from "./chronic-diseases/chronic-diseases";
import { DiabeticEmergency } from "./diabetic-emergency/diabetic-emergency";
import { FamilyHasDiseases } from "./family-has-diseases/family-has-diseases";
import { ObesityProcedure } from "./obesity-procedure/obesity-procedure";

export const MedicalHistoryTab = () => {
  return (
    <>
      <ChronicDiseases />
      <DiabeticEmergency />
      <ObesityProcedure />
      <AnginaOrStroke />
      <FamilyHasDiseases />
    </>
  );
};
