import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  mediumRegularFont,
  regularMediumFont,
} from "../../../styles/classes/gloabl-classes";

export const ModalHeaderContainer = styled.div`
  ${flexCenterSpaceBetween}
  padding: 0px 8px 16px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

  div {
    h1 {
      ${regularMediumFont}
      color: ${({ theme }) => theme.colors.titleColor};
      margin: 0;

      @media screen and (max-width: 500px) {
        max-width: 75%;
      }
    }

    p {
      margin: 4px 0px 0px;
      color: ${({ theme }) => theme.colors.textColor};
      ${mediumRegularFont}
    }
  }
`;

export const ModalCloseIcon = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textColor};
`;
