import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";

type NutritionReportProteingmTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportProteingmTableCell = (
  props: NutritionReportProteingmTableCellProps
) => {
  const { kind } = props;

  const { proteinGm } = useMemo(() => {
    const proteinGm =
      kind.kind === "Row"
        ? kind.data.totalProteinGm
        : kind.data.patientMealMacrosSummary.proteinGm;

    return { proteinGm };
  }, [kind.kind, kind.data]);

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      value={proteinGm}
    />
  );
};
