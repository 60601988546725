import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  flexCenterSpaceBetween,
  regularSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";

export const PateintProfileColumnHeaderContainer = styled.div<{
  lifeActivities?: boolean;
}>`
  ${flexCenterSpaceBetween}
  padding: ${({ lifeActivities }) =>
    lifeActivities ? "16px 8px 16px 24px" : "16px 24px"};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.containerColor};
  z-index: ${({ theme }) => theme.zIndex.z300};
  flex-wrap: wrap;
`;

export const PateintProfileColumnHeaderTitleContainer = styled.div`
  ${flexCenter}
  gap: 0px 12px;
`;

export const PateintProfileColumnHeaderTitleIconContainer = styled.div`
  ${flexCenterCenter}
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};

  img {
    width: 50%;
    height: 50%;
  }
`;

export const PateintProfileColumnHeaderTitle = styled.p`
  ${regularSemiBoldFont}
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0px;
`;

export const PateintProfileColumnHeaderEditButtonContainer = styled.div`
  img {
    filter: invert(1) grayscale(100%);
  }
`;
