import { useFormContext } from "react-hook-form";
import { ContentModuleLessonMobilePreview } from "../../../components/content-module-lesson-mobile-preview/content-module-lesson-mobile-preview";
import { CreateLessonCards } from "./create-lesson-cards/create-lesson-cards";
import { CreateLessonNameController } from "./create-lesson-controllers-left-controllers/create-lesson-name-controller";
import { CreateLessonTargetAudienceController } from "./create-lesson-controllers-right-controllers/create-lesson-target-audience-controller";
import { CreateLessonThumbnailController } from "./create-lesson-controllers-right-controllers/create-lesson-thumbnail-controller";
import { CreateLessonInputs } from "../create-lesson";
import {
  ContentModuleFormControls,
  ContentModuleLeftFormControls,
  ContentModuleRightFormControls,
} from "../../../styles/content-modules-form-controllers/content-modules-form-controllers-css";

export const CreateLessonControllers = () => {
  const { watch } = useFormContext<CreateLessonInputs>();
  const lessonTitle = watch("name");
  const contentModuleCards = watch("contentModuleCards");

  return (
    <ContentModuleFormControls $lessonsUI>
      <ContentModuleLeftFormControls $lessonsUI>
        <CreateLessonNameController />
        <CreateLessonCards />
      </ContentModuleLeftFormControls>
      <ContentModuleRightFormControls $lessonsUI>
        <CreateLessonThumbnailController />
        <CreateLessonTargetAudienceController />
        <ContentModuleLessonMobilePreview
          kind={{ mode: "Create", lessonTitle, contentModuleCards }}
        />
      </ContentModuleRightFormControls>
    </ContentModuleFormControls>
  );
};
