import { Temporal } from "temporal-polyfill";
import { PatientCGMEntryDTO } from "../../../../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { GAP_THRESHOLD_MINUTES } from "../../../../../../../../../../../constants/config";

type ReadingDataSource = {
  x: number;
  y?: number;
};

export function buildReadingsSereis(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  startPlainDateTime: Temporal.PlainDateTime
): ReadingDataSource[] {
  const readingsSeries: ReadingDataSource[] = [];

  let lastPlainDateTime: Temporal.PlainDateTime | undefined = undefined;

  for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
    if (patientCGMEntryDTO.source === "Libreview") {
      const currentPlainDateTime = Temporal.PlainDateTime.from(
        `${patientCGMEntryDTO.date}T${patientCGMEntryDTO.time}`
      );

      const x = currentPlainDateTime
        .since(startPlainDateTime)
        .total({ unit: "minutes" });
      const y = patientCGMEntryDTO.glucoseMGPerDL;

      if (
        lastPlainDateTime !== undefined &&
        Temporal.PlainDateTime.compare(
          lastPlainDateTime.add({ minutes: GAP_THRESHOLD_MINUTES }),
          currentPlainDateTime
        ) === -1
      ) {
        readingsSeries.push({ x });
      }

      readingsSeries.push({
        x: x,
        y: y,
      });

      lastPlainDateTime = currentPlainDateTime;
    }
  }

  return readingsSeries;
}
