import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import axios, { Axios } from "axios";
import { useAuth } from "./auth-context/auth-context";
import { BASE_URL } from "../constants/config";
import { assertNever } from "../utils/assert-never";

const ClientContext = createContext<Axios | undefined>(undefined);

function callOnce(fn: () => void) {
  let called = false;
  return () => {
    if (called) {
      return;
    }

    called = true;
    fn();
  };
}

export function ClientProvider({ children }: PropsWithChildren<{}>) {
  const auth = useAuth();

  const logOut = useMemo(
    () =>
      callOnce(() => {
        alert(
          "An unauthorized request. Probably, your session has expired. We're logging you out..."
        );
        auth.clearAndUnpersistUser();
      }),
    [auth]
  );

  const value = useMemo(() => {
    const client = axios.create({ baseURL: BASE_URL });

    client.interceptors.request.use((config) => {
      const user = auth.user;
      if (user === undefined) {
        return config;
      }

      if (config.headers === undefined) {
        config.headers = {};
      }

      if ("credential" in user) {
        const token = user.credential;
        config.headers["Authorization"] = `Bearer ${token}`;
      } else if ("patientToken" in user) {
        const token = user.patientToken;
        config.headers["Authorization"] = `Bearer ${token}`;
      } else {
        assertNever(user);
      }

      return config;
    });

    client.interceptors.response.use(undefined, (error) => {
      if (error.response.status === 401) {
        logOut();
      }

      return Promise.reject(error);
    });

    return client;
  }, [auth, logOut]);

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
}

export function useClient(): Axios {
  const client = useContext(ClientContext);
  if (client === undefined) {
    throw Error("useClient must be used within an ClientProvider");
  }

  return client;
}
