import { MealsAnalysisScreenHeader } from "./meals-analysis/meals-analysis-screen-header/meals-analysis-screen-header";
import { MealsAnalysisResponse } from "./meals-analysis/meals-analysis-response";

export const MealsAnalysis = () => {
  return (
    <div>
      <MealsAnalysisScreenHeader />
      <MealsAnalysisResponse />
    </div>
  );
};
