import styled from "styled-components/macro";
import {
  flexCenterCenter,
  mediumRegularFont,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const StepsInfoContainer = styled.div<{ arabicFlex?: boolean }>`
  ${flexCenterCenter}
  flex-direction: ${({ arabicFlex }) => (arabicFlex ? "row-reverse" : "row")};
  gap: 1rem 0rem;
`;

export const StepsInfoIconContainer = styled.div<{ arabicFlex?: boolean }>`
  ${flexCenterCenter}
  margin: ${({ arabicFlex }) =>
    arabicFlex ? "0px 0px 0px 12px" : "0px 12px 0px 0px"};
  padding: 0.5rem;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};

  img {
    width: 20px;
    height: 20px;
  }
`;

export const StepsInfoTextContainer = styled.div<{ arabicFlex?: boolean }>`
  text-align: ${({ arabicFlex }) => (arabicFlex ? "right" : "left")};

  p {
    color: ${({ theme }) => theme.colors.screenTitleColor};
    margin: 0;
    ${regularMediumFont}
  }

  span {
    margin-top: 4px;
    color: ${({ theme }) => theme.newColors.modalContainerAlt};
    ${mediumRegularFont}
  }
`;
