import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "./screens/login/login";
import { AdminOrPatientLogin } from "./admin-or-patient-login";
import { PatientLogin } from "./screens-patient/patient-login/patient-login";
import { FourZeroFourNotFound } from "./screens/404-not-found/404-not-found";
import { FEATURE_FLAG_404_NOT_FOUND } from "./constants/config";

export function UnauthenticatedApp() {
  return (
    <Routes>
      <Route path="/" element={<AdminOrPatientLogin />} />
      <Route path="team-login" element={<Login />} />
      <Route path="patient-login" element={<PatientLogin />} />
      <Route path="view-plans/*" element={<Navigate to="/patient-login" />} />
      {FEATURE_FLAG_404_NOT_FOUND && (
        <Route path="*" element={<FourZeroFourNotFound />} />
      )}
    </Routes>
  );
}
