import {
  ScreenSubNavbar,
  SubNavLinksType,
} from "../../../components/screen-sub-navbar/screen-sub-navbar";
import { SubScreenContainer } from "../../../styles/classes/reusable-classes";
import { useParams } from "react-router-dom";
import { PlansBody } from "./plans-body";
import { useCallback } from "react";
import { useGetPatientPlans } from "../../../hooks/patient-plan-hooks/use-get-patient-plans";
import { Loading } from "../../../components/loading";

export const Plans = () => {
  const { id } = useParams();
  const patientID = parseInt(id!);

  const getPatientPlans = useGetPatientPlans();

  const loadPatientPlans = useCallback(async () => {
    const patientPlans = await getPatientPlans(patientID);

    return {
      patientPlans,
    };
  }, [patientID, getPatientPlans]);

  return (
    <div>
      <ScreenSubNavbar links={buildPlansNavbarLinks(patientID)} />
      <SubScreenContainer>
        <Loading
          load={loadPatientPlans}
          successComponent={(patientPlans) => (
            <PlansBody patientPlans={patientPlans.patientPlans} />
          )}
        />
      </SubScreenContainer>
    </div>
  );
};

export function buildPlansNavbarLinks(id: number): SubNavLinksType {
  const navLinks: SubNavLinksType = [
    {
      name: `Weekly plans`,
      path: `/patients/${id}/plans/weekly-plans`,
    },
  ];

  return navLinks;
}
