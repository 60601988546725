import { Controller, useForm } from "react-hook-form";
import { ModalForm } from "../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../styles/classes/reusable-classes";
import {
  AddNoteModalBodyContainer,
  AddNoteTextArea,
  AddNoteTextAreaContainer,
} from "./styled-add-note-modal-body";
import { usePutPatientMedicationNote } from "../../../../../hooks/patient-medication-hooks/use-put-patient-medication-note";
import { PatientMedicationDTO } from "../../../../../models/patient-medication-dto";
import { useToastService } from "../../../../../context/toast-service-context";
import { useReloadMedications } from "../../context/loadable-medications-context";

type AddNoteModalBodyProps = {
  onClose: () => void;
  row: PatientMedicationDTO;
};

type Input = {
  note?: string;
};

export const AddNoteModalBody = (props: AddNoteModalBodyProps) => {
  const { onClose, row } = props;

  const putPatientMedicationNote = usePutPatientMedicationNote();
  const { showToast } = useToastService();

  const reloadMedications = useReloadMedications();

  const { control, handleSubmit } = useForm<Input>({
    defaultValues: { note: undefined },
  });

  const onSubmit = handleSubmit(async (input) => {
    const note = {
      note: input.note!,
    };

    try {
      await putPatientMedicationNote(row, note);
      showToast("Success", "Note updated successfully!");
      reloadMedications();
      onClose();
    } catch (error) {
      showToast("Error", "Failed to update note");
    }
  });

  return (
    <ModalForm width={375} height={306} onSubmit={onSubmit}>
      <ModalHeader title="Edit note" onModalClose={onClose} />
      <AddNoteModalBodyContainer>
        <AddNoteTextAreaContainer>
          <Controller
            name="note"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <AddNoteTextArea
                value={value === undefined ? "" : value}
                onChange={(e) =>
                  onChange(e.target.value === undefined ? "" : e.target.value)
                }
                placeholder="Write notes..."
              ></AddNoteTextArea>
            )}
          />
        </AddNoteTextAreaContainer>
      </AddNoteModalBodyContainer>
      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit">Confirm</Button>
      </ModalFooter>
    </ModalForm>
  );
};
