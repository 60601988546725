import { useMemo } from "react";
import { roundTo1DecimalPlace } from "../../../../../../utils/math-utils";
import { useNutritionAnalysisData } from "../../context/nutrition-analysis-data-context";
import {
  NutritionAnalysisResponseHeaderContainer,
  NutritionAnalysisResponseHeaderMacroItem,
  NutritionAnalysisResponseHeaderMacrosContainer,
} from "./styled-nutrition-analysis-response-header";

export const NutritionAnalysisResponseHeader = () => {
  const {
    groupedPatientMealsByDateTotalKcals,
    groupedPatientMealsByDateTotalMacros,
  } = useNutritionAnalysisData();

  const { dailyAverage } = useMemo(() => {
    const kcalsSum = groupedPatientMealsByDateTotalKcals.reduce((sum, kcal) => {
      return sum + kcal;
    }, 0);

    const dailyAverage = roundTo1DecimalPlace(
      kcalsSum / groupedPatientMealsByDateTotalKcals.length
    );

    return { dailyAverage };
  }, [groupedPatientMealsByDateTotalKcals]);

  const { totalCarbs } = useMemo(() => {
    const totalCarbs = groupedPatientMealsByDateTotalMacros.reduce(
      (sumCarbs, macro) => {
        return sumCarbs + macro.carbs;
      },
      0
    );

    return { totalCarbs };
  }, [groupedPatientMealsByDateTotalMacros]);

  const { totalFat } = useMemo(() => {
    const totalFat = groupedPatientMealsByDateTotalMacros.reduce(
      (sumFats, macro) => {
        return roundTo1DecimalPlace(sumFats + macro.fats);
      },
      0
    );

    return { totalFat };
  }, [groupedPatientMealsByDateTotalMacros]);

  const { totalProtein } = useMemo(() => {
    const totalProtein = groupedPatientMealsByDateTotalMacros.reduce(
      (sumProtein, macro) => {
        return roundTo1DecimalPlace(sumProtein + macro.protein);
      },
      0
    );

    return { totalProtein };
  }, [groupedPatientMealsByDateTotalMacros]);

  const {
    carbsPercentage,
    fatPercentage,
    proteinPercentage,
    carbsGM,
    fatGM,
    proteinGM,
    // carbsGmPercentage,
    // fatGmPercentage,
    // proteinGmPercentage,
  } = useMemo(() => {
    let carbsPercentage = 0,
      fatPercentage = 0,
      proteinPercentage = 0;

    const carbsEnergy = totalCarbs * 4;
    const fatEnergy = totalFat * 9;
    const proteinEnergy = totalProtein * 4;

    const totalEnergyFromMacros = carbsEnergy + fatEnergy + proteinEnergy;

    if (totalEnergyFromMacros !== 0) {
      carbsPercentage = roundTo1DecimalPlace(
        (carbsEnergy / totalEnergyFromMacros) * 100
      );
      fatPercentage = roundTo1DecimalPlace(
        (fatEnergy / totalEnergyFromMacros) * 100
      );
      proteinPercentage = roundTo1DecimalPlace(
        (proteinEnergy / totalEnergyFromMacros) * 100
      );
    }

    const carbsGM = roundTo1DecimalPlace(
      totalCarbs / groupedPatientMealsByDateTotalMacros.length
    );

    const fatGM = roundTo1DecimalPlace(
      totalFat / groupedPatientMealsByDateTotalMacros.length
    );

    const proteinGM = roundTo1DecimalPlace(
      totalProtein / groupedPatientMealsByDateTotalMacros.length
    );

    return {
      carbsPercentage,
      fatPercentage,
      proteinPercentage,
      carbsGM,
      fatGM,
      proteinGM,
      carbsGmPercentage: roundTo1DecimalPlace((carbsGM / 249) * 100),
      fatGmPercentage: roundTo1DecimalPlace((fatGM / 55) * 100),
      proteinGmPercentage: roundTo1DecimalPlace((proteinGM / 119) * 100),
    };
  }, [
    totalCarbs,
    totalFat,
    totalProtein,
    groupedPatientMealsByDateTotalMacros,
  ]);

  const {
    carbsGoalPercentage,
    fatGoalPercentage,
    proteinGoalPercentage,
  } = useMemo(() => {
    const goalCarbEnergy = 249 * 4;
    const goalFatEnergy = 55 * 9;
    const goalProteinEnergy = 119 * 4;
    const totalGoalMacros = goalCarbEnergy + goalFatEnergy + goalProteinEnergy;

    return {
      carbsGoalPercentage: roundTo1DecimalPlace(
        (goalCarbEnergy / totalGoalMacros) * 100
      ),
      fatGoalPercentage: roundTo1DecimalPlace(
        (goalFatEnergy / totalGoalMacros) * 100
      ),
      proteinGoalPercentage: roundTo1DecimalPlace(
        (goalProteinEnergy / totalGoalMacros) * 100
      ),
    };
  }, []);

  return (
    <NutritionAnalysisResponseHeaderContainer>
      <p>Daily Average: {dailyAverage}</p>
      <NutritionAnalysisResponseHeaderMacrosContainer>
        <NutritionAnalysisResponseHeaderMacroItem>
          <div />
          <p>Net Carbs ({roundTo1DecimalPlace(totalCarbs)} g)</p>
          <p>
            Avg:{" "}
            <span>
              {roundTo1DecimalPlace(carbsPercentage)} % - {carbsGM} g
            </span>
          </p>
          <p>
            Goal:{" "}
            <span>{roundTo1DecimalPlace(carbsGoalPercentage)} % - 249 g</span>
          </p>
        </NutritionAnalysisResponseHeaderMacroItem>
        <NutritionAnalysisResponseHeaderMacroItem>
          <div />
          <p>Fat ({roundTo1DecimalPlace(totalFat)} g)</p>
          <p>
            Avg:{" "}
            <span>
              {roundTo1DecimalPlace(fatPercentage)} % - {fatGM} g
            </span>
          </p>
          <p>
            Goal:{" "}
            <span>{roundTo1DecimalPlace(fatGoalPercentage)} % - 55 g</span>
          </p>
        </NutritionAnalysisResponseHeaderMacroItem>
        <NutritionAnalysisResponseHeaderMacroItem>
          <div />
          <p>Protein ({roundTo1DecimalPlace(totalProtein)} g)</p>
          <p>
            Avg:{" "}
            <span>
              {roundTo1DecimalPlace(proteinPercentage)} % - {proteinGM} g
            </span>
          </p>
          <p>
            Goal:{" "}
            <span>{roundTo1DecimalPlace(proteinGoalPercentage)} % - 119 g</span>
          </p>
        </NutritionAnalysisResponseHeaderMacroItem>
      </NutritionAnalysisResponseHeaderMacrosContainer>
      <p>Goal: 2000 kcal</p>
    </NutritionAnalysisResponseHeaderContainer>
  );
};
