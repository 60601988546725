import styled from "styled-components/macro";
import { flexCenterSpaceBetween } from "../../../../../styles/classes/gloabl-classes";

export const ImmediateAttentionPatientsSidebarPatientInfoContainer = styled.div`
  ${flexCenterSpaceBetween}
  border: 1px solid #dcdde4;
  padding: 12px;
  border-radius: 8px;
  background: #f4f5fc;
  margin-bottom: 16px;
`;

export const ImmediateAttentionPatientsSidebarPatientInfoLeftContainer = styled.div`
  display: flex;
  width: 100%;
  border-right: 2px solid #dcdde4;

  img {
    width: 48px;
    border-radius: 100%;
    border: 1px solid #dcdde4;
    margin-right: 18px;
    cursor: pointer;
  }

  p {
    margin: 0px;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const ImmediateAttentionPatientsSidebarPatientInfoRightContainer = styled.div`
  width: 100%;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px 0px;

  div {
    display: flex;
    align-items: center;

    p {
      margin: 0px 0px 0px 8px;
      font-size: 14px;
      font-weight: 500;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;
