import { useMemo } from "react";
import { Temporal } from "temporal-polyfill";
import { LabTestTypeDTO } from "../../../../../../models/lab-test-types/lab-test-type-dto";
import { PatientLabTestResultDTO } from "../../../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";

type GroupedLabTestResultsByType = Array<{
  labTestTypeDTO: LabTestTypeDTO;
  sortedLabTestResultsByDate: PatientLabTestResultDTO[];
}>;

export const useGetGroupedLabTestResultsByTypeAndDate = (
  labTestTypeId: number,
  startDate: string,
  endDate: string,
  patientLabTestResultsDTOs: PatientLabTestResultDTO[],
  labTestTypeDTOs: LabTestTypeDTO[]
) => {
  const filteredLabTestTypeDTOs = labTestTypeDTOs.filter(
    (labTestType) => labTestType.id === labTestTypeId
  );

  const filteredLabTestResults = useMemo(() => {
    return patientLabTestResultsDTOs
      .filter((labTest) =>
        labTestTypeDTOs.some(
          (labTestTypeDTO) => labTestTypeDTO.id === labTest.labTestTypeId
        )
      )
      .filter(
        (item) =>
          item.date.toString() >= startDate && item.date.toString() <= endDate
      );
  }, [patientLabTestResultsDTOs, labTestTypeDTOs, endDate, startDate]);

  const groupedLabTestResultsByType = useMemo(() => {
    const result: GroupedLabTestResultsByType = [];

    for (const labTestTypeDTO of filteredLabTestTypeDTOs) {
      const labTestResults = filteredLabTestResults.filter(
        (labTest) => labTest.labTestTypeId === labTestTypeDTO.id
      );

      if (labTestResults.length > 0) {
        const sortedLabTestResultsByDate = labTestResults.sort(
          (a: PatientLabTestResultDTO, b: PatientLabTestResultDTO) => {
            const aDate = Temporal.PlainDate.from(a.date);
            const bDate = Temporal.PlainDate.from(b.date);

            return Temporal.PlainDate.compare(aDate, bDate);
          }
        );

        result.push({ labTestTypeDTO, sortedLabTestResultsByDate });
      }
    }

    return result;
  }, [filteredLabTestTypeDTOs, filteredLabTestResults]);

  return groupedLabTestResultsByType;
};
