import { FoodGroupDTO } from "../../../../../models/food-groups/food-group-dto";

type FoodGroupDTOWithChildren = FoodGroupDTO & {
  children?: FoodGroupDTO[];
};

export const createFoodGroupsWithChildren = (
  foodGroups: FoodGroupDTO[]
): FoodGroupDTOWithChildren[] => {
  const foodGroupDTOWithChildren: FoodGroupDTOWithChildren[] = [];
  const children = (name: string): FoodGroupDTO[] =>
    foodGroups.filter(
      (item) =>
        item.parentFoodGroup !== undefined && item.parentFoodGroup.name === name
    );

  for (const foodGroup of foodGroups) {
    if (foodGroup.parentFoodGroup !== undefined) {
      const key = foodGroup.parentFoodGroup.name;

      if (!foodGroupDTOWithChildren.find((item) => item.name === key)) {
        foodGroupDTOWithChildren.push({
          ...foodGroup.parentFoodGroup,
          children: children(key),
        });
      }
    } else {
      foodGroupDTOWithChildren.push({
        ...foodGroup,
      });
    }
  }

  return foodGroupDTOWithChildren;
};
