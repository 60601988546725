import { useSinglePatientSidebarUI } from "../../context/single-patient-sidebar-ui-context";
import { PatientHeaderSidebarIcon } from "./styled-sidebar-icon";

export const SidebarIcon = () => {
  const { onSidebarShowOrHide } = useSinglePatientSidebarUI();

  return (
    <PatientHeaderSidebarIcon onClick={() => onSidebarShowOrHide()}>
      <span className="material-symbols-outlined">menu</span>
    </PatientHeaderSidebarIcon>
  );
};
