import styled, { css } from "styled-components/macro";
import {
  mediumFont,
  regularSemiBoldFont,
  smallRegularFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const PatientProfileNotesModalBodyContainer = styled.div`
  margin: 24px 0px;
  display: grid;
  gap: 16px 0px;
`;

export const PatientProfileNotesInputContainer = styled.div`
  display: grid;
  position: relative;
`;

export const PatientProfileNotesInputLabel = styled.label`
  ${smallRegularFont}
  color: ${({ theme }) => theme.colors.textColor};
  margin: 0px 0px 4px;
`;

const PatientProfileInputCSS = css`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  outline: 0px;
  padding: 8px 4px 8px 8px;
  ${regularSemiBoldFont}
  color: ${({ theme }) => theme.colors.titleColor};
  border-radius: 8px;
`;

export const PatientProfileNotesInput = styled.input`
  ${PatientProfileInputCSS}
`;

export const PatientProfileNotesTextarea = styled.textarea`
  ${PatientProfileInputCSS}
  ${mediumFont}
  height: 140px;
`;
