import styled from "styled-components/macro";
import { SidebarWidth } from "../../../../../styles/global-style";
import { Button } from "../../../../../styles/classes/reusable-classes";
import { flexCenter } from "../../../../../styles/classes/gloabl-classes";

export const NutritionReportSummaryFooterContainer = styled.footer`
  position: fixed;
  left: ${SidebarWidth};
  bottom: 0px;
  right: 0px;
  background: ${({ theme }) => theme.colors.containerColor};
  ${flexCenter}
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};

  ${Button} {
    margin-left: auto;
  }
`;
