import { useEffect, useState } from "react";
import { PatientMealDTO } from "../../../models/patient-meal-dtos/patient-meal-dto";
import { useGetPatientMeals } from "../../../hooks/patient-meal-hooks/use-get-patient-meals";
import { useParams } from "react-router-dom";

type UseGetPatientMealDTOs = {
  patientMealDTOs: PatientMealDTO[];
  isLoaded: boolean;
};

export function useGetPatientMealDTOs(
  startDate?: string,
  endDate?: string
): UseGetPatientMealDTOs {
  const { id } = useParams();
  const [patientMealDTOs, setPatientMealDTOs] = useState<PatientMealDTO[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const getPatientMeals = useGetPatientMeals();

  useEffect(() => {
    const fetch = async () => {
      getPatientMeals(Number(id!), undefined, startDate, endDate).then(
        (response) => {
          setPatientMealDTOs(response);
          setIsLoaded(true);
        }
      );
    };

    fetch();
  }, [id, getPatientMeals, setIsLoaded, startDate, endDate]);

  return { patientMealDTOs, isLoaded };
}
