import { ContentModuleDTO } from "../../../../../models/content-module-dtos/content-module-dto";
import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../styles/classes/reusable-classes";
import { ContentModuleStatusAndOrderControls } from "../../../styles/content-modules-form-controllers/content-modules-form-controllers-css";
import { ReadContentModuleOrderController } from "./read-content-module-order-controller";
import { ReadContentModuleStatusController } from "./read-content-module-status-controller";

type ReadContentModuleNameControllerProps = {
  contentModuleDTO: ContentModuleDTO;
};

export const ReadContentModuleNameController = (
  props: ReadContentModuleNameControllerProps
) => {
  const { contentModuleDTO } = props;

  const { contentModuleType } = contentModuleDTO;
  const { contentModuleCards } = contentModuleDTO;
  const contentModuleOrder = contentModuleDTO.uiOrder;
  const conentModuleName = contentModuleDTO.name;

  return (
    <UXInputContainer>
      <UXInputLabel $width="150px">{contentModuleType} Name</UXInputLabel>
      <UXInputWrapper
        className="content-module-name-input-wrapper"
        $readOnly
        $width="80%"
      >
        <UXInputInput
          type="text"
          readOnly
          defaultValue={conentModuleName}
          dir="auto"
        />
        <ContentModuleStatusAndOrderControls>
          <ReadContentModuleStatusController
            isDisabled={
              contentModuleType === "Lesson" && contentModuleCards.length === 0
            }
          />
          {contentModuleType !== "Course" && (
            <ReadContentModuleOrderController
              contentModuleOrder={contentModuleOrder}
            />
          )}
        </ContentModuleStatusAndOrderControls>
      </UXInputWrapper>
    </UXInputContainer>
  );
};
