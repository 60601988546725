import { IAxisLabelRenderEventArgs } from "@syncfusion/ej2-react-charts";

export function buildAxisLabelRender(vAxisTicks: number[]) {
  return (args: IAxisLabelRenderEventArgs) => {
    if (args.axis.orientation !== "Vertical") {
      return;
    }

    if (!vAxisTicks.includes(args.value)) {
      args.labelStyle.color = "transparent";
    }
  };
}
