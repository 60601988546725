import { useGetPatients } from "../../hooks/patient-hooks/use-get-patients";
import { PatientsTable } from "./patients-table/patients-table";
import { BulkImport } from "./bulk-import/bulk-import-libreview";
import { Dropdown } from "../../components/dropdown/dropdown";
import { ScreenHeader } from "../../components/screen-header/screen-header";
import { AddNewPatient } from "./add-new-patient/add-new-patient";
import { useLoadableData } from "../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../components/loadable-data-loading";
import {
  LoadablePatients,
  LoadablePatientsProvider,
} from "./context/loadable-patients-context";
import { BulkImportCronometer } from "./bulk-import/bulk-import-cronometer/bulk-import-cronometer";
import { NavLink } from "../../components/screen-navbar/screen-navbar";
import { useParams } from "react-router-dom";

export function Patients() {
  const getPatients = useGetPatients();

  const loadablePatients = useLoadableData(getPatients);
  const { page } = useParams();

  const buttons = (
    <>
      <Dropdown text="Import" key={10}>
        <BulkImport />
        <BulkImportCronometer />
      </Dropdown>
      <AddNewPatient />
    </>
  );

  return (
    <LoadablePatientsProvider loadablePatients={loadablePatients}>
      <div>
        <ScreenHeader
          flexCol={true}
          title="All Patients"
          buttons={buttons}
          navLinks={buildNavLinks(loadablePatients, page!)}
        />
        <LoadableDataLoading
          state={loadablePatients.state}
          successComponent={(patientDTOs) => (
            <PatientsTable patientDTOs={patientDTOs} />
          )}
        />
      </div>
    </LoadablePatientsProvider>
  );
}

function buildNavLinks(
  loadablePatients: LoadablePatients,
  page: string
): NavLink[] {
  const allPatientsLinkText =
    loadablePatients.state.data === undefined
      ? "All"
      : `All (${loadablePatients.state.data.length})`;

  const navLinks = [
    {
      name: allPatientsLinkText,
      path: `/${page}`,
    },
  ];

  return navLinks;
}
