import styled from "styled-components/macro";

export const ImmediateAttentionPatientsTableAgeFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImmediateAttentionPatientsTableAgeFilterText = styled.p`
  margin: 0px;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center;
  color: ${({ theme }) => theme.colors.textColor} !important;
  margin-bottom: 8px !important;
`;
