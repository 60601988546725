import { useState } from "react";
import { EmptyState } from "../../../../components/empty-state/empty-state";
import { SearchInput } from "../../../../styles/atoms/search-input";
import { CreatePrescription } from "./components/create-prescription/create-prescription";
import { SinglePrescription } from "./single-prescription/single-prescription";
import {
  PrescriptionsContainer,
  PrescriptionsHeader,
  PrescriptionsHeaderTitleContainer,
} from "./styled-prescriptions";
import { usePrescription } from "./context/lodable-prescription-context";

export const PrescriptionsLoading = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { patientDetailedPrescriptions } = usePrescription();

  return (
    <>
      {patientDetailedPrescriptions.length > 0 ? (
        <div>
          <PrescriptionsHeader>
            <PrescriptionsHeaderTitleContainer>
              <p>Otida prescriptions</p>
              <SearchInput
                valueProp={searchTerm}
                onChangeHandler={(e) => setSearchTerm(e.target.value)}
              />
            </PrescriptionsHeaderTitleContainer>
            <CreatePrescription />
          </PrescriptionsHeader>
          <PrescriptionsContainer>
            {patientDetailedPrescriptions
              .sort((a, b) =>
                b.date.localeCompare(a.date, "en", {
                  sensitivity: "base",
                })
              )
              .filter((item) => {
                if (
                  item.date.toString().includes(searchTerm) ||
                  item.patientMedications.find((item) =>
                    item.medication.name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                ) {
                  return item;
                } else if (searchTerm === "") {
                  return item;
                } else {
                  return 0;
                }
              })
              .map((prescription, index) => (
                <SinglePrescription
                  key={index}
                  prescription={prescription}
                  searchTerm={searchTerm}
                />
              ))}
          </PrescriptionsContainer>
        </div>
      ) : (
        <EmptyState
          title="Otida Prescriptions"
          text="No Prescriptions"
          hasSearch={true}
          hasHeader={true}
          button={<CreatePrescription />}
        />
      )}
    </>
  );
};
