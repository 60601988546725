import styled from "styled-components/macro";
import { DropdownContainer } from "../../../../../../../components/dropdown/styled-dropdown";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
} from "../../../../styles/patient-profile-styles/patient-profile-classes";

export const PatientProfileDietEditModalContainer = styled.div`
  ${DropdownContainer} {
    height: 40px;
    background: ${({ theme }) => theme.newColors.inputColorAlt};
    border-radius: 8px;
    width: 233px;
    padding: ${({ theme }) => theme.padding.px_05};
  }

  ${PatientProfileModalFormControl} {
    :nth-child(5),
    :nth-child(4) {
      ${PatientProfileModalInputContainer} {
        ${DropdownContainer} {
          width: 100%;
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
  }
`;

export const PatientProfileDietEditModalSugarTypeContainer = styled.div`
  display: grid;
  gap: 20px 0px;
  flex: 1;
`;
