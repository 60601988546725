import styled from "styled-components/macro";
import { transition } from "../../../../../../../styles/global-style";
import {
  flexCenter,
  flexCenterSpaceBetween,
} from "../../../../../../../styles/classes/gloabl-classes";

export const ImmediateAttentionPatientsFiltersSidebarContainer = styled.div<{
  $showSidebar: boolean;
}>`
  position: fixed;
  z-index: 700;
  inset: 0px;
  display: flex;
  width: ${({ $showSidebar }) => ($showSidebar ? "100%" : "0px")};
  opacity: ${({ $showSidebar }) => ($showSidebar ? "1" : "0")};
  overflow: ${({ $showSidebar }) => ($showSidebar ? "visible" : "hidden")};
  transform: ${({ $showSidebar }) =>
    $showSidebar ? "translateX(0px)" : "translateX(-100vw)"};
  transition: ${({ $showSidebar }) => ($showSidebar ? transition : "")};
`;

export const ImmediateAttentionPatientsFiltersSidebarBackdrop = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.newColors.backdrop};
`;

export const ImmediateAttentionPatientsFiltersSidebarBodyContainer = styled.div<{
  $showSidebar: boolean;
}>`
  width: 750px;
  height: 100%;
  padding: 16px;
  background: ${({ theme }) => theme.colors.containerColor};
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1440px) {
    width: 850px;
  }
`;

export const ImmediateAttentionPatientsFiltersSidebarFiltersGroup = styled.div`
  ${flexCenterSpaceBetween}
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 8px 0px;
`;

export const ImmediateAttentionPatientsFiltersSidebarFiltersSubGroup = styled.div`
  width: 50%;
  border-right: 1px solid ${({ theme }) => theme.colors.borderColor};
`;

export const ImmediateAttentionPatientsFiltersSidebarFilterTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 8px 0px;
  ${flexCenter}
`;

export const ImmediateAttentionPatientsFiltersSidebarFilterCheckboxList = styled.div<{
  $height: string;
}>`
  height: ${({ $height }) => $height};
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  div {
    ${flexCenter}
    gap: 0px 8px;

    label {
      font-size: 13px;
      font-weight: 400;
    }
  }
`;
