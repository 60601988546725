import styled from "styled-components/macro";
import { lightShadowAlt, transition } from "../../../../../styles/global-style";
import {
  flexCenter,
  mediumRegularFont,
  mediumSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";

export const TargetAudienceAutoSelectListList = styled.ul<{
  $showDropdown: boolean;
}>`
  max-height: ${({ $showDropdown }) => ($showDropdown ? "206px" : "0px")};
  height: fit-content;
  overflow-y: ${({ $showDropdown }) => ($showDropdown ? "auto" : "hidden")};
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.z300};
  background: ${({ theme }) => theme.colors.containerColor};
  ${lightShadowAlt}
  border-radius: 1rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  transition: ${({ $showDropdown }) => ($showDropdown ? transition : "")};
  margin: 0px;
`;

export const TargetAudienceAutoSelectListItem = styled.li`
  user-select: none;
  ${mediumRegularFont}
  cursor: pointer;
  width: 100%;
  padding: 10px 16px;
  transition: ${transition};

  :hover {
    background: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.buttonColor};
  }

  &.create-new-list-item {
    ${flexCenter}
    gap: 0px 8px;
    color: ${({ theme }) => theme.colors.primaryColor};
    ${mediumSemiBoldFont}

    span {
      color: ${({ theme }) => theme.colors.primaryColor};
    }

    :hover {
      color: ${({ theme }) => theme.colors.buttonColor};

      span {
        color: ${({ theme }) => theme.colors.buttonColor};
      }
    }
  }

  &.target-audience-search-list-item {
    :hover {
      background: ${({ theme }) => theme.colors.containerColor};
      color: ${({ theme }) => theme.colors.titleColor};
    }
  }
`;
