import { useImmediateAttentionPatientsFiltersSidebar } from "../../../context/immediate-attention-patients-filters-sidebar-context";
import { HomeFilterDropdownButton } from "./components/styled-home-filter-dropdown";
import { ImmediateAttentionPatientsTableAgeFilter } from "./immediate-attention-patients-table-age-filter/immediate-attention-patients-table-age-filter";
import { ImmediateAttentionPatientsTableChronicDiseasesFilter } from "./immediate-attention-patients-table-chronic-diseases-filter/immediate-attention-patients-table-chronic-diseases-filter";
import { ImmediateAttentionPatientsTableGenderFilter } from "./immediate-attention-patients-table-gender-filter/immediate-attention-patients-table-gender-filter";
import { ImmediateAttentionPatientsTablePriorityFilter } from "./immediate-attention-patients-table-priority-filter/immediate-attention-patients-table-priority-filter";
import { ImmediateAttentionPatientsTableFiltersContainer } from "./styled-immediate-attention-patients-table-filters";

export const ImmediateAttentionPatientsTableFilters = (props: {
  allPatients?: boolean;
}) => {
  const { allPatients } = props;

  const { openSidebar } = useImmediateAttentionPatientsFiltersSidebar();

  return (
    <ImmediateAttentionPatientsTableFiltersContainer>
      <ImmediateAttentionPatientsTableChronicDiseasesFilter />
      {!allPatients && <ImmediateAttentionPatientsTablePriorityFilter />}
      <ImmediateAttentionPatientsTableGenderFilter />
      <ImmediateAttentionPatientsTableAgeFilter />
      <HomeFilterDropdownButton $showDropdown onClick={openSidebar}>
        All Filters
        <span className="material-symbols-outlined">tune</span>
      </HomeFilterDropdownButton>
    </ImmediateAttentionPatientsTableFiltersContainer>
  );
};
