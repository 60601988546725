import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { ActivityDTO } from "../../models/activity-dtos/activity-dto";

export function useGetActivities(): () => Promise<ActivityDTO[]> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<ActivityDTO[]>(`${BASE_PATH}/activities`);

    return response.data;
  }, [client]);
}
