import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  flexCenterSpaceBetween,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const ImmediateAttentionPatientsSidebarHeaderContainer = styled.header`
  ${flexCenterSpaceBetween}
  margin-bottom: 16px;
`;

export const ImmediateAttentionPatientsSidebarHeaderText = styled.div`
  p {
    margin: 0px;
    ${regularMediumFont}
  }
`;

export const ImmediateAttentionPatientsSidebarHeaderIcons = styled.div`
  ${flexCenter}
  gap: 0px 12px;

  a {
    color: inherit;
    ${flexCenterCenter}
    text-decoration: none;
  }

  span {
    cursor: pointer;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
