import { Link, useLocation } from "react-router-dom";
import {
  SubNavbarList,
  SubNavbarContainer,
  SubNavbarListItem,
} from "./styled-screen-sub-navbar";

export type SubNavLinksType = {
  name: string;
  path: string;
}[];

type ScreenSubNavbarProps = {
  links: SubNavLinksType;
};

export const ScreenSubNavbar = (props: ScreenSubNavbarProps) => {
  const { links } = props;
  const location = useLocation();

  return (
    <SubNavbarContainer>
      <SubNavbarList>
        {links.map((link, index) => (
          <Link key={index} to={link.path}>
            <SubNavbarListItem
              key={index}
              activeLink={location.pathname.endsWith(link.path)}
            >
              {link.name}
            </SubNavbarListItem>
          </Link>
        ))}
      </SubNavbarList>
    </SubNavbarContainer>
  );
};
