import styled from "styled-components/macro";
import { NavbarHeight } from "../../styles/global-style";

export const ContentLayoutContainer = styled.div`
  height: calc(100vh - ${NavbarHeight});
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.containerColor};

  form {
    height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;
