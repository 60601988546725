import { PutContentModuleCardDTO } from "../../../../../../models/content-module-dtos/put-content-module-card-dto";
import {
  ContentModuleLessonMobilePreviewCardHeader,
  ContentModuleLessonMobilePreviewCardListItem,
} from "../../mobile-preview-lesson-cards-styles";
import { UpdateContentModuleLessonMobilePreviewCardParts } from "./update-content-module-lesson-mobile-preview-card-parts";

type UpdateContentModuleLessonMobilePreviewCardProps = {
  card: PutContentModuleCardDTO;
  activeCard: boolean;
};

export const UpdateContentModuleLessonMobilePreviewCard = (
  props: UpdateContentModuleLessonMobilePreviewCardProps
) => {
  const { card, activeCard } = props;

  return (
    <ContentModuleLessonMobilePreviewCardListItem $activeCard={activeCard}>
      <ContentModuleLessonMobilePreviewCardHeader>
        <h6 dir="auto">{card.title}</h6>
      </ContentModuleLessonMobilePreviewCardHeader>
      <UpdateContentModuleLessonMobilePreviewCardParts
        cardParts={card.contentModuleCardParts}
      />
    </ContentModuleLessonMobilePreviewCardListItem>
  );
};
