import styled from "styled-components/macro";

export const MessagingMediaSidebarCustomMessageContainer = styled.div`
  margin: 16px 0px;

  .str-chat__message-simple-status,
  .str-chat__avatar {
    display: none;
  }

  .str-chat__message-inner {
    margin: 0px;
  }

  .str-chat__message,
  .str-chat__message-simple {
    justify-content: center;
  }

  .str-chat__modal {
    height: 200%;
  }
`;
