import styled, { css } from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  lgSemiBoldFont,
  regularMediumFont,
} from "../../../styles/classes/gloabl-classes";

export const FoodLoggingHeaderContainer = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.containerColor};
  ${flexCenterSpaceBetween}
`;

const FoodLoggingHeaderTitleCSS = css`
  h3 {
    ${lgSemiBoldFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
  }

  span {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const FoodLoggingHeaderTitleContainer = styled.div`
  ${FoodLoggingHeaderTitleCSS}
`;

export const FoodLoggingHeaderNutritionReportTitleContainer = styled.div`
  ${flexCenter}
  gap: 0px 16px;

  ${FoodLoggingHeaderTitleCSS}
`;
