import styled from "styled-components/macro";
import {
  FoodLoggingSelectedPatientMealMacrosBar,
  FoodLoggingSelectedPatientMealMacrosContainer,
  FoodLoggingSelectedPatientMealMacrosLegend,
  FoodLoggingSelectedPatientMealMacrosLegendPoint,
  FoodLoggingSelectedPatientMealMacrosTitleContainer,
} from "../../../../food-logging/food-logging-selected-patient-meal/legacy-food-logging-selected-patient-meal/food-logging-selected-patient-meal-macros/styled-food-logging-selected-patient-meal-macros";
import { smallRegularFont } from "../../../../../styles/classes/gloabl-classes";

export const DietitianReportMacrosBarContainer = styled.div`
  ${FoodLoggingSelectedPatientMealMacrosContainer} {
    width: 480px;
  }

  ${FoodLoggingSelectedPatientMealMacrosBar} {
    margin: 4px auto;
    width: 480px;
  }

  ${FoodLoggingSelectedPatientMealMacrosTitleContainer} {
    p {
      ${smallRegularFont}
    }

    span {
      ${smallRegularFont}
    }
  }

  ${FoodLoggingSelectedPatientMealMacrosLegend} {
    gap: 0px;
    padding: 0px;
  }

  ${FoodLoggingSelectedPatientMealMacrosLegendPoint} {
    flex: 1;

    span {
      ${smallRegularFont}
    }
  }
`;
