import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { PATIENTS_PATH } from "../../constants/paths";
import { PatientNutritionReportDTO } from "../../models/patient-nutrition-report-dtos/patient-nutrition-report-dto";
import { CreatePatientNutritionReportDTO } from "../../models/patient-nutrition-report-dtos/create-patient-nutrition-report-dto";

export function useAddPatientNutritionReport(): (
  patientId: number,
  createPatientNutritionReportDTO: CreatePatientNutritionReportDTO
) => Promise<PatientNutritionReportDTO> {
  const client = useClient();

  return useCallback(
    async (patientId, createPatientNutritionReportDTO) => {
      const response = await client.post<PatientNutritionReportDTO>(
        `${PATIENTS_PATH}/${patientId}/nutrition-reports`,
        createPatientNutritionReportDTO
      );

      return response.data;
    },
    [client]
  );
}
