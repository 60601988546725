import { useState, useEffect, useRef } from "react";
import { useRowsActions } from "../../context/rows-actions-context";
import { ActionsListItem, ActionsList } from "./styled-actions-list-dropdown";

type ActionsListDropdownProps = {
  index: number;
  // onEdit: () => void;
};

export const ActionsListDropdown = (props: ActionsListDropdownProps) => {
  const {
    index,
    // onEdit
  } = props;

  const [showActionsList, setShowActionsList] = useState<{
    id: number | undefined;
    show: boolean;
  }>({ id: undefined, show: false });
  const showActionsListSpanRef = useRef<HTMLSpanElement | null>(null);
  const { startDeleting, startEditing } = useRowsActions();

  useEffect(() => {
    const closeActionsList = (e: MouseEvent) => {
      if (e.composedPath()[0] !== showActionsListSpanRef.current) {
        setShowActionsList({ id: undefined, show: false });
      }
    };

    document.body.addEventListener("click", closeActionsList);

    return () => document.body.removeEventListener("click", closeActionsList);
  }, [showActionsList]);

  return (
    <>
      <span
        className="material-symbols-outlined"
        ref={showActionsListSpanRef}
        onClick={() => {
          if (!showActionsList.show) {
            setShowActionsList({
              show: true,
              id: index,
            });
          }
        }}
      >
        more_horiz
      </span>
      <ActionsList
        showCrudList={
          showActionsList.id === index &&
          showActionsList.show &&
          showActionsList.show
        }
      >
        <ActionsListItem onClick={() => startEditing(index)}>
          Edit
        </ActionsListItem>
        <ActionsListItem onClick={() => startDeleting(index)}>
          Delete
        </ActionsListItem>
      </ActionsList>
    </>
  );
};
