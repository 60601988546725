import { Controller, useFormContext } from "react-hook-form";
import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../styles/classes/reusable-classes";
import { CreateLessonInputs } from "../../create-lesson";
import { ContentModuleStatusAndOrderControls } from "../../../../styles/content-modules-form-controllers/content-modules-form-controllers-css";
import { CreateLessonStatusController } from "./create-lesson-status-controller";
import { CreateLessonOrderController } from "./create-lesson-order-controller";

export const CreateLessonNameController = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateLessonInputs>();

  return (
    <Controller
      control={control}
      name="name"
      rules={{ required: true }}
      render={({ field: { value, onChange } }) => (
        <UXInputContainer>
          <UXInputLabel $width="150px">Lesson Name</UXInputLabel>
          <UXInputWrapper
            className="content-module-name-input-wrapper"
            $errorState={errors.name?.type === "required"}
            $width="80%"
          >
            <UXInputInput
              width={"100%"}
              dir="auto"
              type="text"
              placeholder="Enter Lesson Name"
              value={value === undefined ? "" : value}
              onChange={(e) =>
                onChange(e.target.value === "" ? undefined : e.target.value)
              }
            />
            <ContentModuleStatusAndOrderControls>
              <CreateLessonStatusController />
              <CreateLessonOrderController />
            </ContentModuleStatusAndOrderControls>
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
