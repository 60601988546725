import { useState } from "react";
import { SearchInput } from "../../../../../styles/atoms/search-input";
import { usePlansGoals } from "../../context/goals-context";
import { CreatePlansFooter } from "../../create-plans/create-plans-footer/create-plans-footer";
import { PlanStepsHeader } from "../../components/plan-steps-header/plan-steps-header";
import { PlansSubScreenContainer } from "../styles";
import { AddGoalModal } from "./add-goal-modal/add-goal-modal";
import { AssignedGoalsList } from "./assigned-goals-list";
import { EmptyGoals } from "./empty-goals";
import { SelectGoalsList } from "./select-goals-list";
import { GoalsScreenContainer } from "./styled-goals";

export const Goals = () => {
  const { assignedGoals, selectGoalsList } = usePlansGoals();
  const [searchTerm, setSearchTerm] = useState("");

  const assignedGoalsCondition =
    assignedGoals.length > 0 && selectGoalsList.length === 0;

  const selectGoalsCondition =
    assignedGoals.length === 0 && selectGoalsList.length > 0;

  const bothGoalsCondition =
    assignedGoals.length > 0 && selectGoalsList.length > 0;

  return (
    <PlansSubScreenContainer>
      <PlanStepsHeader
        title="Overall Goals"
        subTitle="Add or update patient goals"
        icon="/img/plans/nav/goals.svg"
        button={
          assignedGoals.length > 0 || selectGoalsList.length > 0 ? (
            <AddGoalModal />
          ) : (
            <></>
          )
        }
      />
      <GoalsScreenContainer>
        <SearchInput
          width="100%"
          placeHolder="Search for goals"
          valueProp={searchTerm}
          onChangeHandler={(e) => setSearchTerm(e.target.value)}
        />
        {assignedGoalsCondition ? (
          <AssignedGoalsList searchTerm={searchTerm} />
        ) : selectGoalsCondition ? (
          <SelectGoalsList searchTerm={searchTerm} />
        ) : bothGoalsCondition ? (
          <>
            <AssignedGoalsList searchTerm={searchTerm} />
            <SelectGoalsList searchTerm={searchTerm} />
          </>
        ) : (
          <EmptyGoals />
        )}
      </GoalsScreenContainer>
      <CreatePlansFooter nextStep="Feedback" prevStep="" />
    </PlansSubScreenContainer>
  );
};
