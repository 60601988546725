import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { PATIENTS_PATH } from "../../constants/paths";
import { BulkUpdatePatientBloodPressuresDTO } from "../../models/patient-blood-pressure-dtos/bulk-update-patient-blood-pressures-dto";
import { BulkUpdatePatientBloodPressuresResponseDTO } from "../../models/patient-blood-pressure-dtos/bulk-update-patient-blood-pressures-response-dto";

export function useBulkUpdatePatientBloodPressures(): (
  patientId: number,
  bulkUpdatePatientBloodPressuresDTO: BulkUpdatePatientBloodPressuresDTO
) => Promise<BulkUpdatePatientBloodPressuresResponseDTO> {
  const client = useClient();

  return useCallback(
    async (patientId, bulkUpdatePatientBloodPressuresDTO) => {
      const response =
        await client.post<BulkUpdatePatientBloodPressuresResponseDTO>(
          `${PATIENTS_PATH}/${patientId}/blood-pressures/bulk-update`,
          bulkUpdatePatientBloodPressuresDTO
        );

      return response.data;
    },
    [client]
  );
}
