import { PATIENTS_PATH } from "../constants/paths";
import { useClient } from "../context/client-context";
import { useCallback } from "react";
import { CreatePatientMedicationDTO } from "../models/create-patient-medication-dto";
import { PatientMedicationDTO } from "../models/patient-medication-dto";

export function useAddPatientMedication(): (
  patientId: number,
  createPatientMedicationDTO: CreatePatientMedicationDTO
) => Promise<PatientMedicationDTO> {
  const client = useClient();

  return useCallback(
    async (patientId, createPatientMedicationDTO) => {
      const response = await client.post<PatientMedicationDTO>(
        `${PATIENTS_PATH}/${patientId}/medications`,
        createPatientMedicationDTO
      );

      return response.data;
    },
    [client]
  );
}
