import { Temporal } from "temporal-polyfill";
import { PatientNutritionReportDTO } from "../../../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-dto";
import {
  NutritionReportSummaryHeaderDatesRangeContainer,
  NutritionReportSummaryHeaderDatesRangeText,
} from "../../../../../../../food-logging/nutrition-report/nutrition-report-summary/nutrition-report-summary-header/nutrition-report-summary-header-dates-range/styled-nutrition-report-summary-header-dates-range";
import { useMemo } from "react";
import { getDatesInRange } from "../../../../../../../food-logging/nutrition-report/nutrition-report-summary/util/getDatesInRange";
import {
  ViewNutritionReportSummaryHeaderContainer,
  ViewNutritionReportSummaryHeaderTitle,
} from "./styled-view-nutrition-report-summary-header";

type ViewNutritionReportSummaryHeaderProps = {
  patientNutritionReport: PatientNutritionReportDTO;
};

export const ViewNutritionReportSummaryHeader = (
  props: ViewNutritionReportSummaryHeaderProps
) => {
  const { patientNutritionReport } = props;

  const selectedDates = useMemo(
    () =>
      patientNutritionReport.patientNutritionReportDates.map((day) => day.date),
    [patientNutritionReport.patientNutritionReportDates]
  );

  const datesInRange = getDatesInRange(
    patientNutritionReport.startDate,
    patientNutritionReport.endDate
  );
  const inRangeDates =
    datesInRange.length <= 30 ? datesInRange : datesInRange.slice(0, 30);

  return (
    <ViewNutritionReportSummaryHeaderContainer>
      <ViewNutritionReportSummaryHeaderTitle>
        From:{" "}
        {Temporal.PlainDate.from(
          patientNutritionReport.startDate
        ).toLocaleString("en-UK", {
          day: "2-digit",
          month: "short",
        })}{" "}
        -
        {Temporal.PlainDate.from(patientNutritionReport.endDate).toLocaleString(
          "en-UK",
          {
            day: "2-digit",
            month: "short",
          }
        )}
      </ViewNutritionReportSummaryHeaderTitle>
      <NutritionReportSummaryHeaderDatesRangeContainer viewReportUI>
        {inRangeDates.map((dateInRange, index) => (
          <NutritionReportSummaryHeaderDatesRangeText
            key={index}
            selected={selectedDates.includes(
              dateInRange.toISOString().split("T")[0].toString()
            )}
          >
            {Temporal.PlainDate.from(
              dateInRange.toISOString().split("T")[0]
            ).toLocaleString("en-UK", {
              month: "short",
              day: "numeric",
            })}
          </NutritionReportSummaryHeaderDatesRangeText>
        ))}
      </NutritionReportSummaryHeaderDatesRangeContainer>
    </ViewNutritionReportSummaryHeaderContainer>
  );
};
