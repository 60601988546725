import { useCallback } from "react";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { PatientMealDTO } from "../../models/patient-meal-dtos/patient-meal-dto";

export function useGetPatientMeals(): (
  patientId: number,
  finishedFoodLogging?: boolean,
  startDate?: string,
  endDate?: string
) => Promise<PatientMealDTO[]> {
  const client = useClient();

  return useCallback(
    async (
      patientId,
      finishedFoodLogging = undefined,
      startDate = undefined,
      endDate = undefined
    ) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientMealDTO[]>(
        `${PATIENTS_PATH}/${patientId}/meals`,
        {
          params: {
            finishedFoodLogging,
            startDate,
            endDate,
          },
        }
      );

      return response.data;
    },
    [client]
  );
}
