import { buildPatientProfileHeaderBottomCards } from "../../../../patient-profile/utils/patient-profile-utils";
import { useSinglePatientSidebar } from "../../../context/loadable-single-patient-sidebar-context";
import {
  PersonalInfoGroup,
  PersonalInfoGroupTitle,
  PersonalInfoGroupTop,
} from "../styled-personal-info-tab";
import { BmiModal } from "./bmi-modal/bmi-modal";
import { Measurment, MeasurmentsContainer } from "./styled-body-measurments";
import { WeightModal } from "./weight-modal/weight-modal";
import { WeightProgressModal } from "./weight-progress-modal/weight-progress-modal";

export const BodyMeasurments = () => {
  const { patientDTO, patientBloodPressureDTOs, patientWeightDTOs } =
    useSinglePatientSidebar();

  const height = buildPatientProfileHeaderBottomCards(
    patientDTO,
    patientBloodPressureDTOs,
    patientWeightDTOs
  ).find((item) => item.name === "Height")?.value;

  const latestWeight =
    patientWeightDTOs.length > 0
      ? patientWeightDTOs.sort((a, b) =>
          a.date.localeCompare(b.date, "en", {
            sensitivity: "base",
          })
        )[patientWeightDTOs.length - 1].weightKg
      : 0;

  const bmi = buildPatientProfileHeaderBottomCards(
    patientDTO,
    patientBloodPressureDTOs,
    patientWeightDTOs
  ).find((item) => item.name === "BMI")?.value;

  const bodyMesurments = buildBodyMeasurments(
    bmi !== undefined ? bmi : "",
    Number(height),
    latestWeight
  ).map((measurment, index) => (
    <Measurment key={index}>
      <img src={measurment.img} alt="" />
      <div>
        <span>{measurment.title}</span>
        {measurment.title === "Weight" ? (
          <WeightProgressModal
            value={measurment.value}
            unit={measurment.unit}
          />
        ) : measurment.title === "BMI" ? (
          <BmiModal value={measurment.value} height={Number(height)} />
        ) : (
          <span>
            <strong>{measurment.value}</strong>
            {measurment.unit}
          </span>
        )}
      </div>
    </Measurment>
  ));

  return (
    <PersonalInfoGroup>
      <PersonalInfoGroupTop>
        <PersonalInfoGroupTitle>Body measurments</PersonalInfoGroupTitle>
        <WeightModal />
      </PersonalInfoGroupTop>
      <MeasurmentsContainer>{bodyMesurments}</MeasurmentsContainer>
    </PersonalInfoGroup>
  );
};

function buildBodyMeasurments(
  bmi: number | string,
  height: number,
  latestWeight: number
) {
  const bodyMeasurments = [
    {
      img: "/img/single-patient-sidebar/4.png",
      title: "BMI",
      value: height !== 0 ? bmi : "",
      unit: "",
    },
    {
      img: "/img/single-patient-sidebar/5.png",
      title: "Height",
      value: height,
      unit: "cm",
    },
    {
      img: "/img/single-patient-sidebar/6.png",
      title: "Weight",
      value: latestWeight,
      unit: "Kg",
    },
  ];

  return bodyMeasurments;
}
