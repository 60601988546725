import { ModalCloseIcon, ModalHeaderContainer } from "./styled-modal-header";

type ModalHeaderProps = {
  title: string;
  subTitle?: string;
  onModalClose: () => void;
};

export const ModalHeader = (props: ModalHeaderProps) => {
  const { title, onModalClose, subTitle } = props;
  return (
    <ModalHeaderContainer>
      <div>
        <h1>{title}</h1>
        <p>{subTitle}</p>
      </div>
      <ModalCloseIcon onClick={onModalClose}>
        <span className="material-symbols-outlined">close</span>
      </ModalCloseIcon>
    </ModalHeaderContainer>
  );
};
