import { PlanStepsHeader } from "../../components/plan-steps-header/plan-steps-header";
import { useDetailedPlan } from "../../context/loadable-detailed-plan-context";
import { ListFeedback } from "../feedback/list-feedback/list-feedback";
import { ReviewCard } from "./styled-review";

export const ReviewFeedbacks = () => {
  const { patientDetailedPlanDTO } = useDetailedPlan();

  const listFeedbacksData = patientDetailedPlanDTO.patientPlanFeedbacks;
  const feedbacks = listFeedbacksData.length > 0 && (
    <ReviewCard>
      <PlanStepsHeader
        title="Feedback about last week"
        subTitle="Give the patient a feedback results"
        icon="/img/plans/nav/feedback.svg"
        button={<></>}
      />
      {listFeedbacksData.map((item, index) => (
        <ListFeedback key={index} item={item} />
      ))}
    </ReviewCard>
  );

  return <>{feedbacks}</>;
};
