import { PatientNutritionReportDTO } from "../../../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-dto";
import {
  NutrtionReportSummaryNotesList,
  NutrtionReportSummaryNotesListContainer,
  NutrtionReportSummaryNotesListHeader,
  NutrtionReportSummaryNotesListItem,
} from "../../../../../../../food-logging/nutrition-report/nutrition-report-summary/nutrition-report-summary-screen/nutrtion-report-summary-notes/styled-nutrtion-report-summary-notes";

type ViewNutritionReportSummaryHealthyNotesProps = {
  patientNutritionReport: PatientNutritionReportDTO;
};

export const ViewNutritionReportSummaryHealthyNotes = (
  props: ViewNutritionReportSummaryHealthyNotesProps
) => {
  const { patientNutritionReport } = props;

  return (
    <NutrtionReportSummaryNotesListContainer>
      <NutrtionReportSummaryNotesListHeader habbits="Healthy">
        <img src="/img/plans/Celebration.svg" alt="Celebration" />
        <p>Healthy habits to keep</p>
      </NutrtionReportSummaryNotesListHeader>
      <NutrtionReportSummaryNotesList isFieldsEmpty={false} viewReportUI={true}>
        {patientNutritionReport.patientNutritionReportHealthyHabits.map(
          (note) => (
            <NutrtionReportSummaryNotesListItem key={note.id} viewReportUI>
              <p>{note.note}</p>
            </NutrtionReportSummaryNotesListItem>
          )
        )}
      </NutrtionReportSummaryNotesList>
    </NutrtionReportSummaryNotesListContainer>
  );
};
