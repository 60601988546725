import { PatientMealTag } from "../../../../../../../models/patient-meal-dtos/patient-meal-tag";
import { useMealsAnalysisResponseTags } from "../../context/meals-analysis-response-tags-context";
import {
  MealsAnalysisResponseHeaderTagsList,
  MealsAnalysisResponseHeaderTagsListItem,
} from "./styled-meals-analysis-response-header-tags";

type MealsAnalysisResponseHeaderTagsArray = {
  id: number;
  tag?: PatientMealTag;
};

const mealsAnalysisResponseHeaderTagsArray: MealsAnalysisResponseHeaderTagsArray[] = [
  {
    id: 0,
    tag: undefined,
  },
  {
    id: 1,
    tag: "Breakfast",
  },
  {
    id: 2,
    tag: "Lunch",
  },
  {
    id: 3,
    tag: "Dinner",
  },
  {
    id: 4,
    tag: "SnackOrOther",
  },
];

export const MealsAnalysisResponseHeaderTags = () => {
  const { insulinTag, handleInsulinTag } = useMealsAnalysisResponseTags();

  return (
    <MealsAnalysisResponseHeaderTagsList>
      {mealsAnalysisResponseHeaderTagsArray.map((insulinResponseTag) => (
        <MealsAnalysisResponseHeaderTagsListItem
          key={insulinResponseTag.id}
          $active={insulinTag === insulinResponseTag.tag}
          onClick={() => {
            let targetTag: PatientMealTag | undefined = undefined;

            if (insulinResponseTag.tag === undefined) {
              targetTag = undefined;
            } else if (insulinResponseTag.tag === "SnackOrOther") {
              targetTag = "SnackOrOther";
            } else {
              targetTag = insulinResponseTag.tag;
            }

            handleInsulinTag(targetTag);
          }}
        >
          {insulinResponseTag.tag === "SnackOrOther"
            ? "Snack"
            : insulinResponseTag.tag === undefined
            ? "All"
            : insulinResponseTag.tag}
        </MealsAnalysisResponseHeaderTagsListItem>
      ))}
    </MealsAnalysisResponseHeaderTagsList>
  );
};
