import {
  AxisModel,
  Category,
  ChartComponent,
  DataLabel,
  Inject,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { PatientWeightDTO } from "../../../../../../../../models/patient-weight-dtos/patient-weight-dto";

type WeightProgressChartProps = {
  dataSource: PatientWeightDTO[];
};

export const WeightProgressChart = (props: WeightProgressChartProps) => {
  const { dataSource } = props;

  const sortedDataSource = dataSource.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  const primaryxAxis: AxisModel = {
    valueType: "Category",
    majorGridLines: { width: 0 },
    edgeLabelPlacement: "Shift",
  };

  const primaryyAxis: AxisModel = {
    minimum: 50,
  };

  const marker = {
    visible: true,
    width: 10,
    height: 10,
    isFilled: true,
    fill: "#1097cf",
    border: { width: 0, color: "transparent" },
    dataLabel: { visible: true },
  };

  return (
    <ChartComponent
      primaryXAxis={primaryxAxis}
      primaryYAxis={primaryyAxis}
      title="Weight Progress"
      height="100%"
    >
      <Inject services={[Category, LineSeries, StripLine, DataLabel]} />
      <SeriesCollectionDirective>
        <SeriesDirective
          dataSource={sortedDataSource}
          xName="date"
          yName="weightKg"
          opacity={1}
          zOrder={110}
          type="Line"
          width={3}
          fill="#00086b"
          marker={marker}
        ></SeriesDirective>
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};
