import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { TargetAudienceDTO } from "../../models/target-audience-dtos/target-audience-dto";

export function useGetTargetAudiences(): () => Promise<TargetAudienceDTO[]> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<TargetAudienceDTO[]>(
      `${BASE_PATH}/target-audiences`
    );

    return response.data;
  }, [client]);
}
