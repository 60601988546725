import { usePatient } from "../../../../context/loadable-patient-context";
import {
  ViewPlansHeaderContainer,
  ViewPlansHeaderTextContainer,
} from "./styled-view-plans-header";

export const ViewPlansHeader = () => {
  const { patientDTO } = usePatient();

  return (
    <ViewPlansHeaderContainer>
      <ViewPlansHeaderTextContainer>
        <span>مرحباً بك 👋</span>
        <p>{patientDTO.name}</p>
      </ViewPlansHeaderTextContainer>
    </ViewPlansHeaderContainer>
  );
};
