import { useCallback } from "react";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { PatientDetailedPlanDTO } from "../../models/patient-plan-dtos/patient-detailed-plan-dto";

export function useGetPatientDetailedPlan(): (
  patientId: number,
  patientPlanId: number
) => Promise<PatientDetailedPlanDTO> {
  const client = useClient();

  return useCallback(
    async (patientId, patientPlanId) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientDetailedPlanDTO>(
        `${PATIENTS_PATH}/${patientId}/plans/${patientPlanId}/detailed`
      );

      return response.data;
    },
    [client]
  );
}
