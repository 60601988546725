import { roundTo1DecimalPlace } from "../../../../../../utils/math-utils";
import { PatientNutritionReportDailySummaryDayDTOWithId } from "../create-patient-nutrition-report-daily-summary-day-dto-with-ids";
import { getSelectedDaysCount } from "./get-selected-days-count";

export function calculateMacrosSummaryTotalsAndAverages(
  selectedDays: PatientNutritionReportDailySummaryDayDTOWithId[]
) {
  let kcalSum = 0;
  let kcalAvg = 0;

  let carbsGmSum = 0;
  let carbsGmAvg = 0;

  let fatGmSum = 0;
  let fatGmAvg = 0;

  let proteinGmSum = 0;
  let proteinGmAvg = 0;

  let carbPercentageSum = 0;
  let carbPercentageAvg = 0;

  let fatPercentageSum = 0;
  let fatPercentageAvg = 0;

  let proteinPercentageSum = 0;
  let proteinPercentageAvg = 0;

  for (const day of selectedDays) {
    kcalSum += day.totalKCals;
    kcalAvg = kcalSum / getSelectedDaysCount(selectedDays);

    carbsGmSum += day.totalCarbsGm;
    carbsGmAvg = carbsGmSum / getSelectedDaysCount(selectedDays);

    fatGmSum += day.totalFatGm;
    fatGmAvg = fatGmSum / getSelectedDaysCount(selectedDays);

    proteinGmSum += day.totalProteinGm;
    proteinGmAvg = proteinGmSum / getSelectedDaysCount(selectedDays);

    carbPercentageSum += day.carbsPercentage;
    carbPercentageAvg = carbPercentageSum / getSelectedDaysCount(selectedDays);

    fatPercentageSum += day.fatPercentage;
    fatPercentageAvg = fatPercentageSum / getSelectedDaysCount(selectedDays);

    proteinPercentageSum += day.proteinPercentage;
    proteinPercentageAvg =
      proteinPercentageSum / getSelectedDaysCount(selectedDays);
  }

  return {
    kcalSum,
    kcalAvg: roundTo1DecimalPlace(kcalAvg),
    carbsGmSum,
    carbsGmAvg: roundTo1DecimalPlace(carbsGmAvg),
    fatGmSum,
    fatGmAvg: roundTo1DecimalPlace(fatGmAvg),
    proteinGmSum,
    proteinGmAvg: roundTo1DecimalPlace(proteinGmAvg),
    carbPercentageAvg: roundTo1DecimalPlace(carbPercentageAvg),
    fatPercentageAvg: roundTo1DecimalPlace(fatPercentageAvg),
    proteinPercentageAvg: roundTo1DecimalPlace(proteinPercentageAvg),
  };
}
