import styled, { css } from "styled-components";
import {
  regularRegularFont,
  regularSemiBoldFont,
  smallRegularFont,
} from "../../../../styles/classes/gloabl-classes";

export const LabResultsLabel = css`
  ${regularSemiBoldFont}
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const LabResultsInputCSS = css`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  width: 300px;
  padding: 10px 14px;
  outline: 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.titleColor};
  ${regularRegularFont}

  :read-only {
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const LabResultsModalBodyUnitInputContainer = styled.div`
  input {
    ${LabResultsInputCSS}
  }
`;

export const LabResultsHeaderContainerCSS = css`
  background: ${({ theme }) => theme.newColors.containerColorAlt};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 16px 0px 16px 24px;

  p {
    margin: 0px;
    font-size: 18px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
    color: ${({ theme }) => theme.colors.titleColor};
  }

  span {
    ${smallRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
