import {
  CGMReportResponseGlucoseStatisticsBody,
  CGMReportResponseGlucoseStatisticsBodyItem,
  CGMReportResponseGlucoseStatisticsBodyItemTitle,
} from "../../../../../single-patient/bgl-analysis/cgm-report/cgm-report-response/cgm-report-response-cards/cgm-report-response-glucose-statistics/styled-cgm-report-response-glucose-statistics";
import {
  CGMReportResponseTimeRangesChartContainer,
  CGMReportResponseTimeRangesChartItem,
} from "../../../../../single-patient/bgl-analysis/cgm-report/cgm-report-response/cgm-report-response-cards/cgm-report-response-time-ranges/cgm-report-response-time-ranges-chart/styled-cgm-report-response-time-ranges-chart";
import {
  CGMReportResponseTimeRangesDetailsContainer,
  CGMReportResponseTimeRangesDetailsItem,
} from "../../../../../single-patient/bgl-analysis/cgm-report/cgm-report-response/cgm-report-response-cards/cgm-report-response-time-ranges/cgm-report-response-time-ranges-details/styled-cgm-report-response-time-ranges-details";
import {
  CGMReportResponseTimeRangesBody,
  CGMReportResponseTimeRangesContainer,
  CGMReportResponseTimeRangesHeader,
} from "../../../../../single-patient/bgl-analysis/cgm-report/cgm-report-response/cgm-report-response-cards/cgm-report-response-time-ranges/styled-cgm-report-response-time-ranges";
import { ImmediateAttentionPatientsSidebarGlucoseTabContainer } from "./styled-immediate-attention-patients-sidebar-glucose-tab";

export const ImmediateAttentionPatientsSidebarGlucoseTab = () => {
  return (
    <ImmediateAttentionPatientsSidebarGlucoseTabContainer>
      <p className="caption">Last 14 days</p>
      <CGMReportResponseGlucoseStatisticsBody>
        <CGMReportResponseGlucoseStatisticsBodyItem>
          <CGMReportResponseGlucoseStatisticsBodyItemTitle>
            Average Glucose
          </CGMReportResponseGlucoseStatisticsBodyItemTitle>
          <span>
            154.8 <span>mg/dl</span>
          </span>
        </CGMReportResponseGlucoseStatisticsBodyItem>
        <CGMReportResponseGlucoseStatisticsBodyItem>
          <CGMReportResponseGlucoseStatisticsBodyItemTitle className="shrink-cgm-report-title">
            GMI
            <i className="material-symbols-outlined">info</i>
            <span className="shrinked-cgm-report-span">
              Glucose Management Indicator (Approximate A1C level based in
              average CGM Glucose level.)
            </span>
          </CGMReportResponseGlucoseStatisticsBodyItemTitle>
          <span>7%</span>
        </CGMReportResponseGlucoseStatisticsBodyItem>
      </CGMReportResponseGlucoseStatisticsBody>
      <CGMReportResponseTimeRangesContainer>
        <CGMReportResponseTimeRangesHeader>
          <p>Time Ranges</p>
        </CGMReportResponseTimeRangesHeader>
        <CGMReportResponseTimeRangesBody>
          <CGMReportResponseTimeRangesChartContainer>
            <CGMReportResponseTimeRangesChartItem
              className="very-high"
              $height={`10.1%`}
            />
            <CGMReportResponseTimeRangesChartItem
              className="high"
              $height={`26.5%`}
            />
            <CGMReportResponseTimeRangesChartItem
              className="target"
              $height={`60.1%`}
            />
            <CGMReportResponseTimeRangesChartItem
              className="low"
              $height={`${3.2}%`}
            />
            <CGMReportResponseTimeRangesChartItem
              className="very-low"
              $height={`${0.1}%`}
            />
          </CGMReportResponseTimeRangesChartContainer>
          <CGMReportResponseTimeRangesDetailsContainer>
            <CGMReportResponseTimeRangesDetailsItem $height={`${10.1}%`}>
              <p>
                Very High <span>{`>`}250 mg/dl</span>
              </p>
              <strong>{10.1}%</strong>
            </CGMReportResponseTimeRangesDetailsItem>
            <CGMReportResponseTimeRangesDetailsItem $height={`${26.5}%`}>
              <p>
                High <span>181 - 250 mg/dl</span>
              </p>
              <strong>{26.5}%</strong>
            </CGMReportResponseTimeRangesDetailsItem>
            <CGMReportResponseTimeRangesDetailsItem $height={`${60.1}%`}>
              <p>
                Target <span>70 - 180 mg/dl</span>
              </p>
              <strong>{60.1}%</strong>
            </CGMReportResponseTimeRangesDetailsItem>
            <CGMReportResponseTimeRangesDetailsItem $height={`${3.2}%`}>
              <p>
                Low <span>54 - 69 mg/dl</span>
              </p>
              <strong>{3.2}%</strong>
            </CGMReportResponseTimeRangesDetailsItem>
            <CGMReportResponseTimeRangesDetailsItem $height={`${0.1}%`}>
              <p>
                Very Low <span>{`<`} 54</span>
              </p>
              <strong>{0.1}%</strong>
            </CGMReportResponseTimeRangesDetailsItem>
          </CGMReportResponseTimeRangesDetailsContainer>
        </CGMReportResponseTimeRangesBody>
      </CGMReportResponseTimeRangesContainer>
    </ImmediateAttentionPatientsSidebarGlucoseTabContainer>
  );
};
