import { Controller, useForm } from "react-hook-form";
import { ModalForm } from "../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ErrorText,
  ModalFooter,
} from "../../../../../../styles/classes/reusable-classes";
import {
  PatientProfileNotesInput,
  PatientProfileNotesInputContainer,
  PatientProfileNotesInputLabel,
  PatientProfileNotesModalBodyContainer,
  PatientProfileNotesTextarea,
} from "./styled-patient-profile-notes-modal-body";
import { useAddPatientNote } from "../../../../../../hooks/patient-notes-hooks/use-add-patient-note";
import { useToastService } from "../../../../../../context/toast-service-context";
import { CreatePatientNoteDTO } from "../../../../../../models/patient-note-dtos/create-patient-note-dto";
import { useUpdatePatientNote } from "../../../../../../hooks/patient-notes-hooks/use-update-patient-note";
import { PatientNoteDTO } from "../../../../../../models/patient-note-dtos/patient-note-dto";
import {
  usePatientProfileNotes,
  useReloadPatientProfileNotes,
} from "../context/loadable-patient-profile-notes";

type PatientProfileNotesModalBodyProps = {
  onClose: () => void;
  state: { kind: "Add" } | { kind: "Edit"; data: PatientNoteDTO };
};

type PatientProfileNotesModalInputs = {
  title?: string;
  body?: string;
};

export const PatientProfileNotesModalBody = (
  props: PatientProfileNotesModalBodyProps
) => {
  const { onClose, state } = props;

  const { patientDTO } = usePatientProfileNotes();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PatientProfileNotesModalInputs>({
    defaultValues:
      state.kind === "Add"
        ? {
            title: undefined,
            body: undefined,
          }
        : {
            title: state.data.title,
            body: state.data.body,
          },
  });

  const addPatientNote = useAddPatientNote();
  const updatePatientNote = useUpdatePatientNote();
  const { showToast } = useToastService();
  const reloadPatientProfileNotes = useReloadPatientProfileNotes();

  const onSubmit = handleSubmit(async ({ title, body }) => {
    const createPatientNoteDTO: CreatePatientNoteDTO = {
      title: title!,
      body: body!,
    };

    try {
      if (state.kind === "Add") {
        await addPatientNote(patientDTO.id, createPatientNoteDTO);
      } else {
        await updatePatientNote(state.data, createPatientNoteDTO);
      }
      showToast("Success", `Note ${state.kind}ed successfully!`);
      reloadPatientProfileNotes();
    } catch (error) {
      showToast("Error", `Failed to ${state.kind} Note..`);
    }
  });

  return (
    <ModalForm width={500} height={500} onSubmit={onSubmit}>
      <ModalHeader onModalClose={onClose} title={`${state.kind} Notes`} />
      <PatientProfileNotesModalBodyContainer>
        <PatientProfileNotesInputContainer>
          <PatientProfileNotesInputLabel>Title</PatientProfileNotesInputLabel>
          <Controller
            control={control}
            name="title"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <PatientProfileNotesInput
                type="text"
                placeholder="Note Title"
                value={value !== undefined ? value : undefined}
                onChange={(e) =>
                  onChange(
                    e.target.value !== undefined ? e.target.value : undefined
                  )
                }
              />
            )}
          />
          {errors.title?.type === "required" && (
            <ErrorText>Required!</ErrorText>
          )}
        </PatientProfileNotesInputContainer>
        <PatientProfileNotesInputContainer>
          <PatientProfileNotesInputLabel>Body</PatientProfileNotesInputLabel>
          <Controller
            control={control}
            name="body"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <PatientProfileNotesTextarea
                placeholder="Note Body...."
                value={value !== undefined ? value : undefined}
                onChange={(e) =>
                  onChange(
                    e.target.value !== undefined ? e.target.value : undefined
                  )
                }
                dir="auto"
              ></PatientProfileNotesTextarea>
            )}
          />
          {errors.body?.type === "required" && <ErrorText>Required!</ErrorText>}
        </PatientProfileNotesInputContainer>
      </PatientProfileNotesModalBodyContainer>
      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </ModalFooter>
    </ModalForm>
  );
};
