import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { useNutritionGoals } from "./loadable-nutrition-goals-context";

type NutritionGoalsDropdownText = {
  dropdownText: string;
  setDropdownText: (dropdownText: string) => void;
};

const NutritionGoalsDropdownTextContext = createContext<
  NutritionGoalsDropdownText | undefined
>(undefined);

export const NutritionGoalsDropdownTextProvider = (
  props: PropsWithChildren<{}>
) => {
  const { children } = props;

  const { activeNutritionRegimen } = useNutritionGoals();

  const [dropdownText, setDropdownText] = useState(
    activeNutritionRegimen !== undefined &&
      activeNutritionRegimen.nutritionRegimen !== undefined
      ? activeNutritionRegimen.nutritionRegimen.name
      : "Choose daily goal"
  );

  const value = useMemo(
    () => ({
      dropdownText,
      setDropdownText,
    }),
    [dropdownText]
  );

  return (
    <NutritionGoalsDropdownTextContext.Provider value={value}>
      {children}
    </NutritionGoalsDropdownTextContext.Provider>
  );
};

export const useNutritionGoalsDropdownText = (): NutritionGoalsDropdownText => {
  const nutritionGoalsDropdownText = useContext(
    NutritionGoalsDropdownTextContext
  );

  if (nutritionGoalsDropdownText === undefined) {
    throw new Error(
      `useNutritionGoalsDropdownText must be within NutritionGoalsDropdownTextProvider`
    );
  }

  return nutritionGoalsDropdownText;
};
