import { LabResultsHeader } from "./lab-results-header";
import { Outlet, useParams } from "react-router-dom";
import { useCallback } from "react";
import { LabTestCategoryDTO } from "../../../models/lab-test-categories/lab-test-category-dto";
import { LoadableLabResultsProvider } from "./context/loadable-lab-results-context";
import { useGetPatient } from "../../../hooks/patient-hooks/use-get-patient";
import { useGetPatientLabTestResults } from "../../../hooks/patient-lab-test-result-hooks/use-get-patient-lab-test-results";
import { useGetLabTestCategories } from "../../../hooks/lab-test-categories/use-get-lab-test-categories";
import { useGetLabTestTypes } from "../../../hooks/lab-test-types/use-get-lab-test-types";
import { useGetLabTestTypeRefs } from "../../../hooks/lab-test-type-refs/use-get-lab-test-type-refs";
import { useLoadableData } from "../../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../../components/loadable-data-loading";
import { SubScreenContainer } from "../../../styles/classes/reusable-classes";

export const LabResults = () => {
  const { id } = useParams();

  const getPatient = useGetPatient();
  const getLabTestsResults = useGetPatientLabTestResults();
  const getLabTestCategories = useGetLabTestCategories();
  const getLabTestTypes = useGetLabTestTypes();
  const getLabTestTypeRefs = useGetLabTestTypeRefs();

  const loadableLabResultsCallback = useCallback(async () => {
    const patientId = parseInt(id!);

    const [
      patientDTO,
      patientLabTestResultsDTOs,
      labTestCategoryDTOs,
      labTestTypeDTOs,
      labTestTypeRefDTOs,
    ] = await Promise.all([
      getPatient(patientId),
      getLabTestsResults(patientId),
      // Endpoint will return 404 if patient has no diagnosis, in which case we'll set the diagnosis to undefined.
      // We definitely ought to check on status & problem type to make sure this is indeed the error, though.
      getLabTestCategories(),
      getLabTestTypes(),
      getLabTestTypeRefs(),
    ]);

    return {
      patientDTO,
      patientLabTestResultsDTOs,
      labTestCategoryDTOs,
      labTestTypeDTOs,
      labTestTypeRefDTOs,
    };
  }, [
    id,
    getPatient,
    getLabTestsResults,
    getLabTestCategories,
    getLabTestTypes,
    getLabTestTypeRefs,
  ]);

  const loadableLabResults = useLoadableData(loadableLabResultsCallback);

  return (
    <>
      <LabResultsHeader />
      <SubScreenContainer>
        <LoadableLabResultsProvider loadableLabResults={loadableLabResults}>
          <LoadableDataLoading
            state={loadableLabResults.state}
            successComponent={() => <Outlet />}
          />
        </LoadableLabResultsProvider>
      </SubScreenContainer>
    </>
  );
};

export function buildLabResultsNavbarLinks(
  id: string,
  labTestCategoryDTOs: LabTestCategoryDTO[]
) {
  return labTestCategoryDTOs
    .sort((a, b) => a.uiOrder - b.uiOrder)
    .map((labTestCategoryDTO) => ({
      name: labTestCategoryDTO.name,
      path: `/patients/${id}/lab-results/${labTestCategoryDTO.urlSegment}`,
    }));
}
