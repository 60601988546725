import styled from "styled-components/macro";
import { flexCol } from "../../../../../../../styles/classes/gloabl-classes";

export const DailyFixedSummaryChartCardContainer = styled.div`
  border-radius: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 30%;
`;

export const DailyFixedSummaryChartCardChildContainer = styled.div`
  ${flexCol}
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;

  img {
    width: 25px;
    height: 25px;
    margin-bottom: 8px;
  }

  p {
    margin: 0px;
    font-size: 21px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.titleColor};
    display: grid;
    text-align: center;

    span {
      font-size: 16px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;
