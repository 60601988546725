import { GoogleLogin } from "@react-oauth/google";
import { FormEvent } from "react";
import { useAuth } from "../../context/auth-context/auth-context";
import {
  FormBody,
  FormHeader,
  LoginContainer,
  LoginForm,
  LoginFormContainer,
  LoginFormLogoContainer,
} from "./styled-login";
import { LoginWithEmailAndPassword } from "./login-with-email-and-password/login-with-email-and-password";
import { FEATURE_FLAG_LOGIN_WITH_EMAIL_AND_PASSWORD } from "../../constants/config";

export function Login() {
  const { setAndPersistUser, clearAndUnpersistUser } = useAuth();

  return (
    <LoginContainer>
      <LoginFormContainer>
        <LoginFormLogoContainer>
          <img src="/img/logo.svg" alt="" />
        </LoginFormLogoContainer>
        <LoginForm
          onSubmit={(e: FormEvent<HTMLFormElement>) => e.preventDefault()}
        >
          <FormHeader>
            <h1>Welcome Back!</h1>
            <p>Sign in to start manage your patient's progress</p>
          </FormHeader>
          <FormBody>
            <GoogleLogin
              locale="en"
              onSuccess={(credentialResponse) =>
                // For `!`, see: https://github.com/MomenSherif/react-oauth/issues/104
                setAndPersistUser({
                  credential: credentialResponse.credential!,
                })
              }
              onError={() => clearAndUnpersistUser()}
              shape="pill"
              size="medium"
              width="200px"
              theme="filled_blue"
            />
            {FEATURE_FLAG_LOGIN_WITH_EMAIL_AND_PASSWORD && (
              <LoginWithEmailAndPassword />
            )}
          </FormBody>
        </LoginForm>
      </LoginFormContainer>
    </LoginContainer>
  );
}
