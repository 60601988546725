import { PateintProfileColumnHeader } from "../../components/pateint-profile-column-header/pateint-profile-column-header";
import {
  PatientProfileContainer,
  PatientProfileContainerBody,
} from "../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileMedicalHistoryContainer } from "./styled-profile-medical-history";
import { PatientProfileMedicalHistoryEditModal } from "./patient-profile-medical-history-edit-modal/profile-medical-history-edit-modal";
import { PatientProfileMedicalHistoryReadChronicDisease } from "./patient-profile-medical-history-read/patient-profile-medical-history-read-chronic-disease";
import { PatientProfileMedicalHistoryReadDiabetesEmergencies } from "./patient-profile-medical-history-read/patient-profile-medical-history-read-diabetes-emergencies";
import { PatientProfileMedicalHistoryReadSurgery } from "./patient-profile-medical-history-read/patient-profile-medical-history-read-surgery";
import { PatientProfileMedicalHistoryReadFamilyDisease } from "./patient-profile-medical-history-read/patient-profile-medical-history-read-family-disease";
import { PatientProfileMedicalHistoryReadDiabetesComplications } from "./patient-profile-medical-history-read/patient-profile-medical-history-read-diabetes-complications";
import { LoadablePatientProfileMedicalHistoryProvider } from "../context/loadable-patient-profile-medical-history";
import { LoadableDataLoading } from "../../../../../components/loadable-data-loading";
import { useParams } from "react-router-dom";
import { useGetPatientMedicalHistory } from "../../../../../hooks/patient-medical-history-hooks/use-get-patient-medical-history";
import { useCallback } from "react";
import { useLoadableData } from "../../../../../hooks/use-loadable-data";
import { useGetDiabetesComplications } from "../../../../../hooks/diabetes-complication-hooks/use-get-diabetes-complications";
import { useGetPatient } from "../../../../../hooks/patient-hooks/use-get-patient";

export const PatientProfileMedicalHistory = () => {
  const { id } = useParams();
  const patientID = parseInt(id!);

  const getPatient = useGetPatient();
  const getPatientMedicalHistory = useGetPatientMedicalHistory();
  const getDiabetesComplications = useGetDiabetesComplications();

  const loadPatientProfileMedicalHistoryCallback = useCallback(async () => {
    const patientDTO = await getPatient(patientID);
    const patientMedicalHistoryDTO = await getPatientMedicalHistory(patientID);
    const diabetesComplications = await getDiabetesComplications();

    return { patientDTO, patientMedicalHistoryDTO, diabetesComplications };
  }, [
    patientID,
    getPatient,
    getPatientMedicalHistory,
    getDiabetesComplications,
  ]);

  const loadablePatientProfileMedicalHistory = useLoadableData(
    loadPatientProfileMedicalHistoryCallback
  );
  return (
    <PatientProfileContainer>
      <LoadablePatientProfileMedicalHistoryProvider
        loadablePatientProfileMedicalHistory={
          loadablePatientProfileMedicalHistory
        }
      >
        <LoadableDataLoading
          state={loadablePatientProfileMedicalHistory.state}
          successComponent={() => (
            <>
              <PateintProfileColumnHeader
                img="/img/patient-profile/medical-history/medical-history.svg"
                title="Medical history"
                button={<PatientProfileMedicalHistoryEditModal />}
              />
              <PatientProfileContainerBody>
                <PatientProfileMedicalHistoryContainer>
                  <PatientProfileMedicalHistoryReadChronicDisease />
                  <PatientProfileMedicalHistoryReadDiabetesEmergencies />
                  <PatientProfileMedicalHistoryReadSurgery />
                  <PatientProfileMedicalHistoryReadFamilyDisease />
                  <PatientProfileMedicalHistoryReadDiabetesComplications />
                </PatientProfileMedicalHistoryContainer>
              </PatientProfileContainerBody>
            </>
          )}
        />
      </LoadablePatientProfileMedicalHistoryProvider>
    </PatientProfileContainer>
  );
};
