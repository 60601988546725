import styled from "styled-components/macro";

export const PatientHeaderSidebarIcon = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textColor};
  background: 0;
  padding: 0;
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
  }
`;
