import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { ContentModuleCardDTO } from "../../../../../../models/content-module-dtos/content-module-card-dto";
import { LessonCardsContainer } from "../../../../styles/content-module-lesson-classes";
import { LessonReadModeCard } from "./lesson-read-mode-card/lesson-read-mode-card";

type LessonReadModeCardsProps = {
  contentModuleCards: ContentModuleCardDTO[];
};

export const LessonReadModeCards = (props: LessonReadModeCardsProps) => {
  const { contentModuleCards } = props;

  if (contentModuleCards.length === 0) {
    return (
      <LessonCardsContainer>
        <EmptyState
          button={<></>}
          hasHeader={false}
          hasSearch={false}
          text="No Cards Added Yet"
          title=""
        />
      </LessonCardsContainer>
    );
  }

  return (
    <LessonCardsContainer>
      {contentModuleCards.map((card) => (
        <LessonReadModeCard key={card.id} card={card} />
      ))}
    </LessonCardsContainer>
  );
};
