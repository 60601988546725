import { roundTo1DecimalPlace } from "../../../../../../utils/math-utils";
import { PatientNutritionReportDailySummaryDayDTOWithId } from "../create-patient-nutrition-report-daily-summary-day-dto-with-ids";
import { getSelectedDaysCount } from "./get-selected-days-count";

export function calculateDaysFoodGroupsTotalsAndAverages(
  selectedDays: PatientNutritionReportDailySummaryDayDTOWithId[]
) {
  const daysFoodGroups = selectedDays.map((day) => day.foodGroups);

  let starchesSum = 0;
  let starchesAvg = 0;

  let legumesSum = 0;
  let legumesAvg = 0;

  let fruitsSum = 0;
  let fruitsAvg = 0;

  let vegetablesSum = 0;
  let vegetablesAvg = 0;

  const meatsSum = {
    highFat: 0,
    mediumFat: 0,
    lowFat: 0,
    veryLean: 0,
  };
  const meatsAvg = {
    highFat: 0,
    mediumFat: 0,
    lowFat: 0,
    veryLean: 0,
  };

  const dairySum = {
    highFat: 0,
    mediumFat: 0,
    lowFat: 0,
  };
  const dairyAvg = {
    highFat: 0,
    mediumFat: 0,
    lowFat: 0,
  };

  const fatsSum = {
    trans: 0,
    saturated: 0,
    unsaturated: 0,
    nuts: 0,
    legacy: 0,
  };
  const fatsAvg = {
    trans: 0,
    saturated: 0,
    unsaturated: 0,
    nuts: 0,
    legacy: 0,
  };

  let caffBevSum = 0;
  let caffBevAvg = 0;

  for (const dayFoodGroup of daysFoodGroups) {
    for (const foodGroup of dayFoodGroup) {
      if (foodGroup.foodGroup.id === 1) {
        // Starch
        starchesSum += foodGroup.totalServingsCount;
        starchesAvg = starchesSum / getSelectedDaysCount(selectedDays);
      }

      if (foodGroup.foodGroup.id === 2) {
        // Legumes
        legumesSum += foodGroup.totalServingsCount;
        legumesAvg = legumesSum / getSelectedDaysCount(selectedDays);
      }

      if (foodGroup.foodGroup.id === 3) {
        // Fruits
        fruitsSum += foodGroup.totalServingsCount;
        fruitsAvg = fruitsSum / getSelectedDaysCount(selectedDays);
      }

      if (foodGroup.foodGroup.id === 4) {
        // Vegetables
        vegetablesSum += foodGroup.totalServingsCount;
        vegetablesAvg = vegetablesSum / getSelectedDaysCount(selectedDays);
      }

      if (foodGroup.foodGroup.id === 8) {
        // Meats (High Fat)
        meatsSum.highFat += foodGroup.totalServingsCount;
        meatsAvg.highFat = roundTo1DecimalPlace(
          meatsSum.highFat / getSelectedDaysCount(selectedDays)
        );
      }

      if (foodGroup.foodGroup.id === 9) {
        // Meats (Medium Fat)
        meatsSum.mediumFat += foodGroup.totalServingsCount;
        meatsAvg.mediumFat = roundTo1DecimalPlace(
          meatsSum.mediumFat / getSelectedDaysCount(selectedDays)
        );
      }

      if (foodGroup.foodGroup.id === 10) {
        // Meats (Low Fat)
        meatsSum.lowFat += foodGroup.totalServingsCount;
        meatsAvg.lowFat = roundTo1DecimalPlace(
          meatsSum.lowFat / getSelectedDaysCount(selectedDays)
        );
      }

      if (foodGroup.foodGroup.id === 11) {
        // Meats (Very Lean)
        meatsSum.veryLean += foodGroup.totalServingsCount;
        meatsAvg.veryLean = roundTo1DecimalPlace(
          meatsSum.veryLean / getSelectedDaysCount(selectedDays)
        );
      }

      if (foodGroup.foodGroup.id === 5) {
        // Dairy (High Fat)
        dairySum.highFat += foodGroup.totalServingsCount;
        dairyAvg.highFat = roundTo1DecimalPlace(
          dairySum.highFat / getSelectedDaysCount(selectedDays)
        );
      }

      if (foodGroup.foodGroup.id === 6) {
        // Dairy (Medium Fat)
        dairySum.mediumFat += foodGroup.totalServingsCount;
        dairyAvg.mediumFat = roundTo1DecimalPlace(
          dairySum.mediumFat / getSelectedDaysCount(selectedDays)
        );
      }

      if (foodGroup.foodGroup.id === 7) {
        // Dairy (Low Fat)
        dairySum.lowFat += foodGroup.totalServingsCount;
        dairyAvg.lowFat = roundTo1DecimalPlace(
          dairySum.lowFat / getSelectedDaysCount(selectedDays)
        );
      }

      if (foodGroup.foodGroup.id === 16) {
        // Oils and Fats (Trans)
        fatsSum.trans += foodGroup.totalServingsCount;
        fatsAvg.trans = roundTo1DecimalPlace(
          fatsSum.trans / getSelectedDaysCount(selectedDays)
        );
      }

      if (foodGroup.foodGroup.id === 17) {
        // Oils and Fats (Saturated)
        fatsSum.saturated += foodGroup.totalServingsCount;
        fatsAvg.saturated = roundTo1DecimalPlace(
          fatsSum.saturated / getSelectedDaysCount(selectedDays)
        );
      }

      if (foodGroup.foodGroup.id === 18) {
        // Oils and Fats (Unsaturated)
        fatsSum.unsaturated += foodGroup.totalServingsCount;
        fatsAvg.unsaturated = roundTo1DecimalPlace(
          fatsSum.unsaturated / getSelectedDaysCount(selectedDays)
        );
      }

      if (foodGroup.foodGroup.id === 19) {
        // Oils and Fats (Nuts)
        fatsSum.nuts += foodGroup.totalServingsCount;
        fatsAvg.nuts = roundTo1DecimalPlace(
          fatsSum.nuts / getSelectedDaysCount(selectedDays)
        );
      }

      if (foodGroup.foodGroup.id === 12) {
        // Oils and Fats (Legacy)
        fatsSum.legacy += foodGroup.totalServingsCount;
        fatsAvg.legacy = roundTo1DecimalPlace(
          fatsSum.legacy / getSelectedDaysCount(selectedDays)
        );
      }

      if (foodGroup.foodGroup.id === 14) {
        // Caff. Bev.
        caffBevSum += foodGroup.totalServingsCount;
        caffBevAvg = caffBevSum / getSelectedDaysCount(selectedDays);
      }
    }
  }

  return {
    starchesSum,
    starchesAvg: roundTo1DecimalPlace(starchesAvg),
    legumesSum,
    legumesAvg: roundTo1DecimalPlace(legumesAvg),
    fruitsSum,
    fruitsAvg: roundTo1DecimalPlace(fruitsAvg),
    vegetablesSum,
    vegetablesAvg: roundTo1DecimalPlace(vegetablesAvg),
    meatsSum,
    meatsAvg,
    dairySum,
    dairyAvg,
    fatsSum,
    fatsAvg,
    caffBevSum,
    caffBevAvg: roundTo1DecimalPlace(caffBevAvg),
  };
}
