export const FAMILY_MEMBER_DISEASES = [
  "DiabetesType1",
  "DiabetesType2",
  "Obesity",
  "HeartDiseases",
  "Hypertension",
  "Other",
] as const;

export type FamilyMemberDisease = typeof FAMILY_MEMBER_DISEASES[number];
