import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";

type NutritionReportStarchesTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportStarchesTableCell = (
  props: NutritionReportStarchesTableCellProps
) => {
  const { kind } = props;

  const { starchesMeal, starchesRow } = useMemo(() => {
    const starchesRow =
      kind.kind === "Row"
        ? kind.data.foodGroups.find((item) => item.foodGroup.id === 1)
        : undefined;

    const starchesMeal =
      kind.kind === "Meal"
        ? kind.data.foodGroups.find((item) => item.foodGroup.id === 1)
        : undefined;

    return { starchesMeal, starchesRow };
  }, [kind.data, kind.kind]);

  return (
    <NutritionReportTableCell
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      value={
        kind.kind === "Row"
          ? starchesRow?.totalServingsCount
          : starchesMeal?.servingsCount
      }
    />
  );
};
