import styled from "styled-components/macro";
import {
  mediumFont,
  regularRegularFont,
} from "../../../../../styles/classes/gloabl-classes";

export const SinglePrescriptionContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: ${({ theme }) => theme.padding.pad1All};
  border-radius: 12px;
`;

export const SinglePrescriptionHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 1rem;
  gap: 1rem;

  @media screen and (max-width: 360px) {
    flex-direction: column;
  }
`;

export const SinglePrescriptionHeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  span {
    color: ${({ theme }) => theme.colors.titleColor};
  }

  p {
    ${regularRegularFont}
    color: ${({ theme }) => theme.colors.titleColor};
    display: grid;
    margin: 0;

    span {
      margin-top: 4px;
      ${mediumFont}
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;

export const SinglePrescriptionMedications = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
  gap: 1rem;
  padding: ${({ theme }) => theme.padding.py_05};

  @media screen and (1129px > width > 1025px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }

  @media screen and (max-width: 736px) {
    grid-template-columns: repeat(auto-fill, 100%);
  }
`;
