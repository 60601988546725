import { useCallback } from "react";
import { MealsAnalysisResponseTableHeaders } from "./meals-analysis-response-table-headers/meals-analysis-response-table-headers";
import { MealsAnalysisResponseTableRows } from "./meals-analysis-response-table-rows/meals-analysis-response-table-rows";
import { MealsAnalysisResponseTableContainer } from "./styled-meals-analysis-response-table";
import { useParams } from "react-router-dom";
import { useGetPatient } from "../../../../../../hooks/patient-hooks/use-get-patient";
import { useGetPatientMealResponses } from "../../../../../../hooks/patient-meal-response-hooks/use-get-patient-meal-responses";
import { Loading } from "../../../../../../components/loading";
import { INSULIN_SPAN_IN_MINUTES } from "../../constants/insulin-span-in-minutes";
import { INSULIN_THRESHOLD_IN_MINUTES } from "../../constants/insulin-threshold-in-minutes";
import { useMealsAnalysisResponseTags } from "../context/meals-analysis-response-tags-context";
import { useDateRange } from "../../../../../../components/date-range/context/date-range-context";

export const MealsAnalysisResponseTable = () => {
  const { id } = useParams();
  const patientID = parseInt(id!);

  const getPatient = useGetPatient();
  const getPatientMealResponses = useGetPatientMealResponses();

  const { temporalStartDate, temporalEndDate } = useDateRange();
  const { insulinTag } = useMealsAnalysisResponseTags();

  const load = useCallback(async () => {
    const [patientDTO, patientMealResponseDTO] = await Promise.all([
      getPatient(patientID),
      getPatientMealResponses(
        patientID,
        temporalStartDate,
        temporalEndDate,
        INSULIN_SPAN_IN_MINUTES,
        INSULIN_THRESHOLD_IN_MINUTES,
        insulinTag
      ),
    ]);

    return { patientDTO, patientMealResponseDTO };
  }, [
    patientID,
    getPatient,
    getPatientMealResponses,
    temporalStartDate,
    temporalEndDate,
    insulinTag,
  ]);

  return (
    <MealsAnalysisResponseTableContainer>
      <table>
        <MealsAnalysisResponseTableHeaders />
        <Loading
          load={load}
          successComponent={(insulinDataLoaded) => (
            <MealsAnalysisResponseTableRows
              insulinDataLoaded={insulinDataLoaded}
            />
          )}
        />
      </table>
    </MealsAnalysisResponseTableContainer>
  );
};
