import { PlanStepsHeader } from "../../components/plan-steps-header/plan-steps-header";
import {
  getEducationContent,
  usePlansGoals,
} from "../../context/goals-context";
import {
  EducationListItem,
  EducationListItemImageContainer,
  EducationListItemLeftContainer,
  EducationListItemTextContainer,
} from "../education/styled-education";
import { VideoModal } from "../education/video-modal/video-modal";
import { GoalsScreenContainer } from "../goals/styled-goals";
import { ReviewCard, ReviewEducationList } from "./styled-review";

export const ReviewEducation = () => {
  const { assignedEducationGoalsList } = usePlansGoals();

  const education = assignedEducationGoalsList.length > 0 && (
    <ReviewCard>
      <PlanStepsHeader
        title="Education content"
        subTitle="Give the patient an education content to increase their learning"
        icon="/img/plans/nav/education.svg"
        button={<></>}
      />
      <GoalsScreenContainer>
        <ReviewEducationList>
          {assignedEducationGoalsList.map((goal, index) => {
            const goalObject = getEducationContent(goal.goal).lesson;

            return (
              <EducationListItem key={index}>
                <EducationListItemLeftContainer>
                  <EducationListItemImageContainer>
                    <img
                      src="/img/plans/education/food.svg"
                      alt="food item icon"
                    />
                  </EducationListItemImageContainer>
                  <EducationListItemTextContainer>
                    <p>{goalObject.title}</p>
                    {goalObject.type === "Video" && (
                      <VideoModal videoURL={goalObject.link} />
                    )}
                  </EducationListItemTextContainer>
                </EducationListItemLeftContainer>
              </EducationListItem>
            );
          })}
        </ReviewEducationList>
      </GoalsScreenContainer>
    </ReviewCard>
  );

  return <>{education}</>;
};
