import { PATIENTS_PATH } from "../constants/paths";
import { useClient } from "../context/client-context";
import { useCallback } from "react";

export function useImportLibreview(): (
  patientId: number,
  libreviewCSV: string,
  force?: boolean
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientId, libreviewCSV, force) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      await client.post<void>(
        `${PATIENTS_PATH}/${patientId}/cgm-entries/import-libreview`,
        libreviewCSV,
        { headers: { "Content-Type": "text/plain" }, params: { force } }
      );
    },
    [client]
  );
}
