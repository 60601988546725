import styled from "styled-components/macro";
import { flexCenterCenter } from "../../../../../styles/classes/gloabl-classes";

export const FoodLoggingSelectedPatientMealImagesContainer = styled.div<{
  patientMealImageDTOsLength: number;
}>`
  background: ${({ theme }) => theme.colors.bodyColor};
  border-radius: 16px;
  display: grid;
  grid-template-columns: ${({ patientMealImageDTOsLength }) =>
    patientMealImageDTOsLength === 1
      ? "repeat(1, 1fr)"
      : patientMealImageDTOsLength === 2
      ? "repeat(2, 1fr)"
      : patientMealImageDTOsLength === 3
      ? "repeat(3, 1fr)"
      : "repeat(2, 1fr)"};
  gap: 8px;
  justify-items: center;
  padding: 8px;
  max-height: 270px;
  width: fit-content;
  margin: 0 auto;
`;

export const FoodLoggingSelectedPatientMealImagesImageContainer = styled.div<{
  faded: boolean;
  mealImagesLength?: number;
}>`
  cursor: pointer;
  height: ${({ mealImagesLength }) =>
    mealImagesLength! >= 4
      ? "120px"
      : mealImagesLength! === 3
      ? "150px"
      : "auto"};
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  ${flexCenterCenter}

  img {
    height: 100%;
    max-height: 200px;
  }
`;

export const FoodLoggingSelectedPatientMealImagesImageFadded = styled.div<{
  faded: boolean;
}>`
  position: absolute;
  background: ${({ faded, theme }) =>
    faded ? theme.newColors.imageFrontdrop : "transparent"};
  inset: 0;
  ${flexCenterCenter}

  p {
    font-size: 35px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    color: ${({ theme }) => theme.colors.containerColor};
  }
`;
