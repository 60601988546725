import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";

type NutritionReportKcalTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportKcalTableCell = (
  props: NutritionReportKcalTableCellProps
) => {
  const { kind } = props;

  const { kcal } = useMemo(() => {
    const kcal =
      kind.kind === "Row"
        ? kind.data.totalKCals
        : kind.data.patientMealMacrosSummary.kCals;

    return { kcal };
  }, [kind.kind, kind.data]);

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      value={kcal}
    />
  );
};
