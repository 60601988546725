import { useCallback } from "react";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { PatientProfileDiagnosisDTO } from "../../models/patient-profile-diagnosis-dtos/patient-profile-diagnosis-dto";

export function useGetPatientProfileDiagnoses(): (
  patientId: number
) => Promise<PatientProfileDiagnosisDTO[]> {
  const client = useClient();

  return useCallback(
    async (patientId) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientProfileDiagnosisDTO[]>(
        `${PATIENTS_PATH}/${patientId}/profile-diagnoses`
      );

      return response.data;
    },
    [client]
  );
}
