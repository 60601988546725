import { PatientNutritionReportDailySummaryDayDTO } from "../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-daily-summary-day-dto";
import { PatientNutritionReportDailySummaryDTO } from "../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-daily-summary-dto";

export type PatientNutritionReportDailySummaryDayDTOWithId = PatientNutritionReportDailySummaryDayDTO & {
  id: number;
};

export function createPatientNutritionReportDailySummaryDayDTOWithIds(
  patientNutritionReportDailySummary: PatientNutritionReportDailySummaryDTO
): PatientNutritionReportDailySummaryDayDTOWithId[] {
  const result: PatientNutritionReportDailySummaryDayDTOWithId[] = [];

  for (let id = 0; id < patientNutritionReportDailySummary.days.length; id++) {
    result.push({
      ...patientNutritionReportDailySummary.days[id],
      id,
    });
  }

  return result;
}
