import { BASE_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { CarbCounterV1AddFoodDTO } from "../../models/carb-counter-dtos/carb-counter-v1-add-food-dto";
import { CarbCounterV1AddFoodResponseDTO } from "../../models/carb-counter-dtos/carb-counter-v1-add-food-response-dto";

export function useAddFood(): (
  carbCounterV1AddFoodDTO: CarbCounterV1AddFoodDTO,
  temperature?: number
) => Promise<CarbCounterV1AddFoodResponseDTO> {
  const client = useClient();

  return useCallback(
    async (carbCounterV1AddFoodDTO, temperature = undefined) => {
      const response = await client.post<CarbCounterV1AddFoodResponseDTO>(
        `${BASE_PATH}/carb-counter/v1/meals/add-food`,
        carbCounterV1AddFoodDTO,
        { params: { temperature } }
      );

      return response.data;
    },
    [client]
  );
}
