import { PropsWithChildren } from "react";
import { MealsAnalysisResponseTagsProvider } from "./meals-analysis-response-tags-context";
import { CarbsourcesFilterProvider } from "./meals-analysis-response-carbsources-filter-context";
import { DateRangeProvider } from "../../../../../../components/date-range/context/date-range-context";

type MealsAnalysisResponseProvidersProps = PropsWithChildren<{}>;

export const MealsAnalysisResponseProviders = (
  props: MealsAnalysisResponseProvidersProps
) => {
  const { children } = props;

  return (
    <DateRangeProvider>
      <MealsAnalysisResponseTagsProvider>
        <CarbsourcesFilterProvider>{children}</CarbsourcesFilterProvider>
      </MealsAnalysisResponseTagsProvider>
    </DateRangeProvider>
  );
};
