import { createContext, PropsWithChildren, useContext } from "react";
import { PatientDTO } from "../../../../models/patient-dtos/patient-dto";
import { UseLoadableDataReturn } from "../../../../hooks/use-loadable-data";

export type BglAnalysis = {
  patientDTO: PatientDTO;
};

export type LoadableBglAnalysis = UseLoadableDataReturn<BglAnalysis>;

const LoadableBglAnalysisContext = createContext<
  LoadableBglAnalysis | undefined
>(undefined);

export type LoadableBglAnalysisProviderProps = PropsWithChildren<{
  loadableBglAnalysis: LoadableBglAnalysis;
}>;

export function LoadableBglAnalysisProvider(
  props: LoadableBglAnalysisProviderProps
) {
  const { loadableBglAnalysis, children } = props;

  return (
    <LoadableBglAnalysisContext.Provider value={loadableBglAnalysis}>
      {children}
    </LoadableBglAnalysisContext.Provider>
  );
}

export function useLoadableBglAnalysis(): LoadableBglAnalysis {
  const loadableBglAnalysis = useContext(LoadableBglAnalysisContext);
  if (loadableBglAnalysis === undefined) {
    throw Error(
      "useLoadableBglAnalysis must be used within an LoadableBglAnalysissProvider"
    );
  }

  return loadableBglAnalysis;
}

export function useBglAnalysis(): BglAnalysis {
  const loadableBglAnalysis = useLoadableBglAnalysis();
  if (loadableBglAnalysis.state.data === undefined) {
    throw Error("BglAnalysis have never been loaded successfully yet.");
  }

  return loadableBglAnalysis.state.data;
}

export function useReloadBglAnalysis(): () => void {
  const loadableBglAnalysis = useLoadableBglAnalysis();
  return loadableBglAnalysis.reload;
}
