import { ModalForm } from "../../../../components/modal/modal-form/modal-form";
import { NewLabResultsModalHeader } from "../components/new-lab-results-modal-header/new-lab-results-modal-header";
import { PatientLabTestResultDTO } from "../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import {
  NewBulkLabResultsModalBodySubHeader,
  NewBulkLabResultsModalBodySubHeaderButtons,
} from "../bulk-lab-results-modal/bulk-lab-results-modal-body/styled-new-bulk-lab-results-modal-body";
import {
  ErrorText,
  InputContainer,
} from "../../../../styles/classes/reusable-classes";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { LabResultsButton } from "../components/lab-results-form-footer/styled-lab-results-form-footer";
import { NewLabResultsTypeDropdown } from "../components/new-lab-results-type-dropdown/new-lab-results-type-dropdown";
import { Temporal } from "temporal-polyfill";
import { useBulkAddPatientLabTestResults } from "../../../../hooks/patient-lab-test-result-hooks/use-bulk-add-patient-lab-test-results";
import { useBulkUpdatePatientLabTestResults } from "../../../../hooks/patient-lab-test-result-hooks/use-bulk-update-patient-lab-test-results";
import { useToastService } from "../../../../context/toast-service-context";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { LabResultsFormFooter } from "../components/lab-results-form-footer/lab-results-form-footer";
import { BulkCreatePatientLabTestResultsDTO } from "../../../../models/patient-lab-test-result-dtos/bulk-create-patient-lab-test-results-dto";
import { NewLabResultsList } from "../components/new-lab-results-list/new-lab-results-list";
import {
  NewLabResultsListIconContainer,
  NewLabResultsListInputContainer,
  NewLabResultsListListItem,
} from "../components/new-lab-results-list/styled-new-lab-results-list";
import { NewLabResultsUnits } from "../components/new-lab-results-units/new-lab-results-units";
import {
  useLabResults,
  useReloadLabResults,
} from "../context/loadable-lab-results-context";

type LabTestResultModalProps = {
  onClose: () => void;
  mode: { kind: "add" } | { kind: "edit"; labTest: PatientLabTestResultDTO[] };
};

type LabTestResultModalInputs = {
  labTestTypeId?: number;
  labTestResults: {
    date: string;
    result?: number;
    labTestUnit?: string;
  }[];
};

export const LabTestResultModal = (props: LabTestResultModalProps) => {
  const { onClose, mode } = props;

  const today = Temporal.Now.plainDateISO();

  const { id } = useParams();
  const addPatientLabResult = useBulkAddPatientLabTestResults();
  const updatePatientLabResult = useBulkUpdatePatientLabTestResults();
  const { showToast } = useToastService();
  const reloadLabResults = useReloadLabResults();

  const { labTestTypeDTOs } = useLabResults();

  const {
    control,
    handleSubmit,
    watch,
    resetField,
    reset,
    formState: { errors },
  } = useForm<LabTestResultModalInputs>({
    defaultValues:
      mode.kind === "add"
        ? {
            labTestResults: [],
          }
        : {
            labTestTypeId: mode.labTest[0].labTestTypeId,
            labTestResults: Object.values(mode.labTest).map((value) => {
              return {
                date: value.date,
                result: value.result,
                labTestUnit: labTestTypeDTOs.find(
                  (labTestTypeDTO) =>
                    labTestTypeDTO.id === mode.labTest[0].labTestTypeId
                )!.unit,
              };
            }),
          },
  });

  const { fields, append, remove } = useFieldArray({
    name: "labTestResults",
    control,
    rules: mode.kind === "add" ? { required: true, minLength: 1 } : {},
  });

  const labTestTypeId = watch("labTestTypeId");

  const labTestTypeDTO = useMemo(
    () =>
      labTestTypeDTOs.find(
        (labTestTypeDTO) => labTestTypeDTO.id === labTestTypeId
      ),
    [labTestTypeId, labTestTypeDTOs]
  );

  const defaultUnit = useMemo(() => {
    if (labTestTypeDTO === undefined) {
      return undefined;
    }

    if (labTestTypeDTO.name === "Ionized Calcium") {
      return undefined;
    }

    return labTestTypeDTO.unit;
  }, [labTestTypeDTO]);

  const deleteAllFields = () => {
    reset({ labTestTypeId: undefined, labTestResults: [] });
  };

  const clearAllFields =
    mode.kind === "add"
      ? () => {}
      : () => {
          reset({
            labTestTypeId: mode.labTest[0].labTestTypeId,
            labTestResults: Object.values(mode.labTest).map((value) => {
              return {
                date: value.date,
                result: undefined,
                labTestUnit: labTestTypeDTOs.find(
                  (labTestTypeDTO) =>
                    labTestTypeDTO.id === mode.labTest[0].labTestTypeId
                )!.unit,
              };
            }),
          });
        };

  const onSubmit = handleSubmit(async (inputs) => {
    // Some inputs could be undefined for some time. However, with `required` rules, they will be defined before submitting.
    // Update: With the latest update in `BulkCreatePatientLabTestResultsDTO`, this is not really a createOrUpdateDTO. But
    // still useful to keep some common logic like null assertion & Ionized Calcium case.
    const createOrUpdateDTO = {
      ...inputs,
      labTestTypeId: inputs.labTestTypeId!,
      labTestResults: inputs.labTestResults.map((labTestResult) => ({
        ...labTestResult,
        result:
          labTestTypeDTO!.name === "Ionized Calcium" &&
          labTestResult.labTestUnit === "mmol/L"
            ? labTestResult.result! * 4.01
            : labTestResult.result!,
      })),
    };

    if (mode.kind === "add") {
      try {
        const createDTO: BulkCreatePatientLabTestResultsDTO = {
          patientLabTestResults: createOrUpdateDTO.labTestResults.map(
            (labTestResult) => ({
              labTestTypeId: createOrUpdateDTO.labTestTypeId,
              date: labTestResult.date,
              result: labTestResult.result,
            })
          ),
        };
        await addPatientLabResult(parseInt(id!), createDTO);
        onClose();
        showToast("Success", "Lab test result added successfully!");
        reloadLabResults();
      } catch (e) {
        showToast("Error", "Failed to add Lab test result :(");
      }
    } else {
      try {
        await updatePatientLabResult(parseInt(id!), createOrUpdateDTO);
        showToast("Success", "Lab test result updated successfully!");
        reloadLabResults();
      } catch (error) {
        showToast("Error", "Failed to update Lab test result :(");
      }
    }
  });

  const headers = ["Date", "Reading", "Unit"].map((header, index) => (
    <p key={index}>{header}</p>
  ));

  const listItems = fields.map((field, index) => {
    return (
      <NewLabResultsListListItem key={field.id}>
        <NewLabResultsListInputContainer smallInputs={true}>
          <Controller
            name={`labTestResults.${index}.date`}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <input
                type="date"
                max={today.toString()}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.labTestResults?.root?.type === "required" && (
            <ErrorText>Add at least one reading</ErrorText>
          )}
        </NewLabResultsListInputContainer>
        <NewLabResultsListInputContainer smallInputs={true}>
          <Controller
            name={`labTestResults.${index}.result`}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <input
                type="number"
                step="0.001"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.labTestResults?.[index]?.result?.type === "required" && (
            <ErrorText>Required</ErrorText>
          )}
        </NewLabResultsListInputContainer>
        <NewLabResultsListInputContainer smallInputs={true}>
          <Controller
            name={`labTestResults.${index}.labTestUnit`}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <NewLabResultsUnits
                labTestTypeDTO={labTestTypeDTO!}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.labTestResults?.[index]?.labTestUnit?.type === "required" && (
            <ErrorText>Required</ErrorText>
          )}
        </NewLabResultsListInputContainer>
        <NewLabResultsListIconContainer>
          <img
            src="/img/trash.svg"
            alt="Trash Icon"
            onClick={() => remove(index)}
          />
        </NewLabResultsListIconContainer>
      </NewLabResultsListListItem>
    );
  });

  return (
    <ModalForm width={990} height={750} onSubmit={onSubmit}>
      <NewLabResultsModalHeader onClose={onClose} />
      <NewBulkLabResultsModalBodySubHeader>
        <InputContainer>
          <Controller
            name="labTestTypeId"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <NewLabResultsTypeDropdown
                value={value}
                onChange={(labTestType) => {
                  resetField("labTestResults");
                  onChange(labTestType);
                }}
                disabledValue={mode.kind === "edit" && true}
              />
            )}
          />
          {errors.labTestResults?.root?.type === "required" && (
            <ErrorText>Add at least one reading</ErrorText>
          )}
        </InputContainer>
        <NewBulkLabResultsModalBodySubHeaderButtons>
          <LabResultsButton
            type="button"
            disabled={labTestTypeDTO === undefined}
            onClick={() =>
              append({
                date: today.toString(),
                labTestUnit: defaultUnit,
              })
            }
          >
            Add New Reading
          </LabResultsButton>
          <LabResultsButton danger onClick={deleteAllFields} type="button">
            Delete all fields
          </LabResultsButton>
          <LabResultsButton
            type={mode.kind === "add" ? "reset" : "button"}
            onClick={clearAllFields}
          >
            Clear all fields
          </LabResultsButton>
        </NewBulkLabResultsModalBodySubHeaderButtons>
      </NewBulkLabResultsModalBodySubHeader>
      <NewLabResultsList
        headers={headers}
        listItems={listItems}
        smallInputs={true}
      />
      <LabResultsFormFooter onClose={onClose} />
    </ModalForm>
  );
};
