import { Link, useParams } from "react-router-dom";
import {
  SinglePatientHeaderTitleContainer,
  SinglePatientHeaderTitle,
  SinglePatientHeaderIcon,
} from "./styled-single-patient-header";
import { ScreenHeader } from "../../../components/screen-header/screen-header";
import { NavLink } from "../../../components/screen-navbar/screen-navbar";
import { ReactNode } from "react";
import { FEATURE_FLAG_NUTRITION_GOALS } from "../../../constants/config";
import { useGetPatientDto } from "../../../hooks/use-get-patient-dto";

type SinglePatientHeaderProps = {
  buttons: ReactNode;
};

export const SinglePatientHeader = (props: SinglePatientHeaderProps) => {
  const { buttons } = props;
  const { id } = useParams();
  const patientID = parseInt(id!);

  const { patientDTO } = useGetPatientDto(patientID);

  const titleElement = (
    <SinglePatientHeaderTitleContainer>
      <SinglePatientHeaderIcon>
        <Link to={`/1`}>
          <span className="material-symbols-outlined">west</span>
        </Link>
      </SinglePatientHeaderIcon>
      <SinglePatientHeaderTitle>
        {patientDTO !== undefined && (
          <>
            {patientDTO.name} <span>[{patientDTO.type}]</span>
          </>
        )}
      </SinglePatientHeaderTitle>
    </SinglePatientHeaderTitleContainer>
  );

  return (
    <ScreenHeader
      title={titleElement}
      buttons={patientDTO !== undefined ? buttons : <></>}
      navLinks={buildNavbarLinks(id)}
    />
  );
};

function buildNavbarLinks(id: string | undefined): NavLink[] {
  const navLinks = [
    {
      name: "Patient Profile",
      path: `/patients/${id}/patient-profile`,
    },
    {
      name: "BGL-Analysis",
      path: `/patients/${id}/bgl-analysis`,
    },
    {
      name: "Medications",
      path: `/patients/${id}/medications`,
    },
    {
      name: "Lab results",
      path: `/patients/${id}/lab-results`,
    },
    {
      name: "Plans",
      path: `/patients/${id}/plans`,
    },
    {
      name: "Administration",
      path: `/patients/${id}/administration`,
    },
    {
      name: "Activities",
      path: `/patients/${id}/life-activities`,
    },
  ];

  if (FEATURE_FLAG_NUTRITION_GOALS) {
    navLinks.splice(4, 0, {
      name: "Nutrition",
      path: `/patients/${id}/dietitian-report`,
    });
  }

  return navLinks;
}
