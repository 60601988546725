import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

export type DirtyMeal = {
  dirty: boolean;
  setDirty: (dirty: boolean) => void;
};

const DirtyMealContext = createContext<DirtyMeal | undefined>(undefined);

export function DirtyMealProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const [dirty, setDirty] = useState(false);

  const value = useMemo(() => ({ dirty, setDirty }), [dirty, setDirty]);

  return (
    <DirtyMealContext.Provider value={value}>
      {children}
    </DirtyMealContext.Provider>
  );
}

export function useDirtyMeal() {
  const context = useContext(DirtyMealContext);

  if (context === undefined) {
    throw new Error("useDirtyMeal must be used within a DirtyMealProvider");
  }

  return context;
}
