import styled from "styled-components/macro";
import {
  NewPrescriotionSidebarWidth,
  NewPrescriptionHeaderHeight,
} from "../../../constants/prescription-constants";

export const PrescriptionSidebarContainer = styled.aside`
  padding: ${({ theme }) => theme.padding.py_05};
  height: calc(100% - ${NewPrescriptionHeaderHeight});
  background: ${({ theme }) => theme.colors.containerColor};
  width: ${NewPrescriotionSidebarWidth};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-top: 0px;
  border-left: 0px;
`;

export const PrescriptionSidebarHeader = styled.div`
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors.titleColor};
    margin-top: 1px;
  }
`;

export const PrescriptionSidebarBody = styled.div`
  overflow-y: auto;
  height: 100%;
  margin-right: -7px;
`;
