import { MealsAnalysisResponseTableHeadersHead } from "./styled-meals-analysis-response-table-headers";

export const MealsAnalysisResponseTableHeaders = () => {
  const tableHeaders = [
    "Date - Time",
    "Type",
    "Media",
    "Carb Servings",
    "Macros",
    "MealsAnalysis",
    "Graph",
  ].map((header) => <th key={header}>{header}</th>);

  return (
    <MealsAnalysisResponseTableHeadersHead>
      <tr>{tableHeaders}</tr>
    </MealsAnalysisResponseTableHeadersHead>
  );
};
