import styled from "styled-components/macro";
import { lightShadowAlt } from "../../../../../../styles/global-style";
import { mediumSemiBoldFont } from "../../../../../../styles/classes/gloabl-classes";

export const CGMReportResponseAGPContainer = styled.div`
  padding: 0px 24px;
`;

export const CGMReportResponseAGPCard = styled.div`
  ${lightShadowAlt}
  border-radius: 8px;
  margin-top: 32px;
`;

export const CGMReportResponseAGPHeader = styled.div`
  background: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;

  p {
    ${mediumSemiBoldFont}
    margin: 0px;
  }
`;

export const CGMReportResponseAGPBody = styled.div<{ $isEmpty: boolean }>`
  padding: 8px 16px;
  height: calc(100% - 37px);
  overflow-x: auto;
  display: ${({ $isEmpty }) => ($isEmpty ? "block" : "flex")};
`;
