import { Controller, useFormContext } from "react-hook-form";
import {
  RadioButton,
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../styles/classes/reusable-classes";
import { CreateLessonInputs } from "../../create-lesson";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { LessonStatusMessageModal } from "../../../../components/lesson-status-message-modal/lesson-status-message-modal";
import { useEffect } from "react";

export const CreateLessonStatusController = () => {
  const { control, watch, setValue } = useFormContext<CreateLessonInputs>();
  const contentModuleCardsWatcher = watch("contentModuleCards");

  const isDisabled = contentModuleCardsWatcher.length === 0;

  useEffect(() => {
    if (isDisabled) {
      setValue("status", false);
    }
  }, [setValue, isDisabled]);

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  return (
    <Controller
      control={control}
      name="status"
      render={({ field: { value, onChange } }) => (
        <UXInputContainer $width="240px">
          <UXInputLabel $width="110px">Lesson Status</UXInputLabel>
          <RadioButton
            type="button"
            checked={value}
            onClick={() => {
              if (isDisabled) {
                openModal();
              } else {
                onChange(!value);
              }
            }}
          >
            <span />
          </RadioButton>
          <LessonStatusMessageModal
            closeModal={closeModal}
            isModalOpen={isModalOpen}
            modalBodyKey={modalBodyKey}
          />
        </UXInputContainer>
      )}
    />
  );
};
