import styled from "styled-components/macro";
import { transition } from "../../../../styles/global-style";

export const ImmediateAttentionPatientsSidebarContainer = styled.div<{
  $showSidebar: boolean;
}>`
  position: fixed;
  z-index: 700;
  inset: 0px;
  display: flex;
  width: ${({ $showSidebar }) => ($showSidebar ? "100%" : "0px")};
  opacity: ${({ $showSidebar }) => ($showSidebar ? "1" : "0")};
  overflow: ${({ $showSidebar }) => ($showSidebar ? "visible" : "hidden")};
  transform: ${({ $showSidebar }) =>
    $showSidebar ? "translateX(0px)" : "translateX(100vw)"};
  transition: ${({ $showSidebar }) => ($showSidebar ? transition : "")};
`;

export const ImmediateAttentionPatientsSidebarBackdrop = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.newColors.backdrop};
`;

export const ImmediateAttentionPatientsSidebarBodyContainer = styled.div<{
  $showSidebar: boolean;
}>`
  width: 800px;
  height: 100%;
  padding: 16px;
  background: ${({ theme }) => theme.colors.containerColor};
  overflow-y: auto;
`;
