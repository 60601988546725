import { createContext, PropsWithChildren, useContext } from "react";
import { PatientDTO } from "../../../models/patient-dtos/patient-dto";
import { UseLoadableDataReturn } from "../../../hooks/use-loadable-data";
import { PatientCGMEntryDTO } from "../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientServingDTO } from "../../../models/patient-serving-dto";
import { PatientLabTestResultDTO } from "../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import { LabTestCategoryDTO } from "../../../models/lab-test-categories/lab-test-category-dto";
import { LabTestTypeDTO } from "../../../models/lab-test-types/lab-test-type-dto";
import { LabTestTypeRefDTO } from "../../../models/lab-test-type-refs/lab-test-type-ref-dto";
import { PatientWeightDTO } from "../../../models/patient-weight-dtos/patient-weight-dto";
import { PatientMedicationDTO } from "../../../models/patient-medication-dto";
import { FrequencyDTO } from "../../../models/frequencies/frequency-dto";
import { PatientPlanDTO } from "../../../models/patient-plan-dtos/patient-plan-dto";
import { PatientMealDTO } from "../../../models/patient-meal-dtos/patient-meal-dto";

export type Patient = {
  patientDTO: PatientDTO;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
  patientServingDTOs: PatientServingDTO[];
  patientLabTestResultsDTOs: PatientLabTestResultDTO[];
  labTestCategoryDTOs: LabTestCategoryDTO[];
  labTestTypeDTOs: LabTestTypeDTO[];
  labTestTypeRefDTOs: LabTestTypeRefDTO[];
  patientMedicationDTOs: PatientMedicationDTO[];
  patientWeightDTOs: PatientWeightDTO[];
  frequencyDTOs: FrequencyDTO[];
  patientPlans: PatientPlanDTO[];
  patientMealDTOs: PatientMealDTO[];
};

export type LoadablePatient = UseLoadableDataReturn<Patient>;

const LoadablePatientContext = createContext<LoadablePatient | undefined>(
  undefined
);

export type LoadablePatientProviderProps = PropsWithChildren<{
  loadablePatient: LoadablePatient;
}>;

export function LoadablePatientProvider(props: LoadablePatientProviderProps) {
  const { loadablePatient, children } = props;

  return (
    <LoadablePatientContext.Provider value={loadablePatient}>
      {children}
    </LoadablePatientContext.Provider>
  );
}

export function useLoadablePatient(): LoadablePatient {
  const loadablePatient = useContext(LoadablePatientContext);
  if (loadablePatient === undefined) {
    throw Error(
      "useLoadablePatient must be used within an LoadablePatientsProvider"
    );
  }

  return loadablePatient;
}

export function usePatient(): Patient {
  const loadablePatient = useLoadablePatient();
  if (loadablePatient.state.data === undefined) {
    throw Error("Patient have never been loaded successfully yet.");
  }

  return loadablePatient.state.data;
}

export function useReloadPatient(): () => void {
  const loadablePatient = useLoadablePatient();
  return loadablePatient.reload;
}
