import { useCallback, useMemo } from "react";
import { Temporal } from "temporal-polyfill";
import { PatientMedicationDTO } from "../../../../../models/patient-medication-dto";
import { MedicationCardWithSig } from "../../../medications/components/medication-card/medication-card-with-sig";
import { sortMedicationsByName } from "../../../medications/utils/medications-utils";
import {
  ViewPlansMedicationsList,
  ViewPlansMedicationsListItem,
  ViewPlansMedicationsListItemMedicationIndicator,
} from "./styled-view-plans-medications";
import { useGetPatientMedicationDTOs } from "../../hooks/use-get-patient-medication-dtos";
import { usePatient } from "../../../context/loadable-patient-context";

export const ViewPlansMedications = () => {
  const { patientDTO } = usePatient();

  const { patientMedicationDTOs } = useGetPatientMedicationDTOs(patientDTO);

  const sorted = useMemo(
    () => sortMedicationsByName(patientMedicationDTOs),
    [patientMedicationDTOs]
  );

  const sortedPatientMedicationData = useMemo(
    () =>
      sorted.sort((a, b) => {
        if (
          a.discontinueDate === undefined &&
          b.discontinueDate !== undefined
        ) {
          return -1;
        }
        if (
          a.discontinueDate !== undefined &&
          b.discontinueDate === undefined
        ) {
          return 1;
        }

        if (a.startDate === undefined) {
          if (b.startDate === undefined) {
            return 0;
          } else {
            return -1;
          }
        } else {
          if (b.startDate === undefined) {
            return 1;
          } else {
            return Temporal.PlainDate.compare(b.startDate, a.startDate);
          }
        }
      }),
    [sorted]
  );

  const isNewMedication = useCallback((item: PatientMedicationDTO) => {
    if (item.startDate === undefined) {
      return false;
    }

    const today = Temporal.Now.plainDateISO().toString();
    const startDate = Temporal.PlainDate.from(item.startDate).toString();
    const startDatePlus1Week = Temporal.PlainDate.from(item.startDate)
      .add({
        days: 7,
      })
      .toString();

    if (startDate <= today && startDatePlus1Week >= today) {
      return true;
    } else {
      return false;
    }
  }, []);

  return (
    <ViewPlansMedicationsList>
      {sortedPatientMedicationData.map((item) => (
        <ViewPlansMedicationsListItem key={item.id}>
          <MedicationCardWithSig patientMedicationDTO={item} />
          <ViewPlansMedicationsListItemMedicationIndicator
            isStopped={item.discontinueDate !== undefined}
            isNew={isNewMedication(item)}
          >
            <div>
              {item.discontinueDate !== undefined
                ? `Stopped`
                : isNewMedication(item) && item.discontinueDate === undefined
                ? `New`
                : ``}
            </div>
            <span>
              {item.discontinueDate !== undefined
                ? `${item.discontinueDate}`
                : ``}
            </span>
          </ViewPlansMedicationsListItemMedicationIndicator>
        </ViewPlansMedicationsListItem>
      ))}
    </ViewPlansMedicationsList>
  );
};
