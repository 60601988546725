import { useSinglePatientSidebar } from "../../../context/loadable-single-patient-sidebar-context";
import {
  PersonalInfoGroup,
  PersonalInfoGroupTitle,
  PersonalInfoGroupTop,
} from "../../personal-info-tab/styled-personal-info-tab";

export const AnginaOrStroke = () => {
  const { patientTimelineDTO } = useSinglePatientSidebar();

  const anginaOrStroke =
    patientTimelineDTO.events.find(
      (item) =>
        item.emergency === "AnginaPectoris" || item.emergency === "Stroke"
    ) !== undefined
      ? "Yes"
      : "No";

  return (
    <PersonalInfoGroup>
      <PersonalInfoGroupTop>
        <PersonalInfoGroupTitle>Angina or Stroke</PersonalInfoGroupTitle>
        <PersonalInfoGroupTitle>{anginaOrStroke}</PersonalInfoGroupTitle>
      </PersonalInfoGroupTop>
    </PersonalInfoGroup>
  );
};
