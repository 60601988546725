import { useEffect, useState } from "react";
import { useGetMedications } from "../../../../hooks/medications/use-get-medications";
import { MedicationDTO } from "../../../../models/medications/medication-dto";

type UseGetMedicationDTOs = { medicationDTOs: MedicationDTO[] };

export function useGetMedicationDTOs(): UseGetMedicationDTOs {
  const [medicationDTOs, setMedicationDTOs] = useState<MedicationDTO[]>([]);

  const getMedications = useGetMedications();

  useEffect(() => {
    const fetch = async () => {
      getMedications().then((response) => setMedicationDTOs(response));
    };

    fetch();
  }, [getMedications]);

  return { medicationDTOs };
}
