import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { CarbSourceDTO } from "../../../../../../models/carb-sources/carb-source-dto";
import { PatientMealCarbSourceDTO } from "../../../../../../models/patient-meal-dtos/patient-meal-carb-source-dto";

type CarbsourcesFilter = {
  carbSourceDTO?: CarbSourceDTO;
  onCarbSourceChange: (carbSourceDTO?: CarbSourceDTO) => void;
  mealsCarbsourceDTOs: PatientMealCarbSourceDTO[];
  setMealsCarbsourceDTOs: (
    patientMealCarbSourceDTOs: PatientMealCarbSourceDTO[]
  ) => void;
};

const CarbsourcesFilterContext = createContext<CarbsourcesFilter | undefined>(
  undefined
);

type CarbsourcesFilterProviderProps = PropsWithChildren<{}>;

export function CarbsourcesFilterProvider(
  props: CarbsourcesFilterProviderProps
) {
  const { children } = props;

  const [carbSourceDTO, setCarbSourceDTO] = useState<
    CarbSourceDTO | undefined
  >();
  const [mealsCarbsourceDTOs, setMealsCarbsourceDTOs] = useState<
    PatientMealCarbSourceDTO[]
  >([]);

  const onCarbSourceChange = useCallback(
    (carbSourceDTO?: CarbSourceDTO) => setCarbSourceDTO(carbSourceDTO),
    []
  );

  const value = useMemo(
    () => ({
      carbSourceDTO,
      onCarbSourceChange,
      mealsCarbsourceDTOs,
      setMealsCarbsourceDTOs,
    }),
    [
      carbSourceDTO,
      onCarbSourceChange,
      mealsCarbsourceDTOs,
      setMealsCarbsourceDTOs,
    ]
  );

  return (
    <CarbsourcesFilterContext.Provider value={value}>
      {children}
    </CarbsourcesFilterContext.Provider>
  );
}

export function useCarbsourcesFilter(): CarbsourcesFilter {
  const carbsourcesFilter = useContext(CarbsourcesFilterContext);

  if (carbsourcesFilter === undefined) {
    throw new Error(
      "useCarbsourcesFilter must be used within a CarbsourcesFilterProvider"
    );
  }

  return carbsourcesFilter;
}
