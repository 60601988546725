import styled from "styled-components/macro";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";

export const PateintProfileFamilyMemberDiseasesFormControlBody = styled.div<{
  isNotEmptyFamilyMemberDiseases: boolean;
}>`
  display: grid;
  width: 100%;

  ${Button} {
    margin-top: ${({ isNotEmptyFamilyMemberDiseases }) =>
      isNotEmptyFamilyMemberDiseases ? "12px" : "0px"};
    margin-left: 32px;
    padding: 0px;
  }
`;

export const PateintProfileFamilyMemberDiseasesFormControlDropdownsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  position: relative;
  width: 100%;
  gap: 0px 24px;

  ${Button} {
    position: absolute;
    left: -60px;
    top: 0px;
  }

  input {
    margin-bottom: 10px;
  }
`;
