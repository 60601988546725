import { createContext, PropsWithChildren, useContext } from "react";
import { UseLoadableDataReturn } from "../../../hooks/use-loadable-data";
import { PatientMealDTO } from "../../../models/patient-meal-dtos/patient-meal-dto";

export type LoadablePatientMeals = UseLoadableDataReturn<PatientMealDTO[]>;

const LoadablePatientMealsContext = createContext<
  LoadablePatientMeals | undefined
>(undefined);

export type LoadablePatientMealsProviderProps = PropsWithChildren<{
  loadablePatientMeals: LoadablePatientMeals;
}>;

export function LoadablePatientMealsProvider(
  props: LoadablePatientMealsProviderProps
) {
  const { loadablePatientMeals, children } = props;

  return (
    <LoadablePatientMealsContext.Provider value={loadablePatientMeals}>
      {children}
    </LoadablePatientMealsContext.Provider>
  );
}

export function useLoadablePatientMeals(): LoadablePatientMeals {
  const loadablePatientMeals = useContext(LoadablePatientMealsContext);

  if (loadablePatientMeals === undefined) {
    throw Error(
      "useLoadablePatientMeals must be used within an LoadablePatientMealsProvider"
    );
  }

  return loadablePatientMeals;
}

export function usePatientMeals(): PatientMealDTO[] {
  const loadablePatientMeals = useLoadablePatientMeals();

  if (loadablePatientMeals.state.data === undefined) {
    throw Error("Patient Meals have never been loaded successfully yet.");
  }

  return loadablePatientMeals.state.data;
}

export function useReloadPatientMeals(): () => void {
  const loadablePatientMeals = useLoadablePatientMeals();

  return loadablePatientMeals.reload;
}
