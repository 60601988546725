import styled from "styled-components/macro";
import {
  FoodLoggingSelectedPatientMealFeedbackContainer,
  FoodLoggingSelectedPatientMealFeedbackInputContainer,
} from "../../components/food-logging-selected-patient-meal-feedback/styled-food-logging-selected-patient-meal-feedback";
import { flexCenter } from "../../../../../styles/classes/gloabl-classes";
import { FoodLoggingSidebarWidth } from "../../../constants";
import { SidebarWidth } from "../../../../../styles/global-style";
import { FoodLoggingSelectedPatientMealContainerTitle } from "../../styled-food-logging-selected-patient-meal";

export const CarbCounterFoodLoggingSelectedPatientMealLayoutContainer = styled.div`
  background: ${({ theme }) => theme.colors.bodyColor};
  width: 100%;
  padding: 16px;
`;

export const CarbCounterFoodLoggingSelectedPatientMealLayoutFeedbackContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  position: fixed;
  z-index: 10;
  bottom: 16px;
  right: 350px;
  left: calc(${SidebarWidth} + ${FoodLoggingSidebarWidth} + 250px);

  ${FoodLoggingSelectedPatientMealFeedbackContainer} {
    ${flexCenter}
    gap: 0px 32px;
    margin: 0px;

    ${FoodLoggingSelectedPatientMealContainerTitle} {
      width: 400px;
      margin: 0px;
    }

    ${FoodLoggingSelectedPatientMealFeedbackInputContainer} {
      margin-top: 0px;
    }
  }
`;
