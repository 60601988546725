import { useRef, useState, KeyboardEvent } from "react";
import {
  AutoSelectButton,
  AutoSelectContainer,
  AutoSelectInput,
  AutoSelectList,
  AutoSelectListItem,
} from "./styled-auto-select";
import { useDropdown } from "../dropdown/hooks/use-dropdown";

type AutoSelectProps = {
  value: string | undefined;
  onChange: (value: string) => void;
  itemsArray: Readonly<string[]>;
  fullWidth?: boolean;
  placeHolder?: string;
  disabled?: boolean;
  scrollIntoView?: boolean;
};

export const AutoSelect = (props: AutoSelectProps) => {
  const {
    value,
    onChange,
    itemsArray,
    fullWidth,
    placeHolder,
    disabled,
    scrollIntoView,
  } = props;

  const {
    dropdownContainerRef,
    hideDropdown,
    showDropdown,
    toggleDropdown,
  } = useDropdown();
  const selectedRef = useRef<null | HTMLLIElement>(null);

  function setChange() {
    if (selectedRef?.current) {
      selectedRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  const [activeListItem, setActiveListItem] = useState<number>(0);

  const keyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 38 && activeListItem > 0) {
      // Arrow Up
      setActiveListItem(activeListItem - 1);
    } else if (e.keyCode === 40 && activeListItem < itemsArray.length - 1) {
      // Arrow Down
      setActiveListItem(activeListItem + 1);
    } else if (e.keyCode === 13) {
      // Enter Key
      // setActiveListItem(0);
      hideDropdown();
      onChange(itemsArray[activeListItem]);
    }
  };

  const items = itemsArray
    .filter(
      (item) =>
        value === undefined || item.toLowerCase().includes(value.toLowerCase())
    )
    .map((item: string, index) => {
      if (scrollIntoView !== undefined && scrollIntoView === true) {
        setTimeout(() => {
          setChange();
        });
      }

      return (
        <AutoSelectListItem
          key={index}
          active={activeListItem === index}
          ref={activeListItem === index ? selectedRef : null}
          onClick={() => {
            hideDropdown();
            onChange(item);
          }}
          disabled={disabled}
        >
          {item}
        </AutoSelectListItem>
      );
    });

  return (
    <AutoSelectContainer ref={dropdownContainerRef} fullWidth={fullWidth}>
      <AutoSelectButton showDropdown={showDropdown} onClick={toggleDropdown}>
        <AutoSelectInput
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeHolder}
          onKeyDown={keyDownHandler}
        />
        <span className="material-symbols-outlined">expand_more</span>
      </AutoSelectButton>
      <AutoSelectList showTagList={showDropdown}>{items}</AutoSelectList>
    </AutoSelectContainer>
  );
};
