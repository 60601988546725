import styled from "styled-components/macro";
import { transition } from "../../styles/global-style";
import {
  flexCenter,
  flexCenterSpaceBetween,
  mediumFont,
} from "../../styles/classes/gloabl-classes";
import {
  DropdownButton,
  DropdownList,
  DropdownListItem,
} from "../dropdown/styled-dropdown";

export const PaginationContainer = styled.div`
  ${flexCenterSpaceBetween}
  flex-wrap: wrap;

  ${DropdownButton} {
    border-radius: 8px;
  }

  ${DropdownList} {
    top: unset;
    bottom: 34px;
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  ${DropdownListItem} {
    :last-child {
      border-radius: 0px;
    }
  }
`;

export const PaginationInfo = styled.div`
  ${mediumFont}
  color: ${({ theme }) => theme.colors.textColor};
  display: flex;
  gap: 0rem 1rem;

  @media screen and (max-width: 450px) {
    gap: 0rem 0.5rem;
  }
`;

export const PaginationButtonsList = styled.ul`
  ${flexCenter}
  gap: 0px 4px;

  @media screen and (max-width: 450px) {
    gap: 0rem 0.3rem;
  }
`;

export const PaginationButton = styled.button<{ activePage?: boolean }>`
  background: transparent;
  width: 40px;
  height: 40px;
  ${mediumFont}
  color: ${({ theme, activePage }) =>
    activePage ? theme.colors.primaryColorAlt : theme.colors.textColor};
  border: 1px solid
    ${({ theme, activePage }) =>
      activePage ? theme.colors.primaryColorAlt : theme.colors.borderColor};
  border-radius: 0.5rem;
  transition: ${transition};

  span {
    color: ${({ theme }) => theme.colors.titleColor};
  }

  :disabled {
    opacity: 0.6;
    cursor: not-allowed;

    :hover {
      color: ${({ theme }) => theme.colors.textColor};
      background: transparent;

      span {
        color: ${({ theme }) => theme.colors.titleColor};
      }
    }
  }

  :hover {
    background: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.buttonColor};

    span {
      color: ${({ theme }) => theme.colors.buttonColor};
    }

    @media screen and (max-width: 500px) {
      background: ${({ theme, activePage }) =>
        activePage ? theme.colors.primaryColorAlt : "transparent"};
      color: ${({ theme, activePage }) =>
        activePage ? theme.colors.buttonColor : theme.colors.textColor};
      border: 1px solid
        ${({ theme, activePage }) =>
          activePage ? theme.colors.borderColor : "transparent"};
    }
  }

  @media screen and (max-width: 450px) {
    width: 35px;
    height: 35px;
  }
`;
