import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PatientPlanDTO } from "../../models/patient-plan-dtos/patient-plan-dto";

export function useAddPatientPlan(): (
  patientId: number
) => Promise<PatientPlanDTO> {
  const client = useClient();

  return useCallback(
    async (patientId) => {
      const response = await client.post<PatientPlanDTO>(
        `${PATIENTS_PATH}/${patientId}/plans`
      );

      return response.data;
    },
    [client]
  );
}
