import { createFoodGroupsWithChildren } from "../utils/create-food-groups-with-children";
import { useNutritionReportSelectedTableRows } from "../../../context/nutrition-report-context/nutrition-report-selected-table-rows-context";
import {
  NutritionReportTableHeadersTableTH,
  NutritionReportTableHeadersThContainerTitle,
  NutritionReportTableHeadersThSubContainer,
} from "./styled-nutrition-report-table-headers";
import { useMemo } from "react";
import { usePatientNutritionReportDailySummary } from "../../../context/nutrition-report-context/patient-nutrition-report-daily-summary-context";

export const NutritionReportTableHeaders = () => {
  const { allRowsSelectedFlag, toggleAllRows } =
    useNutritionReportSelectedTableRows();

  const { patientNutritionReportDailySummary } =
    usePatientNutritionReportDailySummary();

  const foodGroupsHeaders = useMemo(
    () =>
      createFoodGroupsWithChildren(
        patientNutritionReportDailySummary.foodGroups
      ).map((item) => (
        <NutritionReportTableHeadersTableTH
          key={item.id}
          type={
            item.name === "Meats"
              ? "Meat"
              : item.name === "Dairy"
              ? "Dairy"
              : item.name === "Oils and Fats"
              ? "Fat"
              : "Normal"
          }
        >
          <div>
            <NutritionReportTableHeadersThContainerTitle>
              {item.name === "Caffeinated Beverages" ? "Caff. Bev." : item.name}
            </NutritionReportTableHeadersThContainerTitle>
            {item.children !== undefined && (
              <NutritionReportTableHeadersThSubContainer>
                {item.children.map((child) => (
                  <p key={child.id}>
                    {child.name === "Oils and Fats (Legacy)"
                      ? "Legacy"
                      : child.name.slice(
                          child.name.indexOf("-") + 1,
                          child.name.length
                        )}
                  </p>
                ))}
              </NutritionReportTableHeadersThSubContainer>
            )}
          </div>
        </NutritionReportTableHeadersTableTH>
      )),
    [patientNutritionReportDailySummary.foodGroups]
  );

  const carbSourcesHeaders = useMemo(
    () =>
      patientNutritionReportDailySummary.carbSources.map((carbSource) => (
        <NutritionReportTableHeadersTableTH type="Normal" key={carbSource.id}>
          <NutritionReportTableHeadersThSubContainer>
            {carbSource.name} Freq.
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
      )),
    [patientNutritionReportDailySummary.carbSources]
  );

  const proteinSourcesHeaders = useMemo(
    () =>
      patientNutritionReportDailySummary.proteinSources.map((proteinSource) => (
        <NutritionReportTableHeadersTableTH
          type="Normal"
          key={proteinSource.id}
        >
          <NutritionReportTableHeadersThSubContainer>
            {proteinSource.name} Freq.
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
      )),
    [patientNutritionReportDailySummary.proteinSources]
  );

  return (
    <thead>
      <tr>
        <NutritionReportTableHeadersTableTH type={"Normal"}>
          <NutritionReportTableHeadersThSubContainer>
            <input
              type="checkbox"
              checked={allRowsSelectedFlag}
              onChange={toggleAllRows}
            />{" "}
            Date
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        <NutritionReportTableHeadersTableTH type={"Normal"}>
          <NutritionReportTableHeadersThSubContainer>
            # Meals
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        {foodGroupsHeaders}
        <NutritionReportTableHeadersTableTH type={"Normal"}>
          <NutritionReportTableHeadersThSubContainer>
            Na
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        <NutritionReportTableHeadersTableTH type={"Normal"}>
          <NutritionReportTableHeadersThSubContainer>
            K
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        <NutritionReportTableHeadersTableTH type={"Normal"}>
          <NutritionReportTableHeadersThSubContainer>
            Kcal
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        <NutritionReportTableHeadersTableTH type={"Normal"}>
          <NutritionReportTableHeadersThSubContainer>
            Carb gm
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        <NutritionReportTableHeadersTableTH type={"Normal"}>
          <NutritionReportTableHeadersThSubContainer>
            Fat gm
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        <NutritionReportTableHeadersTableTH type={"Normal"}>
          <NutritionReportTableHeadersThSubContainer>
            Protein gm
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        <NutritionReportTableHeadersTableTH type={"Normal"}>
          <NutritionReportTableHeadersThSubContainer>
            Carb %
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        <NutritionReportTableHeadersTableTH type={"Normal"}>
          <NutritionReportTableHeadersThSubContainer>
            Fat %
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        <NutritionReportTableHeadersTableTH type={"Normal"}>
          <NutritionReportTableHeadersThSubContainer>
            Protein %
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        {carbSourcesHeaders}
        {proteinSourcesHeaders}
        <NutritionReportTableHeadersTableTH type="Normal">
          <NutritionReportTableHeadersThSubContainer>
            Nuts Freq.
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        <NutritionReportTableHeadersTableTH type="Normal">
          <NutritionReportTableHeadersThSubContainer>
            Carb Sources
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        <NutritionReportTableHeadersTableTH type="Normal">
          <NutritionReportTableHeadersThSubContainer>
            Protein Sources
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
        <NutritionReportTableHeadersTableTH type="Normal">
          <NutritionReportTableHeadersThSubContainer>
            Feedback
          </NutritionReportTableHeadersThSubContainer>
        </NutritionReportTableHeadersTableTH>
      </tr>
    </thead>
  );
};
