import { ReactElement, ReactNode } from "react";
import { NavLink, ScreenNavbar } from "../screen-navbar/screen-navbar";
import {
  ButtonsContainer,
  HeaderTitle,
  HeaderTopContainer,
  ScreenHeaderContainer,
} from "./styled-screen-header";

type ScreenHeaderProps = {
  title: string | ReactElement;
  buttons: ReactNode;
  navLinks: NavLink[];
  flexCol?: boolean;
};

export const ScreenHeader = (props: ScreenHeaderProps) => {
  const { title, buttons, navLinks, flexCol } = props;

  return (
    <ScreenHeaderContainer data-testid="single-patient-header">
      <HeaderTopContainer>
        {typeof title === "string" ? <HeaderTitle>{title}</HeaderTitle> : title}
        <ButtonsContainer flexCol={flexCol}>{buttons}</ButtonsContainer>
      </HeaderTopContainer>
      <ScreenNavbar links={navLinks} />
    </ScreenHeaderContainer>
  );
};
