import styled from "styled-components/macro";
import {
  flexCenter,
  mediumRegularFont,
  mediumSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";

// Parent List
export const PatientProfileTimeLineList = styled.ul`
  margin: 0px 24px;
  padding: 16px 0px 0px;
`;

// Left
export const PatientProfileTimeLineListItem = styled.li`
  ${flexCenter}
  padding: 0px 0px 0px;
`;

export const PatientProfileTimeLineDateContainer = styled.div`
  width: 50px;
  align-self: flex-start;

  p {
    margin: 0px;
    ${mediumSemiBoldFont}
    color: ${({ theme }) => theme.newColors.progressDateTitleColor};
  }

  span {
    ${mediumSemiBoldFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

// Center
export const PatientProfileTimeLineProgressBarContainer = styled.div`
  height: 76px;
  width: 16px;
  margin: 0px 16px 0px 4px;
  display: grid;
  align-content: center;
  gap: 5px 0px;
`;

export const PatientProfileTimeLineProgressBarEllipse = styled.div`
  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.colors.primaryColor};
  width: 10px;
  height: 10px;
  background: ${({ theme }) => theme.colors.containerColor};
  margin: 0px auto;
`;

export const PatientProfileTimeLineProgressBarLine = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.newColors.progressBarLineColor};
  height: 45px;
  width: 4px;
  margin: 0px auto;
`;

// Right
export const PatientProfileTimeLineTextContainer = styled.div`
  align-self: flex-start;

  p {
    margin: 0px;
    color: ${({ theme }) => theme.colors.titleColor};
    font-size: 18px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  }

  span {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.newColors.textColorAlt};
  }
`;
