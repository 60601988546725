import {
  isMedicine,
  PatientServingDTO,
} from "../../../models/patient-serving-dto";
import { Temporal } from "temporal-polyfill";
import { SeriesDataSource } from "./series";
import { MedicinesTooltipProps } from "../tooltips/medicines-tooltip";
import { CommonTooltipProps } from "../tooltips/tooltip";

export function buildMedicinesSeries(
  patientServingDTOs: PatientServingDTO[],
  startPlainDateTime: Temporal.PlainDateTime
): SeriesDataSource {
  const medicines = groupServingsIntoMedicines(patientServingDTOs);

  const series: SeriesDataSource = [];
  for (const medicinesPatientServingDTOs of medicines) {
    const firstPatientServingDTO = medicinesPatientServingDTOs[0];

    const dateTime = Temporal.PlainDateTime.from(
      `${firstPatientServingDTO.date}T${firstPatientServingDTO.time}`
    );

    const x = dateTime.since(startPlainDateTime).total({ unit: "minutes" });
    const y = 100;
    const tooltip: CommonTooltipProps & {
      kind: "medicines";
      medicinesTooltipProps: MedicinesTooltipProps;
    } = {
      x,
      y,
      kind: "medicines",
      medicinesTooltipProps: {
        patientServingDTOs: medicinesPatientServingDTOs,
      },
    };

    // Medicines
    series.push({ x, y, tooltip: tooltip });

    // Gap
    series.push({ x });
  }

  return series;
}

function groupServingsIntoMedicines(patientServingDTOs: PatientServingDTO[]) {
  const medicines: Array<[PatientServingDTO, ...PatientServingDTO[]]> = [];
  let lastPlainDateTime: Temporal.PlainDateTime | undefined = undefined;
  for (const patientServingDTO of patientServingDTOs) {
    if (!isMedicine(patientServingDTO)) {
      continue;
    }

    const currentDateTime = Temporal.PlainDateTime.from(
      `${patientServingDTO.date}T${patientServingDTO.time}`
    );

    if (currentDateTime !== lastPlainDateTime) {
      medicines.push([patientServingDTO]);
    } else {
      medicines[medicines.length - 1].push(patientServingDTO);
    }

    lastPlainDateTime = currentDateTime;
  }
  return medicines;
}
