import { usePatientUser } from "../context/auth-context/auth-context";
import { Outlet } from "react-router-dom";
import { useGetPatientCGMEntries } from "../hooks/use-get-patient-cgm-entries";
import { useGetPatientServings } from "../hooks/use-get-patient-servings";
import { useGetPatientMedications } from "../hooks/use-get-patient-medications";
import { useGetPatientLabTestResults } from "../hooks/patient-lab-test-result-hooks/use-get-patient-lab-test-results";
import { useGetLabTestCategories } from "../hooks/lab-test-categories/use-get-lab-test-categories";
import { useGetLabTestTypes } from "../hooks/lab-test-types/use-get-lab-test-types";
import { useGetLabTestTypeRefs } from "../hooks/lab-test-type-refs/use-get-lab-test-type-refs";
import { useGetPatientWeights } from "../hooks/patient-weights-hooks/use-get-patient-weights";
import { useGetPatientPlans } from "../hooks/patient-plan-hooks/use-get-patient-plans";
import { useCallback } from "react";
import { useLoadableData } from "../hooks/use-loadable-data";
import { LoadablePatientProvider } from "../screens/single-patient/context/loadable-patient-context";
import { LoadableDataLoading } from "../components/loadable-data-loading";
import { PatientDTO } from "../models/patient-dtos/patient-dto";
import { useGetFrequencies } from "../hooks/frequencies/use-get-frequencies";
import { useGetPatientMeals } from "../hooks/patient-meal-hooks/use-get-patient-meals";

export function PatientHome() {
  const { patientId, patientName, patientType } = usePatientUser();

  const getPatientCGMEntries = useGetPatientCGMEntries();
  const getPatientServings = useGetPatientServings();
  const getMedciations = useGetPatientMedications();
  const getLabTestsResults = useGetPatientLabTestResults();
  const getLabTestCategories = useGetLabTestCategories();
  const getLabTestTypes = useGetLabTestTypes();
  const getLabTestTypeRefs = useGetLabTestTypeRefs();
  const getPatientWeights = useGetPatientWeights();
  const getFrequencies = useGetFrequencies();
  const getPatientPlans = useGetPatientPlans();
  const getPatientMeals = useGetPatientMeals();

  const load = useCallback(async () => {
    const [
      patientDTO,
      patientCGMEntryDTOs,
      patientServingDTOs,
      patientLabTestResultsDTOs,
      labTestCategoryDTOs,
      labTestTypeDTOs,
      labTestTypeRefDTOs,
      patientMedicationDTOs,
      patientWeightDTOs,
      frequencyDTOs,
      patientPlans,
      patientMealDTOs,
    ] = await Promise.all([
      Promise.resolve<PatientDTO>({
        id: patientId,
        name: patientName,
        type: patientType,
      }),
      getPatientCGMEntries(patientId),
      getPatientServings(patientId),
      getLabTestsResults(patientId),
      getLabTestCategories(),
      getLabTestTypes(),
      getLabTestTypeRefs(),
      getMedciations(patientId),
      getPatientWeights(patientId),
      getFrequencies(),
      getPatientPlans(patientId),
      getPatientMeals(patientId),
    ]);

    return {
      patientDTO,
      patientCGMEntryDTOs,
      patientServingDTOs,
      patientLabTestResultsDTOs,
      labTestCategoryDTOs,
      labTestTypeDTOs,
      labTestTypeRefDTOs,
      patientMedicationDTOs,
      patientWeightDTOs,
      frequencyDTOs,
      patientPlans,
      patientMealDTOs,
    };
  }, [
    patientId,
    patientName,
    patientType,
    getPatientCGMEntries,
    getPatientServings,
    getMedciations,
    getLabTestsResults,
    getLabTestCategories,
    getLabTestTypes,
    getLabTestTypeRefs,
    getPatientWeights,
    getFrequencies,
    getPatientPlans,
    getPatientMeals,
  ]);

  const loadablePatient = useLoadableData(load);

  return (
    <div>
      <LoadablePatientProvider loadablePatient={loadablePatient}>
        <LoadableDataLoading
          state={loadablePatient.state}
          successComponent={() => {
            return <Outlet />;
          }}
        />
      </LoadablePatientProvider>
    </div>
  );
}
