import { AddNewLabResult } from "../../../add-new-lab-result/add-new-lab-result";
import { BulkLabResultsModal } from "../../../bulk-lab-results-modal/bulk-lab-results-modal";
import { LabResultTestButtonsContainer } from "./styled-lab-result-test-buttons";

export const LabResultTestButtons = () => {
  return (
    <LabResultTestButtonsContainer>
      <AddNewLabResult />
      <BulkLabResultsModal />
    </LabResultTestButtonsContainer>
  );
};
