import { Medicine } from "../../build-days-aggregates";
import { Temporal } from "temporal-polyfill";

export function medicineToHtmlTooltip(medicine: Medicine): string {
  const timeHtml = medicineTimeToHtml(medicine.time);

  const divStyle = `
    width: 300px;
    background: #fff;
    box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0) !important;
    border: 1px solid rgba(255, 0, 0 ,0) !important;
  `;

  const medicineItemsStyle = `
    padding: 1rem 1rem 1.5rem;
  `;

  const titleStyle = `
    font-size: 1.1rem;
    font-weight: 700;
    color: #040415;
    margin: 0rem;
  `;

  const medicineNameStyle = `
    font-size: 13px;
    font-weight: 500;
    color: #040415;
  `;

  const medicineAmountStyle = `
    color: #040415;
    font-size: 14px;
    font-weight: 700;
    float: right;
  `;

  const medicineItemsHtml =
    `<div style='${medicineItemsStyle}'>
        <strong style='${titleStyle}'>Medications</strong>` +
    medicine.items
      .map(
        (item) =>
          `
               <br /><span>
                 <br /><span style='${medicineNameStyle}'>${item.name}</span>
                 <span style='${medicineAmountStyle}'>${item.amount}</span>
               </span>
             `
      )
      .join() +
    "</div>";

  return `
    <div style='${divStyle}'>
      ${timeHtml}
      ${medicineItemsHtml}
    </div>`;
}

function medicineTimeToHtml(time: Temporal.PlainTime): string {
  const topContainerStyle = `
    background: #eaf7ff;
    border-bottom: 1px solid #ecedef;
    padding: 1rem;
  `;

  const timeContainerStyle = `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  `;

  const titleStyle = `
    font-size: 1.1rem;
    font-weight: 700;
    color: #040415;
    margin: 0;
  `;

  const timeTextStyle = `
    font-size: 1rem;
    font-weight: 400;
    color: #8c8e97;
  `;

  return `
  <div style='${topContainerStyle}'>
    <div style="${timeContainerStyle}">
      <p style='${titleStyle}'>Medication log</p>
      <span style='${timeTextStyle}'>${time.toLocaleString("en", {
    hour12: true,
    second: undefined,
  })}</span>
    </div>
  </div>
  `;
}
