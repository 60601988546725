import { memo, ReactNode } from "react";
import { Temporal } from "temporal-polyfill";
import { GlucoseChart } from "../../../../../components/glucose-chart/glucose-chart";
import {
  DailyMealGraphsCard,
  DailyMealGraphsCardChartContainer,
  DailyMealGraphsCardDate,
  DailyMealGraphsCardDateContainer,
  DailyMealGraphsCardHeader,
  DailyMealGraphsCardsContainer,
  DailyMealGraphsCardTitle,
} from "./styled-daily-meal-graphs-cards";
import { patientMealResponse } from "./daily-meal-graphs-cards";

type DailyMealGraphsResponseProps = {
  patientMealResponse: patientMealResponse;
  hasToolTip: boolean;
  smallModalGraphs: boolean;
  viewPlanGraphs?: boolean;
  button?: (date: string, time: string) => ReactNode;
};

export const DailyMealGraphsResponse = memo(
  (props: DailyMealGraphsResponseProps) => {
    const {
      patientMealResponse: mealResponse,
      hasToolTip,
      smallModalGraphs,
      button,
      viewPlanGraphs,
    } = props;

    return (
      <DailyMealGraphsCardsContainer
        viewPlanGraphs={viewPlanGraphs}
        smallModalGraphs={viewPlanGraphs ? false : smallModalGraphs}
      >
        {mealResponse.patientMealResponses.map((patientMealResponse) => {
          const date = Temporal.PlainDate.from(patientMealResponse.date);
          return (
            <div key={`${date.toString()}-${patientMealResponse.time}`}>
              <DailyMealGraphsCardDateContainer>
                <DailyMealGraphsCardDate
                  smallModalGraphs={viewPlanGraphs ? false : smallModalGraphs}
                >
                  <span className="material-symbols-outlined">schedule</span>
                  {date
                    .toPlainDateTime(patientMealResponse.time)
                    .toLocaleString("en-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    })}
                </DailyMealGraphsCardDate>
                {button !== undefined ? (
                  button(patientMealResponse.date, patientMealResponse.time)
                ) : (
                  <></>
                )}
              </DailyMealGraphsCardDateContainer>
              <DailyMealGraphsCard
                smallModalGraphs={viewPlanGraphs ? false : smallModalGraphs}
                viewPlanGraphs={viewPlanGraphs}
              >
                <DailyMealGraphsCardHeader
                  smallModalGraphs={viewPlanGraphs ? false : smallModalGraphs}
                >
                  {patientMealResponse.mealServings.map((serving, index) => {
                    return (
                      <DailyMealGraphsCardTitle
                        key={index}
                        smallModalGraphs={
                          viewPlanGraphs ? false : smallModalGraphs
                        }
                      >
                        {serving.foodName} <span>{serving.amount}</span>
                      </DailyMealGraphsCardTitle>
                    );
                  })}
                </DailyMealGraphsCardHeader>
                <DailyMealGraphsCardChartContainer
                  smallModalGraphs={viewPlanGraphs ? false : smallModalGraphs}
                >
                  <GlucoseChart
                    startPlainDateTime={date.toPlainDateTime(
                      Temporal.PlainTime.from(patientMealResponse.time)
                    )}
                    patientDTO={mealResponse.patientDTO}
                    patientServingDTOs={patientMealResponse.mealServings.concat(
                      patientMealResponse.postMealServings
                    )}
                    patientCGMEntryDTOs={patientMealResponse.readings}
                    height={smallModalGraphs ? "200px" : "300px"}
                    hasToolTip={hasToolTip}
                    smallModalGraphs={viewPlanGraphs ? false : smallModalGraphs}
                  />
                </DailyMealGraphsCardChartContainer>
              </DailyMealGraphsCard>
            </div>
          );
        })}
      </DailyMealGraphsCardsContainer>
    );
  }
);
