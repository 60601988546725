import styled from "styled-components/macro";
import { NewPrescriptionHeaderHeight } from "../../constants/prescription-constants";

export const AddedPrescriptionMedicinesContainer = styled.div<{
  calculateHeight: "0px" | "130px";
}>`
  margin-top: calc(${NewPrescriptionHeaderHeight} + 16px);
  margin-bottom: 16px;
  min-height: ${({ calculateHeight }) => calculateHeight};
  height: fit-content;
`;

export const AddedPrescriptionMedicinesTitle = styled.p`
  font-size: 16px;
  font-weight: 450;
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0px 0px 8px 30px;
`;

export const AddedPrescriptionMedicinesCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
  margin-left: 24px;
  gap: 12px;
`;
