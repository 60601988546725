import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
} from "../../../../../styles/classes/gloabl-classes";

export const PatientProfileInformationHeaderImageContainer = styled.div`
  position: relative;
  width: 160px;
  height: 170px;

  @media screen and (max-width: 1199px) {
    width: 50%;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 580px) {
    width: 80%;
  }
`;

export const PatientProfileInformationHeaderImageWrapper = styled.div`
  height: 160px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.bodyColor};
  ${flexCenterCenter}

  span {
    font-size: 100px;
  }

  img {
    width: 155px;
  }
`;

export const PatientProfileInformationHeaderImageSmkOrAlcContainer = styled.div`
  ${flexCenter}
  justify-content: center;
  gap: 0px 8px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 2px solid ${({ theme }) => theme.colors.containerColor};

  img {
    width: 50px;
    height: 50px;

    @media screen and (max-width: 1300px) {
      width: 45px;
      height: 45px;
    }

    @media screen and (max-width: 1199px) {
      width: 60px;
      height: 60px;
    }
  }
`;
