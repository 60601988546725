import styled from "styled-components/macro";
import { TimeLineDateInputContainerCSS } from "../styled-patient-profile-edit-timeline-modal-body";
import { PatientProfileModalInputContainer } from "../../../../../styles/patient-profile-styles/patient-profile-classes";

export const AnginaDateInputContainer = styled.div`
  ${TimeLineDateInputContainerCSS}

  ${PatientProfileModalInputContainer} {
    margin-bottom: 0px;
    margin-left: 40px;
  }
`;
