import styled from "styled-components/macro";
import { lgSemiBoldFont } from "../../../styles/classes/gloabl-classes";

export const HomeHeaderContainer = styled.header`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 16px 24px 0px;

  h1 {
    margin: 0px 0px 24px;
    ${lgSemiBoldFont}
    color: ${({ theme }) => theme.colors.titleColor};
    text-transform: capitalize;
  }

  ul {
    margin: 0px;
  }
`;

export const UnderDevelopmentTag = styled.div`
  background: ${({ theme }) => theme.colors.success};
  color: ${({ theme }) => theme.colors.containerColor};
  font-size: 28px;
  text-align: center;
  padding: 8px;
  width: 520px;
  position: fixed;
  z-index: 500;
  right: -110px;
  top: 110px;
  transform: rotate(45deg);
`;
