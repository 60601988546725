import { PatientMealTag } from "../../../../../../../../models/patient-meal-dtos/patient-meal-tag";
import { MealsAnalysisResponseTableCell } from "./styled-meals-analysis-response-table-cells";

type MealsAnalysisResponseTypeTableCellProps = {
  patientMealTag?: PatientMealTag;
};

export const MealsAnalysisResponseTypeTableCell = (
  props: MealsAnalysisResponseTypeTableCellProps
) => {
  const { patientMealTag } = props;

  return (
    <MealsAnalysisResponseTableCell $typeCell>
      {patientMealTag !== undefined ? patientMealTag : "N/A"}
    </MealsAnalysisResponseTableCell>
  );
};
