export function getDateRange(startDate: Date, endDate: Date) {
  const dates = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

export function dateRanges(startDate: Date, endDate: Date) {
  return getDateRange(startDate, endDate).map((item) => {
    const date = new Date(item);
    const isoDateString = date.toISOString();
    const dateOnlyString = isoDateString.slice(0, 10);

    return {
      date: dateOnlyString,
      mins: 0,
    };
  });
}
