import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  mediumRegularFont,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const NutritionReportSummaryHeaderContainer = styled.div`
  ${flexCenterSpaceBetween}
  margin: 0px 24px;
`;

export const NutritionReportSummaryHeaderPatientInfoContainer = styled.div`
  p {
    ${regularMediumFont}
    margin: 0px;
    color: ${({ theme }) => theme.colors.titleColor};
  }

  span {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
