import styled from "styled-components/macro";
import {
  Input,
  InputContainer,
  InputLabel,
} from "../../../../styles/classes/reusable-classes";
import {
  flexCenter,
  mediumFont,
} from "../../../../styles/classes/gloabl-classes";

export const OrganizationDetailsDetailsContainer = styled.div`
  max-width: 500px;

  ${InputContainer} {
    ${flexCenter}
    flex-direction: row;

    div {
      ${flexCenter}
      gap: 0px 8px;

      ${InputLabel} {
        width: 150px;
        ${mediumFont}
        margin: 0px;
        margin-right: 8px;
      }
    }
  }

  ${Input} {
    background: ${({ theme }) => theme.colors.inputColor};
    border: 0px;
  }
`;
