import styled from "styled-components/macro";
import {
  PatientProfileEditModalFormControlContainerCSSType,
  PatientProfileModalBodyContainerCSSType,
  PatientProfileModalInputContainerCSSType,
  PatientProfileSectionCSSType,
} from "./patient-profile-css-types";
import {
  PaitnetProfileChartContainerCSS,
  PatientProfileAutoSelectContainerCSS,
  PatientProfileAutoSelectFormControlContainerCSS,
  PatientProfileContainerBodyCSS,
  PatientProfileContainerCSS,
  PatientProfileModalBodyContainerCSS,
  PatientProfileModalFormControlCSS,
  PatientProfileModalFormControlFlexNoStylesCSS,
  PatientProfileModalInputInputContainerCSS,
  PatientProfileModalLabelContainerCSS,
  PatientProfileSectionCSS,
  PatientProfileTagListCSS,
  PatientProfileTagListItemCSS,
  PatientProfileTagsLabelContainerCSS,
} from "./patient-profile-css";

export const PatientProfileSection = styled.section<PatientProfileSectionCSSType>`
  ${PatientProfileSectionCSS}
`;

export const PatientProfileContainerBody = styled.div`
  ${PatientProfileContainerBodyCSS}
`;

export const PatientProfileContainer = styled.div`
  ${PatientProfileContainerCSS}
`;

export const PaitnetProfileChartContainer = styled.div`
  ${PaitnetProfileChartContainerCSS}
`;

// Modals
export const PatientProfileModalBodyContainer = styled.div<PatientProfileModalBodyContainerCSSType>`
  ${PatientProfileModalBodyContainerCSS}
`;

export const PatientProfileModalFormControl = styled.div<PatientProfileEditModalFormControlContainerCSSType>`
  ${PatientProfileModalFormControlCSS}
`;

export const PatientProfileModalFormControlFlexNoStyles = styled.div`
  ${PatientProfileModalFormControlFlexNoStylesCSS}
`;

export const PatientProfileModalLabelContainer = styled.div`
  ${PatientProfileModalLabelContainerCSS}
`;

export const PatientProfileAutoSelectFormControlContainer = styled.div`
  ${PatientProfileAutoSelectFormControlContainerCSS}
`;

export const PatientProfileAutoSelectContainer = styled.div`
  ${PatientProfileAutoSelectContainerCSS}
`;

export const PatientProfileTagsLabelContainer = styled.div`
  ${PatientProfileTagsLabelContainerCSS}
`;

export const PatientProfileTagList = styled.ul`
  ${PatientProfileTagListCSS}
`;

export const PatientProfileTagListItem = styled.li`
  ${PatientProfileTagListItemCSS}
`;

export const PatientProfileModalInputContainer = styled.div<PatientProfileModalInputContainerCSSType>`
  ${PatientProfileModalInputInputContainerCSS}
`;
