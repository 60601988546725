import { viewChronicDisease } from "../../../../patient-profile/utils/patient-profile-utils";
import { useSinglePatientSidebar } from "../../../context/loadable-single-patient-sidebar-context";
import {
  PersonalInfoGroup,
  PersonalInfoGroupBottom,
  PersonalInfoGroupTitle,
  PersonalInfoGroupTop,
  PersonalInfoItem,
} from "../../personal-info-tab/styled-personal-info-tab";

export const ChronicDiseases = () => {
  const { patientMedicalHistoryDTO } = useSinglePatientSidebar();

  const choronicDiseases = patientMedicalHistoryDTO.chronicDiseases.map(
    (disease, index) => (
      <PersonalInfoItem key={index}>
        {viewChronicDisease(disease.chronicDisease)}
      </PersonalInfoItem>
    )
  );

  return (
    <PersonalInfoGroup>
      <PersonalInfoGroupTop>
        <PersonalInfoGroupTitle>Chronic Diseases</PersonalInfoGroupTitle>
      </PersonalInfoGroupTop>
      <PersonalInfoGroupBottom>{choronicDiseases}</PersonalInfoGroupBottom>
    </PersonalInfoGroup>
  );
};
