import { Button } from "../../../../../styles/classes/reusable-classes";
import { usePlansGoals } from "../../context/goals-context";
import { PlanStepInfo } from "../../components/plan-step-info/plan-step-info";
import { GoalsListItem } from "../styles";
import { ContainerTitle, NewGoalsContainer } from "./styled-goals";

type SelectGoalsListProps = {
  searchTerm: string;
};

export const SelectGoalsList = (props: SelectGoalsListProps) => {
  const { searchTerm } = props;
  const { selectGoalsList, handleAddGoal } = usePlansGoals();

  return (
    <NewGoalsContainer>
      <ContainerTitle>Select new goals</ContainerTitle>
      {selectGoalsList
        .sort((a, b) => {
          if (a.goal > b.goal) {
            return 1;
          } else {
            return -1;
          }
        })
        .filter((item) => {
          if (searchTerm === "") {
            return item;
          } else if (
            item.goal.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return item;
          } else {
            return 0;
          }
        })
        .map((goal, index) => (
          <GoalsListItem key={index}>
            <PlanStepInfo
              arabicFlex
              icon="/img/plans/nav/goals.svg"
              title={goal.goal}
              subTitle={`Assigned for weeks: (${goal.patientPlans
                .map((plan) => plan.planNumber)
                .join(", ")})`}
            />
            <Button
              flex
              type="button"
              outlinedPrimary
              onClick={() =>
                handleAddGoal({ goal: goal.goal, goalType: "Goal" })
              }
            >
              <span className="material-symbols-outlined">add</span>
              Add
            </Button>
          </GoalsListItem>
        ))}
    </NewGoalsContainer>
  );
};
