import { useForm } from "react-hook-form";
import { useImportNewCGMEntriesFromLibreview } from "../../../../../../hooks/use-import-new-cgm-entries-from-libreview";
import { useApiWithFullPageLoader } from "../../../../../../hooks/use-api-with-full-page-loader";
import {
  ErrorText,
  Input,
  InputContainer,
  InputLabel,
  ModalFooter,
  PaddingTop,
} from "../../../../../../styles/classes/reusable-classes";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { ModalHeader } from "../../../../../../components/modal/modal-header/modal-header";
import { ModalForm } from "../../../../../../components/modal/modal-form/modal-form";
import { useToastService } from "../../../../../../context/toast-service-context";
import { useParams } from "react-router-dom";
import { useGetPatientDto } from "../../../../../../hooks/use-get-patient-dto";

export type ImportLibreviewNewReadingsModalProps = {
  onModalClose: () => void;
};

type Inputs = {
  authTicket: string;
};

export function ImportLibreviewNewReadingsModal(
  props: ImportLibreviewNewReadingsModalProps
) {
  const { onModalClose } = props;

  const importNewCGMEntriesFromLibreview = useApiWithFullPageLoader(
    useImportNewCGMEntriesFromLibreview()
  );

  const { id } = useParams();
  const patientID = parseInt(id!);

  const { patientDTO } = useGetPatientDto(patientID);

  const { showToast } = useToastService();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = handleSubmit(async (inputs) => {
    const importNewPatientCGMEntriesFromLibreviewDTO = {
      authToken: inputs.authTicket,
    };

    if (patientDTO !== undefined) {
      try {
        await importNewCGMEntriesFromLibreview(
          patientDTO,
          importNewPatientCGMEntriesFromLibreviewDTO
        );
        showToast("Success", "Libreview new readings imported successfully!");
        onModalClose();
      } catch (e) {
        showToast(
          "Error",
          "Failed to import Libreview new readings. Perhaps you forgot to set patient's Libreview ID?"
        );
      }
    }
  });

  return (
    <ModalForm onSubmit={onSubmit}>
      <ModalHeader title="Libreview New Readings" onModalClose={onModalClose} />
      <PaddingTop>
        <InputContainer>
          <InputLabel>Libreview Auth Ticket</InputLabel>
          <Input
            error={errors.authTicket?.type === "required" && true}
            {...register("authTicket", { required: true })}
          />
          {errors.authTicket?.type === "required" && (
            <ErrorText>Libreview Auth Ticket is required</ErrorText>
          )}
        </InputContainer>
      </PaddingTop>
      <ModalFooter>
        <Button outlined onClick={onModalClose} type="button">
          Cancel
        </Button>
        <Button type="submit">Import</Button>
      </ModalFooter>
    </ModalForm>
  );
}
