import {
  PatientProfileTagList,
  PatientProfileTagListItem,
} from "../../styles/patient-profile-styles/patient-profile-classes";
import { usePatientProfileInformationHeader } from "../context/loadable-patient-profile-information-header-context";
import { PatientProfileEditProfileModal } from "../patient-profile-edit-profile-modal/patient-profile-edit-profile-modal";
import {
  PatientProfileInformationHeaderDiagnosisContainer,
  PatientProfileInformationHeaderOwnDiagnosis,
} from "./styled-patient-profile-information-header-diagnosis";

export const PatientProfileInformationHeaderDiagnosis = () => {
  const {
    patientHealthBarriers,
    patientProfileDiagnosis,
    patientDiagnosisDTO,
  } = usePatientProfileInformationHeader();

  const isDefinedPatientDiagnosisDTO = patientDiagnosisDTO !== undefined;

  return (
    <PatientProfileInformationHeaderDiagnosisContainer>
      <PatientProfileEditProfileModal />
      <PatientProfileInformationHeaderOwnDiagnosis>
        <p>Otida Diagnosis</p>
        <PatientProfileTagList>
          {patientProfileDiagnosis.map((patientProfileDiagnose) => (
            <PatientProfileTagListItem
              key={patientProfileDiagnose.id}
              variant="Diagnosis"
            >
              {patientProfileDiagnose.profileDiagnosis.name}
            </PatientProfileTagListItem>
          ))}
        </PatientProfileTagList>
      </PatientProfileInformationHeaderOwnDiagnosis>
      <PatientProfileInformationHeaderOwnDiagnosis>
        <p>Health barriers</p>
        <PatientProfileTagList>
          {patientHealthBarriers.map((patientHealthBarrier) => (
            <PatientProfileTagListItem
              key={patientHealthBarrier.id}
              variant="HelthBarrier"
            >
              {patientHealthBarrier.healthBarrier.name}
            </PatientProfileTagListItem>
          ))}
        </PatientProfileTagList>
      </PatientProfileInformationHeaderOwnDiagnosis>
      {isDefinedPatientDiagnosisDTO && (
        <PatientProfileInformationHeaderOwnDiagnosis>
          <p>Old Diagnosis</p>
          <PatientProfileTagList>
            {patientDiagnosisDTO.tags.map((tag, index) => (
              <PatientProfileTagListItem key={index} variant="Diagnosis">
                {tag}
              </PatientProfileTagListItem>
            ))}
          </PatientProfileTagList>
        </PatientProfileInformationHeaderOwnDiagnosis>
      )}
    </PatientProfileInformationHeaderDiagnosisContainer>
  );
};
