import { useNavigate } from "react-router-dom";
import { Button } from "../../../../../styles/classes/reusable-classes";
import {
  DietitianReportTabFooterButtons,
  DietitianReportTabFooterContainer,
} from "../../components/reusable-styles";
import { PatientDTO } from "../../../../../models/patient-dtos/patient-dto";

type AnalyticsAndReportsFooterProps = {
  patientDTO: PatientDTO;
};

export const AnalyticsAndReportsFooter = (
  props: AnalyticsAndReportsFooterProps
) => {
  const { patientDTO } = props;
  const navigate = useNavigate();

  return (
    <DietitianReportTabFooterContainer>
      <DietitianReportTabFooterButtons>
        <Button
          outlined
          type="button"
          onClick={() =>
            navigate(
              `/food-logging/food-logging-nutrition-report/${patientDTO.id}`
            )
          }
        >
          Generate Report
        </Button>
      </DietitianReportTabFooterButtons>
    </DietitianReportTabFooterContainer>
  );
};
