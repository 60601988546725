import styled from "styled-components/macro";
import {
  smallRegularFont,
  smallSemiBoldFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const NutritionReportSummaryHeaderDatesRangeContainer = styled.div<{
  viewReportUI?: boolean;
}>`
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  gap: 8px 16px;
  width: 60%;
  margin-left: 16px;
`;

export const NutritionReportSummaryHeaderDatesRangeText = styled.p<{
  selected: boolean;
}>`
  border-radius: 94px;
  ${({ selected }) => (selected ? smallSemiBoldFont : smallRegularFont)};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.primaryColorAlt : theme.colors.textColor};
  margin: 0px;
`;
