import { GenericActionsListItemItem } from "../../../../../../components/generic-actions-list/styled-generic-actions-list";
import { Modal } from "../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { EditChannelModalBody } from "./edit-channel-modal-body/edit-channel-modal-body";

export const EditChannelModal = () => {
  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <>
      <GenericActionsListItemItem onClick={openModal}>
        Edit Group
      </GenericActionsListItemItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <EditChannelModalBody key={modalBodyKey} onClose={closeModal} />
      </Modal>
    </>
  );
};
