import styled from "styled-components/macro";

export const DateInputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const DateInputContainer = styled.div`
  background: ${({ theme }) => theme.colors.inputColor};
  width: 300px;
  height: 40px;
  border-radius: 8px;
  padding: 8px 16px;
  margin-left: 49px;

  input {
    border: 0;
    outline: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }
`;
