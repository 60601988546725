import { EmptyState } from "../../../components/empty-state/empty-state";
import { FoodLoggingEmptyStateContainer } from "./styled-food-logging-empty-state";

export const FoodLoggingEmptyState = () => {
  return (
    <FoodLoggingEmptyStateContainer>
      <EmptyState
        button={<></>}
        hasHeader={false}
        hasSearch={false}
        title=""
        text="Select a patient to preview meals"
      />
    </FoodLoggingEmptyStateContainer>
  );
};
