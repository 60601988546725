export const PATIENT_TYPES = [
  "Type1",
  "Type2",
  "Type1Fragile",
  "Type2Fragile",
  "NormalOrHealthy",
  "Prediabetic",
  "Type1Pregnant",
  "Type2Pregnant",
  "Gestational",
  "Type1Pediatric",
] as const;

export type PatientType = typeof PATIENT_TYPES[number];
