import { MarkerSettingsModel } from "@syncfusion/ej2-react-charts";
import { MarkerTemplateBGMContainer } from "./styled-marker-template";
import { BGMReadingDataSource } from "../../series/build-bgm-readings-series";

export function buildBgmMarkerTemplate(): MarkerSettingsModel {
  const template: any = markerTemplate;

  return {
    visible: true,
    border: { width: 0 },
    shape: "Image",
    imageUrl: "/img/readings.svg",
    width: 20,
    height: 20,
    dataLabel: {
      visible: true,
      template: template,
    },
  };
}

function markerTemplate(args: any) {
  const dataSource: BGMReadingDataSource[] = args.series.properties.dataSource;

  const findCurrentDataSource = dataSource.find(
    (item) => item.y === args.point.y
  );

  if (
    findCurrentDataSource &&
    findCurrentDataSource.tooltip.kind.kind === "Reading"
  ) {
    const reading = findCurrentDataSource?.tooltip.kind.data;

    return (
      <MarkerTemplateBGMContainer>
        <p>
          {reading.glucoseMGPerDL} <span>mg/dl</span>
        </p>
      </MarkerTemplateBGMContainer>
    );
  }

  return <></>;
}
