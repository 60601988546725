import styled from "styled-components";

export const MealsAnalysisResponseTableHeadersHead = styled.thead`
  background: ${({ theme }) => theme.colors.bodyColor};

  th {
    text-align: center;
    padding: 8px;
  }
`;
