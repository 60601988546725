import { PropsWithChildren, createContext, useContext } from "react";
import { PatientDTO } from "../../../../../models/patient-dtos/patient-dto";
import { PatientWeightDTO } from "../../../../../models/patient-weight-dtos/patient-weight-dto";
import { PatientBloodPressureDTO } from "../../../../../models/patient-blood-pressure-dtos/patient-blood-pressure-dto";
import { MaritalStatusDTO } from "../../../../../models/marital-status-dtos/marital-status-dto";
import { PatientProfileDiagnosisDTO } from "../../../../../models/patient-profile-diagnosis-dtos/patient-profile-diagnosis-dto";
import { PatientDiagnosisDTO } from "../../../../../models/patient-diagnosis-dtos/patient-diagnosis-dto";
import { ProfileDiagnosisDTO } from "../../../../../models/profile-diagnosis-dtos/profile-diagnosis-dto";
import { PatientHealthBarrierDTO } from "../../../../../models/patient-health-barrier-dtos/patient-health-barrier-dto";
import { LabTestTypeDTO } from "../../../../../models/lab-test-types/lab-test-type-dto";
import { PatientLabTestResultDTO } from "../../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import { HealthBarrierDTO } from "../../../../../models/health-barrier-dtos/health-barrier-dto";
import { UseLoadableDataReturn } from "../../../../../hooks/use-loadable-data";

export type PatientProfileInformationHeader = {
  patientDTO: PatientDTO;
  patientBloodPressureDTOs: PatientBloodPressureDTO[];
  patientWeightDTOs: PatientWeightDTO[];
  MaritalStatusDTOs: MaritalStatusDTO[];
  patientDiagnosisDTO?: PatientDiagnosisDTO;
  patientProfileDiagnosis: PatientProfileDiagnosisDTO[];
  profileDiagnosis: ProfileDiagnosisDTO[];
  patientHealthBarriers: PatientHealthBarrierDTO[];
  labTestTypeDTOs: LabTestTypeDTO[];
  patientLabTestResultsDTOs: PatientLabTestResultDTO[];
  healthBarriers: HealthBarrierDTO[];
};

export type LoadablePatientProfileInformationHeader =
  UseLoadableDataReturn<PatientProfileInformationHeader>;

const LoadablePatientProfileInformationHeaderContext = createContext<
  LoadablePatientProfileInformationHeader | undefined
>(undefined);

export type LoadablePatientProfileInformationHeaderProviderProps =
  PropsWithChildren<{
    loadablePatientProfileInformationHeader: LoadablePatientProfileInformationHeader;
  }>;

export function LoadablePatientProfileInformationHeaderProvider(
  props: LoadablePatientProfileInformationHeaderProviderProps
) {
  const { loadablePatientProfileInformationHeader, children } = props;

  return (
    <LoadablePatientProfileInformationHeaderContext.Provider
      value={loadablePatientProfileInformationHeader}
    >
      {children}
    </LoadablePatientProfileInformationHeaderContext.Provider>
  );
}

export function useLoadablePatientProfileInformationHeader(): LoadablePatientProfileInformationHeader {
  const loadablePatientProfileInformationHeader = useContext(
    LoadablePatientProfileInformationHeaderContext
  );

  if (loadablePatientProfileInformationHeader === undefined) {
    throw Error(
      "useLoadablePatientProfileInformationHeader must be used within an LoadablePatientProfileInformationHeaderProvider"
    );
  }

  return loadablePatientProfileInformationHeader;
}

export function usePatientProfileInformationHeader(): PatientProfileInformationHeader {
  const loadablePatientProfileInformationHeader =
    useLoadablePatientProfileInformationHeader();

  if (loadablePatientProfileInformationHeader.state.data === undefined) {
    throw Error("Patient Profile have never been loaded successfully yet.");
  }

  return loadablePatientProfileInformationHeader.state.data;
}

export function useReloadPatientProfileInformationHeader(): () => void {
  const loadablePatientProfileInformationHeader =
    useLoadablePatientProfileInformationHeader();

  return loadablePatientProfileInformationHeader.reload;
}
