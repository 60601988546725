import styled from "styled-components/macro";
import {
  flexCenterCenter,
  regularMediumFont,
} from "../../../styles/classes/gloabl-classes";
import { transition } from "../../../styles/global-style";

export const OtpInputsContainer = styled.div`
  ${flexCenterCenter}
  gap: 0px 12px;
`;

export const OtpInput = styled.input`
  width: 50px;
  height: 50px;
  border: 0px;
  outline: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  background: ${({ theme }) => theme.newColors.otpInputsBackground};
  ${regularMediumFont}
  text-align: center;
  transition: ${transition};

  :focus {
    outline: 1px solid ${({ theme }) => theme.colors.primaryColor};
  }

  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 374px) {
    width: 30px;
    height: 30px;
  }
`;
