import styled from "styled-components/macro";
import { flexCenter } from "../../../../../../styles/classes/gloabl-classes";

export const MessagingChannelPreviewContainer = styled.div<{
  active: boolean;
  hideMessagingChannelPreview: boolean;
}>`
  height: 56px;
  cursor: pointer;
  display: ${({ hideMessagingChannelPreview }) =>
    hideMessagingChannelPreview ? "none" : "flex"};
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: ${({ theme, active }) =>
    active ? theme.newColors.otidaO2 : theme.colors.containerColor};
  transition: background 0.3s ease;

  :hover {
    background: ${({ theme }) => theme.colors.bodyColor};
  }
`;

export const MessagingChannelPreviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;
`;

export const MessagingChannelPreviewContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: 18px;
  margin-bottom: 4px;
`;

export const MessagingChannelPreviewContentTopNameContainer = styled.div`
  ${flexCenter}
  gap: 0px 8px;

  .str-chat__avatar {
    margin-right: 0px;
  }
`;

export const MessagingChannelPreviewName = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSize.regularSmFont};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0;
  max-width: 158px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MessagingChannelPreviewTime = styled.p`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.textColor};
  margin: 0;
`;

export const MessagingChannelPreviewMessage = styled.p`
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  margin-left: 28px;
  color: ${({ theme }) => theme.colors.textColor};
  height: 16px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MessagingChannelPreviewUnreadCount = styled.span`
  background: ${({ theme }) => theme.colors.primaryColor};
  border-radius: 50%;
  padding: 12px;
  width: 17px;
  height: 17px;
  color: ${({ theme }) => theme.colors.buttonColor};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
