import styled from "styled-components/macro";
import {
  flexCenter,
  mediumFont,
  smallRegularFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const PatientProfileInformationHeaderCenterBottomContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max-content, 130px));
  gap: 8px 16px;
  margin-top: 20px;
  min-height: 50px;
  height: fit-content;
  max-width: 591px;

  @media screen and (max-width: 950px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 722px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 544px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PatientProfileInformationHeaderCenterBottomCard = styled.div`
  border: 1px dashed ${({ theme }) => theme.newColors.newBorderColorAlt};
  border-radius: 8px;
  padding: 4px 16px;
`;

export const PatientProfileInformationHeaderCenterBottomCardValue = styled.p`
  margin: 0px 0px 0px;
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.titleColor};

  span {
    color: ${({ theme }) => theme.colors.textColor};
    ${mediumFont}
  }
`;

export const PatientProfileInformationHeaderCenterBottomCardProgress = styled.div`
  ${flexCenter}

  h1 {
    margin: 0px 0px 0px 0px;
    ${mediumFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const PatientProfileInformationHeaderCenterBottomCardProgressIcon = styled.div<{
  successProgress: boolean;
}>`
  ${flexCenter}
  color: ${({ theme, successProgress }) =>
    successProgress ? theme.newColors.successAlt2 : theme.colors.danger};
  ${smallRegularFont}

  span {
    transform: ${({ successProgress }) =>
      successProgress ? "rotate(0deg)" : "rotate(180deg)"};
  }

  p {
    margin: 0px;
  }
`;
