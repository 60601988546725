import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { CarbCounterV1MealDTO } from "../../../../../models/carb-counter-dtos/carb-counter-v1-meal-dto";
import { PatientMealFoodDTO } from "../../../../../models/patient-meal-dtos/patient-meal-food-dto";
import { CarbCounterV1CreateMealDTO } from "../../../../../models/carb-counter-dtos/carb-counter-v1-create-meal-dto";
import { PatientMealDTO } from "../../../../../models/patient-meal-dtos/patient-meal-dto";
import { useAddMeal } from "../../../../../hooks/carb-counter-v1-meals-hooks/use-add-meal";

export type CarbCounterState =
  | { state: "Idle" }
  | {
      state: "Analyzing";
      loading: true;
    }
  | {
      state: "Analyzed";
      carbCounterV1MealDTO: CarbCounterV1MealDTO;
      loading: false;
    }
  | {
      state: "Persisted";
      patientMealFoods: PatientMealFoodDTO[];
    }
  | {
      state: "PatientCarbCounter";
      patientMealFoods: PatientMealFoodDTO[];
    };

type CarbCounterMealState = {
  carbCounterState: CarbCounterState;
  setCarbCounterState: (carbCounterState: CarbCounterState) => void;
  handleAnalizeMeal(): Promise<void>;
};

const CarbCounterMealStateContext = createContext<
  CarbCounterMealState | undefined
>(undefined);

type CarbCounterMealStateProviderProps = PropsWithChildren<{
  patientMealDTO: PatientMealDTO;
}>;

export function CarbCounterMealStateProvider(
  props: CarbCounterMealStateProviderProps
) {
  const { children, patientMealDTO } = props;

  const patientMealFoods = patientMealDTO.patientMealFoods;
  const isPatientMealFoodsDefined =
    patientMealDTO.patientMealFoods.length > 0 &&
    patientMealDTO.patientMealFoods !== undefined;

  const initialCarbCounterState: CarbCounterState = isPatientMealFoodsDefined
    ? {
        state: "Persisted",
        patientMealFoods,
      }
    : patientMealDTO.source === "PatientCarbCounter"
    ? {
        state: "PatientCarbCounter",
        patientMealFoods,
      }
    : {
        state: "Idle",
      };

  const [carbCounterState, setCarbCounterState] = useState<CarbCounterState>(
    initialCarbCounterState
  );

  const addMeal = useAddMeal();

  const handleAnalizeMeal = useCallback(async () => {
    const carbCounterV1CreateMealDTO: CarbCounterV1CreateMealDTO = {
      mealDescription: patientMealDTO.note,
    };

    const mealImageFilesPublicURLs = patientMealDTO.patientMealImages.map(
      (img) => img.publicUrl
    );

    const blobArray = await Promise.all(
      mealImageFilesPublicURLs.map(async (imageUrl) => {
        const response = await fetch(imageUrl);

        if (!response.ok) {
          throw new Error(
            `Failed to fetch image: ${response.status} ${response.statusText}`
          );
        }

        return response.blob();
      })
    );

    const temperature = 0;

    setCarbCounterState({ state: "Analyzing", loading: true });
    await addMeal(carbCounterV1CreateMealDTO, blobArray, [], temperature).then(
      (response) =>
        setCarbCounterState({
          state: "Analyzed",
          carbCounterV1MealDTO: response,
          loading: false,
        })
    );
  }, [addMeal, patientMealDTO.patientMealImages, patientMealDTO.note]);

  const value = useMemo(
    () => ({ carbCounterState, setCarbCounterState, handleAnalizeMeal }),
    [carbCounterState, setCarbCounterState, handleAnalizeMeal]
  );

  return (
    <CarbCounterMealStateContext.Provider value={value}>
      {children}
    </CarbCounterMealStateContext.Provider>
  );
}

export function useCarbCounterMealState(): CarbCounterMealState {
  const carbCounterMealState = useContext(CarbCounterMealStateContext);

  if (carbCounterMealState === undefined) {
    throw new Error(
      `useCarbCounterMealState must be within CarbCounterMealStateProvider`
    );
  }

  return carbCounterMealState;
}
