export const SMOKES = [
  "Cigarettes",
  "Vape",
  "Shisha",
  "Iqos",
  "Pipe",
  "Other",
] as const;

export type Smoke = typeof SMOKES[number];
