import { useCallback } from "react";
import { PatientBloodPressureDTO } from "../../models/patient-blood-pressure-dtos/patient-blood-pressure-dto";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";

export function useGetPatientBloodPressures(): (
  patientId: number
) => Promise<PatientBloodPressureDTO[]> {
  const client = useClient();

  return useCallback(
    async (patientId) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientBloodPressureDTO[]>(
        `${PATIENTS_PATH}/${patientId}/blood-pressures`
      );

      return response.data;
    },
    [client]
  );
}
