import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";

type NutritionReportDairyTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportDairyTableCell = (
  props: NutritionReportDairyTableCellProps
) => {
  const { kind } = props;

  const {
    dairyHighFatRow,
    dairyMediumFatRow,
    dairyLowFatRow,
    dairyHighFatMeal,
    dairyMediumFatMeal,
    dairyLowFatMeal,
  } = useMemo(() => {
    const dairyRow =
      kind.kind === "Row"
        ? kind.data.foodGroups.filter((item) => {
            return item.foodGroup.parentFoodGroupId === 21;
          })
        : undefined;

    const dairyMeals =
      kind.kind === "Meal"
        ? kind.data.foodGroups.filter((item) => {
            return item.foodGroup.parentFoodGroupId === 21;
          })
        : undefined;

    const dairyHighFatRow = dairyRow?.find((item) => item.foodGroup.id === 5);
    const dairyMediumFatRow = dairyRow?.find((item) => item.foodGroup.id === 6);
    const dairyLowFatRow = dairyRow?.find((item) => item.foodGroup.id === 7);

    const dairyHighFatMeal = dairyMeals?.find(
      (item) => item.foodGroup.id === 5
    );
    const dairyMediumFatMeal = dairyMeals?.find(
      (item) => item.foodGroup.id === 6
    );
    const dairyLowFatMeal = dairyMeals?.find((item) => item.foodGroup.id === 7);

    return {
      dairyHighFatRow,
      dairyMediumFatRow,
      dairyLowFatRow,
      dairyHighFatMeal,
      dairyMediumFatMeal,
      dairyLowFatMeal,
    };
  }, [kind.data, kind.kind]);

  const rowValues = [
    dairyHighFatRow?.totalServingsCount,
    dairyMediumFatRow?.totalServingsCount,
    dairyLowFatRow?.totalServingsCount,
  ];

  const mealValues = [
    dairyHighFatMeal?.servingsCount,
    dairyMediumFatMeal?.servingsCount,
    dairyLowFatMeal?.servingsCount,
  ];

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type="Dairy"
      values={kind.kind === "Row" ? rowValues : mealValues}
    />
  );
};
