import { PatientType } from "../../../models/patient-dtos/patient-type";
import { DaysAggregatesReadingsWithTimeDifferenceValue } from "../build-readings-with-time-differance-value";
import { isGlucosePointAboveRange } from "./is-glucose-point-above-range";
import { removeTimeDifferanceValueFromReading } from "./remove-time-differance-value-from-reading";

export function calculateAboveRangeReadingsTimeDiffrenceTotal(
  patientType: PatientType,
  readingsWithTimeDifferenceValue: DaysAggregatesReadingsWithTimeDifferenceValue[]
) {
  let aboveRangeReadingsTimeDiffrenceTotal: number = 0;

  for (const readingWithTimeDifferenceValue of readingsWithTimeDifferenceValue) {
    if (
      isGlucosePointAboveRange(
        patientType,
        removeTimeDifferanceValueFromReading(readingWithTimeDifferenceValue)
      )
    ) {
      if (readingWithTimeDifferenceValue.timeDifference !== undefined) {
        if (readingWithTimeDifferenceValue.timeDifference > 15) {
          aboveRangeReadingsTimeDiffrenceTotal += 15;
        } else {
          aboveRangeReadingsTimeDiffrenceTotal +=
            readingWithTimeDifferenceValue.timeDifference;
        }
      }
    }
  }

  return aboveRangeReadingsTimeDiffrenceTotal;
}
