import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { PatientProfileDietModalInputs } from "../../patient-profile-edit-diet-modal";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileTagsLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import {
  PateintProfileFamilyMemberDiseasesFormControlBody,
  PateintProfileFamilyMemberDiseasesFormControlDropdownsContainer,
} from "../../../../../patient-profile-medical-history-section/patient-profile-medical-history/patient-profile-medical-history-edit-modal/patient-profile-medical-history-edit-modal-body/pateint-profile-family-member-diseases-form-control/styled-pateint-profile-family-member-diseases-form-control";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";
import { Dropdown } from "../../../../../../../../components/dropdown/dropdown";
import { ALCOHOLS, Alcohol } from "../../../../../../../../models/alcohol";
import { DropdownListItem } from "../../../../../../../../components/dropdown/styled-dropdown";

export const PatientProfileEditDietAlcoholsFormControl = () => {
  type AlcoholsFrequency = "Daily" | "Occasionally" | undefined;
  const alcoholsFrequecy: AlcoholsFrequency[] = ["Daily", "Occasionally"];

  const { control, watch } = useFormContext<PatientProfileDietModalInputs>();

  const { append, fields, remove } = useFieldArray({
    name: "patientAlcohols",
    control,
  } as never);

  const patientAlcohols = watch("patientAlcohols");

  return (
    <PatientProfileModalFormControl>
      <PatientProfileTagsLabelContainer>
        <label>Alcohols</label>
      </PatientProfileTagsLabelContainer>
      <PateintProfileFamilyMemberDiseasesFormControlBody
        isNotEmptyFamilyMemberDiseases={
          patientAlcohols !== undefined && patientAlcohols.length > 0
        }
        style={{ gap: "24px" }}
      >
        {fields.map((field, index) => (
          <PatientProfileModalInputContainer key={field.id}>
            <PateintProfileFamilyMemberDiseasesFormControlDropdownsContainer>
              <Button outlineNoBorder type="button">
                <img
                  src="/img/trash.svg"
                  alt="Trash Icon"
                  onClick={() => remove(index)}
                />
              </Button>
              <Controller
                name={`patientAlcohols.${index}.alcohol`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Dropdown
                      text={
                        value !== undefined && value !== ("" as Alcohol)
                          ? value
                          : "Select Alcohol"
                      }
                      variant="designed-ux"
                      fullWidth
                    >
                      {ALCOHOLS.slice(0, ALCOHOLS.indexOf("Other")).map(
                        (alcohol) => (
                          <DropdownListItem
                            variant="designed-ux"
                            key={alcohol}
                            onClick={() => onChange(alcohol)}
                          >
                            {alcohol}
                          </DropdownListItem>
                        )
                      )}
                    </Dropdown>
                  );
                }}
              />
              <Controller
                name={`patientAlcohols.${index}.quantity`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <input
                      type="number"
                      placeholder="Alcohol Quantity"
                      value={value !== undefined ? value : ""}
                      onChange={(e) =>
                        onChange(
                          e.target.value !== undefined ? e.target.value : ""
                        )
                      }
                    />
                  );
                }}
              />
              <Controller
                name={`patientAlcohols.${index}.frequency`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Dropdown
                      text={
                        value !== undefined &&
                        value !== ("" as AlcoholsFrequency)
                          ? value
                          : "Select Frequency"
                      }
                      variant="designed-ux"
                      fullWidth
                    >
                      {alcoholsFrequecy.map((frequecy) => (
                        <DropdownListItem
                          variant="designed-ux"
                          key={frequecy}
                          onClick={() => onChange(frequecy)}
                        >
                          {frequecy}
                        </DropdownListItem>
                      ))}
                    </Dropdown>
                  );
                }}
              />
            </PateintProfileFamilyMemberDiseasesFormControlDropdownsContainer>
          </PatientProfileModalInputContainer>
        ))}
        <Button
          type="button"
          width={100}
          height={40}
          flex
          outlineNoBorder
          onClick={() => {
            append({});
          }}
        >
          <span className="material-symbols-outlined">add</span> Add
        </Button>
      </PateintProfileFamilyMemberDiseasesFormControlBody>
    </PatientProfileModalFormControl>
  );
};
