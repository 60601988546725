import { useEffect, useState } from "react";
import { Modal } from "../../../../components/modal/modal";
import { ModalForm } from "../../../../components/modal/modal-form/modal-form";
import { useModalHelpers } from "../../../../components/modal/use-modal-helpers";
import {
  Button,
  ModalFooter,
} from "../../../../styles/classes/reusable-classes";
import { AddNewMealModalBody } from "./add-new-meal-modal-body/add-new-meal-modal-body";
import { AddNewMealContainer } from "./styled-add-new-meal";
import { useFoodLoggingData } from "../../context/food-logging-data-context";
import { FormProvider, useForm } from "react-hook-form";
import { PatientMealTag } from "../../../../models/patient-meal-dtos/patient-meal-tag";

export type AddNewMealModalInputs = {
  date: string;
  time: string;
  mealTag?: PatientMealTag;
  note?: string;
  patientMealImageFiles: Blob[];
  patientMealVoiceNoteFiles: Blob[];
};

export const AddNewMeal = () => {
  const { selectedPatientMealDTO } = useFoodLoggingData();

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  const [showAddNewMealForm, setShowAddNewMealForm] = useState(
    selectedPatientMealDTO ? false : true
  );

  useEffect(() => {
    if (selectedPatientMealDTO) {
      setShowAddNewMealForm(false);
    } else {
      setShowAddNewMealForm(true);
    }
  }, [selectedPatientMealDTO]);

  const addNewMealFormMethods = useForm<AddNewMealModalInputs>();

  const handleCloseModal = () => {
    closeModal();
    addNewMealFormMethods.reset();
  };

  return (
    <AddNewMealContainer>
      <Button onClick={openModal} type="button">
        Add New Meal
      </Button>
      <Modal handleHideModal={handleCloseModal} showModal={isModalOpen}>
        {!showAddNewMealForm ? (
          <ModalForm height={250}>
            <h2>Are you sure you want to leave this page?</h2>
            <ModalFooter>
              <Button outlined type="button" onClick={closeModal}>
                Cancel
              </Button>
              <Button type="button" onClick={() => setShowAddNewMealForm(true)}>
                Yes
              </Button>
            </ModalFooter>
          </ModalForm>
        ) : (
          <FormProvider {...addNewMealFormMethods}>
            <AddNewMealModalBody
              key={modalBodyKey}
              onClose={handleCloseModal}
            />
          </FormProvider>
        )}
      </Modal>
    </AddNewMealContainer>
  );
};
