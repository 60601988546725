import { useFormContext } from "react-hook-form";
import { useToastService } from "../../../../../../../../../context/toast-service-context";
import { useAddUploadedFile } from "../../../../../../../../../hooks/uploaded-file-hooks/use-add-uploaded-file";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../../styles/classes/reusable-classes";
import { CreateLessonInputs } from "../../../../../create-lesson";
import { useCallback, useEffect } from "react";
import { CreateUploadedFileDTO } from "../../../../../../../../../models/uploaded-file-dtos/create-uploaded-file-dto";
import { useVideoUploadHelpers } from "../../../../../../../components/video-upload/context/video-upload-helpers-context";
import { VideoUpload } from "../../../../../../../components/video-upload/video-upload";
import { useCardPartsLoading } from "../../../../../../context/card-parts-loading-context";

type CreateLessonCardPartsVideoUploadProps = {
  cardIndex: number;
  cardPartIndex: number;
};

export const CreateLessonCardPartsVideoUpload = (
  props: CreateLessonCardPartsVideoUploadProps
) => {
  const { cardIndex, cardPartIndex } = props;

  const { showToast } = useToastService();
  const addUploadedFile = useAddUploadedFile();

  const {
    videoFile,
    setLoading,
    handleUploadedVideoState,
    videoUploadState,
    videoFileInputRef,
  } = useVideoUploadHelpers();

  const { setValue } = useFormContext<CreateLessonInputs>();
  const { setCardPartsLoading } = useCardPartsLoading();

  const onUpload = useCallback(async () => {
    try {
      if (videoFile) {
        const createUploadedFileDTO: CreateUploadedFileDTO = {
          feature: "ContentModuleCardPartVideo",
        };

        setLoading(true);
        setCardPartsLoading({
          cardIndex,
          loading: true,
        });
        await addUploadedFile(createUploadedFileDTO, videoFile).then(
          (response) => {
            setValue(
              `contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}.videoUploadedFileURL`,
              response.presignedUrl
            );
            setValue(
              `contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}.videoUploadedFileId`,
              response.id
            );
          }
        );
        handleUploadedVideoState(videoFile);
        showToast("Success", "Card Video Uploaded Successfully");
        setLoading(false);
        setCardPartsLoading({
          cardIndex: undefined,
          loading: false,
        });
      }
    } catch (error) {
      showToast("Error", "Failed to Upload Card Video :(");
    }
  }, [
    addUploadedFile,
    cardIndex,
    cardPartIndex,
    handleUploadedVideoState,
    videoFile,
    setLoading,
    setValue,
    showToast,
    setCardPartsLoading,
  ]);

  useEffect(() => {
    if (videoFile && videoUploadState.state === "Upload") {
      onUpload();
    }
  }, [videoFile, onUpload, videoUploadState]);

  const openUploaderOnMount = useCallback(() => {
    videoFileInputRef.current?.click();
  }, [videoFileInputRef]);

  useEffect(() => {
    openUploaderOnMount();
  }, [openUploaderOnMount]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="50px" $isOptional>
        Video
      </UXInputLabel>
      <VideoUpload uploadText="Video" />
    </UXInputContainer>
  );
};
