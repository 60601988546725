import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  PatientProfileAutoSelectContainer,
  PatientProfileAutoSelectFormControlContainer,
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileTagList,
  PatientProfileTagListItem,
  PatientProfileTagsLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileDietModalInputs } from "../../patient-profile-edit-diet-modal";
import { AutoSelect } from "../../../../../../../../components/auto-select/auto-select";
import { PutPatientDietProfileFoodAllergyDTO } from "../../../../../../../../models/patient-diet-profile-dtos/put-patient-diet-profile-food-allergy-dto";
import { useCallback, useState } from "react";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";
import { useEnterKeypress } from "../../../../../../../../hooks/use-enter-keypress/use-enter-keypress";
import { usePatientProfileDiet } from "../../../../context/loadable-patient-profile-diet-context";

export const PatientProfileEditDietAllergiesFormControl = () => {
  const { control, watch } = useFormContext<PatientProfileDietModalInputs>();

  const { foodAllergies } = usePatientProfileDiet();

  const findFoodAllergieName = useCallback(
    (id: number) => foodAllergies.find((item) => item.id === id),
    [foodAllergies]
  );

  const findFoodAllergieIndex = useCallback(
    (name: string) => foodAllergies.find((item) => item.name === name),
    [foodAllergies]
  );

  const { append, fields, remove } = useFieldArray({
    name: "patientFoodAllergies",
    control,
  } as never);

  const patientFoodAllergies = watch("patientFoodAllergies");

  const [searchTerm, setSearchTerm] = useState("");
  const [autoSelectTagValue, setAutoSelectTagValue] = useState<
    PutPatientDietProfileFoodAllergyDTO | undefined
  >();

  const handleAppend = () => {
    if (
      patientFoodAllergies !== undefined &&
      autoSelectTagValue !== undefined &&
      !patientFoodAllergies.some(
        (obj) => obj.foodAllergyId === autoSelectTagValue.foodAllergyId
      ) &&
      foodAllergies.some((obj) => obj.id === autoSelectTagValue?.foodAllergyId)
    ) {
      append(autoSelectTagValue);
      setAutoSelectTagValue(undefined);
      setSearchTerm("");
    }
  };

  const { onEnterKeyPress } = useEnterKeypress(handleAppend);

  return (
    <PatientProfileModalFormControl>
      <PatientProfileTagsLabelContainer>
        <label>Allergies</label>
      </PatientProfileTagsLabelContainer>
      <PatientProfileModalInputContainer>
        <Controller
          control={control}
          name="patientFoodAllergies"
          render={() => {
            return (
              <PatientProfileAutoSelectFormControlContainer>
                <PatientProfileAutoSelectContainer>
                  <AutoSelect
                    itemsArray={foodAllergies.map(
                      (foodAllergie) => foodAllergie.name
                    )}
                    onChange={(value: string) => {
                      const valueID = findFoodAllergieIndex(value)?.id;
                      setAutoSelectTagValue({ foodAllergyId: valueID! });
                      setSearchTerm(value);
                    }}
                    value={searchTerm}
                    fullWidth
                    placeHolder="Select Food Allergies"
                  />
                  <Button
                    type="button"
                    onClick={handleAppend}
                    onKeyDown={(e) => onEnterKeyPress(e)}
                    width={100}
                    height={40}
                    flex
                    outlineNoBorder
                  >
                    <span className="material-symbols-outlined">add</span> Add
                  </Button>
                </PatientProfileAutoSelectContainer>
                <PatientProfileTagList>
                  {fields.map((field, index) => (
                    <PatientProfileTagListItem key={field.id} variant="Normal">
                      {patientFoodAllergies !== undefined
                        ? findFoodAllergieName(
                            patientFoodAllergies[index].foodAllergyId
                          )?.name
                        : ""}
                      <span
                        className="material-symbols-outlined"
                        onClick={() => remove(index)}
                      >
                        close
                      </span>
                    </PatientProfileTagListItem>
                  ))}
                </PatientProfileTagList>
              </PatientProfileAutoSelectFormControlContainer>
            );
          }}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
