import { useFieldArray, useFormContext } from "react-hook-form";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../styles/classes/reusable-classes";
import { TargetAudienceAutoselect } from "../../../components/target-audience-autoselect/target-audience-autoselect";
import { useCallback } from "react";
import { TargetAudienceDTO } from "../../../../../models/target-audience-dtos/target-audience-dto";
import { LoadableContentModuleTargetAudienceProvider } from "../../../context/loadable-content-modules-target-audience-context";
import { LoadableDataLoading } from "../../../../../components/loadable-data-loading";
import { useGetTargetAudiences } from "../../../../../hooks/target-audience-hooks/use-get-target-audiences";
import { useLoadableData } from "../../../../../hooks/use-loadable-data";
import { PutContentModuleDTO } from "../../../../../models/content-module-dtos/put-content-module-dto";
import {
  ContentModuleReadModeInputs,
  useContentModuleMode,
} from "../../../context/content-module-mode-context";

export const ReadContentModuleTargetAudienceController = () => {
  const { contentModuleMode } = useContentModuleMode();

  const contentModuleDTO = contentModuleMode.mode.contentModule;

  const { control, watch, handleSubmit } = useFormContext<
    ContentModuleReadModeInputs
  >();
  const targetAudienceWatcher = watch("targetAudience");

  const onSubmit = handleSubmit(async (inputs) => {
    if (contentModuleMode.mode.kind === "Read") {
      const putContentModuleDTO: PutContentModuleDTO = {
        childContentModuleIds: contentModuleDTO.childContentModules?.map(
          (ccm) => ccm.id
        )!,
        contentModuleCards: contentModuleDTO.contentModuleCards,
        description: contentModuleDTO.description,
        isDraft: contentModuleDTO.isDraft,
        name: contentModuleDTO.name!,
        objective: contentModuleDTO.objective,
        parentContentModuleId: contentModuleDTO.parentContentModuleId,
        targetAudienceIds: inputs.targetAudience.map((item) => item.id),
        thumbnailUploadedFileId: contentModuleDTO.thumbnailUploadedFileId,
        uiOrder: contentModuleDTO.uiOrder,
      };

      await contentModuleMode.mode.handleSubmit(
        contentModuleDTO.id,
        putContentModuleDTO,
        contentModuleDTO.contentModuleType
      );
    }
  });

  const { append, fields, remove } = useFieldArray<ContentModuleReadModeInputs>(
    {
      name: "targetAudience",
      control,
    } as never
  );

  const handleAppend = useCallback(
    (targetAudienceDTO: TargetAudienceDTO) => {
      append(targetAudienceDTO);
      onSubmit();
    },
    [append, onSubmit]
  );

  const handleRemove = useCallback(
    (index: number) => {
      remove(index);
      onSubmit();
    },
    [remove, onSubmit]
  );

  const getTargetAudiences = useGetTargetAudiences();

  const loadTargetAudience = useCallback(async () => {
    const [targetAudienceDTOs] = await Promise.all([getTargetAudiences()]);

    return { targetAudienceDTOs };
  }, [getTargetAudiences]);

  const loadableTargetAudience = useLoadableData(loadTargetAudience);

  return (
    <div>
      <UXInputContainer>
        <UXInputLabel $width="150px" $isOptional>
          Target Audience
          <span>(Optional)</span>
        </UXInputLabel>
        <LoadableDataLoading
          state={loadableTargetAudience.state}
          successComponent={() => (
            <LoadableContentModuleTargetAudienceProvider
              loadableContentModuleTargetAudience={loadableTargetAudience}
            >
              <TargetAudienceAutoselect
                handleAppend={handleAppend}
                targetAudienceWatcher={targetAudienceWatcher}
                fields={fields}
                handleRemove={handleRemove}
              />
            </LoadableContentModuleTargetAudienceProvider>
          )}
        />
      </UXInputContainer>
    </div>
  );
};
