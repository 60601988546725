import { CarbCounterV1FoodMeasureDTO } from "../../../../../models/carb-counter-dtos/carb-counter-v1-food-measure-dto";
import { CarbCounterV1MealFoodDTO } from "../../../../../models/carb-counter-dtos/carb-counter-v1-meal-food-dto";
import { roundTo3DecimalPlaces } from "../../../../../utils/math-utils";

export function getFoodMeasureDisplayName(
  foodMeasure: CarbCounterV1FoodMeasureDTO,
  food: CarbCounterV1MealFoodDTO
): string {
  const foodMessaureNameFirstLetterToUpperCase = foodMeasure.name
    .slice(0, 1)
    .toUpperCase();
  const foodMessaureNameExcludeFirstLetter = foodMeasure.name.slice(
    1,
    foodMeasure.name.length
  );

  const foodMeassureName = `${foodMessaureNameFirstLetterToUpperCase}${foodMessaureNameExcludeFirstLetter}`;

  if (foodMeassureName === "Gram" && food.food.gramsPerOneLitre !== undefined) {
    return "Litre";
  }

  if (foodMeasure.name === "gram" || foodMeasure.name === "milliliter") {
    return foodMeassureName;
  }

  if (food.food.gramsPerOneLitre !== undefined) {
    const litresPerOneUnitOfMeasure =
      foodMeasure.gramsPerOneUnitOfMeasure / food.food.gramsPerOneLitre;
    const millilitresPerOneUnitOfMeasure = litresPerOneUnitOfMeasure * 1000.0;

    return `${foodMeassureName} (${roundTo3DecimalPlaces(
      millilitresPerOneUnitOfMeasure
    )} ml)`;
  }

  return `${foodMeassureName} (${roundTo3DecimalPlaces(
    foodMeasure.gramsPerOneUnitOfMeasure
  )} g)`;
}

export function isFoodMeasureSelected(
  foodMeasure: CarbCounterV1FoodMeasureDTO,
  mealFood: CarbCounterV1MealFoodDTO
): boolean {
  return foodMeasure.name === mealFood.quantityFoodMeasure.name;
}
