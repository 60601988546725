import styled from "styled-components/macro";
import { flexCenterSpaceBetween } from "../../../../../../../../styles/classes/gloabl-classes";

export const ImmediateAttentionPatientsFiltersSidebarGenderContainer = styled.div`
  ${flexCenterSpaceBetween}

  ul {
    margin: 0px 8px 0px 0px;
    display: flex;
    gap: 0px 12px;
    font-size: 13px;
    font-weight: 400;
  }
`;
