import { Controller, useFormContext } from "react-hook-form";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  Input,
  InputContainer,
  InputLabel,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import { CarbCounterMealAddFoodModalInputs } from "../carb-counter-meal-add-food-modal";
import { CarbCounterMealAddFoodModalBodyContainer } from "./styled-carb-counter-meal-add-food-modal-body";
import { useAddFood } from "../../../../../../../hooks/carb-counter-v1-meals-hooks/use-add-food";
import { CarbCounterV1AddFoodDTO } from "../../../../../../../models/carb-counter-dtos/carb-counter-v1-add-food-dto";
import { useCarbCounterMeal } from "../../../context/carb-counter-meal-dto-context";
import { useState } from "react";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { OtidaLoader } from "../../../../../../../components/loaders/otida-loader/otida-loader";
import { CarbCounterMealAddFoodModalBodyFoodResponse } from "./carb-counter-meal-add-food-modal-body-food-response/carb-counter-meal-add-food-modal-body-food-response";

type CarbCounterMealAddFoodModalBodyProps = {
  onClose: () => void;
};

export const CarbCounterMealAddFoodModalBody = (
  props: CarbCounterMealAddFoodModalBodyProps
) => {
  const { onClose } = props;

  const { meal, handleAddMealFood } = useCarbCounterMeal();

  const { handleSubmit, control, watch, reset, setValue } = useFormContext<
    CarbCounterMealAddFoodModalInputs
  >();

  const [isAddNewFoodLoading, setIsAddNewFoodLoading] = useState(false);

  const foodDescriptionWatcher = watch("foodDescription");
  const foodMessureWatcher = watch("foodMessure");
  const foodQuantityWatcher = watch("foodQuantity");
  const addFoodResponseWatcher = watch("addFoodResponse");

  const isFoodDescriptionWatcher =
    foodDescriptionWatcher !== undefined && foodDescriptionWatcher !== "";

  const isFormDisabled =
    !isFoodDescriptionWatcher ||
    foodMessureWatcher === undefined ||
    foodQuantityWatcher === undefined ||
    foodQuantityWatcher === "0" ||
    foodQuantityWatcher === "";

  const addFood = useAddFood();
  const addFoodTemprature = 0;
  const { showToast } = useToastService();

  async function handleGoToNextStep() {
    try {
      if (isFoodDescriptionWatcher) {
        const carbCounterV1AddFoodDTO: CarbCounterV1AddFoodDTO = {
          foodDescription: foodDescriptionWatcher,
          meal,
        };

        setIsAddNewFoodLoading(true);
        await addFood(
          carbCounterV1AddFoodDTO,
          addFoodTemprature
        ).then((response) => setValue("addFoodResponse", response));
        setIsAddNewFoodLoading(false);
      }
    } catch (error) {
      showToast("Error", `Request Failed.. Try again`);
    }
  }

  const onSubmit = handleSubmit(async (inputs) => {
    const newFoodMeassure = inputs.foodMessure;
    const newFoodDescription = inputs.foodDescription;
    const newFoodQuantity = inputs.foodQuantity;

    const isFormValuesDefined =
      newFoodDescription !== undefined &&
      newFoodMeassure !== undefined &&
      newFoodQuantity !== undefined;

    try {
      if (isFormValuesDefined && addFoodResponseWatcher !== undefined) {
        handleAddMealFood(
          newFoodMeassure,
          newFoodQuantity,
          addFoodResponseWatcher
        );
        showToast(
          "Success",
          `${
            foodDescriptionWatcher !== undefined
              ? foodDescriptionWatcher
              : `Food`
          } Successfully Added to Meal`
        );
        reset();
        onClose();
      }
    } catch (error) {
      showToast(
        "Error",
        `Failed to Add ${
          foodDescriptionWatcher !== undefined ? foodDescriptionWatcher : `Food`
        } to Meal :(`
      );
    }
  });

  return (
    <ModalForm width={650} height={650} onSubmit={onSubmit}>
      <ModalHeader title="Add Food Item" onModalClose={onClose} />
      <CarbCounterMealAddFoodModalBodyContainer>
        <Controller
          control={control}
          name="foodDescription"
          render={({ field: { value, onChange } }) => (
            <InputContainer margin>
              <InputLabel>Food Description</InputLabel>
              <Input
                placeholder="Type in a food description (ex: Mango...)"
                type="text"
                value={value !== undefined ? value : ""}
                onChange={(e) =>
                  onChange(e.target.value !== undefined ? e.target.value : "")
                }
              />
              <Button
                className="next-button"
                flex
                type="button"
                onClick={handleGoToNextStep}
                disabled={
                  foodDescriptionWatcher === undefined ||
                  foodDescriptionWatcher === ""
                }
              >
                Next
                <span className="material-symbols-outlined">
                  arrow_right_alt
                </span>
              </Button>
            </InputContainer>
          )}
        />
        {isAddNewFoodLoading ? (
          <OtidaLoader />
        ) : (
          <CarbCounterMealAddFoodModalBodyFoodResponse />
        )}
      </CarbCounterMealAddFoodModalBodyContainer>
      <ModalFooter>
        <Button type="button" outlined onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" disabled={isFormDisabled}>
          Confirm
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
