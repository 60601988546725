import {
  GoogleDataTableColumn,
  GoogleDataTableColumnRoleType,
} from "react-google-charts";

export function buildColumns(): GoogleDataTableColumn[] {
  // I think the labels below have no visible effect for the user due to the options we're using (e.g., custom tooltips)
  // They're left as documentation for the developer(s) reading the code.
  return [
    { type: "datetime", label: "Time in day" },
    { type: "number", label: "Color band 1" },
    { type: "number", label: "Color band 2" },
    { type: "number", label: "Color band 3" },
    { type: "number", label: "Glucose" },
    {
      type: "string",
      role: GoogleDataTableColumnRoleType.tooltip,
      p: { html: true },
    },
    { type: "number", label: "Meal" },
    {
      type: "string",
      role: GoogleDataTableColumnRoleType.tooltip,
      p: { html: true },
    },
    { type: "string", role: GoogleDataTableColumnRoleType.style },
  ];
}
