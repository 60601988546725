import { useCallback } from "react";
import { Outlet } from "react-router-dom";
import { LoadableContentProvider } from "./context/loadable-content-context";
import { useGetContentModules } from "../../hooks/content-module-hooks/use-get-content-modules";
import { ContentModuleType } from "../../models/content-module-dtos/content-module-type";
import { useLoadableData } from "../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../components/loadable-data-loading";
import { ContentLayoutContainer } from "./styled-content";

export const Content = () => {
  const getContentModules = useGetContentModules();

  const loadContentCallback = useCallback(async () => {
    const contentModuleType: ContentModuleType = "Course";

    const contentModuleDTOs = await getContentModules(contentModuleType);
    const contentModuleLessonsDTOs = await getContentModules("Lesson");

    return { contentModuleDTOs, contentModuleLessonsDTOs };
  }, [getContentModules]);

  const loadableContent = useLoadableData(loadContentCallback);

  return (
    <LoadableContentProvider loadableContent={loadableContent}>
      <ContentLayoutContainer>
        <LoadableDataLoading
          state={loadableContent.state}
          successComponent={() => <Outlet />}
        />
      </ContentLayoutContainer>
    </LoadableContentProvider>
  );
};
