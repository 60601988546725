import { useState } from "react";
import { ModalForm } from "../../../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../../../components/modal/modal-header/modal-header";
import { FrequencyDTO } from "../../../../../../../../../models/frequencies/frequency-dto";
import { CreatePatientPrescriptionMedicationDTO } from "../../../../../../../../../models/patient-prescriptions/create-patient-prescription-medication-dto";
import {
  Button,
  ModalFooter,
} from "../../../../../../../../../styles/classes/reusable-classes";
import { FrequencyAutoSelect } from "../../../../../../components/frequency-auto-select/frequency-auto-select";
import { MedicationsNotesInput } from "../../../../../../components/medication-inputs/medications-notes-input/medications-notes-input";
import { MedicationsNumberInput } from "../../../../../../components/medication-inputs/medications-number-input/medications-number-input";
import { PrescriptionFormHeader } from "../../../../../components/prescription-form-header/prescription-form-header";
import { usePrescriptions } from "../../../../../context/prescriptions-context";
import { EditPrescriptionModalBodyInputsContainer } from "../styled-edit-medicine";

type EditFormProps = {
  onClose: () => void;
  card: CreatePatientPrescriptionMedicationDTO;
  currentFrequency?: FrequencyDTO;
  formHeaderData: {
    name: string | undefined;
    strength: string | undefined;
    routeOfAdministration: string | undefined;
    doseUnit: string | undefined;
  };
};

export const EditForm = (props: EditFormProps) => {
  const { onClose, card, currentFrequency, formHeaderData } = props;

  const { startEditing } = usePrescriptions();

  const handleEdit = () => {
    startEditing(card.medicationId, newState);
    onClose();
  };

  const [newState, setNewState] =
    useState<CreatePatientPrescriptionMedicationDTO>(card);

  return (
    <ModalForm width={467} height={650} onSubmit={(e) => e.preventDefault()}>
      <ModalHeader title="Edit Prescription" onModalClose={onClose} />
      <PrescriptionFormHeader formHeaderData={formHeaderData} />
      <EditPrescriptionModalBodyInputsContainer>
        <MedicationsNumberInput
          label="Quantity"
          subLabel="tablet"
          step="0.1"
          value={newState.quantity}
          onChange={(newValue) => {
            setNewState({ ...newState, quantity: Number(newValue) });
          }}
        />
        <FrequencyAutoSelect
          defaultValue={`${currentFrequency?.explanation} (${currentFrequency?.abbreviation})`}
          onChange={(newValue) => {
            setNewState({ ...newState, frequencyId: newValue.id });
          }}
        />
        <MedicationsNumberInput
          label="Duration"
          subLabel="days"
          step="1"
          value={newState.durationDays}
          onChange={(newValue) => {
            setNewState({ ...newState, durationDays: Number(newValue) });
          }}
        />
        <MedicationsNotesInput
          value={newState.notes}
          onChange={(newValue) => {
            setNewState({ ...newState, notes: newValue });
          }}
        />
      </EditPrescriptionModalBodyInputsContainer>
      <ModalFooter>
        <Button type="button" outlined onClick={onClose}>
          Cancel
        </Button>
        <Button type="button" onClick={handleEdit}>
          Confirm
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
