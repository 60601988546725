import { ReactNode } from "react";
import {
  NewLabResultsListContainer,
  NewLabResultsListHeaders,
  NewLabResultsListScrollContainer,
  NewLabResultsListUL,
} from "./styled-new-lab-results-list";

type NewLabResultsListProps = {
  headers: ReactNode[];
  listItems: ReactNode[];
  smallInputs?: boolean;
};

export const NewLabResultsList = (props: NewLabResultsListProps) => {
  const { headers, listItems, smallInputs } = props;

  return (
    <NewLabResultsListScrollContainer>
      <NewLabResultsListContainer isListItemsEmpty={listItems.length === 0}>
        <NewLabResultsListHeaders smallInputs={smallInputs}>
          {headers}
        </NewLabResultsListHeaders>
        <NewLabResultsListUL>{listItems}</NewLabResultsListUL>
      </NewLabResultsListContainer>
    </NewLabResultsListScrollContainer>
  );
};
