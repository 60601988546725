import styled from "styled-components/macro";
import { regularRegularFont } from "../../../../../../../../../styles/classes/gloabl-classes";
import { lightShadow } from "../../../../../../../../../styles/global-style";

export const MediaSidebarMediaListContainer = styled.div`
  display: grid;
  place-items: center;
`;

export const MediaSidebarMediaListList = styled.ul`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 160px);
  gap: 8px;
  margin: 24px 0px;
  padding-top: 32px;

  :first-child {
    margin-top: 0px;
  }
`;

export const MediaSidebarMediaListDateListItem = styled.li`
  position: absolute;
  top: -16px;
  z-index: 500;

  p {
    ${regularRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const MediaSidebarMediaListListItem = styled.li`
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: ${lightShadow};
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
