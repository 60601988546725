import { CreatePatientPrescriptionMedicationDTO } from "../../../../../models/patient-prescriptions/create-patient-prescription-medication-dto";

type calculatePrescriptionsBodyMarginTopType = "0px" | "130px";

export function calculateHeightAndMarginTop(
  addedMedicinesContainerIsVisible: boolean
): calculatePrescriptionsBodyMarginTopType {
  if (addedMedicinesContainerIsVisible) {
    return "130px";
  } else {
    return "0px";
  }
}

export function objectAlreadyInculded(
  obj: CreatePatientPrescriptionMedicationDTO,
  list: CreatePatientPrescriptionMedicationDTO[]
) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].medicationId === obj.medicationId) {
      return true;
    }
  }

  return false;
}
