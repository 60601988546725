import { Controller, useFormContext } from "react-hook-form";
import { Dropdown } from "../../../../../../../../components/dropdown/dropdown";
import { DropdownListItem } from "../../../../../../../../components/dropdown/styled-dropdown";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileModalLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileDietModalInputs } from "../../patient-profile-edit-diet-modal";
import { SLEEP_PATTERNS } from "../../../../../../../../models/patient-dtos/sleep-pattern";

export const PatientProfileEditDietSleepFormControl = () => {
  const { control } = useFormContext<PatientProfileDietModalInputs>();

  return (
    <PatientProfileModalFormControl>
      <PatientProfileModalLabelContainer>
        <label>Sleep Pattern</label>
      </PatientProfileModalLabelContainer>
      <PatientProfileModalInputContainer hasTwoInputsFlex inputWithSublabel>
        <div>
          <Controller
            control={control}
            name="sleepHoursPerDay"
            render={({ field: { value, onChange } }) => (
              <input
                type="number"
                placeholder="Hours"
                value={value !== undefined ? value : undefined}
                onChange={(e) =>
                  onChange(
                    e.target.value !== undefined ? e.target.value : undefined
                  )
                }
              />
            )}
          />
          <strong>Hours</strong>
        </div>
        <Controller
          control={control}
          name="sleepPattern"
          render={({ field: { value, onChange } }) => (
            <Dropdown
              text={value !== undefined ? value : "Select Sleep Pattern"}
              variant="designed-ux"
              fullWidth
            >
              {SLEEP_PATTERNS.map((sleepPattern) => (
                <DropdownListItem
                  variant="designed-ux"
                  key={sleepPattern}
                  onClick={() => onChange(sleepPattern)}
                >
                  {sleepPattern}
                </DropdownListItem>
              ))}
            </Dropdown>
          )}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
