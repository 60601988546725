import { calculateNumberOfMealsTotalsAndAverages } from "./calculate-number-of-meals-totals-and-averages";
import { calculateCarbSourcesTotalsAndAverages } from "./calculate-carb-sources-totals-and-averages";
import { calculateDaysFoodGroupsTotalsAndAverages } from "./calculate-days-food-groups-totals-and-averages";
import { calculateFoodGroupsTotalsAndAverages } from "./calculate-food-groups-totals-and-averages";
import { calculateMacrosSummaryTotalsAndAverages } from "./calculate-macros-summary-totals-and-averages";
import { calculateNaAndKTotalsAndAverages } from "./calculate-na-and-k-totals-and-averages";
import { calcualteProteinSourcesTotalsAndAverages } from "./calculate-protein-sources-totals-and-averages";
import { PatientNutritionReportDailySummaryDayDTOWithId } from "../create-patient-nutrition-report-daily-summary-day-dto-with-ids";

export const calculateTotalsAndAverages = (
  selectedDays: PatientNutritionReportDailySummaryDayDTOWithId[]
) => {
  return {
    numberOfMealsSum: calculateNumberOfMealsTotalsAndAverages(selectedDays)
      .numberOfMealsSum,
    numberOfMealsAvg: calculateNumberOfMealsTotalsAndAverages(selectedDays)
      .numberOfMealsAvg,

    starchesSum: calculateDaysFoodGroupsTotalsAndAverages(selectedDays)
      .starchesSum,
    starchesAvg: calculateDaysFoodGroupsTotalsAndAverages(selectedDays)
      .starchesAvg,

    legumesSum: calculateDaysFoodGroupsTotalsAndAverages(selectedDays)
      .legumesSum,
    legumesAvg: calculateDaysFoodGroupsTotalsAndAverages(selectedDays)
      .legumesAvg,

    fruitsSum: calculateDaysFoodGroupsTotalsAndAverages(selectedDays).fruitsSum,
    fruitsAvg: calculateDaysFoodGroupsTotalsAndAverages(selectedDays).fruitsAvg,

    vegetablesSum: calculateDaysFoodGroupsTotalsAndAverages(selectedDays)
      .vegetablesSum,
    vegetablesAvg: calculateDaysFoodGroupsTotalsAndAverages(selectedDays)
      .vegetablesAvg,

    meatsSum: calculateDaysFoodGroupsTotalsAndAverages(selectedDays).meatsSum,
    meatsAvg: calculateDaysFoodGroupsTotalsAndAverages(selectedDays).meatsAvg,

    dairySum: calculateDaysFoodGroupsTotalsAndAverages(selectedDays).dairySum,
    dairyAvg: calculateDaysFoodGroupsTotalsAndAverages(selectedDays).dairyAvg,

    fatsSum: calculateDaysFoodGroupsTotalsAndAverages(selectedDays).fatsSum,
    fatsAvg: calculateDaysFoodGroupsTotalsAndAverages(selectedDays).fatsAvg,

    caffBevSum: calculateDaysFoodGroupsTotalsAndAverages(selectedDays)
      .caffBevSum,
    caffBevAvg: calculateDaysFoodGroupsTotalsAndAverages(selectedDays)
      .caffBevAvg,

    naSum: calculateNaAndKTotalsAndAverages(selectedDays).naSum,
    naAvg: calculateNaAndKTotalsAndAverages(selectedDays).naAvg,

    kSum: calculateNaAndKTotalsAndAverages(selectedDays).kSum,
    kAvg: calculateNaAndKTotalsAndAverages(selectedDays).kAvg,

    kcalSum: calculateMacrosSummaryTotalsAndAverages(selectedDays).kcalSum,
    kcalAvg: calculateMacrosSummaryTotalsAndAverages(selectedDays).kcalAvg,

    carbsGmSum: calculateMacrosSummaryTotalsAndAverages(selectedDays)
      .carbsGmSum,
    carbsGmAvg: calculateMacrosSummaryTotalsAndAverages(selectedDays)
      .carbsGmAvg,

    fatGmSum: calculateMacrosSummaryTotalsAndAverages(selectedDays).fatGmSum,
    fatGmAvg: calculateMacrosSummaryTotalsAndAverages(selectedDays).fatGmAvg,

    proteinGmSum: calculateMacrosSummaryTotalsAndAverages(selectedDays)
      .proteinGmSum,
    proteinGmAvg: calculateMacrosSummaryTotalsAndAverages(selectedDays)
      .proteinGmAvg,

    carbPercentageAvg: calculateMacrosSummaryTotalsAndAverages(selectedDays)
      .carbPercentageAvg,

    fatPercentageAvg: calculateMacrosSummaryTotalsAndAverages(selectedDays)
      .fatPercentageAvg,

    proteinPercentageAvg: calculateMacrosSummaryTotalsAndAverages(selectedDays)
      .proteinPercentageAvg,

    sweetsSum: calculateCarbSourcesTotalsAndAverages(selectedDays).sweetsSum,
    sweetsAvg: calculateCarbSourcesTotalsAndAverages(selectedDays).sweetsAvg,

    seaFoodSum: calcualteProteinSourcesTotalsAndAverages(selectedDays)
      .seaFoodSum,
    seaFoodAvg: calcualteProteinSourcesTotalsAndAverages(selectedDays)
      .seaFoodAvg,

    redMeatSum: calcualteProteinSourcesTotalsAndAverages(selectedDays)
      .redMeatSum,
    redMeatAvg: calcualteProteinSourcesTotalsAndAverages(selectedDays)
      .redMeatAvg,

    whiteMeatSum: calcualteProteinSourcesTotalsAndAverages(selectedDays)
      .whiteMeatSum,
    whiteMeatAvg: calcualteProteinSourcesTotalsAndAverages(selectedDays)
      .whiteMeatAvg,

    nutsFreqSum: calculateFoodGroupsTotalsAndAverages(selectedDays).nutsFreqSum,
    nutsFreqAvg: calculateFoodGroupsTotalsAndAverages(selectedDays).nutsFreqAvg,
  };
};
