import { useFormContext } from "react-hook-form";
import { Button } from "../../../../../../../styles/classes/reusable-classes";
import { useActivePrescriptionItem } from "../../../context/active-prescription-context";
import { PrescriptionFormInputs } from "../../new-prescription";
import {
  NewPrescriptionFormFooterContainer,
  NewPrescriptionFormFooterSig,
} from "./styled-new-prescription-form-footer";

type NewPrescriptionFormFooterProps = {
  isValid: boolean;
  addSingleMedicine: () => void;
};

export const NewPrescriptionFormFooter = (
  props: NewPrescriptionFormFooterProps
) => {
  const { isValid, addSingleMedicine } = props;

  const { watch } = useFormContext<PrescriptionFormInputs>();

  const quantity = watch("quantity");
  const durationDays = watch("durationDays");
  const notes = watch("notes");
  const frequency = watch("frequency");
  const { activePrescriptionItem } = useActivePrescriptionItem();

  const sig = {
    quantity:
      quantity !== undefined
        ? `${quantity} ${
            activePrescriptionItem.medicine?.doseUnit !== undefined
              ? activePrescriptionItem.medicine?.doseUnit
              : ""
          },`
        : ``,
    frequency:
      frequency?.explanation !== undefined
        ? `${frequency?.explanation} (${frequency.abbreviation})`
        : ``,
    duration: durationDays !== undefined ? `for ${durationDays} days` : ``,
    notes: notes !== undefined ? `${notes}` : ``,
  };

  return (
    <NewPrescriptionFormFooterContainer>
      <NewPrescriptionFormFooterSig>
        <span>sig.</span>
        <p>{`${sig.quantity} ${sig.frequency} ${sig.duration} ${sig.notes}`}</p>
      </NewPrescriptionFormFooterSig>
      <Button
        type="button"
        outlinedPrimary
        disabled={!isValid}
        onClick={addSingleMedicine}
      >
        Add Medicine
      </Button>
    </NewPrescriptionFormFooterContainer>
  );
};
