import { ChangeEvent, FocusEvent, KeyboardEvent } from "react";
import { RE_DIGIT } from "./otp-inputs-reg";

export const onOtpInputFocus = (focusEvent: FocusEvent<HTMLInputElement>) => {
  const { target } = focusEvent;

  target.setSelectionRange(0, target.value.length);
};

export const focusOnPreviousInput = (target: HTMLElement) => {
  const previousElementSibling =
    target.previousElementSibling as HTMLInputElement | null;

  if (previousElementSibling) {
    previousElementSibling.focus();
  }
};

export const focusOnNextInput = (target: HTMLElement) => {
  const nextElementSibling =
    target.nextElementSibling as HTMLInputElement | null;

  if (nextElementSibling) {
    nextElementSibling.focus();
  }
};

export const onOtpInputKeydown = (
  keyboardEvent: KeyboardEvent<HTMLInputElement>
) => {
  // Delete values from inputs with backspace and focus on the previous input
  const target = keyboardEvent.target as HTMLInputElement;
  const targetValue = target.value;

  // keep the selection range position
  // if the same digit was typed
  target.setSelectionRange(0, targetValue.length);

  if (keyboardEvent.key !== "Backspace" || target.value !== "") {
    return;
  }

  focusOnPreviousInput(target);
  // Delete values from inputs with backspance and focus on the previous input
};

export const onOtpInputChange = (
  event: ChangeEvent<HTMLInputElement>,
  index: number,
  value: string,
  valueLength: number,
  onChange: (value: string) => void
) => {
  const target = event.target;
  let targetValue = target.value.trim();
  const isTargetValueDigit = RE_DIGIT.test(targetValue);

  if (!isTargetValueDigit && targetValue !== "") {
    return;
  }

  // the value should be something like this [1, 2, 3, 4, 5, 6]
  // if the value is deleted which means it's an empty string
  // the value will look something like this [1, 2, 4, 5, 6], will change the length
  // the next line will fix this to let the value be like this [1, 2, 3, 4, 5, " "]
  targetValue = isTargetValueDigit ? targetValue : " ";
  const targetValueLength = targetValue.length;

  if (targetValueLength === 1) {
    const newValue =
      value.substring(0, index) + targetValue + value.substring(index + 1);

    onChange(newValue);

    if (!isTargetValueDigit) {
      return;
    }
    focusOnNextInput(target);
  } else if (targetValueLength === valueLength) {
    onChange(targetValue);

    target.blur();
  }
  // autoFocus on next input
};
