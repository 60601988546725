import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  PatientProfileAutoSelectContainer,
  PatientProfileAutoSelectFormControlContainer,
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileTagList,
  PatientProfileTagListItem,
  PatientProfileTagsLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { AutoSelect } from "../../../../../../../../components/auto-select/auto-select";
import { useCallback, useState } from "react";
import { PutPatientMedicalHistoryDiabetesComplicationDTO } from "../../../../../../../../models/patient-medical-history-dtos/put-patient-medical-history-diabetes-complication-dto";
import { PatientProfileMedicalHistoryEditModalInputs } from "../../profile-medical-history-edit-modal";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";
import { useEnterKeypress } from "../../../../../../../../hooks/use-enter-keypress/use-enter-keypress";
import { usePatientProfileMedicalHistory } from "../../../../context/loadable-patient-profile-medical-history";

export const PateintProfileDiabetesComplicationsFormControl = () => {
  const { diabetesComplications } = usePatientProfileMedicalHistory();

  const findDiabetesComplicationByName = useCallback(
    (diabetesComplicationName: string) =>
      diabetesComplications.find(
        (item) => item.name === diabetesComplicationName
      ),
    [diabetesComplications]
  );

  const findDiabetesComplicationById = useCallback(
    (diabetesComplicationId: number) =>
      diabetesComplications.find((item) => item.id === diabetesComplicationId),
    [diabetesComplications]
  );

  const { control, watch } = useFormContext<
    PatientProfileMedicalHistoryEditModalInputs
  >();

  const { append, fields, remove } = useFieldArray({
    name: "diabetesComplications",
    control,
  } as never);

  const diabetesComplicationsWatcher = watch("diabetesComplications");

  const [searchTerm, setSearchTerm] = useState("");
  const [autoSelectTagValue, setAutoSelectTagValue] = useState<
    PutPatientMedicalHistoryDiabetesComplicationDTO | undefined
  >();

  const handleAppend = () => {
    if (
      diabetesComplicationsWatcher !== undefined &&
      autoSelectTagValue !== undefined &&
      !diabetesComplicationsWatcher.some(
        (obj) =>
          obj.diabetesComplicationId ===
          autoSelectTagValue.diabetesComplicationId
      ) &&
      diabetesComplications.some(
        (obj) =>
          obj ===
          findDiabetesComplicationById(
            autoSelectTagValue?.diabetesComplicationId
          )
      )
    ) {
      append(autoSelectTagValue);
      setSearchTerm("");
      setAutoSelectTagValue(undefined);
    }
  };

  const { onEnterKeyPress } = useEnterKeypress(handleAppend);

  return (
    <PatientProfileModalFormControl>
      <PatientProfileTagsLabelContainer>
        <label>Diabetes Related Complication</label>
      </PatientProfileTagsLabelContainer>
      <PatientProfileModalInputContainer>
        <Controller
          control={control}
          name="diabetesComplications"
          render={() => {
            return (
              <PatientProfileAutoSelectFormControlContainer>
                <PatientProfileAutoSelectContainer>
                  <AutoSelect
                    itemsArray={diabetesComplications
                      .filter((item) =>
                        item.name.toLocaleLowerCase().startsWith(searchTerm)
                      )
                      .map((item) => item.name)}
                    onChange={(value: string) => {
                      // This value should never be changed with undefined !
                      setAutoSelectTagValue({
                        diabetesComplicationId: findDiabetesComplicationByName(
                          value
                        )?.id!,
                      });
                      setSearchTerm(value);
                    }}
                    value={searchTerm}
                    fullWidth
                    placeHolder="Select Diabetes Complication"
                  />
                  <Button
                    type="button"
                    onClick={handleAppend}
                    onKeyDown={(e) => onEnterKeyPress(e)}
                    width={100}
                    height={40}
                    flex
                    outlineNoBorder
                  >
                    <span className="material-symbols-outlined">add</span> Add
                  </Button>
                </PatientProfileAutoSelectContainer>
                <PatientProfileTagList>
                  {fields.map((field, index) => (
                    <PatientProfileTagListItem key={field.id} variant="Normal">
                      {diabetesComplicationsWatcher !== undefined
                        ? findDiabetesComplicationById(
                            diabetesComplicationsWatcher[index]
                              .diabetesComplicationId
                          )?.name
                        : ""}
                      <span
                        className="material-symbols-outlined"
                        onClick={() => remove(index)}
                      >
                        close
                      </span>
                    </PatientProfileTagListItem>
                  ))}
                </PatientProfileTagList>
              </PatientProfileAutoSelectFormControlContainer>
            );
          }}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
