import styled from "styled-components/macro";
import { MedicationsInputCSS } from "../../components/css";
import { flexCenter } from "../../../../../styles/classes/gloabl-classes";

export const AddNoteModalBodyContainer = styled.div`
  display: grid;
  place-items: center;
`;

export const AddNoteTextAreaContainer = styled.div`
  ${flexCenter}
  background: ${({ theme }) => theme.colors.inputColor};
  border-radius: 5px;
  padding: ${({ theme }) => theme.padding.pad05All};
  margin: 24px 16px;
  width: 306px;
  height: 102px;
  position: relative;
  border: 0;
  outline: 0;
`;

export const AddNoteTextArea = styled.textarea`
  ${MedicationsInputCSS}
  resize: none;
  font-family: "IBM Plex Sans Arabic", sans-serif;
`;
