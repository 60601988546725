import { MarkerSettingsModel } from "@syncfusion/ej2-react-charts";

export const MealsMarker: MarkerSettingsModel = {
  visible: true,
  border: { width: 0 },
  shape: "Image",
  imageUrl: "/img/meals.svg",
  width: 20,
  height: 20,
};
