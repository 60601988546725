import { Temporal } from "temporal-polyfill";
import { PatientCGMEntryDTO } from "../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientType } from "../../../../models/patient-dtos/patient-type";
import { Reading } from "../../../cgm-google-chart/utils/build-days-aggregates";
import { CgmTooltipProps } from "../cgm-tooltip/cgm-tooltip";
import { GAP_THRESHOLD_MINUTES } from "../../../../constants/config";
import { isGlucosePointInRange } from "../../../../utils/glucose-point-utils/glucose-point-range-utils/is-glucose-point-in-range";

type ReadingDataSource = {
  x: Date;
  y?: number;
  tooltip?: CgmTooltipProps;
};

export function buildReadingsSereis(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientType: PatientType
): ReadingDataSource[] {
  const readingsSeries: ReadingDataSource[] = [];

  let lastPlainDateTime: Temporal.PlainDateTime | undefined = undefined;
  for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
    if (patientCGMEntryDTO.source === "Libreview") {
      const plainDateTime = Temporal.PlainDate.from(
        patientCGMEntryDTO.date
      ).toPlainDateTime(Temporal.PlainTime.from(patientCGMEntryDTO.time));

      if (
        lastPlainDateTime !== undefined &&
        Temporal.PlainDateTime.compare(
          lastPlainDateTime.add({ minutes: GAP_THRESHOLD_MINUTES }),
          plainDateTime
        ) === -1
      ) {
        readingsSeries.push({
          x: new Date(`${patientCGMEntryDTO.date}T${patientCGMEntryDTO.time}`),
        });
      }

      const reading: Reading = {
        glucose: patientCGMEntryDTO.glucoseMGPerDL,
        source: patientCGMEntryDTO.source,
        time: Temporal.PlainTime.from(patientCGMEntryDTO.time),
        tag: patientCGMEntryDTO.tag,
      };

      const tooltip: CgmTooltipProps = {
        x: new Date(`${patientCGMEntryDTO.date}T${patientCGMEntryDTO.time}`),
        y: patientCGMEntryDTO.glucoseMGPerDL,
        kind: {
          kind: "Reading",
          data: {
            ...patientCGMEntryDTO,
            isInRange: isGlucosePointInRange(patientType, reading)!,
          },
        },
      };

      readingsSeries.push({
        x: new Date(`${patientCGMEntryDTO.date}T${patientCGMEntryDTO.time}`),
        y: patientCGMEntryDTO.glucoseMGPerDL,
        tooltip,
      });

      lastPlainDateTime = plainDateTime;
    }
  }

  return readingsSeries;
}
