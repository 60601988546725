import { Link, useParams } from "react-router-dom";
import { useContent } from "../../context/loadable-content-context";
import { useMemo } from "react";
import { Button } from "../../../../styles/classes/reusable-classes";
import { Loader } from "../../../../components/loaders/otida-loader/styled-otida-loader";
import { useImageUploadHelpers } from "../../components/image-upload/context/image-upload-helpers-context";
import { useFormContext } from "react-hook-form";
import { CreateModuleInputs } from "./create-module";
import {
  ContentModulesFormHeaderContainer,
  ContentModulesFormHeaderButtonContainer,
  ContentModulesFormHeaderTitle,
} from "../../styles/content-modules-form-controllers/content-modules-form-controllers-css";

export const CreateModuleHeader = () => {
  const { courseID } = useParams();
  const contentCourseID = parseInt(courseID!);

  const { contentModuleDTOs } = useContent();
  const { loading } = useImageUploadHelpers();

  const { currentContentCourse } = useMemo(() => {
    const currentContentCourse = contentModuleDTOs.find(
      (contentCourse) => contentCourse.id === contentCourseID
    );

    return { currentContentCourse };
  }, [contentCourseID, contentModuleDTOs]);

  const { watch } = useFormContext<CreateModuleInputs>();
  const moduleNameWatcher = watch("name");
  const moduleUiOrderWatcher = watch("order");

  return (
    <ContentModulesFormHeaderContainer>
      <ContentModulesFormHeaderTitle>
        {currentContentCourse !== undefined && (
          <p dir="auto">
            <span>Course</span>
            <Link dir="auto" to={`/content/courses/course/${courseID}`}>
              {currentContentCourse.name}
            </Link>
          </p>
        )}
        {moduleNameWatcher !== undefined && moduleNameWatcher !== "" && (
          <>
            <span className="material-symbols-outlined">chevron_right</span>
            <p dir="auto">
              <span dir="auto">
                Module{" "}
                {moduleUiOrderWatcher !== undefined ? moduleUiOrderWatcher : ""}
              </span>
              {moduleNameWatcher}
            </p>
          </>
        )}
      </ContentModulesFormHeaderTitle>
      <ContentModulesFormHeaderButtonContainer>
        <Button type="submit" disabled={loading}>
          {loading ? <Loader radius={20} /> : `Save`}
        </Button>
      </ContentModulesFormHeaderButtonContainer>
    </ContentModulesFormHeaderContainer>
  );
};
