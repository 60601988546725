import { useCallback } from "react";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { PatientNutritionReportDailySummaryDTO } from "../../models/patient-nutrition-report-dtos/patient-nutrition-report-daily-summary-dto";

export function useGetPatientNutritionReportDailySummary(): (
  patientId: number,
  startDate: string,
  endDate: string
) => Promise<PatientNutritionReportDailySummaryDTO> {
  const client = useClient();

  return useCallback(
    async (patientId, startDate, endDate) => {
      const response = await client.get<PatientNutritionReportDailySummaryDTO>(
        `${PATIENTS_PATH}/${patientId}/nutrition-reports/daily-summary`,
        {
          params: {
            startDate,
            endDate,
          },
        }
      );

      return response.data;
    },
    [client]
  );
}
