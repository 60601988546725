import styled from "styled-components/macro";
import { OtidaSpinner } from "../animations/otida-loader-animation";

export const NoLogoLoaderLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem 0rem;
  align-self: center;
`;

export const NoLogoLoaderLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ::before {
    content: "";
    width: 35px;
    height: 35px;
    border: 3px solid ${({ theme }) => theme.newColors.byOtida};
    border-top-color: ${({ theme }) => theme.colors.containerColor};
    border-radius: 50%;
    animation: ${OtidaSpinner} 0.8s linear infinite;
  }
`;
