import {
  NutritionReportTableTable,
  NutritionReportTableTableContainer,
} from "./styled-nutrition-report-table";
import { NutritionReportTableHeader } from "./nutrition-report-table-header/nutrition-report-table-header";
import { NutritionReportTableHeaders } from "./nutrition-report-table-headers/nutrition-report-table-headers";
import { NutritionReportTableRows } from "./nutrition-report-table-rows/nutrition-report-table-rows";
import { NutritionReportTableFooter } from "./nutrition-report-table-footer/nutrition-report-table-footer";

export const NutritionReportTable = () => {
  return (
    <>
      <NutritionReportTableHeader />
      <NutritionReportTableTableContainer>
        <NutritionReportTableTable>
          <NutritionReportTableHeaders />
          <NutritionReportTableRows />
        </NutritionReportTableTable>
      </NutritionReportTableTableContainer>
      <NutritionReportTableFooter />
    </>
  );
};
