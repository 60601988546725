import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PatientMealDTO } from "../../models/patient-meal-dtos/patient-meal-dto";

export function useDeletePatientMeal(): (
  patientMealDTO: PatientMealDTO
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientMealDTO) => {
      await client.delete<void>(
        `${PATIENTS_PATH}/${patientMealDTO.patientId}/meals/${patientMealDTO.id}`
      );
    },
    [client]
  );
}
