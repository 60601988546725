import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetPatientCGMEntries } from "../../../../../../../hooks/use-get-patient-cgm-entries";
import { PatientCGMEntryDTO } from "../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";

const useOnReadingsChanged = (patientPlansPatientId?: number) => {
  const [patientData, setPatientData] = useState<PatientCGMEntryDTO[]>([]);

  const { id } = useParams<{ id: string }>();
  const getPatientCGMEntries = useGetPatientCGMEntries();

  const patientID =
    patientPlansPatientId !== undefined ? patientPlansPatientId : parseInt(id!);

  const load = useCallback(
    () => getPatientCGMEntries(patientID, "Manual"),
    [getPatientCGMEntries, patientID]
  );

  const onReadingsChanged = () => {
    load().then((res) => setPatientData(res));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onReadingsChanged(), []);

  return { onReadingsChanged, patientData };
};

export default useOnReadingsChanged;
