import {
  NutritionReportCellKind,
  NutritionReportThContainerVariants,
} from "../../types/nutrition-report-table-types";
import {
  NutritionReportTableRowTD,
  NutritionReportTableRowTDContainer,
  NutritionReportTableRowTDText,
} from "./styled-nutrition-report-table-cell";

type NutritionReportTableCellProps = {
  type: NutritionReportThContainerVariants;
  value?: number;
  values?: (number | undefined)[];
  stringValue?: string;
  cellKind?: NutritionReportCellKind;
};

export const NutritionReportTableCell = (
  props: NutritionReportTableCellProps
) => {
  const { type, value, values, stringValue, cellKind } = props;

  if (
    !type.includes("Dairy") &&
    !type.includes("Meat") &&
    !type.includes("Fat") &&
    stringValue === undefined
  ) {
    return (
      <NutritionReportTableRowTD type={type} cellKind={cellKind}>
        <NutritionReportTableRowTDContainer>
          <NutritionReportTableRowTDText cellKind={cellKind}>
            {value}
          </NutritionReportTableRowTDText>
        </NutritionReportTableRowTDContainer>
      </NutritionReportTableRowTD>
    );
  } else if (stringValue !== undefined) {
    return (
      <NutritionReportTableRowTD type={type} cellKind={cellKind}>
        <NutritionReportTableRowTDContainer>
          <NutritionReportTableRowTDText cellKind={cellKind}>
            {stringValue}
          </NutritionReportTableRowTDText>
        </NutritionReportTableRowTDContainer>
      </NutritionReportTableRowTD>
    );
  } else if (
    stringValue === undefined &&
    (type.includes("Dairy") || type.includes("Meat") || type.includes("Fat"))
  ) {
    return (
      <NutritionReportTableRowTD type={type} cellKind={cellKind}>
        <NutritionReportTableRowTDContainer>
          {values?.map((nestedValue, index) => (
            <NutritionReportTableRowTDText key={index} cellKind={cellKind}>
              {nestedValue !== undefined ? nestedValue : ""}
            </NutritionReportTableRowTDText>
          ))}
        </NutritionReportTableRowTDContainer>
      </NutritionReportTableRowTD>
    );
  } else {
    return <></>;
  }
};
