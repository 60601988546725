import { useNavigate } from "react-router-dom";
import {
  ListPlansLIContainerIcon,
  ListPlansLIContainerText,
} from "../../styled-plans";
import {
  ViewWeeklyPlansList,
  ViewWeeklyPlansListItem,
} from "./styled-view-weekly-plans";
import { usePatient } from "../../../context/loadable-patient-context";

export const WeeklyPlansList = () => {
  const { patientPlans } = usePatient();
  const navigate = useNavigate();

  const plans = patientPlans
    .sort((a, b) => b.planNumber - a.planNumber)
    .map((plan) => (
      <ViewWeeklyPlansListItem
        key={plan.id}
        onClick={() => navigate(`../plans-list/${plan.id}`)}
      >
        <ListPlansLIContainerIcon>
          <span className="material-symbols-outlined">description</span>
        </ListPlansLIContainerIcon>
        <ListPlansLIContainerText>
          <p>{`(${plan.planNumber}) خطة الإسبوع`}</p>{" "}
          <span>{plan.createdAt.split("T")[0].toString()}</span>
        </ListPlansLIContainerText>
      </ViewWeeklyPlansListItem>
    ));

  return <ViewWeeklyPlansList>{plans}</ViewWeeklyPlansList>;
};
