import { useMemo } from "react";
import { useNutritionReportSelectedTableRows } from "../../../context/nutrition-report-context/nutrition-report-selected-table-rows-context";
import {
  NutritionReportTableRowTD,
  NutritionReportTableRowTDContainer,
  NutritionReportTableRowTDText,
} from "../nutrition-report-table-cells/components/styled-nutrition-report-table-cell";
import { NutritionReportTableRowTR } from "../nutrition-report-table-row/styled-nutrition-report-table-row";
import { calculateTotalsAndAverages } from "../utils/calculate-totals-and-averages/calculate-totals-and-averages";
import { NutritionReportTableCell } from "../nutrition-report-table-cells/components/nutrition-report-table-cell";

type NutritionReportTotalOrAverageTableRowProps = {
  type: "Total" | "Average";
};

export const NutritionReportTotalOrAverageTableRow = (
  props: NutritionReportTotalOrAverageTableRowProps
) => {
  const { type } = props;

  const { selectedDays } = useNutritionReportSelectedTableRows();

  const totalsAndAverages = useMemo(
    () => calculateTotalsAndAverages(selectedDays),
    [selectedDays]
  );

  const meatTotals = [
    totalsAndAverages.meatsSum.highFat,
    totalsAndAverages.meatsSum.mediumFat,
    totalsAndAverages.meatsSum.lowFat,
    totalsAndAverages.meatsSum.veryLean,
  ];

  const meatAverages = [
    totalsAndAverages.meatsAvg.highFat,
    totalsAndAverages.meatsAvg.mediumFat,
    totalsAndAverages.meatsAvg.lowFat,
    totalsAndAverages.meatsAvg.veryLean,
  ];

  const dairyTotals = [
    totalsAndAverages.dairySum.highFat,
    totalsAndAverages.dairySum.mediumFat,
    totalsAndAverages.dairySum.lowFat,
  ];

  const dairyAverages = [
    totalsAndAverages.dairyAvg.highFat,
    totalsAndAverages.dairyAvg.mediumFat,
    totalsAndAverages.dairyAvg.lowFat,
  ];

  const fatTotals = [
    totalsAndAverages.fatsSum.trans,
    totalsAndAverages.fatsSum.saturated,
    totalsAndAverages.fatsSum.unsaturated,
    totalsAndAverages.fatsSum.nuts,
    totalsAndAverages.fatsSum.legacy,
  ];

  const fatAverages = [
    totalsAndAverages.fatsAvg.trans,
    totalsAndAverages.fatsAvg.saturated,
    totalsAndAverages.fatsAvg.unsaturated,
    totalsAndAverages.fatsAvg.nuts,
    totalsAndAverages.fatsAvg.legacy,
  ];

  return (
    <NutritionReportTableRowTR fade={false} showList={true}>
      <NutritionReportTableRowTD type={type} sticky={true}>
        <NutritionReportTableRowTDContainer>
          <NutritionReportTableRowTDText>
            {type === "Average" ? "Average per day" : type}
          </NutritionReportTableRowTDText>
        </NutritionReportTableRowTDContainer>
      </NutritionReportTableRowTD>

      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.numberOfMealsSum
            : totalsAndAverages.numberOfMealsAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.starchesSum
            : totalsAndAverages.starchesAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.legumesSum
            : totalsAndAverages.legumesAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.fruitsSum
            : totalsAndAverages.fruitsAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.vegetablesSum
            : totalsAndAverages.vegetablesAvg
        }
      />
      <NutritionReportTableCell
        type={`${type === "Total" ? "Total" : "Average"}-Meat`}
        values={type === "Total" ? meatTotals : meatAverages}
      />
      <NutritionReportTableCell
        type={`${type === "Total" ? "Total" : "Average"}-Dairy`}
        values={type === "Total" ? dairyTotals : dairyAverages}
      />
      <NutritionReportTableCell
        type={`${type === "Total" ? "Total" : "Average"}-Fat`}
        values={type === "Total" ? fatTotals : fatAverages}
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.caffBevSum
            : totalsAndAverages.caffBevAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total" ? totalsAndAverages.naSum : totalsAndAverages.naAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total" ? totalsAndAverages.kSum : totalsAndAverages.kAvg
        }
      />

      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.kcalSum
            : totalsAndAverages.kcalAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.carbsGmSum
            : totalsAndAverages.carbsGmAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.fatGmSum
            : totalsAndAverages.fatGmAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.proteinGmSum
            : totalsAndAverages.proteinGmAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        stringValue={
          type === "Total" ? `` : `${totalsAndAverages.carbPercentageAvg}%`
        }
      />
      <NutritionReportTableCell
        type={type}
        stringValue={
          type === "Total" ? `` : `${totalsAndAverages.fatPercentageAvg}%`
        }
      />
      <NutritionReportTableCell
        type={type}
        stringValue={
          type === "Total" ? `` : `${totalsAndAverages.proteinPercentageAvg}%`
        }
      />

      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.sweetsSum
            : totalsAndAverages.sweetsAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.seaFoodSum
            : totalsAndAverages.seaFoodAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.redMeatSum
            : totalsAndAverages.redMeatAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.whiteMeatSum
            : totalsAndAverages.whiteMeatAvg
        }
      />
      <NutritionReportTableCell
        type={type}
        value={
          type === "Total"
            ? totalsAndAverages.nutsFreqSum
            : totalsAndAverages.nutsFreqAvg
        }
      />
      <NutritionReportTableCell type={type} stringValue="" />
      <NutritionReportTableCell type={type} stringValue="" />
      <NutritionReportTableCell type={type} stringValue="" />
    </NutritionReportTableRowTR>
  );
};
