import { TH } from "../../../../components/table/styled-table";
import { useImmediateAttentionPatientsSelectedRowsRows } from "../context/immediate-attention-patients-selected-rows-context";

export const ImmediateAttentionPatientsTableHeadings = (props: {
  allPatinets?: boolean;
}) => {
  const { allPatinets } = props;

  const {
    allRowsSelectedFlag,
    toggleAllRows,
  } = useImmediateAttentionPatientsSelectedRowsRows();

  const allPatientsHeadings = [
    "",
    "Patient Name",
    "Diagnosis",
    "Visits",
    "Complications",
    "Actions",
  ];

  const immediateAttentionPatientsHeadings = [
    "",
    "Patient Name",
    "Diagnosis",
    "Alerts",
    "Priority",
    "Visits",
    "Complications",
    "Actions",
  ];

  const tableHeadings = allPatinets
    ? allPatientsHeadings
    : immediateAttentionPatientsHeadings;

  return (
    <>
      {tableHeadings.map((heading, index) => {
        if (index === 0) {
          return (
            <TH
              key={index}
              actions={false}
              style={{
                textAlign: "left",
              }}
            >
              <input
                type="checkbox"
                checked={allRowsSelectedFlag}
                onChange={toggleAllRows}
              />
            </TH>
          );
        } else {
          return (
            <TH
              key={index}
              actions={heading === "Actions" ? true : false}
              style={{
                textAlign: "left",
              }}
            >
              {heading}
            </TH>
          );
        }
      })}
    </>
  );
};
