import { useNutritionGoalsDropdownText } from "../context/nutrition-goals-dropdown-text-context";
import { useNutritionGolasLastFoodGroupSelected } from "../context/nutrition-goals-last-food-group-selected-context";
import {
  NutritionGoalsBodyListItem,
  NutritionGoalsBodyListItemInput,
  NutritionGoalsBodyListItemText,
} from "./styled-nutrition-goals-body";

export const NutritionGoalsBodyBuiltInListItem = () => {
  const { setDropdownText } = useNutritionGoalsDropdownText();

  const { lastFoodGroupSelected } = useNutritionGolasLastFoodGroupSelected();

  return (
    <>
      {lastFoodGroupSelected?.nutritionRegimenFoodGroups.map((item) => (
        <NutritionGoalsBodyListItem key={item.foodGroup.id}>
          <NutritionGoalsBodyListItemText>
            <img src={item.foodGroup.iconUrl} alt={item.foodGroup.name} />
            <p>{item.foodGroup.name}</p>
          </NutritionGoalsBodyListItemText>
          <NutritionGoalsBodyListItemInput>
            <input
              type="number"
              value={item.dailyServingsCount}
              onChange={(e) => {
                if (
                  lastFoodGroupSelected !== undefined &&
                  lastFoodGroupSelected?.customForPatientId === undefined
                ) {
                  setDropdownText("Custom");
                }
              }}
              step={0.1}
              placeholder="-"
            />
          </NutritionGoalsBodyListItemInput>
        </NutritionGoalsBodyListItem>
      ))}
    </>
  );
};
