import styled from "styled-components/macro";
import { regularMediumFont } from "../../../../../styles/classes/gloabl-classes";

export const GoalsScreenContainer = styled.div`
  padding: 16px 24px;
`;

export const AssignedGoalsContainer = styled.ul`
  padding: 0;
  margin: 0;
`;

export const NewGoalsContainer = styled.ul`
  padding: 0;
  margin: 0;
`;

export const ContainerTitle = styled.p`
  margin: 16px 0px;
  color: ${({ theme }) => theme.colors.titleColor};
  ${regularMediumFont}
`;
