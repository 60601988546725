import { PatientMedicationDTO } from "../../../../../models/patient-medication-dto";
import { generateSig } from "../../utils/medications-utils";
import {
  MedicationCardIconContainer,
  MedicationCardText,
  MedicationCardTitle,
} from "./styled-medication-card";

type MedicationCardWithSigProps = {
  patientMedicationDTO: PatientMedicationDTO;
};

export const MedicationCardWithSig = (props: MedicationCardWithSigProps) => {
  const { patientMedicationDTO } = props;

  return (
    <>
      <MedicationCardIconContainer>
        <span className="material-symbols-outlined">medication</span>
      </MedicationCardIconContainer>
      <div>
        <MedicationCardTitle>
          {patientMedicationDTO.medication.name}{" "}
          {patientMedicationDTO.medication.strength !== undefined && (
            <span>- {patientMedicationDTO.medication.strength}</span>
          )}
        </MedicationCardTitle>
        <MedicationCardText>
          {`${generateSig(patientMedicationDTO).quantity} ${
            generateSig(patientMedicationDTO).frequency
          } ${generateSig(patientMedicationDTO).duration} ${
            generateSig(patientMedicationDTO).notes
          }`}
        </MedicationCardText>
      </div>
    </>
  );
};
