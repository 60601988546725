import { VideoUploadHelpersProvider } from "../../../../../../../components/video-upload/context/video-upload-helpers-context";
import { LessonUpdateModeCardVideoPartController } from "./lesson-update-mode-card-video-part-controller";

type LessonUpdateModeCardVideoPartProps = {
  cardIndex: number;
  cardPartIndex: number;
};

export const LessonUpdateModeCardVideoPart = (
  props: LessonUpdateModeCardVideoPartProps
) => {
  const { cardIndex, cardPartIndex } = props;

  return (
    <VideoUploadHelpersProvider>
      <LessonUpdateModeCardVideoPartController
        cardIndex={cardIndex}
        cardPartIndex={cardPartIndex}
      />
    </VideoUploadHelpersProvider>
  );
};
