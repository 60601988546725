import { Navigate, Route, Routes } from "react-router-dom";
import { ViewPlans } from "./screens/single-patient/plans/view-plans/view-plans";
import { ViewPlansHome } from "./screens/single-patient/plans/view-plans/view-plans-home/view-plans-home";
import { ViewWeeklyPlans } from "./screens/single-patient/plans/view-plans/view-weekly-plans/view-weekly-plans";
import { WeeklyPlansList } from "./screens/single-patient/plans/view-plans/view-weekly-plans/weekly-plans-list";
import { ViewPlansMedications } from "./screens/single-patient/plans/view-plans/view-plans-medications/view-plans-medications";
import { SinglePlan } from "./screens/single-patient/plans/view-plans/view-weekly-plans/single-plan/single-plan";
import { PatientHome } from "./screens-patient/patient-home";

export function PatientAuthenticatedApp() {
  return (
    <Routes>
      <Route path="/" element={<PatientHome />}>
        <Route index element={<Navigate to="view-plans" replace={true} />} />
        <Route path="view-plans" element={<ViewPlans />}>
          <Route index element={<Navigate to="home" replace={true} />} />
          <Route path="home" element={<ViewPlansHome />} />
          <Route path="patient-plans" element={<ViewWeeklyPlans />}>
            <Route
              index
              element={<Navigate to="plans-list" replace={true} />}
            />
            <Route path="plans-list" element={<WeeklyPlansList />} />
            <Route path="medications" element={<ViewPlansMedications />} />
            <Route path="plans-list/:viewPlanID" element={<SinglePlan />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="view-plans" replace={true} />} />
      </Route>
    </Routes>
  );
}
