import styled from "styled-components/macro";

export const CrudListContainer = styled.div`
  display: flex;
  position: relative;
`;

export const ActionIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 0rem 0.6rem;
`;
