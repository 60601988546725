import { Reading } from "../../../components/cgm-google-chart/utils/build-days-aggregates";
import { DaysAggregatesReadingsWithTimeDifferenceValue } from "../build-readings-with-time-differance-value";

export function removeTimeDifferanceValueFromReading(
  readingWithTimeDifferenceValue: DaysAggregatesReadingsWithTimeDifferenceValue
): Reading {
  return {
    glucose: readingWithTimeDifferenceValue.glucose,
    time: readingWithTimeDifferenceValue.time,
    source: readingWithTimeDifferenceValue.source,
    tag: readingWithTimeDifferenceValue.tag,
  };
}
