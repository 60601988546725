import styled from "styled-components/macro";

export const PrescriptionSidebarCardContainer = styled.div<{
  activeState?: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => theme.padding.pad05_1};
  gap: 1rem;
  border: 1px solid
    ${({ theme, activeState }) =>
      activeState ? theme.colors.success : theme.colors.borderColor};
  width: 100%;
  cursor: pointer;
  min-height: 65px;
  height: fit-content;
  background: ${({ activeState, theme }) =>
    activeState ? theme.newColors.activeState : theme.colors.containerColor};
`;
