import { useLocation } from "react-router-dom";
import {
  NavLink,
  ScreenNavbar,
} from "../../../components/screen-navbar/screen-navbar";
import { HomeHeaderContainer, UnderDevelopmentTag } from "./styled-home-header";
import { useHomeStaticData } from "../context/home-static-data-context";

export const HomeHeader = () => {
  const location = useLocation();

  const { homeStaticData } = useHomeStaticData();

  return (
    <HomeHeaderContainer>
      <h1>{location.pathname.split("/")[2].split("-").join(" ")}</h1>
      <UnderDevelopmentTag>Under Development</UnderDevelopmentTag>
      <ScreenNavbar links={buildNavbarLinks(homeStaticData.length)} />
    </HomeHeaderContainer>
  );
};

function buildNavbarLinks(dataLength: number): NavLink[] {
  const navLinks = [
    {
      name: `Immediate Attention (${dataLength + 6})`,
      path: `/home/immediate-attention-patients/1`,
    },
    {
      name: `All (${dataLength + 34})`,
      path: `/home/all-patients/1`,
    },
  ];

  return navLinks;
}
