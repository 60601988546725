import { Reading } from "../../components/cgm-google-chart/utils/build-days-aggregates";
import { calculateTimeDifferences } from "./calculate-time-differences";
import { convertReadingsToTimeStrings } from "./convert-readings-to-time-strings";

export type DaysAggregatesReadingsWithTimeDifferenceValue = Reading & {
  timeDifference: number;
};

export function buildDaysAggregatesReadingsWithTimeDifference(
  readings: Reading[]
): DaysAggregatesReadingsWithTimeDifferenceValue[] {
  const daysAggregatesReadingsWithTimeDifference: DaysAggregatesReadingsWithTimeDifferenceValue[] = readings.map(
    (reading, index) => {
      return {
        ...reading,
        timeDifference: calculateTimeDifferences(
          convertReadingsToTimeStrings(readings)
        )[index],
      };
    }
  );

  return daysAggregatesReadingsWithTimeDifference;
}
