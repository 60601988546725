import { useCallback } from "react";
import { PatientInsulinLogDTO } from "../../models/patient-insulin-log-dtos/patient-insulin-log-dto";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";

export function useGetPatientInsulinLogs(): (
  patientId: number,
  startDate?: string,
  endDate?: string
) => Promise<PatientInsulinLogDTO[]> {
  const client = useClient();

  return useCallback(
    async (patientId, startDate = undefined, endDate = undefined) => {
      const response = await client.get<PatientInsulinLogDTO[]>(
        `${PATIENTS_PATH}/${patientId}/insulin-logs`,
        {
          params: {
            startDate,
            endDate,
          },
        }
      );

      return response.data;
    },
    [client]
  );
}
