import styled from "styled-components/macro";
import { regularMediumFont } from "../../../../../styles/classes/gloabl-classes";

export const FoodLoggingSelectedPatientMealFeedbackContainer = styled.div`
  margin-top: 16px;
`;

export const FoodLoggingSelectedPatientMealFeedbackInputContainer = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: ${({ theme }) => theme.padding.pad05_1};
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.inputColor};
  margin-top: 16px;

  input {
    ${regularMediumFont}
    width: 100%;
    background: transparent;
    border: 0px;
    outline: 0px;
  }
`;
