import { MedicationDTO } from "../../../../../../models/medications/medication-dto";
import { NewPrescriptionSidebar } from "./new-prescription-sidebar/new-prescription-sidebar";
import { AddPrescriptionForm } from "./add-prescription-form/add-prescription-form";
import {
  NewPrescriptionBodyContainer,
  NewPrescriptionBodyMain,
} from "./styled-new-prescription-body";
import { calculateHeightAndMarginTop } from "../../utils/prescription-utils";
import { usePrescriptions } from "../../context/prescriptions-context";
import { useActivePrescriptionItem } from "../../context/active-prescription-context";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";

export type ActivePrescriptionDataType = {
  id?: number;
  medicine?: MedicationDTO;
};

export const NewPrescriptionBody = () => {
  const { prescriptionMedicines } = usePrescriptions();
  const { activePrescriptionItem } = useActivePrescriptionItem();

  return (
    <NewPrescriptionBodyContainer
      calculateMarginTop={calculateHeightAndMarginTop(
        prescriptionMedicines.length > 0
      )}
    >
      <NewPrescriptionSidebar />
      <NewPrescriptionBodyMain>
        {activePrescriptionItem.medicine === undefined ? (
          <EmptyState
            title=""
            text="Select a prescription to preview it"
            hasSearch={false}
            hasHeader={false}
            button={<></>}
          />
        ) : (
          <AddPrescriptionForm />
        )}
      </NewPrescriptionBodyMain>
    </NewPrescriptionBodyContainer>
  );
};
