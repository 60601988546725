import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  flexCenterSpaceBetween,
  smallSemiBoldFont,
} from "../../../../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../../../../styles/global-style";
import { ChartsContainerCSS } from "../../feedback-modal-body/styled-feedback-modal-body";

export const GlucoseChartsContainer = styled.div`
  ${ChartsContainerCSS}
`;

export const GlucoseChartsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 270px);
  gap: 0.4rem;
  place-items: center;
  justify-content: center;
`;

export const GlucoseChartsCard = styled.div<{ viewPlanChart: boolean }>`
  border-radius: 8px;
  border: ${({ viewPlanChart, theme }) =>
    viewPlanChart ? "0px" : `1px solid ${theme.colors.borderColor}`};
  padding: ${({ theme, viewPlanChart }) =>
    viewPlanChart ? "0px" : theme.padding.pad05All};
  min-width: 270px;
  width: ${({ viewPlanChart }) => (viewPlanChart ? "100%" : "auto")};
  transition: ${transition};
`;

export const GlucoseChartsCardHeader = styled.div`
  ${flexCenterSpaceBetween}

  p {
    ${flexCenter}
    gap: 0px 4px;
    ${smallSemiBoldFont}
    color: ${({ theme }) => theme.colors.titleColor};

    span {
      font-size: 14px;
    }
  }
`;

export const GlucoseChartContainer = styled.div<{ viewPlanChart?: boolean }>`
  border-radius: 8px;
  border: ${({ viewPlanChart, theme }) =>
    viewPlanChart ? "0px" : `1px solid ${theme.colors.borderColor}`};
  padding: ${({ theme, viewPlanChart }) =>
    viewPlanChart ? "0px" : theme.padding.pad05All};
  padding-bottom: 0;
`;

export const GlucoseChartContainerHeader = styled.div<{
  viewPlanChart?: boolean;
}>`
  ${({ viewPlanChart }) =>
    viewPlanChart ? flexCenterSpaceBetween : flexCenterCenter};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding-bottom: 0.5rem;
`;
