import { Temporal } from "temporal-polyfill";
import {
  PatientAge,
  PatientGender,
  PatientName,
  PatientSidebarInfoContainer,
} from "./styled-patient-sidebar-info";
import { FEATURE_FLAG_FRONTEND_DEMO } from "../../../../constants/config";
import { useSinglePatientSidebar } from "../context/loadable-single-patient-sidebar-context";

export const PatientSidebarInfo = () => {
  const { patientDTO } = useSinglePatientSidebar();

  function calculateAge(dateString: string) {
    const today = Temporal.Now.plainDateISO();
    const birthDate = Temporal.PlainDate.from(dateString);
    let age = today.year - birthDate.year;
    let month = today.month - birthDate.month;

    if (month < 0 || (month === 0 && today.day < birthDate.day)) {
      age--;
    }

    return age;
  }

  const age =
    patientDTO.dateOfBirth !== undefined
      ? calculateAge(patientDTO.dateOfBirth)
      : "";

  const gender = patientDTO.sex !== undefined ? patientDTO.sex : "";

  const demoCondition = FEATURE_FLAG_FRONTEND_DEMO && patientDTO.id === 83;

  return (
    <PatientSidebarInfoContainer>
      {demoCondition && <img src="/img/demo/human.png" alt="human" />}
      <PatientName>{patientDTO.name}</PatientName>
      <PatientAge>
        {age} Years - <PatientGender gender={gender}>{gender}</PatientGender>
      </PatientAge>
    </PatientSidebarInfoContainer>
  );
};
