import { FormProvider, useForm } from "react-hook-form";
import { PatientPlanFeedbackTone } from "../../../../../../../models/patient-plan-dtos/patient-plan-feedback-dto";
import { UpdatePatientPlanPatientPlanFeedbackAttachmentDTO } from "../../../../../../../models/patient-plan-dtos/update-patient-plan-patient-plan-feedback-attachment-dto";
import { MealGraphsModal } from "./meal-graphs-modal";

type MealGraphsContainerProps = {
  resetCurrentFeedbackModal: () => void;
};

export type MealGraphsModalInputs = {
  feedbackTone?: PatientPlanFeedbackTone;
  notes?: string;
  attachments: UpdatePatientPlanPatientPlanFeedbackAttachmentDTO[];
};

export const MealGraphsContainer = (props: MealGraphsContainerProps) => {
  const { resetCurrentFeedbackModal } = props;

  const methods = useForm<MealGraphsModalInputs>({
    defaultValues: {
      feedbackTone: undefined,
      notes: undefined,
      attachments: [],
    },
  });

  return (
    <FormProvider {...methods}>
      <MealGraphsModal resetCurrentFeedbackModal={resetCurrentFeedbackModal} />
    </FormProvider>
  );
};
