import styled from "styled-components/macro";
import { TableHeaderContainer } from "../../../../components/table/table-header/styled-table-header";
import { TableContainer } from "../../../../components/table/styled-table";
import {
  ContentScreenTableRowsStatusContainer,
  ContentScreenTableRowsThumbnailContainer,
} from "../../styles/content-reusable-classes";

export const ContentCourseModulesTableContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0px;
  }

  ${TableContainer} {
    padding: 0px;
  }

  ${TableHeaderContainer} {
    p {
      margin: 10px 0px;
    }
  }

  thead {
    tr {
      background: ${({ theme }) => theme.colors.borderColor};

      th {
        div {
          padding: 8px;
          margin-bottom: 0px;
        }
      }
    }
  }

  ${ContentScreenTableRowsThumbnailContainer} {
    padding: 8px;
  }

  ${ContentScreenTableRowsStatusContainer} {
    padding: 8px;
  }
`;
