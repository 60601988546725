import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  smallMediumFont,
  smallRegularFont,
} from "../../../../../../styles/classes/gloabl-classes";
import { LabResultsHeaderContainerCSS } from "../../../components/styles";

export const LabResultTestRefsContainer = styled.div`
  width: 200px;
`;

export const LabResultTestRefsHeader = styled.div`
  ${LabResultsHeaderContainerCSS}
  height: 84px;
  padding: 16px 24px 16px 0px;
  border-top-right-radius: 14px;
`;

export const LabResultTestRefsSubContainer = styled.div`
  height: 77px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 12px 24px 12px 0px;
  ${flexCenterSpaceBetween}

  p {
    ${smallMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
  }
`;

export const LabResultTestRefsTagContainer = styled.div`
  ${flexCenterSpaceBetween}
  gap: 0px 16px;

  span {
    ${smallRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
