import { Controller, useFormContext } from "react-hook-form";
import { CreateModuleInputs } from "../../create-module";
import {
  UXInputContainer,
  UXInputLabel,
  UXInputTextarea,
  UXInputWrapper,
} from "../../../../../../styles/classes/reusable-classes";

export const CreateModuleObjectiveController = () => {
  const { control } = useFormContext<CreateModuleInputs>();

  return (
    <Controller
      control={control}
      name="objective"
      render={({ field: { value, onChange } }) => (
        <UXInputContainer $textareaDimentions>
          <UXInputLabel $width="150px" $isOptional>
            Module Objective <span>(Optional)</span>
          </UXInputLabel>
          <UXInputWrapper $width="80%" $textareaDimentions>
            <UXInputTextarea
              dir="auto"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Enter Module Objective (Optional)"
            />
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
