import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { CarbSourceDTO } from "../../../models/carb-sources/carb-source-dto";
import { FoodGroupDTO } from "../../../models/food-groups/food-group-dto";
import { PatientDTO } from "../../../models/patient-dtos/patient-dto";
import { ProteinSourceDTO } from "../../../models/protein-sources/protein-source-dto";
import { PatientMealDTO } from "../../../models/patient-meal-dtos/patient-meal-dto";

type FoodLoggingDataContextType = {
  patientDTOs: PatientDTO[];
  carbSourceDTOs: CarbSourceDTO[];
  foodGroupDTOs: FoodGroupDTO[];
  proteinSources: ProteinSourceDTO[];
  selectedPatientDTO?: PatientDTO;
  setSelectedPatientDTO: (selectedPatientDTO?: PatientDTO) => void;
  selectedPatientMealDTO?: PatientMealDTO;
  setSelectedPatientMealDTO: (selectedPatientMealDTO?: PatientMealDTO) => void;
};

const FoodLoggingDataContext = createContext<
  FoodLoggingDataContextType | undefined
>(undefined);

type FoodLoggingDataProviderProps = PropsWithChildren<{
  patientDTOs: PatientDTO[];
  carbSourceDTOs: CarbSourceDTO[];
  foodGroupDTOs: FoodGroupDTO[];
  proteinSources: ProteinSourceDTO[];
}>;

export const FoodLoggingDataProvider = (
  props: FoodLoggingDataProviderProps
) => {
  const {
    children,
    patientDTOs,
    carbSourceDTOs,
    foodGroupDTOs,
    proteinSources,
  } = props;

  const [selectedPatientDTO, setSelectedPatientDTO] = useState<PatientDTO>();
  const [selectedPatientMealDTO, setSelectedPatientMealDTO] =
    useState<PatientMealDTO>();

  const value = useMemo(
    () => ({
      patientDTOs,
      carbSourceDTOs,
      foodGroupDTOs,
      proteinSources,
      selectedPatientDTO,
      setSelectedPatientDTO,
      selectedPatientMealDTO,
      setSelectedPatientMealDTO,
    }),
    [
      patientDTOs,
      carbSourceDTOs,
      foodGroupDTOs,
      proteinSources,
      selectedPatientDTO,
      setSelectedPatientDTO,
      selectedPatientMealDTO,
      setSelectedPatientMealDTO,
    ]
  );

  return (
    <FoodLoggingDataContext.Provider value={value}>
      {children}
    </FoodLoggingDataContext.Provider>
  );
};

export const useFoodLoggingData = (): FoodLoggingDataContextType => {
  const foodLoggingData = useContext(FoodLoggingDataContext);

  if (foodLoggingData === undefined) {
    throw new Error(
      `useFoodLoggingData must be within FoodLoggingDataProvider`
    );
  }

  return foodLoggingData;
};
