import { Reading } from "../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { roundTo1DecimalPlace } from "../../../math-utils";
import { buildDaysAggregatesReadingsWithTimeDifference } from "../../build-readings-with-time-differance-value";
import { calculateReadingsTimeDiffrenceTotal } from "../../calculate-readings-time-differance-total";
import { calculateVeryHighReadingsTimeDiffrenceTotal } from "../calculate-very-high-readings-time-differance-total";

export function calculateVeryHighPercentage(readings: Reading[]) {
  const readingsWithTimeDifferenceValue = buildDaysAggregatesReadingsWithTimeDifference(
    readings
  );

  const veryHighReadingsTimeDiffrenceTotal = calculateVeryHighReadingsTimeDiffrenceTotal(
    readingsWithTimeDifferenceValue
  );
  const readingsTimeDifferanceTotal = calculateReadingsTimeDiffrenceTotal(
    readingsWithTimeDifferenceValue
  );

  const veryHighPercentage = roundTo1DecimalPlace(
    (veryHighReadingsTimeDiffrenceTotal / readingsTimeDifferanceTotal) * 100
  );

  return veryHighPercentage;
}
