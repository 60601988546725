import { PatientMealDTO } from "../../../../models/patient-meal-dtos/patient-meal-dto";
import { FoodLoggingPatientsTab } from "../../food-logging-patients/food-logging-patients-tabs/food-logging-patients-tabs";
import { AnalyzedCarbCounterMeal } from "./analyzed-carb-counter-meal";
import { AnalyzingCarbCounterMeal } from "./analyzing-carb-counter-meal";
import { useCarbCounterMealState } from "./context/carb-counter-meal-state-context";
import { IdleCarbCounterMeal } from "./idle-carb-counter-meal";
import { CarbCounterFoodLoggingSelectedPatientMealLayout } from "./carb-counter-selected-patient-meal-layout/carb-counter-selected-patient-meal-layout";
import { CarbCounterMealProvider } from "./context/carb-counter-meal-dto-context";
import { PersistedCarbCounterMeal } from "./persisted-carb-counter-meal";
import { PatientCarbCounterMeal } from "./patient-carb-counter-meal";

type CarbCounterFoodLoggingSelectedPatientMealProps = {
  patientMealDTO: PatientMealDTO;
  selectedTab: FoodLoggingPatientsTab;
};

export function CarbCounterFoodLoggingSelectedPatientMeal(
  props: CarbCounterFoodLoggingSelectedPatientMealProps
) {
  const { patientMealDTO, selectedTab } = props;

  const { carbCounterState } = useCarbCounterMealState();

  return (
    <CarbCounterFoodLoggingSelectedPatientMealLayout
      patientMealDTO={patientMealDTO}
      selectedTab={selectedTab}
      mealBody={
        carbCounterState.state === "Idle" ? (
          <IdleCarbCounterMeal patientMealDTO={patientMealDTO} />
        ) : carbCounterState.state === "Analyzing" ? (
          <AnalyzingCarbCounterMeal patientMealDTO={patientMealDTO} />
        ) : carbCounterState.state === "Analyzed" ? (
          <CarbCounterMealProvider
            initialMeal={carbCounterState.carbCounterV1MealDTO}
          >
            <AnalyzedCarbCounterMeal patientMealDTO={patientMealDTO} />
          </CarbCounterMealProvider>
        ) : carbCounterState.state === "Persisted" ? (
          <CarbCounterMealProvider
            initialMeal={{ mealFoods: carbCounterState.patientMealFoods }}
          >
            <PersistedCarbCounterMeal patientMealDTO={patientMealDTO} />
          </CarbCounterMealProvider>
        ) : carbCounterState.state === "PatientCarbCounter" ? (
          <CarbCounterMealProvider
            initialMeal={{ mealFoods: carbCounterState.patientMealFoods }}
          >
            <PatientCarbCounterMeal patientMealDTO={patientMealDTO} />
          </CarbCounterMealProvider>
        ) : (
          <>undefined state</>
        )
      }
    />
  );
}
