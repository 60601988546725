import { ModalForm } from "../../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../../../styles/classes/reusable-classes";
import { BmiModalChart } from "../bmi-modal-chart";

type BmiModalBodyProps = {
  onClose: () => void;
  height: number;
};

export const BmiModalBody = (props: BmiModalBodyProps) => {
  const { onClose, height } = props;

  return (
    <ModalForm width={900} height={650} onSubmit={(e) => e.preventDefault()}>
      <ModalHeader onModalClose={onClose} title="BMI Progress" />
      <BmiModalChart height={height} />
      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
