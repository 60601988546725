import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  mediumRegularFont,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";
import {
  SidebarWidth,
  SinglePatientSidebarWidth,
} from "../../../../../styles/global-style";
import { CreatePlansHeaderHeight } from "../styles";

export const ReviewContainer = styled.div`
  position: relative;
`;

export const ReviewHeader = styled.div`
  margin: calc(${CreatePlansHeaderHeight} + 16px) 24px 0px;
  background: ${({ theme }) => theme.colors.containerColor};
  border-radius: 24px;
  height: 120px;
  ${flexCenterSpaceBetween}
  padding: 8px 24px;
`;

export const ReviewHeaderTextContainer = styled.div`
  ${flexCenter}
  gap: 0px 16px;

  div {
    p {
      margin: 0;
      ${regularMediumFont}
      color: ${({ theme }) => theme.colors.titleColor};
    }

    span {
      ${mediumRegularFont}
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;

export const ReviewCard = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  margin: 24px 0px;
`;

export const ReviewEducationList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  margin: 0;
  gap: 0rem 1rem;
`;

export const ReviewFooter = styled.div`
  position: fixed;
  bottom: 0;
  right: calc(${SinglePatientSidebarWidth} + 24px);
  left: calc(${SidebarWidth} + 24px);
  ${flexCenterSpaceBetween}
  padding: 16px 24px;
  z-index: ${({ theme }) => theme.zIndex.z300};
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  background-color: ${({ theme }) => theme.colors.containerColor};

  @media screen and (max-width: 1024px) {
    right: 0px;
  }
`;
