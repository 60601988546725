import {
  AxisModel,
  ChartComponent,
  DataLabel,
  DateTimeCategory,
  HiloSeries,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
} from "@syncfusion/ej2-react-charts";
import { useCallback, useMemo } from "react";
import { usePatientProfileBloodPressure } from "../context/patient-profile-blood-pressure-context";
import { PaitnetProfileChartContainer } from "../../../styles/patient-profile-styles/patient-profile-classes";
import { randomUUID } from "../../../../../../utils/random-uuid";
import { usePatientProfile } from "../../../context/loadable-patient-profile-context";

export const PatientProfileBloodPressureChart = () => {
  const { patientBloodPressureDTOs } = usePatientProfile();

  const { temporalStartDate, temporalEndDate, startDate, endDate } =
    usePatientProfileBloodPressure();

  const filteredPatientBloodPressureDTOsByDateRange = useMemo(
    () =>
      patientBloodPressureDTOs
        .sort((a, b) => a.date.localeCompare(b.date))
        .filter(
          (item) =>
            item.date >= temporalStartDate && item.date <= temporalEndDate
        ),
    [temporalStartDate, temporalEndDate, patientBloodPressureDTOs]
  );

  const primaryxAxis: AxisModel = {
    valueType: "DateTimeCategory",
    labelFormat: "dd-MM",
    edgeLabelPlacement: "Shift",
    title: "Date",
  };

  const primaryyAxis: AxisModel = {
    minimum: 10,
    title: "Blood Pressure (mmHg)",
  };

  const marker = {
    visible: true,
    width: 10,
    height: 10,
    isFilled: true,
    fill: "transparent",
    border: { width: 0, color: "transparent" },
    dataLabel: { visible: true },
  };

  const id = useMemo(
    () => `patient-profile-blood-pressure-chart-${randomUUID()}`,
    []
  );
  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);
    // Should always enter this condition, but let's be safe
    if (chart) {
      chart.style.overflow = "visible";
      chart.style.display = "block";
    }
  }, [id]);

  return (
    <PaitnetProfileChartContainer>
      <ChartComponent
        id={id}
        primaryXAxis={primaryxAxis}
        primaryYAxis={primaryyAxis}
        title="Blood Pressure Progress"
        height="100%"
        width="100%"
        loaded={handleChartLoaded}
      >
        <Inject services={[DataLabel, DateTimeCategory, HiloSeries]} />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={
              filteredPatientBloodPressureDTOsByDateRange !== undefined &&
              startDate !== undefined &&
              endDate !== undefined
                ? filteredPatientBloodPressureDTOsByDateRange
                : patientBloodPressureDTOs
            }
            xName="date"
            high="systolic"
            low="diastolic"
            type="Hilo"
            fill="#1097CF"
            marker={marker}
          ></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    </PaitnetProfileChartContainer>
  );
};
