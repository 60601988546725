import { useCallback } from "react";
import { PatientNutritionReportDTO } from "../../models/patient-nutrition-report-dtos/patient-nutrition-report-dto";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";

export function useGetPatientNutritionReports(): (
  patientId: number
) => Promise<PatientNutritionReportDTO[]> {
  const client = useClient();

  return useCallback(
    async (patientId) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientNutritionReportDTO[]>(
        `${PATIENTS_PATH}/${patientId}/nutrition-reports`
      );

      return response.data;
    },
    [client]
  );
}
