import { useCallback } from "react";
import { PatientMedicationDTO } from "../../models/patient-medication-dto";
import { PutPatientMedicationNoteDTO } from "../../models/patient-medication-dtos/put-patient-medication-note-dto";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";

export function usePutPatientMedicationNote(): (
  patientMedicationDTO: PatientMedicationDTO,
  putPatientMedicationNoteDTO: PutPatientMedicationNoteDTO
) => Promise<PatientMedicationDTO> {
  const client = useClient();

  return useCallback(
    async (patientMedicationDTO, putPatientMedicationNoteDTO) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.put<PatientMedicationDTO>(
        `${PATIENTS_PATH}/${patientMedicationDTO.patientId}/medications/${patientMedicationDTO.id}/note`,
        putPatientMedicationNoteDTO
      );

      return response.data;
    },
    [client]
  );
}
