import { Reading } from "../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { roundTo1DecimalPlace } from "../../../math-utils";
import { buildDaysAggregatesReadingsWithTimeDifference } from "../../build-readings-with-time-differance-value";
import { calculateReadingsTimeDiffrenceTotal } from "../../calculate-readings-time-differance-total";
import { calculateHighReadingsTimeDiffrenceTotal } from "../calculate-high-readings-time-differance-total";

export function calculateHighPercentage(readings: Reading[], maxRange: number) {
  const readingsWithTimeDifferenceValue = buildDaysAggregatesReadingsWithTimeDifference(
    readings
  );

  const highReadingsTimeDiffrenceTotal = calculateHighReadingsTimeDiffrenceTotal(
    readingsWithTimeDifferenceValue,
    maxRange
  );
  const readingsTimeDifferanceTotal = calculateReadingsTimeDiffrenceTotal(
    readingsWithTimeDifferenceValue
  );

  const highPercentage = roundTo1DecimalPlace(
    (highReadingsTimeDiffrenceTotal / readingsTimeDifferanceTotal) * 100
  );

  return highPercentage;
}
