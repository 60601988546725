import styled, { css } from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  lgSemiBoldFont,
  regularMediumFont,
  regularSemiBoldFont,
} from "../../styles/classes/gloabl-classes";
import { FEATURE_FLAG_LOGIN_WITH_EMAIL_AND_PASSWORD } from "../../constants/config";

export const LoginFormContainerCSS = css`
  background: ${({ theme }) => theme.colors.containerColor};
  width: 100%;
  height: 500px;
  border-radius: 1rem;
  padding: ${({ theme }) => theme.padding.pad1All};
`;

export const LoginContainer = styled.div`
  background: url("/img/login-bg.svg") center center / cover no-repeat;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
`;

export const LoginFormContainer = styled.div`
  width: 470px;
  display: grid;
  place-items: center;

  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const LoginFormLogoContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.mr_b_2};
`;

export const LoginForm = styled.form`
  ${LoginFormContainerCSS}
`;

export const FormHeader = styled.div`
  h1 {
    ${lgSemiBoldFont}
  }

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.textColor};
    margin: 0px;
  }
`;

export const FormBody = styled.div`
  ${FEATURE_FLAG_LOGIN_WITH_EMAIL_AND_PASSWORD
    ? `margin-top: 64px;`
    : `margin-top: 40%;`}
  display: grid;
  place-items: center;
`;

export const AdminOrPatientLoginContainer = styled.div`
  ${LoginFormContainerCSS}
  ${flexCenterCenter}
  gap: 0rem 4rem;
  margin-top: 10%;

  :last-child {
    margin: 20px 0px 0px;
  }

  h1 {
    ${lgSemiBoldFont}
    color: ${({ theme }) => theme.colors.titleColor};
    ${flexCenter}
  }

  a {
    text-align: center;
    text-decoration: none;
    ${regularSemiBoldFont}
    color: ${({ theme }) => theme.colors.primaryColor};
    background: transparent;
    padding: ${({ theme }) => theme.padding.pad05_1};
    border: 1px solid ${({ theme }) => theme.colors.primaryColor};
    border-radius: 8px;
  }
`;
