import styled from "styled-components/macro";
import { transition } from "../../../../../styles/global-style";

export const MessagingInputContainer = styled.div<{ isRecording: boolean }>`
  background: ${({ theme }) => theme.colors.bodyColor};
  display: flex;
  align-items: last baseline;
  gap: 0px 16px;
  padding: 16px 24px;
  min-height: 100px;

  /* Message Input & Send Button */
  .str-chat__input-flat {
    background: ${({ theme }) => theme.colors.bodyColor};
    padding: 0px;
  }

  .str-chat__input-flat-wrapper {
    justify-content: space-between;
    display: ${({ isRecording }) => (isRecording ? "none" : "flex")};
  }

  .str-chat__input-flat .str-chat__textarea > textarea {
    background: ${({ theme }) => theme.colors.containerColor};
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  }

  .rfu-file-upload-button {
    right: unset;
    left: -45px;
    width: 20px;
    height: 20px;
    display: ${({ isRecording }) => (isRecording ? "none" : "block")};
  }

  .rfu-file-upload-button svg {
    visibility: hidden;
  }

  .str-chat__input-flat-emojiselect svg {
    fill: #016dff;
    opacity: 1;
  }

  .plus {
    position: relative;
    left: -5px;
    top: 5px;
    width: 30px;
    height: 30px;
    transition: ${transition};
    display: ${({ isRecording }) => (isRecording ? "none" : "block")};

    img {
      transform: rotate(45deg);
      width: 100%;
      height: 100%;
    }
  }

  .str-chat__tooltip {
    right: unset;
    left: -20px;
  }

  .str-chat__tooltip::after {
    content: "";
    width: 5px;
    background: black;
    left: 3px;
    right: unset;
  }

  .str-chat__input-flat--textarea-wrapper
    .str-chat__fileupload-wrapper
    .str-chat__tooltip {
    right: unset;
  }

  .str-chat__send-button {
    @media screen and (min-width: 768px) {
      display: block;
    }
  }
  /* Message Input & Send Button */
`;
