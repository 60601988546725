import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export type CurrentStep =
  | "Goals"
  | "Feedback"
  | "Medical"
  | "Nutrition"
  | "Lifestyle"
  | "Education"
  | "Review";

type PlansSteps = {
  currentStep: CurrentStep;
  completedSteps: CurrentStep[] | undefined;
  handleSetCurrentStep: (currentStep: CurrentStep) => void;
  handleSetCompletedSteps: () => void;
};

const PlansStepsContext = createContext<PlansSteps | undefined>(undefined);

export const PlansStepsProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  const [currentStep, setCurrentStep] = useState<CurrentStep>("Goals");
  const [completedSteps, setCompletedSteps] = useState<
    CurrentStep[] | undefined
  >(undefined);

  const handleSetCompletedSteps = useCallback(() => {
    if (currentStep === "Goals") {
      setCompletedSteps(undefined);
    } else if (currentStep === "Feedback") {
      setCompletedSteps(["Goals"]);
    } else if (currentStep === "Medical") {
      setCompletedSteps(["Goals", "Feedback"]);
    } else if (currentStep === "Nutrition") {
      setCompletedSteps(["Goals", "Feedback", "Medical"]);
    } else if (currentStep === "Lifestyle") {
      setCompletedSteps(["Goals", "Feedback", "Medical", "Nutrition"]);
    } else if (currentStep === "Education") {
      setCompletedSteps([
        "Goals",
        "Feedback",
        "Medical",
        "Nutrition",
        "Lifestyle",
      ]);
    } else if (currentStep === "Review") {
      setCompletedSteps([
        "Goals",
        "Feedback",
        "Medical",
        "Nutrition",
        "Lifestyle",
        "Education",
      ]);
    } else {
      setCompletedSteps(undefined);
    }
  }, [currentStep]);

  const handleSetCurrentStep = useCallback(
    (step: CurrentStep) => setCurrentStep(step),
    []
  );

  const value = useMemo(
    () => ({
      currentStep,
      handleSetCurrentStep,
      completedSteps,
      handleSetCompletedSteps,
    }),
    [currentStep, handleSetCurrentStep, completedSteps, handleSetCompletedSteps]
  );

  return (
    <PlansStepsContext.Provider value={value}>
      {children}
    </PlansStepsContext.Provider>
  );
};

export const usePlansSteps = () => {
  const plansSteps = useContext(PlansStepsContext);

  if (plansSteps === undefined) {
    throw new Error("usePlansSteps must be used within PlansStepsProvider");
  }

  return plansSteps;
};
