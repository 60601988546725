import { TooltipSettingsModel } from "@syncfusion/ej2-react-charts";
import { CgmTooltip, CgmTooltipProps } from "../cgm-tooltip";

export function buildCgmTooltip(hasToolTip: boolean): TooltipSettingsModel {
  const template: any = chartTemplate;

  return {
    enable: hasToolTip,
    fadeOutMode: "Click",
    template,
    fadeOutDuration: 1,
  };
}

function chartTemplate(args: { tooltip: CgmTooltipProps }) {
  return <CgmTooltip {...args.tooltip} />;
}
