import { PatientProfileActivitiesSection } from "./patient-profile-activities-section/patient-profile-activities-section";
import { PatientProfileInformationHeader } from "./patient-profile-information-header/patient-profile-information-header";
import { PatientProfileMedicalHistorySection } from "./patient-profile-medical-history-section/patient-profile-medical-history-section";
import { PatientProfileMedicationsSection } from "./patient-profile-medications-section/patient-profile-medications-section";
import { PatientProfileProviders } from "./patient-profile-providers";
import { PatientProfileWeightSection } from "./patient-profile-weight-section/patient-profile-weight-section";

export const PatientProfile = () => {
  return (
    <PatientProfileProviders>
      <PatientProfileInformationHeader />
      <PatientProfileMedicalHistorySection />
      <PatientProfileMedicationsSection />
      <PatientProfileActivitiesSection />
      <PatientProfileWeightSection />
    </PatientProfileProviders>
  );
};
