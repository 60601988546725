import { Link } from "react-router-dom";
import { EmptyState } from "../../../components/empty-state/empty-state";
import { ContentModuleDTO } from "../../../models/content-module-dtos/content-module-dto";
import { ContentScreenTableContainer } from "../styles/content-reusable-classes";
import { ContentCourseModulesTable } from "./content-course-modules-table/content-course-modules-table";
import { Button } from "../../../styles/classes/reusable-classes";

type ContentCourseModulesProps = {
  contentModuleDTO: ContentModuleDTO;
};

export const ContentCourseModules = (props: ContentCourseModulesProps) => {
  const { contentModuleDTO } = props;

  if (
    contentModuleDTO.childContentModules === undefined ||
    contentModuleDTO.childContentModules.length === 0
  ) {
    return (
      <ContentScreenTableContainer $childTable>
        <EmptyState
          button={
            <Link
              to={`../courses/course/${contentModuleDTO.id}/module/create-new-module`}
            >
              <Button flex type="button">
                <span className="material-symbols-outlined">add</span>
                Add Module
              </Button>
            </Link>
          }
          hasHeader={false}
          hasSearch={false}
          text="No modules for this course"
          title=""
        />
      </ContentScreenTableContainer>
    );
  }

  return (
    <ContentScreenTableContainer $childTable>
      <ContentCourseModulesTable contentModuleDTO={contentModuleDTO} />
    </ContentScreenTableContainer>
  );
};
