import { PatientNutritionReportDTO } from "../../../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-dto";
import {
  NutrtionReportSummaryNotesList,
  NutrtionReportSummaryNotesListContainer,
  NutrtionReportSummaryNotesListHeader,
  NutrtionReportSummaryNotesListItem,
} from "../../../../../../../food-logging/nutrition-report/nutrition-report-summary/nutrition-report-summary-screen/nutrtion-report-summary-notes/styled-nutrtion-report-summary-notes";

type ViewNutritionReportSummaryUnhealthyNotesProps = {
  patientNutritionReport: PatientNutritionReportDTO;
};

export const ViewNutritionReportSummaryUnhealthyNotes = (
  props: ViewNutritionReportSummaryUnhealthyNotesProps
) => {
  const { patientNutritionReport } = props;

  return (
    <NutrtionReportSummaryNotesListContainer>
      <NutrtionReportSummaryNotesListHeader habbits="Unhealthy">
        <img src="/img/plans/Warning.svg" alt="Warning" />
        <p>Healthy habits to keep</p>
      </NutrtionReportSummaryNotesListHeader>
      <NutrtionReportSummaryNotesList isFieldsEmpty={false} viewReportUI={true}>
        {patientNutritionReport.patientNutritionReportUnhealthyHabits.map(
          (note) => (
            <NutrtionReportSummaryNotesListItem key={note.id} viewReportUI>
              <p>{note.note}</p>
            </NutrtionReportSummaryNotesListItem>
          )
        )}
      </NutrtionReportSummaryNotesList>
    </NutrtionReportSummaryNotesListContainer>
  );
};
