import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
} from "../../styles/classes/gloabl-classes";

export const DateContainer = styled.div`
  ${flexCenter}
  gap: 0rem 1rem;
  margin: 0 auto;

  span {
    cursor: pointer;
  }

  @media screen and (max-width: 550px) {
    margin: 0.5rem auto 1rem;
    gap: 0rem 0.5rem;
  }

  @media screen and (max-width: 320px) {
    gap: 0rem 0.5rem;
  }
`;

export const DateInputContainer = styled.div`
  ${flexCenterSpaceBetween}
  gap: 0rem 1rem;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.borderColor};
  padding: 0.5rem 1rem 0rem;
  cursor: pointer;

  input {
    border: 0;
    outline: 0;
    font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
    cursor: pointer;
    padding-bottom: 0.6rem;
    width: 100%;

    ::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  span {
    padding-bottom: 0.6rem;

    :last-child {
      transform: rotate(-90deg);
      padding-bottom: 0rem;
      padding-left: 0.4rem;
    }
  }

  @media screen and (max-width: 550px) {
    gap: 0rem 0.5rem;
    width: 250px;
  }

  @media screen and (max-width: 320px) {
    width: 200px;
  }
`;
