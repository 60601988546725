import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  regularMediumFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const CarbCounterMealMediaContainerMaxHeight = `342px`;

export const CarbCounterFoodLoggingSelectedPatientMealMediaContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 16px;
  border-radius: 8px;
  width: 50%;
  max-height: ${CarbCounterMealMediaContainerMaxHeight};
  height: 290px;
  overflow-y: auto;
`;

export const CarbCounterFoodLoggingSelectedPatientMealMediaTimeStamp = styled.div`
  margin-bottom: 16px;
  ${flexCenterSpaceBetween}

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
  }

  span {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.success};
  }
`;
