import { GenericActionsListItem } from "../../../components/generic-actions-list/generic-actions-list-item";
import { Modal } from "../../../components/modal/modal";
import { useModalHelpers } from "../../../components/modal/use-modal-helpers";
import { PatientDTO } from "../../../models/patient-dtos/patient-dto";
import { PatientModal } from "../patient-modal/patient-modal";

type EditPatientProps = {
  patientDTO: PatientDTO;
};

export const EditPatient = (props: EditPatientProps) => {
  const { patientDTO } = props;

  const { isModalOpen, closeModal, openModal, modalBodyKey } =
    useModalHelpers();

  return (
    <>
      <GenericActionsListItem text="Edit" onClick={openModal} />
      <Modal showModal={isModalOpen} handleHideModal={closeModal}>
        <PatientModal
          onModalClose={closeModal}
          key={modalBodyKey}
          mode={{ kind: "edit", patientDTO }}
        />
      </Modal>
    </>
  );
};
