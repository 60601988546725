import { useFormContext } from "react-hook-form";
import { ContentModuleDTO } from "../../../../models/content-module-dtos/content-module-dto";
import { CreateContentModuleCardDTO } from "../../../../models/content-module-dtos/create-content-module-card-dto";
import { ContentModuleLessonMobilePreviewCards } from "./content-module-lesson-mobile-preview-cards/content-module-lesson-mobile-preview-cards";
import { CreateContentModuleLessonMobilePreviewCards } from "./content-module-lesson-mobile-preview-cards/create-content-module-lesson-mobile-preview-cards/create-content-module-lesson-mobile-preview-cards";
import { UpdateContentModuleLessonMobilePreviewCards } from "./content-module-lesson-mobile-preview-cards/update-content-module-lesson-mobile-preview-cards/update-content-module-lesson-mobile-preview-cards";
import { ContentModuleLessonMobilePreviewFooter } from "./content-module-lesson-mobile-preview-footer/content-module-lesson-mobile-preview-footer";
import { ContentModuleLessonMobilePreviewHeader } from "./content-module-lesson-mobile-preview-header/content-module-lesson-mobile-preview-header";
import { ContentModuleLessonMobilePreviewStatusBar } from "./content-module-lesson-mobile-preview-status-bar/content-module-lesson-mobile-preview-status-bar";
import { ConentModuleMobilePreviewCarouselProvider } from "./context/conent-module-mobile-preview-carousel-context";
import { ContentModuleLessonMobilePreviewContainer } from "./styled-content-module-lesson-mobile-preview";
import { ContentModuleUpdateModeInputs } from "../../context/content-module-mode-context";

type ContentModuleLessonMobilePreviewProps =
  | {
      kind: {
        mode: "Read";
        contentModuleDTO: ContentModuleDTO;
      };
    }
  | {
      kind: {
        mode: "Create";
        lessonTitle: string;
        contentModuleCards: CreateContentModuleCardDTO[];
      };
    }
  | {
      kind: {
        mode: "Update";
        lessonTitle: string;
      };
    };

export const ContentModuleLessonMobilePreview = (
  props: ContentModuleLessonMobilePreviewProps
) => {
  const { kind } = props;

  // This watcher is danger to use anywhere else update mode
  // It will be === undefined if used in any mode except update mode
  const { watch } = useFormContext<ContentModuleUpdateModeInputs>();
  const updateModeContentModuleCards = watch("contentModuleCards");

  if (kind.mode === "Read") {
    return (
      <ConentModuleMobilePreviewCarouselProvider>
        <ContentModuleLessonMobilePreviewContainer>
          <ContentModuleLessonMobilePreviewStatusBar />
          <ContentModuleLessonMobilePreviewHeader
            lessonTitle={kind.contentModuleDTO.name}
            lessonCardsLength={kind.contentModuleDTO.contentModuleCards.length}
          />
          <ContentModuleLessonMobilePreviewCards
            contentModuleCards={kind.contentModuleDTO.contentModuleCards}
          />
          <ContentModuleLessonMobilePreviewFooter
            lessonCardsLength={kind.contentModuleDTO.contentModuleCards.length}
          />
        </ContentModuleLessonMobilePreviewContainer>
      </ConentModuleMobilePreviewCarouselProvider>
    );
  }

  if (kind.mode === "Create") {
    return (
      <ConentModuleMobilePreviewCarouselProvider>
        <ContentModuleLessonMobilePreviewContainer>
          <ContentModuleLessonMobilePreviewStatusBar />
          <ContentModuleLessonMobilePreviewHeader
            lessonTitle={kind.lessonTitle}
            lessonCardsLength={kind.contentModuleCards.length}
          />
          <CreateContentModuleLessonMobilePreviewCards
            contentModuleCards={kind.contentModuleCards}
          />
          <ContentModuleLessonMobilePreviewFooter
            lessonCardsLength={kind.contentModuleCards.length}
          />
        </ContentModuleLessonMobilePreviewContainer>
      </ConentModuleMobilePreviewCarouselProvider>
    );
  }

  if (kind.mode === "Update") {
    return (
      <ConentModuleMobilePreviewCarouselProvider>
        <ContentModuleLessonMobilePreviewContainer>
          <ContentModuleLessonMobilePreviewStatusBar />
          <ContentModuleLessonMobilePreviewHeader
            lessonTitle={kind.lessonTitle}
            lessonCardsLength={updateModeContentModuleCards.length}
          />
          <UpdateContentModuleLessonMobilePreviewCards />
          <ContentModuleLessonMobilePreviewFooter
            lessonCardsLength={updateModeContentModuleCards.length}
          />
        </ContentModuleLessonMobilePreviewContainer>
      </ConentModuleMobilePreviewCarouselProvider>
    );
  }

  return (
    <ContentModuleLessonMobilePreviewContainer>
      <ContentModuleLessonMobilePreviewStatusBar />
    </ContentModuleLessonMobilePreviewContainer>
  );
};
