import { Temporal } from "temporal-polyfill";
import { Reading } from "../../build-days-aggregates";
import { GoogleDataTableRow } from "react-google-charts";
import { GAP_THRESHOLD_MINUTES } from "../../../../../constants/config";
import { buildGapRow } from "../build-gap-row";
import { buildReadingRow } from "./build-reading-row";
import { PatientDTO } from "../../../../../models/patient-dtos/patient-dto";

export function buildReadingsRows(
  date: Temporal.PlainDate,
  readings: Reading[],
  reverseVAxisTicks: number[],
  patientDTO: PatientDTO
): GoogleDataTableRow[] {
  const rows: GoogleDataTableRow[] = [];

  let lastPlainDateTime: Temporal.PlainDateTime | undefined = undefined;
  for (const reading of readings) {
    const time = reading.time;
    const plainDateTime = date.toPlainDateTime(time);

    if (
      lastPlainDateTime !== undefined &&
      Temporal.PlainDateTime.compare(
        lastPlainDateTime.add({ minutes: GAP_THRESHOLD_MINUTES }),
        plainDateTime
      ) === -1
    ) {
      rows.push(buildGapRow(plainDateTime, reverseVAxisTicks));
    }

    rows.push(
      buildReadingRow(plainDateTime, reverseVAxisTicks, reading, patientDTO)
    );

    lastPlainDateTime = plainDateTime;
  }

  return rows;
}
