import { PatientMealDTO } from "../../../../models/patient-meal-dtos/patient-meal-dto";
import { Button } from "../../../../styles/classes/reusable-classes";
import { CarbCounterFoodLoggingSelectedPatientMealDetails } from "./carb-counter-food-logging-selected-patient-meal-header/carb-counter-food-logging-selected-patient-meal-details/carb-counter-food-logging-selected-patient-meal-details";
import { CarbCounterFoodLoggingSelectedPatientMealHeader } from "./carb-counter-food-logging-selected-patient-meal-header/carb-counter-food-logging-selected-patient-meal-header";
import { CarbCounterFoodLoggingSelectedPatientMealAnalysisContainer } from "./carb-counter-food-logging-selected-patient-meal-response/styled-carb-counter-food-logging-selected-patient-meal-response";
import { useCarbCounterMealState } from "./context/carb-counter-meal-state-context";

type IdleCarbCounterMealProps = {
  patientMealDTO: PatientMealDTO;
};

export const IdleCarbCounterMeal = (props: IdleCarbCounterMealProps) => {
  const { patientMealDTO } = props;

  const { handleAnalizeMeal } = useCarbCounterMealState();

  return (
    <>
      <CarbCounterFoodLoggingSelectedPatientMealHeader
        patientMealDTO={patientMealDTO}
        carbCounterFoodLoggingSelectedPatientMealDetails={
          <CarbCounterFoodLoggingSelectedPatientMealDetails
            carbCounterMealDetailsState={{ kind: "Empty" }}
          />
        }
      />
      <CarbCounterFoodLoggingSelectedPatientMealAnalysisContainer>
        <Button type="button" onClick={handleAnalizeMeal}>
          Analyze Meal
        </Button>
      </CarbCounterFoodLoggingSelectedPatientMealAnalysisContainer>
    </>
  );
};
