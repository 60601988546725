import { assertNever } from "../utils/assert-never";
import { PatientType } from "../models/patient-dtos/patient-type";

export type ViewPatientTypeProps = { type: PatientType };

export function ViewPatientType(props: ViewPatientTypeProps) {
  const { type } = props;

  const userText = typeToUserText(type);

  return <>{userText}</>;
}

function typeToUserText(type: PatientType) {
  if (type === "Type1") {
    return "Type 1";
  } else if (type === "Type2") {
    return "Type 2";
  } else if (type === "Type1Fragile") {
    return "Type 1 Fragile";
  } else if (type === "Type2Fragile") {
    return "Type 2 Fragile";
  } else if (type === "NormalOrHealthy") {
    return "Normal/Healthy";
  } else if (type === "Prediabetic") {
    return "Prediabetic";
  } else if (type === "Type1Pregnant") {
    return "Type 1 Pregnant";
  } else if (type === "Type2Pregnant") {
    return "Type 2 Pregnant";
  } else if (type === "Gestational") {
    return "Gestational";
  } else if (type === "Type1Pediatric") {
    return "Type 1 Pediatric";
  } else {
    return assertNever(type);
  }
}
