import { PatientMealResponseDTO } from "../../models/patient-meal-response-dtos/patient-meal-response-dto";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PATIENTS_PATH } from "../../constants/paths";
import { PatientMealTag } from "../../models/patient-meal-dtos/patient-meal-tag";

export function useGetPatientMealResponses(): (
  patientId: number,
  startDate: string,
  endDate: string,
  spanInMinutes: number,
  insulinThresholdInMinutes: number,
  patientMealTag?: PatientMealTag,
  offset?: number,
  limit?: number
) => Promise<PatientMealResponseDTO[]> {
  const client = useClient();

  return useCallback(
    async (
      patientId,
      startDate,
      endDate,
      spanInMinutes,
      insulinThresholdInMinutes,
      patientMealTag = undefined,
      offset = undefined,
      limit = undefined
    ) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientMealResponseDTO[]>(
        `${PATIENTS_PATH}/${patientId}/meal-responses`,
        {
          params: {
            startDate,
            endDate,
            spanInMinutes,
            insulinThresholdInMinutes,
            patientMealTag,
            offset,
            limit,
          },
        }
      );

      return response.data;
    },
    [client]
  );
}
