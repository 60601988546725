import { PrescriptionFormHeader } from "../../../components/prescription-form-header/prescription-form-header";
import { NewPrescriptionFormFooter } from "../new-prescription-form-footer/new-prescription-form-footer";
import { PrescriptionFormContainer } from "./styled-prescription-form";
import { NewPrescriptionFormInputsContainer } from "../styled-new-prescription-body";
import { usePrescriptions } from "../../../context/prescriptions-context";
import { Controller, useFormContext } from "react-hook-form";
import {
  ErrorText,
  InputContainer,
} from "../../../../../../../styles/classes/reusable-classes";
import { MedicationsNumberInput } from "../../../../components/medication-inputs/medications-number-input/medications-number-input";
import { FrequencyAutoSelect } from "../../../../components/frequency-auto-select/frequency-auto-select";
import { MedicationsNotesInput } from "../../../../components/medication-inputs/medications-notes-input/medications-notes-input";
import { useActivePrescriptionItem } from "../../../context/active-prescription-context";
import { PrescriptionFormInputs } from "../../new-prescription";

export const AddPrescriptionForm = () => {
  const { startAdding } = usePrescriptions();
  const { activePrescriptionItem, handleResetActivePrescriptionItem } =
    useActivePrescriptionItem();

  const {
    watch,
    reset,
    control,
    formState: { errors, isValid },
  } = useFormContext<PrescriptionFormInputs>();

  const quantity = watch("quantity");
  const durationDays = watch("durationDays");
  const notes = watch("notes");
  const frequency = watch("frequency");

  const formHasValues =
    quantity !== undefined ||
    durationDays !== undefined ||
    notes !== undefined ||
    frequency !== undefined;

  const formHeaderData = {
    id: activePrescriptionItem.medicine?.id,
    name: activePrescriptionItem.medicine?.name,
    strength: activePrescriptionItem.medicine?.strength,
    routeOfAdministration:
      activePrescriptionItem.medicine?.routeOfAdministration,
    doseUnit: activePrescriptionItem.medicine?.doseUnit,
  };

  const addSingleMedicine = () => {
    if (formHasValues) {
      startAdding({
        durationDays: durationDays!,
        frequencyId: frequency?.id!,
        medicationId: formHeaderData?.id!,
        quantity: quantity!,
        notes: notes!,
      });
      reset({
        durationDays: 1,
        frequency: undefined,
        notes: undefined,
        quantity: undefined,
      });
      handleResetActivePrescriptionItem();
    }
  };

  return (
    <PrescriptionFormContainer>
      <PrescriptionFormHeader formHeaderData={formHeaderData} />
      <NewPrescriptionFormInputsContainer>
        <InputContainer>
          <Controller
            name={`quantity`}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <MedicationsNumberInput
                label="Quantity"
                subLabel={
                  activePrescriptionItem.medicine?.doseUnit !== undefined
                    ? activePrescriptionItem.medicine.doseUnit
                    : ""
                }
                step="0.1"
                value={value}
                onChange={onChange}
                errorState={errors.quantity?.type === "required"}
              />
            )}
          />
          {errors.quantity?.type === "min" && (
            <ErrorText forMedications>
              Quantity canot be less than 1 day
            </ErrorText>
          )}
          {errors.quantity?.type === "required" && (
            <ErrorText forMedications>Required</ErrorText>
          )}
        </InputContainer>
        <InputContainer>
          <Controller
            name={`frequency`}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <FrequencyAutoSelect
                onChange={onChange}
                errorState={errors.frequency?.type === "required"}
              />
            )}
          />
          {errors.frequency?.type === "required" && (
            <ErrorText forMedications>Required</ErrorText>
          )}
        </InputContainer>
        <InputContainer>
          <Controller
            name={`durationDays`}
            control={control}
            rules={{ min: 1 }}
            render={({ field: { value, onChange } }) => (
              <MedicationsNumberInput
                label="Duration"
                subLabel="days"
                step="1"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.durationDays?.type === "min" && (
            <ErrorText forMedications>
              Duration days canot be less than 1 day
            </ErrorText>
          )}
        </InputContainer>
        <Controller
          name={`notes`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <MedicationsNotesInput value={value} onChange={onChange} />
          )}
        />
      </NewPrescriptionFormInputsContainer>
      <NewPrescriptionFormFooter
        isValid={isValid}
        addSingleMedicine={addSingleMedicine}
      />
    </PrescriptionFormContainer>
  );
};
