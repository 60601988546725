import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { GenericActionsList } from "../../../../../components/generic-actions-list/generic-actions-list";
import { PateintProfileColumnHeader } from "../../components/pateint-profile-column-header/pateint-profile-column-header";
import { PatientProfileContainerBody } from "../../styles/patient-profile-styles/patient-profile-classes";
import { usePatientProfileNotes } from "./context/loadable-patient-profile-notes";
import { PatientProfileAddNotesModal } from "./patient-profile-add-notes-modal/patient-profile-add-notes-modal";
import { PatientProfileEditNotesModal } from "./patient-profile-edit-notes-modal/patient-profile-edit-notes-modal";
import {
  ShowTextOnHover,
  PatientProfileNotesBodyList,
  PatientProfileNotesBodyListItem,
  PatientProfileNotesBodyListItemDate,
  PatientProfileNotesBodyListItemIconContainer,
  PatientProfileNotesBodyListItemTitle,
  PatientProfileNotesBodyListItemTopContainer,
  PatientProfileNotesListItemNoteContainer,
  PatientProfileListItemAuthorText,
} from "./styled-patient-profile-notes";

export const PatientProfileNotes = () => {
  const { patientNotes } = usePatientProfileNotes();

  return (
    <>
      <PateintProfileColumnHeader
        title="Notes"
        img="/img/plans/nav/review.svg"
        button={<PatientProfileAddNotesModal />}
      />
      <PatientProfileContainerBody>
        {patientNotes.length > 0 ? (
          <PatientProfileNotesBodyList>
            {patientNotes.map((patientNote) => {
              const displayCreatedAt = new Date(
                patientNote.createdAt
              ).toLocaleString("en-EG", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                timeZoneName: "short",
                hour12: true,
              });

              const displayUpdatedAt =
                patientNote.updatedAt !== undefined
                  ? new Date(patientNote.updatedAt).toLocaleString("en-EG", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      timeZoneName: "short",
                      hour12: true,
                    })
                  : undefined;
              return (
                <PatientProfileNotesBodyListItem key={patientNote.id}>
                  <PatientProfileNotesBodyListItemTopContainer>
                    <div>
                      <PatientProfileNotesBodyListItemDate>
                        {displayCreatedAt}{" "}
                        <span>
                          {displayUpdatedAt !== undefined ? "(Edited)" : ""}
                          {displayUpdatedAt !== undefined ? (
                            <ShowTextOnHover>
                              {displayUpdatedAt}
                            </ShowTextOnHover>
                          ) : (
                            <></>
                          )}
                        </span>
                      </PatientProfileNotesBodyListItemDate>
                      <PatientProfileNotesBodyListItemTitle>
                        {patientNote.title}
                      </PatientProfileNotesBodyListItemTitle>
                    </div>
                    <PatientProfileNotesBodyListItemIconContainer>
                      <GenericActionsList>
                        <PatientProfileEditNotesModal
                          patientNoteDTO={patientNote}
                        />
                      </GenericActionsList>
                    </PatientProfileNotesBodyListItemIconContainer>
                  </PatientProfileNotesBodyListItemTopContainer>
                  <PatientProfileNotesListItemNoteContainer>
                    <p dir="auto">{patientNote.body}</p>
                  </PatientProfileNotesListItemNoteContainer>
                  <div>
                    <PatientProfileListItemAuthorText>
                      {patientNote.createdByAdmin.name}
                      <ShowTextOnHover>
                        {patientNote.createdByAdmin.email}
                      </ShowTextOnHover>
                    </PatientProfileListItemAuthorText>
                  </div>
                </PatientProfileNotesBodyListItem>
              );
            })}
          </PatientProfileNotesBodyList>
        ) : (
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="There are no data in Notes"
            title=""
          />
        )}
      </PatientProfileContainerBody>
    </>
  );
};
