import { Fragment, useCallback } from "react";
import { SinglePlanDropdown } from "./components/single-plan-dropdown";
import { SinglePlanDropdownListItem } from "./components/styled-single-plan-dropdown";
import {
  FeedbackCard,
  FeedbackCardBody,
  FeedbackCardBodyTitle,
  FeedbackCardCharts,
  FeedbackCardHeader,
  FeedbackCardHeaderTextContainer,
  FeedbackChartContainer,
} from "../../../plan-steps/feedback/styled-feedback";
import { WeightProgressChart } from "../../../../single-patient-sidebar/patient-sidebar-tabs/personal-info-tab/body-measurments/weight-progress-modal/weight-progress-modal-body/weight-progress-chart";
import { ListLabResultCards } from "../../../plan-steps/feedback/list-feedback/list-lab-result-cards";
import { ListMealGraphChart } from "../../../plan-steps/feedback/list-feedback/list-meal-graph-chart";
import { ListBloodGlucoseChart } from "../../../plan-steps/feedback/list-feedback/list-blood-glucose-chart";
import { VeiwPlansManualReadingsChart } from "./components/veiw-plans-manual-readings-chart";
import { translateTag } from "./utils/translate-tags";
import { buildDaysAggregates } from "../../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { useGetPatientCGMEntryDTOs } from "../../../../../../components/cgm-google-chart/hooks/use-get-patient-cgm-entry-dtos";
import { useGetPatientServingDTOs } from "../../../../../../components/cgm-google-chart/hooks/use-get-patient-serving-dtos";
import { useGetPatientMealDTOs } from "../../../../../../components/cgm-google-chart/hooks/use-get-patient-meal-dtos";
import { usePatient } from "../../../../context/loadable-patient-context";
import { PatientDetailedPlanDTO } from "../../../../../../models/patient-plan-dtos/patient-detailed-plan-dto";

type SinglePlanFeedbackProps = {
  patientDetailedPlanDTO: PatientDetailedPlanDTO;
};

export const SinglePlanFeedback = (props: SinglePlanFeedbackProps) => {
  const { patientDetailedPlanDTO } = props;
  const {
    patientDTO,
    labTestTypeRefDTOs,
    patientWeightDTOs,
    patientLabTestResultsDTOs,
    labTestTypeDTOs,
  } = usePatient();

  const { patientCGMEntryDTOs } = useGetPatientCGMEntryDTOs();
  const { patientServingDTOs } = useGetPatientServingDTOs();
  const { patientMealDTOs } = useGetPatientMealDTOs();

  const listFeedbacksData = patientDetailedPlanDTO.patientPlanFeedbacks;

  const daysAggregates = useCallback(
    (date: string) =>
      buildDaysAggregates(
        patientDTO,
        patientCGMEntryDTOs,
        patientServingDTOs,
        patientMealDTOs
      ).filter((item) => item.date.toString() === date),
    [patientDTO, patientMealDTOs, patientCGMEntryDTOs, patientServingDTOs]
  );

  const weightDTOs = useCallback(
    (startDate: string, endDate: string) =>
      patientWeightDTOs.filter(
        (item) => item.date >= startDate && item.date <= endDate
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const feedback = listFeedbacksData.map((item, index) => (
    <SinglePlanDropdownListItem key={index} viewPlanGraphs={true}>
      <FeedbackCard viewPlans>
        <FeedbackCardHeader>
          <FeedbackCardHeaderTextContainer>
            <img src={`/img/plans/${item.feedbackTone}.svg`} alt="" />
            <p>{item.notes}</p>
          </FeedbackCardHeaderTextContainer>
        </FeedbackCardHeader>
        <FeedbackCardBody>
          <FeedbackCardBodyTitle>
            {item.feedbackType === "BloodGlucoseManual"
              ? `Manual Readings: ${
                  item.bloodGlucoseManualTag !== undefined
                    ? translateTag(item.bloodGlucoseManualTag) === undefined
                      ? ``
                      : translateTag(item.bloodGlucoseManualTag)
                    : ``
                }`
              : item.feedbackType === "BloodGlucose"
              ? `Blood Glucose Readings`
              : item.feedbackType === "MealGraph"
              ? `Meal Graphs`
              : item.feedbackType === "Weight"
              ? `Weight graph`
              : ""}
          </FeedbackCardBodyTitle>
          {item.weightStartDate !== undefined &&
            item.weightEndDate !== undefined && (
              <FeedbackChartContainer>
                <WeightProgressChart
                  dataSource={weightDTOs(
                    item.weightStartDate,
                    item.weightEndDate
                  )}
                />
              </FeedbackChartContainer>
            )}
          {item.bloodGlucoseManualStartDate !== undefined &&
            item.bloodGlucoseManualEndDate && (
              <VeiwPlansManualReadingsChart
                startDate={item.bloodGlucoseManualStartDate}
                endDate={item.bloodGlucoseManualEndDate}
                tagValue={
                  item.bloodGlucoseManualTag !== undefined
                    ? item.bloodGlucoseManualTag
                    : "All"
                }
              />
            )}
          {item.labTestResultsStartDate !== undefined &&
            item.labTestResultsEndDate !== undefined &&
            item.labTestTypeId !== undefined && (
              <ListLabResultCards
                labTestTypeRefDTOs={labTestTypeRefDTOs}
                labTestTypeId={item.labTestTypeId}
                startDate={item.labTestResultsStartDate}
                endDate={item.labTestResultsEndDate}
                viewPlanCard={true}
                labTestTypeDTOs={labTestTypeDTOs}
                patientLabTestResultsDTOs={patientLabTestResultsDTOs}
              />
            )}
          <FeedbackCardCharts viewplans>
            {item.attachments?.map((attachment, index) => {
              if (attachment.attachmentType === "MealGraphChart") {
                return <></>;
              } else if (attachment.attachmentType === "BloodGlucoseChart") {
                return (
                  <Fragment key={index}>
                    {daysAggregates(attachment.bloodGlucoseChartDate!).map(
                      (item) => (
                        <ListBloodGlucoseChart
                          key={item.date.toString()}
                          dayAggregate={item}
                          viewPlanChart={true}
                        />
                      )
                    )}
                  </Fragment>
                );
              } else {
                return (
                  <ListMealGraphChart
                    key={index}
                    viewPlanGraphs={true}
                    attachment={attachment}
                  />
                );
              }
            })}
          </FeedbackCardCharts>
        </FeedbackCardBody>
      </FeedbackCard>
    </SinglePlanDropdownListItem>
  ));

  return (
    <div>
      <SinglePlanDropdown
        icon="/img/plans/nav/feedback.svg"
        img=""
        items={feedback}
        viewPlanGraphs={true}
        subtitle="أبرز النتائج وردود الفعل من الإسبوع الماضي"
        title="معدلات الإسبوع السابق"
      />
    </div>
  );
};
