import styled from "styled-components/macro";
import {
  cardDateFont,
  cardTitleFont,
  flexCenter,
  flexCenterSpaceBetween,
  flexCol,
  regularMediumFont,
  xSmallSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";

export const DailyMealGraphsCardsContainer = styled.div<{
  smallModalGraphs: boolean;
  viewPlanGraphs?: boolean;
}>`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: ${({ smallModalGraphs, viewPlanGraphs }) =>
    smallModalGraphs
      ? "repeat(auto-fit, 270px)"
      : viewPlanGraphs
      ? "repeat(auto-fit, 1fr)"
      : "repeat(auto-fit, minmax(400px, 1fr))"};
  padding: ${({ theme, smallModalGraphs }) =>
    smallModalGraphs ? theme.padding.py_05 : theme.padding.pad05All};

  > div {
    border: ${({ theme, smallModalGraphs }) =>
      smallModalGraphs
        ? `1px solid ${theme.colors.borderColor}`
        : "0px solid transparent"};
    padding: ${({ theme, smallModalGraphs }) =>
      smallModalGraphs ? theme.padding.pad05All : "0px"};
    border-radius: ${({ smallModalGraphs }) =>
      smallModalGraphs ? "0.5rem" : "0rem"};
  }

  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(1, 100%);
    padding: 0.1rem;
  }
`;

export const DailyMealGraphsCardDateContainer = styled.div`
  ${flexCenterSpaceBetween}
`;

export const DailyMealGraphsCardDate = styled.h3<{
  smallModalGraphs: boolean;
}>`
  ${({ smallModalGraphs }) =>
    smallModalGraphs ? xSmallSemiBoldFont : cardDateFont};

  span {
    font-size: ${({ smallModalGraphs }) =>
      smallModalGraphs ? "16px" : "24px"};
  }

  ${flexCenter}
  gap: 0rem 0.5rem;
`;

export const DailyMealGraphsCard = styled.div<{
  smallModalGraphs: boolean;
  viewPlanGraphs?: boolean;
}>`
  ${flexCol}
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 0.5rem;
  padding: ${({ theme, smallModalGraphs }) =>
    smallModalGraphs ? "0.5rem 0rem 0rem" : theme.padding.pad05_1};
  min-height: ${({ smallModalGraphs }) =>
    smallModalGraphs ? "350px" : "500px"};
  position: relative;
  width: ${({ viewPlanGraphs }) => viewPlanGraphs && "375px"};

  @media screen and (max-width: 425px) {
    padding: ${({ theme }) => theme.padding.pad05All};
  }
`;

export const DailyMealGraphsCardHeader = styled.div<{
  smallModalGraphs: boolean;
}>`
  ${flexCol}
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: ${({ theme, smallModalGraphs }) =>
    smallModalGraphs ? theme.padding.pad05All : theme.padding.py_05};
`;

export const DailyMealGraphsCardTitle = styled.p<{
  smallModalGraphs: boolean;
}>`
  ${({ smallModalGraphs }) =>
    smallModalGraphs ? xSmallSemiBoldFont : cardTitleFont};
  ${flexCenterSpaceBetween}
  margin: 0;

  span {
    ${({ smallModalGraphs }) =>
      smallModalGraphs ? xSmallSemiBoldFont : regularMediumFont};
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const DailyMealGraphsCardChartContainer = styled.div<{
  smallModalGraphs: boolean;
}>`
  margin-top: auto;
  padding: ${({ theme, smallModalGraphs }) =>
    smallModalGraphs ? "1rem 0rem 0rem" : theme.padding.py_1};
`;
