import { useMemo } from "react";
import {
  Carbs,
  Fat,
  NutritionSummaryCardFooter,
  NutritionSummaryCardFooterBottom,
  NutritionSummaryCardFooterCenter,
  NutritionSummaryCardFooterTop,
  Protien,
} from "../nutrition-summary/styled-nutrition-summary";
import { PatientMealDTO } from "../../../../../../../models/patient-meal-dtos/patient-meal-dto";
import { roundTo1DecimalPlace } from "../../../../../../../utils/math-utils";

type NutritionSummaryFromMealsFooterProps = {
  patientMealDTOs: PatientMealDTO[];
};

export const NutritionSummaryFromMealsFooter = (
  props: NutritionSummaryFromMealsFooterProps
) => {
  const { patientMealDTOs } = props;

  const { totalKCals } = useMemo(() => {
    let totalKCals = 0;

    for (const patientMealDTO of patientMealDTOs) {
      const carbCounterMealSummary = patientMealDTO.carbCounterV1MealSummary;

      if (carbCounterMealSummary) {
        totalKCals += carbCounterMealSummary.totalKcal;
      }
    }

    return { totalKCals };
  }, [patientMealDTOs]);

  const { carbsPercentage } = useMemo(() => {
    let carbsPercentage = 0;
    let totalCarbsKcal = 0;

    for (const patientMealDTO of patientMealDTOs) {
      const carbCounterMealSummary = patientMealDTO.carbCounterV1MealSummary;

      if (carbCounterMealSummary) {
        totalCarbsKcal += carbCounterMealSummary.totalCarbsKcal;
        carbsPercentage = (totalCarbsKcal / totalKCals) * 100;
      }
    }

    return { carbsPercentage };
  }, [patientMealDTOs, totalKCals]);

  const { fatsPercentage } = useMemo(() => {
    let fatsPercentage = 0;
    let totalFatsKcal = 0;

    for (const patientMealDTO of patientMealDTOs) {
      const carbCounterMealSummary = patientMealDTO.carbCounterV1MealSummary;

      if (carbCounterMealSummary) {
        totalFatsKcal += carbCounterMealSummary.totalFatKcal;
        fatsPercentage = (totalFatsKcal / totalKCals) * 100;
      }
    }

    return { fatsPercentage };
  }, [patientMealDTOs, totalKCals]);

  const { proteinPercentage } = useMemo(() => {
    let proteinPercentage = 0;
    let totalProteinKcal = 0;

    for (const patientMealDTO of patientMealDTOs) {
      const carbCounterMealSummary = patientMealDTO.carbCounterV1MealSummary;

      if (carbCounterMealSummary) {
        totalProteinKcal += carbCounterMealSummary.totalProteinKcal;
        proteinPercentage = (totalProteinKcal / totalKCals) * 100;
      }
    }

    return { proteinPercentage };
  }, [patientMealDTOs, totalKCals]);

  return (
    <NutritionSummaryCardFooter>
      <NutritionSummaryCardFooterTop>
        <p>Macronutrients</p>
        <span>{roundTo1DecimalPlace(totalKCals)} Kcal</span>
      </NutritionSummaryCardFooterTop>
      <NutritionSummaryCardFooterCenter style={{ overflow: "hidden" }}>
        <Carbs width={`${carbsPercentage}%`} />
        <Fat width={`${carbsPercentage + fatsPercentage}%`} />
        <Protien
          width={`${carbsPercentage + fatsPercentage + proteinPercentage}%`}
        />
      </NutritionSummaryCardFooterCenter>
      <NutritionSummaryCardFooterBottom>
        <div>
          <div />
          <span>Carbs: {roundTo1DecimalPlace(carbsPercentage)}%</span>
        </div>
        <div>
          <div />
          <span>Fat: {roundTo1DecimalPlace(fatsPercentage)}%</span>
        </div>
        <div>
          <div />
          <span>Protien: {roundTo1DecimalPlace(proteinPercentage)}%</span>
        </div>
      </NutritionSummaryCardFooterBottom>
    </NutritionSummaryCardFooter>
  );
};
