import { Temporal } from "temporal-polyfill";

/**
 * Using the Date String representation. See:https://developers.google.com/chart/interactive/docs/datesandtimes#dates-and-times-using-the-date-string-representation
 *
 * I'm not using JS `Date` because I'm not sure how Google Charts would interact with the timezone.
 */
export function plainDateTimeToGoogleChartsDateStringRepresentation(
  plainDateTime: Temporal.PlainDateTime
): string {
  // In Date constructor, month is 0-based while day is not. That's why we're subtracting 1 below.
  // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#syntax

  return `Date(${plainDateTime.year}, ${plainDateTime.month - 1}, ${
    plainDateTime.day
  }, ${plainDateTime.hour}, ${plainDateTime.minute}, ${plainDateTime.second})`;
}
