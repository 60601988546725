import styled from "styled-components/macro";

export const AddedPrescriptionMedicinesCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.containerColor};
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.newColors.newBorderColor};
  border-radius: 12px;
  padding: 8px 16px;
`;

export const AddedPrescriptionMedicinesCardDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: auto;
  gap: 0rem 1rem;
`;

export const AddedPrescriptionMedicinesCardIcon = styled.div`
  margin: 0px 16px 17px 0px;
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;
