import { useEffect } from "react";
import { useImmediateAttentionPatientsFiltersSidebar } from "../../../../context/immediate-attention-patients-filters-sidebar-context";
import {
  ImmediateAttentionPatientsFiltersSidebarBackdrop,
  ImmediateAttentionPatientsFiltersSidebarBodyContainer,
  ImmediateAttentionPatientsFiltersSidebarContainer,
  ImmediateAttentionPatientsFiltersSidebarFiltersGroup,
  ImmediateAttentionPatientsFiltersSidebarFiltersSubGroup,
} from "./styled-immediate-attention-patients-filters-sidebar";
import { ImmediateAttentionPatientsFiltersSidebarHeader } from "./immediate-attention-patients-filters-sidebar-header/immediate-attention-patients-filters-sidebar-header";
import { ImmediateAttentionPatientsFiltersSidebarGender } from "./immediate-attention-patients-filters-sidebar-gender/immediate-attention-patients-filters-sidebar-gender";
import { ImmediateAttentionPatientsFiltersSidebarAge } from "./immediate-attention-patients-filters-sidebar-age/immediate-attention-patients-filters-sidebar-age";
import { ImmediateAttentionPatientsFiltersSidebarHba1c } from "./immediate-attention-patients-filters-sidebar-hba1c/immediate-attention-patients-filters-sidebar-hba1c";
import { ImmediateAttentionPatientsFiltersSidebarBmi } from "./immediate-attention-patients-filters-sidebar-bmi/immediate-attention-patients-filters-sidebar-bmi";
import { ImmediateAttentionPatientsFiltersSidebarChronicDiseases } from "./immediate-attention-patients-filters-sidebar-chronic-diseases/immediate-attention-patients-filters-sidebar-chronic-diseases";
import { ImmediateAttentionPatientsFiltersSidebarRiskFactors } from "./immediate-attention-patients-filters-sidebar-risk-factors/immediate-attention-patients-filters-sidebar-risk-factors";
import { ImmediateAttentionPatientsFiltersSidebarComplications } from "./immediate-attention-patients-filters-sidebar-complications/immediate-attention-patients-filters-sidebar-complications";
import { ImmediateAttentionPatientsFiltersSidebarNumberOfChronicDiseases } from "./immediate-attention-patients-filters-sidebar-number-of-chronic-diseases/immediate-attention-patients-filters-sidebar-number-of-chronic-diseases";
import { ImmediateAttentionPatientsFiltersSidebarNumberOfComplications } from "./immediate-attention-patients-filters-sidebar-number-of-complications/immediate-attention-patients-filters-sidebar-number-of-complications";
import { ImmediateAttentionPatientsFiltersSidebarPriority } from "./immediate-attention-patients-filters-sidebar-priority/immediate-attention-patients-filters-sidebar-priority";

export const ImmediateAttentionPatientsFiltersSidebar = () => {
  const {
    showSidebar,
    closeSidebar,
  } = useImmediateAttentionPatientsFiltersSidebar();

  useEffect(() => {
    if (showSidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showSidebar]);

  return (
    <ImmediateAttentionPatientsFiltersSidebarContainer
      $showSidebar={showSidebar}
    >
      <ImmediateAttentionPatientsFiltersSidebarBodyContainer
        $showSidebar={showSidebar}
      >
        <ImmediateAttentionPatientsFiltersSidebarHeader />
        <ImmediateAttentionPatientsFiltersSidebarFiltersGroup>
          <ImmediateAttentionPatientsFiltersSidebarFiltersSubGroup>
            <ImmediateAttentionPatientsFiltersSidebarGender />
            <ImmediateAttentionPatientsFiltersSidebarPriority />
          </ImmediateAttentionPatientsFiltersSidebarFiltersSubGroup>
          <ImmediateAttentionPatientsFiltersSidebarAge />
        </ImmediateAttentionPatientsFiltersSidebarFiltersGroup>
        <ImmediateAttentionPatientsFiltersSidebarFiltersGroup>
          <ImmediateAttentionPatientsFiltersSidebarHba1c />
          <ImmediateAttentionPatientsFiltersSidebarBmi />
        </ImmediateAttentionPatientsFiltersSidebarFiltersGroup>
        <ImmediateAttentionPatientsFiltersSidebarFiltersGroup>
          <ImmediateAttentionPatientsFiltersSidebarNumberOfChronicDiseases />
          <ImmediateAttentionPatientsFiltersSidebarNumberOfComplications />
        </ImmediateAttentionPatientsFiltersSidebarFiltersGroup>
        <ImmediateAttentionPatientsFiltersSidebarChronicDiseases />
        <ImmediateAttentionPatientsFiltersSidebarRiskFactors />
        <ImmediateAttentionPatientsFiltersSidebarComplications />
      </ImmediateAttentionPatientsFiltersSidebarBodyContainer>
      <ImmediateAttentionPatientsFiltersSidebarBackdrop
        onClick={closeSidebar}
      />
    </ImmediateAttentionPatientsFiltersSidebarContainer>
  );
};
