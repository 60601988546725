import { PatientDTO } from "../../../../../models/patient-dtos/patient-dto";
import { isGlucosePointInRange } from "../../../../../utils/glucose-point-utils/glucose-point-range-utils/is-glucose-point-in-range";
import { Reading } from "../../build-days-aggregates";
import { Temporal } from "temporal-polyfill";

const glucoseContainerStyle = `
  display: flex;
  align-items: center;
  padding: 0.3rem 0.7rem;
  justify-content: center;
`;

export function readingToHtmlTooltip(
  reading: Reading,
  patientDTO: PatientDTO
): string {
  const glucoseTime = glucoseTimeToHtml(reading.time);
  const glucoseTooltipBody = glucoseTooltipBodyToHtml(reading, patientDTO);

  const tooltipContainerStyle = `
    width: 270px;
    background: #fff;
  `;

  const toolTipContainerHtml = `
    <div style='${tooltipContainerStyle}'>
      ${glucoseTime}
      ${glucoseTooltipBody}
    </div>
  `;

  return toolTipContainerHtml;
}

function glucoseTimeToHtml(time: Temporal.PlainTime): string {
  const timeContainerStyle = `
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #eaf7ff;
    border-bottom: 1px solid #ecedef;
    padding: 0rem 0.7rem;
  `;

  const titleTextStyle = `
    font-size: 1.1rem;
    font-weight: 700;
    color: #040415;
  `;

  const timeTextStyle = `
    font-size: 1.1rem;
    font-weight: 400;
    color: #8c8e97;
  `;

  return `
    <div style="${timeContainerStyle}">
      <p style='${titleTextStyle}'>Glucose</p>
      <span style='${timeTextStyle}'>
      ${time.toLocaleString("en", { hour12: true, second: undefined })}
      </span>
    </div>
  `;
}

function glucoseReadingToHtml(reading: Reading): string {
  const glucoseValueTextStyle = `
    color: #040415;
    font-size: 23px;
    font-weight: 700;
  `;

  const glucoseUnit = `
    font-size: 1rem;
    font-weight: 600;
    color: #8c8e97;
    transform: translateX(-110%);
  `;

  return `
  <div style="${glucoseContainerStyle}">
    <span style='${glucoseValueTextStyle}'>
      ${String(reading.glucose)}
      <span style='${glucoseUnit}'>mg/dL</span>
    </span>
  </div>
  `;
}

function glucoseTagToHtml(reading: Reading): string {
  const glucoseTagTextStyle = `
    color: #8c8e97;
    font-size: 16px;
    text-align: center;
    margin: 0px auto;
  `;

  const glucoseTagHtml = `
      <div style="${glucoseContainerStyle}">
        <span style='${glucoseTagTextStyle}'>${reading.tag}</span>
      </div>
    `;

  return reading.tag === undefined ? `` : glucoseTagHtml;
}

function glucoseInOrOutOfRangeToHtml(
  reading: Reading,
  patientDTO: PatientDTO
): string {
  const inOrOutOfRangeContainerBgColor = isGlucosePointInRange(
    patientDTO.type,
    reading
  )
    ? "#63c38f"
    : "#ee3c3e";

  const inOrOutOfRangeContainerStyle = `
    width: 100px;
    height: 25px;
    border-radius: 10px;
    color: #fff;
    background-color: ${inOrOutOfRangeContainerBgColor};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    margin-top: 6px;
  `;

  const inOrOutOfRangeText = isGlucosePointInRange(patientDTO.type, reading)
    ? "In Range"
    : "Out of Range";

  return `
  <div style='${inOrOutOfRangeContainerStyle}'>
    ${inOrOutOfRangeText}
  </div>
  `;
}

function glucoseTooltipBodyToHtml(
  reading: Reading,
  patientDTO: PatientDTO
): string {
  const glucoseReading = glucoseReadingToHtml(reading);
  const glucoseTag = glucoseTagToHtml(reading);
  const glucoseInOrOutOfRange = glucoseInOrOutOfRangeToHtml(
    reading,
    patientDTO
  );

  const glucoseReadingAndTagContainer = `<div>${glucoseReading}${glucoseTag}</div>`;
  const glucoseInOrOutOfRangeContainer = `<div style='${glucoseContainerStyle}'>${glucoseInOrOutOfRange}</div>`;

  const tooltipBodyContainerStyle = `
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  `;

  return `
    <div style='${tooltipBodyContainerStyle}'>
      ${glucoseReadingAndTagContainer}
      ${glucoseInOrOutOfRangeContainer}
    </div>
  `;
}
