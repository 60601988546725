import { PatientMealDTO } from "../../../../../../../../../models/patient-meal-dtos/patient-meal-dto";
import { MealsAnalysisResponseTableCell } from "../styled-meals-analysis-response-table-cells";
import { MealsAnalysisResponseTableRowsMacros } from "./meals-analysis-response-table-rows-macros/meals-analysis-response-table-rows-macros";

type MealsAnalysisResponseMacrosTableCellProps = {
  patientMeal: PatientMealDTO;
};

export const MealsAnalysisResponseMacrosTableCell = (
  props: MealsAnalysisResponseMacrosTableCellProps
) => {
  const { patientMeal } = props;

  const macrosCarbCounter = patientMeal.carbCounterV1MealSummary;
  const macrosMealSummary = patientMeal.patientMealMacrosSummary;

  return (
    <MealsAnalysisResponseTableCell $macrosCell>
      <MealsAnalysisResponseTableRowsMacros
        macrosMealSummary={macrosMealSummary}
        macrosCarbCounter={macrosCarbCounter}
      />
    </MealsAnalysisResponseTableCell>
  );
};
