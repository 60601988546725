import { Temporal } from "temporal-polyfill";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { UpdatePatientPlanDTO } from "../../../../../../../models/patient-plan-dtos/update-patient-plan-dto";
import {
  Button,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import {
  useDetailedPlan,
  useReloadDetailedPlan,
} from "../../../../context/loadable-detailed-plan-context";
import { FeedbackModalModalContainer } from "../feedback-modal-body/styled-feedback-modal-body";
import { useState } from "react";
import { useUpdatePatientPlan } from "../../../../../../../hooks/patient-plan-hooks/use-update-patient-plan";
import { useFormContext } from "react-hook-form";
import { ManualReadingsFormInputs } from "./manual-readings-modal-container";
import { PlansForm } from "../../../../components/plans-form/plans-form";
import { EmptyFeedbackModal } from "../feedback-modal-body/empty-feedback-modal";
import { useFilterPatientCGMEntries } from "../../../../../../../hooks/use-filter-patient-cgm-entries/use-filter-patient-cgm-entries";
import { ManualReadingsModalGraphContainer } from "./styled-manual-readings-modal";
import { BgmChart } from "../../../../../../../components/bgm-chart/bgm-chart";
import useOnReadingsChanged from "../../../../../bgl-analysis/upload-options/manual-readings/manual-readings-modal/hook/useOnReadingsChanged";

type ManualReadingsModalProps = {
  resetCurrentFeedbackModal: () => void;
};

export const ManualReadingsModal = (props: ManualReadingsModalProps) => {
  const { resetCurrentFeedbackModal } = props;

  const { handleSubmit, watch } = useFormContext<ManualReadingsFormInputs>();
  const { patientData } = useOnReadingsChanged();
  const { patientDTO } = useDetailedPlan();

  const updatePatientPlan = useUpdatePatientPlan();
  const reloadPatientDetailedPlan = useReloadDetailedPlan();

  const [tagValue, setTagValue] = useState("All");
  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const temporalStartDate = Temporal.PlainDate.from(
    startDate.toISOString().split("T")[0]
  ).toString();

  const temporalEndDate = Temporal.PlainDate.from(
    endDate.toISOString().split("T")[0]
  ).toString();

  const { filteredPatientDataByDate, filteredByTags } =
    useFilterPatientCGMEntries(
      temporalStartDate,
      temporalEndDate,
      tagValue,
      patientData
    );

  const feedbackTone = watch("feedbackTone");
  const notes = watch("notes");

  const bloodGlucoseManualStartDate = watch("bloodGlucoseManualStartDate");
  const bloodGlucoseManualEndDate = watch("bloodGlucoseManualEndDate");

  const { patientDetailedPlanDTO } = useDetailedPlan();
  const { showToast } = useToastService();

  const onSubmit = handleSubmit(async () => {
    const updateDTO: UpdatePatientPlanDTO = {
      ...patientDetailedPlanDTO,
      patientPlanFeedbacks: [
        ...patientDetailedPlanDTO.patientPlanFeedbacks,
        {
          feedbackType: "BloodGlucoseManual",
          feedbackTone: feedbackTone!,
          notes,
          bloodGlucoseManualStartDate: temporalStartDate,
          bloodGlucoseManualEndDate: temporalEndDate,
          bloodGlucoseManualTag: tagValue,
        },
      ],
    };

    updatePatientPlan(patientDetailedPlanDTO.patientPlan, updateDTO)
      .then(() => {
        showToast("Success", "Feedback added successfully");
        reloadPatientDetailedPlan();
      })
      .catch(() => showToast("Error", "Failed to add feedback :("));
  });

  const notesCondition = notes === "" || notes === undefined;
  const datesCondition =
    bloodGlucoseManualStartDate !== undefined &&
    bloodGlucoseManualEndDate !== undefined;
  const modalDisabledCondition =
    feedbackTone === undefined || datesCondition || notesCondition;

  return (
    <ModalForm width={900} height={750} onSubmit={onSubmit}>
      <ModalHeader
        onModalClose={resetCurrentFeedbackModal}
        title="Manual Readings Feedback"
        subTitle="Send a manual reading Feedback to the patient"
      />
      <FeedbackModalModalContainer>
        <PlansForm
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          manualReadingsFeedbackForm={{ setTagValue, state: true, tagValue }}
        />
        {filteredPatientDataByDate.length === 0 ||
        (filteredByTags.length === 0 && tagValue !== "All") ? (
          <EmptyFeedbackModal subText="Select start and end date from Graph date to show manual readings graph" />
        ) : (
          <ManualReadingsModalGraphContainer>
            <BgmChart
              startDate={temporalStartDate}
              endDate={temporalEndDate}
              tagValue={tagValue}
              patientType={patientDTO.type}
              hasTooltip={false}
            />
          </ManualReadingsModalGraphContainer>
        )}
      </FeedbackModalModalContainer>
      <ModalFooter>
        <Button type="button" outlined onClick={resetCurrentFeedbackModal}>
          Cancel
        </Button>
        <Button type="submit" disabled={modalDisabledCondition}>
          Add
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
