import { PatientActivityLogDTO } from "../../../../models/patient-activity-log-dtos/patient-activity-log-dto";
import { PatientCGMEntryDTO } from "../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientInsulinLogDTO } from "../../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";
import { PatientMealDTO } from "../../../../models/patient-meal-dtos/patient-meal-dto";
import {
  Meal,
  Medicine,
} from "../../../cgm-google-chart/utils/build-days-aggregates";
import { ActivitiesTooltip } from "./activities-tooltip";
import { CgmOrBgmReadingsTooltip } from "./cgm-or-bgm-readings-tooltip";
import { InsulinTooltip } from "./insuiln-tooltip";
import { MealsTooltip } from "./meals-tooltip";
import { MedicationTooltip } from "./medication-tooltip";
import { PatientMealsTooltip } from "./patient-meals-tooltip";

export type CgmTooltipProps = {
  x: Date;
  y: number;
  kind:
    | {
        kind: "Reading";
        data: PatientCGMEntryDTO & { isInRange: boolean };
      }
    | {
        kind: "Insulin";
        data: PatientInsulinLogDTO;
      }
    | {
        kind: "Meal";
        data: Meal;
      }
    | {
        kind: "Medicine";
        data: Medicine;
      }
    | {
        kind: "PatientMeal";
        data: PatientMealDTO;
      }
    | {
        kind: "Activities";
        data: PatientActivityLogDTO;
      };
};

export const CgmTooltip = (props: CgmTooltipProps) => {
  const { x, y, kind } = props;

  if (kind.kind === "Reading") {
    return <CgmOrBgmReadingsTooltip x={x} y={y} data={kind.data} />;
  } else if (kind.kind === "Insulin") {
    return <InsulinTooltip x={x} y={y} data={kind.data} />;
  } else if (kind.kind === "Meal") {
    return <MealsTooltip x={x} y={y} data={kind.data} />;
  } else if (kind.kind === "Medicine") {
    return <MedicationTooltip x={x} y={y} data={kind.data} />;
  } else if (kind.kind === "PatientMeal") {
    return <PatientMealsTooltip x={x} y={y} data={kind.data} />;
  } else if (kind.kind === "Activities") {
    return <ActivitiesTooltip x={x} y={y} data={kind.data} />;
  } else {
    return <></>;
  }
};
