import { GoogleDataTableRow } from "react-google-charts";
import { PatientMealDTO } from "../../../../../models/patient-meal-dtos/patient-meal-dto";
import { Temporal } from "temporal-polyfill";
import { buildGapRow } from "../build-gap-row";
import { buildPatientMealsRow } from "./build-patient-meals-row";

export function buildPatientMealsRows(
  date: Temporal.PlainDate,
  patientMeals: PatientMealDTO[],
  reverseVAxisTicks: number[]
): GoogleDataTableRow[] {
  const rows: GoogleDataTableRow[] = [];

  for (const patientMeal of patientMeals) {
    const time = Temporal.PlainTime.from(patientMeal.time);
    const plainDateTime = date.toPlainDateTime(time);

    rows.push(buildGapRow(plainDateTime, reverseVAxisTicks));
    rows.push(
      buildPatientMealsRow(plainDateTime, reverseVAxisTicks, patientMeal)
    );
  }

  return rows;
}
