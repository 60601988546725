import {
  UXInputContainer,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../../styles/classes/reusable-classes";

type LessonReadModeCardTextPartProps = {
  cardPartText?: string;
};

export const LessonReadModeCardTextPart = (
  props: LessonReadModeCardTextPartProps
) => {
  const { cardPartText } = props;

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="50px">Text</UXInputLabel>
      <UXInputWrapper $textareaDimentions $width="100%" $readOnly>
        <h6>{cardPartText}</h6>
      </UXInputWrapper>
    </UXInputContainer>
  );
};
