import { CGMReportResponseGlucoseStatistics } from "./cgm-report-response-glucose-statistics/cgm-report-response-glucose-statistics";
import { CGMReportResponseTimeRanges } from "./cgm-report-response-time-ranges/cgm-report-response-time-ranges";
import { CGMReportResponseCardsContainer } from "./styled-cgm-report-response-cards";

export const CGMReportResponseCards = () => {
  return (
    <CGMReportResponseCardsContainer>
      <CGMReportResponseTimeRanges />
      <CGMReportResponseGlucoseStatistics />
    </CGMReportResponseCardsContainer>
  );
};
