import { PatientNutritionReportDailySummaryDTO } from "../../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-daily-summary-dto";
import { NutritionSummaryForDemoCenter } from "./nutrition-summary-for-demo-center";
import { NutritionSummaryForDemoFooter } from "./nutrition-summary-for-demo-footer";
import {
  nutritionSummaryForDemo,
  nutritionSummaryForDemoMacros,
  nutritionSummaryForDemoToolTips,
} from "./utils/nutrition-summary-for-demo-utils";

type NutritionSummaryForDemoResponseProps = {
  patientNutritionReportDailySummaryDTO: PatientNutritionReportDailySummaryDTO;
};

export const NutritionSummaryForDemoResponse = (
  props: NutritionSummaryForDemoResponseProps
) => {
  const { patientNutritionReportDailySummaryDTO } = props;
  return (
    <>
      <NutritionSummaryForDemoCenter
        nutritionSummaryForDemo={nutritionSummaryForDemo(
          patientNutritionReportDailySummaryDTO
        )}
        nutritionSummaryForDemoToolTips={nutritionSummaryForDemoToolTips(
          patientNutritionReportDailySummaryDTO
        )}
      />
      <NutritionSummaryForDemoFooter
        nutritionSummaryForDemoMacros={nutritionSummaryForDemoMacros(
          patientNutritionReportDailySummaryDTO
        )}
      />
    </>
  );
};
