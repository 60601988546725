import { PatientMedicationDTO } from "../../../../../../models/patient-medication-dto";
import {
  MedicationCardIconContainer,
  MedicationCardText,
  MedicationCardTitle,
} from "../../../components/medication-card/styled-medication-card";
import { SinglePrescriptionMedicationCardContainer } from "./styled-single-prescription-medication-card";

type SinglePrescriptionMedicationCardProps = {
  card: PatientMedicationDTO;
};

export const SinglePrescriptionMedicationCard = (
  props: SinglePrescriptionMedicationCardProps
) => {
  const { card } = props;

  const cardFrequency =
    card.frequency !== undefined
      ? `${card.frequency.explanation} (${card.frequency.abbreviation})`
      : ``;

  return (
    <SinglePrescriptionMedicationCardContainer>
      <MedicationCardIconContainer>
        <span className="material-symbols-outlined">medication</span>
      </MedicationCardIconContainer>
      <div>
        <MedicationCardTitle>{card.medication.name}</MedicationCardTitle>
        <MedicationCardText>
          {`${card.quantity !== undefined ? card.quantity : ""} ${
            card.medication.doseUnit !== undefined
              ? card.medication.doseUnit
              : ""
          }`}
          , {cardFrequency} for {`${card.durationDays} Days`}{" "}
          {`${card.notes !== undefined ? card.notes : ""}`}
        </MedicationCardText>
      </div>
    </SinglePrescriptionMedicationCardContainer>
  );
};
