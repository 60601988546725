import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { FeedbackModal } from "./feedback-modal/feedback-modal";

export const EmptyFeedback = () => {
  return (
    <div>
      <EmptyState
        title=""
        text="Add a feedback"
        subText="Write a feedback to your patient about last week"
        hasSearch={false}
        hasHeader={false}
        button={<FeedbackModal />}
      />
    </div>
  );
};
