import { Controller, useForm } from "react-hook-form";
import {
  Button,
  ErrorText,
  Input,
  InputContainer,
  InputsGroup,
} from "../../../styles/classes/reusable-classes";
import {
  LoginWithEmailAndPasswordContainer,
  LoginWithEmailAndPasswordForm,
  LoginWithEmailAndPasswordOrText,
} from "./styled-login-with-email-and-password";
import { useAuth } from "../../../context/auth-context/auth-context";
import { useLogin } from "../../../hooks/auth-hooks/use-login";
import { useApiWithFullPageLoader } from "../../../hooks/use-api-with-full-page-loader";
import { useToastService } from "../../../context/toast-service-context";

type LoginWithEmailAndPasswordInputs = {
  email?: string;
  password?: string;
};

export const LoginWithEmailAndPassword = () => {
  const login = useApiWithFullPageLoader(useLogin());

  const { setAndPersistUser } = useAuth();

  const { showToast } = useToastService();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginWithEmailAndPasswordInputs>();

  const onSubmit = handleSubmit(async (inputs) => {
    const loginDTO = { email: inputs.email!, password: inputs.password! };

    try {
      const loginResponse = await login(loginDTO);

      setAndPersistUser({
        credential: loginResponse.token!,
      });
    } catch (error) {
      showToast("Error", "Invalid credentials.");
    }
  });

  return (
    <LoginWithEmailAndPasswordContainer>
      <LoginWithEmailAndPasswordOrText>or</LoginWithEmailAndPasswordOrText>
      <LoginWithEmailAndPasswordForm>
        <InputsGroup>
          <Controller
            control={control}
            name="email"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <InputContainer margin={errors.email?.type === "required"}>
                <Input
                  error={errors.email?.type === "required"}
                  type="text"
                  placeholder="Email"
                  value={value !== undefined ? value : ""}
                  onChange={(e) =>
                    onChange(e.target.value !== undefined ? e.target.value : "")
                  }
                />
                {errors.email?.type === "required" && (
                  <ErrorText>Email is required</ErrorText>
                )}
              </InputContainer>
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <InputContainer margin={errors.password?.type === "required"}>
                <Input
                  error={errors.password?.type === "required"}
                  type="password"
                  placeholder="Password"
                  value={value !== undefined ? value : ""}
                  onChange={(e) =>
                    onChange(e.target.value !== undefined ? e.target.value : "")
                  }
                />
                {errors.password?.type === "required" && (
                  <ErrorText>Password is required</ErrorText>
                )}
              </InputContainer>
            )}
          />
        </InputsGroup>
        <Button type="button" onClick={onSubmit}>
          Login
        </Button>
      </LoginWithEmailAndPasswordForm>
    </LoginWithEmailAndPasswordContainer>
  );
};
