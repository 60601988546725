import styled, { keyframes } from "styled-components/macro";
import { transition } from "../../../../../../styles/global-style";
import { flexCenter } from "../../../../../../styles/classes/gloabl-classes";

const Flash = keyframes`
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

export const AudioRecorderContainer = styled.div<{ isRecording: boolean }>`
  position: relative;
  top: 8px;
  align-self: ${({ isRecording }) => (isRecording ? "center" : "auto")};
`;

export const AudioRecorderButton = styled.button<{ isRecording: boolean }>`
  display: flex;
  justify-content: ${({ isRecording }) =>
    isRecording ? `space-between` : `center`};
  align-items: center;
  border: 1px solid
    ${({ isRecording }) => (isRecording ? "#016dff" : "transparent")};
  height: 50px;
  background: "transparent";
  background: transparent;
  padding: ${({ isRecording }) => (isRecording ? "8px" : "0px")};
  width: ${({ isRecording }) => (isRecording ? "250px" : "50px")};
  border-radius: ${({ isRecording }) => (isRecording ? "4px" : "0px")};
  transition: ${transition};

  img {
    width: ${({ isRecording }) => (isRecording ? "" : "30px")};
  }
`;

export const RedDot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.danger};
  animation: ${Flash} 1s ease-in-out infinite;
`;

export const TimeTextContainer = styled.div`
  ${flexCenter}
  gap: 0px 8px;

  img {
    margin-right: 1rem;
  }
`;
