import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  PropsWithChildren,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Temporal } from "temporal-polyfill";

type CGMReportSelectedDates = {
  temporalStartDate?: string;
  temporalEndDate?: string;
  count: number;
};

type DailyChartsDateRangePickerRef = RefObject<DateRangePickerComponent>;

type DailyChartsDateRange = {
  startDate?: Date;
  endDate?: Date;
  temporalStartDate?: string;
  temporalEndDate?: string;
  onDatesChange: (e: any) => void;
  handleIncrementDates: () => void;
  handleDecrementDates: () => void;
  selectedDates: CGMReportSelectedDates;
  DailyChartsDateRangePickerRef: DailyChartsDateRangePickerRef;
  onDailyChartsDateRangePickerClick: () => void | undefined;
  resetDates: () => void;
};

const DailyChartsDateRangeContext = createContext<
  DailyChartsDateRange | undefined
>(undefined);

type DailyChartsDateRangeProviderProps = PropsWithChildren<{
  startFromHowManyDaysAgo?: Date;
  startAndEndDatesAreUndefined?: boolean;
}>;

export function DailyChartsDateRangeProvider(
  props: DailyChartsDateRangeProviderProps
) {
  const { children } = props;

  const DailyChartsDateRangePickerRef: DailyChartsDateRangePickerRef = useRef<
    DateRangePickerComponent
  >(null);

  const onDailyChartsDateRangePickerClick = useCallback(
    () => DailyChartsDateRangePickerRef.current?.show(),
    []
  );

  const { currentDate, twoWeeksAgo } = useMemo(() => {
    let currentDate = new Date();

    let twoWeeksAgo = new Date(
      currentDate.getTime() - 13 * 24 * 60 * 60 * 1000
    );
    return { currentDate, twoWeeksAgo };
  }, []);

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const temporalStartDate =
    startDate !== undefined
      ? Temporal.PlainDate.from(
          startDate.toISOString().split("T")[0]
        ).toString()
      : undefined;

  const temporalEndDate =
    endDate !== undefined
      ? Temporal.PlainDate.from(endDate.toISOString().split("T")[0]).toString()
      : undefined;

  const generateDailyChartsDateRange = useCallback(
    (startDate: Date, endDate: Date) => {
      const DailyChartsDateRange: Date[] = [];
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        DailyChartsDateRange.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return {
        dates: DailyChartsDateRange,
        numberOfDays: DailyChartsDateRange.length - 1,
      };
    },
    []
  );

  const onDatesChange = useCallback(
    (e: any) => {
      if (e.target.value !== null) {
        setStartDate(e.target.value[0]);
        setEndDate(e.target.value[1]);
      }
    },
    [setStartDate, setEndDate]
  );

  const handleIncrementDates = useCallback(() => {
    const newStartDate = new Date(
      endDate !== undefined ? endDate : currentDate
    );
    newStartDate.setDate(newStartDate.getDate() + 1);
    setStartDate(newStartDate);

    const newEndDate = new Date(newStartDate);
    newEndDate.setDate(
      newEndDate.getDate() +
        generateDailyChartsDateRange(
          startDate !== undefined ? startDate : twoWeeksAgo,
          endDate !== undefined ? endDate : currentDate
        ).numberOfDays
    );
    setEndDate(newEndDate);
  }, [
    setStartDate,
    setEndDate,
    endDate,
    generateDailyChartsDateRange,
    startDate,
    currentDate,
    twoWeeksAgo,
  ]);

  const handleDecrementDates = useCallback(() => {
    const newEndDate = new Date(
      startDate !== undefined ? startDate : twoWeeksAgo
    );
    newEndDate.setDate(newEndDate.getDate() - 1);
    setEndDate(newEndDate);

    const newStartDate = new Date(newEndDate);
    newStartDate.setDate(
      newStartDate.getDate() -
        generateDailyChartsDateRange(
          startDate !== undefined ? startDate : twoWeeksAgo,
          endDate !== undefined ? endDate : currentDate
        ).numberOfDays
    );
    setStartDate(newStartDate);
  }, [
    setStartDate,
    setEndDate,
    endDate,
    generateDailyChartsDateRange,
    startDate,
    currentDate,
    twoWeeksAgo,
  ]);

  const selectedDates = useMemo(
    () => ({
      temporalStartDate,
      temporalEndDate,
      count:
        generateDailyChartsDateRange(
          startDate !== undefined ? startDate : twoWeeksAgo,
          endDate !== undefined ? endDate : currentDate
        ).numberOfDays + 1,
    }),
    [
      temporalStartDate,
      temporalEndDate,
      generateDailyChartsDateRange,
      startDate,
      endDate,
      twoWeeksAgo,
      currentDate,
    ]
  );

  const resetDates = useCallback(() => {
    setStartDate(undefined);
    setEndDate(undefined);
  }, [setStartDate, setEndDate]);

  const value = useMemo(
    () => ({
      startDate,
      endDate,
      temporalStartDate,
      temporalEndDate,
      onDatesChange,
      handleIncrementDates,
      handleDecrementDates,
      selectedDates,
      DailyChartsDateRangePickerRef,
      onDailyChartsDateRangePickerClick,
      resetDates,
    }),
    [
      startDate,
      endDate,
      temporalStartDate,
      temporalEndDate,
      onDatesChange,
      handleIncrementDates,
      handleDecrementDates,
      selectedDates,
      DailyChartsDateRangePickerRef,
      onDailyChartsDateRangePickerClick,
      resetDates,
    ]
  );

  return (
    <DailyChartsDateRangeContext.Provider value={value}>
      {children}
    </DailyChartsDateRangeContext.Provider>
  );
}

export function useDailyChartsDateRange(): DailyChartsDateRange {
  const DailyChartsDateRange = useContext(DailyChartsDateRangeContext);

  if (DailyChartsDateRange === undefined) {
    throw new Error(
      `useDailyChartsDateRange must be used within DailyChartsDateRangeProvider`
    );
  }

  return DailyChartsDateRange;
}
