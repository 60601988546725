import { TagDropdown } from "../../../upload-options/manual-readings/manual-readings-modal/manual-readings-table/tag-dropdown/tag-dropdown";

type ManualReadingsTagsFilterProps = {
  tagValue: string;
  setTagValue: (tagValue: string) => void;
};

export const ManualReadingsTagsFilter = (
  props: ManualReadingsTagsFilterProps
) => {
  const { tagValue, setTagValue } = props;

  return (
    <div>
      <TagDropdown
        value={undefined}
        onChange={() => {}}
        filter={{ onChange: setTagValue, value: tagValue }}
      />
    </div>
  );
};
