import { useLocation } from "react-router-dom";
import { getCategoryLabTestTypeDTOsFromLocation } from "../utils/lab-test-results-utils";
import { PatientLabTestResultDTO } from "../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import { Temporal } from "temporal-polyfill";
import { useMemo } from "react";
import { LabTestTypeDTO } from "../../../../models/lab-test-types/lab-test-type-dto";
import { LabResultTestLists } from "./lab-result-test/lab-result-test-lists";
import { useLabResults } from "../context/loadable-lab-results-context";

/**
 * Using an array instead of an object or map to preserve the order of the lab test types.
 */
export type GroupedLabTestResultsByType = Array<{
  labTestTypeDTO: LabTestTypeDTO;
  sortedLabTestResultsByDate: PatientLabTestResultDTO[];
}>;

export const LabResultTest = () => {
  const { patientLabTestResultsDTOs, labTestCategoryDTOs, labTestTypeDTOs } =
    useLabResults();

  const location = useLocation();

  const filteredLabTestTypeDTOs = useMemo(
    () =>
      getCategoryLabTestTypeDTOsFromLocation(
        location.pathname,
        labTestCategoryDTOs,
        labTestTypeDTOs
      ),
    [location.pathname, labTestTypeDTOs, labTestCategoryDTOs]
  );

  const filteredLabTestResults = useMemo(
    () =>
      patientLabTestResultsDTOs.filter((labTest) => {
        return filteredLabTestTypeDTOs.some(
          (labTestTypeDTO) => labTestTypeDTO.id === labTest.labTestTypeId
        );
      }),
    [patientLabTestResultsDTOs, filteredLabTestTypeDTOs]
  );

  const groupedLabTestResultsByType = useMemo(() => {
    const result: GroupedLabTestResultsByType = [];

    // O(N^2) but N is small.
    // Can be optimized with a map if needed. Still possible to preserve order in that case.
    for (const labTestTypeDTO of filteredLabTestTypeDTOs) {
      const labTestResults = filteredLabTestResults.filter(
        (labTest) => labTest.labTestTypeId === labTestTypeDTO.id
      );

      if (labTestResults.length > 0) {
        const sortedLabTestResultsByDate = labTestResults.sort(
          (a: PatientLabTestResultDTO, b: PatientLabTestResultDTO) => {
            const aDate = Temporal.PlainDate.from(a.date);
            const bDate = Temporal.PlainDate.from(b.date);

            return Temporal.PlainDate.compare(aDate, bDate);
          }
        );

        result.push({ labTestTypeDTO, sortedLabTestResultsByDate });
      }
    }

    return result;
  }, [filteredLabTestResults, filteredLabTestTypeDTOs]);

  return (
    <LabResultTestLists
      groupedLabTestResultsByType={groupedLabTestResultsByType}
    />
  );
};
