import { CarbCounterV1MealSummaryDTO } from "../../../../../../../../../models/carb-counter-dtos/carb-counter-v1-meal-summary-dto";
import { PatientMealMacrosSummaryDTO } from "../../../../../../../../../models/patient-meal-dtos/patient-meal-macros-summary-dto";
import { GroupedPatientMealsByDate } from "../../../../../context/nutrition-analysis-data-context";

type MacrosSeriesDataSource = {
  x: string;
  y?: number;
  y1?: number;
  y2?: number;
};

export function buildMacrosSeries(
  groupedPatientMealsByDate: GroupedPatientMealsByDate[]
) {
  const mealsTagsSereis: MacrosSeriesDataSource[] = [];

  for (const groupedPatientMeals of groupedPatientMealsByDate) {
    const date = groupedPatientMeals.date;

    for (const patientMeal of groupedPatientMeals.patientMeals) {
      const carbCounterV1MealSummary = patientMeal.carbCounterV1MealSummary;
      const patientMealMacrosSummary = patientMeal.patientMealMacrosSummary;

      if (carbCounterV1MealSummary) {
        generateMealTagSeriesCarbCounterItem(
          carbCounterV1MealSummary,
          date,
          mealsTagsSereis,
          groupedPatientMeals
        );
      } else {
        generateMealTagSeriesMacrosSummaryItem(
          patientMealMacrosSummary,
          date,
          mealsTagsSereis,
          groupedPatientMeals
        );
      }
    }
  }

  return mealsTagsSereis;
}

function generateMealTagSeriesCarbCounterItem(
  carbCounterV1MealSummary: CarbCounterV1MealSummaryDTO,
  date: string,
  mealsTagsSereis: MacrosSeriesDataSource[],
  groupedPatientMeals: GroupedPatientMealsByDate
) {
  if (carbCounterV1MealSummary.totalCarbsGm > 0) {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y: groupedPatientMeals.totalMacros.carbs,
    });
  }

  if (carbCounterV1MealSummary.totalFatGm > 0) {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y1: groupedPatientMeals.totalMacros.fats,
    });
  }

  if (carbCounterV1MealSummary.totalProteinGm > 0) {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y2: groupedPatientMeals.totalMacros.protein,
    });
  }
}

function generateMealTagSeriesMacrosSummaryItem(
  patientMealMacrosSummary: PatientMealMacrosSummaryDTO,
  date: string,
  mealsTagsSereis: MacrosSeriesDataSource[],
  groupedPatientMeals: GroupedPatientMealsByDate
) {
  if (patientMealMacrosSummary.carbsGm > 0) {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y: groupedPatientMeals.totalMacros.carbs,
    });
  }

  if (patientMealMacrosSummary.fatGm > 0) {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y1: groupedPatientMeals.totalMacros.fats,
    });
  }

  if (patientMealMacrosSummary.proteinGm > 0) {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y2: groupedPatientMeals.totalMacros.protein,
    });
  }
}

function generateXaxisValue(date: string) {
  return new Date(date).toLocaleDateString("en-UK", {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
  });
}
