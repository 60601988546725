import { buildPatientProfileHeaderBottomCards } from "../../../utils/patient-profile-utils";
import { usePatientProfileInformationHeader } from "../../context/loadable-patient-profile-information-header-context";
import { PatientProfileInformationHeaderKeyIndicators } from "./patient-profile-information-header-key-indicators/patient-profile-information-header-key-indicators";
import {
  PatientProfileInformationHeaderCenterBottomCard,
  PatientProfileInformationHeaderCenterBottomCardProgress,
  PatientProfileInformationHeaderCenterBottomCardProgressIcon,
  PatientProfileInformationHeaderCenterBottomCardValue,
  PatientProfileInformationHeaderCenterBottomContainer,
} from "./styled-patient-profile-information-header-center-bottom";

export const PatientProfileInformationHeaderCenterBottom = () => {
  const {
    patientDTO,
    patientBloodPressureDTOs,
    patientWeightDTOs,
  } = usePatientProfileInformationHeader();

  const cards = buildPatientProfileHeaderBottomCards(
    patientDTO,
    patientBloodPressureDTOs,
    patientWeightDTOs
  ).map((card) => (
    <PatientProfileInformationHeaderCenterBottomCard key={card.id}>
      <PatientProfileInformationHeaderCenterBottomCardValue>
        {card.value !== undefined && card.bloodPressureValue === undefined
          ? card.value
          : card.value === undefined && card.bloodPressureValue !== undefined
          ? `${card.bloodPressureValue.systolic}/${card.bloodPressureValue.diastolic}`
          : ""}{" "}
        <span>{card.unit !== undefined ? card.unit : ""}</span>
      </PatientProfileInformationHeaderCenterBottomCardValue>
      <PatientProfileInformationHeaderCenterBottomCardProgress>
        <h1>{card.name}</h1>
        {card.successProgress !== undefined && (
          <PatientProfileInformationHeaderCenterBottomCardProgressIcon
            successProgress={card.successProgress!}
          >
            <span className="material-symbols-outlined">arrow_drop_down</span>
            <p>
              {`${card.progressValue} ${
                card.unit !== undefined ? card.unit : ""
              }`}
            </p>
          </PatientProfileInformationHeaderCenterBottomCardProgressIcon>
        )}
      </PatientProfileInformationHeaderCenterBottomCardProgress>
    </PatientProfileInformationHeaderCenterBottomCard>
  ));

  return (
    <PatientProfileInformationHeaderCenterBottomContainer>
      {cards}
      <PatientProfileInformationHeaderKeyIndicators />
    </PatientProfileInformationHeaderCenterBottomContainer>
  );
};
