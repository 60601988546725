import { usePlansSteps } from "../context/plans-steps-context";
import { Education } from "./education/education";
import { Feedback } from "./feedback/feedback";
import { Goals } from "./goals/goals";
import { Lifestyle } from "./lifestyle/lifestyle";
import { Medical } from "./medical/medical";
import { Nutrition } from "./nutrition/nutrition";
import { Review } from "./review/review";
import { GoalsProvider } from "../context/goals-context";

export const PlanSubScreens = () => {
  const { currentStep } = usePlansSteps();

  return <GoalsProvider>{handleCurrentStep(currentStep)}</GoalsProvider>;
};

type CurrentStep =
  | "Goals"
  | "Feedback"
  | "Medical"
  | "Nutrition"
  | "Lifestyle"
  | "Education"
  | "Review";

function handleCurrentStep(step: CurrentStep) {
  if (step === "Goals") {
    return <Goals />;
  } else if (step === "Feedback") {
    return <Feedback />;
  } else if (step === "Medical") {
    return <Medical />;
  } else if (step === "Nutrition") {
    return <Nutrition />;
  } else if (step === "Lifestyle") {
    return <Lifestyle />;
  } else if (step === "Education") {
    return <Education />;
  } else if (step === "Review") {
    return <Review />;
  } else {
    // Should never happen
    return <></>;
  }
}
