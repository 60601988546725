import { useCGMReportLoadedResponse } from "../../../../context/cgm-report-loaded-response-context";
import {
  CGMReportResponseTimeRangesChartContainer,
  CGMReportResponseTimeRangesChartItem,
} from "./styled-cgm-report-response-time-ranges-chart";

export const CGMReportResponseTimeRangesChart = () => {
  const {
    veryHighPercentage,
    highPercentage,
    targetPercentage,
    lowPercentage,
    veryLowPercentage,
  } = useCGMReportLoadedResponse();

  return (
    <CGMReportResponseTimeRangesChartContainer>
      <CGMReportResponseTimeRangesChartItem
        className="very-high"
        $height={`${veryHighPercentage}%`}
      />
      <CGMReportResponseTimeRangesChartItem
        className="high"
        $height={`${highPercentage}%`}
      />
      <CGMReportResponseTimeRangesChartItem
        className="target"
        $height={`${targetPercentage}%`}
      />
      <CGMReportResponseTimeRangesChartItem
        className="low"
        $height={`${lowPercentage}%`}
      />
      <CGMReportResponseTimeRangesChartItem
        className="very-low"
        $height={`${veryLowPercentage}%`}
      />
    </CGMReportResponseTimeRangesChartContainer>
  );
};
