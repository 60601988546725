import { Button } from "../../../styles/classes/reusable-classes";
import { useModalHelpers } from "../../../components/modal/use-modal-helpers";
import { Modal } from "../../../components/modal/modal";
import { PatientModal } from "../patient-modal/patient-modal";

export function AddNewPatient() {
  const {
    isModalOpen,
    openModal,
    closeModal,
    modalBodyKey,
  } = useModalHelpers();

  return (
    <>
      <Button flex onClick={openModal}>
        <span className="material-symbols-outlined">add_circle_outline</span>
        <p>Add New Patient</p>
      </Button>

      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <PatientModal
          key={modalBodyKey}
          onModalClose={closeModal}
          mode={{ kind: "add" }}
        />
      </Modal>
    </>
  );
}
