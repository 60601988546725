import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import {
  NavigateOptions,
  URLSearchParamsInit,
  useSearchParams,
} from "react-router-dom";

type NutritionReportSearchParams = {
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit?:
      | URLSearchParamsInit
      | ((prev: URLSearchParams) => URLSearchParamsInit)
      | undefined,
    navigateOpts?: NavigateOptions | undefined
  ) => void;
};

const NutritionReportSearchParamsContext = createContext<
  NutritionReportSearchParams | undefined
>(undefined);

export const NutritionReportSearchParamsProvider = (
  props: PropsWithChildren<{}>
) => {
  const { children } = props;

  const [searchParams, setSearchParams] = useSearchParams({
    patientID: "",
    startDate: "",
    endDate: "",
    selectedDays: [],
    meals: [],
  });

  const value = useMemo(
    () => ({
      searchParams,
      setSearchParams,
    }),
    [searchParams, setSearchParams]
  );

  return (
    <NutritionReportSearchParamsContext.Provider value={value}>
      {children}
    </NutritionReportSearchParamsContext.Provider>
  );
};

export const useNutritionReportSearchParams =
  (): NutritionReportSearchParams => {
    const nutritionReportSearchParams = useContext(
      NutritionReportSearchParamsContext
    );

    if (nutritionReportSearchParams === undefined) {
      throw new Error(
        `useNutritionReportSearchParams must be within NutritionReportSearchParamsProvider`
      );
    }

    return nutritionReportSearchParams;
  };
