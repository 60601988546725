import { roundTo1DecimalPlace } from "../../../../../../utils/math-utils";
import { PatientNutritionReportDailySummaryDayDTOWithId } from "../create-patient-nutrition-report-daily-summary-day-dto-with-ids";
import { getSelectedDaysCount } from "./get-selected-days-count";

export function calculateNaAndKTotalsAndAverages(
  selectedDays: PatientNutritionReportDailySummaryDayDTOWithId[]
) {
  let naSum = 0;
  let naAvg = 0;
  let kSum = 0;
  let kAvg = 0;

  for (const day of selectedDays) {
    naSum += day.totalNaSodiumGm;
    naAvg = naSum / getSelectedDaysCount(selectedDays);
    kSum += day.totalKPotassiumGm;
    kAvg = kSum / getSelectedDaysCount(selectedDays);
  }

  return {
    naSum,
    naAvg: roundTo1DecimalPlace(naAvg),
    kSum,
    kAvg: roundTo1DecimalPlace(kAvg),
  };
}
