import { Controller, useFormContext } from "react-hook-form";
import { InputLabel } from "../../../../../../../../../styles/classes/reusable-classes";
import { CarbCounterMealAddFoodModalInputs } from "../../../carb-counter-meal-add-food-modal";
import { CarbCounterMealAddFoodModalBodyFoodResponseQuantityContainer } from "./styled-carb-counter-meal-add-food-modal-body-food-response-quantity";

export const CarbCounterMealAddFoodModalBodyFoodResponseQuantity = () => {
  const { control, watch } =
    useFormContext<CarbCounterMealAddFoodModalInputs>();

  const foodMessureWatcher = watch("foodMessure");

  return (
    <>
      {foodMessureWatcher !== undefined && (
        <Controller
          control={control}
          name="foodQuantity"
          render={({ field: { value, onChange } }) => (
            <CarbCounterMealAddFoodModalBodyFoodResponseQuantityContainer>
              <InputLabel>Food Quantity</InputLabel>
              <div>
                <button
                  type="button"
                  onClick={
                    value !== undefined
                      ? () => {
                          onChange(Number(value) - 0.5);
                        }
                      : () => {}
                  }
                >
                  -
                </button>
                <input
                  type="text"
                  placeholder="0"
                  value={value !== undefined ? value : ""}
                  onChange={(e) =>
                    onChange(e.target.value === undefined ? "" : e.target.value)
                  }
                />
                <button
                  type="button"
                  onClick={
                    value !== undefined
                      ? () => {
                          onChange(Number(value) + 0.5);
                        }
                      : () => {}
                  }
                >
                  +
                </button>
              </div>
            </CarbCounterMealAddFoodModalBodyFoodResponseQuantityContainer>
          )}
        />
      )}
    </>
  );
};
