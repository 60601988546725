import { Controller, useFieldArray, useForm } from "react-hook-form";
import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ErrorText,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import { PatientProfileWeightChart } from "../../patient-profile-weight-chart/patient-profile-weight-chart";
import { Temporal } from "temporal-polyfill";
import { useBulkUpdatePatientWeights } from "../../../../../../../hooks/patient-weights-hooks/use-bulk-update-patient-weights";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { useParams } from "react-router-dom";
import {
  TD,
  TDContainer,
  TDInput,
  TH,
} from "../../../../../../../components/table/styled-table";
import { Table } from "../../../../../../../components/table/table";
import { useMemo } from "react";
import {
  PatientProfileModalTableAndChartTableContainer,
  PatientProfileModalTableAndChartTableHeaderContainer,
  PatientProfileModalTableAndChartWrapper,
} from "../../../styles/patient-profile-weight-section-classes";
import {
  usePatientProfile,
  useReloadPatientProfile,
} from "../../../../context/loadable-patient-profile-context";

type PatientProfileWeightEditModalBodyProps = {
  onClose: () => void;
};

type WeightInputs = {
  weights: {
    date: string;
    weightKg: number;
  }[];
};

export const PatientProfileWeightEditModalBody = (
  props: PatientProfileWeightEditModalBodyProps
) => {
  const { onClose } = props;

  const { patientWeightDTOs } = usePatientProfile();

  const formatedDatesPatientWeightDTOs = useMemo(
    () =>
      patientWeightDTOs.map((patientWeightDTO) => ({
        ...patientWeightDTO,
        date: Temporal.PlainDate.from(patientWeightDTO.date).toLocaleString(
          "en-UK",
          {
            day: "2-digit",
            month: "2-digit",
          }
        ),
      })),
    [patientWeightDTOs]
  );

  const { id } = useParams();
  const { showToast } = useToastService();
  const reloadPatientProfile = useReloadPatientProfile();
  const today = Temporal.Now.plainDateISO();
  const bulkUpdatePatientWeights = useBulkUpdatePatientWeights();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<WeightInputs>({
    defaultValues: {
      weights: patientWeightDTOs,
    },
  });

  const { append, fields, remove } = useFieldArray({
    name: "weights",
    control,
  } as never);

  const onSubmit = handleSubmit(async (inputs) => {
    try {
      await bulkUpdatePatientWeights(parseInt(id!), inputs);
      showToast("Success", "Weight Readings updated successfully");
      onClose();
      reloadPatientProfile();
    } catch (error) {
      showToast("Error", "Failed to update Weight readings :(");
    }
  });

  const tableHeadings = ["Date", "Body Weight"].map((heading) => (
    <TH key={heading} modalTableHead actions={heading === "Remove"}>
      <div>{heading}</div>
    </TH>
  ));

  const tableRows = fields.map((field, index) => (
    <tr key={field.id}>
      <TD>
        <TDContainer>
          <Controller
            name={`weights.${index}.date`}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <TDInput
                type="date"
                value={value !== undefined ? value : undefined}
                onChange={(e) =>
                  onChange(
                    e.target.value !== undefined ? e.target.value : undefined
                  )
                }
              />
            )}
          />
          {errors.weights?.[index]?.date?.type === "required" && (
            <ErrorText>Required!</ErrorText>
          )}
        </TDContainer>
      </TD>
      <TD>
        <TDContainer>
          <Controller
            name={`weights.${index}.weightKg`}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <TDInput
                type="number"
                step={0.01}
                value={value !== undefined ? value : undefined}
                onChange={(e) =>
                  onChange(e.target.value !== undefined ? e.target.value : 0)
                }
              />
            )}
          />
          <strong>KG</strong>
          {errors.weights?.[index]?.weightKg?.type === "required" && (
            <ErrorText>Required!</ErrorText>
          )}
        </TDContainer>
      </TD>
      <TD>
        <TDContainer className="cursor-pointer">
          <span
            title="Delete"
            className="material-symbols-outlined"
            onClick={() => remove(index)}
          >
            delete
          </span>
        </TDContainer>
      </TD>
    </tr>
  ));

  return (
    <ModalForm
      width={900}
      height={patientWeightDTOs.length > 0 ? 740 : 520}
      onSubmit={onSubmit}
    >
      <ModalHeader
        title="Weight"
        subTitle="Weight graph with all readings"
        onModalClose={onClose}
      />
      <PatientProfileModalTableAndChartWrapper>
        {patientWeightDTOs.length > 0 ? (
          <div>
            <PatientProfileWeightChart
              formatedDatesPatientWeightDTOs={formatedDatesPatientWeightDTOs}
            />
          </div>
        ) : (
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="There are no data in Weight"
            title=""
            subText=""
          />
        )}
        <PatientProfileModalTableAndChartTableContainer>
          <PatientProfileModalTableAndChartTableHeaderContainer>
            <p>Readings</p>
            <Button
              type="button"
              flex
              onClick={() =>
                append({
                  date: today.toString(),
                  weight: 0,
                })
              }
            >
              <span className="material-symbols-outlined">add</span>
              New reading
            </Button>
          </PatientProfileModalTableAndChartTableHeaderContainer>
          <Table tableHeadings={tableHeadings} tableRows={tableRows} />
        </PatientProfileModalTableAndChartTableContainer>
      </PatientProfileModalTableAndChartWrapper>
      <ModalFooter>
        <Button type="button" outlined onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </ModalFooter>
    </ModalForm>
  );
};
