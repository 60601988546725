import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { FoodAllergyDTO } from "../../models/food-allergy-dtos/food-allergy-dto";

export function useGetFoodAllergies(): () => Promise<FoodAllergyDTO[]> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<FoodAllergyDTO[]>(
      `${BASE_PATH}/food-allergies`
    );

    return response.data;
  }, [client]);
}
