import styled from "styled-components/macro";
import { LoaderContainer } from "../../../../../../components/loaders/otida-loader/styled-otida-loader";

export const CarbCounterFoodLoggingSelectedPatientMealDetailsContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 16px;
  border-radius: 8px;
  width: 50%;
  max-height: 342px;
  overflow-y: auto;

  ${LoaderContainer} {
    width: 100%;
  }
`;
