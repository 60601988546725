import { CarbCounterV1MealSummaryDTO } from "../../../../../../../../../../models/carb-counter-dtos/carb-counter-v1-meal-summary-dto";
import { PatientMealMacrosSummaryDTO } from "../../../../../../../../../../models/patient-meal-dtos/patient-meal-macros-summary-dto";
import { roundTo1DecimalPlace } from "../../../../../../../../../../utils/math-utils";
import { MealsAnalysisResponseTableCellMacrosInfo } from "./meals-analysis-response-table-cell-macros-info/meals-analysis-response-table-cell-macros-info";
import {
  MealsAnalysisResponseTableRowsMacrosBar,
  MealsAnalysisResponseTableRowsMacrosCarbs,
  MealsAnalysisResponseTableRowsMacrosContainer,
  MealsAnalysisResponseTableRowsMacrosFats,
  MealsAnalysisResponseTableRowsMacrosLegend,
  MealsAnalysisResponseTableRowsMacrosLegendPoint,
  MealsAnalysisResponseTableRowsMacrosProtiens,
} from "./styled-meals-analysis-response-table-rows-macros";

type MealsAnalysisResponseTableRowsMacrosProps = {
  macrosMealSummary: PatientMealMacrosSummaryDTO;
  macrosCarbCounter?: CarbCounterV1MealSummaryDTO;
};

export const MealsAnalysisResponseTableRowsMacros = (
  props: MealsAnalysisResponseTableRowsMacrosProps
) => {
  const { macrosMealSummary, macrosCarbCounter } = props;

  const isCarbCounterMacrosDefined = macrosCarbCounter !== undefined;

  const carbsPercentage = isCarbCounterMacrosDefined
    ? macrosCarbCounter.carbsKcalPercentage
    : macrosMealSummary.carbsPercentage;

  const fatsPercentage = isCarbCounterMacrosDefined
    ? macrosCarbCounter.fatKcalPercentage
    : macrosMealSummary.fatPercentage;

  const proteinPercentage = isCarbCounterMacrosDefined
    ? macrosCarbCounter.fatKcalPercentage
    : macrosMealSummary.fatPercentage;

  const carbsGM = isCarbCounterMacrosDefined
    ? macrosCarbCounter.totalCarbsGm
    : macrosMealSummary.carbsGm;

  const fatsGM = isCarbCounterMacrosDefined
    ? macrosCarbCounter.totalFatGm
    : macrosMealSummary.fatGm;

  const proteinGM = isCarbCounterMacrosDefined
    ? macrosCarbCounter.totalProteinGm
    : macrosMealSummary.proteinGm;

  const kCals = isCarbCounterMacrosDefined
    ? macrosCarbCounter.totalKcal
    : macrosMealSummary.kCals;

  const macrosPercentage = {
    carbsPercentage,
    fatsPercentage,
    proteinPercentage,
  };

  return (
    <MealsAnalysisResponseTableRowsMacrosContainer>
      <MealsAnalysisResponseTableRowsMacrosBar>
        <MealsAnalysisResponseTableRowsMacrosCarbs
          $height={`${carbsPercentage}%`}
        />
        <MealsAnalysisResponseTableRowsMacrosFats
          $height={`${carbsPercentage + fatsPercentage}%`}
        />
        <MealsAnalysisResponseTableRowsMacrosProtiens
          $height={`${carbsPercentage + fatsPercentage + proteinPercentage}%`}
        />
        <MealsAnalysisResponseTableCellMacrosInfo
          macrosPercentage={macrosPercentage}
        />
      </MealsAnalysisResponseTableRowsMacrosBar>
      <MealsAnalysisResponseTableRowsMacrosLegend>
        <MealsAnalysisResponseTableRowsMacrosLegendPoint>
          <p>Carbs: {roundTo1DecimalPlace(carbsGM)} gm</p>
        </MealsAnalysisResponseTableRowsMacrosLegendPoint>
        <MealsAnalysisResponseTableRowsMacrosLegendPoint>
          <p>Fat: {roundTo1DecimalPlace(fatsGM)} gm</p>
        </MealsAnalysisResponseTableRowsMacrosLegendPoint>
        <MealsAnalysisResponseTableRowsMacrosLegendPoint>
          <p>Protien: {roundTo1DecimalPlace(proteinGM)} gm</p>
        </MealsAnalysisResponseTableRowsMacrosLegendPoint>
        <strong>{roundTo1DecimalPlace(kCals)} Kcal</strong>
      </MealsAnalysisResponseTableRowsMacrosLegend>
    </MealsAnalysisResponseTableRowsMacrosContainer>
  );
};
