import styled from "styled-components/macro";
import { flexCol } from "../../styles/classes/gloabl-classes";

export const DeleteModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: auto;

  p {
    color: ${({ theme }) => theme.colors.titleColor};
    font-size: 18px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
    margin: 0;
    ${flexCol}

    strong {
      color: ${({ theme }) => theme.colors.danger};
    }
  }

  span {
    :first-child {
      position: absolute;
      top: -40px;
      right: 5px;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.textColor};
    }

    :last-child {
      border: 1px solid ${({ theme }) => theme.colors.borderColor};
      padding: 1rem;
      border-radius: 100%;
      color: ${({ theme }) => theme.colors.danger};
    }
  }
`;

export const DeleteModalButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0rem 1rem;
  margin-top: auto;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding-top: 1rem;
`;
