import styled from "styled-components/macro";
import { flexCenterSpaceBetween } from "../../../../styles/classes/gloabl-classes";
import { LoaderContainer } from "../../../../components/loaders/otida-loader/styled-otida-loader";

export const PatientProfileInformationHeaderContainer = styled.header`
  ${flexCenterSpaceBetween}
  margin: 24px;
  padding: 24px 24px 32px;
  background: ${({ theme }) => theme.colors.containerColor};
  border-radius: 16px;

  ${LoaderContainer} {
    width: 100%;
  }

  @media screen and (max-width: 1199px) {
    flex-direction: column;
  }
`;
