import { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { useSinglePatientSidebarUI } from "../context/single-patient-sidebar-ui-context";
import {
  SinglePatientContainer,
  SinglePatientMain,
} from "./styled-single-patient-layout";
import { SinglePatientSidebar } from "../single-patient-sidebar/single-patient-sidebar";
import { SinglePatientHeader } from "../single-patient-header/single-patient-header";
import { SidebarIcon } from "../single-patient-header/sidebar-icon/sidebar-icon";
import { BglAnalysisUploadOptions } from "../bgl-analysis/upload-options/bgl-analysis-upload-options";

export const SinglePatientLayout = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const location = useLocation();

  const isPatientProfileRoute = location.pathname.includes("patient-profile");

  const { showSinglePatientSidebarUI } = useSinglePatientSidebarUI();

  const isPlansLocation =
    location.pathname.includes("plans/") &&
    !location.pathname.includes("/weekly-plans");

  const isNewPrescription = location.pathname.includes("/new-prescription");

  return (
    <SinglePatientContainer>
      <SinglePatientMain
        isPatientProfileRoute={isPatientProfileRoute}
        $showSinglePatientSidebarUI={showSinglePatientSidebarUI}
        plansLayout={isPlansLocation}
      >
        {isNewPrescription ? (
          <></>
        ) : (
          !isPlansLocation && (
            <SinglePatientHeader
              buttons={
                location.pathname.includes("/bgl-analysis") ? (
                  <BglAnalysisUploadOptions />
                ) : location.pathname.includes("/patient-profile") ? (
                  <></>
                ) : (
                  <SidebarIcon />
                )
              }
            />
          )
        )}
        {children}
      </SinglePatientMain>
      {!isPatientProfileRoute && <SinglePatientSidebar />}
    </SinglePatientContainer>
  );
};
