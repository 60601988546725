import {
  NutritionGoalsBodyContainer,
  NutritionGoalsBodyList,
  NutritionGoalsBodySubContainer,
} from "./styled-nutrition-goals-body";
import { NutritionGoalsBodyBuiltInListItem } from "./nutrition-goals-body-built-in-list-item";
import { NutritionGoalsBodyUpdateCustomListItem } from "./nutrition-goals-body-update-custom-list-item";
import { NutritionGoalsBodyCreateCustomListItem } from "./nutrition-goals-body-create-custom-list-item";
import { useNutritionGolasLastFoodGroupSelected } from "../context/nutrition-goals-last-food-group-selected-context";
import { useNutritionGoalsDropdownText } from "../context/nutrition-goals-dropdown-text-context";

export const NutritionGoalsBody = () => {
  const { dropdownText } = useNutritionGoalsDropdownText();

  const { lastFoodGroupSelected } = useNutritionGolasLastFoodGroupSelected();

  return (
    <NutritionGoalsBodyContainer>
      {dropdownText !== "Choose daily goal" && (
        <NutritionGoalsBodySubContainer>
          <NutritionGoalsBodyList>
            {dropdownText !== "Custom" &&
            lastFoodGroupSelected?.customForPatientId === undefined ? (
              <NutritionGoalsBodyBuiltInListItem />
            ) : lastFoodGroupSelected !== undefined &&
              lastFoodGroupSelected.customForPatientId !== undefined ? (
              <NutritionGoalsBodyUpdateCustomListItem />
            ) : (
              <NutritionGoalsBodyCreateCustomListItem />
            )}
          </NutritionGoalsBodyList>
        </NutritionGoalsBodySubContainer>
      )}
    </NutritionGoalsBodyContainer>
  );
};
