import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";

type NutritionReportFatTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportFatTableCell = (
  props: NutritionReportFatTableCellProps
) => {
  const { kind } = props;

  const {
    oilsAndFatsTransRow,
    oilsAndFatsSaturatedRow,
    oilsAndFatsUnsaturatedRow,
    oilsAndFatsNutsRow,
    oilsAndFatsLegacyRow,
    oilsAndFatsTransMeal,
    oilsAndFatsSaturatedMeal,
    oilsAndFatsUnsaturatedMeal,
    oilsAndFatsNutsMeal,
    oilsAndFatsLegacyMeal,
  } = useMemo(() => {
    const oilsAndFatsRow =
      kind.kind === "Row"
        ? kind.data.foodGroups.filter((item) => {
            return item.foodGroup.parentFoodGroupId === 22;
          })
        : undefined;

    const oilsAndFatsMeal =
      kind.kind === "Meal"
        ? kind.data.foodGroups.filter((item) => {
            return item.foodGroup.parentFoodGroupId === 22;
          })
        : undefined;

    const oilsAndFatsTransRow = oilsAndFatsRow?.find(
      (item) => item.foodGroup.id === 16
    );
    const oilsAndFatsSaturatedRow = oilsAndFatsRow?.find(
      (item) => item.foodGroup.id === 17
    );
    const oilsAndFatsUnsaturatedRow = oilsAndFatsRow?.find(
      (item) => item.foodGroup.id === 18
    );
    const oilsAndFatsNutsRow = oilsAndFatsRow?.find(
      (item) => item.foodGroup.id === 19
    );
    const oilsAndFatsLegacyRow = oilsAndFatsRow?.find(
      (item) => item.foodGroup.id === 12
    );

    const oilsAndFatsTransMeal = oilsAndFatsMeal?.find(
      (item) => item.foodGroup.id === 16
    );
    const oilsAndFatsSaturatedMeal = oilsAndFatsMeal?.find(
      (item) => item.foodGroup.id === 17
    );
    const oilsAndFatsUnsaturatedMeal = oilsAndFatsMeal?.find(
      (item) => item.foodGroup.id === 18
    );
    const oilsAndFatsNutsMeal = oilsAndFatsMeal?.find(
      (item) => item.foodGroup.id === 19
    );
    const oilsAndFatsLegacyMeal = oilsAndFatsMeal?.find(
      (item) => item.foodGroup.id === 12
    );

    return {
      oilsAndFatsTransRow,
      oilsAndFatsSaturatedRow,
      oilsAndFatsUnsaturatedRow,
      oilsAndFatsNutsRow,
      oilsAndFatsLegacyRow,
      oilsAndFatsTransMeal,
      oilsAndFatsSaturatedMeal,
      oilsAndFatsUnsaturatedMeal,
      oilsAndFatsNutsMeal,
      oilsAndFatsLegacyMeal,
    };
  }, [kind.kind, kind.data]);

  const rowValues = [
    oilsAndFatsTransRow?.totalServingsCount,
    oilsAndFatsSaturatedRow?.totalServingsCount,
    oilsAndFatsUnsaturatedRow?.totalServingsCount,
    oilsAndFatsNutsRow?.totalServingsCount,
    oilsAndFatsLegacyRow?.totalServingsCount,
  ];

  const mealValues = [
    oilsAndFatsTransMeal?.servingsCount,
    oilsAndFatsSaturatedMeal?.servingsCount,
    oilsAndFatsUnsaturatedMeal?.servingsCount,
    oilsAndFatsNutsMeal?.servingsCount,
    oilsAndFatsLegacyMeal?.servingsCount,
  ];

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type="Fat"
      values={kind.kind === "Row" ? rowValues : mealValues}
    />
  );
};
