import { viewEmergencies } from "../../../utils/patient-profile-utils";
import { usePatientProfileMedicalHistory } from "../../context/loadable-patient-profile-medical-history";
import {
  PatientProfileMedicalHistoryItemContainer,
  PatientProfileMedicalHistoryItemTextContainer,
  PatientProfileMedicalHistoryItemTitleContainer,
} from "../styled-profile-medical-history";

export const PatientProfileMedicalHistoryReadDiabetesEmergencies = () => {
  const { patientMedicalHistoryDTO } = usePatientProfileMedicalHistory();

  const diabetesEmergencies = patientMedicalHistoryDTO.diabetesEmergencies;

  return (
    <PatientProfileMedicalHistoryItemContainer>
      <PatientProfileMedicalHistoryItemTitleContainer>
        <img src="/img/patient-profile/medical-history/diabetes.svg" alt="" />
        <p>Diabetes Emergencies</p>
      </PatientProfileMedicalHistoryItemTitleContainer>
      <PatientProfileMedicalHistoryItemTextContainer>
        <p>
          {diabetesEmergencies !== undefined && diabetesEmergencies.length > 0
            ? diabetesEmergencies.map(
                (item, index) =>
                  `${viewEmergencies(item.emergency)}${
                    diabetesEmergencies.length > 1 &&
                    diabetesEmergencies[index + 1]
                      ? ", "
                      : ""
                  }`
              )
            : "--"}
        </p>
      </PatientProfileMedicalHistoryItemTextContainer>
    </PatientProfileMedicalHistoryItemContainer>
  );
};
