import styled from "styled-components/macro";
import { flexCenter } from "../../../../../../styles/classes/gloabl-classes";

export const ImmediateAttentionPatientsTableSelectedFiltersContainer = styled.div``;

export const ImmediateAttentionPatientsTableSelectedFiltersList = styled.ul`
  ${flexCenter}
  gap: 0px 8px;
`;

export const ImmediateAttentionPatientsTableSelectedFiltersLi = styled.li`
  background: ${({ theme }) => theme.newColors.gray6};
  border-radius: 94px;
  padding: 6px 16px;
  gap: 8px;
  width: max-content;
  height: fit-content;
  ${flexCenter}

  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    color: #14212e;
  }

  .material-symbols-outlined {
    font-size: 14px;
    font-weight: 900;
    cursor: pointer;
  }
`;
