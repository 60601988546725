import { ContentModuleType } from "../../../../../models/content-module-dtos/content-module-type";
import {
  UXInputContainer,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../styles/classes/reusable-classes";

type ReadContentModuleObjectiveControllerProps = {
  contnetModuleType: ContentModuleType;
  contentModuleObjective?: string;
};

export const ReadContentModuleObjectiveController = (
  props: ReadContentModuleObjectiveControllerProps
) => {
  const { contnetModuleType, contentModuleObjective } = props;

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px" $isOptional>
        {contnetModuleType} Objective <span>(Optional)</span>
      </UXInputLabel>
      <UXInputWrapper $width="80%" $textareaDimentions $readOnly>
        <h6 dir="auto">
          {contentModuleObjective
            ? contentModuleObjective
            : `No objective for this ${contnetModuleType}`}
        </h6>
      </UXInputWrapper>
    </UXInputContainer>
  );
};
