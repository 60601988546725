import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PutPatientMedicalHistoryDTO } from "../../models/patient-medical-history-dtos/put-patient-medical-history-dto";
import { PatientDTO } from "../../models/patient-dtos/patient-dto";

export function usePutPatientMedicalHistory(): (
  patientDTO: PatientDTO,
  putPatientMedicalHistoryDTO: PutPatientMedicalHistoryDTO
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientDTO, putPatientMedicalHistoryDTO) => {
      await client.put<void>(
        `${PATIENTS_PATH}/${patientDTO.id}/medical-history`,
        putPatientMedicalHistoryDTO
      );
    },
    [client]
  );
}
