import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  PropsWithChildren,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Temporal } from "temporal-polyfill";

type CGMReportSelectedDates = {
  temporalStartDate: string;
  temporalEndDate: string;
  count: number;
};

type DateRangePickerRef = RefObject<DateRangePickerComponent>;

type DateRange = {
  startDate: Date;
  endDate: Date;
  temporalStartDate: string;
  temporalEndDate: string;
  onDatesChange: (e: any) => void;
  handleIncrementDates: () => void;
  handleDecrementDates: () => void;
  selectedDates: CGMReportSelectedDates;
  dateRangePickerRef: DateRangePickerRef;
  onDateRangePickerClick: () => void | undefined;
};

const DateRangeContext = createContext<DateRange | undefined>(undefined);

type DateRangeProviderProps = PropsWithChildren<{
  startFromHowManyDaysAgo?: Date;
}>;

export function DateRangeProvider(props: DateRangeProviderProps) {
  const { children, startFromHowManyDaysAgo } = props;

  const dateRangePickerRef: DateRangePickerRef = useRef<
    DateRangePickerComponent
  >(null);

  const onDateRangePickerClick = useCallback(
    () => dateRangePickerRef.current?.show(),
    []
  );

  let currentDate = new Date();
  let twoWeeksAgo = new Date(currentDate.getTime() - 13 * 24 * 60 * 60 * 1000);

  const [startDate, setStartDate] = useState(
    startFromHowManyDaysAgo ? startFromHowManyDaysAgo : twoWeeksAgo
  );
  const [endDate, setEndDate] = useState(currentDate);

  const temporalStartDate = Temporal.PlainDate.from(
    startDate.toISOString().split("T")[0]
  ).toString();

  const temporalEndDate = Temporal.PlainDate.from(
    endDate.toISOString().split("T")[0]
  ).toString();

  const generateDateRange = useCallback((startDate: Date, endDate: Date) => {
    const dateRange: Date[] = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return {
      dates: dateRange,
      numberOfDays: dateRange.length - 1,
    };
  }, []);

  const onDatesChange = useCallback(
    (e: any) => {
      if (e.target.value !== null) {
        setStartDate(e.target.value[0]);
        setEndDate(e.target.value[1]);
      }
    },
    [setStartDate, setEndDate]
  );

  const handleIncrementDates = useCallback(() => {
    const newStartDate = new Date(endDate);
    newStartDate.setDate(newStartDate.getDate() + 1);
    setStartDate(newStartDate);

    const newEndDate = new Date(newStartDate);
    newEndDate.setDate(
      newEndDate.getDate() + generateDateRange(startDate, endDate).numberOfDays
    );
    setEndDate(newEndDate);
  }, [setStartDate, setEndDate, endDate, generateDateRange, startDate]);

  const handleDecrementDates = useCallback(() => {
    const newEndDate = new Date(startDate);
    newEndDate.setDate(newEndDate.getDate() - 1);
    setEndDate(newEndDate);

    const newStartDate = new Date(newEndDate);
    newStartDate.setDate(
      newStartDate.getDate() -
        generateDateRange(startDate, endDate).numberOfDays
    );
    setStartDate(newStartDate);
  }, [setStartDate, setEndDate, endDate, generateDateRange, startDate]);

  const selectedDates = useMemo(
    () => ({
      temporalStartDate,
      temporalEndDate,
      count: generateDateRange(startDate, endDate).numberOfDays + 1,
    }),
    [temporalStartDate, temporalEndDate, generateDateRange, startDate, endDate]
  );

  const value = useMemo(
    () => ({
      startDate,
      endDate,
      temporalStartDate,
      temporalEndDate,
      onDatesChange,
      handleIncrementDates,
      handleDecrementDates,
      selectedDates,
      dateRangePickerRef,
      onDateRangePickerClick,
    }),
    [
      startDate,
      endDate,
      temporalStartDate,
      temporalEndDate,
      onDatesChange,
      handleIncrementDates,
      handleDecrementDates,
      selectedDates,
      dateRangePickerRef,
      onDateRangePickerClick,
    ]
  );

  return (
    <DateRangeContext.Provider value={value}>
      {children}
    </DateRangeContext.Provider>
  );
}

export function useDateRange(): DateRange {
  const DateRange = useContext(DateRangeContext);

  if (DateRange === undefined) {
    throw new Error(`useDateRange must be used within DateRangeProvider`);
  }

  return DateRange;
}
