import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { AddNutritionModal } from "./add-nutrition-modal/add-nutrition-modal";

export const EmptyNutrition = () => {
  return (
    <EmptyState
      hasHeader={false}
      hasSearch={false}
      text="Add a Nutrition Goal"
      subText="Assign Nutrition goals to your patient"
      button={<AddNutritionModal />}
      title=""
    />
  );
};
