import { Temporal } from "temporal-polyfill";
import { GenericActionsList } from "../../../../components/generic-actions-list/generic-actions-list";
import { PatientMedicationDTO } from "../../../../models/patient-medication-dto";
import { AddNoteModal } from "../add-note-modal/add-note-modal";
import { MedicationCard } from "../components/medication-card/medication-card";
import { StopMedicineModal } from "../stop-medicine-modal/stop-medicine-modal";
import {
  generateSig,
  sortedPatientMedicationDTOs,
} from "../utils/medications-utils";
import {
  ActionsContainer,
  AssignedContainer,
  DateContainer,
  IconContainer,
  MedicationContainer,
  MedicationTD,
  NoteContainer,
  SigContainer,
  StatusContainer,
} from "./styled-medications-table";
import { DeleteMedicine } from "../delete-medicine/delete-medicine";
import { useDeletePatientMedication } from "../../../../hooks/patient-medication-hooks/use-delete-patient-medication";
import { useToastService } from "../../../../context/toast-service-context";
import { EditMedication } from "../components/edit-medication/edit-medication";
import { useReloadMedications } from "../context/loadable-medications-context";

type MedicationsTableRowsProps = {
  patientMedicationData: PatientMedicationDTO[];
};

export const MedicationsTableRows = (props: MedicationsTableRowsProps) => {
  const { patientMedicationData } = props;

  const deletePatientMedication = useDeletePatientMedication();
  const { showToast } = useToastService();
  const reloadMedications = useReloadMedications();

  const onDelete = async (patientMedicationDTO: PatientMedicationDTO) => {
    try {
      await deletePatientMedication(patientMedicationDTO);
      showToast("Success", "Medicine deleted successfully!");
      reloadMedications();
    } catch (error) {
      showToast("Error", "Failed to delete medicine :(");
    }
  };

  const rows = sortedPatientMedicationDTOs(patientMedicationData).map(
    (row, index) => {
      const today = Temporal.Now.plainDateISO();

      const startDatePlusDuration =
        row.startDate !== undefined && row.durationDays !== undefined
          ? Temporal.PlainDate.from(row.startDate).add({
              days: row.durationDays,
            })
          : undefined;

      const isFinished =
        startDatePlusDuration !== undefined
          ? Temporal.PlainDate.compare(today, startDatePlusDuration)
          : undefined;

      const isNotStartedYet =
        row.startDate !== undefined
          ? Temporal.PlainDate.compare(row.startDate, today)
          : undefined;

      return (
        <tr key={index}>
          <MedicationTD>
            <MedicationContainer>
              <MedicationCard patientMedicationDTO={row} />
            </MedicationContainer>
          </MedicationTD>
          <MedicationTD>
            <IconContainer>-</IconContainer>
          </MedicationTD>
          <MedicationTD>
            <StatusContainer
              stopped={
                row.discontinueDate !== undefined ||
                (isFinished !== undefined &&
                  isFinished === 1 &&
                  startDatePlusDuration !== undefined)
              }
              notStartedYet={
                isNotStartedYet !== undefined && isNotStartedYet === 1
                  ? true
                  : false
              }
            >
              <p>
                {row.discontinueDate !== undefined
                  ? "Stopped"
                  : isFinished !== undefined &&
                    isFinished === 1 &&
                    startDatePlusDuration !== undefined
                  ? "Finished"
                  : isNotStartedYet !== undefined && isNotStartedYet === 1
                  ? "Not started yet"
                  : "Current"}
              </p>
              <span>
                {row.discontinueDate !== undefined && isFinished !== 1
                  ? row.discontinueDate
                  : ""}
                <span>
                  {isFinished !== undefined &&
                  isFinished === 1 &&
                  startDatePlusDuration !== undefined
                    ? startDatePlusDuration.toString()
                    : ""}
                </span>
              </span>
            </StatusContainer>
          </MedicationTD>
          <MedicationTD>
            <SigContainer>
              <p>
                {`${generateSig(row).quantity} ${generateSig(row).frequency} ${
                  row.durationDays !== undefined
                    ? generateSig(row).duration
                    : ""
                } ${generateSig(row).notes}`}
              </p>
            </SigContainer>
          </MedicationTD>
          <MedicationTD>
            <DateContainer>
              <p>{row.startDate !== undefined ? row.startDate : ""}</p>
            </DateContainer>
          </MedicationTD>
          <MedicationTD>
            <AssignedContainer
              byOtida={
                row.patientPrescriptionId !== undefined ||
                row.isAssignedByOtida === true
              }
            >
              <img src="/img/logo-2.svg" alt="Otida" />
              <p>{`${
                row.patientPrescriptionId !== undefined ||
                row.isAssignedByOtida === true
                  ? "Otida"
                  : "Patient"
              }`}</p>
            </AssignedContainer>
          </MedicationTD>
          <MedicationTD>
            <NoteContainer>
              <p>{row.note !== undefined ? row.note : ""}</p>
            </NoteContainer>
          </MedicationTD>
          <MedicationTD>
            <ActionsContainer>
              <GenericActionsList>
                <StopMedicineModal row={row} />
                <AddNoteModal row={row} />
                <DeleteMedicine
                  title={row.medication.name}
                  onDelete={() => onDelete(row)}
                />
                {row.isFromPatientOnboarding ? (
                  <EditMedication row={row} />
                ) : (
                  <></>
                )}
              </GenericActionsList>
            </ActionsContainer>
          </MedicationTD>
        </tr>
      );
    }
  );

  return <>{rows}</>;
};
