import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  regularMediumFont,
} from "../../../../../../styles/classes/gloabl-classes";
import { Button } from "../../../../../../styles/classes/reusable-classes";

export const NutrtionReportSummaryNotesContainer = styled.div`
  display: grid;
  gap: 12px 0px;
`;

export const NutrtionReportSummaryNotesListContainer = styled.div`
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  height: 237px;
`;

export const NutrtionReportSummaryNotesListHeader = styled.div<{
  habbits: "Healthy" | "Unhealthy";
}>`
  ${flexCenter}
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 12px 16px;
  gap: 0px 16px;

  img {
    width: 25px;
    height: 25px;
  }

  p {
    ${regularMediumFont}
    margin: 0px;
    color: ${({ theme, habbits }) =>
      habbits === "Healthy"
        ? theme.newColors.successAlt2
        : theme.colors.warning};
  }
`;

export const NutrtionReportSummaryNotesList = styled.ul<{
  isFieldsEmpty: boolean;
  viewReportUI?: boolean;
}>`
  padding: 16px 24px;
  margin: 0px;
  height: ${({ isFieldsEmpty }) => (isFieldsEmpty ? "0px" : "185px")};
  display: grid;
  grid-template-columns: ${({ viewReportUI }) =>
    viewReportUI ? "1fr" : "repeat(2, 1fr)"};
  gap: 8px 24px;
  overflow-y: auto;
  align-items: flex-start;
  align-content: flex-start;
  width: ${({ viewReportUI }) => (viewReportUI ? "400px" : "auto")};
  list-style-type: disc;
  margin-left: ${({ viewReportUI }) => (viewReportUI ? "16px" : "0px")};

  p {
    word-break: break-all;
  }
`;

export const NutrtionReportSummaryNotesListItem = styled.li<{
  viewReportUI?: boolean;
}>`
  color: ${({ theme }) => theme.newColors.textColorAlt};
  ${flexCenter}
  display: ${({ viewReportUI }) => (viewReportUI ? "list-item" : "flex")};

  p {
    ${regularMediumFont}
    margin: 0px;
  }
`;

export const NutrtionReportSummaryNotesListItemDeleteIcon = styled.div`
  img {
    margin-right: 8px;
    cursor: pointer;
    transition: scale 0.3s ease;

    :hover {
      scale: 1.2;
    }
  }
`;

export const NutrtionReportSummaryNotesListItemInputForm = styled.form`
  ${flexCenterSpaceBetween}
  width: 100%;

  ${Button} {
    padding: 0px 16px;
  }

  input {
    ${regularMediumFont}
    color: ${({ theme }) => theme.newColors.textColorAlt};
    background: ${({ theme }) => theme.newColors.inputColorAlt};
    border: 0px;
    padding: 12px 16px 10px;
    outline: 0px;
    border-radius: 8px;
    width: 100%;
  }
`;
