import { MarkerSettingsModel } from "@syncfusion/ej2-react-charts";

export const InsulinMarker: MarkerSettingsModel = {
  visible: true,
  border: { width: 0 },
  shape: "Image",
  imageUrl: "/img/insulin.svg",
  width: 12,
  height: 12,
};
