import { ContentModuleCardPartDTO } from "../../../../../../../../models/content-module-dtos/content-module-card-part-dto";
import { ImageUploadHelpersProvider } from "../../../../../../components/image-upload/context/image-upload-helpers-context";
import { VideoUploadHelpersProvider } from "../../../../../../components/video-upload/context/video-upload-helpers-context";
import {
  LessonCardPartsList,
  LessonCardPartsListItem,
} from "../../../../../../styles/content-module-lesson-classes";
import { LessonReadModeCardPartImage } from "./lesson-read-mode-card-part-image";
import { LessonReadModeCardPartVideo } from "./lesson-read-mode-card-part-video";
import { LessonReadModeCardTextPart } from "./lesson-read-mode-card-text-part";

type LessonReadModeCardPartsProps = {
  cardParts: ContentModuleCardPartDTO[];
};

export const LessonReadModeCardParts = (
  props: LessonReadModeCardPartsProps
) => {
  const { cardParts } = props;

  return (
    <div className="card-parts-list">
      <LessonCardPartsList>
        {cardParts.map((cardPart) => (
          <LessonCardPartsListItem key={cardPart.id}>
            {cardPart.contentModuleCardPartType === "Text" && (
              <LessonReadModeCardTextPart cardPartText={cardPart.text} />
            )}
            {cardPart.contentModuleCardPartType === "Image" && (
              <ImageUploadHelpersProvider>
                <LessonReadModeCardPartImage
                  thumbnailUploadedFile={cardPart.imageUploadedFile}
                />
              </ImageUploadHelpersProvider>
            )}
            {cardPart.contentModuleCardPartType === "Video" && (
              <VideoUploadHelpersProvider>
                <LessonReadModeCardPartVideo
                  thumbnailUploadedFile={cardPart.videoUploadedFile}
                />
              </VideoUploadHelpersProvider>
            )}
          </LessonCardPartsListItem>
        ))}
      </LessonCardPartsList>
    </div>
  );
};
