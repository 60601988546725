import { MedicationDTO } from "../../../../../../../../models/medications/medication-dto";
import {
  MedicationCardIconContainer,
  MedicationCardText,
  MedicationCardTitle,
} from "../../../../../components/medication-card/styled-medication-card";
import { PrescriptionSidebarCardContainer } from "./styled-new-prescription-sidebar-card";

type NewPrescriptionSidebarCardProps = {
  card: MedicationDTO;
  handleActiveState: () => void;
  activeState: boolean;
};

export const NewPrescriptionSidebarCard = (
  props: NewPrescriptionSidebarCardProps
) => {
  const { card, handleActiveState, activeState } = props;

  return (
    <PrescriptionSidebarCardContainer
      onClick={handleActiveState}
      activeState={activeState}
    >
      <MedicationCardIconContainer className="material-symbols-outlined">
        medication
      </MedicationCardIconContainer>
      <div>
        <MedicationCardTitle>{card.name}</MedicationCardTitle>
        <MedicationCardText>
          {`${
            card.strength === undefined || card.strength === ""
              ? ""
              : `${card.strength}, `
          }`}
          {`${
            card.routeOfAdministration === undefined
              ? ""
              : ` ${card.routeOfAdministration},`
          }`}
          {card.doseUnit !== undefined ? card.doseUnit : ""}
        </MedicationCardText>
      </div>
    </PrescriptionSidebarCardContainer>
  );
};
