import styled from "styled-components/macro";

export const NutritionAnalysisMacrosChartContainer = styled.div`
  width: 50%;
`;

export const NutritionAnalysisMacrosChartWrapper = styled.div`
  height: 400px;
  width: 100%;
  margin: 0 auto;
`;
