import { GroupedLabTestResultsByType } from "../../lab-result-test";
import {
  LabResultTestTypesContainer,
  LabResultTestTypesHeader,
  LabResultTestTypesList,
  LabResultTestTypesListItem,
} from "./styled-lab-result-test-types";

type LabResultTestTypesProps = {
  groupedLabTestResultsByType: GroupedLabTestResultsByType;
};

export const LabResultTestTypes = (props: LabResultTestTypesProps) => {
  const { groupedLabTestResultsByType } = props;

  const typesAndUnits = groupedLabTestResultsByType.map((labTest) => (
    <LabResultTestTypesListItem key={labTest.labTestTypeDTO.name}>
      <p>{labTest.labTestTypeDTO.name}</p>
      <span>
        {labTest.labTestTypeDTO.unit === "-"
          ? ""
          : ` ${labTest.labTestTypeDTO.unit}`}
      </span>
    </LabResultTestTypesListItem>
  ));

  return (
    <LabResultTestTypesContainer>
      <LabResultTestTypesHeader>
        <p>Type</p>
        <span>Unit</span>
      </LabResultTestTypesHeader>
      <LabResultTestTypesList>{typesAndUnits}</LabResultTestTypesList>
    </LabResultTestTypesContainer>
  );
};
