import { useFormContext } from "react-hook-form";
import { Button } from "../../../../../styles/classes/reusable-classes";
import {
  DietitianReportTabFooterButtons,
  DietitianReportTabFooterContainer,
} from "../../components/reusable-styles";
import { useToastService } from "../../../../../context/toast-service-context";
import { usePutActiveNutritionRegimen } from "../../../../../hooks/patient-nutrition-regimen-hooks/use-put-active-nutrition-regimen";
import { PutActiveNutritionRegimenDTO } from "../../../../../models/patient-nutrition-regimen-dtos/put-active-nutrition-regimen-dto";
import { useAddNutritionRegimen } from "../../../../../hooks/patient-nutrition-regimen-hooks/use-add-nutrition-regimen";
import { CreateNutritionRegimenDTO } from "../../../../../models/patient-nutrition-regimen-dtos/create-nutrition-regimen-dto";
import { usePutNutritionRegimen } from "../../../../../hooks/patient-nutrition-regimen-hooks/use-put-nutrition-regimen";
import { PutNutritionRegimenDTO } from "../../../../../models/patient-nutrition-regimen-dtos/put-nutrition-regimen-dto";
import { useNutritionGolasLastFoodGroupSelected } from "../context/nutrition-goals-last-food-group-selected-context";
import { useNutritionGoalsDropdownText } from "../context/nutrition-goals-dropdown-text-context";
import {
  NutritionGoalsFormState,
  NutritionGoalsInput,
} from "../nutrition-loading";
import {
  useNutritionGoals,
  useReloadNutritionGoals,
} from "../context/loadable-nutrition-goals-context";

export const NutritionGoalsFooter = () => {
  const { patientDTO } = useNutritionGoals();

  const { lastFoodGroupSelected } = useNutritionGolasLastFoodGroupSelected();
  const { dropdownText } = useNutritionGoalsDropdownText();

  const formState: NutritionGoalsFormState = dropdownText.startsWith("Choose")
    ? "Disabled"
    : dropdownText === "Custom"
    ? "Create Custom"
    : lastFoodGroupSelected !== undefined &&
      lastFoodGroupSelected.customForPatientId !== undefined
    ? "Update Custom"
    : "Update";

  const { handleSubmit, reset } = useFormContext<NutritionGoalsInput>();

  const putActiveNutritionRegimen = usePutActiveNutritionRegimen();
  const addNutritionRegimen = useAddNutritionRegimen();
  const putNutritionRegimen = usePutNutritionRegimen();

  const { showToast } = useToastService();
  const reloadNutritionGoals = useReloadNutritionGoals();

  const onSubmit = handleSubmit(
    async ({
      nutritionRegimenId,
      nutritionRegimenFoodGroups,
      updateNutritionRegimenFoodGroups,
    }) => {
      try {
        if (formState === "Create Custom") {
          const createNutritionRegimenDTO: CreateNutritionRegimenDTO = {
            nutritionRegimenFoodGroups,
          };

          await addNutritionRegimen(patientDTO, createNutritionRegimenDTO);
        } else if (
          formState === "Update Custom" &&
          lastFoodGroupSelected !== undefined
        ) {
          const updateNutritionRegimenFoodGroupsDTO: PutNutritionRegimenDTO = {
            nutritionRegimenFoodGroups: updateNutritionRegimenFoodGroups,
          };

          await putNutritionRegimen(
            lastFoodGroupSelected,
            updateNutritionRegimenFoodGroupsDTO
          );
        } else {
          // The reason for this (nutritionRegimenId!), I'm disabling the submit button if...
          // there is no nutritionRegimenId selected
          const putActiveNutritionRegimenDTO: PutActiveNutritionRegimenDTO = {
            nutritionRegimenId: nutritionRegimenId!,
          };

          await putActiveNutritionRegimen(
            patientDTO,
            putActiveNutritionRegimenDTO
          );
        }
        showToast(
          "Success",
          `${
            formState === "Create Custom"
              ? "Customized Nutrition goal Created successfully!"
              : formState === "Update Custom"
              ? "Customized Nutrition goal Updated successfully!"
              : "Nutrition goal updated successfully!"
          }`
        );
        reloadNutritionGoals();
      } catch (error) {
        showToast("Error", "Failed to update Nutrition goal");
      }
    }
  );

  return (
    <DietitianReportTabFooterContainer>
      <DietitianReportTabFooterButtons>
        <Button outlined type="button" onClick={() => reset()}>
          Discard Changes
        </Button>
        <Button
          type="button"
          disabled={formState === "Disabled"}
          onClick={onSubmit}
        >
          Update Goals
        </Button>
      </DietitianReportTabFooterButtons>
    </DietitianReportTabFooterContainer>
  );
};
