import { Temporal } from "temporal-polyfill";
import { FilteredPatientDataByDate } from "../../../hooks/use-filter-patient-cgm-entries/use-filter-patient-cgm-entries";
import { IsInRange } from "../bgm-chart";
import { BgmChartTooltipProps } from "../bgm-chart-tooltip/bgm-chart-tooltip";

type InRangeDataSource = {
  x: Date;
  y: number;
  dataSource: FilteredPatientDataByDate[];
  tooltip: BgmChartTooltipProps;
};

export function buildInRangeSeries(isInRange: IsInRange[]) {
  const inRangeSeries: InRangeDataSource[] = [];
  const dataSource: FilteredPatientDataByDate[] = [];

  for (const isInRangeItem of isInRange) {
    if (isInRangeItem.isInrange === true) {
      const tooltip: BgmChartTooltipProps = {
        x: `${isInRangeItem.item.date} ${Temporal.PlainTime.from(
          isInRangeItem.item.time
        ).toLocaleString("en-UK", {
          hour: "2-digit",
          minute: "2-digit",
          second: undefined,
          hour12: true,
        })}`,
        y: isInRangeItem.item.glucoseMGPerDL,
        kind: {
          kind: "Reading",
          data: {
            ...isInRangeItem.item,
            isInRange: isInRangeItem.isInrange,
          },
        },
      };

      dataSource.push(isInRangeItem.item);

      inRangeSeries.push({
        x: isInRangeItem.item.dateTime,
        y: isInRangeItem.item.glucoseMGPerDL,
        dataSource,
        tooltip,
      });
    }
  }

  return inRangeSeries;
}
