import {
  AxisModel,
  ChartComponent,
  ColumnSeries,
  DataLabel,
  DateTimeCategory,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  TooltipSettingsModel,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import {
  PaitnetProfileChartContainer,
  PatientProfileContainerBody,
} from "../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileLifeActivitiesHeader } from "./patient-profile-life-activities-header/patient-profile-life-activities-header";
import { useCallback, useMemo } from "react";
import { PatientActivityLogStatsDTO } from "../../../../../models/patient-activity-log-dtos/patient-activity-log-stats-dto";
import { usePatientProfileLifeActivities } from "./context/patient-profile-life-activities-context";
import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { buildPatientProfileLifeActivitiesTooltip } from "./patient-profile-life-activities-tooltip/build-patient-profile-life-activities-tooltip";
import { buildAerobicSeries } from "./series/build-aerobic-series";
import { buildMixedSeries } from "./series/build-mixed-series";
import { buildAnaerobicSeries } from "./series/build-anaerobic-series";
import { buildOtherSeries } from "./series/build-other-series";
import { dateRanges } from "./utils/patient-profile-life-activities-utils";
import { randomUUID } from "../../../../../utils/random-uuid";

type PatientProfileLifeActivitiesprops = {
  patientActivityLogStatsDTO: PatientActivityLogStatsDTO;
  chartID: string;
};

export const PatientProfileLifeActivities = (
  props: PatientProfileLifeActivitiesprops
) => {
  const { patientActivityLogStatsDTO, chartID } = props;

  const { startDate, endDate } = usePatientProfileLifeActivities();

  const aerobicActivity = useMemo(
    () => buildAerobicSeries(patientActivityLogStatsDTO),
    [patientActivityLogStatsDTO]
  );

  const anaerobicActivity = useMemo(
    () => buildAnaerobicSeries(patientActivityLogStatsDTO),
    [patientActivityLogStatsDTO]
  );

  const mixedActivity = useMemo(
    () => buildMixedSeries(patientActivityLogStatsDTO),
    [patientActivityLogStatsDTO]
  );

  const otherActivity = useMemo(
    () => buildOtherSeries(patientActivityLogStatsDTO),
    [patientActivityLogStatsDTO]
  );

  const isPatientActivitiesEmpty =
    aerobicActivity?.length === 0 &&
    anaerobicActivity?.length === 0 &&
    mixedActivity?.length === 0 &&
    otherActivity?.length === 0;

  const primaryxAxis: AxisModel = {
    valueType: "DateTimeCategory",
    labelFormat: "dd/MM",
    edgeLabelPlacement: "Shift",
    desiredIntervals: 7,
    interval: 7,
  };

  const primaryyAxis: AxisModel = {
    minimum: 10,
  };

  const marker = {
    visible: true,
    width: 10,
    height: 10,
    isFilled: true,
    fill: "transparent",
    border: { width: 0, color: "transparent" },
    dataLabel: { visible: true },
  };

  const tooltip: TooltipSettingsModel = useMemo(
    () => buildPatientProfileLifeActivitiesTooltip(true),
    []
  );

  const id = useMemo(
    () => `${chartID}-life-activities-chart-${randomUUID()}`,
    [chartID]
  );

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);
    // Should always enter this condition, but let's be safe
    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  return (
    <>
      <PatientProfileLifeActivitiesHeader
        patientActivityLogStatsDTO={patientActivityLogStatsDTO}
      />
      <PatientProfileContainerBody>
        {isPatientActivitiesEmpty ? (
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            title=""
            text="There are no data in Life activity"
          />
        ) : (
          <PaitnetProfileChartContainer>
            <ChartComponent
              id={id}
              primaryXAxis={primaryxAxis}
              primaryYAxis={primaryyAxis}
              title=""
              height="100%"
              width="100%"
              loaded={handleChartLoaded}
              tooltip={tooltip}
            >
              <Inject
                services={[DataLabel, ColumnSeries, DateTimeCategory, Tooltip]}
              />
              <SeriesCollectionDirective>
                <SeriesDirective
                  dataSource={dateRanges(startDate, endDate)}
                  xName="date"
                  yName=""
                  type="Column"
                  fill="transparent"
                  width={1}
                  marker={marker}
                  cornerRadius={{
                    topLeft: 8,
                    topRight: 8,
                  }}
                  enableTooltip
                  tooltipMappingName="tooltip"
                ></SeriesDirective>
                <SeriesDirective
                  dataSource={
                    aerobicActivity !== undefined ? aerobicActivity : []
                  }
                  xName="x"
                  yName="y"
                  type="Column"
                  fill="#1097CF"
                  width={1}
                  marker={marker}
                  cornerRadius={{
                    topLeft: 8,
                    topRight: 8,
                  }}
                  enableTooltip
                  tooltipMappingName="tooltip"
                ></SeriesDirective>
                <SeriesDirective
                  dataSource={
                    anaerobicActivity !== undefined ? anaerobicActivity : []
                  }
                  xName="x"
                  yName="y"
                  type="Column"
                  fill="#3E66FB"
                  width={1}
                  marker={marker}
                  cornerRadius={{
                    topLeft: 8,
                    topRight: 8,
                  }}
                  enableTooltip
                  tooltipMappingName="tooltip"
                ></SeriesDirective>
                <SeriesDirective
                  dataSource={mixedActivity !== undefined ? mixedActivity : []}
                  xName="x"
                  yName="y"
                  type="Column"
                  fill="#00086B"
                  width={1}
                  marker={marker}
                  cornerRadius={{
                    topLeft: 8,
                    topRight: 8,
                  }}
                  enableTooltip
                  tooltipMappingName="tooltip"
                ></SeriesDirective>
                <SeriesDirective
                  dataSource={otherActivity !== undefined ? otherActivity : []}
                  xName="x"
                  yName="y"
                  type="Column"
                  fill="#4682B4"
                  width={1}
                  marker={marker}
                  cornerRadius={{
                    topLeft: 8,
                    topRight: 8,
                  }}
                  enableTooltip
                  tooltipMappingName="tooltip"
                ></SeriesDirective>
              </SeriesCollectionDirective>
            </ChartComponent>
          </PaitnetProfileChartContainer>
        )}
      </PatientProfileContainerBody>
    </>
  );
};
