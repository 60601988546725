import { DateRangeProvider } from "../../../../components/date-range/context/date-range-context";
import { ManualReadingsBody } from "./manual-readings-body";

export const ManualReadings = () => {
  let oneWeekAgo = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);

  return (
    <DateRangeProvider startFromHowManyDaysAgo={oneWeekAgo}>
      <ManualReadingsBody />
    </DateRangeProvider>
  );
};
