import { useCallback, useEffect, useState } from "react";
import { MessageResponse } from "stream-chat";
import { Message, useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { MediaSidebarMediaListContainer } from "./media-sidebar-media-list/styled-media-sidebar-media-list";
import { EmptyState } from "../../../../../../../../components/empty-state/empty-state";
import { randomUUID } from "../../../../../../../../utils/random-uuid";

export const MediaSidebarLinksList = () => {
  const { client, channel } = useChatContext();

  const [messages, setMessages] = useState<
    MessageResponse<DefaultStreamChatGenerics>[]
  >([]);

  useEffect(() => {
    const getMessages = async () => {
      const channelState = await client
        .channel("messaging", channel?.id)
        .query({});

      setMessages(channelState.messages);
    };

    getMessages();
  }, [channel?.id, client]);

  const getLinks = useCallback(() => {
    const result: MessageResponse<DefaultStreamChatGenerics>[] = [];

    for (const message of messages) {
      const attachments = message.attachments;

      if (message.text?.includes("http")) {
        result.push(message);
      } else if (attachments) {
        for (const attachment of attachments) {
          if (attachment.title_link !== undefined) {
            result.push(message);
          }
        }
      }
    }

    return result;
  }, [messages]);

  if (getLinks().length === 0) {
    return (
      <EmptyState
        hasSearch={false}
        text="No Links Yet"
        title=""
        hasHeader={false}
        button={<></>}
      />
    );
  }

  return (
    <MediaSidebarMediaListContainer key={randomUUID()}>
      {getLinks()
        .sort(() => -1)
        .map((message) => {
          return (
            <Message
              key={message.id}
              message={message}
              renderText={(text) => (
                <a
                  href={text}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "inherit" }}
                >
                  {text}
                </a>
              )}
              formatDate={(date) =>
                date.toLocaleTimeString("en-AU", {
                  hour12: true,
                  second: undefined,
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }
              messageActions={[]}
            />
          );
        })}
    </MediaSidebarMediaListContainer>
  );
};
