import styled from "styled-components/macro";
import { flexCenterCenter } from "../../../../../../styles/classes/gloabl-classes";

export const NutritionReportFeedbackTableCellTD = styled.td`
  background: ${({ theme }) => theme.colors.bodyColor};
`;

export const NutritionReportFeedbackTableCellContainer = styled.div`
  ${flexCenterCenter}
  gap: 16px;
  padding: 8px 16px;
  min-width: 250px;
`;

export const NutritionReportFeedbackTableCellIconContainer = styled.div`
  cursor: pointer;
  margin-left: auto;

  img {
    width: 20px;
    height: 20px;
  }
`;
