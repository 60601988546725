import { DateRange } from "../../../../../components/date-range/date-range";
import { BGLAnalysisSubHeaderContainer } from "../../styled-bgl-analysis";
import { ManualReadingsTagsFilter } from "./manual-readings-tags-filter/manual-readings-tags-filter";

type ManualReadingsFiltersProps = {
  tagValue: string;
  setTagValue: (value: string) => void;
};

export const ManualReadingsFilters = (props: ManualReadingsFiltersProps) => {
  const { tagValue, setTagValue } = props;

  return (
    <BGLAnalysisSubHeaderContainer>
      <DateRange />
      <ManualReadingsTagsFilter tagValue={tagValue} setTagValue={setTagValue} />
    </BGLAnalysisSubHeaderContainer>
  );
};
