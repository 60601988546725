import styled from "styled-components/macro";
import { flexCenterSpaceBetween } from "../../../../../styles/classes/gloabl-classes";

export const ImmediateAttentionPatientsTableHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 8px 16px;
`;

export const ImmediateAttentionPatientsTableHeaderTopContainer = styled.div`
  ${flexCenterSpaceBetween}
  background: ${({ theme }) => theme.colors.containerColor};
  margin-bottom: 16px;
`;

export const ImmediateAttentionPatientsTableHeaderBottomContainer = styled.div<{
  $isEmpty: boolean;
}>`
  height: ${({ $isEmpty }) => ($isEmpty ? "0px" : "auto")};
`;
