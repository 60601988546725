import { PropsWithChildren, Suspense, useEffect, useState } from "react";
import { DefaultGenerics, StreamChat, User } from "stream-chat";
import { useChatTokenOrUndefined } from "./chat-token-context";
import { useGenerateToken } from "../../../hooks/chat-hooks/use-generate-token";
import { STREAM_CHAT_API_KEY } from "../../../constants/config";
import { Chat } from "stream-chat-react";
import { customStyles } from "../chat-home/styled-chat-home";
import { ChatQueryUsersProvider } from "./chat-query-users-context";
import { ChatNotificationsProvider } from "./chat-notifications-context";

type ChatProvidersProps = PropsWithChildren<{}>;

export const ChatProviders = (props: ChatProvidersProps) => {
  const { children } = props;

  const [client, setClient] = useState<StreamChat<DefaultGenerics> | null>(
    null
  );

  const chatTokenDTO = useChatTokenOrUndefined();

  const generateToken = useGenerateToken();

  useEffect(() => {
    if (chatTokenDTO) {
      const user: User = {
        id: chatTokenDTO.chatTokenDTO.chatTokenDTO.streamChatUserId,
      };

      const init = async () => {
        const chatClient = StreamChat.getInstance(STREAM_CHAT_API_KEY);

        const token = async () => {
          const res = await generateToken();
          return res.token;
        };

        await chatClient.connectUser(user, token);

        setClient(chatClient);
      };

      init();
    }
  }, [generateToken, client, chatTokenDTO]);

  if (chatTokenDTO) {
    return (
      <Suspense fallback={<>{children}</>}>
        {client ? (
          <Chat client={client} customStyles={customStyles}>
            <ChatQueryUsersProvider>
              <ChatNotificationsProvider>{children}</ChatNotificationsProvider>
            </ChatQueryUsersProvider>
          </Chat>
        ) : (
          <>{children}</>
        )}
      </Suspense>
    );
  } else {
    return <>{children}</>;
  }
};
