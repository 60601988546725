import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { LabTestTypeRefDTO } from "../../models/lab-test-type-refs/lab-test-type-ref-dto";

export function useGetLabTestTypeRefs(): () => Promise<LabTestTypeRefDTO[]> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<LabTestTypeRefDTO[]>(
      `${BASE_PATH}/lab-test-type-refs`
    );

    return response.data;
  }, [client]);
}
