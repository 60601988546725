import { useState } from "react";
import { GenericActionsList } from "../../../../../components/generic-actions-list/generic-actions-list";
import { GenericActionsListItemItem } from "../../../../../components/generic-actions-list/styled-generic-actions-list";
import { TD, TDContainer } from "../../../../../components/table/styled-table";
import { HomeStaticData } from "../../../context/home-static-data-context";
import { useImmediateAttentionPatientsSidebar } from "../../context/immediate-attention-patients-sidebar-context";
import {
  ImmediateAttentionPatientsTableAlertsCell,
  ImmediateAttentionPatientsTableDiagnosisCell,
  ImmediateAttentionPatientsTableNameCell,
  ImmediateAttentionPatientsTablePriorityCell,
  ImmediateAttentionPatientsTableVisitsCell,
} from "./styled-immediate-attention-patients-table-rows";
import { useImmediateAttentionPatientsSelectedRowsRows } from "../../context/immediate-attention-patients-selected-rows-context";
import { BehavioralIcon } from "./assets/behavioral-icon";
import { SymptomsIcon } from "./assets/symptoms-icon";
import { ReadingsIcon } from "./assets/readings-icon";
import { DiagnosisIcon } from "./assets/diagnosis-icon";

type ImmediateAttentionPatientsTableRowsProps = {
  filtered: HomeStaticData[];
  allPatinets?: boolean;
};

export const ImmediateAttentionPatientsTableRows = (
  props: ImmediateAttentionPatientsTableRowsProps
) => {
  const { allPatinets } = props;

  const { filtered } = props;

  const { openSidebar } = useImmediateAttentionPatientsSidebar();

  const [showCopiedText, setShowCopiedText] = useState<number | undefined>();

  const copyMRN = (mrn: string, rowIndex: number) => {
    navigator.clipboard.writeText(mrn);
    setShowCopiedText(rowIndex);

    setTimeout(() => {
      setShowCopiedText(undefined);
    }, 5000);
  };

  const actions = [
    {
      action: "Patient Profile",
      icon: "profile",
    },
    {
      action: "Scheduale Appointment",
      icon: "calendar",
    },
    {
      action: "Refer to Caregiver",
      icon: "care-giver",
    },
    {
      action: "Send Message",
      icon: "send-2",
    },
    {
      action: "Request Lab test",
      icon: "lab",
    },
    { action: "Note", icon: "edit" },
  ];

  const {
    handleRowSelect,
    selectedRows,
  } = useImmediateAttentionPatientsSelectedRowsRows();

  const tableRows = filtered.map((tableRow, index) => (
    <tr key={index}>
      <TD width={30}>
        <input
          type="checkbox"
          checked={selectedRows.includes(tableRow.patient.id)}
          onChange={() => handleRowSelect(tableRow.patient.id)}
        />
      </TD>
      <TD>
        <ImmediateAttentionPatientsTableNameCell>
          <img
            onClick={openSidebar}
            src={`/img/all-patients/patients/patient-${tableRow.patient.id}.png`}
            alt="patient"
          />
          <div>
            <p
              onClick={openSidebar}
              style={index === 0 ? { marginBottom: "8px" } : {}}
            >
              {tableRow.patient.name}
            </p>
            <span>
              <span
                className="mrn-tag"
                onClick={() => copyMRN(tableRow.mrn, index)}
              >
                #MRN{tableRow.mrn}
                {showCopiedText !== undefined && showCopiedText === index && (
                  <strong>Copied</strong>
                )}
              </span>{" "}
              |{" "}
              <span>
                {" "}
                {tableRow.patient.age} {tableRow.patient.y},{" "}
                {tableRow.patient.gender}
              </span>
            </span>
          </div>
        </ImmediateAttentionPatientsTableNameCell>
      </TD>
      <TD>
        <ImmediateAttentionPatientsTableDiagnosisCell>
          <p>{tableRow.diagnosis.type}</p>
          <span>{tableRow.diagnosis.date}</span>
        </ImmediateAttentionPatientsTableDiagnosisCell>
      </TD>
      {!allPatinets && (
        <TD>
          <ImmediateAttentionPatientsTableAlertsCell>
            {tableRow.alertsIcon === "behavioral" && (
              // <span className="material-symbols-outlined">help_outline</span>
              <BehavioralIcon />
            )}
            {tableRow.alertsIcon === "diagnosis" && (
              // <span className="material-symbols-outlined">report_problem</span>
              <DiagnosisIcon />
            )}
            {tableRow.alertsIcon === "readings" && (
              // <span className="material-symbols-outlined">trending_up</span>
              <ReadingsIcon />
            )}
            {/* <img
              src={`/img/all-patients/table-icons/${tableRow.alertsIcon}.svg`}
              alt={tableRow.alertsIcon}
              crossOrigin="anonymous"
            /> */}
            {tableRow.alertsIcon === "symptoms" && (
              // <span className="material-symbols-outlined symptoms-icon">
              //   psychology_alt
              // </span>
              <SymptomsIcon />
            )}
            <p>{tableRow.alerts}</p>
          </ImmediateAttentionPatientsTableAlertsCell>
        </TD>
      )}
      {!allPatinets && (
        <TD>
          <ImmediateAttentionPatientsTablePriorityCell
            $level={tableRow.priority as "High" | "Low" | "Medium"}
          >
            <span></span>
            <p>{tableRow.priority}</p>
          </ImmediateAttentionPatientsTablePriorityCell>
        </TD>
      )}
      <TD>
        <ImmediateAttentionPatientsTableVisitsCell>
          <p>Next Visit: {tableRow.visits.next}</p>
          <p>Last Visit: {tableRow.visits.last}</p>
        </ImmediateAttentionPatientsTableVisitsCell>
      </TD>
      <TD>
        <ImmediateAttentionPatientsTableAlertsCell>
          <p>{tableRow.complications}</p>
        </ImmediateAttentionPatientsTableAlertsCell>
      </TD>
      <TD width={100}>
        <TDContainer>
          <GenericActionsList>
            {actions.map((action) => (
              <GenericActionsListItemItem hasIcon key={action.action}>
                <img
                  src={`/img/all-patients/actions-icons/${action.icon}.png`}
                  alt={action.action}
                  style={action.icon === "lab" ? { opacity: "0.8" } : {}}
                />
                {action.action}
              </GenericActionsListItemItem>
            ))}
          </GenericActionsList>
        </TDContainer>
      </TD>
    </tr>
  ));
  return <>{tableRows}</>;
};
