import { TH } from "../../../../components/table/styled-table";

export const ContentCourseModulesTableHeadings = () => {
  const tableHeadings = ["Order", "Thumbnail", "Name", "Status", "Actions"].map(
    (heading, index) => (
      <TH
        key={index}
        actions={(heading === "Actions" || heading === "Order") && true}
      >
        <div>{heading}</div>
      </TH>
    )
  );

  return <>{tableHeadings}</>;
};
