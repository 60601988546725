import { Behavioral } from "./behavioral/behavioral";
import { BodyMeasurments } from "./body-measurments/body-measurments";
import { Diagnosis } from "./diagnosis/diagnosis";
import { OtherData } from "./other-data/other-data";
import { KeyIndicators } from "./key-indicators/key-indicators";
import { BloodPressure } from "./blood-pressure/blood-pressure";

export const PersonalInfoTab = () => {
  return (
    <>
      <Diagnosis />
      <KeyIndicators />
      <Behavioral />
      <BloodPressure />
      <BodyMeasurments />
      <OtherData />
    </>
  );
};
