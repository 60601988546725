import { HomeFilterDropdown } from "../components/home-filter-dropdown";
import { ImmediateAttentionPatientsTableChronicDiseasesFilterContainer } from "./styled-immediate-attention-patients-table-chronic-diseases-filter";

const chronicDiseases = [
  "Pre-diabetes",
  "Diabetes Mellitus Type 2",
  "Diabetes Mellitus Type 1",
  "Gestational Diabetes",
  "Hypertension",
  "Chronic Heart Disease",
  "Ischemic Heart Disease",
  "Polycystic ovaries",
  "Dyslipidemia",
  "Chronic thyroid disorder",
  "GIT disorders",
  "Kidney diseases",
  "Liver diseases",
  "Oncology",
  "Kidney stones",
  "Cholecystitis / stones",
  "Respiratory disorders",
];

export const ImmediateAttentionPatientsTableChronicDiseasesFilter = () => {
  return (
    <HomeFilterDropdown
      width="calc(100% + 50px)"
      height="fit-content"
      buttonText="Chronic Diseases"
      dropdownOptions={
        <>
          {chronicDiseases.map((chronicDisease) => (
            <ImmediateAttentionPatientsTableChronicDiseasesFilterContainer
              key={chronicDisease}
            >
              <input type="checkbox" />
              <label>{chronicDisease}</label>
            </ImmediateAttentionPatientsTableChronicDiseasesFilterContainer>
          ))}
        </>
      }
    />
  );
};
