import { Link } from "react-router-dom";
import {
  AdminOrPatientLoginContainer,
  LoginContainer,
  LoginFormContainer,
  LoginFormLogoContainer,
} from "./screens/login/styled-login";

export function AdminOrPatientLogin() {
  return (
    <LoginContainer>
      <LoginFormContainer>
        <LoginFormLogoContainer>
          <img src="/img/logo.svg" alt="" />
        </LoginFormLogoContainer>
        <AdminOrPatientLoginContainer>
          <Link to="/team-login">Otida Team Login</Link>
          <Link to="/patient-login">Patient login</Link>
        </AdminOrPatientLoginContainer>
      </LoginFormContainer>
    </LoginContainer>
  );
}
