import { useHomeStaticData } from "../../../../context/home-static-data-context";
import { useImmediateAttentionPatientsSelectedFilters } from "../../../context/immediate-attention-patients-selected-filters-context";
import {
  ImmediateAttentionPatientsTableSelectedFiltersContainer,
  ImmediateAttentionPatientsTableSelectedFiltersLi,
  ImmediateAttentionPatientsTableSelectedFiltersList,
} from "./styled-immediate-attention-patients-table-selected-filters";

export const ImmediateAttentionPatientsTableSelectedFilters = () => {
  const {
    setSelectedFilters,
    selectedFilters,
    setSelectedGender,
    setMinAgeValue,
    setMaxAgeValue,
    setPriorityOptions,
  } = useImmediateAttentionPatientsSelectedFilters();

  const { homeStaticData, setFilteredHomeStaticData } = useHomeStaticData();

  return (
    <ImmediateAttentionPatientsTableSelectedFiltersContainer>
      <ImmediateAttentionPatientsTableSelectedFiltersList>
        {selectedFilters.map((filter, index) => (
          <ImmediateAttentionPatientsTableSelectedFiltersLi key={index}>
            <p>
              <span>{filter.filterName}: </span>
              {filter.filterValue}
            </p>
            <span
              className="material-symbols-outlined"
              onClick={() => {
                setSelectedFilters(
                  selectedFilters.filter(
                    (item) =>
                      filter.filterName !== item.filterName &&
                      filter.filterValue !== item.filterValue
                  )
                );
                setFilteredHomeStaticData(homeStaticData);
                if (filter.filterName === "Sex") setSelectedGender("All");
                if (filter.filterName === "Age") {
                  setMinAgeValue(0);
                  setMaxAgeValue(100);
                }

                if (filter.filterName === "Priority") {
                  setPriorityOptions([]);
                }
              }}
            >
              close
            </span>
          </ImmediateAttentionPatientsTableSelectedFiltersLi>
        ))}
        {selectedFilters.length > 0 && (
          <ImmediateAttentionPatientsTableSelectedFiltersLi>
            <p>Clear all filters</p>
            <span
              className="material-symbols-outlined"
              onClick={() => {
                setSelectedFilters([]);
                setSelectedGender("All");
                setMinAgeValue(0);
                setMaxAgeValue(100);
                setPriorityOptions([]);
                setFilteredHomeStaticData(homeStaticData);
              }}
            >
              close
            </span>
          </ImmediateAttentionPatientsTableSelectedFiltersLi>
        )}
      </ImmediateAttentionPatientsTableSelectedFiltersList>
    </ImmediateAttentionPatientsTableSelectedFiltersContainer>
  );
};
