import { useState } from "react";
import { SearchInput } from "../../../../../styles/atoms/search-input";
import { usePlansGoals } from "../../context/goals-context";
import { CreatePlansFooter } from "../../create-plans/create-plans-footer/create-plans-footer";
import { GoalsScreenContainer } from "../goals/styled-goals";
import { PlanStepsHeader } from "../../components/plan-steps-header/plan-steps-header";
import { PlansSubScreenContainer } from "../styles";
import { AddLifestyleModal } from "./add-lifestyle-modal/add-lifestyle-modal";
import { AssignedLifestyleList } from "./assigned-lifestyle-list";
import { EmptyLifestyle } from "./empty-lifestyle";
import { SelectLifestyleList } from "./select-lifestyle-list";

export const Lifestyle = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { selectLifestyleGoalsList, assignedLifestyleGoalsList } =
    usePlansGoals();

  const assignedGoalsCondition =
    assignedLifestyleGoalsList.length > 0 &&
    selectLifestyleGoalsList.length === 0;

  const selectGoalsCondition =
    assignedLifestyleGoalsList.length === 0 &&
    selectLifestyleGoalsList.length > 0;

  const bothGoalsCondition =
    assignedLifestyleGoalsList.length > 0 &&
    selectLifestyleGoalsList.length > 0;

  return (
    <PlansSubScreenContainer>
      <PlanStepsHeader
        title="Lifestyle Instructions"
        subTitle="Add Lifestyle item for you patient"
        icon="/img/plans/nav/life-style.svg"
        button={
          assignedLifestyleGoalsList.length === 0 &&
          selectLifestyleGoalsList.length === 0 ? (
            <></>
          ) : (
            <AddLifestyleModal />
          )
        }
      />
      <GoalsScreenContainer>
        <SearchInput
          valueProp={searchTerm}
          onChangeHandler={(e) => setSearchTerm(e.target.value)}
          width="100%"
          placeHolder="Search for Lifestyle goals"
        />
        {assignedGoalsCondition ? (
          <AssignedLifestyleList searchTerm={searchTerm} />
        ) : selectGoalsCondition ? (
          <SelectLifestyleList searchTerm={searchTerm} />
        ) : bothGoalsCondition ? (
          <>
            <AssignedLifestyleList searchTerm={searchTerm} />
            <SelectLifestyleList searchTerm={searchTerm} />
          </>
        ) : (
          <EmptyLifestyle />
        )}
      </GoalsScreenContainer>
      <CreatePlansFooter nextStep="Education" prevStep="Nutrition" />
    </PlansSubScreenContainer>
  );
};
