import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PatientDTO } from "../../models/patient-dtos/patient-dto";
import { CreateNutritionRegimenDTO } from "../../models/patient-nutrition-regimen-dtos/create-nutrition-regimen-dto";
import { NutritionRegimenDTO } from "../../models/nutrition-regimen-dtos/nutrition-regimen-dto";

export function useAddNutritionRegimen(): (
  patientDTO: PatientDTO,
  createNutritionRegimenDTO: CreateNutritionRegimenDTO
) => Promise<NutritionRegimenDTO> {
  const client = useClient();

  return useCallback(
    async (patientDTO, createNutritionRegimenDTO) => {
      const response = await client.post<NutritionRegimenDTO>(
        `${PATIENTS_PATH}/${patientDTO.id}/nutrition-regimens`,
        createNutritionRegimenDTO
      );

      return response.data;
    },
    [client]
  );
}
