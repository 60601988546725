import { roundTo1DecimalPlace } from "../../../../../../../../../../../utils/math-utils";
import {
  MealsAnalysisResponseTableCellMacrosInfoContainer,
  MealsAnalysisResponseTableCellMacrosInfoDetails,
} from "./styled-meals-analysis-response-table-cell-macros-info";

type MealsAnalysisResponseTableCellMacrosInfoProps = {
  macrosPercentage: {
    carbsPercentage: number;
    fatsPercentage: number;
    proteinPercentage: number;
  };
};

export const MealsAnalysisResponseTableCellMacrosInfo = (
  props: MealsAnalysisResponseTableCellMacrosInfoProps
) => {
  const { macrosPercentage } = props;

  return (
    <MealsAnalysisResponseTableCellMacrosInfoContainer>
      <MealsAnalysisResponseTableCellMacrosInfoDetails>
        <p>Carbs</p>
        <span>{roundTo1DecimalPlace(macrosPercentage.carbsPercentage)} %</span>
      </MealsAnalysisResponseTableCellMacrosInfoDetails>
      <MealsAnalysisResponseTableCellMacrosInfoDetails>
        <p>Fats</p>
        <span>{roundTo1DecimalPlace(macrosPercentage.fatsPercentage)} %</span>
      </MealsAnalysisResponseTableCellMacrosInfoDetails>
      <MealsAnalysisResponseTableCellMacrosInfoDetails>
        <p>Proteins</p>
        <span>
          {roundTo1DecimalPlace(macrosPercentage.proteinPercentage)} %
        </span>
      </MealsAnalysisResponseTableCellMacrosInfoDetails>
    </MealsAnalysisResponseTableCellMacrosInfoContainer>
  );
};
