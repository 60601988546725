import { PatientInsulinLogDTO } from "../../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";
import {
  ToolTipBody,
  TooltipContainer,
  TooltipHeader,
} from "../../../../styles/classes/tooltips";

type InsulinTooltipProps = {
  x: Date;
  y: number;
  data: PatientInsulinLogDTO;
};

export const InsulinTooltip = (props: InsulinTooltipProps) => {
  const { x, y, data } = props;

  return (
    <TooltipContainer>
      <TooltipHeader>
        <p>Insulin</p>
        <strong>
          {x.toLocaleString("en-UK", {
            year: undefined,
            month: undefined,
            day: undefined,
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
            second: undefined,
          })}
        </strong>
        <span className="material-symbols-outlined">close</span>
      </TooltipHeader>
      <ToolTipBody>
        <p>
          <span>{data.insulinMedication.name}</span>
        </p>
        <p>
          {y} <span>Units</span>
        </p>
      </ToolTipBody>
    </TooltipContainer>
  );
};
