import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  regularMediumFont,
  regularRegularFont,
} from "../../../../../styles/classes/gloabl-classes";
import { GlucoseChartsCard } from "./feedback-modal/blood-glucose-modal-body/blood-glucose-charts/styled-blood-glucose-charts";

export const FeedbackCard = styled.div<{ viewPlans?: boolean }>`
  border-radius: 8px;
  border: ${({ viewPlans, theme }) =>
    viewPlans ? "0px" : `1px solid ${theme.colors.borderColor}`};
  padding: ${({ theme, viewPlans }) =>
    viewPlans ? "0px" : theme.padding.pad1All};
  margin: 24px auto;
  width: ${({ viewPlans }) => (viewPlans ? "340px" : "95%")};

  @media screen and (min-width: 768px) {
    width: ${({ viewPlans }) => (viewPlans ? "100%" : "95%")};
  }
`;

export const FeedbackCardHeader = styled.div`
  ${flexCenterSpaceBetween}
  flex-direction: row-reverse;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: ${({ theme }) => theme.padding.pad1All};
`;

export const FeedbackCardHeaderTextContainer = styled.div`
  ${flexCenter}
  flex-direction: row-reverse;
  gap: 0rem 1rem;

  img {
    width: 40px;
    height: 40px;
  }

  p {
    margin: 0px;
  }
`;

export const FeedbackCardButtonsContainer = styled.div`
  ${flexCenter}
  gap: 0rem 1rem;

  button {
    ${regularRegularFont}
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    padding: 8px;
    background: transparent;

    img {
      width: 15px;
      height: 15px;
      margin-right: 1rem;
    }
  }
`;

export const FeedbackCardBody = styled.div`
  padding: 1rem 0rem;
`;

export const FeedbackCardBodyTitle = styled.p`
  margin: 0px 0px 16px;
  ${regularMediumFont}
`;

export const FeedbackCardCharts = styled.div<{ viewplans?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 0.4rem;
  place-items: center;
  justify-content: center;

  ${GlucoseChartsCard} {
    min-width: 100%;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: ${({ viewplans }) =>
      viewplans
        ? "repeat(auto-fill, minmax(370px, 1fr))"
        : "repeat(auto-fill, minmax(270px, 1fr))"};
  }
`;

export const FeedbackChartContainer = styled.div`
  height: 350px;
`;
