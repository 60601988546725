import { Loading } from "../../../components/loading";
import { SubScreenContainer } from "../../../styles/classes/reusable-classes";
import { usePatientProfileLifeActivities } from "../patient-profile/patient-profile-activities-section/patient-profile-life-activities/context/patient-profile-life-activities-context";
import { PatientProfileLifeActivities } from "../patient-profile/patient-profile-activities-section/patient-profile-life-activities/patient-profile-life-activities";

export const LifeActivitiesResponse = () => {
  const { load } = usePatientProfileLifeActivities();

  return (
    <SubScreenContainer>
      <Loading
        load={load}
        successComponent={(patientActivityLogStatsDTO) => (
          <PatientProfileLifeActivities
            patientActivityLogStatsDTO={
              patientActivityLogStatsDTO.patientActivityLogStatsDTO
            }
            chartID="activities-tab"
          />
        )}
      />
    </SubScreenContainer>
  );
};
