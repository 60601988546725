import styled from "styled-components/macro";
import {
  lightShadowSimple,
  transition,
} from "../../../../../../../../styles/global-style";

export const ActionsList = styled.ul<{ showCrudList: boolean }>`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 0.7rem;
  border-radius: 0.5rem;
  width: 90px;
  ${lightShadowSimple}
  display: ${({ showCrudList }) => (showCrudList ? "block" : "none")};
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.z700};

  @media screen and (max-width: 500px) {
    left: -60px;
  }
`;

export const ActionsListItem = styled.li`
  padding: 0.5rem 0rem;
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  transition: ${transition};

  :last-child {
    color: ${({ theme }) => theme.colors.danger};
  }

  :hover {
    transform: scale(1.1);
  }
`;
