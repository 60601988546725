import { createContext, useContext } from "react";
import { UseLoadableDataReturn } from "../../../../../hooks/use-loadable-data";
import { PropsWithChildren } from "react";
import { PatientDetailedPrescriptionDTO } from "../../../../../models/patient-prescriptions/patient-detailed-prescription-dto";

type Prescription = {
  patientDetailedPrescriptions: PatientDetailedPrescriptionDTO[];
};

export type LoadablePrescription = UseLoadableDataReturn<Prescription>;

const LoadablePrescriptionContext = createContext<
  LoadablePrescription | undefined
>(undefined);

export type LoadablePrescriptionProviderProps = PropsWithChildren<{
  loadablePrescription: LoadablePrescription;
}>;

export function LoadablePrescriptionProvider(
  props: LoadablePrescriptionProviderProps
) {
  const { loadablePrescription, children } = props;

  return (
    <LoadablePrescriptionContext.Provider value={loadablePrescription}>
      {children}
    </LoadablePrescriptionContext.Provider>
  );
}

export function useLoadablePrescription(): LoadablePrescription {
  const loadablePrescription = useContext(LoadablePrescriptionContext);

  if (loadablePrescription === undefined) {
    throw Error(
      "useLoadablePrescription must be used within an LoadablePrescriptionsProvider"
    );
  }

  return loadablePrescription;
}

export function usePrescription(): Prescription {
  const loadablePrescription = useLoadablePrescription();

  if (loadablePrescription.state.data === undefined) {
    throw Error("Prescription have never been loaded successfully yet.");
  }

  return loadablePrescription.state.data;
}

export function useReloadPrescription(): () => void {
  const loadablePrescription = useLoadablePrescription();

  return loadablePrescription.reload;
}
