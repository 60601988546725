import { PatientCGMEntryDTO } from "../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientInsulinLogDTO } from "../../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";
import { CgmTooltipProps } from "../cgm-tooltip/cgm-tooltip";
import { calculateYaxisValue } from "../cgm-tooltip/utils/calculateYaxisValue";

export type InsulinSeries = {
  x: Date;
  y: number;
  patientInsulinLogDTO: PatientInsulinLogDTO;
  tooltip: CgmTooltipProps;
};

export function buildInsulinSeries(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientInsulinLogDTOs: PatientInsulinLogDTO[]
): InsulinSeries[] {
  const insulinSeries: InsulinSeries[] = [];

  for (const patientInsulinLogDTO of patientInsulinLogDTOs) {
    const jsDate = new Date(patientInsulinLogDTO.date);

    const year = jsDate.getFullYear();
    const month = jsDate.getMonth() + 1;

    let realMonth = ``;
    if (month >= 0 && month <= 9) {
      realMonth = `0${month.toString()}`;
    } else {
      realMonth = month.toString();
    }

    const day = jsDate.getDate();
    let realDay = ``;
    if (day >= 0 && day <= 9) {
      realDay = `0${day.toString()}`;
    } else {
      realDay = day.toString();
    }

    const realDate = `${year}-${realMonth}-${realDay}`;

    const x = new Date(`${realDate}T${patientInsulinLogDTO.time}`);
    const y = calculateYaxisValue(
      patientCGMEntryDTOs,
      patientInsulinLogDTO.date,
      x
    );

    const tooltip: CgmTooltipProps = {
      x,
      y: patientInsulinLogDTO.dose,
      kind: {
        kind: "Insulin",
        data: patientInsulinLogDTO,
      },
    };

    insulinSeries.push({
      x,
      y,
      patientInsulinLogDTO,
      tooltip,
    });
  }

  return insulinSeries;
}
