import styled from "styled-components/macro";
import { OtidaLoader } from "./otida-loader/otida-loader";

export const FullPageLoaderContainer = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 8, 107, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.z1000};
`;

export function FullPageOtidaLoader() {
  return (
    <FullPageLoaderContainer>
      <OtidaLoader fullScreen />
    </FullPageLoaderContainer>
  );
}
