import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  regularMediumFont,
  smallRegularFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const ViewPlansHeaderContainer = styled.header`
  ${flexCenterSpaceBetween}
  flex-direction: row-reverse;
  padding: 8px 16px;
  margin-bottom: 24px;
`;

export const ViewPlansHeaderTextContainer = styled.div`
  text-align: right;

  span {
    ${smallRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 4px 0px 0px;
  }
`;
