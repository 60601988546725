import styled from "styled-components/macro";
import { Button } from "../../../../../styles/classes/reusable-classes";
import {
  flexCenterCenter,
  mediumSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../styles/global-style";

export const ContentModuleLessonMobilePreviewFooterContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  position: absolute;
  inset: 0px;
  top: unset;
  box-shadow: 0 -3px 10px 0px rgba(0, 0, 0, 0.1);

  ${Button} {
    padding: 4px 8px;
    width: 90%;
    height: 30px;
    margin: 0 auto;
    ${flexCenterCenter}
    ${mediumSemiBoldFont}
  }
`;

export const ContentModuleLessonMobilePreviewFooterDots = styled.div`
  ${flexCenterCenter}
  gap: 0px 8px;
  margin: 8px 0px;
`;

export const ContentModuleLessonMobilePreviewFooterDot = styled.div<{
  $activeDot: boolean;
}>`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.primaryColorAlt};
  opacity: ${({ $activeDot }) => ($activeDot ? "1" : "0.5")};
  transition: ${transition};
`;

export const ContentModuleLessonMobilePreviewFooterBlackLine = styled.div`
  width: 50%;
  height: 4px;
  border-radius: 94px;
  background: #000;
  margin: 12px auto 8px;
`;
