import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Dropdown } from "../../../../../../../../components/dropdown/dropdown";
import { DropdownListItem } from "../../../../../../../../components/dropdown/styled-dropdown";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileTagsLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileDietModalInputs } from "../../patient-profile-edit-diet-modal";
import { PatientProfileDietEditModalSugarTypeContainer } from "../styled-patient-profile-edit-diet-modal-body";
import { useCallback } from "react";
import {
  Button,
  ErrorText,
} from "../../../../../../../../styles/classes/reusable-classes";
import {
  PateintProfileDietsAddedSugarsFormControlDropdownsContainer,
  PateintProfileDietsRelativeInputContainer,
} from "./styled-patient-profile-edit-diet-added-sugars-form-control";
import { PateintProfileFamilyMemberDiseasesFormControlBody } from "../../../../../patient-profile-medical-history-section/patient-profile-medical-history/patient-profile-medical-history-edit-modal/patient-profile-medical-history-edit-modal-body/pateint-profile-family-member-diseases-form-control/styled-pateint-profile-family-member-diseases-form-control";
import { usePatientProfileDiet } from "../../../../context/loadable-patient-profile-diet-context";

export const PatientProfileEditDietAddedSugarsFormControl = () => {
  const { addedSugars } = usePatientProfileDiet();

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<PatientProfileDietModalInputs>();

  const findAddedSugarName = useCallback(
    (id: number) => addedSugars.find((item) => item.id === id),
    [addedSugars]
  );

  const { fields, append, remove } = useFieldArray({
    name: "patientAddedSugars",
    control,
  });

  const patientAddedSugars = watch("patientAddedSugars");

  return (
    <PatientProfileModalFormControl>
      <PatientProfileTagsLabelContainer>
        <label>Sugar type</label>
      </PatientProfileTagsLabelContainer>
      <PateintProfileFamilyMemberDiseasesFormControlBody
        isNotEmptyFamilyMemberDiseases={
          patientAddedSugars !== undefined && patientAddedSugars.length > 0
        }
      >
        <PateintProfileDietsAddedSugarsFormControlDropdownsContainer>
          {fields.map((field, index) => (
            <PatientProfileDietEditModalSugarTypeContainer key={field.id}>
              <PatientProfileModalInputContainer>
                <Button outlineNoBorder type="button">
                  <img
                    src="/img/trash.svg"
                    alt="Trash Icon"
                    onClick={() => remove(index)}
                  />
                </Button>
                <Controller
                  name={`patientAddedSugars.${index}.addedSugarId`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <Dropdown
                      text={
                        value !== undefined &&
                        findAddedSugarName(value)?.name !== undefined
                          ? findAddedSugarName(value)?.name!
                          : "Select Type"
                      }
                      variant="designed-ux"
                      fullWidth
                    >
                      {addedSugars.slice(0, 3).map((addedSugar) => (
                        <DropdownListItem
                          key={addedSugar.id}
                          variant="designed-ux"
                          onClick={() => onChange(addedSugar.id)}
                        >
                          {addedSugar.name}
                        </DropdownListItem>
                      ))}
                    </Dropdown>
                  )}
                />
                {errors.patientAddedSugars?.[index]?.addedSugarId?.type ===
                  "required" && <ErrorText>Required</ErrorText>}
              </PatientProfileModalInputContainer>
              <PatientProfileModalInputContainer
                hasTwoInputsFlex
                inputWithSublabel
              >
                <PateintProfileDietsRelativeInputContainer>
                  <Controller
                    name={`patientAddedSugars.${index}.avgQuantityPerBeverage`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <input
                        type="text"
                        placeholder="Quantity/beverage"
                        value={value !== undefined ? value : ""}
                        onChange={(e) =>
                          onChange(
                            e.target.value !== undefined ? e.target.value : ""
                          )
                        }
                      />
                    )}
                  />
                  <strong>tsp/drink</strong>
                  {errors.patientAddedSugars?.[index]?.avgQuantityPerBeverage
                    ?.type === "required" && <ErrorText>Required</ErrorText>}
                </PateintProfileDietsRelativeInputContainer>
                <PateintProfileDietsRelativeInputContainer>
                  <Controller
                    name={`patientAddedSugars.${index}.avgQuantityPerDay`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <input
                        type="text"
                        placeholder="Quantity/day"
                        value={value !== undefined ? value : ""}
                        onChange={(e) =>
                          onChange(e.target.value !== "" ? e.target.value : "")
                        }
                      />
                    )}
                  />
                  <strong>tsp/day</strong>
                  {errors.patientAddedSugars?.[index]?.avgQuantityPerDay
                    ?.type === "required" && <ErrorText>Required</ErrorText>}
                </PateintProfileDietsRelativeInputContainer>
              </PatientProfileModalInputContainer>
            </PatientProfileDietEditModalSugarTypeContainer>
          ))}
        </PateintProfileDietsAddedSugarsFormControlDropdownsContainer>
        <Button
          type="button"
          width={100}
          height={40}
          flex
          outlineNoBorder
          onClick={() => {
            append({
              addedSugarId: undefined!,
              avgQuantityPerBeverage: undefined!,
              avgQuantityPerDay: undefined!,
            });
          }}
        >
          <span className="material-symbols-outlined">add</span> Add
        </Button>
      </PateintProfileFamilyMemberDiseasesFormControlBody>
    </PatientProfileModalFormControl>
  );
};
