/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { LabTestTypeDTO } from "../../../../../../../models/lab-test-types/lab-test-type-dto";
import { PatientLabTestResultDTO } from "../../../../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import { displayRef } from "../../../../utils/lab-test-results-utils";
import { LabResultIndiactor } from "./styled-lab-results-indicators";
import { useLabResults } from "../../../../context/loadable-lab-results-context";

type LabResultsIndicatorsProps = {
  item: {
    labTestTypeDTO: LabTestTypeDTO;
    sortedLabTestResultsByDate: PatientLabTestResultDTO[];
  };
  patientLabTestResultDTO: PatientLabTestResultDTO;
};

export const LabResultsIndicators = (props: LabResultsIndicatorsProps) => {
  const { item, patientLabTestResultDTO } = props;
  const { labTestTypeRefDTOs } = useLabResults();

  const refsDisplays = useCallback(
    (labTestTypeDTO: LabTestTypeDTO) =>
      labTestTypeRefDTOs
        .filter((ref) => ref.labTestTypeId === labTestTypeDTO.id)
        .map((ref) => displayRef(ref)),
    [labTestTypeRefDTOs]
  );

  const checkIsNormal = useCallback(() => {
    const isNormal = refsDisplays(item.labTestTypeDTO).every((test) => {
      return (
        test.max >= patientLabTestResultDTO.result &&
        patientLabTestResultDTO.result >= test.min
      );
    });

    return isNormal;
  }, [refsDisplays, patientLabTestResultDTO.result]);

  const checkIsAbove = useCallback(() => {
    const isAbove = refsDisplays(item.labTestTypeDTO).every((test) => {
      return test.max < patientLabTestResultDTO.result;
    });

    return isAbove;
  }, [refsDisplays, patientLabTestResultDTO.result]);

  const checkIsBelow = useCallback(() => {
    const isBelow = refsDisplays(item.labTestTypeDTO).every((test) => {
      return test.min > patientLabTestResultDTO.result;
    });

    return isBelow;
  }, [refsDisplays, patientLabTestResultDTO.result]);

  return (
    <div>
      <LabResultIndiactor
        state={
          checkIsNormal()
            ? "Normal"
            : checkIsAbove()
            ? "Above"
            : checkIsBelow()
            ? "Bellow"
            : undefined
        }
      />
    </div>
  );
};
