import { PATIENTS_PATH } from "../constants/paths";
import { useClient } from "../context/client-context";
import { useCallback } from "react";
import { PatientCGMEntryDTO } from "../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { CreatePatientCGMEntryDTO } from "../models/patient-cgm-entry-dtos/create-patient-cgm-entry-dto";

export function useAddPatientCGMEntry(): (
  patientId: number,
  createPatientCGMEntryDTO: CreatePatientCGMEntryDTO
) => Promise<PatientCGMEntryDTO> {
  const client = useClient();

  return useCallback(
    async (patientId, createPatientCGMEntryDTO) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.post<PatientCGMEntryDTO>(
        `${PATIENTS_PATH}/${patientId}/cgm-entries`,
        createPatientCGMEntryDTO
      );

      return response.data;
    },
    [client]
  );
}
