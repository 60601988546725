import styled from "styled-components/macro";

export const ModalFormForm = styled.form<{ height?: number; width?: number }>`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 1rem;
  border-radius: 0.75rem;
  width: ${({ width }) => (width !== undefined ? `${width}px` : "470px")};
  height: ${({ height }) => (height !== undefined ? `${height}px` : "500px")};
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.z600};
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 980px) {
    width: 100%;
    z-index: ${({ theme }) => theme.zIndex.z1000};
  }

  @media screen and (max-width: 500px) {
    position: fixed;
    border-radius: 2.625rem 2.625rem 0rem 0rem;
    bottom: 0;
  }

  @media screen and (max-width: 426px) {
    padding: 1rem 0.5rem;
  }
`;
