import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";
import { roundTo1DecimalPlace } from "../../../../../utils/math-utils";

type NutritionReportNaTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportNaTableCell = (
  props: NutritionReportNaTableCellProps
) => {
  const { kind } = props;

  const { na } = useMemo(() => {
    const na =
      kind.kind === "Row"
        ? kind.data.totalNaSodiumGm
        : kind.data.patientMealMacrosSummary.naSodiumGm;

    return { na };
  }, [kind.data, kind.kind]);

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      value={roundTo1DecimalPlace(na)}
    />
  );
};
