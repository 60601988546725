import styled from "styled-components";
import {
  PatientProfileModalTableAndChartTableContainerCSS,
  PatientProfileModalTableAndChartTableHeaderContainerCSS,
  PatientProfileModalTableAndChartWrapperCSS,
  PatientProfileTableAndChartContainerHeaderContainerCSS,
  PatientProfileTableAndChartContainerHeaderDateContainerCSS,
} from "./patient-profile-weight-section-css";

export const PatientProfileModalTableAndChartWrapper = styled.div`
  ${PatientProfileModalTableAndChartWrapperCSS}
`;

export const PatientProfileModalTableAndChartTableContainer = styled.div`
  ${PatientProfileModalTableAndChartTableContainerCSS}
`;

export const PatientProfileModalTableAndChartTableHeaderContainer = styled.div`
  ${PatientProfileModalTableAndChartTableHeaderContainerCSS}
`;

export const PatientProfileTableAndChartContainerHeaderContainer = styled.div`
  ${PatientProfileTableAndChartContainerHeaderContainerCSS}
`;

export const PatientProfileTableAndChartContainerHeaderDateContainer = styled.div`
  ${PatientProfileTableAndChartContainerHeaderDateContainerCSS}
`;
