import { SearchInput } from "../../../../../styles/atoms/search-input";
import { useImmediateAttentionPatientsSelectedFilters } from "../../context/immediate-attention-patients-selected-filters-context";
import { ImmediateAttentionPatientsTableFilters } from "./immediate-attention-patients-table-filters/immediate-attention-patients-table-filters";
import { ImmediateAttentionPatientsTableSelectedFilters } from "./immediate-attention-patients-table-selected-filters/immediate-attention-patients-table-selected-filters";
import {
  ImmediateAttentionPatientsTableHeaderBottomContainer,
  ImmediateAttentionPatientsTableHeaderContainer,
  ImmediateAttentionPatientsTableHeaderTopContainer,
} from "./styled-immediate-attention-patients-table-header";
import { ChangeEvent } from "react";

type ImmediateAttentionPatientsTableHeaderProps = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  allPatinets?: boolean;
};

export const ImmediateAttentionPatientsTableHeader = (
  props: ImmediateAttentionPatientsTableHeaderProps
) => {
  const { searchTerm, setSearchTerm, allPatinets } = props;

  const { selectedFilters } = useImmediateAttentionPatientsSelectedFilters();

  return (
    <ImmediateAttentionPatientsTableHeaderContainer>
      <ImmediateAttentionPatientsTableHeaderTopContainer>
        <ImmediateAttentionPatientsTableFilters allPatients={allPatinets} />
        <SearchInput
          tableSearch
          onChangeHandler={(event: ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(event.target.value)
          }
          valueProp={searchTerm}
        />
      </ImmediateAttentionPatientsTableHeaderTopContainer>
      <ImmediateAttentionPatientsTableHeaderBottomContainer
        $isEmpty={selectedFilters.length === 0}
      >
        <ImmediateAttentionPatientsTableSelectedFilters />
      </ImmediateAttentionPatientsTableHeaderBottomContainer>
    </ImmediateAttentionPatientsTableHeaderContainer>
  );
};
