import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import { CurrentFeedbackModal } from "../feedback-modal";
import {
  FeedbackModalBodyCard,
  FeedbackModalBodyContainer,
} from "./styled-feedback-modal-body";

type FeedbackModalBodyProps = {
  onClose: () => void;
  setCurrentFeedbackModal: (currentFeedbackModal: CurrentFeedbackModal) => void;
};

export const FeedbackModalBody = (props: FeedbackModalBodyProps) => {
  const { onClose, setCurrentFeedbackModal } = props;

  return (
    <ModalForm width={390} height={700}>
      <ModalHeader
        title="Add Feedback"
        subTitle="Select the type of feedback to send to the patient"
        onModalClose={onClose}
      />
      <FeedbackModalBodyContainer>
        <FeedbackModalBodyCard
          onClick={() => setCurrentFeedbackModal("Manual Readings")}
        >
          <img src="/img/plans/feedback/manual-readings.svg" alt="" />
          <p>BGM</p>
        </FeedbackModalBodyCard>
        <FeedbackModalBodyCard
          onClick={() => setCurrentFeedbackModal("Blood glucose")}
        >
          <img src="/img/plans/feedback/blood-glucose.svg" alt="" />
          <p>CGM</p>
        </FeedbackModalBodyCard>
        <FeedbackModalBodyCard
          onClick={() => setCurrentFeedbackModal("Meal graphs")}
        >
          <img src="/img/plans/feedback/meal-graphs.svg" alt="" />
          <p>Meal graphs</p>
        </FeedbackModalBodyCard>
        <FeedbackModalBodyCard
          onClick={() => setCurrentFeedbackModal("Weight loss")}
        >
          <img src="/img/plans/feedback/weight-loss.svg" alt="" />
          <p>Weight loss</p>
        </FeedbackModalBodyCard>
        <FeedbackModalBodyCard
          onClick={() => setCurrentFeedbackModal("Custom Feedback")}
        >
          <img src="/img/plans/feedback/custom-feedback.svg" alt="" />
          <p>Custom Feedback</p>
        </FeedbackModalBodyCard>
      </FeedbackModalBodyContainer>
    </ModalForm>
  );
};
