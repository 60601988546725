import { PATIENTS_PATH } from "../constants/paths";
import { useClient } from "../context/client-context";
import { useCallback } from "react";
import { PatientServingDTO } from "../models/patient-serving-dto";

export function useGetPatientServings(): (
  patientId: number,
  date?: string
) => Promise<PatientServingDTO[]> {
  const client = useClient();

  return useCallback(
    async (patientId, date) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientServingDTO[]>(
        `${PATIENTS_PATH}/${patientId}/cronometer-servings`,
        { params: { date } }
      );

      return response.data;
    },
    [client]
  );
}
