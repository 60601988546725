import {
  MutableRefObject,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNutritionReportSearchParams } from "./nutrition-report-search-params-context";

type NutritionReportMealTableRow = {
  showMealTableRow: number[];
  MealTableRowContainerRef: MutableRefObject<HTMLTableRowElement | null>;
  toggleMealTableRow: (id: number) => void;
};

const NutritionReportMealTableRowContext = createContext<
  NutritionReportMealTableRow | undefined
>(undefined);

export const NutritionReportMealTableRowProvider = (
  props: PropsWithChildren<{}>
) => {
  const { children } = props;

  const { searchParams } = useNutritionReportSearchParams();

  const mealsSearchParamsConvertedToNumbers = searchParams
    .getAll("meals")
    .map((item) => Number(item));

  const [showMealTableRow, setShowMealTableRow] = useState<number[]>(
    mealsSearchParamsConvertedToNumbers
  );

  const MealTableRowContainerRef = useRef<HTMLTableRowElement | null>(null);

  const viewMealTableRow = useCallback(
    (id: number) => {
      setShowMealTableRow([...showMealTableRow, id]);
    },
    [showMealTableRow]
  );

  const hideMealTableRow = useCallback(
    (id: number) => {
      if (showMealTableRow.length === 1) {
        setShowMealTableRow([]);
      } else {
        setShowMealTableRow(showMealTableRow.filter((item) => item !== id));
      }
    },
    [showMealTableRow]
  );

  const toggleMealTableRow = useCallback(
    (id: number) => {
      if (showMealTableRow.includes(id)) {
        hideMealTableRow(id);
      } else {
        viewMealTableRow(id);
      }
    },
    [hideMealTableRow, showMealTableRow, viewMealTableRow]
  );

  const value = useMemo(
    () => ({
      showMealTableRow,
      MealTableRowContainerRef,
      toggleMealTableRow,
    }),
    [showMealTableRow, MealTableRowContainerRef, toggleMealTableRow]
  );

  return (
    <NutritionReportMealTableRowContext.Provider value={value}>
      {children}
    </NutritionReportMealTableRowContext.Provider>
  );
};

export const useNutritionReportMealTableRow =
  (): NutritionReportMealTableRow => {
    const nutritionReportMealTableRow = useContext(
      NutritionReportMealTableRowContext
    );

    if (nutritionReportMealTableRow === undefined) {
      throw new Error(
        `useNutritionReportMealTableRow must be wrapped with in NutritionReportMealTableRowProvider`
      );
    }

    return nutritionReportMealTableRow;
  };
