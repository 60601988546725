import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  flexCol,
  mediumFont,
  mediumRegularFont,
  regularSemiBoldFont,
  smallRegularFont,
} from "../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../styles/global-style";

export const PatientProfileNotesBodyList = styled.ul`
  margin: 10px 24px;
  ${flexCol}
  gap: 16px 0px;
  padding-bottom: 2rem;
`;

export const PatientProfileNotesBodyListItem = styled.li`
  padding: 16px 24px;
  background: ${({ theme }) => theme.newColors.containerColorBackgroundAlt};
  border-radius: 8px;
`;

export const PatientProfileNotesBodyListItemTopContainer = styled.div`
  ${flexCenterSpaceBetween}
`;

export const ShowTextOnHover = styled.span`
  width: max-content;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.buttonColor};
  opacity: 0;
  overflow: hidden;
  border-radius: 12px;
  padding: 0px;
  height: 0px;
  position: absolute;
  top: 100%;
  left: 10px;
  transition: ${transition};
`;

export const PatientProfileNotesBodyListItemDate = styled.p`
  margin: 0px;
  ${mediumRegularFont}
  color: ${({ theme }) => `${theme.colors.screenTitleColor}80`};
  margin-bottom: 8px;
  position: relative;

  span {
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }

    :hover ${ShowTextOnHover} {
      padding: 12px;
      opacity: 0.9;
      overflow: visible;
      height: auto;
    }
  }
`;

export const PatientProfileNotesBodyListItemIconContainer = styled.div`
  span {
    cursor: pointer;
  }
`;

export const PatientProfileNotesBodyListItemTitle = styled.p`
  margin: 0px;
  ${regularSemiBoldFont}
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const PatientProfileNotesListItemNoteContainer = styled.div`
  margin: 12px 0px 16px 0px;

  p {
    margin: 0px;
    ${mediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;

export const PatientProfileListItemAuthorText = styled.p`
  margin: 0px;
  color: ${({ theme }) => theme.newColors.modalContainerAlt};
  ${smallRegularFont}
  position: relative;
  width: fit-content;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }

  :hover ${ShowTextOnHover} {
    padding: 12px;
    opacity: 0.9;
    overflow: visible;
    height: auto;
  }
`;
