import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PatientNoteDTO } from "../../models/patient-note-dtos/patient-note-dto";
import { UpdatePatientNoteDTO } from "../../models/patient-note-dtos/update-patient-note-dto";

export function useUpdatePatientNote(): (
  patientNoteDTO: PatientNoteDTO,
  updatePatientNoteDTO: UpdatePatientNoteDTO
) => Promise<PatientNoteDTO> {
  const client = useClient();

  return useCallback(
    async (patientNoteDTO, updatePatientNoteDTO) => {
      const response = await client.put<PatientNoteDTO>(
        `${PATIENTS_PATH}/${patientNoteDTO.patientId}/notes/${patientNoteDTO.id}`,
        updatePatientNoteDTO
      );

      return response.data;
    },
    [client]
  );
}
