import styled from "styled-components/macro";
import { flexCenterCenter } from "../../../../../../styles/classes/gloabl-classes";

export const FoodLoggingSelectedPatientMealImagesModalContainer = styled.div`
  z-index: ${({ theme }) => theme.zIndex.z600};
  max-width: 800px;
  width: 100%;
  background: ${({ theme }) => theme.newColors.modalContainerAlt};
  border-radius: 8px;
  ${flexCenterCenter}
  margin-bottom: 20px;

  img {
    ::selection {
      display: none;
    }
  }
`;

export const FoodLoggingSelectedPatientMealImagesModalSlide = styled.div<{
  active: boolean;
}>`
  display: ${({ active }) => (active ? "inline-block" : "none")};
  width: 100%;
  height: 100%;
`;

export const FoodLoggingSelectedPatientMealImagesModalSlideArrow = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.containerColor};
  font-size: 40px;

  ::selection {
    display: none;
  }
`;

export const FoodLoggingSelectedPatientMealImagesModalSlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media screen and (max-width: 1000px) {
    margin: 100px 0 0;
  }
`;

export const FoodLoggingSelectedPatientMealImagesModalIndicatorsContainer = styled.div`
  ${flexCenterCenter}
  gap: 0px 16px;
  position: absolute;
  bottom: 30px;
`;

export const FoodLoggingSelectedPatientMealImagesModalIndicatorsImageContainer = styled.div<{
  active?: boolean;
}>`
  width: 76px;
  height: 76px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid
    ${({ theme, active }) =>
      active ? theme.colors.primaryColor : "transparent"};

  img {
    height: 100%;
    width: 100%;
  }
`;
