import {
  FoodLoggingSelectedPatientMealHeaderText,
  FoodLoggingSelectedPatientMealNotesOrVoiceNotesContainerContainer,
} from "../../styled-food-logging-selected-patient-meal";

type FoodLoggingSelectedPatientMealNotesProps = {
  patientMealDTONote?: string;
};

export const FoodLoggingSelectedPatientMealNotes = (
  props: FoodLoggingSelectedPatientMealNotesProps
) => {
  const { patientMealDTONote } = props;

  return (
    <FoodLoggingSelectedPatientMealNotesOrVoiceNotesContainerContainer>
      <FoodLoggingSelectedPatientMealHeaderText>
        <strong>
          <img src="/img/document-blue.svg" alt="Document Icon" />
          {patientMealDTONote}
        </strong>
      </FoodLoggingSelectedPatientMealHeaderText>
    </FoodLoggingSelectedPatientMealNotesOrVoiceNotesContainerContainer>
  );
};
