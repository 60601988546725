import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PatientPlanDTO } from "../../models/patient-plan-dtos/patient-plan-dto";
import { UpdatePatientPlanDTO } from "../../models/patient-plan-dtos/update-patient-plan-dto";

export function useUpdatePatientPlan(): (
  patientPlanDTO: PatientPlanDTO,
  updatePatientPlanDTO: UpdatePatientPlanDTO
) => Promise<PatientPlanDTO> {
  const client = useClient();

  return useCallback(
    async (patientPlanDTO, updatePatientPlanDTO) => {
      const response = await client.put<PatientPlanDTO>(
        `${PATIENTS_PATH}/${patientPlanDTO.patientId}/plans/${patientPlanDTO.id}`,
        updatePatientPlanDTO
      );

      return response.data;
    },
    [client]
  );
}
