import { PatientProfileLifeActivitiesProvider } from "../patient-profile/patient-profile-activities-section/patient-profile-life-activities/context/patient-profile-life-activities-context";
import { LifeActivitiesResponse } from "./life-activities-response";

export const LifeActivities = () => {
  return (
    <PatientProfileLifeActivitiesProvider>
      <LifeActivitiesResponse />
    </PatientProfileLifeActivitiesProvider>
  );
};
