import { PatientInsulinLogDTO } from "../../../../../../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";

type MealsAnalysisResponseInsulinTableCellProps = {
  patientInsulinLog?: PatientInsulinLogDTO;
};

export const MealsAnalysisResponseInsulinTableCell = (
  props: MealsAnalysisResponseInsulinTableCellProps
) => {
  const { patientInsulinLog } = props;

  return (
    <td>
      {patientInsulinLog !== undefined ? (
        <>
          {patientInsulinLog?.insulinMedication.name} -{" "}
          {patientInsulinLog?.dose} Units
        </>
      ) : (
        `No Inulin Log`
      )}
    </td>
  );
};
