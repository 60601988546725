import { Modal } from "../../../../components/modal/modal";
import { BulkImportModalContent } from "../styled-bulk-import";
import { useModalHelpers } from "../../../../components/modal/use-modal-helpers";
import { DropdownListItem } from "../../../../components/dropdown/styled-dropdown";
import { useLoadablePatients } from "../../context/loadable-patients-context";
import { BulkImportCronometerModal } from "./bulk-import-cronometer-modal";

export function BulkImportCronometer() {
  const { isModalOpen, openModal, closeModal, modalBodyKey } =
    useModalHelpers();

  const loadablePatients = useLoadablePatients();

  const arePatientsLoaded = loadablePatients.state.data !== undefined;

  return (
    <>
      <DropdownListItem
        onClick={openModal}
        className={`${arePatientsLoaded ? "" : "disabled"}`}
      >
        Servings data
      </DropdownListItem>
      {arePatientsLoaded && (
        <Modal handleHideModal={closeModal} showModal={isModalOpen}>
          <BulkImportModalContent key={modalBodyKey}>
            <BulkImportCronometerModal onModalClose={closeModal} />
          </BulkImportModalContent>
        </Modal>
      )}
    </>
  );
}
