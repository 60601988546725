import {
  ImmediateAttentionPatientsFiltersSidebarFilterCheckboxList,
  ImmediateAttentionPatientsFiltersSidebarFilterTitle,
} from "../styled-immediate-attention-patients-filters-sidebar";

const complications = [
  "Retinopathy",
  "Nephropathy level I",
  "Nephropathy level II",
  "Nephropathy level III",
  "Cardiovascular disease",
  "Deep vein thrombosis",
  "Left ventricular hypertrophy",
  "Sexual dysfunction",
  "Neuropathy",
  "Diabetic foot",
  "Foot or finger amputation",
];

export const ImmediateAttentionPatientsFiltersSidebarComplications = () => {
  return (
    <>
      <ImmediateAttentionPatientsFiltersSidebarFilterTitle>
        Complications
      </ImmediateAttentionPatientsFiltersSidebarFilterTitle>
      <ImmediateAttentionPatientsFiltersSidebarFilterCheckboxList $height="140px">
        {complications.map((complication) => (
          <div key={complication}>
            <input type="checkbox" />
            <label>{complication}</label>
          </div>
        ))}
      </ImmediateAttentionPatientsFiltersSidebarFilterCheckboxList>
    </>
  );
};
