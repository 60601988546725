import { Controller, useForm } from "react-hook-form";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import { AddGoalModalTextAreaContainer } from "../../../styles";
import { usePlansGoals } from "../../../../context/goals-context";

type AddGoalModalBodyProps = {
  onClose: () => void;
};

type GoalsFormInput = {
  goal?: string;
};

export const AddGoalModalBody = (props: AddGoalModalBodyProps) => {
  const { onClose } = props;

  const { handleAddGoal } = usePlansGoals();

  const { control, watch, handleSubmit } = useForm<GoalsFormInput>({
    defaultValues: {
      goal: undefined,
    },
  });
  const goal = watch("goal");

  const onSubmit = handleSubmit(async () => {
    handleAddGoal({ goal: goal !== undefined ? goal : "", goalType: "Goal" });

    onClose();
  });

  return (
    <ModalForm width={670} height={340} onSubmit={onSubmit}>
      <ModalHeader
        title="Add a goal"
        onModalClose={onClose}
        subTitle="Write a custom goal for your patients"
      />

      <Controller
        name="goal"
        control={control}
        render={({ field: { value, onChange } }) => (
          <AddGoalModalTextAreaContainer>
            <textarea
              placeholder="Write here..."
              value={value === undefined ? "" : value}
              onChange={(e) =>
                onChange(e.target.value === undefined ? "" : e.target.value)
              }
            ></textarea>
          </AddGoalModalTextAreaContainer>
        )}
      />

      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={goal === "" || goal === undefined ? true : false}
        >
          Yes, Add
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
