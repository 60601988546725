import { TagDropdownListItem } from "../styled-tag-dropdown";
import { DropdownTag } from "../tag-dropdown-utils";
import { TagNestedDropdownList } from "./styled-tag-nested-dropdown";

type TagNestedDropdownProps = {
  tag: DropdownTag;
  onChange: (value: string, afterMealValue?: string) => void;
  handleCloseAllDropdowns: () => void;
  filterOnChange?: (value: string) => void;
};

export const TagNestedDropdown = (props: TagNestedDropdownProps) => {
  const { tag, onChange, handleCloseAllDropdowns, filterOnChange } = props;

  return (
    <TagNestedDropdownList onClick={handleCloseAllDropdowns}>
      {tag.nestedTags?.map((nestedTag, index) => (
        <TagDropdownListItem
          key={index}
          onClick={() => {
            if (filterOnChange !== undefined) {
              filterOnChange(`${tag.tagName}${`- ${nestedTag.tagName}`}`);
            }
            onChange(tag.tagName, nestedTag.tagName);
          }}
        >
          {nestedTag.tagName}
        </TagDropdownListItem>
      ))}
    </TagNestedDropdownList>
  );
};
