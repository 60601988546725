import { useParams } from "react-router-dom";
import { useGetPatientMedications } from "../../../../../hooks/use-get-patient-medications";
import { LoadableMedicationsProvider } from "../../../medications/context/loadable-medications-context";
import { PateintProfileColumnHeader } from "../../components/pateint-profile-column-header/pateint-profile-column-header";
import {
  PatientProfileContainer,
  PatientProfileContainerBody,
} from "../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileMedicationsTable } from "./patient-profile-medications-table/patient-profile-medications-table";
import { useCallback } from "react";
import { useLoadableData } from "../../../../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../../../../components/loadable-data-loading";

export const PatientProfileMedications = () => {
  const getMedciations = useGetPatientMedications();

  const { id } = useParams();
  const patientID = Number(id!);

  const loadableMedicationsCallback = useCallback(async () => {
    const [patientMedicationDTOs] = await Promise.all([
      getMedciations(patientID),
    ]);

    return { patientDTO: undefined!, patientMedicationDTOs };
  }, [patientID, getMedciations]);

  const loadableMedications = useLoadableData(loadableMedicationsCallback);

  return (
    <PatientProfileContainer>
      <LoadableMedicationsProvider loadableMedications={loadableMedications}>
        <LoadableDataLoading
          state={loadableMedications.state}
          successComponent={() => (
            <>
              <PateintProfileColumnHeader
                img="/img/plans/nav/medical.svg"
                title="Medications"
                button={<></>}
              />
              <PatientProfileContainerBody>
                <PatientProfileMedicationsTable />
              </PatientProfileContainerBody>
            </>
          )}
        />
      </LoadableMedicationsProvider>
    </PatientProfileContainer>
  );
};
