import { ChatMessagingMediaSidebarActiveList } from "../../messaging-media-sidebar";
import { MessagingMediaSidebarActiveListHeaderContainer } from "./styled-messaging-media-sidebar-active-list-header";

type MessagingMediaSidebarActiveListHeaderProps = {
  handleResetActiveList: () => void;
  title: ChatMessagingMediaSidebarActiveList;
};

export const MessagingMediaSidebarActiveListHeader = (
  props: MessagingMediaSidebarActiveListHeaderProps
) => {
  const { handleResetActiveList, title } = props;

  return (
    <MessagingMediaSidebarActiveListHeaderContainer>
      <div onClick={handleResetActiveList}>
        <img src="/img/chat/chev.svg" alt="Back" />
      </div>
      {title === "Pinned Messages" && <p>{title}</p>}
    </MessagingMediaSidebarActiveListHeaderContainer>
  );
};
