import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  mediumFont,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";
import { PersonalInfoItemCSS } from "../../styled-single-patient-sidebar";

export const PersonalInfoGroup = styled.div`
  padding: 0rem 1rem;
  margin-bottom: 2.5rem;
`;

export const PersonalInfoGroupTop = styled.div`
  ${flexCenterSpaceBetween}
`;

export const PersonalInfoGroupTitle = styled.div`
  ${regularMediumFont}
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0;
`;

export const PersonalInfoGroupText = styled.span`
  ${mediumFont}
  color: ${({ theme }) => theme.colors.textColor};
  cursor: pointer;
`;

export const DiagnosedDate = styled.p`
  ${mediumFont}
  color: ${({ theme }) => theme.colors.textColor};
  margin: 1.5rem 0rem 0rem;
`;

export const PersonalInfoGroupBottom = styled.div`
  margin: 1rem 0rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;
  align-items: center;
  justify-content: flex-start;
`;

export const PersonalInfoItem = styled.div`
  ${PersonalInfoItemCSS}
`;
