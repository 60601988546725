import styled from "styled-components/macro";
import { mediumFont } from "../../../../../../styles/classes/gloabl-classes";

export const TargetAudienceAutoSelectInput = styled.input`
  width: 100%;
  padding: 8px 16px;
  outline: 0px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${mediumFont}
`;
