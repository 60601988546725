import { Temporal } from "temporal-polyfill";
import { GenericActionsList } from "../../../../../../components/generic-actions-list/generic-actions-list";
import { Table } from "../../../../../../components/table/table";
import { AddNoteModal } from "../../../../medications/add-note-modal/add-note-modal";
import { MedicationCard } from "../../../../medications/components/medication-card/medication-card";
import {
  ActionsContainer,
  AssignedContainer,
  DateContainer,
  MedicationContainer,
  MedicationTD,
  MedicationTH,
  NoteContainer,
  SigContainer,
  StatusContainer,
} from "../../../../medications/medications-table/styled-medications-table";
import { StopMedicineModal } from "../../../../medications/stop-medicine-modal/stop-medicine-modal";
import {
  generateSig,
  sortMedicationsByName,
  sortedPatientMedicationDTOs,
} from "../../../../medications/utils/medications-utils";
import { useMemo } from "react";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { useMedications } from "../../../../medications/context/loadable-medications-context";

export const PatientProfileMedicationsTable = () => {
  const { patientMedicationDTOs } = useMedications();

  const sorted = useMemo(
    () => sortMedicationsByName(patientMedicationDTOs),
    [patientMedicationDTOs]
  );

  const tableHeadings = [
    "Name",
    "Status",
    "Sig.",
    "Start date",
    "Assign by",
    "Note",
    "Actions",
  ].map((heading, index) => (
    <MedicationTH
      key={index}
      minWidth={
        index === 1
          ? 40
          : index === 0
          ? 200
          : index === 2 || index === 5
          ? 140
          : index === 4
          ? 100
          : index === 3
          ? 100
          : 30
      }
    >
      <div>{heading}</div>
    </MedicationTH>
  ));

  const tableRows = sortedPatientMedicationDTOs(sorted).map((row) => {
    const today = Temporal.Now.plainDateISO();

    const startDatePlusDuration =
      row.startDate !== undefined && row.durationDays !== undefined
        ? Temporal.PlainDate.from(row.startDate).add({
            days: row.durationDays,
          })
        : undefined;

    const isFinished =
      startDatePlusDuration !== undefined
        ? Temporal.PlainDate.compare(today, startDatePlusDuration)
        : undefined;

    const isNotStartedYet =
      row.startDate !== undefined
        ? Temporal.PlainDate.compare(row.startDate, today)
        : undefined;

    return (
      <tr key={row.id}>
        <MedicationTD>
          <MedicationContainer>
            <MedicationCard patientMedicationDTO={row} />
          </MedicationContainer>
        </MedicationTD>
        <MedicationTD>
          <StatusContainer
            stopped={
              row.discontinueDate !== undefined ||
              (isFinished !== undefined &&
                isFinished === 1 &&
                startDatePlusDuration !== undefined)
            }
            notStartedYet={
              isNotStartedYet !== undefined && isNotStartedYet === 1
                ? true
                : false
            }
          >
            <p>
              {row.discontinueDate !== undefined
                ? "Stopped"
                : isFinished !== undefined &&
                  isFinished === 1 &&
                  startDatePlusDuration !== undefined
                ? "Finished"
                : isNotStartedYet !== undefined && isNotStartedYet === 1
                ? "Not started yet"
                : "Current"}
            </p>
            <span>
              {row.discontinueDate !== undefined && isFinished !== 1
                ? row.discontinueDate
                : ""}
              <span>
                {isFinished !== undefined &&
                isFinished === 1 &&
                startDatePlusDuration !== undefined
                  ? startDatePlusDuration.toString()
                  : ""}
              </span>
            </span>
          </StatusContainer>
        </MedicationTD>
        <MedicationTD>
          <SigContainer>
            <p>
              {`${generateSig(row).quantity} ${generateSig(row).frequency} ${
                row.durationDays !== undefined ? generateSig(row).duration : ""
              } ${generateSig(row).notes}`}
            </p>
          </SigContainer>
        </MedicationTD>
        <MedicationTD>
          <DateContainer>
            <p>{row.startDate !== undefined ? row.startDate : ""}</p>
          </DateContainer>
        </MedicationTD>
        <MedicationTD>
          <AssignedContainer
            byOtida={
              row.patientPrescriptionId !== undefined ||
              row.isAssignedByOtida === true
            }
          >
            <img src="/img/logo-2.svg" alt="Otida" />
            <p>{`${
              row.patientPrescriptionId !== undefined ||
              row.isAssignedByOtida === true
                ? "Otida"
                : "Patient"
            }`}</p>
          </AssignedContainer>
        </MedicationTD>
        <MedicationTD>
          <NoteContainer>
            <p>{row.note !== undefined ? row.note : ""}</p>
          </NoteContainer>
        </MedicationTD>
        <MedicationTD>
          <ActionsContainer>
            <GenericActionsList>
              <StopMedicineModal row={row} />
              <AddNoteModal row={row} />
            </GenericActionsList>
          </ActionsContainer>
        </MedicationTD>
      </tr>
    );
  });

  return (
    <>
      {sorted.length > 0 ? (
        <Table tableHeadings={tableHeadings} tableRows={tableRows} />
      ) : (
        <EmptyState
          button={<></>}
          hasHeader={false}
          hasSearch={false}
          title=""
          text="There are no data in Medications"
        />
      )}
    </>
  );
};
