import { useCGMReportLoadedResponse } from "../../../../context/cgm-report-loaded-response-context";
import {
  CGMReportResponseTimeRangesDetailsContainer,
  CGMReportResponseTimeRangesDetailsItem,
} from "./styled-cgm-report-response-time-ranges-details";

export const CGMReportResponseTimeRangesDetails = () => {
  const {
    veryHighPercentage,
    highPercentage,
    targetPercentage,
    lowPercentage,
    veryLowPercentage,
  } = useCGMReportLoadedResponse();

  return (
    <CGMReportResponseTimeRangesDetailsContainer>
      <CGMReportResponseTimeRangesDetailsItem
        $height={`${veryHighPercentage}%`}
      >
        <p>
          Very High <span>{`>`}250 mg/dl</span>
        </p>
        <strong>{veryHighPercentage}%</strong>
      </CGMReportResponseTimeRangesDetailsItem>
      <CGMReportResponseTimeRangesDetailsItem $height={`${highPercentage}%`}>
        <p>
          High <span>181 - 250 mg/dl</span>
        </p>
        <strong>{highPercentage}%</strong>
      </CGMReportResponseTimeRangesDetailsItem>
      <CGMReportResponseTimeRangesDetailsItem $height={`${targetPercentage}%`}>
        <p>
          Target <span>70 - 180 mg/dl</span>
        </p>
        <strong>{targetPercentage}%</strong>
      </CGMReportResponseTimeRangesDetailsItem>
      <CGMReportResponseTimeRangesDetailsItem $height={`${lowPercentage}%`}>
        <p>
          Low <span>54 - 69 mg/dl</span>
        </p>
        <strong>{lowPercentage}%</strong>
      </CGMReportResponseTimeRangesDetailsItem>
      <CGMReportResponseTimeRangesDetailsItem $height={`${veryLowPercentage}%`}>
        <p>
          Very Low <span>{`<`} 54</span>
        </p>
        <strong>{veryLowPercentage}%</strong>
      </CGMReportResponseTimeRangesDetailsItem>
    </CGMReportResponseTimeRangesDetailsContainer>
  );
};
