import { PATIENTS_PATH } from "../constants/paths";
import { useClient } from "../context/client-context";
import { useCallback } from "react";
import { PatientCGMEntryDTO } from "../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { Source } from "../models/patient-cgm-entry-dtos/patient-cgm-entry-source";

export function useGetPatientCGMEntries(): (
  patientId: number,
  source?: Source,
  startDate?: string,
  endDate?: string
) => Promise<PatientCGMEntryDTO[]> {
  const client = useClient();

  return useCallback(
    async (patientId, source, startDate = undefined, endDate = undefined) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientCGMEntryDTO[]>(
        `${PATIENTS_PATH}/${patientId}/cgm-entries`,
        { params: { source, startDate, endDate } }
      );

      return response.data;
    },
    [client]
  );
}
