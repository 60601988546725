export const PATIENT_PLAN_FEEDBACK_TYPES = [
  "BloodGlucose",
  "MealGraph",
  "Weight",
  "Custom",
  "BloodGlucoseManual",
] as const;

export type PatientPlanFeedbackType =
  typeof PATIENT_PLAN_FEEDBACK_TYPES[number];

export const PATIENT_PLAN_FEEDBACK_TONES = [
  "Celebration",
  "Warning",
  "Neutral",
] as const;

export type PatientPlanFeedbackTone =
  typeof PATIENT_PLAN_FEEDBACK_TONES[number];
