import styled from "styled-components";
import { flexCenter } from "../../../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../../../styles/global-style";

export const MealsAnalysisResponseHeaderTagsList = styled.ul`
  ${flexCenter}
  gap: 0px 8px;
`;

export const MealsAnalysisResponseHeaderTagsListItem = styled.li<{
  $active: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 100px;
  min-height: 35px;
  min-width: 60px;
  text-align: center;
  border: 1px solid
    ${({ theme, $active }) =>
      $active ? "transparent" : theme.colors.primaryColorAlt};
  background: ${({ $active, theme }) =>
    $active ? theme.colors.primaryColorAlt : "transparent"};
  color: ${({ $active, theme }) =>
    $active ? theme.colors.buttonColor : theme.colors.primaryColorAlt};
  transition: ${transition};

  :hover {
    border: 1px solid "transparent";
    background: ${({ theme }) => theme.colors.primaryColorAlt};
    color: ${({ theme }) => theme.colors.buttonColor};
  }
`;
