import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  mediumSemiBoldFont,
  regularSemiBoldFont,
} from "../../../../../../../../styles/classes/gloabl-classes";
import { DropdownListItem } from "../../../../../../../../components/dropdown/styled-dropdown";

export const CarbCounterMealAddFoodModalBodyFoodResponseFoodName = styled.p`
  ${regularSemiBoldFont}
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const CarbCounterMealAddFoodModalBodyFoodResponseFoodMeassures = styled.div`
  ${flexCenterSpaceBetween}
`;

export const CarbCounterMealAddFoodModalBodyFoodResponseDropdownContainer = styled.div`
  width: 50%;

  ${DropdownListItem} {
    span {
      color: ${({ theme }) => theme.newColors.byOtida};
      ${mediumSemiBoldFont}
    }

    :hover {
      span {
        color: ${({ theme }) => theme.colors.buttonColor};
      }
    }
  }
`;
