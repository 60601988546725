import { useFormContext } from "react-hook-form";
import { Loader } from "../../../../components/loaders/otida-loader/styled-otida-loader";
import { Button } from "../../../../styles/classes/reusable-classes";
import { useImageUploadHelpers } from "../../components/image-upload/context/image-upload-helpers-context";
import { CreateLessonInputs } from "../../content-course-module-lessons/create-lesson/create-lesson";
import {
  ContentModulesFormHeaderContainer,
  ContentModulesFormHeaderButtonContainer,
  ContentModulesFormHeaderTitle,
} from "../../styles/content-modules-form-controllers/content-modules-form-controllers-css";

export const CreateCourseHeader = () => {
  const { loading } = useImageUploadHelpers();

  const { watch } = useFormContext<CreateLessonInputs>();
  const courseNameWatcher = watch("name");

  return (
    <ContentModulesFormHeaderContainer>
      <ContentModulesFormHeaderTitle>
        <p dir="auto">
          <span>Course</span>
          {courseNameWatcher !== undefined && courseNameWatcher !== "" && (
            <span dir="auto">{courseNameWatcher}</span>
          )}
        </p>
      </ContentModulesFormHeaderTitle>
      <ContentModulesFormHeaderButtonContainer>
        <Button type="submit" disabled={loading}>
          {loading ? <Loader radius={20} /> : `Save`}
        </Button>
      </ContentModulesFormHeaderButtonContainer>
    </ContentModulesFormHeaderContainer>
  );
};
