import { Modal } from "../../../../components/modal/modal";
import { useModalHelpers } from "../../../../components/modal/use-modal-helpers";
import { PatientLabTestResultDTO } from "../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import { LabTestResultModal } from "../lab-test-result-modal/lab-test-result-modal";

type EditLabResultProps = {
  labTest: PatientLabTestResultDTO[];
};

export const EditLabResult = (props: EditLabResultProps) => {
  const { labTest } = props;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <>
      <img
        src="/img/edit.svg"
        alt="Edit Icon"
        className="cursor-pointer"
        onClick={openModal}
        title="edit"
      />
      <Modal showModal={isModalOpen} handleHideModal={closeModal}>
        <LabTestResultModal
          key={modalBodyKey}
          onClose={closeModal}
          mode={{
            kind: "edit",
            labTest,
          }}
        />
      </Modal>
    </>
  );
};
