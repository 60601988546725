import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { PatientMealTag } from "../../../../../../models/patient-meal-dtos/patient-meal-tag";

type MealsAnalysisResponseTags = {
  insulinTag?: PatientMealTag;
  handleInsulinTag: (tag?: PatientMealTag) => void;
};

const MealsAnalysisResponseTagsContext = createContext<
  MealsAnalysisResponseTags | undefined
>(undefined);

type MealsAnalysisResponseTagsProviderProps = PropsWithChildren<{}>;

export function MealsAnalysisResponseTagsProvider(
  props: MealsAnalysisResponseTagsProviderProps
) {
  const { children } = props;

  const [insulinTag, setInsulinTag] = useState<PatientMealTag>();

  const handleInsulinTag = useCallback((tag?: PatientMealTag) => {
    setInsulinTag(tag);
  }, []);

  const value = useMemo(
    () => ({
      insulinTag,
      handleInsulinTag,
    }),
    [insulinTag, handleInsulinTag]
  );

  return (
    <MealsAnalysisResponseTagsContext.Provider value={value}>
      {children}
    </MealsAnalysisResponseTagsContext.Provider>
  );
}

export function useMealsAnalysisResponseTags(): MealsAnalysisResponseTags {
  const MealsAnalysisResponseTags = useContext(
    MealsAnalysisResponseTagsContext
  );

  if (MealsAnalysisResponseTags === undefined) {
    throw new Error(
      `useMealsAnalysisResponseTags must be used within MealsAnalysisResponseTagsProvider`
    );
  }

  return MealsAnalysisResponseTags;
}
