import { usePatientUser } from "../../../../../../../context/auth-context/auth-context";
import { BgmChart } from "../../../../../../../components/bgm-chart/bgm-chart";
import {
  VeiwPlansManualReadingsChartLegendContainer,
  VeiwPlansManualReadingsChartLegendItem,
  VeiwPlansManualReadingsChartLegendPoint,
  VeiwPlansManualReadingsChartLegendText,
} from "./styled-view-plans-manaul-readings-chart";

type VeiwPlansManualReadingsChartProps = {
  startDate: string;
  endDate: string;
  tagValue: string;
};

export const VeiwPlansManualReadingsChart = (
  props: VeiwPlansManualReadingsChartProps
) => {
  const { startDate, endDate, tagValue } = props;

  const { patientType, patientId } = usePatientUser();

  return (
    <div>
      <VeiwPlansManualReadingsChartLegendContainer>
        <VeiwPlansManualReadingsChartLegendItem>
          <VeiwPlansManualReadingsChartLegendPoint inRange={false} />
          <VeiwPlansManualReadingsChartLegendText>
            خارج المدي
          </VeiwPlansManualReadingsChartLegendText>
        </VeiwPlansManualReadingsChartLegendItem>
        <VeiwPlansManualReadingsChartLegendItem>
          <VeiwPlansManualReadingsChartLegendPoint inRange />
          <VeiwPlansManualReadingsChartLegendText>
            في المدى
          </VeiwPlansManualReadingsChartLegendText>
        </VeiwPlansManualReadingsChartLegendItem>
      </VeiwPlansManualReadingsChartLegendContainer>
      <BgmChart
        startDate={startDate}
        endDate={endDate}
        tagValue={tagValue}
        containerHeight="350px"
        patientType={patientType}
        hasTooltip={false}
        patientPlansPatientId={patientId}
        fullWidth
      />
    </div>
  );
};
