import {
  StepsInfoContainer,
  StepsInfoIconContainer,
  StepsInfoTextContainer,
} from "./styled-plan-step-info";

type PlanStepInfoProps = {
  icon: string;
  title: string;
  subTitle: string;
  arabicFlex?: boolean;
};

export const PlanStepInfo = (props: PlanStepInfoProps) => {
  const { icon, title, subTitle, arabicFlex } = props;

  return (
    <StepsInfoContainer arabicFlex={arabicFlex}>
      <StepsInfoIconContainer arabicFlex={arabicFlex}>
        <img src={icon} alt={`${icon} icon`} />
      </StepsInfoIconContainer>
      <StepsInfoTextContainer arabicFlex={arabicFlex}>
        <p>{title}</p>
        <span>{subTitle}</span>
      </StepsInfoTextContainer>
    </StepsInfoContainer>
  );
};
