import { useEffect, useState } from "react";
import { MessageResponse } from "stream-chat";
import { Avatar, useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import {
  MessagingMediaSidebarPinnedMessagesListList,
  MessagingMediaSidebarPinnedMessagesListListItem,
  MessagingMediaSidebarPinnedMessagesUserInfo,
  MessagingMediaSidebarPinnedMessagesListListItemTopContainer,
  MessagingMediaSidebarPinnedMessagesDate,
} from "./styled-messaging-media-sidebar-pinned-messages-list";
import { MessagingMediaSidebarCustomMessage } from "../messaging-media-sidebar-custom-message/messaging-media-sidebar-custom-message";
import { EmptyState } from "../../../../../../../components/empty-state/empty-state";

export const MessagingMediaSidebarPinnedMessagesList = () => {
  const { channel, client } = useChatContext();

  const [pinnedMessagesState, setPinnedMessagesState] =
    useState<MessageResponse<DefaultStreamChatGenerics>[]>();

  useEffect(() => {
    const getPinnedMessages = async () => {
      const channelState = await client
        .channel("messaging", channel?.id)
        .query({});

      const pinnedMessages = channelState.pinned_messages;

      return pinnedMessages;
    };

    getPinnedMessages().then((res) => setPinnedMessagesState(res));
  }, [channel?.id, client, channel?.state.pinnedMessages.length]);

  if (pinnedMessagesState === undefined || pinnedMessagesState?.length === 0) {
    return (
      <EmptyState
        hasSearch={false}
        text="No Pinned Messages Yet"
        title=""
        hasHeader={false}
        button={<></>}
      />
    );
  }

  return (
    <MessagingMediaSidebarPinnedMessagesListList>
      {pinnedMessagesState &&
        pinnedMessagesState
          .filter(
            (pinnedMessage) => pinnedMessage.pinned_by?.id === client.userID
          )
          .map((pinnedMessage) => (
            <MessagingMediaSidebarPinnedMessagesListListItem
              key={pinnedMessage.id}
            >
              <MessagingMediaSidebarPinnedMessagesListListItemTopContainer>
                <MessagingMediaSidebarPinnedMessagesUserInfo>
                  <Avatar
                    image={
                      pinnedMessage.user?.image !== undefined
                        ? pinnedMessage.user?.image
                        : "/img/chat/user.svg"
                    }
                    size={20}
                  />
                  <p>
                    {pinnedMessage.user?.name !== undefined
                      ? pinnedMessage.user.name
                      : pinnedMessage.user_id || ""}
                  </p>
                </MessagingMediaSidebarPinnedMessagesUserInfo>
                <MessagingMediaSidebarPinnedMessagesDate>
                  {pinnedMessage.created_at !== undefined
                    ? new Date(pinnedMessage.created_at).toLocaleDateString(
                        "en-AU",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        }
                      )
                    : ""}
                </MessagingMediaSidebarPinnedMessagesDate>
              </MessagingMediaSidebarPinnedMessagesListListItemTopContainer>
              <MessagingMediaSidebarCustomMessage message={pinnedMessage} />
            </MessagingMediaSidebarPinnedMessagesListListItem>
          ))}
    </MessagingMediaSidebarPinnedMessagesListList>
  );
};
