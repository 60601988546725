import { Modal } from "../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { VideoModalBody } from "./video-modal-body/video-modal-body";

type VideoModalProps = {
  videoURL: string;
  viewPlansModal?: boolean;
};

export const VideoModal = (props: VideoModalProps) => {
  const { videoURL, viewPlansModal } = props;
  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  return (
    <>
      <button onClick={openModal}>
        <span className="material-symbols-outlined">play_circle</span>
        Video
      </button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <VideoModalBody
          key={modalBodyKey}
          onClose={closeModal}
          videoURL={videoURL}
          viewPlansModal={viewPlansModal}
        />
      </Modal>
    </>
  );
};
