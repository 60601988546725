import { PatientDTO } from "../../models/patient-dtos/patient-dto";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";

export function useGetPatients(): (
  includeNotFinishedLoggingMealsCount?: boolean
) => Promise<PatientDTO[]> {
  const client = useClient();

  return useCallback(
    async (includeNotFinishedLoggingMealsCount = undefined) => {
      const response = await client.get<PatientDTO[]>(PATIENTS_PATH, {
        params: {
          includeNotFinishedLoggingMealsCount,
        },
      });

      return response.data;
    },
    [client]
  );
}
