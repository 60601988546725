import { ChannelFilters, ChannelOptions, ChannelSort } from "stream-chat";
import { useChatTokenContext } from "../chat-providers/chat-token-context";

export const useSetChannelOptions = () => {
  const { chatTokenDTO } = useChatTokenContext();

  const channelFilters: ChannelFilters = {
    type: "messaging",
    members: { $in: [chatTokenDTO.chatTokenDTO.streamChatUserId] },
  };

  const channelOptions: ChannelOptions = {
    state: true,
    presence: true,
    limit: 10,
  };

  const channelSort: ChannelSort = {
    last_message_at: -1,
    has_unread: 1,
    unread_count: 1,
  };

  return { channelFilters, channelOptions, channelSort };
};
