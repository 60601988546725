import { Controller, useFormContext } from "react-hook-form";
import { ContentModuleUpdateModeInputs } from "../../../context/content-module-mode-context";
import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../styles/classes/reusable-classes";
import { ContentModuleStatusAndOrderControls } from "../../../styles/content-modules-form-controllers/content-modules-form-controllers-css";
import { UpdateContentModuleStatusController } from "./update-content-module-status-controller";
import { UpdateContentModuleOrderController } from "./update-content-module-order-controller";
import { ContentModuleDTO } from "../../../../../models/content-module-dtos/content-module-dto";

type UpdateContentModuleNameControllerProps = {
  contentModuleDTO: ContentModuleDTO;
};

export const UpdateContentModuleNameController = (
  props: UpdateContentModuleNameControllerProps
) => {
  const { contentModuleDTO } = props;

  const { contentModuleType } = contentModuleDTO;
  const { contentModuleCards } = contentModuleDTO;

  const {
    control,
    formState: { errors },
  } = useFormContext<ContentModuleUpdateModeInputs>();

  return (
    <Controller
      control={control}
      name="name"
      rules={{ required: true }}
      render={({ field: { value, onChange } }) => (
        <UXInputContainer>
          <UXInputLabel $width="150px">{contentModuleType} Name</UXInputLabel>
          <UXInputWrapper
            className="content-module-name-input-wrapper"
            $errorState={errors.name?.type === "required"}
            $width="80%"
          >
            <UXInputInput
              width={"100%"}
              dir="auto"
              type="text"
              placeholder={`Enter ${contentModuleType} Name`}
              value={value === undefined ? "" : value}
              onChange={(e) =>
                onChange(e.target.value === "" ? "" : e.target.value)
              }
            />
            <ContentModuleStatusAndOrderControls>
              <UpdateContentModuleStatusController
                contentModuleType={contentModuleDTO.contentModuleType}
                isDisabled={
                  contentModuleType === "Lesson" &&
                  contentModuleCards.length === 0
                }
              />
              {contentModuleType !== "Course" && (
                <UpdateContentModuleOrderController />
              )}
            </ContentModuleStatusAndOrderControls>
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
