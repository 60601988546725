import styled from "styled-components/macro";
import {
  flexCenter,
  mediumFont,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const PatientProfileMedicalHistoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin: 16px 24px;
`;

export const PatientProfileMedicalHistoryItemContainer = styled.div`
  padding: 12px 16px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 12px;

  :nth-child(5) {
    grid-column: span 2;
  }
`;

export const PatientProfileMedicalHistoryItemTitleContainer = styled.div`
  ${flexCenter}
  gap: 0px 12px;

  p {
    margin: 0px;
    ${mediumFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const PatientProfileMedicalHistoryItemTextContainer = styled.div`
  margin-top: 8px;

  p {
    margin: 0px;
    color: ${({ theme }) => theme.colors.titleColor};
    ${regularMediumFont}
  }
`;
