import {
  CircleCharts,
  ChartCard,
  ChartCardHeader,
  ChartCardTitle,
  ChartContainer,
  DailyChartsContainer,
  DailyChartsDate,
  LineChartContainer,
  ChartContainerLegend,
  ChartLegendPoint,
} from "./styled-daily-chart";
import { DailyChartRanges } from "./daily-chart-ranges/daily-chart-ranges";
import { NutritionSummary } from "./nutrition-summary/nutrition-summary";
import { NutritionSummaryForDemo } from "./nutrition-summary-for-demo/nutrition-summary-for-demo";
import { DayAggregate } from "../../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { useBglAnalysis } from "../../../context/loadable-bgl-analysis-context";
import {
  FEATURE_FLAG_NUTRITION_SUMMARY_FOR_DEMO,
  FEATURE_FLAG_NUTRITION_SUMMARY_FROM_MEALS,
  FEATURE_FLAG_REPLACE_GOOGLE_CHART_WITH_SYNCFUSION,
} from "../../../../../../constants/config";
import { CgmSyncfusionChart } from "../../../../../../components/cgm-syncfusion-chart/cgm-syncfusion-chart";
import { CgmGoogleChart } from "../../../../../../components/cgm-google-chart/cgm-google-chart";
import { useBuildDayAggregates } from "../../../../../../components/cgm-google-chart/hooks/use-build-day-aggregates";
import { OtidaLoader } from "../../../../../../components/loaders/otida-loader/otida-loader";
import { useMemo } from "react";
import { calculateInRangePercentage } from "../../../../../../utils/glucose-point-utils/glucose-point-range-utils/glucose-range-percentages-utils/calculate-in-range-percentage";
import { calculateBelowRangePercentage } from "../../../../../../utils/glucose-point-utils/glucose-point-range-utils/glucose-range-percentages-utils/calculate-below-range-percentage";
import { calculateAboveRangePercentage } from "../../../../../../utils/glucose-point-utils/glucose-point-range-utils/glucose-range-percentages-utils/calculate-above-range-percentage";
import { NutritionSummaryFromMeals } from "./nutrition-summary-from-meals/nutrition-summary-from-meals";

export type DailyChartProps = {
  dayAggregate: DayAggregate;
  zIndex: number;
};

export function DailyChart(props: DailyChartProps) {
  const { dayAggregate, zIndex } = props;

  const { patientDTO } = useBglAnalysis();

  const { isLoading } = useBuildDayAggregates(patientDTO);

  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const displayDate = `${dayAggregate.date.toLocaleString(
    "en-US",
    dateOptions
  )}`;

  const cgmReadings = dayAggregate.readings.filter(
    (reading) => reading.source === "Libreview"
  );

  const belowRangePercentage = useMemo(
    () => calculateBelowRangePercentage(cgmReadings),
    [cgmReadings]
  );

  const inRangePercentage = useMemo(
    () => calculateInRangePercentage(patientDTO.type, cgmReadings),
    [patientDTO.type, cgmReadings]
  );

  const aboveRangePercentage = useMemo(
    () => calculateAboveRangePercentage(patientDTO.type, cgmReadings),
    [patientDTO.type, cgmReadings]
  );

  if (dayAggregate.date.toString() === "2024-05-21" && patientDTO.id === 130) {
    return <></>;
  }

  return (
    <ChartCard>
      <DailyChartsDate>
        <span className="material-symbols-outlined">calendar_today</span>
        <p>{displayDate}</p>
      </DailyChartsDate>

      <DailyChartsContainer>
        <ChartContainer>
          <ChartCardHeader>
            <ChartCardTitle>
              <p>Daily graph</p>
              <span>Blood glucose reading</span>
            </ChartCardTitle>
            <CircleCharts>
              {cgmReadings.length > 0 && (
                <DailyChartRanges
                  smallCircles={false}
                  rangesCounts={{
                    above: aboveRangePercentage,
                    inRange: inRangePercentage,
                    below: belowRangePercentage,
                  }}
                />
              )}
            </CircleCharts>
          </ChartCardHeader>
          <LineChartContainer>
            {isLoading ? (
              <OtidaLoader />
            ) : (
              <>
                {FEATURE_FLAG_REPLACE_GOOGLE_CHART_WITH_SYNCFUSION ? (
                  <CgmSyncfusionChart
                    patientDTO={patientDTO}
                    dayAggregate={dayAggregate}
                    zindex={zIndex}
                    hasTooltip
                    enableLegend={false}
                  />
                ) : (
                  <CgmGoogleChart
                    patientDTO={patientDTO}
                    dayAggregate={dayAggregate}
                  />
                )}
              </>
            )}
          </LineChartContainer>
          <ChartContainerLegend>
            {FEATURE_FLAG_REPLACE_GOOGLE_CHART_WITH_SYNCFUSION ? (
              <ChartLegendPoint>
                <img src="/img/meals.svg" alt="meals" />
                <span>Meals</span>
              </ChartLegendPoint>
            ) : (
              <ChartLegendPoint>
                <div />
                <span>Meals</span>
              </ChartLegendPoint>
            )}
            {FEATURE_FLAG_REPLACE_GOOGLE_CHART_WITH_SYNCFUSION ? (
              <ChartLegendPoint medic>
                <img src="/img/medications.svg" alt="medications" />
                <span>Medications</span>
              </ChartLegendPoint>
            ) : (
              <ChartLegendPoint medic>
                <div />
                <span>Medications</span>
              </ChartLegendPoint>
            )}
            {FEATURE_FLAG_REPLACE_GOOGLE_CHART_WITH_SYNCFUSION ? (
              <ChartLegendPoint manual>
                <img src="/img/readings.svg" alt="readings" />
                <span>Manual Reading</span>
              </ChartLegendPoint>
            ) : (
              <ChartLegendPoint manual>
                <div />
                <span>Manual Reading</span>
              </ChartLegendPoint>
            )}
            {FEATURE_FLAG_REPLACE_GOOGLE_CHART_WITH_SYNCFUSION ? (
              <ChartLegendPoint activity>
                <img src="/img/activity.svg" alt="activity" />
                <span>Activity</span>
              </ChartLegendPoint>
            ) : (
              <ChartLegendPoint activity>
                <div />
                <span>Activity</span>
              </ChartLegendPoint>
            )}
            {FEATURE_FLAG_REPLACE_GOOGLE_CHART_WITH_SYNCFUSION ? (
              <ChartLegendPoint insulin>
                <img src="/img/insulin.svg" alt="insulin" />
                <span>Insulin</span>
              </ChartLegendPoint>
            ) : (
              <ChartLegendPoint insulin>
                <div />
                <span>Insulin</span>
              </ChartLegendPoint>
            )}
          </ChartContainerLegend>
        </ChartContainer>
        {FEATURE_FLAG_NUTRITION_SUMMARY_FROM_MEALS ? (
          <NutritionSummaryFromMeals
            patientMealDTOs={dayAggregate.patientMealDTO}
          />
        ) : FEATURE_FLAG_NUTRITION_SUMMARY_FOR_DEMO ? (
          <NutritionSummaryForDemo date={dayAggregate.date} />
        ) : (
          <NutritionSummary date={dayAggregate.date} />
        )}
      </DailyChartsContainer>
    </ChartCard>
  );
}
