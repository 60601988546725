import { Controller, useFormContext } from "react-hook-form";
import { ContentModuleType } from "../../../../../models/content-module-dtos/content-module-type";
import { ContentModuleUpdateModeInputs } from "../../../context/content-module-mode-context";
import {
  UXInputContainer,
  UXInputLabel,
  UXInputTextarea,
  UXInputWrapper,
} from "../../../../../styles/classes/reusable-classes";

type UpdateContentModuleObjectiveControllerProps = {
  contentModuleType: ContentModuleType;
};

export const UpdateContentModuleObjectiveController = (
  props: UpdateContentModuleObjectiveControllerProps
) => {
  const { contentModuleType } = props;

  const { control } = useFormContext<ContentModuleUpdateModeInputs>();

  return (
    <Controller
      control={control}
      name="objective"
      render={({ field: { value, onChange } }) => (
        <UXInputContainer $textareaDimentions>
          <UXInputLabel $width="150px" $isOptional>
            {contentModuleType} Objective <span>(Optional)</span>
          </UXInputLabel>
          <UXInputWrapper $width="80%" $textareaDimentions>
            <UXInputTextarea
              dir="auto"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder={`Enter ${contentModuleType} Objective (Optional)`}
            />
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
