import { useFormContext } from "react-hook-form";
import { FEATURE_FLAG_FOOD_LOGGING_NEW_UI } from "../../../constants/config";
import { useDirtyMeal } from "../context/dirty-meal-context";
import { FoodLoggingSelectedPatientMealInputs } from "./food-logging-selected-patient-meal";
import { useEffect } from "react";
import { FoodLoggingPatientsTab } from "../food-logging-patients/food-logging-patients-tabs/food-logging-patients-tabs";
import { PatientMealDTO } from "../../../models/patient-meal-dtos/patient-meal-dto";
import { LegacyFoodLoggingSelectedPatientMeal } from "./legacy-food-logging-selected-patient-meal/legacy-food-logging-selected-patient-meal";
import { isPatientMealDTOLegacy } from "./utils/food-logging-selected-patient-meal-utils";
import { CarbCounterFoodLoggingSelectedPatientMeal } from "./carb-counter-food-logging-selected-patient-meal/carb-counter-food-logging-selected-patient-meal";
import { CarbCounterMealStateProvider } from "./carb-counter-food-logging-selected-patient-meal/context/carb-counter-meal-state-context";

type FoodLoggingSelectedPatientMealFormContextChildrenProps = {
  patientMealDTO: PatientMealDTO;
  selectedTab: FoodLoggingPatientsTab;
};

export const FoodLoggingSelectedPatientMealFormContextChildren = (
  props: FoodLoggingSelectedPatientMealFormContextChildrenProps
) => {
  const { patientMealDTO, selectedTab } = props;

  const { setDirty } = useDirtyMeal();

  const {
    reset,
    formState: { isDirty, isSubmitted },
  } = useFormContext<FoodLoggingSelectedPatientMealInputs>();

  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty, setDirty]);

  // clean up dirty state when submitting form
  useEffect(() => {
    if (isSubmitted) {
      reset(undefined, { keepValues: true, keepDirty: false });
    }
  }, [isSubmitted, reset]);

  // clean up dirty state when unmounting
  useEffect(() => {
    return () => {
      setDirty(false);
    };
  }, [setDirty]);

  if (FEATURE_FLAG_FOOD_LOGGING_NEW_UI) {
    if (isPatientMealDTOLegacy(patientMealDTO)) {
      return (
        <LegacyFoodLoggingSelectedPatientMeal
          patientMealDTO={patientMealDTO}
          selectedTab={selectedTab}
        />
      );
    } else {
      return (
        <CarbCounterMealStateProvider patientMealDTO={patientMealDTO}>
          <CarbCounterFoodLoggingSelectedPatientMeal
            patientMealDTO={patientMealDTO}
            selectedTab={selectedTab}
          />
        </CarbCounterMealStateProvider>
      );
    }
  } else {
    return (
      <LegacyFoodLoggingSelectedPatientMeal
        patientMealDTO={patientMealDTO}
        selectedTab={selectedTab}
      />
    );
  }
};
