import { Temporal } from "temporal-polyfill";
import { PatientDTO } from "../../../../models/patient-dtos/patient-dto";
import { roundTo3DecimalPlaces } from "../../../../utils/math-utils";
import { calculateBMI } from "../../single-patient-sidebar/patient-sidebar-tabs/personal-info-tab/body-measurments/body-measurments-utils";
import { PatientWeightDTO } from "../../../../models/patient-weight-dtos/patient-weight-dto";
import { PatientBloodPressureDTO } from "../../../../models/patient-blood-pressure-dtos/patient-blood-pressure-dto";
import { Emergency } from "../../../../models/emergency";
import { NonBariatricSurgery, Surgery } from "../../../../models/surgery";
import { ChronicDisease } from "../../../../models/chronic-disease";
import { FamilyMemberDisease } from "../../../../models/family-member-disease";

function calculateAge(dateString: string) {
  const today = Temporal.Now.plainDateISO();
  const birthDate = Temporal.PlainDate.from(dateString);
  let age = today.year - birthDate.year;
  let month = today.month - birthDate.month;

  if (month < 0 || (month === 0 && today.day < birthDate.day)) {
    age--;
  }

  return age;
}

type PatientInfo = {
  id: number;
  title: string;
  icon: string;
}[];

export function buildPatientInfo(patientDTO: PatientDTO): PatientInfo {
  const patientSex = patientDTO.sex !== undefined ? patientDTO.sex : "Unknown";
  const patientJob = patientDTO.job !== undefined ? patientDTO.job : "Unknown";
  const patientDateOfBirth =
    patientDTO.dateOfBirth !== undefined ? patientDTO.dateOfBirth : "Unknown";
  const patientAge =
    patientDateOfBirth !== "Unknown"
      ? calculateAge(patientDateOfBirth)
      : "Unknown";

  const patientAddress =
    patientDTO.address !== undefined ? patientDTO.address : "Unknown";

  const patientInfo: PatientInfo = [
    {
      id: 0,
      title: patientSex,
      icon: "person",
    },
    {
      id: 1,
      title: patientAddress,
      icon: "location_on",
    },
    {
      id: 2,
      title: patientJob === "" ? "Unknown" : patientJob,
      icon: "business_center",
    },
    {
      id: 3,
      title: `${
        patientDateOfBirth !== "Unknown"
          ? Temporal.PlainDate.from(patientDateOfBirth).toLocaleString(
              "en-UK",
              {
                day: "numeric",
                month: "short",
                year: "numeric",
              }
            )
          : ""
      } (${patientAge} years)`,
      icon: "event",
    },
    {
      id: 4,
      title: `${
        patientDTO.maritalStatusId !== undefined
          ? patientDTO.maritalStatusId
          : ""
      }`,
      icon: "favorite_border",
    },
    {
      id: 5,
      title: `${
        patientDTO.whatsAppId !== undefined ? patientDTO.whatsAppId : "Unknown"
      }`,
      icon: "whats-app",
    },
    {
      id: 6,
      title: `${
        patientDTO.activationDate !== undefined
          ? `active scince: ${patientDTO.activationDate}`
          : "Unknown"
      }`,
      icon: "calendar_today",
    },
  ];

  return patientInfo;
}

type HeaderBottomCards = {
  id: number;
  value?: number | string;
  bloodPressureValue?: {
    systolic: number;
    diastolic: number;
  };
  unit?: string;
  name: string;
  successProgress?: boolean;
  progressValue?: number;
}[];

export function buildPatientProfileHeaderBottomCards(
  patientDTO: PatientDTO,
  patientBloodPressureDTOs: PatientBloodPressureDTO[],
  patientWeightDTOs: PatientWeightDTO[]
): HeaderBottomCards {
  const latestPatientBloodPressureDTO =
    patientBloodPressureDTOs.length > 0
      ? patientBloodPressureDTOs[patientBloodPressureDTOs.length - 1]
      : undefined;

  // sort patientWeightDTOs by date#
  const sortedPatientWeightDTOs = patientWeightDTOs.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA.getTime() - dateB.getTime();
  });

  const latestPatientWeightDTO =
    sortedPatientWeightDTOs.length > 0
      ? sortedPatientWeightDTOs[sortedPatientWeightDTOs.length - 1]
      : undefined;
  const beforeLatestPatientWeightDTO =
    patientWeightDTOs.length > 0
      ? patientWeightDTOs[patientWeightDTOs.length - 2]
      : undefined;

  const isPatientWeightSuccessProgress = patientWeightDTOs.every(() => {
    if (
      latestPatientWeightDTO !== undefined &&
      beforeLatestPatientWeightDTO !== undefined
    ) {
      if (
        latestPatientWeightDTO.weightKg < beforeLatestPatientWeightDTO.weightKg
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const weightProgressValue =
    isPatientWeightSuccessProgress &&
    latestPatientWeightDTO !== undefined &&
    beforeLatestPatientWeightDTO !== undefined
      ? beforeLatestPatientWeightDTO!.weightKg -
        latestPatientWeightDTO!.weightKg
      : !isPatientWeightSuccessProgress &&
        latestPatientWeightDTO !== undefined &&
        beforeLatestPatientWeightDTO !== undefined
      ? latestPatientWeightDTO!.weightKg -
        beforeLatestPatientWeightDTO!.weightKg
      : 0;

  const patientHeight =
    patientDTO.heightCm !== undefined ? patientDTO.heightCm : 0;

  const latestBMI = calculateBMI(
    patientHeight,
    latestPatientWeightDTO !== undefined ? latestPatientWeightDTO.weightKg : 0
  );

  const beforeLatestBMI = calculateBMI(
    patientHeight,
    beforeLatestPatientWeightDTO !== undefined
      ? beforeLatestPatientWeightDTO.weightKg
      : 0
  );

  const isPatientBMISuccessProgress = patientWeightDTOs.every(() => {
    if (Number(latestBMI) < Number(beforeLatestBMI)) {
      return true;
    } else {
      return false;
    }
  });

  const bmiProgressValue = isPatientBMISuccessProgress
    ? Number(beforeLatestBMI) - Number(latestBMI)
    : Number(latestBMI) - Number(beforeLatestBMI);

  const bmiValue =
    patientHeight !== undefined &&
    latestPatientWeightDTO !== undefined &&
    latestPatientWeightDTO.weightKg !== 0 &&
    patientHeight !== 0
      ? roundTo3DecimalPlaces(Number(latestBMI))
      : "--";

  const headerBottomCards: HeaderBottomCards = [
    {
      id: 0,
      value: bmiValue,
      bloodPressureValue: undefined,
      unit: undefined,
      name: "BMI",
      successProgress: isPatientBMISuccessProgress,
      progressValue:
        bmiValue !== "--"
          ? roundTo3DecimalPlaces(bmiProgressValue)
          : Number(""),
    },
    {
      id: 1,
      value:
        latestPatientWeightDTO !== undefined
          ? latestPatientWeightDTO.weightKg
          : 0,
      bloodPressureValue: undefined,
      unit: "kg",
      name: "Weight",
      successProgress: isPatientWeightSuccessProgress,
      progressValue: roundTo3DecimalPlaces(weightProgressValue),
    },
    {
      id: 2,
      value: patientDTO.heightCm !== undefined ? patientDTO.heightCm : "--",
      bloodPressureValue: undefined,
      unit: "cm",
      name: "Height",
      successProgress: undefined,
      progressValue: undefined,
    },
    {
      id: 3,
      value: undefined,
      bloodPressureValue: {
        systolic:
          latestPatientBloodPressureDTO !== undefined
            ? latestPatientBloodPressureDTO.systolic
            : 0,
        diastolic:
          latestPatientBloodPressureDTO !== undefined
            ? latestPatientBloodPressureDTO.diastolic
            : 0,
      },
      unit: "mmHg",
      name: "Blood Pressure",
      successProgress: undefined,
      progressValue: undefined,
    },
  ];

  return headerBottomCards;
}

export type DisplayEmergencies =
  | "Diabetic Ketoacidosis"
  | "Angina Pectoris"
  | "Hypoglycemia"
  | "Stroke";

export const viewEmergencies = (emergency: Emergency): DisplayEmergencies => {
  if (emergency === "DiabeticKetoacidosis") {
    return "Diabetic Ketoacidosis";
  } else if (emergency === "AnginaPectoris") {
    return "Angina Pectoris";
  } else {
    return emergency;
  }
};

export const revertViewEmergencies = (
  emergency: DisplayEmergencies
): Emergency => {
  if (emergency === "Diabetic Ketoacidosis") {
    return "DiabeticKetoacidosis";
  } else if (emergency === "Angina Pectoris") {
    return "AnginaPectoris";
  } else {
    return emergency;
  }
};

type DisplaySurgeries =
  | "Gastric Bypass"
  | "Gastric Balloon"
  | "Gastric Ring"
  | "Sleeve Gastrectomy"
  | "Tummy Tuck"
  | "Cholecystectomy"
  | "Cs Section"
  | "Liposuction"
  | "Open Heart"
  | "IhdStents"
  | "Other";

export const viewSurgeries = (surgery: Surgery): DisplaySurgeries => {
  if (surgery === "GastricBypass") {
    return "Gastric Bypass";
  } else if (surgery === "GastricBalloon") {
    return "Gastric Balloon";
  } else if (surgery === "GastricRing") {
    return "Gastric Ring";
  } else if (surgery === "SleeveGastrectomy") {
    return "Sleeve Gastrectomy";
  } else if (surgery === "TummyTuck") {
    return "Tummy Tuck";
  } else if (surgery === "CsSection") {
    return "Cs Section";
  } else if (surgery === "OpenHeart") {
    return "Open Heart";
  } else {
    return surgery;
  }
};

export type DisplayNonBariatricSurgeries =
  | "Cholecystectomy"
  | "Cs Section"
  | "Liposuction"
  | "Open Heart"
  | "Ihd Stents"
  | "Other";

export const viewNonBariatricSurgeries = (
  nonBariatricSurgeries: NonBariatricSurgery
): DisplayNonBariatricSurgeries => {
  if (nonBariatricSurgeries === "CsSection") {
    return "Cs Section";
  } else if (nonBariatricSurgeries === "OpenHeart") {
    return "Open Heart";
  } else if (nonBariatricSurgeries === "IhdStents") {
    return "Ihd Stents";
  } else {
    return nonBariatricSurgeries;
  }
};

export const revertNonBariatricSurgeries = (
  nonBariatricSurgeries: DisplayNonBariatricSurgeries
): NonBariatricSurgery => {
  if (nonBariatricSurgeries === "Cs Section") {
    return "CsSection";
  } else if (nonBariatricSurgeries === "Open Heart") {
    return "OpenHeart";
  } else if (nonBariatricSurgeries === "Ihd Stents") {
    return "IhdStents";
  } else {
    return nonBariatricSurgeries;
  }
};

export type DisplayChronicDisease =
  | "Hypertension"
  | "Chronic Heart Diseases"
  | "Ischemic Heart Disease"
  | "Polycystic Ovary Syndrome"
  | "Dyslipidemia"
  | "Chronic Thyroid Disorder"
  | "Gastrointestinal Disorders"
  | "Kidney Diseases"
  | "Pulmonary Diseases"
  | "Chronic Obstructive Pulmonary Disease"
  | "Cancer or Malignancy"
  | "Osteoporosis"
  | "Respiratory Disease"
  | "Arthritis";

export const viewChronicDisease = (
  chronicDisease: ChronicDisease
): DisplayChronicDisease => {
  if (chronicDisease === "ChronicHeartDiseases") {
    return "Chronic Heart Diseases";
  } else if (chronicDisease === "IschemicHeartDisease") {
    return "Ischemic Heart Disease";
  } else if (chronicDisease === "PolycysticOvarySyndrome") {
    return "Polycystic Ovary Syndrome";
  } else if (chronicDisease === "ChronicThyroidDisorder") {
    return "Chronic Thyroid Disorder";
  } else if (chronicDisease === "GastrointestinalDisorders") {
    return "Gastrointestinal Disorders";
  } else if (chronicDisease === "KidneyDiseases") {
    return "Kidney Diseases";
  } else if (chronicDisease === "PulmonaryDiseases") {
    return "Pulmonary Diseases";
  } else if (chronicDisease === "ChronicObstructivePulmonaryDisease") {
    return "Chronic Obstructive Pulmonary Disease";
  } else if (chronicDisease === "CancerOrMalignancy") {
    return "Cancer or Malignancy";
  } else if (chronicDisease === "RespiratoryDisease") {
    return "Respiratory Disease";
  } else {
    return chronicDisease;
  }
};

export const revertViewChronicDisease = (
  chronicDisease: DisplayChronicDisease
): ChronicDisease => {
  if (chronicDisease === "Chronic Heart Diseases") {
    return "ChronicHeartDiseases";
  } else if (chronicDisease === "Ischemic Heart Disease") {
    return "IschemicHeartDisease";
  } else if (chronicDisease === "Polycystic Ovary Syndrome") {
    return "PolycysticOvarySyndrome";
  } else if (chronicDisease === "Chronic Thyroid Disorder") {
    return "ChronicThyroidDisorder";
  } else if (chronicDisease === "Gastrointestinal Disorders") {
    return "GastrointestinalDisorders";
  } else if (chronicDisease === "Kidney Diseases") {
    return "KidneyDiseases";
  } else if (chronicDisease === "Pulmonary Diseases") {
    return "PulmonaryDiseases";
  } else if (chronicDisease === "Chronic Obstructive Pulmonary Disease") {
    return "ChronicObstructivePulmonaryDisease";
  } else if (chronicDisease === "Cancer or Malignancy") {
    return "CancerOrMalignancy";
  } else if (chronicDisease === "Respiratory Disease") {
    return "RespiratoryDisease";
  } else {
    return chronicDisease;
  }
};

type DisplayFamilyMemberDisease =
  | "Diabetes Type 1"
  | "Diabetes Type 2"
  | "Obesity"
  | "Heart Diseases"
  | "Hypertension"
  | "Other";

export const viewFamilyMemberDisease = (
  familyMemberDisease: FamilyMemberDisease
): DisplayFamilyMemberDisease => {
  if (familyMemberDisease === "DiabetesType1") {
    return "Diabetes Type 1";
  } else if (familyMemberDisease === "DiabetesType2") {
    return "Diabetes Type 2";
  } else if (familyMemberDisease === "HeartDiseases") {
    return "Heart Diseases";
  } else {
    return familyMemberDisease;
  }
};

type BmiIndicatorsType =
  | "Underweight"
  | "Normal"
  | "Overweight"
  | "Obesity 1st Class"
  | "Obesity 2nd Class"
  | "Extreme Obesity 3rd Class"
  | "";

export const displayBMI_Indicators = (bmi: number): BmiIndicatorsType => {
  if (bmi < 18.5) {
    return "Underweight";
  } else if (18.5 < bmi && bmi < 24.9) {
    return "Normal";
  } else if (25 < bmi && bmi < 29.9) {
    return "Overweight";
  } else if (30 < bmi && bmi < 34.9) {
    return "Obesity 1st Class";
  } else if (35 < bmi && bmi < 39.9) {
    return "Obesity 2nd Class";
  } else if (bmi > 40) {
    return "Extreme Obesity 3rd Class";
  } else {
    return "";
  }
};
