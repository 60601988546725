import { v4 as uuidv4 } from "uuid";

/**
 * Instead of using the `uuid` package, we could use this stackoverflow answer instead:
 *
 * https://stackoverflow.com/a/2117523
 */
export function randomUUID() {
  return uuidv4();
}
