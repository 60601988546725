import { PatientLabTestResultDTO } from "../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import {
  LabResultsCardBody,
  LabResultsCardContainer,
  LabResultsCardHeader,
  LabResultsCardIcons,
  LabResultsCardUnit,
  LabResultsCardSubTitle,
  LabResultsCardTextContainer,
  LabResultsCardTitle,
  LabResultsCardRange,
} from "./styled-lab-results-card";
import { LabResultsChart } from "../lab-results-chart/lab-results-chart";
import { displayRef } from "../utils/lab-test-results-utils";
import { EditLabResult } from "../edit-lab-result/edit-lab-result";
import { roundTo3DecimalPlaces } from "../../../../utils/math-utils";
import { LabTestTypeDTO } from "../../../../models/lab-test-types/lab-test-type-dto";
import { ReactNode, useMemo } from "react";
import { Temporal } from "temporal-polyfill";
import { LabTestTypeRefDTO } from "../../../../models/lab-test-type-refs/lab-test-type-ref-dto";

export type LabTestTypeProps = {
  labTestTypeDTO: LabTestTypeDTO;
  labTest: PatientLabTestResultDTO[];
  labTestTypeRefDTOs: LabTestTypeRefDTO[];
  modalCard?: {
    show: boolean;
    button: () => ReactNode;
  };
};

export const LabResultsCard = (props: LabTestTypeProps) => {
  const { labTestTypeDTO, labTest, modalCard, labTestTypeRefDTOs } = props;

  const title = labTestTypeDTO.name;
  const latestResult = labTest[labTest.length - 1];
  const updatedAtDate = Temporal.PlainDate.from(
    latestResult.date
  ).toLocaleString("en-UK", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  const refsDisplays = useMemo(
    () =>
      labTestTypeRefDTOs
        .filter((ref) => ref.labTestTypeId === labTestTypeDTO.id)
        .map((ref) => displayRef(ref)),
    [labTestTypeRefDTOs, labTestTypeDTO.id]
  );

  return (
    <LabResultsCardContainer
      data-testid="lab-results-card"
      modalCard={modalCard?.show}
    >
      <LabResultsCardHeader>
        <LabResultsCardTextContainer>
          <LabResultsCardTitle>{title}</LabResultsCardTitle>
          <LabResultsCardSubTitle>
            updated at {updatedAtDate}
          </LabResultsCardSubTitle>
        </LabResultsCardTextContainer>
        {modalCard?.button === undefined ? (
          <LabResultsCardIcons>
            <EditLabResult labTest={labTest} />
          </LabResultsCardIcons>
        ) : (
          <>{modalCard.button()}</>
        )}
      </LabResultsCardHeader>
      <LabResultsCardBody>
        <LabResultsCardTextContainer>
          <LabResultsCardUnit>
            {roundTo3DecimalPlaces(latestResult.result)}
            {labTestTypeDTO.unit === "-" ? "" : ` ${labTestTypeDTO.unit}`}
          </LabResultsCardUnit>
          <LabResultsCardRange>
            Ref.
            {refsDisplays.map((refDisplay, index) => {
              if (refDisplay.parts.includes(":")) {
                const indexOfColon = refDisplay.parts.indexOf(":");
                const tag = refDisplay.parts.slice(0, indexOfColon);
                const refDefinition = refDisplay.parts.slice(
                  indexOfColon + 1,
                  refDisplay.parts.length
                );
                return (
                  <div key={index}>
                    <span>{tag}</span> <p>{refDefinition}</p>
                  </div>
                );
              } else {
                return <p key={index}>{refDisplay.parts}</p>;
              }
            })}
          </LabResultsCardRange>
        </LabResultsCardTextContainer>
        <LabResultsChart labTest={labTest} />
      </LabResultsCardBody>
    </LabResultsCardContainer>
  );
};
