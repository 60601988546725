import { PatientMealDTO } from "../../../models/patient-meal-dtos/patient-meal-dto";

export function getPatientMealAfterUpdates(
  patientMealDTO: PatientMealDTO,
  lastUpdatedPatientMealByPatientMealId: Map<number, PatientMealDTO>
): PatientMealDTO {
  const lastUpdatedPatientMeal = lastUpdatedPatientMealByPatientMealId.get(
    patientMealDTO.id!
  );

  if (lastUpdatedPatientMeal === undefined) {
    return patientMealDTO;
  }

  return lastUpdatedPatientMeal;
}
