import { timeDifference } from "./time-difference";

export function calculateTimeDifferences(timeArray: string[]) {
  const differences = [0];

  for (let i = 1; i < timeArray.length; i++) {
    const difference = timeDifference(timeArray[i - 1], timeArray[i]);
    differences.push(difference);
  }

  return differences;
}
