import { usePatientProfileMedicalHistory } from "../../context/loadable-patient-profile-medical-history";
import {
  PatientProfileMedicalHistoryItemContainer,
  PatientProfileMedicalHistoryItemTextContainer,
  PatientProfileMedicalHistoryItemTitleContainer,
} from "../styled-profile-medical-history";

export const PatientProfileMedicalHistoryReadDiabetesComplications = () => {
  const { patientMedicalHistoryDTO } = usePatientProfileMedicalHistory();

  const diabetesComplications = patientMedicalHistoryDTO.diabetesComplications;

  return (
    <PatientProfileMedicalHistoryItemContainer>
      <PatientProfileMedicalHistoryItemTitleContainer>
        <img src="/img/patient-profile/medical-history/blood-drop.svg" alt="" />
        <p>Diabetes Related Complication</p>
      </PatientProfileMedicalHistoryItemTitleContainer>
      <PatientProfileMedicalHistoryItemTextContainer>
        <p>
          {diabetesComplications !== undefined &&
          diabetesComplications.length > 0
            ? diabetesComplications.map(
                (item, index) =>
                  `${item.diabetesComplication.name}${
                    diabetesComplications.length > 1 &&
                    diabetesComplications[index + 1]
                      ? ", "
                      : ""
                  }`
              )
            : "--"}
        </p>
      </PatientProfileMedicalHistoryItemTextContainer>
    </PatientProfileMedicalHistoryItemContainer>
  );
};
