import { Controller, useFormContext } from "react-hook-form";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileModalLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileDietModalInputs } from "../../patient-profile-edit-diet-modal";

export const PatientProfileEditDietAlcoholsOtherFormControl = () => {
  const { control, setValue } = useFormContext<PatientProfileDietModalInputs>();

  return (
    <PatientProfileModalFormControl>
      <PatientProfileModalLabelContainer>
        <label>Alcohols (Others)</label>
      </PatientProfileModalLabelContainer>
      <PatientProfileModalInputContainer>
        <Controller
          name={`patientAlcoholsOther.other`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <input
              type="text"
              placeholder="Notes"
              value={value !== undefined ? value : ""}
              onChange={(e) => {
                onChange(e.target.value !== undefined ? e.target.value : "");
                setValue(`patientAlcoholsOther.alcohol`, "Other");
                setValue(`patientAlcoholsOther.quantity`, undefined);
                setValue(`patientAlcoholsOther.frequency`, undefined);
              }}
            />
          )}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
