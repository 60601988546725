import { PlansSubScreenContainer } from "../styles";
import { ReviewGoalsList } from "./review-goals-list";
import { ReviewFeedbacks } from "./review-feedbacks";
import { ReviewMedical } from "./review-medical";
import {
  ReviewContainer,
  ReviewFooter,
  ReviewHeader,
  ReviewHeaderTextContainer,
} from "./styled-review";
import { ReviewNutrition } from "./review-nutrition";
import { ReviewLifestyle } from "./review-lifestyle";
import { ReviewEducation } from "./review-education";
import { Temporal } from "temporal-polyfill";
import { useCallback, useEffect } from "react";
import { usePlansSteps } from "../../context/plans-steps-context";
import { Button } from "../../../../../styles/classes/reusable-classes";
import { useUpdatePatientPlan } from "../../../../../hooks/patient-plan-hooks/use-update-patient-plan";
import { useDetailedPlan } from "../../context/loadable-detailed-plan-context";
import { useToastService } from "../../../../../context/toast-service-context";
import { useNavigate, useParams } from "react-router-dom";

export const Review = () => {
  const { handleSetCompletedSteps } = usePlansSteps();
  const updatePatientPlan = useUpdatePatientPlan();
  const { patientDetailedPlanDTO, patientDTO } = useDetailedPlan();
  const { showToast } = useToastService();
  const navigate = useNavigate();
  const { id } = useParams();
  const { handleSetCurrentStep } = usePlansSteps();

  const handleBackClick = () => {
    handleSetCurrentStep("Education");
  };

  useEffect(() => {
    handleSetCompletedSteps();
  }, [handleSetCompletedSteps]);

  function calculateAge(dateString: string) {
    const today = Temporal.Now.plainDateISO();
    const birthDate = Temporal.PlainDate.from(dateString);
    let age = today.year - birthDate.year;
    let month = today.month - birthDate.month;

    if (month < 0 || (month === 0 && today.day < birthDate.day)) {
      age--;
    }

    return age;
  }

  const age =
    patientDTO.dateOfBirth !== undefined
      ? calculateAge(patientDTO.dateOfBirth)
      : "unspecified";

  const onSubmit = useCallback(() => {
    const updateDTO = {
      ...patientDetailedPlanDTO,
      patientPlan: {
        ...patientDetailedPlanDTO.patientPlan,
        isDraft: false,
      },
    };

    updatePatientPlan(patientDetailedPlanDTO.patientPlan, updateDTO)
      .then(() => {
        showToast("Success", "Plan added successfully");
        navigate(`/patients/${id}/plans`);
      })
      .catch(() => showToast("Error", "Failed to add Plan :("));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate, showToast, updatePatientPlan]);

  return (
    <ReviewContainer>
      <ReviewHeader>
        <ReviewHeaderTextContainer>
          <div>
            <p>{patientDTO.name}</p>
            <span>{age} years</span>
          </div>
        </ReviewHeaderTextContainer>
      </ReviewHeader>
      <PlansSubScreenContainer reviewLayout>
        <ReviewGoalsList />
        <ReviewFeedbacks />
        <ReviewMedical />
        <ReviewNutrition />
        <ReviewLifestyle />
        <ReviewEducation />
      </PlansSubScreenContainer>
      <ReviewFooter>
        <Button flex outlinedPrimary type="button" onClick={handleBackClick}>
          <span className="material-symbols-outlined">navigate_before</span>{" "}
          Back
        </Button>
        <Button flex type="button" onClick={onSubmit}>
          <span className="material-symbols-outlined">check_circle</span> Finish
        </Button>
      </ReviewFooter>
    </ReviewContainer>
  );
};
