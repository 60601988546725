import { Controller, useFormContext } from "react-hook-form";
import { ContentModuleUpdateModeInputs } from "../../../context/content-module-mode-context";
import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../styles/classes/reusable-classes";

export const UpdateContentModuleOrderController = () => {
  const { control } = useFormContext<ContentModuleUpdateModeInputs>();

  return (
    <Controller
      control={control}
      name="uiOrder"
      render={({ field: { onChange, value } }) => (
        <UXInputContainer $width="100px">
          <UXInputLabel $width="30px">Order</UXInputLabel>
          <UXInputWrapper $width="50px">
            <UXInputInput
              type="number"
              value={value}
              onChange={(e) => onChange(Number(e.target.value))}
            />
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
