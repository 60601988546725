import { Button } from "../../styles/classes/reusable-classes";
import { ModalForm } from "../modal/modal-form/modal-form";
import { ModalHeader } from "../modal/modal-header/modal-header";
import {
  DeleteModalButtonsContainer,
  DeleteModalContainer,
} from "./styled-delete-modal";

type DeleteModalProps = {
  onModalClose: () => void;
  title: string;
  onDelete: () => void;
  disableDelete?: boolean;
  subMessage?: string;
};

export const DeleteModal = (props: DeleteModalProps) => {
  const { onModalClose, title, onDelete, disableDelete, subMessage } = props;

  const handleOnDelete = () => {
    onDelete();
    onModalClose();
  };

  return (
    <ModalForm width={550} height={270} onSubmit={(e) => e.preventDefault()}>
      <ModalHeader title={`Delete ${title}`} onModalClose={onModalClose} />
      <DeleteModalContainer>
        <p>
          Proceed deleting {title} ?
          {subMessage && (
            <strong className="delete-modal-sub-message">{subMessage}</strong>
          )}
        </p>
      </DeleteModalContainer>
      <DeleteModalButtonsContainer>
        <Button outlined onClick={onModalClose} type="button">
          Cancel
        </Button>
        <Button onClick={handleOnDelete} disabled={disableDelete}>
          Delete
        </Button>
      </DeleteModalButtonsContainer>
    </ModalForm>
  );
};
