import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getUserFromLocalStorage,
  LocalStorageUser,
  removeUserFromLocalStorage,
  setUserToLocalStorage,
} from "./local-storage-user";
import { credentialToUser } from "./credential-to-user";
import { assertNever } from "../../utils/assert-never";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { PatientType } from "../../models/patient-dtos/patient-type";

export type PatientUser = {
  patientPhoneNumber: string;
  patientToken: string;
  patientId: number;
  patientName: string;
  patientType: PatientType;
};

/**
 * Admin user. Not renamed to minimize changes && because patient login is temporary.
 */
export type User = {
  credential: string;
  name: string;
  imageUrl?: string;
  iss: string;
};

export type Auth = {
  user?: User | PatientUser;
  setAndPersistUser: (localStorageUser: LocalStorageUser) => void;
  clearAndUnpersistUser: () => void;
};

const AuthContext = createContext<Auth | undefined>(undefined);

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const navigate = useNavigate();

  const navigateRef = useRef<NavigateFunction>(navigate);

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  const [user, setUser] = useState<User | PatientUser | undefined>(() => {
    const localStorageUser = getUserFromLocalStorage();

    if (localStorageUser === undefined) {
      return undefined;
    }

    if ("credential" in localStorageUser) {
      return credentialToUser(localStorageUser.credential);
    }

    if ("patientToken" in localStorageUser) {
      return localStorageUser;
    }

    assertNever(localStorageUser);
  });

  const setAndPersistUser = useCallback(
    (localStorageUser: LocalStorageUser) => {
      if ("credential" in localStorageUser) {
        setUser(credentialToUser(localStorageUser.credential));
      } else if ("patientToken" in localStorageUser) {
        setUser(localStorageUser);
      } else {
        assertNever(localStorageUser);
      }

      setUserToLocalStorage(localStorageUser);

      navigateRef.current("/", { replace: true });
    },
    [setUser]
  );

  const clearAndUnpersistUser = useCallback(() => {
    setUser(undefined);
    removeUserFromLocalStorage();

    if (user === undefined) {
      window.location.pathname = "/";
    } else if ("credential" in user) {
      window.location.pathname = "/team-login";
    } else if ("patientToken" in user) {
      window.location.pathname = "/patient-login";
    } else {
      assertNever(user);
    }
  }, [setUser, user]);

  const value = useMemo(
    () => ({ user, setAndPersistUser, clearAndUnpersistUser }),
    [user, setAndPersistUser, clearAndUnpersistUser]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth(): Auth {
  const auth = useContext(AuthContext);
  if (auth === undefined) {
    throw Error("useAuth must be used within an AuthProvider");
  }

  return auth;
}

export function useOptionalUser(): User | undefined {
  const auth = useAuth();

  if (auth.user !== undefined && "credential" in auth.user) {
    return auth.user;
  }
}

/**
 * Credits: https://twitter.com/kentcdodds/status/1401010403576999941/photo/1
 */
export function useUser(): User {
  const user = useOptionalUser();

  if (user === undefined) {
    throw Error(
      "No user in AuthProvider context. If user is optional in this situation, use `useOptionalUser` instead of `useUser`."
    );
  }

  return user;
}

export function useOptionalPatientUser(): PatientUser | undefined {
  const auth = useAuth();

  if (auth.user !== undefined && "patientToken" in auth.user) {
    return auth.user;
  }
}

export function usePatientUser(): PatientUser {
  const user = useOptionalPatientUser();

  if (user === undefined) {
    throw Error(
      "No patient user in AuthProvider context. If user is optional in this situation, use `useOptionalPatientUser` instead of `usePatientUser`."
    );
  }

  return user;
}
