import { PateintProfileColumnHeader } from "../../components/pateint-profile-column-header/pateint-profile-column-header";
import {
  PatientProfileContainer,
  PatientProfileContainerBody,
} from "../../styles/patient-profile-styles/patient-profile-classes";
import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { PatientProfileWeightHeader } from "./patient-profile-weight-header/patient-profile-weight-header";
import { useMemo, useState } from "react";
import { PatientProfileWeightChart } from "./patient-profile-weight-chart/patient-profile-weight-chart";
import { Temporal } from "temporal-polyfill";
import { usePatientProfile } from "../../context/loadable-patient-profile-context";

export const PatientProfileWeight = () => {
  const { patientWeightDTOs } = usePatientProfile();

  const formatedDatesPatientWeightDTOs = useMemo(
    () =>
      patientWeightDTOs
        .sort((a, b) => a.date.localeCompare(b.date))
        .map((patientWeightDTO) => ({
          ...patientWeightDTO,
          date: Temporal.PlainDate.from(patientWeightDTO.date).toLocaleString(
            "en-UK",
            {
              day: "2-digit",
              month: "2-digit",
            }
          ),
        })),
    [patientWeightDTOs]
  );

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  const temporalStartDate =
    startDate !== undefined
      ? Temporal.PlainDate.from(startDate.toISOString().split("T")[0])
          .add({ days: 1 })
          .toString()
      : "";

  const temporalEndDate =
    endDate !== undefined
      ? Temporal.PlainDate.from(endDate.toISOString().split("T")[0])
          .add({ days: 1 })
          .toString()
      : "";

  const filteredPatientWeightDTOsByDateRange = useMemo(
    () =>
      formatedDatesPatientWeightDTOs.filter(
        (weightDTO) =>
          weightDTO.date >= temporalStartDate &&
          weightDTO.date <= temporalEndDate
      ),
    [temporalStartDate, temporalEndDate, formatedDatesPatientWeightDTOs]
  );

  return (
    <PatientProfileContainer>
      <PateintProfileColumnHeader
        title="Weight"
        img="/img/patient-profile/weight.svg"
        button={
          patientWeightDTOs.length > 0 ? (
            <PatientProfileWeightHeader
              showPicker={patientWeightDTOs.length > 0}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          ) : (
            <></>
          )
        }
      />
      <PatientProfileContainerBody>
        {patientWeightDTOs.length > 0 ? (
          <PatientProfileWeightChart
            filteredPatientWeightDTOsByDateRange={
              filteredPatientWeightDTOsByDateRange
            }
            startDate={startDate}
            endDate={endDate}
            formatedDatesPatientWeightDTOs={formatedDatesPatientWeightDTOs}
          />
        ) : (
          <EmptyState
            hasHeader={false}
            hasSearch={false}
            button={
              <PatientProfileWeightHeader
                showPicker={patientWeightDTOs.length > 0}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            }
            text="There are no data in Weight"
            title=""
          />
        )}
      </PatientProfileContainerBody>
    </PatientProfileContainer>
  );
};
