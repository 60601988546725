import styled from "styled-components/macro";

export const NutritionReportSummaryScreenContainer = styled.div<{
  viewReportUI?: boolean;
}>`
  margin: ${({ viewReportUI }) => (viewReportUI ? "16px" : "24px")};
  padding: ${({ viewReportUI }) => (viewReportUI ? "16px" : "24px")};
  border-radius: 16px;
  background: #fbfbfb;
  display: grid;
  grid-template-columns: ${({ viewReportUI }) =>
    viewReportUI ? "repeat(2, 1fr)" : "1fr 2fr"};
  gap: 32px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 200px;
  }
`;
