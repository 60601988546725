import { Temporal } from "temporal-polyfill";
import {
  PatientProfileTimeLineDateContainer,
  PatientProfileTimeLineList,
  PatientProfileTimeLineListItem,
  PatientProfileTimeLineProgressBarContainer,
  PatientProfileTimeLineProgressBarEllipse,
  PatientProfileTimeLineProgressBarLine,
  PatientProfileTimeLineTextContainer,
} from "./styled-patient-profile-timeline";
import { PateintProfileColumnHeader } from "../../components/pateint-profile-column-header/pateint-profile-column-header";
import { PatientProfileContainerBody } from "../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileEditTimelineModal } from "./patient-profile-edit-timeline-modal/patient-profile-edit-timeline-modal";
import { ReactNode } from "react";
import { EmptyState } from "../../../../../components/empty-state/empty-state";
import {
  viewEmergencies,
  viewSurgeries,
} from "../../utils/patient-profile-utils";
import { usePatientProfileTimeline } from "../context/loadable-patient-profile-timeline";

export const PatientProfileTimeline = () => {
  const { patientTimelineDTO } = usePatientProfileTimeline();

  const patientTimeLineListItem = (
    index: number,
    title: string,
    date?: string,
    subTitle?: number
  ): ReactNode => {
    const displayDate = date !== undefined ? `${date}-01` : undefined;

    return (
      <PatientProfileTimeLineListItem key={index}>
        <PatientProfileTimeLineDateContainer>
          <p>
            {date !== undefined
              ? Temporal.PlainDate.from(`${displayDate}`).toLocaleString(
                  "en-UK",
                  {
                    month: "short",
                  }
                )
              : ""}
          </p>
          <span>
            {date !== undefined
              ? Temporal.PlainDate.from(`${displayDate}`).toLocaleString(
                  "en-UK",
                  {
                    year: "numeric",
                  }
                )
              : ""}
          </span>
        </PatientProfileTimeLineDateContainer>
        <PatientProfileTimeLineProgressBarContainer>
          <PatientProfileTimeLineProgressBarEllipse />
          <PatientProfileTimeLineProgressBarLine />
        </PatientProfileTimeLineProgressBarContainer>
        <PatientProfileTimeLineTextContainer>
          <p>{title}</p>
          <span>{subTitle !== undefined ? `A1c: ${subTitle}` : ""}</span>
        </PatientProfileTimeLineTextContainer>
      </PatientProfileTimeLineListItem>
    );
  };

  const patientTimeLineListItems = patientTimelineDTO.events.map(
    (item, index) => {
      const date =
        item.diagnosedSince !== undefined ? item.diagnosedSince : undefined;

      if (item.diabetesDiagnosis !== undefined) {
        const a1cAtDiabetesDiagnosis =
          item.a1cAtDiabetesDiagnosis !== undefined
            ? item.a1cAtDiabetesDiagnosis
            : undefined;

        return patientTimeLineListItem(
          index,
          item.diabetesDiagnosis,
          date,
          a1cAtDiabetesDiagnosis
        );
      }
      if (item.surgery !== undefined) {
        if (item.surgery === "Other") {
          const surgeryOther =
            item.surgeryOther !== undefined ? item.surgeryOther : "";

          return patientTimeLineListItem(index, `${surgeryOther}`, date);
        } else {
          return patientTimeLineListItem(
            index,
            `${viewSurgeries(item.surgery)}`,
            date
          );
        }
      } else if (item.emergency !== undefined) {
        return patientTimeLineListItem(
          index,
          `${viewEmergencies(item.emergency)}`,
          date
        );
      } else {
        return <></>;
      }
    }
  );

  return (
    <>
      <PateintProfileColumnHeader
        icon="calendar_month"
        title="Timeline"
        button={<PatientProfileEditTimelineModal />}
      />
      <PatientProfileContainerBody>
        <PatientProfileTimeLineList>
          {patientTimeLineListItems.length > 0 ? (
            patientTimeLineListItems
          ) : (
            <EmptyState
              button={<></>}
              hasHeader={false}
              hasSearch={false}
              text="There are no data in Timeline"
              title=""
            />
          )}
        </PatientProfileTimeLineList>
      </PatientProfileContainerBody>
    </>
  );
};
