import styled from "styled-components/macro";
import {
  flexCenter,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const PatientProfileDietContainer = styled.div`
  margin: 16px 24px;
  display: grid;
  gap: 12px;
`;

export const PatientProfileDietCardSubContainer = styled.div`
  ${flexCenter}
  gap: 8px;
  padding: 12px 16px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 12px;

  p {
    margin: 0px;
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};

    span {
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;

export const PatientProfileDietCard = styled.div`
  display: grid;
  gap: 0px 12px;

  :first-child {
    grid-template-columns: repeat(2, 1fr);
  }

  :nth-child(2) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
