import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  NutritionGoalsBodyListItem,
  NutritionGoalsBodyListItemInput,
  NutritionGoalsBodyListItemText,
} from "./styled-nutrition-goals-body";
import { useNutritionGolasLastFoodGroupSelected } from "../context/nutrition-goals-last-food-group-selected-context";
import { NutritionGoalsInput } from "../nutrition-loading";

export const NutritionGoalsBodyCreateCustomListItem = () => {
  const { lastFoodGroupSelected } = useNutritionGolasLastFoodGroupSelected();

  const { control, setValue } = useFormContext<NutritionGoalsInput>();
  const { fields } = useFieldArray<NutritionGoalsInput>({
    name: "nutritionRegimenFoodGroups",
    control,
  });

  return (
    <>
      {fields.map((field, index) => {
        return (
          <NutritionGoalsBodyListItem key={field.id}>
            <NutritionGoalsBodyListItemText>
              <img
                src={
                  lastFoodGroupSelected?.nutritionRegimenFoodGroups[index]
                    .foodGroup.iconUrl
                }
                alt={
                  lastFoodGroupSelected?.nutritionRegimenFoodGroups[index]
                    .foodGroup.name
                }
              />
              <p>
                {
                  lastFoodGroupSelected?.nutritionRegimenFoodGroups[index]
                    .foodGroup.name
                }
              </p>
            </NutritionGoalsBodyListItemText>
            <NutritionGoalsBodyListItemInput>
              <Controller
                control={control}
                name={`nutritionRegimenFoodGroups.${index}.dailyServingsCount`}
                render={({ field: { value, onChange } }) => (
                  <input
                    type="number"
                    value={value}
                    onChange={(e) => {
                      onChange(
                        e.target.value !== undefined
                          ? Number(e.target.value)
                          : undefined
                      );
                      setValue(
                        `nutritionRegimenFoodGroups.${index}.foodGroupId`,
                        field.foodGroupId
                      );
                    }}
                    step={0.1}
                    placeholder="-"
                  />
                )}
              />
            </NutritionGoalsBodyListItemInput>
          </NutritionGoalsBodyListItem>
        );
      })}
    </>
  );
};
