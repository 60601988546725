import { TH } from "../../../components/table/styled-table";

export function PatientsTableHeadings() {
  const headings = [
    "Patient",
    "Medical conditions",
    "Mobile",
    "Activation Date",
    "Created At",
    "Actions",
  ];

  return (
    <>
      {headings.map((heading, index) => (
        <TH key={index} actions={heading === "Actions" && true}>
          <div>
            {heading}
            {/* <span className="material-symbols-outlined">arrow_drop_down</span> */}
          </div>
        </TH>
      ))}
    </>
  );
}
