import { useMemo, useState } from "react";
import { PatientDTO } from "../../../models/patient-dtos/patient-dto";
import { Pagination } from "../../../components/pagination/pagination";
import { Table } from "../../../components/table/table";
import { PatientsTableHeadings } from "./patients-table-headings";
import { PatientsTableRows } from "./patients-table-rows";
import { sortPatients } from "../../../utils/patient-utils";
import { TableHeader } from "../../../components/table/table-header/table-header";
import { TablePaginationContainer } from "../../../components/table/styled-table";
import { useNavigate, useParams } from "react-router-dom";
import { useOptionalUser } from "../../../context/auth-context/auth-context";
import { PatientsTableContainer } from "./styled-patients-table";

type PatientsTableProps = {
  patientDTOs: PatientDTO[];
};

export const PatientsTable = (props: PatientsTableProps) => {
  const { patientDTOs } = props;

  const [tableRowsPerPage, setTableRowsPerPage] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");

  const { page } = useParams();
  const navigate = useNavigate();

  const user = useOptionalUser();

  const sorted = useMemo(() => sortPatients(patientDTOs), [patientDTOs]);

  const filtered = useMemo(
    () =>
      sorted.filter((item) => {
        if (searchTerm === "") {
          return item;
        } else if (
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.whatsAppId?.toString().includes(searchTerm)
        ) {
          return item;
        } else {
          return 0;
        }
      }),
    [sorted, searchTerm]
  );

  const maxPage = Math.ceil(filtered.length / tableRowsPerPage);

  const currentOrMaxPage = Math.min(Number(page), maxPage);

  const currentPagePatientDTOs = useMemo(() => {
    const indexOfLastEntry = currentOrMaxPage * tableRowsPerPage;
    const indexOfFirstEntry = indexOfLastEntry - tableRowsPerPage;

    return user !== undefined && user.iss !== "https://accounts.google.com"
      ? filtered.filter(
          (patientDTO) =>
            patientDTO.id === 125 ||
            patientDTO.id === 122 ||
            patientDTO.id === 130
        )
      : filtered.slice(indexOfFirstEntry, indexOfLastEntry);
  }, [filtered, tableRowsPerPage, currentOrMaxPage, user]);

  function onSearchTermChange(searchTerm: string) {
    navigate(`../1`);
    setSearchTerm(searchTerm);
  }

  return (
    <PatientsTableContainer>
      <TableHeader
        title="All Patients"
        searchTerm={searchTerm}
        setSearchTerm={onSearchTermChange}
      />
      <Table
        tableHeadings={<PatientsTableHeadings />}
        tableRows={
          <PatientsTableRows currentPagePatientDTOs={currentPagePatientDTOs} />
        }
      />
      <TablePaginationContainer>
        <Pagination
          totalRowsCount={filtered.length}
          tableRowsPerPage={tableRowsPerPage}
          setRowsPerPage={setTableRowsPerPage}
          navigateBasePath={"/"}
        />
      </TablePaginationContainer>
    </PatientsTableContainer>
  );
};
