import styled from "styled-components/macro";

export const PlansNotesInputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0px 87px;
`;

export const PlansNotesTextAreaContainer = styled.div`
  background: ${({ theme }) => theme.colors.inputColor};
  width: 300px;
  height: 79px;
  border-radius: 8px;
  padding: 8px 16px;

  textarea {
    width: 100%;
    height: 100%;
    background: transparent;
  }
`;
