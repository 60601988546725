import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { BASE_PATH } from "../../constants/paths";
import { ContentModuleDTO } from "../../models/content-module-dtos/content-module-dto";
import { PutContentModuleDTO } from "../../models/content-module-dtos/put-content-module-dto";

export function usePutContentModule(): (
  contentModuleId: number,
  putContentModuleDTO: PutContentModuleDTO
) => Promise<ContentModuleDTO> {
  const client = useClient();

  return useCallback(
    async (contentModuleId, putContentModuleDTO) => {
      const response = await client.put<ContentModuleDTO>(
        `${BASE_PATH}/content-modules/${contentModuleId}`,
        putContentModuleDTO
      );

      return response.data;
    },
    [client]
  );
}
