import { useCallback, useState } from "react";
import { AutoSelect } from "../../../../../../../../../components/auto-select/auto-select";
import {
  Button,
  InputContainer,
  InputLabel,
} from "../../../../../../../../../styles/classes/reusable-classes";
import { DiagnosisAutoSelectContainer } from "./styled-diagnosis-auto-select";
import { useEnterKeypress } from "../../../../../../../../../hooks/use-enter-keypress/use-enter-keypress";
import { PutPatientProfileDiagnosesProfileDiagnosisDTO } from "../../../../../../../../../models/patient-profile-diagnosis-dtos/put-patient-profile-diagnoses-profile-diagnosis-dto";
import { useSinglePatientSidebar } from "../../../../../../context/loadable-single-patient-sidebar-context";

type DiagnosisAutoSelectProps = {
  handleAppend: () => void;
  autoSelectTagValue?: PutPatientProfileDiagnosesProfileDiagnosisDTO;
  setAutoSelectTagValue: (
    autoSelectTagValue: PutPatientProfileDiagnosesProfileDiagnosisDTO
  ) => void;
};

export const DiagnosisAutoSelect = (props: DiagnosisAutoSelectProps) => {
  const { handleAppend, autoSelectTagValue, setAutoSelectTagValue } = props;

  const { profileDiagnoses } = useSinglePatientSidebar();

  const findDiagnosisByName = useCallback(
    (profileDiagnosesName: string) =>
      profileDiagnoses.find((item) => item.name === profileDiagnosesName),
    [profileDiagnoses]
  );

  const findDiagnosisById = useCallback(
    (profileDiagnosesId: number) =>
      profileDiagnoses.find((item) => item.id === profileDiagnosesId),
    [profileDiagnoses]
  );

  const { onEnterKeyPress } = useEnterKeypress(handleAppend);

  const [searchTerm, setSearchTerm] = useState(
    autoSelectTagValue !== undefined
      ? findDiagnosisById(autoSelectTagValue.profileDiagnosisId)?.name
      : ""
  );

  return (
    <InputContainer>
      <InputLabel>Add new diagnose</InputLabel>
      <DiagnosisAutoSelectContainer>
        <AutoSelect
          itemsArray={profileDiagnoses
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => item.name)}
          onChange={(value: string) => {
            // This value should never be changed with undefined !
            setAutoSelectTagValue({
              profileDiagnosisId: findDiagnosisByName(value)?.id!,
            });
            setSearchTerm(value);
          }}
          value={searchTerm}
          fullWidth
        />
        <Button
          type="button"
          onClick={handleAppend}
          onKeyDown={(e) => onEnterKeyPress(e)}
          width={200}
        >
          Add diagnose
        </Button>
      </DiagnosisAutoSelectContainer>
    </InputContainer>
  );
};
