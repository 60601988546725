import { BASE_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { UploadedFileWithPresignedUrlDTO } from "../../models/uploaded-file-dtos/uploaded-file-with-presigned-url-dto";
import { CreateUploadedFileDTO } from "../../models/uploaded-file-dtos/create-uploaded-file-dto";

export function useAddUploadedFile(): (
  createUploadedFileDTO: CreateUploadedFileDTO,
  file: Blob
) => Promise<UploadedFileWithPresignedUrlDTO> {
  const client = useClient();

  return useCallback(
    async (createUploadedFileDTO, file) => {
      const formData = new FormData();

      formData.append(
        "createUploadedFileDTO",
        JSON.stringify(createUploadedFileDTO)
      );

      formData.append("file", file);

      const response = await client.post<UploadedFileWithPresignedUrlDTO>(
        `${BASE_PATH}/uploaded-files`,
        formData
      );

      return response.data;
    },
    [client]
  );
}
