import { useParams } from "react-router-dom";
import { useFoodLoggingData } from "../../../context/food-logging-data-context";
import {
  NutritionReportHeaderContainer,
  NutritionReportHeaderIcons,
  NutritionReportHeaderPatientInfo,
  NutritionReportHeaderReloadIcon,
} from "./styled-nutrition-report-table-header";
import { useReloadPatientNutritionReportDailySummary } from "../../../context/nutrition-report-context/loadable-patient-nutrition-report-daily-summary-context";

export const NutritionReportTableHeader = () => {
  const { patientID } = useParams();
  const { patientDTOs } = useFoodLoggingData();

  const findPatientName = patientDTOs.find(
    (patient) => patient.id === parseInt(patientID!)
  )?.name;

  const reload = useReloadPatientNutritionReportDailySummary();

  return (
    <NutritionReportHeaderContainer>
      <NutritionReportHeaderPatientInfo>
        <span>Nutrition Report</span>
        <p>{findPatientName}</p>
      </NutritionReportHeaderPatientInfo>
      <NutritionReportHeaderIcons>
        <NutritionReportHeaderReloadIcon
          className="material-symbols-outlined"
          onClick={reload}
        >
          autorenew
        </NutritionReportHeaderReloadIcon>
      </NutritionReportHeaderIcons>
    </NutritionReportHeaderContainer>
  );
};
