import { useForm } from "react-hook-form";
import {
  BulkImportCenter,
  BulkImportData,
  BulkImportDataItem,
  BulkImportModalForm,
  PatientName,
  PatientStatus,
} from "../styled-bulk-import";
import { ModalHeader } from "../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ErrorText,
  Input,
  InputContainer,
  InputLabel,
  ModalFooter,
} from "../../../../styles/classes/reusable-classes";
import { PatientDTO } from "../../../../models/patient-dtos/patient-dto";
import { useState } from "react";
import { Loading } from "../loading/loading";
import { usePatients } from "../../context/loadable-patients-context";
import { comparePatientsNames } from "../../../../utils/patient-utils";
import { useImportCronometer } from "../../../../hooks/use-import-cronometer";

export type BulkImportModalProps = {
  onModalClose: () => void;
};

type Inputs = {
  sesNonce: string;
};

type Status = "skipped" | "loading" | "success" | "failure";

type PatientStatusType = {
  patientDTO: PatientDTO;
  status: Status;
};

export function BulkImportCronometerModal(props: BulkImportModalProps) {
  const { onModalClose } = props;

  const importNewServingsFromCronometer = useImportCronometer();

  const patientDTOs = usePatients();

  const [patientIdToStatus, setPatientIdToStatus] = useState<{
    [key: number]: PatientStatusType;
  }>({});

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<Inputs>();

  function setPatientStatus(patientDTO: PatientDTO, status: Status) {
    setPatientIdToStatus((patientIdToStatus) => ({
      ...patientIdToStatus,
      [patientDTO.id!]: {
        patientDTO,
        status,
      },
    }));
  }

  const onSubmit = handleSubmit(async (inputs) => {
    for (const patientDTO of patientDTOs) {
      setPatientStatus(patientDTO, "loading");
    }

    for (const patientDTO of patientDTOs) {
      if (patientDTO.cronometerPatientId === undefined) {
        setPatientStatus(patientDTO, "skipped");
        continue;
      }

      try {
        const importNewPatientServingsFromCronometer = {
          sesNonce: inputs.sesNonce,
        };
        await importNewServingsFromCronometer(
          patientDTO,
          importNewPatientServingsFromCronometer
        );
        setPatientStatus(patientDTO, "success");
      } catch (e) {
        setPatientStatus(patientDTO, "failure");
      }
    }
  });

  const patientStatuses = Object.values(patientIdToStatus).sort((a, b) =>
    comparePatientsNames(a.patientDTO.name, b.patientDTO.name)
  );

  return (
    <BulkImportModalForm onSubmit={onSubmit}>
      <ModalHeader
        title="Bulk Import New Cronometer Servings"
        onModalClose={onModalClose}
      />
      <BulkImportCenter>
        <InputContainer>
          <InputLabel>Cronometer SES Nonce</InputLabel>
          <Input
            error={errors.sesNonce?.type === "required" && true}
            {...register("sesNonce", { required: true })}
          />
          {errors.sesNonce?.type === "required" && (
            <ErrorText>Cronometer SES Nonce is required</ErrorText>
          )}
        </InputContainer>
        <BulkImportData>
          {(isSubmitting || isSubmitted) &&
            patientStatuses.map((patientStatus) => (
              <BulkImportDataItem key={patientStatus.patientDTO.id}>
                <div>
                  <PatientName>{patientStatus.patientDTO.name}</PatientName>
                  {patientStatus.status === "skipped" && (
                    <PatientStatus>
                      Please, set patient's Cronometer ID
                    </PatientStatus>
                  )}
                </div>

                {patientStatus.status === "success" ? (
                  <PatientStatus>
                    <span className="material-symbols-outlined success">
                      done
                    </span>
                  </PatientStatus>
                ) : patientStatus.status === "failure" ? (
                  <PatientStatus>
                    <span className="material-symbols-outlined failure">
                      error_outline
                    </span>
                  </PatientStatus>
                ) : patientStatus.status === "skipped" ? (
                  <PatientStatus className="skipped">
                    {patientStatus.status}
                  </PatientStatus>
                ) : patientStatus.status === "loading" ? (
                  <Loading />
                ) : (
                  patientStatus.status
                )}
              </BulkImportDataItem>
            ))}
        </BulkImportData>
      </BulkImportCenter>
      <ModalFooter>
        <Button
          outlined
          onClick={onModalClose}
          type="button"
          disabled={isSubmitting}
        >
          Close
        </Button>
        <Button type="submit" disabled={isSubmitting || isSubmitted}>
          Import
        </Button>
      </ModalFooter>
    </BulkImportModalForm>
  );
}
