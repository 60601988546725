import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  mediumRegularFont,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const NutritionGoalsBodyContainer = styled.div`
  padding: 0px 24px 0px;
`;

export const NutritionGoalsBodySubContainer = styled.div`
  padding: 32px 18px 0px;
`;

export const NutritionGoalsBodyList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(40px, auto);
  gap: 24px 67px;
`;

export const NutritionGoalsBodyListItem = styled.li`
  ${flexCenterSpaceBetween}
`;

export const NutritionGoalsBodyListItemText = styled.div`
  ${flexCenter}
  gap: 0px 8px;

  img {
    width: 24px;
    height: 24px;
  }

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;

    ::first-letter {
      text-transform: capitalize;
    }
  }
`;

export const NutritionGoalsBodyListItemInput = styled.div`
  input {
    height: 40px;
    width: 55px;
    ${mediumRegularFont}
    background: ${({ theme }) => theme.newColors.inputColorAlt};
    border: 0px;
    outline: 0px;
    border-radius: 5px;
    text-align: center;
    padding: 0px;
    padding-left: 10px;

    ::placeholder {
      ${mediumRegularFont}
      color: ${({ theme }) => theme.colors.titleColor};
    }
  }
`;
