import styled from "styled-components/macro";
import {
  flexCenterCenter,
  flexCenterSpaceAround,
  flexCenterSpaceBetween,
} from "../../../styles/classes/gloabl-classes";
import {
  lightShadowAlt,
  lightShadowSecondary,
  transition,
} from "../../../styles/global-style";
import { EmptyStateBody } from "../../../components/empty-state/styled-empty-state";
import {
  Button,
  UXInputContainer,
  UXInputLabel,
} from "../../../styles/classes/reusable-classes";
import {
  VideoUploadClearIcon,
  VideoUploadContainer,
} from "../components/video-upload/styled-video-upload";
import {
  ImageUploadClearIcon,
  ImageUploadContainer,
} from "../components/image-upload/styled-image-upload";

export const LessonCardsContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  padding: 8px 16px;

  ${EmptyStateBody} {
    height: 300px;

    ${Button} {
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const LessonCardsFooter = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding-top: 16px;

  ${Button} {
    margin-left: auto;
  }
`;

export const LessonCardContainer = styled.div<{
  $showParts: boolean;
}>`
  ${lightShadowSecondary}
  width: 90%;
  border-radius: 16px;
  margin: 16px auto;
  padding: 8px;

  .card-parts-list {
    display: ${({ $showParts }) => ($showParts ? "block" : "none")};
  }

  .show-parts-arrow {
    transition: ${transition};
    transform: ${({ $showParts }) =>
      $showParts ? "rotate(-180deg)" : "rotate(0deg)"};
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const LessonCardHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenterSpaceBetween}
  margin-bottom: 16px;
  padding: 8px 0px;

  span {
    margin-bottom: 8px;
    margin-left: 24px;
    ${flexCenterCenter}
    cursor: pointer;

    img {
      width: 17px;
      height: 17px;
    }
  }

  @media screen and (max-width: 1024px) {
    ${UXInputLabel} {
      margin: 0px;
      margin-right: 12px;
    }

    span {
      margin-left: 12px;
    }
  }
`;

export const LessonCardPartsList = styled.ul`
  width: 80%;
  margin: 16px auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const LessonCardPartsListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin: 8px 0px;
  padding: 4px;

  .remove-lesson-card-part-icon {
    ${flexCenterCenter}
    cursor: pointer;
    margin-top: 4px;
    margin-left: 12px;

    img {
      width: 17px;
      height: 17px;
    }
  }

  .grab-lesson-card-part-icon {
    color: ${({ theme }) => theme.colors.textColor};
    cursor: grab;
  }

  @media screen and (max-width: 1024px) {
    ${UXInputContainer} {
      flex-direction: column;
      width: 100%;

      ${UXInputLabel} {
        margin-bottom: 16px;
      }
    }

    ${VideoUploadContainer}, ${ImageUploadContainer} {
      width: 100%;

      ${ImageUploadClearIcon}, ${VideoUploadClearIcon} {
        top: 0px;
      }

      ${ImageUploadClearIcon} {
        right: -35px;
      }
    }
  }
`;

export const LessonCardPartsActions = styled.div`
  ${lightShadowAlt}
  width: 70%;
  border-radius: 16px;
  margin: 16px auto;
  ${flexCenterSpaceAround}

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
