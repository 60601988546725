import {
  GlucoseChartsContainer,
  GlucoseChartsGrid,
} from "./styled-blood-glucose-charts";
import { BloodGlucoseChart } from "./blood-glucose-chart";
import { ChartsContainerTitle } from "../../feedback-modal-body/styled-feedback-modal-body";
import { memo } from "react";
import { DayAggregate } from "../../../../../../../../components/cgm-google-chart/utils/build-days-aggregates";

type BloodGlucoseChartsProps = {
  daysAggregates: DayAggregate[];
};

export const BloodGlucoseCharts = memo((props: BloodGlucoseChartsProps) => {
  const { daysAggregates } = props;

  return (
    <GlucoseChartsContainer>
      <ChartsContainerTitle>
        Select attached daily graph to send <span>(maximum 3)</span>
      </ChartsContainerTitle>
      <GlucoseChartsGrid>
        {daysAggregates.map((dayAggregate) => (
          <BloodGlucoseChart
            key={dayAggregate.date.toString()}
            dayAggregate={dayAggregate}
            hasButton={true}
          />
        ))}
      </GlucoseChartsGrid>
    </GlucoseChartsContainer>
  );
});
