import { useState } from "react";
import { truncateText } from "../../../../../../utils/truncate-text";
import {
  NutritionReportTableRowTDNote,
  NutritionReportTableRowTDNoteContainer,
} from "../components/styled-nutrition-report-table-cell";

type NutritionReportMealDateTableCellNoteProps = {
  mealNote?: string;
};

export const NutritionReportMealDateTableCellNote = (
  props: NutritionReportMealDateTableCellNoteProps
) => {
  const { mealNote } = props;

  const [note, setNote] = useState(truncateText(mealNote!, 30));

  const toggleNote = () => {
    if (note === mealNote!) {
      setNote(truncateText(mealNote!, 30));
    } else {
      setNote(mealNote!);
    }
  };

  return (
    <NutritionReportTableRowTDNoteContainer>
      <NutritionReportTableRowTDNote>
        {note}
        {mealNote?.length! >= 30 && (
          <span onClick={toggleNote}>
            {note === mealNote! ? "Hide" : "See more"}
          </span>
        )}
      </NutritionReportTableRowTDNote>
    </NutritionReportTableRowTDNoteContainer>
  );
};
