export const CHRONIC_DISEASES = [
  "Hypertension",
  "ChronicHeartDiseases",
  "IschemicHeartDisease",
  "PolycysticOvarySyndrome",
  "Dyslipidemia",
  "ChronicThyroidDisorder",
  "GastrointestinalDisorders",
  "KidneyDiseases",
  "PulmonaryDiseases",
  "ChronicObstructivePulmonaryDisease",
  "CancerOrMalignancy",
  "Osteoporosis",
  "RespiratoryDisease",
  "Arthritis",
] as const;

export type ChronicDisease = typeof CHRONIC_DISEASES[number];
