import styled from "styled-components/macro";
import { flexCenter } from "../../../../../styles/classes/gloabl-classes";
import { SidebarWidth } from "../../../../../styles/global-style";
import { FoodLoggingSidebarWidth } from "../../../constants";
import { FEATURE_FLAG_NUTRITION_REPORT } from "../../../../../constants/config";

export const FoodLoggingSelectedPatientMealFooterContainer = styled.div`
  ${flexCenter}
  justify-content: flex-end;
  padding: 8px 24px;
  gap: 0px 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.containerColor};
  position: fixed;
  bottom: 0;
  right: 0;
  left: calc(${SidebarWidth} + ${FoodLoggingSidebarWidth});

  ${FEATURE_FLAG_NUTRITION_REPORT &&
  `
    a {
        margin-right: auto;
        color: inherit;
        text-decoration: none;
      }
  `}
`;
