import { ContentModuleDTO } from "../../../../../models/content-module-dtos/content-module-dto";
import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { ContentModuleModeProvider } from "../../../context/content-module-mode-context";
import { ContentCourseModes } from "./content-course-modes";
import { ImageUploadHelpersProvider } from "../../../components/image-upload/context/image-upload-helpers-context";
import { ContentScreenContainer } from "../../../styles/content-reusable-classes";

type ContentCourseBodyProps = {
  contentCourse?: ContentModuleDTO;
};

export const ContentCourseBody = (props: ContentCourseBodyProps) => {
  const { contentCourse } = props;

  if (contentCourse === undefined) {
    return (
      <EmptyState
        button={<></>}
        hasHeader={false}
        hasSearch={false}
        text="Something went wrong please reload the page or try again"
        title=""
      />
    );
  }

  return (
    <ContentModuleModeProvider contentModuleDTO={contentCourse}>
      <ContentScreenContainer $lessonUI={false}>
        <ImageUploadHelpersProvider>
          <ContentCourseModes />
        </ImageUploadHelpersProvider>
      </ContentScreenContainer>
    </ContentModuleModeProvider>
  );
};
