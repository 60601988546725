import { useCallback } from "react";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import {
  LessonCardsContainer,
  LessonCardsFooter,
} from "../../../../styles/content-module-lesson-classes";
import { LessonUpdateModeCard } from "./lesson-update-mode-card/lesson-update-mode-card";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ContentModuleUpdateModeInputs } from "../../../../context/content-module-mode-context";

export const LessonUpdateModeCards = () => {
  const { control } = useFormContext<ContentModuleUpdateModeInputs>();

  const { append, fields, remove } = useFieldArray<
    ContentModuleUpdateModeInputs
  >({
    name: "contentModuleCards",
    control,
  } as never);

  const addInitialCard = (
    <Button
      outlinedPrimary
      flex
      type="button"
      onClick={() =>
        append({
          uiOrder: fields.length + 1,
          title: "",
          contentModuleCardParts: [],
        })
      }
    >
      <span className="material-symbols-outlined">add</span>
      Add Card
    </Button>
  );

  const handleRemove = useCallback((index: number) => remove(index), [remove]);

  if (fields.length === 0) {
    return (
      <LessonCardsContainer>
        <EmptyState
          button={addInitialCard}
          hasHeader={false}
          hasSearch={false}
          text="No Cards Added Yet"
          title=""
        />
      </LessonCardsContainer>
    );
  }

  return (
    <LessonCardsContainer>
      {fields.map((card, cardIndex) => (
        <LessonUpdateModeCard
          key={card.id}
          cardIndex={cardIndex}
          handleRemove={() => handleRemove(cardIndex)}
        />
      ))}
      <LessonCardsFooter>{addInitialCard}</LessonCardsFooter>
    </LessonCardsContainer>
  );
};
