import { useEffect, useState } from "react";
import { useGetPatientInsulinLogs } from "../../../hooks/patient-insulin-logs-hooks/use-get-patient-insulin-logs";
import { PatientInsulinLogDTO } from "../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";
import { useParams } from "react-router-dom";

type UseGetPatientInsulinLogDTOs = {
  patientInsulinLogDTOs: PatientInsulinLogDTO[];
  isLoaded: boolean;
};

export function useGetPatientInsulinLogDTOs(
  startDate?: string,
  endDate?: string
): UseGetPatientInsulinLogDTOs {
  const { id } = useParams();
  const [patientInsulinLogDTOs, setPatientInsulinLogDTOs] = useState<
    PatientInsulinLogDTO[]
  >([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const getPatientInsulinLogs = useGetPatientInsulinLogs();

  useEffect(() => {
    const fetch = async () => {
      getPatientInsulinLogs(Number(id!), startDate, endDate).then(
        (response) => {
          setPatientInsulinLogDTOs(response);
          setIsLoaded(true);
        }
      );
    };

    fetch();
  }, [id, getPatientInsulinLogs, setIsLoaded, startDate, endDate]);

  return { patientInsulinLogDTOs, isLoaded };
}
