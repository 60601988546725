import { GenericActionsListItemItem } from "../../../../../../components/generic-actions-list/styled-generic-actions-list";
import { Modal } from "../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { PatientNoteDTO } from "../../../../../../models/patient-note-dtos/patient-note-dto";
import { PatientProfileNotesModalBody } from "../patient-profile-notes-modal-body/patient-profile-notes-modal-body";

type PatientProfileEditNotesModalProps = {
  patientNoteDTO: PatientNoteDTO;
};

export const PatientProfileEditNotesModal = (
  props: PatientProfileEditNotesModalProps
) => {
  const { patientNoteDTO } = props;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <>
      <GenericActionsListItemItem hasIcon onClick={openModal}>
        <img src="/img/medications/actions/note.svg" alt="note icon" />
        Edit note
      </GenericActionsListItemItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <PatientProfileNotesModalBody
          key={modalBodyKey}
          onClose={closeModal}
          state={{ kind: "Edit", data: patientNoteDTO }}
        />
      </Modal>
    </>
  );
};
