import { viewSurgeries } from "../../../utils/patient-profile-utils";
import { usePatientProfileMedicalHistory } from "../../context/loadable-patient-profile-medical-history";
import {
  PatientProfileMedicalHistoryItemContainer,
  PatientProfileMedicalHistoryItemTextContainer,
  PatientProfileMedicalHistoryItemTitleContainer,
} from "../styled-profile-medical-history";

export const PatientProfileMedicalHistoryReadSurgery = () => {
  const { patientMedicalHistoryDTO } = usePatientProfileMedicalHistory();

  const surgeries = patientMedicalHistoryDTO.nonBariatricSurgeries;

  return (
    <PatientProfileMedicalHistoryItemContainer>
      <PatientProfileMedicalHistoryItemTitleContainer>
        <img src="/img/patient-profile/medical-history/sugar.svg" alt="" />
        <p>Surgery</p>
      </PatientProfileMedicalHistoryItemTitleContainer>
      <PatientProfileMedicalHistoryItemTextContainer>
        <p>
          {surgeries !== undefined && surgeries.length > 0
            ? surgeries.map(
                (item, index) =>
                  `${
                    item.surgery !== "Other" ? viewSurgeries(item.surgery) : ""
                  }${
                    surgeries.length > 1 &&
                    surgeries[index + 1] &&
                    surgeries[index + 1].surgery !== "Other"
                      ? ", "
                      : ""
                  }`
              )
            : "--"}
        </p>
        <p>
          {surgeries !== undefined && surgeries.length > 0
            ? surgeries.map(
                (item, index) =>
                  `${
                    item.surgery === "Other" && item.other !== undefined
                      ? `Other: ${item.other}`
                      : ""
                  }`
              )
            : ""}
        </p>
      </PatientProfileMedicalHistoryItemTextContainer>
    </PatientProfileMedicalHistoryItemContainer>
  );
};
