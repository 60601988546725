import styled from "styled-components/macro";

export const VideoModalContainer = styled.div<{ viewPlansModal?: boolean }>`
  background: ${({ theme }) => theme.colors.containerColor};
  border-radius: 0.75rem;
  width: 870px;
  height: 700px;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.z600};
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    position: fixed;
    border-radius: ${({ viewPlansModal }) =>
      viewPlansModal ? "0rem" : "2.625rem 2.625rem 0rem 0rem"};
    top: ${({ viewPlansModal }) => (viewPlansModal ? "0" : "unset")};
    bottom: ${({ viewPlansModal }) => (viewPlansModal ? "unset" : "0")};
    height: 50%;
    overflow-y: auto;
  }

  iframe {
    width: 100%;
    height: 100%;
    border-radius: ${({ viewPlansModal }) =>
      viewPlansModal ? "0rem" : "0.75rem"};
  }
`;

export const VideoModalCloseIcon = styled.span`
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  color: ${({ theme }) => theme.colors.containerColor};
  cursor: pointer;
  z-index: ${({ theme }) => theme.zIndex.z700};
`;
