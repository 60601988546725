import { Controller, useForm } from "react-hook-form";
import { ModalForm } from "../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../styles/classes/reusable-classes";
import { usePlansGoals } from "../../../context/goals-context";
import { AddGoalModalTextAreaContainer } from "../../styles";

type AddNutritionModalBodyProps = {
  onClose: () => void;
};

type NutritionInputs = {
  nutritionGoal?: string;
};

export const AddNutritionModalBody = (props: AddNutritionModalBodyProps) => {
  const { onClose } = props;

  const { handleSubmit, control, watch } = useForm<NutritionInputs>({
    defaultValues: {
      nutritionGoal: undefined,
    },
  });

  const { handleAddGoal } = usePlansGoals();
  const nutritionGoal = watch("nutritionGoal");

  const onSubmit = handleSubmit(async () => {
    handleAddGoal({
      goal: nutritionGoal !== undefined ? nutritionGoal : "",
      goalType: "NutritionInstruction",
    });

    onClose();
  });

  return (
    <ModalForm width={670} height={340} onSubmit={onSubmit}>
      <ModalHeader
        title="Add a Nutrition item"
        onModalClose={onClose}
        subTitle="Add nutrition item for your patient"
      />

      <Controller
        name="nutritionGoal"
        control={control}
        render={({ field: { value, onChange } }) => (
          <AddGoalModalTextAreaContainer>
            <textarea
              placeholder="Write here..."
              value={value === undefined ? "" : value}
              onChange={(e) =>
                onChange(e.target.value === undefined ? "" : e.target.value)
              }
            ></textarea>
          </AddGoalModalTextAreaContainer>
        )}
      />

      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={nutritionGoal === undefined || nutritionGoal === ""}
        >
          Yes, Add
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
