import { ChangeEvent, DragEvent, useEffect, useRef, useState } from "react";
import {
  ImageUploadClearIcon,
  ImageUploadContainer,
  ImageUploadImageContainer,
  ImageUploadImagesContainer,
} from "./styled-image-upload";
import {
  Button,
  ErrorText,
} from "../../../../../../styles/classes/reusable-classes";
import { Controller, useFormContext } from "react-hook-form";
import { AddNewMealModalInputs } from "../../add-new-meal";

type ImageUploadProps = {
  imageFiles: Blob[] | null;
  setImageFiles: (imageFiles: Blob[] | null) => void;
  requiredImageOrTextNote: boolean;
};

type ImageUploadState =
  | { state: "Idle" }
  | { state: "Upload"; previewUrls: string[] }
  | { state: "Drag"; dragActive: boolean };

export const ImageUpload = (props: ImageUploadProps) => {
  const { imageFiles, setImageFiles, requiredImageOrTextNote } = props;

  const [imageUploadState, setImageUploadState] = useState<ImageUploadState>({
    state: "Idle",
  });

  useEffect(() => {
    if (
      imageUploadState.state === "Upload" &&
      imageUploadState.previewUrls.length === 0
    ) {
      setImageUploadState({ state: "Idle" });
      setImageFiles(null);
    }
  }, [imageUploadState, setImageFiles]);

  const {
    control,
    formState: { errors },
  } = useFormContext<AddNewMealModalInputs>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const isImageUploaded = imageFiles && imageUploadState.state === "Upload";

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const selectedFiles = Array.from(files);
      setImageFiles(selectedFiles);

      const readerPromises = selectedFiles.map((file) => {
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(readerPromises).then((previewUrls) => {
        setImageUploadState({
          state: "Upload",
          previewUrls,
        });
      });
    }
  };

  const handleDrag = (e: DragEvent<HTMLFormElement | HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setImageUploadState({
        state: "Drag",
        dragActive: true,
      });
    } else if (e.type === "dragleave") {
      setImageUploadState({
        state: "Drag",
        dragActive: false,
      });
    }
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;

    if (files && files.length > 0) {
      const selectedFiles = Array.from(files);
      setImageFiles(selectedFiles);

      const readerPromises = selectedFiles.map((file) => {
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(readerPromises).then((previewUrls) => {
        setImageUploadState({
          state: "Upload",
          previewUrls,
        });
      });
    }
  };

  const handleRemoveImage = (index: number) => {
    if (imageFiles !== null && imageUploadState.state === "Upload") {
      const updatedImageFiles = [...imageFiles];
      updatedImageFiles.splice(index, 1);
      setImageFiles(updatedImageFiles);

      const updatedPreviewUrls = [...imageUploadState.previewUrls];
      updatedPreviewUrls.splice(index, 1);
      setImageUploadState({
        state: "Upload",
        previewUrls: updatedPreviewUrls,
      });
    }
  };

  return (
    <Controller
      control={control}
      name="patientMealImageFiles"
      rules={{ required: requiredImageOrTextNote ? true : false }}
      render={() => (
        <ImageUploadContainer
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          $dragActive={
            imageUploadState.state === "Drag" && imageUploadState.dragActive
          }
          onClick={() =>
            !isImageUploaded ? fileInputRef.current?.click() : {}
          }
        >
          {isImageUploaded ? (
            <ImageUploadImagesContainer
              $imagesArrayLength={imageUploadState.previewUrls.length}
            >
              {imageUploadState.previewUrls.map((previewUrl, index) => (
                <ImageUploadImageContainer
                  key={previewUrl}
                  $imagesArrayLength={imageUploadState.previewUrls.length}
                >
                  <img src={previewUrl} alt={`Meal ${index + 1}`} />
                  <ImageUploadClearIcon
                    className="material-symbols-outlined"
                    onClick={() => handleRemoveImage(index)}
                  >
                    close
                  </ImageUploadClearIcon>
                </ImageUploadImageContainer>
              ))}
            </ImageUploadImagesContainer>
          ) : (
            <>
              <span className="material-symbols-outlined">image</span>
              <p>Drag and drop Images</p>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                multiple
                onChange={handleFileChange}
              />
              {!isImageUploaded && (
                <Button outlineNoBorder type="button">
                  Upload Images
                </Button>
              )}
            </>
          )}
          {errors.patientMealImageFiles?.type === "required" && (
            <ErrorText>Upload an image or write a short note</ErrorText>
          )}
        </ImageUploadContainer>
      )}
    />
  );
};
