import { Avatar, useChatContext } from "stream-chat-react";
import {
  MessagingChannelListHeaderBottom,
  MessagingChannelListHeaderContainer,
  MessagingChannelListHeaderTop,
  MessagingChannelListHeaderTopCloseSidebarIcon,
  MessagingChannelListHeaderTopIcons,
  MessagingChannelListHeaderTopUserInfo,
} from "./styled-messaging-channel-list-header";
import { memo, useCallback, useState } from "react";
import { Channel, SearchAPIResponse, UserResponse } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { MessagingChannelListHeaderSearchResults } from "./messaging-channel-list-header-search-results/messaging-channel-list-header-search-results";
import { StreamChatGenerics } from "../../../../types/stream-chat-types";
import { useChatTokenContext } from "../../../../chat-providers/chat-token-context";
import { CreateMessagingChannel } from "./create-messaging-channel/create-messaging-channel";
import { NoLogoLoader } from "../../../../../../components/loaders/no-logo-loader/no-logo-loader";
import { useChatNotifications } from "../../../../chat-providers/chat-notifications-context";

type MessagingChannelListHeaderProps = {
  handleHideSidebar: () => void;
};

export const MessagingChannelListHeader = memo(
  (props: MessagingChannelListHeaderProps) => {
    const { handleHideSidebar } = props;

    const { client, channel: activeChannel } =
      useChatContext<StreamChatGenerics>();
    const { chatTokenDTO } = useChatTokenContext();

    const {
      id,
      image = "/img/chat/user.svg",
      name = "Example User",
    } = client.user || {};

    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const [allChannels, setAllChannels] =
      useState<Channel<StreamChatGenerics>[]>();

    const [allUsers, setAllUsers] =
      useState<UserResponse<DefaultStreamChatGenerics>[]>();

    const [searchApiResponse, setSearchApiResponse] =
      useState<SearchAPIResponse<StreamChatGenerics>>();

    const [isCreating, setIsCreating] = useState(false);

    const getChannelsOrUsersOrMessages = useCallback(
      async (text: string) => {
        try {
          const channelResponse = client.queryChannels({
            type: "messaging",
            name: { $autocomplete: text },
            members: { $in: [chatTokenDTO.chatTokenDTO.streamChatUserId] },
          });

          const userResponse = client.queryUsers({
            id: { $ne: client.userID || "" },
            $and: [{ name: { $autocomplete: text } }],
          });

          const searchFilters = {
            members: { $in: [chatTokenDTO.chatTokenDTO.streamChatUserId] },
          };

          const search = await client.search(searchFilters, text);

          const [channels, { users }] = await Promise.all([
            channelResponse,
            userResponse,
          ]);

          setAllChannels([...channels]);
          setAllUsers([...users]);
          setSearchApiResponse(search);
        } catch (event) {
          setSearchTerm("");
        }

        setLoading(false);
      },
      [chatTokenDTO.chatTokenDTO.streamChatUserId, client]
    );

    const onSearch = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        setLoading(true);
        setSearchTerm(event.target.value);
        if (!event.target.value) return;

        getChannelsOrUsersOrMessages(event.target.value);
      },
      [getChannelsOrUsersOrMessages]
    );

    const { requestingChatNotificationsPermission, enableNotification } =
      useChatNotifications();

    return (
      <>
        <MessagingChannelListHeaderContainer>
          <MessagingChannelListHeaderTop>
            <MessagingChannelListHeaderTopUserInfo>
              <Avatar image={image} name={name} size={40} />
              <p>{name !== undefined ? name : id}</p>
            </MessagingChannelListHeaderTopUserInfo>
            <MessagingChannelListHeaderTopIcons>
              <div
                className="cursor-pointer"
                onClick={() => setIsCreating(true)}
              >
                <img src="/img/edit.svg" alt="add new channel" />
              </div>
              {"Notification" in window &&
                Notification.permission === "default" &&
                !requestingChatNotificationsPermission && (
                  <div className="cursor-pointer" onClick={enableNotification}>
                    <img
                      className="bell-icon"
                      src="/img/chat/bell.svg"
                      alt="bell"
                    />
                  </div>
                )}
              {requestingChatNotificationsPermission && <NoLogoLoader />}
            </MessagingChannelListHeaderTopIcons>
            {activeChannel !== undefined && (
              <MessagingChannelListHeaderTopCloseSidebarIcon
                onClick={handleHideSidebar}
              >
                <img src="/img/chat/close.svg" alt="close sidebar" />
              </MessagingChannelListHeaderTopCloseSidebarIcon>
            )}
          </MessagingChannelListHeaderTop>
          <MessagingChannelListHeaderBottom>
            <input
              onChange={onSearch}
              placeholder="Search"
              type="text"
              value={searchTerm}
            />
          </MessagingChannelListHeaderBottom>
          <MessagingChannelListHeaderSearchResults
            loading={loading}
            showList={searchTerm !== ""}
            allChannels={allChannels}
            allUsers={allUsers}
            searchApiResponse={searchApiResponse}
          />
        </MessagingChannelListHeaderContainer>
        <CreateMessagingChannel
          show={isCreating}
          onClose={() => setIsCreating(false)}
        />
      </>
    );
  }
);
