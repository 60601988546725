import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  flexCenterSpaceBetween,
  regularMediumFont,
  regularRegularFont,
  regularSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";
import { Button } from "../../../../../styles/classes/reusable-classes";
import { AutoSelectForMedicationsInputLabel } from "../../../../../components/auto-select-for-medications/styled-auto-select-for-medications";
import { DropdownButton } from "../../../../../components/dropdown/styled-dropdown";

export const CarbCounterFoodLoggingSelectedPatientMealAnalysisContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
  display: grid;
  max-height: 296px;
  overflow-y: auto;

  ${Button} {
    margin: 0 auto;
  }

  img {
    width: 300px;
    margin: 0 auto;
  }
`;

export const CarbCounterFoodLoggingSelectedPatientMealResponseTitleContainer = styled.div`
  ${flexCenterSpaceBetween}
  gap: 0px 32px;
  width: 50%;

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
  }

  span {
    color: ${({ theme }) => theme.newColors.byOtida};
    cursor: pointer;
  }
`;

export const CarbCounterFoodLoggingSelectedPatientMealResponseTableContainer = styled.div`
  max-width: 1500px;
  overflow-x: auto;
`;

export const CarbCounterFoodLoggingSelectedPatientMealResponseMealFoodNameContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 8px 0px;
  gap: 0px 8px;

  p {
    ${regularSemiBoldFont}
    margin: 0px;
    width: 250px;
    word-break: break-all;
  }

  ${Button} {
    margin: 0px auto 0px 0px !important;
  }
`;

export const CarbCounterFoodLoggingSelectedPatientMealResponseFoodItemsDeleteIcon = styled.div`
  width: 20px;
  ${flexCenterCenter}
  cursor: pointer;
  margin-right: 12px;

  img {
    width: 100%;
  }
`;

export const CarbCounterFoodLoggingSelectedPatientMealResponseMealFoodNameInfoIcon = styled.div`
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.colors.primaryColorAlt};
    font-size: 20px;
  }
`;

export const CarbCounterFoodLoggingSelectedPatientMealResponseMealFoodNameInfoContainer = styled.div`
  p {
    ${regularSemiBoldFont}
    margin: 0px;
    width: 220px;
    word-break: break-all;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const CarbCounterFoodLoggingSelectedPatientMealQuantityContainer = styled.div`
  width: 150px;
  text-align: center;
  margin: 0px 24px 0px 32px;
  ${flexCenter}
  gap: 0px 16px;

  button,
  input {
    border-radius: 2px;
    background: ${({ theme }) => theme.colors.borderColor};
  }

  input {
    padding: 10px;
    width: 60px;
    height: 40px;
    outline: 0px;
    border: 0px;
    text-align: center;

    ::-webkit-outer-spin-button {
      display: none;
    }

    ::-webkit-inner-spin-button {
      display: none;
    }
  }

  button {
    ${regularRegularFont}
  }
`;

export const CarbCounterFoodLoggingSelectedPatientMealResponseDropdownContainer = styled.div`
  min-width: 280px;

  ${DropdownButton} {
    padding: 8px;
  }
`;

export const CarbCounterFoodLoggingSelectedPatientMealResponseAutoSelectContainer = styled.div`
  width: 280px;
  height: 48px;

  ${AutoSelectForMedicationsInputLabel} {
    display: none;
  }
`;

export const CarbCounterFoodLoggingSelectedPatientMealResponseAddMoreFoodsContainer = styled.div`
  ${flexCenter}
  padding: 8px 0px;

  p {
    ${regularSemiBoldFont}
    margin: 0px;
    width: 250px;
    word-break: break-all;
  }

  ${Button} {
    margin: 0px auto 0px 0px !important;
  }
`;
