import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PatientMealDTO } from "../../models/patient-meal-dtos/patient-meal-dto";
import { CreatePatientMealDTO } from "../../models/patient-meal-dtos/create-patient-meal-dto";

export function useAddPatientMeal(): (
  patientId: number,
  createPatientMealDTO: CreatePatientMealDTO,
  patientMealImageFiles: Blob[],
  patientMealVoiceNoteFiles: Blob[]
) => Promise<PatientMealDTO> {
  const client = useClient();

  return useCallback(
    async (
      patientId,
      createPatientMealDTO,
      patientMealImageFiles,
      patientMealVoiceNoteFiles
    ) => {
      const formData = new FormData();

      formData.append(
        "createPatientMealDTO",
        JSON.stringify(createPatientMealDTO)
      );

      for (const patientMealImageFile of patientMealImageFiles) {
        formData.append("patientMealImageFiles", patientMealImageFile);
      }

      for (const patientMealVoiceNoteFile of patientMealVoiceNoteFiles) {
        formData.append("patientMealVoiceNoteFiles", patientMealVoiceNoteFile);
      }

      const response = await client.post<PatientMealDTO>(
        `${PATIENTS_PATH}/${patientId}/meals`,
        formData
      );

      return response.data;
    },
    [client]
  );
}
