import { useContent } from "../context/loadable-content-context";
import { ContentCoursesTable } from "./content-courses-table/content-courses-table";
import { EmptyState } from "../../../components/empty-state/empty-state";
import { Button } from "../../../styles/classes/reusable-classes";
import { Link } from "react-router-dom";

export const ContentCourses = () => {
  const { contentModuleDTOs } = useContent();

  if (contentModuleDTOs.length === 0) {
    return (
      <EmptyState
        button={
          <Link to={`../courses/create-new-course`}>
            <Button flex type="button">
              <span className="material-symbols-outlined">add</span>
              Add Course
            </Button>
          </Link>
        }
        hasHeader={false}
        hasSearch={false}
        text="No Courses Added Yet"
        title=""
      />
    );
  }

  return <ContentCoursesTable />;
};
