import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
} from "../../../../../styles/classes/gloabl-classes";

export const ViewWeeklyPlansHeaderContianer = styled.header`
  ${flexCenter}
  flex-direction: column;
  margin-bottom: 24px;
`;

export const ViewWeeklyPlansHeaderBackIcon = styled.span`
  rotate: 180deg;
  position: absolute;
  top: 10px;
  right: 16px;
  z-index: 100000;
  font-size: 16px;
  cursor: pointer;

  @media screen and (min-width: 1024px) {
    right: 20%;
    font-size: 24px;
  }
`;

export const ViewWeeklyPlansTitleCard = styled.div`
  background: ${({ theme }) => theme.colors.inputColor};
  background-size: 40%;
  height: 152px;
  width: 100%;
  padding: 1rem 1rem 0rem;
  ${flexCenterSpaceBetween}
  flex-direction: row-reverse;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    justify-content: center;
  }
`;

export const ViewWeeklyPlansList = styled.ul`
  margin-top: 24px;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fit, 343px);
  justify-content: center;
  padding: 0px 16px;
`;

export const ViewWeeklyPlansListItem = styled.li`
  ${flexCenter}
  flex-direction: row-reverse;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  width: 100%;

  div:first-child {
    margin: 0rem 0rem 0rem 1rem;
  }

  div:nth-child(2) {
    p {
      margin-bottom: 4px;
    }
  }
`;
