import { useMemo, useState } from "react";
import { Pagination } from "../../../../components/pagination/pagination";
import { TablePaginationContainer } from "../../../../components/table/styled-table";
import { Table } from "../../../../components/table/table";
import { sortMedicationsByName } from "../utils/medications-utils";
import { MedicationsTableHeadings } from "./medications-table-headings";
import { MedicationsTableRows } from "./medications-table-rows";
import { useNavigate, useParams } from "react-router-dom";
import {
  MedicationsSubHeader,
  MedicationsSubHeaderContainer,
} from "./styled-medications-table";
import { SearchInput } from "../../../../styles/atoms/search-input";
import { CreateMedication } from "../components/create-medication/create-medication";
import { EmptyState } from "../../../../components/empty-state/empty-state";
import { useMedications } from "../context/loadable-medications-context";

export const MedicationsTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [tableRowsPerPage, setTableRowsPerPage] = useState(40);
  const { page } = useParams();
  const navigate = useNavigate();

  const { patientMedicationDTOs } = useMedications();

  const sorted = useMemo(() => sortMedicationsByName(patientMedicationDTOs), [
    patientMedicationDTOs,
  ]);

  const filtered = useMemo(
    () =>
      sorted.filter((item) => {
        if (searchTerm === "") {
          return item;
        } else if (
          item.medication.name.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return item;
        } else {
          return 0;
        }
      }),
    [searchTerm, sorted]
  );

  const maxPage = Math.ceil(filtered.length / tableRowsPerPage);

  const currentOrMaxPage = Math.min(Number(page), maxPage);

  const currentPageMedications = useMemo(() => {
    const indexOfLastEntry = currentOrMaxPage * tableRowsPerPage;
    const indexOfFirstEntry = indexOfLastEntry - tableRowsPerPage;

    return filtered.slice(indexOfFirstEntry, indexOfLastEntry);
  }, [filtered, tableRowsPerPage, currentOrMaxPage]);

  function onSearchTermChange(searchTerm: string) {
    navigate(`../table/1`);
    setSearchTerm(searchTerm);
  }

  return (
    <>
      {patientMedicationDTOs.length === 0 ? (
        <EmptyState
          title="Medications"
          text="There is no medication added yet"
          hasSearch={true}
          hasHeader={true}
          button={<CreateMedication />}
          dataTestId="medications-table-header"
        />
      ) : (
        <>
          <MedicationsSubHeaderContainer>
            <MedicationsSubHeader>
              <p>Medications</p>
              <SearchInput
                valueProp={searchTerm}
                onChangeHandler={(e) => onSearchTermChange(e.target.value)}
              />
            </MedicationsSubHeader>
            <CreateMedication />
          </MedicationsSubHeaderContainer>
          <div style={{ paddingBottom: "5rem" }}>
            <Table
              tableHeadings={<MedicationsTableHeadings />}
              tableRows={
                <MedicationsTableRows
                  patientMedicationData={currentPageMedications}
                />
              }
            />
          </div>
          <TablePaginationContainer>
            <Pagination
              totalRowsCount={filtered.length}
              tableRowsPerPage={tableRowsPerPage}
              setRowsPerPage={setTableRowsPerPage}
              navigateBasePath="/table"
            />
          </TablePaginationContainer>
        </>
      )}
    </>
  );
};
