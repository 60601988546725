import Chart from "react-google-charts";
import { useMemo } from "react";
import { DayAggregate } from "./utils/build-days-aggregates";
import { buildColumns } from "./utils/build-columns";
import { buildRows } from "./utils/build-rows/build-rows";
import { buildOptions } from "./utils/build-options";
import { PatientDTO } from "../../models/patient-dtos/patient-dto";

type CgmGoogleChartProps = {
  patientDTO: PatientDTO;
  dayAggregate: DayAggregate;
  feedbackHeight?: number;
  chartAreaOptionsProp?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
};

export const CgmGoogleChart = (props: CgmGoogleChartProps) => {
  const { patientDTO, dayAggregate, feedbackHeight, chartAreaOptionsProp } =
    props;

  const { data, options } = useMemo(() => {
    const {
      maxGlucose,
      minGlucose,
      inRangeNumbers,
      date,
      readings,
      meals,
      medicines,
      source,
      patientMealDTO,
      patientActivityLogs,
      patientInsulinLogDTOs,
    } = dayAggregate;

    const max = maxGlucose > 250 ? maxGlucose + 25 : 250;
    const min = minGlucose < 40 ? minGlucose : 40;

    const vAxisTicks = [...inRangeNumbers, max];
    const reverseVAxisTicks = [...vAxisTicks].reverse();

    const columns = buildColumns();
    const rows = buildRows(
      date,
      readings,
      meals,
      medicines,
      reverseVAxisTicks,
      patientMealDTO,
      patientActivityLogs,
      patientInsulinLogDTOs,
      patientDTO
    );

    const data = [columns, ...rows];

    const chartAreaOptions =
      chartAreaOptionsProp !== undefined
        ? chartAreaOptionsProp
        : {
            left: 35,
            top: 20,
            bottom: 50,
            right: 30,
          };

    const options = buildOptions(
      date,
      max,
      min,
      vAxisTicks,
      feedbackHeight,
      chartAreaOptions,
      source
    );

    return { data, options };
  }, [dayAggregate, patientDTO, chartAreaOptionsProp, feedbackHeight]);

  return <Chart chartType="ComboChart" data={data} options={options} />;
};
