import { AxisModel } from "@syncfusion/ej2-react-charts";
import { DAILY_MEALS_GRAPHS_RESPONE_SPAN_IN_MINUTES } from "../../../constants/daily-meals-graphs-constants";

export function buildPrimaryXAxis(): AxisModel {
  return {
    title: "Time",
    valueType: "Double",
    edgeLabelPlacement: "Shift",
    majorGridLines: {
      color: "transparent",
      width: 0,
    },
    interval: 15,
    minimum: 0,
    maximum: DAILY_MEALS_GRAPHS_RESPONE_SPAN_IN_MINUTES,
  };
}
