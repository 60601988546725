import {
  LabResultsCardChart,
  LabResultsCardChartContainer,
  LabResultsCardCharts,
} from "./styled-lab-results-chart";
import { roundTo3DecimalPlaces } from "../../../../utils/math-utils";
import { Temporal } from "temporal-polyfill";
import { PatientLabTestResultDTO } from "../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";

type LabResultsChartProps = {
  labTest: PatientLabTestResultDTO[];
};

export const LabResultsChart = (props: LabResultsChartProps) => {
  const { labTest } = props;

  const maxResult = Math.max(...labTest.map((item) => item.result));
  const labTestsCharts = labTest.map((item, index) => {
    const chartDate = Temporal.PlainDate.from(item.date).toLocaleString(
      "en-UK",
      {
        day: "numeric",
        month: "short",
      }
    );

    return (
      <LabResultsCardChartContainer key={index}>
        <LabResultsCardChart
          height={(item.result / maxResult) * 100}
          current={index === labTest.length - 1}
          result={roundTo3DecimalPlaces(item.result)}
          date={chartDate}
        />
      </LabResultsCardChartContainer>
    );
  });

  return <LabResultsCardCharts>{labTestsCharts}</LabResultsCardCharts>;
};
