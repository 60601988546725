import { Channel, SearchAPIResponse, UserResponse } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { Avatar, useChatContext } from "stream-chat-react";
import {
  MessagingChannelListHeaderSearchResultsContainer,
  MessagingChannelListHeaderSearchResultsItem,
} from "./styled-messaging-channel-list-header-search-results";
import { OtidaLoader } from "../../../../../../../components/loaders/otida-loader/otida-loader";
import { StreamChatGenerics } from "../../../../../types/stream-chat-types";
import { memo } from "react";

type MessagingChannelListHeaderSearchResultsProps = {
  loading: boolean;
  showList: boolean;
  allChannels?: Channel<StreamChatGenerics>[];
  allUsers?: UserResponse<DefaultStreamChatGenerics>[];
  searchApiResponse?: SearchAPIResponse<StreamChatGenerics>;
};

export const MessagingChannelListHeaderSearchResults = memo(
  (props: MessagingChannelListHeaderSearchResultsProps) => {
    const { loading, showList, allChannels, allUsers } = props;

    const { setActiveChannel } = useChatContext<StreamChatGenerics>();

    return (
      <MessagingChannelListHeaderSearchResultsContainer showList={showList}>
        {loading ? (
          <OtidaLoader />
        ) : (
          <>
            {allChannels?.map((channel) => (
              <MessagingChannelListHeaderSearchResultsItem
                key={channel.id}
                onClick={() => setActiveChannel(channel)}
              >
                <Avatar image="/img/chat/group.svg" size={20} shape="circle" />
                <p>{channel.data?.name}</p>
              </MessagingChannelListHeaderSearchResultsItem>
            ))}
            {allUsers?.map((user) => {
              const userWhatsAppId =
                user.whatsAppId !== undefined ? ` (${user.whatsAppId})` : "";

              return (
                <MessagingChannelListHeaderSearchResultsItem key={user.id}>
                  <Avatar
                    image={
                      user.image !== undefined
                        ? user.image
                        : "/img/chat/user.svg"
                    }
                    size={20}
                    shape="circle"
                  />
                  <p>
                    {user.name !== undefined ? user.name : user.id}
                    {userWhatsAppId}
                  </p>
                </MessagingChannelListHeaderSearchResultsItem>
              );
            })}
            {/* <h1>{searchApiResponse?.results.map((r) => r.message.text)}</h1> */}
          </>
        )}
      </MessagingChannelListHeaderSearchResultsContainer>
    );
  }
);
