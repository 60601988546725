import styled from "styled-components/macro";
import { flexCenter } from "../../../../../../../styles/classes/gloabl-classes";

export const ImmediateAttentionPatientsTableGenderItem = styled.li<{
  $selected: boolean;
}>`
  ${flexCenter}
  gap: 0px 8px;
  margin: 8px 0px;

  div {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.borderColor};
    border: 1px solid
      ${({ theme, $selected }) =>
        $selected ? theme.colors.primaryColorAlt : "transparent"};
    position: relative;
    cursor: pointer;

    ::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: ${({ theme, $selected }) =>
        $selected ? theme.colors.primaryColorAlt : "transparent"};
      position: absolute;
      inset: 0px;
      margin: auto auto;
    }
  }

  p {
    margin: 0px;
  }
`;
