import { Controller, useForm } from "react-hook-form";
import { ModalForm } from "../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../components/modal/modal-header/modal-header";
import { OrganizationAdminDTO } from "../../../../../../models/organization-admin-dtos/organization-admin-dto";
import {
  Button,
  ErrorText,
  Input,
  InputContainer,
  InputLabel,
  ModalFooter,
} from "../../../../../../styles/classes/reusable-classes";
import {
  useOrganizations,
  useReloadOrganizations,
} from "../../../../context/organizations-context";
import { Dropdown } from "../../../../../../components/dropdown/dropdown";
import { DropdownListItem } from "../../../../../../components/dropdown/styled-dropdown";
import { useAddOrganizationAdmin } from "../../../../../../hooks/organization-admin-hooks/use-add-organization-admin";
import { CreateOrganizationAdminDTO } from "../../../../../../models/organization-admin-dtos/create-organization-admin-dto";
import { useParams } from "react-router-dom";
import { useToastService } from "../../../../../../context/toast-service-context";
import { usePutOrganizationAdmin } from "../../../../../../hooks/organization-admin-hooks/use-put-organization-admin";
import { PutOrganizationAdminDTO } from "../../../../../../models/organization-admin-dtos/put-organization-admin-dto";

type OrganizationUserModalBodyProps = {
  onClose: () => void;
  kind: { mode: "Add" } | { mode: "Update"; user: OrganizationAdminDTO };
};

type OrganizationUserModalBodyInputs = {
  name?: string;
  email?: string;
  mobile?: string;
  roleId?: string;
};

export const OrganizationUserModalBody = (
  props: OrganizationUserModalBodyProps
) => {
  const { onClose, kind } = props;
  const { organizationID } = useParams();

  const { roleDTOs } = useOrganizations();

  const findRoleById = (id: string) =>
    roleDTOs.find((roleDTO) => roleDTO.id === Number(id));

  const addOrganizationAdmin = useAddOrganizationAdmin();
  const putOrganizationAdmin = usePutOrganizationAdmin();

  const { showToast } = useToastService();
  const reload = useReloadOrganizations();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<OrganizationUserModalBodyInputs>({
    defaultValues:
      kind.mode === "Add"
        ? {
            name: undefined,
            email: undefined,
            mobile: undefined,
            roleId: undefined,
          }
        : {
            name: kind.user.admin.name,
            email: kind.user.admin.email,
            mobile: kind.user.admin.mobile,
            roleId: kind.user.roleId.toString(),
          },
  });

  const onSubmit = handleSubmit(async (inputs) => {
    try {
      if (kind.mode === "Add") {
        const createOrganizationAdminDTO: CreateOrganizationAdminDTO = {
          name: inputs.name!,
          email: inputs.email!,
          mobile: inputs.mobile!,
          roleId: Number(inputs.roleId!),
        };

        await addOrganizationAdmin(
          Number(organizationID!),
          createOrganizationAdminDTO
        );
        reload();
        showToast("Success", `User Added Successfuly`);
        onClose();
      } else {
        const organizationAdminDTO: OrganizationAdminDTO = kind.user;

        const putOrganizationAdminDTO: PutOrganizationAdminDTO = {
          roleId: Number(inputs.roleId!),
        };

        await putOrganizationAdmin(
          organizationAdminDTO,
          putOrganizationAdminDTO
        );
        reload();
        showToast("Success", `User Updated Successfuly`);
        onClose();
      }
    } catch (error) {
      showToast("Error", `Failed to ${kind.mode} User`);
    }
  });

  return (
    <ModalForm onSubmit={onSubmit} height={kind.mode === "Add" ? 550 : 400}>
      <ModalHeader
        onModalClose={onClose}
        title={`${kind.mode === "Add" ? "Add User" : `Edit User`}`}
      />
      <div>
        {kind.mode === "Add" && (
          <>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <InputContainer margin>
                  <InputLabel>Name</InputLabel>
                  <Input
                    placeholder="Enter User Name"
                    value={value === undefined ? "" : value}
                    onChange={(e) =>
                      e.target.value === undefined
                        ? onChange("")
                        : onChange(e.target.value)
                    }
                  />
                  {errors.name?.type === "required" && (
                    <ErrorText>User name is requierd</ErrorText>
                  )}
                </InputContainer>
              )}
            />
            <Controller
              control={control}
              name="email"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <InputContainer margin>
                  <InputLabel>Email</InputLabel>
                  <Input
                    placeholder="Enter User Email"
                    value={value === undefined ? "" : value}
                    onChange={(e) =>
                      e.target.value === undefined
                        ? onChange("")
                        : onChange(e.target.value)
                    }
                  />
                  {errors.email?.type === "required" && (
                    <ErrorText>User email is requierd</ErrorText>
                  )}
                </InputContainer>
              )}
            />
            <Controller
              control={control}
              name="mobile"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <InputContainer margin>
                  <InputLabel>Mobile Number</InputLabel>
                  <Input
                    placeholder="Enter User Mobile Number"
                    value={value === undefined ? "" : value}
                    onChange={(e) =>
                      e.target.value === undefined
                        ? onChange("")
                        : onChange(e.target.value)
                    }
                  />
                  {errors.mobile?.type === "required" && (
                    <ErrorText>User mobile is requierd</ErrorText>
                  )}
                </InputContainer>
              )}
            />
          </>
        )}
        <Controller
          control={control}
          name="roleId"
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <InputContainer margin>
              <InputLabel>Role</InputLabel>
              <Dropdown
                variant="designed-ux"
                text={`${
                  value === undefined
                    ? "Select User Role"
                    : findRoleById(value)?.name
                }`}
                fullWidth
              >
                {roleDTOs.map((roleDTO) => (
                  <DropdownListItem
                    key={roleDTO.id}
                    variant="designed-ux"
                    onClick={() => onChange(roleDTO.id)}
                  >
                    {roleDTO.name}
                  </DropdownListItem>
                ))}
              </Dropdown>
              {errors.roleId?.type === "required" && (
                <ErrorText>User role is requierd</ErrorText>
              )}
            </InputContainer>
          )}
        />
      </div>
      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit">Confirm</Button>
      </ModalFooter>
    </ModalForm>
  );
};
