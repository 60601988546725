import styled from "styled-components/macro";
import {
  flexCenter,
  regularMediumFont,
  regularRegularFont,
} from "../../../../../styles/classes/gloabl-classes";

type NutritionReportTableHeadersThContainerType = {
  type: "Normal" | "Meat" | "Dairy" | "Fat";
};

export const NutritionReportTableHeadersTableTH = styled.th<NutritionReportTableHeadersThContainerType>`
  text-align: left;
  padding: 8px 16px;
  background: ${({ theme, type }) =>
    type === "Meat"
      ? theme.newColors.otidaTableCells
      : type === "Dairy"
      ? theme.newColors.redTableCells
      : type === "Fat"
      ? theme.newColors.greenTableCells
      : theme.colors.bodyColor};

  :first-child {
    border-top-left-radius: 16px;
    position: sticky;
    left: 0;
  }

  :last-child {
    border-top-right-radius: 16px;
  }
`;

export const NutritionReportTableHeadersThContainerTitle = styled.p`
  ${regularMediumFont}
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0px;
  width: max-content;
`;

export const NutritionReportTableHeadersThSubContainer = styled.div`
  ${flexCenter}
  gap: 0px 16px;

  p {
    ${regularRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
    margin: 0px;
    width: max-content;
  }
`;
