import { BASE_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { CarbCounterV1MealDTO } from "../../models/carb-counter-dtos/carb-counter-v1-meal-dto";
import { CarbCounterV1MealSummaryDTO } from "../../models/carb-counter-dtos/carb-counter-v1-meal-summary-dto";

export function useCalculateMealSummary(): (
  carbCounterV1MealDTO: CarbCounterV1MealDTO
) => Promise<CarbCounterV1MealSummaryDTO> {
  const client = useClient();

  return useCallback(
    async (carbCounterV1MealDTO) => {
      const response = await client.post<CarbCounterV1MealSummaryDTO>(
        `${BASE_PATH}/carb-counter/v1/meals/summary`,
        carbCounterV1MealDTO
      );

      return response.data;
    },
    [client]
  );
}
