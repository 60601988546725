import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  PatientProfileAutoSelectContainer,
  PatientProfileAutoSelectFormControlContainer,
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileTagList,
  PatientProfileTagListItem,
  PatientProfileTagsLabelContainer,
} from "../../../styles/patient-profile-styles/patient-profile-classes";
import { AutoSelect } from "../../../../../../components/auto-select/auto-select";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { PatientProfileEditProfileInputs } from "../patient-profile-edit-profile-modal";
import { useCallback, useState } from "react";
import { PutPatientProfileHealthBarrierDTO } from "../../../../../../models/patient-profile-dtos/put-patient-profile-health-barrier-dto";
import { useEnterKeypress } from "../../../../../../hooks/use-enter-keypress/use-enter-keypress";
import { usePatientProfileInformationHeader } from "../../context/loadable-patient-profile-information-header-context";

export const PatientProfileEditProfileHealthBarriersFormControl = () => {
  const { healthBarriers } = usePatientProfileInformationHeader();

  const { control, watch } = useFormContext<PatientProfileEditProfileInputs>();

  const [searchTerm, setSearchTerm] = useState("");
  const [autoSelectTagValue, setAutoSelectTagValue] = useState<
    PutPatientProfileHealthBarrierDTO | undefined
  >();

  const findHealthBarrierByID = useCallback(
    (healthBarrierName: string) =>
      healthBarriers.find(
        (healthBarrier) => healthBarrier.name === healthBarrierName
      ),
    [healthBarriers]
  );

  const findHealthBarrierByName = useCallback(
    (healthBarrierId: number) =>
      healthBarriers.find(
        (healthBarrier) => healthBarrier.id === healthBarrierId
      ),
    [healthBarriers]
  );

  const { append, fields, remove } = useFieldArray({
    name: "patientHealthBarriers",
    control,
  } as never);

  const patientHealthBarriersWatcher = watch("patientHealthBarriers");

  const handleAppend = () => {
    if (
      patientHealthBarriersWatcher !== undefined &&
      autoSelectTagValue !== undefined &&
      !patientHealthBarriersWatcher.some(
        (obj) => obj.healthBarrierId === autoSelectTagValue.healthBarrierId
      ) &&
      healthBarriers.some(
        (obj) => obj.id === autoSelectTagValue?.healthBarrierId
      )
    ) {
      append(autoSelectTagValue);
      setAutoSelectTagValue(undefined);
      setSearchTerm("");
    }
  };

  const { onEnterKeyPress } = useEnterKeypress(handleAppend);

  return (
    <PatientProfileModalFormControl>
      <PatientProfileTagsLabelContainer>
        <label>Health barriers</label>
        <span>Select or add own health barries</span>
      </PatientProfileTagsLabelContainer>
      <PatientProfileModalInputContainer>
        <Controller
          name="patientHealthBarriers"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <PatientProfileAutoSelectFormControlContainer>
                <PatientProfileAutoSelectContainer>
                  <AutoSelect
                    itemsArray={healthBarriers.map(
                      (healthBarrier) => healthBarrier.name
                    )}
                    onChange={(value: string) => {
                      // This value should never be returned as undefined
                      setAutoSelectTagValue({
                        healthBarrierId: findHealthBarrierByID(value)?.id!,
                      });
                      setSearchTerm(value);
                    }}
                    value={searchTerm}
                    fullWidth
                    placeHolder="Select Health Barrier"
                  />
                  <Button
                    type="button"
                    onClick={handleAppend}
                    onKeyDown={(e) => onEnterKeyPress(e)}
                    width={100}
                    height={40}
                    flex
                    outlineNoBorder
                  >
                    <span className="material-symbols-outlined">add</span> Add
                  </Button>
                </PatientProfileAutoSelectContainer>
                <PatientProfileTagList>
                  {fields.map((field, index) => (
                    <PatientProfileTagListItem
                      key={field.id}
                      variant="HelthBarrier"
                    >
                      {patientHealthBarriersWatcher !== undefined
                        ? findHealthBarrierByName(
                            patientHealthBarriersWatcher[index].healthBarrierId
                          )?.name
                        : ""}
                      <span
                        className="material-symbols-outlined"
                        onClick={() => remove(index)}
                      >
                        close
                      </span>
                    </PatientProfileTagListItem>
                  ))}
                </PatientProfileTagList>
              </PatientProfileAutoSelectFormControlContainer>
            );
          }}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
