import { PropsWithChildren, createContext, useContext } from "react";
import { PatientSmsOtpDTO } from "../../../../models/patient-sms-otp-dtos/patient-sms-otp-dto";

type SmsOtpsResponse = {
  smsOtps: PatientSmsOtpDTO[];
};

type Administration = {
  smsOtpsResponse: SmsOtpsResponse;
};

const AdministrationContext = createContext<Administration | undefined>(
  undefined
);

type AdministrationProviderProps = PropsWithChildren<{
  smsOtpsResponse: SmsOtpsResponse;
}>;

export function AdministrationProvider(props: AdministrationProviderProps) {
  const { children, smsOtpsResponse } = props;

  return (
    <AdministrationContext.Provider value={{ smsOtpsResponse }}>
      {children}
    </AdministrationContext.Provider>
  );
}

export function useAdministration(): Administration {
  const administration = useContext(AdministrationContext);

  if (administration === undefined) {
    throw Error(
      "useAdministration must be used within an AdministrationProvider"
    );
  }

  return administration;
}
