import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { BASE_PATH } from "../../constants/paths";
import { LoginDTO } from "../../models/auth-dtos/login-dto";
import { LoginResponseDTO } from "../../models/auth-dtos/login-response-dto";

export function useLogin(): (loginDTO: LoginDTO) => Promise<LoginResponseDTO> {
  const client = useClient();

  return useCallback(
    async (loginDTO) => {
      const response = await client.post<LoginResponseDTO>(
        `${BASE_PATH}/auth/login`,
        loginDTO
      );

      return response.data;
    },
    [client]
  );
}
