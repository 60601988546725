import { PatientActivityLogStatsActivityModeDayDTO } from "../../../../../../models/patient-activity-log-dtos/patient-activity-log-stats-activity-mode-day-dto";
import { PatientActivityLogStatsDTO } from "../../../../../../models/patient-activity-log-dtos/patient-activity-log-stats-dto";
import { PatientProfileLifeActivitiesTooltipProps } from "../patient-profile-life-activities-tooltip/patient-profile-life-activities-tooltip";

type AerobicSeriesDataSource = {
  x: string;
  y: number;
  aerobicActivity?: PatientActivityLogStatsActivityModeDayDTO[];
  tooltip: PatientProfileLifeActivitiesTooltipProps;
}[];

export function buildAerobicSeries(
  patientActivityLogStatsDTO: PatientActivityLogStatsDTO
): AerobicSeriesDataSource {
  const aerobicSeries: AerobicSeriesDataSource = [];

  const aerobicActivity = patientActivityLogStatsDTO.activityModes.find(
    (activity) => activity.activityMode?.name === "Aerobic Activity"
  )?.days;

  if (aerobicActivity !== undefined) {
    for (const day of aerobicActivity) {
      const date = day.date;
      const mins = day.mins;

      const tooltip: PatientProfileLifeActivitiesTooltipProps = {
        data: day.patientActivityLogs,
        activityModeName: "Aerobic Activity",
      };

      aerobicSeries.push({
        x: date,
        y: mins,
        aerobicActivity,
        tooltip: tooltip,
      });
    }
  }

  return aerobicSeries;
}
