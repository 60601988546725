import { GenericActionsListItemItem } from "../../../../../components/generic-actions-list/styled-generic-actions-list";
import { Modal } from "../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../components/modal/use-modal-helpers";
import { PatientMedicationDTO } from "../../../../../models/patient-medication-dto";
import { MedicationModalBody } from "../../medication-modal-body/medication-modal-body";

type EditMedicationProps = {
  row: PatientMedicationDTO;
};

export const EditMedication = (props: EditMedicationProps) => {
  const { row } = props;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <>
      <GenericActionsListItemItem hasIcon isPrimary onClick={openModal}>
        <img src="/img/edit.svg" alt="trash icon" />
        Edit
      </GenericActionsListItemItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <MedicationModalBody
          key={modalBodyKey}
          closeModal={closeModal}
          mode={{ kind: "PUT", data: row }}
        />
      </Modal>
    </>
  );
};
