import styled from "styled-components/macro";
import {
  Button,
  ErrorText,
} from "../../../../../../styles/classes/reusable-classes";

export const ImageUploadContainer = styled.div<{ $dragActive: boolean }>`
  display: grid;
  position: relative;
  align-content: center;
  justify-items: center;
  padding: 16px;
  margin: 16px auto;
  background: ${({ theme }) => theme.colors.containerColor};
  border: 2px dashed ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  cursor: pointer;
  opacity: ${({ $dragActive }) => ($dragActive ? "0.5" : "1")};
  height: 250px;
  max-width: 560px;
  min-width: 460px;
  width: fit-content;

  ${Button} {
    margin: 0px auto;
  }

  img {
    max-width: 240px;
    max-height: 172px;
  }

  input {
    display: none;
  }

  p {
    margin-bottom: 0px;
  }

  span {
    font-size: 64px;
  }

  ${ErrorText} {
    font-size: ${({ theme }) => theme.typography.fontSize.smFontSize};
  }
`;

export const ImageUploadImagesContainer = styled.div<{
  $imagesArrayLength: number;
}>`
  display: grid;
  gap: ${({ $imagesArrayLength }) =>
    $imagesArrayLength === 1 || $imagesArrayLength === 2 ? "8px" : "16px"};
  grid-template-columns: ${({ $imagesArrayLength }) =>
    $imagesArrayLength === 1
      ? "repeat(1, 1fr)"
      : $imagesArrayLength === 2
      ? "repeat(2, 1fr)"
      : "repeat(auto-fit, minmax(150px, 1fr))"};
  width: 100%;
  overflow-y: auto;
`;

export const ImageUploadImageContainer = styled.div<{
  $imagesArrayLength: number;
}>`
  position: relative;
  display: grid;
  place-items: center;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  padding: 18px;

  img {
    margin: ${({ $imagesArrayLength }) =>
      $imagesArrayLength === 1 ? "0px auto" : "0px"};
    width: ${({ $imagesArrayLength }) =>
      $imagesArrayLength === 1
        ? "100%"
        : $imagesArrayLength === 2
        ? "100%"
        : "150px"};
  }
`;

export const ImageUploadClearIcon = styled.span`
  cursor: pointer;
  font-size: 16px !important;
  position: absolute;
  top: 2px;
  right: 5px;
`;
