import styled from "styled-components";
import { LoaderContainer } from "../../../../../../components/loaders/otida-loader/styled-otida-loader";
import { EmptyStateBody } from "../../../../../../components/empty-state/styled-empty-state";

export const MealsAnalysisResponseTableContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  margin: 0px 16px;
  border-radius: 8px;
  width: 95%;
  margin: 0 auto;
  position: relative;

  table {
    width: 100%;
    border-collapse: collapse;

    ${LoaderContainer} {
      width: 100%;
      position: absolute;
    }

    ${EmptyStateBody} {
      width: 100%;
      position: absolute;
      height: 300px;
    }
  }
`;
