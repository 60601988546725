import { useMemo } from "react";
import {
  CGMReportResponseGlucoseStatisticsBody,
  CGMReportResponseGlucoseStatisticsBodyItem,
  CGMReportResponseGlucoseStatisticsBodyItemTitle,
  CGMReportResponseGlucoseStatisticsContainer,
  CGMReportResponseGlucoseStatisticsHeader,
} from "./styled-cgm-report-response-glucose-statistics";
import { roundTo1DecimalPlace } from "../../../../../../../utils/math-utils";
import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { useCGMReportLoadedResponse } from "../../../context/cgm-report-loaded-response-context";
import { useDateRange } from "../../../../../../../components/date-range/context/date-range-context";

export const CGMReportResponseGlucoseStatistics = () => {
  const { patientCGMEntryDTOs } = useCGMReportLoadedResponse();
  const { selectedDates } = useDateRange();

  const { average } = useMemo(() => {
    let total = 0;

    for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
      const glucoseReading = patientCGMEntryDTO.glucoseMGPerDL;

      total += glucoseReading;
    }

    const average = roundTo1DecimalPlace(total / patientCGMEntryDTOs.length);

    return { average };
  }, [patientCGMEntryDTOs]);

  const { gmi, warningGMIMessage } = useMemo(() => {
    let warningGMIMessage: string = "";

    // 3.31 + 0.02392 * average
    const glycatedAlbumin = 3.31;
    const glycatedProteins = 0.02392;

    if (selectedDates.count < 14) {
      warningGMIMessage =
        "Can't be calculated, date range is less than 14 days!";
    }

    const glycatedProteinsByAverage = glycatedProteins * average;
    const gmi = glycatedAlbumin + glycatedProteinsByAverage;

    return { gmi, warningGMIMessage };
  }, [selectedDates.count, average]);

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <CGMReportResponseGlucoseStatisticsContainer>
        <CGMReportResponseGlucoseStatisticsHeader>
          <p>Glucose Statistics</p>
        </CGMReportResponseGlucoseStatisticsHeader>
        <CGMReportResponseGlucoseStatisticsBody>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </CGMReportResponseGlucoseStatisticsBody>
      </CGMReportResponseGlucoseStatisticsContainer>
    );
  }

  return (
    <CGMReportResponseGlucoseStatisticsContainer>
      <CGMReportResponseGlucoseStatisticsHeader>
        <p>Glucose Statistics</p>
      </CGMReportResponseGlucoseStatisticsHeader>
      <CGMReportResponseGlucoseStatisticsBody>
        <CGMReportResponseGlucoseStatisticsBodyItem>
          <CGMReportResponseGlucoseStatisticsBodyItemTitle>
            Average Glucose
          </CGMReportResponseGlucoseStatisticsBodyItemTitle>
          <span>
            {average} <span>mg/dl</span>
          </span>
        </CGMReportResponseGlucoseStatisticsBodyItem>
        <CGMReportResponseGlucoseStatisticsBodyItem>
          <CGMReportResponseGlucoseStatisticsBodyItemTitle>
            Glucose Management Indicator (GMI)
            <span>
              Approximate A1C level based in average CGM Glucose level.
            </span>
          </CGMReportResponseGlucoseStatisticsBodyItemTitle>
          {selectedDates.count < 14 ? (
            <strong>{warningGMIMessage}</strong>
          ) : (
            <span>{roundTo1DecimalPlace(gmi)}%</span>
          )}
        </CGMReportResponseGlucoseStatisticsBodyItem>
      </CGMReportResponseGlucoseStatisticsBody>
    </CGMReportResponseGlucoseStatisticsContainer>
  );
};
