import { CreateCourseTargetAudienceController } from "./create-course-right-controllers/create-course-target-audience-controller";
import { CreateCourseDescriptionController } from "./create-course-left-controllers/create-course-description-controller";
import { CreateCourseNameController } from "./create-course-left-controllers/create-course-name-controller";
import { CreateCourseObjectiveController } from "./create-course-left-controllers/create-course-objective-controller";
import { CreateCourseThumbnailController } from "./create-course-right-controllers/create-course-thumbnail-controller";
import {
  ContentModuleFormControls,
  ContentModuleLeftFormControls,
  ContentModuleRightFormControls,
} from "../../../styles/content-modules-form-controllers/content-modules-form-controllers-css";

export const CreateCourseControllers = () => {
  return (
    <ContentModuleFormControls $lessonsUI={false}>
      <ContentModuleLeftFormControls $lessonsUI={false}>
        <CreateCourseNameController />
        <CreateCourseDescriptionController />
        <CreateCourseObjectiveController />
      </ContentModuleLeftFormControls>
      <ContentModuleRightFormControls $lessonsUI={false}>
        <CreateCourseThumbnailController />
        <CreateCourseTargetAudienceController />
      </ContentModuleRightFormControls>
    </ContentModuleFormControls>
  );
};
