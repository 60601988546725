import styled from "styled-components/macro";
import { flexCenterSpaceBetween } from "../../../../../styles/classes/gloabl-classes";
import {
  SidebarWidth,
  SinglePatientSidebarWidth,
} from "../../../../../styles/global-style";

export const CreatePlansFooterContainer = styled.footer`
  ${flexCenterSpaceBetween}
  background: ${({ theme }) => theme.colors.containerColor};
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 16px 24px;
  margin-top: auto;
  position: fixed;
  bottom: 0;
  left: calc(${SidebarWidth} + 24px);
  right: calc(${SinglePatientSidebarWidth} + 24px);

  @media screen and (max-width: 1024px) {
    right: 0px;
  }
`;
