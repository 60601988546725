import { PatientProfileTimeline } from "./patient-profile-timeline/patient-profile-timeline";
import {
  PatientProfileContainer,
  PatientProfileSection,
} from "../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileMedicalHistory } from "./patient-profile-medical-history/patient-profile-medical-history";
import { LoadablePatientProfileTimelineProvider } from "./context/loadable-patient-profile-timeline";
import { useGetPatientTimeline } from "../../../../hooks/patient-timeline-hooks/use-get-patient-timeline";
import { useParams } from "react-router-dom";
import { useCallback } from "react";
import { useLoadableData } from "../../../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../../../components/loadable-data-loading";

export const PatientProfileMedicalHistorySection = () => {
  const { id } = useParams();

  const getPatientTimeline = useGetPatientTimeline();

  const patientProfileTimelineCallback = useCallback(async () => {
    const patientId = parseInt(id!);

    const [patientTimelineDTO] = await Promise.all([
      getPatientTimeline(patientId),
    ]);

    return {
      patientTimelineDTO,
    };
  }, [id, getPatientTimeline]);

  const loadablePatientProfileTimeline = useLoadableData(
    patientProfileTimelineCallback
  );

  return (
    <PatientProfileSection reverse>
      <LoadablePatientProfileTimelineProvider
        loadablePatientProfileTimeline={loadablePatientProfileTimeline}
      >
        <PatientProfileContainer>
          <LoadableDataLoading
            state={loadablePatientProfileTimeline.state}
            successComponent={() => <PatientProfileTimeline />}
          />
        </PatientProfileContainer>
      </LoadablePatientProfileTimelineProvider>
      <PatientProfileMedicalHistory />
    </PatientProfileSection>
  );
};
