import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";

type NutritionReportMeatTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportMeatTableCell = (
  props: NutritionReportMeatTableCellProps
) => {
  const { kind } = props;

  const {
    meatHighFatRow,
    meatMediumFatRow,
    meatLowFatRow,
    meatVeryLeanRow,
    meatHighFatMeal,
    meatMediumFatMeal,
    meatLowFatMeal,
    meatVeryLeanMeal,
  } = useMemo(() => {
    const meatsRow =
      kind.kind === "Row"
        ? kind.data.foodGroups.filter((item) => {
            return item.foodGroup.parentFoodGroupId === 20;
          })
        : undefined;

    const meatsMeals =
      kind.kind === "Meal"
        ? kind.data.foodGroups.filter((item) => {
            return item.foodGroup.parentFoodGroupId === 20;
          })
        : undefined;

    const meatHighFatRow = meatsRow?.find((item) => item.foodGroup.id === 8);
    const meatMediumFatRow = meatsRow?.find((item) => item.foodGroup.id === 9);
    const meatLowFatRow = meatsRow?.find((item) => item.foodGroup.id === 10);
    const meatVeryLeanRow = meatsRow?.find((item) => item.foodGroup.id === 11);

    const meatHighFatMeal = meatsMeals?.find((item) => item.foodGroup.id === 8);
    const meatMediumFatMeal = meatsMeals?.find(
      (item) => item.foodGroup.id === 9
    );
    const meatLowFatMeal = meatsMeals?.find((item) => item.foodGroup.id === 10);
    const meatVeryLeanMeal = meatsMeals?.find(
      (item) => item.foodGroup.id === 11
    );

    return {
      meatHighFatRow,
      meatMediumFatRow,
      meatLowFatRow,
      meatVeryLeanRow,
      meatHighFatMeal,
      meatMediumFatMeal,
      meatLowFatMeal,
      meatVeryLeanMeal,
    };
  }, [kind.data, kind.kind]);

  const rowValues = [
    meatHighFatRow?.totalServingsCount,
    meatMediumFatRow?.totalServingsCount,
    meatLowFatRow?.totalServingsCount,
    meatVeryLeanRow?.totalServingsCount,
  ];

  const mealValues = [
    meatHighFatMeal?.servingsCount,
    meatMediumFatMeal?.servingsCount,
    meatLowFatMeal?.servingsCount,
    meatVeryLeanMeal?.servingsCount,
  ];

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type="Meat"
      values={kind.kind === "Row" ? rowValues : mealValues}
    />
  );
};
