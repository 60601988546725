import React, { PropsWithChildren } from "react";
import { AuthProvider } from "./auth-context/auth-context";
import { ClientProvider } from "./client-context";
import { FullPageLoaderServiceProvider } from "./full-page-loader-service-context";
import { ToastServiceProvider } from "./toast-service-context";
import { theme } from "../styles/theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "../styles/global-style";
import { BrowserRouter } from "react-router-dom";
import { ChatTokenProvider } from "../screens/chat/chat-providers/chat-token-context";
import { FEATURE_FLAG_CHAT } from "../constants/config";
import { ChatProviders } from "../screens/chat/chat-providers/chat-providers";

export function AppProviders({ children }: PropsWithChildren<{}>) {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <FullPageLoaderServiceProvider>
            <ToastServiceProvider>
              <AuthProvider>
                <ClientProvider>
                  {FEATURE_FLAG_CHAT ? (
                    <ChatTokenProvider>
                      <ChatProviders>{children}</ChatProviders>
                    </ChatTokenProvider>
                  ) : (
                    <>{children}</>
                  )}
                </ClientProvider>
              </AuthProvider>
            </ToastServiceProvider>
          </FullPageLoaderServiceProvider>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
}
