import { useFormContext } from "react-hook-form";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import { PatientProfileModalBodyContainer } from "../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileMedicalHistoryEditModalInputs } from "../profile-medical-history-edit-modal";
import { PatientProfileChronicDiseaseFormControl } from "./patient-profile-chronic-disease-form-control/patient-profile-chronic-disease-form-control";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { usePutPatientMedicalHistory } from "../../../../../../../hooks/patient-medical-history-hooks/use-put-patient-medical-history";
import { PutPatientMedicalHistoryDTO } from "../../../../../../../models/patient-medical-history-dtos/put-patient-medical-history-dto";
import { PatientProfileDiabetesEmergenciesFormControl } from "./patient-profile-diabetes-emergencies-form-control/patient-profile-diabetes-emergencies-form-control";
import { PateintProfileFamilyMemberDiseasesFormControl } from "./pateint-profile-family-member-diseases-form-control/pateint-profile-family-member-diseases-form-control";
import { PateintProfileFamilyMemberOtherDiseasesFormControl } from "./pateint-profile-family-member-other-diseases-form-control/pateint-profile-family-member-other-diseases-form-control";
import { PateintProfileDiabetesComplicationsFormControl } from "./pateint-profile-diabetes-complications-form-control/pateint-profile-diabetes-complications-form-control";
import { PatientProfileSurgeryFormControl } from "./patient-profile-surgery-form-control/patient-profile-surgery-form-control";
import { PatientProfileSurgeryOtherFormControl } from "./patient-profile-surgery-other-form-control/patient-profile-surgery-other-form-control";
import {
  usePatientProfileMedicalHistory,
  useReloadPatientProfileMedicalHistory,
} from "../../../context/loadable-patient-profile-medical-history";

type ProfileMedicalHistoryEditModalBodyProps = {
  onClose: () => void;
};

export const ProfileMedicalHistoryEditModalBody = (
  props: ProfileMedicalHistoryEditModalBodyProps
) => {
  const { onClose } = props;

  const { patientDTO } = usePatientProfileMedicalHistory();

  const { showToast } = useToastService();
  const reloadPatientProfileMedicalHistory =
    useReloadPatientProfileMedicalHistory();
  const putPatientMedicalHistory = usePutPatientMedicalHistory();

  const { handleSubmit } =
    useFormContext<PatientProfileMedicalHistoryEditModalInputs>();

  const onSubmit = handleSubmit(async (inputs) => {
    const familyMemberDiseasesArray = inputs.familyMemberDiseases;
    const familyMemberOtherDiseases = inputs.familyMemberDiseasesOther;

    const familyMemberDiseases =
      familyMemberDiseasesArray !== undefined &&
      familyMemberOtherDiseases?.other !== undefined &&
      familyMemberOtherDiseases?.other !== ""
        ? familyMemberDiseasesArray.concat(familyMemberOtherDiseases)
        : familyMemberDiseasesArray;

    const nonBariatricSurgeriesArray = inputs.nonBariatricSurgeries;
    const nonBariatricSurgeriesOther = inputs.nonBariatricSurgeriesOther;

    const nonBariatricSurgeries =
      nonBariatricSurgeriesArray !== undefined &&
      nonBariatricSurgeriesOther?.other !== undefined &&
      nonBariatricSurgeriesOther?.other !== ""
        ? nonBariatricSurgeriesArray.concat(nonBariatricSurgeriesOther)
        : nonBariatricSurgeriesArray;

    const putPatientMedicalHistoryDTO: PutPatientMedicalHistoryDTO = {
      chronicDiseases: inputs.chronicDiseases!,
      diabetesEmergencies: inputs.diabetesEmergencies!,
      nonBariatricSurgeries: nonBariatricSurgeries!,
      familyMemberDiseases: familyMemberDiseases!,
      diabetesComplications: inputs.diabetesComplications!,
    };

    try {
      await putPatientMedicalHistory(patientDTO, putPatientMedicalHistoryDTO);
      showToast("Success", "Patient medical history updated successfully!");
      reloadPatientProfileMedicalHistory();
      onClose();
    } catch (error) {
      showToast("Error", "Failed to update patient medical history");
    }
  });

  return (
    <ModalForm width={880} height={700} onSubmit={onSubmit}>
      <ModalHeader title="Edit medical history" onModalClose={onClose} />
      <PatientProfileModalBodyContainer canScrollY>
        <PatientProfileChronicDiseaseFormControl />
        <PatientProfileDiabetesEmergenciesFormControl />
        <PatientProfileSurgeryFormControl />
        <PatientProfileSurgeryOtherFormControl />
        <PateintProfileFamilyMemberDiseasesFormControl />
        <PateintProfileFamilyMemberOtherDiseasesFormControl />
        <PateintProfileDiabetesComplicationsFormControl />
      </PatientProfileModalBodyContainer>
      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </ModalFooter>
    </ModalForm>
  );
};
