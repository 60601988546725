import { useFormContext } from "react-hook-form";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import { PatientProfileModalBodyContainer } from "../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileDietEditModalContainer } from "./styled-patient-profile-edit-diet-modal-body";
import { PatientProfileDietModalInputs } from "../patient-profile-edit-diet-modal";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { usePutPatientDietProfile } from "../../../../../../../hooks/patient-diet-profile-hooks/use-put-patient-diet-profile";
import { PutPatientDietProfileDTO } from "../../../../../../../models/patient-diet-profile-dtos/put-patient-diet-profile-dto";
import { PatientProfileEditDietWaterIntakeFormControl } from "./patient-profile-edit-diet-water-intake-form-control/patient-profile-edit-diet-water-intake-form-control";
import { PatientProfileEditDietCaffeineIntakeFormControl } from "./patient-profile-edit-diet-caffeine-intake-form-control/patient-profile-edit-diet-caffeine-intake-form-control";
import { PatientProfileEditDietSleepFormControl } from "./patient-profile-edit-diet-sleep-form-control/patient-profile-edit-diet-sleep-form-control";
import { PatientProfileEditDietAllergiesFormControl } from "./patient-profile-edit-diet-allergies-form-control/patient-profile-edit-diet-allergies-form-control";
import { PatientProfileEditDietFollowedDietFormControl } from "./patient-profile-edit-diet-followed-diet-form-control/patient-profile-edit-diet-followed-diet-form-control";
import { PatientProfileEditDietAddedSugarsFormControl } from "./patient-profile-edit-diet-added-sugars-form-control/patient-profile-edit-diet-added-sugars-form-control";
import { PatientProfileEditDietOtherSugarsFormControl } from "./patient-profile-edit-diet-other-sugars-form-control/patient-profile-edit-diet-other-sugars-form-control";
import { PatientProfileEditDietSmokesFormControl } from "./patient-profile-edit-diet-smokes-form-control/patient-profile-edit-diet-smokes-form-control";
import { PatientProfileEditDietSmokesOtherFormControl } from "./patient-profile-edit-diet-smokes-other-form-control/patient-profile-edit-diet-smokes-other-form-control";
import { PatientProfileEditDietAlcoholsFormControl } from "./patient-profile-edit-diet-alcohols-form-control/patient-profile-edit-diet-alcohols-form-control";
import { PatientProfileEditDietAlcoholsOtherFormControl } from "./patient-profile-edit-diet-alcohols-other-form-control/patient-profile-edit-diet-alcohols-other-form-control";
import { PatientProfileEditDietLifeActivitiesFormControl } from "./patient-profile-edit-diet-life-activities-form-control/patient-profile-edit-diet-life-activities-form-control";
import { PatientProfileEditDietOtherLifeActivitiesFormControl } from "./patient-profile-edit-diet-other-life-activities-form-control/patient-profile-edit-diet-other-life-activities-form-control";
import {
  usePatientProfileDiet,
  useReloadPatientProfileDiet,
} from "../../../context/loadable-patient-profile-diet-context";

type PatientProfileEditDietModalBodyProps = {
  onClose: () => void;
};

export const PatientProfileEditDietModalBody = (
  props: PatientProfileEditDietModalBodyProps
) => {
  const { onClose } = props;

  const { patientDTO } = usePatientProfileDiet();

  const { showToast } = useToastService();
  const reloadPatientProfileDiet = useReloadPatientProfileDiet();
  const putPatientDietProfile = usePutPatientDietProfile();

  const { handleSubmit } = useFormContext<PatientProfileDietModalInputs>();

  const onSubmit = handleSubmit(async (inputs) => {
    const patientAddedSugarsArray = inputs.patientAddedSugars;
    const patientOtherAddedSugars = inputs.patientAddedSugarsOther;
    const patientSmokesArray = inputs.patientSmokes;
    const patientOtherSmokes = inputs.patientSmokesOther;
    const patientAlcoholsArray = inputs.patientAlcohols;
    const patientOtherAlcohols = inputs.patientAlcoholsOther;
    const patientLifeActivityArray = inputs.patientLifeActivity;
    const patientOtherLifeActivity = inputs.patientLifeActivityOther;

    const patientAddedSugars =
      patientOtherAddedSugars?.notes !== undefined &&
      patientOtherAddedSugars?.notes !== ""
        ? patientAddedSugarsArray.concat(patientOtherAddedSugars)
        : patientAddedSugarsArray;

    const patientSmokes =
      patientOtherSmokes?.other !== undefined && patientOtherSmokes.other !== ""
        ? patientSmokesArray?.concat(patientOtherSmokes)
        : patientSmokesArray;

    const patientAlcohols =
      patientOtherAlcohols?.other !== undefined &&
      patientOtherAlcohols.other !== ""
        ? patientAlcoholsArray?.concat(patientOtherAlcohols)
        : patientAlcoholsArray;

    const patientLifeActivities =
      patientOtherLifeActivity?.notes !== undefined &&
      patientOtherLifeActivity.notes !== ""
        ? patientLifeActivityArray?.concat(patientOtherLifeActivity)
        : patientLifeActivityArray;

    const putPatientDietProfileDTO: PutPatientDietProfileDTO = {
      waterIntakeCupsPerDay:
        inputs.waterIntakeCupsPerDay !== undefined &&
        inputs.waterIntakeCupsPerDay !== ""
          ? Number(inputs.waterIntakeCupsPerDay)
          : undefined,
      caffeineIntakeCupsPerDay:
        inputs.caffeineIntakeCupsPerDay !== undefined &&
        inputs.caffeineIntakeCupsPerDay !== ""
          ? Number(inputs.caffeineIntakeCupsPerDay)
          : undefined,
      sleepHoursPerDay: Number(inputs.sleepHoursPerDay)!,
      sleepPattern: inputs.sleepPattern!,
      patientDiets: inputs.patientDiets!,
      patientFoodAllergies: inputs.patientFoodAllergies!,
      patientAddedSugars,
      patientSmokes: patientSmokes!,
      patientAlcohols: patientAlcohols!,
      patientLifeActivities: patientLifeActivities!,
    };

    try {
      await putPatientDietProfile(patientDTO, putPatientDietProfileDTO);
      showToast("Success", "Patient diet updated successfully!");
      reloadPatientProfileDiet();
      onClose();
    } catch (error) {
      showToast("Error", "Failed to update patient diet");
    }
  });

  return (
    <ModalForm width={880} height={740} onSubmit={onSubmit}>
      <ModalHeader title="Edit Diet" onModalClose={onClose} />
      <PatientProfileModalBodyContainer canScrollY>
        <PatientProfileDietEditModalContainer>
          <PatientProfileEditDietWaterIntakeFormControl />
          <PatientProfileEditDietCaffeineIntakeFormControl />
          <PatientProfileEditDietSmokesFormControl />
          <PatientProfileEditDietSmokesOtherFormControl />
          <PatientProfileEditDietAlcoholsFormControl />
          <PatientProfileEditDietAlcoholsOtherFormControl />
          <PatientProfileEditDietFollowedDietFormControl />
          <PatientProfileEditDietAddedSugarsFormControl />
          <PatientProfileEditDietOtherSugarsFormControl />
          <PatientProfileEditDietAllergiesFormControl />
          <PatientProfileEditDietSleepFormControl />
          <PatientProfileEditDietLifeActivitiesFormControl />
          <PatientProfileEditDietOtherLifeActivitiesFormControl />
        </PatientProfileDietEditModalContainer>
      </PatientProfileModalBodyContainer>
      <ModalFooter>
        <Button type="button" outlined onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </ModalFooter>
    </ModalForm>
  );
};
