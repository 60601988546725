import { Temporal } from "temporal-polyfill";
import { Meal } from "../../build-days-aggregates";
import { GoogleDataTableRow } from "react-google-charts";
import { isMobileOrTablet } from "../../../../../utils/device-utils";
import { mealToHtmlTooltip } from "./meal-to-html-tooltip";
import { buildRow } from "../build-row";
import { MEAL_GLUCOSE_POINT } from "../../../../../constants/config";

export function buildMealRow(
  datetime: Temporal.PlainDateTime,
  reverseVAxisTicks: number[],
  meal: Meal
): GoogleDataTableRow {
  let bigPointSize!: number;
  if (isMobileOrTablet()) {
    bigPointSize = 4;
  } else {
    bigPointSize = 4;
  }

  const tooltip = mealToHtmlTooltip(meal);

  const style = `
  point {
    size: ${bigPointSize};
    shape-type: circle;
    fill-color: #1097CF;
    visible :true
  }`;

  return buildRow({
    datetime,
    reverseVAxisTicks,
    isNotGlucose: {
      value: MEAL_GLUCOSE_POINT,
      tooltip,
    },
    isNotGlucoseStyle: style,
  });
}
