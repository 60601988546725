import { useState } from "react";
import {
  PatientPlanFeedbackTone,
  PATIENT_PLAN_FEEDBACK_TONES,
} from "../../../../../models/patient-plan-dtos/patient-plan-feedback-dto";
import {
  PlansDropdownContainer,
  PlansDropdownLabel,
} from "./styled-plans-dropdown";
import { Dropdown } from "../../../../../components/dropdown/dropdown";
import { DropdownListItem } from "../../../../../components/dropdown/styled-dropdown";
import { useDetailedPlan } from "../../context/loadable-detailed-plan-context";

type PlansDropdownProps = {
  label: string;
  onChange: (value: PatientPlanFeedbackTone | number) => void;
  customFeedbackForm?: boolean;
};

export const PlansDropdown = (props: PlansDropdownProps) => {
  const { label, onChange, customFeedbackForm } = props;

  const [displayText, setDisplayText] = useState(`Select ${label}`);
  const { labTestTypeDTOs } = useDetailedPlan();

  const items = PATIENT_PLAN_FEEDBACK_TONES.map((item, index) => (
    <DropdownListItem
      key={index}
      variant="designed-ux"
      onClick={() => {
        setDisplayText(item);
        onChange(item);
      }}
    >
      {item}
    </DropdownListItem>
  ));

  const labTestTypeDTOsItems = labTestTypeDTOs.map((item, index) => (
    <DropdownListItem
      key={index}
      variant="designed-ux"
      onClick={() => {
        setDisplayText(item.name);
        onChange(item.id);
      }}
    >
      {item.name}
    </DropdownListItem>
  ));

  return (
    <PlansDropdownContainer>
      <PlansDropdownLabel>{label}</PlansDropdownLabel>
      <Dropdown text={displayText} variant="designed-ux" width={300}>
        {customFeedbackForm === undefined ? items : labTestTypeDTOsItems}
      </Dropdown>
    </PlansDropdownContainer>
  );
};
