import {
  TargetAudienceAutoSelectContainer,
  TargetAudienceAutoSelectDropdownToggle,
  TargetAudienceAutoSelectDropdownToggleIcon,
} from "./styled-target-audience-autoselect";
import { useDropdown } from "../../../../components/dropdown/hooks/use-dropdown";
import { TargetAudienceAutoselectList } from "./target-audience-autoselect-list/target-audience-autoselect-list";
import { TargetAudienceDTO } from "../../../../models/target-audience-dtos/target-audience-dto";
import { TargetAudienceTags } from "./target-audience-tags/target-audience-tags";
import { TargetAudienceSearchTermProvider } from "./context/target-audience-search-term-context";

type TargetAudienceAutoselectProps = {
  handleAppend: (targetAudienceDTO: TargetAudienceDTO) => void;
  targetAudienceWatcher: TargetAudienceDTO[];
  fields: TargetAudienceDTO[];
  handleRemove: (index: number) => void;
};

export const TargetAudienceAutoselect = (
  props: TargetAudienceAutoselectProps
) => {
  const { targetAudienceWatcher, handleAppend, fields, handleRemove } = props;

  const { dropdownContainerRef, showDropdown, toggleDropdown } = useDropdown();

  return (
    <TargetAudienceAutoSelectContainer ref={dropdownContainerRef}>
      <TargetAudienceAutoSelectDropdownToggle onClick={toggleDropdown}>
        <TargetAudienceTags fields={fields} handleRemove={handleRemove} />
        <TargetAudienceAutoSelectDropdownToggleIcon
          className="material-symbols-outlined"
          $showDropdown={showDropdown}
        >
          expand_more
        </TargetAudienceAutoSelectDropdownToggleIcon>
      </TargetAudienceAutoSelectDropdownToggle>
      <TargetAudienceSearchTermProvider>
        <TargetAudienceAutoselectList
          showDropdown={showDropdown}
          targetAudienceWatcher={targetAudienceWatcher}
          handleAppend={handleAppend}
        />
      </TargetAudienceSearchTermProvider>
    </TargetAudienceAutoSelectContainer>
  );
};
