import {
  SmsOtpsContainer,
  SmsOtpsList,
  SmsOtpsListItem,
} from "./styled-sms-otps";
import { EmptyState } from "../../../../components/empty-state/empty-state";
import { useAdministration } from "../context/administration-context";

export const SmsOtps = () => {
  const { smsOtpsResponse } = useAdministration();

  const patientSmsOtps = smsOtpsResponse.smsOtps;

  const headers = ["Verification code", "Date"].map((header) => (
    <p key={header}>{header}</p>
  ));

  const items = patientSmsOtps.map((otp, index) => {
    const displayDate =
      otp.createdAt !== undefined
        ? new Date(otp.createdAt).toLocaleString("en-EG", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            timeZoneName: "short",
            hour12: true,
          })
        : "";

    const smsOtp = otp.smsOtpBody;
    const smsOtpMatchRegex = smsOtp.match(/\d+/g);
    const smsOtpNumber =
      smsOtpMatchRegex !== null ? smsOtpMatchRegex.join("") : 0;

    return (
      <SmsOtpsListItem key={index} header={false}>
        <p>{smsOtpNumber}</p>
        <p>{displayDate}</p>
      </SmsOtpsListItem>
    );
  });

  return (
    <>
      {patientSmsOtps.length > 0 ? (
        <SmsOtpsContainer>
          <SmsOtpsList>
            <SmsOtpsListItem header>{headers}</SmsOtpsListItem>
            {items}
          </SmsOtpsList>
        </SmsOtpsContainer>
      ) : (
        <EmptyState
          button={<></>}
          hasHeader={false}
          hasSearch={false}
          text="This patient has Zero SMS OTPs"
          title=""
        />
      )}
    </>
  );
};
