import { useNavigate, useParams } from "react-router-dom";
import { useHomeStaticData } from "../../context/home-static-data-context";
import { useMemo, useState } from "react";
import { Pagination } from "../../../../components/pagination/pagination";
import { Table } from "../../../../components/table/table";
import { TablePaginationContainer } from "../../../../components/table/styled-table";
import { ImmediateAttentionPatientsTableHeadings } from "./immediate-attention-patients-table-headings";
import {
  ImmediateAttentionPatientsTableContainer,
  ImmediateAttentionPatientsTableTableContainer,
} from "./styled-immediate-attention-patients-table";
import { ImmediateAttentionPatientsTableRows } from "./immediate-attention-patients-table-rows/immediate-attention-patients-table-rows";
import { ImmediateAttentionPatientsSelectedActions } from "./immediate-attention-patients-selected-actions/immediate-attention-patients-selected-actions";
import { ImmediateAttentionPatientsTableHeader } from "./immediate-attention-patients-table-header/immediate-attention-patients-table-header";

export const ImmediateAttentionPatientsTable = (props: {
  allPatinets?: boolean;
}) => {
  const { allPatinets } = props;

  const { filteredHomeStaticData } = useHomeStaticData();

  const { immediateAttentionPatientsPage } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [tableRowsPerPage, setTableRowsPerPage] = useState(8);

  const filtered = filteredHomeStaticData.filter((item) => {
    if (searchTerm === "") {
      return item;
    } else if (
      item.patient.name.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return item;
    } else {
      return 0;
    }
  });

  const maxPage = Math.ceil(filtered.length / tableRowsPerPage);

  const currentOrMaxPage = Math.min(
    Number(immediateAttentionPatientsPage),
    maxPage
  );

  const currentPagePatients = useMemo(() => {
    const indexOfLastEntry = currentOrMaxPage * tableRowsPerPage;
    const indexOfFirstEntry = indexOfLastEntry - tableRowsPerPage;

    return filtered.slice(indexOfFirstEntry, indexOfLastEntry);
  }, [filtered, tableRowsPerPage, currentOrMaxPage]);

  function onSearchTermChange(searchTerm: string) {
    navigate(`../immediate-attention-patients/1`);
    setSearchTerm(searchTerm);
  }

  return (
    <ImmediateAttentionPatientsTableContainer>
      <ImmediateAttentionPatientsTableHeader
        searchTerm={searchTerm}
        setSearchTerm={onSearchTermChange}
        allPatinets={allPatinets}
      />
      <ImmediateAttentionPatientsTableTableContainer>
        <Table
          tableHeadings={
            <ImmediateAttentionPatientsTableHeadings
              allPatinets={allPatinets}
            />
          }
          tableRows={
            <ImmediateAttentionPatientsTableRows
              filtered={currentPagePatients}
              allPatinets={allPatinets}
            />
          }
        />
      </ImmediateAttentionPatientsTableTableContainer>
      <TablePaginationContainer>
        <Pagination
          totalRowsCount={filtered.length}
          tableRowsPerPage={tableRowsPerPage}
          setRowsPerPage={setTableRowsPerPage}
          navigateBasePath={"/immediate-attention-patients"}
          pageVariable={immediateAttentionPatientsPage}
          allPatinets={allPatinets}
        />
      </TablePaginationContainer>
      <ImmediateAttentionPatientsSelectedActions />
    </ImmediateAttentionPatientsTableContainer>
  );
};
