import { useEffect, useRef, useState } from "react";
import {
  AudioRecorderButton,
  AudioRecorderContainer,
  RedDot,
  TimeTextContainer,
} from "./styled-audio-recorder";
import { randomUUID } from "../../../../../../utils/random-uuid";
import { Channel, StreamChat } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { useChatContext } from "stream-chat-react";
import { assertNever } from "../../../../../../utils/assert-never";
import { Temporal } from "temporal-polyfill";
import { useConvertWebmToM4a } from "../../../../../../hooks/media-convert-hooks/use-convert-webm-to-m4a";
import { NoLogoLoader } from "../../../../../../components/loaders/no-logo-loader/no-logo-loader";

export type AudioRecorderProps = {
  onStartRecording: () => void;
  onStopRecording: () => void;
};

type State =
  | { kind: "idle" }
  | { kind: "requesting-permission" }
  | { kind: "starting-recording" }
  | {
      kind: "recording";
      startInstant: Temporal.Instant;
      latestInstant: Temporal.Instant;
      intervalId: number;
      audioChunks: BlobPart[];
      stopRecording: () => void;
    }
  | { kind: "stopping"; audioChunks: BlobPart[]; shouldDiscard: boolean }
  | { kind: "sending" };

async function requestMediaPermission() {
  const mediaStream = await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false,
  });

  return mediaStream;
}

async function startRecording(
  mediaStream: MediaStream,
  onNewAudioChunk: (audioChunk: BlobPart) => void,
  onStopRecording: () => void
) {
  const mediaRecorder = new MediaRecorder(mediaStream, {
    mimeType: "audio/webm",
  });

  mediaRecorder.addEventListener("dataavailable", (event) => {
    onNewAudioChunk(event.data);
  });

  mediaRecorder.addEventListener("stop", () => {
    mediaStream.getTracks().forEach((track) => {
      track.stop();
    });

    onStopRecording();
  });

  const stopRecording = () => {
    mediaRecorder.stop();
  };

  mediaRecorder.start();

  return { stopRecording };
}

async function sendMessage(
  client: StreamChat<DefaultStreamChatGenerics>,
  activeChannel: Channel<DefaultStreamChatGenerics>,
  audioChunks: BlobPart[],
  convertWebmToMp4: (webmFile: Blob) => Promise<Blob>
) {
  const webmBlob = new Blob(audioChunks);

  const m4aBlob = await convertWebmToMp4(webmBlob);
  const m4aFile: File = new File([m4aBlob], "audio.m4a", {
    type: "audio/m4a",
  });

  const url = `${activeChannel._channelURL()}/file`;
  const uploadedFile = await client.sendFile(url, m4aFile);

  const messageID = randomUUID();

  await activeChannel.sendMessage({
    attachments: [
      {
        audio: uploadedFile.file,
        name: `AudioMessage-${messageID}.m4a`,
        type: "audio",
        url: uploadedFile.file,
        asset_url: uploadedFile.file,
        title: `AudioMessage-${messageID}.m4a`,
        mime_type: "audio/m4a",
        text: uploadedFile.duration,
      },
    ],
  });
}

export const AudioRecorder = (props: AudioRecorderProps) => {
  const { onStartRecording, onStopRecording } = props;

  const { client, channel: activeChannel } = useChatContext();

  const convertWebmToMp4 = useConvertWebmToM4a();

  const initialState: State = { kind: "idle" };
  const stateRef = useRef<State>(initialState);
  const [renderState, setRenderState] = useState<State>(initialState);

  useEffect(() => {
    return () => {
      if (stateRef.current.kind === "recording") {
        stateRef.current.stopRecording();
        window.clearInterval(stateRef.current.intervalId);
      }
    };
  }, []);

  function setState(newState: State) {
    stateRef.current = newState;
    setRenderState(newState);
  }

  function handleRecordClicked() {
    if (stateRef.current.kind === "idle") {
      requestMediaPermission().then((mediaStream) => {
        handlePermissionGranted(mediaStream);
      });

      setState({ kind: "requesting-permission" });
    }
  }

  function handlePermissionGranted(mediaStream: MediaStream) {
    if (stateRef.current.kind === "requesting-permission") {
      startRecording(
        mediaStream,
        handleNewAudioChunk,
        handleStopRecording
      ).then(({ stopRecording }) => {
        handleRecordingStarted(stopRecording);
      });

      setState({ kind: "starting-recording" });
    }
  }

  function handleRecordingStarted(stopRecording: () => void) {
    if (stateRef.current.kind === "starting-recording") {
      window.setTimeout(onStartRecording, 0);

      const intervalId = window.setInterval(handleInterval, 10);

      const currentInstant = Temporal.Now.instant();
      setState({
        kind: "recording",
        startInstant: currentInstant,
        latestInstant: currentInstant,
        intervalId,
        audioChunks: [],
        stopRecording,
      });
    }
  }

  function handleInterval() {
    if (stateRef.current.kind === "recording") {
      const currentInstant = Temporal.Now.instant();
      setState({
        ...stateRef.current,
        latestInstant: currentInstant,
      });
    }
  }

  function handleNewAudioChunk(audioChunk: BlobPart) {
    if (
      stateRef.current.kind === "recording" ||
      stateRef.current.kind === "stopping"
    ) {
      setState({
        ...stateRef.current,
        audioChunks: [...stateRef.current.audioChunks, audioChunk],
      });
    }
  }

  function handleDiscardClicked() {
    if (stateRef.current.kind === "recording") {
      stateRef.current.stopRecording();
      window.clearInterval(stateRef.current.intervalId);

      setState({
        kind: "stopping",
        audioChunks: stateRef.current.audioChunks,
        shouldDiscard: true,
      });
    }
  }

  function handleSendClicked() {
    if (stateRef.current.kind === "recording") {
      stateRef.current.stopRecording();
      window.clearInterval(stateRef.current.intervalId);

      setState({
        kind: "stopping",
        audioChunks: stateRef.current.audioChunks,
        shouldDiscard: false,
      });
    }
  }

  function handleStopRecording() {
    if (stateRef.current.kind === "stopping") {
      window.setTimeout(onStopRecording, 0);

      if (stateRef.current.shouldDiscard) {
        setState({ kind: "idle" });
        return;
      }

      if (activeChannel === undefined) {
        setState({ kind: "idle" });
        return;
      }

      sendMessage(
        client,
        activeChannel,
        stateRef.current.audioChunks,
        convertWebmToMp4
      ).then(handleSent);

      setState({ kind: "sending" });
    }
  }

  function handleSent() {
    if (stateRef.current.kind === "sending") {
      setState({ kind: "idle" });
    }
  }

  if (renderState.kind === "idle") {
    return (
      <AudioRecorderContainer isRecording={false}>
        <AudioRecorderButton
          title="Audio Note"
          isRecording={false}
          type="button"
        >
          <img
            onClick={handleRecordClicked}
            src="/img/chat/mic.svg"
            alt="mic"
          />
        </AudioRecorderButton>
      </AudioRecorderContainer>
    );
  }

  if (
    renderState.kind === "requesting-permission" ||
    renderState.kind === "starting-recording"
  ) {
    // ? FYI, made `starting-recording` with `requesting-permission`
    // ? because `starting-recording` remains for a very short period of time.
    return <NoLogoLoader />;
  }

  if (renderState.kind === "recording") {
    const duration = renderState.latestInstant.since(renderState.startInstant);

    const minutes = duration.minutes;
    const seconds = duration.seconds;

    return (
      <AudioRecorderContainer isRecording={true}>
        <AudioRecorderButton
          title="Audio Note"
          isRecording={true}
          type="button"
        >
          <TimeTextContainer>
            <img
              onClick={handleDiscardClicked}
              src="/img/trash.svg"
              alt="stop"
            />
            <RedDot />
            <p>
              {minutes.toString().padStart(2, "0")}:
              {seconds.toString().padStart(2, "0")}
            </p>
          </TimeTextContainer>
          <div onClick={handleSendClicked}>
            <SendSvg />
          </div>
        </AudioRecorderButton>
      </AudioRecorderContainer>
    );
  }

  if (renderState.kind === "stopping" || renderState.kind === "sending") {
    return <NoLogoLoader />;
  }

  return assertNever(renderState);
};

const SendSvg = () => (
  <svg
    height="17"
    viewBox="0 0 18 17"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Send</title>
    <path
      d="M0 17.015l17.333-8.508L0 0v6.617l12.417 1.89L0 10.397z"
      fill="#016dff"
      fillRule="evenodd"
    ></path>
  </svg>
);
