import { useEffect } from "react";
import { Loader } from "../../../../components/loaders/otida-loader/styled-otida-loader";
import { Button } from "../../../../styles/classes/reusable-classes";
import {
  ImageUploadClearIcon,
  ImageUploadContainer,
  ImageUploadImageContainer,
} from "./styled-image-upload";
import { useImageUploadHelpers } from "./context/image-upload-helpers-context";

type ImageUploadProps = {
  uploadText?: string;
  readOnly?: boolean;
};

export const ImageUpload = (props: ImageUploadProps) => {
  const { uploadText, readOnly } = props;

  const {
    imageFile,
    imageUploadState,
    handleDrag,
    handleDrop,
    handleFileChange,
    handleRemoveImage,
    loading,
    setImageFile,
    setImageUploadState,
    imageFileInputRef,
  } = useImageUploadHelpers();

  const isImageUploading = imageFile && imageUploadState.state === "Upload";

  useEffect(() => {
    if (
      imageUploadState.state === "Upload" &&
      imageUploadState.previewUrl === undefined
    ) {
      setImageUploadState({ state: "Idle" });
      setImageFile(null);
    }
  }, [imageUploadState, setImageFile, setImageUploadState]);

  if (loading) {
    return (
      <ImageUploadContainer $dragActive={false}>
        <ImageUploadImageContainer>
          <Loader radius={80} />
        </ImageUploadImageContainer>
      </ImageUploadContainer>
    );
  }

  if (imageUploadState.state === "Uploaded") {
    return (
      <ImageUploadContainer $dragActive={false} $uploadedDimentions>
        <ImageUploadImageContainer>
          <img
            src={imageUploadState.previewUrl}
            alt={`Course ${uploadText ? uploadText : "Thumbnail"}`}
          />
          {!readOnly && (
            <ImageUploadClearIcon
              className="material-symbols-outlined"
              onClick={handleRemoveImage}
            >
              close
            </ImageUploadClearIcon>
          )}
        </ImageUploadImageContainer>
      </ImageUploadContainer>
    );
  }

  if (isImageUploading) {
    <ImageUploadContainer
      onDragEnter={(event) => handleDrag(event)}
      onDragLeave={(event) => handleDrag(event)}
      onDragOver={(event) => handleDrag(event)}
      onDrop={(event) => handleDrop(event)}
      $dragActive={false}
    >
      <ImageUploadImageContainer>
        <img
          src={imageUploadState.previewUrl}
          alt={`Course ${uploadText ? uploadText : "Thumbnail"}`}
        />
        {!readOnly && (
          <ImageUploadClearIcon
            className="material-symbols-outlined"
            onClick={handleRemoveImage}
          >
            close
          </ImageUploadClearIcon>
        )}
      </ImageUploadImageContainer>
    </ImageUploadContainer>;
  }

  if (
    (imageUploadState.state === "Drag" || imageUploadState.state === "Idle") &&
    !readOnly
  ) {
    return (
      <ImageUploadContainer
        onDragEnter={(event) => handleDrag(event)}
        onDragLeave={(event) => handleDrag(event)}
        onDragOver={(event) => handleDrag(event)}
        onDrop={(event) => handleDrop(event)}
        $dragActive={
          imageUploadState.state === "Drag" && imageUploadState.dragActive
        }
        onClick={() => imageFileInputRef.current?.click()}
      >
        <span className="material-symbols-outlined">image</span>
        <p>Drag and drop a {uploadText ? uploadText : `Thumbnail`}</p>
        <input
          ref={imageFileInputRef}
          type="file"
          accept="image/jpeg, image/png, image/jpg, image/bmp, image/webp"
          onChange={(event) => handleFileChange(event)}
        />
        <Button outlineNoBorder type="button">
          Upload {uploadText ? uploadText : `Thumbnail`}
        </Button>
      </ImageUploadContainer>
    );
  }

  return (
    <ImageUploadContainer $dragActive={false}>
      <span className="material-symbols-outlined">image</span>
    </ImageUploadContainer>
  );
};
