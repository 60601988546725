import { Fragment, useMemo, useState } from "react";
import { AutoSelect } from "../../../../../components/auto-select/auto-select";
import {
  FoodLoggingSelectedPatientMealSourcesContainer,
  FoodLoggingSelectedPatientMealSourcesInputContainer,
  FoodLoggingSelectedPatientMealSourcesList,
  FoodLoggingSelectedPatientMealSourcesListItem,
} from "../../styled-food-logging-selected-patient-meal";
import { FoodLoggingSelectedPatientMealContainerTitle } from "../../styled-food-logging-selected-patient-meal";
import { ProteinSourceDTO } from "../../../../../models/protein-sources/protein-source-dto";
import { useEnterKeypress } from "../../../../../hooks/use-enter-keypress/use-enter-keypress";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useFoodLoggingData } from "../../../context/food-logging-data-context";
import { FoodLoggingSelectedPatientMealInputs } from "../../food-logging-selected-patient-meal";

export const FoodLoggingSelectedPatientProteinSources = () => {
  const { proteinSources } = useFoodLoggingData();

  const [autoSelectTagValue, setAutoSelectTagValue] = useState("");

  const { control } = useFormContext<FoodLoggingSelectedPatientMealInputs>();

  const {
    fields: updatePatientMealProteinSourcesDTOs,
    append: appendProteinSource,
    remove: removeProteinSource,
  } = useFieldArray({
    name: "proteinSources",
    control,
  });

  const updatePatientMealProteinSourceIndexById: Map<
    number,
    number
  > = useMemo(() => {
    return new Map(
      updatePatientMealProteinSourcesDTOs.map(
        (updatePatientMealProteinSourcesDTO, index) => [
          updatePatientMealProteinSourcesDTO.proteinSourceId,
          index,
        ]
      )
    );
  }, [updatePatientMealProteinSourcesDTOs]);

  const filteredProteins = useMemo(
    () =>
      proteinSources.reduce((acc: string[], proteinSourceDTO) => {
        const proteinSourceName = proteinSourceDTO.name;

        if (!updatePatientMealProteinSourceIndexById.has(proteinSourceDTO.id)) {
          acc.push(proteinSourceName);
        }

        return acc;
      }, []),
    [proteinSources, updatePatientMealProteinSourceIndexById]
  );

  const proteinSource = useMemo(
    () => proteinSources.find((item) => item.name === autoSelectTagValue),
    [proteinSources, autoSelectTagValue]
  );

  function addToProteinSources() {
    if (!updatePatientMealProteinSourceIndexById.has(proteinSource!.id)) {
      appendProteinSource({ proteinSourceId: proteinSource!.id });
      setAutoSelectTagValue("");
    }
  }

  function removeFromProteinSources(proteinSourceDTO: ProteinSourceDTO) {
    const updatePatientMealProteinSource = updatePatientMealProteinSourceIndexById.get(
      proteinSourceDTO.id
    );
    removeProteinSource(updatePatientMealProteinSource);
  }

  const { onEnterKeyPress } = useEnterKeypress(addToProteinSources);

  const currentMealProteinSources = proteinSources.map(
    (proteinSource, index) => {
      return (
        <Fragment key={index}>
          {updatePatientMealProteinSourceIndexById.has(proteinSource.id) && (
            <FoodLoggingSelectedPatientMealSourcesListItem
              onClick={() => removeFromProteinSources(proteinSource)}
            >
              {proteinSource.name}
              <span className="material-symbols-outlined">close</span>
            </FoodLoggingSelectedPatientMealSourcesListItem>
          )}
        </Fragment>
      );
    }
  );

  return (
    <FoodLoggingSelectedPatientMealSourcesContainer>
      <div>
        <FoodLoggingSelectedPatientMealContainerTitle>
          Protein sources
        </FoodLoggingSelectedPatientMealContainerTitle>
        <FoodLoggingSelectedPatientMealSourcesInputContainer>
          <AutoSelect
            placeHolder="Add protein sources"
            itemsArray={
              filteredProteins.length > 0
                ? filteredProteins
                : ["No more protein sources"]
            }
            onChange={(value: string) => setAutoSelectTagValue(value)}
            value={autoSelectTagValue}
            fullWidth
            disabled={filteredProteins.length === 0}
          />
          <button
            type="button"
            onClick={addToProteinSources}
            onKeyDown={(e) => onEnterKeyPress(e)}
          >
            Add
          </button>
        </FoodLoggingSelectedPatientMealSourcesInputContainer>
      </div>
      <FoodLoggingSelectedPatientMealSourcesList>
        {currentMealProteinSources}
      </FoodLoggingSelectedPatientMealSourcesList>
    </FoodLoggingSelectedPatientMealSourcesContainer>
  );
};
