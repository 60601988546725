import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHomeStaticData } from "../../context/home-static-data-context";

type ImmediateAttentionPatientsSelectedRowsRows = {
  handleRowSelect: (id: number) => void;
  allRowsSelectedFlag: boolean;
  toggleAllRows: () => void;
  selectedRows: number[];
};

const ImmediateAttentionPatientsSelectedRowsRowsContext = createContext<
  ImmediateAttentionPatientsSelectedRowsRows | undefined
>(undefined);

type ImmediateAttentionPatientsSelectedRowsRowsProviderProps = PropsWithChildren<{}>;

export const ImmediateAttentionPatientsSelectedRowsRowsProvider = (
  props: ImmediateAttentionPatientsSelectedRowsRowsProviderProps
) => {
  const { children } = props;

  const { filteredHomeStaticData } = useHomeStaticData();

  const idsForAllRows = useMemo(
    () => filteredHomeStaticData.map((item) => item.id),
    [filteredHomeStaticData]
  );

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const isAllRowsSelected =
    selectedRows.length === idsForAllRows.length && idsForAllRows.length !== 0;

  const [allRowsSelectedFlag, setAllRowsSelectedFlag] = useState(
    isAllRowsSelected
  );

  const toggleAllRows = useCallback(() => {
    if (allRowsSelectedFlag && isAllRowsSelected) {
      setAllRowsSelectedFlag(false);
      setSelectedRows([]);
    } else {
      setAllRowsSelectedFlag(true);
      setSelectedRows(idsForAllRows);
    }
  }, [allRowsSelectedFlag, isAllRowsSelected, idsForAllRows]);

  const handleRowSelect = useCallback(
    (id: number) => {
      if (selectedRows.length === 0) {
        setSelectedRows(idsForAllRows.filter((item) => item === id));
      } else if (!selectedRows.find((item) => item === id)) {
        const newItem = idsForAllRows.filter((item) => item === id)[0];

        setSelectedRows([...selectedRows, newItem]);
      } else {
        setAllRowsSelectedFlag(false);
        setSelectedRows(selectedRows.filter((item) => item !== id));
      }
    },
    [idsForAllRows, selectedRows]
  );

  useEffect(() => {
    if (isAllRowsSelected) {
      setAllRowsSelectedFlag(true);
    }
  }, [isAllRowsSelected]);

  const value = useMemo(
    () => ({
      handleRowSelect,
      allRowsSelectedFlag,
      toggleAllRows,
      selectedRows,
    }),
    [handleRowSelect, allRowsSelectedFlag, toggleAllRows, selectedRows]
  );

  return (
    <ImmediateAttentionPatientsSelectedRowsRowsContext.Provider value={value}>
      {children}
    </ImmediateAttentionPatientsSelectedRowsRowsContext.Provider>
  );
};

export const useImmediateAttentionPatientsSelectedRowsRows = (): ImmediateAttentionPatientsSelectedRowsRows => {
  const ImmediateAttentionPatientsSelectedRowsRows = useContext(
    ImmediateAttentionPatientsSelectedRowsRowsContext
  );

  if (ImmediateAttentionPatientsSelectedRowsRows === undefined) {
    throw new Error(
      `useImmediateAttentionPatientsSelectedRowsRows must be wrapped with in ImmediateAttentionPatientsSelectedRowsRowsProvider`
    );
  }

  return ImmediateAttentionPatientsSelectedRowsRows;
};
