import { Controller, useFormContext } from "react-hook-form";
import {
  RadioButton,
  UXInputContainer,
  UXInputLabel,
} from "../../../../../styles/classes/reusable-classes";
import { PutContentModuleDTO } from "../../../../../models/content-module-dtos/put-content-module-dto";
import {
  ContentModuleReadModeInputs,
  useContentModuleMode,
} from "../../../context/content-module-mode-context";
import { LessonStatusMessageModal } from "../../lesson-status-message-modal/lesson-status-message-modal";
import { useModalHelpers } from "../../../../../components/modal/use-modal-helpers";

type ReadContentModuleStatusControllerProps = {
  isDisabled: boolean;
};

export const ReadContentModuleStatusController = (
  props: ReadContentModuleStatusControllerProps
) => {
  const { isDisabled } = props;

  const { control, handleSubmit } = useFormContext<
    ContentModuleReadModeInputs
  >();

  const { contentModuleMode } = useContentModuleMode();
  const contentModuleDTO = contentModuleMode.mode.contentModule;

  const onSubmit = handleSubmit(async (inputs) => {
    if (contentModuleMode.mode.kind === "Read") {
      const putContentModuleDTO: PutContentModuleDTO = {
        childContentModuleIds: contentModuleDTO.childContentModules?.map(
          (ccm) => ccm.id
        )!,
        contentModuleCards: contentModuleDTO.contentModuleCards,
        description: contentModuleDTO.description,
        isDraft: inputs.status ? false : true,
        name: contentModuleDTO.name!,
        objective: contentModuleDTO.objective,
        parentContentModuleId: contentModuleDTO.parentContentModuleId,
        targetAudienceIds: contentModuleDTO.contentModuleTargetAudiences.map(
          (ccm) => ccm.targetAudienceId
        ),
        thumbnailUploadedFileId: contentModuleDTO.thumbnailUploadedFileId,
        uiOrder: contentModuleDTO.uiOrder,
      };

      await contentModuleMode.mode.handleSubmit(
        contentModuleDTO.id,
        putContentModuleDTO,
        contentModuleDTO.contentModuleType
      );
    }
  });

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  return (
    <Controller
      control={control}
      name="status"
      render={({ field: { value, onChange } }) => (
        <UXInputContainer $width="240px">
          <UXInputLabel $width="110px">
            {contentModuleDTO.contentModuleType} Status
          </UXInputLabel>
          <RadioButton
            type="button"
            checked={value}
            onClick={() => {
              if (isDisabled) {
                openModal();
              } else {
                onChange(!value);
                onSubmit();
              }
            }}
          >
            <span />
          </RadioButton>
          <LessonStatusMessageModal
            closeModal={closeModal}
            isModalOpen={isModalOpen}
            modalBodyKey={modalBodyKey}
          />
        </UXInputContainer>
      )}
    />
  );
};
