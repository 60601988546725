import { GoogleDataTableRow } from "react-google-charts";
import { Temporal } from "temporal-polyfill";
import { PatientActivityLogDTO } from "../../../../../models/patient-activity-log-dtos/patient-activity-log-dto";
import { buildGapRow } from "../build-gap-row";
import { buildActivityRow } from "./build-activities-row";

export function buildPatientActivitiesRows(
  date: Temporal.PlainDate,
  patientActivityLogs: PatientActivityLogDTO[],
  reverseVAxisTicks: number[]
): GoogleDataTableRow[] {
  const rows: GoogleDataTableRow[] = [];

  for (const patientActivityLog of patientActivityLogs) {
    const time = Temporal.PlainTime.from(patientActivityLog.time);
    const plainDateTime = date.toPlainDateTime(time);

    rows.push(buildGapRow(plainDateTime, reverseVAxisTicks));
    rows.push(
      buildActivityRow(plainDateTime, reverseVAxisTicks, patientActivityLog)
    );
  }

  return rows;
}
