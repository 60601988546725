import { ReactNode, useState } from "react";
import {
  SinglePlanDropdownContainer,
  SinglePlanDropdownButtonIcon,
  SinglePlanDropdownButtonText,
  SinglePlanDropdownList,
  SinglePlanDropdownListItem,
} from "./styled-single-plan-dropdown";

type SinglePlanDropdownProps = {
  icon: string;
  img: string;
  items: ReactNode[];
  title: string;
  subtitle: string;
  viewPlanGraphs?: boolean;
};

export const SinglePlanDropdown = (props: SinglePlanDropdownProps) => {
  const { icon, img, items, subtitle, title, viewPlanGraphs } = props;
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <>
      <SinglePlanDropdownContainer
        showDropdown={showDropdown}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <div>
          <SinglePlanDropdownButtonIcon>
            <img src={icon} alt={icon.slice(icon.length - 4, icon.length)} />
          </SinglePlanDropdownButtonIcon>
          <SinglePlanDropdownButtonText>
            <p>{title}</p>
            <span>{subtitle}</span>
          </SinglePlanDropdownButtonText>
        </div>
        <span className="material-symbols-outlined">expand_more</span>
      </SinglePlanDropdownContainer>
      <SinglePlanDropdownList showDropdown={showDropdown}>
        {img === "" ? (
          <></>
        ) : (
          <SinglePlanDropdownListItem viewPlanGraphs={viewPlanGraphs}>
            <img
              style={img.includes("goals") ? { zoom: "1.5" } : {}}
              src={img}
              alt={img.slice(img.length - 4, img.length)}
            />
          </SinglePlanDropdownListItem>
        )}
        {items}
      </SinglePlanDropdownList>
    </>
  );
};
