import { Controller, useFormContext } from "react-hook-form";
import { CreateModuleInputs } from "../../create-module";
import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../styles/classes/reusable-classes";
import { CreateModuleStatusController } from "./create-module-status-controller";
import { CreateModuleOrderController } from "./create-module-order-controller";
import { ContentModuleStatusAndOrderControls } from "../../../../styles/content-modules-form-controllers/content-modules-form-controllers-css";

export const CreateModuleNameController = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateModuleInputs>();

  return (
    <Controller
      control={control}
      name="name"
      rules={{ required: true }}
      render={({ field: { value, onChange } }) => (
        <UXInputContainer>
          <UXInputLabel $width="150px">Module Name</UXInputLabel>
          <UXInputWrapper
            className="content-module-name-input-wrapper"
            $errorState={errors.name?.type === "required"}
            $width="80%"
          >
            <UXInputInput
              width={"100%"}
              dir="auto"
              type="text"
              placeholder="Enter Module Name"
              value={value === undefined ? "" : value}
              onChange={(e) =>
                onChange(e.target.value === "" ? undefined : e.target.value)
              }
            />
            <ContentModuleStatusAndOrderControls>
              <CreateModuleStatusController />
              <CreateModuleOrderController />
            </ContentModuleStatusAndOrderControls>
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
