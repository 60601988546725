import { viewEmergencies } from "../../../../patient-profile/utils/patient-profile-utils";
import { useSinglePatientSidebar } from "../../../context/loadable-single-patient-sidebar-context";
import {
  PersonalInfoGroup,
  PersonalInfoGroupBottom,
  PersonalInfoGroupTitle,
  PersonalInfoGroupTop,
  PersonalInfoItem,
} from "../../personal-info-tab/styled-personal-info-tab";

export const DiabeticEmergency = () => {
  const { patientMedicalHistoryDTO } = useSinglePatientSidebar();

  const diabeticEmergency = patientMedicalHistoryDTO.diabetesEmergencies.map(
    (emergency, index) => (
      <PersonalInfoItem key={index}>
        {viewEmergencies(emergency.emergency)}
      </PersonalInfoItem>
    )
  );

  return (
    <PersonalInfoGroup>
      <PersonalInfoGroupTop>
        <PersonalInfoGroupTitle>Diabetes Emergencies</PersonalInfoGroupTitle>
      </PersonalInfoGroupTop>
      <PersonalInfoGroupBottom>{diabeticEmergency}</PersonalInfoGroupBottom>
    </PersonalInfoGroup>
  );
};
