import styled from "styled-components/macro";
import { flexCenterCenter } from "../../../../styles/classes/gloabl-classes";

export const LessonStatusMessageModalContainer = styled.div`
  ${flexCenterCenter}
  margin: auto;

  h3 {
    text-align: center;
  }
`;
