import { useFullPageLoaderServiceWithCleanup } from "../context/full-page-loader-service-context";
import { useCallback } from "react";

export function useApiWithFullPageLoader<Args extends unknown[], T>(
  api: (...args: Args) => Promise<T>
) {
  const { startLoading, stopLoading } = useFullPageLoaderServiceWithCleanup();

  return useCallback(
    (...args: Args) => {
      const loadingId = startLoading();
      return api(...args).finally(() => stopLoading(loadingId));
    },
    [api, startLoading, stopLoading]
  );
}
