import styled from "styled-components/macro";
import { NavContainer } from "../../../../components/screen-navbar/styled-screen-navbar";

export const OrganizationHeaderContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};

  ${NavContainer} {
    padding: 0px 24px;
  }
`;
