import { Controller, useFormContext } from "react-hook-form";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileModalLabelContainer,
} from "../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileEditProfileInputs } from "../patient-profile-edit-profile-modal";
import { Dropdown } from "../../../../../../components/dropdown/dropdown";
import { useCallback } from "react";
import { DropdownListItem } from "../../../../../../components/dropdown/styled-dropdown";
import { usePatientProfileInformationHeader } from "../../context/loadable-patient-profile-information-header-context";

export const PateintProfileEditProfileMaritalStatusFormControl = () => {
  const { MaritalStatusDTOs } = usePatientProfileInformationHeader();

  const findMaritalStatusById = useCallback(
    (id: number) => MaritalStatusDTOs.find((item) => item.id === id),
    [MaritalStatusDTOs]
  );

  const { control } = useFormContext<PatientProfileEditProfileInputs>();

  return (
    <PatientProfileModalFormControl>
      <PatientProfileModalLabelContainer>
        <label>Marital Status</label>
      </PatientProfileModalLabelContainer>
      <PatientProfileModalInputContainer hasTwoInputsFlex>
        <Controller
          name="maritalStatusId"
          control={control}
          rules={{ required: false }}
          render={({ field: { value, onChange } }) => (
            <Dropdown
              text={
                value !== undefined &&
                findMaritalStatusById(value)?.name !== undefined
                  ? findMaritalStatusById(value)?.name!
                  : "Select Marital Status"
              }
              variant="designed-ux"
              fullWidth
            >
              {MaritalStatusDTOs.map((item) => (
                <DropdownListItem
                  key={item.id}
                  variant="designed-ux"
                  onClick={() => onChange(item.id)}
                >
                  {item.name}
                </DropdownListItem>
              ))}
            </Dropdown>
          )}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
