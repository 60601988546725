import { ContentModuleLessonMobilePreviewStatusBarContainer } from "./styled-content-module-lesson-mobile-preview-status-bar";

export const ContentModuleLessonMobilePreviewStatusBar = () => {
  return (
    <ContentModuleLessonMobilePreviewStatusBarContainer>
      <p className="create-lesson-mobile-preview-time">10:10</p>
      <div className="create-lesson-mobile-preview-dynamic-island" />
      <div>
        <span className="material-symbols-outlined">signal_cellular_alt</span>
        <span className="material-symbols-outlined">wifi</span>
        <span className="material-symbols-outlined create-lesson-mobile-preview-battery">
          battery_5_bar
        </span>
      </div>
    </ContentModuleLessonMobilePreviewStatusBarContainer>
  );
};
