import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { MedicationDTO } from "../../models/medications/medication-dto";

export function useGetMedications(): () => Promise<MedicationDTO[]> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<MedicationDTO[]>(
      `${BASE_PATH}/medications`
    );

    return response.data;
  }, [client]);
}
