import styled from "styled-components/macro";
import {
  flexCenter,
  mediumSemiBoldFont,
} from "../../styles/classes/gloabl-classes";
import { transition } from "../../styles/global-style";

export const DateRangeContainer = styled.div`
  ${flexCenter}
  position: relative;

  .clear-dates-button {
    position: absolute;
    right: 20px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 20px;
  }
`;

export const DateRangeLabel = styled.label`
  ${mediumSemiBoldFont}
`;

export const DateRangeSubContainer = styled.div`
  ${flexCenter}
  gap: 0px 14px;
  padding: 8px 16px;
  min-width: 350px;

  input {
    width: 100%;
  }

  button {
    color: ${({ theme }) => theme.colors.textColor};
    cursor: pointer;
    padding: 8px;
    background: transparent;
    ${flexCenter}

    span {
      font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
    }
  }
`;

export const DateRangeInputContainer = styled.div`
  .e-date-range-wrapper {
    width: 200px;

    input {
      cursor: pointer;
    }
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
    .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
    .e-input-in-wrap::after {
    background: transparent;
    display: none;
  }

  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap {
    border: 2px solid ${({ theme }) => theme.colors.borderColor};
    padding: 0px 12px;
    border-radius: 94px;
    margin-bottom: 0px;
    box-shadow: none;
  }

  .e-input-group {
    transition: ${transition};
  }

  .e-input-group:hover {
    border: 2px solid ${({ theme }) => theme.colors.primaryColor} !important;
  }

  /* X icon */
  .e-float-input .e-clear-icon,
  .e-float-input.e-control-wrapper .e-clear-icon,
  .e-input-group .e-clear-icon,
  .e-input-group.e-control-wrapper .e-clear-icon {
    display: none;
  }

  .e-date-range-wrapper .e-input-group-icon.e-icons.e-active {
    color: ${({ theme }) => theme.colors.textColor};
  }

  .e-input-group .e-input-group-icon,
  .e-input-group.e-control-wrapper .e-input-group-icon {
    background: transparent;
    border-color: ${({ theme }) => theme.colors.borderColor};
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
