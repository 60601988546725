import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { Temporal } from "temporal-polyfill";

type PatientProfileBloodPressure = {
  startDate?: Date;
  endDate?: Date;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date) => void;
  temporalStartDate: string;
  temporalEndDate: string;
};

type PatientProfileBloodPressreProviderProps = PropsWithChildren<{}>;

const PatientProfileBloodPressureContext = createContext<
  undefined | PatientProfileBloodPressure
>(undefined);

export const PatientProfileBloodPressreProvider = (
  props: PatientProfileBloodPressreProviderProps
) => {
  const { children } = props;

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  const temporalStartDate =
    startDate !== undefined
      ? Temporal.PlainDate.from(startDate.toISOString().split("T")[0])
          .add({ days: 1 })
          .toString()
      : "";

  const temporalEndDate =
    endDate !== undefined
      ? Temporal.PlainDate.from(endDate.toISOString().split("T")[0])
          .add({ days: 1 })
          .toString()
      : "";

  const value = useMemo(
    () => ({
      startDate,
      endDate,
      setStartDate,
      setEndDate,
      temporalStartDate,
      temporalEndDate,
    }),
    [
      startDate,
      endDate,
      setStartDate,
      setEndDate,
      temporalStartDate,
      temporalEndDate,
    ]
  );

  return (
    <PatientProfileBloodPressureContext.Provider value={value}>
      {children}
    </PatientProfileBloodPressureContext.Provider>
  );
};

export const usePatientProfileBloodPressure = () => {
  const patientProfileBloodPressure = useContext(
    PatientProfileBloodPressureContext
  );

  if (patientProfileBloodPressure === undefined) {
    throw new Error(
      "usePatientProfileBloodPressure must be used within PatientProfileBloodPressreProvider"
    );
  }

  return patientProfileBloodPressure;
};
