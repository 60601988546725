import { DaysAggregatesReadingsWithTimeDifferenceValue } from "../build-readings-with-time-differance-value";
import { isGlucosePointHigh } from "./is-glucose-point-high";
import { removeTimeDifferanceValueFromReading } from "./remove-time-differance-value-from-reading";

export function calculateHighReadingsTimeDiffrenceTotal(
  readingsWithTimeDifferenceValue: DaysAggregatesReadingsWithTimeDifferenceValue[],
  maxRange: number
) {
  let highReadingsTimeDiffrenceTotal: number = 0;

  for (const readingWithTimeDifferenceValue of readingsWithTimeDifferenceValue) {
    if (
      isGlucosePointHigh(
        removeTimeDifferanceValueFromReading(readingWithTimeDifferenceValue),
        maxRange
      )
    ) {
      if (readingWithTimeDifferenceValue.timeDifference !== undefined) {
        if (readingWithTimeDifferenceValue.timeDifference > 15) {
          highReadingsTimeDiffrenceTotal += 15;
        } else {
          highReadingsTimeDiffrenceTotal +=
            readingWithTimeDifferenceValue.timeDifference;
        }
      }
    }
  }

  return highReadingsTimeDiffrenceTotal;
}
