import { Temporal } from "temporal-polyfill";
import { Reading } from "../../components/cgm-google-chart/utils/build-days-aggregates";
import { PatientCGMEntryDTO } from "../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";

export function convertCGMEntriesToReadings(
  patientCGMEntryDTO: PatientCGMEntryDTO[]
): Reading[] {
  return patientCGMEntryDTO.map((entry) => ({
    glucose: entry.glucoseMGPerDL,
    source: entry.source,
    time: Temporal.PlainTime.from(entry.time),
    tag: entry.tag,
  }));
}
