import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { ProfileDiagnosisDTO } from "../../models/profile-diagnosis-dtos/profile-diagnosis-dto";

export function useGetProfileDiagnoses(): () => Promise<ProfileDiagnosisDTO[]> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<ProfileDiagnosisDTO[]>(
      `${BASE_PATH}/profile-diagnoses`
    );

    return response.data;
  }, [client]);
}
