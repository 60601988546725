import { usePlansGoals } from "../../context/goals-context";
import { CreatePlansFooter } from "../../create-plans/create-plans-footer/create-plans-footer";
import { GoalsScreenContainer } from "../goals/styled-goals";
import { PlanStepsHeader } from "../../components/plan-steps-header/plan-steps-header";
import { PlansSubScreenContainer } from "../styles";
import { AssignedEducationList } from "./assigned-education-list";
import { EmptyEducation } from "./empty-education";
import { SelectEducationList } from "./select-education-list";
import { useState } from "react";
import { SearchInput } from "../../../../../styles/atoms/search-input";

export const Education = () => {
  const { assignedEducationGoalsList, selectEducationGoalsList } =
    usePlansGoals();

  const assignedGoalsCondition =
    assignedEducationGoalsList.length > 0 &&
    selectEducationGoalsList.length === 0;

  const selectGoalsCondition =
    assignedEducationGoalsList.length === 0 &&
    selectEducationGoalsList.length > 0;

  const bothGoalsCondition =
    assignedEducationGoalsList.length > 0 &&
    selectEducationGoalsList.length > 0;

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <PlansSubScreenContainer>
      <PlanStepsHeader
        title="Education"
        subTitle="Give the patient an education content to increase their learning"
        icon="/img/plans/nav/education.svg"
        button={<></>}
      />
      <GoalsScreenContainer>
        <SearchInput
          valueProp={searchTerm}
          onChangeHandler={(e) => setSearchTerm(e.target.value)}
          width="100%"
        />
        {assignedGoalsCondition ? (
          <AssignedEducationList searchTerm={searchTerm} />
        ) : selectGoalsCondition ? (
          <SelectEducationList searchTerm={searchTerm} />
        ) : bothGoalsCondition ? (
          <>
            <AssignedEducationList searchTerm={searchTerm} />
            <SelectEducationList searchTerm={searchTerm} />
          </>
        ) : (
          <EmptyEducation />
        )}
      </GoalsScreenContainer>
      <CreatePlansFooter nextStep="Review" prevStep="Lifestyle" />
    </PlansSubScreenContainer>
  );
};
