import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { DiabetesComplicationDTO } from "../../models/diabetes-complication-dtos/diabetes-complication-dto";

export function useGetDiabetesComplications(): () => Promise<
  DiabetesComplicationDTO[]
> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<DiabetesComplicationDTO[]>(
      `${BASE_PATH}/diabetes-complications`
    );

    return response.data;
  }, [client]);
}
