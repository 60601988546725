import styled from "styled-components/macro";
import { transition } from "../../styles/global-style";
import { mediumFont } from "../../styles/classes/gloabl-classes";

export const SubNavbarContainer = styled.div`
  @media screen and (max-width: 580px) {
    overflow: auto;

    ::-webkit-scrollbar {
      /* Hides Scroll bar for Chrome, Safari and Opera */
      display: none;
    }
  }
`;

export const SubNavbarList = styled.ul`
  display: flex;
  gap: 0 1rem;
  padding: ${({ theme }) => theme.padding.px_1};

  a {
    color: inherit;
    text-decoration: none;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    height: 50px;
    align-items: center;

    overflow-x: auto;

    ::-webkit-scrollbar {
      height: 5px;
    }
  }
`;

export const SubNavbarListItem = styled.li<{ activeLink: boolean }>`
  cursor: pointer;
  border-radius: 3rem;
  padding: 0.4rem 0.5rem;
  transition: ${transition};
  ${mediumFont}
  background: ${({ theme }) => theme.colors.containerColor};
  border: 1px solid
    ${({ theme, activeLink }) =>
      activeLink ? theme.colors.primaryColorAlt : theme.colors.borderColor};
  color: ${({ theme, activeLink }) =>
    activeLink ? theme.colors.titleColor : theme.colors.textColor};
  opacity: 0.9;
  width: max-content;

  :hover {
    opacity: 1;
    transform: scale(1.1);
  }

  @media screen and (max-width: 580px) {
    white-space: nowrap;
  }
`;
