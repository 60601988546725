import { Modal } from "../../../../components/modal/modal";
import { useModalHelpers } from "../../../../components/modal/use-modal-helpers";
import { LabTestResultModal } from "../lab-test-result-modal/lab-test-result-modal";
import { Button } from "../../../../styles/classes/reusable-classes";

export const AddNewLabResult = () => {
  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  return (
    <>
      <Button
        width={230}
        height={44}
        flex
        type="button"
        onClick={openModal}
        outlinedPrimary
      >
        <span className="material-symbols-outlined">add</span>
        Add new lab result
      </Button>
      <Modal showModal={isModalOpen} handleHideModal={closeModal}>
        <LabTestResultModal
          key={modalBodyKey}
          onClose={closeModal}
          mode={{ kind: "add" }}
        />
      </Modal>
    </>
  );
};
