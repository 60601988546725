import { useParams } from "react-router-dom";
import { Loading } from "../../../../../components/loading";
import { CGMReportResponseCards } from "./cgm-report-response-cards/cgm-report-response-cards";
import { CGMReportResponseHeader } from "./cgm-report-response-header/cgm-report-response-header";
import {
  CGMReportLoadedResponse,
  CGMReportLoadedResponseProvider,
} from "../context/cgm-report-loaded-response-context";
import { useGetPatient } from "../../../../../hooks/patient-hooks/use-get-patient";
import { useGetPatientCGMEntries } from "../../../../../hooks/use-get-patient-cgm-entries";
import { useCallback } from "react";
import { CGMReportResponceCharts } from "./cgm-report-response-charts/cgm-report-response-charts";
import { AGPLoadedResponseProvider } from "../context/agp-loaded-response-context";
import { CGMReportResponseAGP } from "./cgm-report-response-agp/cgm-report-response-agp";
import { useDateRange } from "../../../../../components/date-range/context/date-range-context";

export const CGMReportResponse = () => {
  const { id } = useParams();
  const patientID = parseInt(id!);

  const getPatient = useGetPatient();
  const getPatientCGMEntries = useGetPatientCGMEntries();

  const { temporalStartDate, temporalEndDate } = useDateRange();

  const cgmReportResponseLoadCallback = useCallback(async (): Promise<
    CGMReportLoadedResponse
  > => {
    const patientDTO = await getPatient(patientID);

    const cgmSource = "Libreview";
    const patientCGMEntryDTOs = await getPatientCGMEntries(
      patientID,
      cgmSource,
      temporalStartDate,
      temporalEndDate
    );

    return {
      patientDTO,
      patientCGMEntryDTOs,
    };
  }, [
    patientID,
    getPatient,
    getPatientCGMEntries,
    temporalStartDate,
    temporalEndDate,
  ]);

  return (
    <Loading
      load={cgmReportResponseLoadCallback}
      successComponent={(cgmReportResponse) => (
        <CGMReportLoadedResponseProvider
          patientDTO={cgmReportResponse.patientDTO}
          patientCGMEntryDTOs={cgmReportResponse.patientCGMEntryDTOs}
        >
          <CGMReportResponseHeader />
          <AGPLoadedResponseProvider>
            <CGMReportResponseCards />
            <CGMReportResponseAGP />
            <CGMReportResponceCharts />
          </AGPLoadedResponseProvider>
        </CGMReportLoadedResponseProvider>
      )}
    />
  );
};
