import { Button } from "../../../../../styles/classes/reusable-classes";
import { LabResultsFormFooterContainer } from "./styled-lab-results-form-footer";

type LabResultsFormFooterProps = {
  onClose: () => void;
};

export const LabResultsFormFooter = (props: LabResultsFormFooterProps) => {
  const { onClose } = props;

  return (
    <LabResultsFormFooterContainer>
      <Button width={220} height={49} outlined type="button" onClick={onClose}>
        Close
      </Button>
      <Button width={220} height={49} type="submit">
        Confirm
      </Button>
    </LabResultsFormFooterContainer>
  );
};
