import axios from "axios";
import { useImportLibreview } from "../../../../hooks/use-import-libreview";
import { alertAndReload } from "../../../../utils/alert-and-reload";
import { useApiWithFullPageLoader } from "../../../../hooks/use-api-with-full-page-loader";
import { useParams } from "react-router-dom";
import { DropdownListItemFileUpload } from "./dropdown-list-item-file-upload";

export function ImportLibreview() {
  const { id } = useParams();
  const patientID = parseInt(id!);

  const importLibreview = useApiWithFullPageLoader(useImportLibreview());

  async function handleFileSelect(libreviewCSV: string) {
    try {
      await importLibreview(patientID, libreviewCSV);
      alertAndReload("Libreview file imported successfully!");
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 400) {
        await handlePatientNameChange(libreviewCSV, e.response.data as string);
      } else {
        alert("Failed to import the libreview file :(");
      }
    }
  }

  async function handlePatientNameChange(
    libreviewCSV: string,
    errorMessage: string
  ) {
    const userResponse = prompt(
      `${errorMessage} Type 'force' (without quotes) to force import.`
    );

    if (userResponse === "force") {
      await handleForceImportLibreview(libreviewCSV);
    } else if (userResponse != null && userResponse !== "force") {
      alert("Didn't receive 'force' as input. Aborting.");
    }
  }

  async function handleForceImportLibreview(libreviewCSV: string) {
    try {
      await importLibreview(patientID, libreviewCSV, true);
      alertAndReload("Libreview file imported successfully!");
    } catch (e) {
      alert("Failed to import the libreview file :(");
    }
  }

  return (
    <DropdownListItemFileUpload onFileSelect={handleFileSelect}>
      Libreview
    </DropdownListItemFileUpload>
  );
}
