import { useState } from "react";
import { SearchInput } from "../../../../../../../styles/atoms/search-input";
import { useActivePrescriptionItem } from "../../../context/active-prescription-context";
import { NewPrescriptionSidebarCard } from "./new-prescription-sidebar-card/new-prescription-sidebar-card";
import {
  PrescriptionSidebarBody,
  PrescriptionSidebarContainer,
  PrescriptionSidebarHeader,
} from "./styled-new-prescription-sidebar";
import { useGetMedicationDTOs } from "../../../../hooks/use-get-medication-dtos";

export const NewPrescriptionSidebar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { handleSetActivePrescriptionItem, activePrescriptionItem } =
    useActivePrescriptionItem();

  const { medicationDTOs } = useGetMedicationDTOs();

  return (
    <PrescriptionSidebarContainer>
      <PrescriptionSidebarHeader>
        <SearchInput
          width="261px"
          valueProp={searchTerm}
          onChangeHandler={(e) => setSearchTerm(e.target.value)}
        />
      </PrescriptionSidebarHeader>
      <PrescriptionSidebarBody>
        {medicationDTOs
          .filter((item) => {
            if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
              return item;
            } else if (searchTerm === "") {
              return item;
            } else {
              return 0;
            }
          })
          .map((item) => (
            <NewPrescriptionSidebarCard
              key={item.id}
              card={item}
              handleActiveState={() =>
                handleSetActivePrescriptionItem({
                  id: item.id,
                  medicine: item,
                })
              }
              activeState={item.id === activePrescriptionItem.id}
            />
          ))}
      </PrescriptionSidebarBody>
    </PrescriptionSidebarContainer>
  );
};
