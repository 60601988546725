import { Controller, useFormContext } from "react-hook-form";
import { PatientProfileEditTimelineModalInputs } from "../../patient-profile-edit-timeline-modal";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileModalLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import {
  ErrorText,
  RadioButton,
} from "../../../../../../../../styles/classes/reusable-classes";
import { Dropdown } from "../../../../../../../../components/dropdown/dropdown";
import { DropdownListItem } from "../../../../../../../../components/dropdown/styled-dropdown";
import { useRef } from "react";
import { BARIATRIC_SURGERIES } from "../../../../../../../../models/surgery";
import {
  BariatricSurgeryDateInputContainer,
  BariatricSurgeryInputContainer,
} from "./styled-patient-profile-bariatric-surgery-form-control";

type PatientProfileBariatricSurgeryFormControlProps = {
  isBariatricSurgery: boolean;
  toggleIsBariatricSurgery: () => void;
};

export const PatientProfileBariatricSurgeryFormControl = (
  props: PatientProfileBariatricSurgeryFormControlProps
) => {
  const { isBariatricSurgery, toggleIsBariatricSurgery } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext<PatientProfileEditTimelineModalInputs>();

  const bariatricSurgeryDiagnosedSinceRef = useRef<HTMLInputElement | null>(
    null
  );

  return (
    <PatientProfileModalFormControl>
      <PatientProfileModalLabelContainer>
        <label>Bariatric Surgery?</label>
      </PatientProfileModalLabelContainer>

      <BariatricSurgeryInputContainer>
        <RadioButton
          type="button"
          checked={isBariatricSurgery}
          onClick={toggleIsBariatricSurgery}
        >
          <span></span>
        </RadioButton>

        {isBariatricSurgery ? (
          <PatientProfileModalInputContainer>
            <Controller
              name="bariatricSurgery.surgery"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  text={value !== undefined ? value : "Select Surgery Type"}
                  variant="designed-ux"
                >
                  {BARIATRIC_SURGERIES.map((bariatricSurgery) => (
                    <DropdownListItem
                      key={bariatricSurgery}
                      variant="designed-ux"
                      onClick={() => onChange(bariatricSurgery)}
                    >
                      {bariatricSurgery}
                    </DropdownListItem>
                  ))}
                </Dropdown>
              )}
            />
            {errors.bariatricSurgery?.surgery?.type === "required" ? (
              <ErrorText>Required</ErrorText>
            ) : (
              <></>
            )}
            <BariatricSurgeryDateInputContainer>
              <PatientProfileModalInputContainer
                hasOneInput
                onClick={() => {
                  bariatricSurgeryDiagnosedSinceRef.current?.showPicker();
                }}
              >
                <span className="material-symbols-outlined">
                  calendar_today
                </span>
                <Controller
                  name="bariatricSurgery.diagnosedSince"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { value, onChange } }) => (
                    <input
                      ref={bariatricSurgeryDiagnosedSinceRef}
                      type="month"
                      value={value === undefined ? undefined : value}
                      placeholder="Date"
                      onChange={(e) =>
                        onChange(
                          e.target.value !== undefined ? e.target.value : ""
                        )
                      }
                    />
                  )}
                />
              </PatientProfileModalInputContainer>
            </BariatricSurgeryDateInputContainer>
          </PatientProfileModalInputContainer>
        ) : (
          <></>
        )}
      </BariatricSurgeryInputContainer>
    </PatientProfileModalFormControl>
  );
};
