import { Modal } from "../../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../../components/modal/use-modal-helpers";
import { AddBloodPressureButton } from "../styled-blood-pressure";
import { BloodPressureModalBody } from "./blood-pressure-modal-body/blood-pressure-modal-body";

export const BloodPressureModal = () => {
  const {
    openModal,
    closeModal,
    isModalOpen,
    modalBodyKey,
  } = useModalHelpers();

  return (
    <>
      <AddBloodPressureButton onClick={openModal}>
        <span className="material-symbols-outlined">add</span>
        Add Reading
      </AddBloodPressureButton>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <BloodPressureModalBody key={modalBodyKey} onClose={closeModal} />
      </Modal>
    </>
  );
};
