import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  mediumFont,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const NutritionReportHeaderContainer = styled.div`
  ${flexCenterSpaceBetween}
  margin: 0px 24px;
`;

export const NutritionReportHeaderPatientInfo = styled.div`
  span {
    ${mediumFont}
    color: ${({ theme }) => theme.colors.textColor};
  }

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
  }
`;

export const NutritionReportHeaderIcons = styled.div`
  ${flexCenter}
`;

export const NutritionReportHeaderReloadIcon = styled.span`
  color: ${({ theme }) => theme.colors.textColor};
  cursor: pointer;
`;
