import styled from "styled-components/macro";
import { regularMediumFont } from "../../../../../styles/classes/gloabl-classes";

export const ImmediateAttentionPatientsSidebarPatientKeyIndicatorsContainer = styled.div`
  margin-bottom: 16px;
  padding-left: 8px;

  p {
    margin: 0px;
    ${regularMediumFont}
  }

  ul {
    margin: 8px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0px 8px;

    li {
      display: flex;
      flex-direction: column;

      strong {
        font-size: ${({ theme }) => theme.typography.fontSize.smFontSize};
        font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
        color: ${({ theme }) => theme.colors.textColor};
      }

      span {
        font-size: ${({ theme }) => theme.typography.fontSize.mdFontSize};
        color: ${({ theme }) => theme.colors.titleColor};
        font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
        margin-right: 0.3rem;
      }
    }
  }
`;
