import { EmptyState } from "../../../../../components/empty-state/empty-state";

export const EmptyMedical = () => {
  return (
    <EmptyState
      hasHeader={false}
      hasSearch={false}
      text="No Current Medications"
      subText="There are no current medications for this patient"
      button={<></>}
      title=""
    />
  );
};
