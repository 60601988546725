import { Link, useLocation } from "react-router-dom";
import { NavContainer, NavList, NavListItem } from "./styled-screen-navbar";

export type NavLink = {
  name: string;
  path: string;
};

type ScreenNavProps = {
  links: NavLink[];
  initialActiveLinkIndex?: number;
};

export const ScreenNavbar = (props: ScreenNavProps) => {
  const { links } = props;
  const location = useLocation();

  return (
    <NavContainer>
      <NavList>
        {links.map((link, index) => (
          <Link key={index} to={link.path}>
            <NavListItem activeLink={location.pathname.startsWith(link.path)}>
              {link.name}
            </NavListItem>
          </Link>
        ))}
      </NavList>
    </NavContainer>
  );
};
