import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  flexCenterSpaceBetween,
  lgSemiBoldFont,
  mediumRegularFont,
  regularMediumFont,
  smallRegularFont,
  xlSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";
import { NavbarHeight, SidebarWidth } from "../../../../../styles/global-style";
import { CreatePlansHeaderHeight } from "../../plan-steps/styles";

// I mean by CPlans -> CreatePlans
export const CPlansHeaderContainer = styled.header`
  padding: 8px 0px;
  position: fixed;
  top: ${NavbarHeight};
  left: ${SidebarWidth};
  right: 0;
  background: ${({ theme }) => theme.colors.containerColor};
  height: ${CreatePlansHeaderHeight};
  z-index: ${({ theme }) => theme.zIndex.z300};

  @media screen and (max-width: 500px) {
    left: 0;
  }
`;

export const CPlansHeaderTitleContainer = styled.div`
  ${flexCenterSpaceBetween}
  padding: 0px 24px;
  height: 76px;

  @media screen and (max-width: 400px) {
    padding: 0px 8px;
  }
`;

export const CPlansTitle = styled.div`
  margin: 1rem 0rem 0rem 1rem;
  position: fixed;
  left: 120px;
  z-index: 301;

  @media screen and (max-width: 500px) {
    left: 40px;
  }

  @media screen and (max-width: 400px) {
    left: 20px;
  }

  h1 {
    margin: 0;
    ${xlSemiBoldFont}
    color: ${({ theme }) => theme.colors.titleColor};

    @media screen and (max-width: 1060px) {
      ${lgSemiBoldFont}
    }
  }

  div {
    ${flexCenter}

    p {
      ${mediumRegularFont}
      color: ${({ theme }) => theme.colors.titleColor};
      margin: 0rem;
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
    }
  }
`;

export const CPlansHeaderLeftContainer = styled.div`
  ${flexCenter}

  span {
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const CPlansHeaderRightContainer = styled.div`
  ${flexCenterCenter}
  gap: 0rem 1rem;
`;

export const CPlansHeaderProgressList = styled.ul`
  ${flexCenter}
  gap: 0px 24px;
  padding: 24px 16px 0px 8px;
  margin: 0;

  @media screen and (max-width: 1285px) {
    overflow-x: auto;

    ::-webkit-scrollbar {
      height: 4px;
    }
  }
`;

export const CPlansHeaderPrgressListItem = styled.li<{
  active: boolean;
  completed?: boolean;
}>`
  ${flexCenter}
  gap: 0px 16px;
  padding: 8px 16px;
  border: 1px solid
    ${({ theme, active, completed }) =>
      active
        ? theme.colors.success
        : completed
        ? "transparent"
        : theme.colors.borderColor};
  border-radius: 12px;
  background: ${({ theme, completed }) =>
    completed ? theme.newColors.successAlt : theme.colors.containerColor};
  position: relative;

  ::after {
    content: "";
    width: 20px;
    height: 2px;
    background: ${({ theme }) => theme.colors.borderColor};
    position: absolute;
    right: -23px;
  }

  :last-child {
    ::after {
      width: 0px;
    }
  }

  @media screen and (max-width: 1285px) {
    margin-bottom: 7px;
  }
`;

export const CPlansHeaderPrgressListItemImageContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenterCenter}
  padding: 0.5rem;
  background: ${({ theme }) => theme.colors.containerColor};

  img {
    width: 20px;
    height: 20px;
  }
`;

export const CPlansHeaderPrgressListItemTextContainer = styled.div`
  span {
    ${smallRegularFont}
    color: ${({ theme }) => theme.newColors.imageFront};
  }

  p {
    margin: 0;
    color: ${({ theme }) => theme.newColors.imageFront};
    ${regularMediumFont}

    @media screen and (max-width: 1285px) {
      min-width: 68px;
    }
  }
`;
