import { css } from "styled-components/macro";
import { mediumFont } from "../../../../styles/classes/gloabl-classes";

export const MedicationsInputCSS = css`
  background: transparent;
  border: 0;
  outline: 0;
  width: 100%;
  height: 100%;
  ${mediumFont}
  color: ${({ theme }) => theme.colors.titleColor};
`;
