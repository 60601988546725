import { PropsWithChildren } from "react";
import { LoadablePatientNutritionReportDailySummaryProvider } from "./loadable-patient-nutrition-report-daily-summary-context";
import { NutritionReportMealTableRowProvider } from "./nutrition-report-meal-table-row-context";
import { NutritionReportSelectedTableRowsProvider } from "./nutrition-report-selected-table-rows-context";
import { useParams } from "react-router-dom";

type NutritionReportProvidersProps = PropsWithChildren<{}>;

export const NutritionReportProviders = (
  props: NutritionReportProvidersProps
) => {
  const { children } = props;

  const { patientID } = useParams();

  return (
    <>
      {patientID !== undefined ? (
        <LoadablePatientNutritionReportDailySummaryProvider>
          <NutritionReportSelectedTableRowsProvider>
            <NutritionReportMealTableRowProvider>
              {children}
            </NutritionReportMealTableRowProvider>
          </NutritionReportSelectedTableRowsProvider>
        </LoadablePatientNutritionReportDailySummaryProvider>
      ) : (
        children
      )}
    </>
  );
};
