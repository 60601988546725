import styled from "styled-components/macro";
import { flexCenterSpaceBetween } from "../../../../../styles/classes/gloabl-classes";

export const ViewPlansHomeCardsList = styled.ul`
  display: grid;
  place-items: center;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, 343px);
  place-items: center;
  justify-content: center;
`;

export const ViewPlansHomeCardsListItem = styled.li`
  background: ${({ theme }) => theme.colors.inputColor};
  background-size: 40%;
  height: 170px;
  min-width: 343px;
  border-radius: 1rem;
  padding: 0rem 1rem;
  ${flexCenterSpaceBetween}
  flex-direction: row-reverse;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;
