import { Temporal } from "temporal-polyfill";
import { Medicine } from "../../build-days-aggregates";
import { GoogleDataTableRow } from "react-google-charts";
import { buildGapRow } from "../build-gap-row";
import { buildMedicineRow } from "./build-medicine-row";

export function buildMedicinesRows(
  date: Temporal.PlainDate,
  medicines: Medicine[],
  reverseVAxisTicks: number[]
): GoogleDataTableRow[] {
  const rows: GoogleDataTableRow[] = [];

  for (const medicine of medicines) {
    const time = medicine.time;
    const plainDateTime = date.toPlainDateTime(time);

    rows.push(buildGapRow(plainDateTime, reverseVAxisTicks));
    rows.push(buildMedicineRow(plainDateTime, reverseVAxisTicks, medicine));
  }

  return rows;
}
