import { PatientActivityLogDTO } from "../../../../../models/patient-activity-log-dtos/patient-activity-log-dto";

export function activityToHtmlTooltip(
  patientActivityLog: PatientActivityLogDTO
): string {
  const divStyle = `
    width: 300px;
    background: #fff;
    box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0) !important;
    border: 1px solid rgba(255, 0, 0 ,0) !important;
  `;

  const titleStyle = `
    font-size: 1.1rem;
    font-weight: 700;
    color: #040415;
    margin: 0rem;
  `;

  const topContainerStyle = `
    background: #eaf7ff;
    border-bottom: 1px solid #ecedef;
    padding: 1rem;
  `;

  const minsTextStyle = `
    font-size: 1rem;
    font-weight: 500;
    color: #8c8e97;
  `;

  const activityContainerStyle = `
    padding: 1rem 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;

  const activityNameStyle = `
   font-size: 16px;
   font-weight: 500;
   color: #040415;
  `;

  const activityName =
    patientActivityLog.activity?.name === undefined
      ? patientActivityLog.description
      : patientActivityLog.activity?.name;

  const headerHtml = `
      <div style="${topContainerStyle}">
        <p style='${titleStyle}'>
          ${
            patientActivityLog.activity?.activityMode.name === undefined
              ? "Other Activity"
              : patientActivityLog.activity?.activityMode.name
          }
        </p>
      </div>
    `;

  const activitesHtml = `
      <div style='${activityContainerStyle}'>
        <p style='${activityNameStyle}'>${activityName}</p>
        <span style='${minsTextStyle}'>${patientActivityLog.durationMins} mins
        </span>
      </div>
      `;

  return `
  <div style='${divStyle}'>
    ${headerHtml}
    ${activitesHtml}
  </div>
  `;
}
