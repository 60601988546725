import { Button } from "../../../../../styles/classes/reusable-classes";
import { usePlansGoals } from "../../context/goals-context";
import { ContainerTitle } from "../goals/styled-goals";
import { PlanStepInfo } from "../../components/plan-step-info/plan-step-info";
import { GoalsListItem } from "../styles";

type SelectLifestyleListProps = {
  searchTerm: string;
};

export const SelectLifestyleList = (props: SelectLifestyleListProps) => {
  const { searchTerm } = props;
  const { handleAddGoal, selectLifestyleGoalsList } = usePlansGoals();

  return (
    <ul>
      <ContainerTitle>Select new Lifestyle goals</ContainerTitle>
      {selectLifestyleGoalsList
        .sort((a, b) => {
          if (a.goal > b.goal) {
            return 1;
          } else {
            return -1;
          }
        })
        .filter((item) => {
          if (searchTerm === "") {
            return item;
          } else if (
            item.goal.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return item;
          } else {
            return 0;
          }
        })
        .map((goal, index) => (
          <GoalsListItem key={index}>
            <PlanStepInfo
              arabicFlex
              icon="/img/plans/nav/goals.svg"
              title={goal.goal}
              subTitle={
                goal.patientPlans.length === 0
                  ? ``
                  : `Assigned for weeks: (${goal.patientPlans
                      .map((plan) => plan.planNumber)
                      .join(", ")})`
              }
            />
            <Button
              flex
              type="button"
              outlinedPrimary
              onClick={() =>
                handleAddGoal({
                  goal: goal.goal,
                  goalType: "LifestyleInstruction",
                })
              }
            >
              <span className="material-symbols-outlined">add</span>
              Add
            </Button>
          </GoalsListItem>
        ))}
    </ul>
  );
};
