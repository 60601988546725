import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useGetPatientDetailedPlan } from "../../../../../../hooks/patient-plan-hooks/use-get-patient-detailed-plan";
import { SinglePlanDropdownsContainer } from "../../styled-view-plans";
import { SinglePlanGoals } from "./single-plan-goals";
import { SinglePlanLifestyle } from "./single-plan-lifestyle";
import { SinglePlanNutrition } from "./single-plan-nutrition";
import { SinglePlanFeedback } from "./single-plan-feedback";
import { SinglePlanEducation } from "./single-plan-education";
import { usePatientUser } from "../../../../../../context/auth-context/auth-context";
import { Loading } from "../../../../../../components/loading";

export const SinglePlan = () => {
  const { patientId } = usePatientUser();

  const { viewPlanID } = useParams();

  const getPatientDetailedPlan = useGetPatientDetailedPlan();

  const load = useCallback(async () => {
    const planId = parseInt(viewPlanID!);

    const patientDetailedPlanDTO = await getPatientDetailedPlan(
      patientId,
      planId
    );

    return {
      patientDetailedPlanDTO,
    };
  }, [patientId, viewPlanID, getPatientDetailedPlan]);

  return (
    <Loading
      load={load}
      successComponent={(patientDetailedPlanDTO) => (
        <SinglePlanDropdownsContainer>
          <SinglePlanGoals
            patientDetailedPlanDTO={
              patientDetailedPlanDTO.patientDetailedPlanDTO
            }
          />
          <SinglePlanFeedback
            patientDetailedPlanDTO={
              patientDetailedPlanDTO.patientDetailedPlanDTO
            }
          />
          <SinglePlanNutrition
            patientDetailedPlanDTO={
              patientDetailedPlanDTO.patientDetailedPlanDTO
            }
          />
          <SinglePlanLifestyle
            patientDetailedPlanDTO={
              patientDetailedPlanDTO.patientDetailedPlanDTO
            }
          />
          <SinglePlanEducation
            patientDetailedPlanDTO={
              patientDetailedPlanDTO.patientDetailedPlanDTO
            }
          />
        </SinglePlanDropdownsContainer>
      )}
    />
  );
};
