import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PatientDTO } from "../../models/patient-dtos/patient-dto";

export function useDeletePatient(): (patientDTO: PatientDTO) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientDTO) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      await client.delete<void>(`${PATIENTS_PATH}/${patientDTO.id}`);
    },
    [client]
  );
}
