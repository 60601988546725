import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  flexColCenterCenter,
  regularRegularFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const MessagingChannelListHeaderContainer = styled.div`
  ${flexColCenterCenter}
  gap: 16px 0px;
  padding: 16px;
  position: relative;
`;

export const MessagingChannelListHeaderTop = styled.div`
  width: 100%;
  ${flexCenterSpaceBetween}
`;

export const MessagingChannelListHeaderTopIcons = styled.div`
  ${flexCenter}
  gap: 0px 8px;

  .bell-icon {
    filter: invert(0.5);
  }
`;

export const MessagingChannelListHeaderTopCloseSidebarIcon = styled.div`
  display: none;
  cursor: pointer;

  @media screen and (max-width: 960px) {
    display: block;
  }
`;

export const MessagingChannelListHeaderTopUserInfo = styled.div`
  ${flexCenter}

  p {
    margin: 0px;
    ${regularRegularFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;

export const MessagingChannelListHeaderBottom = styled.div`
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.inputColor};

  input {
    outline: 0px;
    border: 0px;
    background: transparent;
  }
`;
