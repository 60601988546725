import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
} from "../../../../../../../../../styles/classes/gloabl-classes";
import {
  lightShadow,
  transition,
} from "../../../../../../../../../styles/global-style";

export const MessagingMediaSidebarCustomMessageImagesModalContainer = styled.div`
  border-radius: 8px;
  padding: 24px;
  background: ${({ theme }) => theme.colors.containerColor};
  z-index: ${({ theme }) => theme.zIndex.z600};
`;

export const MessagingMediaSidebarCustomMessageImagesModalSlider = styled.div`
  position: relative;
  ${flexCenter}
`;

export const MessagingMediaSidebarCustomMessageImagesModalSlide = styled.div<{
  showImage: boolean;
}>`
  display: ${({ showImage }) => (showImage ? "block" : "none")};
  position: relative;

  img,
  video {
    max-width: 80vw;
    max-height: 80vh;
  }
`;

export const MessagingMediaSidebarCustomMessageImagesModalSlideNumber = styled.div`
  background: rgba(0, 0, 0, 0.5);
  width: fit-content;
  padding: 8px;
  position: absolute;
  top: 0px;
  right: 0px;

  p {
    color: ${({ theme }) => theme.colors.buttonColor};
    margin: 0px;
  }
`;

export const MessagingMediaSidebarCustomMessageImagesModalSliderButtons = styled.div`
  ${flexCenterSpaceBetween}
  position: absolute;
  top: 30%;
  left: 20px;
  right: 20px;

  span {
    font-size: 100px;
    color: ${({ theme }) => theme.colors.borderColor};
    cursor: pointer;
    ${lightShadow}
    transition: ${transition};

    ::selection {
      background: transparent;
    }

    :hover {
      color: ${({ theme }) => theme.colors.primaryColorAlt};
      scale: 1.3;
    }
  }
`;
