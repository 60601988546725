import {
  MedicationsInputContainer,
  MedicationsInputInput,
  MedicationsInputLabel,
  MedicationsInputWrapper,
} from "../styled-medications-inputs";

type MedicationsDateInputProps = {
  label: string;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  isOptional?: true;
};

export const MedicationsDateInput = (props: MedicationsDateInputProps) => {
  const { label, value, onChange, isOptional } = props;

  return (
    <MedicationsInputContainer>
      <MedicationsInputLabel isOptional={isOptional}>
        {label}
        {isOptional !== undefined && <span>(Optional)</span>}
      </MedicationsInputLabel>
      <MedicationsInputWrapper>
        <MedicationsInputInput
          type="date"
          placeholder={label}
          value={value === undefined ? "" : value}
          onChange={(e) =>
            onChange(e.target.value === "" ? undefined : e.target.value)
          }
        />
      </MedicationsInputWrapper>
    </MedicationsInputContainer>
  );
};
