import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ModalForm } from "../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../components/modal/modal-header/modal-header";
import { useToastService } from "../../../../context/toast-service-context";
import { useAddPatientMedication } from "../../../../hooks/use-add-patient-medication";
import {
  Button,
  ErrorText,
  InputContainer,
  ModalFooter,
} from "../../../../styles/classes/reusable-classes";
import { MedicineModalContainer } from "./styled-medicine-modal-body";
import { FrequencyAutoSelect } from "../components/frequency-auto-select/frequency-auto-select";
import { Temporal } from "temporal-polyfill";
import { MedicationDTO } from "../../../../models/medications/medication-dto";
import { FrequencyDTO } from "../../../../models/frequencies/frequency-dto";
import { MedicationAutoSelect } from "./medication-auto-select/medication-auto-select";
import { MedicationsDateInput } from "../components/medication-inputs/medications-date-input/medications-date-input";
import { MedicationsNumberInput } from "../components/medication-inputs/medications-number-input/medications-number-input";
import { MedicationsNotesInput } from "../components/medication-inputs/medications-notes-input/medications-notes-input";
import { AssignedByRadioButton } from "../components/medication-inputs/assigned-by-radio-button/assigned-by-radio-button";
import { CreatePatientMedicationDTO } from "../../../../models/create-patient-medication-dto";
import { useState } from "react";
import { PutPatientMedicationDTO } from "../../../../models/patient-medication-dtos/put-patient-medication-dto";
import { PatientMedicationDTO } from "../../../../models/patient-medication-dto";
import { usePutPatientMedication } from "../../../../hooks/patient-medication-hooks/use-put-patient-medication";
import { useReloadMedications } from "../context/loadable-medications-context";

export type MedicationModalBodyProps = {
  closeModal: () => void;
  mode: { kind: "POST" } | { kind: "PUT"; data: PatientMedicationDTO };
};

type MedicationModalBodyInputs = {
  startDate?: Temporal.PlainDate;
  durationDays?: number;
  medication: MedicationDTO | undefined;
  frequency: FrequencyDTO | undefined;
  quantity?: number;
  notes?: string;
};

export const MedicationModalBody = (props: MedicationModalBodyProps) => {
  const { closeModal, mode } = props;

  const [isAssignedByOtida, setIsAssignedByOtida] = useState(false);
  const toggleIsAssignedByOtida = () =>
    setIsAssignedByOtida(!isAssignedByOtida);

  const { id } = useParams<{ id: string }>();

  const addPatientMedication = useAddPatientMedication();
  const putPatientMedication = usePutPatientMedication();
  const { showToast } = useToastService();
  const reloadMedications = useReloadMedications();

  const formDefaultValues: MedicationModalBodyInputs = {
    startDate: undefined,
    durationDays: undefined,
    medication: undefined,
    frequency: undefined,
    quantity: undefined,
    notes: undefined,
  };

  const editFormDefaultValues: PutPatientMedicationDTO =
    mode.kind === "PUT"
      ? {
          medicationId: mode.data.medicationId,
          frequencyId: mode.data.frequencyId,
          durationDays: mode.data.durationDays,
          notes: mode.data.notes,
          quantity: mode.data.quantity,
          startDate: mode.data.startDate,
        }
      : ({} as PutPatientMedicationDTO);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<MedicationModalBodyInputs>({
    defaultValues:
      mode.kind === "POST" ? formDefaultValues : editFormDefaultValues,
  });

  const medication = watch("medication");
  const startDate = watch("startDate");
  const durationDays = watch("durationDays");

  const onSubmit = handleSubmit(async (inputs) => {
    if (mode.kind === "POST") {
      // Here, we're sure that `selectedMedicationID` and `selectedFrequencyID` are not undefined due to required validation.
      const createDTO: CreatePatientMedicationDTO = {
        startDate: startDate?.toString(),
        durationDays: durationDays!,
        medicationId: inputs.medication!.id,
        frequencyId: inputs.frequency!.id,
        quantity: inputs.quantity!,
        notes: inputs.notes!,
        isAssignedByOtida: isAssignedByOtida,
      };
      try {
        await addPatientMedication(parseInt(id!), createDTO);
        showToast("Success", "Medicine added successfully!");
        closeModal();
        reloadMedications();
      } catch (e) {
        showToast("Error", "Failed to add Medicine :(");
      }
    } else {
      const putDTO: PutPatientMedicationDTO = {
        startDate: startDate?.toString(),
        durationDays: durationDays!,
        medicationId:
          inputs.medication?.id !== undefined
            ? inputs.medication?.id
            : mode.data.medicationId,
        frequencyId:
          inputs.frequency?.id !== undefined
            ? inputs.frequency?.id
            : mode.data.frequencyId,
        quantity: inputs.quantity!,
        notes: inputs.notes!,
      };

      try {
        await putPatientMedication(mode.data, putDTO);
        showToast("Success", "Medicine edited successfully!");
        closeModal();
        reloadMedications();
      } catch (e) {
        showToast("Error", "Failed to edit Medicine :(");
      }
    }
  });

  return (
    <ModalForm width={550} height={730} onSubmit={onSubmit}>
      <ModalHeader
        title={`${mode.kind === "POST" ? "New" : "Edit"} Medicine`}
        onModalClose={closeModal}
      />
      <MedicineModalContainer>
        <InputContainer>
          <Controller
            name="medication"
            control={control}
            rules={{ required: mode.kind === "POST" ? true : false }}
            render={({ field: { onChange } }) => (
              <MedicationAutoSelect
                onChange={onChange}
                errorState={errors.medication?.type === "required"}
                editValue={mode.kind === "PUT" ? mode.data.medication : ""}
              />
            )}
          />
          {errors.medication?.type === "required" && (
            <ErrorText forMedications>Enter medication name</ErrorText>
          )}
        </InputContainer>
        <InputContainer>
          <Controller
            name={`quantity`}
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <MedicationsNumberInput
                step="0.1"
                label="Quantity"
                subLabel={medication !== undefined ? medication.doseUnit : ""}
                value={value}
                onChange={onChange}
                errorState={errors.quantity?.type === "required"}
              />
            )}
          />
          {errors.quantity?.type === "required" && (
            <ErrorText forMedications>Enter medicatioin quantity</ErrorText>
          )}
        </InputContainer>
        <InputContainer>
          <Controller
            name="frequency"
            control={control}
            rules={{ required: mode.kind === "POST" ? true : false }}
            render={({ field: { onChange } }) => (
              <FrequencyAutoSelect
                onChange={onChange}
                errorState={errors.frequency?.type === "required"}
                defaultValue={
                  mode.kind === "PUT"
                    ? `${mode.data.frequency?.explanation} (${mode.data.frequency?.abbreviation})`
                    : ""
                }
              />
            )}
          />
          {errors.frequency?.type === "required" && (
            <ErrorText forMedications>Enter medication frequency</ErrorText>
          )}
        </InputContainer>
        <Controller
          name="startDate"
          control={control}
          rules={{ required: false }}
          render={({ field: { value, onChange } }) => (
            <MedicationsDateInput
              isOptional
              label="Start Date"
              value={value !== undefined ? value.toString() : ""}
              onChange={(newValue) =>
                onChange(
                  newValue === undefined
                    ? Temporal.Now.plainDateISO()
                    : Temporal.PlainDate.from(newValue)
                )
              }
            />
          )}
        />
        <InputContainer>
          <Controller
            name={`durationDays`}
            control={control}
            rules={{
              min: 1,
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
            }}
            render={({ field: { value, onChange } }) => (
              <MedicationsNumberInput
                isOptional
                label="Duration"
                step="1"
                subLabel="days"
                value={value}
                onChange={onChange}
                errorState={errors.durationDays?.type === "min"}
              />
            )}
          />
          {errors.durationDays?.type === "min" && (
            <ErrorText forMedications>Duration must be 1 day or more</ErrorText>
          )}
          {errors.durationDays?.type === "pattern" && (
            <ErrorText forMedications>Duration must be 1 day or more</ErrorText>
          )}
        </InputContainer>
        <Controller
          name={`notes`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <MedicationsNotesInput value={value} onChange={onChange} />
          )}
        />
        {mode.kind === "POST" ? (
          <AssignedByRadioButton
            isAssignedByOtida={isAssignedByOtida}
            toggleIsAssignedByOtida={toggleIsAssignedByOtida}
          />
        ) : (
          <></>
        )}
      </MedicineModalContainer>
      <ModalFooter>
        <Button type="button" outlined onClick={closeModal}>
          Cancel
        </Button>
        <Button type="submit">Confirm</Button>
      </ModalFooter>
    </ModalForm>
  );
};
