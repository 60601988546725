import styled, { css } from "styled-components/macro";
import {
  flexColCenterCenter,
  mediumFont,
} from "../../../styles/classes/gloabl-classes";
import {
  SinglePatientSidebarWidth,
  transition,
} from "../../../styles/global-style";
import { CreatePlansHeaderHeight } from "../plans/plan-steps/styles";
import { LoaderContainer } from "../../../components/loaders/otida-loader/styled-otida-loader";

export const SinglePatientSidebarLoadableContainer = styled.div<{
  $showSinglePatientSidebarUI: boolean;
  plansLayout: boolean;
}>`
  height: 100vh;
  background: ${({ theme }) => theme.colors.containerColor};
  width: ${SinglePatientSidebarWidth};
  overflow-y: auto;
  padding-bottom: 80px;

  height: ${({ plansLayout, $showSinglePatientSidebarUI }) =>
    plansLayout
      ? "570px"
      : $showSinglePatientSidebarUI && plansLayout
      ? "100vh"
      : "100vh"};
  margin-top: ${({ plansLayout, $showSinglePatientSidebarUI }) =>
    plansLayout
      ? `calc(${CreatePlansHeaderHeight} + 24px)`
      : $showSinglePatientSidebarUI && plansLayout
      ? "unset"
      : "unset"};

  overflow: ${({ $showSinglePatientSidebarUI, plansLayout }) =>
    $showSinglePatientSidebarUI && plansLayout
      ? "auto"
      : $showSinglePatientSidebarUI
      ? "auto"
      : "hidden"};
  opacity: ${({ $showSinglePatientSidebarUI, plansLayout }) =>
    $showSinglePatientSidebarUI && plansLayout
      ? "1"
      : $showSinglePatientSidebarUI
      ? "1"
      : "0"};
  transform: ${({ $showSinglePatientSidebarUI, plansLayout }) =>
    $showSinglePatientSidebarUI && plansLayout
      ? "translateX(0%)"
      : $showSinglePatientSidebarUI
      ? "translateX(0%)"
      : "translateX(100%)"};

  transition: ${transition};
  border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-top-left-radius: ${({ plansLayout }) =>
    plansLayout ? "24px" : "0px"};
  border-bottom-left-radius: ${({ plansLayout }) =>
    plansLayout ? "24px" : "0px"};

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    z-index: ${({ theme }) => theme.zIndex.z900};
    position: fixed;
    right: 0;
    width: 400px;
    top: 0;
    margin-top: 0px;
    height: 100vh;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    overflow: ${({ $showSinglePatientSidebarUI }) =>
      $showSinglePatientSidebarUI ? "auto" : "hidden"};
    opacity: ${({ $showSinglePatientSidebarUI }) =>
      $showSinglePatientSidebarUI ? "1" : "0"};
    transform: ${({ $showSinglePatientSidebarUI }) =>
      $showSinglePatientSidebarUI ? "translateX(0%)" : "translateX(100%)"};
  }

  @media screen and (max-width: 425px) {
    width: 100%;
    border-radius: 0px;
  }

  ${LoaderContainer} {
    width: 100%;
  }
`;

export const SinglePatientSidebarBackdrop = styled.div<{
  $showSinglePatientSidebarUI: boolean;
}>`
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    transition: ${transition};
    inset: 0;
    z-index: ${({ theme }) => theme.zIndex.z800};
    background: ${({ theme }) => theme.newColors.backdrop};
    width: ${({ $showSinglePatientSidebarUI }) =>
      $showSinglePatientSidebarUI ? "100%" : "0%"};
    overflow: ${({ $showSinglePatientSidebarUI }) =>
      $showSinglePatientSidebarUI ? "visible" : "hidden"};
    opacity: ${({ $showSinglePatientSidebarUI }) =>
      $showSinglePatientSidebarUI ? "1" : "0"};
  }

  @media screen and (max-width: 425px) {
    display: none;
  }
`;

export const CloseIcon = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const SidebarSubContainer = styled.div`
  padding: 2rem 0rem 0rem;
`;

export const SidebarSubContainerUserInfo = styled.div`
  ${flexColCenterCenter}
`;

export const SidebarSubContainerUserInfoButtons = styled.div`
  background: ${({ theme }) => theme.colors.inputColor};
  margin: 1.5rem 0rem;
  padding: 0.3rem;
  border-radius: 12px;
`;

export const SidebarSubContainerUserInfoButton = styled.button<{
  activeBtn: boolean;
}>`
  padding: 0.5rem;
  border-radius: 12px;
  color: ${({ activeBtn, theme }) =>
    activeBtn ? theme.colors.primaryColor : theme.colors.textColor};
  background: ${({ activeBtn, theme }) =>
    activeBtn ? theme.colors.containerColor : "transparent"};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  transition: ${transition};
  width: 120px;
`;

export const PersonalInfoItemCSS = css`
  ${mediumFont}
  color: #14212e;
  background: ${({ theme }) => theme.newColors.gray6};
  border-radius: 94px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
  width: max-content;
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.newColors.newBorderColorAlt};
  margin-right: 0.4rem;
`;
