import { Modal } from "../../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../../components/modal/use-modal-helpers";
import { PersonalInfoGroupText } from "../../styled-personal-info-tab";
import { WeightModalBody } from "./weight-modal-body/weight-modal-body";

export const WeightModal = () => {
  const { closeModal, isModalOpen, openModal, modalBodyKey } =
    useModalHelpers();

  return (
    <>
      <PersonalInfoGroupText onClick={openModal}>Edit</PersonalInfoGroupText>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <WeightModalBody key={modalBodyKey} onClose={closeModal} />
      </Modal>
    </>
  );
};
