import styled from "styled-components/macro";
import {
  flexCenter,
  lgBoldFont,
  mediumFont,
  mediumSemiBoldFont,
  smallRegularFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const PatientProfileLifeActivitiesHeaderSummaryCardsContainer = styled.div`
  ${flexCenter}

  @media screen and (max-width: 1280px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  @media screen and (max-width: 1024px) {
    ${flexCenter}
  }

  @media screen and (max-width: 910px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }
`;

export const PatientProfileLifeActivitiesHeaderSummaryContainer = styled.div`
  padding: 0px 8px;
`;

export const PatientProfileLifeActivitiesHeaderSummaryTitle = styled.p`
  ${lgBoldFont}
  color: ${({ theme }) => theme.newColors.textColorAlt2};
  margin: 0px;

  span {
    ${mediumSemiBoldFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const PatientProfileLifeActivitiesHeaderSummaryBottom = styled.div`
  ${flexCenter}
  gap: 0px 4px;
`;

export const PatientProfileLifeActivitiesHeaderSummaryBottomDot = styled.div<{
  color: string;
}>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const PatientProfileLifeActivitiesHeaderSummaryBottomText = styled.div<{
  positiveFeedback: boolean;
}>`
  ${flexCenter}
  gap: 0px 4px;

  h4 {
    margin: 0px;
    ${mediumFont}
    color: ${({ theme }) => theme.colors.textColor};
  }

  div {
    ${flexCenter}
    ${smallRegularFont}
    color: ${({ theme, positiveFeedback }) =>
      positiveFeedback ? theme.newColors.successAlt2 : theme.colors.danger};

    span {
      transform: ${({ positiveFeedback }) =>
        positiveFeedback ? "rotate(180deg)" : "rotate(0deg)"};
    }

    p {
      margin: 0px;
    }
  }
`;

export const PatientProfileLifeActivitiesHeaderDateRange = styled.div`
  background: ${({ theme }) => theme.colors.inputColor};
  width: 210px;
  height: 40px;
  border-radius: 8px;
  padding: 8px 16px;
  position: relative;

  input {
    border: 0;
    outline: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }
`;
