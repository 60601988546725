import { Fragment, useState } from "react";
import {
  TagDropdownButton,
  TagDropdownContainer,
  TagDropdownList,
  TagDropdownListItem,
} from "./styled-tag-dropdown";
import { buildNestedDropdownTags, DropdownTag } from "./tag-dropdown-utils";
import { TagNestedDropdown } from "./tag-nested-dropdown/tag-nested-dropdown";
import { useDropdown } from "../../../../../../../../components/dropdown/hooks/use-dropdown";

export type TagDropdownProps = {
  value: string | undefined;
  onChange: (value?: string, afterMealTag?: string) => void;
  filter?: { value: string; onChange: (value: string) => void };
  feedbackFilter?: boolean;
};

export function TagDropdown(props: TagDropdownProps) {
  const { value, onChange, filter, feedbackFilter } = props;

  const {
    dropdownContainerRef,
    hideDropdown,
    showDropdown,
    toggleDropdown,
  } = useDropdown();

  const [showNestedList, setShowNestedList] = useState(false);

  const handleCloseAllDropdowns = () => {
    hideDropdown();
    setShowNestedList(false);
  };

  const handleToggleDropdownAndCloseNestedDropdown = () => {
    toggleDropdown();
    setShowNestedList(false);
  };

  const handleDropdownListItemClick = (tag: DropdownTag) => {
    if (filter?.value !== undefined && tag.tagName !== "After Meal") {
      filter.onChange(tag.tagName);
    }

    if (tag.tagName !== "After Meal") {
      handleCloseAllDropdowns();
      onChange(tag.tagName);
    } else {
      setShowNestedList(!showNestedList);
    }
  };

  const tags = buildNestedDropdownTags().map((tag, index) => (
    <Fragment key={index}>
      <TagDropdownListItem
        active={
          showNestedList && tag.nestedTags !== undefined ? true : undefined
        }
        showNestedList={showNestedList ? true : undefined}
        onClick={() => handleDropdownListItemClick(tag)}
      >
        {tag.tagName}
        {tag.nestedTags !== undefined ? (
          <span className="material-symbols-outlined">expand_more</span>
        ) : (
          <></>
        )}
      </TagDropdownListItem>
    </Fragment>
  ));

  return (
    <TagDropdownContainer
      ref={dropdownContainerRef}
      filter={filter !== undefined ? "true" : "false"}
    >
      <TagDropdownButton
        type="button"
        value={value || filter?.value}
        showDropdown={showDropdown}
        onClick={handleToggleDropdownAndCloseNestedDropdown}
        feedbackFilter={feedbackFilter}
      >
        {value !== undefined && filter?.value === undefined
          ? value
          : value === undefined && filter?.value !== undefined
          ? filter.value
          : "Select Tag"}
        <span className="material-symbols-outlined">expand_more</span>
      </TagDropdownButton>
      <TagDropdownList
        showDropdown={showDropdown}
        showNestedList={showNestedList}
      >
        {filter !== undefined ? (
          <>
            <TagDropdownListItem
              onClick={() => {
                filter.onChange("All");
                handleCloseAllDropdowns();
              }}
            >
              All
            </TagDropdownListItem>
            {tags}
          </>
        ) : (
          tags
        )}
      </TagDropdownList>
      {buildNestedDropdownTags().map(
        (tag, index) =>
          tag.nestedTags !== undefined &&
          showNestedList && (
            <TagNestedDropdown
              key={`${index}-${tag.tagName}`}
              tag={tag}
              onChange={onChange}
              handleCloseAllDropdowns={handleCloseAllDropdowns}
              filterOnChange={filter?.onChange}
            />
          )
      )}
    </TagDropdownContainer>
  );
}
