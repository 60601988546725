import { TargetAudienceAutoSelectListItem } from "../styled-target-audience-autoselect-list";
import { TargetAudienceAutoSelectInput } from "./styled-search-for-target-audience-list-item";
import { useTargetAudienceSearchTerm } from "../../context/target-audience-search-term-context";

export const SearchForTargetAudienceListItem = () => {
  const {
    searchTerm,
    handleOnSearchInputChange,
  } = useTargetAudienceSearchTerm();

  return (
    <TargetAudienceAutoSelectListItem className="target-audience-search-list-item">
      <TargetAudienceAutoSelectInput
        dir="auto"
        type="search"
        placeholder="Choose target audience"
        value={searchTerm}
        onChange={handleOnSearchInputChange}
      />
    </TargetAudienceAutoSelectListItem>
  );
};
