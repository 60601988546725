import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";
import { roundTo1DecimalPlace } from "../../../../../utils/math-utils";

type NutritionReportProteinPercentageTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportProteinPercentageTableCell = (
  props: NutritionReportProteinPercentageTableCellProps
) => {
  const { kind } = props;

  const { proteinPercentage } = useMemo(() => {
    const proteinPercentage =
      kind.kind === "Row"
        ? kind.data.proteinPercentage
        : kind.data.patientMealMacrosSummary.proteinPercentage;

    return { proteinPercentage };
  }, [kind.kind, kind.data]);

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      stringValue={
        proteinPercentage !== undefined
          ? `${roundTo1DecimalPlace(proteinPercentage)}%`
          : ``
      }
    />
  );
};
