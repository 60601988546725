import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { LabTestTypeDTO } from "../../models/lab-test-types/lab-test-type-dto";
import { BASE_PATH } from "../../constants/paths";

export function useGetLabTestTypes(): () => Promise<LabTestTypeDTO[]> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<LabTestTypeDTO[]>(
      `${BASE_PATH}/lab-test-types`
    );

    return response.data;
  }, [client]);
}
