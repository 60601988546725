import { useCallback, useMemo } from "react";
import { randomUUID } from "../../../../../../../../../../utils/random-uuid";
import { PatientCGMEntryDTO } from "../../../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientMealDTO } from "../../../../../../../../../../models/patient-meal-dtos/patient-meal-dto";
import { PatientInsulinLogDTO } from "../../../../../../../../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";
import { getInRangeNumbers } from "../../../../../../../../../../utils/patient-type-utils";
import { PatientDTO } from "../../../../../../../../../../models/patient-dtos/patient-dto";
import { buildPrimaryXAxis } from "./utils/build-primary-x-axis";
import { buildPrimaryYAxis } from "./utils/build-primary-y-axis";
import { buildAxisLabelRender } from "./utils/build-axis-label-render";
import { buildReadingsSereis } from "./series/build-readings-series";
import { buildPatientMealsSeries } from "./series/build-patient-meals-series";
import { buildBGMReadingsSereis } from "./series/build-bgm-readings-series";
import { buildInsulinSeries } from "./series/build-insulin-series";
import {
  ChartComponent,
  DataLabel,
  DateTime,
  DateTimeCategory,
  Inject,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineSeries,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { MealsMarker } from "./markers/meals-marker";
import { InsulinMarker } from "./markers/insulin-marker";
import { BgmMarker } from "./markers/bgm-marker";
import { CgmMarker } from "./markers/cgm-marker";
import { Temporal } from "temporal-polyfill";

type MealsAnalysisResponseGraphTableCellGraphProps = {
  startPlainDateTime: Temporal.PlainDateTime;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
  patientMealDTOs: PatientMealDTO[];
  patientInsulinLogDTOs: PatientInsulinLogDTO[];
  patientDTO: PatientDTO;
};

export const MealsAnalysisResponseGraphTableCellGraph = (
  props: MealsAnalysisResponseGraphTableCellGraphProps
) => {
  const {
    startPlainDateTime,
    patientCGMEntryDTOs,
    patientMealDTOs,
    patientInsulinLogDTOs,
    patientDTO,
  } = props;

  const id = useMemo(() => `cgm-syncfusion-chart-${randomUUID()}`, []);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);

    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const minY = useMemo(
    () =>
      Math.min(
        ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
      ),
    [patientCGMEntryDTOs]
  );

  const maxY = useMemo(() => {
    let maxGlucose = Math.max(
      ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
    );
    return maxGlucose > 200 ? maxGlucose + 50 : 250;
  }, [patientCGMEntryDTOs]);

  const inRangeNumbers = useMemo(() => getInRangeNumbers(patientDTO.type), [
    patientDTO,
  ]);

  const vAxisTicks = useMemo(() => [...inRangeNumbers, maxY], [
    inRangeNumbers,
    maxY,
  ]);

  const primaryxAxis = useMemo(() => buildPrimaryXAxis(), []);

  const primaryyAxis = useMemo(
    () => buildPrimaryYAxis(minY, maxY, inRangeNumbers),
    [minY, maxY, inRangeNumbers]
  );

  const axisLabelRender = useMemo(() => buildAxisLabelRender(vAxisTicks), [
    vAxisTicks,
  ]);

  const readingsSeries = useMemo(
    () => buildReadingsSereis(patientCGMEntryDTOs, startPlainDateTime),
    [patientCGMEntryDTOs, startPlainDateTime]
  );

  const bgmReadingsSeries = useMemo(
    () => buildBGMReadingsSereis(patientCGMEntryDTOs, startPlainDateTime),
    [patientCGMEntryDTOs, startPlainDateTime]
  );

  const patientMealsSeries = useMemo(
    () =>
      buildPatientMealsSeries(
        patientCGMEntryDTOs,
        patientMealDTOs,
        startPlainDateTime
      ),
    [patientCGMEntryDTOs, patientMealDTOs, startPlainDateTime]
  );

  const insulinSeries = useMemo(
    () =>
      buildInsulinSeries(
        startPlainDateTime,
        patientCGMEntryDTOs,
        patientInsulinLogDTOs
      ),
    [startPlainDateTime, patientCGMEntryDTOs, patientInsulinLogDTOs]
  );

  return (
    <ChartComponent
      id={id}
      primaryXAxis={primaryxAxis}
      primaryYAxis={primaryyAxis}
      height="130px"
      width="220px"
      loaded={handleChartLoaded}
      axisLabelRender={axisLabelRender}
      border={{ width: 0 }}
    >
      <Inject
        services={[
          DataLabel,
          DateTime,
          DateTimeCategory,
          LineSeries,
          SplineSeries,
          StripLine,
        ]}
      />
      <SeriesCollectionDirective>
        <SeriesDirective
          type="Spline"
          name="CGM Readings"
          dataSource={readingsSeries}
          xName="x"
          yName="y"
          fill="#686868"
          marker={CgmMarker}
          width={2}
          opacity={0.5}
          enableTooltip
          tooltipMappingName="tooltip"
        />
        <SeriesDirective
          type="Line"
          name="BGM Readings"
          dataSource={bgmReadingsSeries}
          xName="x"
          yName="y"
          fill={readingsSeries.length > 0 ? "transparent" : "#686868"}
          width={2}
          opacity={0.5}
          marker={BgmMarker}
          enableTooltip
          tooltipMappingName="tooltip"
        />
        <SeriesDirective
          type="Spline"
          name="Patient Meals"
          dataSource={patientMealsSeries}
          xName="x"
          yName="y"
          fill="transparent"
          marker={MealsMarker}
          enableTooltip
          tooltipMappingName="tooltip"
        />
        <SeriesDirective
          type="Spline"
          name="Insulin Doses"
          dataSource={insulinSeries}
          xName="x"
          yName="y"
          fill="transparent"
          marker={InsulinMarker}
          enableTooltip
          tooltipMappingName="tooltip"
        />
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};
