import { Meal } from "../../build-days-aggregates";
import { Temporal } from "temporal-polyfill";

export function mealToHtmlTooltip(meal: Meal): string {
  const timeHtml = mealTimeToHtml(meal.time, meal.fats, meal.carbs);

  const divStyle = `
    width: 300px;
    background: #fff;
    box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0) !important;
    border: 1px solid rgba(255, 0, 0 ,0) !important;
  `;

  const foodItemsStyle = `
    padding: 1rem 1rem 1.5rem;
  `;

  const titleStyle = `
    font-size: 1.1rem;
    font-weight: 700;
    color: #040415;
    margin: 0rem;
  `;

  const mealNameStyle = `
    font-size: 15px;
    font-weight: 500;
    color: #040415;
  `;

  const mealAmountStyle = `
    color: #040415;
    font-size: 15px;
    font-weight: 700;
    float: right;
  `;

  const foodItemsHtml =
    `<div style='${foodItemsStyle}'>
        <strong style='${titleStyle}'>Meal ingredients</strong>` +
    meal.items
      .map(
        (item) =>
          `
               <br /><span>
                 <br /><span style='${mealNameStyle}'>${item.name}</span>
                 <span style='${mealAmountStyle}'>${item.amount}</span>
               </span>
             `
      )
      .join("") +
    "</div>";

  return `
    <div style='${divStyle}'>
      ${timeHtml}
      ${foodItemsHtml}
    </div>`;
}

function mealTimeToHtml(
  time: Temporal.PlainTime,
  fats: number,
  carbs: number
): string {
  const topContainerStyle = `
    background: #eaf7ff;
    border-bottom: 1px solid #ecedef;
    padding: 1rem;
  `;

  const timeContainerStyle = `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  `;

  const titleStyle = `
    font-size: 1.1rem;
    font-weight: 700;
    color: #040415;
    margin: 0;
  `;

  const timeTextStyle = `
    font-size: 1rem;
    font-weight: 400;
    color: #8c8e97;
  `;

  const macroContainerStyle = `
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;

  const macroPTagStyle = `
    font-size: 14px;
    font-weight: 600;
    color: #040415;
    margin: 0;
  `;

  const macroHtml = `
    <div style="${macroContainerStyle}">
      <p style='${macroPTagStyle}'>Carbs: ${carbs.toFixed(0)} g</p>
      <p style='${macroPTagStyle}'>Fat: ${fats.toFixed(0)} g</p>
    </div>
  `;

  return `
  <div style='${topContainerStyle}'>
    <div style="${timeContainerStyle}">
      <p style='${titleStyle}'>Food log</p>
      <span style='${timeTextStyle}'>${time.toLocaleString("en", {
    hour12: true,
    second: undefined,
  })}</span>
    </div>
    ${macroHtml}
  </div>
  `;
}
