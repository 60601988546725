import { Controller, useFormContext } from "react-hook-form";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileModalLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileEditTimelineModalInputs } from "../../patient-profile-edit-timeline-modal";

export const PatientProfileA1cAtDiabetesDiagnosisFormControl = () => {
  const { control } = useFormContext<PatientProfileEditTimelineModalInputs>();
  return (
    <PatientProfileModalFormControl>
      <PatientProfileModalLabelContainer>
        <label>HbA1c at Diagnosis</label>
      </PatientProfileModalLabelContainer>

      <PatientProfileModalInputContainer
        hasOneInput
        style={{ flex: "0.5", marginRight: "auto" }}
      >
        <Controller
          name="a1cAtDiabetesDiagnosis"
          control={control}
          render={({ field: { value, onChange } }) => (
            <input
              type="text"
              placeholder="HbA1c..."
              value={value !== undefined ? value : undefined}
              onChange={(e) =>
                onChange(
                  e.target.value !== undefined ? e.target.value : undefined
                )
              }
            />
          )}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
