import { Controller, useFormContext } from "react-hook-form";
import { CreateLessonInputs } from "../../create-lesson";
import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../styles/classes/reusable-classes";

export const CreateLessonOrderController = () => {
  const { control } = useFormContext<CreateLessonInputs>();

  return (
    <Controller
      control={control}
      name="order"
      render={({ field: { value, onChange } }) => (
        <UXInputContainer $width="100px">
          <UXInputLabel $width="30px">Order</UXInputLabel>
          <UXInputWrapper $width="50px">
            <UXInputInput
              type="number"
              step={1}
              min={1}
              value={value === undefined ? "" : value}
              onChange={(e) =>
                onChange(e.target.value === "" ? undefined : e.target.value)
              }
            />
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
