import styled, { css } from "styled-components/macro";
import { NavbarHeight, transition } from "../global-style";
import {
  flexCenter,
  mediumFont,
  regularRegularFont,
  smallRegularFont,
} from "./gloabl-classes";
import { LoaderContainer } from "../../components/loaders/otida-loader/styled-otida-loader";

type ButtonType = {
  outlined?: boolean;
  flex?: boolean;
  width?: number;
  height?: number;
  outlinedPrimary?: boolean;
  success?: boolean;
  outlineTitleColor?: boolean;
  outlineNoBorder?: boolean;
  danger?: boolean;
};

export const Button = styled.button<ButtonType>`
  border: ${({
      outlinedPrimary,
      success,
      outlineTitleColor,
      outlineNoBorder,
    }) =>
      outlinedPrimary || success || outlineTitleColor
        ? "1px"
        : outlineNoBorder
        ? "0px"
        : "2px"}
    solid
    ${({ theme, outlined, outlinedPrimary, success, outlineTitleColor }) =>
      success
        ? theme.colors.success
        : outlined
        ? theme.colors.borderColor
        : outlinedPrimary
        ? theme.colors.primaryColor
        : outlineTitleColor
        ? theme.colors.titleColor
        : "transparent"};
  background: ${({
    theme,
    outlined,
    outlinedPrimary,
    success,
    outlineTitleColor,
    outlineNoBorder,
    danger,
  }) =>
    outlined ||
    outlinedPrimary ||
    success ||
    outlineTitleColor ||
    outlineNoBorder
      ? "transparent"
      : danger
      ? theme.newColors.veryDanger
      : theme.colors.primaryColor};
  color: ${({
    theme,
    outlined,
    outlinedPrimary,
    success,
    outlineTitleColor,
    outlineNoBorder,
  }) =>
    success
      ? theme.colors.success
      : outlined
      ? theme.colors.textColor
      : outlinedPrimary
      ? theme.colors.primaryColor
      : outlineTitleColor
      ? theme.colors.titleColor
      : outlineNoBorder
      ? theme.colors.primaryColor
      : theme.colors.buttonColor};
  padding: ${({ flex }) => (flex ? "0.5rem 1rem" : "0.75rem 1.5rem")};
  transition: ${transition};
  border-radius: 0.5rem;
  font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
  font-weight: ${({ theme, outlinedPrimary, success, outlineNoBorder }) =>
    outlinedPrimary || success || outlineNoBorder
      ? theme.typography.fontWeight.bold
      : theme.typography.fontWeight.regular};
  width: ${({ width }) => (width ? `${width}px` : "unset")};
  transition: ${transition};
  height: ${({ height }) => height && `${height}px`};

  display: ${({ flex }) => (flex ? "flex" : "block")};
  align-items: ${({ flex }) => (flex ? "center" : "unset")};
  gap: ${({ flex }) => (flex ? "0rem 1rem" : "unset")};

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  }

  img {
    transition: ${transition};
  }

  :hover {
    border: ${({
        outlinedPrimary,
        success,
        outlineTitleColor,
        outlineNoBorder,
      }) =>
        outlinedPrimary || success || outlineTitleColor
          ? "1px"
          : outlineNoBorder
          ? "0px"
          : "2px"}
      solid
      ${({ theme, success }) =>
        success ? theme.colors.success : "transparent"};
    background: ${({
      theme,
      outlined,
      outlinedPrimary,
      outlineTitleColor,
      outlineNoBorder,
    }) =>
      outlined || outlinedPrimary || outlineTitleColor
        ? theme.colors.primaryColor
        : outlineNoBorder
        ? "transparent"
        : ""};
    color: ${({
      theme,
      outlined,
      outlinedPrimary,
      success,
      outlineTitleColor,
      outlineNoBorder,
    }) =>
      outlined || outlinedPrimary || outlineTitleColor
        ? theme.colors.buttonColor
        : success
        ? theme.colors.success
        : outlineNoBorder
        ? theme.colors.primaryColor
        : theme.colors.buttonColor};

    scale: ${({ outlineNoBorder }) => (outlineNoBorder ? "1.1" : "1")};

    img {
      filter: invert(1) grayscale(100%);
    }
  }

  :disabled {
    opacity: 0.7;

    border: ${({ outlinedPrimary }) => (outlinedPrimary ? "1px" : "2px")} solid
      ${({ theme, outlined, outlinedPrimary }) =>
        outlined || outlinedPrimary ? theme.colors.borderColor : "transparent"};
    background: ${({ theme, outlined, outlinedPrimary, outlineNoBorder }) =>
      outlined || outlinedPrimary || outlineNoBorder
        ? "transparent"
        : theme.colors.primaryColor};
    color: ${({ theme, outlined, outlinedPrimary, outlineNoBorder }) =>
      outlined || outlinedPrimary
        ? theme.colors.textColor
        : outlineNoBorder
        ? theme.colors.primaryColor
        : theme.colors.buttonColor};

    :hover {
      pointer-events: none;
      scale: 1;
    }
  }

  span {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    display: block;
  }
`;

export const InputsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.3rem;
  margin-bottom: 0.6rem;
  gap: 0rem 1rem;
`;

export const InputLabel = styled.label<{ small?: boolean }>`
  font-size: ${({ theme, small }) =>
    small
      ? theme.typography.fontSize.xsFontSize
      : theme.typography.fontSize.smFontSize};
  color: ${({ theme, small }) =>
    small ? theme.colors.textColor : theme.colors.titleColor};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  margin-bottom: 0.6rem;
  margin-left: ${({ small }) => small && "0.4rem"};
`;

export const InputContainer = styled.div<{
  margin?: boolean;
  small?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: ${({ margin }) => (margin ? "1.3rem 0rem 0.6rem" : "unset")};
  width: ${({ small }) => (small ? "50%" : "unset")};
  opacity: ${({ disabled }) => (disabled ? "0.8" : "1")};

  @media screen and (max-width: 425px) {
    width: 100%;
  }

  input {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};
  }

  label {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};
  }
`;

export const Input = styled.input<{ error?: boolean }>`
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.danger : theme.colors.borderColor};
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.inputColor};
  padding: 0.5rem 1rem;
  outline: 0;
  font-size: ${({ theme }) => theme.typography.fontSize.smFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.titleColor};
  width: 100%;
  height: 100%;

  &.small-input {
    height: 50px;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

type ErrorTextType = {
  forMedications?: boolean;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

export const ErrorText = styled.span<ErrorTextType>`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  margin-top: 0.5rem;
  position: absolute;
  bottom: -20px;
  left: ${({ forMedications, left }) =>
    forMedications ? "25%" : left ? `${left}px` : ""};
  top: ${({ top }) => (top !== undefined ? `${top}px` : "")};
  bottom: ${({ bottom }) => (bottom !== undefined ? `${bottom}px` : "")};
  right: ${({ right }) => (right !== undefined ? `${right}px` : "")};
  font-size: ${({ theme }) => theme.typography.fontSize.smFontSize};
`;

export const ModalFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  margin-top: 3rem;
  padding: 2rem 1rem 0rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0 1rem;
  margin-top: auto;
  margin-bottom: 1rem;
`;

export const SubScreenContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  min-height: calc(100vh - (${NavbarHeight} + 200px));
`;

export const PaddingTop = styled.div`
  padding-top: 2rem;
`;

export const TextArea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.inputColor};
  padding: 0.5rem 1rem;
  outline: 0;
  font-size: ${({ theme }) => theme.typography.fontSize.smFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.titleColor};
  width: 100%;
  height: 100%;
`;

export const RadioButton = styled.button<{ checked: boolean }>`
  width: 60px;
  height: 30px;
  border-radius: 1rem;
  background: ${({ theme, checked }) =>
    checked ? theme.colors.success : theme.colors.borderColor};
  position: relative;
  cursor: pointer;
  transition: ${transition};
  margin-left: auto;
  margin-right: 40px;

  span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.containerColor};
    position: absolute;
    top: 5px;
    left: ${({ checked }) => (checked ? "35px" : "5px")};
    transition: ${transition};
  }
`;

/*
 * Designed UX Inputs with there containers [Design by UX Designer Saied El Mohamady]
 * First use of these containers and inputs was in the medications and prescription features
 * Then, I Wrote them here to be Global and reusable
 * I will be using them in other features as well
 * NOTE FOR OMAR: Medications & Prescription styles need to be refactored (remove inputs from medications and use the global ones instead)
 * NOTE FOR OMAR: I don't know whats the best nameing convention to these styled components, so Forgive me if the namming is bad!
 */

export const UXInputContainer = styled.div<{
  $textareaDimentions?: boolean;
  $width?: string;
}>`
  display: flex;
  align-items: ${({ $textareaDimentions }) =>
    $textareaDimentions ? "flex-start" : "center"};
  width: ${({ $width }) => ($width ? $width : "100%")};
  position: relative;

  ${LoaderContainer} {
    width: 100%;
    height: 40px;
    padding: 50px;
  }
`;

export const UXInputLabel = styled.label<{
  $width?: string;
  $isOptional?: true;
}>`
  display: ${({ $isOptional }) => ($isOptional ? "block" : "flex")};
  position: relative;
  ${regularRegularFont}
  margin: 0px 16px;
  width: ${({ $width }) => ($width !== undefined ? $width : "30%")};

  span {
    position: absolute;
    bottom: -15px;
    left: 0;
    ${smallRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const UXInputWrapper = styled.div<{
  $textareaDimentions?: boolean;
  $errorState?: boolean;
  $width?: string;
  $readOnly?: boolean;
}>`
  ${flexCenter}
  background: ${({ theme, $readOnly }) =>
    $readOnly ? "transparent" : theme.colors.inputColor};
  border-radius: 5px;
  padding: ${({ theme }) => theme.padding.pad05All};
  width: ${({ $width }) => ($width ? $width : "70%")};
  height: ${({ $textareaDimentions, $readOnly }) =>
    $textareaDimentions && !$readOnly
      ? "102px"
      : $readOnly
      ? "fit-content"
      : "40px"};
  position: relative;
  border: 1px solid
    ${({ theme, $errorState }) =>
      $errorState ? theme.colors.danger : "transparent"};

  p {
    margin: 0px 0px 0px 8px;
    ${mediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    width: 100px;
    text-align: right;
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    cursor: pointer;
    transition: ${transition};
  }

  h6 {
    ${mediumFont}
    margin: 0px;
  }

  input[type="number"] {
    padding: 0px;
    text-align: center;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`;

export const CSSUXInput = css`
  background: transparent;
  border: 0;
  outline: 0;
  width: 100%;
  height: 100%;
  ${mediumFont}
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const UXInputInput = styled.input`
  ${CSSUXInput}
`;

export const UXInputTextarea = styled.textarea`
  ${CSSUXInput}
  resize: none;
  font-family: "IBM Plex Sans Arabic", sans-serif;
`;
