import { useEffect, useState } from "react";
import { PatientMedicationDTO } from "../../../../models/patient-medication-dto";
import { PatientDTO } from "../../../../models/patient-dtos/patient-dto";
import { useGetPatientMedications } from "../../../../hooks/use-get-patient-medications";

type UseGetPatientMedicationDTOs = {
  patientMedicationDTOs: PatientMedicationDTO[];
};

export function useGetPatientMedicationDTOs(
  patientDTO: PatientDTO
): UseGetPatientMedicationDTOs {
  const [patientMedicationDTOs, setPatientMedicationDTOs] = useState<
    PatientMedicationDTO[]
  >([]);

  const getPatientMedicationss = useGetPatientMedications();

  useEffect(() => {
    const fetch = async () => {
      getPatientMedicationss(patientDTO.id!).then((response) =>
        setPatientMedicationDTOs(response)
      );
    };

    fetch();
  }, [patientDTO.id, getPatientMedicationss]);

  return { patientMedicationDTOs };
}
