import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  regularMediumFont,
} from "../../../styles/classes/gloabl-classes";

export const TableHeaderContainer = styled.div`
  ${flexCenterSpaceBetween}
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: ${({ theme }) => theme.padding.pad05_1};
  background: ${({ theme }) => theme.colors.containerColor};
`;

export const TableTitle = styled.p<{ $boldTitle?: boolean }>`
  ${({ $boldTitle }) =>
    $boldTitle
      ? `
    font-size: 21px;
    font-weight: 500;
  `
      : regularMediumFont}
  color: ${({ theme }) => theme.colors.buttonColorAlt};
`;
