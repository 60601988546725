import styled from "styled-components/macro";

export const FoodLoggingHeaderDatePickerContainer = styled.div`
  background: ${({ theme }) => theme.colors.inputColor};
  width: 300px;
  border-radius: 8px;
  padding: 8px 16px;

  input {
    border: 0;
    outline: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }
`;
