import { useEffect, useState } from "react";
import { FrequencyDTO } from "../../../../models/frequencies/frequency-dto";
import { useGetFrequencies } from "../../../../hooks/frequencies/use-get-frequencies";

type UseGetFrequencyDTOs = { frequencyDTOs: FrequencyDTO[] };

export function useGetFrequencyDTOs(): UseGetFrequencyDTOs {
  const [frequencyDTOs, setFrequencyDTOs] = useState<FrequencyDTO[]>([]);

  const getFrequency = useGetFrequencies();

  useEffect(() => {
    const fetch = async () => {
      getFrequency().then((response) => setFrequencyDTOs(response));
    };

    fetch();
  }, [getFrequency]);

  return { frequencyDTOs };
}
