import React from "react";
import { Temporal } from "temporal-polyfill";
import { useDetailedPlan } from "../../context/loadable-detailed-plan-context";
import { CPlansTitle } from "./styled-create-plans-header";

export const PlansHeaderTitle = () => {
  const { patientDetailedPlanDTO } = useDetailedPlan();

  const planNumber = patientDetailedPlanDTO.patientPlan.planNumber;
  const date = Temporal.Instant.from(
    patientDetailedPlanDTO.patientPlan.createdAt
  )
    .toZonedDateTimeISO("Africa/Cairo")
    .toString()
    .split("T")[0];

  return (
    <CPlansTitle>
      <h1>Create week ({planNumber}) plan</h1>
      <div>
        <img src="/img/plans/time.svg" alt="Clock Icon" />
        <p>{`${date}`}</p>
      </div>
    </CPlansTitle>
  );
};
