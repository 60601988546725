import { Controller, useFormContext } from "react-hook-form";
import { PatientProfileDietModalInputs } from "../../patient-profile-edit-diet-modal";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileModalLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";

export const PatientProfileEditDietOtherSugarsFormControl = () => {
  const { control, setValue } = useFormContext<PatientProfileDietModalInputs>();

  return (
    <PatientProfileModalFormControl>
      <PatientProfileModalLabelContainer>
        <label>Sugar (Others)</label>
      </PatientProfileModalLabelContainer>
      <PatientProfileModalInputContainer>
        <Controller
          name={`patientAddedSugarsOther.notes`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <input
              type="text"
              placeholder="Notes (Other Only)"
              value={value !== undefined ? value : ""}
              onChange={(e) => {
                onChange(e.target.value !== undefined ? e.target.value : "");
                setValue(`patientAddedSugarsOther.addedSugarId`, 4);
                setValue(`patientAddedSugarsOther.avgQuantityPerBeverage`, 0);
                setValue(`patientAddedSugarsOther.avgQuantityPerDay`, 0);
              }}
            />
          )}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
