import { useCallback } from "react";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { PatientTimelineDTO } from "../../models/patient-timeline-dtos/patient-timeline-dto";

export function useGetPatientTimeline(): (
  patientId: number
) => Promise<PatientTimelineDTO> {
  const client = useClient();

  return useCallback(
    async (patientId) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientTimelineDTO>(
        `${PATIENTS_PATH}/${patientId}/timeline`
      );

      return response.data;
    },
    [client]
  );
}
