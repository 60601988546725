import { CGMReportResponse } from "./cgm-report-response/cgm-report-response";
import { CGMReportScreenHeader } from "./cgm-report-screen-header/cgm-report-screen-header";
import { CGMReportProviders } from "./context/cgm-report-providers";

export const CGMReport = () => {
  return (
    <CGMReportProviders>
      <CGMReportScreenHeader />
      <CGMReportResponse />
    </CGMReportProviders>
  );
};
