import { LabTestTypeRefDTO } from "../../../../../../models/lab-test-type-refs/lab-test-type-ref-dto";
import { LabTestTypeDTO } from "../../../../../../models/lab-test-types/lab-test-type-dto";
import { PatientLabTestResultDTO } from "../../../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import { LabResultsCard } from "../../../../lab-results/lab-results-card/lab-results-card";
import { useGetGroupedLabTestResultsByTypeAndDate } from "../utils/feedback-utils";

type ListLabResultCardsProps = {
  labTestTypeId: number;
  startDate: string;
  endDate: string;
  labTestTypeRefDTOs: LabTestTypeRefDTO[];
  viewPlanCard?: boolean;
  patientLabTestResultsDTOs: PatientLabTestResultDTO[];
  labTestTypeDTOs: LabTestTypeDTO[];
};

export const ListLabResultCards = (props: ListLabResultCardsProps) => {
  const {
    labTestTypeId,
    startDate,
    endDate,
    viewPlanCard,
    labTestTypeRefDTOs,
    labTestTypeDTOs,
    patientLabTestResultsDTOs,
  } = props;

  const groupedLabTestResultsByType = useGetGroupedLabTestResultsByTypeAndDate(
    labTestTypeId,
    startDate,
    endDate,
    patientLabTestResultsDTOs,
    labTestTypeDTOs
  );

  return (
    <>
      {groupedLabTestResultsByType.map((item) => (
        <LabResultsCard
          key={item?.labTestTypeDTO.id}
          labTestTypeRefDTOs={labTestTypeRefDTOs}
          labTestTypeDTO={item.labTestTypeDTO}
          labTest={item.sortedLabTestResultsByDate}
          modalCard={{
            show: viewPlanCard ? false : true,
            button: () => <></>,
          }}
        />
      ))}
    </>
  );
};
