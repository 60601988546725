import { useCallback } from "react";
import { Button } from "../../../../../styles/classes/reusable-classes";
import { useConentModuleMobilePreviewCarousel } from "../context/conent-module-mobile-preview-carousel-context";
import {
  ContentModuleLessonMobilePreviewFooterBlackLine,
  ContentModuleLessonMobilePreviewFooterContainer,
  ContentModuleLessonMobilePreviewFooterDot,
  ContentModuleLessonMobilePreviewFooterDots,
} from "./styled-content-module-lesson-mobile-preview-footer";

type ContentModuleLessonMobilePreviewFooterProps = {
  lessonCardsLength: number;
};

export const ContentModuleLessonMobilePreviewFooter = (
  props: ContentModuleLessonMobilePreviewFooterProps
) => {
  const { lessonCardsLength } = props;

  const { currentCard, goToNextCard } = useConentModuleMobilePreviewCarousel();

  const handleNextClick = useCallback(() => {
    const index = (currentCard + 1) % lessonCardsLength;

    if (lessonCardsLength > 0) {
      goToNextCard(index);
    }
  }, [currentCard, goToNextCard, lessonCardsLength]);

  const dots = new Array(lessonCardsLength).fill(0).map((_, index) => index);

  return (
    <ContentModuleLessonMobilePreviewFooterContainer>
      <ContentModuleLessonMobilePreviewFooterDots>
        {dots.map((dot) => (
          <ContentModuleLessonMobilePreviewFooterDot
            key={dot}
            $activeDot={dot === currentCard}
          />
        ))}
      </ContentModuleLessonMobilePreviewFooterDots>
      <Button type="button" onClick={handleNextClick}>
        Next
      </Button>
      <ContentModuleLessonMobilePreviewFooterBlackLine />
    </ContentModuleLessonMobilePreviewFooterContainer>
  );
};
