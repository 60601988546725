import { Button } from "../../../../../styles/classes/reusable-classes";
import {
  getEducationContent,
  usePlansGoals,
} from "../../context/goals-context";
import { ContainerTitle } from "../goals/styled-goals";
import {
  EducationListItem,
  EducationListItemLeftContainer,
  EducationListItemTextContainer,
} from "./styled-education";
import { VideoModal } from "./video-modal/video-modal";

export const AssignedEducationList = (props: { searchTerm: string }) => {
  const { searchTerm } = props;

  const { assignedEducationGoalsList, handleDeleteGoal } = usePlansGoals();

  return (
    <ul>
      <ContainerTitle>Assigned Education Goals</ContainerTitle>
      {assignedEducationGoalsList
        .filter((goal) => {
          const goalObject = getEducationContent(goal.goal).lesson;

          if (searchTerm === "") {
            return goal;
          } else if (
            goalObject.title.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return goalObject;
          } else {
            return 0;
          }
        })
        .map((goal, index) => {
          const goalObject = getEducationContent(goal.goal).lesson;

          return (
            <EducationListItem key={index}>
              <EducationListItemLeftContainer>
                <EducationListItemTextContainer>
                  <p>{goalObject.title}</p>
                  {goalObject.type === "Video" && (
                    <VideoModal videoURL={goalObject.link} />
                  )}
                  {(goalObject.type === "PDF" ||
                    goalObject.type === "Image") && (
                    <a href={goalObject.link} target="_blank" rel="noreferrer">
                      <span className="material-symbols-outlined">
                        description
                      </span>
                      {goalObject.type === "Image" && "Article"}
                      {goalObject.type === "PDF" && "PDF"}
                    </a>
                  )}
                </EducationListItemTextContainer>
              </EducationListItemLeftContainer>
              <Button
                success
                flex
                type="button"
                onClick={() =>
                  handleDeleteGoal({
                    goal: goal.goal,
                    goalType: "EducationContent",
                  })
                }
              >
                <span className="material-symbols-outlined">done</span>
                Added
              </Button>
            </EducationListItem>
          );
        })}
    </ul>
  );
};
