import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  regularMediumFont,
} from "../../../../../../../../styles/classes/gloabl-classes";

export const ImmediateAttentionPatientsFiltersSidebarHeaderContainer = styled.header`
  ${flexCenterSpaceBetween}
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
`;

export const ImmediateAttentionPatientsFiltersSidebarHeaderHeaderText = styled.div`
  p {
    margin: 0px;
    ${regularMediumFont}
  }
`;

export const ImmediateAttentionPatientsFiltersSidebarHeaderHeaderIcons = styled.div`
  ${flexCenter}
  gap: 0px 12px;

  span {
    cursor: pointer;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
