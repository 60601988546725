import styled from "styled-components/macro";
import { smallRegularFont } from "../../../../../../styles/classes/gloabl-classes";
import { LabResultsHeaderContainerCSS } from "../../../components/styles";

export const LabResultTestTypesContainer = styled.div`
  min-width: 300px;
  width: fit-content;
`;

export const LabResultTestTypesHeader = styled.div`
  ${LabResultsHeaderContainerCSS}
  border-top-left-radius: 14px;
`;

export const LabResultTestTypesList = styled.ul`
  margin: 0px;
`;

export const LabResultTestTypesListItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 12px 0px 12px 24px;
  height: 77px;

  p {
    font-size: 18px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
    margin: 0px;
    color: ${({ theme }) => theme.colors.titleColor};
  }

  span {
    ${smallRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
