import { useCallback } from "react";
import { useToastService } from "../../../../../context/toast-service-context";
import { useAddTargetAudience } from "../../../../../hooks/target-audience-hooks/use-add-target-audience";
import { TargetAudienceDTO } from "../../../../../models/target-audience-dtos/target-audience-dto";
import { TargetAudienceAutoSelectListItem } from "./styled-target-audience-autoselect-list";
import { useTargetAudienceSearchTerm } from "../context/target-audience-search-term-context";

type CreateNewTargetAudienceListItemProps = {
  handleAppend: (targetAudienceDTO: TargetAudienceDTO) => void;
};

export const CreateNewTargetAudienceListItem = (
  props: CreateNewTargetAudienceListItemProps
) => {
  const { handleAppend } = props;

  const { searchTerm } = useTargetAudienceSearchTerm();

  const addTargetAudience = useAddTargetAudience();
  const { showToast } = useToastService();

  const onTargetAudienceCreation = useCallback(async () => {
    try {
      if (searchTerm !== "") {
        await addTargetAudience({ name: searchTerm }).then((response) =>
          handleAppend(response)
        );
        showToast("Success", "Audience Created Successfully!");
      }
    } catch (error) {
      showToast("Error", "Failed to Create Audience :(");
    }
  }, [showToast, addTargetAudience, searchTerm, handleAppend]);

  return (
    <TargetAudienceAutoSelectListItem
      className="create-new-list-item"
      onClick={onTargetAudienceCreation}
    >
      <span className="material-symbols-outlined">add</span>
      {searchTerm !== ""
        ? `Create Tag For "${searchTerm}"`
        : "Type to create new audience"}
    </TargetAudienceAutoSelectListItem>
  );
};
