import { PatientMealDTO } from "../../../../../../../models/patient-meal-dtos/patient-meal-dto";
import {
  NutritionSummaryCard,
  NutritionSummaryCardHeader,
} from "../nutrition-summary/styled-nutrition-summary";
import { NutritionSummaryFromMealsCenter } from "./nutrition-summary-from-meals-center";
import { NutritionSummaryFromMealsFooter } from "./nutrition-summary-from-meals-footer";

type NutritionSummaryFromMealsProps = {
  patientMealDTOs: PatientMealDTO[];
};

export const NutritionSummaryFromMeals = (
  props: NutritionSummaryFromMealsProps
) => {
  const { patientMealDTOs } = props;

  return (
    <NutritionSummaryCard>
      <NutritionSummaryCardHeader>
        <h3>Nutrition summary</h3>
        <p>Food groups</p>
      </NutritionSummaryCardHeader>
      <NutritionSummaryFromMealsCenter patientMealDTOs={patientMealDTOs} />
      <NutritionSummaryFromMealsFooter patientMealDTOs={patientMealDTOs} />
    </NutritionSummaryCard>
  );
};
