import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  flexCenterSpaceBetween,
  mediumFont,
  regularMediumFont,
} from "../../../styles/classes/gloabl-classes";

export const ListPlansContainer = styled.div``;

export const ListPlansHeader = styled.div`
  padding: 24px;
  ${flexCenterSpaceBetween}
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

  p {
    ${regularMediumFont}
    margin: 0px;
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;

export const ListPlansListItem = styled.li`
  padding: 24px 0px 16px;
  border-bottom: 4px solid ${({ theme }) => theme.colors.borderColor};
`;

export const ListPlansLIHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenter}
  gap: 0rem 1rem;
  padding: 0px 24px 24px;

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    gap: 0px 16px;
    margin: 0px;
  }
`;

export const ListPlansLIHeaderIcon = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenterCenter}
  border-radius: 0.5rem;

  img {
    width: 50%;
    height: 50%;
  }
`;

export const ListPlansLIContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ListPlansLIContainer = styled.div`
  ${flexCenterSpaceBetween}
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 0.75rem 1rem;
`;

export const ListPlansLISubContainer = styled.div`
  ${flexCenter}
  gap: 0rem 1rem;
`;

export const DraftNotification = styled.div`
  width: 65px;
  height: 35px;
  border-radius: 94px;
  background: ${({ theme }) => theme.newColors.draftBackground};
  ${flexCenterCenter}
  cursor: pointer;

  ::after {
    content: "Draft";
    color: ${({ theme }) => theme.colors.danger};
    ${mediumFont}
  }
`;

export const ListPlansLIContainerIcon = styled.div`
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.newColors.otidaO2};
  ${flexCenterCenter}
  border-radius: 8px;
`;

export const ListPlansLIContainerText = styled.div`
  a {
    text-decoration: none;
  }

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }

  span {
    margin-top: 4px;
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
