import {
  PaginationContainer,
  PaginationInfo,
  PaginationButton,
  PaginationButtonsList,
} from "./styled-pagination";
import { Dropdown } from "../dropdown/dropdown";
import { DropdownListItem } from "../dropdown/styled-dropdown";
import { useNavigate, useParams } from "react-router-dom";
import { ReactNode } from "react";

type PaginationProps = {
  totalRowsCount: number;
  tableRowsPerPage: number;
  setRowsPerPage: (tableRowsPerPage: number) => void;
  navigateBasePath: string;
  pageVariable?: string;
  allPatinets?: boolean;
  jsxComponent?: ReactNode;
};

export const Pagination = (props: PaginationProps) => {
  const {
    totalRowsCount,
    tableRowsPerPage,
    setRowsPerPage,
    navigateBasePath,
    pageVariable,
    allPatinets,
    jsxComponent,
  } = props;

  const { page: routePage } = useParams();
  const page = pageVariable !== undefined ? pageVariable : routePage;

  const navigate = useNavigate();

  function generateRange(start: number, end: number) {
    const range: Array<number> = [];

    for (let index = start; index < end; index++) {
      range.push(index);
    }

    return range;
  }

  const tableRowsNumbers = [8, 16, 32];
  const visibleCount = window.innerWidth <= 410 ? 3 : 5;

  const numberOfPages = allPatinets
    ? [1, 2, 3, 4, 5]
    : generateRange(1, Math.ceil(totalRowsCount / tableRowsPerPage) + 1);

  const getSliceStart = () => {
    if (generateRange(0, Math.ceil(visibleCount / 2)).includes(Number(page))) {
      return numberOfPages[0] - 1;
    } else if (
      generateRange(
        numberOfPages.length - Math.floor(visibleCount / 2),
        numberOfPages.length + 1
      ).includes(Number(page))
    ) {
      return numberOfPages[numberOfPages.length - 1 - visibleCount];
    } else {
      return Number(page) - Math.ceil(visibleCount / 2);
    }
  };

  const getSliceEnd = () => {
    if (
      generateRange(
        numberOfPages.length - Math.floor(visibleCount / 2),
        numberOfPages.length + 1
      ).includes(Number(page))
    ) {
      return numberOfPages[numberOfPages.length - 1];
    } else if (
      generateRange(0, Math.ceil(visibleCount / 2)).includes(Number(page))
    ) {
      return visibleCount;
    } else {
      return Number(page) + Math.floor(visibleCount / 2);
    }
  };

  return (
    <PaginationContainer>
      <PaginationInfo>
        <p>Showing</p>
        <Dropdown text={tableRowsPerPage.toString()} variant="pagination">
          {tableRowsNumbers.map((number, index) => (
            <DropdownListItem
              key={index}
              active={tableRowsPerPage === number}
              onClick={() => {
                navigate(`..${navigateBasePath}/1`);
                setRowsPerPage(number);
              }}
            >
              {number}
            </DropdownListItem>
          ))}
        </Dropdown>
        <p>out of {totalRowsCount}</p>
      </PaginationInfo>
      {jsxComponent ? jsxComponent : <></>}
      <PaginationButtonsList>
        <li>
          <PaginationButton
            disabled={Number(page) <= 1 ? true : false}
            onClick={() =>
              navigate(
                `..${navigateBasePath}/${
                  Number(page) > 1 ? Number(page) - 1 : Number(page)
                }`
              )
            }
          >
            <span className="material-symbols-outlined">chevron_left</span>
          </PaginationButton>
        </li>
        {numberOfPages.length > 5 && Number(page) > visibleCount - 2 && (
          <li>
            <PaginationButton
              onClick={() =>
                navigate(
                  `..${navigateBasePath}/${
                    Number(page) - visibleCount > 0
                      ? Number(page) - visibleCount
                      : 1
                  }`
                )
              }
            >
              ...
            </PaginationButton>
          </li>
        )}
        {numberOfPages
          .slice(getSliceStart(), getSliceEnd())
          .map((number: number, i) => (
            <li key={i}>
              <PaginationButton
                activePage={Number(page) === number}
                onClick={() => navigate(`..${navigateBasePath}/${number}`)}
              >
                {number}
              </PaginationButton>
            </li>
          ))}
        {numberOfPages.length > 5 &&
          Number(page) <
            numberOfPages[numberOfPages.length - 1] -
              Math.floor(visibleCount / 2) && (
            <li>
              <PaginationButton
                onClick={() =>
                  navigate(
                    `..${navigateBasePath}/${
                      Number(page) + visibleCount <= numberOfPages.length
                        ? numberOfPages[Number(page) + visibleCount - 1]
                        : numberOfPages[numberOfPages.length - 1]
                    }`
                  )
                }
              >
                ...
              </PaginationButton>
            </li>
          )}
        <li>
          <PaginationButton
            onClick={() =>
              navigate(
                `..${navigateBasePath}/${
                  Number(page) < numberOfPages.length
                    ? Number(page) + 1
                    : Number(page)
                }`
              )
            }
            disabled={Number(page) === numberOfPages.length ? true : false}
          >
            <span className="material-symbols-outlined">navigate_next</span>
          </PaginationButton>
        </li>
      </PaginationButtonsList>
    </PaginationContainer>
  );
};
