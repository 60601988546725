import { NutritionRegimenDTO } from "../../../../../models/nutrition-regimen-dtos/nutrition-regimen-dto";
import { roundTo1DecimalPlace } from "../../../../../utils/math-utils";

const proteinKCalsPerGram = 4;
const fatKCalsPerGram = 9;

export const calculateNutritionGoalsMacros = (
  lastFoodGroupSelected?: NutritionRegimenDTO
) => {
  let totalKCals = 0;
  let proteinKCals = 0;
  let fatKCals = 0;
  let proteinKCalsGrams = 0;
  let fatKCalsGrams = 0;
  let carbsKCalsGrams = 0;

  if (lastFoodGroupSelected !== undefined) {
    for (const nutritionRegimenFoodGroup of lastFoodGroupSelected?.nutritionRegimenFoodGroups) {
      const foodGroupKcals =
        nutritionRegimenFoodGroup.foodGroup.kcals !== undefined
          ? nutritionRegimenFoodGroup.foodGroup.kcals
          : 0;

      const foodGroupFats =
        nutritionRegimenFoodGroup.foodGroup.fat !== undefined
          ? nutritionRegimenFoodGroup.foodGroup.fat
          : 0;

      const foodGroupProtein =
        nutritionRegimenFoodGroup.foodGroup.protein !== undefined
          ? nutritionRegimenFoodGroup.foodGroup.protein
          : 0;

      proteinKCalsGrams +=
        nutritionRegimenFoodGroup.foodGroup.protein !== undefined
          ? nutritionRegimenFoodGroup.foodGroup.protein
          : 0;

      fatKCalsGrams +=
        nutritionRegimenFoodGroup.foodGroup.fat !== undefined
          ? nutritionRegimenFoodGroup.foodGroup.fat
          : 0;

      carbsKCalsGrams +=
        nutritionRegimenFoodGroup.foodGroup.fat !== undefined
          ? nutritionRegimenFoodGroup.foodGroup.fat
          : 0;

      totalKCals +=
        nutritionRegimenFoodGroup.dailyServingsCount * foodGroupKcals;
      proteinKCals +=
        nutritionRegimenFoodGroup.dailyServingsCount *
        foodGroupProtein *
        proteinKCalsPerGram;

      fatKCals +=
        nutritionRegimenFoodGroup.dailyServingsCount *
        foodGroupFats *
        fatKCalsPerGram;
    }
  }

  const carbsKCals = totalKCals - fatKCals - proteinKCals;

  if (totalKCals === 0) {
    return {
      kCals: 0,
      carbsPercentage: 0,
      proteinPercentage: 0,
      fatPercentage: 0,
      proteinKCalsGrams: 0,
      fatKCalsGrams: 0,
      carbsKCalsGrams: 0,
    };
  }

  return {
    kCals: totalKCals,
    carbsPercentage: roundTo1DecimalPlace((carbsKCals / totalKCals) * 100),
    proteinPercentage: roundTo1DecimalPlace((proteinKCals / totalKCals) * 100),
    fatPercentage: roundTo1DecimalPlace((fatKCals / totalKCals) * 100),
    proteinKCalsGrams,
    fatKCalsGrams,
    carbsKCalsGrams,
  };
};
