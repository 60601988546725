import styled from "styled-components/macro";
import {
  flexCenter,
  mediumRegularFont,
} from "../../../../../styles/classes/gloabl-classes";

export const FoodLoggingSelectedPatientMealFoodGroupsContainer = styled.div`
  margin-top: 16px;
`;

export const FoodLoggingSelectedPatientMealFoodGroupsInputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  justify-items: flex-end;
  gap: 16px 0px;
`;

export const FoodLoggingSelectedPatientMealFoodGroupsInputContainer = styled.div`
  ${flexCenter}
  gap: 0px 8px;
  position: relative;

  input {
    width: 50px;
    border: 0;
    outline: 0;
    height: 40px;
    padding: 10px;
    background: ${({ theme }) => theme.colors.inputColor};
    border-radius: 5px;
    text-align: center;
    ${mediumRegularFont}

    ::-webkit-outer-spin-button {
      display: none;
    }

    ::-webkit-inner-spin-button {
      display: none;
    }
  }

  span {
    width: 50px;
    height: 40px;
    padding: 10px;
    background: ${({ theme }) => theme.colors.inputColor};
    border-radius: 5px;
    text-align: center;
    ${mediumRegularFont}
  }
`;

export const FoodLoggingSelectedPatientMealFoodGroupsInputLabel = styled.label`
  ${mediumRegularFont}
  color: ${({ theme }) => theme.colors.titleColor};
  width: fit-content;
  text-align: right;
`;
