import styled from "styled-components/macro";
import { lightShadowSecondary } from "../../../../../styles/global-style";

export const ManualReadingsChartContainer = styled.div`
  padding: 16px 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  ${lightShadowSecondary}
  margin: 8px 16px;
  display: grid;
  place-items: center;
`;
