import { Link, useParams } from "react-router-dom";
import { useContent } from "../../context/loadable-content-context";
import { useMemo } from "react";
import { Button } from "../../../../styles/classes/reusable-classes";
import { useImageUploadHelpers } from "../image-upload/context/image-upload-helpers-context";
import { Loader } from "../../../../components/loaders/otida-loader/styled-otida-loader";
import { CardPartsLoadingState } from "../../content-course-module-lessons/context/card-parts-loading-context";
import {
  ContentModulesFormHeaderContainer,
  ContentModulesFormHeaderButtonContainer,
  ContentModulesFormHeaderTitle,
} from "../../styles/content-modules-form-controllers/content-modules-form-controllers-css";

type UpdateContentModuleHeaderProps = {
  lessonCardPartsLoading?: CardPartsLoadingState;
};

export const UpdateContentModuleHeader = (
  props: UpdateContentModuleHeaderProps
) => {
  const { lessonCardPartsLoading } = props;

  const { contentModuleDTOs, contentModuleLessonsDTOs } = useContent();

  const { courseID, moduleID, lessonID } = useParams();
  const courseId = parseInt(courseID!);
  const moduleId = parseInt(moduleID!);
  const lessonId = parseInt(lessonID!);

  const course = useMemo(
    () => contentModuleDTOs.find((course) => course.id === courseId),
    [contentModuleDTOs, courseId]
  );

  const contentModule = useMemo(
    () =>
      course?.childContentModules?.find(
        (moduleDTO) => moduleDTO.id === moduleId
      ),
    [course, moduleId]
  );

  const lesson = useMemo(
    () =>
      contentModuleLessonsDTOs.find((lessonDTO) => lessonDTO.id === lessonId),
    [contentModuleLessonsDTOs, lessonId]
  );

  const { loading } = useImageUploadHelpers();

  return (
    <ContentModulesFormHeaderContainer>
      <ContentModulesFormHeaderTitle>
        <p dir="auto">
          <span>Course</span>
          <Link dir="auto" to={`/content/courses/course/${courseId}`}>
            {course?.name}
          </Link>
        </p>
        {contentModule !== undefined && (
          <>
            <span className="material-symbols-outlined">chevron_right</span>
            <p dir="auto">
              <span>
                Module{" "}
                {contentModule.uiOrder !== undefined
                  ? contentModule.uiOrder
                  : ""}
              </span>
              <Link
                dir="auto"
                to={`/content/courses/course/${courseId}/module/${moduleId}`}
              >
                {contentModule.name}
              </Link>
            </p>
          </>
        )}
        {lesson !== undefined && (
          <>
            <span className="material-symbols-outlined">chevron_right</span>
            <p dir="auto">
              <span>
                Lesson {lesson.uiOrder !== undefined ? lesson.uiOrder : ""}
              </span>
              <Link
                dir="auto"
                to={`/content/courses/course/${courseId}/module/${moduleId}/lesson/${lessonId}`}
              >
                {lesson.name}
              </Link>
            </p>
          </>
        )}
      </ContentModulesFormHeaderTitle>
      <ContentModulesFormHeaderButtonContainer>
        <Button
          type="submit"
          disabled={loading || lessonCardPartsLoading?.loading}
        >
          {loading || lessonCardPartsLoading?.loading ? (
            <Loader radius={20} />
          ) : (
            `Save`
          )}
        </Button>
      </ContentModulesFormHeaderButtonContainer>
    </ContentModulesFormHeaderContainer>
  );
};
