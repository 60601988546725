import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";

type NutritionReportNutsFrequencyTableCellsProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportNutsFrequencyTableCells = (
  props: NutritionReportNutsFrequencyTableCellsProps
) => {
  const { kind } = props;

  const nutsFrequencyTableCells =
    kind.kind === "Row"
      ? kind.data.frequencyFoodGroups.map((cell) => (
          <NutritionReportTableCell
            cellKind="Row"
            key={cell.foodGroup.id}
            type="Normal"
            value={cell.totalCount}
          />
        ))
      : kind.data.frequencyFoodGroups.map((cell, index) => (
          <NutritionReportTableCell
            key={index}
            cellKind="Meal"
            type="Normal-bodyColor"
            stringValue={cell}
          />
        ));

  return <>{nutsFrequencyTableCells}</>;
};
