import { css } from "styled-components/macro";
import {
  Button,
  RadioButton,
  UXInputContainer,
} from "../../../../styles/classes/reusable-classes";
import { NavbarHeight } from "../../../../styles/global-style";
import {
  flexCenter,
  mediumFont,
} from "../../../../styles/classes/gloabl-classes";
import { ContentModuleLessonMobilePreviewWidth } from "../../components/content-module-lesson-mobile-preview/styled-content-module-lesson-mobile-preview";
import styled from "styled-components";

export const ContentModulesFormHeaderContainer = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenter}
  gap: 0px 24px;

  @media screen and (max-width: 800px) {
    padding: 16px;

    ${Button} {
      padding: 8px 16px;
    }
  }
`;

export const ContentModulesFormHeaderTitle = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0px 8px;

  p {
    margin: 0px;
    display: grid;
  }

  a {
    margin: 0;
    ${mediumFont}
    color: ${({ theme }) => theme.colors.primaryColor};
  }

  @media screen and (max-width: 800px) {
    ${mediumFont}
  }
`;

export const ContentModulesFormHeaderButtonContainer = styled.div`
  margin-left: auto;
  ${flexCenter}
  gap: 0px 16px;
`;

export const ContentModuleFormControls = styled.div<{ $lessonsUI: boolean }>`
  display: flex;
  padding: 12px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 12px 0px;
  }
`;

const ContentModuleLeftFormControlsCSS = css`
  display: grid;
`;

const ContentModuleLeftFormControlsLessonUICSS = css`
  display: flex;
  flex-direction: column;
  height: calc(100vh - (${NavbarHeight} + 85px + 32px));
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    width: calc(100% - (${ContentModuleLessonMobilePreviewWidth} + 12px));
  }
`;

export const ContentModuleLeftFormControls = styled.div<{
  $lessonsUI: boolean;
}>`
  flex: 0.6;
  gap: 12px 0px;

  ${({ $lessonsUI }) =>
    $lessonsUI
      ? ContentModuleLeftFormControlsLessonUICSS
      : ContentModuleLeftFormControlsCSS};

  .content-module-name-input-wrapper {
    background: transparent;
    padding: 0px;

    input {
      background: ${({ theme }) => theme.newColors.inputColorAlt};
      padding: 8px;
      border-radius: 5px;
    }

    input:read-only {
      background: transparent;
    }
  }

  @media screen and (max-width: 1200px) {
    .content-module-name-input-wrapper {
      flex-direction: column-reverse;
      gap: 12px 0px;
      height: auto;
    }

    ${UXInputContainer}:has(input[type='text']) {
      align-items: flex-end;
    }
  }
`;

const ContentModuleRightFormControlsUICSS = css`
  display: grid;
  align-content: flex-start;
  gap: 12px 0px;
`;

const ContentModuleRightFormControlsLessonsUICSS = css`
  display: flex;
  flex-direction: column;
  height: calc(100vh - (${NavbarHeight} + 85px + 32px));
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    width: calc(100% - (${ContentModuleLessonMobilePreviewWidth} + 12px));
  }
`;

export const ContentModuleRightFormControls = styled.div<{
  $lessonsUI: boolean;
}>`
  flex: 0.4;
  gap: 12px 0px;

  ${({ $lessonsUI }) =>
    $lessonsUI
      ? ContentModuleRightFormControlsLessonsUICSS
      : ContentModuleRightFormControlsUICSS};
`;

export const ContentModuleStatusAndOrderControls = styled.div`
  display: flex;
  align-items: center;

  ${UXInputContainer}:has(input[type='number']) {
    label {
      margin: 0px 18px 0px auto;
    }
  }

  ${UXInputContainer}:has(${RadioButton}) {
    label {
      margin: 0px auto;
    }
  }

  ${RadioButton} {
    margin-left: 0px;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    justify-content: space-between;
  }

  @media screen and (max-width: 1024px) {
    ${UXInputContainer}:has(${RadioButton}) {
      label {
        margin: 0px;
      }
    }
  }
`;
