import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  flexCol,
  mediumRegularFont,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";
import {
  lightShadowDropdownList,
  transition,
} from "../../../../../styles/global-style";
import { LabResultsLabel } from "../styles";

export const LabResultsDropdownContainer = styled.div`
  ${flexCol}
  gap: 8px 0px;
`;

export const LabResultsDropdownWrapper = styled.div<{
  disabledValue: boolean;
}>`
  ${flexCenter}
  background: transparent;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: ${({ theme }) => theme.padding.py_05};
  width: 300px;
  height: 40px;
  position: relative;
  opacity: ${({ disabledValue }) => (disabledValue ? "0.5" : "1")};
  pointer-events: ${({ disabledValue }) => (disabledValue ? "none" : "auto")};
`;

export const LabResultsDropdownLabel = styled.label`
  ${LabResultsLabel}
`;

export const LabResultsDropdownButton = styled.button<{
  showDropdown: boolean;
}>`
  ${flexCenterSpaceBetween}
  background: transparent;
  color: ${({ theme }) => theme.colors.titleColor};
  padding: 8px 16px;
  width: 100%;
  border-radius: 8px;
  ${regularMediumFont}

  span {
    color: ${({ theme }) => theme.colors.textColor};
    cursor: pointer;
    transform: ${({ showDropdown }) =>
      showDropdown ? "rotate(-180deg)" : "rotate(0deg)"};
    transition: ${transition};
  }
`;

export const LabResultsDropdownList = styled.ul<{
  showDropdown: boolean;
}>`
  max-height: ${({ showDropdown }) => (showDropdown ? "206px" : "0px")};
  height: fit-content;
  overflow-y: ${({ showDropdown }) => (showDropdown ? "auto" : "hidden")};
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  inset: 0;
  top: 40px;
  z-index: ${({ theme }) => theme.zIndex.z300};
  background: ${({ theme }) => theme.colors.containerColor};
  ${lightShadowDropdownList}
  border-radius: 1rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  transition: ${({ showDropdown }) => (showDropdown ? transition : "")};
`;

export const LabResultsDropdownListItem = styled.li`
  ${mediumRegularFont}
  cursor: pointer;
  width: 100%;
  padding: 10px 16px;
  transition: ${transition};

  :hover {
    background: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.buttonColor};
  }
`;
