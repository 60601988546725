import { PatientInsulinLogDTO } from "../../../../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";
import { InsulinChartTooltipProps } from "../tooltip/insulin-chart-tooltip";

export type InsulinSeries = {
  x: Date;
  y: number;
  patientInsulinLogDTO: PatientInsulinLogDTO;
  tooltip: InsulinChartTooltipProps;
};

export function buildInsulinDosesSeries(
  patientInsulinLogDTOs: PatientInsulinLogDTO[]
) {
  const insulinSeries: InsulinSeries[] = [];

  for (const patientInsulinLogDTO of patientInsulinLogDTOs) {
    const jsDate = new Date(patientInsulinLogDTO.date);

    const year = jsDate.getFullYear();
    const month = jsDate.getMonth() + 1;

    let realMonth = ``;
    if (month >= 0 && month <= 9) {
      realMonth = `0${month.toString()}`;
    } else {
      realMonth = month.toString();
    }

    const day = jsDate.getDate();
    let realDay = ``;
    if (day >= 0 && day <= 9) {
      realDay = `0${day.toString()}`;
    } else {
      realDay = day.toString();
    }

    const realDate = `${year}-${realMonth}-${realDay}`;

    const tooltip: InsulinChartTooltipProps = {
      x: new Date(`${realDate}T${patientInsulinLogDTO.time}`).toLocaleString(
        "en-UK",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
          second: undefined,
        }
      ),
      y: patientInsulinLogDTO.dose,
      kind: {
        kind: "Insulin",
        data: patientInsulinLogDTO,
      },
    };

    insulinSeries.push({
      x: new Date(`${realDate}T${patientInsulinLogDTO.time}`),
      y: patientInsulinLogDTO.dose,
      patientInsulinLogDTO,
      tooltip,
    });
  }

  return insulinSeries;
}
