import { PatientNutritionReportDTO } from "../../../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-dto";
import {
  NutritionReportSummaryListBar,
  NutritionReportSummaryListBarContainer,
  NutritionReportSummaryListGoalContainer,
  NutritionReportSummaryListLI,
  NutritionReportSummaryListTitleContainer,
} from "../../../../../../../food-logging/nutrition-report/nutrition-report-summary/nutrition-report-summary-screen/nutrition-report-summary-list/styled-nutrition-report-summary-list";
import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";

type ViewNutritionReportSummaryListFoodgroupsProps = {
  patientNutritionReport: PatientNutritionReportDTO;
};

export const ViewNutritionReportSummaryListFoodgroups = (
  props: ViewNutritionReportSummaryListFoodgroupsProps
) => {
  const { patientNutritionReport } = props;

  return (
    <>
      {patientNutritionReport.patientNutritionReportFoodGroups.map(
        (foodGroup) => (
          <NutritionReportSummaryListLI key={foodGroup.foodGroup.id}>
            <NutritionReportSummaryListTitleContainer>
              <img
                src={foodGroup.foodGroup.iconUrl}
                alt={foodGroup.foodGroup.name}
              />
              <p>Av. {foodGroup.foodGroup.name}/day</p>
            </NutritionReportSummaryListTitleContainer>
            <NutritionReportSummaryListBarContainer>
              <NutritionReportSummaryListBar
                progress={
                  ((foodGroup.actualDailyServingsCount /
                    foodGroup.goalDailyServingsCount) *
                    100) /
                  2
                }
              >
                <div />
              </NutritionReportSummaryListBar>
              <NutritionReportSummaryListGoalContainer>
                <p>
                  {roundTo1DecimalPlace(foodGroup.actualDailyServingsCount)}/
                  {foodGroup.goalDailyServingsCount}
                </p>
              </NutritionReportSummaryListGoalContainer>
            </NutritionReportSummaryListBarContainer>
          </NutritionReportSummaryListLI>
        )
      )}
    </>
  );
};
