import { CarbCounterV1MealSummaryDTO } from "../../../../../../../models/carb-counter-dtos/carb-counter-v1-meal-summary-dto";
import { roundTo1DecimalPlace } from "../../../../../../../utils/math-utils";
import {
  FoodLoggingSelectedPatientMealMacrosBar,
  FoodLoggingSelectedPatientMealMacrosCarbs,
  FoodLoggingSelectedPatientMealMacrosContainer,
  FoodLoggingSelectedPatientMealMacrosFats,
  FoodLoggingSelectedPatientMealMacrosProtiens,
  FoodLoggingSelectedPatientMealMacrosTitleContainer,
} from "../../../../legacy-food-logging-selected-patient-meal/food-logging-selected-patient-meal-macros/styled-food-logging-selected-patient-meal-macros";
import { CarbCounterFoodLoggingSelectedPatientMealMacrosLegend } from "./carb-counter-food-logging-selected-patient-meal-macros-legend";

type LoadedCarbCounterFoodLoggingSelectedPatientMealMacrosProps = {
  carbCounterV1MealSummaryDTO: CarbCounterV1MealSummaryDTO;
};

export const LoadedCarbCounterFoodLoggingSelectedPatientMealMacros = (
  props: LoadedCarbCounterFoodLoggingSelectedPatientMealMacrosProps
) => {
  const { carbCounterV1MealSummaryDTO } = props;

  return (
    <FoodLoggingSelectedPatientMealMacrosContainer $width="70%">
      <FoodLoggingSelectedPatientMealMacrosTitleContainer>
        <p>Macronutrients</p>
        <span>
          {roundTo1DecimalPlace(carbCounterV1MealSummaryDTO.totalKcal)} Kcal
        </span>
      </FoodLoggingSelectedPatientMealMacrosTitleContainer>
      <FoodLoggingSelectedPatientMealMacrosBar $width="100%">
        <FoodLoggingSelectedPatientMealMacrosCarbs
          width={`${carbCounterV1MealSummaryDTO.carbsKcalPercentage}%`}
        />
        <FoodLoggingSelectedPatientMealMacrosFats
          width={`${
            carbCounterV1MealSummaryDTO.carbsKcalPercentage +
            carbCounterV1MealSummaryDTO.fatKcalPercentage
          }%`}
        />
        <FoodLoggingSelectedPatientMealMacrosProtiens
          width={`${
            carbCounterV1MealSummaryDTO.carbsKcalPercentage +
            carbCounterV1MealSummaryDTO.fatKcalPercentage +
            carbCounterV1MealSummaryDTO.proteinKcalPercentage
          }%`}
        />
      </FoodLoggingSelectedPatientMealMacrosBar>
      <CarbCounterFoodLoggingSelectedPatientMealMacrosLegend
        carbCounterV1MealSummaryDTO={carbCounterV1MealSummaryDTO}
      />
    </FoodLoggingSelectedPatientMealMacrosContainer>
  );
};
