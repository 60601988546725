import { PatientMealDTO } from "../../../models/patient-meal-dtos/patient-meal-dto";
import { useCallback, useMemo } from "react";
import { Temporal } from "temporal-polyfill";
import {
  DraftMealIndicator,
  FoodLoggingPatientMealsLI,
  FoodLoggingPatientMealsLiImageOrVoiceNoteContainer,
  FoodLoggingPatientMealsLiIndicatorsContainer,
  FoodLoggingPatientMealsLiText,
  FoodLoggingPatientMealsUL,
  UserAddedMealIdicator,
} from "./styled-food-logging-patient-meals";
import { FoodLoggingPatientsTab } from "../food-logging-patients/food-logging-patients-tabs/food-logging-patients-tabs";
import { assertNever } from "../../../utils/assert-never";
import { AddNewMeal } from "./add-new-meal/add-new-meal";
import { DeletePatientMeal } from "./delete-patient-meal/delete-patient-meal";

export type FoodLoggingPatientMealsProps = {
  patientMealDTOs: PatientMealDTO[];
  selectedPatientMealDTO?: PatientMealDTO;
  onPatientMealChange: (patientMealDTO: PatientMealDTO) => void;
  selectedTab: FoodLoggingPatientsTab;
};

export function FoodLoggingPatientMeals(props: FoodLoggingPatientMealsProps) {
  const {
    patientMealDTOs,
    selectedPatientMealDTO,
    onPatientMealChange,
    selectedTab,
  } = props;

  const displayedPatientMealDTOs = useMemo(() => {
    return [...patientMealDTOs]
      .filter((patientMealDTO) => {
        if (selectedTab === "all") {
          return true;
        } else if (selectedTab === "todo") {
          return !patientMealDTO.finishedFoodLogging;
        } else {
          return assertNever(selectedTab);
        }
      })
      .sort((a, b) => {
        return Temporal.PlainDateTime.compare(
          `${b.date}T${b.time}`,
          `${a.date}T${a.time}`
        );
      });
  }, [patientMealDTOs, selectedTab]);

  const displayTime = useCallback(
    (time: string) =>
      Temporal.PlainTime.from(time).toLocaleString("en-UK", {
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
        second: undefined,
      }),
    []
  );

  const displayDate = useCallback(
    (date: string) =>
      Temporal.PlainDate.from(date).toLocaleString("en-UK", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }),
    []
  );

  return (
    <FoodLoggingPatientMealsUL>
      {displayedPatientMealDTOs.map((patientMealDTO) => (
        <FoodLoggingPatientMealsLI
          key={patientMealDTO.id}
          onClick={() => onPatientMealChange(patientMealDTO)}
          activeState={patientMealDTO.id === selectedPatientMealDTO?.id}
        >
          {patientMealDTO.patientMealImages.length > 0 ? (
            <FoodLoggingPatientMealsLiImageOrVoiceNoteContainer>
              <img
                src={patientMealDTO.patientMealImages[0].publicUrl}
                alt="Meal"
                crossOrigin="anonymous"
              />
            </FoodLoggingPatientMealsLiImageOrVoiceNoteContainer>
          ) : patientMealDTO.patientMealVoiceNotes.length > 0 ? (
            <FoodLoggingPatientMealsLiImageOrVoiceNoteContainer>
              <span />
            </FoodLoggingPatientMealsLiImageOrVoiceNoteContainer>
          ) : (
            <FoodLoggingPatientMealsLiImageOrVoiceNoteContainer />
          )}
          <FoodLoggingPatientMealsLiText>
            <p>{displayTime(patientMealDTO.time)}</p>
            <span>{displayDate(patientMealDTO.date)}</span>
          </FoodLoggingPatientMealsLiText>
          <FoodLoggingPatientMealsLiIndicatorsContainer>
            {patientMealDTO.source !== "Admin" && (
              <UserAddedMealIdicator className="material-symbols-outlined">
                account_circle
              </UserAddedMealIdicator>
            )}
            <DraftMealIndicator show={!patientMealDTO.finishedFoodLogging} />
            <DeletePatientMeal patientMealDTO={patientMealDTO} />
          </FoodLoggingPatientMealsLiIndicatorsContainer>
        </FoodLoggingPatientMealsLI>
      ))}
      <AddNewMeal />
    </FoodLoggingPatientMealsUL>
  );
}
