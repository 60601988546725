import { useNavigate } from "react-router-dom";
import { Temporal } from "temporal-polyfill";
import { PatientPlanDTO } from "../../../models/patient-plan-dtos/patient-plan-dto";
import { PlansBtn } from "./plans-btn";
import {
  DraftNotification,
  ListPlansContainer,
  ListPlansHeader,
  ListPlansLIContainer,
  ListPlansLIContainerGrid,
  ListPlansLIContainerIcon,
  ListPlansLIContainerText,
  ListPlansLIHeader,
  ListPlansLIHeaderIcon,
  ListPlansListItem,
  ListPlansLISubContainer,
} from "./styled-plans";
import { EmptyPlans } from "./empty-plans";

type PlansBodyProps = {
  patientPlans: PatientPlanDTO[];
};

export const PlansBody = (props: PlansBodyProps) => {
  const { patientPlans } = props;

  const navigate = useNavigate();

  type GroupedPatientPlansByMonth = {
    month: string;
    displayDate: string;
    fullDate: string;
    groupedPlans: {
      id: number;
      planNumber: number;
      createdAt: string;
      updatedAt: string;
      patientId: number;
      isDraft: boolean;
    }[];
  };

  type GroupedPatientPlansType = Record<string, GroupedPatientPlansByMonth>;

  const groupedPatientPlans = patientPlans.reduce(
    (accumulator: GroupedPatientPlansType, item: PatientPlanDTO) => {
      const month = item.createdAt.split("-")[1];
      const year = item.createdAt.split("-")[0];
      const objectKey = `${year}-${month}`;

      const planDate = Temporal.PlainDateTime.from(
        item.createdAt.split("T")[0]
      ).toLocaleString("en-US", {
        month: "long",
        year: "numeric",
      });

      const fullDate = Temporal.PlainDateTime.from(
        item.createdAt.split("T")[0]
      ).toString();

      if (!accumulator[objectKey]) {
        accumulator[objectKey] = {
          month: objectKey,
          displayDate: planDate,
          fullDate,
          groupedPlans: [],
        };
      }

      accumulator[objectKey].groupedPlans.push(item);

      return accumulator;
    },
    {}
  );

  if (patientPlans.length === 0) {
    return <EmptyPlans />;
  }

  return (
    <ListPlansContainer>
      <ListPlansHeader>
        <p>Weekly Plans ({patientPlans.length})</p>
        <PlansBtn outlined={true} />
      </ListPlansHeader>
      <ul>
        {Object.values(groupedPatientPlans)
          .sort((a, b) =>
            Temporal.PlainDateTime.compare(b.fullDate, a.fullDate)
          )
          .map((plan, index) => (
            <ListPlansListItem key={index}>
              <ListPlansLIHeader>
                <ListPlansLIHeaderIcon>
                  <img src="/img/plans/plan-date.svg" alt="Calendar Icon" />
                </ListPlansLIHeaderIcon>
                <p>{plan.displayDate}</p>
              </ListPlansLIHeader>
              <ListPlansLIContainerGrid>
                {plan.groupedPlans
                  .sort((a, b) => b.planNumber - a.planNumber)
                  .map((weekPlan) => {
                    const weekPlanDate = Temporal.PlainDateTime.from(
                      weekPlan.createdAt.split("T")[0]
                    ).toLocaleString("en-UK", {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    });

                    return (
                      <ListPlansLIContainer key={weekPlan.id}>
                        <ListPlansLISubContainer>
                          <ListPlansLIContainerIcon>
                            <span className="material-symbols-outlined">
                              description
                            </span>
                          </ListPlansLIContainerIcon>
                          <ListPlansLIContainerText>
                            <p
                              onClick={() =>
                                navigate(`../../plans/${weekPlan.id}`)
                              }
                            >{`Plan (${weekPlan.planNumber})`}</p>
                            <span>{weekPlanDate}</span>
                          </ListPlansLIContainerText>
                        </ListPlansLISubContainer>
                        {weekPlan.isDraft ? (
                          <DraftNotification
                            onClick={() =>
                              navigate(`../../plans/${weekPlan.id}`)
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </ListPlansLIContainer>
                    );
                  })}
              </ListPlansLIContainerGrid>
            </ListPlansListItem>
          ))}
      </ul>
    </ListPlansContainer>
  );
};
