import { NutritionReportTableRowTR } from "./styled-nutrition-report-table-row";
import { useNutritionReportSelectedTableRows } from "../../../context/nutrition-report-context/nutrition-report-selected-table-rows-context";
import { useNutritionReportMealTableRow } from "../../../context/nutrition-report-context/nutrition-report-meal-table-row-context";
import { NutritionReportMeatTableCell } from "../nutrition-report-table-cells/nutrition-report-meat-table-cell";
import { NutritionReportDairyTableCell } from "../nutrition-report-table-cells/nutrition-report-dairy-table-cell";
import { NutritionReportVegetablesTableCell } from "../nutrition-report-table-cells/nutrition-report-vegetables-table-cell";
import { NutritionReportFruitsTableCell } from "../nutrition-report-table-cells/nutrition-report-fruits-table-cell";
import { NutritionReportLegumesTableCell } from "../nutrition-report-table-cells/nutrition-report-legumes-table-cell";
import { NutritionReportStarchesTableCell } from "../nutrition-report-table-cells/nutrition-report-starches-table-cell";
import { NutritionReportFatTableCell } from "../nutrition-report-table-cells/nutrition-report-fat-table-cell";
import { NutritionReportCaffeineTableCell } from "../nutrition-report-table-cells/nutrition-report-caffeine-table-cell";
import { NutritionReportDateTableCell } from "../nutrition-report-table-cells/nutrition-report-date-table-cell";
import { NutritionReportMealDateTableCell } from "../nutrition-report-table-cells/nutrition-report-meal-date-table-cell/nutrition-report-meal-date-table-cell";
import { NutritionReportNumberOfMealsTableCell } from "../nutrition-report-table-cells/nutrition-report-number-of-meals-table-cell";
import { NutritionReportCarbSourcesFrequencyTableCells } from "../nutrition-report-table-cells/nutrition-report-carb-sources-frequency-table-cells";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";
import { NutritionReportProteinSourcesFrequencyTableCells } from "../nutrition-report-table-cells/nutrition-report-protein-sources-frequency-table-cells";
import { NutritionReportAllCarbSourcesTableCell } from "../nutrition-report-table-cells/nutrition-report-all-carb-sources-table-cell";
import { NutritionReportAllProteinSourcesTableCell } from "../nutrition-report-table-cells/nutrition-report-all-protein-sources-table-cell";
import { NutritionReportNutsFrequencyTableCells } from "../nutrition-report-table-cells/nutrition-report-nuts-frequency-table-cells";
import { NutritionReportKcalTableCell } from "../nutrition-report-table-cells/nutrition-report-kcal-table-cell";
import { NutritionReportCarbsgmTableCell } from "../nutrition-report-table-cells/nutrition-report-carbsgm-table-cell";
import { NutritionReportFatgmTableCell } from "../nutrition-report-table-cells/nutrition-report-fatgm-table-cell";
import { NutritionReportProteingmTableCell } from "../nutrition-report-table-cells/nutrition-report-proteingm-table-cell";
import { NutritionReportCarbsPercentageTableCell } from "../nutrition-report-table-cells/nutrition-report-carbs-percentage-table-cell";
import { NutritionReportProteinPercentageTableCell } from "../nutrition-report-table-cells/nutrition-report-protein-percentage-table-cell";
import { NutritionReportFatPercentageTableCell } from "../nutrition-report-table-cells/nutrition-report-fat-percentage-table-cell";
import { NutritionReportKTableCell } from "../nutrition-report-table-cells/nutrition-report-k-table-cell";
import { NutritionReportNaTableCell } from "../nutrition-report-table-cells/nutrition-report-na-table-cell";
import { NutritionReportFeedbackTableCell } from "../nutrition-report-table-cells/nutrition-report-feedback-table-cell/nutrition-report-feedback-table-cell";
import { getSelectedDaysIds } from "../utils/calculate-totals-and-averages/get-selected-days-ids";

type NutritionReportTableRowProps = {
  index: number;
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportTableRow = (
  props: NutritionReportTableRowProps
) => {
  const { index, kind } = props;

  const { MealTableRowContainerRef, showMealTableRow } =
    useNutritionReportMealTableRow();

  const { selectedDays } = useNutritionReportSelectedTableRows();

  return (
    <NutritionReportTableRowTR
      fade={!getSelectedDaysIds(selectedDays).includes(index)}
      ref={MealTableRowContainerRef}
      showList={
        kind.kind === "Row"
          ? true
          : kind.kind === "Meal" && showMealTableRow.includes(index)
          ? true
          : false
      }
    >
      {kind.kind === "Row" ? (
        <NutritionReportDateTableCell
          date={kind.kind === "Row" ? kind.data.date : ""}
          index={index}
        />
      ) : (
        <NutritionReportMealDateTableCell meal={kind.data} />
      )}
      <NutritionReportNumberOfMealsTableCell kind={kind} />
      <NutritionReportStarchesTableCell kind={kind} />
      <NutritionReportLegumesTableCell kind={kind} />
      <NutritionReportFruitsTableCell kind={kind} />
      <NutritionReportVegetablesTableCell kind={kind} />
      <NutritionReportMeatTableCell kind={kind} />
      <NutritionReportDairyTableCell kind={kind} />
      <NutritionReportFatTableCell kind={kind} />
      <NutritionReportCaffeineTableCell kind={kind} />
      <NutritionReportNaTableCell kind={kind} />
      <NutritionReportKTableCell kind={kind} />

      <NutritionReportKcalTableCell kind={kind} />
      <NutritionReportCarbsgmTableCell kind={kind} />
      <NutritionReportFatgmTableCell kind={kind} />
      <NutritionReportProteingmTableCell kind={kind} />
      <NutritionReportCarbsPercentageTableCell kind={kind} />
      <NutritionReportFatPercentageTableCell kind={kind} />
      <NutritionReportProteinPercentageTableCell kind={kind} />

      <NutritionReportCarbSourcesFrequencyTableCells kind={kind} />
      <NutritionReportProteinSourcesFrequencyTableCells kind={kind} />
      <NutritionReportNutsFrequencyTableCells kind={kind} />
      <NutritionReportAllCarbSourcesTableCell kind={kind} />
      <NutritionReportAllProteinSourcesTableCell kind={kind} />
      <NutritionReportFeedbackTableCell kind={kind} />
    </NutritionReportTableRowTR>
  );
};
