import { useForm } from "react-hook-form";
import { useApiWithFullPageLoader } from "../../../../../../hooks/use-api-with-full-page-loader";
import {
  ErrorText,
  Input,
  InputContainer,
  InputLabel,
  ModalFooter,
  PaddingTop,
} from "../../../../../../styles/classes/reusable-classes";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { ModalHeader } from "../../../../../../components/modal/modal-header/modal-header";
import { ModalForm } from "../../../../../../components/modal/modal-form/modal-form";
import { useImportCronometer } from "../../../../../../hooks/use-import-cronometer";
import { useToastService } from "../../../../../../context/toast-service-context";
import { useGetPatientDto } from "../../../../../../hooks/use-get-patient-dto";
import { useParams } from "react-router-dom";

export type ImportCronometerNewReadingsModalProps = {
  onModalClose: () => void;
};

type Inputs = {
  sesNonce: string;
};

export function ImportCronometerNewReadingsModal(
  props: ImportCronometerNewReadingsModalProps
) {
  const { onModalClose } = props;

  const importNewReadingsFromCronometer = useApiWithFullPageLoader(
    useImportCronometer()
  );

  const { id } = useParams();
  const patientID = parseInt(id!);

  const { patientDTO } = useGetPatientDto(patientID);
  const { showToast } = useToastService();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = handleSubmit(async (inputs) => {
    const importNewPatientServingsFromCronometerDTO = {
      sesNonce: inputs.sesNonce,
    };
    if (patientDTO !== undefined) {
      try {
        await importNewReadingsFromCronometer(
          patientDTO,
          importNewPatientServingsFromCronometerDTO
        );
        showToast("Success", "Cronometer New Readings imported successfully!");
        onModalClose();
      } catch (e) {
        showToast(
          "Error",
          "Failed to import Cronometer new readings. Perhaps you forgot to set patient's SES Nonce?"
        );
      }
    }
  });

  return (
    <ModalForm onSubmit={onSubmit}>
      <ModalHeader
        title="Cronometer New Readings"
        onModalClose={onModalClose}
      />
      <PaddingTop>
        <InputContainer>
          <InputLabel>Cronometer SES Nonce</InputLabel>
          <Input
            error={errors.sesNonce?.type === "required" && true}
            {...register("sesNonce", { required: true })}
          />
          {errors.sesNonce?.type === "required" && (
            <ErrorText>SES Nonce is required</ErrorText>
          )}
        </InputContainer>
      </PaddingTop>
      <ModalFooter>
        <Button outlined onClick={onModalClose} type="button">
          Cancel
        </Button>
        <Button type="submit">Import</Button>
      </ModalFooter>
    </ModalForm>
  );
}
