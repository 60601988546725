import { PlansStepsProvider } from "../context/plans-steps-context";
import { PlanSubScreens } from "../plan-steps/plan-sub-screens";
import { CreatePlansHeader } from "./create-plans-header/create-plans-header";
import { useParams } from "react-router-dom";
import { useGetPatientDetailedPlan } from "../../../../hooks/patient-plan-hooks/use-get-patient-detailed-plan";
import { useCallback } from "react";
import { useLoadableData } from "../../../../hooks/use-loadable-data";
import { LoadableDetailedPlanProvider } from "../context/loadable-detailed-plan-context";
import { LoadableDataLoading } from "../../../../components/loadable-data-loading";
import { PlansHeaderTitle } from "./create-plans-header/plans-header-title";
import { useGetPatientPlansGoals } from "../../../../hooks/patient-plan-hooks/use-get-patient-plans-goals";
import { useGetPatientPlans } from "../../../../hooks/patient-plan-hooks/use-get-patient-plans";
import { useGetPatientWeights } from "../../../../hooks/patient-weights-hooks/use-get-patient-weights";
import { useGetPatient } from "../../../../hooks/patient-hooks/use-get-patient";
import { useGetLabTestTypes } from "../../../../hooks/lab-test-types/use-get-lab-test-types";
import { useGetPatientLabTestResults } from "../../../../hooks/patient-lab-test-result-hooks/use-get-patient-lab-test-results";
import { useGetLabTestTypeRefs } from "../../../../hooks/lab-test-type-refs/use-get-lab-test-type-refs";

type RouteParams = {
  id: string;
  planID: string;
};

export const CreatePlans = () => {
  const { id, planID } = useParams<RouteParams>();

  const getPatient = useGetPatient();
  const getPatientDetailedPlan = useGetPatientDetailedPlan();
  const getPatientPlansGoals = useGetPatientPlansGoals();
  const getPatientPlans = useGetPatientPlans();
  const getPatientWeights = useGetPatientWeights();
  const getLabTestTypes = useGetLabTestTypes();
  const getLabTestsResults = useGetPatientLabTestResults();
  const getLabTestTypeRefs = useGetLabTestTypeRefs();

  const load = useCallback(async () => {
    const patientId = parseInt(id!);
    const planId = parseInt(planID!);

    const patientDTO = await getPatient(patientId);

    const patientDetailedPlanDTO = await getPatientDetailedPlan(
      patientId,
      planId
    );

    const patientPlansGoals = await getPatientPlansGoals(patientId);

    const patientPlans = await getPatientPlans(patientId);

    const patientWeightDTOs = await getPatientWeights(patientId);

    const labTestTypeDTOs = await getLabTestTypes();

    const patientLabTestResultsDTOs = await getLabTestsResults(patientId);

    const labTestTypeRefDTOs = await getLabTestTypeRefs();

    return {
      patientDTO,
      patientDetailedPlanDTO,
      patientPlansGoals,
      patientPlans,
      patientWeightDTOs,
      labTestTypeDTOs,
      patientLabTestResultsDTOs,
      labTestTypeRefDTOs,
    };
  }, [
    id,
    planID,
    getPatientDetailedPlan,
    getPatientPlansGoals,
    getPatientPlans,
    getPatientWeights,
    getPatient,
    getLabTestTypes,
    getLabTestsResults,
    getLabTestTypeRefs,
  ]);

  const loadableDetailedPlan = useLoadableData(load);

  return (
    <PlansStepsProvider>
      <LoadableDetailedPlanProvider loadableDetailedPlan={loadableDetailedPlan}>
        <CreatePlansHeader />
        <LoadableDataLoading
          state={loadableDetailedPlan.state}
          successComponent={() => (
            <>
              <PlansHeaderTitle />
              <PlanSubScreens />
            </>
          )}
        />
      </LoadableDetailedPlanProvider>
    </PlansStepsProvider>
  );
};
