import { assertNever } from "./assert-never";
import { PatientType } from "../models/patient-dtos/patient-type";

export function getInRangeNumbers(patientType: PatientType): [number, number] {
  if (
    patientType === "Type1" ||
    patientType === "Type2" ||
    patientType === "Type1Fragile" ||
    patientType === "Type2Fragile" ||
    patientType === "Type1Pediatric"
  ) {
    return [70, 180];
  } else if (
    patientType === "NormalOrHealthy" ||
    patientType === "Prediabetic"
  ) {
    return [70, 140];
  } else if (
    patientType === "Type1Pregnant" ||
    patientType === "Type2Pregnant" ||
    patientType === "Gestational"
  ) {
    return [63, 140];
  } else {
    return assertNever(patientType);
  }
}
