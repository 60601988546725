import { createContext, PropsWithChildren, useContext } from "react";
import { UseLoadableDataReturn } from "../../../../hooks/use-loadable-data";
import { PatientDetailedPlanDTO } from "../../../../models/patient-plan-dtos/patient-detailed-plan-dto";
import { PatientPlansGoalsDTO } from "../../../../models/patient-plan-dtos/patient-plans-goals-dto";
import { PatientPlanDTO } from "../../../../models/patient-plan-dtos/patient-plan-dto";
import { PatientWeightDTO } from "../../../../models/patient-weight-dtos/patient-weight-dto";
import { PatientDTO } from "../../../../models/patient-dtos/patient-dto";
import { LabTestTypeDTO } from "../../../../models/lab-test-types/lab-test-type-dto";
import { PatientLabTestResultDTO } from "../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import { LabTestTypeRefDTO } from "../../../../models/lab-test-type-refs/lab-test-type-ref-dto";

export type DetailedPlan = {
  patientDTO: PatientDTO;
  patientDetailedPlanDTO: PatientDetailedPlanDTO;
  patientPlansGoals: PatientPlansGoalsDTO;
  patientPlans: PatientPlanDTO[];
  patientWeightDTOs: PatientWeightDTO[];
  labTestTypeDTOs: LabTestTypeDTO[];
  patientLabTestResultsDTOs: PatientLabTestResultDTO[];
  labTestTypeRefDTOs: LabTestTypeRefDTO[];
};

export type LoadableDetailedPlan = UseLoadableDataReturn<DetailedPlan>;

const LoadableDetailedPlanContext = createContext<
  LoadableDetailedPlan | undefined
>(undefined);

export type LoadableDetailedPlanProviderProps = PropsWithChildren<{
  loadableDetailedPlan: LoadableDetailedPlan;
}>;

export function LoadableDetailedPlanProvider(
  props: LoadableDetailedPlanProviderProps
) {
  const { loadableDetailedPlan, children } = props;

  return (
    <LoadableDetailedPlanContext.Provider value={loadableDetailedPlan}>
      {children}
    </LoadableDetailedPlanContext.Provider>
  );
}

export function useLoadableDetailedPlan(): LoadableDetailedPlan {
  const loadableDetailedPlan = useContext(LoadableDetailedPlanContext);
  if (loadableDetailedPlan === undefined) {
    throw Error(
      "useLoadableDetailedPlan must be used within an LoadableDetailedPlanProvider"
    );
  }

  return loadableDetailedPlan;
}

export function useDetailedPlan(): DetailedPlan {
  const loadableDetailedPlan = useLoadableDetailedPlan();
  if (loadableDetailedPlan.state.data === undefined) {
    throw Error("DetailedPlan have never been loaded successfully yet.");
  }

  return loadableDetailedPlan.state.data;
}

export function useReloadDetailedPlan(): () => void {
  const loadableDetailedPlan = useLoadableDetailedPlan();
  return loadableDetailedPlan.reload;
}
