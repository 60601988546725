import styled from "styled-components/macro";
import { flexCenterCenter } from "../../../../../../styles/classes/gloabl-classes";
import { NavbarHeight } from "../../../../../../styles/global-style";
import { NewPrescriptionHeaderHeight } from "../../constants/prescription-constants";

export const NewPrescriptionBodyContainer = styled.div<{
  calculateMarginTop: "0px" | "130px";
}>`
  display: flex;
  background: ${({ theme }) => theme.colors.containerColor};
  height: calc(100vh - (${NewPrescriptionHeaderHeight} + ${NavbarHeight}));
  margin-top: ${({ calculateMarginTop }) =>
    calculateMarginTop === "0px" ? `${NewPrescriptionHeaderHeight}` : "0px"};
`;

export const NewPrescriptionBodyMain = styled.main`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-top: 0px;
  border-right: 0px;
  flex: 1;
  margin-left: 0.5rem;
  ${flexCenterCenter}
`;

export const NewPrescriptionFormInputsContainer = styled.div`
  display: grid;
  gap: 1.5rem 0rem;
  margin-top: 40px;
  width: 500px;
`;
