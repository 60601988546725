import { useCallback } from "react";
import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { PatientActivityLogStatsDTO } from "../../models/patient-activity-log-dtos/patient-activity-log-stats-dto";

export function useGetPatientActivityLogStats(): (
  patientId: number,
  startDate?: string,
  endDate?: string
) => Promise<PatientActivityLogStatsDTO> {
  const client = useClient();

  return useCallback(
    async (patientId, startDate = undefined, endDate = undefined) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientActivityLogStatsDTO>(
        `${PATIENTS_PATH}/${patientId}/activity-logs/stats`,
        {
          params: {
            startDate,
            endDate,
          },
        }
      );

      return response.data;
    },
    [client]
  );
}
