import { PropsWithChildren, createContext, useContext } from "react";
import { UseLoadableDataReturn } from "../../../../hooks/use-loadable-data";
import { PatientDTO } from "../../../../models/patient-dtos/patient-dto";
import { PatientMedicalHistoryDTO } from "../../../../models/patient-medical-history-dtos/patient-medical-history-dto";
import { PatientWeightDTO } from "../../../../models/patient-weight-dtos/patient-weight-dto";
import { PatientBloodPressureDTO } from "../../../../models/patient-blood-pressure-dtos/patient-blood-pressure-dto";
import { PatientTimelineDTO } from "../../../../models/patient-timeline-dtos/patient-timeline-dto";
import { MaritalStatusDTO } from "../../../../models/marital-status-dtos/marital-status-dto";
import { PatientDietProfileDTO } from "../../../../models/patient-diet-profile-dtos/patient-diet-profile-dto";
import { PatientDiagnosisDTO } from "../../../../models/patient-diagnosis-dtos/patient-diagnosis-dto";
import { PatientProfileDiagnosisDTO } from "../../../../models/patient-profile-diagnosis-dtos/patient-profile-diagnosis-dto";
import { ProfileDiagnosisDTO } from "../../../../models/profile-diagnosis-dtos/profile-diagnosis-dto";
import { PatientLabTestResultDTO } from "../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import { LabTestTypeDTO } from "../../../../models/lab-test-types/lab-test-type-dto";

export type SinglePatientSidebar = {
  patientDTO: PatientDTO;
  patientMedicalHistoryDTO: PatientMedicalHistoryDTO;
  patientWeightDTOs: PatientWeightDTO[];
  patientBloodPressureDTOs: PatientBloodPressureDTO[];
  patientTimelineDTO: PatientTimelineDTO;
  MaritalStatusDTOs: MaritalStatusDTO[];
  patientDietProfileDTO: PatientDietProfileDTO;
  patientDiagnosisDTO?: PatientDiagnosisDTO;
  patientProfileDiagnoses: PatientProfileDiagnosisDTO[];
  profileDiagnoses: ProfileDiagnosisDTO[];
  patientLabTestResultsDTOs: PatientLabTestResultDTO[];
  labTestTypeDTOs: LabTestTypeDTO[];
};

export type LoadableSinglePatientSidebar =
  UseLoadableDataReturn<SinglePatientSidebar>;

const LoadableSinglePatientSidebarContext = createContext<
  LoadableSinglePatientSidebar | undefined
>(undefined);

export type LoadableSinglePatientSidebarProviderProps = PropsWithChildren<{
  loadableSinglePatientSidebar: LoadableSinglePatientSidebar;
}>;

export function LoadableSinglePatientSidebarProvider(
  props: LoadableSinglePatientSidebarProviderProps
) {
  const { loadableSinglePatientSidebar, children } = props;

  return (
    <LoadableSinglePatientSidebarContext.Provider
      value={loadableSinglePatientSidebar}
    >
      {children}
    </LoadableSinglePatientSidebarContext.Provider>
  );
}

export function useLoadableSinglePatientSidebar(): LoadableSinglePatientSidebar {
  const loadableSinglePatientSidebar = useContext(
    LoadableSinglePatientSidebarContext
  );

  if (loadableSinglePatientSidebar === undefined) {
    throw Error(
      "useLoadableSinglePatientSidebar must be used within an LoadableSinglePatientSidebarProvider"
    );
  }

  return loadableSinglePatientSidebar;
}

export function useSinglePatientSidebar(): SinglePatientSidebar {
  const loadableSinglePatientSidebar = useLoadableSinglePatientSidebar();

  if (loadableSinglePatientSidebar.state.data === undefined) {
    throw Error("Patient Sidebar have never been loaded successfully yet.");
  }

  return loadableSinglePatientSidebar.state.data;
}

export function useReloadSinglePatientSidebar(): () => void {
  const loadableSinglePatientSidebar = useLoadableSinglePatientSidebar();

  return loadableSinglePatientSidebar.reload;
}
