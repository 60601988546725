import styled from "styled-components/macro";
import { mediumSemiBoldFont } from "../../../../../styles/classes/gloabl-classes";

export const NutritionAnalysisHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;

  @media screen and (max-width: 1250px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const NutritionAnalysisHeaderSelectedDatesContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px;

  p {
    margin: 0px;
    ${mediumSemiBoldFont}
  }
`;
