import styled from "styled-components/macro";

export const NutritionReportTableRowTR = styled.tr<{
  fade: boolean;
  showList: boolean;
  sticky?: boolean;
}>`
  position: relative;
  z-index: 100;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  opacity: ${({ fade }) => (fade ? "0.5" : "1")};
  display: ${({ showList }) => (showList ? "table-row" : "none")};

  :last-child {
    border: 0px;

    td {
      :first-child {
        border-bottom-left-radius: 16px;
      }

      :last-child {
        border-bottom-right-radius: 16px;
      }
    }
  }

  td {
    vertical-align: center;
  }
`;
