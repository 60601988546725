import { PropsWithChildren, createContext, useContext } from "react";
import { UseLoadableDataReturn } from "../../../../../hooks/use-loadable-data";
import { PatientDietProfileDTO } from "../../../../../models/patient-diet-profile-dtos/patient-diet-profile-dto";
import { AddedSugarDTO } from "../../../../../models/added-sugar-dtos/added-sugar-dto";
import { ActivityDTO } from "../../../../../models/activity-dtos/activity-dto";
import { DietDTO } from "../../../../../models/diet-dtos/diet-dto";
import { FoodAllergyDTO } from "../../../../../models/food-allergy-dtos/food-allergy-dto";
import { PatientDTO } from "../../../../../models/patient-dtos/patient-dto";

export type PatientProfileDiet = {
  patientDTO: PatientDTO;
  patientDietProfileDTO: PatientDietProfileDTO;
  addedSugars: AddedSugarDTO[];
  activities: ActivityDTO[];
  diets: DietDTO[];
  foodAllergies: FoodAllergyDTO[];
};

export type LoadablePatientProfileDiet =
  UseLoadableDataReturn<PatientProfileDiet>;

const LoadablePatientProfileDietContext = createContext<
  LoadablePatientProfileDiet | undefined
>(undefined);

export type LoadablePatientProfileDietProviderProps = PropsWithChildren<{
  loadablePatientProfileDiet: LoadablePatientProfileDiet;
}>;

export function LoadablePatientProfileDietProvider(
  props: LoadablePatientProfileDietProviderProps
) {
  const { loadablePatientProfileDiet, children } = props;

  return (
    <LoadablePatientProfileDietContext.Provider
      value={loadablePatientProfileDiet}
    >
      {children}
    </LoadablePatientProfileDietContext.Provider>
  );
}

export function useLoadablePatientProfileDiet(): LoadablePatientProfileDiet {
  const loadablePatientProfileDiet = useContext(
    LoadablePatientProfileDietContext
  );

  if (loadablePatientProfileDiet === undefined) {
    throw Error(
      "useLoadablePatientProfileDiet must be used within an LoadablePatientProfileDietProvider"
    );
  }

  return loadablePatientProfileDiet;
}

export function usePatientProfileDiet(): PatientProfileDiet {
  const loadablePatientProfileDiet = useLoadablePatientProfileDiet();

  if (loadablePatientProfileDiet.state.data === undefined) {
    throw Error("Patient Profile have never been loaded successfully yet.");
  }

  return loadablePatientProfileDiet.state.data;
}

export function useReloadPatientProfileDiet(): () => void {
  const loadablePatientProfileDiet = useLoadablePatientProfileDiet();

  return loadablePatientProfileDiet.reload;
}
