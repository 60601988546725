import styled from "styled-components/macro";

export const MedicineModalContainer = styled.div`
  padding: 24px 0px;
  display: grid;
  gap: 24px 0px;

  label {
    margin-left: 0px;
  }
`;
