import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";
import { roundTo1DecimalPlace } from "../../../../../utils/math-utils";

type NutritionReportFatPercentageTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportFatPercentageTableCell = (
  props: NutritionReportFatPercentageTableCellProps
) => {
  const { kind } = props;

  const { fatPercentage } = useMemo(() => {
    const fatPercentage =
      kind.kind === "Row"
        ? kind.data.fatPercentage
        : kind.data.patientMealMacrosSummary.fatPercentage;

    return { fatPercentage };
  }, [kind.kind, kind.data]);

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      stringValue={
        fatPercentage !== undefined
          ? `${roundTo1DecimalPlace(fatPercentage)}%`
          : ``
      }
    />
  );
};
