import { useMemo } from "react";
import { Modal } from "../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { ProfileMedicalHistoryEditModalBody } from "./patient-profile-medical-history-edit-modal-body/patient-profile-medical-history-edit-modal-body";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { FormProvider, useForm } from "react-hook-form";
import { PutPatientMedicalHistoryChronicDiseaseDTO } from "../../../../../../models/patient-medical-history-dtos/put-patient-medical-history-chronic-disease-dto";
import { PutPatientMedicalHistoryDiabetesEmergencyDTO } from "../../../../../../models/patient-medical-history-dtos/put-patient-medical-history-diabetes-emergency-dto";
import { PutPatientMedicalHistoryFamilyMemberDiseaseDTO } from "../../../../../../models/patient-medical-history-dtos/put-patient-medical-history-family-member-disease-dto";
import { FamilyMember } from "../../../../../../models/family-member";
import { FamilyMemberDisease } from "../../../../../../models/family-member-disease";
import { PutPatientMedicalHistoryDiabetesComplicationDTO } from "../../../../../../models/patient-medical-history-dtos/put-patient-medical-history-diabetes-complication-dto";
import { PutPatientMedicalHistoryNonBariatricSurgeryDTO } from "../../../../../../models/patient-medical-history-dtos/put-patient-medical-history-non-bariatric-surgery-dto";
import { usePatientProfileMedicalHistory } from "../../context/loadable-patient-profile-medical-history";

export type PatientProfileMedicalHistoryEditModalInputs = {
  chronicDiseases?: PutPatientMedicalHistoryChronicDiseaseDTO[];
  diabetesEmergencies?: PutPatientMedicalHistoryDiabetesEmergencyDTO[];
  familyMemberDiseases?: PutPatientMedicalHistoryFamilyMemberDiseaseDTO[];
  familyMemberDiseasesOther?: PutPatientMedicalHistoryFamilyMemberDiseaseDTO;
  diabetesComplications?: PutPatientMedicalHistoryDiabetesComplicationDTO[];
  nonBariatricSurgeries?: PutPatientMedicalHistoryNonBariatricSurgeryDTO[];
  nonBariatricSurgeriesOther?: PutPatientMedicalHistoryNonBariatricSurgeryDTO;
};

type TransformedFamilyMemberDiseases = {
  familyMemberDisease: FamilyMemberDisease;
  familyMember: FamilyMember;
};

export const PatientProfileMedicalHistoryEditModal = () => {
  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  const { patientMedicalHistoryDTO } = usePatientProfileMedicalHistory();

  const chronicDiseases = patientMedicalHistoryDTO.chronicDiseases;
  const diabetesEmergencies = patientMedicalHistoryDTO.diabetesEmergencies;
  const familyMemberDiseases = patientMedicalHistoryDTO.familyMemberDiseases;
  const diabetesComplications = patientMedicalHistoryDTO.diabetesComplications;
  const nonBariatricSurgeries = patientMedicalHistoryDTO.nonBariatricSurgeries;

  const transformFamilyData = useMemo((): TransformedFamilyMemberDiseases[] => {
    const transformedFamilyMemberDiseases: TransformedFamilyMemberDiseases[] =
      [];

    familyMemberDiseases.forEach((item) => {
      item.familyMembers.forEach((familyMember) => {
        transformedFamilyMemberDiseases.push({
          familyMemberDisease: item.familyMemberDisease,
          familyMember: familyMember,
        });
      });
    });

    return transformedFamilyMemberDiseases;
  }, [familyMemberDiseases]);

  const methods = useForm<PatientProfileMedicalHistoryEditModalInputs>({
    defaultValues: {
      chronicDiseases:
        chronicDiseases !== undefined ? chronicDiseases : undefined,
      diabetesEmergencies:
        diabetesEmergencies !== undefined ? diabetesEmergencies : undefined,
      familyMemberDiseases:
        transformFamilyData !== undefined
          ? transformFamilyData
              .filter((item) => item.familyMemberDisease !== "Other")
              .map((familyMemberDisease) => ({
                familyMemberDisease: familyMemberDisease.familyMemberDisease,
                familyMember: familyMemberDisease.familyMember,
              }))
          : undefined,
      familyMemberDiseasesOther:
        familyMemberDiseases !== undefined
          ? familyMemberDiseases.filter(
              (item) => item.familyMemberDisease === "Other"
            )[0]
          : undefined,
      diabetesComplications:
        diabetesComplications !== undefined ? diabetesComplications : undefined,
      nonBariatricSurgeries:
        nonBariatricSurgeries !== undefined
          ? nonBariatricSurgeries.filter((item) => item.surgery !== "Other")
          : undefined,
      nonBariatricSurgeriesOther:
        nonBariatricSurgeries !== undefined
          ? nonBariatricSurgeries.filter((item) => item.surgery === "Other")[0]
          : undefined,
    },
  });

  return (
    <>
      <Button outlined onClick={openModal} flex>
        <img src="/img/edit.svg" alt="Edit" /> Edit
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FormProvider {...methods}>
          <ProfileMedicalHistoryEditModalBody
            key={modalBodyKey}
            onClose={closeModal}
          />
        </FormProvider>
      </Modal>
    </>
  );
};
