import { useCallback } from "react";
import { ContentModuleDTO } from "../../../../../models/content-module-dtos/content-module-dto";
import { useModalHelpers } from "../../../../../components/modal/use-modal-helpers";
import { useDeleteContentModule } from "../../../../../hooks/content-module-hooks/use-delete-content-module";
import { useToastService } from "../../../../../context/toast-service-context";
import { useReloadContent } from "../../../context/loadable-content-context";
import { GenericActionsListItemItem } from "../../../../../components/generic-actions-list/styled-generic-actions-list";
import { Modal } from "../../../../../components/modal/modal";
import { DeleteModal } from "../../../../../components/delete-modal/delete-modal";

type DeleteContentCourseModalProps = {
  contentModule: ContentModuleDTO;
};

export const DeleteContentCourseModal = (
  props: DeleteContentCourseModalProps
) => {
  const { contentModule } = props;

  const doesCourseHasChildModules =
    contentModule.childContentModules !== undefined &&
    contentModule.childContentModules.length > 0;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  const deleteContentModule = useDeleteContentModule();
  const { showToast } = useToastService();
  const reloadCourses = useReloadContent();

  const onDelete = useCallback(async () => {
    if (!doesCourseHasChildModules) {
      try {
        await deleteContentModule(contentModule.id);

        showToast("Success", "Course Deleted Successfully!");
        closeModal();
        reloadCourses();
      } catch (error) {
        showToast("Error", "Failed to Delete Course :(");
      }
    }
  }, [
    contentModule.id,
    doesCourseHasChildModules,
    closeModal,
    deleteContentModule,
    reloadCourses,
    showToast,
  ]);

  return (
    <>
      <GenericActionsListItemItem isDanger onClick={openModal}>
        Delete
      </GenericActionsListItemItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <DeleteModal
          key={modalBodyKey}
          title={`Course ${contentModule.name}`}
          onDelete={onDelete}
          onModalClose={closeModal}
          disableDelete={doesCourseHasChildModules}
          subMessage={
            doesCourseHasChildModules
              ? `Unable to delete course, delete it's modules first`
              : ``
          }
        />
      </Modal>
    </>
  );
};
