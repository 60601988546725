import styled from "styled-components/macro";
import {
  flexCenterCenter,
  mediumFont,
} from "../../../../../styles/classes/gloabl-classes";

export const LabResultsFormFooterContainer = styled.div`
  margin-top: auto;
  padding: 24px 16px 16px;
  ${flexCenterCenter}
  gap: 0px 32px;

  button {
    border-radius: 14px;
  }
`;

export const LabResultsButton = styled.button<{ danger?: true }>`
  border-radius: 10px;
  ${flexCenterCenter}
  gap: 0rem 0.875rem;
  padding: 12px 24px;
  ${mediumFont}
  background: ${({ danger, theme }) =>
    danger
      ? theme.newColors.dangerBtnBgTransparent
      : theme.newColors.primaryBtnBgTransparent};
  color: ${({ theme, danger }) =>
    danger ? theme.colors.dangerText : theme.colors.primaryColor};

  :disabled {
    background-color: #fafbfc;
    color: #7f8aa3;
    pointer-events: none;
  }
`;
