import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileTagsLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileMedicalHistoryEditModalInputs } from "../../profile-medical-history-edit-modal";
import { Dropdown } from "../../../../../../../../components/dropdown/dropdown";
import { DropdownListItem } from "../../../../../../../../components/dropdown/styled-dropdown";
import {
  PateintProfileFamilyMemberDiseasesFormControlBody,
  PateintProfileFamilyMemberDiseasesFormControlDropdownsContainer,
} from "./styled-pateint-profile-family-member-diseases-form-control";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";
import {
  FAMILY_MEMBERS,
  FamilyMember,
} from "../../../../../../../../models/family-member";
import {
  FAMILY_MEMBER_DISEASES,
  FamilyMemberDisease,
} from "../../../../../../../../models/family-member-disease";
import { viewFamilyMemberDisease } from "../../../../../utils/patient-profile-utils";

export const PateintProfileFamilyMemberDiseasesFormControl = () => {
  const { control, watch } = useFormContext<
    PatientProfileMedicalHistoryEditModalInputs
  >();

  const { fields, append, remove } = useFieldArray({
    name: "familyMemberDiseases",
    control,
  });

  const familyMemberDiseases = watch("familyMemberDiseases");

  return (
    <PatientProfileModalFormControl>
      <PatientProfileTagsLabelContainer>
        <label>Family Disease</label>
      </PatientProfileTagsLabelContainer>
      <PateintProfileFamilyMemberDiseasesFormControlBody
        isNotEmptyFamilyMemberDiseases={
          familyMemberDiseases !== undefined && familyMemberDiseases.length > 0
        }
      >
        {fields.map((field, index) => (
          <PatientProfileModalInputContainer key={field.id}>
            <PateintProfileFamilyMemberDiseasesFormControlDropdownsContainer>
              <Button outlineNoBorder type="button">
                <img
                  src="/img/trash.svg"
                  alt="Trash Icon"
                  onClick={() => remove(index)}
                />
              </Button>
              <Controller
                name={`familyMemberDiseases.${index}.familyMemberDisease`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Dropdown
                      text={
                        value !== undefined &&
                        value !== ("" as FamilyMemberDisease)
                          ? viewFamilyMemberDisease(value)
                          : "Select Disease"
                      }
                      variant="designed-ux"
                      fullWidth
                    >
                      {FAMILY_MEMBER_DISEASES.slice(
                        0,
                        FAMILY_MEMBER_DISEASES.indexOf("Other")
                      ).map((familyMemberDisease) => (
                        <DropdownListItem
                          variant="designed-ux"
                          key={familyMemberDisease}
                          onClick={() => onChange(familyMemberDisease)}
                        >
                          {viewFamilyMemberDisease(familyMemberDisease)}
                        </DropdownListItem>
                      ))}
                    </Dropdown>
                  );
                }}
              />
              <Controller
                name={`familyMemberDiseases.${index}.familyMember`}
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Dropdown
                      text={
                        value !== undefined && value !== ("" as FamilyMember)
                          ? value
                          : "Select Family Member"
                      }
                      variant="designed-ux"
                      fullWidth
                    >
                      {FAMILY_MEMBERS.slice(
                        0,
                        FAMILY_MEMBERS.indexOf("Other")
                      ).map((familyMember) => (
                        <DropdownListItem
                          variant="designed-ux"
                          key={familyMember}
                          onClick={() => onChange(familyMember)}
                        >
                          {familyMember}
                        </DropdownListItem>
                      ))}
                    </Dropdown>
                  );
                }}
              />
            </PateintProfileFamilyMemberDiseasesFormControlDropdownsContainer>
          </PatientProfileModalInputContainer>
        ))}
        <Button
          type="button"
          width={100}
          height={40}
          flex
          outlineNoBorder
          onClick={() => {
            append({
              familyMember: "" as FamilyMember,
              familyMemberDisease: "" as FamilyMemberDisease,
            });
          }}
        >
          <span className="material-symbols-outlined">add</span> Add
        </Button>
      </PateintProfileFamilyMemberDiseasesFormControlBody>
    </PatientProfileModalFormControl>
  );
};
