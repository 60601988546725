import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

export type HomeStaticData = {
  id: number;
  mrn: string;
  patient: {
    id: number;
    name: string;
    age: number;
    y: string;
    gender: string;
  };
  diagnosis: {
    type: string;
    date: string;
  };
  alerts: string;
  alertsIcon: string;
  priority: string;
  visits: {
    next: string;
    last: string;
  };
  complications: string;
};

type ContextT = {
  filteredHomeStaticData: HomeStaticData[];
  setFilteredHomeStaticData: (filteredHomeStaticData: HomeStaticData[]) => void;
  homeStaticData: HomeStaticData[];
};

const HomeStaticDataContext = createContext<ContextT | undefined>(undefined);

type HomeStaticDataProviderProps = PropsWithChildren<{}>;

export const HomeStaticDataProvider = (props: HomeStaticDataProviderProps) => {
  const { children } = props;

  const homeStaticData: HomeStaticData[] = useMemo(
    () => [
      {
        id: 83,
        mrn: "16985",
        patient: {
          id: 83,
          name: "Ahmed Hamed",
          age: 50,
          y: "Y",
          gender: "Male",
        },
        diagnosis: {
          type: "Type 2 Diabetes",
          date: "since 2013 (11 Y)",
        },
        alerts: "Uncontrolled BG 80% of time",
        alertsIcon: "readings",
        priority: "High",
        visits: {
          next: "1/8/2024 (5 Days)",
          last: "30/4/2024 (85 Days)",
        },
        complications: "Neuropathy",
      },
      {
        id: 1,
        mrn: "14132",
        patient: {
          id: 1,
          name: "Mohamed Tarek",
          age: 61,
          y: "Y",
          gender: "Male",
        },
        diagnosis: {
          type: "Type 2 Diabetes, Obesity",
          date: "since 2014 (10 Y)",
        },
        alerts: "2 attacks of hypoglycemia in the past 2 weeks",
        alertsIcon: "readings",
        priority: "High",
        visits: {
          next: "1/8/2024 (5 Days)",
          last: "30/4/2024 (85 Days)",
        },
        complications: "Retinopathy",
      },
      {
        id: 2,
        mrn: "14132",
        patient: {
          id: 2,
          name: "Mostafa Ahmed",
          age: 65,
          y: "Y",
          gender: "Male",
        },
        diagnosis: {
          type: "Type 1 Diabetes",
          date: "since 1970 (60 Y)",
        },
        alerts: "Symptom: numbness in fingers or toes",
        alertsIcon: "symptoms",
        priority: "High",
        visits: {
          next: "1/9/2024 (20 Days)",
          last: "20/5/2024 (65 Days)",
        },
        complications: "Diabetic foot",
      },
      {
        id: 3,
        mrn: "14132",
        patient: {
          id: 3,
          name: "Yasmine Ahmed",
          age: 55,
          y: "Y",
          gender: "Female",
        },
        diagnosis: {
          type: "HTN",
          date: "since 2014 (10 Y)",
        },
        alerts: "Symptom: blurred vision",
        alertsIcon: "symptoms",
        priority: "High",
        visits: {
          next: "15/8/2024 (20 Days)",
          last: "30/4/2024 (85 Days)",
        },
        complications: "-",
      },
      {
        id: 4,
        mrn: "14132",
        patient: {
          id: 4,
          name: "Hamoud Abdullah",
          age: 53,
          y: "Y",
          gender: "Male",
        },
        diagnosis: {
          type: "Type 2 Diabetes",
          date: "since 2014 (10 Y)",
        },
        alerts: "Poor medication adherence",
        alertsIcon: "behavioral",
        priority: "Medium",
        visits: {
          next: "1/8/2024 (5 Days)",
          last: "30/4/2024 (85 Days)",
        },
        complications: "-",
      },
      {
        id: 5,
        mrn: "14132",
        patient: {
          id: 5,
          name: "Rana Badr diagnosis",
          age: 58,
          y: "Y",
          gender: "Female",
        },
        diagnosis: {
          type: "Type 2 Diabetes",
          date: "since 2014 (10 Y)",
        },
        alerts: "BMI has increased to over 30",
        alertsIcon: "diagnosis",
        priority: "Medium",
        visits: {
          next: "1/8/2024 (5 Days)",
          last: "30/4/2024 (85 Days)",
        },
        complications: "CVD",
      },
      {
        id: 6,
        mrn: "14139",
        patient: {
          id: 6,
          name: "Salman Aldossary",
          age: 45,
          y: "Y",
          gender: "Male",
        },
        diagnosis: {
          type: "Type 1 Diabetes",
          date: "since 2014 (10 Y)",
        },
        alerts: "Missing BG for 3 days",
        alertsIcon: "behavioral",
        priority: "Low",
        visits: {
          next: "1/8/2024 (5 Days)",
          last: "30/4/2024 (85 Days)",
        },
        complications: "-",
      },
      {
        id: 7,
        mrn: "14132",
        patient: {
          id: 7,
          name: "Belal Ahmed",
          age: 49,
          y: "Y",
          gender: "Male",
        },
        diagnosis: {
          type: "Type 2 Diabetes",
          date: "since 2014 (10 Y)",
        },
        alerts: "Missing food logging for 3 days",
        alertsIcon: "behavioral",
        priority: "Low",
        visits: {
          next: "1/8/2024 (5 Days)",
          last: "30/4/2024 (85 Days)",
        },
        complications: "-",
      },
      {
        id: 8,
        mrn: "14132",
        patient: {
          id: 8,
          name: "Fatima Mohamed",
          age: 47,
          y: "Y",
          gender: "Female",
        },
        diagnosis: {
          type: "Type 2 Diabetes",
          date: "since 2014 (10 Y)",
        },
        alerts: "Missing BG for 3 days",
        alertsIcon: "behavioral",
        priority: "Low",
        visits: {
          next: "10/8/2024 (15 Days)",
          last: "25/5/2024 (70 Days)",
        },
        complications: "-",
      },
    ],
    []
  );

  const [filteredHomeStaticData, setFilteredHomeStaticData] = useState<
    HomeStaticData[]
  >(homeStaticData);

  const value = useMemo(
    () => ({
      filteredHomeStaticData,
      setFilteredHomeStaticData,
      homeStaticData,
    }),
    [filteredHomeStaticData, setFilteredHomeStaticData, homeStaticData]
  );

  return (
    <HomeStaticDataContext.Provider value={value}>
      {children}
    </HomeStaticDataContext.Provider>
  );
};

export const useHomeStaticData = () => {
  const context = useContext(HomeStaticDataContext);

  if (context === undefined) {
    throw new Error(
      "useHomeStaticData must be used within a HomeStaticDataProvider"
    );
  }
  return context;
};
