import { useFormContext } from "react-hook-form";
import { LabResultsCard } from "../../../../../lab-results/lab-results-card/lab-results-card";
import { useGetGroupedLabTestResultsByTypeAndDate } from "../../utils/feedback-utils";
import { EmptyFeedbackModal } from "../feedback-modal-body/empty-feedback-modal";
import {
  ChartButton,
  ChartsContainerTitle,
} from "../feedback-modal-body/styled-feedback-modal-body";
import { CustomFeedbackFormInputs } from "./custom-feedback-container";
import { useDetailedPlan } from "../../../../context/loadable-detailed-plan-context";

type CustomFeedbackLabCardsProps = {
  temporalStartDate: string;
  temporalEndDate: string;
};

export const CustomFeedbackLabCards = (props: CustomFeedbackLabCardsProps) => {
  const { temporalStartDate, temporalEndDate } = props;

  const {
    labTestTypeRefDTOs,
    labTestTypeDTOs,
    patientLabTestResultsDTOs,
  } = useDetailedPlan();

  const { watch } = useFormContext<CustomFeedbackFormInputs>();
  const labTestTypeId = watch("labTestTypeId");

  const groupedLabTestResultsByType = useGetGroupedLabTestResultsByTypeAndDate(
    labTestTypeId!,
    temporalStartDate,
    temporalEndDate,
    patientLabTestResultsDTOs,
    labTestTypeDTOs
  );

  const cardButton = () => {
    return (
      <ChartButton disabled={true} added={true} type="button">
        <span className="material-symbols-outlined">done</span>
      </ChartButton>
    );
  };

  const labResultsCards = groupedLabTestResultsByType.map(
    (labTestGroupedResults) => (
      <LabResultsCard
        key={labTestGroupedResults?.labTestTypeDTO.id}
        labTestTypeRefDTOs={labTestTypeRefDTOs}
        labTestTypeDTO={labTestGroupedResults.labTestTypeDTO}
        labTest={labTestGroupedResults.sortedLabTestResultsByDate}
        modalCard={{
          show: true,
          button: cardButton,
        }}
      />
    )
  );

  return (
    <>
      {groupedLabTestResultsByType.length === 0 ? (
        <EmptyFeedbackModal
          customFeedback
          subText="Select the feedback to show it's details here"
        />
      ) : (
        <>
          <ChartsContainerTitle>
            Select attached lab result
          </ChartsContainerTitle>
          {labResultsCards}
        </>
      )}
    </>
  );
};
