import { Controller, useFormContext } from "react-hook-form";
import { BloodGlucoseFormInputs } from "../../plan-steps/feedback/feedback-modal/blood-glucose-modal-body/blood-glucose-container";
import { CustomFeedbackFormInputs } from "../../plan-steps/feedback/feedback-modal/custom-feedback/custom-feedback-container";
import { MealGraphsModalInputs } from "../../plan-steps/feedback/feedback-modal/meal-graphs-modal/meal-graphs-container";
import { WeightLossModalFormInputs } from "../../plan-steps/feedback/feedback-modal/weight-loss-modal/weight-loss-modal-container";
import { PlansDateInput } from "../plans-date-input/plans-date-input";
import { PlansDropdown } from "../plans-dropdown/plans-dropdown";
import { PlansNotesInput } from "../plans-notes-input/plans-notes-input";
import { PlansFormInputs } from "./styled-plans-form";
import { ManualReadingsFormInputs } from "../../plan-steps/feedback/feedback-modal/manual-readings-modal/manual-readings-modal-container";
import { PlansInputLabel } from "../../plan-steps/styles";
import { PlansNotesInputContainer } from "../plans-notes-input/styled-plans-notes-input";
import { ManualReadingsModalTagsFilterContainer } from "../../plan-steps/feedback/feedback-modal/manual-readings-modal/styled-manual-readings-modal";
import { TagDropdown } from "../../../bgl-analysis/upload-options/manual-readings/manual-readings-modal/manual-readings-table/tag-dropdown/tag-dropdown";

type PlansFormProps = {
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date) => void;
  customFeedbackForm?: boolean;
  manualReadingsFeedbackForm?: {
    state: boolean;
    tagValue: string;
    setTagValue: (tagValue: string) => void;
  };
};

export const PlansForm = (props: PlansFormProps) => {
  const {
    setEndDate,
    setStartDate,
    customFeedbackForm,
    manualReadingsFeedbackForm,
  } = props;

  const { control } = useFormContext<
    | BloodGlucoseFormInputs
    | MealGraphsModalInputs
    | WeightLossModalFormInputs
    | CustomFeedbackFormInputs
    | ManualReadingsFormInputs
  >();

  return (
    <PlansFormInputs>
      {customFeedbackForm !== undefined ? (
        <Controller
          name="labTestTypeId"
          control={control}
          render={({ field: { onChange } }) => (
            <PlansDropdown
              label="Feedback"
              onChange={onChange}
              customFeedbackForm={customFeedbackForm}
            />
          )}
        />
      ) : (
        <></>
      )}
      <Controller
        name="feedbackTone"
        control={control}
        render={({ field: { onChange } }) => (
          <PlansDropdown label="Feedback type" onChange={onChange} />
        )}
      />
      <Controller
        name="notes"
        control={control}
        render={({ field: { value, onChange } }) => (
          <PlansNotesInput value={value} onChange={onChange} />
        )}
      />
      {manualReadingsFeedbackForm !== undefined &&
      manualReadingsFeedbackForm.state === true ? (
        <PlansNotesInputContainer>
          <PlansInputLabel>Tags</PlansInputLabel>
          <ManualReadingsModalTagsFilterContainer>
            <TagDropdown
              value={undefined}
              onChange={() => {}}
              filter={{
                onChange: manualReadingsFeedbackForm.setTagValue,
                value: manualReadingsFeedbackForm.tagValue,
              }}
              feedbackFilter
            />
          </ManualReadingsModalTagsFilterContainer>
        </PlansNotesInputContainer>
      ) : (
        <></>
      )}
      <PlansDateInput
        label="Graph Date"
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    </PlansFormInputs>
  );
};
