import styled from "styled-components/macro";
import { GenericActionsListList } from "../../../../components/generic-actions-list/styled-generic-actions-list";
import {
  TableContainer,
  TablePaginationContainerHeight,
} from "../../../../components/table/styled-table";
import { NavbarHeight } from "../../../../styles/global-style";

export const ImmediateAttentionPatientsTableContainer = styled.div`
  padding: 16px 8px;
  background: ${({ theme }) => theme.colors.containerColor};

  ${TableContainer} {
    padding-bottom: ${TablePaginationContainerHeight};
    height: calc(
      100vh -
        (
          ${NavbarHeight} + 106px + 108px + ${TablePaginationContainerHeight} +
            64px
        )
    );
    overflow-y: auto;

    @media screen and (max-width: 1300px) {
      width: 1440px;
      overflow-x: auto;
    }
  }

  thead th {
    padding-bottom: 16px;
  }

  table {
    margin-top: 0px;

    tbody {
      tr {
        div {
          padding: 10px 0px;
        }
      }
    }
  }
`;

export const ImmediateAttentionPatientsTableTableContainer = styled.div`
  width: 100%;

  ${GenericActionsListList} {
    right: 0px;
    left: unset;
  }
`;
