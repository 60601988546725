import { useCallback, useState } from "react";
import { Temporal } from "temporal-polyfill";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import { PlansForm } from "../../../../components/plans-form/plans-form";
import { BloodGlucoseCharts } from "./blood-glucose-charts/blood-glucose-charts";
import { PatientPlanFeedbackTone } from "../../../../../../../models/patient-plan-dtos/patient-plan-feedback-dto";
import { useFormContext } from "react-hook-form";
import { useUpdatePatientPlan } from "../../../../../../../hooks/patient-plan-hooks/use-update-patient-plan";
import { UpdatePatientPlanDTO } from "../../../../../../../models/patient-plan-dtos/update-patient-plan-dto";
import { FeedbackModalModalContainer } from "../feedback-modal-body/styled-feedback-modal-body";
import { EmptyFeedbackModal } from "../feedback-modal-body/empty-feedback-modal";
import {
  useDetailedPlan,
  useReloadDetailedPlan,
} from "../../../../context/loadable-detailed-plan-context";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { BloodGlucoseFormInputs } from "./blood-glucose-container";
import { useBuildDayAggregates } from "../../../../../../../components/cgm-google-chart/hooks/use-build-day-aggregates";

type BloodGlucoseModalBodyProps = {
  resetCurrentFeedbackModal: () => void;
};

export const BloodGlucoseModalBody = (props: BloodGlucoseModalBodyProps) => {
  const { resetCurrentFeedbackModal } = props;

  const reloadPatientDetailedPlan = useReloadDetailedPlan();
  const { watch, setValue, handleSubmit } =
    useFormContext<BloodGlucoseFormInputs>();
  const updatePatientPlan = useUpdatePatientPlan();

  const { patientDetailedPlanDTO, patientDTO } = useDetailedPlan();
  const { showToast } = useToastService();

  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const temporalStartDate = Temporal.PlainDate.from(
    startDate.toISOString().split("T")[0]
  ).add({ days: 1 });
  const temporalEndDate = Temporal.PlainDate.from(
    endDate.toISOString().split("T")[0]
  ).add({ days: 1 });

  const feedbackDayAggregates = true;

  const { filterDaysAggregatesBetweenStartDateAndEndDate } =
    useBuildDayAggregates(patientDTO, feedbackDayAggregates);

  const daysAggregates = filterDaysAggregatesBetweenStartDateAndEndDate(
    temporalStartDate.toString(),
    temporalEndDate.toString()
  );

  const feedbackTone: PatientPlanFeedbackTone | undefined =
    watch("feedbackTone");
  const notes: string | undefined = watch("notes");
  const bloodGlucoseAttachments = watch("attachments");

  const onSubmit = handleSubmit(async () => {
    const updateDTO: UpdatePatientPlanDTO = {
      ...patientDetailedPlanDTO,
      patientPlanFeedbacks: [
        ...patientDetailedPlanDTO.patientPlanFeedbacks,
        {
          feedbackType: "BloodGlucose",
          feedbackTone: feedbackTone!,
          notes,
          attachments: bloodGlucoseAttachments,
        },
      ],
    };

    updatePatientPlan(patientDetailedPlanDTO.patientPlan, updateDTO)
      .then(() => {
        showToast("Success", "Feedback added successfully");
        reloadPatientDetailedPlan();
      })
      .catch(() => showToast("Error", "Failed to add feedback :("));
  });

  const notesCondition = notes === "" || notes === undefined;
  const modalDisabledCondition =
    bloodGlucoseAttachments.length === 0 ||
    feedbackTone === undefined ||
    notesCondition;

  const handleFormSetStartDate = useCallback(
    (startDate: Date) => {
      setValue("attachments", []);
      setStartDate(startDate);
    },
    [setValue]
  );

  const handleFormSetEndDate = useCallback(
    (endDate: Date) => {
      setValue("attachments", []);
      setEndDate(endDate);
    },
    [setValue]
  );

  return (
    <ModalForm width={900} height={750} onSubmit={onSubmit}>
      <ModalHeader
        onModalClose={resetCurrentFeedbackModal}
        title="Add Feedback - Blood glucose"
        subTitle="Complete the info related to Blood glucose  for the patient"
      />
      <FeedbackModalModalContainer>
        <PlansForm
          setStartDate={handleFormSetStartDate}
          setEndDate={handleFormSetEndDate}
        />
        {daysAggregates.length === 0 ? (
          <EmptyFeedbackModal subText="Select start and end date from Graph date to show blood glucose graphs" />
        ) : (
          <BloodGlucoseCharts daysAggregates={daysAggregates} />
        )}
      </FeedbackModalModalContainer>
      <ModalFooter>
        <Button type="button" outlined onClick={resetCurrentFeedbackModal}>
          Cancel
        </Button>
        <Button type="submit" disabled={modalDisabledCondition}>
          Add
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
