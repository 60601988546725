import { useImportCronometerServings } from "../../../../hooks/use-import-cronometer-servings";
import { alertAndReload } from "../../../../utils/alert-and-reload";
import { useApiWithFullPageLoader } from "../../../../hooks/use-api-with-full-page-loader";
import { useToastService } from "../../../../context/toast-service-context";
import { useParams } from "react-router-dom";
import { DropdownListItemFileUpload } from "./dropdown-list-item-file-upload";

export function ImportCronometer() {
  const { id } = useParams();
  const patientID = parseInt(id!);

  const importCronometerServings = useApiWithFullPageLoader(
    useImportCronometerServings()
  );

  const { showToast } = useToastService();

  async function handleFileSelect(cronometerServingsCSV: string) {
    try {
      await importCronometerServings(patientID, cronometerServingsCSV);
      showToast("Success", "Cronometer servings file imported successfully!");
      alertAndReload("Cronometer servings file imported successfully!");
    } catch (e) {
      alert("Failed to import the Cronometer servings file :(");
    }
  }

  return (
    <DropdownListItemFileUpload onFileSelect={handleFileSelect}>
      Cronometer
    </DropdownListItemFileUpload>
  );
}
