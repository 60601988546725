import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { CarbSourceDTO } from "../../models/carb-sources/carb-source-dto";

export function useGetCarbSources(): () => Promise<CarbSourceDTO[]> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<CarbSourceDTO[]>(
      `${BASE_PATH}/carb-sources`
    );

    return response.data;
  }, [client]);
}
