import {
  VideoModalCloseIcon,
  VideoModalContainer,
} from "./styled-video-modal-body";

type VideoModalBodyProps = {
  onClose: () => void;
  videoURL: string;
  viewPlansModal?: boolean;
};

export const VideoModalBody = (props: VideoModalBodyProps) => {
  const { onClose, videoURL, viewPlansModal } = props;

  return (
    <VideoModalContainer viewPlansModal={viewPlansModal}>
      <VideoModalCloseIcon
        className="material-symbols-outlined"
        onClick={onClose}
      >
        close
      </VideoModalCloseIcon>

      <iframe
        src={videoURL}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </VideoModalContainer>
  );
};
