import { useParams } from "react-router-dom";
import { useModalHelpers } from "../../../../../../../components/modal/use-modal-helpers";
import { useMemo } from "react";
import { PatientMealImagesModalBody } from "./patient-meal-images-modal-body";
import { Modal } from "../../../../../../../components/modal/modal";
import { useGetPatientMealDTOs } from "../../../../../../../components/cgm-google-chart/hooks/use-get-patient-meal-dtos";

export const PatientMealImagesModal = () => {
  const { modalBodyKey, closeModal } = useModalHelpers();
  const { patientMealDTOs } = useGetPatientMealDTOs();

  const { patientMealId } = useParams();

  const findSelectedPatientMeal = useMemo(
    () => patientMealDTOs.find((meal) => meal.id === parseInt(patientMealId!)),
    [patientMealDTOs, patientMealId]
  );

  return (
    <Modal handleHideModal={closeModal} showModal>
      <PatientMealImagesModalBody
        key={modalBodyKey}
        patientMealImageDTOs={findSelectedPatientMeal?.patientMealImages!}
      />
    </Modal>
  );
};
