import { ContentModuleCardPartDTO } from "../../../../../models/content-module-dtos/content-module-card-part-dto";
import { ContentModuleLessonMobilePreviewCardPartsListItem } from "../mobile-preview-lesson-cards-styles";

type ContentModuleLessonMobilePreviewCardPartsProps = {
  cardParts: ContentModuleCardPartDTO[];
};

export const ContentModuleLessonMobilePreviewCardParts = (
  props: ContentModuleLessonMobilePreviewCardPartsProps
) => {
  const { cardParts } = props;

  return (
    <ul>
      {cardParts.map((cardPart, index) => (
        <ContentModuleLessonMobilePreviewCardPartsListItem key={index}>
          {cardPart.contentModuleCardPartType === "Text" && (
            <p dir="auto">{cardPart.text}</p>
          )}
          {cardPart.contentModuleCardPartType === "Image" && (
            <img src={cardPart.imageUploadedFile?.presignedUrl} alt="" />
          )}
          {cardPart.contentModuleCardPartType === "Video" && (
            <video
              src={cardPart.videoUploadedFile?.presignedUrl}
              controls
            ></video>
          )}
        </ContentModuleLessonMobilePreviewCardPartsListItem>
      ))}
    </ul>
  );
};
