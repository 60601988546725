import { Controller, useFormContext } from "react-hook-form";
import { FoodLoggingSelectedPatientMealContainerTitle } from "../../styled-food-logging-selected-patient-meal";
import {
  FoodLoggingSelectedPatientMealFeedbackContainer,
  FoodLoggingSelectedPatientMealFeedbackInputContainer,
} from "./styled-food-logging-selected-patient-meal-feedback";
import { FoodLoggingSelectedPatientMealInputs } from "../../food-logging-selected-patient-meal";

export const FoodLoggingSelectedPatientMealFeedback = () => {
  const { control } = useFormContext<FoodLoggingSelectedPatientMealInputs>();

  return (
    <FoodLoggingSelectedPatientMealFeedbackContainer>
      <FoodLoggingSelectedPatientMealContainerTitle>
        Meal Feedback (Optional)
      </FoodLoggingSelectedPatientMealContainerTitle>
      <FoodLoggingSelectedPatientMealFeedbackInputContainer>
        <Controller
          control={control}
          name="feedback"
          render={({ field: { value, onChange } }) => (
            <input
              value={value !== undefined ? value : ""}
              onChange={(e) =>
                onChange(e.target.value !== undefined ? e.target.value : "")
              }
              type="text"
              placeholder="Enter your feedback"
            />
          )}
        />
      </FoodLoggingSelectedPatientMealFeedbackInputContainer>
    </FoodLoggingSelectedPatientMealFeedbackContainer>
  );
};
