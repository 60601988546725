import styled from "styled-components/macro";
import {
  Button,
  InputContainer,
  InputsGroup,
} from "../../../styles/classes/reusable-classes";
import {
  flexColCenterCenter,
  regularSemiBoldFont,
} from "../../../styles/classes/gloabl-classes";

export const LoginWithEmailAndPasswordContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  ${flexColCenterCenter}
`;

export const LoginWithEmailAndPasswordOrText = styled.span`
  color: ${({ theme }) => theme.colors.textColor};
  ${regularSemiBoldFont}
  position: relative;

  ::before {
    content: "";
    width: 180px;
    border-radius: 8px;
    height: 2px;
    background: ${({ theme }) => theme.colors.textColor};
    opacity: 0.3;
    position: absolute;
    top: 50%;
    right: 24px;
  }

  ::after {
    content: "";
    width: 180px;
    border-radius: 8px;
    height: 2px;
    background: ${({ theme }) => theme.colors.textColor};
    opacity: 0.3;
    position: absolute;
    margin-left: 8px;
    top: 50%;
  }
`;

export const LoginWithEmailAndPasswordForm = styled.div`
  width: 90%;

  ${InputsGroup} {
    ${flexColCenterCenter}
    gap: 8px;
    margin-bottom: 24px;

    ${InputContainer} {
      width: 100%;

      :first-child {
        margin-top: 0px;
      }
    }
  }

  ${Button} {
    margin: 0 auto;
    width: 100%;
  }
`;
