import { roundTo1DecimalPlace } from "../../../../../../utils/math-utils";
import { PatientNutritionReportDailySummaryDayDTOWithId } from "../create-patient-nutrition-report-daily-summary-day-dto-with-ids";
import { getSelectedDaysCount } from "./get-selected-days-count";

export function calculateCarbSourcesTotalsAndAverages(
  selectedDays: PatientNutritionReportDailySummaryDayDTOWithId[]
) {
  const daysCarbSources = selectedDays.map((item) => item.carbSources);

  let sweetsSum = 0;
  let sweetsAvg = 0;

  for (const daysCarbSource of daysCarbSources) {
    for (const dayCarbSource of daysCarbSource) {
      if (dayCarbSource.carbSource.id === 73) {
        sweetsSum += dayCarbSource.totalCount;
        sweetsAvg = sweetsSum / getSelectedDaysCount(selectedDays);
      }
    }
  }

  return {
    sweetsSum,
    sweetsAvg: roundTo1DecimalPlace(sweetsAvg),
  };
}
