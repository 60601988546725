import { FormProvider, useForm } from "react-hook-form";
import { FrequencyDTO } from "../../../../../models/frequencies/frequency-dto";
import { PrescriptionsProvider } from "../context/prescriptions-context";
import { NewPrescriptionForm } from "./new-prescription-form";

export type PrescriptionFormInputs = {
  quantity?: number;
  durationDays: number;
  notes?: string;
  frequency?: FrequencyDTO;
};

export const NewPrescription = () => {
  const prescriptionFormDefaultValues: PrescriptionFormInputs = {
    quantity: undefined,
    durationDays: 1,
    notes: undefined,
    frequency: undefined,
  };

  const methods = useForm<PrescriptionFormInputs>({
    defaultValues: prescriptionFormDefaultValues,
  });

  return (
    <FormProvider {...methods}>
      <PrescriptionsProvider>
        <NewPrescriptionForm />
      </PrescriptionsProvider>
    </FormProvider>
  );
};
