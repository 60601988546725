import { PatientActivityLogDTO } from "../../../../../../models/patient-activity-log-dtos/patient-activity-log-dto";
import {
  ToolTipBody,
  TooltipContainer,
  TooltipHeader,
  TooltipWrapper,
} from "../../../../../../styles/classes/tooltips";
import {
  PatientProfileLifeActivitiesTooltipBodyList,
  PatientProfileLifeActivitiesTooltipBodyListItem,
} from "./styled-patient-profile-life-activities-tooltip";

export type PatientProfileLifeActivitiesTooltipProps = {
  data?: PatientActivityLogDTO[];
  activityModeName: string;
};

export const PatientProfileLifeActivitiesTooltip = (
  props: PatientProfileLifeActivitiesTooltipProps
) => {
  const { data, activityModeName } = props;

  return (
    <TooltipWrapper translateToolTip={"translateX(-50%)"}>
      <TooltipContainer>
        <TooltipHeader>
          <p>{activityModeName}</p>
        </TooltipHeader>
        <ToolTipBody>
          <PatientProfileLifeActivitiesTooltipBodyList>
            {data !== undefined
              ? activityModeName !== "Other Activity"
                ? data.map((item) => (
                    <PatientProfileLifeActivitiesTooltipBodyListItem
                      key={item.id}
                    >
                      <strong>{item.activity?.name}</strong>
                      <span>{item.durationMins} Mins</span>
                    </PatientProfileLifeActivitiesTooltipBodyListItem>
                  ))
                : data.map((item) => (
                    <PatientProfileLifeActivitiesTooltipBodyListItem
                      key={item.id}
                    >
                      <strong>{item.description}</strong>
                      <span>{item.durationMins} Mins</span>
                    </PatientProfileLifeActivitiesTooltipBodyListItem>
                  ))
              : ""}
          </PatientProfileLifeActivitiesTooltipBodyList>
        </ToolTipBody>
      </TooltipContainer>
    </TooltipWrapper>
  );
};
