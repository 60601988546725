import styled from "styled-components/macro";
import {
  flexCenter,
  smallRegularFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const LabResultLabResultTestResultsContainer = styled.div`
  overflow-x: auto;
  flex: 1;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    height: 7px;
  }
`;

export const LabResultTestResultsContainerHeader = styled.div`
  background: ${({ theme }) => theme.newColors.containerColorAlt};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  height: 84px;
  padding: ${({ theme }) => theme.padding.py_1};
  min-width: 100%;
  width: max-content;
  max-width: max-content;
`;

export const LabResultTestResultsContainerHeaderTitle = styled.p`
  margin: 0px;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.titleColor};
  transform: translateX(50%);
  left: 50%;
  top: 16px;
  position: absolute;
`;

export const LabResultTestResultsContainerHeaderDates = styled.div`
  display: grid;
  min-width: 100%;
  width: max-content;
  max-width: max-content;
  grid-template-columns: repeat(auto-fit, minmax(104px, 1fr));
  grid-auto-flow: column;
  margin-top: 32px;

  span {
    ${smallRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
    width: 104px;
  }
`;

export const LabResultTestResultsGridLayoutContainer = styled.div`
  min-width: 100%;
  width: max-content;
  max-width: max-content;
`;

export const LabResultTestResultsGridLayout = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(104px, 1fr));

  grid-auto-flow: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
`;

export const LabResultReadingContainer = styled.div`
  padding: ${({ theme }) => theme.padding.py_1};
  height: 76px;
  width: 104px;
  ${flexCenter}
`;

export const LabResultNumberContainer = styled.div`
  ${flexCenter}
`;

export const LabResultNumber = styled.p<{ latestResult: boolean }>`
  font-size: ${({ theme, latestResult }) =>
    latestResult ? "18px" : theme.typography.fontSize.mdFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  color: ${({ theme, latestResult }) =>
    latestResult ? theme.colors.titleColor : theme.colors.textColor};
  margin: 0px 0px 0px 0px;
`;
