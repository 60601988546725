import { PatientMealDTO } from "../../../../../../../../../models/patient-meal-dtos/patient-meal-dto";
import { FoodLoggingSelectedPatientMealImages } from "../../../../../../../../food-logging/food-logging-selected-patient-meal/components/food-logging-selected-patient-meal-images/food-logging-selected-patient-meal-images";
import { MealsAnalysisResponseTableCell } from "../styled-meals-analysis-response-table-cells";
import {
  MealsAnalysisResponseMediaTableCellVoiceNoteContainer,
  MealsAnalysisResponseMediaTableCellVoiceNotesContainer,
} from "./styled-meals-analysis-response-media-table-cell";

type MealsAnalysisResponseMediaTableCellProps = {
  patientMeal: PatientMealDTO;
};

export const MealsAnalysisResponseMediaTableCell = (
  props: MealsAnalysisResponseMediaTableCellProps
) => {
  const { patientMeal } = props;

  return (
    <MealsAnalysisResponseTableCell $mediaCell>
      {patientMeal.patientMealImages.length > 0 ? (
        <FoodLoggingSelectedPatientMealImages
          patientMealImageDTOs={patientMeal.patientMealImages}
          maxImagesDisplay={2}
        />
      ) : patientMeal.patientMealVoiceNotes.length > 0 ? (
        <MealsAnalysisResponseMediaTableCellVoiceNotesContainer>
          {patientMeal.patientMealVoiceNotes.map((item) => (
            <MealsAnalysisResponseMediaTableCellVoiceNoteContainer
              key={item.publicUrl}
            >
              <audio controls src={item.publicUrl} />
            </MealsAnalysisResponseMediaTableCellVoiceNoteContainer>
          ))}
        </MealsAnalysisResponseMediaTableCellVoiceNotesContainer>
      ) : patientMeal.note !== undefined ? (
        <span>{patientMeal.note}</span>
      ) : (
        <></>
      )}
    </MealsAnalysisResponseTableCell>
  );
};
