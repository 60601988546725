import styled from "styled-components/macro";

export const DoubleRangeInputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;

  .thumb {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 0;
    pointer-events: none;
    appearance: none;
    background: transparent;
  }

  .thumb::-webkit-slider-thumb {
    pointer-events: all;
    width: 15px;
    height: 15px;
    border: 2px solid ${({ theme }) => theme.colors.primaryColorAlt};
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.containerColor};
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
  }

  .thumb::-moz-range-thumb {
    pointer-events: all;
    width: 20px;
    height: 20px;
    border: 2px solid ${({ theme }) => theme.colors.primaryColorAlt};
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.containerColor};
    cursor: pointer;
  }

  .thumb-left {
    z-index: 3;
  }

  .thumb-right {
    z-index: 4;
  }
`;

export const LeftInputRangeContainer = styled.div<{
  $left: string;
  $content: number;
}>`
  ::before {
    content: ${({ $content }) => `'${$content}'`};
    position: absolute;
    top: -25px;
    left: ${({ $left }) => $left};
    z-index: 5;
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 12px;
    border-radius: 50%;
  }
`;

export const RightInputRangeContainer = styled.div<{
  $right: string;
  $content: number;
}>`
  ::before {
    content: ${({ $content }) => `'${$content}'`};
    position: absolute;
    top: -25px;
    right: ${({ $right }) => $right};
    z-index: 5;
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 12px;
    border-radius: 50%;
  }
`;

export const DoubleRangeInputSlider = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  top: 50%;
  transform: translateY(-50%);
`;

export const DoubleRangeInputTrack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.newColors.otidaO2};
  z-index: 1;
  border-radius: 3px;
`;

export const DoubleRangeInputRange = styled.div<{
  $left: string;
  $right: string;
}>`
  position: absolute;
  height: 100%;
  background: ${({ theme }) => theme.colors.primaryColorAlt};
  z-index: 2;
  border-radius: 3px;
  left: ${({ $left }) => $left};
  right: ${({ $right }) => $right};
`;
