import {
  ContentModuleFormControls,
  ContentModuleLeftFormControls,
  ContentModuleRightFormControls,
} from "../../../styles/content-modules-form-controllers/content-modules-form-controllers-css";
import { CreateModuleDescriptionController } from "./create-module-left-controllers/create-module-description-controller";
import { CreateModuleNameController } from "./create-module-left-controllers/create-module-name-controller";
import { CreateModuleObjectiveController } from "./create-module-left-controllers/create-module-objective-controller";
import { CreateModuleTargetAudienceController } from "./create-module-right-controllers/create-module-target-audience-controller";
import { CreateModuleThumbnailController } from "./create-module-right-controllers/create-module-thumbnail-controller";

export const CreateModuleControllers = () => {
  return (
    <ContentModuleFormControls $lessonsUI={false}>
      <ContentModuleLeftFormControls $lessonsUI={false}>
        <CreateModuleNameController />
        <CreateModuleDescriptionController />
        <CreateModuleObjectiveController />
      </ContentModuleLeftFormControls>
      <ContentModuleRightFormControls $lessonsUI={false}>
        <CreateModuleThumbnailController />
        <CreateModuleTargetAudienceController />
      </ContentModuleRightFormControls>
    </ContentModuleFormControls>
  );
};
