import { useCallback, useEffect, useState } from "react";
import { PatientDTO } from "../models/patient-dtos/patient-dto";
import { useGetPatient } from "./patient-hooks/use-get-patient";

type UseGetPatientDto = {
  patientDTO?: PatientDTO;
};

export function useGetPatientDto(patientID: number): UseGetPatientDto {
  const [patientDTO, setPatientDTO] = useState<PatientDTO>();

  const getPatient = useGetPatient();

  const fetchPatient = useCallback(
    async () =>
      await getPatient(patientID).then((response) => setPatientDTO(response)),
    [patientID, getPatient]
  );

  useEffect(() => {
    fetchPatient();
  }, [fetchPatient]);

  return { patientDTO };
}
