import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  mediumFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const ManualReadingsLegendContainer = styled.div`
  ${flexCenterSpaceBetween}
  gap: 0px 16px;
  padding: 8px 16px;
  border: 2px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 5px;
`;

export const ManualReadingsLegendPoint = styled.div<{
  variant: "Reading-InRange" | "Reading-OutOfRange" | "Insulin";
}>`
  ${flexCenter}

  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme, variant }) =>
      variant === "Reading-InRange"
        ? theme.newColors.bgmChartGreen
        : variant === "Reading-OutOfRange"
        ? theme.newColors.bgmChartRed
        : theme.colors.containerColor};
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    ${mediumFont}
    margin-left: 8px;
  }

  img {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
`;
