import { ToastData } from "../../context/toast-service-context";
import {
  ToastCard,
  ToastCenter,
  ToastCloseIcon,
  ToastHeader,
  ToastIcon,
  ToastLoader,
} from "./styled-toast";

export type SuccessOrError = "Success" | "Error";

type ToastProps = {
  successOrError: SuccessOrError;
  message: string;
  onCloseClick: () => void;
  toastList: ToastData[];
};

export function Toast(props: ToastProps) {
  const { successOrError, message, onCloseClick } = props;

  return (
    <ToastCard showToast={true}>
      <ToastHeader>
        <ToastIcon
          className="material-symbols-outlined"
          successOrError={successOrError}
        >
          {successOrError === "Success"
            ? "check_circle_outline"
            : "error_outline"}
        </ToastIcon>
        <p>{successOrError}</p>
        <ToastCloseIcon
          onClick={onCloseClick}
          className="material-symbols-outlined"
        >
          close
        </ToastCloseIcon>
      </ToastHeader>
      <ToastCenter>
        <p>{message}</p>
      </ToastCenter>
      <ToastLoader>
        <div />
      </ToastLoader>
    </ToastCard>
  );
}
