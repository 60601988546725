import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { AddGoalModal } from "./add-goal-modal/add-goal-modal";

export const EmptyGoals = () => {
  return (
    <EmptyState
      hasHeader={false}
      hasSearch={false}
      text="Add a Goal"
      subText="Assign goals to your patient"
      button={<AddGoalModal />}
      title=""
    />
  );
};
