import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  PatientProfileAutoSelectContainer,
  PatientProfileAutoSelectFormControlContainer,
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileTagList,
  PatientProfileTagListItem,
  PatientProfileTagsLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileDietModalInputs } from "../../patient-profile-edit-diet-modal";
import { useCallback, useState } from "react";
import { PutPatientDietProfileDietDTO } from "../../../../../../../../models/patient-diet-profile-dtos/put-patient-diet-profile-diet-dto";
import { useEnterKeypress } from "../../../../../../../../hooks/use-enter-keypress/use-enter-keypress";
import { AutoSelect } from "../../../../../../../../components/auto-select/auto-select";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";
import { usePatientProfileDiet } from "../../../../context/loadable-patient-profile-diet-context";

export const PatientProfileEditDietFollowedDietFormControl = () => {
  const { diets } = usePatientProfileDiet();

  const findDietName = useCallback(
    (id: number) => diets.find((item) => item.id === id),
    [diets]
  );

  const findDietIndex = useCallback(
    (name: string) => diets.find((item) => item.name === name),
    [diets]
  );

  const { control, watch } = useFormContext<PatientProfileDietModalInputs>();

  const { append, fields, remove } = useFieldArray({
    name: "patientDiets",
    control,
  } as never);

  const patientDiets = watch("patientDiets");

  const [searchTerm, setSearchTerm] = useState("");
  const [autoSelectTagValue, setAutoSelectTagValue] = useState<
    PutPatientDietProfileDietDTO | undefined
  >();

  const handleAppend = () => {
    if (
      patientDiets !== undefined &&
      autoSelectTagValue !== undefined &&
      !patientDiets.some((obj) => obj.dietId === autoSelectTagValue.dietId) &&
      diets.some((obj) => obj.id === autoSelectTagValue?.dietId)
    ) {
      append(autoSelectTagValue);
      setAutoSelectTagValue(undefined);
      setSearchTerm("");
    }
  };

  const { onEnterKeyPress } = useEnterKeypress(handleAppend);

  return (
    <PatientProfileModalFormControl>
      <PatientProfileTagsLabelContainer>
        <label>Followed diet</label>
      </PatientProfileTagsLabelContainer>
      <PatientProfileModalInputContainer>
        <Controller
          control={control}
          name="patientDiets"
          render={() => {
            return (
              <PatientProfileAutoSelectFormControlContainer>
                <PatientProfileAutoSelectContainer>
                  <AutoSelect
                    itemsArray={diets.map((foodAllergie) => foodAllergie.name)}
                    onChange={(value: string) => {
                      const valueID = findDietIndex(value)?.id;
                      setAutoSelectTagValue({ dietId: valueID! });
                      setSearchTerm(value);
                    }}
                    value={searchTerm}
                    fullWidth
                    placeHolder="Select Diets"
                  />
                  <Button
                    type="button"
                    onClick={handleAppend}
                    onKeyDown={(e) => onEnterKeyPress(e)}
                    width={100}
                    height={40}
                    flex
                    outlineNoBorder
                  >
                    <span className="material-symbols-outlined">add</span> Add
                  </Button>
                </PatientProfileAutoSelectContainer>
                <PatientProfileTagList>
                  {fields.map((field, index) => (
                    <PatientProfileTagListItem key={field.id} variant="Normal">
                      {patientDiets !== undefined
                        ? findDietName(patientDiets[index].dietId)?.name
                        : ""}
                      <span
                        className="material-symbols-outlined"
                        onClick={() => remove(index)}
                      >
                        close
                      </span>
                    </PatientProfileTagListItem>
                  ))}
                </PatientProfileTagList>
              </PatientProfileAutoSelectFormControlContainer>
            );
          }}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
