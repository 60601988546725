import { viewChronicDisease } from "../../../utils/patient-profile-utils";
import { usePatientProfileMedicalHistory } from "../../context/loadable-patient-profile-medical-history";
import {
  PatientProfileMedicalHistoryItemContainer,
  PatientProfileMedicalHistoryItemTextContainer,
  PatientProfileMedicalHistoryItemTitleContainer,
} from "../styled-profile-medical-history";

export const PatientProfileMedicalHistoryReadChronicDisease = () => {
  const { patientMedicalHistoryDTO } = usePatientProfileMedicalHistory();

  const chronicDiseases = patientMedicalHistoryDTO.chronicDiseases;

  return (
    <PatientProfileMedicalHistoryItemContainer>
      <PatientProfileMedicalHistoryItemTitleContainer>
        <img src="/img/patient-profile/medical-history/heart.svg" alt="" />
        <p>Chronic Disease</p>
      </PatientProfileMedicalHistoryItemTitleContainer>
      <PatientProfileMedicalHistoryItemTextContainer>
        <p>
          {chronicDiseases !== undefined && chronicDiseases.length > 0
            ? chronicDiseases.map(
                (item, index) =>
                  `${viewChronicDisease(item.chronicDisease)}${
                    chronicDiseases.length > 1 && chronicDiseases[index + 1]
                      ? ", "
                      : ""
                  }`
              )
            : "--"}
        </p>
      </PatientProfileMedicalHistoryItemTextContainer>
    </PatientProfileMedicalHistoryItemContainer>
  );
};
