import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { ProteinSourceDTO } from "../../models/protein-sources/protein-source-dto";

export function useGetProteinSources(): () => Promise<ProteinSourceDTO[]> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<ProteinSourceDTO[]>(
      `${BASE_PATH}/protein-sources`
    );

    return response.data;
  }, [client]);
}
