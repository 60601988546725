import styled from "styled-components/macro";
import {
  flexCenterSpaceAround,
  regularMediumFont,
} from "../../../../../../../../styles/classes/gloabl-classes";
import { PersonalInfoItemCSS } from "../../../../../styled-single-patient-sidebar";

export const DiagnosisModalBodyContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-top: 1rem;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const DiagnosisAddedTagsContainer = styled.div`
  padding: ${({ theme }) => theme.padding.py_05};

  div {
    ${flexCenterSpaceAround}
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
    cursor: pointer;
  }
`;

export const DiagnosisList = styled.ul`
  padding: 0;
  display: flex;
  gap: 0.5rem 0rem;
  flex-wrap: wrap;
`;

export const DiagnosisItem = styled.div`
  ${PersonalInfoItemCSS}
  cursor: pointer;
`;

export const DiagnosisIsEmpyText = styled.p`
  ${regularMediumFont}
  color: ${({ theme }) => theme.colors.textColor};
  margin: 0;
  width: 100%;
  text-align: center;
`;
