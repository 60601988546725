import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type ImmediateAttentionPatientsFiltersSidebar = {
  showSidebar: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
  toggleSidebar: () => void;
};

const ImmediateAttentionPatientsFiltersSidebarContext = createContext<
  ImmediateAttentionPatientsFiltersSidebar | undefined
>(undefined);

type ImmediateAttentionPatientsFiltersSidebarProviderProps = PropsWithChildren<{}>;

export const ImmediateAttentionPatientsFiltersSidebarProvider = (
  props: ImmediateAttentionPatientsFiltersSidebarProviderProps
) => {
  const { children } = props;

  const [showSidebar, setShowSidebar] = useState(false);

  const openSidebar = useCallback(() => setShowSidebar(true), []);

  const closeSidebar = useCallback(() => setShowSidebar(false), []);

  const toggleSidebar = useCallback(() => setShowSidebar(!showSidebar), [
    showSidebar,
  ]);

  const value = useMemo(
    () => ({
      showSidebar,
      openSidebar,
      closeSidebar,
      toggleSidebar,
    }),
    [showSidebar, openSidebar, closeSidebar, toggleSidebar]
  );

  return (
    <ImmediateAttentionPatientsFiltersSidebarContext.Provider value={value}>
      {children}
    </ImmediateAttentionPatientsFiltersSidebarContext.Provider>
  );
};

export const useImmediateAttentionPatientsFiltersSidebar = () => {
  const context = useContext(ImmediateAttentionPatientsFiltersSidebarContext);

  if (context === undefined) {
    throw new Error(
      "useImmediateAttentionPatientsFiltersSidebar must be used within a ImmediateAttentionPatientsFiltersSidebarProvider"
    );
  }

  return context;
};
