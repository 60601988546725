import { useFieldArray, useFormContext } from "react-hook-form";
import { CreateLessonInputs } from "../../../../create-lesson";
import { CreateContentModuleCardPartDTO } from "../../../../../../../../models/content-module-dtos/create-content-module-card-part-dto";
import { useCallback, useRef } from "react";
import { CardVideoPart } from "./create-lesson-card-part-video-upload/card-video-part";
import { CardImagePart } from "./create-lesson-card-parts-image-upload/card-image-part";
import { CardTextPart } from "./card-text-part";
import {
  LessonCardPartsActions,
  LessonCardPartsList,
  LessonCardPartsListItem,
} from "../../../../../../styles/content-module-lesson-classes";
import { CardPartsActionButton } from "../../../../../../components/card-parts-actions-button";
import { useCardPartsLoading } from "../../../../../context/card-parts-loading-context";

type CreateLessonCardPartsProps = {
  cardIndex: number;
};

export const CreateLessonCardParts = (props: CreateLessonCardPartsProps) => {
  const { cardIndex } = props;

  const { cardPartsLoading } = useCardPartsLoading();

  const { control } = useFormContext<CreateLessonInputs>();

  const { append, fields, remove, move } = useFieldArray<CreateLessonInputs>({
    name: `contentModuleCards.${cardIndex}.contentModuleCardParts`,
    control,
  } as never);

  type ContentModuleCardPartsFields = CreateContentModuleCardPartDTO & {
    id: string;
  };

  const contentModuleCardPartsFields = fields as ContentModuleCardPartsFields[];

  const handleRemove = useCallback(
    (partIndex: number) => {
      remove(partIndex);
    },
    [remove]
  );

  const dragItem = useRef<number>(0);
  const draggedOverItem = useRef<number>(0);

  const handleSort = useCallback(() => {
    move(dragItem.current, draggedOverItem.current);
  }, [move]);

  return (
    <div className="card-parts-list">
      <LessonCardPartsList>
        {contentModuleCardPartsFields.map((field, cardPartIndex) => (
          <LessonCardPartsListItem
            key={field.id}
            draggable={cardPartsLoading.cardIndex !== cardIndex}
            onDragStart={() => (dragItem.current = cardPartIndex)}
            onDragEnter={() => (draggedOverItem.current = cardPartIndex)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
          >
            {cardPartsLoading.cardIndex !== cardIndex && (
              <span className="material-symbols-outlined grab-lesson-card-part-icon">
                menu
              </span>
            )}
            {field.contentModuleCardPartType === "Text" && (
              <CardTextPart
                cardIndex={cardIndex}
                cardPartIndex={cardPartIndex}
              />
            )}
            {field.contentModuleCardPartType === "Image" && (
              <CardImagePart
                cardIndex={cardIndex}
                cardPartIndex={cardPartIndex}
              />
            )}
            {field.contentModuleCardPartType === "Video" && (
              <CardVideoPart
                cardIndex={cardIndex}
                cardPartIndex={cardPartIndex}
              />
            )}
            {cardPartsLoading.cardIndex !== cardIndex && (
              <span
                className="remove-lesson-card-part-icon"
                onClick={() => handleRemove(cardPartIndex)}
              >
                <img src="/img/trash.svg" alt="Trash Icon" />
              </span>
            )}
          </LessonCardPartsListItem>
        ))}
      </LessonCardPartsList>
      <LessonCardPartsActions>
        <CardPartsActionButton
          handleClick={() =>
            append({
              contentModuleCardPartType: "Text",
              uiOrder: fields.length + 1,
            })
          }
          contentModuleCardPartType="Text"
        />
        <CardPartsActionButton
          handleClick={() =>
            append({
              contentModuleCardPartType: "Image",
              uiOrder: fields.length + 1,
            })
          }
          contentModuleCardPartType="Image"
        />
        <CardPartsActionButton
          handleClick={() =>
            append({
              contentModuleCardPartType: "Video",
              uiOrder: fields.length + 1,
            })
          }
          contentModuleCardPartType="Video"
        />
      </LessonCardPartsActions>
    </div>
  );
};
