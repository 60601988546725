import { CarbCounterV1MealSummaryDTO } from "../../../../../../../models/carb-counter-dtos/carb-counter-v1-meal-summary-dto";
import { roundTo1DecimalPlace } from "../../../../../../../utils/math-utils";
import {
  FoodLoggingSelectedPatientMealFoodGroupsContainer,
  FoodLoggingSelectedPatientMealFoodGroupsInputContainer,
  FoodLoggingSelectedPatientMealFoodGroupsInputLabel,
  FoodLoggingSelectedPatientMealFoodGroupsInputsContainer,
} from "../../../../legacy-food-logging-selected-patient-meal/food-logging-selected-patient-meal-food-groups/styled-food-logging-selected-patient-meal-food-groups";
import { FoodLoggingSelectedPatientMealContainerTitle } from "../../../../styled-food-logging-selected-patient-meal";

type LoadedCarbCounterFoodLoggingSelectedPatientMealFoodGroupsProps = {
  carbCounterV1MealSummaryDTO: CarbCounterV1MealSummaryDTO;
};

export const LoadedCarbCounterFoodLoggingSelectedPatientMealFoodGroups = (
  props: LoadedCarbCounterFoodLoggingSelectedPatientMealFoodGroupsProps
) => {
  const { carbCounterV1MealSummaryDTO } = props;

  const foodGroupsDTOs = carbCounterV1MealSummaryDTO.foodGroups;

  const foodGroupsWithServingsCountGreaterThanZero = foodGroupsDTOs.filter(
    (foodGroupsDTO) => foodGroupsDTO.servingsCount > 0
  );

  return (
    <FoodLoggingSelectedPatientMealFoodGroupsContainer>
      <FoodLoggingSelectedPatientMealContainerTitle>
        Food groups
      </FoodLoggingSelectedPatientMealContainerTitle>
      <FoodLoggingSelectedPatientMealFoodGroupsInputsContainer>
        {foodGroupsWithServingsCountGreaterThanZero.map((foodGroupDTO) => (
          <FoodLoggingSelectedPatientMealFoodGroupsInputContainer
            key={foodGroupDTO.foodGroup.id}
          >
            <FoodLoggingSelectedPatientMealFoodGroupsInputLabel>
              {foodGroupDTO.foodGroup.name}
            </FoodLoggingSelectedPatientMealFoodGroupsInputLabel>
            <span>{roundTo1DecimalPlace(foodGroupDTO.servingsCount)}</span>
          </FoodLoggingSelectedPatientMealFoodGroupsInputContainer>
        ))}
      </FoodLoggingSelectedPatientMealFoodGroupsInputsContainer>
    </FoodLoggingSelectedPatientMealFoodGroupsContainer>
  );
};
