import { GenericActionsListItem } from "../../../components/generic-actions-list/generic-actions-list-item";
import { Modal } from "../../../components/modal/modal";
import { ModalForm } from "../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../components/modal/modal-header/modal-header";
import { useModalHelpers } from "../../../components/modal/use-modal-helpers";
import { useToastService } from "../../../context/toast-service-context";
import { useSyncPatient } from "../../../hooks/chat-hooks/use-sync-patient";
import { PatientDTO } from "../../../models/patient-dtos/patient-dto";
import { Button, ModalFooter } from "../../../styles/classes/reusable-classes";

type SyncPatientProps = {
  patientDTO: PatientDTO;
};

export const SyncPatient = (props: SyncPatientProps) => {
  const { patientDTO } = props;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  const syncPatient = useSyncPatient();

  const { showToast } = useToastService();

  return (
    <>
      <GenericActionsListItem text="Sync Patient" onClick={openModal} />
      <Modal showModal={isModalOpen} handleHideModal={closeModal}>
        <ModalForm
          key={modalBodyKey}
          height={350}
          width={550}
          onSubmit={async (e) => {
            e.preventDefault();
            await syncPatient(patientDTO.id);
            showToast("Success", `patient ${patientDTO.name} Synced`);

            closeModal();
            try {
            } catch (error) {
              showToast("Error", "Failed to Sync Patient");
            }
          }}
        >
          <ModalHeader
            onModalClose={closeModal}
            title={`Sync patient: ${patientDTO.name} ${
              patientDTO.whatsAppId !== undefined
                ? `(${patientDTO.whatsAppId})`
                : ``
            }`}
          />
          <h1 style={{ textAlign: "center" }}>
            You're about to sync patient {patientDTO.name} to chat
          </h1>
          <ModalFooter>
            <Button type="button" outlined onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit">Confirm</Button>
          </ModalFooter>
        </ModalForm>
      </Modal>
    </>
  );
};
