import styled from "styled-components/macro";
import {
  mediumRegularFont,
  smallRegularFont,
} from "../../../../styles/classes/gloabl-classes";

export const MedicationTH = styled.th<{ minWidth: number }>`
  text-align: left;

  div {
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.newColors.textColorAlt};
    padding: 12px;
    min-width: ${({ minWidth }) => `${minWidth}px`};
  }
`;

export const MedicationTD = styled.td<{ width?: number }>`
  padding: 12px 4px;
  width: ${({ width }) => (width ? `${width}px` : "unset")};
`;

export const MedicationsSubHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

export const MedicationsSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    font-size: 21px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
    margin-bottom: 8px;

    @media screen and (max-width: 650px) {
      margin: 0 auto 16px;
      border-bottom: 1px solid transparent;
    }
  }

  @media screen and (max-width: 650px) {
    margin-bottom: 16px;
  }
`;

export const MedicationContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  min-height: 48px;
  height: fit-content;
  width: fit-content;
  margin-left: 4px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    width: 41px;
    height: 41px;
  }
`;

export const StatusContainer = styled.div<{
  stopped: boolean;
  notStartedYet: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70px;
  width: ${({ notStartedYet }) => (notStartedYet ? "120px" : "70px")};

  p {
    ${mediumRegularFont}
    color: ${({ theme, stopped, notStartedYet }) =>
      stopped
        ? theme.colors.danger
        : notStartedYet
        ? theme.colors.warning
        : theme.colors.success};
    background: ${({ stopped, notStartedYet, theme }) =>
      stopped
        ? theme.newColors.dangerBackground
        : notStartedYet
        ? theme.newColors.warningBackground
        : theme.newColors.successAlt};
    padding: 2px 8px;
    border-radius: 16px;
    margin: 0;
  }

  span {
    ${smallRegularFont}
    color: ${({ theme }) => theme.newColors.textColorAlt};
    margin-top: 4px;
  }
`;

export const SigContainer = styled.div`
  margin: 0px;

  p {
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
    margin-left: 8px;
    max-width: 150px;
    word-break: break-all;
  }
`;

export const DateContainer = styled.div`
  ${mediumRegularFont}
  color: ${({ theme }) => theme.colors.titleColor};
  margin-left: 12px;
`;

export const AssignedContainer = styled.div<{ byOtida: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px 6px;
  padding: 2px 8px;
  width: 77px;
  border-radius: 16px;
  background: ${({ byOtida, theme }) =>
    byOtida ? theme.newColors.otidaO2 : theme.colors.borderColor};
  color: ${({ byOtida, theme }) =>
    byOtida ? theme.newColors.byOtida : theme.colors.titleColor};
  margin: 0 auto 0 8px;

  p {
    margin: 0;
  }

  img {
    display: ${({ byOtida }) => (byOtida ? "block" : "none")};
    width: 21px;
    height: 21px;
  }
`;

export const NoteContainer = styled.div`
  p {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.titleColor};
    word-break: break-all;
    min-width: 132px;
    max-width: 150px;
    margin: 0 0 0 8px;
  }
`;

export const ActionsContainer = styled.div`
  padding: 0px 0px 0px 8px;
  cursor: pointer;
`;
