import styled from "styled-components/macro";

export const DailyFixedSummaryChartContainerContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 8px 16px;

  @media screen and (max-width: 1440px) {
    padding: 0.5rem calc(1.7rem - 3px);
  }

  @media screen and (max-width: 940px) {
    width: 100%;
    overflow: auto;
  }

  @media screen and (max-width: 375px) {
    padding: 0.5rem 0rem;
  }
`;

export const DailyFixedSummaryChartWrapper = styled.div`
  display: flex;
  gap: 0rem calc(2rem - 13px);
  padding: 16px 0px;
`;

export const DailyFixedSummaryChartChart = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  width: 70%;
  border-radius: 12px;
`;
