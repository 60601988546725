import { Temporal } from "temporal-polyfill";
import { GoogleDataTableRow } from "react-google-charts";
import { plainDateTimeToGoogleChartsDateStringRepresentation } from "./plain-date-time-to-google-charts-date-string-representation";

export function buildRow(opts: {
  datetime: Temporal.PlainDateTime;
  reverseVAxisTicks: number[];
  glucose?: {
    value: number;
    tooltip: string;
  };
  isNotGlucose?: {
    value: number;
    tooltip: string;
  };
  isNotGlucoseStyle?: string;
}): GoogleDataTableRow {
  const {
    datetime,
    reverseVAxisTicks,
    glucose,
    isNotGlucose,
    isNotGlucoseStyle,
  } = opts;

  const dateStringRepresentation = plainDateTimeToGoogleChartsDateStringRepresentation(
    datetime
  );

  return [
    dateStringRepresentation,
    ...reverseVAxisTicks,
    glucose?.value || {},
    glucose?.tooltip || "",
    isNotGlucose?.value || {},
    isNotGlucose?.tooltip || "",
    isNotGlucoseStyle || "",
  ];
}
