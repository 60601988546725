import { PateintProfileColumnHeader } from "../../components/pateint-profile-column-header/pateint-profile-column-header";
import { PatientProfileMedicalHistoryItemTitleContainer } from "../../patient-profile-medical-history-section/patient-profile-medical-history/styled-profile-medical-history";
import { PatientProfileContainerBody } from "../../styles/patient-profile-styles/patient-profile-classes";
import { usePatientProfileDiet } from "../context/loadable-patient-profile-diet-context";
import { PatientProfileEditDietModal } from "./patient-profile-edit-diet-modal/patient-profile-edit-diet-modal";
import {
  PatientProfileDietCard,
  PatientProfileDietCardSubContainer,
  PatientProfileDietContainer,
} from "./styled-patient-profile-diet";

export const PatientProfileDiet = () => {
  const { patientDietProfileDTO } = usePatientProfileDiet();

  const waterIntake =
    patientDietProfileDTO.waterIntakeCupsPerDay !== undefined
      ? `${patientDietProfileDTO.waterIntakeCupsPerDay} glasses`
      : "--";

  const caffeineIntakeCupsPerDay =
    patientDietProfileDTO.caffeineIntakeCupsPerDay !== undefined
      ? `${patientDietProfileDTO.caffeineIntakeCupsPerDay} cups`
      : "--";

  const patientSmokes =
    patientDietProfileDTO.patientSmokes.length > 0 &&
    patientDietProfileDTO.patientSmokes !== undefined
      ? patientDietProfileDTO.patientSmokes.map(
          (item, index) => `
        ${item.smoke === "Other" ? item.other : item.smoke} ${
            item.quantity !== 0 && item.quantity !== undefined
              ? `(${item.quantity}${
                  item.frequency !== undefined
                    ? ` ${
                        item.smoke === "Cigarettes" || item.smoke === "Iqos"
                          ? `cigarettes`
                          : item.smoke === "Vape"
                          ? `ml`
                          : `times`
                      }/${item.frequency})`
                    : ""
                }`
              : ""
          }${
            patientDietProfileDTO.patientSmokes.length > 1 &&
            patientDietProfileDTO.patientSmokes[index + 1]
              ? ", "
              : ""
          }
      `
        )
      : "--";

  const patientAlcohols =
    patientDietProfileDTO.patientAlcohols.length > 0 &&
    patientDietProfileDTO.patientAlcohols !== undefined
      ? patientDietProfileDTO.patientAlcohols.map(
          (item, index) => `
          ${item.alcohol === "Other" ? item.other : item.alcohol} ${
            item.quantity !== 0 && item.quantity !== undefined
              ? `(${item.quantity}${
                  item.frequency !== undefined
                    ? ` ${
                        item.alcohol === "Beer"
                          ? `bottels`
                          : item.alcohol === "Spirits" ||
                            item.alcohol === "Wine"
                          ? `glasses`
                          : ``
                      } /${item.frequency})`
                    : ""
                }`
              : ""
          }${
            patientDietProfileDTO.patientAlcohols.length > 1 &&
            patientDietProfileDTO.patientAlcohols[index + 1]
              ? ", "
              : ""
          }
        `
        )
      : "--";

  const patientDiets =
    patientDietProfileDTO.patientDiets.length > 0 &&
    patientDietProfileDTO.patientDiets !== undefined
      ? patientDietProfileDTO.patientDiets.map(
          (diet, index) =>
            `${diet.diet.name}${
              patientDietProfileDTO.patientDiets.length > 1 &&
              patientDietProfileDTO.patientDiets[index + 1]
                ? ", "
                : ""
            }`
        )
      : "--";

  const patientAddedSugars =
    patientDietProfileDTO.patientAddedSugars.length > 0 &&
    patientDietProfileDTO.patientAddedSugars !== undefined
      ? patientDietProfileDTO.patientAddedSugars.map(
          (sugar, index) =>
            `${
              sugar.addedSugar.name === "Other"
                ? sugar.notes
                : sugar.addedSugar.name
            } ${
              sugar.avgQuantityPerDay !== 0 &&
              sugar.avgQuantityPerBeverage !== 0
                ? `(${sugar.avgQuantityPerDay} tsp/day - ${sugar.avgQuantityPerBeverage} tsp/drink)`
                : ``
            }${
              patientDietProfileDTO.patientAddedSugars.length > 1 &&
              patientDietProfileDTO.patientAddedSugars[index + 1]
                ? ", "
                : ""
            }`
        )
      : "--";

  const patientFoodAllergies =
    patientDietProfileDTO.patientFoodAllergies.length > 0 &&
    patientDietProfileDTO.patientFoodAllergies !== undefined
      ? patientDietProfileDTO.patientFoodAllergies.map(
          (allergy, index) =>
            `${allergy.foodAllergy.name}${
              patientDietProfileDTO.patientFoodAllergies.length > 1 &&
              patientDietProfileDTO.patientFoodAllergies[index + 1]
                ? ", "
                : ""
            }`
        )
      : "--";

  const patientSleepInfo =
    patientDietProfileDTO.sleepHoursPerDay !== undefined &&
    patientDietProfileDTO.sleepHoursPerDay !== undefined
      ? `${patientDietProfileDTO.sleepHoursPerDay} H (${patientDietProfileDTO.sleepPattern})`
      : "--";

  const patientLifeActivties =
    patientDietProfileDTO.patientLifeActivities !== undefined &&
    patientDietProfileDTO.patientLifeActivities.length > 0
      ? patientDietProfileDTO.patientLifeActivities.map(
          (item, index) =>
            `${
              item.activity?.name !== undefined
                ? item.activity?.name
                : item.notes
            } ${
              item.minsPerWeek !== undefined ? `(${item.minsPerWeek}/week)` : ``
            }${
              patientDietProfileDTO.patientLifeActivities.length > 1 &&
              patientDietProfileDTO.patientLifeActivities[index + 1]
                ? ", "
                : ""
            }`
        )
      : "--";

  return (
    <>
      <PateintProfileColumnHeader
        img="/img/patient-profile/diet/diet.svg"
        title="Diet & Lifestyle"
        button={<PatientProfileEditDietModal />}
      />
      <PatientProfileContainerBody>
        <PatientProfileDietContainer>
          <PatientProfileDietCard>
            <PatientProfileDietCardSubContainer>
              <img src="/img/patient-profile/diet/glass.svg" alt="Glass" />
              <p>
                {waterIntake}{" "}
                <span>{waterIntake !== "--" ? "- per day" : ""}</span>
              </p>
            </PatientProfileDietCardSubContainer>
            <PatientProfileDietCardSubContainer>
              <img src="/img/patient-profile/diet/coffee.svg" alt="coffee" />
              <p>
                {caffeineIntakeCupsPerDay}{" "}
                <span>
                  {caffeineIntakeCupsPerDay !== "--" ? "- per day" : ""}
                </span>
              </p>
            </PatientProfileDietCardSubContainer>
          </PatientProfileDietCard>
          <PatientProfileDietCard>
            <PatientProfileDietCardSubContainer>
              <span className="material-symbols-outlined">smoking_rooms</span>
              <p>{patientSmokes}</p>
            </PatientProfileDietCardSubContainer>
            <PatientProfileDietCardSubContainer>
              <span className="material-symbols-outlined">local_bar</span>
              <p>{patientAlcohols}</p>
            </PatientProfileDietCardSubContainer>
          </PatientProfileDietCard>
          <PatientProfileDietCardSubContainer>
            <img src="/img/patient-profile/diet/fasting.svg" alt="fasting" />
            <p>{patientDiets}</p>
          </PatientProfileDietCardSubContainer>
          <PatientProfileDietCardSubContainer>
            <img
              src="/img/patient-profile/diet/sugar-free.svg"
              alt="sugar-free"
            />
            <p>{patientAddedSugars}</p>
          </PatientProfileDietCardSubContainer>
          <PatientProfileDietCardSubContainer>
            <PatientProfileMedicalHistoryItemTitleContainer>
              <img
                src="/img/patient-profile/diet/allergies.svg"
                alt="allergies"
              />
              <p>Allergies: </p>
            </PatientProfileMedicalHistoryItemTitleContainer>
            <p>{patientFoodAllergies}</p>
          </PatientProfileDietCardSubContainer>
          <PatientProfileDietCardSubContainer>
            <img src="/img/patient-profile/diet/sleep.svg" alt="sleep" />
            <p>{patientSleepInfo}</p>
          </PatientProfileDietCardSubContainer>
          <PatientProfileDietCardSubContainer>
            <img src="/img/plans/nav/life-style.svg" alt="life-style" />
            <p>{patientLifeActivties}</p>
          </PatientProfileDietCardSubContainer>
        </PatientProfileDietContainer>
      </PatientProfileContainerBody>
    </>
  );
};
