import styled from "styled-components/macro";

export const SinglePatientHeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 1rem;
  font-size: 21px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};

  @media screen and (max-width: 650px) {
    align-self: flex-start;
  }
`;

export const SinglePatientHeaderIcon = styled.div`
  span {
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const SinglePatientHeaderTitle = styled.h3`
  color: ${({ theme }) => theme.colors.titleColor};
  font-size: ${({ theme }) => theme.typography.fontSize.lgFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};

  span {
    color: ${({ theme }) => theme.colors.success};
    font-size: ${({ theme }) => theme.typography.fontSize.lgFontSize};
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};

    @media screen and (max-width: 768px) {
      font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    margin-left: 0.3rem;
  }

  @media screen and (max-width: 650px) {
    flex-direction: row;
    align-items: center;
    gap: 0rem 1rem;
  }
`;
