import styled from "styled-components/macro";
import {
  flexCenter,
  flexCol,
  mediumRegularFont,
  regularMediumFont,
  smallRegularFont,
} from "../../../../../styles/classes/gloabl-classes";

export const MedicalListContainer = styled.div`
  padding: 0px 24px;
`;

export const MedicalListTitle = styled.p`
  margin: 24px 0px 16px;
  ${regularMediumFont}
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const MedicalListUL = styled.ul<{ currentMedicationsList?: boolean }>`
  ${flexCol}
  gap: 12px 0px;
  height: ${({ currentMedicationsList }) =>
    currentMedicationsList ? "400px" : "auto"};
  overflow-y: scroll;
  padding-right: ${({ currentMedicationsList }) =>
    currentMedicationsList ? "8px" : "0px"};
`;

export const MedicalListLI = styled.li`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 16px;
  padding: ${({ theme }) => theme.padding.pad05_1};
  ${flexCenter}
`;

export const MedicalListStatusContainer = styled.div<{ stopped: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;

  p {
    ${mediumRegularFont}
    color: ${({ theme, stopped }) =>
      stopped ? theme.colors.danger : theme.colors.success};
    background: ${({ stopped, theme }) =>
      stopped ? theme.newColors.dangerBackground : theme.newColors.successAlt};
    padding: 2px 8px;
    border-radius: 16px;
    margin: 0;
  }

  span {
    ${smallRegularFont}
    color: ${({ theme }) => theme.newColors.textColorAlt};
    margin-top: 4px;
  }
`;
