import { PatientNutritionReportSummaryDTO } from "../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-summary-dto";
import { roundTo1DecimalPlace } from "../../../../../../utils/math-utils";
import { NutritionReportSummaryListFoodgroups } from "./nutrition-report-summary-list-foodgroups";
import {
  NutritionReportSummaryListBar,
  NutritionReportSummaryListBarContainer,
  NutritionReportSummaryListGoalContainer,
  NutritionReportSummaryListLI,
  NutritionReportSummaryListTitleContainer,
} from "./styled-nutrition-report-summary-list";

type NutritionReportSummaryListProps = {
  patientNutritionReportSummary: PatientNutritionReportSummaryDTO;
};

export const NutritionReportSummaryList = (
  props: NutritionReportSummaryListProps
) => {
  const { patientNutritionReportSummary } = props;

  return (
    <ul>
      <NutritionReportSummaryListFoodgroups
        patientNutritionReportSummary={patientNutritionReportSummary}
      />
      <NutritionReportSummaryListLI>
        <NutritionReportSummaryListTitleContainer>
          <img
            src={`/img/nutrition-goals/carbs.svg`}
            alt={`carbs percentage`}
          />
          <p>Carbs %</p>
        </NutritionReportSummaryListTitleContainer>
        <NutritionReportSummaryListBarContainer>
          <NutritionReportSummaryListBar
            progress={
              ((patientNutritionReportSummary.actualCarbsKCalsPercentage! /
                patientNutritionReportSummary.goalCarbsKCalsPercentage!) *
                100) /
              2
            }
          >
            <div />
          </NutritionReportSummaryListBar>
          <NutritionReportSummaryListGoalContainer>
            <p>
              {patientNutritionReportSummary.actualCarbsKCalsPercentage !==
              undefined
                ? roundTo1DecimalPlace(
                    patientNutritionReportSummary.actualCarbsKCalsPercentage
                  )
                : 0}
              /
              {patientNutritionReportSummary.goalCarbsKCalsPercentage !==
              undefined
                ? roundTo1DecimalPlace(
                    patientNutritionReportSummary.goalCarbsKCalsPercentage
                  )
                : 0}
              %
            </p>
          </NutritionReportSummaryListGoalContainer>
        </NutritionReportSummaryListBarContainer>
      </NutritionReportSummaryListLI>
    </ul>
  );
};
