import { FormProvider, useForm } from "react-hook-form";
import { Modal } from "../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../components/modal/use-modal-helpers";
import { Button } from "../../../../../styles/classes/reusable-classes";
import { PatientProfileEditProfileModalBody } from "./patient-profile-edit-profile-modal-body/patient-profile-edit-profile-modal-body";
import { PatientHealthBarrierDTO } from "../../../../../models/patient-health-barrier-dtos/patient-health-barrier-dto";
import { PutPatientProfileProfileDiagnosisDTO } from "../../../../../models/patient-profile-dtos/put-patient-profile-profile-diagnosis-dto";
import { usePatientProfileInformationHeader } from "../context/loadable-patient-profile-information-header-context";

export type PatientProfileEditProfileInputs = {
  name?: string;
  email?: string;
  mobile?: string;
  job?: string;
  dateOfBirth?: string;
  address?: string;
  patientHealthBarriers?: PatientHealthBarrierDTO[];
  patientProfileDiagnoses?: PutPatientProfileProfileDiagnosisDTO[];
  maritalStatusId?: number;
};

export const PatientProfileEditProfileModal = () => {
  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  const { patientDTO, patientHealthBarriers, patientProfileDiagnosis } =
    usePatientProfileInformationHeader();

  const methods = useForm<PatientProfileEditProfileInputs>({
    defaultValues: {
      name: patientDTO.name,
      email: patientDTO.email !== undefined ? patientDTO.email : "",
      mobile: patientDTO.mobile !== undefined ? patientDTO.mobile : "",
      job: patientDTO.job !== undefined ? patientDTO.job : "",
      dateOfBirth:
        patientDTO.dateOfBirth !== undefined ? patientDTO.dateOfBirth : "",
      address: patientDTO.address !== undefined ? patientDTO.address : "",
      patientHealthBarriers:
        patientHealthBarriers !== undefined && patientHealthBarriers.length > 0
          ? patientHealthBarriers
          : undefined,
      patientProfileDiagnoses:
        patientProfileDiagnosis !== undefined
          ? patientProfileDiagnosis
          : undefined,
      maritalStatusId:
        patientDTO.maritalStatusId !== undefined
          ? patientDTO.maritalStatusId
          : undefined,
    },
  });

  return (
    <>
      <Button outlineTitleColor flex onClick={openModal}>
        <img src="/img/edit-primary.svg" alt="Edit" />
        Edit
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FormProvider {...methods}>
          <PatientProfileEditProfileModalBody
            key={modalBodyKey}
            onClose={closeModal}
          />
        </FormProvider>
      </Modal>
    </>
  );
};
