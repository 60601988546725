import styled from "styled-components/macro";

export const FoodLoggingSelectedPatientMealVoiceNotesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
  place-items: center;
  justify-content: center;
`;

export const FoodLoggingSelectedPatientMealVoiceNotesSubContainer = styled.div`
  /* Source: https://stackoverflow.com/questions/4126708/is-it-possible-to-style-html5-audio-tag */
  audio {
    height: 40px;
    border-radius: 10px;
  }

  audio::-webkit-media-controls-panel {
    background: ${({ theme }) => theme.colors.containerColor};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  audio::-webkit-media-controls-timeline-container {
    background: ${({ theme }) => theme.colors.primaryColor};
  }
`;
