import styled from "styled-components/macro";
import {
  flexCenter,
  mediumFont,
  regularSemiBoldFont,
  smallRegularFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const BloodPressureContainer = styled.div`
  margin-top: 1rem;
`;

export const AddBloodPressureButton = styled.button`
  ${regularSemiBoldFont}
  color: ${({ theme }) => theme.colors.primaryColor};
  padding: 0px;
  ${flexCenter}
  gap: 0px 4px;
  background: transparent;
`;

export const BloodPressureList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, 140px);
  justify-content: center;
  gap: 0.5rem 0.75rem;
`;

export const BloodPressureListItem = styled.li`
  background: #eaf7ff;
  border-radius: 12px;
  padding: 8px 16px;

  p {
    margin: 0px;
    ${mediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }

  span {
    ${smallRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
