import {
  ManualReadingsLegendContainer,
  ManualReadingsLegendPoint,
} from "./styled-manual-reading-legend";

export const ManualReadingsLegend = () => {
  return (
    <ManualReadingsLegendContainer>
      <ManualReadingsLegendPoint variant="Reading-InRange">
        <div />
        <span>In Range</span>
      </ManualReadingsLegendPoint>
      <ManualReadingsLegendPoint variant="Reading-OutOfRange">
        <div />
        <span>Out of Range</span>
      </ManualReadingsLegendPoint>
      <ManualReadingsLegendPoint variant="Insulin">
        <img src="/img/insulin.svg" alt="Insulin" />
        <span>Insulin</span>
      </ManualReadingsLegendPoint>
    </ManualReadingsLegendContainer>
  );
};
