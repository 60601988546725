import { PATIENTS_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { PutPatientProfileDTO } from "../../models/patient-profile-dtos/put-patient-profile-dto";
import { PatientDTO } from "../../models/patient-dtos/patient-dto";

export function usePutPatientProfile(): (
  patientDTO: PatientDTO,
  putPatientProfileDTO: PutPatientProfileDTO
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (patientDTO, putPatientProfileDTO) => {
      await client.put<void>(
        `${PATIENTS_PATH}/${patientDTO.id}/profile`,
        putPatientProfileDTO
      );
    },
    [client]
  );
}
