import { PatientMedicationDTO } from "../../../../../models/patient-medication-dto";
import {
  MedicationCardIconContainer,
  MedicationCardImageContainer,
  MedicationCardText,
  MedicationCardTitle,
} from "./styled-medication-card";

type MedicationCardProps = {
  patientMedicationDTO: PatientMedicationDTO;
  img?: true;
};

export const MedicationCard = (props: MedicationCardProps) => {
  const { patientMedicationDTO, img } = props;

  const medicationName = patientMedicationDTO.medication.name;
  const medicationStrength = `${
    patientMedicationDTO.medication.strength === undefined ||
    patientMedicationDTO.medication.strength === ""
      ? ""
      : `${patientMedicationDTO.medication.strength}, `
  }`;
  const medicationRouteOfAdministration = `${
    patientMedicationDTO.medication.routeOfAdministration === undefined
      ? ""
      : ` ${patientMedicationDTO.medication.routeOfAdministration},`
  }`;
  const medicationDoseUnit =
    patientMedicationDTO.medication.doseUnit !== undefined
      ? patientMedicationDTO.medication.doseUnit
      : "";

  return (
    <>
      {img ? (
        <MedicationCardImageContainer>
          <img src="/img/medications/vitamin.svg" alt="Medication Icon" />
        </MedicationCardImageContainer>
      ) : (
        <MedicationCardIconContainer>
          <span className="material-symbols-outlined">medication</span>
        </MedicationCardIconContainer>
      )}
      <div>
        <MedicationCardTitle>{medicationName}</MedicationCardTitle>
        <MedicationCardText>
          {medicationStrength}
          {medicationRouteOfAdministration}
          {medicationDoseUnit}
        </MedicationCardText>
      </div>
    </>
  );
};
