import {
  DietitianReportTabHeaderContainer,
  DietitianReportTabHeaderTitleContainer,
  DietitianReportTabHeaderTitleIcon,
  DietitianReportTabHeaderTitleText,
} from "./styled-dietitian-report-header";

type DietitianReportTabHeaderProps = {
  icon: string;
  title: string;
  subTitle: string;
};

export const DietitianReportTabHeader = (
  props: DietitianReportTabHeaderProps
) => {
  const { icon, title, subTitle } = props;

  return (
    <DietitianReportTabHeaderContainer>
      <DietitianReportTabHeaderTitleContainer>
        <DietitianReportTabHeaderTitleIcon>
          <img src={icon} alt={title} />
        </DietitianReportTabHeaderTitleIcon>
        <DietitianReportTabHeaderTitleText>
          <p>{title}</p>
          <span>{subTitle}</span>
        </DietitianReportTabHeaderTitleText>
      </DietitianReportTabHeaderTitleContainer>
    </DietitianReportTabHeaderContainer>
  );
};
