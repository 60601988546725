import { GenericActionsListItemItem } from "../../../../components/generic-actions-list/styled-generic-actions-list";
import { Modal } from "../../../../components/modal/modal";
import { useModalHelpers } from "../../../../components/modal/use-modal-helpers";
import { PatientMedicationDTO } from "../../../../models/patient-medication-dto";
import { AddNoteModalBody } from "./add-note-modal-body/add-note-modal-body";

type AddNoteModalProps = {
  row: PatientMedicationDTO;
};

export const AddNoteModal = (props: AddNoteModalProps) => {
  const { row } = props;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <>
      <GenericActionsListItemItem hasIcon onClick={openModal}>
        <img src="/img/medications/actions/note.svg" alt="note icon" />
        Add note
      </GenericActionsListItemItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <AddNoteModalBody key={modalBodyKey} onClose={closeModal} row={row} />
      </Modal>
    </>
  );
};
