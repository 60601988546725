import { PatientCGMEntryDTO } from "../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { ActionsListDropdown } from "../actions-list-dropdown/actions-list-dropdown";
import { useRowsActions } from "../../context/rows-actions-context";
import { CrudListContainer } from "./styled-table-rows";
import { ActionIcons } from "../../../../../../../../components/action-icons/action-icons";
import {
  TD,
  TDContainer,
  TDText,
} from "../../../../../../../../components/table/styled-table";
import { getAfterMealTag, getTag } from "../tag-dropdown/tag-dropdown-utils";

type Props = {
  row: PatientCGMEntryDTO;
  index: number;
  confirmDeleting: (row: PatientCGMEntryDTO) => Promise<void>;
};

export const ManualReadingsTableRow = (props: Props) => {
  const { row, index, confirmDeleting } = props;

  const { cancelOrConfirm, state } = useRowsActions();

  const handleConfirmDelete = () => {
    if (state.kind === "isDeleting") {
      confirmDeleting(row);
      cancelOrConfirm();
    }
  };

  const convertFrom24To12 = (time: string) => {
    const removeSeconds = time.slice(0, -3);
    let timeHours = Number(removeSeconds.slice(0, 2));
    const timeMinutes = time.slice(3, 5);

    let latinTime: "A:M" | "P:M" = "A:M";

    if (timeHours > 12) {
      timeHours = timeHours - 12;
      latinTime = "P:M";
    } else if (timeHours === 12) {
      timeHours = 12;
      latinTime = "P:M";
    } else if (timeHours === 0) {
      timeHours = timeHours + 12;
    } else {
      latinTime = "A:M";
    }

    const displayTime =
      timeHours.toString() + ":" + timeMinutes + " " + latinTime;

    return displayTime;
  };

  return (
    <tr
      key={index}
      style={{ opacity: state.kind === "isAdding" ? "0.5" : "1" }}
    >
      <TD>
        <TDContainer>
          <TDText>{convertFrom24To12(row.time)}</TDText>
        </TDContainer>
      </TD>
      <TD>
        <TDContainer>
          <TDText>{row.glucoseMGPerDL}</TDText>
        </TDContainer>
      </TD>
      <TD>
        <TDContainer>
          <TDText>
            {`${row.tag !== undefined ? getTag(row.tag) : ""}${
              row.afterMealTag !== undefined
                ? ` - ${getAfterMealTag(row.afterMealTag)}`
                : ""
            }`}
          </TDText>
        </TDContainer>
      </TD>
      <TD width={100}>
        <CrudListContainer>
          {state.kind === "isDeleting" && state.id === index && (
            <ActionIcons
              onConfirm={handleConfirmDelete}
              onCancel={cancelOrConfirm}
            />
          )}
          {state.kind === "idle" && <ActionsListDropdown index={index} />}
        </CrudListContainer>
      </TD>
    </tr>
  );
};
