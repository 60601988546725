import { DaysAggregatesReadingsWithTimeDifferenceValue } from "../build-readings-with-time-differance-value";
import { isGlucosePointVeryLow } from "./is-glucose-point-very-low";
import { removeTimeDifferanceValueFromReading } from "./remove-time-differance-value-from-reading";

export function calculateVeryLowReadingsTimeDiffrenceTotal(
  readingsWithTimeDifferenceValue: DaysAggregatesReadingsWithTimeDifferenceValue[]
) {
  let veryLowReadingsTimeDiffrenceTotal: number = 0;

  for (const readingWithTimeDifferenceValue of readingsWithTimeDifferenceValue) {
    if (
      isGlucosePointVeryLow(
        removeTimeDifferanceValueFromReading(readingWithTimeDifferenceValue)
      )
    ) {
      if (readingWithTimeDifferenceValue.timeDifference !== undefined) {
        if (readingWithTimeDifferenceValue.timeDifference > 15) {
          veryLowReadingsTimeDiffrenceTotal += 15;
        } else {
          veryLowReadingsTimeDiffrenceTotal +=
            readingWithTimeDifferenceValue.timeDifference;
        }
      }
    }
  }

  return veryLowReadingsTimeDiffrenceTotal;
}
