import styled from "styled-components/macro";
import { PatientProfileContainerBody } from "../../../../../single-patient/patient-profile/styles/patient-profile-styles/patient-profile-classes";
import {
  PatientProfileTimeLineList,
  PatientProfileTimeLineProgressBarEllipse,
  PatientProfileTimeLineProgressBarLine,
  PatientProfileTimeLineTextContainer,
} from "../../../../../single-patient/patient-profile/patient-profile-medical-history-section/patient-profile-timeline/styled-patient-profile-timeline";
import {
  PateintProfileColumnHeaderContainer,
  PateintProfileColumnHeaderTitle,
  PateintProfileColumnHeaderTitleIconContainer,
} from "../../../../../single-patient/patient-profile/components/pateint-profile-column-header/styled-pateint-profile-column-header";

export const ImmediateAttentionPatientsSidebarHistoryTabContainer = styled.div`
  ${PatientProfileTimeLineList} {
    width: 80%;
    margin: 0px;
    padding-left: 16px;
    padding-top: 0px;
  }

  ${PateintProfileColumnHeaderContainer} {
    margin-bottom: 16px;
    padding: 14px 16px 16px;
  }

  ${PateintProfileColumnHeaderTitleIconContainer} {
    width: 30px;
    height: 30px;

    img {
      width: 50%;
      height: 50%;
    }
  }

  ${PateintProfileColumnHeaderTitle} {
    font-size: 14px;
    font-weight: 600;
  }

  ${PatientProfileContainerBody} {
    display: flex;
  }

  ${PatientProfileTimeLineTextContainer} {
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }

  ${PatientProfileTimeLineProgressBarEllipse} {
    width: 10px;
    height: 10px;
  }

  ${PatientProfileTimeLineProgressBarLine} {
    height: 52px;
  }
`;

export const ImmediateAttentionPatientsSidebarHistoryPatientProfileItemsContainer = styled.div`
  display: grid;
  gap: 16px 0px;
`;
