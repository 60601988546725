import { TargetAudienceDTO } from "../../../../../models/target-audience-dtos/target-audience-dto";
import {
  TargetAudienceTagsEmptyStateText,
  TargetAudienceTagsList,
  TargetAudienceTagsListItem,
} from "./styled-target-audience-tags";

type TargetAudienceTagsProps = {
  fields: TargetAudienceDTO[];
  handleRemove: (index: number) => void;
};

export const TargetAudienceTags = (props: TargetAudienceTagsProps) => {
  const { fields, handleRemove } = props;

  if (fields.length === 0) {
    return (
      <TargetAudienceTagsEmptyStateText>
        Choose target audience
      </TargetAudienceTagsEmptyStateText>
    );
  }

  return (
    <TargetAudienceTagsList>
      {fields.map((field, index) => (
        <TargetAudienceTagsListItem
          key={field.id}
          onClick={() => handleRemove(index)}
        >
          {field.name}
          <span className="material-symbols-outlined">close</span>
        </TargetAudienceTagsListItem>
      ))}
    </TargetAudienceTagsList>
  );
};
