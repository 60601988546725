import { PATIENTS_PATH } from "../constants/paths";
import { useClient } from "../context/client-context";
import { useCallback } from "react";
import { PatientServingsMealResponseDTO } from "../models/patient-servings-meal-response-dto";

export function useGetPatientServingsMealResponses(): (
  patientId: number,
  startDate: string,
  endDate: string,
  spanInMinutes: number
) => Promise<PatientServingsMealResponseDTO[]> {
  const client = useClient();

  return useCallback(
    async (patientId, startDate, endDate, spanInMinutes) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientServingsMealResponseDTO[]>(
        `${PATIENTS_PATH}/${patientId}/servings-meal-responses`,
        {
          params: {
            startDate,
            endDate,
            spanInMinutes,
          },
        }
      );

      return response.data;
    },
    [client]
  );
}
