import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  flexColSpaceBetween,
  lgSemiBoldFont,
  regularSemiBoldFont,
  smallRegularFont,
} from "../../../../styles/classes/gloabl-classes";

export const LabResultsCardContainer = styled.div<{
  modalCard?: boolean;
}>`
  ${flexColSpaceBetween}
  width: ${({ modalCard }) => (modalCard ? "400px" : "100%")};
  height: 220px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  position: relative;

  @media screen and (max-width: 600px) {
    height: 270px;
  }
`;

export const LabResultsCardHeader = styled.div`
  ${flexCenterSpaceBetween}
`;

export const LabResultsCardBody = styled.div`
  ${flexCenterSpaceBetween}
  height: 100%;
`;

export const LabResultsCardIcons = styled.div`
  display: flex;
  gap: 0rem 0.6rem;

  span {
    font-size: 1.2rem;
    cursor: pointer;
    position: relative;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const LabResultsCardTextContainer = styled.div`
  flex: 1;
`;

export const LabResultsCardTitle = styled.p`
  ${regularSemiBoldFont}
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0;
`;

export const LabResultsCardSubTitle = styled.p`
  ${smallRegularFont}
  color: ${({ theme }) => theme.colors.textColor};
  margin: 0;
`;

export const LabResultsCardUnit = styled.p`
  ${lgSemiBoldFont}
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0;

  @media screen and (max-width: 575px) {
    ${regularSemiBoldFont}
  }
`;

export const LabResultsCardRange = styled.div`
  font-size: 10px;
  font-weight: 100;
  color: ${({ theme }) => theme.colors.textColor};

  div {
    display: flex;
    align-items: center;

    p {
      margin: 0px 0px 4px 16px;
      text-align: left;

      @media screen and (max-width: 600px) {
        margin: 0px;
      }
    }

    span {
      text-align: left;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
