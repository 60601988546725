import { TooltipSettingsModel } from "@syncfusion/ej2-react-charts";
import {
  PatientProfileLifeActivitiesTooltip,
  PatientProfileLifeActivitiesTooltipProps,
} from "./patient-profile-life-activities-tooltip";

export function buildPatientProfileLifeActivitiesTooltip(
  hasToolTip: boolean
): TooltipSettingsModel {
  // Not the cleanest code, but that's how they do it in the Syncfusion docs:
  // https://ej2.syncfusion.com/react/documentation/chart/tool-tip/#tooltip-template
  const template: any = chartTemplate;
  return {
    enable: hasToolTip,
    template,
  };
}

function chartTemplate(args: {
  tooltip: PatientProfileLifeActivitiesTooltipProps;
}) {
  return <PatientProfileLifeActivitiesTooltip {...args.tooltip} />;
}
