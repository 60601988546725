import {
  useOptionalPatientUser,
  useOptionalUser,
} from "./context/auth-context/auth-context";
import { UnauthenticatedApp } from "./unauthenticated-app";
import { AuthenticatedApp } from "./authenticated-app";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "./constants/config";
import "./styles/google-charts-tooltip.css";
import "./styles/App.css";
import { PatientAuthenticatedApp } from "./patient-authenticated-app";

function App() {
  const user = useOptionalUser();
  const patientUser = useOptionalPatientUser();

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      {user === undefined && patientUser === undefined && (
        <UnauthenticatedApp />
      )}
      {user !== undefined && <AuthenticatedApp />}
      {patientUser !== undefined && <PatientAuthenticatedApp />}
    </GoogleOAuthProvider>
  );
}

export default App;
