import { CarbCounterV1MealSummaryDTO } from "../../../../../../../../../models/carb-counter-dtos/carb-counter-v1-meal-summary-dto";
import { PatientMealMacrosSummaryDTO } from "../../../../../../../../../models/patient-meal-dtos/patient-meal-macros-summary-dto";
import { GroupedPatientMealsByDate } from "../../../../../context/nutrition-analysis-data-context";

type MealsTagsSeriesDataSource = {
  x: string;
  y?: number;
  y1?: number;
  y2?: number;
  y3?: number;
};

export function buildMealsTagsSeries(
  groupedPatientMealsByDate: GroupedPatientMealsByDate[]
) {
  const mealsTagsSereis: MealsTagsSeriesDataSource[] = [];

  for (const groupedPatientMeals of groupedPatientMealsByDate) {
    const date = groupedPatientMeals.date;
    const patientMeals = groupedPatientMeals.patientMeals;

    for (const patientMeal of patientMeals) {
      const carbCounterV1MealSummary = patientMeal.carbCounterV1MealSummary;
      const patientMealMacrosSummary = patientMeal.patientMealMacrosSummary;

      if (carbCounterV1MealSummary) {
        generateMealTagSeriesCarbCounterItem(
          date,
          carbCounterV1MealSummary,
          patientMeal.tag,
          mealsTagsSereis
        );
      } else {
        generateMealTagSeriesMacrosSummaryItem(
          date,
          patientMealMacrosSummary,
          patientMeal.tag,
          mealsTagsSereis
        );
      }
    }
  }

  return mealsTagsSereis;
}

function generateMealTagSeriesCarbCounterItem(
  date: string,
  carbCounterV1MealSummary: CarbCounterV1MealSummaryDTO,
  tag: "Breakfast" | "Lunch" | "Dinner" | "SnackOrOther" | undefined,
  mealsTagsSereis: MealsTagsSeriesDataSource[]
) {
  if (tag === "Breakfast") {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y: carbCounterV1MealSummary.totalKcal,
    });
  }

  if (tag === "Lunch") {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y1: carbCounterV1MealSummary.totalKcal,
    });
  }

  if (tag === "SnackOrOther") {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y2: carbCounterV1MealSummary.totalKcal,
    });
  }

  if (tag === "Dinner") {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y3: carbCounterV1MealSummary.totalKcal,
    });
  }
}

function generateMealTagSeriesMacrosSummaryItem(
  date: string,
  patientMealMacrosSummary: PatientMealMacrosSummaryDTO,
  tag: "Breakfast" | "Lunch" | "Dinner" | "SnackOrOther" | undefined,
  mealsTagsSereis: MealsTagsSeriesDataSource[]
) {
  if (tag === "Breakfast") {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y: patientMealMacrosSummary.kCals,
    });
  }

  if (tag === "Lunch") {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y1: patientMealMacrosSummary.kCals,
    });
  }

  if (tag === "SnackOrOther") {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y2: patientMealMacrosSummary.kCals,
    });
  }

  if (tag === "Dinner") {
    mealsTagsSereis.push({
      x: generateXaxisValue(date),
      y3: patientMealMacrosSummary.kCals,
    });
  }
}

function generateXaxisValue(date: string) {
  return new Date(date).toLocaleDateString("en-UK", {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
  });
}
