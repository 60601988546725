import { Modal } from "../../../../components/modal/modal";
import { ModalForm } from "../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../styles/classes/reusable-classes";
import { LessonStatusMessageModalContainer } from "./styled-lesson-status-message-modal";

type LessonStatusMessageModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  modalBodyKey: number;
};

export const LessonStatusMessageModal = (
  props: LessonStatusMessageModalProps
) => {
  const { closeModal, isModalOpen, modalBodyKey } = props;

  return (
    <Modal handleHideModal={closeModal} showModal={isModalOpen}>
      <ModalForm
        key={modalBodyKey}
        onSubmit={(e) => e.preventDefault()}
        width={500}
        height={300}
      >
        <ModalHeader title="Lesson Status Disabled" onModalClose={closeModal} />
        <LessonStatusMessageModalContainer>
          <h3 style={{ textAlign: "center" }}>
            Add one or more cards to activate the Lesson
          </h3>
        </LessonStatusMessageModalContainer>
        <ModalFooter>
          <Button type="button" onClick={closeModal}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
