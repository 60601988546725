import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppProviders } from "./context/app-providers";
import { registerLicense } from "@syncfusion/ej2-base";
import * as Sentry from "@sentry/react";
import { SENTRY_DSN, SENTRY_ENVIRONMENT } from "./constants/config";

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
});

// To Solve This Error:-
// 1) https://ej2.syncfusion.com/react/documentation/licensing/license-key-registration
// 2) Pair with Jimmy

registerLicense(
  "Mgo+DSMBaFt+QHJqVk1hXk5Hd0BLVGpAblJ3T2ZQdVt5ZDU7a15RRnVfR19qSX1RdURhXHtedw==;Mgo+DSMBPh8sVXJ1S0R+X1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jTHxadkZhWn5cdXFXQQ==;ORg4AjUWIQA/Gnt2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtRf0VgW3xfcnFTQmc=;MjIzMjkxMEAzMjMxMmUzMDJlMzBteVpGZHJPaUl1em1zSHhiYktZcUF5TklMengrT2NvSWlKWHk0b05uN01vPQ==;MjIzMjkxMUAzMjMxMmUzMDJlMzBHUjM2MnhNUzRwbmdCL282KzQyR3hOVkR4K2pibkFhT3N6S2J3MXVPblJnPQ==;NRAiBiAaIQQuGjN/V0d+Xk9HfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdExjWn1dcXZTRGhb;MjIzMjkxM0AzMjMxMmUzMDJlMzBWN25SRS9lTkZhV2pvWk1HaWRuNFN1T3hDQ0pUangyQ2txajkzNHEyNnZrPQ==;MjIzMjkxNEAzMjMxMmUzMDJlMzBHc2loSys1cGppMFpaU1RSdmlqWlVtUENwWHF2dm9kenZHaHFLQkZxWHV3PQ==;Mgo+DSMBMAY9C3t2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtRf0VgW3xfcnNTQGc=;MjIzMjkxNkAzMjMxMmUzMDJlMzBtWXoxd2NVOGdOUU44UHJpQTJxVFlJcTN0NW4rdDBTczlBSC91aFNLMWNrPQ==;MjIzMjkxN0AzMjMxMmUzMDJlMzBHbERIem9zbFcxNUMwOHFGUEtBM3RTWlBXYUxvSGo0YjBndmxtWTR0WmRJPQ==;MjIzMjkxOEAzMjMxMmUzMDJlMzBWN25SRS9lTkZhV2pvWk1HaWRuNFN1T3hDQ0pUangyQ2txajkzNHEyNnZrPQ=="
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AppProviders>
    <App />
  </AppProviders>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
