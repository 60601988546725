import { PropsWithChildren, useRef, MouseEvent } from "react";
import { DropdownListItem } from "../../../../components/dropdown/styled-dropdown";

export type DropdownListItemFileUploadProps = PropsWithChildren<{
  onFileSelect: (fileContent: string) => void;
}>;

export function DropdownListItemFileUpload(
  props: DropdownListItemFileUploadProps
) {
  const { children, onFileSelect } = props;

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  function handleListItemClick() {
    fileInputRef.current?.click();
  }

  // Called when we synthetically click the file input through its ref
  function handleFileInputClick(e: MouseEvent<HTMLInputElement>) {
    // Reset the file input so that the same file can be selected again.
    // See: https://stackoverflow.com/a/40429197/6690391
    e.currentTarget.value = "";
  }

  async function handleFileInputChange() {
    const fileContent = await fileInputRef.current!.files![0].text();
    onFileSelect(fileContent);
  }

  return (
    <DropdownListItem onClick={handleListItemClick}>
      {children}
      <input
        style={{ display: "none" }}
        type="file"
        ref={fileInputRef}
        onClick={handleFileInputClick}
        onChange={() => handleFileInputChange()}
      />
    </DropdownListItem>
  );
}
