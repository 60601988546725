import styled from "styled-components/macro";
import { NavbarHeight } from "../../../styles/global-style";
import { TableContainer } from "../../../components/table/styled-table";
import { flexCol } from "../../../styles/classes/gloabl-classes";

export const OrganizationsTableEmptyStateContainer = styled.div`
  height: calc(100vh - ${NavbarHeight});
  display: grid;
  place-items: center;
`;

export const OrganizationsTableContainer = styled.div`
  ${TableContainer} {
    height: calc(100vh - ${NavbarHeight});
    ${flexCol}
  }
`;

export const OrganizationsTableViewLink = styled.p`
  margin: 0px;

  a {
    color: inherit;
    text-decoration: none;
  }
`;
