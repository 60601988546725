import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../../../styles/classes/reusable-classes";

export const CreatePrescription = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Button
      flex
      onClick={() => navigate(`/patients/${id}/prescriptions/new-prescription`)}
    >
      <span className="material-symbols-outlined">add</span>
      New Prescription
    </Button>
  );
};
