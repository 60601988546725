import styled from "styled-components/macro";
import { flexCenterSpaceBetween } from "../../../styles/classes/gloabl-classes";
import { NavbarHeight } from "../../../styles/global-style";

export const OrganizationsHeaderWrapper = styled.div`
  ${flexCenterSpaceBetween}
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.containerColor};
  max-height: 99px;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

export const OrganizationsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    font-size: 21px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
    margin-bottom: 16px;

    @media screen and (max-width: 650px) {
      margin: 0 auto 16px;
      border-bottom: 1px solid transparent;
    }
  }

  @media screen and (max-width: 650px) {
    margin-bottom: 16px;
  }
`;

const OrganizationVariables = {
  organizationHeaderHeightAndPadding: 99 + 24,
  organizationScreenNavbarHeightAndMargin: 32 + 16,
};

export const OrganizationSubscreenContainer = styled.div`
  margin: 24px 16px;
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 24px 16px;
  border-radius: 8px;
  min-height: calc(
    100vh -
      (
        ${NavbarHeight} + 24px +
          ${OrganizationVariables.organizationHeaderHeightAndPadding}px +
          ${OrganizationVariables.organizationScreenNavbarHeightAndMargin}px
      )
  );
`;
