import { createContext, PropsWithChildren, useContext } from "react";
import { PatientDTO } from "../../../models/patient-dtos/patient-dto";
import { UseLoadableDataReturn } from "../../../hooks/use-loadable-data";

export type LoadablePatients = UseLoadableDataReturn<PatientDTO[]>;

const LoadablePatientsContext = createContext<LoadablePatients | undefined>(
  undefined
);

export type LoadablePatientsProviderProps = PropsWithChildren<{
  loadablePatients: LoadablePatients;
}>;

export function LoadablePatientsProvider(props: LoadablePatientsProviderProps) {
  const { loadablePatients, children } = props;

  return (
    <LoadablePatientsContext.Provider value={loadablePatients}>
      {children}
    </LoadablePatientsContext.Provider>
  );
}

export function useLoadablePatients(): LoadablePatients {
  const loadablePatients = useContext(LoadablePatientsContext);
  if (loadablePatients === undefined) {
    throw Error(
      "useLoadablePatients must be used within an LoadablePatientsProvider"
    );
  }

  return loadablePatients;
}

export function usePatients(): PatientDTO[] {
  const loadablePatients = useLoadablePatients();
  if (loadablePatients.state.data === undefined) {
    throw Error("Patients have never been loaded successfully yet.");
  }

  return loadablePatients.state.data;
}

export function useReloadPatients(): () => void {
  const loadablePatients = useLoadablePatients();
  return loadablePatients.reload;
}
