import { Fragment, useMemo } from "react";
import { Temporal } from "temporal-polyfill";
import { PatientLabTestResultDTO } from "../../../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import { roundTo3DecimalPlaces } from "../../../../../../utils/math-utils";
import { GroupedLabTestResultsByType } from "../../lab-result-test";
import { LabResultsIndicators } from "./lab-results-indicators/lab-results-indicators";
import {
  LabResultLabResultTestResultsContainer,
  LabResultReadingContainer,
  LabResultTestResultsContainerHeader,
  LabResultTestResultsContainerHeaderDates,
  LabResultTestResultsGridLayout,
  LabResultTestResultsGridLayoutContainer,
  LabResultNumberContainer,
  LabResultNumber,
} from "./styled-lab-result-test-results";

type LabResultTestResultsProps = {
  filteredLabTestResults: PatientLabTestResultDTO[];
  groupedLabTestResultsByType: GroupedLabTestResultsByType;
};

type GroupedLabTestResultsByDateType = Record<
  string,
  PatientLabTestResultDTO[]
>;

export const LabResultTestResults = (props: LabResultTestResultsProps) => {
  const { filteredLabTestResults, groupedLabTestResultsByType } = props;

  const groupedLabTestResultsByDate = useMemo(
    () =>
      filteredLabTestResults.reduce(
        (
          acc: GroupedLabTestResultsByDateType,
          curr: PatientLabTestResultDTO
        ) => {
          if (!acc[curr.date]) {
            acc[curr.date] = [];
          }
          acc[curr.date].push(curr);
          return acc;
        },
        {}
      ),
    [filteredLabTestResults]
  );

  const sortedGroupedLabTestResultsByDateKey = useMemo(
    () =>
      Object.keys(groupedLabTestResultsByDate).sort((a, b) => {
        return Temporal.PlainDate.compare(b, a);
      }),
    [groupedLabTestResultsByDate]
  );

  const groupedDataKeys = useMemo(
    () =>
      sortedGroupedLabTestResultsByDateKey.map((date, index) => {
        const displayDate = Temporal.PlainDate.from(date).toLocaleString(
          "en-UK",
          {
            day: "numeric",
            month: "short",
            year: "2-digit",
          }
        );
        return <span key={index}>{displayDate}</span>;
      }),
    [sortedGroupedLabTestResultsByDateKey]
  );

  return (
    <LabResultLabResultTestResultsContainer>
      <LabResultTestResultsContainerHeader>
        <LabResultTestResultsContainerHeaderDates>
          {groupedDataKeys}
        </LabResultTestResultsContainerHeaderDates>
      </LabResultTestResultsContainerHeader>
      <LabResultTestResultsGridLayoutContainer>
        {groupedLabTestResultsByType.map((item) => {
          const latestResult =
            item.sortedLabTestResultsByDate[
              item.sortedLabTestResultsByDate.length - 1
            ];

          return (
            <LabResultTestResultsGridLayoutContainer
              key={item.labTestTypeDTO.name}
            >
              <LabResultTestResultsGridLayout>
                {sortedGroupedLabTestResultsByDateKey.map((date, dateKey) => {
                  return (
                    <LabResultReadingContainer key={dateKey}>
                      {Object.values(groupedLabTestResultsByDate).map(
                        (value, index) => (
                          <div key={index}>
                            {value
                              .sort((a, b) => {
                                return Temporal.PlainDate.compare(
                                  a.date,
                                  b.date
                                );
                              })
                              .map((patientLabTestResultDTO) => {
                                return (
                                  <Fragment key={patientLabTestResultDTO.id}>
                                    {patientLabTestResultDTO.date === date &&
                                    patientLabTestResultDTO.labTestTypeId ===
                                      item.labTestTypeDTO.id ? (
                                      <LabResultNumberContainer>
                                        <LabResultsIndicators
                                          item={item}
                                          patientLabTestResultDTO={
                                            patientLabTestResultDTO
                                          }
                                        />
                                        <LabResultNumber
                                          latestResult={
                                            patientLabTestResultDTO.result ===
                                            latestResult.result
                                          }
                                        >
                                          {roundTo3DecimalPlaces(
                                            patientLabTestResultDTO.result
                                          )}
                                        </LabResultNumber>
                                      </LabResultNumberContainer>
                                    ) : (
                                      ""
                                    )}
                                  </Fragment>
                                );
                              })}
                          </div>
                        )
                      )}
                    </LabResultReadingContainer>
                  );
                })}
              </LabResultTestResultsGridLayout>
            </LabResultTestResultsGridLayoutContainer>
          );
        })}
      </LabResultTestResultsGridLayoutContainer>
    </LabResultLabResultTestResultsContainer>
  );
};
