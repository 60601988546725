import {
  buildPatientProfileHeaderBottomCards,
  displayBMI_Indicators,
} from "../../../../patient-profile/utils/patient-profile-utils";
import { useSinglePatientSidebar } from "../../../context/loadable-single-patient-sidebar-context";
import {
  PersonalInfoGroup,
  PersonalInfoGroupTitle,
  PersonalInfoGroupTop,
} from "../../personal-info-tab/styled-personal-info-tab";

export const ObesityProcedure = () => {
  const { patientDTO, patientWeightDTOs, patientBloodPressureDTOs } =
    useSinglePatientSidebar();

  const currentBMI = buildPatientProfileHeaderBottomCards(
    patientDTO,
    patientBloodPressureDTOs,
    patientWeightDTOs
  ).find((item) => item.name === "BMI")?.value;

  const obesity = displayBMI_Indicators(Number(currentBMI)).includes("Obesity")
    ? "Yes"
    : "No";

  return (
    <PersonalInfoGroup>
      <PersonalInfoGroupTop>
        <PersonalInfoGroupTitle>Obesity Procedure</PersonalInfoGroupTitle>
        <PersonalInfoGroupTitle>{obesity}</PersonalInfoGroupTitle>
      </PersonalInfoGroupTop>
    </PersonalInfoGroup>
  );
};
