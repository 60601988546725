import { Outlet } from "react-router-dom";
import { HomeHeader } from "./home-header/home-header";
import { HomeStaticDataProvider } from "./context/home-static-data-context";

export const Home = () => {
  return (
    <HomeStaticDataProvider>
      <div style={{ overflow: "hidden" }}>
        <HomeHeader />
        <Outlet />
      </div>
    </HomeStaticDataProvider>
  );
};
