import { Button } from "../../../../../styles/classes/reusable-classes";
import { usePlansGoals } from "../../context/goals-context";
import { PlanStepInfo } from "../../components/plan-step-info/plan-step-info";
import { GoalsListItem } from "../styles";
import {
  AssignedGoalsContainer,
  GoalsScreenContainer,
} from "../goals/styled-goals";
import { ReviewCard } from "./styled-review";
import { PlanStepsHeader } from "../../components/plan-steps-header/plan-steps-header";

export const ReviewGoalsList = () => {
  const { assignedGoals, handleDeleteGoal } = usePlansGoals();

  const goals = assignedGoals.length > 0 && (
    <ReviewCard>
      <PlanStepsHeader
        title="Overall Goals"
        subTitle="Adding a goal indicator for the paitent"
        icon="/img/plans/nav/goals.svg"
        button={<></>}
      />
      <GoalsScreenContainer>
        <AssignedGoalsContainer>
          {assignedGoals.map((goal, index) => (
            <GoalsListItem key={index}>
              <PlanStepInfo
                arabicFlex
                icon="/img/plans/nav/goals.svg"
                title={goal.goal}
                subTitle=""
              />
              <Button
                outlinedPrimary
                flex
                type="button"
                onClick={() =>
                  handleDeleteGoal({ goal: goal.goal, goalType: "Goal" })
                }
              >
                <img src="/img/prescription/delete.svg" alt="Delete Icon" />
                Delete
              </Button>
            </GoalsListItem>
          ))}
        </AssignedGoalsContainer>
      </GoalsScreenContainer>
    </ReviewCard>
  );

  return <>{goals}</>;
};
