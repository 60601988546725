import { Fragment, useCallback } from "react";
import { PatientDetailedPlanPatientPlanFeedbackDTO } from "../../../../../../models/patient-plan-dtos/patient-detailed-plan-patient-plan-feedback-dto";
import { WeightProgressChart } from "../../../../single-patient-sidebar/patient-sidebar-tabs/personal-info-tab/body-measurments/weight-progress-modal/weight-progress-modal-body/weight-progress-chart";
import { DeleteFeedback } from "../delete-feedback/delete-feedback";
import { ListBloodGlucoseChart } from "./list-blood-glucose-chart";
import { ListLabResultCards } from "./list-lab-result-cards";
import {
  FeedbackCard,
  FeedbackCardBody,
  FeedbackCardBodyTitle,
  FeedbackCardButtonsContainer,
  FeedbackCardCharts,
  FeedbackCardHeader,
  FeedbackCardHeaderTextContainer,
  FeedbackChartContainer,
} from "../styled-feedback";
import { ListMealGraphChart } from "./list-meal-graph-chart";
import { BgmChart } from "../../../../../../components/bgm-chart/bgm-chart";
import { useBuildDayAggregates } from "../../../../../../components/cgm-google-chart/hooks/use-build-day-aggregates";
import { useDetailedPlan } from "../../../context/loadable-detailed-plan-context";

type ListFeedbackProps = {
  item: PatientDetailedPlanPatientPlanFeedbackDTO;
};

export const ListFeedback = (props: ListFeedbackProps) => {
  const { item } = props;

  const {
    patientWeightDTOs,
    patientDTO,
    labTestTypeRefDTOs,
    labTestTypeDTOs,
    patientLabTestResultsDTOs,
  } = useDetailedPlan();

  const feedbackDayAggregates = true;

  const { filteredDaysAggregatesByDate } = useBuildDayAggregates(
    patientDTO,
    feedbackDayAggregates
  );

  const weightDTOs = useCallback(
    (startDate: string, endDate: string) =>
      patientWeightDTOs.filter(
        (item) => item.date >= startDate && item.date <= endDate
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <FeedbackCard>
      <FeedbackCardHeader>
        <FeedbackCardHeaderTextContainer>
          <img
            src={`/img/plans/${item.feedbackTone}.svg`}
            alt={`${item.feedbackTone}`}
          />
          <p>{item.notes}</p>
        </FeedbackCardHeaderTextContainer>
        <FeedbackCardButtonsContainer>
          <DeleteFeedback item={item} />
        </FeedbackCardButtonsContainer>
      </FeedbackCardHeader>
      {item.labTestTypeId === undefined && item.feedbackType === "Custom" ? (
        <></>
      ) : (
        <FeedbackCardBody>
          <FeedbackCardBodyTitle>
            {item.feedbackType === "BloodGlucoseManual"
              ? `Manual Readings ${item.bloodGlucoseManualTag}`
              : item.feedbackType === "BloodGlucose"
              ? `Blood Glucose Readings`
              : item.feedbackType === "MealGraph"
              ? `Meal Graphs`
              : item.feedbackType === "Weight"
              ? `Weight graph`
              : item.labTestTypeId !== undefined
              ? item.feedbackType
              : ``}
          </FeedbackCardBodyTitle>
          {item.weightStartDate !== undefined &&
            item.weightEndDate !== undefined && (
              <FeedbackChartContainer>
                <WeightProgressChart
                  dataSource={weightDTOs(
                    item.weightStartDate,
                    item.weightEndDate
                  )}
                />
              </FeedbackChartContainer>
            )}
          {item.bloodGlucoseManualStartDate !== undefined &&
            item.bloodGlucoseManualEndDate && (
              <BgmChart
                startDate={item.bloodGlucoseManualStartDate}
                endDate={item.bloodGlucoseManualEndDate}
                tagValue={
                  item.bloodGlucoseManualTag !== undefined
                    ? item.bloodGlucoseManualTag
                    : "All"
                }
                containerHeight="350px"
                patientType={patientDTO.type}
                hasTooltip={false}
                fullWidth
              />
            )}
          {item.labTestResultsStartDate !== undefined &&
            item.labTestResultsEndDate !== undefined &&
            item.labTestTypeId !== undefined && (
              <ListLabResultCards
                labTestTypeRefDTOs={labTestTypeRefDTOs}
                labTestTypeId={item.labTestTypeId}
                startDate={item.labTestResultsStartDate}
                endDate={item.labTestResultsEndDate}
                labTestTypeDTOs={labTestTypeDTOs}
                patientLabTestResultsDTOs={patientLabTestResultsDTOs}
              />
            )}
          <FeedbackCardCharts>
            {item.attachments?.map((attachment, index) => {
              if (attachment.attachmentType === "MealGraphChart") {
                return (
                  <ListMealGraphChart key={index} attachment={attachment} />
                );
              } else if (attachment.attachmentType === "BloodGlucoseChart") {
                return (
                  <Fragment key={index}>
                    {filteredDaysAggregatesByDate(
                      attachment.bloodGlucoseChartDate!
                    ).map((item) => (
                      <ListBloodGlucoseChart
                        key={item.date.toString()}
                        dayAggregate={item}
                        viewPlanChart={false}
                      />
                    ))}
                  </Fragment>
                );
              } else {
                return <></>;
              }
            })}
          </FeedbackCardCharts>
        </FeedbackCardBody>
      )}
    </FeedbackCard>
  );
};
