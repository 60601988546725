import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../styles/classes/reusable-classes";

type ReadContentModuleOrderControllerProps = {
  contentModuleOrder?: number;
};

export const ReadContentModuleOrderController = (
  props: ReadContentModuleOrderControllerProps
) => {
  const { contentModuleOrder } = props;

  if (contentModuleOrder === undefined) {
    return <></>;
  }

  return (
    <UXInputContainer $width="100px">
      <UXInputLabel $width="30px">Order</UXInputLabel>
      <UXInputWrapper $width="50px">
        <UXInputInput
          type="number"
          readOnly
          defaultValue={contentModuleOrder}
        />
      </UXInputWrapper>
    </UXInputContainer>
  );
};
