import { useCallback, useEffect, useMemo } from "react";
import {
  ImmediateAttentionPatientsFiltersSidebarPriorityContainer,
  ImmediateAttentionPatientsFiltersSidebarPriorityList,
  ImmediateAttentionPatientsFiltersSidebarPriorityListItem,
} from "./styled-immediate-attention-patients-filters-sidebar-priority";
import { useHomeStaticData } from "../../../../../../context/home-static-data-context";
import { ImmediateAttentionPatientsFiltersSidebarFilterTitle } from "../styled-immediate-attention-patients-filters-sidebar";
import { useImmediateAttentionPatientsSelectedFilters } from "../../../../../context/immediate-attention-patients-selected-filters-context";

export const ImmediateAttentionPatientsFiltersSidebarPriority = () => {
  const {
    setSelectedFilters,
    selectedFilters,
    priorityOptions,
    setPriorityOptions,
  } = useImmediateAttentionPatientsSelectedFilters();

  const { homeStaticData, setFilteredHomeStaticData } = useHomeStaticData();

  const handleAddFilter = useCallback(
    (newPriorityOptions: string[]) => {
      const priority = newPriorityOptions.join(", ");
      const existingFilter = selectedFilters.find(
        (filter) => filter.filterName === "priority"
      );

      if (existingFilter) {
        // Update the existing filter
        setSelectedFilters(
          selectedFilters.map((filter) =>
            filter.filterName === "priority"
              ? { ...filter, filterValue: priority }
              : filter
          )
        );
      } else {
        // Add a new filter
        setSelectedFilters([
          ...selectedFilters,
          { filterName: "priority", filterValue: priority },
        ]);
      }
    },
    [selectedFilters, setSelectedFilters]
  );

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const newPriorityOptions = checked
      ? [...priorityOptions, value]
      : priorityOptions.filter((priority) => priority !== value);

    setPriorityOptions(newPriorityOptions);
    handleAddFilter(newPriorityOptions); // Update filters based on the new state
  };

  const filteredPatients = useMemo(() => {
    if (priorityOptions.length === 0) return homeStaticData;

    return homeStaticData.filter((patient) =>
      priorityOptions.includes(patient.priority)
    );
  }, [priorityOptions, homeStaticData]);

  useEffect(() => {
    setFilteredHomeStaticData(filteredPatients);
  }, [filteredPatients, setFilteredHomeStaticData]);

  return (
    <ImmediateAttentionPatientsFiltersSidebarPriorityContainer>
      <ImmediateAttentionPatientsFiltersSidebarFilterTitle>
        Priority
      </ImmediateAttentionPatientsFiltersSidebarFilterTitle>
      <ImmediateAttentionPatientsFiltersSidebarPriorityList>
        {["High", "Medium", "Low"].map((p) => (
          <ImmediateAttentionPatientsFiltersSidebarPriorityListItem
            key={p}
            $color={p}
          >
            <input
              type="checkbox"
              value={p}
              checked={priorityOptions.includes(p)}
              onChange={handleCheckboxChange}
            />
            <div />
            <p>{p}</p>
          </ImmediateAttentionPatientsFiltersSidebarPriorityListItem>
        ))}
      </ImmediateAttentionPatientsFiltersSidebarPriorityList>
    </ImmediateAttentionPatientsFiltersSidebarPriorityContainer>
  );
};
