import { Link, useParams } from "react-router-dom";
import {
  TD,
  TDContainer,
  TDLink,
} from "../../../../../components/table/styled-table";
import { GenericActionsList } from "../../../../../components/generic-actions-list/generic-actions-list";
import { GenericActionsListItemItem } from "../../../../../components/generic-actions-list/styled-generic-actions-list";
import { useMemo } from "react";
import { DeleteConentCourseModule } from "./delete-conent-course-module";
import { ContentModuleDTO } from "../../../../../models/content-module-dtos/content-module-dto";
import {
  ContentScreenTableRowsStatusContainer,
  ContentScreenTableRowsThumbnailContainer,
} from "../../../styles/content-reusable-classes";

type ContentCourseModulesTableRowsProps = {
  searchTerm: string;
  contentModuleDTO: ContentModuleDTO;
};

export const ContentCourseModulesTableRows = (
  props: ContentCourseModulesTableRowsProps
) => {
  const { searchTerm, contentModuleDTO } = props;

  const childContentModules = contentModuleDTO.childContentModules;

  const { courseID } = useParams();
  const parentContentModuleId = parseInt(courseID!);

  const sorted = useMemo(
    () =>
      childContentModules !== undefined
        ? childContentModules.sort((a, b) => {
            if (a.uiOrder !== undefined && b.uiOrder !== undefined) {
              return a.uiOrder - b.uiOrder;
            } else if (a.uiOrder !== undefined) {
              return -1;
            } else if (b.uiOrder !== undefined) {
              return 1;
            } else {
              return a.name.localeCompare(b.name);
            }
          })
        : [],
    [childContentModules]
  );

  const filtered = useMemo(
    () =>
      sorted.filter((item) => {
        if (searchTerm === "") {
          return item;
        } else if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        } else {
          return 0;
        }
      }),
    [sorted, searchTerm]
  );

  const tableRows = filtered.map((moduleDTO) => (
    <tr key={moduleDTO.id}>
      <TD width={100}>
        <TDContainer>
          <span>{moduleDTO.uiOrder}</span>
        </TDContainer>
      </TD>
      <TD width={100}>
        <ContentScreenTableRowsThumbnailContainer>
          {moduleDTO.thumbnailUploadedFile?.presignedUrl && (
            <img
              src={moduleDTO.thumbnailUploadedFile?.presignedUrl}
              alt={`${moduleDTO.name}'s Course Thumbnail`}
            />
          )}
        </ContentScreenTableRowsThumbnailContainer>
      </TD>
      <TD>
        <TDContainer>
          <TDLink>
            <Link
              to={`../courses/course/${parentContentModuleId}/module/${moduleDTO.id}`}
            >
              {moduleDTO.name}
            </Link>
          </TDLink>
        </TDContainer>
      </TD>
      <TD>
        <ContentScreenTableRowsStatusContainer
          $live={moduleDTO.isDraft ? false : true}
        >
          <span>{moduleDTO.isDraft ? "Darft" : "Live"}</span>
        </ContentScreenTableRowsStatusContainer>
      </TD>
      <TD width={100}>
        <TDContainer>
          <GenericActionsList>
            <Link
              to={`../courses/course/${parentContentModuleId}/module/${moduleDTO.id}`}
            >
              <GenericActionsListItemItem>View</GenericActionsListItemItem>
            </Link>
            <Link
              to={`../courses/course/${moduleDTO.parentContentModuleId}/module/${moduleDTO.id}/update`}
            >
              <GenericActionsListItemItem>Update</GenericActionsListItemItem>
            </Link>
            <DeleteConentCourseModule moduleDTO={moduleDTO} />
          </GenericActionsList>
        </TDContainer>
      </TD>
    </tr>
  ));

  return <>{tableRows}</>;
};
