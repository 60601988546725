import { PatientMealDTO } from "../../../models/patient-meal-dtos/patient-meal-dto";
import { useMemo } from "react";
import { UpdatePatientMealCarbSourceDTO } from "../../../models/patient-meal-dtos/update-patient-meal-carb-source-dto";
import { FormProvider, useForm } from "react-hook-form";
import { UpdatePatientMealFoodGroupDTO } from "../../../models/patient-meal-dtos/update-patient-meal-food-group-dto";
import { UpdatePatientMealProteinSourceDTO } from "../../../models/patient-meal-dtos/update-patient-meal-protein-source-dto";
import { FoodLoggingPatientsTab } from "../food-logging-patients/food-logging-patients-tabs/food-logging-patients-tabs";
import { FoodLoggingSelectedPatientMealFormContextChildren } from "./food-logging-selected-patient-meal-form-context-children";
import { UpdatePatientMealFoodDTO } from "../../../models/patient-meal-dtos/update-patient-meal-food-dto";

export type FoodLoggingSelectedPatientMealProps = {
  patientMealDTO: PatientMealDTO;
  selectedTab: FoodLoggingPatientsTab;
};

export type FoodLoggingSelectedPatientMealInputs = {
  carbSources: UpdatePatientMealCarbSourceDTO[];
  proteinSources: UpdatePatientMealProteinSourceDTO[];
  foodGroups: UpdatePatientMealFoodGroupDTO[];
  feedback?: string;
  patientMealFoods?: UpdatePatientMealFoodDTO[];
};

export function FoodLoggingSelectedPatientMeal(
  props: FoodLoggingSelectedPatientMealProps
) {
  const { patientMealDTO, selectedTab } = props;

  // It'll be used only once. But using `useMemo` to avoid unnecessary re-computations.
  const defaultValues: FoodLoggingSelectedPatientMealInputs = useMemo(() => {
    return {
      carbSources: patientMealDTO.patientMealCarbSources.map(
        ({ carbSourceId }) => ({ carbSourceId })
      ),
      proteinSources: patientMealDTO.patientMealProteinSources.map(
        ({ proteinSourceId }) => ({ proteinSourceId })
      ),
      foodGroups: patientMealDTO.patientMealFoodGroups.map(
        ({ foodGroupId, servingsCount }) => ({ foodGroupId, servingsCount })
      ),
      feedback:
        patientMealDTO.feedback !== undefined
          ? patientMealDTO.feedback
          : undefined,
    };
  }, [patientMealDTO]);

  const foodLoggingSelectedPatientMealFormMethods =
    useForm<FoodLoggingSelectedPatientMealInputs>({
      defaultValues,
    });

  return (
    <FormProvider {...foodLoggingSelectedPatientMealFormMethods}>
      <FoodLoggingSelectedPatientMealFormContextChildren
        patientMealDTO={patientMealDTO}
        selectedTab={selectedTab}
      />
    </FormProvider>
  );
}
