import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  mediumFont,
} from "../../../styles/classes/gloabl-classes";
import { NavbarHeight, SidebarWidth } from "../../../styles/global-style";

export const NavigationNav = styled.nav`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.containerColor};
  padding: ${({ theme }) => theme.padding.pad05_1};
  height: ${NavbarHeight};
  position: fixed;
  top: 0;
  right: 0;
  left: ${SidebarWidth};
  z-index: ${({ theme }) => theme.zIndex.z400};

  @media screen and (max-width: 500px) {
    left: 0;
    ${flexCenterSpaceBetween}
  }
`;

export const NavigationUserInfo = styled.div`
  ${flexCenterSpaceBetween}
  width: 170px;
  margin-left: auto;
  border-radius: 0.5rem;
  padding: ${({ theme }) => theme.padding.pad05All};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};

  @media screen and (max-width: 600px) {
    width: 175px;
  }

  @media screen and (max-width: 500px) {
    border: 0;
    width: unset;
  }
`;

export const NavigationUserImage = styled.div`
  width: 42px;
  height: 42px;

  img {
    width: 100%;
    border-radius: 100%;
  }
`;

export const NavigationUserDetails = styled.div`
  h4 {
    margin: 0;
    color: ${({ theme }) => theme.colors.titleColor};
    ${mediumFont}
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

// Mobile View
export const NavigationLeftContainerMobile = styled.div`
  display: none;

  @media screen and (max-width: 500px) {
    ${flexCenter}
  }
`;

export const NavigationMenuIcon = styled.div<{
  showResponsiveSideber: boolean;
}>`
  display: none;

  @media screen and (max-width: 500px) {
    display: block;
    color: ${({ theme }) => theme.colors.textColor};
    cursor: pointer;
    margin: 0rem 1rem 0rem 0.5rem;
  }
`;
// Mobile View
