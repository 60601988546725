import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
} from "../../../../../styles/classes/gloabl-classes";

export const FoodLoggingSelectedPatientMealMacrosContainer = styled.div<{
  $width?: string;
}>`
  width: ${({ $width }) => ($width ? $width : "260px")};
`;

export const FoodLoggingSelectedPatientMealMacrosTitleContainer = styled.div`
  ${flexCenterSpaceBetween}

  p {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0;
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    font-weight: 600;
  }
`;

export const FoodLoggingSelectedPatientMealMacrosBar = styled.div<{
  $width?: string;
}>`
  ${flexCenter}
  width: ${({ $width }) => ($width ? $width : "250px")};
  height: 10px;
  border-radius: 6px;
  background: ${({ theme }) => theme.newColors.otidaO2};
  margin: 4px 0px;
  position: relative;
`;

export const FoodLoggingSelectedPatientMealMacrosCarbs = styled.div<{
  width: string;
}>`
  width: ${({ width }) => width};
  left: -1px;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.newColors.warningAlt};
  position: absolute;
  z-index: 100;
`;

export const FoodLoggingSelectedPatientMealMacrosProtiens = styled.div<{
  width: string;
}>`
  width: ${({ width }) => width};
  left: 0;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.primaryColorAlt};
  position: absolute;
  z-index: 10;
`;

export const FoodLoggingSelectedPatientMealMacrosFats = styled.div<{
  width: string;
}>`
  width: ${({ width }) => width};
  left: 0;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.primaryColor};
  position: absolute;
  z-index: 50;
`;

export const FoodLoggingSelectedPatientMealMacrosLegend = styled.div`
  ${flexCenterSpaceBetween}
  gap: 0px 16px;
  margin-top: 8px;
  padding: ${({ theme }) => theme.padding.px_1};
`;

export const FoodLoggingSelectedPatientMealMacrosLegendPoint = styled.div`
  ${flexCenter}

  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  :first-child > div {
    background: ${({ theme }) => theme.newColors.warningAlt};
  }

  :nth-child(2) > div {
    background: ${({ theme }) => theme.colors.primaryColor};
  }

  :nth-child(3) > div {
    background: ${({ theme }) => theme.colors.primaryColorAlt};
  }

  span {
    color: ${({ theme }) => theme.colors.titleColor};
    margin-left: 0.5rem;
  }
`;
