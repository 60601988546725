import { useState } from "react";
import { SearchInput } from "../../../../../styles/atoms/search-input";
import { usePlansGoals } from "../../context/goals-context";
import { CreatePlansFooter } from "../../create-plans/create-plans-footer/create-plans-footer";
import { GoalsScreenContainer } from "../goals/styled-goals";
import { PlanStepsHeader } from "../../components/plan-steps-header/plan-steps-header";
import { PlansSubScreenContainer } from "../styles";
import { AddNutritionModal } from "./add-nutrition-modal/add-nutrition-modal";
import { AssignedNutritionList } from "./assigned-nutrition-list";
import { EmptyNutrition } from "./empty-nutrition";
import { SelectNutritionList } from "./select-nutrition-list";

export const Nutrition = () => {
  const { assignedNutritionGoalsList, selectNutritionGoalsList } =
    usePlansGoals();
  const [searchTerm, setSearchTerm] = useState("");

  const assignedGoalsCondition =
    assignedNutritionGoalsList.length > 0 &&
    selectNutritionGoalsList.length === 0;

  const selectGoalsCondition =
    assignedNutritionGoalsList.length === 0 &&
    selectNutritionGoalsList.length > 0;

  const bothGoalsCondition =
    assignedNutritionGoalsList.length > 0 &&
    selectNutritionGoalsList.length > 0;

  return (
    <PlansSubScreenContainer>
      <PlanStepsHeader
        title="Nutrition Instructions"
        subTitle="Add nutrition item for you patient"
        icon="/img/plans/nav/nutrition.svg"
        button={
          assignedNutritionGoalsList.length === 0 &&
          selectNutritionGoalsList.length === 0 ? (
            <></>
          ) : (
            <AddNutritionModal />
          )
        }
      />
      <GoalsScreenContainer>
        <SearchInput
          valueProp={searchTerm}
          onChangeHandler={(e) => setSearchTerm(e.target.value)}
          width="100%"
          placeHolder="Search for nutrition goals"
        />
        {assignedGoalsCondition ? (
          <AssignedNutritionList searchTerm={searchTerm} />
        ) : selectGoalsCondition ? (
          <SelectNutritionList searchTerm={searchTerm} />
        ) : bothGoalsCondition ? (
          <>
            <AssignedNutritionList searchTerm={searchTerm} />
            <SelectNutritionList searchTerm={searchTerm} />
          </>
        ) : (
          <EmptyNutrition />
        )}
      </GoalsScreenContainer>
      <CreatePlansFooter nextStep="Lifestyle" prevStep="Medical" />
    </PlansSubScreenContainer>
  );
};
