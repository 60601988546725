import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getCategoryLabTestTypeDTOsFromLocation } from "../../utils/lab-test-results-utils";
import { GroupedLabTestResultsByType } from "../lab-result-test";
import { LabResultTestButtons } from "./lab-result-test-buttons/lab-result-test-buttons";
import { LabResultTestHeader } from "./lab-result-test-header/lab-result-test-header";
import {
  LabResultTestListsContainer,
  LabResultTestListsSubContainer,
} from "./styled-lab-result-test-lists";
import { LabResultTestTypes } from "./lab-result-test-types/lab-result-test-types";
import { LabResultTestRefs } from "./lab-result-test-refs/lab-result-test-refs";
import { LabResultTestResultsContainerHeaderTitle } from "./lab-result-test-results/styled-lab-result-test-results";
import { LabResultTestResults } from "./lab-result-test-results/lab-result-test-results";
import { useLabResults } from "../../context/loadable-lab-results-context";

type LabResultTestListsProps = {
  groupedLabTestResultsByType: GroupedLabTestResultsByType;
};

export const LabResultTestLists = (props: LabResultTestListsProps) => {
  const { groupedLabTestResultsByType } = props;
  const location = useLocation();

  const { patientLabTestResultsDTOs, labTestCategoryDTOs, labTestTypeDTOs } =
    useLabResults();

  const filteredLabTestTypeDTOs = useMemo(
    () =>
      getCategoryLabTestTypeDTOsFromLocation(
        location.pathname,
        labTestCategoryDTOs,
        labTestTypeDTOs
      ),
    [location.pathname, labTestTypeDTOs, labTestCategoryDTOs]
  );

  const filteredLabTestResults = useMemo(
    () =>
      patientLabTestResultsDTOs.filter((labTest) => {
        return filteredLabTestTypeDTOs.some(
          (labTestTypeDTO) => labTestTypeDTO.id === labTest.labTestTypeId
        );
      }),
    [patientLabTestResultsDTOs, filteredLabTestTypeDTOs]
  );

  return (
    <LabResultTestListsContainer>
      <LabResultTestHeader />
      <LabResultTestListsSubContainer>
        <LabResultTestTypes
          groupedLabTestResultsByType={groupedLabTestResultsByType}
        />
        <LabResultTestResultsContainerHeaderTitle>
          Result
        </LabResultTestResultsContainerHeaderTitle>
        <LabResultTestResults
          groupedLabTestResultsByType={groupedLabTestResultsByType}
          filteredLabTestResults={filteredLabTestResults}
        />
        <LabResultTestRefs
          groupedLabTestResultsByType={groupedLabTestResultsByType}
        />
      </LabResultTestListsSubContainer>
      <LabResultTestButtons />
    </LabResultTestListsContainer>
  );
};
