/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useFoodLoggingDatePicker } from "../context/food-logging-date-picker-context";
import { useNutritionReportMealTableRow } from "../context/nutrition-report-context/nutrition-report-meal-table-row-context";
import { useNutritionReportSearchParams } from "../context/nutrition-report-context/nutrition-report-search-params-context";
import { useNutritionReportSelectedTableRows } from "../context/nutrition-report-context/nutrition-report-selected-table-rows-context";
import { NutritionReportContainer } from "./styled-nutrition-report";
import { Outlet, useParams } from "react-router-dom";

export const NutritionReport = () => {
  const { startDateString, endDateString } = useFoodLoggingDatePicker();
  const { setSearchParams } = useNutritionReportSearchParams();
  const { selectedDays } = useNutritionReportSelectedTableRows();
  const { showMealTableRow } = useNutritionReportMealTableRow();
  const { patientID } = useParams();

  const selectedDates = useMemo(
    () => selectedDays.map((day) => day.date),
    [selectedDays]
  );

  const showMeals = useMemo(
    () => showMealTableRow.map((meal) => meal.toString()),
    [showMealTableRow]
  );

  // setSearchParams logic
  /*
   * Note: bad news: for some reason I can not setSearchParams from diffrent components
   * E.g: setSearchParams({ ...searchParams, patientID: patientID!, startDate: startDateString, });
   * so, that's why I have to handle it's set logic all in one place or one component
   * and I choose this component because I need the search params value accross the 2 nutrition report screens
   * daily summary (Table) and summary (Report summary)
   */

  /*
   * the first useEffect doesn't containt start and end dates in it's Dependency list,
   * that is because I don't need it so set selected days and meals on every date change
   * the second useEffect is Responsible for this, (reset search params) on every date change and also,
   * you will notice that setSearchParams is missing from the 2nd useEffect's Dependency list,
   * that is because if it's included, on every NutritionReport component render it will always run the
   * second useEffect, the first one will run then the second so on the UI the user will never setSearchParams
   * as expected.
   */
  useEffect(() => {
    setSearchParams(
      {
        patientID: patientID!,
        startDate: startDateString,
        endDate: endDateString,
        selectedDays: selectedDates,
        meals: showMeals,
      },
      { replace: true }
    );
  }, [selectedDates, setSearchParams, showMeals, patientID]);

  useEffect(() => {
    // Reason for this (patientID!),
    // users will never reach to this point without a defined patientID
    setSearchParams(
      {
        patientID: patientID!,
        startDate: startDateString,
        endDate: endDateString,
        selectedDays: [],
        meals: [],
      },
      { replace: true }
    );
  }, [startDateString, endDateString, patientID]);

  return (
    <NutritionReportContainer>
      <Outlet />
    </NutritionReportContainer>
  );
};
