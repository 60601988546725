import { useRef, useState } from "react";
import { Temporal } from "temporal-polyfill";
import { DateContainer, DateInputContainer } from "./styled-date-picker";

type DatePickerProps = {
  date: Temporal.PlainDate;
  onChange: (date: Temporal.PlainDate) => void;
};

export const DatePicker = (props: DatePickerProps) => {
  const { date, onChange } = props;

  const [datePickerFocused, setDatePickerFocused] = useState(false);

  const dateInputRef = useRef<HTMLInputElement | null>(null);

  function handleDateInputClick() {
    dateInputRef.current?.showPicker();
  }

  function handleIncrementDate() {
    onChange(date.add({ days: 1 }));
  }

  function handleDecrementDate() {
    onChange(date.subtract({ days: 1 }));
  }

  return (
    <DateContainer>
      <span className="material-symbols-outlined" onClick={handleDecrementDate}>
        arrow_back_ios
      </span>
      <DateInputContainer onClick={handleDateInputClick}>
        <span className="material-symbols-outlined">calendar_today</span>
        <input
          type={datePickerFocused ? "date" : "text"}
          ref={dateInputRef}
          value={
            datePickerFocused
              ? date.toString()
              : date.toLocaleString("en-GB", {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })
          }
          onFocus={() => setDatePickerFocused(true)}
          onBlur={() => setDatePickerFocused(false)}
          onChange={(e) => onChange(Temporal.PlainDate.from(e.target.value))}
        />
      </DateInputContainer>
      <span className="material-symbols-outlined" onClick={handleIncrementDate}>
        arrow_forward_ios
      </span>
    </DateContainer>
  );
};
