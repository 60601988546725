import styled from "styled-components/macro";
import { flexCenter } from "../../../../../../styles/classes/gloabl-classes";

export const ManualReadingsFormHeader = styled.div`
  ${flexCenter}
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 1rem 1.5rem;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;
