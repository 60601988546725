import { ReactNode } from "react";
import {
  LabResultsDropdownButton,
  LabResultsDropdownContainer,
  LabResultsDropdownLabel,
  LabResultsDropdownList,
  LabResultsDropdownWrapper,
} from "./styled-lab-results-dropdown";
import { useDropdown } from "../../../../../components/dropdown/hooks/use-dropdown";

type LabResultsDropdownProps = {
  label: string;
  items: ReactNode[];
  displayText: string;
  disabledValue?: boolean;
};

export const LabResultsDropdown = (props: LabResultsDropdownProps) => {
  const { label, items, displayText, disabledValue } = props;
  const {
    dropdownContainerRef,
    showDropdown,
    hideDropdown,
    toggleDropdown,
  } = useDropdown();

  return (
    <LabResultsDropdownContainer ref={dropdownContainerRef}>
      {label === "" ? (
        <></>
      ) : (
        <LabResultsDropdownLabel>{label}</LabResultsDropdownLabel>
      )}
      <LabResultsDropdownWrapper
        disabledValue={disabledValue !== undefined ? disabledValue : false}
      >
        <LabResultsDropdownButton
          showDropdown={showDropdown}
          onClick={toggleDropdown}
          type="button"
        >
          {displayText}
          <span className="material-symbols-outlined">expand_more</span>
        </LabResultsDropdownButton>
        <LabResultsDropdownList
          onClick={hideDropdown}
          showDropdown={showDropdown}
        >
          {items}
        </LabResultsDropdownList>
      </LabResultsDropdownWrapper>
    </LabResultsDropdownContainer>
  );
};
