import { useParams } from "react-router-dom";
import { useFoodLoggingData } from "../../../context/food-logging-data-context";
import { NutritionReportSummaryHeaderPatientInfoContainer } from "./styled-nutrition-report-summary-header";
import { Temporal } from "temporal-polyfill";
import { NutritionReportHeaderContainer } from "../../nutrition-report-table/nutrition-report-table-header/styled-nutrition-report-table-header";
import { useMemo } from "react";
import { NutritionReportSummaryHeaderDatesRange } from "./nutrition-report-summary-header-dates-range/nutrition-report-summary-header-dates-range";

type NutritionReportSummaryHeaderProps = {
  dates: {
    startDate: string;
    endDate: string;
  };
};

export const NutritionReportSummaryHeader = (
  props: NutritionReportSummaryHeaderProps
) => {
  const { dates } = props;

  const { patientID } = useParams();
  const { patientDTOs } = useFoodLoggingData();

  const findPatientName = patientDTOs.find(
    (patient) => patient.id === parseInt(patientID!)
  )?.name;

  const displayDates = useMemo(() => {
    const displayStartDate = Temporal.PlainDate.from(
      dates.startDate
    ).toLocaleString("en-UK", {
      day: "numeric",
      month: "short",
    });
    const displayEndDate = Temporal.PlainDate.from(
      dates.endDate
    ).toLocaleString("en-UK", {
      day: "numeric",
      month: "short",
    });

    return `${displayStartDate} - ${displayEndDate}`;
  }, [dates.startDate, dates.endDate]);

  return (
    <NutritionReportHeaderContainer>
      <NutritionReportSummaryHeaderPatientInfoContainer>
        <p>{findPatientName}</p>
        <span>From: {displayDates}</span>
      </NutritionReportSummaryHeaderPatientInfoContainer>
      <NutritionReportSummaryHeaderDatesRange />
    </NutritionReportHeaderContainer>
  );
};
