import { Temporal } from "temporal-polyfill";
import { MealsAnalysisResponseTableCell } from "./styled-meals-analysis-response-table-cells";

type MealsAnalysisResponseDateTableCellProps = {
  date: string;
  time: string;
};

const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: "short",
  day: "numeric",
  month: "short",
  year: undefined,
};

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
  second: undefined,
};

export const MealsAnalysisResponseDateTableCell = (
  props: MealsAnalysisResponseDateTableCellProps
) => {
  const { date, time } = props;

  const displayDate = Temporal.PlainDate.from(date).toLocaleString(
    "en-US",
    dateOptions
  );

  const displayTime = Temporal.PlainTime.from(time).toLocaleString(
    "en-US",
    timeOptions
  );

  return (
    <MealsAnalysisResponseTableCell $dateCell>
      <span>{displayDate}</span>
      <span>-</span>
      <span>{displayTime}</span>
    </MealsAnalysisResponseTableCell>
  );
};
