import { BASE_PATH } from "../../constants/paths";
import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { CarbCounterV1CreateMealDTO } from "../../models/carb-counter-dtos/carb-counter-v1-create-meal-dto";
import { CarbCounterV1MealDTO } from "../../models/carb-counter-dtos/carb-counter-v1-meal-dto";

export function useAddMeal(): (
  carbCounterV1CreateMealDTO: CarbCounterV1CreateMealDTO,
  mealImageFiles: Blob[],
  mealVoiceNoteFiles: Blob[],
  temperature?: number
) => Promise<CarbCounterV1MealDTO> {
  const client = useClient();

  return useCallback(
    async (
      carbCounterV1CreateMealDTO,
      mealImageFiles,
      mealVoiceNoteFiles,
      temperature = undefined
    ) => {
      const formData = new FormData();

      formData.append(
        "carbCounterV1CreateMealDTO",
        JSON.stringify(carbCounterV1CreateMealDTO)
      );

      for (const mealImageFile of mealImageFiles) {
        formData.append("mealImageFiles", mealImageFile);
      }

      for (const mealVoiceNoteFile of mealVoiceNoteFiles) {
        formData.append("mealVoiceNoteFiles", mealVoiceNoteFile);
      }

      const response = await client.post<CarbCounterV1MealDTO>(
        `${BASE_PATH}/carb-counter/v1/meals`,
        formData,
        { params: { temperature } }
      );

      return response.data;
    },
    [client]
  );
}
