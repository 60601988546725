import { PatientNutritionReportSummaryDTO } from "../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-summary-dto";
import { NutritionReportSummaryList } from "./nutrition-report-summary-list/nutrition-report-summary-list";
import { NutritionReportSummaryFooter } from "../nutrition-report-summary-footer/nutrition-report-summary-footer";
import { NutrtionReportSummaryNotes } from "./nutrtion-report-summary-notes/nutrtion-report-summary-notes";
import { NutritionReportSummaryScreenContainer } from "./styled-nutrition-report-summary-response";

type NutritionReportSummaryResponseProps = {
  patientNutritionReportSummary: PatientNutritionReportSummaryDTO;
};

export const NutritionReportSummaryResponse = (
  props: NutritionReportSummaryResponseProps
) => {
  const { patientNutritionReportSummary } = props;

  return (
    <NutritionReportSummaryScreenContainer>
      <NutritionReportSummaryList
        patientNutritionReportSummary={patientNutritionReportSummary}
      />
      <NutrtionReportSummaryNotes />
      <NutritionReportSummaryFooter />
    </NutritionReportSummaryScreenContainer>
  );
};
