import styled from "styled-components/macro";
import { EmptyStateBody } from "../../../../../../components/empty-state/styled-empty-state";
import { flexCenterSpaceBetween } from "../../../../../../styles/classes/gloabl-classes";

export const NutritionAnalysisResponseContainer = styled.div`
  ${EmptyStateBody} {
    height: 40vh;
  }
`;

export const NutritionAnalysisResponseChartsContainer = styled.div`
  ${flexCenterSpaceBetween}
`;
