import { MessagingMediaSidebarMediaListActiveState } from "../messaging-media-sidebar-media-list";
import {
  MessagingMediaSidebarMediaListNavListItem,
  MessagingMediaSidebarMediaListNavListNav,
} from "./styled-messaging-media-sidebar-media-list-nav-list";

type MessagingMediaSidebarMediaListNavListProps = {
  mediaListActiveState: MessagingMediaSidebarMediaListActiveState;
  setMediaListActiveState: (
    mediaListActiveState: MessagingMediaSidebarMediaListActiveState
  ) => void;
};

export const MessagingMediaSidebarMediaListNavList = (
  props: MessagingMediaSidebarMediaListNavListProps
) => {
  const { mediaListActiveState, setMediaListActiveState } = props;

  return (
    <MessagingMediaSidebarMediaListNavListNav>
      <MessagingMediaSidebarMediaListNavListItem
        active={mediaListActiveState === "Media"}
        onClick={() => setMediaListActiveState("Media")}
      >
        Media
      </MessagingMediaSidebarMediaListNavListItem>
      <MessagingMediaSidebarMediaListNavListItem
        active={mediaListActiveState === "Links"}
        onClick={() => setMediaListActiveState("Links")}
      >
        Links
      </MessagingMediaSidebarMediaListNavListItem>
      <MessagingMediaSidebarMediaListNavListItem
        active={mediaListActiveState === "Docs"}
        onClick={() => setMediaListActiveState("Docs")}
      >
        Docs
      </MessagingMediaSidebarMediaListNavListItem>
    </MessagingMediaSidebarMediaListNavListNav>
  );
};
