import styled from "styled-components/macro";
import { OtidaSpinner } from "../animations/otida-loader-animation";

export const LoaderContainer = styled.div<{ fullScreen?: boolean }>`
  display: flex;
  padding: 5rem 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem 0rem;

  img {
    width: 70px;
    filter: ${({ fullScreen }) => fullScreen && "brightness(0) invert(1)"};
  }
`;

export const Loader = styled.span<{ radius?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ::before {
    content: "";
    width: ${({ radius }) => (radius ? `${radius}px` : "50px")};
    height: ${({ radius }) => (radius ? `${radius}px` : "50px")};
    border: 3px solid ${({ theme }) => theme.colors.barBackground};
    border-top-color: ${({ theme }) => theme.colors.primaryColor};
    border-radius: 50%;
    animation: ${OtidaSpinner} 0.8s linear infinite;
  }
`;
