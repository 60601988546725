import {
  AxisModel,
  Category,
  ChartComponent,
  DataLabel,
  Inject,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { useMemo } from "react";
import { calculateBMI } from "../body-measurments-utils";
import { useSinglePatientSidebar } from "../../../../context/loadable-single-patient-sidebar-context";

type BmiModalChartProps = {
  height: number;
};

export const BmiModalChart = (props: BmiModalChartProps) => {
  const { height } = props;

  const { patientWeightDTOs } = useSinglePatientSidebar();

  const dataSource = useMemo(
    () =>
      patientWeightDTOs.map((item) => ({
        date: item.date,
        bmi: Number(calculateBMI(height, item.weightKg)),
      })),
    [patientWeightDTOs, height]
  );

  const primaryxAxis: AxisModel = {
    valueType: "Category",
    majorGridLines: { width: 0 },
    edgeLabelPlacement: "Shift",
  };

  const primaryyAxis: AxisModel = {
    minimum: 15,
  };

  const marker = {
    visible: true,
    width: 10,
    height: 10,
    isFilled: true,
    fill: "#1097cf",
    border: { width: 0, color: "transparent" },
    dataLabel: { visible: true },
  };

  return (
    <ChartComponent
      primaryXAxis={primaryxAxis}
      primaryYAxis={primaryyAxis}
      title="BMI Progress"
      height="100%"
    >
      <Inject services={[Category, LineSeries, StripLine, DataLabel]} />
      <SeriesCollectionDirective>
        <SeriesDirective
          dataSource={dataSource}
          xName="date"
          yName="bmi"
          opacity={1}
          zOrder={110}
          type="Line"
          width={3}
          fill="#00086b"
          marker={marker}
        ></SeriesDirective>
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};
