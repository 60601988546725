import { Link, useParams } from "react-router-dom";
import { SidebarIcon } from "../../../single-patient-header/sidebar-icon/sidebar-icon";
import { CurrentStep, usePlansSteps } from "../../context/plans-steps-context";
import {
  CPlansHeaderContainer,
  CPlansHeaderPrgressListItem,
  CPlansHeaderPrgressListItemImageContainer,
  CPlansHeaderPrgressListItemTextContainer,
  CPlansHeaderProgressList,
  CPlansHeaderTitleContainer,
  CPlansHeaderRightContainer,
  CPlansHeaderLeftContainer,
} from "./styled-create-plans-header";
import { useReloadDetailedPlan } from "../../context/loadable-detailed-plan-context";

export const CreatePlansHeader = () => {
  const { currentStep, completedSteps } = usePlansSteps();
  const { id } = useParams();
  const reloadDetailedPlan = useReloadDetailedPlan();

  return (
    <CPlansHeaderContainer>
      <CPlansHeaderTitleContainer>
        <CPlansHeaderLeftContainer>
          <Link
            to={`/patients/${id}/plans/weekly-plans`}
            onClick={() => reloadDetailedPlan()}
          >
            <span className="material-symbols-outlined">west</span>
          </Link>
          {/* CPlansTitle is positions fixed and normaly it's displayed here if it was postioin static */}
        </CPlansHeaderLeftContainer>
        <CPlansHeaderRightContainer>
          <SidebarIcon />
        </CPlansHeaderRightContainer>
      </CPlansHeaderTitleContainer>
      {/* Bottom */}
      <CPlansHeaderProgressList>
        {buildProgressBarListItems().map((item) => (
          <CPlansHeaderPrgressListItem
            key={item.id}
            active={item.name === currentStep}
            completed={completedSteps?.includes(item.name as CurrentStep)}
          >
            <CPlansHeaderPrgressListItemImageContainer>
              <img src={item.img} alt={item.name} />
            </CPlansHeaderPrgressListItemImageContainer>
            <CPlansHeaderPrgressListItemTextContainer>
              <span>{item.step}</span>
              <p>{item.name}</p>
            </CPlansHeaderPrgressListItemTextContainer>
          </CPlansHeaderPrgressListItem>
        ))}
      </CPlansHeaderProgressList>
    </CPlansHeaderContainer>
  );
};

function buildProgressBarListItems() {
  const prgoressBar = [
    {
      id: 0,
      img: "/img/plans/nav/goals.svg",
      step: "Step 1",
      name: "Goals",
    },
    {
      id: 1,
      img: "/img/plans/nav/feedback.svg",
      step: "Step 2",
      name: "Feedback",
    },
    {
      id: 2,
      img: "/img/plans/nav/medical.svg",
      step: "Step 3",
      name: "Medical",
    },
    {
      id: 3,
      img: "/img/plans/nav/nutrition.svg",
      step: "Step 4",
      name: "Nutrition",
    },
    {
      id: 4,
      img: "/img/plans/nav/life-style.svg",
      step: "Step 5",
      name: "Lifestyle",
    },
    {
      id: 5,
      img: "/img/plans/nav/education.svg",
      step: "Step 6",
      name: "Education",
    },
    {
      id: 6,
      img: "/img/plans/nav/review.svg",
      step: "Step 7",
      name: "Review",
    },
  ];

  return prgoressBar;
}
