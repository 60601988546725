import { Reading } from "../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { roundTo1DecimalPlace } from "../../../math-utils";
import { buildDaysAggregatesReadingsWithTimeDifference } from "../../build-readings-with-time-differance-value";
import { calculateReadingsTimeDiffrenceTotal } from "../../calculate-readings-time-differance-total";
import { calculateVeryLowReadingsTimeDiffrenceTotal } from "../calculate-very-low-readings-time-differance-total";

export function calculateVeryLowPercentage(readings: Reading[]) {
  const readingsWithTimeDifferenceValue = buildDaysAggregatesReadingsWithTimeDifference(
    readings
  );

  const veryLowReadingsTimeDiffrenceTotal = calculateVeryLowReadingsTimeDiffrenceTotal(
    readingsWithTimeDifferenceValue
  );
  const readingsTimeDifferanceTotal = calculateReadingsTimeDiffrenceTotal(
    readingsWithTimeDifferenceValue
  );

  const veryLowPercentage = roundTo1DecimalPlace(
    (veryLowReadingsTimeDiffrenceTotal / readingsTimeDifferanceTotal) * 100
  );

  return veryLowPercentage;
}
