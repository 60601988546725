import { useCallback, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { Temporal } from "temporal-polyfill";
import { ModalForm } from "../../../../../components/modal/modal-form/modal-form";
import { useToastService } from "../../../../../context/toast-service-context";
import { useBulkAddPatientLabTestResults } from "../../../../../hooks/patient-lab-test-result-hooks/use-bulk-add-patient-lab-test-results";
import { LabTestTypeDTO } from "../../../../../models/lab-test-types/lab-test-type-dto";
import { BulkCreatePatientLabTestResultsDTO } from "../../../../../models/patient-lab-test-result-dtos/bulk-create-patient-lab-test-results-dto";
import {
  ErrorText,
  InputContainer,
} from "../../../../../styles/classes/reusable-classes";
import { LabResultsDateInput } from "../../components/lab-results-date-input/lab-results-date-input";
import { LabResultsFormFooter } from "../../components/lab-results-form-footer/lab-results-form-footer";
import { LabResultsButton } from "../../components/lab-results-form-footer/styled-lab-results-form-footer";
import { NewLabResultsList } from "../../components/new-lab-results-list/new-lab-results-list";
import {
  NewLabResultsListIconContainer,
  NewLabResultsListInputContainer,
  NewLabResultsListListItem,
  NewLabResultsListTypeContainer,
} from "../../components/new-lab-results-list/styled-new-lab-results-list";
import { NewLabResultsModalHeader } from "../../components/new-lab-results-modal-header/new-lab-results-modal-header";
import { NewLabResultsUnits } from "../../components/new-lab-results-units/new-lab-results-units";
import { getCategoryLabTestTypeDTOsFromLocation } from "../../utils/lab-test-results-utils";
import {
  NewBulkLabResultsModalBodySubHeader,
  NewBulkLabResultsModalBodySubHeaderButtons,
} from "./styled-new-bulk-lab-results-modal-body";
import {
  useLabResults,
  useReloadLabResults,
} from "../../context/loadable-lab-results-context";

type BulkLabResultsModalBodyProps = {
  onClose: () => void;
};

type BulkLabResultsModalBodyInputs = {
  date: Temporal.PlainDate;
  labTestResults: Array<{
    labTestTypeDTO: LabTestTypeDTO;
    result?: number;
    labTestUnit?: string;
  }>;
};

export const BulkLabResultsModalBody = (
  props: BulkLabResultsModalBodyProps
) => {
  const { onClose } = props;
  const { labTestCategoryDTOs, labTestTypeDTOs } = useLabResults();
  const location = useLocation();
  const bulkAddPatientLabTestResults = useBulkAddPatientLabTestResults();
  const { id } = useParams();
  const { showToast } = useToastService();
  const reloadLabResults = useReloadLabResults();

  const today = Temporal.Now.plainDateISO();

  const filteredLabTestTypeDTOs = useMemo(
    () =>
      getCategoryLabTestTypeDTOsFromLocation(
        location.pathname,
        labTestCategoryDTOs,
        labTestTypeDTOs
      ),
    [location.pathname, labTestTypeDTOs, labTestCategoryDTOs]
  );

  const defaultValues: BulkLabResultsModalBodyInputs = {
    date: today,
    labTestResults: filteredLabTestTypeDTOs.map((labTestTypeDTO) => {
      let defaultLabTestUnit = undefined;

      if (labTestTypeDTO.name !== "Ionized Calcium") {
        defaultLabTestUnit = labTestTypeDTO.unit;
      }

      return {
        labTestTypeDTO,
        labTestUnit: defaultLabTestUnit,
      };
    }),
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<BulkLabResultsModalBodyInputs>({
    defaultValues,
  });

  const onSubmit = handleSubmit(async (inputs) => {
    const createDTO: BulkCreatePatientLabTestResultsDTO = {
      patientLabTestResults: inputs.labTestResults.map((labTestResult) => ({
        labTestTypeId: labTestResult.labTestTypeDTO.id!,
        date: inputs.date.toString()!,
        result:
          labTestResult.labTestTypeDTO!.name === "Ionized Calcium" &&
          labTestResult.labTestUnit === "mmol/L"
            ? labTestResult.result! * 4.01
            : labTestResult.result!,
      })),
    };

    try {
      await bulkAddPatientLabTestResults(parseInt(id!), createDTO);
      onClose();
      showToast("Success", "Bulk Lab test results added successfully!");
      reloadLabResults();
    } catch (e) {
      showToast("Error", "Failed to add Bulk Lab test results :(");
    }
  });

  const { fields: labTestResults, remove } = useFieldArray({
    name: "labTestResults",
    control,
    rules: { required: true, minLength: 1 },
  });

  const date = watch("date");

  const headers = ["Type", "Reading", "Unit"].map((header, index) => (
    <p key={index}>{header}</p>
  ));

  const displayValue = useCallback(
    (value: number) =>
      labTestTypeDTOs.find((labTestTypeDTO) => labTestTypeDTO.id === value)
        ?.name,
    [labTestTypeDTOs]
  );

  const listItems = labTestResults.map((labTestResult, index) => (
    <NewLabResultsListListItem key={labTestResult.labTestTypeDTO.id}>
      <NewLabResultsListTypeContainer>
        <p>{displayValue(labTestResult.labTestTypeDTO.id)}</p>
      </NewLabResultsListTypeContainer>
      <NewLabResultsListInputContainer>
        <Controller
          name={`labTestResults.${index}.result`}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <input type="number" step="0.001" onChange={onChange} />
          )}
        />
        {errors.labTestResults?.[index]?.result?.type === "required" && (
          <ErrorText>Required</ErrorText>
        )}
      </NewLabResultsListInputContainer>
      <NewLabResultsListInputContainer>
        <Controller
          name={`labTestResults.${index}.labTestUnit`}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <NewLabResultsUnits
              labTestTypeDTO={labTestResult.labTestTypeDTO!}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {errors.labTestResults?.[index]?.labTestUnit?.type === "required" && (
          <ErrorText>Required</ErrorText>
        )}
      </NewLabResultsListInputContainer>
      <NewLabResultsListIconContainer>
        <img
          src="/img/trash.svg"
          alt="Trash Icon"
          onClick={() => remove(index)}
        />
      </NewLabResultsListIconContainer>
    </NewLabResultsListListItem>
  ));

  return (
    <ModalForm width={990} height={750} onSubmit={onSubmit}>
      <NewLabResultsModalHeader onClose={onClose} />
      <NewBulkLabResultsModalBodySubHeader>
        <InputContainer>
          <Controller
            name="date"
            control={control}
            render={({ field: { onChange } }) => (
              <LabResultsDateInput
                value={date.toString()}
                onChange={onChange}
              />
            )}
          />
          {errors.labTestResults?.root?.type === "required" && (
            <ErrorText>Add at least one reading</ErrorText>
          )}
        </InputContainer>
        <NewBulkLabResultsModalBodySubHeaderButtons>
          <LabResultsButton type="reset">Clear all fields</LabResultsButton>
        </NewBulkLabResultsModalBodySubHeaderButtons>
      </NewBulkLabResultsModalBodySubHeader>
      <NewLabResultsList headers={headers} listItems={listItems} />
      <LabResultsFormFooter onClose={onClose} />
    </ModalForm>
  );
};
