import { useCallback } from "react";
import { DietitianReportTabHeader } from "../components/dietitian-report-header/dietitian-report-header";
import { DietitianReportTabContainer } from "../components/reusable-styles";
import { AnalyticsAndReportsBody } from "./analytics-and-reports-body/analytics-and-reports-body";
import { AnalyticsAndReportsFooter } from "./analytics-and-reports-footer/analytics-and-reports-footer";
import { AnalyticsAndReportsHeader } from "./analytics-and-reports-header/analytics-and-reports-header";
import { Loading } from "../../../../components/loading";
import { useGetPatientNutritionReports } from "../../../../hooks/patient-nutrition-reports-hooks/use-get-patient-nutrition-reports";
import { useParams } from "react-router-dom";
import { useGetPatient } from "../../../../hooks/patient-hooks/use-get-patient";

export const AnalyticsAndReports = () => {
  const { id } = useParams();

  const getPatientNutritionReports = useGetPatientNutritionReports();
  const getPatient = useGetPatient();

  const loadAnalyticsAndReports = useCallback(async () => {
    const [patientDTO, patientNutritionReportDTOs] = await Promise.all([
      getPatient(Number(id!)),
      getPatientNutritionReports(Number(id!)),
    ]);

    return { patientDTO, patientNutritionReportDTOs };
  }, [id, getPatientNutritionReports, getPatient]);

  return (
    <Loading
      load={loadAnalyticsAndReports}
      successComponent={(analyticsAndReportsData) => (
        <DietitianReportTabContainer>
          <DietitianReportTabHeader
            icon="/img/plans/nav/goals.svg"
            title="Nutrition Reports"
            subTitle="Feedback about patients entered meals"
          />
          <AnalyticsAndReportsHeader />
          <AnalyticsAndReportsBody
            analyticsAndReportsData={{
              patientDTO: analyticsAndReportsData.patientDTO,
              patientNutritionReportDTOs:
                analyticsAndReportsData.patientNutritionReportDTOs,
            }}
          />
          <AnalyticsAndReportsFooter
            patientDTO={analyticsAndReportsData.patientDTO}
          />
        </DietitianReportTabContainer>
      )}
    />
  );
};
