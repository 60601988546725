import {
  MedicationsInputContainer,
  MedicationsInputInput,
  MedicationsInputLabel,
  MedicationsInputWrapper,
} from "../styled-medications-inputs";

type MedicationsNumberInputProps = {
  value: string | number | undefined;
  onChange: (value: string | number | undefined) => void;
  label: string;
  subLabel?: string;
  errorState?: boolean;
  step?: string;
  isOptional?: true;
};

export const MedicationsNumberInput = (props: MedicationsNumberInputProps) => {
  const { value, onChange, label, subLabel, errorState, step, isOptional } =
    props;

  return (
    <MedicationsInputContainer>
      <MedicationsInputLabel isOptional={isOptional}>
        {label}
        {isOptional && <span>(Optional)</span>}
      </MedicationsInputLabel>
      <MedicationsInputWrapper errorState={errorState}>
        <MedicationsInputInput
          type="number"
          placeholder={label}
          step={step !== undefined ? step : ""}
          value={value === undefined ? "" : value}
          onChange={(e) =>
            onChange(e.target.value === undefined ? "" : e.target.value)
          }
        />
        <p>{subLabel}</p>
      </MedicationsInputWrapper>
    </MedicationsInputContainer>
  );
};
