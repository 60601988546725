import { useEffect, useState } from "react";
import { ChatHomeBody } from "./chat-home-body/chat-home-body";
import { DefaultGenerics, StreamChat, User } from "stream-chat";
import { useChatTokenContext } from "../chat-providers/chat-token-context";
import { useGenerateToken } from "../../../hooks/chat-hooks/use-generate-token";
import { STREAM_CHAT_API_KEY } from "../../../constants/config";
import { Chat } from "stream-chat-react";
import { customStyles } from "./styled-chat-home";
import { ChatQueryUsersProvider } from "../chat-providers/chat-query-users-context";
import { ChatNotificationsProvider } from "../chat-providers/chat-notifications-context";
import { OtidaLoader } from "../../../components/loaders/otida-loader/otida-loader";

export const ChatHome = () => {
  const [client, setClient] = useState<StreamChat<DefaultGenerics> | null>(
    null
  );

  const { chatTokenDTO } = useChatTokenContext();

  const generateToken = useGenerateToken();

  useEffect(() => {
    const user: User = {
      id: chatTokenDTO.chatTokenDTO.streamChatUserId,
    };

    async function init() {
      const chatClient = StreamChat.getInstance(STREAM_CHAT_API_KEY);

      const token = async () => {
        const res = await generateToken();
        return res.token;
      };

      await chatClient.connectUser(user, token);

      setClient(chatClient);
    }

    init();
  }, [generateToken, chatTokenDTO.chatTokenDTO.streamChatUserId, client]);

  return (
    <>
      {client ? (
        <Chat client={client} customStyles={customStyles}>
          <ChatQueryUsersProvider>
            <ChatNotificationsProvider>
              <ChatHomeBody />
            </ChatNotificationsProvider>
          </ChatQueryUsersProvider>
        </Chat>
      ) : (
        <OtidaLoader />
      )}
    </>
  );
};
