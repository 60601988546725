import { PatientDTO } from "../models/patient-dtos/patient-dto";

export function comparePatientsNames(aName: string, bName: string) {
  return aName.localeCompare(bName);
}

export function sortPatients(patientDTOs: PatientDTO[]): PatientDTO[] {
  return patientDTOs.sort((a, b) => {
    if (a.whatsAppId === undefined) {
      if (b.whatsAppId === undefined) {
        return comparePatientsNames(a.name, b.name);
      } else {
        return 1;
      }
    } else {
      if (b.whatsAppId === undefined) {
        return -1;
      } else {
        if (a.whatsAppId === b.whatsAppId) {
          // This case should never happen since the WhatsApp ID is unique.
          return comparePatientsNames(a.name, b.name);
        } else {
          return b.whatsAppId - a.whatsAppId;
        }
      }
    }
  });
}
