import { Temporal } from "temporal-polyfill";
import { PatientMealDTO } from "../../../../../models/patient-meal-dtos/patient-meal-dto";
import { GoogleDataTableRow } from "react-google-charts";
import { buildRow } from "../build-row";
import { MEDICINE_GLUCOSE_POINT } from "../../../../../constants/config";
import { isMobileOrTablet } from "../../../../../utils/device-utils";
import { patientMealToHtmlTooltip } from "./patient-meal-to-html-tooltip";

export function buildPatientMealsRow(
  datetime: Temporal.PlainDateTime,
  reverseVAxisTicks: number[],
  patientMeal: PatientMealDTO
): GoogleDataTableRow {
  let bigPointSize!: number;
  if (isMobileOrTablet()) {
    bigPointSize = 4;
  } else {
    bigPointSize = 4;
  }

  const tooltip = patientMealToHtmlTooltip(patientMeal);

  const style = `
    point {
      size: ${bigPointSize};
      shape-type: circle;
      fill-color: #1097cf;
      visible :true
    }`;

  return buildRow({
    datetime,
    reverseVAxisTicks,
    isNotGlucose: {
      value: MEDICINE_GLUCOSE_POINT,
      tooltip,
    },
    isNotGlucoseStyle: style,
  });
}
