import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";

type NutritionReportNumberOfMealsTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportNumberOfMealsTableCell = (
  props: NutritionReportNumberOfMealsTableCellProps
) => {
  const { kind } = props;

  return (
    <NutritionReportTableCell
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      value={kind.kind === "Row" ? kind.data.totalNumberOfMeals : undefined}
    />
  );
};
