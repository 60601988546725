import { TooltipSettingsModel } from "@syncfusion/ej2-react-charts";
import { BgmChartTooltip, BgmChartTooltipProps } from "./bgm-chart-tooltip";

export function buildBgmChartTooltip(
  hasToolTip: boolean
): TooltipSettingsModel {
  const template: any = chartTemplate;

  return {
    enable: hasToolTip,
    template,
  };
}

function chartTemplate(args: { tooltip: BgmChartTooltipProps }) {
  return <BgmChartTooltip {...args.tooltip} />;
}
