import { Modal } from "../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../components/modal/use-modal-helpers";
import { Button } from "../../../../../styles/classes/reusable-classes";
import { MedicationModalBody } from "../../medication-modal-body/medication-modal-body";

export const CreateMedication = () => {
  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  return (
    <>
      <Button flex onClick={openModal}>
        <span className="material-symbols-outlined">add</span>
        New medicine
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <MedicationModalBody
          key={modalBodyKey}
          closeModal={closeModal}
          mode={{ kind: "POST" }}
        />
      </Modal>
    </>
  );
};
