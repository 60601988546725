import styled from "styled-components";

export const MealsAnalysisResponseMediaTableCellVoiceNotesContainer = styled.div`
  width: 240px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const MealsAnalysisResponseMediaTableCellVoiceNoteContainer = styled.div`
  /* Source: https://stackoverflow.com/questions/4126708/is-it-possible-to-style-html5-audio-tag */
  audio {
    height: 40px;
    width: 200px;
    border-radius: 10px;
  }

  audio::-webkit-media-controls-panel {
    background: ${({ theme }) => theme.newColors.newBorderColorAlt};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  audio::-webkit-media-controls-timeline-container {
    background: ${({ theme }) => theme.colors.primaryColor};
  }
`;
