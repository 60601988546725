import { Modal } from "../../../../../../../../../components/modal/modal";
import {
  MessagingMediaSidebarCustomMessageImagesModalContainer,
  MessagingMediaSidebarCustomMessageImagesModalSlide,
  MessagingMediaSidebarCustomMessageImagesModalSlideNumber,
  MessagingMediaSidebarCustomMessageImagesModalSlider,
  MessagingMediaSidebarCustomMessageImagesModalSliderButtons,
} from "../../../messaging-media-sidebar-custom-message/messaging-media-sidebar-custom-message-images/messaging-media-sidebar-custom-message-images-modal/styled-messaging-media-sidebar-custom-message-images-modal";
import { TransformMessagesAndAttachments } from "../../utils/group-attachments-by-month";

type MediaSidebarMediaListModalProps = {
  closeModal: () => void;
  isModalOpen: boolean;
  modalBodyKey: number;
  groupedMessages: TransformMessagesAndAttachments[];
  currentSlide: number;
  setCurrentSlide: (currentSlide: number) => void;
};

export const MediaSidebarMediaListModal = (
  props: MediaSidebarMediaListModalProps
) => {
  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    groupedMessages,
    currentSlide,
    setCurrentSlide,
  } = props;

  const attachments = groupedMessages
    .map((groupedMessages) => groupedMessages.attachments)
    .flat();

  const media = attachments
    .filter((item) => item?.type !== "file" && item?.title_link === undefined)
    .map((attachment) => ({
      type: attachment?.type,
      asset_url: attachment?.asset_url,
      image_url: attachment?.image_url,
      author_name: attachment?.author_name,
    }));

  const leftButton = (
    <span
      onClick={() => {
        if (media) {
          if (currentSlide <= 0) {
            setCurrentSlide(media.length - 1);
          } else {
            setCurrentSlide(currentSlide - 1);
          }
        }
      }}
    >
      &#8249;
    </span>
  );

  const rightButton = (
    <span
      onClick={() => {
        if (media) {
          if (currentSlide + 1 >= media?.length) {
            setCurrentSlide(0);
          } else {
            setCurrentSlide(currentSlide + 1);
          }
        }
      }}
    >
      &#8250;
    </span>
  );

  const buttons = (
    <MessagingMediaSidebarCustomMessageImagesModalSliderButtons>
      {leftButton}
      {rightButton}
    </MessagingMediaSidebarCustomMessageImagesModalSliderButtons>
  );

  return (
    <Modal handleHideModal={closeModal} showModal={isModalOpen}>
      <MessagingMediaSidebarCustomMessageImagesModalContainer
        key={modalBodyKey}
      >
        <MessagingMediaSidebarCustomMessageImagesModalSlider>
          {media.map((mediaItem, index) => (
            <MessagingMediaSidebarCustomMessageImagesModalSlide
              key={mediaItem.image_url || mediaItem.asset_url}
              showImage={currentSlide === index}
            >
              <MessagingMediaSidebarCustomMessageImagesModalSlideNumber>
                <p>{`${currentSlide + 1} / ${media.length}`}</p>
              </MessagingMediaSidebarCustomMessageImagesModalSlideNumber>
              {mediaItem.type === "image" && (
                <img
                  src={mediaItem.image_url}
                  alt={`from ${mediaItem.author_name}`}
                />
              )}
              {mediaItem.type === "video" && (
                <video src={mediaItem.asset_url} controls></video>
              )}
            </MessagingMediaSidebarCustomMessageImagesModalSlide>
          ))}
          {buttons}
        </MessagingMediaSidebarCustomMessageImagesModalSlider>
      </MessagingMediaSidebarCustomMessageImagesModalContainer>
    </Modal>
  );
};
