import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../styles/classes/reusable-classes";
import { useAddPatientPlan } from "../../../hooks/patient-plan-hooks/use-add-patient-plan";

type PlansBtnProps = {
  outlined: boolean;
};

export const PlansBtn = (props: PlansBtnProps) => {
  const { outlined } = props;

  const navigate = useNavigate();
  const addPatientPlan = useAddPatientPlan();
  const { id } = useParams();

  const handleCreatePlan = () => {
    addPatientPlan(parseInt(id!)).then((res) => {
      navigate(`../${res.id}`);
    });
  };

  return (
    <Button
      outlinedPrimary={outlined ? true : false}
      flex
      onClick={handleCreatePlan}
    >
      <span className="material-symbols-outlined">add_circle_outline</span>
      Create new plan
    </Button>
  );
};
