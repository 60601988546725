import { ContentModuleType } from "../../../../../models/content-module-dtos/content-module-type";
import {
  UXInputContainer,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../styles/classes/reusable-classes";

type ReadContentModuleDescriptionControllerProps = {
  contnetModuleType: ContentModuleType;
  conentModuleDescription?: string;
};

export const ReadContentModuleDescriptionController = (
  props: ReadContentModuleDescriptionControllerProps
) => {
  const { contnetModuleType, conentModuleDescription } = props;

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px" $isOptional>
        {contnetModuleType} Description <span>(Optional)</span>
      </UXInputLabel>
      <UXInputWrapper $width="80%" $textareaDimentions $readOnly>
        <h6 dir="auto">
          {conentModuleDescription
            ? conentModuleDescription
            : `No desctiption for this ${contnetModuleType}`}
        </h6>
      </UXInputWrapper>
    </UXInputContainer>
  );
};
