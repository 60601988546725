import styled from "styled-components/macro";
import { TableContainer } from "../../../components/table/styled-table";
import { NavbarHeight } from "../../../styles/global-style";
import { flexCenter, mediumFont } from "../../../styles/classes/gloabl-classes";
import { EmptyStateBody } from "../../../components/empty-state/styled-empty-state";

export const ContentScreenContainer = styled.div<{ $lessonUI: boolean }>`
  position: relative;
  background: ${({ theme }) => theme.colors.containerColor};
  overflow: ${({ $lessonUI }) => ($lessonUI ? `hidden` : ``)};
  height: ${({ $lessonUI }) =>
    $lessonUI ? `calc(100vh - ${NavbarHeight})` : ``};

  @media screen and (max-width: 1024px) {
    overflow: ${({ $lessonUI }) => ($lessonUI ? `visible` : ``)};
    height: ${({ $lessonUI }) => ($lessonUI ? `auto` : ``)};
  }
`;

export const ContentScreenTableContainer = styled.div<{ $childTable: boolean }>`
  ${({ $childTable }) => ($childTable ? `margin-bottom: 24px;` : ``)};

  ${TableContainer} {
    height: ${({ $childTable }) =>
      $childTable
        ? `300px`
        : `calc(100vh - (${NavbarHeight} + 91px + 80px + 96px))`};
    overflow-y: auto;
  }

  ${EmptyStateBody} {
    max-height: 300px;
  }
`;

export const ContentScreenTableRowsThumbnailContainer = styled.div`
  ${flexCenter}

  img {
    width: 35px;
    height: 35px;
  }
`;

export const ContentScreenTableRowsStatusContainer = styled.div<{
  $live: boolean;
}>`
  span {
    ${mediumFont}
    color: ${({ theme, $live }) =>
      $live ? theme.colors.success : theme.colors.titleColor};
  }
`;
