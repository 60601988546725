import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { AddLifestyleModal } from "./add-lifestyle-modal/add-lifestyle-modal";

export const EmptyLifestyle = () => {
  return (
    <EmptyState
      hasHeader={false}
      hasSearch={false}
      text="Add a lifestyle Goal"
      subText="Assign lifestyle goals to your patient"
      button={<AddLifestyleModal />}
      title=""
    />
  );
};
