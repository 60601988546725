import { Modal } from "../../../components/modal/modal";
import { useModalHelpers } from "../../../components/modal/use-modal-helpers";
import {
  LogoutModalButton,
  LogoutModalButtons,
  LogoutModalContainer,
  LogoutModalHeader,
  LogoutModalImage,
  LogoutText,
} from "./styled-logout-modal";

type Props = {
  handleHideMobileLogout: () => void;
  showLogoutModal: boolean;
  clearAndUnpersistUser: () => void;
};

export const LogoutModal = (props: Props) => {
  const {
    handleHideMobileLogout,
    showLogoutModal,
    clearAndUnpersistUser,
  } = props;

  const { modalBodyKey } = useModalHelpers();

  return (
    <Modal showModal={showLogoutModal} handleHideModal={handleHideMobileLogout}>
      <LogoutModalContainer key={modalBodyKey}>
        <LogoutModalHeader>
          <p>Confirm Logout</p>
          <span
            className="material-symbols-outlined"
            onClick={handleHideMobileLogout}
          >
            close
          </span>
        </LogoutModalHeader>
        <LogoutModalImage>
          <img src="/img/logout-icon.svg" alt="" />
        </LogoutModalImage>
        <LogoutText>Are you sure you want to logout?</LogoutText>
        <LogoutModalButtons>
          <LogoutModalButton onClick={clearAndUnpersistUser}>
            Logout
          </LogoutModalButton>
          <LogoutModalButton outline onClick={handleHideMobileLogout}>
            Back
          </LogoutModalButton>
        </LogoutModalButtons>
      </LogoutModalContainer>
    </Modal>
  );
};
