import { PatientMealDTO } from "../../../../models/patient-meal-dtos/patient-meal-dto";
import { FoodLoggingPatientsTab } from "../../food-logging-patients/food-logging-patients-tabs/food-logging-patients-tabs";
import { FoodLoggingSelectedPatientMealCarbSources } from "./food-logging-selected-patient-meal-carb-sources/food-logging-selected-patient-meal-carb-sources";
import { FoodLoggingSelectedPatientMealFoodGroups } from "./food-logging-selected-patient-meal-food-groups/food-logging-selected-patient-meal-food-groups";
import { FoodLoggingSelectedPatientMealMacros } from "./food-logging-selected-patient-meal-macros/food-logging-selected-patient-meal-macros";
import { FoodLoggingSelectedPatientProteinSources } from "./food-logging-selected-patient-protein-sources/food-logging-selected-patient-protein-sources";
import {
  FoodLoggingSelectedPatientMealBody,
  FoodLoggingSelectedPatientMealContainer,
  FoodLoggingSelectedPatientMealHeader,
  FoodLoggingSelectedPatientMealHeaderText,
} from "../styled-food-logging-selected-patient-meal";
import { getMealDisplayDateAndTime } from "../utils/food-logging-selected-patient-meal-utils";
import { FoodLoggingSelectedPatientMealVoiceNotes } from "../components/food-logging-selected-patient-meal-voice-notes/food-logging-selected-patient-meal-voice-notes";
import { FoodLoggingSelectedPatientMealImages } from "../components/food-logging-selected-patient-meal-images/food-logging-selected-patient-meal-images";
import { FoodLoggingSelectedPatientMealNotes } from "../components/food-logging-selected-patient-meal-notes/food-logging-selected-patient-meal-notes";
import { FoodLoggingSelectedPatientMealFeedback } from "../components/food-logging-selected-patient-meal-feedback/food-logging-selected-patient-meal-feedback";
import { FoodLoggingSelectedPatientMealFooter } from "../components/food-logging-selected-patient-meal-footer/food-logging-selected-patient-meal-footer";

type LegacyFoodLoggingSelectedPatientMealProps = {
  patientMealDTO: PatientMealDTO;
  selectedTab: FoodLoggingPatientsTab;
};

export const LegacyFoodLoggingSelectedPatientMeal = (
  props: LegacyFoodLoggingSelectedPatientMealProps
) => {
  const { patientMealDTO, selectedTab } = props;

  const displayMealDate =
    getMealDisplayDateAndTime(patientMealDTO).displayMealDate;
  const displayMealTime =
    getMealDisplayDateAndTime(patientMealDTO).displayMealTime;

  return (
    <FoodLoggingSelectedPatientMealContainer>
      <FoodLoggingSelectedPatientMealHeader>
        <FoodLoggingSelectedPatientMealHeaderText>
          <p>
            {displayMealDate}, {displayMealTime}
            {patientMealDTO.tag !== undefined && (
              <span>[{patientMealDTO.tag}]</span>
            )}
          </p>
          {(patientMealDTO.patientMealVoiceNotes.length > 0 ||
            patientMealDTO.patientMealImages.length > 0) && (
            <span>
              <img src="/img/document-blue.svg" alt="Document Icon" />
              {patientMealDTO.note}
            </span>
          )}
        </FoodLoggingSelectedPatientMealHeaderText>
        <FoodLoggingSelectedPatientMealMacros
          macros={patientMealDTO.patientMealMacrosSummary}
        />
      </FoodLoggingSelectedPatientMealHeader>
      <FoodLoggingSelectedPatientMealBody>
        {patientMealDTO.patientMealVoiceNotes.length > 0 && (
          <FoodLoggingSelectedPatientMealVoiceNotes
            patientMealVoiceNotes={patientMealDTO.patientMealVoiceNotes}
          />
        )}
        {patientMealDTO.patientMealImages.length > 0 && (
          <FoodLoggingSelectedPatientMealImages
            patientMealImageDTOs={patientMealDTO.patientMealImages}
          />
        )}
        {patientMealDTO.patientMealVoiceNotes.length === 0 &&
          patientMealDTO.patientMealImages.length === 0 && (
            <FoodLoggingSelectedPatientMealNotes
              patientMealDTONote={patientMealDTO.note}
            />
          )}
        <FoodLoggingSelectedPatientMealCarbSources />
        <FoodLoggingSelectedPatientProteinSources />
        <FoodLoggingSelectedPatientMealFoodGroups />
        <FoodLoggingSelectedPatientMealFeedback />
      </FoodLoggingSelectedPatientMealBody>
      <FoodLoggingSelectedPatientMealFooter
        patientMealDTO={patientMealDTO}
        selectedTab={selectedTab}
      />
    </FoodLoggingSelectedPatientMealContainer>
  );
};
