import { MedicalHistoryTab } from "./medical-history-tab/medical-history-tab";
import { PersonalInfoTab } from "./personal-info-tab/personal-info-tab";

type PatientSidebarTabsProps = {
  activeBtn: number;
};

export const PatientSidebarTabs = (props: PatientSidebarTabsProps) => {
  const { activeBtn } = props;

  return (
    <div>{activeBtn === 1 ? <PersonalInfoTab /> : <MedicalHistoryTab />}</div>
  );
};
