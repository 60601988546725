import styled from "styled-components/macro";

export const MessagingSidebarContiner = styled.div<{ showSidebar: boolean }>`
  background: ${({ theme }) => theme.colors.containerColor};
  width: 300px;
  position: relative;

  @media screen and (max-width: 960px) {
    position: fixed;
    inset: 0px;
    left: ${({ showSidebar }) => (showSidebar ? "0px" : "-1000px")};
    z-index: ${({ theme }) => theme.zIndex.z900};
    transition: left 200ms ease-in-out;
  }
`;

export const MessagingSidebarBackdrop = styled.div<{ showSidebar: boolean }>`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    background: ${({ theme }) => theme.newColors.backdrop};
    position: fixed;
    inset: 0px;
    width: 100%;
    left: ${({ showSidebar }) => (showSidebar ? "300px" : "-1000px")};
    transition: left 200ms ease-in-out;
  }
`;
