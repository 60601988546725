import { useEffect, useState } from "react";
import { PatientServingDTO } from "../../../models/patient-serving-dto";
import { useGetPatientServings } from "../../../hooks/use-get-patient-servings";
import { useParams } from "react-router-dom";

type UseGetPatientServingDTOs = {
  patientServingDTOs: PatientServingDTO[];
  isLoaded: boolean;
};

export function useGetPatientServingDTOs(): UseGetPatientServingDTOs {
  const { id } = useParams();

  const [patientServingDTOs, setPatientServingDTOs] = useState<
    PatientServingDTO[]
  >([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const getPatientServings = useGetPatientServings();

  useEffect(() => {
    const fetch = async () => {
      getPatientServings(Number(id!)).then((response) => {
        setPatientServingDTOs(response);
        setIsLoaded(true);
      });
    };

    fetch();
  }, [id, getPatientServings, setIsLoaded]);

  return { patientServingDTOs, isLoaded };
}
