import {
  ChangeEvent,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CarbCounterV1MealDTO } from "../../../../../models/carb-counter-dtos/carb-counter-v1-meal-dto";
import { CarbCounterV1MealFoodDTO } from "../../../../../models/carb-counter-dtos/carb-counter-v1-meal-food-dto";
import { CarbCounterV1FoodMeasureDTO } from "../../../../../models/carb-counter-dtos/carb-counter-v1-food-measure-dto";
import { CarbCounterV1AddFoodResponseDTO } from "../../../../../models/carb-counter-dtos/carb-counter-v1-add-food-response-dto";
import { useFormContext } from "react-hook-form";
import { FoodLoggingSelectedPatientMealInputs } from "../../food-logging-selected-patient-meal";
import { UpdatePatientMealFoodDTO } from "../../../../../models/patient-meal-dtos/update-patient-meal-food-dto";

type CarbCounterMeal = {
  meal: CarbCounterV1MealDTO;
  decrementMealQuantity: (
    mealFood: CarbCounterV1MealFoodDTO,
    mealFoodIndex: number
  ) => void;
  incrementMealQuantity: (
    mealFood: CarbCounterV1MealFoodDTO,
    mealFoodIndex: number
  ) => void;
  removeMealFood: (mealFoodIndex: number) => void;
  updateMealFoodQuantity: (mealFoodIndex: number, quantity: number) => void;
  updateMealFoodQuantityFoodMeasure: (
    mealFoodIndex: number,
    foodMeasureIndex: number
  ) => void;
  handleInputChange: (
    mealFoodIndex: number,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  handleAddMealFood: (
    newFoodMeassure: CarbCounterV1FoodMeasureDTO,
    newFoodQuantity: string,
    newFood: CarbCounterV1AddFoodResponseDTO
  ) => void;
  updateFoodGroup: (mealFoodIndex: number, selectedFoodGroupID: number) => void;
};

const CarbCounterMealContext = createContext<CarbCounterMeal | undefined>(
  undefined
);

type CarbCounterMealProviderProps = PropsWithChildren<{
  initialMeal: CarbCounterV1MealDTO;
}>;

export const CarbCounterMealProvider = (
  props: CarbCounterMealProviderProps
) => {
  const { children, initialMeal } = props;

  const [meal, setMeal] = useState<CarbCounterV1MealDTO>(initialMeal);
  const { setValue } = useFormContext<FoodLoggingSelectedPatientMealInputs>();

  const patientMealFoods: UpdatePatientMealFoodDTO[] = meal.mealFoods.map(
    (item) => ({
      foodId: item.food.id!,
      quantity: item.quantity,
      quantityFoodMeasureId: item.quantityFoodMeasure.id!,
    })
  );

  useEffect(() => {
    setValue("patientMealFoods", patientMealFoods);
  }, [meal, setValue, patientMealFoods]);

  const removeMealFood = useCallback(
    (mealFoodIndex: number) => {
      const mealFoods = [...meal.mealFoods];
      mealFoods.splice(mealFoodIndex, 1);

      setMeal({
        ...meal,
        mealFoods,
      });
    },
    [meal]
  );

  const updateMealFoodQuantity = useCallback(
    (mealFoodIndex: number, quantity: number) => {
      const mealFoods = [...meal.mealFoods];
      mealFoods[mealFoodIndex].quantity = quantity;

      setMeal({
        ...meal,
        mealFoods,
      });
    },
    [meal]
  );

  const updateMealFoodQuantityFoodMeasure = useCallback(
    (mealFoodIndex: number, foodMeasureIndex: number) => {
      const mealFoods = [...meal.mealFoods];

      const mealFood = mealFoods[mealFoodIndex];
      const currentSelectedMeasure = mealFood.quantityFoodMeasure;
      const newSelectedMeasure = mealFood.food.foodMeasures[foodMeasureIndex];

      const updatedQuantity =
        (mealFood.quantity * currentSelectedMeasure.gramsPerOneUnitOfMeasure) /
        newSelectedMeasure.gramsPerOneUnitOfMeasure;

      mealFoods[mealFoodIndex].quantity = updatedQuantity;
      mealFoods[mealFoodIndex].quantityFoodMeasure = newSelectedMeasure;

      setMeal({
        ...meal,
        mealFoods,
      });
    },
    [meal]
  );

  const decrementQuantityByHalfRespectively = useCallback((number: number) => {
    const decimalPart = number % 1;
    if (decimalPart > 0.0 && decimalPart < 0.5) {
      return Math.floor(number);
    } else if (decimalPart > 0.5) {
      return Math.round(number) - 0.5;
    } else {
      return number - 0.5;
    }
  }, []);

  const decrementMealQuantity = useCallback(
    (mealFood: CarbCounterV1MealFoodDTO, mealFoodIndex: number) => {
      updateMealFoodQuantity(
        mealFoodIndex,
        decrementQuantityByHalfRespectively(mealFood.quantity)
      );
    },
    [updateMealFoodQuantity, decrementQuantityByHalfRespectively]
  );

  const incrementQuantityByHalfRespectively = useCallback((number: number) => {
    const decimalPart = number % 1;
    if (decimalPart > 0.0 && decimalPart < 0.5) {
      return Math.floor(number) + 0.5;
    } else if (decimalPart > 0.5) {
      return Math.round(number);
    } else {
      return number + 0.5;
    }
  }, []);

  const incrementMealQuantity = useCallback(
    (mealFood: CarbCounterV1MealFoodDTO, mealFoodIndex: number) => {
      updateMealFoodQuantity(
        mealFoodIndex,
        incrementQuantityByHalfRespectively(mealFood.quantity)
      );
    },
    [updateMealFoodQuantity, incrementQuantityByHalfRespectively]
  );

  const handleInputChange = useCallback(
    (mealFoodIndex: number, event: ChangeEvent<HTMLInputElement>) =>
      updateMealFoodQuantity(mealFoodIndex, Number(event.target.value)),
    [updateMealFoodQuantity]
  );

  const updateFoodGroup = useCallback(
    (mealFoodIndex: number, selectedFoodGroupID: number) => {
      const mealFoods = [...meal.mealFoods];
      mealFoods[mealFoodIndex].food.foodGroupId = selectedFoodGroupID;

      setMeal({
        ...meal,
        mealFoods,
      });
    },
    [meal]
  );

  const handleAddMealFood = useCallback(
    (
      newFoodMeassure: CarbCounterV1FoodMeasureDTO,
      newFoodQuantity: string,
      newFood: CarbCounterV1AddFoodResponseDTO
    ) => {
      const newMealFoods = meal.mealFoods.concat({
        food: {
          ...newFood.food,
          foodMeasures: newFood.food.foodMeasures,
          name: newFood.food.name,
        },
        quantity: Number(newFoodQuantity),
        quantityFoodMeasure: newFoodMeassure,
      });

      setMeal({
        ...meal,
        mealFoods: newMealFoods,
      });
    },
    [meal]
  );

  const value = useMemo(
    () => ({
      meal,
      decrementMealQuantity,
      incrementMealQuantity,
      removeMealFood,
      updateMealFoodQuantity,
      updateMealFoodQuantityFoodMeasure,
      handleInputChange,
      handleAddMealFood,
      updateFoodGroup,
    }),
    [
      meal,
      decrementMealQuantity,
      incrementMealQuantity,
      removeMealFood,
      updateMealFoodQuantity,
      updateMealFoodQuantityFoodMeasure,
      handleInputChange,
      handleAddMealFood,
      updateFoodGroup,
    ]
  );

  return (
    <CarbCounterMealContext.Provider value={value}>
      {children}
    </CarbCounterMealContext.Provider>
  );
};

export const useCarbCounterMeal = (): CarbCounterMeal => {
  const carbCounterMeal = useContext(CarbCounterMealContext);

  if (carbCounterMeal === undefined) {
    throw new Error(
      `useCarbCounterMeal must be within CarbCounterMealProvider`
    );
  }

  return carbCounterMeal;
};
