import { Modal } from "../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../components/modal/use-modal-helpers";
import { PatientMealImageDTO } from "../../../../../models/patient-meal-dtos/patient-meal-image-dto";
import { FoodLoggingSelectedPatientMealImagesModal } from "./food-logging-selected-patient-meal-images-modal/food-logging-selected-patient-meal-images-modal";
import {
  FoodLoggingSelectedPatientMealImagesContainer,
  FoodLoggingSelectedPatientMealImagesImageContainer,
  FoodLoggingSelectedPatientMealImagesImageFadded,
} from "./styled-food-logging-selected-patient-meal-images";

export type FoodLoggingSelectedPatientMealImagesProps = {
  patientMealImageDTOs: PatientMealImageDTO[];
  maxImagesDisplay?: number;
};

export function FoodLoggingSelectedPatientMealImages(
  props: FoodLoggingSelectedPatientMealImagesProps
) {
  const { patientMealImageDTOs, maxImagesDisplay } = props;

  const { openModal, isModalOpen, closeModal, modalBodyKey } =
    useModalHelpers();

  const slicePatientMealImageDTOsFromZeroTo =
    maxImagesDisplay !== undefined ? maxImagesDisplay : 4;

  return (
    <FoodLoggingSelectedPatientMealImagesContainer
      patientMealImageDTOsLength={patientMealImageDTOs.length}
    >
      {patientMealImageDTOs
        .slice(0, slicePatientMealImageDTOsFromZeroTo)
        .map((patientMealImageDTO, index) => {
          const moreThan4PatientMealImages = patientMealImageDTOs.length > 4;

          return (
            <FoodLoggingSelectedPatientMealImagesImageContainer
              key={patientMealImageDTO.publicUrl}
              faded={moreThan4PatientMealImages}
              onClick={openModal}
              mealImagesLength={patientMealImageDTOs.length}
            >
              {index === slicePatientMealImageDTOsFromZeroTo - 1 && (
                <FoodLoggingSelectedPatientMealImagesImageFadded
                  faded={moreThan4PatientMealImages}
                >
                  <p>
                    +
                    {
                      patientMealImageDTOs.slice(
                        slicePatientMealImageDTOsFromZeroTo,
                        patientMealImageDTOs.length
                      ).length
                    }
                  </p>
                </FoodLoggingSelectedPatientMealImagesImageFadded>
              )}
              <img
                src={patientMealImageDTO.publicUrl}
                alt="Meal"
                crossOrigin="anonymous"
              />
            </FoodLoggingSelectedPatientMealImagesImageContainer>
          );
        })}
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FoodLoggingSelectedPatientMealImagesModal
          key={modalBodyKey}
          patientMealImageDTOs={patientMealImageDTOs}
        />
      </Modal>
    </FoodLoggingSelectedPatientMealImagesContainer>
  );
}
