import { useCallback, useState } from "react";
import {
  CopiedText,
  PatientProfileInformationHeaderCenterTopInformation,
  PatientProfileInformationHeaderCenterTopInformationItem,
  PatientProfileInformationHeaderCenterTopMobileOrMobile,
  PatientProfileInformationHeaderCenterTopName,
  PatientProfileInformationHeaderCenterTopNameContainer,
  PatientProfileInformationHeaderCenterTopNameIcons,
} from "./styled-patient-profile-information-header-center-top";
import { buildPatientInfo } from "../../../utils/patient-profile-utils";
import { usePatientProfileInformationHeader } from "../../context/loadable-patient-profile-information-header-context";

export const PatientProfileInformationHeaderCenterTop = () => {
  const {
    patientDTO,
    MaritalStatusDTOs,
  } = usePatientProfileInformationHeader();

  const findMaritalStatusById = useCallback(
    (id: number) => MaritalStatusDTOs.find((item) => item.id === id),
    [MaritalStatusDTOs]
  );

  const [showCopiedText, setShowCopiedText] = useState({
    showCopiedTextForEmail: false,
    showCopiedTextForMobile: false,
  });

  const copyMobile = () => {
    navigator.clipboard.writeText(
      patientDTO.mobile !== undefined ? patientDTO.mobile : ""
    );
    setShowCopiedText({
      showCopiedTextForEmail: false,
      showCopiedTextForMobile: true,
    });

    setTimeout(() => {
      setShowCopiedText({
        showCopiedTextForEmail: false,
        showCopiedTextForMobile: false,
      });
    }, 5000);
  };

  const copyEmail = () => {
    navigator.clipboard.writeText(
      patientDTO.email !== undefined ? patientDTO.email : ""
    );
    setShowCopiedText({
      showCopiedTextForEmail: true,
      showCopiedTextForMobile: false,
    });

    setTimeout(() => {
      setShowCopiedText({
        showCopiedTextForEmail: false,
        showCopiedTextForMobile: false,
      });
    }, 5000);
  };

  const patientInfo = buildPatientInfo(patientDTO).map((info) => (
    <PatientProfileInformationHeaderCenterTopInformationItem key={info.id}>
      {info.icon !== "whats-app" ? (
        <span className="material-symbols-outlined">{info.icon}</span>
      ) : (
        <img
          style={{ width: "20px", height: "20px" }}
          src={`/img/${info.icon}.svg`}
          alt="What's app"
        />
      )}
      <p>
        {info.id !== 4
          ? info.title
          : findMaritalStatusById(Number(info.title))?.name !== undefined
          ? findMaritalStatusById(Number(info.title))?.name
          : "Unknown"}
      </p>
    </PatientProfileInformationHeaderCenterTopInformationItem>
  ));

  return (
    <div>
      <PatientProfileInformationHeaderCenterTopNameContainer>
        <PatientProfileInformationHeaderCenterTopName>
          {patientDTO.name}
        </PatientProfileInformationHeaderCenterTopName>
        <PatientProfileInformationHeaderCenterTopNameIcons>
          <div onClick={copyMobile}>
            <span className="material-symbols-outlined">call</span>
            <PatientProfileInformationHeaderCenterTopMobileOrMobile>
              {patientDTO.mobile !== undefined ? patientDTO.mobile : ""}
            </PatientProfileInformationHeaderCenterTopMobileOrMobile>
            {showCopiedText.showCopiedTextForMobile ? (
              <CopiedText>copied !</CopiedText>
            ) : (
              <></>
            )}
          </div>
          <div onClick={copyEmail}>
            <span className="material-symbols-outlined">email</span>
            <PatientProfileInformationHeaderCenterTopMobileOrMobile>
              {patientDTO.email !== undefined ? patientDTO.email : ""}
            </PatientProfileInformationHeaderCenterTopMobileOrMobile>
            {showCopiedText.showCopiedTextForEmail ? (
              <CopiedText>copied !</CopiedText>
            ) : (
              <></>
            )}
          </div>
        </PatientProfileInformationHeaderCenterTopNameIcons>
      </PatientProfileInformationHeaderCenterTopNameContainer>
      <PatientProfileInformationHeaderCenterTopInformation>
        {patientInfo}
      </PatientProfileInformationHeaderCenterTopInformation>
    </div>
  );
};
