import { useClient } from "../../context/client-context";
import { useCallback } from "react";
import { BASE_PATH } from "../../constants/paths";
import { AddedSugarDTO } from "../../models/added-sugar-dtos/added-sugar-dto";

export function useGetAddedSugars(): () => Promise<AddedSugarDTO[]> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<AddedSugarDTO[]>(
      `${BASE_PATH}/added-sugars`
    );

    return response.data;
  }, [client]);
}
