import { useForm } from "react-hook-form";
import { Modal } from "../../../../components/modal/modal";
import { ModalForm } from "../../../../components/modal/modal-form/modal-form";
import { useModalHelpers } from "../../../../components/modal/use-modal-helpers";
import { useDeletePatientMeal } from "../../../../hooks/patient-meal-hooks/use-delete-patient-meal";
import { PatientMealDTO } from "../../../../models/patient-meal-dtos/patient-meal-dto";
import {
  Button,
  ModalFooter,
} from "../../../../styles/classes/reusable-classes";
import { DeletePatientMealIcon } from "./styled-delete-patient-meal";
import { useToastService } from "../../../../context/toast-service-context";
import { useReloadPatientMeals } from "../../context/loadable-patient-meals-context";
import { useFoodLoggingData } from "../../context/food-logging-data-context";

type DeletePatientMealProps = {
  patientMealDTO: PatientMealDTO;
};

export const DeletePatientMeal = (props: DeletePatientMealProps) => {
  const { patientMealDTO } = props;

  const { setSelectedPatientMealDTO } = useFoodLoggingData();

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  const deletePatientMeal = useDeletePatientMeal();

  const { handleSubmit } = useForm();
  const { showToast } = useToastService();
  const reload = useReloadPatientMeals();

  const onSubmit = handleSubmit(async () => {
    try {
      await deletePatientMeal(patientMealDTO).then(() =>
        setSelectedPatientMealDTO(undefined)
      );

      closeModal();
      showToast("Success", "Meal Deleted Successfully");
      reload();
    } catch (error) {
      showToast("Error", "Failed to Delete Meal :(");
    }
  });

  return (
    <>
      <DeletePatientMealIcon onClick={openModal}>
        <img src="/img/trash.svg" alt="Delete Icon" />
      </DeletePatientMealIcon>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <ModalForm key={modalBodyKey} onSubmit={onSubmit} height={250}>
          <h2>Are you sure you want to Delete this Meal?</h2>
          <ModalFooter>
            <Button outlined type="button" onClick={closeModal}>
              Cancel
            </Button>
            <Button danger type="submit">
              Delete
            </Button>
          </ModalFooter>
        </ModalForm>
      </Modal>
    </>
  );
};
