import { Temporal } from "temporal-polyfill";
import { PatientCGMEntryDTO } from "../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientType } from "../../../../../../../../models/patient-dtos/patient-type";
import { GAP_THRESHOLD_MINUTES } from "../../../../../../../../constants/config";

type ReadingDataSource = {
  x: Date;
  y?: number;
};

export function buildReadingsSereis(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientType: PatientType
): ReadingDataSource[] {
  const readingsSeries: ReadingDataSource[] = [];

  let lastPlainDateTime: Temporal.PlainDateTime | undefined = undefined;
  for (const patientCGMEntryDTO of patientCGMEntryDTOs) {
    if (patientCGMEntryDTO.source === "Libreview") {
      const plainDateTime = Temporal.PlainDate.from(
        patientCGMEntryDTO.date
      ).toPlainDateTime(Temporal.PlainTime.from(patientCGMEntryDTO.time));

      if (
        lastPlainDateTime !== undefined &&
        Temporal.PlainDateTime.compare(
          lastPlainDateTime.add({ minutes: GAP_THRESHOLD_MINUTES }),
          plainDateTime
        ) === -1
      ) {
        readingsSeries.push({
          x: new Date(`${patientCGMEntryDTO.date}T${patientCGMEntryDTO.time}`),
        });
      }

      readingsSeries.push({
        x: new Date(`${patientCGMEntryDTO.date}T${patientCGMEntryDTO.time}`),
        y: patientCGMEntryDTO.glucoseMGPerDL,
      });

      lastPlainDateTime = plainDateTime;
    }
  }

  return readingsSeries;
}
