import styled from "styled-components/macro";
import {
  flexCenter,
  regularRegularFont,
} from "../../../../../styles/classes/gloabl-classes";

export const PlansDropdownContainer = styled.div`
  ${flexCenter}
  gap: 0px 24px;
`;

export const PlansDropdownLabel = styled.label`
  ${regularRegularFont}
  color: ${({ theme }) => theme.colors.titleColor};
  min-width: 106px;
`;
