import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { PatientMealDTO } from "../../../../../models/patient-meal-dtos/patient-meal-dto";
import { CarbCounterV1MealSummaryDTO } from "../../../../../models/carb-counter-dtos/carb-counter-v1-meal-summary-dto";

export type GroupedPatientMealsByDateMacros = {
  carbs: number;
  fats: number;
  protein: number;
};

export type GroupedPatientMealsByDate = {
  date: string;
  patientMeals: PatientMealDTO[];
  totalKCals: number;
  totalMacros: GroupedPatientMealsByDateMacros;
};

type NutritionAnalysisData = {
  patientMeals: PatientMealDTO[];
  groupedPatientMealsByDate: GroupedPatientMealsByDate[];
  groupedPatientMealsByDateTotalKcals: number[];
  groupedPatientMealsByDateTotalMacros: GroupedPatientMealsByDateMacros[];
};

const NutritionAnalysisDataContext = createContext<
  NutritionAnalysisData | undefined
>(undefined);

type NutritionAnalysisDataProviderProps = PropsWithChildren<{
  patientMeals: PatientMealDTO[];
}>;

function generateExistingCarbCounterMealItem(
  existingPatientMeal: GroupedPatientMealsByDate,
  patientMeal: PatientMealDTO,
  carbCounterV1MealSummary: CarbCounterV1MealSummaryDTO
) {
  existingPatientMeal.patientMeals.push(patientMeal);
  existingPatientMeal.totalKCals += carbCounterV1MealSummary.totalKcal;
  existingPatientMeal.totalMacros.carbs +=
    carbCounterV1MealSummary.totalCarbsGm;
  existingPatientMeal.totalMacros.fats += carbCounterV1MealSummary.totalFatGm;
  existingPatientMeal.totalMacros.protein +=
    carbCounterV1MealSummary.totalProteinGm;
}

function generateExistingMacrosSummaryMealItem(
  existingPatientMeal: GroupedPatientMealsByDate,
  patientMeal: PatientMealDTO
) {
  existingPatientMeal.patientMeals.push(patientMeal);
  existingPatientMeal.totalKCals += patientMeal.patientMealMacrosSummary.kCals;
  existingPatientMeal.totalMacros.carbs +=
    patientMeal.patientMealMacrosSummary.carbsGm;
  existingPatientMeal.totalMacros.fats +=
    patientMeal.patientMealMacrosSummary.fatGm;
  existingPatientMeal.totalMacros.protein +=
    patientMeal.patientMealMacrosSummary.proteinGm;
}

export function NutritionAnalysisDataProvider(
  props: NutritionAnalysisDataProviderProps
) {
  const { patientMeals } = props;

  const groupedPatientMealsByDate: GroupedPatientMealsByDate[] = useMemo(() => {
    const groupedPatientMealsByDate: GroupedPatientMealsByDate[] = [];

    const patientMealsWithPatientMealTagsAndKcalsGreaterThan0 = patientMeals.filter(
      (patientMeal) => {
        if (patientMeal.carbCounterV1MealSummary) {
          return (
            patientMeal.tag !== undefined &&
            patientMeal.carbCounterV1MealSummary.totalKcal > 0
          );
        } else {
          return (
            patientMeal.tag !== undefined &&
            patientMeal.patientMealMacrosSummary.kCals > 0
          );
        }
      }
    );

    for (const patientMeal of patientMealsWithPatientMealTagsAndKcalsGreaterThan0) {
      const existingPatientMeal = groupedPatientMealsByDate.find(
        (patientMealTarget) => patientMealTarget.date === patientMeal.date
      );

      if (existingPatientMeal) {
        const carbCounterV1MealSummary = patientMeal.carbCounterV1MealSummary;

        if (carbCounterV1MealSummary) {
          generateExistingCarbCounterMealItem(
            existingPatientMeal,
            patientMeal,
            carbCounterV1MealSummary
          );
        } else {
          generateExistingMacrosSummaryMealItem(
            existingPatientMeal,
            patientMeal
          );
        }
      } else {
        if (patientMeal.carbCounterV1MealSummary) {
          groupedPatientMealsByDate.push({
            date: patientMeal.date,
            patientMeals: [patientMeal],
            totalKCals: patientMeal.carbCounterV1MealSummary.totalKcal,
            totalMacros: {
              carbs: patientMeal.carbCounterV1MealSummary.totalCarbsGm,
              fats: patientMeal.carbCounterV1MealSummary.totalFatGm,
              protein: patientMeal.carbCounterV1MealSummary.totalProteinGm,
            },
          });
        } else {
          groupedPatientMealsByDate.push({
            date: patientMeal.date,
            patientMeals: [patientMeal],
            totalKCals: patientMeal.patientMealMacrosSummary.kCals,
            totalMacros: {
              carbs: patientMeal.patientMealMacrosSummary.carbsGm,
              fats: patientMeal.patientMealMacrosSummary.fatGm,
              protein: patientMeal.patientMealMacrosSummary.proteinGm,
            },
          });
        }
      }
    }

    return groupedPatientMealsByDate;
  }, [patientMeals]);

  const { groupedPatientMealsByDateTotalKcals } = useMemo(() => {
    const groupedPatientMealsByDateTotalKcals = groupedPatientMealsByDate.map(
      (item) => item.totalKCals
    );

    return { groupedPatientMealsByDateTotalKcals };
  }, [groupedPatientMealsByDate]);

  const { groupedPatientMealsByDateTotalMacros } = useMemo(() => {
    const groupedPatientMealsByDateTotalMacros = groupedPatientMealsByDate.map(
      (item) => item.totalMacros
    );

    return { groupedPatientMealsByDateTotalMacros };
  }, [groupedPatientMealsByDate]);

  const value = useMemo(
    () => ({
      patientMeals,
      groupedPatientMealsByDate,
      groupedPatientMealsByDateTotalKcals,
      groupedPatientMealsByDateTotalMacros,
    }),
    [
      patientMeals,
      groupedPatientMealsByDate,
      groupedPatientMealsByDateTotalKcals,
      groupedPatientMealsByDateTotalMacros,
    ]
  );

  return (
    <NutritionAnalysisDataContext.Provider value={value}>
      {props.children}
    </NutritionAnalysisDataContext.Provider>
  );
}

export function useNutritionAnalysisData(): NutritionAnalysisData {
  const nutritionAnalysisData = useContext(NutritionAnalysisDataContext);

  if (nutritionAnalysisData === undefined) {
    throw new Error(
      `useNutritionAnalysisData must be called within NutritionAnalysisDataProvider`
    );
  }

  return nutritionAnalysisData;
}
