import { useCallback, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { UserResponse } from "stream-chat";
import { useChannelStateContext, useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import {
  Button,
  Input,
  InputContainer,
  InputLabel,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { StreamChatGenerics } from "../../../../../types/stream-chat-types";
import { useEnterKeypress } from "../../../../../../../hooks/use-enter-keypress/use-enter-keypress";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import {
  ChannelModalAutoSelectContainer,
  ChannelModalMembersContainer,
  ChannelModalTagList,
  ChannelModalTagListItem,
} from "../../../../styled-chat-home";
import { useChatQueryUsers } from "../../../../../chat-providers/chat-query-users-context";
import { AutoSelectForMedications } from "../../../../../../../components/auto-select-for-medications/auto-select-for-medications";

type EditChannelModalBodyProps = {
  onClose: () => void;
};

type EditChannelInputs = {
  channelName: string;
  channelUsers: UserResponse<DefaultStreamChatGenerics>[];
};

export const EditChannelModalBody = (props: EditChannelModalBodyProps) => {
  const { onClose } = props;

  const { channel } = useChannelStateContext<StreamChatGenerics>();
  const { users } = useChatQueryUsers();
  const { client } = useChatContext();

  const { control, watch, handleSubmit } = useForm<EditChannelInputs>({
    defaultValues: {
      channelName: channel.data?.name,
      channelUsers: Object.values(channel.state.members).map(
        (member) => member.user
      ),
    },
  });

  const findUserById = useCallback(
    (userId: string) => users.find((item) => item.id === userId),
    [users]
  );

  const [targetUserToAppend, setTargetUserToAppend] = useState<
    UserResponse<DefaultStreamChatGenerics> | undefined
  >();
  const [removedUsers, setRemovedUsers] = useState<
    UserResponse<DefaultStreamChatGenerics>[]
  >([]);

  const { append, fields, remove } = useFieldArray({
    name: "channelUsers",
    control,
  } as never);

  const channelUsersWatcher = watch("channelUsers");

  const handleAppend = () => {
    if (
      channelUsersWatcher !== undefined &&
      targetUserToAppend !== undefined &&
      !channelUsersWatcher.some((obj) => obj.id === targetUserToAppend.id) &&
      users.some((obj) => obj?.id === targetUserToAppend.id)
    ) {
      append(targetUserToAppend);
      setTargetUserToAppend(undefined);
    }
  };

  const { showToast } = useToastService();

  const onSubmit = handleSubmit(async (inputs) => {
    try {
      if (
        client &&
        client.user !== undefined &&
        channelUsersWatcher !== undefined
      ) {
        if (
          inputs.channelUsers !== channel.data?.members &&
          removedUsers.length === 0
        ) {
          await channel.addMembers(
            inputs.channelUsers?.map((channelUser) => channelUser.id)!,
            {
              text: `${
                client.user.name !== undefined
                  ? client.user.name
                  : client.user.id
              } Added a new user to this group`,
            }
          );
        }

        if (inputs.channelName !== channel.data?.name) {
          await channel.update(
            { name: inputs.channelName! },
            {
              text: `${
                client.user.name !== undefined
                  ? client.user.name
                  : client.user.id
              } Changed this group's name`,
            }
          );
        }

        if (removedUsers.length > 0) {
          await channel.removeMembers(removedUsers.map((user) => user.id));
        }

        showToast("Success", "Chat Updated successfully!");

        onClose();
      }
    } catch (error) {
      showToast("Error", "Failed to Create Chat");
    }
  });

  const { onEnterKeyPress } = useEnterKeypress(handleAppend);

  const display = useCallback(
    (user: UserResponse<DefaultStreamChatGenerics>) =>
      user.name !== undefined
        ? `${user.name} ${
            user.whatsAppId !== undefined ? `(user.whatsAppId)` : ``
          }`
        : user.id,
    []
  );

  return (
    <ModalForm onSubmit={onSubmit} height={700} width={520}>
      <ModalHeader onModalClose={onClose} title="Edit Group" />
      <ChannelModalMembersContainer>
        <InputContainer>
          <InputLabel>Channel Name</InputLabel>
          <Controller
            control={control}
            name="channelName"
            render={({ field: { value, onChange } }) => (
              <Input
                type="text"
                placeholder="Enter Channel Name"
                value={value !== undefined ? value : ""}
                onChange={(e) =>
                  onChange(e.target.value !== undefined ? e.target.value : "")
                }
              />
            )}
          />
        </InputContainer>
        <InputLabel>Choose Members</InputLabel>
        <Controller
          control={control}
          name="channelUsers"
          render={() => (
            <ChannelModalAutoSelectContainer>
              <AutoSelectForMedications<UserResponse<DefaultStreamChatGenerics>>
                display={display}
                label=""
                onChange={(value: UserResponse<DefaultStreamChatGenerics>) => {
                  setTargetUserToAppend(value);
                }}
                options={users}
                placeHolder="Add Users"
              />
              <Button
                type="button"
                onClick={handleAppend}
                onKeyDown={(e) => onEnterKeyPress(e)}
                width={100}
                height={40}
                flex
                outlineNoBorder
              >
                <span className="material-symbols-outlined">add</span> Add
              </Button>
            </ChannelModalAutoSelectContainer>
          )}
        />
        <ChannelModalTagList>
          {fields.map((field, index) => (
            <ChannelModalTagListItem key={field.id}>
              {channelUsersWatcher !== undefined
                ? findUserById(channelUsersWatcher[index].id!)?.name !==
                  undefined
                  ? findUserById(channelUsersWatcher[index].id!)?.name
                  : findUserById(channelUsersWatcher[index].id!)?.id
                : ""}
              {channelUsersWatcher[index].id! !== client.userID && (
                <span
                  className="material-symbols-outlined"
                  onClick={async () => {
                    remove(index);
                    setRemovedUsers([
                      ...removedUsers,
                      channelUsersWatcher[index],
                    ]);
                  }}
                >
                  close
                </span>
              )}
            </ChannelModalTagListItem>
          ))}
        </ChannelModalTagList>
      </ChannelModalMembersContainer>
      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit">Submit</Button>
      </ModalFooter>
    </ModalForm>
  );
};
