import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { BASE_PATH } from "../../constants/paths";
import { ChatTokenDTO } from "../../models/chat-dtos/chat-token-dto";

export function useGenerateToken(): () => Promise<ChatTokenDTO> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.post<ChatTokenDTO>(`${BASE_PATH}/chat/token`);

    return response.data;
  }, [client]);
}
