import { ActionIconsContainer } from "./styled-action-icons";

type ActionIconsProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

export const ActionIcons = (props: ActionIconsProps) => {
  const { onConfirm, onCancel } = props;

  return (
    <ActionIconsContainer>
      <span className="material-symbols-outlined" onClick={onConfirm}>
        done
      </span>
      <span className="material-symbols-outlined" onClick={onCancel}>
        close
      </span>
    </ActionIconsContainer>
  );
};
