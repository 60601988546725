import { Temporal } from "temporal-polyfill";
import { ModalForm } from "../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../../styles/classes/reusable-classes";
import { MealWithFrequencies } from "../../../utils/create-meals-with-frequencies";
import {
  NutritionReportFeedbackTableCellBodyContainer,
  NutritionReportFeedbackTableCellTextArea,
  NutritionReportFeedbackTableCellTextAreaContainer,
} from "./styled-nutrition-report-feedback-table-cell-modal-body";
import { Controller, useForm } from "react-hook-form";
import { usePutPatientMealFeedback } from "../../../../../../../hooks/patient-meal-hooks/use-put-patient-meal-feedback";
import { PutPatientMealFeedbackDTO } from "../../../../../../../models/patient-meal-dtos/put-patient-meal-feedback-dto";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { useFoodLoggingData } from "../../../../../context/food-logging-data-context";
import { useReloadPatientNutritionReportDailySummary } from "../../../../../context/nutrition-report-context/loadable-patient-nutrition-report-daily-summary-context";

type NutritionReportFeedbackTableCellModalBodyProps = {
  onClose: () => void;
  meal: MealWithFrequencies;
};

type NutritionReportFeedbackTableCellModalBodyInput = {
  feedback?: string;
};

export const NutritionReportFeedbackTableCellModalBody = (
  props: NutritionReportFeedbackTableCellModalBodyProps
) => {
  const { onClose, meal } = props;

  const mealTitle = `${Temporal.PlainDate.from(meal.date).toLocaleString(
    "en-UK",
    { day: "numeric", month: "short" }
  )} ${Temporal.PlainTime.from(meal.time).toLocaleString("en-UK", {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    second: undefined,
  })}`;

  const putPatientMealFeedback = usePutPatientMealFeedback();
  const { showToast } = useToastService();
  const { patientDTOs } = useFoodLoggingData();
  const reload = useReloadPatientNutritionReportDailySummary();

  const findPatientDTO = patientDTOs.filter(
    (patientDTO) => patientDTO.id === meal.patientId
  )[0];

  const { control, handleSubmit } =
    useForm<NutritionReportFeedbackTableCellModalBodyInput>({
      defaultValues: {
        feedback: meal.feedback !== undefined ? meal.feedback : undefined,
      },
    });

  const onSubmit = handleSubmit(async (input) => {
    const putPatientMealFeedbackDTO: PutPatientMealFeedbackDTO = {
      feedback: input.feedback!,
    };

    try {
      await putPatientMealFeedback(
        findPatientDTO,
        meal.id,
        putPatientMealFeedbackDTO
      );
      showToast("Success", "Feedback updated successfully!");
      onClose();
      reload();
    } catch (error) {
      showToast("Error", "Failed to update Feedback note");
    }
  });

  return (
    <ModalForm onSubmit={onSubmit} width={400} height={306}>
      <ModalHeader
        onModalClose={onClose}
        title={`Edit Meal Feedback (${mealTitle})`}
      />
      <NutritionReportFeedbackTableCellBodyContainer>
        <NutritionReportFeedbackTableCellTextAreaContainer>
          <Controller
            name="feedback"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <NutritionReportFeedbackTableCellTextArea
                value={value === undefined ? "" : value}
                onChange={(e) =>
                  onChange(e.target.value === undefined ? "" : e.target.value)
                }
                placeholder="Write feedback..."
              ></NutritionReportFeedbackTableCellTextArea>
            )}
          />
        </NutritionReportFeedbackTableCellTextAreaContainer>
      </NutritionReportFeedbackTableCellBodyContainer>
      <ModalFooter>
        <Button outlined type="button">
          Cancel
        </Button>
        <Button type="submit">Confirm</Button>
      </ModalFooter>
    </ModalForm>
  );
};
