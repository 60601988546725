import { PATIENTS_PATH } from "../constants/paths";
import { useClient } from "../context/client-context";
import { useCallback } from "react";
import { PatientMedicationDTO } from "../models/patient-medication-dto";
import { MedicationIndication } from "../models/medications/medication-indication";

export function useGetPatientMedications(): (
  patientId: number,
  indication?: MedicationIndication,
  isPrescribed?: boolean
) => Promise<PatientMedicationDTO[]> {
  const client = useClient();

  return useCallback(
    async (patientId, indication = undefined, isPrescribed = undefined) => {
      // A note: If `patientId` changes to be `string`, you shouldn't use string interpolation to build the url.
      // E.g., if patientId is "abc/def", that'd break the request if you directly interpolate it. You need to encode it first.
      const response = await client.get<PatientMedicationDTO[]>(
        `${PATIENTS_PATH}/${patientId}/medications`,
        {
          params: {
            indication,
            isPrescribed,
          },
        }
      );

      return response.data;
    },
    [client]
  );
}
