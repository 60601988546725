import styled from "styled-components/macro";
import { flexCenterSpaceBetween } from "../../../../styles/classes/gloabl-classes";

export const PrescriptionsHeader = styled.div`
  padding: ${({ theme }) => theme.padding.pad1All};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenterSpaceBetween}

  @media screen and (max-width: 640px) {
    flex-direction: column;
    gap: 1rem 0rem;
  }
`;

export const PrescriptionsHeaderTitleContainer = styled.div`
  p {
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
    font-size: 21px;
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0rem 0rem 0.5rem;
  }

  @media screen and (max-width: 640px) {
    ${flexCenterSpaceBetween}
    gap: 0rem 1rem;
  }
`;

export const PrescriptionsContainer = styled.div`
  padding: ${({ theme }) => theme.padding.pad1All};
  display: grid;
  gap: 1rem 0rem;
`;
