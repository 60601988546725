import { useLocation } from "react-router-dom";
import { useContentModuleMode } from "../../../context/content-module-mode-context";
import { useEffect } from "react";
import { ReadContentModule } from "../../../components/read-content-module/read-content-module";
import { ContentCourseModules } from "../../../content-course-modules/content-course-modules";
import { UpdateContentModule } from "../../../components/update-content-module/update-content-module";

export const ContentCourseModes = () => {
  const {
    contentModuleMode,
    onContentModuleModeChangeToUpdate,
  } = useContentModuleMode();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("update")) {
      onContentModuleModeChangeToUpdate(contentModuleMode.mode.contentModule);
    }
  }, [
    location,
    onContentModuleModeChangeToUpdate,
    contentModuleMode.mode.contentModule,
  ]);

  if (contentModuleMode.mode.kind === "Read") {
    return (
      <ReadContentModule
        contentModuleDTOType="Course"
        childTable={
          <ContentCourseModules
            contentModuleDTO={contentModuleMode.mode.contentModule}
          />
        }
      />
    );
  }

  return (
    <UpdateContentModule
      contentModuleDTO={contentModuleMode.mode.contentModule}
      routeAfterUpdate={`/content/courses/course/${contentModuleMode.mode.contentModule.id}`}
      childTable={
        <ContentCourseModules
          contentModuleDTO={contentModuleMode.mode.contentModule}
        />
      }
    />
  );
};
