import styled from "styled-components/macro";
import { flexCenterSpaceBetween } from "../../../styles/classes/gloabl-classes";
import {
  Loader,
  LoaderContainer,
} from "../../../components/loaders/otida-loader/styled-otida-loader";

export const BGLAnalysisHeaderContainer = styled.header`
  ${flexCenterSpaceBetween}
  padding: 18px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  height: 80px;

  p {
    margin: 0px;
    font-size: 20px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;

export const BGLAnalysisSubHeaderContainer = styled.div`
  ${flexCenterSpaceBetween}
  padding: 16px;

  ${LoaderContainer} {
    padding: 0px;

    img {
      display: none;
    }
  }

  ${Loader} {
    ::before {
      width: 30px;
      height: 30px;
    }
  }
`;
