import {
  Avatar,
  ChannelPreviewUIComponentProps,
  useChatContext,
} from "stream-chat-react";
import type { Channel, DefaultGenerics } from "stream-chat";
import {
  MessagingChannelPreviewContainer,
  MessagingChannelPreviewContentTop,
  MessagingChannelPreviewContentTopNameContainer,
  MessagingChannelPreviewContentWrapper,
  MessagingChannelPreviewMessage,
  MessagingChannelPreviewName,
  MessagingChannelPreviewTime,
  MessagingChannelPreviewUnreadCount,
} from "./styled-messaging-channel-preview";
import { StreamChatGenerics } from "../../../../types/stream-chat-types";
import { memo } from "react";
import {
  getChannelName,
  getTimeStamp,
} from "./utils/messaging-channel-preview-utils";
import { useChatQueryUsers } from "../../../../chat-providers/chat-query-users-context";

type MessagingChannelPreviewProps =
  ChannelPreviewUIComponentProps<DefaultGenerics> & {
    channel: Channel;
    handleHideMediaSidebar: () => void;
  };

export const MessagingChannelPreview = memo(
  (props: MessagingChannelPreviewProps) => {
    const { channel, lastMessage, handleHideMediaSidebar } = props;

    const {
      channel: activeChannel,
      client,
      setActiveChannel,
    } = useChatContext<StreamChatGenerics>();

    const { users } = useChatQueryUsers();

    const members = Object.values(channel.state.members).filter(
      ({ user }) => user?.id !== client.userID
    );

    const channelAvatar = (() => {
      if (members.length === 0) {
        return "/img/chat/user.svg";
      }

      if (members.length === 1) {
        const user = users.find((user) => user.id === members[0].user?.id);

        return user?.image ?? "/img/chat/user.svg";
      }

      return "/img/chat/group.svg";
    })();

    const hideMessagingChannelPreview = lastMessage === undefined;

    return (
      <MessagingChannelPreviewContainer
        active={channel?.id === activeChannel?.id}
        hideMessagingChannelPreview={hideMessagingChannelPreview}
        onClick={() => {
          setActiveChannel?.(channel);
          handleHideMediaSidebar();
        }}
      >
        <MessagingChannelPreviewContentWrapper>
          <MessagingChannelPreviewContentTop>
            <MessagingChannelPreviewContentTopNameContainer>
              <Avatar image={channelAvatar} size={20} shape="circle" />
              <MessagingChannelPreviewName>
                {channel.data?.name || getChannelName(members)}
              </MessagingChannelPreviewName>
            </MessagingChannelPreviewContentTopNameContainer>
            <MessagingChannelPreviewTime>
              {getTimeStamp(channel)}
            </MessagingChannelPreviewTime>
          </MessagingChannelPreviewContentTop>
          <MessagingChannelPreviewMessage>
            {lastMessage?.text ?? "Send a message"}
            {lastMessage?.attachments?.length! > 0 && "attachment"}
          </MessagingChannelPreviewMessage>
        </MessagingChannelPreviewContentWrapper>
        {channel.countUnread() > 0 && (
          <MessagingChannelPreviewUnreadCount>
            {channel.countUnread()}
          </MessagingChannelPreviewUnreadCount>
        )}
      </MessagingChannelPreviewContainer>
    );
  }
);
