import { useEffect, useState } from "react";
import {
  MessagingMediaSidebarMediaListContainer,
  MessagingMediaSidebarMediaSubListContainer,
} from "./styled-messaging-media-sidebar-media-list";
import { MessagingMediaSidebarMediaListNavList } from "./messaging-media-sidebar-media-list-nav-list/messaging-media-sidebar-media-list-nav-list";
import { MediaSidebarDocsList } from "./lists/media-sidebar-docs-list";
import { MediaSidebarMediaList } from "./lists/media-sidebar-media-list/media-sidebar-media-list";
import { MediaSidebarLinksList } from "./lists/media-sidebar-links-list";
import { useChatContext } from "stream-chat-react";
import { MessageResponse } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { randomUUID } from "../../../../../../../utils/random-uuid";

export type MessagingMediaSidebarMediaListActiveState =
  | "Media"
  | "Links"
  | "Docs";

export const MessagingMediaSidebarMediaList = () => {
  const [mediaListActiveState, setMediaListActiveState] =
    useState<MessagingMediaSidebarMediaListActiveState>("Media");

  const { channel, client } = useChatContext();

  const [messages, setMessages] = useState<
    MessageResponse<DefaultStreamChatGenerics>[]
  >([]);

  useEffect(() => {
    const getMessages = async () => {
      const channelState = await client
        .channel("messaging", channel?.id)
        .query({});

      if (
        channelState.messages.some(
          (message) =>
            message.attachments !== undefined && message.attachments.length > 0
        )
      ) {
        const filteredMessages = channelState.messages.filter(
          (message) =>
            message.attachments !== undefined &&
            message.attachments.length > 0 &&
            message.attachments.some(
              (attachment) => attachment.type !== "audio"
            )
        );

        setMessages([...filteredMessages]);
      }
    };

    getMessages();
  }, [channel?.id, client, channel?.state.messages.length]);

  return (
    <MessagingMediaSidebarMediaListContainer>
      <MessagingMediaSidebarMediaListNavList
        mediaListActiveState={mediaListActiveState}
        setMediaListActiveState={setMediaListActiveState}
      />
      <MessagingMediaSidebarMediaSubListContainer>
        {mediaListActiveState === "Media" && (
          <MediaSidebarMediaList key={randomUUID()} messages={messages} />
        )}
        {mediaListActiveState === "Links" && (
          <MediaSidebarLinksList key={randomUUID()} />
        )}
        {mediaListActiveState === "Docs" && (
          <MediaSidebarDocsList key={randomUUID()} />
        )}
      </MessagingMediaSidebarMediaSubListContainer>
    </MessagingMediaSidebarMediaListContainer>
  );
};
