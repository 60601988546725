import { PatientMealDTO } from "../../../../../../models/patient-meal-dtos/patient-meal-dto";
import { FoodLoggingSelectedPatientMealImages } from "../../../components/food-logging-selected-patient-meal-images/food-logging-selected-patient-meal-images";
import { FoodLoggingSelectedPatientMealNotes } from "../../../components/food-logging-selected-patient-meal-notes/food-logging-selected-patient-meal-notes";
import { FoodLoggingSelectedPatientMealVoiceNotes } from "../../../components/food-logging-selected-patient-meal-voice-notes/food-logging-selected-patient-meal-voice-notes";
import { getMealDisplayDateAndTime } from "../../../utils/food-logging-selected-patient-meal-utils";
import {
  CarbCounterFoodLoggingSelectedPatientMealMediaContainer,
  CarbCounterFoodLoggingSelectedPatientMealMediaTimeStamp,
} from "./styled-carb-counter-food-logging-selected-patient-meal-media";

type CarbCounterFoodLoggingSelectedPatientMealMediaProps = {
  patientMealDTO: PatientMealDTO;
};

export const CarbCounterFoodLoggingSelectedPatientMealMedia = (
  props: CarbCounterFoodLoggingSelectedPatientMealMediaProps
) => {
  const { patientMealDTO } = props;

  const displayMealDate =
    getMealDisplayDateAndTime(patientMealDTO).displayMealDate;

  const displayMealTime =
    getMealDisplayDateAndTime(patientMealDTO).displayMealTime;

  return (
    <CarbCounterFoodLoggingSelectedPatientMealMediaContainer>
      <CarbCounterFoodLoggingSelectedPatientMealMediaTimeStamp>
        <p>
          {displayMealDate}, {displayMealTime}
        </p>
        <span>
          {patientMealDTO.tag !== undefined ? `[${patientMealDTO.tag}]` : ``}
        </span>
      </CarbCounterFoodLoggingSelectedPatientMealMediaTimeStamp>
      <div>
        {patientMealDTO.patientMealVoiceNotes.length > 0 && (
          <FoodLoggingSelectedPatientMealVoiceNotes
            patientMealVoiceNotes={patientMealDTO.patientMealVoiceNotes}
          />
        )}
        {patientMealDTO.patientMealImages.length > 0 && (
          <FoodLoggingSelectedPatientMealImages
            patientMealImageDTOs={patientMealDTO.patientMealImages}
          />
        )}
        {patientMealDTO.patientMealVoiceNotes.length === 0 &&
          patientMealDTO.patientMealImages.length === 0 && (
            <FoodLoggingSelectedPatientMealNotes
              patientMealDTONote={patientMealDTO.note}
            />
          )}
      </div>
    </CarbCounterFoodLoggingSelectedPatientMealMediaContainer>
  );
};
