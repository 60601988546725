import { Controller, useFormContext } from "react-hook-form";
import {
  RadioButton,
  UXInputContainer,
  UXInputLabel,
} from "../../../../../styles/classes/reusable-classes";
import { ContentModuleUpdateModeInputs } from "../../../context/content-module-mode-context";
import { ContentModuleType } from "../../../../../models/content-module-dtos/content-module-type";
import { useModalHelpers } from "../../../../../components/modal/use-modal-helpers";
import { LessonStatusMessageModal } from "../../lesson-status-message-modal/lesson-status-message-modal";
import { useEffect } from "react";

type UpdateContentModuleStatusControllerProps = {
  contentModuleType: ContentModuleType;
  isDisabled: boolean;
};

export const UpdateContentModuleStatusController = (
  props: UpdateContentModuleStatusControllerProps
) => {
  const { contentModuleType, isDisabled } = props;
  const { control, setValue } = useFormContext<ContentModuleUpdateModeInputs>();

  useEffect(() => {
    if (isDisabled) {
      setValue("status", false);
    }
  }, [setValue, isDisabled]);

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  return (
    <Controller
      control={control}
      name="status"
      render={({ field: { value, onChange } }) => (
        <UXInputContainer $width="240px">
          <UXInputLabel $width="110px">{contentModuleType} Status</UXInputLabel>
          <RadioButton
            type="button"
            checked={value}
            onClick={() => {
              if (isDisabled) {
                openModal();
              } else {
                onChange(!value);
              }
            }}
          >
            <span />
          </RadioButton>
          <LessonStatusMessageModal
            closeModal={closeModal}
            isModalOpen={isModalOpen}
            modalBodyKey={modalBodyKey}
          />
        </UXInputContainer>
      )}
    />
  );
};
