import { useMemo } from "react";
import { PatientLabTestResultDTO } from "../../../../../../../models/patient-lab-test-result-dtos/patient-lab-test-result-dto";
import { DEFAULT_KEY_INDICATORS_LAB_TESTS_NAMES } from "../../../../../../../data/sidebar-data/default-key-indicators-data";
import { Temporal } from "temporal-polyfill";
import {
  PatientProfileInformationHeaderCenterBottomCard,
  PatientProfileInformationHeaderCenterBottomCardProgress,
  PatientProfileInformationHeaderCenterBottomCardValue,
} from "../styled-patient-profile-information-header-center-bottom";
import { usePatientProfileInformationHeader } from "../../../context/loadable-patient-profile-information-header-context";

export const PatientProfileInformationHeaderKeyIndicators = () => {
  const { patientLabTestResultsDTOs, labTestTypeDTOs } =
    usePatientProfileInformationHeader();

  const keyIndicatorsLatestResult = useMemo(() => {
    const keyIndicatorsLatestResult: Map<string, PatientLabTestResultDTO> =
      new Map();
    for (const patientLabTestResultsDTO of patientLabTestResultsDTOs) {
      const labTestType = labTestTypeDTOs.find(
        (labTestTypeDTO) =>
          labTestTypeDTO.id === patientLabTestResultsDTO.labTestTypeId
      )!.name;
      if (!DEFAULT_KEY_INDICATORS_LAB_TESTS_NAMES.includes(labTestType)) {
        continue;
      }

      const currentLatestResult = keyIndicatorsLatestResult.get(labTestType);

      if (
        currentLatestResult === undefined ||
        Temporal.PlainDate.compare(
          Temporal.PlainDate.from(patientLabTestResultsDTO.date),
          Temporal.PlainDate.from(currentLatestResult.date)
        ) > 0
      ) {
        keyIndicatorsLatestResult.set(labTestType, patientLabTestResultsDTO);
      }
    }

    return DEFAULT_KEY_INDICATORS_LAB_TESTS_NAMES.map((keyIndicator) => ({
      keyIndicator,
      patientLabTestResultDTO: keyIndicatorsLatestResult.get(keyIndicator),
      unit: labTestTypeDTOs.find(
        (labTestTypeDTO) => labTestTypeDTO.name === keyIndicator
      )!.unit,
    }));
  }, [labTestTypeDTOs, patientLabTestResultsDTOs]);

  const patientProfileKeyIndicators = keyIndicatorsLatestResult.map(
    ({ keyIndicator, patientLabTestResultDTO, unit }) => (
      <PatientProfileInformationHeaderCenterBottomCard key={keyIndicator}>
        {patientLabTestResultDTO === undefined && (
          <PatientProfileInformationHeaderCenterBottomCardValue>
            N/A
          </PatientProfileInformationHeaderCenterBottomCardValue>
        )}
        {patientLabTestResultDTO !== undefined && (
          <PatientProfileInformationHeaderCenterBottomCardValue>
            {patientLabTestResultDTO.result}{" "}
            <span>{unit === "-" ? "" : unit}</span>
          </PatientProfileInformationHeaderCenterBottomCardValue>
        )}
        <PatientProfileInformationHeaderCenterBottomCardProgress>
          <h1>{keyIndicator}</h1>
        </PatientProfileInformationHeaderCenterBottomCardProgress>
      </PatientProfileInformationHeaderCenterBottomCard>
    )
  );

  return <>{patientProfileKeyIndicators}</>;
};
