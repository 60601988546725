import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { Temporal } from "temporal-polyfill";
import { useNutritionReportSearchParams } from "./nutrition-report-context/nutrition-report-search-params-context";

type FoodLoggingDatePickerContextType = {
  startDate: Date;
  endDate: Date;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date) => void;
  startDateString: string;
  endDateString: string;
};

const FoodLoggingDatePickerContext = createContext<
  FoodLoggingDatePickerContextType | undefined
>(undefined);

export const FoodLoggingDatePickerProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  const { searchParams } = useNutritionReportSearchParams();

  const searchParamsStartDate = searchParams.get("startDate");
  const searchParamsEndDate = searchParams.get("endDate");

  const [startDate, setStartDate] = useState(
    searchParamsStartDate !== null && searchParamsStartDate !== ""
      ? new Date(searchParamsStartDate)
      : new Date()
  );
  const [endDate, setEndDate] = useState(
    searchParamsEndDate !== null && searchParamsEndDate !== ""
      ? new Date(searchParamsEndDate)
      : new Date()
  );

  const startDateString = Temporal.PlainDate.from(
    startDate.toISOString().split("T")[0]
  ).toString();

  const endDateString = Temporal.PlainDate.from(
    endDate.toISOString().split("T")[0]
  ).toString();

  const value = useMemo(
    () => ({
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      startDateString,
      endDateString,
    }),
    [
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      startDateString,
      endDateString,
    ]
  );

  return (
    <FoodLoggingDatePickerContext.Provider value={value}>
      {children}
    </FoodLoggingDatePickerContext.Provider>
  );
};

export const useFoodLoggingDatePicker =
  (): FoodLoggingDatePickerContextType => {
    const foodLoggingDatePicker = useContext(FoodLoggingDatePickerContext);

    if (foodLoggingDatePicker === undefined) {
      throw new Error(
        `useFoodLoggingDatePicker must be within FoodLoggingDatePickerProvider`
      );
    }

    return foodLoggingDatePicker;
  };
