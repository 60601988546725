import { ChangeEventHandler } from "react";
import {
  LabResultsDateInputContainer,
  LabResultsDateInputInput,
  LabResultsDateInputLabel,
} from "./styled-lab-results-date-input";

type LabResultsDateInputProps = {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
};

export const LabResultsDateInput = (props: LabResultsDateInputProps) => {
  const { value, onChange } = props;

  return (
    <LabResultsDateInputContainer>
      <LabResultsDateInputLabel>Date</LabResultsDateInputLabel>
      <LabResultsDateInputInput type="date" value={value} onChange={onChange} />
    </LabResultsDateInputContainer>
  );
};
