import { ReadingTooltip, ReadingTooltipProps } from "./reading-tooltip";
import { MealTooltip, MealTooltipProps } from "./meal-tooltip";
import { assertNever } from "../../../utils/assert-never";
import { MedicinesTooltip, MedicinesTooltipProps } from "./medicines-tooltip";
import { TooltipWrapper } from "../../../styles/classes/tooltips";

export type TooltipProps = CommonTooltipProps &
  (
    | { kind: "reading"; readingTooltipProps: ReadingTooltipProps }
    | { kind: "meal"; mealTooltipProps: MealTooltipProps }
    | { kind: "medicines"; medicinesTooltipProps: MedicinesTooltipProps }
  );

export type CommonTooltipProps = { x: number; y: number };

export function Tooltip(props: TooltipProps) {
  const { x, y } = props;

  function returnXandYForToolTip(x: number, y: number) {
    const xRangeCondition = x > 25 && x < 75;
    const readingTooltipCondtion = props.kind === "reading";

    if (xRangeCondition && !readingTooltipCondtion) {
      return "translate(-150px, -100%)";
    } else if (x > 75 && !readingTooltipCondtion) {
      return "translate(-290px, -50%)";
    } else if (y > 70 && !readingTooltipCondtion) {
      return "translateY(-50%)";
    } else if (xRangeCondition && readingTooltipCondtion) {
      return "translate(-140px, 0px)";
    } else if (x > 75 && readingTooltipCondtion) {
      return "translate(-190px, 0%)";
    } else {
      return "";
    }
  }

  if (props.kind === "reading") {
    return (
      <TooltipWrapper translateToolTip={returnXandYForToolTip(x, y)}>
        <ReadingTooltip {...props.readingTooltipProps} />
      </TooltipWrapper>
    );
  } else if (props.kind === "meal") {
    return (
      <TooltipWrapper translateToolTip={returnXandYForToolTip(x, y)}>
        <MealTooltip {...props.mealTooltipProps} />
      </TooltipWrapper>
    );
  } else if (props.kind === "medicines") {
    return (
      <TooltipWrapper translateToolTip={returnXandYForToolTip(x, y)}>
        <MedicinesTooltip {...props.medicinesTooltipProps} />
      </TooltipWrapper>
    );
  } else {
    return assertNever(props);
  }
}
