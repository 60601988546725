import { css } from "styled-components";

// Flex Classes
export const flexCenterSpaceBetween = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const flexCenterCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const flexCenterSpaceAround = css`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const flexCenter = css`
  display: flex;
  align-items: center;
`;

export const flexCol = css`
  display: flex;
  flex-direction: column;
`;

export const flexColSpaceBetween = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const flexColCenterSpaceBetween = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const flexColCenterCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const flexColCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Fonts
export const xSmallSemiBoldFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.xsFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
`;

export const smallRegularFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.smFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
`;

export const smallMediumFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.smFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
`;

export const smallSemiBoldFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.smFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
`;

export const mediumSemiBoldFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.mdFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
`;

export const mediumFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.mdFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
`;

export const mediumRegularFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.mdFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
`;

export const regularMediumFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
`;

export const regularRegularFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
`;

export const regularSemiBoldFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
`;

export const lgBoldFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.lgFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`;

export const lgSemiBoldFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.lgFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
`;

export const lgMediumFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.lgFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
`;

export const xlSemiBoldFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.xlFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
`;

export const cardDateFont = css`
  font-size: ${({ theme }) => theme.typography.fontSize.regularSmFont};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const cardTitleFont = css`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  font-size: 18px;
  color: ${({ theme }) => theme.colors.titleColor};
`;
