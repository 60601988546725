import styled, { css } from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  mediumFont,
} from "../../../../../../../../styles/classes/gloabl-classes";
import {
  lightShadowDropdownList,
  transition,
} from "../../../../../../../../styles/global-style";

export const TagDropdownListCSS = css`
  background: ${({ theme }) => theme.colors.containerColor};
  z-index: ${({ theme }) => theme.zIndex.z700};
  position: absolute;
  border-radius: 8px;
  ${lightShadowDropdownList}
  padding: 0px;
  margin: 0px;
`;

export const TagDropdownContainer = styled.div<{ filter?: "true" | "false" }>`
  cursor: pointer;
  position: relative;
  width: fit-content;
  z-index: ${({ theme, filter }) =>
    filter ? theme.zIndex.z500 : theme.zIndex.z700};

  div {
    background: ${({ theme }) => theme.colors.containerColor};
    border-radius: 8px;
    height: 31px;
  }

  @media screen and (max-width: 500px) {
    width: 200px;
  }
`;

export const TagDropdownButton = styled.button<{
  value?: string;
  showDropdown: boolean;
  feedbackFilter?: boolean;
}>`
  ${flexCenterSpaceBetween}
  ${mediumFont}
  padding: 5px 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  border-bottom-left-radius: ${({ showDropdown }) =>
    showDropdown ? "0px" : "8px"};
  border-bottom-right-radius: ${({ showDropdown }) =>
    showDropdown ? "0px" : "8px"};
  background: ${({ theme, feedbackFilter }) =>
    feedbackFilter ? theme.colors.inputColor : theme.colors.containerColor};
  color: ${({ theme, value }) =>
    value !== undefined ? theme.colors.titleColor : theme.colors.textColor};
  min-width: 170px;
  width: fit-content;
  transition: ${transition};

  span {
    margin-left: 16px;
    transform: ${({ showDropdown }) =>
      showDropdown ? "rotate(-180deg)" : "rotate(0deg)"};
    transition: ${transition};
  }
`;

export const TagDropdownList = styled.ul<{
  showDropdown: boolean;
  showNestedList: boolean;
}>`
  ${TagDropdownListCSS}
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: ${({ showNestedList }) =>
    showNestedList ? "0px" : "8px"};
  max-height: ${({ showDropdown }) => (showDropdown ? "206px" : "0px")};
  height: fit-content;
  overflow-y: ${({ showDropdown }) => (showDropdown ? "auto" : "hidden")};
  overflow-x: hidden;
  top: 36px;
  left: 0;
  width: 100%;
  right: 0;
  transition: ${({ showDropdown }) => (showDropdown ? transition : "")};
`;

export const TagDropdownListItem = styled.li<{
  active?: true;
  showNestedList?: true;
}>`
  ${flexCenterSpaceBetween}
  ${mediumFont}
  cursor: pointer;
  width: 100%;
  padding: 10px 16px;
  padding-right: ${({ showNestedList }) => (showNestedList ? "8px" : "16px")};
  background: ${({ theme, active }) =>
    active ? theme.colors.primaryColor : theme.colors.containerColor};
  color: ${({ theme, active }) =>
    active ? theme.colors.buttonColor : theme.colors.titleColor};
  transition: ${transition};

  ::selection {
    display: none;
  }

  :hover {
    background: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.buttonColor};
  }

  span {
    transform: rotate(270deg);
  }
`;
