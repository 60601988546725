import styled from "styled-components/macro";
import { transition } from "../../../styles/global-style";

export const LogoutModalContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 1rem;
  border-radius: 0.75rem;
  width: 470px;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.z600};

  @media screen and (max-width: 500px) {
    width: 100%;
    position: fixed;
    border-radius: 2.625rem 2.625rem 0rem 0rem;
    bottom: 0;
  }

  ::before {
    content: "";
    background: ${({ theme }) => theme.colors.logoutModalBackground};
    border-radius: 32px;
    position: absolute;
    width: 48px;
    height: 4px;
    left: calc(50% - 48px / 2 + 0.5px);
    top: 0.5rem;
  }
`;

export const LogoutModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: ${({ theme }) => theme.colors.titleColor};
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
    font-size: ${({ theme }) => theme.typography.fontSize.lgFontSize};
  }

  span {
    border-radius: 12px;
    padding: 0.5rem;
    color: ${({ theme }) => theme.colors.titleColor};
    border: 1.5px solid ${({ theme }) => theme.colors.borderColor};
    cursor: pointer;
  }
`;

export const LogoutModalImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoutText = styled.p`
  text-align: center;
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
`;

export const LogoutModalButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0rem;
`;

export const LogoutModalButton = styled.button<{ outline?: boolean }>`
  border-radius: 12px;
  padding: 17px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: ${transition};
  cursor: pointer;
  background: ${({ outline, theme }) =>
    outline ? "transparent" : theme.colors.danger};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
  color: ${({ theme, outline }) =>
    outline ? theme.colors.titleColor : theme.colors.buttonColor};
  border: 1px solid
    ${({ outline, theme }) =>
      outline ? theme.colors.logoutModalBorder : "transparent"};
`;
