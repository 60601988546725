import { useCallback } from "react";
import { useGetPatientMeals } from "../../../hooks/patient-meal-hooks/use-get-patient-meals";
import { PatientMealDTO } from "../../../models/patient-meal-dtos/patient-meal-dto";
import { useFoodLoggingData } from "../context/food-logging-data-context";
import { FoodLoggingPatientsTab } from "../food-logging-patients/food-logging-patients-tabs/food-logging-patients-tabs";
import { useLoadableData } from "../../../hooks/use-loadable-data";
import { usePatientTodoMealsCountsUpdates } from "../context/patient-todo-meals-counts-updates-context";
import { LoadablePatientMealsProvider } from "../context/loadable-patient-meals-context";
import { LoadableDataLoading } from "../../../components/loadable-data-loading";
import { FoodLoggingPatientMeals } from "./food-logging-patient-meals";
import { getPatientMealAfterUpdates } from "../util/get-patient-meal-after-updates";

type LoadablePatientMealsProps = {
  selectedPatientMealDTO: PatientMealDTO | undefined;
  onPatientMealChange: (patientMealDTO: PatientMealDTO) => void;
  selectedTab: FoodLoggingPatientsTab;
};

export const LoadablePatientMeals = (props: LoadablePatientMealsProps) => {
  const { onPatientMealChange, selectedPatientMealDTO, selectedTab } = props;

  const { selectedPatientDTO } = useFoodLoggingData();

  const getPatientMeals = useGetPatientMeals();

  const load = useCallback(
    // guaranteed to be defined because of the `selectedPatientDTO && <Loading load={loadPatientMeals}...` in the
    // render part
    () => getPatientMeals(selectedPatientDTO?.id!),
    [getPatientMeals, selectedPatientDTO?.id]
  );

  const loadablePatientMeals = useLoadableData(load);

  const { lastUpdatedPatientMealByPatientMealId } =
    usePatientTodoMealsCountsUpdates();

  return (
    <LoadablePatientMealsProvider loadablePatientMeals={loadablePatientMeals}>
      <LoadableDataLoading
        state={loadablePatientMeals.state}
        successComponent={(patientMealDTOs) => (
          <FoodLoggingPatientMeals
            patientMealDTOs={patientMealDTOs.map((patientMealDTO) =>
              getPatientMealAfterUpdates(
                patientMealDTO,
                lastUpdatedPatientMealByPatientMealId
              )
            )}
            selectedPatientMealDTO={selectedPatientMealDTO}
            onPatientMealChange={onPatientMealChange}
            selectedTab={selectedTab}
          />
        )}
      />
    </LoadablePatientMealsProvider>
  );
};
