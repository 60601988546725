import styled from "styled-components/macro";
import { mediumRegularFont } from "../../styles/classes/gloabl-classes";
import { transition } from "../../styles/global-style";

export const AutoSelectInput = styled.input`
  outline: 0;
  border: 0;
  font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
  background: transparent;

  @media screen and (max-width: 500px) {
    width: 80%;
  }
`;

export const AutoSelectContainer = styled.div<{ fullWidth?: boolean }>`
  cursor: pointer;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "max-content")};
  position: relative;

  ${AutoSelectInput} {
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "max-content")};
  }
`;

export const AutoSelectButton = styled.div<{ showDropdown: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.inputColor};
  padding: ${({ theme }) => theme.padding.pad05All};
  border-radius: ${({ showDropdown }) =>
    showDropdown ? "0.5rem 0.5rem 0rem 0rem" : "0.5rem"};

  @media screen and (max-width: 500px) {
    width: 100%;
  }

  span {
    transition: ${transition};
    color: ${({ theme }) => theme.colors.textColor};
    transform: ${({ showDropdown }) =>
      showDropdown ? "rotate(-180deg)" : "rotate(0deg)"};
  }
`;

export const AutoSelectList = styled.ul<{ showTagList: boolean }>`
  max-height: ${({ showTagList }) => (showTagList ? "206px" : "0px")};
  height: fit-content;
  overflow-y: ${({ showTagList }) => (showTagList ? "auto" : "hidden")};
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  inset: 0;
  top: 24px;
  z-index: ${({ theme }) => theme.zIndex.z300};
  background: ${({ theme }) => theme.colors.containerColor};
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 1rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  transition: ${({ showTagList }) => (showTagList ? transition : "")};
`;

export const AutoSelectListItem = styled.li<{
  active: boolean;
  disabled?: boolean;
}>`
  ${mediumRegularFont}
  cursor: pointer;
  width: 100%;
  padding: 10px 16px;
  transition: ${transition};
  background: ${({ theme, active, disabled }) =>
    active && disabled !== true
      ? theme.colors.primaryColor
      : theme.colors.containerColor};
  color: ${({ theme, active, disabled }) =>
    active && disabled !== true
      ? theme.colors.buttonColor
      : theme.colors.titleColor};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  text-align: ${({ disabled }) => (disabled ? "center" : "left")};

  ::selection {
    display: none;
  }

  :hover {
    background: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.buttonColor};
  }
`;
