import { useState } from "react";
import useOnReadingsChanged from "../upload-options/manual-readings/manual-readings-modal/hook/useOnReadingsChanged";
import { useFilterPatientCGMEntries } from "../../../../hooks/use-filter-patient-cgm-entries/use-filter-patient-cgm-entries";
import { ManualReadingsHeader } from "./manual-readings-header/manual-readings-header";
import { ManualReadingsFilters } from "./manual-readings-filters/manual-readings-filters";
import { EmptyState } from "../../../../components/empty-state/empty-state";
import { AddManualReadings } from "./manual-readings-header/add-manual-readings";
import { ManualReadingsChart } from "./manual-readings-chart/manual-readings-chart";
import { ManualReadingsTable } from "./manual-readings-table/manual-readings-table";
import { useDateRange } from "../../../../components/date-range/context/date-range-context";

export const ManualReadingsBody = () => {
  const { temporalStartDate, temporalEndDate } = useDateRange();

  const [tagValue, setTagValue] = useState("All");

  const { patientData } = useOnReadingsChanged();

  const {
    filteredPatientDataByDate,
    filteredByTags,
  } = useFilterPatientCGMEntries(
    temporalStartDate,
    temporalEndDate,
    tagValue,
    patientData
  );

  const isEmpty =
    filteredPatientDataByDate.length === 0 ||
    (filteredByTags.length === 0 && tagValue !== "All");

  if (isEmpty) {
    return (
      <>
        <ManualReadingsHeader isEmpty={isEmpty} />
        <ManualReadingsFilters tagValue={tagValue} setTagValue={setTagValue} />
        <EmptyState
          button={<AddManualReadings />}
          hasHeader={false}
          hasSearch={false}
          text="No readings to show"
          title=""
        />
      </>
    );
  }

  return (
    <>
      <ManualReadingsHeader isEmpty={isEmpty} />
      <ManualReadingsFilters tagValue={tagValue} setTagValue={setTagValue} />
      <ManualReadingsChart
        startDate={temporalStartDate}
        endDate={temporalEndDate}
        tagValue={tagValue}
      />
      <ManualReadingsTable
        startDate={temporalStartDate}
        endDate={temporalEndDate}
        tagValue={tagValue}
      />
    </>
  );
};
