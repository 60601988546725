import { LoadedCarbCounterFoodLoggingSelectedPatientMealFoodGroups } from "./carb-counter-food-logging-selected-patient-meal-food-groups/loaded-carb-counter-food-logging-selected-patient-meal-food-groups";
import { LoadedCarbCounterFoodLoggingSelectedPatientMealMacros } from "./carb-counter-food-logging-selected-patient-meal-macros/loaded-carb-counter-food-logging-selected-patient-meal-macros";
import { EmptyCarbCounterFoodLoggingSelectedPatientMealMacros } from "./carb-counter-food-logging-selected-patient-meal-macros/empty-carb-counter-food-logging-selected-patient-meal-macros";
import { CarbCounterFoodLoggingSelectedPatientMealDetailsContainer } from "./styled-carb-counter-food-logging-selected-patient-meal-details";
import { LoadableDataState } from "../../../../../../hooks/use-loadable-data";
import { CarbCounterV1MealSummaryDTO } from "../../../../../../models/carb-counter-dtos/carb-counter-v1-meal-summary-dto";
import { OtidaLoader } from "../../../../../../components/loaders/otida-loader/otida-loader";
import { LoadableDataLoading } from "../../../../../../components/loadable-data-loading";

export type LoadableCarbCounterMealMacrosState = LoadableDataState<{
  carbCounterV1MealSummaryDTO: CarbCounterV1MealSummaryDTO;
}>;

export type CarbCounterMealDetailsState =
  | { kind: "Empty" }
  | { kind: "Loading" }
  | {
      kind: "Loaded";
      loadableCarbCounterMealMacrosState: LoadableCarbCounterMealMacrosState;
    };

type CarbCounterFoodLoggingSelectedPatientMealDetailsProps = {
  carbCounterMealDetailsState: CarbCounterMealDetailsState;
};

export const CarbCounterFoodLoggingSelectedPatientMealDetails = (
  props: CarbCounterFoodLoggingSelectedPatientMealDetailsProps
) => {
  const { carbCounterMealDetailsState } = props;

  if (carbCounterMealDetailsState.kind === "Loading") {
    return (
      <CarbCounterFoodLoggingSelectedPatientMealDetailsContainer>
        <OtidaLoader />
      </CarbCounterFoodLoggingSelectedPatientMealDetailsContainer>
    );
  }

  if (carbCounterMealDetailsState.kind === "Loaded") {
    return (
      <CarbCounterFoodLoggingSelectedPatientMealDetailsContainer>
        <LoadableDataLoading
          state={carbCounterMealDetailsState.loadableCarbCounterMealMacrosState}
          successComponent={(carbCounterV1MealSummaryDTO) => (
            <>
              <LoadedCarbCounterFoodLoggingSelectedPatientMealMacros
                carbCounterV1MealSummaryDTO={
                  carbCounterV1MealSummaryDTO.carbCounterV1MealSummaryDTO
                }
              />
              <LoadedCarbCounterFoodLoggingSelectedPatientMealFoodGroups
                carbCounterV1MealSummaryDTO={
                  carbCounterV1MealSummaryDTO.carbCounterV1MealSummaryDTO
                }
              />
            </>
          )}
        />
      </CarbCounterFoodLoggingSelectedPatientMealDetailsContainer>
    );
  }

  return (
    <CarbCounterFoodLoggingSelectedPatientMealDetailsContainer>
      <EmptyCarbCounterFoodLoggingSelectedPatientMealMacros />
    </CarbCounterFoodLoggingSelectedPatientMealDetailsContainer>
  );
};
