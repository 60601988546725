import { PatientDTO } from "../../../models/patient-dtos/patient-dto";
import { useMemo } from "react";
import { usePatientTodoMealsCountsUpdates } from "../context/patient-todo-meals-counts-updates-context";
import { getPatientTodoMealsCount } from "../util/get-patient-todo-meals-count";
import {
  FoodLoggingHeaderContainer,
  FoodLoggingHeaderNutritionReportTitleContainer,
  FoodLoggingHeaderTitleContainer,
} from "./styled-food-logging-header";
import { FoodLoggingHeaderDatePicker } from "./food-logging-header-date-picker/food-logging-header-date-picker";
import { Link, useLocation, useParams } from "react-router-dom";

export type FoodLoggingHeaderProps = {
  patientDTOs: PatientDTO[];
};

export function FoodLoggingHeader(props: FoodLoggingHeaderProps) {
  const { patientDTOs } = props;
  const { patientID } = useParams();
  const location = useLocation();

  const {
    todoMealsCountsUpdatesByPatientId,
  } = usePatientTodoMealsCountsUpdates();

  const todoPatientsCount = useMemo(
    () =>
      patientDTOs.filter((patientDTO) => {
        return (
          getPatientTodoMealsCount(
            patientDTO,
            todoMealsCountsUpdatesByPatientId
          ) > 0
        );
      }).length,
    [patientDTOs, todoMealsCountsUpdatesByPatientId]
  );

  const todoMealsCount = useMemo(
    () =>
      patientDTOs.reduce(
        (acc, patientDTO) =>
          acc +
          getPatientTodoMealsCount(
            patientDTO,
            todoMealsCountsUpdatesByPatientId
          ),
        0
      ),
    [patientDTOs, todoMealsCountsUpdatesByPatientId]
  );

  return (
    <FoodLoggingHeaderContainer>
      {location.pathname.includes("/report-summary") ? (
        <FoodLoggingHeaderNutritionReportTitleContainer>
          <Link
            to={`/food-logging/food-logging-nutrition-report/${patientID}/report-table`}
          >
            <span className="material-symbols-outlined">west</span>
          </Link>
          <h3>Nutrition Report</h3>
        </FoodLoggingHeaderNutritionReportTitleContainer>
      ) : (
        <FoodLoggingHeaderTitleContainer>
          <h3>Food logging ({todoPatientsCount})</h3>
          <span>
            {todoMealsCount} todo meal{`${todoMealsCount === 1 ? "" : "s"}`}
          </span>
        </FoodLoggingHeaderTitleContainer>
      )}
      {location.pathname.includes("/report-table") && (
        <FoodLoggingHeaderDatePicker />
      )}
    </FoodLoggingHeaderContainer>
  );
}
