import { useImmediateAttentionPatientsSidebar } from "../../context/immediate-attention-patients-sidebar-context";
import {
  ImmediateAttentionPatientsSidebarPatientInfoContainer,
  ImmediateAttentionPatientsSidebarPatientInfoLeftContainer,
  ImmediateAttentionPatientsSidebarPatientInfoRightContainer,
} from "./styled-immediate-attention-patients-sidebar-patient-info";

export const ImmediateAttentionPatientsSidebarPatientInfo = () => {
  const {
    immediateAttentionPatientsSidebarPatientData,
  } = useImmediateAttentionPatientsSidebar();

  const patientInfo = immediateAttentionPatientsSidebarPatientData.patientInfo;

  return (
    <ImmediateAttentionPatientsSidebarPatientInfoContainer>
      <ImmediateAttentionPatientsSidebarPatientInfoLeftContainer>
        <div>
          <img src={patientInfo.img} alt={`patient ${patientInfo.name}`} />
        </div>
        <div>
          <p>{patientInfo.name}</p>
          <span>
            {patientInfo.age} {patientInfo.gender}
          </span>
        </div>
      </ImmediateAttentionPatientsSidebarPatientInfoLeftContainer>
      <ImmediateAttentionPatientsSidebarPatientInfoRightContainer>
        <div>
          <span className="material-symbols-outlined">call</span>
          <p>01224298090</p>
        </div>
        <div>
          <span className="material-symbols-outlined">email</span>
          <p>ahmed.hamed@gmail.com</p>
        </div>
      </ImmediateAttentionPatientsSidebarPatientInfoRightContainer>
    </ImmediateAttentionPatientsSidebarPatientInfoContainer>
  );
};
