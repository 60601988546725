import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UserResponse } from "stream-chat";
import { useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

type ChatQueryUsers = {
  users: UserResponse<DefaultStreamChatGenerics>[];
  setUsers: (users: UserResponse<DefaultStreamChatGenerics>[]) => void;
};

const ChatQueryUsersContext = createContext<ChatQueryUsers | undefined>(
  undefined
);

type ChatQueryUsersProviderProps = PropsWithChildren<{}>;

export const ChatQueryUsersProvider = (props: ChatQueryUsersProviderProps) => {
  const { children } = props;

  const { client } = useChatContext();

  const [users, setUsers] = useState<UserResponse<DefaultStreamChatGenerics>[]>(
    []
  );

  const fetchUsers = useCallback(async () => {
    const response = await client.queryUsers({}, undefined, { limit: 100 });
    return response.users;
  }, [client]);

  const getUsers = useCallback(async () => {
    const userList = await fetchUsers();
    setUsers(userList);
  }, [fetchUsers]);

  useEffect(() => {
    getUsers();
  }, [fetchUsers, getUsers]);

  const value = useMemo(
    () => ({
      users,
      setUsers,
    }),
    [users, setUsers]
  );

  return (
    <ChatQueryUsersContext.Provider value={value}>
      {children}
    </ChatQueryUsersContext.Provider>
  );
};

export const useChatQueryUsers = (): ChatQueryUsers => {
  const users = useContext(ChatQueryUsersContext);

  if (users === undefined) {
    throw new Error(`useChatQueryUsers must be within ChatQueryUsersProvider`);
  }

  return users;
};
