import styled from "styled-components/macro";
import { TableContainer } from "../../../../../../components/table/styled-table";
import { flexCol } from "../../../../../../styles/classes/gloabl-classes";
import { NavbarHeight } from "../../../../../../styles/global-style";

export const OrganizationUsersTableResponseContainer = styled.div`
  ${TableContainer} {
    height: calc(100vh - (${NavbarHeight} + 32px + 24px + 64px + 99px + 24px));
    ${flexCol}
    padding-bottom: 12px;
  }
`;

export const OrganizationUsersTableResponseEmptyStateContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  height: calc(100vh - (${NavbarHeight} + 32px + 24px + 18px + 99px));
`;
