import {
  DateRangeContainer,
  DateRangeInputContainer,
  DateRangeLabel,
  DateRangeSubContainer,
} from "../../../../components/date-range/styled-date-range";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useDailyChartsDateRange } from "./daily-charts-date-range-context";

type DailyChartsDateRangeProps = { label?: string };

export const DailyChartsDateRange = (props: DailyChartsDateRangeProps) => {
  const { label } = props;

  const {
    handleDecrementDates,
    handleIncrementDates,
    onDatesChange,
    temporalStartDate,
    temporalEndDate,
    DailyChartsDateRangePickerRef,
    onDailyChartsDateRangePickerClick,
    resetDates,
  } = useDailyChartsDateRange();

  return (
    <DateRangeContainer>
      <DateRangeLabel>{label ? label : "Pick a Date"}</DateRangeLabel>
      <DateRangeSubContainer>
        <button onClick={handleDecrementDates}>
          <span className="material-symbols-outlined">arrow_back_ios</span>
        </button>
        <DateRangeInputContainer>
          <DateRangePickerComponent
            startDate={
              temporalStartDate !== undefined
                ? new Date(temporalStartDate)
                : undefined
            }
            endDate={
              temporalEndDate !== undefined
                ? new Date(temporalEndDate)
                : undefined
            }
            placeholder="Select date range"
            format="dd/ M /yyyy"
            separator="-"
            onChange={onDatesChange}
            ref={DailyChartsDateRangePickerRef}
            onClick={onDailyChartsDateRangePickerClick}
          />
        </DateRangeInputContainer>
        <button onClick={handleIncrementDates}>
          <span className="material-symbols-outlined">arrow_forward_ios</span>
        </button>
      </DateRangeSubContainer>
      {(temporalStartDate !== undefined || temporalEndDate !== undefined) && (
        <span
          onClick={resetDates}
          className="material-symbols-outlined clear-dates-button"
        >
          close
        </span>
      )}
    </DateRangeContainer>
  );
};
