import styled from "styled-components/macro";
import {
  DateRangeContainer,
  DateRangeSubContainer,
} from "../../../../../components/date-range/styled-date-range";

export const LoadedDailyChartsContainer = styled.div`
  ${DateRangeContainer} {
    @media screen and (max-width: 1440px) {
      flex-direction: column;
    }
  }

  ${DateRangeSubContainer} {
    @media screen and (max-width: 1440px) {
      gap: 0px 8px;
      min-width: 290px;
    }
  }
`;

export const DailyChartPaginationContainer = styled.div`
  padding: 8px 32px 0px;
`;
