import { Reading } from "../../../components/cgm-google-chart/utils/build-days-aggregates";
import { PatientType } from "../../../models/patient-dtos/patient-type";

export function isGlucosePointInRange(type: PatientType, reading: Reading) {
  if (reading.tag === "fasting") {
    if (type === "NormalOrHealthy" || type === "Prediabetic") {
      return reading.glucose >= 70 && reading.glucose <= 100;
    } else if (type === "Type1Pediatric") {
      return reading.glucose >= 70 && reading.glucose <= 144;
    } else {
      return reading.glucose >= 70 && reading.glucose <= 130;
    }
  } else {
    if (type === "NormalOrHealthy" || type === "Prediabetic") {
      return reading.glucose >= 70 && reading.glucose <= 140;
    } else {
      return reading.glucose >= 70 && reading.glucose <= 180;
    }
  }
}
