import styled from "styled-components/macro";
import { flexCenter } from "../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../styles/global-style";

export const TargetAudienceAutoSelectContainer = styled.div`
  width: 80%;
  background: ${({ theme }) => theme.colors.inputColor};
  border-radius: 5px;
  position: relative;
`;

export const TargetAudienceAutoSelectDropdownToggle = styled.div`
  ${flexCenter}
  padding: 8px;
  cursor: pointer;
`;

export const TargetAudienceAutoSelectDropdownToggleIcon = styled.span<{
  $showDropdown: boolean;
}>`
  margin-left: auto;
  transform: rotate(
    ${({ $showDropdown }) => ($showDropdown ? "-180deg" : "0")}
  );
  transition: ${transition};
`;
