import { PatientActivityLogDTO } from "../../../../models/patient-activity-log-dtos/patient-activity-log-dto";
import { PatientCGMEntryDTO } from "../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { CgmTooltipProps } from "../cgm-tooltip/cgm-tooltip";
import { calculateYaxisValue } from "../cgm-tooltip/utils/calculateYaxisValue";

export type ActivitiesSeriesDataSource = {
  x: Date;
  y: number;
  tooltip: CgmTooltipProps;
};

export function buildActivitesSeries(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  patientActivityLogDTOs: PatientActivityLogDTO[]
): ActivitiesSeriesDataSource[] {
  const activitiesSeries: ActivitiesSeriesDataSource[] = [];

  for (const patientActivityLogDTO of patientActivityLogDTOs) {
    const x = new Date(
      `${patientActivityLogDTO.date}T${patientActivityLogDTO.time}`
    );

    const y = calculateYaxisValue(
      patientCGMEntryDTOs,
      patientActivityLogDTO.date,
      x
    );

    const tooltip: CgmTooltipProps = {
      x,
      y,
      kind: {
        kind: "Activities",
        data: patientActivityLogDTO,
      },
    };

    activitiesSeries.push({ x, y, tooltip });
  }

  return activitiesSeries;
}
