import {
  NutritionAnalysisHeaderContainer,
  NutritionAnalysisHeaderSelectedDatesContainer,
} from "./styled-nutrition-analysis-header";
import { Temporal } from "temporal-polyfill";
import { useDateRange } from "../../../../../components/date-range/context/date-range-context";
import { DateRange } from "../../../../../components/date-range/date-range";

export const NutritionAnalysisHeader = () => {
  const { selectedDates } = useDateRange();

  const selectedStartDate = Temporal.PlainDate.from(
    selectedDates.temporalStartDate
  ).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: undefined,
  });

  const selectedEndDate = Temporal.PlainDate.from(
    selectedDates.temporalEndDate
  ).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <NutritionAnalysisHeaderContainer>
      <DateRange />
      <NutritionAnalysisHeaderSelectedDatesContainer>
        <p>
          Selected Dates:{" "}
          {`${selectedStartDate} - ${selectedEndDate} (${selectedDates.count} Days)`}
        </p>
      </NutritionAnalysisHeaderSelectedDatesContainer>
    </NutritionAnalysisHeaderContainer>
  );
};
