import { MEAL_GLUCOSE_POINT } from "../../../../../constants/config";
import { PatientCGMEntryDTO } from "../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";

// ? Formula: y = y1 + ((y2 - y1) / (x2 - x1)) * (x - x1)
// ? y1: beforeCGM point y, y2: afterCGM point y, x1: beforeCGM point time, x2: afterCGM point time

export function calculateYaxisValue(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  startFrom: string,
  x: Date
) {
  let y = MEAL_GLUCOSE_POINT;

  let pointBefore: PatientCGMEntryDTO | null = null;
  let pointAfter: PatientCGMEntryDTO | null = null;

  for (let i = 0; i < patientCGMEntryDTOs.length; i++) {
    const currentDateTime = new Date(
      `${patientCGMEntryDTOs[i].date}T${patientCGMEntryDTOs[i].time}`
    );

    if (currentDateTime <= x && patientCGMEntryDTOs[i].date === startFrom) {
      pointBefore = patientCGMEntryDTOs[i];
    }

    if (currentDateTime > x && patientCGMEntryDTOs[i].date === startFrom) {
      pointAfter = patientCGMEntryDTOs[i];
      break;
    }
  }

  if (pointBefore !== null && pointAfter !== null) {
    const y1 = pointBefore.glucoseMGPerDL;
    const y2 = pointAfter.glucoseMGPerDL;
    const y2Minusy1 = y2 - y1;
    const x1 = new Date(`${startFrom}T${pointBefore.time}`).getTime();
    const x2 = new Date(`${startFrom}T${pointAfter.time}`).getTime();
    const x2Minusx1 = x2 - x1;
    const xT = x.getTime();
    const xMinusX1 = xT - x1;
    y = y1 + (y2Minusy1 / x2Minusx1) * xMinusX1;
  } else {
    y = MEAL_GLUCOSE_POINT;
  }

  return y;
}
