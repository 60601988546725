import { PatientNutritionReportSummaryDTO } from "../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-summary-dto";
import { roundTo1DecimalPlace } from "../../../../../../utils/math-utils";
import {
  NutritionReportSummaryListBar,
  NutritionReportSummaryListBarContainer,
  NutritionReportSummaryListGoalContainer,
  NutritionReportSummaryListLI,
  NutritionReportSummaryListTitleContainer,
} from "./styled-nutrition-report-summary-list";

type NutritionReportSummaryListFoodgroupsProps = {
  patientNutritionReportSummary: PatientNutritionReportSummaryDTO;
};

export const NutritionReportSummaryListFoodgroups = (
  props: NutritionReportSummaryListFoodgroupsProps
) => {
  const { patientNutritionReportSummary } = props;

  return (
    <>
      {patientNutritionReportSummary.foodGroups.map((foodGroup) => (
        <NutritionReportSummaryListLI key={foodGroup.foodGroup.id}>
          <NutritionReportSummaryListTitleContainer>
            <img
              src={foodGroup.foodGroup.iconUrl}
              alt={foodGroup.foodGroup.name}
            />
            <p>Av. {foodGroup.foodGroup.name}/day</p>
          </NutritionReportSummaryListTitleContainer>
          <NutritionReportSummaryListBarContainer>
            <NutritionReportSummaryListBar
              progress={
                ((foodGroup.actualDailyServingsCount /
                  foodGroup.goalDailyServingsCount) *
                  100) /
                2
              }
            >
              <div />
            </NutritionReportSummaryListBar>
            <NutritionReportSummaryListGoalContainer>
              <p>
                {roundTo1DecimalPlace(foodGroup.actualDailyServingsCount)}/
                {foodGroup.goalDailyServingsCount}
              </p>
            </NutritionReportSummaryListGoalContainer>
          </NutritionReportSummaryListBarContainer>
        </NutritionReportSummaryListLI>
      ))}
    </>
  );
};
