import { Temporal } from "temporal-polyfill";
import { AnalyticsAndReportsBodyModal } from "./analytics-and-reports-body-modal/analytics-and-reports-body-modal";
import {
  AnalyticsAndReportsBodyCard,
  AnalyticsAndReportsBodyCardFooter,
  AnalyticsAndReportsBodyCardHeader,
  AnalyticsAndReportsBodyContainer,
} from "./styled-analytics-and-reports-body";
import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { PatientNutritionReportDTO } from "../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-dto";
import { PatientDTO } from "../../../../../models/patient-dtos/patient-dto";

export type AnalyticsAndReportsData = {
  patientDTO: PatientDTO;
  patientNutritionReportDTOs: PatientNutritionReportDTO[];
};

type AnalyticsAndReportsBodyProps = {
  analyticsAndReportsData: AnalyticsAndReportsData;
};

export const AnalyticsAndReportsBody = (
  props: AnalyticsAndReportsBodyProps
) => {
  const { analyticsAndReportsData } = props;

  if (analyticsAndReportsData.patientNutritionReportDTOs.length === 0) {
    return (
      <EmptyState
        button={<></>}
        hasHeader={false}
        hasSearch={false}
        text="No Previous Reports and analysis"
        title=""
      />
    );
  }

  return (
    <AnalyticsAndReportsBodyContainer>
      {analyticsAndReportsData.patientNutritionReportDTOs.map(
        (patientNutritionReport) => (
          <AnalyticsAndReportsBodyCard key={patientNutritionReport.id}>
            <AnalyticsAndReportsBodyCardHeader>
              <span>
                {new Date(patientNutritionReport.createdAt).toLocaleString(
                  "en-EG",
                  {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    timeZoneName: "short",
                    hour12: true,
                  }
                )}
              </span>
              <p>
                From:{" "}
                {Temporal.PlainDate.from(
                  patientNutritionReport.startDate
                ).toLocaleString("en-UK", {
                  day: "numeric",
                  month: "short",
                })}{" "}
                -
                {Temporal.PlainDate.from(
                  patientNutritionReport.endDate
                ).toLocaleString("en-UK", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </p>
            </AnalyticsAndReportsBodyCardHeader>
            <AnalyticsAndReportsBodyCardFooter>
              <span>{patientNutritionReport.createdByAdmin.name}</span>
              <AnalyticsAndReportsBodyModal
                patientNutritionReport={patientNutritionReport}
                patientDTO={analyticsAndReportsData.patientDTO}
              />
            </AnalyticsAndReportsBodyCardFooter>
          </AnalyticsAndReportsBodyCard>
        )
      )}
    </AnalyticsAndReportsBodyContainer>
  );
};
