import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { BASE_PATH } from "../../constants/paths";

export function useDeleteContentModule(): (
  contentModuleId: number
) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (contentModuleId) => {
      await client.delete<void>(
        `${BASE_PATH}/content-modules/${contentModuleId}`
      );
    },
    [client]
  );
}
