import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { PATIENTS_PATH } from "../../constants/paths";
import { BulkUpdatePatientLabTestResultsDTO } from "../../models/patient-lab-test-result-dtos/bulk-update-patient-lab-test-results-dto";
import { BulkUpdatePatientLabTestResultsResponseDTO } from "../../models/patient-lab-test-result-dtos/bulk-update-patient-lab-test-results-response-dto";

export function useBulkUpdatePatientLabTestResults(): (
  patientId: number,
  bulkUpdatePatientLabTestResultsDTO: BulkUpdatePatientLabTestResultsDTO
) => Promise<BulkUpdatePatientLabTestResultsResponseDTO> {
  const client = useClient();

  return useCallback(
    async (patientId, bulkUpdatePatientLabTestResultsDTO) => {
      const response =
        await client.post<BulkUpdatePatientLabTestResultsResponseDTO>(
          `${PATIENTS_PATH}/${patientId}/lab-test-results/bulk-update`,
          bulkUpdatePatientLabTestResultsDTO
        );

      return response.data;
    },
    [client]
  );
}
