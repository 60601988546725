import { ChangeEvent, useState } from "react";
import { useDropdown } from "../dropdown/hooks/use-dropdown";
import {
  AutoSelectForMedicationsDropdownList,
  AutoSelectForMedicationsDropdownListItem,
  AutoSelectForMedicationsDropdownWrapper,
  AutoSelectForMedicationsInputContainer,
  AutoSelectForMedicationsInput,
  AutoSelectForMedicationsInputLabel,
} from "./styled-auto-select-for-medications";

export type AutoSelectForMedicationsProps<T> = {
  options: T[];
  display: (value: T) => string;
  onChange: (value: T) => void;
  label: string;
  placeHolder: string;
  errorState?: boolean;
  defaultValue?: string;
};

export function AutoSelectForMedications<T>(
  props: AutoSelectForMedicationsProps<T>
) {
  const {
    options,
    display,
    onChange,
    label,
    placeHolder,
    errorState,
    defaultValue,
  } = props;

  const {
    hideDropdown,
    dropdownContainerRef,
    showDropdown,
    toggleDropdown,
    viewDropdown,
  } = useDropdown();

  const [searchTerm, setSearchTerm] = useState(
    defaultValue !== undefined ? defaultValue : ""
  );

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    viewDropdown();
  };

  const items = options
    .filter((option) =>
      display(option).toLowerCase().startsWith(searchTerm.toLowerCase())
    )
    .map((option, index) => (
      <AutoSelectForMedicationsDropdownListItem
        key={index}
        onClick={() => {
          hideDropdown();
          setSearchTerm(display(option));
          onChange(option);
        }}
      >
        {display(option)}
      </AutoSelectForMedicationsDropdownListItem>
    ));

  return (
    <AutoSelectForMedicationsInputContainer ref={dropdownContainerRef}>
      {label !== "" && (
        <AutoSelectForMedicationsInputLabel>
          {label}
        </AutoSelectForMedicationsInputLabel>
      )}
      <AutoSelectForMedicationsDropdownWrapper
        showDropdown={showDropdown}
        errorState={errorState}
        onClick={toggleDropdown}
      >
        <AutoSelectForMedicationsInput
          type="text"
          placeholder={placeHolder}
          value={searchTerm}
          onChange={handleOnChange}
        />
        <span className="material-symbols-outlined">expand_more</span>
        <AutoSelectForMedicationsDropdownList showDropdown={showDropdown}>
          {items}
        </AutoSelectForMedicationsDropdownList>
      </AutoSelectForMedicationsDropdownWrapper>
    </AutoSelectForMedicationsInputContainer>
  );
}
