import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  PatientProfileAutoSelectContainer,
  PatientProfileAutoSelectFormControlContainer,
  PatientProfileModalFormControl,
  PatientProfileModalInputContainer,
  PatientProfileTagList,
  PatientProfileTagListItem,
  PatientProfileTagsLabelContainer,
} from "../../../../../styles/patient-profile-styles/patient-profile-classes";
import { PatientProfileMedicalHistoryEditModalInputs } from "../../profile-medical-history-edit-modal";
import { useState } from "react";
import { PutPatientMedicalHistoryNonBariatricSurgeryDTO } from "../../../../../../../../models/patient-medical-history-dtos/put-patient-medical-history-non-bariatric-surgery-dto";
import { AutoSelect } from "../../../../../../../../components/auto-select/auto-select";
import {
  NON_BARIATRIC_SURGERIES,
  NonBariatricSurgery,
} from "../../../../../../../../models/surgery";
import {
  DisplayNonBariatricSurgeries,
  revertNonBariatricSurgeries,
  viewNonBariatricSurgeries,
} from "../../../../../utils/patient-profile-utils";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";
import { useEnterKeypress } from "../../../../../../../../hooks/use-enter-keypress/use-enter-keypress";

export const PatientProfileSurgeryFormControl = () => {
  const { control, watch } = useFormContext<
    PatientProfileMedicalHistoryEditModalInputs
  >();

  const { append, fields, remove } = useFieldArray({
    name: "nonBariatricSurgeries",
    control,
  } as never);

  const nonBariatricSurgeries = watch("nonBariatricSurgeries");

  const [autoSelectTagValue, setAutoSelectTagValue] = useState<
    PutPatientMedicalHistoryNonBariatricSurgeryDTO | undefined
  >();

  const handleAppend = () => {
    if (
      nonBariatricSurgeries !== undefined &&
      autoSelectTagValue !== undefined &&
      !nonBariatricSurgeries.some(
        (obj) => obj.surgery === autoSelectTagValue.surgery
      ) &&
      NON_BARIATRIC_SURGERIES.some(
        (obj) =>
          obj ===
          revertNonBariatricSurgeries(
            autoSelectTagValue?.surgery as DisplayNonBariatricSurgeries
          )
      )
    ) {
      append(autoSelectTagValue);
      setAutoSelectTagValue(undefined);
    }
  };

  const { onEnterKeyPress } = useEnterKeypress(handleAppend);

  return (
    <PatientProfileModalFormControl>
      <PatientProfileTagsLabelContainer>
        <label>Surgery</label>
      </PatientProfileTagsLabelContainer>
      <PatientProfileModalInputContainer>
        <Controller
          control={control}
          name="nonBariatricSurgeries"
          render={() => {
            return (
              <PatientProfileAutoSelectFormControlContainer>
                <PatientProfileAutoSelectContainer>
                  <AutoSelect
                    itemsArray={NON_BARIATRIC_SURGERIES.filter(
                      (item) => item !== "Other"
                    ).map((item) => viewNonBariatricSurgeries(item))}
                    onChange={(value: string) =>
                      setAutoSelectTagValue({
                        surgery: revertNonBariatricSurgeries(
                          value as DisplayNonBariatricSurgeries
                        ),
                      })
                    }
                    value={viewNonBariatricSurgeries(
                      autoSelectTagValue?.surgery as NonBariatricSurgery
                    )}
                    fullWidth
                    placeHolder="Select Surgery"
                  />
                  <Button
                    type="button"
                    onClick={handleAppend}
                    onKeyDown={(e) => onEnterKeyPress(e)}
                    width={100}
                    height={40}
                    flex
                    outlineNoBorder
                  >
                    <span className="material-symbols-outlined">add</span> Add
                  </Button>
                </PatientProfileAutoSelectContainer>
                <PatientProfileTagList>
                  {fields.map((field, index) => (
                    <PatientProfileTagListItem key={field.id} variant="Normal">
                      {nonBariatricSurgeries !== undefined
                        ? viewNonBariatricSurgeries(
                            nonBariatricSurgeries[index].surgery
                          )
                        : ""}
                      <span
                        className="material-symbols-outlined"
                        onClick={() => remove(index)}
                      >
                        close
                      </span>
                    </PatientProfileTagListItem>
                  ))}
                </PatientProfileTagList>
              </PatientProfileAutoSelectFormControlContainer>
            );
          }}
        />
      </PatientProfileModalInputContainer>
    </PatientProfileModalFormControl>
  );
};
