import styled from "styled-components/macro";
import { flexCenter } from "../../../../../../../styles/classes/gloabl-classes";
import {
  lightShadowAlt,
  transition,
} from "../../../../../../../styles/global-style";

export const HomeFilterDropdownContainer = styled.div`
  position: relative;
`;

export const HomeFilterDropdownButton = styled.button<{
  $showDropdown: boolean;
}>`
  font-size: 16px;
  font-weight: 500;
  color: #14212e;
  background: ${({ theme }) => theme.newColors.gray6};
  border-radius: ${({ $showDropdown }) => ($showDropdown ? "8px" : "94px")};
  padding: 6px 16px;
  gap: 8px;
  width: max-content;
  height: fit-content;
  ${flexCenter}
  transition: ${transition};

  span {
    transform: ${({ $showDropdown }) =>
      $showDropdown ? "rotate(-180deg)" : "rotate(0deg)"};
    transition: ${transition};
  }
`;

export const HomeFilterDropdownList = styled.ul<{
  $showDropdown: boolean;
  $width: string;
  $height: string;
}>`
    ${lightShadowAlt}
    background: ${({ theme }) => theme.colors.containerColor};
    padding: 8px;
    border-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: ${({ $showDropdown, $height }) =>
      $showDropdown ? $height : "0px"};
    overflow: ${({ $showDropdown }) => ($showDropdown ? "visible" : "hidden")};
    opacity: ${({ $showDropdown }) => ($showDropdown ? "1" : "0")};
    transition: ${({ $showDropdown }) => ($showDropdown ? transition : "")};
    margin: 0px;
    position: absolute;
    width: ${({ $width }) => $width};
`;
