import { Temporal } from "temporal-polyfill";
import { PatientMealDTO } from "../../../../../models/patient-meal-dtos/patient-meal-dto";
import { PatientMealMacrosSummaryDTO } from "../../../../../models/patient-meal-dtos/patient-meal-macros-summary-dto";
import { roundTo1DecimalPlace } from "../../../../../utils/math-utils";

const mealSourceTitleStyle = `
  font-size: 14px;
  font-weight: 500;
  color: #14212e;
  margin: 8px 0px;
  text-decoration: underline;
`;

const mealSourceContainerStyle = `
  font-size: 14px;
  font-weight: 500;
  color: #14212e;
  border-radius: 94px;
`;

export function patientMealToHtmlTooltip(patientMeal: PatientMealDTO): string {
  const macros = patientMeal.patientMealMacrosSummary;
  const isMealFinished = patientMeal.finishedFoodLogging;

  const timeHtml = pateintMealTimeToHtml(
    Temporal.PlainTime.from(patientMeal.time),
    macros,
    isMealFinished
  );

  const voiceNotes = mealVoiceNoteToHtml(patientMeal);
  const images = mealImages(patientMeal);
  const note = mealNote(patientMeal);
  const foodGroups = mealFoodGroups(patientMeal);
  const carbSources = mealCarbSources(patientMeal);
  const proteinSources = mealProteinSources(patientMeal);

  const tooltipContainerStyle = `
    width: 300px;
    background: #fff;
    box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0) !important;
    border: 1px solid rgba(255, 0, 0 ,0)  !important;
    display: flex;
    flex-direction: column;
  `;

  const mealItemsHtml = `
  <div>
    ${voiceNotes}
    ${images}
    ${note}
    ${foodGroups}
    ${carbSources}
    ${proteinSources}
  </div>
  `;

  return `
    <div style='${tooltipContainerStyle}'>
      ${timeHtml}
      ${mealItemsHtml}
    </div>`;
}

function pateintMealTimeToHtml(
  time: Temporal.PlainTime,
  macros: PatientMealMacrosSummaryDTO,
  isMealFinished: boolean
): string {
  const topContainerStyle = `
    background: #eaf7ff;
    border-bottom: 1px solid #ecedef;
    padding: 1rem;
  `;

  const timeContainerStyle = `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  `;

  const titleStyle = `
    font-size: 1.1rem;
    font-weight: 700;
    color: #040415;
    margin: 0;
  `;

  const timeTextStyle = `
    font-size: 1rem;
    font-weight: 400;
    color: #8c8e97;
  `;

  const macrosContainerStyle = `
     display: flex;
     align-items: center;
     justify-content: space-between;
  `;

  const macrosContainerPTagStyle = `
    font-weight: 400;
    font-size: 12px;
    color: #040415;
  `;

  return `
  <div style='${topContainerStyle}'>
    <div style="${timeContainerStyle}">
      <p style='${titleStyle}'>${macros.kCals} Kcal</p>
      <span style='${timeTextStyle}'>${time.toLocaleString("en", {
    hour12: true,
    second: undefined,
  })}</span>
  <span style='${timeTextStyle}'>${!isMealFinished ? `(Draft)` : ``}</span>
    </div>
    <div style='${macrosContainerStyle}'>
      <p style='${macrosContainerPTagStyle}'>Carbs: ${
    macros.carbsPercentage !== 0
      ? `${roundTo1DecimalPlace(macros.carbsPercentage)}%`
      : `--`
  }</p>
      <p style='${macrosContainerPTagStyle}'>Fat: ${
    macros.fatPercentage !== 0
      ? `${roundTo1DecimalPlace(macros.fatPercentage)}%`
      : `---`
  }</p>
      <p style='${macrosContainerPTagStyle}'>Protien: ${
    macros.proteinPercentage !== 0
      ? `${roundTo1DecimalPlace(macros.proteinPercentage)}%`
      : `---`
  }</p>
    </div>
  </div>
  `;
}

function mealVoiceNoteToHtml(patientMeal: PatientMealDTO): string {
  const voiceNoteAudioTagStyle = `
    width: 280px;
    height: 40px;
    pointer-events: auto;
  `;

  const voiceNoteContainerStyle = `
    width: 90%;
    margin: 0px auto;
  `;

  const voiceNotesContainerStyle = `
    width: 300px;
    padding: 8px 0px;
  `;

  const mealAudioTagsHtml =
    patientMeal.patientMealVoiceNotes.length !== 0
      ? patientMeal.patientMealVoiceNotes
          ?.map(
            (voiceNote) =>
              `
              <span style='${voiceNoteContainerStyle}'>
                <audio style='${voiceNoteAudioTagStyle}' controls src='${voiceNote.publicUrl}'>
                </audio>
              </span>
            `
          )
          .join("")
      : ``;

  const showVoiceNotes = patientMeal.patientMealVoiceNotes.length > 0;

  return showVoiceNotes
    ? `
    <div style='${voiceNotesContainerStyle}'>
      ${mealAudioTagsHtml}
      </div>
      `
    : ``;
}

function mealImages(patientMeal: PatientMealDTO): string {
  const mealImageStyle = `
    width: 270px;
    border-radius: 8px;
    margin: 16px;
  `;

  const mealImagesLinkStyle = `
    text-align: right;
  `;

  const mealImagesLinkPStyle = `
    color: #040415;
    margin: 8px 0px;
    font-size: 12px;
    font-weight: 400;
    pointer-events: auto;
  `;

  const mealImagesHREF =
    patientMeal.patientMealImages.length > 0
      ? `/patients/${patientMeal.patientId}/bgl-analysis/patient-meal-images/${patientMeal.id}`
      : ``;

  const displayMealImagesLink =
    patientMeal.patientMealImages !== undefined &&
    patientMeal.patientMealImages.length > 0
      ? true
      : false;

  return `
  ${
    displayMealImagesLink
      ? `
      <a style='${mealImagesLinkStyle}' target="_blank" href='${mealImagesHREF}'>
        <img 
          style='${mealImageStyle}'
          src='${
            patientMeal.patientMealImages.length !== 0
              ? patientMeal.patientMealImages[0]?.publicUrl
              : ""
          }' 
            />
        <p style='${mealImagesLinkPStyle}'>${
          patientMeal.patientMealImages !== undefined
            ? patientMeal.patientMealImages.length
            : 0
        } Images
        </p>
      </a>
      `
      : ``
  }`;
}

function mealNote(patientMeal: PatientMealDTO): string {
  const mealNoteContainerStyle = `
    padding: 0px 16px;
  `;

  const mealNoteStyle = `
    font-size: 14px;
    font-weight: 400;
    color: #686868;
    font-family: "IBM Plex Sans Arabic", sans-serif;
    margin: 0px;
  `;

  return `
  <div style='${mealNoteContainerStyle}'>
  <p style='${mealNoteStyle}'>${
    patientMeal.note !== undefined ? patientMeal.note : ""
  }
  </p>
  `;
}

function mealFoodGroups(patientMeal: PatientMealDTO): string {
  const mealFoodGroupNameStyle = `
    font-size: 13px;
    font-weight: 400;
    color: #040415;
    font-family: "IBM Plex Sans Arabic", sans-serif;
    margin: 0px;
  `;

  const mealFoodGroupServingsCountStyle = `
    font-size: 14px;
    font-weight: 700;
    color: #686868;
    font-family: "IBM Plex Sans Arabic", sans-serif;
  `;

  const mealFoodGroupsContainerStyle = `
   display: grid;
   grid-template-columns: repeat(2, 1fr);
  `;

  const mealFoodGroupContainerStyle = `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px;
  `;

  const mealFoodGroupsHtml = patientMeal.patientMealFoodGroups
    .map(
      (pTest) => `
    <div style='${mealFoodGroupContainerStyle}'>
     <p style='${mealFoodGroupNameStyle}'>${pTest.foodGroup.name}</p>
     <span style='${mealFoodGroupServingsCountStyle}'>${pTest.servingsCount}</span>
   </div>
   `
    )
    .join("");

  return `
  <p style='${mealSourceTitleStyle}'>Food groups</p>
  <div style='${mealFoodGroupsContainerStyle}'>
    ${mealFoodGroupsHtml}
  </div>
  `;
}

function mealCarbSources(patientMeal: PatientMealDTO): string {
  const mealCarbSourcesHtml =
    patientMeal.patientMealCarbSources.length !== 0
      ? patientMeal.patientMealCarbSources
          ?.map(
            (carbSource) =>
              `
            <span style='${mealSourceContainerStyle}'>
              ${carbSource.carbSource.name}
            </span>
          `
          )
          .join(",")
      : `No carbs`;

  return `
  <p style='${mealSourceTitleStyle}'>Carbs</p>
  <div>
    ${mealCarbSourcesHtml}
  </div>
  `;
}

function mealProteinSources(patientMeal: PatientMealDTO): string {
  const mealProteinSourcesHtml =
    patientMeal.patientMealProteinSources.length !== 0
      ? patientMeal.patientMealProteinSources
          ?.map(
            (proteinSource) =>
              `
            <span style='${mealSourceContainerStyle}'>
              ${proteinSource.proteinSource.name}
            </span>
          `
          )
          .join(",")
      : `No proteins`;

  return `
    <p style='${mealSourceTitleStyle}'>Proteins</p>
    <div>
      ${mealProteinSourcesHtml}
    </div>
  `;
}
