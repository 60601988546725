import { PatientNutritionReportDTO } from "../../../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-dto";
import { NutrtionReportSummaryNotesContainer } from "../../../../../../../food-logging/nutrition-report/nutrition-report-summary/nutrition-report-summary-screen/nutrtion-report-summary-notes/styled-nutrtion-report-summary-notes";
import { ViewNutritionReportSummaryHealthyNotes } from "./view-nutrition-report-summary-healthy-notes";
import { ViewNutritionReportSummaryUnhealthyNotes } from "./view-nutrition-report-summary-unhealthy-notes";

type ViewNutritionReportSummaryNotesProps = {
  patientNutritionReport: PatientNutritionReportDTO;
};

export const ViewNutritionReportSummaryNotes = (
  props: ViewNutritionReportSummaryNotesProps
) => {
  const { patientNutritionReport } = props;

  return (
    <NutrtionReportSummaryNotesContainer>
      <ViewNutritionReportSummaryHealthyNotes
        patientNutritionReport={patientNutritionReport}
      />
      <ViewNutritionReportSummaryUnhealthyNotes
        patientNutritionReport={patientNutritionReport}
      />
    </NutrtionReportSummaryNotesContainer>
  );
};
