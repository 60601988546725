import styled from "styled-components/macro";
import { flexCenter } from "../../../../../../../styles/classes/gloabl-classes";
import { MedicationsInputCSS } from "../../../../../../single-patient/medications/components/css";

export const NutritionReportFeedbackTableCellBodyContainer = styled.div`
  display: grid;
  place-items: center;
`;

export const NutritionReportFeedbackTableCellTextAreaContainer = styled.div`
  ${flexCenter}
  background: ${({ theme }) => theme.colors.inputColor};
  border-radius: 5px;
  padding: ${({ theme }) => theme.padding.pad05All};
  margin: 24px 16px;
  width: 100%;
  height: 102px;
  position: relative;
  border: 0;
  outline: 0;
`;

export const NutritionReportFeedbackTableCellTextArea = styled.textarea`
  ${MedicationsInputCSS}
  resize: none;
  font-family: "IBM Plex Sans Arabic", sans-serif;
`;
