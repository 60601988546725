import { PatientDTO } from "../../../models/patient-dtos/patient-dto";

export function getPatientTodoMealsCount(
  patientDTO: PatientDTO,
  todoMealsCountsUpdatesByPatientId: Map<number, number>
) {
  let result = patientDTO.notFinishedLoggingMealsCount!;

  const patientTodoMealsCountsUpdate = todoMealsCountsUpdatesByPatientId.get(
    patientDTO.id!
  );
  if (patientTodoMealsCountsUpdate !== undefined) {
    result += patientTodoMealsCountsUpdate;
  }

  return result;
}
