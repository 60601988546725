import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { PATIENTS_PATH } from "../../constants/paths";
import { BulkUpdatePatientWeightsDTO } from "../../models/patient-weight-dtos/bulk-update-patient-weights-dto";
import { BulkUpdatePatientWeightsResponseDTO } from "../../models/patient-weight-dtos/bulk-update-patient-weights-response-dto";

export function useBulkUpdatePatientWeights(): (
  patientId: number,
  bulkUpdatePatientWeightsDTO: BulkUpdatePatientWeightsDTO
) => Promise<BulkUpdatePatientWeightsResponseDTO> {
  const client = useClient();

  return useCallback(
    async (patientId, bulkUpdatePatientWeightsDTO) => {
      const response = await client.post<BulkUpdatePatientWeightsResponseDTO>(
        `${PATIENTS_PATH}/${patientId}/weights/bulk-update`,
        bulkUpdatePatientWeightsDTO
      );

      return response.data;
    },
    [client]
  );
}
