import { useCallback } from "react";
import { FEATURE_FLAG_FRONTEND_DEMO } from "../../../../../../constants/config";
import {
  PersonalInfoGroup,
  PersonalInfoGroupText,
  PersonalInfoGroupTitle,
  PersonalInfoGroupTop,
} from "../styled-personal-info-tab";
import { OtherDataBody, OtherDataHeader } from "./styled-other-data";
import { useSinglePatientSidebar } from "../../../context/loadable-single-patient-sidebar-context";

export const OtherData = () => {
  const { patientDTO, MaritalStatusDTOs } = useSinglePatientSidebar();

  const demoCondition = FEATURE_FLAG_FRONTEND_DEMO && patientDTO.id === 83;

  const job = patientDTO.job !== undefined ? patientDTO.job : "";

  const findMaritalStatusById = useCallback(
    (id: number) => MaritalStatusDTOs.find((item) => item.id === id),
    [MaritalStatusDTOs]
  );

  const maritalStatus =
    patientDTO.maritalStatusId !== undefined
      ? findMaritalStatusById(patientDTO.maritalStatusId)?.name
      : "";

  // const activityLevel =
  //   patientMedicalProfileObject.activityLevel !== undefined
  //     ? patientMedicalProfileObject.activityLevel
  //     : "unspecified";

  // const mainGoalsOfProgram =
  //   patientMedicalProfileObject?.mainGoalsOfProgram !== undefined
  //     ? patientMedicalProfileObject.mainGoalsOfProgram
  //     : "unspecified";

  return (
    <>
      <PersonalInfoGroup>
        <OtherDataHeader>
          <PersonalInfoGroupTitle>Other Data</PersonalInfoGroupTitle>
        </OtherDataHeader>
        <OtherDataBody>
          <PersonalInfoGroupTop>
            <PersonalInfoGroupText>Job</PersonalInfoGroupText>
            <PersonalInfoGroupText>{job}</PersonalInfoGroupText>
          </PersonalInfoGroupTop>
          <PersonalInfoGroupTop>
            <PersonalInfoGroupText>Marital Status</PersonalInfoGroupText>
            <PersonalInfoGroupText>{maritalStatus}</PersonalInfoGroupText>
          </PersonalInfoGroupTop>
          {demoCondition && (
            <PersonalInfoGroupTop>
              <PersonalInfoGroupText>Activity Level</PersonalInfoGroupText>
              <PersonalInfoGroupText>Light</PersonalInfoGroupText>
            </PersonalInfoGroupTop>
          )}
          {demoCondition && (
            <PersonalInfoGroupTop>
              <PersonalInfoGroupText>Main Goal</PersonalInfoGroupText>
              <PersonalInfoGroupText>
                Reversing type 2 diabetes
              </PersonalInfoGroupText>
            </PersonalInfoGroupTop>
          )}
        </OtherDataBody>
      </PersonalInfoGroup>
    </>
  );
};
