import { PropsWithChildren, useCallback } from "react";
import { PatientProfileLifeActivitiesProvider } from "./patient-profile-activities-section/patient-profile-life-activities/context/patient-profile-life-activities-context";
import { PatientProfileBloodPressreProvider } from "./patient-profile-weight-section/patient-profile-blood-pressure/context/patient-profile-blood-pressure-context";
import { LoadablePatientProfileProvider } from "./context/loadable-patient-profile-context";
import { useGetPatient } from "../../../hooks/patient-hooks/use-get-patient";
import { useParams } from "react-router-dom";
import { useGetPatientBloodPressures } from "../../../hooks/patient-blood-pressures-hooks/use-get-patient-blood-pressures";
import { useGetPatientWeights } from "../../../hooks/patient-weights-hooks/use-get-patient-weights";
import { useLoadableData } from "../../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../../components/loadable-data-loading";

type PatientProfileProvidersProps = PropsWithChildren<{}>;

export const PatientProfileProviders = (
  props: PatientProfileProvidersProps
) => {
  const { children } = props;

  const { id } = useParams();

  const getPatient = useGetPatient();
  const getPatientBloodPressures = useGetPatientBloodPressures();
  const getPatientWeights = useGetPatientWeights();

  const patientProfileCallback = useCallback(async () => {
    const patientId = parseInt(id!);

    const [patientDTO, patientBloodPressureDTOs, patientWeightDTOs] =
      await Promise.all([
        getPatient(patientId),
        getPatientBloodPressures(patientId),
        getPatientWeights(patientId),
      ]);

    return {
      patientDTO,
      patientBloodPressureDTOs,
      patientWeightDTOs,
    };
  }, [id, getPatient, getPatientBloodPressures, getPatientWeights]);

  const loadablePatientProfile = useLoadableData(patientProfileCallback);

  return (
    <LoadablePatientProfileProvider
      loadablePatientProfile={loadablePatientProfile}
    >
      <PatientProfileBloodPressreProvider>
        <PatientProfileLifeActivitiesProvider>
          <LoadableDataLoading
            state={loadablePatientProfile.state}
            successComponent={() => <>{children}</>}
          />
        </PatientProfileLifeActivitiesProvider>
      </PatientProfileBloodPressreProvider>
    </LoadablePatientProfileProvider>
  );
};
