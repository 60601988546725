import { FEATURE_FLAG_FRONTEND_DEMO } from "../../../../../constants/config";
import { usePatientProfileInformationHeader } from "../context/loadable-patient-profile-information-header-context";
import {
  PatientProfileInformationHeaderImageContainer,
  PatientProfileInformationHeaderImageSmkOrAlcContainer,
  PatientProfileInformationHeaderImageWrapper,
} from "./styled-patient-profile-information-header-image";

export const PatientProfileInformationHeaderImage = () => {
  const { patientDTO } = usePatientProfileInformationHeader();

  const isSmoker =
    patientDTO.isSmoker !== undefined && patientDTO.isSmoker
      ? "smoker"
      : patientDTO.isSmoker !== undefined && !patientDTO.isSmoker
      ? "non-smoker"
      : "Unknown";

  const isAlcoholic =
    patientDTO.drinksAlcohol !== undefined && patientDTO.drinksAlcohol
      ? "alcohol"
      : patientDTO.drinksAlcohol !== undefined && !patientDTO.drinksAlcohol
      ? "non-alcohol"
      : "Unknown";

  return (
    <PatientProfileInformationHeaderImageContainer>
      <PatientProfileInformationHeaderImageWrapper>
        {FEATURE_FLAG_FRONTEND_DEMO && patientDTO.id === 83 ? (
          <img src="/img/demo/human.png" alt="human" />
        ) : (
          <span className="material-symbols-outlined">face_unlock</span>
        )}
      </PatientProfileInformationHeaderImageWrapper>
      <PatientProfileInformationHeaderImageSmkOrAlcContainer>
        {isAlcoholic !== "Unknown" ? (
          <img src={`/img/patient-profile/${isAlcoholic}.svg`} alt="Smoker" />
        ) : (
          <></>
        )}
        {isSmoker !== "Unknown" ? (
          <img src={`/img/patient-profile/${isSmoker}.svg`} alt="Smoker" />
        ) : (
          <></>
        )}
      </PatientProfileInformationHeaderImageSmkOrAlcContainer>
    </PatientProfileInformationHeaderImageContainer>
  );
};
