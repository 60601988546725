import styled from "styled-components/macro";
import { flexCenter } from "../../../../../styles/classes/gloabl-classes";

export const NewBulkLabResultsModalBodySubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media screen and (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px 0px;
  }
`;

export const NewBulkLabResultsModalBodySubHeaderButtons = styled.div`
  ${flexCenter}
  gap: 0px 24px;
`;
