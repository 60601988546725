import { Temporal } from "temporal-polyfill";
import { GoogleDataTableRow } from "react-google-charts";
import { isMobileOrTablet } from "../../../../../utils/device-utils";
import { buildRow } from "../build-row";
import { PatientActivityLogDTO } from "../../../../../models/patient-activity-log-dtos/patient-activity-log-dto";
import { activityToHtmlTooltip } from "./activity-to-html-tooltip";

export function buildActivityRow(
  datetime: Temporal.PlainDateTime,
  reverseVAxisTicks: number[],
  patientActivityLog: PatientActivityLogDTO
): GoogleDataTableRow {
  let bigPointSize!: number;
  if (isMobileOrTablet()) {
    bigPointSize = 4;
  } else {
    bigPointSize = 4;
  }

  const tooltip = activityToHtmlTooltip(patientActivityLog);

  const style = `
  point {
    size: ${bigPointSize};
    shape-type: circle;
    fill-color: #d18700;
    visible :true
  }`;

  return buildRow({
    datetime,
    reverseVAxisTicks,
    isNotGlucose: {
      value: 150,
      tooltip,
    },
    isNotGlucoseStyle: style,
  });
}
