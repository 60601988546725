import { useCallback } from "react";
import { Loading } from "../../../../../../components/loading";
import { DAILY_MEALS_GRAPHS_RESPONE_SPAN_IN_MINUTES } from "../../../../../../constants/daily-meals-graphs-constants";
import { useGetPatientServingsMealResponses } from "../../../../../../hooks/use-get-patient-servings-meal-responses";
import { PatientDetailedPlanPatientPlanFeedbackAttachmentDTO } from "../../../../../../models/patient-plan-dtos/patient-detailed-plan-patient-plan-feedback-attachment-dto";
import { DailyMealGraphsResponse } from "../../../../bgl-analysis/daily-meal-graphs/daily-meal-graphs-cards/daily-meal-graphs-response";
import { useDetailedPlan } from "../../../context/loadable-detailed-plan-context";

type ListMealGraphChartProps = {
  attachment: PatientDetailedPlanPatientPlanFeedbackAttachmentDTO;
  viewPlanGraphs?: boolean;
};

export const ListMealGraphChart = (props: ListMealGraphChartProps) => {
  const { attachment, viewPlanGraphs } = props;

  const { patientDTO } = useDetailedPlan();

  const patientId = patientDTO.id!;
  const getPatientMealResponses = useGetPatientServingsMealResponses();

  const load = useCallback(
    (date: string, time: string) =>
      getPatientMealResponses(
        patientId,
        date,
        date,
        DAILY_MEALS_GRAPHS_RESPONE_SPAN_IN_MINUTES
      ).then((patientMealResponses) => {
        return patientMealResponses.filter(
          (item) =>
            item.readings.length !== 0 &&
            item.date === date &&
            item.time === time
        );
      }),
    [getPatientMealResponses, patientId]
  );

  return (
    <Loading
      load={() =>
        load(attachment.mealGraphChartDate!, attachment.mealGraphChartTime!)
      }
      successComponent={(patientMealResponses) => {
        return (
          <DailyMealGraphsResponse
            patientMealResponse={{
              patientDTO,
              patientMealResponses,
            }}
            hasToolTip={false}
            smallModalGraphs={true}
            viewPlanGraphs={viewPlanGraphs}
          />
        );
      }}
    />
  );
};
