import { ReactNode } from "react";
import { GenericActionsListItemItem } from "./styled-generic-actions-list";

export type GenericActionsListItemProps = {
  text: string;
  variant?: "normal" | "danger";
  disabled?: boolean;
  icon?: ReactNode;
  onClick: () => void;
};

export function GenericActionsListItem(props: GenericActionsListItemProps) {
  const { text, onClick, icon } = props;

  const variant = props.variant || "normal";
  const disabled = props.disabled || false;
  const hasIcon = icon !== undefined ? icon : <></>;

  return (
    <GenericActionsListItemItem
      onClick={onClick}
      isDanger={variant === "danger"}
      disabled={disabled}
      hasIcon={hasIcon === <></> ? false : true}
    >
      {hasIcon} {text}
    </GenericActionsListItemItem>
  );
}
