import { ContentCourseBody } from "./content-course-body/content-course-body";
import { useParams } from "react-router-dom";
import { useGetContentModules } from "../../../../hooks/content-module-hooks/use-get-content-modules";
import { useCallback } from "react";
import { ContentModuleType } from "../../../../models/content-module-dtos/content-module-type";
import { Loading } from "../../../../components/loading";

export const ContentCourse = () => {
  const { courseID } = useParams();
  const contentCourseID = parseInt(courseID!);

  const getContentModules = useGetContentModules();

  const loadContentCourseCallback = useCallback(async () => {
    const contentModuleTypeModule: ContentModuleType = "Course";

    const contentModuleDTOs = await getContentModules(contentModuleTypeModule);

    const contentCourse =
      contentCourseID !== undefined
        ? contentModuleDTOs.find(
            (courseDTO) => courseDTO.id === contentCourseID
          )
        : undefined;

    return { contentCourse };
  }, [getContentModules, contentCourseID]);

  return (
    <Loading
      load={loadContentCourseCallback}
      successComponent={(contentCourse) => (
        <ContentCourseBody contentCourse={contentCourse.contentCourse} />
      )}
    />
  );
};
