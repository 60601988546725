import { useMemo } from "react";
import { NutritionReportTableCell } from "./components/nutrition-report-table-cell";
import { NutritionReportTableCellRowOrMealKind } from "../types/nutrition-report-table-types";

type NutritionReportCaffeineTableCellProps = {
  kind: NutritionReportTableCellRowOrMealKind;
};

export const NutritionReportCaffeineTableCell = (
  props: NutritionReportCaffeineTableCellProps
) => {
  const { kind } = props;

  const { caffeinatedRow, caffeinatedMeal } = useMemo(() => {
    const caffeinatedRow =
      kind.kind === "Row"
        ? kind.data.foodGroups.find((item) => item.foodGroup.id === 14)
        : undefined;

    const caffeinatedMeal =
      kind.kind === "Meal"
        ? kind.data.foodGroups.find((item) => item.foodGroup.id === 14)
        : undefined;

    return { caffeinatedRow, caffeinatedMeal };
  }, [kind.kind, kind.data]);

  return (
    <NutritionReportTableCell
      cellKind={
        kind.kind === "Row" ? "Row" : kind.kind === "Meal" ? "Meal" : undefined
      }
      type={kind.kind === "Row" ? "Normal" : "Normal-bodyColor"}
      value={
        kind.kind === "Row"
          ? caffeinatedRow?.totalServingsCount
          : caffeinatedMeal?.servingsCount
      }
    />
  );
};
