import { ChangeEvent } from "react";
import styled from "styled-components/macro";

type searchInputProps = {
  valueProp?: string;
  onChangeHandler?: (event: ChangeEvent<HTMLInputElement>) => void;
  navSearch?: boolean | undefined;
  tableSearch?: boolean | undefined;
  width?: string;
  placeHolder?: string;
};

export const SearchInput = (props: searchInputProps) => {
  const {
    valueProp,
    onChangeHandler,
    navSearch,
    tableSearch,
    width,
    placeHolder,
  } = props;

  return (
    <SearchInputContainer
      tableSearch={tableSearch !== undefined && tableSearch}
      navSearch={navSearch !== undefined && navSearch}
      width={width}
    >
      <SearchInputIcon>
        <img src="/img/search.svg" alt="Search Icon" />
      </SearchInputIcon>
      <SearchInputInput
        type="search"
        placeholder={placeHolder !== undefined ? placeHolder : "Search ..."}
        value={valueProp}
        onChange={onChangeHandler}
      />
    </SearchInputContainer>
  );
};

type SearchInputContainerTypes = {
  tableSearch: boolean;
  navSearch: boolean;
  width?: string;
};

export const SearchInputContainer = styled.div<SearchInputContainerTypes>`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  color: ${({ theme }) => theme.colors.textColor};
  border-radius: 9px;
  padding: 0.3rem 0.5rem;
  background: ${({ theme }) => theme.colors.inputColor};
  width: ${({ width }) => (width !== undefined ? width : "320px")};

  @media screen and (max-width: 530px) {
    width: 250px;
  }

  @media screen and (max-width: 500px) {
    display: ${({ tableSearch }) => (tableSearch ? "flex" : "none")};
  }

  @media screen and (max-width: 400px) {
    width: 200px;
  }

  @media screen and (max-width: 350px) {
    width: 170px;
  }
`;

export const SearchInputIcon = styled.div`
  img {
    width: 18px;
    height: 18px;
    margin-top: 2px;
  }
`;

export const SearchInputInput = styled.input`
  background: transparent;
  border: 0;
  outline: 0;
  color: ${({ theme }) => theme.colors.titleColor};
  padding-left: 1rem;
  width: 100%;

  ::placeholder {
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;
