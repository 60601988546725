import { useImmediateAttentionPatientsSidebar } from "../../context/immediate-attention-patients-sidebar-context";
import { ImmediateAttentionPatientsSidebarPatientDiagnosisContainer } from "./styled-immediate-attention-patients-sidebar-patient-diagnosis";

export const ImmediateAttentionPatientsSidebarPatientDiagnosis = () => {
  const {
    immediateAttentionPatientsSidebarPatientData,
  } = useImmediateAttentionPatientsSidebar();

  const patientDiagnosis =
    immediateAttentionPatientsSidebarPatientData.diagnosis;

  return (
    <ImmediateAttentionPatientsSidebarPatientDiagnosisContainer>
      <p>Diagnosis</p>
      <ul>
        {patientDiagnosis.map((diagnosis, index) => (
          <li key={index}>{diagnosis}</li>
        ))}
      </ul>
    </ImmediateAttentionPatientsSidebarPatientDiagnosisContainer>
  );
};
