import styled from "styled-components/macro";
import {
  flexCenterCenter,
  mediumRegularFont,
  regularMediumFont,
} from "../../../styles/classes/gloabl-classes";
import { lightShadowAlt } from "../../../styles/global-style";

export const FoodLoggingSelectedPatientContainer = styled.div`
  display: grid;
  place-items: center;
  padding: 12px 0px;
  background: ${({ theme }) => theme.colors.containerColor};
  ${lightShadowAlt}
  position: sticky;
  top: 0px;
`;

export const FoodLoggingSelectedPatientBackIcon = styled.span`
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textColor};
`;

export const FoodLoggingSelectedPatientIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 12px;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenterCenter}
`;

export const FoodLoggingSelectedPatientText = styled.div`
  display: grid;
  place-items: center;
  text-align: center;

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px 0px 4px;
  }

  span {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
