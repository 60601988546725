import styled from "styled-components/macro";
import { transition } from "../../styles/global-style";
import { toastContainerAnimation } from "./animations/toast-container-animation";
import { toastTimer } from "./animations/toast-timer-animation";
import { SuccessOrError } from "./toast";

export const ToastContainer = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.z1000};
  font-size: 14px;
  bottom: 1rem;
  right: 1rem;
`;

export const ToastCard = styled.div<{ showToast: boolean }>`
  width: 350px;
  min-height: 110px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.buttonColor};
  padding: 16px 8px;
  z-index: ${({ theme }) => theme.zIndex.z1000};
  transition: ${transition};
  display: grid;
  animation: ${toastContainerAnimation} 0.3s linear;
  margin-bottom: 1rem;
  opacity: 0.9;
  position: relative;

  :hover {
    opacity: 1;
  }
`;

export const ToastHeader = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
    font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  }
`;

export const ToastIcon = styled.div<{ successOrError: SuccessOrError }>`
  margin-right: 8px;
  color: ${({ theme, successOrError }) =>
    successOrError === "Success" ? theme.colors.success : theme.colors.danger};
`;

export const ToastCloseIcon = styled.div`
  margin-left: auto;
  cursor: pointer;
`;

export const ToastCenter = styled.div`
  p {
    margin-left: 32px;
    margin-top: 0.5rem;
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
    font-size: 18px;
  }
`;

export const ToastLoader = styled.div`
  width: 320px;
  height: 6px;
  border-radius: 8px;
  background: ${({ theme }) => theme.newColors.otidaO2};
  margin: 0rem auto;

  div {
    height: 6px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.success};
    animation: ${toastTimer} 5s linear;
  }
`;
