import { ReactElement, useEffect, useState } from "react";
import { assertNever } from "../utils/assert-never";
import { OtidaLoader } from "./loaders/otida-loader/otida-loader";

export type LoadingProps<T> = {
  load: () => Promise<T>;
  /**
   * Do NOT call `setState` in this function!
   *
   * This function is called in render of `Loading` component. React warns against calling `setState` of another
   * component in render. I'm not sure why, honestly.
   *
   * Use `onSuccess` instead.
   */
  successComponent: (data: T) => ReactElement;
  onSuccess?: (data: T) => void;
};

type State<T> =
  | { kind: "loading" }
  | { kind: "loaded"; data: T }
  | { kind: "errored"; error: any };

export function Loading<T>(props: LoadingProps<T>) {
  const { load, successComponent, onSuccess } = props;

  const [state, setState] = useState<State<T>>({ kind: "loading" });

  useEffect(() => {
    setState({ kind: "loading" });

    let cancelled = false;

    load()
      .then((data) => {
        if (!cancelled) {
          setState({ kind: "loaded", data });

          if (onSuccess !== undefined) {
            onSuccess(data);
          }
        }
      })
      .catch((error) => {
        if (!cancelled) {
          setState({ kind: "errored", error });
        }
      });

    return () => {
      cancelled = true;
    };
  }, [load, onSuccess]);

  if (state.kind === "loading") {
    return <OtidaLoader />;
  } else if (state.kind === "loaded") {
    return successComponent(state.data);
  } else if (state.kind === "errored") {
    return <>Errored. Error: {state.error}.</>;
  } else {
    return assertNever(state);
  }
}
