import styled, { keyframes } from "styled-components/macro";
import { transition } from "../../../../styles/global-style";

const point = keyframes`
  from {
    opacity: 0.5;
    transform: translateY(0);
} to {
    opacity: 1;
    transform: translateY(-4px);
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  gap: 0rem 0.2rem;

  div {
    border-radius: 100%;
    width: 5px;
    height: 5px;
    background: ${({ theme }) => theme.colors.textColor};
    transition: ${transition};

    :nth-child(1) {
      animation: ${point} 0.7s infinite ease;
    }

    :nth-child(2) {
      animation: ${point} 0.7s 0.2s infinite ease;
    }

    :nth-child(3) {
      animation: ${point} 0.7s 0.4s infinite ease;
    }
  }
`;
