import { GenericActionsListItemItem } from "../../../../components/generic-actions-list/styled-generic-actions-list";
import { Modal } from "../../../../components/modal/modal";
import { useModalHelpers } from "../../../../components/modal/use-modal-helpers";
import { DeleteModal } from "../../../../components/delete-modal/delete-modal";

type DeleteMedicineProps = {
  title: string;
  onDelete: () => void;
};

export const DeleteMedicine = (props: DeleteMedicineProps) => {
  const { title, onDelete } = props;
  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <>
      <GenericActionsListItemItem hasIcon onClick={openModal} isDanger>
        <img src="/img/trash.svg" alt="trash icon" />
        Delete
      </GenericActionsListItemItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <DeleteModal
          key={modalBodyKey}
          onDelete={onDelete}
          onModalClose={closeModal}
          title={title}
        />
      </Modal>
    </>
  );
};
