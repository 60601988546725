import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { UpdatePatientPlanPatientPlanGoalDTO } from "../../../../models/patient-plan-dtos/update-patient-plan-patient-plan-goal-dto";
import {
  useDetailedPlan,
  useReloadDetailedPlan,
} from "./loadable-detailed-plan-context";
import { useUpdatePatientPlan } from "../../../../hooks/patient-plan-hooks/use-update-patient-plan";
import { useToastService } from "../../../../context/toast-service-context";
import { PatientDetailedPlanPatientPlanGoalDTO } from "../../../../models/patient-plan-dtos/patient-detailed-plan-patient-plan-goal-dto";
import { PatientPlansGoalsGoalDTO } from "../../../../models/patient-plan-dtos/patient-plans-goals-goal-dto";

type GoalsContextType = {
  selectGoalsList: PatientPlansGoalsGoalDTO[];
  assignedGoals: PatientDetailedPlanPatientPlanGoalDTO[];
  assignedNutritionGoalsList: PatientDetailedPlanPatientPlanGoalDTO[];
  selectNutritionGoalsList: PatientPlansGoalsGoalDTO[];
  assignedLifestyleGoalsList: PatientDetailedPlanPatientPlanGoalDTO[];
  selectLifestyleGoalsList: PatientPlansGoalsGoalDTO[];
  assignedEducationGoalsList: PatientDetailedPlanPatientPlanGoalDTO[];
  selectEducationGoalsList: PatientPlansGoalsGoalDTO[];
  handleAddGoal: (goal: UpdatePatientPlanPatientPlanGoalDTO) => void;
  handleDeleteGoal: (goal: UpdatePatientPlanPatientPlanGoalDTO) => void;
};

const GolasContext = createContext<GoalsContextType | undefined>(undefined);

export type EducationContent = {
  lesson: EducationContentLesson;
};

export type EducationContentLesson = {
  title: string;
  type: string;
  link: string;
  text: string;
};

export function getEducationContent(goal: string): EducationContent {
  let educationContent: EducationContent = JSON.parse(goal);

  if (
    educationContent.lesson.link ===
    "https://drive.google.com/file/d/1lnz3-VLNZ4FnZLkAQZEMS0s8k0oGO0Xb/view?usp=sharing"
  ) {
    educationContent.lesson.link =
      "https://drive.google.com/file/d/1sCOMaAbnEg3CDmeDBNWTswCEqw_zp5Dp/view?usp=sharing";
  }

  if (educationContent.lesson.title === "نظام غذائي 1500 سعر حراري (متوازن)") {
    educationContent.lesson.title = "نظام غذائي ١٥٠٠ سعر حراري (متوازن)";
  }
  if (
    educationContent.lesson.link ===
    "https://drive.google.com/file/d/1sCOMaAbnEg3CDmeDBNWTswCEqw_zp5Dp/view?usp=sharing"
  ) {
    educationContent.lesson.link =
      "https://drive.google.com/file/d/1kfswg_2p0lPioUQ7p0AmfcXUNmG07y2z/view?usp=sharing";
  }

  if (
    educationContent.lesson.link ===
    "https://drive.google.com/file/d/1GkDVKCxITOU3OeIt1shDy7FN_Y05nCzt/view?usp=sharing"
  ) {
    educationContent.lesson.link =
      "https://drive.google.com/file/d/1iL4_O7ykaR_eorADyBN1h-lF1W30mBym/view?usp=sharing";
  }

  if (
    educationContent.lesson.link ===
    "https://drive.google.com/file/d/19ob1VLPG6xuD68wuOyCood3j1gaDkIVf/view?usp=sharing"
  ) {
    educationContent.lesson.link =
      "https://drive.google.com/file/d/1U3v3uUiXathU99uSNBYKsXkHKji3YKFa/view?usp=sharing";
  }

  if (educationContent.lesson.title === '"عكس مرض السكر"') {
    educationContent.lesson.title = "عكس مرض السكر";
  }

  if (
    educationContent.lesson.link ===
    "https://docs.google.com/presentation/d/13yMWyFd5ud7dpySGlQDRqNHDVl8XD4BewCNaosTMK-U/edit#slide=id.g123047edcb1_0_52"
  ) {
    educationContent.lesson.link =
      "https://drive.google.com/file/d/1P_tTJGpTCiT4MQiX45MDLVlLXDQZDirZ/view?usp=sharing";
  }

  if (
    educationContent.lesson.link ===
    "https://drive.google.com/file/d/1BvTfDQa4VVtLBkknXYZHy67oE61SMpZNe/view?usp=sharing"
  ) {
    educationContent.lesson.link =
      "https://drive.google.com/file/d/1hJolSucuvnRzftMiyxWBgRclbtsqT27i/view?usp=sharing";
  }

  if (
    educationContent.lesson.link ===
    "https://drive.google.com/file/d/1yqS9goB6H4Bk24iJx9PA3kMWfrb_6SNt/view?usp=sharing"
  ) {
    educationContent.lesson.link =
      "https://drive.google.com/file/d/1159FIdW_6KNyyvV14f6oq0y4d7UoM1Y7/view";
  }

  if (
    educationContent.lesson.title === "نظام١٨٠٠سعر حراري منخفض النشويات (٢٥٪)"
  ) {
    educationContent.lesson.title = "نظام1500 سعر حراري منخفض النشويات (٢٥٪)";
  }

  if (
    educationContent.lesson.link === "https://youtu.be/enYITYwvPAQ" &&
    educationContent.lesson.title === "عكس مرض السكر"
  ) {
    educationContent.lesson.link = "https://www.youtube.com/embed/enYITYwvPAQ";
    educationContent.lesson.title = "Walk at Home";
  }

  if (
    educationContent.lesson.title === "الطبق الصحي المتوازن" &&
    educationContent.lesson.link ===
      "https://drive.google.com/uc?export=view&id=1ukfjGfM_yV4RvrenL0jbkkQ9RClSp5Fn"
  ) {
    educationContent.lesson.link =
      "https://drive.google.com/file/d/1g541SLMn0zXwWEadNnqzLFAzTqExwNdu/view?usp=sharing";
  }

  if (
    educationContent.lesson.link ===
    "https://drive.google.com/drive/folders/1Yg4kCNSbmmA1FuaswgqHZrI_0kr10A1E"
  ) {
    educationContent.lesson.link =
      "https://drive.google.com/file/d/1vqNF_Bhle6VFjja5C1k5B03QGd6wNZzk/view?usp=drive_link";
  }

  if (
    educationContent.lesson.link ===
    "https://drive.google.com/uc?export=view&id=1RpWQvHUZgm4Ni33txQtTp3Mm8ODf1wM1"
  ) {
    educationContent.lesson.link =
      "https://drive.google.com/file/d/1RpWQvHUZgm4Ni33txQtTp3Mm8ODf1wM1/view?usp=drive_link";
  }

  if (
    educationContent.lesson.link ===
    "https://drive.google.com/uc?export=view&id=19_bnCTXg8AQ-Kn4NdrNP4ENm2OvJCgH2"
  ) {
    educationContent.lesson.link =
      "https://drive.google.com/file/d/19_bnCTXg8AQ-Kn4NdrNP4ENm2OvJCgH2/view?usp=drive_link";
  }

  if (
    educationContent.lesson.link ===
    "https://drive.google.com/uc?export=view&id=1LwxeGxtq9G03Vpi9UyuuXQQgfrWldY3R"
  ) {
    educationContent.lesson.link =
      "https://drive.google.com/file/d/1LwxeGxtq9G03Vpi9UyuuXQQgfrWldY3R/view?usp=drive_link";
  }

  return educationContent;
}

export function getUniqueEducationContentGoals<
  T extends PatientDetailedPlanPatientPlanGoalDTO | PatientPlansGoalsGoalDTO
>(goalsWithPlans: T[]): T[] {
  let result: T[] = [];

  for (const goal1 of goalsWithPlans) {
    let isExist = false;
    for (const goal2 of result) {
      const educationContent1 = getEducationContent(goal1.goal);
      const educationContent2 = getEducationContent(goal2.goal);

      if (areEducationContentsEqual(educationContent1, educationContent2)) {
        isExist = true;

        for (const plans1 of goal1.patientPlans) {
          let isExistPlan = false;
          for (const plans2 of goal2.patientPlans) {
            if (plans1.id === plans2.id) {
              isExistPlan = true;
              break;
            }
          }

          if (!isExistPlan) {
            goal2.patientPlans.push(plans1);
          }
        }

        break;
      }
    }

    if (!isExist) {
      result.push(goal1);
    }
  }

  for (const goal of result) {
    goal.patientPlans.sort(
      (plan1, plan2) => plan1.planNumber - plan2.planNumber
    );
  }

  return result;
}

export function areEducationContentsEqual(
  educationContent1: EducationContent,
  educationContent2: EducationContent
): boolean {
  return (
    educationContent1.lesson.title === educationContent2.lesson.title &&
    educationContent1.lesson.type === educationContent2.lesson.type &&
    educationContent1.lesson.link === educationContent2.lesson.link &&
    educationContent1.lesson.text === educationContent2.lesson.text
  );
}

export const GoalsProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  const { patientDetailedPlanDTO, patientPlansGoals } = useDetailedPlan();
  const reloadPatientDetailedPlan = useReloadDetailedPlan();
  const updatePatientPlan = useUpdatePatientPlan();
  const { showToast } = useToastService();

  const assignedGoals = patientDetailedPlanDTO.patientPlanGoals.filter(
    (item) => item.goalType === "Goal"
  );

  const assignedNutritionGoalsList =
    patientDetailedPlanDTO.patientPlanGoals.filter(
      (item) => item.goalType === "NutritionInstruction"
    );

  const assignedLifestyleGoalsList =
    patientDetailedPlanDTO.patientPlanGoals.filter(
      (item) => item.goalType === "LifestyleInstruction"
    );

  const assignedEducationGoalsList = useMemo(
    () =>
      getUniqueEducationContentGoals(
        patientDetailedPlanDTO.patientPlanGoals.filter(
          (item) => item.goalType === "EducationContent"
        )
      ),
    [patientDetailedPlanDTO.patientPlanGoals]
  );

  const selectGoalsList = useMemo(
    () =>
      patientPlansGoals.goals
        .filter(
          (goal) =>
            assignedGoals.find(
              (assignedGoal) =>
                assignedGoal.goal === goal.goal &&
                assignedGoal.goalType === goal.goalType
            ) === undefined
        )
        .filter((item) => item.goalType === "Goal"),
    [patientPlansGoals.goals, assignedGoals]
  );

  const selectNutritionGoalsList = useMemo(
    () =>
      patientPlansGoals.goals
        .filter(
          (goal) =>
            assignedNutritionGoalsList.find(
              (assignedGoal) =>
                assignedGoal.goal === goal.goal &&
                assignedGoal.goalType === goal.goalType
            ) === undefined
        )
        .filter((item) => item.goalType === "NutritionInstruction"),
    [patientPlansGoals.goals, assignedNutritionGoalsList]
  );

  const selectLifestyleGoalsList = useMemo(
    () =>
      patientPlansGoals.goals
        .filter(
          (goal) =>
            assignedLifestyleGoalsList.find(
              (assignedGoal) =>
                assignedGoal.goal === goal.goal &&
                assignedGoal.goalType === goal.goalType
            ) === undefined
        )
        .filter((item) => item.goalType === "LifestyleInstruction"),
    [patientPlansGoals.goals, assignedLifestyleGoalsList]
  );

  const selectEducationGoalsList = useMemo(
    () =>
      getUniqueEducationContentGoals(
        patientPlansGoals.goals
          .filter((item) => item.goalType === "EducationContent")
          .concat([
            {
              goalType: "EducationContent",
              goal: `{
                      "lesson": {
                        "title": "عكس مرض السكر",
                        "type": "Video",
                        "link": "https://www.youtube.com/embed/DTtu46q1V-E",
                        "text": "\\"السكري من النوع الثاني مرض مزمن يحدث نتيجة مقاومة الجسم للأنسولين على فترات طويلة، ومع ذلك يمكن عكس مرض السكر والتحكم في مستوياته بأقل حد من الأدوية وأحدث تقنيات العلاج الجديدة في أوتيدا. دكتور منى عبد المطلب - استشاري الباطنة والتغذية الإكلينيكية والمدير الطبي لأوتيدا - تشرح في الفيديو عن مرض السكر من النوع التاني وكيف يمكن اكتشافه وعكسه خلال برنامج أوتيدا👇🏻\\""
                      }
                    }`,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `{
              "lesson": {
                "title": "كمية البروتين والدهون المسموح بها والعناصر الغذائية المهمة",
                "type": "Image",
                "link": "https://drive.google.com/uc?export=view&id=1RpWQvHUZgm4Ni33txQtTp3Mm8ODf1wM1",
                "text": ""
              }
            }`,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `{
                      "lesson": {
                        "title": "كمية النشويات المسموح بها",
                        "type": "Image",
                        "link": "https://drive.google.com/uc?export=view&id=19_bnCTXg8AQ-Kn4NdrNP4ENm2OvJCgH2",
                        "text": ""
                      }
                    }`,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `{
                      "lesson": {
                        "title": "الممنوعات",
                        "type": "Image",
                        "link": "https://drive.google.com/uc?export=view&id=1LwxeGxtq9G03Vpi9UyuuXQQgfrWldY3R",
                        "text": ""
                      }
                    }`,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `{
                      "lesson": {
                        "title": "الطبق الصحي المتوازن",
                        "type": "Image",
                        "link": "https://drive.google.com/uc?export=view&id=1ukfjGfM_yV4RvrenL0jbkkQ9RClSp5Fn",
                        "text": ""
                      }
                    }`,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
            {
              "lesson": {
             "title": "تمارين في وضعية الجلوس",
             "type": "Video",
             "link": "https://www.youtube.com/embed/vPJKAG0mknI",
             "text": ""
        }
      }`,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
              {
                "lesson": {
               "title": "تمارين لتقوية العضلات",
               "type": "Video",
               "link": "https://www.youtube.com/embed/xqVBoyKXbsA",
               "text": ""
             }
            } 
          `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
            {
              "lesson": {
                "title": "نظام غذائي لحساب جرعات الانسولين",
                "type": "PDF",
                "link": "https://drive.google.com/file/d/1hSOB1uJceMebFFU3luZqAH8dDIZPdm6J/view?usp=sharing",
                "text": ""
              }
            }
          `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
            {
              "lesson": {
               "title": "نظام غذائي ١٥٠٠ سعر حراري (متوازن)",
               "type": "PDF",
               "link": "https://drive.google.com/file/d/1kfswg_2p0lPioUQ7p0AmfcXUNmG07y2z/view?usp=sharing",
               "text": ""
              }
            }          
          `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
            {
              "lesson": {
               "title": "نظام غذائي ٢٠٠٠ سعر حراري (متوازن)",
               "type": "PDF",
               "link": "https://drive.google.com/file/d/1U3v3uUiXathU99uSNBYKsXkHKji3YKFa/view?usp=sharing",
               "text": ""
          }
          }
          `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
              {
                "lesson": {
                 "title": "نظام غذائي ١٨٠٠ سعر حراري (متوازن)",
                 "type": "PDF",
                 "link": "https://drive.google.com/file/d/1iL4_O7ykaR_eorADyBN1h-lF1W30mBym/view?usp=sharing",
                 "text": ""
                }
              }
          `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
              {
                "lesson": {
                 "title": "نظام غذائي ١٢٠٠ سعر حراري (متوازن)",
                 "type": "PDF",
                 "link": "https://drive.google.com/file/d/1T4ZiKeDCaxLY4nM1plBv0ugA1cojoMJ6/view?usp=sharing",
                 "text": ""
                }
              }
          `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
              {
                "lesson": {
                  "title": "نظام غذائي لصحة الكلى",
                  "type": "PDF",
                  "link": "https://drive.google.com/file/d/1yqS9goB6H4Bk24iJx9PA3kMWfrb_6SNt/view?usp=sharing",
                  "text": ""
                }
              }
          `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "تعليمات هشاشة العظام",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1mdFqKDN51D0VuVT3eBIFz887C4k-48Dm/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "كيفية التعامل مع هبوط سكر الدم",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1LswC5X_SUQa1TOoshVLtZ4u3H0_rEUGg/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "تعليمات لمرضى خمول الغدة الدرقية",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1bdi1-RfI5YozC0_mZ7ZknsId_WN5VXsM/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "تعليمات لمرضى الأورام",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1uMnKd4RsxxNEi05vun01VPLMJdbTArtz/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "توصيات عيد الأضحى",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1ASywTq8fR948y4rkkLrINTxJvJPfCDHS/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "علاج الإمساك بالتغذيةالصحية",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1hV-wYlvT3jcefAYTZC-zZIiF-l3GA1Ht/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "Food trials 1500 kcal",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1khwP1z06YkQPVN5ol-3hQ6HuLyno6Rhc/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "كيفية علاج الانيميا بالغذاء",
                    "type": "PDF",
                    "link": "https://docs.google.com/presentation/d/13yMWyFd5ud7dpySGlQDRqNHDVl8XD4BewCNaosTMK-U/edit#slide=id.g123047edcb1_0_52",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "كيفية تقليل الكافيين",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1BGiDV9VTgAIIs_ZjAHiBgjQzuNjVwLYe/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "كيفية تقليل التدخين",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1WpnCEUsKaJb6-hQwduhg9XXV1HuI1KzV/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "نظام ١٥٠٠ سعر حراري متوازن جراحات السمنة",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1BvTfDQa4VVtLBkknXYZHy67oE61SMpZNe/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "نظام ١٥٠٠ سعر حراري صيام متقطع",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1BsbfWStJ5S4kRcgEY__l1k45zxrvqJ4s/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "Food trials 2000 kcal",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1TaBFi_uASzpDE--YEykjjPNFMZiDlv-h/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "نظام غذائي ١٥٠٠ سعر منخفض النشويات (٣٥٪)",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1S7i-b5fFoyUHxHLMuck1sgwdaPDXC3XF/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "نظام غذائي ١٨٠٠ سعر منخفض النشويات (٣٥٪)",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/11XJqVSP-3uaV5zqlVAIF_j92QmpT7s2E/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "طريقة تناول الطعام الصحيحةأثناءالعمل",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/17PjozVyAayo9nvyPp0TGwRDVRewnSRR4/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "طريقة الأكل الصحية في المصيف",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/17ueNM8dPJmt75mpBzhYoY6Mu11H3h9I6/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "مصادر البكتيريا النافعة",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1jF8VOawXpG7wNzSQEW8QJeaGSHs6KgBH/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "تعليمات ارتفاع مستوى حمض اليوريك (النقرس)",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1s6FzdWsrveowjjf3Kt-_hc2wSN7eBxNf/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "إرشادات لارتفاع الدهون الثلاثية",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1tZE_PcGEP4iEJc74TcvjAuJSz2CbrheQ/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "إرشادات لإرتفاع مستويات الكولسترول الضار",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1_nrfTfYvU3Exd0oneXeLauWmIqQJ_XGV/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "خطة رمضان",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/10eN4n50qBylheYYaN65yUWF-dCeTIrgN/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "Food Trials BGM week 1",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1jjUPUFevnGmGJAxYmJufdUlZxI7QeEJW/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "Food Trials BGM week 2",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1zAfnWRJv1GnifAsKSeY9QsvNMMOrhMPD/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "نظام غذائي: 1800 سعر حراري",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/13sX0U2YhWFoCknIHVwhhzQQjMTxEQLEF/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "نظام١٨٠٠سعر حراري منخفض النشويات (٢٥٪)",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1-RiGwnzRudorU1kOlLeECWv8vKDJbvKE/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "نظام سعر حراري ١٥٠٠ منخفض البروتين",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1d8z8BuPq7VUU5i0HoTlCyT6O1YUGLzt8/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "نظام سعر حراري ١٢٠٠ منخفض النشويات (٣٥٪)",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1oeH596oXnGwe9lo8oPVrzkZzuUGPbUAR/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "نظام ٢٠٠٠ سعر حراري منخفض النشويات (٣٥٪)",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1HTGuytFU9RAusbvdY_leM-dHzy9zm0uc/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "نظام ١٢٠٠ سعر حراري منخفض النشويات (٢٥٪)",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/18qg-dVg7kSJLSWTeidp7E3JKvSlkyf_6/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": ".نظام ٢٠٠٠سعر حراري منخفض النشويات (٢٥٪)",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1hakU9YJGuKWsFPRbv5NdZW290Beuq4-k/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "نصائح للعناية بالقدم لمريض السكر (1)",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1xpmbD4p6jaA3abFswwxDl60a5x63Z65C/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "كمية الفاكهة المسموح بها",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1uqxxYmoeuJ5nito4NQuQUyz7et2UshgT/view?usp=sharing",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "تعليمات استخدام الانسولين",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1PsFDKX0tHHKFJGvw6SdWOaKsDeGx5jkx/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
              {
                "lesson": {
                  "title": "عكس مرض السكر",
                  "type": "Video",
                  "link": "https://youtu.be/enYITYwvPAQ",
                  "text": ""
                }
              }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
              {
                "lesson": {
                  "title": "كيفية استخدام حقن saxenda",
                  "type": "Video",
                  "link": "https://www.youtube.com/embed/80mOaGs_Poc?si=j2VvcPeup-Pxz57k",
                  "text": ""
                }
              }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "سناكس لا تطلب انسولين",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1uBswTaw-SN4biIBnu-f_VBd2GHAE1Vc3/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
                {
                  "lesson": {
                    "title": "الطريقة الصحيحة لقياس الوزن",
                    "type": "PDF",
                    "link": "https://drive.google.com/file/d/1lZNBrpI8eFr45Xxz4KBz8NUomRoRzdKu/view",
                    "text": ""
                  }
                }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "BGM readings",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1ZSVUAcjgg6tEzN5sqYXLYFx_cg-tGNIN/view",
                   "text": ""
                 }
               }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "Basic diabetes education",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1a-fvL_AWRjd1QZTYKX9bw0r-_WIGpQJv/view",
                   "text": ""
                 }
               }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام غذائى ١٥٠٠ سعر منخفض الدهون",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1k3lfWvphPd4KMj0TaVFDC1OkvqU7Ko19/view",
                   "text": ""
                 }
               }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نصائح لتناول السكريات",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1zaLrtj9Ur4S5M0v2IWRw5WqgXgNB94o1/view",
                   "text": ""
                 }
               }
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نصائح للتقليل من نسبةالفوسفور في الطعام",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1AI5CPy7itQIraYOwdlLB5LOAeIjG1U2L/view",
                   "text": ""
                 }
               }              
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "التعامل مع ارتفاع سكر الدم",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/190j7WkMsGoL464Ha9n-TaQkWeo5U1QLh/view",
                   "text": ""
                 }
               }              
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام وجبات ١٥٠٠ سعر حراري منخفض النشويات (٣١%)",
                   "type": "PDF",
                   "link": "https://drive.google.com/drive/folders/1Yg4kCNSbmmA1FuaswgqHZrI_0kr10A1E",
                   "text": ""
                 }
               }            
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام وجبات ١٨٠٠ سعر حراري منخفض النشويات (٣٥٪)",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1i3JV1F_MrF1Y5Goh3x-fVRCskV8MwKz_/view?usp=drive_link",
                   "text": ""
                 }
               }                          
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام وجبات ٢٠٠٠ سعر حراري منخفض النشويات (٣٥٪)",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1DAO4Dme-lC17jJfwe9qWC_fe79l0eZx-/view?usp=drive_link",
                   "text": ""
                 }
               }                        
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام وجبات ٢٠٠٠ سعر حراري منخفض النشويات (٢٥٪)",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1sARZG5Q1h4ay1npXhhZaF-czUxcSZUgG/view?usp=drive_link",
                   "text": ""
                 }
               }                        
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام وجبات١٨٠٠ سعر حراري منخفض النشويات (٢٥٪)",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1r2Sh9dvFcorgw_5-rEjqtGzFp_64Gy8C/view?usp=drive_link",
                   "text": ""
                 }
               }                        
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام وجبات ١٨٠٠ سعر حراري (متوازن)",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1TUCqtVMFD-lMd-su6izMO_siXvMNf9mh/view?usp=drive_link",
                   "text": ""
                 }
               }                        
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام وجبات ١٢٠٠ سعر حراري (متوازن)",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1rEpgk0nJflhqlUZLeUUwx-Itxv8xMUpr/view?usp=drive_link",
                   "text": ""
                 }
               }                                     
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام وجبات ١٢٠٠ سعر حراري منخفض النشويات (٣٥٪)",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1VSJHmAshYZOIFDJ3fJ2w9ZfMxz0-qcw_/view?usp=drive_link",
                   "text": ""
                 }
               }                                                  
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام وجبات ٢٠٠٠ سعر حراري (متوازن)",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1Szqx3FsBVcshQTWfn_SFrkblGEDle8t0/view?usp=drive_link",
                   "text": ""
                 }
               }                                                  
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام وجبات ١٢٠٠ سعر حراري منخفض النشويات (٢٥٪)",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/12LjBtzMxJDzZ9GNnF9NcG4IWsS5Rbdjf/view?usp=drive_link",
                   "text": ""
                 }
               }                                                  
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام وجبات ١٥٠٠ سعر حراري (متوازن)",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1lVeywF1JHB9B_Lma6F3hmmkQUeDa9r5Y/view?usp=drive_link",
                   "text": ""
                 }
               }                                                 
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام وجبات ١٥٠٠ سعر حراري منخفض البروتين",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1HKEB-SXpTrRQR6HhnREPx8PskNVhfEaj/view?usp=drive_link",
                   "text": ""
                 }
               }                                                
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نظام وجبات ١٥٠٠ سعر حراري منخفض النشويات (٢٥٪)",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1r4QOsWBXuFhuRryZy5b7MSqrkn0TSrZ7/view?usp=drive_link",
                   "text": ""
                 }
               }                                                
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "Cheating Meal",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1zsw7tnVHhW1PemOyk87dzc2xbtbrrgNW/view",
                   "text": ""
                 }
               }                                                
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "كمية المشروبات الكحولية المسموح بها",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/152FHyK_sxVW5xt9z3tOf4cbJAW2UeI7E/view",
                   "text": ""
                 }
               }                                                
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "تعليمات غذائية لقصور وظائف الكلي",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1jfLEeimI2BDfYMfATCklYwedi1IVqQqw/view",
                   "text": ""
                 }
               }                                                
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "نصائح لتجنب ارتفاع معدلات السكر و التراكمي في الدم",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/14CrDda4_CGiCm4PpOfuNjmYT3-CZt1a0/view",
                   "text": ""
                 }
               }                                                
              `,
              patientPlans: [],
            },
            {
              goalType: "EducationContent",
              goal: `
               {
                 "lesson": {
                   "title": "النوم الصحي",
                   "type": "PDF",
                   "link": "https://drive.google.com/file/d/1sqyYXRPf1f_Fw7--xXeFYBjK9Ny2RQ7I/view",
                   "text": ""
                 }
               }                                               
              `,
              patientPlans: [],
            },
          ])
          .filter(
            (goal) =>
              assignedEducationGoalsList
                .filter(
                  (assignedGoal) => assignedGoal.goalType === "EducationContent"
                )
                .find((assignedGoal) =>
                  areEducationContentsEqual(
                    getEducationContent(assignedGoal.goal),
                    getEducationContent(goal.goal)
                  )
                ) === undefined
          )
      ),
    [patientPlansGoals.goals, assignedEducationGoalsList]
  );

  const handleAddGoal = useCallback(
    (goal: UpdatePatientPlanPatientPlanGoalDTO) => {
      const updateDTO = {
        ...patientDetailedPlanDTO,
        patientPlanGoals: [...patientDetailedPlanDTO.patientPlanGoals, goal],
      };

      updatePatientPlan(patientDetailedPlanDTO.patientPlan, updateDTO)
        .then(() => {
          showToast("Success", `${goal.goalType} added successfully`);
          reloadPatientDetailedPlan();
        })
        .catch(() => showToast("Error", `Failed to add ${goal.goalType} :(`));
    },
    [
      patientDetailedPlanDTO,
      reloadPatientDetailedPlan,
      showToast,
      updatePatientPlan,
    ]
  );

  const handleDeleteGoal = useCallback(
    (goal: UpdatePatientPlanPatientPlanGoalDTO) => {
      const updateDTO = {
        ...patientDetailedPlanDTO,
        patientPlanGoals: patientDetailedPlanDTO.patientPlanGoals.filter(
          (assignedGoal) =>
            !(
              assignedGoal.goal === goal.goal &&
              assignedGoal.goalType === goal.goalType
            )
        ),
      };

      updatePatientPlan(patientDetailedPlanDTO.patientPlan, updateDTO)
        .then(() => {
          showToast("Success", `${goal.goalType} removed successfully`);
          reloadPatientDetailedPlan();
        })
        .catch(() =>
          showToast("Error", `Failed to remove ${goal.goalType} :(`)
        );
    },
    [
      patientDetailedPlanDTO,
      reloadPatientDetailedPlan,
      showToast,
      updatePatientPlan,
    ]
  );

  const value = useMemo(
    () => ({
      selectGoalsList,
      assignedGoals,
      handleAddGoal,
      handleDeleteGoal,
      assignedNutritionGoalsList,
      selectNutritionGoalsList,
      assignedLifestyleGoalsList,
      selectLifestyleGoalsList,
      assignedEducationGoalsList,
      selectEducationGoalsList,
    }),
    [
      selectGoalsList,
      assignedGoals,
      handleAddGoal,
      handleDeleteGoal,
      assignedNutritionGoalsList,
      selectNutritionGoalsList,
      assignedLifestyleGoalsList,
      selectLifestyleGoalsList,
      assignedEducationGoalsList,
      selectEducationGoalsList,
    ]
  );

  return (
    <GolasContext.Provider value={value}>{children}</GolasContext.Provider>
  );
};

export const usePlansGoals = () => {
  const plansGoals = useContext(GolasContext);

  if (plansGoals === undefined) {
    throw new Error("usePlansGoals must be used within GoalsProvider");
  }

  return plansGoals;
};
