import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  mediumRegularFont,
  regularSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";
import { lightShadowAlt } from "../../../../../styles/global-style";

export const MessagingChannelHeaderContainer = styled.div`
  ${flexCenter}
  padding: 16px 24px;
  ${lightShadowAlt}
  position: relative;
  z-index: 2;
  background: ${({ theme }) => theme.colors.containerColor};

  @media screen and (max-width: 640px) {
    padding: 16px;
  }
`;

export const MessagingChannelHeaderMenuIconContainer = styled.div`
  width: 30px;
  height: 38px;
  display: none;
  margin-right: 16px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 960px) {
    display: block;
  }
`;

export const MessagingChannelHeaderImageContainer = styled.div`
  overflow: hidden;
  ${flexCenterCenter}
`;

export const MessagingChannelHeaderTextContainer = styled.div`
  margin-left: 8px;
  cursor: pointer;

  p {
    margin: 0px;
    ${regularSemiBoldFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }

  span {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const MessagingChannelHeaderRight = styled.div`
  margin-left: auto;
  ${flexCenter}
  gap: 0px 16px;
`;
