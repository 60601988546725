import { Modal } from "../../../../../components/modal/modal";
import { RowsActionsProvider } from "./manual-readings-modal/context/rows-actions-context";
import { ManualReadingsModal } from "./manual-readings-modal/manual-readings-modal";
import { DropdownListItem } from "../../../../../components/dropdown/styled-dropdown";
import { useModalHelpers } from "../../../../../components/modal/use-modal-helpers";

export function ManualReadings() {
  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <>
      <DropdownListItem onClick={openModal}>Manual Readings</DropdownListItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <RowsActionsProvider>
          <ManualReadingsModal
            key={modalBodyKey}
            handleHideModal={closeModal}
          />
        </RowsActionsProvider>
      </Modal>
    </>
  );
}
