import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  colors: {
    primaryColor: `#00086b`,
    primaryColorAlt: `#1097cf`,
    titleColor: `#040415`,
    screenTitleColor: `#02100F`,
    textColor: `#8C8E97`,
    lightTextColor: `#121212`,
    linkColor: `#333333`,
    linkColorAlt: `#828282`,
    buttonColor: `#ffffff`,
    buttonColorAlt: `#14212E`,
    inputColor: `#f4f5f7`,
    bodyColor: `#f5f7fa`,
    containerColor: `#ffffff`,
    borderColor: `#ecedef`,
    borderColorAlt: `#e8e7e7`,
    success: `#41a56c`,
    warning: `#fc881b`,
    danger: `#d73f1b`,
    dangerText: `#ff1414`,
    sidebarLinkGradient: `linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.19) 0%,
      rgba(255, 255, 255, 0) 100%`,
    scrollbarThumb: `#a7adb8`,
    barBackground: `#ededed`,
    activeLayoutSidebarLink: `linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.19) 0%,
      rgba(255, 255, 255, 0) 100%
    )`,
    logoutModalBorder: `#cac4cf`,
    logoutModalBackground: `#dcdddf`,
  },
  newColors: {
    otidaO2: `#eaf7ff`, // ex: Nutrition Summary loader bg or Tooltip header
    otida03: `#848a9f`,
    otida04: `#fff8e5`,
    backdrop: `rgba(0, 8, 107, 0.5)`,
    containerColorAlt: `#fcfdff`,
    containerColorBackgroundAlt: `rgba(242, 247, 250, 0.5)`,
    gray6: `#f2f2f2`, // ex: diagnosis list item
    imageFrontdrop: `#40444c99`,
    imageFront: `#40444c`,
    imageBackdrop: `#f9fafb`,
    modalContainerAlt: `#8a94a6`,
    warningAlt: `#febb12`,
    dangerAlt: `#fff7fa`,
    veryDanger: `#ff145b`,
    dangerBackground: `#fde1ee`,
    warningBackground: `#fdfdc9`,
    successAlt: `#e8fff2`,
    medicalChartPoint: `#219653`,
    otpInputsBackground: `#c4cacf1a`,
    draftBackground: `#fff8e6`,
    textColorAlt: `#686868`,
    textColorAlt2: `#3f4254`,
    inputColorAlt: `#f4f4f5`,
    successAlt2: `#03CC83`,
    bgmChartGreen: `#63c38f`,
    bgmChartRed: `#ee3c3e`,
    dropdownIconBlue: `#262d81`,
    dropdownIconWhite: `#eaeaed`,
    oddBackground: `#f9fbfc`,
    gold: `#d18700`,
    cardColor: `#f7fafe`,
    labResultsOldUIChartColor: `#e9e9e9`,
    dangerBtnBgTransparent: `#fff0f0`,
    primaryBtnBgTransparent: `#f1f2ff`,
    byOtida: `#3e66fb`,
    activeState: `rgba(33, 150, 83, 0.15)`,
    newBorderColor: `#f1f3f9`,
    newBorderColorAlt: `#e4e6ef`,
    progressBarLineColor: `#e5eaee`,
    progressDateTitleColor: `#464E5F`,
    otidaTableCells: `#f2f8ff`,
    redTableCells: `#fff5f5`,
    greenTableCells: `#ecfaf2`,
    transparentImageCountContainer: `#ecedeff5`,
    insulinSeries: `#df0fdf`,
  },
  typography: {
    fontSize: {
      xlFontSize: `35px`,
      lgFontSize: `24px`,
      regularFont: `16px`,
      regularSmFont: `15px`,
      mdFontSize: `14px`,
      smFontSize: `12px `,
      xsFontSize: `10px`,
    },
    fontWeight: {
      bold: `700`,
      semiBold: `600`,
      medium: `500`,
      regular: `400`,
      light: `300`,
    },
  },
  // z100
  // z200
  // z300: Upload Dropdown
  // z400: Layout (Navigation & Sidebar) & Single patient layout
  // z500: Modal Backdrop
  // z600: Modals
  // z700: Dropdowns (Modals)
  // z800: responsive Sidebar Modal Backdrop
  // z900: responsive sidebar
  // z1000: Loaders
  zIndex: {
    z100: `100`,
    z200: `200`,
    z300: `300`,
    z400: `400`,
    z500: `500`,
    z600: `600`,
    z700: `700`,
    z800: `800`,
    z900: `900`,
    z1000: `1000`,
  },
  padding: {
    pad05_1: `0.5rem 1rem`,
    pad05All: `0.5rem`,
    pad1All: `1rem`,
    py_1: `1rem 0rem`,
    py_1_5: `1.5rem 0rem`,
    py_05: `0.5rem 0rem`,
    px_05: `0rem 0.5rem`,
    px_1: `0rem 1rem`,
  },
  margin: {
    mr_b_2: `2rem`,
    mr_b_1: `1rem`,
  },
};
