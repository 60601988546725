import { Controller, useFormContext } from "react-hook-form";
import { ModalForm } from "../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ErrorText,
  Input,
  InputContainer,
  InputLabel,
  InputsGroup,
  ModalFooter,
} from "../../../../../styles/classes/reusable-classes";
import { AddNewMealModalBodyContainer } from "./styled-add-new-meal-modal-body";
import { ImageUpload } from "./image-upload/image-upload";
import { useState } from "react";
import { useAddPatientMeal } from "../../../../../hooks/patient-meal-hooks/use-add-patient-meal";
import { CreatePatientMealDTO } from "../../../../../models/patient-meal-dtos/create-patient-meal-dto";
import { useToastService } from "../../../../../context/toast-service-context";
import { useFoodLoggingData } from "../../../context/food-logging-data-context";
import { useReloadPatientMeals } from "../../../context/loadable-patient-meals-context";
import { AddNewMealModalInputs } from "../add-new-meal";
import { Loader } from "../../../../../components/loaders/otida-loader/styled-otida-loader";
import { Dropdown } from "../../../../../components/dropdown/dropdown";
import { PATIENT_MEAL_TAGS } from "../../../../../models/patient-meal-dtos/patient-meal-tag";
import { DropdownListItem } from "../../../../../components/dropdown/styled-dropdown";

type AddNewMealModalBodyProps = {
  onClose: () => void;
};

export const AddNewMealModalBody = (props: AddNewMealModalBodyProps) => {
  const { onClose } = props;

  const { selectedPatientDTO, setSelectedPatientMealDTO } =
    useFoodLoggingData();

  const {
    watch,
    handleSubmit,
    formState: { errors },
    control,
  } = useFormContext<AddNewMealModalInputs>();

  const noteWatcher = watch("note");

  const [imageFiles, setImageFiles] = useState<Blob[] | null>(null);
  const [loading, setLoading] = useState(false);

  const requiredImageOrTextNote =
    (noteWatcher === undefined || noteWatcher === "") && imageFiles === null;

  const addPatientMeal = useAddPatientMeal();

  const { showToast } = useToastService();
  const reload = useReloadPatientMeals();

  const onSubmit = handleSubmit(async (inputs) => {
    const createPatientMealDTO: CreatePatientMealDTO = {
      date: inputs.date!,
      time: `${inputs.time!}:00`,
      note: inputs.note,
      tag: inputs.mealTag,
    };

    try {
      setLoading(true);
      await addPatientMeal(
        selectedPatientDTO!.id,
        createPatientMealDTO,
        imageFiles !== null ? imageFiles : [],
        []
      ).then((response) => setSelectedPatientMealDTO(response));

      showToast("Success", "Meal Created Successfully");
      onClose();
      reload();
      setLoading(false);
    } catch (error) {
      showToast("Error", "Failed to Create Meal :(");
    }
  });

  return (
    <ModalForm onSubmit={onSubmit} width={600} height={700}>
      <ModalHeader onModalClose={onClose} title="Add New Meal" />
      <AddNewMealModalBodyContainer>
        <ImageUpload
          imageFiles={imageFiles}
          setImageFiles={setImageFiles}
          requiredImageOrTextNote={requiredImageOrTextNote}
        />
        <InputsGroup>
          <Controller
            control={control}
            name="date"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <InputContainer>
                <InputLabel>Meal Date</InputLabel>
                <Input
                  type="date"
                  value={value === undefined ? "" : value}
                  onChange={(e) =>
                    onChange(e.target.value === "" ? undefined : e.target.value)
                  }
                />
                {errors.date?.type === "required" && (
                  <ErrorText>Required</ErrorText>
                )}
              </InputContainer>
            )}
          />
          <Controller
            control={control}
            name="time"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <InputContainer>
                <InputLabel>Meal Time</InputLabel>
                <Input
                  type="time"
                  value={value === undefined ? "" : value}
                  onChange={(e) =>
                    onChange(e.target.value === "" ? undefined : e.target.value)
                  }
                />
                {errors.time?.type === "required" && (
                  <ErrorText>Required</ErrorText>
                )}
              </InputContainer>
            )}
          />
        </InputsGroup>
        <Controller
          control={control}
          name="mealTag"
          render={({ field: { value, onChange } }) => (
            <InputContainer>
              <InputLabel>Meal Tag</InputLabel>
              <Dropdown
                variant="designed-ux"
                text={value === undefined ? "Select Meal's Tag" : value}
                fullWidth
              >
                {PATIENT_MEAL_TAGS.map((patientMealTag, index) => (
                  <DropdownListItem
                    key={index}
                    variant="designed-ux"
                    onClick={() => onChange(patientMealTag)}
                  >
                    {patientMealTag}
                  </DropdownListItem>
                ))}
              </Dropdown>
            </InputContainer>
          )}
        />
        <Controller
          control={control}
          name="note"
          rules={{ required: requiredImageOrTextNote ? true : false }}
          render={({ field: { value, onChange } }) => (
            <InputContainer margin>
              <InputLabel>
                Note <InputLabel small>(optional)</InputLabel>{" "}
              </InputLabel>
              <Input
                type="text"
                placeholder="Write a short note..."
                value={value === undefined ? "" : value}
                onChange={(e) =>
                  onChange(e.target.value === "" ? undefined : e.target.value)
                }
              />
              {errors.note?.type === "required" && (
                <ErrorText>Upload an image or write a short note</ErrorText>
              )}
            </InputContainer>
          )}
        />
      </AddNewMealModalBodyContainer>
      <ModalFooter>
        <Button outlined type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={loading} type="submit">
          {loading ? <Loader radius={20} /> : `Confirm`}
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
