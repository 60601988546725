import { DietitianReportTabHeader } from "../components/dietitian-report-header/dietitian-report-header";
import { DietitianReportTabContainer } from "../components/reusable-styles";
import { NutritionAnalysisHeader } from "./nutrition-analysis-header/nutrition-analysis-header";
import { NutritionAnalysisBody } from "./nutrition-analysis-body/nutrition-analysis-body";
import { DateRangeProvider } from "../../../../components/date-range/context/date-range-context";

export const NutritionAnalysis = () => {
  return (
    <DateRangeProvider>
      <DietitianReportTabContainer>
        <DietitianReportTabHeader
          icon="/img/plans/nav/goals.svg"
          title="Nutrition Analysis"
          subTitle="Add nutrition item for you patient"
        />
        <NutritionAnalysisHeader />
        <NutritionAnalysisBody />
      </DietitianReportTabContainer>
    </DateRangeProvider>
  );
};
