import styled from "styled-components/macro";

export const NutritionAnalysisMealsChartContainer = styled.div`
  width: 50%;
`;

export const NutritionAnalysisMealsChartWrapper = styled.div`
  height: 400px;
  width: 100%;
  margin: 0 auto;
`;
