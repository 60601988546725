import { PatientActivityLogStatsActivityModeDayDTO } from "../../../../../../models/patient-activity-log-dtos/patient-activity-log-stats-activity-mode-day-dto";
import { PatientActivityLogStatsDTO } from "../../../../../../models/patient-activity-log-dtos/patient-activity-log-stats-dto";
import { PatientProfileLifeActivitiesTooltipProps } from "../patient-profile-life-activities-tooltip/patient-profile-life-activities-tooltip";

type MixedSeriesDataSource = {
  x: string;
  y: number;
  mixedActivity?: PatientActivityLogStatsActivityModeDayDTO[];
  tooltip: PatientProfileLifeActivitiesTooltipProps;
}[];

export function buildMixedSeries(
  patientActivityLogStatsDTO: PatientActivityLogStatsDTO
): MixedSeriesDataSource {
  const mixedSeries: MixedSeriesDataSource = [];

  const mixedActivity = patientActivityLogStatsDTO.activityModes.find(
    (activity) => activity.activityMode?.name === "Mixed Activity"
  )?.days;

  if (mixedActivity !== undefined) {
    for (const day of mixedActivity) {
      const date = day.date;
      const mins = day.mins;

      const tooltip: PatientProfileLifeActivitiesTooltipProps = {
        data: day.patientActivityLogs,
        activityModeName: "Mixed Activity",
      };

      mixedSeries.push({
        x: date,
        y: mins,
        mixedActivity,
        tooltip: tooltip,
      });
    }
  }

  return mixedSeries;
}
