import { TableContainer, TableTable, TBody } from "./styled-table";
import { ReactElement } from "react";

type TableProps = {
  tableHeadings: ReactElement | Array<JSX.Element>;
  tableRows: ReactElement | Array<JSX.Element>;
  modalTable?: boolean;
};

export const Table = (props: TableProps) => {
  const { tableHeadings, tableRows, modalTable } = props;

  return (
    <TableContainer modalTable={modalTable}>
      <TableTable>
        <thead>
          <tr>{tableHeadings}</tr>
        </thead>
        <TBody>{tableRows}</TBody>
      </TableTable>
    </TableContainer>
  );
};
