import { CarbSourceDTO } from "../../../../../models/carb-sources/carb-source-dto";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FoodLoggingSelectedPatientMealInputs } from "../../food-logging-selected-patient-meal";
import { Fragment, useMemo, useState } from "react";
import {
  FoodLoggingSelectedPatientMealSourcesContainer,
  FoodLoggingSelectedPatientMealSourcesInputContainer,
  FoodLoggingSelectedPatientMealSourcesList,
  FoodLoggingSelectedPatientMealSourcesListItem,
} from "../../styled-food-logging-selected-patient-meal";
import { FoodLoggingSelectedPatientMealContainerTitle } from "../../styled-food-logging-selected-patient-meal";
import { AutoSelect } from "../../../../../components/auto-select/auto-select";
import { useEnterKeypress } from "../../../../../hooks/use-enter-keypress/use-enter-keypress";
import { useFoodLoggingData } from "../../../context/food-logging-data-context";

export function FoodLoggingSelectedPatientMealCarbSources() {
  const { carbSourceDTOs } = useFoodLoggingData();

  const [autoSelectTagValue, setAutoSelectTagValue] = useState("");

  const { control } = useFormContext<FoodLoggingSelectedPatientMealInputs>();

  const {
    fields: updatePatientMealCarbSourceDTOs,
    append: appendCarbSource,
    remove: removeCarbSource,
  } = useFieldArray({
    name: "carbSources",
    control,
  });

  const updatePatientMealCarbSourceIndexById: Map<
    number,
    number
  > = useMemo(() => {
    return new Map(
      updatePatientMealCarbSourceDTOs.map(
        (updatePatientMealCarbSourceDTO, index) => [
          updatePatientMealCarbSourceDTO.carbSourceId,
          index,
        ]
      )
    );
  }, [updatePatientMealCarbSourceDTOs]);

  const filteredCarbs = useMemo(
    () =>
      carbSourceDTOs.reduce((acc: string[], carbSourceDTO) => {
        const carbSourceName = carbSourceDTO.name;

        if (!updatePatientMealCarbSourceIndexById.has(carbSourceDTO.id)) {
          acc.push(carbSourceName);
        }

        return acc;
      }, []),
    [carbSourceDTOs, updatePatientMealCarbSourceIndexById]
  );

  const carbSource = useMemo(
    () => carbSourceDTOs.find((item) => item.name === autoSelectTagValue),
    [carbSourceDTOs, autoSelectTagValue]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function addToCarbSources() {
    if (!updatePatientMealCarbSourceIndexById.has(carbSource!.id)) {
      appendCarbSource({ carbSourceId: carbSource!.id });
      setAutoSelectTagValue("");
    }
  }

  function removeFromCarbSources(carbSourceDTO: CarbSourceDTO) {
    const updatePatientMealCarbSource = updatePatientMealCarbSourceIndexById.get(
      carbSourceDTO.id
    );
    removeCarbSource(updatePatientMealCarbSource);
  }

  const { onEnterKeyPress } = useEnterKeypress(addToCarbSources);

  const currentMealCarbSources = carbSourceDTOs.map((carbSourceDTO, index) => {
    return (
      <Fragment key={index}>
        {updatePatientMealCarbSourceIndexById.has(carbSourceDTO.id) && (
          <FoodLoggingSelectedPatientMealSourcesListItem
            onClick={() => removeFromCarbSources(carbSourceDTO)}
          >
            {carbSourceDTO.name}
            <span className="material-symbols-outlined">close</span>
          </FoodLoggingSelectedPatientMealSourcesListItem>
        )}
      </Fragment>
    );
  });

  return (
    <FoodLoggingSelectedPatientMealSourcesContainer>
      <div>
        <FoodLoggingSelectedPatientMealContainerTitle>
          Carb sources
        </FoodLoggingSelectedPatientMealContainerTitle>
        <FoodLoggingSelectedPatientMealSourcesInputContainer>
          <AutoSelect
            placeHolder="Add carb sources"
            itemsArray={
              filteredCarbs.length > 0
                ? filteredCarbs
                : ["No more carb sources"]
            }
            onChange={(value: string) => setAutoSelectTagValue(value)}
            value={autoSelectTagValue}
            fullWidth
            disabled={filteredCarbs.length === 0}
          />
          <button
            type="button"
            onClick={addToCarbSources}
            onKeyDown={(e) => onEnterKeyPress(e)}
          >
            Add
          </button>
        </FoodLoggingSelectedPatientMealSourcesInputContainer>
      </div>
      <FoodLoggingSelectedPatientMealSourcesList>
        {currentMealCarbSources}
      </FoodLoggingSelectedPatientMealSourcesList>
    </FoodLoggingSelectedPatientMealSourcesContainer>
  );
}
