import { Fragment } from "react";
import {
  NutritionReportTableRowTDImage,
  NutritionReportTableRowTDImages,
} from "../components/styled-nutrition-report-table-cell";
import { PatientNutritionReportDailySummaryDayPatientMealDTO } from "../../../../../../models/patient-nutrition-report-dtos/patient-nutrition-report-daily-summary-day-patient-meal-dto";

type NutritionReportMealDateTableCellImagesProps = {
  meal: PatientNutritionReportDailySummaryDayPatientMealDTO;
  openModal: () => void;
};

export const NutritionReportMealDateTableCellImages = (
  props: NutritionReportMealDateTableCellImagesProps
) => {
  const { meal, openModal } = props;

  return (
    <NutritionReportTableRowTDImages show={meal.patientMealImages.length > 0}>
      {meal.patientMealImages.slice(0, 2).map((img, index) => (
        <Fragment key={img.publicUrl}>
          <NutritionReportTableRowTDImage>
            <img
              src={img.publicUrl}
              alt={`meal images for date: ${meal.date}`}
            />
          </NutritionReportTableRowTDImage>
          {index === 1 && (
            <NutritionReportTableRowTDImage
              faded={meal.patientMealImages.length > 2}
              onClick={openModal}
            >
              <p>
                +
                {
                  meal.patientMealImages.slice(2, meal.patientMealImages.length)
                    .length
                }
              </p>
            </NutritionReportTableRowTDImage>
          )}
        </Fragment>
      ))}
    </NutritionReportTableRowTDImages>
  );
};
