import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  mediumFont,
  mediumRegularFont,
  regularMediumFont,
} from "../../../../../styles/classes/gloabl-classes";
import { Button } from "../../../../../styles/classes/reusable-classes";

export const AnalyticsAndReportsBodyContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  gap: 16px;
  margin: 0px 24px;
  padding-bottom: 128px;
`;

export const AnalyticsAndReportsBodyCard = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.newColors.cardColor};
  padding: 16px 24px;
`;

export const AnalyticsAndReportsBodyCardHeader = styled.div`
  span {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.colors.textColor};
  }

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
  }
`;

export const AnalyticsAndReportsBodyCardFooter = styled.div`
  ${flexCenterSpaceBetween}
  margin-top: 24px;

  span {
    ${mediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }

  ${Button} {
    padding: 8px 12px;
    ${mediumFont}
  }
`;
