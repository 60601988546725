import { useCallback } from "react";
import { ImmediateAttentionPatientsTableGenderItem } from "./styled-immediate-attention-patients-table-gender-filter";
import { useHomeStaticData } from "../../../../../context/home-static-data-context";
import { HomeFilterDropdown } from "../components/home-filter-dropdown";
import { useImmediateAttentionPatientsSelectedFilters } from "../../../../context/immediate-attention-patients-selected-filters-context";

export const ImmediateAttentionPatientsTableGenderFilter = () => {
  const {
    setSelectedFilters,
    selectedFilters,
    selectedGender,
    setSelectedGender,
  } = useImmediateAttentionPatientsSelectedFilters();

  const genderOptions: ["All", "Male", "Female"] = ["All", "Male", "Female"];

  const { setFilteredHomeStaticData, homeStaticData } = useHomeStaticData();

  const handleAddFilter = useCallback(
    (gender: string) => {
      const existingFilter = selectedFilters.find(
        (filter) => filter.filterName === "Sex"
      );

      if (existingFilter) {
        // Update the existing filter
        setSelectedFilters(
          selectedFilters.map((filter) =>
            filter.filterName === "Sex"
              ? { ...filter, filterValue: gender }
              : filter
          )
        );
      } else {
        // Add a new filter
        setSelectedFilters([
          ...selectedFilters,
          { filterName: "Sex", filterValue: gender },
        ]);
      }
    },
    [selectedFilters, setSelectedFilters]
  );

  const handleGenderFilter = useCallback(
    (gender: "All" | "Male" | "Female") => {
      setSelectedGender(gender);

      const newData =
        gender !== "All"
          ? homeStaticData.filter((item) => item.patient.gender === gender)
          : homeStaticData.filter((item) => item.patient.gender !== gender);

      setFilteredHomeStaticData(newData);
      handleAddFilter(gender);
    },
    [
      setFilteredHomeStaticData,
      homeStaticData,
      handleAddFilter,
      setSelectedGender,
    ]
  );

  return (
    <HomeFilterDropdown
      width="calc(100% + 10px)"
      height="120px"
      buttonText="Sex"
      dropdownOptions={
        <>
          {genderOptions.map((gender) => (
            <ImmediateAttentionPatientsTableGenderItem
              key={gender}
              $selected={selectedGender === gender}
            >
              <div onClick={() => handleGenderFilter(gender)} />
              <p>{gender}</p>
            </ImmediateAttentionPatientsTableGenderItem>
          ))}
        </>
      }
    />
  );
};
