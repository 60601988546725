import { Temporal } from "temporal-polyfill";
import { PatientInsulinLogDTO } from "../../../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";

export function insulinToHtmlTooltip(
  patientInsulinLogDTO: PatientInsulinLogDTO
) {
  const timeHtml = pateintInsulinTimeToHtml(patientInsulinLogDTO);
  const tooltipBody = pateintInsulinBodyToHtml(patientInsulinLogDTO);

  const tooltipContainerStyle = `
      width: 300px;
      background: #fff;
      box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0) !important;
      border: 1px solid rgba(255, 0, 0 ,0)  !important;
      display: flex;
      flex-direction: column;
    `;

  return `
    <div style='${tooltipContainerStyle}'>
      ${timeHtml}
      ${tooltipBody}
    </div>`;
}

function pateintInsulinTimeToHtml(
  patientInsulinLogDTO: PatientInsulinLogDTO
): string {
  const topContainerStyle = `
      background: #eaf7ff;
      border-bottom: 1px solid #ecedef;
      padding: 1rem;
    `;

  const timeContainerStyle = `
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    `;

  const titleStyle = `
      font-size: 1.1rem;
      font-weight: 700;
      color: #040415;
      margin: 0;
    `;

  const timeTextStyle = `
      font-size: 1rem;
      font-weight: 400;
      color: #8c8e97;
    `;

  return `
    <div style='${topContainerStyle}'>
      <div style="${timeContainerStyle}">
        <p style='${titleStyle}'>Insulin</p>
        <span style='${timeTextStyle}'>${
    patientInsulinLogDTO.date
  }, ${Temporal.PlainTime.from(patientInsulinLogDTO.time).toLocaleString("en", {
    hour12: true,
    second: undefined,
  })}</span>
      </div>
    </div>
    `;
}

function pateintInsulinBodyToHtml(
  patientInsulinLogDTO: PatientInsulinLogDTO
): string {
  const insulinBodyContainerStyle = `
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px 8px;
      padding: 8px;
    `;

  const insulinMedicationNameTextStyle = `
      font-size: 16px;
      color: #848a9f;
      font-weight: 700;
    `;

  const insulinDoseTextStyle = `
      font-weight: 700;
      font-size: 1.2rem;
      text-align: center;
      margin: 0px;
    `;

  return `
    <div style='${insulinBodyContainerStyle}'>
        <span style='${insulinMedicationNameTextStyle}'>${patientInsulinLogDTO.insulinMedication.name}</span>
        <p style='${insulinDoseTextStyle}'>
          ${patientInsulinLogDTO.dose}
          <span style='${insulinMedicationNameTextStyle}'>Units</span>
        </p>
    </div>
  `;
}
