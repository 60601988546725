import {
  CGMReportResponseTimeRangesBody,
  CGMReportResponseTimeRangesContainer,
  CGMReportResponseTimeRangesHeader,
} from "./styled-cgm-report-response-time-ranges";
import { CGMReportResponseTimeRangesChart } from "./cgm-report-response-time-ranges-chart/cgm-report-response-time-ranges-chart";
import { CGMReportResponseTimeRangesDetails } from "./cgm-report-response-time-ranges-details/cgm-report-response-time-ranges-details";
import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { useCGMReportLoadedResponse } from "../../../context/cgm-report-loaded-response-context";

export const CGMReportResponseTimeRanges = () => {
  const { patientCGMEntryDTOs } = useCGMReportLoadedResponse();

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <CGMReportResponseTimeRangesContainer>
        <CGMReportResponseTimeRangesHeader>
          <p>Time Ranges</p>
        </CGMReportResponseTimeRangesHeader>
        <CGMReportResponseTimeRangesBody>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </CGMReportResponseTimeRangesBody>
      </CGMReportResponseTimeRangesContainer>
    );
  }

  return (
    <CGMReportResponseTimeRangesContainer>
      <CGMReportResponseTimeRangesHeader>
        <p>Time Ranges</p>
      </CGMReportResponseTimeRangesHeader>
      <CGMReportResponseTimeRangesBody>
        <CGMReportResponseTimeRangesChart />
        <CGMReportResponseTimeRangesDetails />
      </CGMReportResponseTimeRangesBody>
    </CGMReportResponseTimeRangesContainer>
  );
};
