import { SinglePlanDropdown } from "./components/single-plan-dropdown";
import {
  SinglePlanDropdownButtonIcon,
  SinglePlanDropdownButtonText,
  SinglePlanDropdownListItem,
} from "./components/styled-single-plan-dropdown";
import { PatientDetailedPlanDTO } from "../../../../../../models/patient-plan-dtos/patient-detailed-plan-dto";

type SinglePlanNutritionProps = {
  patientDetailedPlanDTO: PatientDetailedPlanDTO;
};

export const SinglePlanNutrition = (props: SinglePlanNutritionProps) => {
  const { patientDetailedPlanDTO } = props;

  const assignedNutritionGoalsList =
    patientDetailedPlanDTO.patientPlanGoals.filter(
      (item) => item.goalType === "NutritionInstruction"
    );

  const nutrition = assignedNutritionGoalsList.map((goal, index) => (
    <SinglePlanDropdownListItem key={index}>
      <SinglePlanDropdownButtonIcon>
        <img src="/img/plans/nav/nutrition.svg" alt="Goals Icon" />
      </SinglePlanDropdownButtonIcon>
      <SinglePlanDropdownButtonText>
        <p>{goal.goal}</p>
      </SinglePlanDropdownButtonText>
    </SinglePlanDropdownListItem>
  ));

  return (
    <div>
      <SinglePlanDropdown
        icon="/img/plans/nav/nutrition.svg"
        img="/img/view-plans/nutrition.svg"
        items={nutrition}
        subtitle="أهم التعليمات التي تحتاجها في التغذية"
        title="أهداف التغذية"
      />
    </div>
  );
};
