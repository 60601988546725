import { useImmediateAttentionPatientsSidebar } from "../../context/immediate-attention-patients-sidebar-context";
import {
  ImmediateAttentionPatientsSidebarHeaderContainer,
  ImmediateAttentionPatientsSidebarHeaderIcons,
  ImmediateAttentionPatientsSidebarHeaderText,
} from "./styled-immediate-attention-patients-sidebar-header";

export const ImmediateAttentionPatientsSidebarHeader = () => {
  const { closeSidebar } = useImmediateAttentionPatientsSidebar();

  return (
    <ImmediateAttentionPatientsSidebarHeaderContainer>
      <ImmediateAttentionPatientsSidebarHeaderText>
        <p>Patient Summary</p>
      </ImmediateAttentionPatientsSidebarHeaderText>
      <ImmediateAttentionPatientsSidebarHeaderIcons>
        <a href="https://app.staging.disqvr.com/patients/83/patient-profile">
          <span className="material-symbols-outlined">open_in_new</span>
        </a>
        <span className="material-symbols-outlined" onClick={closeSidebar}>
          close
        </span>
      </ImmediateAttentionPatientsSidebarHeaderIcons>
    </ImmediateAttentionPatientsSidebarHeaderContainer>
  );
};
