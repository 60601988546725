import { Link } from "react-router-dom";
import { ContentModuleDTO } from "../../../../../models/content-module-dtos/content-module-dto";
import {
  TD,
  TDContainer,
  TDLink,
} from "../../../../../components/table/styled-table";
import { GenericActionsList } from "../../../../../components/generic-actions-list/generic-actions-list";
import { GenericActionsListItemItem } from "../../../../../components/generic-actions-list/styled-generic-actions-list";
import { DeleteContentCourseModal } from "./delete-content-course-modal";
import {
  ContentScreenTableRowsStatusContainer,
  ContentScreenTableRowsThumbnailContainer,
} from "../../../styles/content-reusable-classes";

type ContentCoursesTableRowsProps = {
  currentPageCourses: ContentModuleDTO[];
};

export const ContentCoursesTableRows = (
  props: ContentCoursesTableRowsProps
) => {
  const { currentPageCourses } = props;

  const tableRows = currentPageCourses.map((courseDTO) => (
    <tr key={courseDTO.id}>
      <TD width={100}>
        <ContentScreenTableRowsThumbnailContainer>
          {courseDTO.thumbnailUploadedFile?.presignedUrl && (
            <img
              src={courseDTO.thumbnailUploadedFile?.presignedUrl}
              alt={`${courseDTO.name}'s Course Thumbnail`}
            />
          )}
        </ContentScreenTableRowsThumbnailContainer>
      </TD>
      <TD>
        <TDContainer>
          <TDLink>
            <Link to={`../courses/course/${courseDTO.id}`}>
              {courseDTO.name}
            </Link>
          </TDLink>
        </TDContainer>
      </TD>
      <TD>
        <ContentScreenTableRowsStatusContainer
          $live={courseDTO.isDraft ? false : true}
        >
          <span>{courseDTO.isDraft ? "Darft" : "Live"}</span>
        </ContentScreenTableRowsStatusContainer>
      </TD>
      <TD width={100}>
        <GenericActionsList>
          <GenericActionsListItemItem>
            <Link to={`../courses/course/${courseDTO.id}`}>View</Link>
          </GenericActionsListItemItem>
          <GenericActionsListItemItem>
            <Link to={`../courses/course/${courseDTO.id}/update`}>Update</Link>
          </GenericActionsListItemItem>
          <DeleteContentCourseModal contentModule={courseDTO} />
        </GenericActionsList>
      </TD>
    </tr>
  ));

  return <>{tableRows}</>;
};
