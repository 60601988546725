import { Temporal } from "temporal-polyfill";
import { PatientMedicationDTO } from "../../../../../models/patient-medication-dto";
import { MedicationCard } from "../../../medications/components/medication-card/medication-card";
import {
  MedicalListContainer,
  MedicalListLI,
  MedicalListTitle,
  MedicalListUL,
  MedicalListStatusContainer,
} from "./styles";

type CurrentMedicationsProps = {
  srotedPatientMedicationDTOs: PatientMedicationDTO[];
};

export const CurrentMedications = (props: CurrentMedicationsProps) => {
  const { srotedPatientMedicationDTOs } = props;

  return (
    <MedicalListContainer>
      <MedicalListTitle>Current medications</MedicalListTitle>
      <MedicalListUL currentMedicationsList>
        {srotedPatientMedicationDTOs.map((medication) => {
          const startDatePlusDuration =
            medication.startDate !== undefined &&
            medication.durationDays !== undefined
              ? Temporal.PlainDate.from(medication.startDate).add({
                  days: medication.durationDays,
                })
              : undefined;
          const today = Temporal.Now.plainDateISO();
          const isFinished =
            startDatePlusDuration !== undefined
              ? Temporal.PlainDate.compare(today, startDatePlusDuration)
              : undefined;

          return (
            <MedicalListLI key={medication.id}>
              <MedicationCard patientMedicationDTO={medication} img={true} />

              <MedicalListStatusContainer
                stopped={
                  medication.discontinueDate !== undefined ||
                  (isFinished !== undefined &&
                    isFinished === 1 &&
                    startDatePlusDuration !== undefined)
                }
              >
                <p>
                  {medication.discontinueDate !== undefined
                    ? "Stopped"
                    : isFinished !== undefined &&
                      isFinished === 1 &&
                      startDatePlusDuration !== undefined
                    ? "Finished"
                    : "Current"}
                </p>
                <span>
                  {medication.discontinueDate !== undefined && isFinished !== 1
                    ? medication.discontinueDate
                    : ""}
                  <span>
                    {isFinished !== undefined &&
                    isFinished === 1 &&
                    startDatePlusDuration !== undefined
                      ? startDatePlusDuration.toString()
                      : ""}
                  </span>
                </span>
              </MedicalListStatusContainer>
            </MedicalListLI>
          );
        })}
      </MedicalListUL>
    </MedicalListContainer>
  );
};
