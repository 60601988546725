import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  regularMediumFont,
  regularSemiBoldFont,
} from "../../../../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../../../../styles/global-style";

export const MessagingMediaSidebarMediaListNavListNav = styled.ul`
  ${flexCenterSpaceBetween}
`;

export const MessagingMediaSidebarMediaListNavListItem = styled.li<{
  active: boolean;
}>`
  cursor: pointer;
  ${({ active }) => (active ? regularSemiBoldFont : regularMediumFont)};
  transition: ${transition};
  position: relative;

  ::after {
    content: "";
    position: absolute;
    width: ${({ active }) => (active ? "100%" : "0px")};
    height: 4px;
    background: ${({ theme }) => theme.colors.primaryColorAlt};
    bottom: -5px;
    left: 0px;
    transition: ${transition};
  }
`;
