import { useCallback } from "react";
import { LoadableOrganizationsProvider } from "./context/organizations-context";
import { useLoadableData } from "../../hooks/use-loadable-data";
import { useGetOrganizationTypes } from "../../hooks/organization-type-hooks/use-get-organization-types";
import { useGetOrganizations } from "../../hooks/organization-hooks/use-get-organizations";
import { LoadableDataLoading } from "../../components/loadable-data-loading";
import { useGetCountries } from "../../hooks/country-hooks/use-get-countries";
import { useGetRoles } from "../../hooks/role-hooks/use-get-roles";
import { Outlet } from "react-router-dom";

export const Organizations = () => {
  const getOrganizationTypes = useGetOrganizationTypes();
  const getOrganizations = useGetOrganizations();
  const getCountries = useGetCountries();
  const getRoles = useGetRoles();

  const load = useCallback(async () => {
    const [organizationTypeDTOs, organizationDTOs, countryDTOs, roleDTOs] =
      await Promise.all([
        getOrganizationTypes(),
        getOrganizations(),
        getCountries(),
        getRoles(),
      ]);

    return { organizationTypeDTOs, organizationDTOs, countryDTOs, roleDTOs };
  }, [getOrganizationTypes, getOrganizations, getCountries, getRoles]);

  const loadableOrganizations = useLoadableData(load);

  return (
    <LoadableOrganizationsProvider
      loadableOrganizations={loadableOrganizations}
    >
      <LoadableDataLoading
        state={loadableOrganizations.state}
        successComponent={() => <Outlet />}
      />
    </LoadableOrganizationsProvider>
  );
};
