import { Modal } from "../../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../../components/modal/use-modal-helpers";
import { BmiModalBody } from "./bmi-modal-body/bmi-modal-body";

type BmiModalProps = {
  value: string | number;
  height: number;
};

export const BmiModal = (props: BmiModalProps) => {
  const { value, height } = props;

  const { closeModal, isModalOpen, modalBodyKey, openModal } =
    useModalHelpers();

  return (
    <>
      <span
        onClick={openModal}
        className="cursor-pointer"
        style={{ textDecoration: "underline" }}
      >
        <strong>{value}</strong>
      </span>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <BmiModalBody key={modalBodyKey} onClose={closeModal} height={height} />
      </Modal>
    </>
  );
};
