import { css } from "styled-components";
import {
  PatientProfileContainerCSSType,
  PatientProfileEditModalFormControlContainerCSSType,
  PatientProfileModalBodyContainerCSSType,
  PatientProfileModalInputContainerCSSType,
  PatientProfileSectionCSSType,
  PatientProfileTagListItemCSSType,
} from "./patient-profile-css-types";
import { TableContainer } from "../../../../../components/table/styled-table";
import { EmptyStateBody } from "../../../../../components/empty-state/styled-empty-state";
import {
  flexCenter,
  flexCenterSpaceBetween,
  mediumFont,
  mediumRegularFont,
  regularRegularFont,
} from "../../../../../styles/classes/gloabl-classes";
import {
  Button,
  RadioButton,
} from "../../../../../styles/classes/reusable-classes";
import { AutoSelectButton } from "../../../../../components/auto-select/styled-auto-select";

export const PatientProfileSectionCSS = css<PatientProfileSectionCSSType>`
  margin: 24px;
  display: grid;
  grid-template-columns: ${({ reverse, halfContainers, threeContainers }) =>
    reverse
      ? `1fr 2fr`
      : halfContainers
      ? `1fr 1fr`
      : threeContainers
      ? `1fr 1fr 1fr`
      : `2fr 1fr`};
  grid-template-rows: ${({ height }) => (height ? `${height}px` : "430px")};
  gap: 0px 24px;

  ${TableContainer} {
    padding-top: 0px;
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 24px 0px;
  }
`;

export const PatientProfileContainerBodyCSS = css`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-bottom: 3rem;

  ${EmptyStateBody} {
    height: 100%;
  }
`;

export const PatientProfileContainerCSS = css<PatientProfileContainerCSSType>`
  padding: 0px 0px 24px;
  background: ${({ theme }) => theme.colors.containerColor};
  border-radius: 16px;
  overflow: ${({ lifeActivitiesContainer }) =>
    lifeActivitiesContainer ? "visible" : "hidden"};
  position: ${({ lifeActivitiesContainer }) =>
    lifeActivitiesContainer ? "relative" : "static"};
  z-index: ${({ lifeActivitiesContainer }) =>
    lifeActivitiesContainer ? "390" : ""};
`;

export const PaitnetProfileChartContainerCSS = css`
  height: 285px;
  margin-top: 48px;
`;

// Modals
export const PatientProfileModalBodyContainerCSS = css<PatientProfileModalBodyContainerCSSType>`
  margin: 16px;
  overflow-y: ${({ canScrollY }) => (canScrollY ? "scroll" : "visible")};
  height: ${({ canScrollY }) => (canScrollY ? "80%" : "auto")};
`;

export const PatientProfileModalFormControlFlexNoStylesCSS = css`
  ${flexCenterSpaceBetween}
`;

export const PatientProfileModalFormControlCSS = css<PatientProfileEditModalFormControlContainerCSSType>`
  ${({ gridLayout }) =>
    gridLayout
      ? `
    display: grid;
    gap: 20px 0px;
  `
      : flexCenterSpaceBetween};
  padding: 16px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

  :nth-child(1) {
    padding-top: 0px;
  }

  :last-child {
    border-bottom: 0px;
  }
`;

export const PatientProfileModalLabelContainerCSS = css`
  display: grid;
  min-width: 280px;

  label {
    ${regularRegularFont}
    color: ${({ theme }) => theme.colors.screenTitleColor};
  }

  span {
    ${regularRegularFont}
    color: ${({ theme }) => theme.newColors.textColorAlt};
    opacity: 0.8;
  }
`;

export const PatientProfileTagsLabelContainerCSS = css`
  ${PatientProfileModalLabelContainerCSS}
  align-self: flex-start;
`;

export const PatientProfileAutoSelectFormControlContainerCSS = css`
  width: 100%;
`;

export const PatientProfileAutoSelectContainerCSS = css`
  ${flexCenter}

  ${AutoSelectButton} {
    height: 40px;
  }

  ${Button} {
    margin: 0px 16px;
  }
`;

export const PatientProfileTagListCSS = css`
  ${flexCenter}
  flex-wrap: wrap;
  gap: 8px;
`;

export const PatientProfileTagListItemCSS = css<PatientProfileTagListItemCSSType>`
  ${mediumFont}
  color: ${({ variant, theme }) =>
    variant === "Normal"
      ? "#14212e"
      : variant === "HelthBarrier"
      ? theme.colors.primaryColor
      : variant === "Diagnosis"
      ? theme.colors.danger
      : ""};
  background: ${({ variant, theme }) =>
    variant === "Normal"
      ? theme.newColors.gray6
      : variant === "HelthBarrier"
      ? theme.newColors.otidaO2
      : variant === "Diagnosis"
      ? "#fff8e5"
      : ""};
  border-radius: 94px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
  width: max-content;
  height: fit-content;
  margin-right: 0.4rem;
  cursor: pointer;

  span {
    font-size: 19px;
  }
`;

export const PatientProfileModalInputCSS = css`
  border: 0px;
  outline: 0px;
  color: ${({ theme }) => theme.colors.titleColor};
  ${mediumRegularFont}
  width: 100%;
`;

export const PatientProfileModalInputContainerCSS = css<PatientProfileModalInputContainerCSSType>`
  ${flexCenter}
  flex: 1;
  margin-left: 32px;
  gap: ${({ hasTwoInputsFlex }) => (hasTwoInputsFlex ? "0px 24px" : "0px")};
  position: relative;

  input {
    ${PatientProfileModalInputCSS}
    background: ${({ theme }) => theme.newColors.inputColorAlt};
    border-radius: 8px;
    padding: 12px 16px 10px;
  }
`;

export const PatientProfileModalInputContainerWithOneInputCSS = css<PatientProfileModalInputContainerCSSType>`
  ${flexCenter}
  flex: 1;
  margin-left: 32px;
  gap: ${({ hasTwoInputsFlex }) => (hasTwoInputsFlex ? "0px 24px" : "0px")};
  background: ${({ theme }) => theme.newColors.inputColorAlt};
  padding: 12px 12px 10px;
  border-radius: 8px;

  input {
    ${PatientProfileModalInputCSS}
    background: transparent;
    margin-left: 8px;
    min-width: ${({ hasTwoInputsFlex }) => (hasTwoInputsFlex ? "233px" : "")};
  }
`;

export const PatientProfileModaInputContainerWithSublabelCSS = css<PatientProfileModalInputContainerCSSType>`
  ${PatientProfileModalInputContainerWithOneInputCSS}
  background: transparent;
  padding: 0px;
  border-radius: 0px;

  div {
    ${flexCenterSpaceBetween}
    background: ${({ theme }) => theme.newColors.inputColorAlt};
    padding: 12px 12px 10px;
    border-radius: 8px;
    width: 233px;

    strong {
      ${mediumFont}
      color: ${({ theme }) => theme.colors.titleColor};
    }
  }

  input {
    margin-left: 0px;
    min-width: unset;
  }
`;

export const PatientProfileModalInputInputContainerCSS = css<PatientProfileModalInputContainerCSSType>`
  ${({ hasOneInput, inputWithSublabel }) =>
    hasOneInput
      ? PatientProfileModalInputContainerWithOneInputCSS
      : inputWithSublabel
      ? PatientProfileModaInputContainerWithSublabelCSS
      : PatientProfileModalInputContainerCSS};

  ${RadioButton} {
    margin: 5px auto 5px 0px;
    width: 65px;
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="month"]::-webkit-inner-spin-button,
  input[type="month"]::-webkit-calendar-picker-indicator {
    display: none;
  }
`;
