import { DateRange } from "../../../../../../components/date-range/date-range";
import { BGLAnalysisSubHeaderContainer } from "../../../styled-bgl-analysis";
import { MealsAnalysisResponseHeaderCarbsourcesFilter } from "./meals-analysis-response-header-carbsources-filter/meals-analysis-response-header-carbsources-filter";
import { MealsAnalysisResponseHeaderTags } from "./meals-analysis-response-header-tags/meals-analysis-response-header-tags";

export const MealsAnalysisResponseHeader = () => {
  return (
    <BGLAnalysisSubHeaderContainer
      style={{
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <DateRange />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <MealsAnalysisResponseHeaderCarbsourcesFilter />
        <MealsAnalysisResponseHeaderTags />
      </div>
    </BGLAnalysisSubHeaderContainer>
  );
};
