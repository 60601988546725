import styled from "styled-components/macro";
import { AutoSelectContainer } from "../../../../../../../components/auto-select/styled-auto-select";

export const MealsAnalysisResponseHeaderCarbsourcesFilterContainer = styled.div`
  width: 400px;
  display: flex;
  align-items: center;
  position: relative;

  ${AutoSelectContainer} {
    height: 40px;
  }
`;

export const MealsAnalysisResponseHeaderCarbsourcesFilterClearIcon = styled.span<{
  $disabled: boolean;
}>`
  display: ${({ $disabled }) => ($disabled ? "none" : "inline")};
  user-select: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: 700;
  font-size: 16px;
  position: absolute;
  right: 35px;
`;
